<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">

          <div class="container">
            <div class="vas-plans loan-plans">
              <div class="page-title text-center">
                <h3>
                  <span *ngIf="activeLang === 'en'">Manage My Loans</span>
                  <span *ngIf="activeLang === 'si'">මගේ ණය කළමනාකරණය කරන්න</span>
                  <span *ngIf="activeLang === 'ta'">எனது கடன்களை நிர்வகிக்கவும்</span>
                </h3>
                <div class="page-sub">
                  <p *ngIf="activeLang === 'en'">Apply your People's Bank's Loan Here</p>
                  <p *ngIf="activeLang === 'si'">ඔබේ මහජන බැංකුවේ ණය මෙතැනට අයදුම් කරන්න</p>
                  <p *ngIf="activeLang === 'ta'">உங்கள் மக்கள் வங்கியின் கடனை இங்கே விண்ணப்பிக்கவும்</p>
                </div>
              </div>

              <hr>
              <div class="vas-loans-container">
                <div class="top-btn">
                  <button class="btn btn-success d-green-btn"
                          [routerLink]="['/value-added-services/loan-forms/'+ tenantValue]">
                    <span *ngIf="activeLang === 'en'">Apply for Helaviru Loan</span>
                    <span *ngIf="activeLang === 'si'">හෙලවිරු ණය සඳහා අයදුම් කරන්න</span>
                    <span *ngIf="activeLang === 'ta'">ஹெலவிரு கடனுக்கு விண்ணப்பிக்கவும்</span>
                  </button>
                </div>
                <div class="tbl-loan- tbl basic-mat-tbl">
                  <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                    <table [dataSource]="dataSource" class="table vastbl" mat-table matSort
                           style="width:100%">

                      <ng-container matColumnDef="LoanID">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          <span *ngIf="activeLang === 'en'">Loan ID</span>
                          <span *ngIf="activeLang === 'si'">ණය හැඳුනුම්පත</span>
                          <span *ngIf="activeLang === 'ta'">கடன் ஐடி</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.LoanID}} </td>
                      </ng-container>

                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="text-center">

                          <span *ngIf="activeLang === 'en'">Loan Amount (LKR)</span>
                          <span *ngIf="activeLang === 'si'">ණය මුදල (LKR)</span>
                          <span *ngIf="activeLang === 'ta'">கடன் தொகை (LKR)</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
                      </ng-container>

                      <ng-container matColumnDef="datel">
                        <th mat-header-cell *matHeaderCellDef class="text-center">


                          <span *ngIf="activeLang === 'en'">Date</span>
                          <span *ngIf="activeLang === 'si'">දිනය</span>
                          <span *ngIf="activeLang === 'ta'">தேதி</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.datel}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef class="text-center">

                          <span *ngIf="activeLang === 'en'">Status</span>
                          <span *ngIf="activeLang === 'si'">තත්ත්වය</span>
                          <span *ngIf="activeLang === 'ta'">நிலை</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          <span *ngIf="activeLang === 'en'">Action</span>
                          <span *ngIf="activeLang === 'si'">කටයුතු</span>
                          <span *ngIf="activeLang === 'ta'">நடவடிக்கை</span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                                                    <span class="material-icons greentext"
                                                          *ngIf="element.Status !== 'DRAFT'">
                                                        lock
                                                    </span>
                          <span class="material-icons greentext" *ngIf="element.Status === 'DRAFT'"
                                (click)="openDraftApplication(element.row)">
                                                        edit
                                                    </span>
                        </td>
                      </ng-container>

                      <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                      </tr>
                      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>

                  </div>

                  <div *ngIf="spinner">
                    <div class="sk-cube-grid">
                      <div class="sk-cube sk-cube1"></div>
                      <div class="sk-cube sk-cube2"></div>
                      <div class="sk-cube sk-cube3"></div>
                      <div class="sk-cube sk-cube4"></div>
                      <div class="sk-cube sk-cube5"></div>
                      <div class="sk-cube sk-cube6"></div>
                      <div class="sk-cube sk-cube7"></div>
                      <div class="sk-cube sk-cube8"></div>
                      <div class="sk-cube sk-cube9"></div>
                    </div>
                  </div>

                  <div class="form-group btn-sections b-0 p-0 text-right">
                    <a aria-label="Close" class="btn cancel-btn-half"
                       [routerLink]="'/value-added-services/wholesale'">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>


