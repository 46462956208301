<app-header></app-header>
<div class="main-container" style="background-color: #f8f9fa">
  <div class="container contact-page">
    <div class="inner-box">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div *ngIf="spinner">
            <div class="gmap" id="location-map">
            </div>
            <div class="address-panel">
              <h4>{{'FOOTER.CONTACT_US.CONNECT_WITH' | translate}}</h4>
              <div class="address">
                <p class="address-txt">
                  <li>{{address1}}</li>
                  <li>{{address2}}</li>
                  <li>{{email}}</li>
              </div>
              <div class="row">
                <div class="col-md-4 extra"><i class="material-icons">
                    perm_phone_msg
                  </i>{{hotline}}</div>
                <div class="col-md-4 extra"><i class="material-icons">
                    public
                    <!--                  {{'FOOTER.CONTACT_US.PUBLIC' | translate}}-->
                  </i><a target="_blank" href="{{website}}">{{website}}</a></div>
              </div>

              <div class="row" style="margin-top: 20px;">
                <div class="col-12">
                  <div class="slt-logos">
                    <img src="assets/images/slt-logos.png" alt="">
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="sk-cube-grid" *ngIf="!spinner">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>


        </div>
        <div class="col-md-6 col-xs-12">
          <div class="inner-cols">
            <form [formGroup]="contactUsForm" class="sms-full-width form-box-panel" (ngSubmit)="onSubmit()">
              <div class="form-group col-lg-12 col-md-12">
                <label>{{'FOOTER.CONTACT_US.FULL_NAME' | translate}}
                  <span style="color: red;margin-left: .5vw">*</span></label>
                <input [(ngModel)]="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                  formControlName="name" name="name" type="text" class="form-control forminput">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div style="font-size: 10px;color: red" *ngIf="f.name.errors.required">
                    {{'FOOTER.CONTACT_US.NAME_REQUIRED' | translate}}
                  </div>
                </div>
                <div style="font-size: 10px;color: red" *ngIf="wrongName">
                  {{'FOOTER.CONTACT_US.VALID_NAME_REQUIRED' | translate}}
                </div>
              </div>
              <div class="form-group col-lg-12 col-md-12">
                <label>{{'FOOTER.CONTACT_US.MOBILE' | translate}}
                  <span style="color: red;margin-left: .5vw">*</span></label>
                <input [(ngModel)]="mobile" name="text" formControlName="mobile" type="number"
                  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" class="form-control forminput">
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                  <div style="font-size: 10px;color: red" *ngIf="f.mobile.errors.required">
                    {{'FOOTER.CONTACT_US.VALID_MOBILE' | translate}}
                  </div>
                  <!--                  <div style="font-size: 10px;color: red" *ngIf="f.mobile.errors.pattern">-->
                  <!--                    {{'FOOTER.CONTACT_US.VALID_MOBILE' | translate}}-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="form-group col-lg-12 col-md-12">
                <label>{{'FOOTER.CONTACT_US.ADDRESS' | translate}}
                  <span style="color: red;margin-left: .5vw">*</span></label>
                <input [(ngModel)]="address" name="address" formControlName="address" type="text"
                  class="form-control forminput" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                  <div style="font-size: 10px;color: red" *ngIf="f.address.errors.required">
                    {{'FOOTER.CONTACT_US.ADDRESS_REQUIRED' | translate}}
                  </div>
                  <div style="font-size: 10px;color: red" *ngIf="f.address.errors.pattern">
                    {{'FOOTER.CONTACT_US.VALID_ADDRESS_REQUIRED' | translate}}
                  </div>
                </div>
                <!--                <div style="font-size: 10px;color: red" *ngIf="wrongAddress">-->
                <!--                  {{'FOOTER.CONTACT_US.VALID_ADDRESS_REQUIRED' | translate}}-->
                <!--                </div>-->
              </div>
              <div class="form-group col-lg-12 col-md-12">
                <label>{{'FOOTER.CONTACT_US.EMAIL' | translate}}</label>
                <input [(ngModel)]="emailAddress" formControlName="emailAddress" name="email" type="text"
                  class="form-control forminput">
                <!--                <div *ngIf="submitted && f.emailAddress.errors" class="invalid-feedback">-->
                <!--                  <div style="font-size: 10px;color: red" *ngIf="f.emailAddress.errors.required">-->
                <!--                    {{'FOOTER.CONTACT_US.EMAIL_REQUIRED' | translate}}-->
                <!--                  </div>-->
                <!--                  <div style="font-size: 10px;color: red" *ngIf="f.emailAddress.errors.pattern">-->
                <!--                    {{'FOOTER.CONTACT_US.VALID_EMAIL_REQUIRED' | translate}}-->
                <!--                  </div>-->
                <!--                </div>-->

              </div>

              <div class="form-group col-lg-12 col-md-12">
                <label>{{'FOOTER.CONTACT_US.YOUR_MESSAGE' | translate}}
                  <span style="color: red;margin-left: .5vw">*</span></label>
                <textarea [(ngModel)]="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                  formControlName="message" name="message" type="text" class="form-control forminput"
                  rows="5"></textarea>
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                  <div style="font-size: 10px;color: red" *ngIf="f.message.errors.required">
                    {{'FOOTER.CONTACT_US.MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
                <div style="font-size: 10px;color: red" *ngIf="wrongAMessage">
                  {{'FOOTER.CONTACT_US.VALID_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
              <div class="form-group form-btn col-lg-12 col-md-12">
                <button class="btn default-b" type="submit">{{'FOOTER.CONTACT_US.SUBMIT' | translate}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAtIVQC4XgKYRRSInhTUA-7jDus_GyH1t4&callback=initMap" async
  defer></script>
<app-footer></app-footer>