<app-header></app-header>

<div class="container">
  <div class="unavailable-alert-page not-found">
    <div class="d-flex align-items-center justify-content-center">
      <div class="message text-center">
        <div class="emoji">
          <img src="../../../../assets/images/icons/not-found.png" alt="">
        </div>
        <div class="info-message">
          <div *ngIf="activeLang === 'en'">
            <h3>Sorry!</h3>
            <p>This value-added service is <strong>not available</strong> to you.</p>
            <div class="back-btn">
              <button class="btn btn-success d-green-btn" [routerLink]="'/home/wholesale'">Go to Home Page</button>
            </div>
          </div>
          <div *ngIf="activeLang === 'si'">
            <h3>සමාවන්න!</h3>
            <p>මෙම අගය එකතු කළ සේවාව ඔබට <strong>ලබා ගත නොහැක.</strong></p>
            <button class="btn btn-success d-green-btn" [routerLink]="'/home/wholesale'">මුල් පිටුවට යන්න</button>
          </div>

          <div *ngIf="activeLang === 'ta'">
            <h3>மன்னிக்கவும்!</h3>
            <p>இந்த மதிப்பு கூட்டப்பட்ட சேவை உங்களுக்கு கிடைக்காது.</p>
            <button class="btn btn-success d-green-btn" [routerLink]="'/home/wholesale'">முகப்பு பக்கத்திற்கு செல்லவும்</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
