<div *ngIf="selectAuctionNext==0" class="sellproduct-steps">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.SELECT_PRO_CATEGORY' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form (ngSubmit)="submitCategoryForm()" [formGroup]="categoryForm">
      <div class="form-group col-12">
        <label for="selectCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.MAIN_CATEGORY' | translate}}
          <span class="validationStar">*</span></label>
        <select (change)="selectCategory($event)"
                [(ngModel)]="selectCategoryId" [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.category.errors }"
                class="form-control"
                formControlName="category" id="selectCategory">
          <option [value]="none" disabled>
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.SELECT_YOUR_CATEGORY' | translate}}</option>
          <option *ngFor="let category of categoryList" [value]="category.code">
            <span *ngIf="activeLang === 'en'">{{category.commonIndex.description}}</span>
            <span *ngIf="activeLang === 'si'">{{category.commonIndex.sinhala}}</span>
            <span *ngIf="activeLang === 'ta'">{{category.commonIndex.tamil}}</span>
          </option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.category.errors" class="inputError">
          <div *ngIf="f1.category.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="selectSubCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.SUB_CATEGORY' | translate}}
          <span class="validationStar">*</span></label>
        <select (change)="selectSubCategory($event)"
                [(ngModel)]="selectSubCategoryId"
                [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.subCategory.errors }" class="form-control"
                formControlName="subCategory" id="selectSubCategory">
          <option [value]="none" disabled>
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.SELECT_SUB_CATEGORY' | translate}}</option>
          <option *ngFor="let subCategory of subCategoryList" [value]="subCategory.code">
            <span *ngIf="activeLang === 'en'">{{subCategory.commonIndex.description}}</span>
            <span *ngIf="activeLang === 'si'">{{subCategory.commonIndex.sinhala}}</span>
            <span *ngIf="activeLang === 'ta'">{{subCategory.commonIndex.tamil}}</span>
          </option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.subCategory.errors" class="inputError">
          <div *ngIf="f1.subCategory.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.SUB_CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.AUCTION_PERIOD' | translate}}
            <span class="validationStar">*</span>
            <span class="validationStar"
                  style="margin-left: 0 !important;">({{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.PERIOD_DESC' | translate}}
              )</span>
          </label>
          <span style="padding: 8px 0;">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.TO' | translate}}</span>
          <input [(ngModel)]="auctionPeriodTo" [min]="todayDate"
                 [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.auctionPeriodTo.errors }"
                 class="form-control smallinput dateinput" formControlName="auctionPeriodTo"
                 id="auctionPeriodTo" style="width:85%" type="date">

          <div *ngIf="submittedCategoryForm && f1.auctionPeriodTo.errors" class="inputError">
            <div *ngIf="f1.auctionPeriodTo.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.AUCTION_DATE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="minimumBidValue">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.MINIMUM_BID_VALUE' | translate}}<span
          class="validationStar">*</span>
          <span class="validationStar">({{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.BID_VALUE_DESC' | translate}})</span>
        </label>
        <span>{{'CURRENCY.CURRENCY' | translate}}</span>
        <input [(ngModel)]="minimumBidValue"
               [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.minimumBidValue.errors }"
               class="form-control smallinput"
               formControlName="minimumBidValue" id="minimumBidValue" min="0" type="number" value="0">
        <div *ngIf="submittedCategoryForm && f1.minimumBidValue.errors" class="inputError">
          <div *ngIf="f1.minimumBidValue.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.MINIMUM_BID_VALUE' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half"
           data-dismiss="modal">{{'MY_ACCOUNT.SELL_A_PRODUCT.CANCEL' | translate}}</a>
        <a (click)="nextListProductDetailsPage()" aria-label="Next" class="btn next-btn-half"
           type="submit">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>
    </form>
  </div>


</div>

<div *ngIf="selectAuctionNext==1" class="sellproduct-steps">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.LIST_PRODUCT_DETAILS' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form (ngSubmit)="productDetailsFormSubmit()" [formGroup]="productDetailsForm">
      <div class="form-group col-12">
        <label for="title">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.TITLE' | translate}}
          <span class="validationStar">*</span></label>
        <input [(ngModel)]="title"
               [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.title.errors }" class="form-control"
               formControlName="title" id="title"
               placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.TITLE_NAME' | translate}}"
               type="text">
        <div *ngIf="submittedProductDetailsForm && f2.title.errors" class="inputError">
          <div *ngIf="f2.title.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.TITLE_REQUIRED' | translate}}
          </div>
          <div *ngIf="f2.title.errors.pattern">
            {{'INVALID_NAME' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="description">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.DESCRIPTION' | translate}}
          <span class="validationStar">*</span></label>
        <textarea [(ngModel)]="description"
                  [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.buyDate.errors }" class="form-control"
                  formControlName="description" id="description"
                  rows="3"></textarea>
        <div *ngIf="submittedProductDetailsForm && f2.description.errors" class="inputError">
          <div *ngIf="f2.description.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.DESCRIPTION_REQUIRED' | translate}}
          </div>
          <div *ngIf="f2.description.errors.pattern">
            Cannot start with space.
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="buyDate">{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.BEST_BUY_DATE' | translate}}
            <span class="validationStar">*</span></label>
          <input [(ngModel)]="buyDate" [min]="auctionPeriodTo"
                 [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.buyDate.errors }" class="form-control"
                 formControlName="buyDate"
                 id="buyDate" type="date">
          <div *ngIf="submittedProductDetailsForm && f2.buyDate.errors" class="inputError">
            <div *ngIf="f2.buyDate.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.BEST_BUY_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <div class="flex-row col-12">
            <div class="col-6">
              <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.AVAILABLE_QUANTITY' | translate}}
                <span class="validationStar">*</span></label>
              <input (focusout)="manageAvailableTotalQty(availableTotalQty)" [(ngModel)]="availableTotalQty"
                     [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.availableTotalQty.errors }"
                     class="form-control smallinput" formControlName="availableTotalQty" id="qty" min="0"
                     placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.AVAILABLE_QUANTITY' | translate}}"
                     style="width: 165px;" type="number" value="0">

              <div *ngIf="submittedProductDetailsForm && f2.availableTotalQty.errors" class="inputError">
                <div *ngIf="f2.availableTotalQty.errors.required">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.VALIDATE.AVAILABLE_TOTAL_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.UNIT_TYPE' | translate}}
                <span class="validationStar">*</span></label>
              <select (change)="selectUnitType($event)" [(ngModel)]="unitType"
                      [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.unitType.errors }"
                      class="form-control smallinput" formControlName="unitType" id="unitType" style="width: 170px;">
                <option [value]="none" disabled>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.UNIT_TYPE' | translate}}
                </option>
                <option *ngFor="let unitType of unitTypeList" [value]="unitType.id">{{unitType.unit}}</option>
              </select>

              <div *ngIf="submittedProductDetailsForm && f2.unitType.errors" class="inputError">
                <div *ngIf="f2.unitType.errors.required">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.UNIT_TYPE_REQUIRED' | translate}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="form-group btn-sections">
        <a (click)="selectAuctionNext=0" aria-label="Close"
           class="btn cancel-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a (click)="productDetailsPageNext()" aria-label="Next" class="btn next-btn-half"
           type="submit">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<div *ngIf="selectAuctionNext==2" class="sellproduct-steps"
     style="max-height: 500px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
  <h4 style="margin: 0 0 15px 0;">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_PIK_ADDRESS' | translate}}</h4>

  <div class="col-12">
    <p>This address will be used as the pickup location for this product by the delivery person. </p>
  </div>
  <div class="anp-select-op categoryform row">
    <form [formGroup]="pickUpAddressForm">

      <div class="transport-section">
        <div class="saved-address">
          <div class="d-flex row" style="max-height: 650px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
            <div class="col-6" *ngFor="let item of addressList; let i = index">
              <div class="section-transport">
                <input class="form-check-input" (click)="setAddress(i)" type="radio"
                       [id]="'address'+i" [name]="'address'" [value]="item.id"
                       [checked]="item.status && !isAgentFarmerCheck">
                <label class="form-check-label" [for]="'address'+i">
                  <h4>{{item.name}} <span *ngIf="item.status === true">(Default)</span></h4>
                  <p>{{item.addressOne}} {{item.addressTwo}} ,{{item.cities.nameEN}}</p>
                </label>
              </div>
            </div>
            <div class="section-transport" *ngIf="isAgentFarmerLogin">
              <input class="form-check-input" (click)="setAddress('FM')" type="radio"
                     [id]="'FM'" [name]="'FM'" [value]="'FM'" [checked]="isAgentFarmerCheck">
              <label class="form-check-label" [for]="'FM'">
                <h4>{{agentFarmerName}} <span>(Farmer)</span></h4>
                <p>{{agentFarmerAddress1}} {{agentFarmerAddress2}},{{agentFarmerCityName}} </p>
              </label>
            </div>

          </div>
        </div>
        <div class="addnewaddress d-flex justify-content-between align-items-center">
          <div>
            <input class="form-check-input" type="radio" name="address" id="newaddesss" value="option3"
                   (click)="setAddress('new')">
            <label class="form-check-label" for="newaddesss">
              <h4>Add New Address</h4>
            </label>
          </div>
          <div class="form-check" [hidden]="!isItNewAddress">
            <input [checked]="isAddressSave" class="form-check-input checkone" (change)="manageAddressSave()"
                   id="saveAddress" type="checkbox">
            <label class="form-check-label" for="saveAddress">
              Save this address
            </label>
          </div>
        </div>
      </div>

      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="address1">
            Address line 1
            <span class="validationStar">*</span></label>
          <input [(ngModel)]="address1"
                 [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.address1.errors }" class="form-control"
                 formControlName="address1" id="address1"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}"
                 type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.address1.errors" class="inputError">
            <div *ngIf="f3.address1.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="address2">
            Address line 2</label>
          <input [(ngModel)]="address2" [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.address2.errors }"
                 class="form-control" formControlName="address2" id="address2"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}" type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.address2.errors" class="inputError">
            <div *ngIf="f3.address2.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="pro">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PROVINCE' | translate}}
            <span class="validationStar">*</span></label>
          <select (change)="selectProvince($event)" [(ngModel)]="selectProvinceId" class="form-control province"
                  formControlName="province" id="pro">
            <option [value]="none" disabled>
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PROVINCE' | translate}}
            </option>
            <option *ngFor="let province of provinceList" [value]="province.id">
              <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.province.errors" class="inputError">
            <div *ngIf="f3.province.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.PROVINCE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="dis">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
          <select (change)="selectDistrict($event)" [(ngModel)]="selectDistrictId" class="form-control province"
                  formControlName="district" id="dis">
            <option [value]="none" disabled>
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_DISTRICT' | translate}}
            </option>
            <option *ngFor="let district of districtList" [value]="district.id">
              <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.district.errors" class="inputError">
            <div *ngIf="f3.district.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">

        <div class="form-group col-6">
          <label for="dis">
            City
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
          <select (change)="selectCity($event)" [(ngModel)]="selectCityId" class="form-control province"
                  formControlName="city" id="city">
            <option [value]="none" disabled>
              Select City
            </option>
            <option *ngFor="let city of allCities" [value]="city.id" (click)="selectCityEn(city.nameEN)">
              <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.city.errors" class="inputError">
            <div *ngIf="f3.city.errors.required">
              City is Required
            </div>
          </div>
        </div>


        <div class="form-group col-6">
          <label for="postalCode">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}
          </label>
          <input [(ngModel)]="postalCode" disabled
                 [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.postalCode.errors }"
                 class="form-control" formControlName="postalCode" id="postalCode"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}" type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.postalCode.errors" class="inputError">
            <div *ngIf="f3.postalCode.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
            </div>
          </div>
        </div>

      </div>

      <div class="form-group btn-sections">
        <a (click)="selectAuctionNext=1" aria-label="Close"
           class="btn cancel-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a (click)="pickUpLocationAddressNextPage()" aria-label="Next"
           class="btn next-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<div class="sellproduct-steps" *ngIf="selectAuctionNext==3"
     style="max-height: 500px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
  <h4 class="margin0">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_OPTION' | translate}}</h4>
  <p class="subtext">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.TRANSPORT_STATUS' | translate}}
  </p>
  <div class="anp-select-op categoryform row">
    <div class="messgnetext">
      <form [formGroup]="deliveryForm">
        <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==1}">
          <div class="form-check">
            <mat-checkbox (change)="checkVal($event,3)" [checked]="isSellerDeliver"></mat-checkbox>
            <label class="form-check-label" style="color: black;">
              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGE' | translate}}
              </h4>
              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' | translate}}</p>

              <div class="row">
                <label
                  style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_PER' | translate}}
                  ({{'CURRENCY.CURRENCY' | translate}})</label>
                <input class="form-control" style="margin-left: 6%" formControlName="deliverCharge" type="number">
                <div *ngIf="isChargeReq" style="font-size: 10px;color: red;margin-left: 4vw;margin-top: 1vh;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' | translate}}
                </div>
              </div>
            </label>
          </div>
        </div>
      </form>
      <form [formGroup]="deliveryFormFixed">
        <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==5}">
          <div class="form-check">
            <mat-checkbox (change)="checkVal($event,5)" [checked]="isSellerDeliverFixed"></mat-checkbox>
            <label class="form-check-label" style="color: black;">
              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_FINED_RATE' | translate}}</h4>
              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' | translate}}</p>
              <br/>
              <div class="row" style="margin-left: 6%">
                <mat-radio-button [checked]="areaDisable" value="1" (change)="areaDisable=true" class="tos-radio">
                  <p style="margin-left: 0">
                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.ISLAND_DELIVERY' | translate}}</p>
                </mat-radio-button>
                <mat-radio-button value="1" [checked]="!areaDisable" (change)="areaDisable=false" class="tos-radio">
                  <p style="margin-left: 0">
                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_DELIVERY' | translate}}</p>
                </mat-radio-button>
              </div>
              <div [hidden]="areaDisable" class="row">
                <label style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA' | translate}}
                </label>
                <textarea class="form-control" maxlength="100" style="margin-left: 6%"
                          formControlName="areas"></textarea>
                <div *ngIf="isAreaReq" style="font-size: 10px;color: red">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA_REQUIRED' | translate}}
                </div>
              </div>
              <div class="row">
                <label style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGED' | translate}}
                  ({{'CURRENCY.CURRENCY' | translate}}) </label>
                <input class="form-control" style="margin-left: 6%" formControlName="deliverChargeFixed" type="number">
                <div *ngIf="isFixedChargeReq" style="font-size: 10px;color: red;margin-left: 4vw;margin-top: 1vh;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' | translate}}
                </div>
              </div>
            </label>
          </div>
        </div>
      </form>
      <div class="optionbox section-transport">
        <div class="form-check">
          <mat-checkbox (change)="checkVal($event,4)" disabled="" [checked]="train"></mat-checkbox>
          <label class="form-check-label">
            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.USE_RAIL_TRANSPORT' | translate}}</h4>
            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY_BUYER' | translate}}</p>
            <div class="selects">
              <div class="form-group">
                <label for="railway">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.NEAREST_STATION' | translate}}
                </label>
                <select disabled (change)="selectRailway($event.target.value)" class="form-control province"
                        id="railway">
                  <option [value]="none" disabled>
                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SELECT_STATION' | translate}}
                  </option>
                  <option *ngFor="let station of railwayList" [value]="station.id">{{station.descriptionEng}}
                  </option>

                </select>
                <div class="inputError" *ngIf="railwayReq"
                     style="font-size: 10px;color: red;margin-left: 4vw;margin-top: 1vh;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.STATION_REQUIRED' | translate}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==3}">
        <div class="form-check">
          <mat-checkbox (change)="checkVal($event,2)" [checked]="buyerDeliver"></mat-checkbox>
          <label class="form-check-label">
            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_TRANSPORT' | translate}}</h4>
            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_RESPONSIBILITY' | translate}}</p>
          </label>
        </div>
      </div>
    </div>
    <div class="inputError" *ngIf="deliveryReq" style="margin-left: 1.5vw;">
      {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_TYPE_REQUIRED' | translate}}</div>
    <div class="form-group btn-sections">
      <a class="btn cancel-btn-half" (click)="selectAuctionNext=2"
         aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
      <a class="btn next-btn-half" (click)="next()"
         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
    </div>
  </div>
</div>

<div *ngIf="selectAuctionNext==4" class="sellproduct-steps">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.PRODUCT_PHOTOS' | translate}}
    <span class="validationStar">*</span></h4>
  <p style="color: #6B6C6F; padding: 0 15px; margin: 0;">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.UPLOAD_HERE' | translate}}</p>
  <p style="
    color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE' | translate}}5MB
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE_ALLOWED' | translate}}</p>
  <p style=" color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
    {{'ALLOWED_FILE_TYPES' | translate}}</p>
  <div class="anp-select-op categoryform row">
    <div class="img-upload">
      <div class="uploader">
        <ngx-dropzone (change)="onSelect($event)" accept="image/png,image/jpeg,image/jpg" [multiple]="false">
          <ngx-dropzone-label>
            <div class="uploaderbox">
              <em class="icon fa fa-camera"></em>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview (removed)="onRemove(f)" *ngFor="let f of files" [file]="f"
                                      [removable]="true" ngProjectAs="ngx-dropzone-preview">
          </ngx-dropzone-image-preview>
          <div class="imgadd"><em class="fa fa-plus"></em></div>
        </ngx-dropzone>
      </div>
      <p>
        {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.PRODUCT_IDENTIFICATION' | translate}}</p>
      <ol>
        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.PRODUCT_IDENTIFICATION_1' | translate}}</li>

        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.PRODUCT_IDENTIFICATION_2' | translate}}</li>

        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.PRODUCT_IDENTIFICATION_3' | translate}}</li>
      </ol>
    </div>

    <div class="form-group btn-sections">
      <a (click)="selectAuctionNext=3" aria-label="Close"
         class="btn cancel-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
      <a (click)="saveProduct()" aria-label="Next"
         class="btn next-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.SUBMIT' | translate}}</a>
    </div>
  </div>
</div>

<div *ngIf="selectAuctionNext==5" class="sellproduct-steps">

  <div class="anp-select-op categoryform row">
    <div class="successmsg">
      <h3>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_SUCCESS_MSG' | translate}}</h3><br><br>
      <a aria-label="Next" class="btn next-btn-close"
         data-dismiss="modal">{{'MY_ACCOUNT.SELL_A_PRODUCT.CLOSE' | translate}}</a>
    </div>
  </div>
</div>
