<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="sing-wrapper">
          <div class="sw-left-col"></div>
          <div class="sw-rignt-col mx-scroll">
            <div class="sw-right-inner-scroll">
              <div *ngIf="selectSignUpOptions==0" class="select-sign-up-options">
                <h3>{{'REGISTER.REGISTER' | translate}}</h3>
                <p>{{'REGISTER.SELECT_ROLE' | translate}}</p>
                <div class="suo-links-wp">
                                    <span (click)="selectSignUpOptions=1" class="suo-links"><span
                                      class="tooltip-bottom flexlist"
                                      matTooltip="{{'REGISTER.TOOLTIP.FARMER' | translate}}">
                                            {{'REGISTER.FARMER.FARMER' | translate}}<i aria-hidden="true"
                                                                                       class="fa fa-long-arrow-right"></i>
                                        </span>
                                    </span>
                  <span (click)="selectSignUpOptions=2" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'REGISTER.TOOLTIP.AGRRO_PRODUCE_AGGREAGRE' | translate}}">
                                            {{'REGISTER.AGGRO_PRODUCE.AGGRO_PRODUCE' | translate}}
                                          <i aria-hidden="true"
                                             class="fa fa-long-arrow-right"></i></span></span>
                  <span (click)="selectSignUpOptions=3" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'REGISTER.TOOLTIP.WHOLE_SALE_BUYER' | translate}}">
                                            {{'REGISTER.WHOLESALE_BUYER.WHOLESALE_BUYER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>
                  <span (click)="selectSignUpOptions=4" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'REGISTER.TOOLTIP.RETAILER' | translate}}">
                                            {{'REGISTER.RETAILER.RETAILER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>
                  <span (click)="selectSignUpOptions=5" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'REGISTER.TOOLTIP.CONSUMER' | translate}}">
                                            {{'REGISTER.LARGE_SCALE_CONSUMER.LARGE_SCALE_CONSUMER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>
                  <span (click)="selectSignUpOptions=6" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'REGISTER.TOOLTIP.EXPORTER' | translate}}">
                                            {{'REGISTER.EXPORTER.EXPORTER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=8" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'REGISTER.TOOLTIP.SERVICE_PROVIDER' | translate}}">
                                            {{'REGISTER.SERVICE_PROVIDER.SERVICE_PROVIDER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=7" class="suo-links">
                                            <span class="tooltip-bottom flexlist"
                                                  matTooltip="{{'REGISTER.TOOLTIP.HELAVIRU_LEAD' | translate}}">
                                                {{'REGISTER.HELAVIRU_AGENT.HELAVIRU_AGENT' | translate}} <i
                                              aria-hidden="true"
                                              class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=18" class="suo-links">
                                            <span class="tooltip-bottom flexlist"
                                                  matTooltip="{{'REGISTER.TOOLTIP.ECONOMIC_CENTER_SHOP' | translate}}">
                                                {{'ECONOMIC_CENTER_SHOP' | translate}} <i
                                              aria-hidden="true"
                                              class="fa fa-long-arrow-right"></i></span></span>
                </div>
              </div>

              <div *ngIf="selectSignUpOptions==10" class="select-sign-up-options">

                <h3>{{'REGISTER.REGISTER' | translate}}</h3>
                <p>{{'COMPANY_REGISTRATION_FORM_HEADER.H1' | translate}}</p>
                <div class="suo-links-wp">
                   <span (click)="selectSignUpOptions=11" class="suo-links">
                                            <span class="tooltip-bottom flexlist"
                                                  matTooltip="{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.FARMING_COMPANY' | translate}}">
                                                {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.FARMING_COMPANY' | translate}}
                                              <i
                                                aria-hidden="true"
                                                class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=12" class="suo-links">
                                            <span class="tooltip-bottom flexlist"
                                                  matTooltip="{{'COMPANY_REGISTRATION_FORM.AGGRO_PRODUCE' | translate}}">
                                            {{'COMPANY_REGISTRATION_FORM.AGGRO_PRODUCE' | translate}}<i
                                              aria-hidden="true"
                                              class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=13" class="suo-links">
                                            <span class="tooltip-bottom flexlist"
                                                  matTooltip="{{'COMPANY_REGISTRATION_FORM.WHOLESALE_BUYER' | translate}}">
                                            {{'COMPANY_REGISTRATION_FORM.WHOLESALE_BUYER' | translate}} <i
                                              aria-hidden="true"
                                              class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=14" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'COMPANY_REGISTRATION_FORM.RETAILER' | translate}}">
                                            {{'COMPANY_REGISTRATION_FORM.RETAILER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=15" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'COMPANY_REGISTRATION_FORM.LARGE_SCALE_CONSUMER' | translate}}">
                                            {{'COMPANY_REGISTRATION_FORM.LARGE_SCALE_CONSUMER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=16" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'COMPANY_REGISTRATION_FORM.EXPORTER' | translate}}">
                                            {{'COMPANY_REGISTRATION_FORM.EXPORTER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>

                  <span (click)="selectSignUpOptions=17" class="suo-links">
                                        <span class="tooltip-bottom flexlist"
                                              matTooltip="{{'COMPANY_REGISTRATION_FORM.SERVICE_PROVIDER' | translate}}">
                                            {{'COMPANY_REGISTRATION_FORM.SERVICE_PROVIDER' | translate}} <i
                                          aria-hidden="true"
                                          class="fa fa-long-arrow-right"></i></span></span>
                </div>
              </div>

              <!-- FARMER SIGN UP -->
              <div *ngIf="selectSignUpOptions==1" class="suo-item">
                                <span (click)="selectSignUpOptions=0" *ngIf="!isAgentFarmer" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i>
                                  {{'REGISTER.BACK' | translate}}
                                </span>
                <span style="outline: unset" *ngIf="isAgentFarmer" [routerLink]="'/my-account/'+tenantValue"
                      [queryParams]="{'isClickedSellButton':'true'}"
                      class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i>
                  {{'REGISTER.BACK' | translate}}
                                </span>
                <app-sign-up-farmer></app-sign-up-farmer>
              </div>

              <!-- AGENT SIGN UP -->
              <div *ngIf="selectSignUpOptions==2" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i>
                                  {{'REGISTER.BACK' | translate}}
                                </span>
                <app-sign-up-collector></app-sign-up-collector>

              </div>

              <!-- WHOLESALE BUYER SIGN UP -->
              <div *ngIf="selectSignUpOptions==3" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-sign-up-whole-sale-buyer></app-sign-up-whole-sale-buyer>
              </div>

              <!-- retailer -->
              <div *ngIf="selectSignUpOptions==4" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-retailer></app-retailer>
              </div>


              <!-- LARGE SCALE CONSUMER SIGN UP -->
              <div *ngIf="selectSignUpOptions==5" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-sign-up-consumer></app-sign-up-consumer>
              </div>

              <!-- EXPORTER SIGN UP -->
              <div *ngIf="selectSignUpOptions==6" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> Back
                                </span>
                <app-sign-up-exporter></app-sign-up-exporter>
              </div>

              <!-- HELAVIRU AGENT -->
              <div *ngIf="selectSignUpOptions==7" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-sign-up-agent></app-sign-up-agent>
              </div>

              <!-- SERVICE PROVIDER -->
              <div *ngIf="selectSignUpOptions==8" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-service-provider></app-service-provider>
              </div>

              <!-- ECONOMIC CENTER SHOP OWNER -->
              <div *ngIf="selectSignUpOptions==18" class="suo-item">
                                <span (click)="selectSignUpOptions=0" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-economic-center-shop-owner></app-economic-center-shop-owner>
              </div>

              <div *ngIf="selectSignUpOptions==11" class="suo-item">
                   <span (click)="selectSignUpOptions=10" class="su-back-btn">
                     <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                    </span>

                <app-farming-company></app-farming-company>
              </div>

              <div *ngIf="selectSignUpOptions==12" class="suo-item">
                                <span (click)="selectSignUpOptions=10" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-aggregator-company></app-aggregator-company>
              </div>

              <div *ngIf="selectSignUpOptions==13" class="suo-item">
                                <span (click)="selectSignUpOptions=10" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-wholesale-buyer-company></app-wholesale-buyer-company>
              </div>

              <div *ngIf="selectSignUpOptions==14" class="suo-item">
                                <span (click)="selectSignUpOptions=10" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-retailer-company></app-retailer-company>
              </div>

              <div *ngIf="selectSignUpOptions==15" class="suo-item">
                                <span (click)="selectSignUpOptions=10" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-consumer-company></app-consumer-company>
              </div>

              <div *ngIf="selectSignUpOptions==16" class="suo-item">
                                <span (click)="selectSignUpOptions=10" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-expoter-company></app-expoter-company>
              </div>

              <div *ngIf="selectSignUpOptions==17" class="suo-item">
                                <span (click)="selectSignUpOptions=10" class="su-back-btn">
                                    <i aria-hidden="true" class="fa fa-angle-left"></i> {{'REGISTER.BACK' | translate}}
                                </span>
                <app-service-provider-company></app-service-provider-company>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<app-footer></app-footer>
