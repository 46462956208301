<div class="w-full-col sliderbasic">

  <div class="slck-product-slider">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of sliderLine2 ; let i = index" class="slide ps-item">
        <a [routerLink]="['/product-view-'+setType(slide.type.type)+'/'+tenantValue]"
           [queryParams]="{id:slide.id,typeId:slide.type.id,typeName:slide.type.type}">
          <div class="ps-inner-wrapper">
            <div class="img-section-item">
              <div class="badge-item" *ngIf="slide.leadLabel !== null">
                <img src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ slide.leadLabel+ '/?X-TenantID=' + this.tenantValue}}" alt="">
              </div>
              <img [ngStyle]="{'opacity': slide.stockAvailable === 0 ? '0.5' : '1' }"
                   *ngIf="slide.type.type === 'SPOT' && slide?.spotImages.length > 0" class="imageTag"
                   src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ slide.spotImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                   alt=""/>
              <img [ngStyle]="{'opacity': slide.stockAvailable === 0 ? '0.5' : '1' }"
                   *ngIf="slide.type.type === 'SPOT' && slide?.spotImages.length === 0" class="imageTag"
                   src="assets/defaultBrokenImage.jpg" alt=""/>

              <img [ngStyle]="{'opacity': slide.stockAvailable === 0 ? '0.5' : '1' }"
                   *ngIf="slide.type.type === 'FORWARD_BN' && slide?.futureImages.length > 0" class="imageTag"
                   src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ slide.futureImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                   alt=""/>

              <img [ngStyle]="{'opacity': slide.stockAvailable === 0 ? '0.5' : '1' }"
                   *ngIf="slide.type.type === 'FORWARD_BN' && slide?.futureImages.length === 0" class="imageTag"
                   src="assets/defaultBrokenImage.jpg" alt=""/>

              <img [ngStyle]="{'opacity': slide.stockAvailable === 0 ? '0.5' : '1' }"
                   *ngIf="slide.type.type === 'FORWARD_ACU' && slide?.forwardAcuImages.length > 0" class="imageTag"
                   src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ slide.forwardAcuImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                   alt=""/>

              <img [ngStyle]="{'opacity': slide.stockAvailable === 0 ? '0.5' : '1' }"
                   *ngIf="slide.type.type === 'FORWARD_ACU' && slide?.forwardAcuImages.length === 0" class="imageTag"
                   src="assets/defaultBrokenImage.jpg" alt=""/>
            </div>

            <!-- economy center badge -->
            <div class="eco-badge homebadge" *ngIf="slide.economicCenter === 'TABUTTEGAMA'">
              <h6 class="badgetxt">
                <img src="../../assets/images/icons/store.png" alt="">
                <span style="color: white;font-weight: bold" *ngIf="activeLang === 'en'">Thambuttegama Economic Center</span>
                <span style="color: white;font-weight: bold" *ngIf="activeLang === 'si'">තඹුත්තේගම ආර්ථික මධ්‍යස්ථානය</span>
                <span style="color: white;font-weight: bold" *ngIf="activeLang === 'ta'">தம்புத்தேகம பொருளாதார நிலையம்</span>
              </h6>
            </div>

            <div class="ps-item-inner">
              <div class="pro-d">
                <h5 class="productName">{{slide.name}}</h5>
                <div class="p-price">
                  <h5 class="greentxt">
                    <span *ngIf="slide.stockAvailable !== 0 && slide.type.type !== 'FORWARD_ACU'">
                      <h5 *ngIf="slide.sellInSmallerQuantities">
                        {{'CURRENCY.CURRENCY' | translate}}
                        {{slide.spotDTOList[0].unitPrice | number:'2.2-2'}}
                        /{{slide.unit.unit}}
                      </h5>

                      <h5 *ngIf="!slide.sellInSmallerQuantities">{{'CURRENCY.CURRENCY' | translate}}
                        {{slide.spotDTOList[0].unitPrice | number:'2.2-2'}}/{{slide.unit.unit}}
                      </h5>
                    </span>
                    <span *ngIf="slide.stockAvailable === 0" class="text" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0);
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                      <h5 style="color: red">{{'PRODUCT_VIEW.SOLD_OUT' | translate}}</h5>
                    </span>
                  </h5>
                </div>
              </div>


              <div class="bottom-rating">

                <button class="d-green-btn">
                  {{'view'|translate}}
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ngx-slick-carousel>
  </div>

  <div *ngIf="spinner2 !== 0">
    <div class="sk-cube-grid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>

</div>
