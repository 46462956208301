<app-header></app-header>

<div class="main-container">
  <div class="container pro-con">
    <div class="row">
      <div class="col-lg-12 filterproducts">
        <div *ngIf="spinner == 0" class="pl-wrapper">

          <div class="col-12 showonlymobile" (click)="clickFilter()">
            <a style="color: white;" type="button" class="displayfilter applyFilterBtn btn"><i class="fa fa-sort"></i>
              &nbsp;Filters</a>
          </div>
          <!-- sidebars -->
          <div class="col-12 hiddenxs filter-sections" style="padding-top: 15px;"
               [ngClass]="{'active': showFilter==true}">
            <div class="row displayfilters">
              <button type="button" class="btn btn-danger" (click)="restFilter()" *ngIf="$hideFilterResetBtn === '1'">
                {{'PRODUCT_LIST.RESET' | translate}}
              </button>
              <button type="button" class="btn btn-success" style="float: right;margin-left: 1vw"
                      (click)="searchFilter()">
                {{'PRODUCT_LIST.FILTER' | translate}}
              </button>
            </div>

            <div style="margin-bottom: 2vh"></div>

            <h4>{{'PRODUCT_LIST.CATEGORY' | translate}}</h4>
            <div class="filter-col">

              <div class="order-list" *ngFor="let mainCategory of mainCategories;let i = index">

                <div class="subcategory-list">
                  <h3>{{'PRODUCT_LIST.SUB_CATEGORY' | translate}}</h3>
                </div>

                <div id="accordion">
                  <div class="card-header" [id]="'headingOne'+i" style="background-color: unset">
                    <button class="btn btn-link" data-toggle="collapse" [id]="'collapseBtn'+i"
                            [attr.data-target]="'#' + 'collapseOne' + i" aria-expanded="true" aria-controls="collapseOne">
                      <span *ngIf="activeLang === 'en'">{{mainCategory.commonIndex.description}}</span>
                      <span *ngIf="activeLang === 'si'">{{mainCategory.commonIndex.sinhala}}</span>
                      <span *ngIf="activeLang === 'ta'">{{mainCategory.commonIndex.tamil}}</span>
                    </button>
                  </div>

                  <div [id]="'collapseOne'+i" class="collapse" aria-expanded="false" data-parent="#accordion"
                       aria-labelledby="headingOne" aria-controls="collapseExample">
                    <div class="s" *ngFor="let subCategory of mainCategory.newSubCategory ;let j = index">
                      <div class="form-check form-check-inline">
                        <div class="form-check">
                          <input class="form-check-input checkone" type="checkbox" [id]="'sub'+j + i"
                                 (change)="selectedCategoryList(subCategory.code,i,j,'collapseBtn'+i)">
                          <label class="form-check-label" [for]="'sub'+j + i">
                            <span *ngIf="activeLang === 'en'">{{subCategory.commonIndex.description}}</span>
                            <span *ngIf="activeLang === 'si'">{{subCategory.commonIndex.sinhala}}</span>
                            <span *ngIf="activeLang === 'ta'">{{subCategory.commonIndex.tamil}}</span>
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4>{{'PRODUCT_LIST.PRICE_RANGE' | translate}} ({{'CURRENCY.CURRENCY' | translate}})</h4>
            <div class="filter-col">
              <div class="row">
                <div class="input-group col-5">
                  <input min="10" type="number" [(ngModel)]="minValue" class="form-control" placeholder="Min">
                </div>
                <p>-</p>
                <div class="input-group col-5">
                  <input min="10" type="number" [(ngModel)]="maxValue" class="form-control" placeholder="Max">
                </div>
              </div>
            </div>

            <h4>{{'PRODUCT_LIST.RATING' | translate}}</h4>
            <div class="filter-col">
              <div class="form-check form-check-inline" (click)="clickStar(1,'star1')">
                <input class="form-check-input" type="radio" name="exampleRadios" id="star1" value="rangeOne">
                <label class="form-check-label cust-lable" for="star1">
                  <star-rating [value]="1" checkedcolor="#FFA84C" uncheckedcolor="#999999" size="26px"></star-rating>
                </label>
              </div>
              <br>
              <div class="form-check form-check-inline" (click)="clickStar(2,'star2')">
                <input class="form-check-input" type="radio" name="exampleRadios" id="star2" value="rangeTwo">
                <label class="form-check-label cust-lable" for="star2">
                  <star-rating [value]="2" checkedcolor="#FFA84C" uncheckedcolor="#999999" size="26px"></star-rating>
                </label>
              </div>
              <br>
              <div class="form-check form-check-inline" (click)="clickStar(3,'star3')">
                <input class="form-check-input" type="radio" name="exampleRadios" id="star3" value="rangeThree">
                <label class="form-check-label cust-lable" for="star3">
                  <star-rating [value]="3" checkedcolor="#FFA84C" uncheckedcolor="#999999" size="26px"></star-rating>
                </label>
              </div>
              <br>
              <div class="form-check form-check-inline" (click)="clickStar(4,'star4')">
                <input class="form-check-input" type="radio" name="exampleRadios" id="star4" value="rangeFour">
                <label class="form-check-label cust-lable" for="star4">
                  <star-rating [value]="4" checkedcolor="#FFA84C" uncheckedcolor="#999999" size="26px"></star-rating>
                </label>
              </div>
              <br>
              <div class="form-check form-check-inline" (click)="clickStar(5,'star5')">
                <input class="form-check-input" type="radio" name="exampleRadios" id="star5" value="Allrange">
                <label class="form-check-label cust-lable" for="star5">
                  <star-rating [value]="5" checkedcolor="#FFA84C" uncheckedcolor="#999999" size="26px"></star-rating>
                </label>
              </div>
            </div>

            <h4 *ngIf="isShowProductLocation">{{'PRODUCT_LIST.LOCATION' | translate}}</h4>
            <div class="filter-col" *ngIf="isShowProductLocation">

              <div class="first-section">
                <div class="order-list" *ngFor="let province of allProvinceList;let i = index">
                  <div class="s provices">
                    <div class="form-check form-check-inline">
                      <div class="form-check">
                        <input (change)="selectProvinceList($event,i)" class="form-check-input checkone" type="checkbox"
                               [value]="province.id" [id]="'province'+i">
                        <label class="form-check-label for-location" [for]="'province'+i">
                          <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                          <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                          <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>

            <h4 *ngIf="!isShowProductLocation">
              <span *ngIf="activeLang === 'en'">Shops</span>
              <span *ngIf="activeLang === 'si'">සාප්පු</span>
              <span *ngIf="activeLang === 'ta'">கடைகள்</span>
            </h4>
            <div *ngIf="!isShowProductLocation" class="filter-col">

              <div class="first-section">
                <div class="order-list">
                  <div class="s">
                    <div class="form-check form-check-inline">
                      <div class="form-check">
                        <input (change)="selectEcoShopList($event,0.123)" class="form-check-input checkone"
                               type="checkbox" [value]="'TABUTTEGAMA'" [id]="'TABUTTEGAMA'" [checked]="false">
                        <label class="cust-lable form-check-label for-location" [for]="'TABUTTEGAMA'">
                          <span *ngIf="activeLang === 'en'">All Shops</span>
                          <span *ngIf="activeLang === 'si'">සියලුම සාප්පු</span>
                          <span *ngIf="activeLang === 'ta'">அனைத்து கடைகள்</span>
                        </label>

                      </div>

                    </div>
                  </div>
                  <span class="cust-lable form-check-label for-location"
                        style="float: right;margin-right: 12px">{{ecoShopListCount}}</span>
                </div>
                <div class="order-list" *ngFor="let ecoShop of ecoShopList;let i = index">
                  <div class="s">
                    <div class="form-check form-check-inline">
                      <div class="form-check">
                        <input (change)="selectEcoShopList($event,i)" class="form-check-input checkone" type="checkbox"
                               [value]="ecoShop.shopCode" [id]="'ecoShop'+i" [checked]="false">
                        <label class="cust-lable form-check-label for-location" [for]="'ecoShop'+i">
                          <span>{{ecoShop.shopName}}</span>
                        </label>

                      </div>

                    </div>
                  </div>
                  <span class="cust-lable form-check-label for-location"
                        style="float: right;margin-right: 12px">{{ecoShop.numberOfProducts}}</span>
                </div>
              </div>

            </div>

            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.BEST_BUY_DATE' | translate}}</h4>
            <div class="filter-col">
              <mat-form-field appearance="fill">
                <mat-label>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.CHOOSE_A_DATE' | translate}}</mat-label>
                <input min="{{todayDate}}" matInput [matDatepicker]="picker" [(ngModel)]="bestBuyDateRange">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>


            </div>
          </div>

          <!-- /sidebars -->

          <!-- Product filters -->
          <div class="pl-content" [hidden]="isOrderShow">
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/products/'+tenantValue]">
                  {{'PRODUCT_LIST.ALL_CATEGORIES' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{'PRODUCT_LIST.NEW_ARRIVALS' | translate}}</li>
              </ol>
              <span *ngIf="marketList === 0">({{allProductCount}} {{'PRODUCT_LIST.RESULTS' | translate}})</span>
              <span *ngIf="marketList === 1">({{allOrderRequestProductsCount}} {{'PRODUCT_LIST.RESULTS' | translate}}
                )</span>
            </nav>

            <div class="product-list-top" id="check-product-list">
              <div class="plt-left">
                <div class="categories">
                  <div class="bottom-section">
                    <div class="f-select">
                      <div class="dropdown filterdrop drop1st">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                style="margin-right: .5vw" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">{{'PRODUCT_LIST.REQUESTS.REQUESTS' | translate}}
                        </button>
                        <div class="language_section dropdown-menu  dropdown-menu-right">
                          <a class="dropdown-item " (click)="manageMarketList(0)">{{'PRODUCT_LIST.REQUESTS.SHOP' |
                            translate}}</a>
                          <a class="dropdown-item" (click)="manageMarketList(1)">
                            {{'PRODUCT_LIST.REQUESTS.ORDER_REQUEST' | translate}}</a>
                          <a class="dropdown-item dropdown-toggle" data-toggle="dropdown">
                            {{'PRODUCT_LIST.REQUESTS.ECONOMIC_CENTER' | translate}}</a>

                          <div class="dropdown-menu" style="margin-left: 184px;margin-top: -105px;">
                            <a class="dropdown-item" (click)="manageMarketList(2)">
                              <span *ngIf="activeLang === 'en'">Thambuttegama</span>
                              <span *ngIf="activeLang === 'si'">තඹුත්තේගම</span>
                              <span *ngIf="activeLang === 'ta'">தம்புத்தேகம</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Dambulla</span>
                              <span *ngIf="activeLang === 'si'">දඹුල්ල</span>
                              <span *ngIf="activeLang === 'ta'">தம்புள்ளை</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Meegoda</span>
                              <span *ngIf="activeLang === 'si'">මීගොඩ</span>
                              <span *ngIf="activeLang === 'ta'">மீகொட</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Narahenpita</span>
                              <span *ngIf="activeLang === 'si'">නාරාහේන්පිට</span>
                              <span *ngIf="activeLang === 'ta'">நாரஹேன்பிட்டி</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Ratmalana</span>
                              <span *ngIf="activeLang === 'si'">රත්මලාන</span>
                              <span *ngIf="activeLang === 'ta'">இரத்மலானை</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Kappetipola</span>
                              <span *ngIf="activeLang === 'si'">කැප්පෙටිපොළ</span>
                              <span *ngIf="activeLang === 'ta'">கெப்பெட்டிபொல</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Bokundara</span>
                              <span *ngIf="activeLang === 'si'">බෝකුන්දර</span>
                              <span *ngIf="activeLang === 'ta'">போகுந்தரா</span>
                            </a>
                            <a class="dropdown-item disabled">
                              <span *ngIf="activeLang === 'en'">Weyangoda</span>
                              <span *ngIf="activeLang === 'si'">වේයන්ගොඩ</span>
                              <span *ngIf="activeLang === 'ta'">வெயங்கொட</span>
                            </a>
                          </div>

                        </div>

                      </div>
                    </div>


                    <div class="fl-select f-select" *ngIf="marketList === 0">
                      <div class="dropdown filterdrop">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2"
                                style="margin-right: .5vw" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.PREFERRED_BUYING_TYPE' | translate}}
                        </button>
                        <div class="language_section dropdown-menu  dropdown-menu-right">
                          <a class="dropdown-item" (click)="selectSpotType()">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.SPOT_BUYING' | translate}}
                          </a>
                          <a class="dropdown-item" (click)="selectAuctionType()">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.AUCTION' | translate}}</a>
                          <a class="dropdown-item disabled">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.FORWARD_BUYING' | translate}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="in-stock-section">

                    </div>
                  </div>
                </div>
              </div>
              <div class="plt-right" id="listortile" *ngIf="marketList === 0">
                <span>{{'PRODUCT_LIST.VIEW' | translate}}</span>&nbsp;&nbsp;
                <span style="margin-right: 5px" (click)="hideListView()" [ngClass]="{'activeicon':activeView==1}">
                  <span class="material-icons iccoon viewtiles">
                    dashboard
                  </span>
                  <!-- change -->
                </span>
                <span (click)="showListView()" [ngClass]="{'activeicon':activeView==2}">
                  <span class="material-icons iccoon viewlist">
                    view_list
                  </span>
                </span>
              </div>
              <div class="clearfix"></div>
            </div>

            <!-- Remove product filter -->
            <div *ngIf="isFilterBySeller" class="remove-fill d-flex justify-content-between align-items-center">
              <div class="seller-card">
                <div class="seller-content">
                  <div class="name">{{sellerUsername}}</div>
                </div>
              </div>
              <div class="fillicon" (click)="clearFilterBySeller()">
                <span class="material-icons">
                  close
                </span>
              </div>
            </div>

            <!-- Products -->
            <div class="products-wp">
              <div *ngIf="marketList === 0 || marketList === 2">
                <div *ngIf="!listShow">
                  <div class="ps-item"
                       *ngFor="let pro of allSpotProducts  | paginate: { itemsPerPage: 40, currentPage: p }">

                    <div class="ps-inner-wrapper">
                      <a [routerLink]="['/product-view-'+setType(pro.type.type)+'/'+tenantValue]"
                         [queryParams]="{id:pro.id,typeId:pro.type.id,typeName:pro.type.type}">
                        <div class="top-image-container">
                          <div class="img-section-item">
                            <div class="badge-item" *ngIf="pro.leadLabel !== null">
                              <img
                                src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.leadLabel+ '/?X-TenantID=' + this.tenantValue}}"
                                alt="">
                            </div>
                            <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                                 *ngIf="pro.type.type === 'SPOT'&& pro?.spotImages.length > 0" class="imgClass"
                                 src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.spotImages[0].image +'/?X-TenantID=' + this.tenantValue}}"
                                 alt="" />

                            <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                                 *ngIf="pro.type.type === 'SPOT' && pro?.spotImages.length === 0" class="imageTag"
                                 src="assets/defaultBrokenImage.jpg" alt="" />

                            <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                                 *ngIf="pro.type.type === 'FORWARD_BN'&& pro?.futureImages.length > 0" class="imgClass"
                                 src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.futureImages[0].image + '/?X-TenantID=' + this.tenantValue}}"
                                 alt="" />

                            <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                                 *ngIf="pro.type.type === 'FORWARD_BN' && pro?.futureImages.length === 0" class="imageTag"
                                 src="assets/defaultBrokenImage.jpg" alt="" />

                            <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                                 *ngIf="pro.type.type === 'FORWARD_ACU'&& pro?.forwardAcuImages.length > 0"
                                 class="imgClass"
                                 src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.forwardAcuImages[0].image +'/?X-TenantID=' + this.tenantValue}}"
                                 alt="" />

                            <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                                 *ngIf="pro.type.type === 'FORWARD_ACU' && pro?.forwardAcuImages.length === 0"
                                 class="imageTag" src="assets/defaultBrokenImage.jpg" alt="" />
                          </div>
                          <!-- economy center badge -->
                          <div class="eco-badges-t" *ngIf="pro.economicCenter === 'TABUTTEGAMA'">
                            <div class="ecobadge">
                              <img src="../../assets/images/icons/thambuththegama.png" alt="">
                            </div>
                          </div>

                        </div>
                      </a>


                      <div class="ps-item-inner new-ps-details">
                        <a [routerLink]="['/product-view-'+setType(pro.type.type)+'/'+tenantValue]"
                           [queryParams]="{id:pro.id,typeId:pro.type.id,typeName:pro.type.type}">
                          <div class="pro-list">
                            <div>
                              <h5 class="productName overflow-ellipsis" title="{{pro.name}}">{{pro.name}}</h5>
                              <p class="pro-category">
                                <span *ngIf="activeLang === 'en'">{{pro.subCategory.commonIndex.description}}</span>
                                <span *ngIf="activeLang === 'si'">{{pro.subCategory.commonIndex.sinhala}}</span>
                                <span *ngIf="activeLang === 'ta'">{{pro.subCategory.commonIndex.tamil}}</span>
                              </p>
                              <star-rating value="{{pro.avgRating}}" totalstars="5" checkedcolor="#dfaf00"
                                           uncheckedcolor="black" size="16px" readonly="true"></star-rating>
                            </div>
                          </div>
                        </a>
                        <div class="bottom-rating">
                          <div class="row">
                            <div class="col-12">
                              <div class="floatlefts">
                                <div class="filter-pill d-flex justify-content-between align-items-center"
                                     title="filter products by {{pro.listedBy}}"
                                     (click)="filterOn(allSpotProducts,pro.listedBy)"
                                     [ngClass]="filstatus ? 'filtered' : 'notfiltered'">
                                  <div class="seller-card">
                                    <div class="profile-picture">
                                      <img src="../../assets/avatar.jpg" alt="">
                                    </div>

                                    <div class="seller-content">
                                      <div class="name">{{pro.listedBy}}</div>
                                      <span
                                        style="font-size: 12px; display: block; color: #7d7d7d;">{{loadCity(pro.location)}}</span>
                                    </div>
                                  </div>
                                  <div class="fillicon">
                                    <span class="material-icons">
                                      filter_list
                                    </span>
                                  </div>
                                </div>

                              </div>

                            </div>
                            <div class="col-4">
                              <div class="productType" style="font-size: 10px; line-height: 10px;"
                                   *ngIf="pro.type.type === 'SPOT'">
                                {{'PRODUCT_LIST.SPOT_PRODUCT' | translate}}</div>
                              <div class="productType" style="font-size: 10px; line-height: 10px;"
                                   *ngIf="pro.type.type === 'FORWARD_BN'">
                                {{'PRODUCT_LIST.FUTURE_PRODUCT' | translate}}</div>
                              <div class="productType"
                                   *ngIf="pro.type.type !== 'SPOT' && pro.type.type !== 'FORWARD_BN'"
                                   style="font-size: 10px; line-height: 10px;">
                                {{'PRODUCT_LIST.AUCTION_PRODUCT' | translate}}
                              </div>
                            </div>
                            <div class="col-8">
                              <div class="pro-price floatright" style="margin-top: 9px;">
                                <span class="unitprice" *ngIf="pro.stockAvailable !== 0 && pro.type.type === 'SPOT'"
                                      style="font-size: 9px; font-weight: 800;">
                                  {{'CURRENCY.CURRENCY' | translate}}
                                </span>
                                <span class="unitprice" *ngIf="pro.stockAvailable !== 0 && pro.type.type === 'SPOT'"
                                      style="font-weight: 800;">
                                  <span *ngIf="pro.sellInSmallerQuantities">
                                    {{pro.spotDTOList[0].unitPrice | number:'2.2-2'}}
                                    /{{pro.unit.unit}}
                                  </span>
                                  <span *ngIf="!pro.sellInSmallerQuantities">
                                    {{pro.spotDTOList[0].unitPrice | number:'2.2-2'}}
                                    /{{pro.unit.unit}}
                                  </span>
                                </span>
                                <div [ngStyle]="{'display': pro.stockAvailable === 0 ? 'unset' : 'none' }">
                                  <span
                                    style="color: red; font-size: 13px; font-weight: 600; margin-top: 13px;">{{'PRODUCT_LIST.SOLD_OUT'
                                    | translate}}</span>
                                </div>
                              </div>

                            </div>
                            <div class="clearfix"></div>


                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="ps-item-list-view" style="display:block;" *ngIf="listShow">
                  <div class="list-view-card"
                       *ngFor="let pro of allSpotProducts  | paginate: { itemsPerPage: 40, currentPage: p }">

                    <div class="inner-list row">
                      <a [routerLink]="['/product-view-'+setType(pro.type.type)+'/'+tenantValue]"
                         [queryParams]="{id:pro.id,typeId:pro.type.id,typeName:pro.type.type}">
                        <div class="col-md-4">
                          <img style="width: 250px!important;"
                               [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                               *ngIf="pro.type.type === 'SPOT'&& pro?.spotImages.length > 0" class="imgClass"
                               src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.spotImages[0].image +'/?X-TenantID=' + this.tenantValue}}"
                               alt="" />

                          <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                               *ngIf="pro.type.type === 'SPOT' && pro?.spotImages.length === 0" class="imageTag"
                               style="width: 250px!important;" src="assets/defaultBrokenImage.jpg" alt="" />

                          <img style="width: 250px!important;"
                               [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                               *ngIf="pro.type.type === 'FORWARD_BN'&& pro?.futureImages.length > 0" class="imgClass"
                               src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.futureImages[0].image + '/?X-TenantID=' + this.tenantValue}}"
                               alt="" />

                          <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                               *ngIf="pro.type.type === 'FORWARD_BN' && pro?.futureImages.length === 0" class="imageTag"
                               style="width: 250px;" src="assets/defaultBrokenImage.jpg" alt="" />

                          <img style="width: 250px!important;"
                               [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                               *ngIf="pro.type.type === 'FORWARD_ACU'&& pro?.forwardAcuImages.length > 0" class="imgClass"
                               src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ pro.forwardAcuImages[0].image +'/?X-TenantID=' + this.tenantValue}}"
                               alt="" />

                          <img [ngStyle]="{'opacity': pro.stockAvailable === 0 ? '0.5' : '1' }"
                               *ngIf="pro.type.type === 'FORWARD_ACU' && pro?.forwardAcuImages.length === 0"
                               style="width: 250px!important;" class="imageTag" src="assets/defaultBrokenImage.jpg"
                               alt="" />
                        </div>
                      </a>
                      <div class="col-md-4 p-col">
                        <div class="prodcutinfo">
                          <div>
                            <a [routerLink]="['/product-view-'+setType(pro.type.type)+'/'+tenantValue]"
                               [queryParams]="{id:pro.id,typeId:pro.type.id,typeName:pro.type.type}">
                              <h5 class="productName " title="{{pro.name}}">{{pro.name}}</h5>
                              <p class="pro-category">
                                <span *ngIf="activeLang === 'en'">{{pro.subCategory.commonIndex.description}}</span>
                                <span *ngIf="activeLang === 'si'">{{pro.subCategory.commonIndex.sinhala}}</span>
                                <span *ngIf="activeLang === 'ta'">{{pro.subCategory.commonIndex.tamil}}</span>
                              </p>
                              <star-rating value="{{pro.avgRating}}" totalstars="5" checkedcolor="#dfaf00"
                                           uncheckedcolor="black" size="16px" readonly="true"></star-rating>
                            </a>
                            <div class="seller-card">
                              <div class="filter-pill d-flex justify-content-between align-items-center"
                                   title="filter products by {{pro.listedBy}}"
                                   (click)="filterOn(allSpotProducts,pro.listedBy)"
                                   [ngClass]="filstatus ? 'filtered' : 'notfiltered'">
                                <div class="profile-picture">
                                  <img src="../../assets/avatar.jpg" alt="">
                                </div>
                                <div class="seller-content">
                                  <div class="name">{{pro.listedBy}}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-4 p-col">
                        <div>
                          <div class="prod-price">
                            <span class="unitprice" *ngIf="pro.stockAvailable !== 0 && pro.type.type === 'SPOT'">
                              {{'CURRENCY.CURRENCY' | translate}}
                            </span>
                            <span class="unitprice" *ngIf="pro.stockAvailable !== 0 && pro.type.type === 'SPOT'">
                              <span *ngIf="pro.sellInSmallerQuantities">
                                {{pro.spotDTOList[0].unitPrice / pro.spotDTOList[0].stock| number:'2.2-2'}}
                                /{{pro.unit.unit}}
                              </span>
                              <span *ngIf="!pro.sellInSmallerQuantities">
                                {{(pro.spotDTOList[0].unitPrice * pro.spotDTOList[0].stock) /
                              pro.spotDTOList[0].stockAvailable | number:'2.2-2'}}
                                /{{pro.unit.unit}}
                              </span>
                            </span>
                            <div [ngStyle]="{'display': pro.stockAvailable === 0 ? 'unset' : 'none' }"
                                 class="pro-price floatright">
                              <span style="color: red">{{'PRODUCT_LIST.SOLD_OUT' | translate}}</span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-12 p-col">
                        <div class="productType" *ngIf="pro.type.type === 'SPOT'">
                          {{'PRODUCT_LIST.SPOT_PRODUCT' | translate}}</div>
                        <div class="productType" *ngIf="pro.type.type === 'FORWARD_BN'">
                          {{'PRODUCT_LIST.FUTURE_PRODUCT' | translate}}</div>
                        <div class="productType" *ngIf="pro.type.type !== 'SPOT' && pro.type.type !== 'FORWARD_BN'">
                          {{'PRODUCT_LIST.AUCTION_PRODUCT' | translate}}
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="clear">&nbsp;</div>

                <div *ngIf="foundResult">
                  <h1 style="font-size: 20px;text-align: center">
                    {{'NO_SEARCH_RESULT1'|translate}}
                  </h1>
                  <h2 style="font-size: 18px;text-align: center;color: darkgrey">
                    {{'NO_SEARCH_RESULT2'|translate}}
                  </h2>
                  <img alt="" style="height: 70px;width: 100%;" src="../../assets/icons8-search.svg">
                </div>
                <div *ngIf="spinnerFilter">
                  <div class="sk-cube-grid">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                    <div class="sk-cube sk-cube3"></div>
                    <div class="sk-cube sk-cube4"></div>
                    <div class="sk-cube sk-cube5"></div>
                    <div class="sk-cube sk-cube6"></div>
                    <div class="sk-cube sk-cube7"></div>
                    <div class="sk-cube sk-cube8"></div>
                    <div class="sk-cube sk-cube9"></div>
                  </div>
                </div>

                <nav class="pagination-wp" aria-label="Page navigation">
                  <ul *ngIf="allSpotProducts.length > 0" class="pagination justify-content-center">
                    <pagination-controls (click)="pageChanged(p,allSpotProducts.length)" (pageChange)="p = $event">
                    </pagination-controls>
                  </ul>
                </nav>
              </div>

              <div *ngIf="marketList === 1">

                <!-- product view for order request -->
                <div class="ps-item for-o-request" style="height: 360px!important;"
                     *ngFor="let pro of allOrderRequestProducts  | paginate: { itemsPerPage: 40, currentPage: p };let i = index">
                  <div class="ps-inner-wrapper order-r-products">
                    <a class="order-img" [routerLink]="'/product-view-order-request/wholesale'"
                       [queryParams]="{id: pro.id}">
                      <div class="top-image-container">
                        <div class="img-section-item">
                          <img src="{{pro.image}}" alt="" class="imgClass">
                        </div>
                      </div>
                    </a>

                    <div class="new-ps-details">
                      <a>
                        <div class="pro-lists">
                          <div class="pro-d" style="margin: 20px">
                            <h5 class="productName overflow-ellipsis">{{pro.name}}</h5>
                            <p class="pro-category">
                              <span>{{pro.category}}</span>
                            </p>
                            <div class="buyer-i-nfo">
                              <div class="seller-card">
                                <div class="profile-picture">
                                  <img src="../../assets/avatar.jpg" alt="">
                                </div>

                                <div class="seller-content">
                                  <div class="name">{{pro.listedBy}}</div>
                                  <span
                                    style="font-size: 12px; display: block; color: #7d7d7d;">{{loadCity(pro.location)}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between order-d">
                              <h6 class="productType pr-type"><small>{{'ORDER_REQUEST'|translate}}</small></h6>
                              <h6 class="o-qty">{{pro.requiredQty}}{{pro.unitType}}</h6>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="clear">&nbsp;</div>

                <div *ngIf="foundResultOrderRequest">
                  <h1 style="font-size: 20px;text-align: center">
                    {{'NO_SEARCH_RESULT1'|translate}}
                  </h1>
                  <h2 style="font-size: 18px;text-align: center;color: darkgrey">
                    {{'NO_SEARCH_RESULT2'|translate}}
                  </h2>
                  <img alt="" style="height: 70px;width: 100%;" src="../../assets/icons8-search.svg">
                </div>
                <div *ngIf="spinnerFilterOrderRequest">
                  <div class="sk-cube-grid">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                    <div class="sk-cube sk-cube3"></div>
                    <div class="sk-cube sk-cube4"></div>
                    <div class="sk-cube sk-cube5"></div>
                    <div class="sk-cube sk-cube6"></div>
                    <div class="sk-cube sk-cube7"></div>
                    <div class="sk-cube sk-cube8"></div>
                    <div class="sk-cube sk-cube9"></div>
                  </div>
                </div>

                <nav class="pagination-wp" aria-label="Page navigation">
                  <ul *ngIf="allOrderRequestProducts.length > 0" class="pagination justify-content-center">
                    <pagination-controls (click)="pageOrderRequestChanged(p,allOrderRequestProducts.length)"
                                         (pageChange)="p = $event">
                    </pagination-controls>
                  </ul>
                </nav>

              </div>
            </div>

          </div>

        </div>

        <div *ngIf="spinner !== 0">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
