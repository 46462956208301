import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {WholesaleBuyerCountryDTO} from '../../../dto/registration/WholesaleBuyerCountryDTO';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {CommonSignUpService} from '../../../service/common-sign-up.service';
import {ProductService} from '../../../service/product.service';
import {Router} from '@angular/router';
import {UnitTypeService} from '../../../service/unit-type.service';
import {MatCheckboxChange, MatChipInputEvent} from '@angular/material';
import {InteractionService} from '../../../service/interaction.service';
import {IdentityService} from '../../../service/identity.service';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {CityFilter} from '../../../dto/filter/cityFilter';
import {DivSecFilter} from '../../../dto/filter/divSecFilter';
import {GramaNilFilter} from '../../../dto/filter/gramaNilFilter';
import {map, startWith} from 'rxjs/operators';
import {SmsService} from '../../../service/sms.service';

@Component({
  selector: 'app-sign-up-collector',
  templateUrl: './sign-up-collector.component.html',
  styleUrls: ['./sign-up-collector.component.css']
})
export class SignUpCollectorComponent implements OnInit {

  signUpCollector: FormGroup;
  submitted = false;

  name: any;
  password: any;
  rePassword: any;
  mobile: any;
  nic: any;
  address: any;
  username: any;
  email: any;

  visible = true;

  tenantValue: any;
  selectedSKUValue: any;

  allCategory: any;
  allUnitTypes: any;
  selectedUnitType: any;

  selectedDivisionalId: any;
  selectedProvinceId: any;
  selectedDistrictId: any;
  selectedGramaId: any;

  allProvinceList: any;
  allDistrictList: any;
  allDivisionList: any;
  allGramaList: any;

  activeLang: string = 'en';
  agreeWithUs: boolean = false;

  incorrectNIC: boolean = false;
  incorrectMobile: boolean = false;
  incorrectEmail: boolean = false;
  incorrectUsername: boolean = false;
  incorrectUsernamePattern: boolean = false;

  allCities: any;
  selectedCity: any;
  address1: any;
  address2: any;
  postalCode: any;
  cityName: any;


  countries = [
    {buyerCountryId: 0, countryCode: 'Sri Lanka'},
    {buyerCountryId: 0, countryCode: 'Japan'},
    {buyerCountryId: 0, countryCode: 'Australia'},
  ];
  cityObject: any;

  // CityFilter
  allCitiesUD: CityFilter[] = [];
  myControl = new FormControl();
  filteredOptions: Observable<CityFilter[]>;
  cityUDObject: CityFilter;
  city: any = '';
  isCitySelected: boolean = false;

  // DeviFilter
  myControlDevi = new FormControl();
  divi: any = '';
  filteredOptionsDivi: Observable<DivSecFilter[]>;
  allDivSecUD: DivSecFilter[] = [];
  divSecObject: DivSecFilter;
  isDiviSelected: boolean = false;

  // GramaFilter
  myControlGrama = new FormControl();
  grama: any = '';
  filteredOptionsGrama: Observable<GramaNilFilter[]>;
  allGramaUD: GramaNilFilter[] = [];
  gramaNilObject: GramaNilFilter;
  isGramaSelected: boolean = false;

  language: any = 'EN';

  @ViewChild('optModal', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;

  constructor(private _formBuilder: FormBuilder,
              private validationHandlerService: ValidationHandlerService,
              private commonSignUpService: CommonSignUpService,
              private productService: ProductService,
              private identityService: IdentityService,
              private smsService: SmsService,
              private interactionService: InteractionService,
              private router: Router,
              private unitTypeService: UnitTypeService,
  ) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  get f() {
    return this.signUpCollector.controls;
  }

  ngOnInit() {
    this.getActiveSKU();
    this.getAllCategory();
    this.fetchAllUnitType();
    this.getAllProvince();
    this.reactiveFormBuyer();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
        this.getAllProvince();
      }
    });
  }

  reactiveFormBuyer() {
    this.signUpCollector = this._formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(this.validationHandlerService.nameValidation())]],
      mobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyTenNumberValidation())]],
      nic: ['', [Validators.required, Validators.pattern(this.validationHandlerService.sriLankaNICValidation())]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      username: ['', [Validators.required, Validators.pattern(this.validationHandlerService.userNameVerification())]],
      province: ['', Validators.required],
      district: ['', Validators.required],
      language: [''],
      email: ['', [Validators.pattern(this.validationHandlerService.emailValidation())]],
      division: ['', Validators.required],
      gramaList: ['', Validators.required]
    });
  }

  submitCollector() {
    this.submitted = true;
    if (this.signUpCollector.invalid) {
      return;
    }
    this.loading();
    this.isOTPModalClose = false;

    let _requestOTP = {
      'telNo': [
        this.mobile
      ],
      'username': this.username,
      'systemUsername': this.name,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
      Swal.close();
      if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
        this.OTPValidation(60 * 3, document.getElementById('time'));
      }
      this.optModal.nativeElement.click();
    }, () => {
      Swal.close();
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.countries.push({buyerCountryId: 0, countryCode: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  remove(country: WholesaleBuyerCountryDTO): void {
    const index = this.countries.indexOf(country);

    if (index >= 0) {
      this.countries.splice(index, 1);
    }
  }

  saveCollector() {
    this.loading();

    if (this.address2 === '' || this.address2 === undefined || this.address2 === null) {
      this.address2 = '';
      this.address = this.address1 + ',' + this.cityName;
    } else {
      this.address = this.address1 + ',' + this.address2 + ',' + this.cityName;
    }

    if (this.email === '' || this.email === null || this.email === undefined) {
      this.email = null;
    }

    let collectorBody = {
      'nic': this.nic,
      'name': this.name,
      'email': this.email,
      'contactNo': this.mobile,
      'address': this.address,
      'username': this.username,
      'pin': "",
      'gramaNiladari': {
        'id': this.selectedGramaId
      },
      'active': false,
      'addressOne': this.address1,
      'addressTwo': this.address2,
      'addressType': 1,
      'cityId': this.selectedCity,
      'countryId': 1,
      'postalCode': this.postalCode
    };

    // to the update sms sending language
    let body = {
      'username': this.username,
      'language': this.language
    };

    this.smsService.saveLanguage(body).subscribe(() => {
      this.commonSignUpService.saveCollector(collectorBody).subscribe(result => {
        if (result) {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.manageLocalizationSuccessTittle(sessionStorage.getItem('LANG')),
            text: this.validationHandlerService.manageLocalizationSuccessBodyMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          }).then(() => {
            this.signUpCollector.reset();
            this.router.navigate(['/home/' + localStorage.getItem('TENANT_VALUE')]);

          });
          const title = Swal.getContent();
          title.style.textAlign = 'center';
        } else {
          Swal.fire({
            type: 'error',
            title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          });
        }
      }, (error) => {
        Swal.fire({
          type: 'error',
          title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        });
        console.log('Agro produce aggregator save function :' + error);
      });
    });
  }

  getAllCategory() {
    this.productService.fetchAllMainCategory().subscribe(result => {
      if (result) {
        this.allCategory = result;
      }
    });
  }

  getActiveSKU() {
    this.productService.getActiveSKU().subscribe(result => {
      this.selectedSKUValue = result.commonIndex.code;
    });
  }

  fetchAllUnitType() {
    this.unitTypeService.fetchAll().subscribe(result => {
      this.allUnitTypes = result;
    });
  }

  selectUnitType(ev) {
    this.selectedUnitType = ev;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.allProvinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.allDistrictList = result;
      }
    });

  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.allDivisionList = result;
        this.allDivSecUD = [];
        for (let div of result) {
          this.divSecObject = new DivSecFilter();
          this.divSecObject.id = div.id;
          this.divSecObject.name = div.nameEN;
          this.divSecObject.nameEN = div.nameEN;
          this.divSecObject.nameSI = div.nameSI;
          this.divSecObject.nameTA = div.nameTA;

          this.allDivSecUD.push(this.divSecObject);
        }

        this.filteredOptionsDivi = this.myControlDevi.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterDivi(state) : this.allDivSecUD.slice())
          );
      }
    });
  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
        this.allCitiesUD = [];
        for (let city of result) {
          this.cityUDObject = new CityFilter();
          this.cityUDObject.id = city.id;
          this.cityUDObject.cityName = city.nameEN;
          this.cityUDObject.nameEN = city.nameEN;
          this.cityUDObject.nameSI = city.nameSI;
          this.cityUDObject.nameTA = city.nameTA;
          this.allCitiesUD.push(this.cityUDObject);
        }

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filter(state) : this.allCitiesUD.slice())
          );
      }
    });
  }

  private _filter(value: string): CityFilter[] {
    if (value != '[object Object]') {
      this.isCitySelected = false;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allCitiesUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allCitiesUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allCitiesUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    }

  }


  private _filterDivi(value: string): DivSecFilter[] {
    if (value != '[object Object]') {
      this.isDiviSelected = false;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allDivSecUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allDivSecUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allDivSecUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    }

  }

  private _filterGrama(value: string): GramaNilFilter[] {
    if (value != '[object Object]') {
      this.isGramaSelected = false;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allGramaUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allGramaUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allGramaUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    }

  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.allGramaList = result;
        this.allGramaUD = [];
        for (let div of result) {
          this.gramaNilObject = new GramaNilFilter();
          this.gramaNilObject.id = div.id;
          this.gramaNilObject.name = div.nameEN;
          this.gramaNilObject.nameEN = div.nameEN;
          this.gramaNilObject.nameSI = div.nameSI;
          this.gramaNilObject.nameTA = div.nameTA;

          this.allGramaUD.push(this.gramaNilObject);
        }

        this.filteredOptionsGrama = this.myControlGrama.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterGrama(state) : this.allGramaUD.slice())
          );
      }
    });
  }

  selectProvince(event: any) {
    this.selectedProvinceId = event;
    this.getAllDistrict(event);
    this.allDivisionList = [];
    this.allGramaList = [];
    this.allCities = [];
  }

  selectDivisional(event: any) {
    if (this.activeLang === 'en') {
      this.divi = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.divi = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.divi = event.nameTA;
    }

    this.selectedDivisionalId = event.id;
    this.getAllGramaNiladari(event.id);
  }

  selectDistrict(event: any) {
    this.selectedDistrictId = event;
    this.getAllCities(event);
    this.getAllDivSec(event);
    this.allGramaList = [];
  }

  selectGrama(event: any) {
    if (this.activeLang === 'en') {
      this.grama = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.grama = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.grama = event.nameTA;
    }
    this.selectedGramaId = event.id;
  }

  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.postalCode = result['postalCode'];
      }
    });
  }

  selectCity(event: any) {
    if (this.activeLang === 'en') {
      this.city = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.city = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.city = event.nameTA;
    }
    this.cityName = event.nameEN;

    this.selectedCity = event.id;
    this.getCityById(event.id);
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  agreeWithAgreement($event: MatCheckboxChange) {
    this.agreeWithUs = $event.checked;
  }

  checkExists(value, type) {
    switch (type) {

      case 'MOBILE':
        if (value !== undefined) {
          this.identityService.checkContactNoExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'CONTACT_NO_NOT_EXISTS') {
              this.incorrectMobile = false;
              this.signUpCollector.updateValueAndValidity();
            } else if (isExists['message'] === 'CONTACT_NO_EXISTS') {
              this.incorrectMobile = true;
              this.signUpCollector.controls['mobile'].setErrors(value);
            }
          });
        }

        break;

      case 'NIC':
        if (value !== undefined) {
          this.identityService.checkNICExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'NIC_NOT_EXISTS') {
              this.incorrectNIC = false;
              this.signUpCollector.updateValueAndValidity();
            } else if (isExists['message'] === 'NIC_EXISTS') {
              Swal.fire({
                position: 'center',
                type: 'error',
                text: this.validationHandlerService.commonExistsMessage(sessionStorage.getItem('LANG')),
                showConfirmButton: true,
                confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
              }).then(() => {
                this.signUpCollector.reset();
                this.interactionService.callSignUpBackButton(true);
              });
            }
          });
        }


        break;

      case 'USERNAME':
        if (value !== undefined) {

          // Cannot be only numbers ( Username should include letters and cannot be only numbers)
          //
          // Letters must be included ( Username should include letters)
          //
          // No Spaces and it has to be one word. (Username cannot have any spaces)

          let boolean = this.validationHandlerService.usernameValidationPattern(value);

          if (boolean) {
            this.incorrectUsernamePattern = true;
            this.signUpCollector.controls['username'].setErrors(value);
          } else {
            this.incorrectUsernamePattern = false;
            this.signUpCollector.updateValueAndValidity();
          }

          this.identityService.checkUsernameExists(value).subscribe(isExists => {
            if (isExists['message'] === 'USERNAME_NOT_EXISTS') {
              this.incorrectUsername = false;
              this.signUpCollector.updateValueAndValidity();
            } else if (isExists['message'] === 'USERNAME_EXISTS') {
              this.incorrectUsername = true;
              this.signUpCollector.controls['username'].setErrors(value);
            }
          });
        }


        break;

      case 'EMAIL':
        if (value !== undefined) {
          this.identityService.checkEmailExists(value).subscribe(isExists => {
            if (isExists['message'] === 'EMAIL_NOT_EXISTS') {
              this.incorrectEmail = false;
              this.signUpCollector.updateValueAndValidity();
            } else if (isExists['message'] === 'EMAIL_EXISTS') {
              this.incorrectEmail = true;
              this.signUpCollector.controls['email'].setErrors(value);
            }
          });
        }
        break;

    }

  }


  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;

  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    let x = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (this.isOTPModalClose) {
        clearInterval(x);
        document.getElementById('time').textContent = '03:00';
        return;
      }

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(x);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    this.isInvalid = false;
    this.ngOtpInputRef.setValue('');
    clearInterval();
  }

  resendOTPCode() {
    this.ngOtpInputRef.setValue('');
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time').textContent = '03:00';
    this.OTPValidation(60 * 3, document.getElementById('time'));
    this.isShowResendButton = false;

    let _requestOTP = {
      'telNo': [
        this.mobile
      ],
      'username': this.username,
      'systemUsername': this.name,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {});
  }

  verifyOTP() {
    this.isLoading = true;
    let verifyOTPBody = {
      'username': this.username,
      'otp': this.OTP_CODE
    };
    this.smsService.verifyOTPCode(verifyOTPBody).subscribe(isVerify => {
      if (isVerify) {
        this.isInvalid = false;
        this.isLoading = false;
        this.closeModal.nativeElement.click();
        this.saveCollector();
      } else {
        this.isInvalid = true;
        this.isLoading = false;
      }
    }, () => {
      this.isInvalid = true;
      this.isLoading = false;
    });
  }

  saveLanguage(event) {
    this.language = event;
  }

}
