<div class="page-contents">
  <div class="packge-form">
    <div class="top-info-section text-center">
      <img src="../../../../../../assets/images/payment-types/pb.png" alt="" class="sltimg">
      <h4 class="title-pck">
        <span *ngIf="activeLang === 'en'">Loan application for Aggregators</span>
        <span *ngIf="activeLang === 'si'">එකතු කරන්නන් සඳහා ණය අයදුම්පත</span>
        <span *ngIf="activeLang === 'ta'">திரட்டிகளுக்கான கடன் விண்ணப்பம்</span>
      </h4>
      <p class="rednotice">
        <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
        <span
          *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
        <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
      </p>
    </div>
    <div class="form-inputs-section vasforms">
      <form [formGroup]="form"  autocomplete="off" class="sms-full-width form-box-panel">
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.FULL_NAME' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
                     formControlName="fullName"    id="fullName" matInput>
              <div *ngIf="submitted && f.fullName.errors" class="inputError">
                <div *ngIf="f.fullName.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
                <div
                  *ngIf="f.fullName.errors.pattern">{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NIC' | translate}}
              <sup style="color: red"> *</sup> </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="nic" [ngClass]="{ 'is-invalid': submitted && f.nic.errors }" formControlName="nic"
                     id="nic"
                        matInput>
              <div *ngIf="submitted && f.nic.errors" class="inputError">
                <div *ngIf="f.nic.errors.required">{{'COMMON_VALIDATION.NIC' | translate}}</div>
                <div *ngIf="f.nic.errors.pattern">{{'COMMON_VALIDATION.VALID_NIC' | translate}}</div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.ADDRESS' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input  readonly [(ngModel)]="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                     formControlName="address"    id="address" matInput>
              <div *ngIf="submitted && f.address.errors" class="inputError">
                <div *ngIf="f.address.errors.required">{{'COMMON_VALIDATION.ADDRESS' | translate}}</div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.CONTACT_NO' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="contact" [ngClass]="{ 'is-invalid': submitted && f.contact.errors }" type="tel"

                     formControlName="contact" id="contact" matInput>
              <div *ngIf="submitted && f.contact.errors" class="inputError">
                <div *ngIf="f.contact.errors.required">{{'COMMON_VALIDATION.MOBILE' | translate}}</div>
                <div *ngIf="f.contact.errors.pattern">{{'COMMON_VALIDATION.VALID_MOBILE' | translate}}
                  {{'COMMON_VALIDATION.VALID_MOBILE_MSG' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.EMAIL' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input  [(ngModel)]="email" type="email" formControlName="email" id="email" matInput>
              <div *ngIf="submitted && f.email.errors" class="inputError">
                <div *ngIf="f.email.errors.pattern">{{'COMMON_VALIDATION.VALID_EMAIL' | translate}}</div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl m40">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NUMBER_OF_DEPENDENCE' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="dependents" [ngClass]="{ 'is-invalid': submitted && f.dependents.errors }"
                     type="number"
                     formControlName="dependents" id="dependents" matInput>
              <div *ngIf="submitted && f.dependents.errors" class="inputError">
                <div *ngIf="f.dependents.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NUMBER_OF_DEPENDENCE_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl m40">
              {{'Location_warehouse'|translate}}<sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="location" [ngClass]="{ 'is-invalid': submitted && f.location.errors }"
                     formControlName="location" id="location" matInput>
              <div *ngIf="submitted && f.location.errors" class="inputError">
                <div *ngIf="f.location.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.LOCATION_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.OWNER_FARM' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <mat-select (openedChange)="clickDropdown()" formControlName="ownership" [(ngModel)]="ownership"
                          [ngClass]="{ 'is-invalid': submitted && f.ownership.errors }">
                <mat-option *ngFor="let ownership of allOwnershipList" [value]="ownership.id">
                  <span *ngIf="activeLang === 'en'">{{ownership.nameEN}}</span>
                  <span *ngIf="activeLang === 'si'">{{ownership.nameSI}}</span>
                  <span *ngIf="activeLang === 'ta'">{{ownership.nameTA}}</span>
                </mat-option>
              </mat-select>
              <div *ngIf="submitted && f.ownership.errors" class="inputError">
                <div *ngIf="f.ownership.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.OWNERSHIP_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl m40">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SIZE_OF_WAREHOUSE' | translate}}<sup
              style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="warehouseSize" [ngClass]="{ 'is-invalid': submitted && warehouseSize }" type="number"
                     formControlName="warehouseSize" id="warehouseSize" matInput>
              <div *ngIf="submitted && f.warehouseSize.errors" class="inputError">
                <div *ngIf="f.warehouseSize.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SIZE_OF_WARE_HOUSE' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl m40">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NUMBER_OF_EMP' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="noOfEmp" [ngClass]="{ 'is-invalid': submitted && f.noOfEmp.errors }" type="number"
                     formControlName="noOfEmp" id="noOfEmp" matInput>
              <div *ngIf="submitted && f.noOfEmp.errors" class="inputError">
                <div *ngIf="f.noOfEmp.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NUMBER_OF_EMP_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NO_YEARS_AGGR' | translate}}<sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="activeYears" [ngClass]="{ 'is-invalid': submitted && f.activeYears.errors }"
                     type="number"
                     formControlName="activeYears" id="activeYears1" matInput>
              <div *ngIf="submitted && f.activeYears.errors" class="inputError">
                <div *ngIf="f.activeYears.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.NUMBER_OF_ACTIVE_YEAR' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.AVERAGE_INCOME' | translate}}
              ({{'CURRENCY.CURRENCY' | translate}})
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="avgIncome" [ngClass]="{ 'is-invalid': submitted && f.avgIncome.errors }" type="number"
                     formControlName="avgIncome" id="avgIncome" matInput>
              <div *ngIf="submitted && f.avgIncome.errors" class="inputError">
                <div *ngIf="f.avgIncome.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.AVERAGE_INCOME_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.AVERAGE_EXPENSES' | translate}}
              ({{'CURRENCY.CURRENCY' | translate}})
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="avgExp" [ngClass]="{ 'is-invalid': submitted && avgExp }" type="number"
                     formControlName="avgExp" id="avgExp" matInput>
              <div *ngIf="submitted && f.avgExp.errors" class="inputError">
                <div *ngIf="f.avgExp.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.AVERAGE_EXPENSES_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>

        </div>
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.LOAN_PURPOSE' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <mat-select (openedChange)="clickDropdown()" [(ngModel)]="purpose"
                          [ngClass]="{ 'is-invalid': submitted && f.purpose.errors }" formControlName="purpose">
                <mat-option *ngFor="let purpose of allPurposeList" [value]="purpose.id">
                  <span *ngIf="activeLang === 'en'">{{purpose.nameEN}}</span>
                  <span *ngIf="activeLang === 'si'">{{purpose.nameSI}}</span>
                  <span *ngIf="activeLang === 'ta'">{{purpose.nameTA}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.LOAN_REQUIREMENT' | translate}}
              ({{'CURRENCY.CURRENCY' | translate}})
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [ngClass]="{ 'is-invalid': submitted && f.requirement.errors }"
                     [(ngModel)]="requirement" type="number" formControlName="requirement" id="requirement" matInput>
              <div *ngIf="submitted && f.requirement.errors" class="inputError">
                <div *ngIf="f.requirement.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.LOAN_REQUIREMENT_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.REPAYMENT_TENURE' | translate}}
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="repaymentTenure" [ngClass]="{ 'is-invalid': submitted && f.repaymentTenure.errors }"
                     type="number" formControlName="repaymentTenure" id="repaymentTenure" matInput>
              <div *ngIf="submitted && f.repaymentTenure.errors" class="inputError">
                <div *ngIf="f.repaymentTenure.errors.required">
                  {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.REPAYMENT_TENURE_REQUIRED' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group btn-sections b-0 p-0 text-right">

          <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    margin-right: 10px;
    border-radius: 5px;" (click)="submitForm('DRAFT')"
                  type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.DRAFT' | translate}}</button>

          <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="submitForm('PENDING')"
                  type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SUBMIT' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>

<button hidden data-target="#successModal" data-toggle="modal" #openSuccessModal></button>
<button hidden data-target="#draftModal" data-toggle="modal" #openDraftModal></button>

<!-- Success Modal -->
<div class="modal fade npa-model" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'loan_save'|translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-loans/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Draft Modal -->
<div class="modal fade npa-model" id="draftModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'loan_draft_save' | translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-loans/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
