import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import Swal from 'sweetalert2';
import {CommonService} from '../../../../common/common.service';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportService} from '../../../../service/report.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';


@Component({
  selector: 'app-farmer-earnings',
  templateUrl: './farmer-earnings.component.html',
  styleUrls: ['./farmer-earnings.component.css']
})
export class FarmerEarningsComponent implements OnInit {
  dataSource;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['transactionId', 'productName', 'paymentMethod', 'amount', 'date', 'accountNo', 'commissionTerm', 'status'];
  tenantValue: any;
  ELEMENT_DATA = [];
  transactionTrimValue: any;
  userRole: any;
  isLead: boolean = false;
  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;
  ecoShopCode: any;

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private generalSettings: SystemSettingsService,
              private router: Router,
              private reportService: ReportService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });

    if (this.userRole === 'LEAD') {
      this.isLead = true;
    }
  }

  ngOnInit() {
    this.getEarningDetails();
    this.fetchAllSettings();
  }

  commonLogoutURL() {
    Swal.close();
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/'] + this.tenantValue);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getEarningDetails() {
    this.dataSource = new MatTableDataSource();
    this.ELEMENT_DATA = [];
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.commonService.processing();
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

        if (this.isLead) {
          this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {
            this.reportService.fetchEarningsByLeadCode(refNo.message).subscribe(resultEarning => {
              for (let item of resultEarning) {
                this.ELEMENT_DATA.push({
                  transactionId: item.transactionId,
                  productName: item.productName,
                  paymentMethod: item.paymentMethod,
                  amount: item.amount,
                  date: item.date,
                  accountNo: item.accountNo,
                  commissionTerm: item.commissionTerm,
                  status: item.status
                });
              }
              Swal.close();
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
            }, () => {
              Swal.close();
            });
          });
        }else if(this.isEcoFarmerSection){
          this.reportService.fetchEarningsByShopCode(this.ecoShopCode).subscribe(resultEarning => {
            for (let item of resultEarning) {
              this.ELEMENT_DATA.push({
                transactionId: item.transactionId,
                productName: item.productName,
                paymentMethod: item.paymentMethod,
                amount: item.amount,
                date: item.date,
                accountNo: item.accountNo,
                commissionTerm: item.commissionTerm,
                status: item.status
              });
            }
            Swal.close();
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          }, () => {
            Swal.close();
          });
        } else {
          this.reportService.fetchEarnings(whoAmIDetails['email']).subscribe(resultEarning => {
            for (let item of resultEarning) {
              this.ELEMENT_DATA.push({
                transactionId: item.transactionId,
                productName: item.productName,
                paymentMethod: item.paymentMethod,
                amount: item.amount,
                date: item.date,
                accountNo: item.accountNo,
                commissionTerm: item.commissionTerm,
                status: item.status
              });
            }
            Swal.close();
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          }, () => {
            Swal.close();
          });
        }
      });
    } else {
      this.commonLogoutURL();
    }
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  fetchAllSettings() {
    this.generalSettings.fetchAll().subscribe(fetchAll => {
      let tempArray = [];
      tempArray.push(fetchAll);
      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

}
