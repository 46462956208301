import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpInterceptor} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import Swal from 'sweetalert2';

/**
 * @author Sachin Dilshan
 * @version 1.0.0
 */

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  tenantValue: any;
  statusLoop: boolean = true;

  constructor(private _router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqWithCredentials = req.clone({withCredentials: true});
    return next.handle(reqWithCredentials)
      .pipe(
        catchError(error => {
          if (error.status === 401 || error.status === 403) {
            if (this.statusLoop) {
              this.statusLoop = false;
              Swal.close();
              let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
              localStorage.removeItem('isLogged');
              localStorage.setItem('TENANT_VALUE', TENANT_VALUE);
            }
          }
          return throwError(error);
        })
      );
  }

}
