import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../common/common.service';
import {UserRoleService} from '../../../service/user-role.service';
import {RiyawiruService} from '../../../service/riyawiru.service';
import {CoreService} from '../../../service/core.service';
import {TransportDetailsDTO} from '../../../dto/TransportDetailsDTO';
import {CartService} from '../../../service/cart.service';
import {UserTokenDTO} from '../../../dto/userTokenDTO';
import {MainUrlService} from '../../../service/main-url.service';
import {ProductService} from '../../../service/product.service';
import Swal from 'sweetalert2';
import {Md5} from 'ts-md5';
import {environment} from '../../../../environments/environment';
import {InteractionService} from '../../../service/interaction.service';

@Component({
  selector: 'app-delivery-bid-requests',
  templateUrl: './delivery-bid-request.component.html',
  styleUrls: ['./delivery-bid-request.component.css']
})
export class DeliveryBidRequestComponent implements OnInit {
  tenantValue;
  rate: any;
  selectNewProductOptions: number;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('transportModal', {static: false}) transportModal;
  @ViewChild('success', {static: false}) success;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;

  displayedColumns: string[] = ['index', 'orderTitle', 'bids', 'date', 'status', 'action1', 'action2'];
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  selectedOption = 0;

  riyaviruModalData: any;
  riyawiruVehicleTypes: any;
  expectationPrice: any;
  isRequiredExpectation: boolean = false;
  isPickVehicle: boolean = false;

  transportList: any[];
  railwayList: any;
  transportID: any;
  dropStation: any;
  prdTransportList = [];
  isSellerFixed = false;
  selectedVehicleId: any;
  riyawiruSelectedVehicleType = [];
  orderId: any;
  item: any;

  driverBidsList: any;
  orderNo: any;
  orderDetails: any;

  driverDetails: any;
  driverOffer: any;
  activeLang: any;

  username: any;

  isTrain: boolean = false;
  isSeller: boolean = false;
  isBuyer: boolean = false;
  station: any;
  seller: any;
  sellerName: any;
  sellerMobile: any;
  sellerLocation: any;
  imgUrl: any;
  deliveryOptList: any;
  userTokenDTO = new UserTokenDTO;

  sku: any;
  productImage: any;
  bidRequestId: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userRoleService: UserRoleService,
              private productService: ProductService,
              private coreService: CoreService,
              private cartService: CartService,
              private riyawiruService: RiyawiruService,
              private interactionService: InteractionService,
              public mainUrl: MainUrlService,
              private commonService: CommonService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit(): void {
    this.loadBidRequests();
    this.getRiyawiruVehicleTypes();
    this.deliveryMethod();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

  loadBidRequests() {
    this.commonService.processing();
    this.dataSource = new MatTableDataSource();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.username = whoAmIDetails['email'];
      this.riyawiruService.fetchRiyawiruBidRequests(whoAmIDetails['email']).subscribe(requests => {
        let arr = [];
        let bidDetailsList = [];

        for (let req of requests) {
          bidDetailsList.push(req);
        }

        for (let bidDetails of bidDetailsList) {
          let bidCountArr = [];
          if (bidDetails['bidDrivers'] !== null) {
            for (let driver of bidDetails['bidDrivers']) {
              if (driver['driversOffer'] !== null &&
                driver['status'] !== 'REQUEST_PENDING' &&
                driver['status'] !== 'REJECTED') {
                bidCountArr.push(driver);
              }
            }
          }
          arr.push({
            'orderList': bidDetails,
            'orderNo': bidDetails['orderNo'],
            'orderTitle': bidDetails['orderItems'][0]['itemName'],
            'bids': bidCountArr.length,
            'date': bidDetails['createdDate'],
            'status': bidDetails['status']
          });
        }

        this.dataSource = new MatTableDataSource(arr);
        this.dataSource.paginator = this.paginator;
        Swal.close();
      }, () => {
        Swal.close();
      });
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  deliveryMethod() {
    this.coreService.getDeliveryMethod().subscribe(
      (data) => {
        this.transportList = data;
      }, () => {
        Swal.close();
      }
    );
  }

  clickedRiyawiruOption: boolean = false;

  checkVal($event, sku, val) {

    this.clickedRiyawiruOption = val === 1;

    for (let delivery of this.prdTransportList) {
      if (sku === delivery.sku) {
        let index = this.prdTransportList.indexOf(delivery);
        if (index !== -1) {
          this.prdTransportList.splice(index, 1);
        }
      }
    }

    for (let delivery of this.riyawiruSelectedVehicleType) {
      if (sku === delivery.sku) {
        let index = this.riyawiruSelectedVehicleType.indexOf(delivery);
        if (index !== -1) {
          this.riyawiruSelectedVehicleType.splice(index, 1);
        }
      }
    }

    this.prdTransportList.push({
      'sku': sku,
      'trnId': val
    });

    this.riyawiruSelectedVehicleType.push({
      'sku': sku,
      'value': 0,
      'paymentMethod': 'Cash On Delivery',
      'vehicleType': null,
      'trnId': val
    });

  }

  updateDeliveryMethod() {
    this.commonService.processing();
    let body = {
      'oderId': this.orderNo,
      'sku': this.riyawiruSelectedVehicleType[0].sku,
      'vehicleType': this.riyawiruSelectedVehicleType[0].vehicleType,
      'deliveryId': this.riyawiruSelectedVehicleType[0].trnId,
      'proxyUrl': environment.proxyUrl,
      'buyer': this.username
    };

    this.productService.updateTransportMethod(body).subscribe(() => {
      Swal.close();
      this.loadBidRequests();
      this.getRiyawiruVehicleTypes();
      this.deliveryMethod();
    });
  }


  selectRailway(event) {
    this.dropStation = event;
  }

  clickRiyaviru(item: any, sku: any) {
    this.transportOptionsClose.nativeElement.click();
    this.riyaviruModalData = item;
    this.selectedOption = sku + 1;
  }

  riyawiruModalShow() {
    this.transportModal.nativeElement.click();
  }

  getRiyawiruVehicleTypes() {
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.fetchAllVehicleTypes(createToken['access_token']).subscribe(result => {
        this.riyawiruVehicleTypes = result;
      });
    });
  }

  selectVehicleType(id, sku) {
    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.vehicleType = id;
      }
    }
    this.selectedVehicleId = id;
    this.isPickVehicle = false;
    this.selectedId = id;
  }

  selectedItem: any;
  selectedId: any;

  isSelectedItem(item) {
    return this.selectedItem === item;
  }

  sendBidRequest(sku: any, expectationPrice: any) {

    if (this.selectedVehicleId === undefined) {
      return this.isPickVehicle = true;
    }
    this.isPickVehicle = false;

    if (expectationPrice === undefined || expectationPrice === 0) {
      return this.isRequiredExpectation = true;
    }
    this.isRequiredExpectation = false;

    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.price = expectationPrice;
      }
    }
    this.expectationPrice = undefined;
    this.selectedVehicleId = undefined;
    this.closeRiyawiruModal.nativeElement.click();
  }

  selectDriverBids(orderNo: any, data: any) {
    this.loadTab(data.orderItems[0].itemNo);
    this.orderNo = orderNo;
    this.bidRequestId = data.bidRequestId;
    this.orderDetails = data;
    let arr = [];
    let temp = [];

    if (data['bidDrivers'] !== null) {
      for (let list of data['bidDrivers']) {
        arr.push(list);
      }
    }

    for (let driver of arr) {
      if (driver['driversOffer'] !== null && driver['status'] !== 'REQUEST_PENDING' && driver['status'] !== 'REJECTED') {
        temp.push(driver);
      }
    }
    this.driverBidsList = temp;
  }

  driverId: any;
  distance: any;

  viewBid(driverId, driverOffer) {
    this.driverOffer = driverOffer;
    this.driverId = driverId;
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.getDistance
      (this.orderDetails.pickupAddresses.lat, this.orderDetails.pickupAddresses.lng, this.orderDetails.deliveryAddress.lat, this.orderDetails.deliveryAddress.lng, createToken['access_token'])
        .subscribe(distance => {
          this.distance = distance['distance'].text;
        });
    });

    this.riyawiruService.fetchDriverDetails(driverId).subscribe(result => {
      this.driverDetails = result;
    }, error => {
      if (error.status === 302) {
        this.driverDetails = error['error'];
      }
    });
  }

  loadTab(sku) {
    this.sku = sku;
    this.isBuyer = false;
    this.isSeller = false;
    this.isTrain = false;
    this.isSellerFixed = false;
    this.selectedOption = 0;
    this.productService.productImageBySku(this.sku).subscribe(result => {
      this.productImage = result[0]['image'];
    });
    this.cartService.fetchTransportDetails(sku).subscribe(
      (data: TransportDetailsDTO) => {
        this.station = data.station;
        this.seller = data.seller;
        this.sellerName = data.sellerName;
        this.sellerMobile = data.sellerMobile;
        this.sellerLocation = data.sellerLocation;
        this.deliveryOptList = data.deliveryOpt;
        if (data.deliveryOpt !== null) {
          for (let opt of data.deliveryOpt) {
            if (opt === 2) {
              this.isBuyer = true;
            }
            if (opt === 3) {
              this.getUser();
              this.isSeller = true;
            }
            if (opt === 4) {
              this.isTrain = true;
            }
            if (opt === 5) {
              this.isSellerFixed = true;
            }
          }
        }

      }
    );
  }

  getUser() {
    this.userTokenDTO.userName = this.seller;
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        let hashStr;
        if (this.seller !== null) {
          hashStr = Md5.hashStr(this.seller);
        } else {
          hashStr = Md5.hashStr('');
        }
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE +
          result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
      }
    });
  }

  awardBid() {
    this.commonService.processing();
    this.riyawiruService.bidAwarding(this.orderNo, this.driverId).subscribe(result => {
      Swal.close();
      if (result) {
        this.success.nativeElement.click();
        this.loadBidRequests();
      }
    }, () => {
      Swal.close();
    });
  }

  riyawiruPaymentArr = [];

  checkout(data) {
    if (data['bidDrivers'] !== null) {
      this.riyawiruPaymentArr.push({
        'orderNo': data.orderNo,
        'data': data
      });
      this.router.navigate(['/riyaviru-payment-checkout/wholesale'], {
        queryParams: {
          data: JSON.stringify(this.riyawiruPaymentArr)
        }
      });
    }
  }

  addToArray(orderNo: any, orderList: any) {
    for (let riyawiru of this.riyawiruPaymentArr) {
      if (orderNo.toString() === riyawiru.orderNo.toString()) {
        let index = this.riyawiruPaymentArr.indexOf(riyawiru);
        if (index !== -1) {
          return this.riyawiruPaymentArr.splice(index, 1);
        }
      }
    }
    this.riyawiruPaymentArr.push({
      'orderNo': orderNo,
      'data': orderList
    });
  }

  checkoutAll() {
    if (this.riyawiruPaymentArr.length > 0) {
      this.router.navigate(['/riyaviru-payment-checkout/wholesale'], {
        queryParams: {
          data: JSON.stringify(this.riyawiruPaymentArr)
        }
      });
    }
  }


  disableButton(status) {
    switch (status) {
      case 'AWARDED':
        return true;

      case 'ACCEPTED':
        return true;

      case 'NOT_AWARDED':
        return true;

      case 'REJECTED':
        return true;

      case 'PAYMENT_CONFIRMED':
        return true;

      case 'DISPATCH_CONFIRMED':
        return true;

      case 'LOADED_AND_LEAVE':
        return true;

      case 'REACHED_DROPOFF_POINT':
        return true;

      case 'DROPOFF_CONFIRMED':
        return true;

      default:
        return false;

    }
  }

  ViewBidText(status) {
    switch (status) {
      case 'AWARDED':
        return 'Awarded';

      case 'ACCEPTED':
        return 'Awarded';

      case 'NOT_AWARDED':
        return 'Awarded';

      case 'REJECTED':
        return 'Awarded';

      case 'PAYMENT_CONFIRMED':
        return 'Awarded';

      case 'DISPATCH_CONFIRMED':
        return 'Awarded';

      case 'LOADED_AND_LEAVE':
        return 'Awarded';

      case 'REACHED_DROPOFF_POINT':
        return 'Awarded';

      case 'DROPOFF_CONFIRMED':
        return 'Awarded';

      default:
        return 'View Bid';

    }
  }

  checkExpectationPrice(value: any) {
    if (value <= 0) {
      this.expectationPrice = undefined;
    }
  }
}
