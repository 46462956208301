export class PurchaseStatusDTO {
  id: number;
  status: string;
  comment: string;
  rejectedBy: string;
  deliveryDate: string;
  timeSlot: string;
  packing: string;
  numberOfPacking: string;
  proxyUrl: string;
}
