<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>User Reviews</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; overflow-y: auto;">
                <table class="table" style="width:100%" mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td w-20">
                      Product
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2" *ngIf="row.productType === 'spot'">
                        <div style="width: 50px;" *ngIf="row.spotProduct.spotList.length > 0">
                          <img alt=""
                               src="{{this.mainUrl.MAIN_URL_FILE + row.spotProduct.spotList[0].productImages[0].image + '/?X-TenantID=' + this.tenantValue}}"
                               style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                        </div>
                        <div class="rightdiv">
                          &nbsp;{{row.spotProduct.name}}
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reviewedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td w-20">
                      {{'MY_ACCOUNT.SALES.REVIEWS.REVIEWERS_NAME' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      {{row.userLocationDTO.name}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="rating">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td w-10">
                      {{'MY_ACCOUNT.SALES.REVIEWS.RATING' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      {{row.rating}}/5
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="review">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td w-30">
                      {{'MY_ACCOUNT.SALES.REVIEWS.REVIEW' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      {{row.comment}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td w-15">
                      {{'MY_ACCOUNT.SALES.REVIEWS.DATE' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      {{convertDate(row.created)}}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns" data-toggle="modal" data-target="#vieworder">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" data-toggle="modal"
                      (click)="loadReviewModal(row)"
                      data-target="#productReviews"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- reviews model -->
<div class="modal fade npa-model" id="productReviews" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title d-flex align-items-center">
          <i class="material-icons">{{'MY_ACCOUNT.SALES.REVIEWS.EDIT' | translate}}</i> &nbsp;
          {{'MY_ACCOUNT.SALES.REVIEWS.REVIEW' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="selectNewProductOptions==0"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-reviews></app-reviews>
      </div>
    </div>
  </div>
</div>
