import {Component, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../../common/common.service';
import Swal from 'sweetalert2';
import {MainUrlService} from '../../../../service/main-url.service';
import {InteractionService} from '../../../../service/interaction.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-farmer-reviews',
  templateUrl: './farmer-reviews.component.html',
  styleUrls: ['./farmer-reviews.component.css']
})
export class FarmerReviewsComponent implements OnInit {

  selectNewProductOptions: number;
  reviewsData: any;
  userRole: any;
  isEconomicCenter: boolean = false;
  dataSource;
  displayedColumns: string[] =
    ['product', 'reviewedBy', 'rating', 'review', 'date'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  imageUrl: string;
  tenantValue: string;
  ecoShopCode: any;
  isEcoFarmerSection: boolean = false;

  constructor(private productService: ProductService,
              private commonService: CommonService,
              public mainUrl: MainUrlService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,) {
    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });

  }

  ngOnInit() {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.fetchAllReviews();
  }

  fetchAllReviews() {
    this.commonService.processing();
    if (this.isEconomicCenter && this.isEcoFarmerSection) {
      this.productService.fetchAllRatingByEcoShopCode(this.ecoShopCode).subscribe(
        result => {
            this.commonFetchBody(result);
        }, () => {
          Swal.close();
        });
    } else {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        if (whoAmIDetails) {
          this.productService.fetchAllRatingByUsername(whoAmIDetails['email']).subscribe(
            result => {
              this.commonFetchBody(result);
            }, () => {
              Swal.close();
            }
          );
        }
      }, () => {
        Swal.close();
      });
    }

  }

  commonFetchBody(values) {
    let arr = [];
    for (let list of values) {
      if (list.status === 'APPROVED') {
        arr.push(list);
      }
    }
    Swal.close();
    this.reviewsData = arr;
    this.dataSource = new MatTableDataSource(this.reviewsData);
    this.dataSource.paginator = this.paginator;
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  loadReviewModal(row: any) {
    this.interactionService.setUpdateReviews(row);
  }
}
