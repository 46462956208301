<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.ORDER_REQUESTS' | translate}}</h2>
            </div>
            <div class="col-4 text-right"><a data-target="#createOrder" data-toggle="modal"
                                             class="btn btn-success">
              <div class="row" style="flex-wrap: unset!important;">
                <div class="col-1">
                  <span class="material-icons">verified_user</span>
                </div>
                <div class="col-11">
                  {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.CREATE_A_NEW_ORDER' | translate}}
                </div>
              </div>

            </a>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                <mat-form-field class="matsearch">
                  <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>

                <table [dataSource]="dataSource" class="table salestable" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="orderTitle">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.ORDER_TITLE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        {{row.orderTitle}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="numberOfBids">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.NUMBER_OF_BIDS' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.numberOfBids}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bidAmount">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.BID_AMOUNT' | translate}}
                      ({{'CURRENCY.CURRENCY' | translate}})
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.highestBid| number:'2.2-2'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bidEndDate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.BID_END_DATE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.bidEndDate |date:'MM/dd/yyyy'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.STATUS' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      <img *ngIf="row.status === 'ACTIVE' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-status.svg" />
                      <img *ngIf="row.status === 'ACTIVE' && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-sin.svg" />
                      <img *ngIf="row.status === 'ACTIVE' && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-tam.svg" />

                      <img *ngIf="row.status === 'PENDING' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-status.svg" />
                      <img *ngIf="row.status === 'PENDING' && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-sin.svg" />
                      <img *ngIf="row.status === 'PENDING' && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-tam.svg" />


                      <img *ngIf="row.status === 'AWARDED' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/awarded.svg" />
                      <img *ngIf="row.status === 'AWARDED' && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/awarded-sin.svg" />
                      <img *ngIf="row.status === 'AWARDED' && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/awarded-tam.svg" />


                      <img *ngIf="row.status === 'DECLINED'  && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/declined-status.svg" />
                      <img *ngIf="row.status === 'DECLINED'  && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/declined-sin.svg" />
                      <img *ngIf="row.status === 'DECLINED'  && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/declined-tam.svg" />

                      <img *ngIf="row.status === 'PURCHASED' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/purchased.svg" />
                      <img *ngIf="row.status === 'PURCHASED'&& activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/purchased-s.svg" />
                      <img *ngIf="row.status === 'PURCHASED'&& activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/purchased-t.svg" />
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                  </tr>
                  <tr *matRowDef="let row; columns: displayedColumns" mat-row disabled="{{row.numberOfBids === 0}}"
                      [routerLink]="['/my-account/buyer-order-request-bids/wholesale']"
                      [queryParams]="{sku:row.sku}"
                  ></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- create order -->
<div aria-hidden="true" class="modal fade npa-model" id="createOrder" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fa fa-pencil" aria-hidden="true"></i>
          {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.NEW_ORDER_REQUEST' | translate}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-buyer-create-new-order></app-buyer-create-new-order>
      </div>
    </div>
  </div>
</div>
