<app-header></app-header>

<div class="main-container">
  <div class="container">

    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">
          <div class="cfs-title">
            <h3>{{'Delivery_Payment'|translate}}</h3>
          </div>

          <div class="checkout-container">
            <div class="checkout-form">
              <div class="delivery-vehcles">
                <h6>{{detailsList.length}} {{'vehicles'|translate}}</h6>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="deliveryrows">

                      <div class="delver-pay-card" *ngFor="let item of detailsList">
                        <div class="top-head d-flex align-items-center justify-content-between dblock">
                          <div class="leftp d-flex align-items-center">
                            <h4 *ngFor="let vehicle of item?.bidDrivers">
                              <span *ngIf="vehicle.status === 'Awarded'">
                                {{vehicle.vehicleType}}
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div class="middlec row">
                          <div class="col-lg-4 col-xs-12">
                            <div class="pro-n d-flex align-items-center">
                              <img class="imageSize"
                                   src="{{item.orderItems[0]?.itemImageUrl}}"
                                   alt="">
                              <div class="rightp">
                                <h5>{{item.orderItems[0].itemName}}</h5>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-8 col-xs-12">
                            <div class="frm-d">
                              <small>{{'From'|translate}}</small>
                              <p>{{item.orderItems[0].pickupAddress.street}}
                                {{item.orderItems[0].pickupAddress.city}}</p>
                              <p class="bluetxt">{{item.orderItems[0].pickupAddress.lat}}"N
                                - {{item.orderItems[0].pickupAddress.lng}}"E</p>
                            </div>
                          </div>
                        </div>
                        <div class="bottomc d-flex align-items-center justify-content-between dblock">
                          <div class="leftp d-flex align-items-center">
                            <p>{{'To'|translate}}</p>
                            <div class="rightinner">
                              <p class="addr">{{item.deliveryAddress.street}}&nbsp;{{item.deliveryAddress.city}}</p>
                              <p class="bluetxt">{{item.deliveryAddress.lat}}"N - {{item.deliveryAddress.lng}}"E</p>
                            </div>
                          </div>
                          <div class="rightp d-flex align-items-center">
                            <h4 *ngFor="let vehicle of item?.bidDrivers">
                              <span *ngIf="vehicle.status === 'ACCEPTED'">
                               {{'CURRENCY.CURRENCY' | translate}} {{vehicle.driversOffer| number:'2.2-2'}}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="total-final text-right">
                      <h2><span class="ttitle">Total: </span>
                        <span>{{'CURRENCY.CURRENCY' | translate}} {{paymentTotal| number:'2.2-2'}}</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <a data-target="#paymentConfirmation" data-toggle="modal" id="PAYCORPMODEL" style="display: none"></a>
                  <p style="font-size: 13px">
                    <span *ngIf="selectPaymentType==1">{{'CHECKOUT.AGREE_MESSAGE' | translate}}</span>
                    <span *ngIf="selectPaymentType==2 || selectPaymentType==3">{{'CHECKOUT.AGREE_MESSAGE_PROCEED' |
                      translate}}</span>

                    <a href="/checkout-terms/{{tenantValue}}" target="_blank">{{'CHECKOUT.TERMS_CONDITIONS' |
                      translate}}</a>.
                  </p>
                </div>
                <div class="col-lg-6">
                  <button (click)="cardPayment()" *ngIf="selectPaymentType==1" class="btn-login"
                          style="width: 100%; max-width: 200px; float: right;" type="submit"><i aria-hidden="true"
                                                                                                class="fa fa-credit-card"></i>
                    {{'CHECKOUT.PAY_NOW.PAY_NOW' | translate}}
                  </button>
                  <a #cardPaymentConfirmation data-target="#cardPaymentConfirmation" data-toggle="modal"
                     style="display: none"></a>
                </div>
              </div>

            </div>
            <div class="checkout-summery">
              <div class="c-products-sum" style=" overflow-y: scroll; margin-bottom: 8vh;">

                <div *ngFor="let item of detailsList" class="dcp-item">
                  <div class="dcp-img">
                    <img class="imageSize"
                         src="{{item.orderItems[0]?.itemImageUrl}}"
                         alt="">
                  </div>
                  <div class="dcp-info">
                    <span class="p-name">{{item.orderItems[0].itemName}}</span>

                    <h4 *ngFor="let vehicle of item?.bidDrivers">
                              <span *ngIf="vehicle.status === 'ACCEPTED'">
                               {{'CURRENCY.CURRENCY' | translate}} {{vehicle.driversOffer| number:'2.2-2'}}
                              </span>
                    </h4>
                  </div>
                </div>

                <ngx-content-loading *ngIf="!detailsList">
                  <svg:g ngx-code-preset></svg:g>
                </ngx-content-loading>
              </div>

              <div class="checkout-summery-midle-box" style=" text-align: center;">
              </div>

              <div *ngIf="detailsList" class="checkout-summery-bottom">
                <dl class="cb-sum total">
                  <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Grand Total</dt>
                  <dt style="font-weight: bold" *ngIf="activeLang === 'si'">මුළු එකතුව</dt>
                  <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">மொத்த மொத்த தொகை</dt>

                  <dd *ngIf="selectPaymentType === 1">{{'CURRENCY.CURRENCY' | translate}}
                    <span>&nbsp;</span>
                    <span id="grandTotal">
                      {{paymentTotal| number:'2.2-2'}}
                    </span>
                  </dd>
                </dl>
              </div>
              <ngx-content-loading *ngIf="!detailsList">
                <svg:g ngx-code-preset></svg:g>
              </ngx-content-loading>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>

<!-- Card Payment Confirmation -->
<div aria-hidden="true" class="modal fade" data-backdrop="static" id="cardPaymentConfirmation" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0 payment-confirmation" role="document">
    <div class="modal-content">
      <button aria-label="Close" class="model-close-btn" data-dismiss="modal">
        <i aria-hidden="true" class="fa fa-times"></i>
      </button>

      <div class="row">
        <h3 style="padding: 30px 20px 0 60px;
        margin: 0;">{{'CHECKOUT.PAY_NOW.PAYMENT_CONFIRM' | translate}}</h3>
      </div>


      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="pca-col" style="margin-top: 50px;">
            <h5>{{'CHECKOUT.PAY_NOW.SHIPPING_ADDRESS' | translate}}</h5>
            <img alt="" src="../../../assets/images/icons/pa-icon.png"/>
            {{selectDeliveryCityName}},<br>
          </div>
          <div class="pca-col" style="margin-top: 97px;">
            <h5>{{'CHECKOUT.PAY_NOW.PAYMENT_METHOD' | translate}}</h5>
            <label *ngIf="selectPaymentType===1">{{'CHECKOUT.PAY_NOW.PAY_WITH_CARD' | translate}}</label><br>
            <label *ngIf="selectPaymentType===2">{{'CHECKOUT.PAY_NOW.PAY_NOW' | translate}}</label>
          </div>
          <p style="color: red;
             display: block;
             position: relative;
             padding-left: 70px;
             margin-bottom: 50px;
             font-size: 13px;
             line-height: 20px;">
            {{'CHECKOUT.PAY_NOW.ALERT_1' | translate}}
            {{checkoutExpiryTime}}
            {{'CHECKOUT.PAY_NOW.ALERT_2' | translate}}

          </p>
        </div>
        <div class="col-md-6 col-sm-12">

          <div class="psmrc-wp">
            <div class="psmrc-inner" style="height: 260px; overflow-y: scroll; margin-bottom: 8vh;">
              <div *ngFor="let item of selectItem" class="dcp-item">
                <div class="dcp-img">
                  <img *ngIf="item.productImages.length > 0" alt="" class="imageSize"
                       src="{{this.mainUrl.MAIN_URL_FILE + item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}"/>
                  <img *ngIf="item.productImages.length <= 0" alt="" class="imageSize"
                       src="assets/defaultBrokenImage.jpg"/>
                </div>
                <div class="dcp-info" style="padding-left: 20px;">
                  <span class="p-name">{{item.name}}</span>
                  <span class="p-qty">{{item.quantity}} x
                    {{'CURRENCY.CURRENCY' | translate}}{{item.unitPrice| number:'2.2-2'}}</span>
                </div>
              </div>

            </div>

            <div class="checkout-summery-bottom">
              <dl class="cb-sum total">
                <dt style="font-weight: bold">{{'CHECKOUT.TOTAL' | translate}}</dt>
                <dd style="font-weight: 700;">
                  {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{subTotalAmount| number:'2.2-2'}}
                </dd>
              </dl>
              <p style="padding-top: 10px; font-size: 13px;color: red;">
                {{'PAYMENT.h1' | translate}}
              </p>
            </div>
          </div>

          <button (click)="confirmPayment()" class="btn-login" data-dismiss="modal"
                  type="button">{{'CHECKOUT.PAY_NOW.CONFIRM' | translate}}
          </button>
          <a #paymentSuccess data-target="#paymentSuccess" data-toggle="modal" style="display: none"></a>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Riyawiru Payment Success -->
<div data-backdrop="static" data-keyboard="false" aria-hidden="true" class="modal fade npa-model"
     id="paymentSuccess" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-500 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <h3>{{'CHECKOUT.THANKS' | translate}}</h3>
          <h3 *ngIf="activeLang === 'en'">Your payment for Riyaviru transportation is received successfully</h3>
          <h3 *ngIf="activeLang === 'si'">රියවිරු ප්‍රවාහනය සදහා ඔබගේ ගෙවිම සාර්ථකයි</h3>
          <h3 *ngIf="activeLang === 'ta'">ரியாவிரு போக்குவரத்துக்கான உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது</h3>
        </div>
        <button [routerLink]="['/my-account/delivery-bid-request/wholesale']" aria-label="Close" class="btn-login"
                data-dismiss="modal" type="button">{{'CHECKOUT.CLOSE' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
