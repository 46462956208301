<div class="row">
    <div class="col-lg-12">
        <div class="pro-right-col blockdiv">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row pip-title">
                        <div class="col-8">
                            <h2>Bids from the Delivery Riders for your Purchase </h2>
                        </div>
                        <div class="col-12">
                            <div class="separator">&nbsp;</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: scroll;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Order Title</th>
                                            <th scope="col">No of Bids</th>
                                            <th scope="col">Bid Start Date </th>
                                            <th scope="col">Bid End Date</th>
                                            <th scope="col" class="text-right">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr data-toggle="modal" data-target="#orderDetails">
                                            <td>
                                                <b>Watermellon</b>&nbsp;
                                                100kg x LKR 350
                                            </td>
                                            <td>10</td>
                                            <td>05/05/2020</td>
                                            <td>05/05/2020</td>
                                            <td class="text-right">
                                                <img class="status-img"
                                                    src="../../../../../assets/images/pending-status-2.svg" />
                                            </td>
                                        </tr>
                                        <tr data-toggle="modal" data-target="#orderDetails">
                                            <td>
                                                <b>Watermellon</b>&nbsp;
                                                100kg x LKR 350
                                            </td>
                                            <td>10</td>
                                            <td>05/05/2020</td>
                                            <td>05/05/2020</td>
                                            <td class="text-right">
                                                <img class="status-img"
                                                    src="../../../../../assets/images/awarded-status-2.svg" />
                                            </td>
                                        </tr>
                                        <tr data-toggle="modal" data-target="#orderDetails">
                                            <td>
                                                <b>Watermellon</b>&nbsp;
                                                100kg x LKR 350
                                            </td>
                                            <td>10</td>
                                            <td>05/05/2020</td>
                                            <td>05/05/2020</td>
                                            <td class="text-right">
                                                <img class="status-img"
                                                    src="../../../../../assets/images/pending-status-2.svg" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- model -->
<div class="modal fade npa-model" id="orderDetails" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-1000 margin-0 profile-edit" role="document">
        <div class="modal-content">
            <button type="button" class="close text-right" data-dismiss="modal" (click)="selectNewProductOptions==0"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div style="width:100%;max-height: 80vh ; height: auto; overflow-y: scroll;padding: 15px 0px;">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Delivery Person </th>
                            <th scope="col">Vehicle Type</th>
                            <th scope="col">Total Bid</th>
                            <th scope="col">Possible Delivery Date</th>
                            <th scope="col" class="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>102453</td>
                            <td>
                                <img src="../../../../../assets/images/temp/profile.png" />
                                Kasun Sameera
                                &nbsp;
                                <img class="vehicle-status" src="../../../../../assets/images/be-awarded-status.svg" />
                            </td>
                            <td class="text-center">
                                <img class="vehicle-img" src="../../../../../assets/images/icons/three-wheler.png" />
                            </td>
                            <td class="font-weight-bold">LKR 600.00</td>
                            <td class="text-center">05/05/2020</td>
                            <td class="text-right">
                                <button class="btn btn-default-2" data-dismiss="modal"
                                    [routerLink]="['/order-request-bids/'+tenantValue]">View Bid</button>
                            </td>
                        </tr>
                        <tr>
                            <td>102453</td>
                            <td>
                                <img src="../../../../../assets/images/temp/profile.png" />
                                Kasun Sameera
                            </td>
                            <td class="text-center">
                                <img class="vehicle-img" src="../../../../../assets/images/icons/van.png" />
                            </td>
                            <td class="font-weight-bold">LKR 600.00</td>
                            <td class="text-center">05/05/2020</td>
                            <td class="text-right">
                                <button class="btn btn-default-2" data-dismiss="modal"
                                    [routerLink]="['/order-request-bids/'+tenantValue]">View Bid</button>
                            </td>
                        </tr>
                        <tr>
                            <td>102453</td>
                            <td>
                                <img src="../../../../../assets/images/temp/profile.png" />
                                Kasun Sameera
                            </td>
                            <td class="text-center">
                                <img class="vehicle-img" src="../../../../../assets/images/icons/truck.png" />
                            </td>
                            <td class="font-weight-bold">LKR 600.00</td>
                            <td class="text-center">05/05/2020</td>
                            <td class="text-right">
                                <button class="btn btn-default-2" data-dismiss="modal"
                                    [routerLink]="['/order-request-bids/'+tenantValue]">View Bid</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end  mt-5 mb-3">
                    <button class="btn btn-default next">Mark as awarded</button>
                </div>
            </div>
        </div>
    </div>
</div>