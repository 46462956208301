import {Component, OnDestroy, OnInit} from '@angular/core';
import {MainUrlService} from '../service/main-url.service';
import {Global} from '../service/global';
import {SystemSettingsService} from '../service/system-settings.service';
import {CoreService} from '../service/core.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {DeliveryService} from '../service/delivery.service';
import {InteractionService} from '../service/interaction.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  subscribeMailForm: FormGroup;

  logo: any;
  version: string;
  footerText: string;
  tenantValue: any;
  companyName: any;
  footerLogo: string;
  email: any;
  ACTIVE_PAYMENT_METHOD: any;

  data: any;

  showErrorEmail: boolean = false;
  facebookURL: any;
  twitterURL: any;
  instagramURL: any;

  activeLang: string = 'en';

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private route: ActivatedRoute,
              private toastr: ToastrService,
              private mainUrl: MainUrlService,
              private global: Global,
              private setting_service: SystemSettingsService,
              public core_service: CoreService,
              private deliveryService: DeliveryService,
              private interactionService: InteractionService,
              private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.reactiveLoginForm();
    this.version = environment.version;
    this.footerText = environment.footerText;
    this.setting_service.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          switch (setting.code) {


            case 'PAYCORP_CLIENT_ID':
              this.global.PAYCORP_CLIENT_ID = setting.value;
              localStorage.setItem('PAYCORP_CLIENT_ID', this.global.PAYCORP_CLIENT_ID);

              if (localStorage.getItem('PAYCORP_CLIENT_ID') !== this.global.PAYCORP_CLIENT_ID) {
                localStorage.setItem('PAYCORP_CLIENT_ID', this.global.PAYCORP_CLIENT_ID);
              } else {
                this.global.PAYCORP_CLIENT_ID = localStorage.getItem('PAYCORP_CLIENT_ID');
              }
              break;


            case 'BASE_LANGUAGE':
              this.global.BASE_LANGUAGE = setting.value;
              localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);

              if (localStorage.getItem('BASE_LANGUAGE') !== this.global.BASE_LANGUAGE) {
                localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);
              } else {
                this.global.BASE_LANGUAGE = localStorage.getItem('BASE_LANGUAGE');
              }

              break;

            case 'BASE_URL':
              this.global.BASE_URL = setting.value;
              localStorage.setItem('BASE_URL', this.global.BASE_URL);

              if (localStorage.getItem('BASE_URL') !== this.global.BASE_URL) {
                localStorage.setItem('BASE_URL', this.global.BASE_URL);
              } else {
                this.global.BASE_URL = localStorage.getItem('BASE_URL');
              }
              break;

            case 'CURRENCY_CODE':
              this.global.CURRENCY_CODE = setting.value;
              localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);

              if (localStorage.getItem('CURRENCY_CODE') !== this.global.CURRENCY_CODE) {
                localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);
              } else {
                this.global.CURRENCY_CODE = localStorage.getItem('CURRENCY_CODE');
              }
              break;

            case 'LOGO_WHITE':
              this.global.LOGO_WHITE = setting.value;
              this.logo = this.mainUrl.MAIN_URL_FILE + this.global.LOGO_WHITE + '/?X-TenantID=' + this.tenantValue;

              localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);

              if (localStorage.getItem('LOGO_WHITE') !== this.global.LOGO_WHITE) {
                localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);
              } else {
                this.global.LOGO_WHITE = localStorage.getItem('LOGO_WHITE');
              }
              break;

            case 'LOGO_BLACK':
              this.global.LOGO_BLACK = setting.value;
              this.footerLogo = this.mainUrl.MAIN_URL_FILE + this.global.LOGO_BLACK + '/?X-TenantID=' + this.tenantValue;

              localStorage.setItem('LOGO_BLACK', this.global.LOGO_BLACK);

              if (localStorage.getItem('LOGO_BLACK') !== this.global.LOGO_BLACK) {
                localStorage.setItem('LOGO_BLACK', this.global.LOGO_BLACK);
              } else {
                this.global.LOGO_BLACK = localStorage.getItem('LOGO_BLACK');
              }
              break;


            case 'FOOTER_INTRO':
              this.global.FOOTER_INTRO = setting.value;
              localStorage.setItem('FOOTER_INTRO', this.global.FOOTER_INTRO);

              if (localStorage.getItem('FOOTER_INTRO') !== this.global.FOOTER_INTRO) {
                localStorage.setItem('FOOTER_INTRO', this.global.FOOTER_INTRO);
              } else {
                this.global.FOOTER_INTRO = localStorage.getItem('FOOTER_INTRO');
              }
              break;

            case 'PRD_ADDR_CNAME':
              this.global.PRD_ADDR_CNAME = setting.value;
              localStorage.setItem('PRD_ADDR_CNAME', this.global.PRD_ADDR_CNAME);
              this.companyName = this.global.PRD_ADDR_CNAME;

              if (localStorage.getItem('PRD_ADDR_CNAME') !== this.global.PRD_ADDR_CNAME) {
                localStorage.setItem('PRD_ADDR_CNAME', this.global.PRD_ADDR_CNAME);
              } else {
                this.global.PRD_ADDR_CNAME = localStorage.getItem('PRD_ADDR_CNAME');
              }
              break;

            case 'SLIDER_TITLE':
              this.global.SLIDER_TITLE = setting.value;
              localStorage.setItem('SLIDER_TITLE', this.global.SLIDER_TITLE);

              if (localStorage.getItem('SLIDER_TITLE') !== this.global.SLIDER_TITLE) {
                localStorage.setItem('SLIDER_TITLE', this.global.SLIDER_TITLE);
              } else {
                this.global.SLIDER_TITLE = localStorage.getItem('SLIDER_TITLE');
              }
              break;

            case 'SLIDER1':
              this.global.SLIDER1 = setting.value;
              localStorage.setItem('SLIDER1', this.global.SLIDER1);
              if (localStorage.getItem('SLIDER1') !== this.global.SLIDER1) {
                localStorage.setItem('SLIDER1', this.global.SLIDER1);
              } else {
                this.global.SLIDER1 = localStorage.getItem('SLIDER1');
              }
              break;

            case 'SLIDER2':
              this.global.SLIDER2 = setting.value;
              localStorage.setItem('SLIDER2', this.global.SLIDER2);

              if (localStorage.getItem('SLIDER2') !== this.global.SLIDER2) {
                localStorage.setItem('SLIDER2', this.global.SLIDER2);
              } else {
                this.global.SLIDER2 = localStorage.getItem('SLIDER2');
              }
              break;

            case 'SLIDER3':
              this.global.SLIDER3 = setting.value;
              localStorage.setItem('SLIDER3', this.global.SLIDER3);

              if (localStorage.getItem('SLIDER3') !== this.global.SLIDER3) {
                localStorage.setItem('SLIDER3', this.global.SLIDER3);
              } else {
                this.global.SLIDER3 = localStorage.getItem('SLIDER3');
              }
              break;


            case 'SLIDER4':
              this.global.SLIDER4 = setting.value;
              localStorage.setItem('SLIDER4', this.global.SLIDER4);

              if (localStorage.getItem('SLIDER4') !== this.global.SLIDER4) {
                localStorage.setItem('SLIDER4', this.global.SLIDER4);
              } else {
                this.global.SLIDER4 = localStorage.getItem('SLIDER4');
              }
              break;

            case 'ACTIVE_PAYMENT_METHOD':
              this.global.ACTIVE_PAYMENT_METHOD = setting.value;
              this.ACTIVE_PAYMENT_METHOD = setting.value;
              break;

            case 'FACEBOOK_URL':
              this.global.FACEBOOK_URL = setting.value;
              this.facebookURL = setting.value;
              break;

            case 'TWITTER_URL':
              this.global.TWITTER_URL = setting.value;
              this.twitterURL = setting.value;
              break;

            case 'INSTA_LINK':
              this.global.INSTAGRAM_URL = setting.value;
              this.instagramURL = setting.value;
              break;

          }
        }

      }

    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  reactiveLoginForm() {
    this.subscribeMailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.validationHandlerService.emailValidation())]]
    });
  }

  get f() {
    return this.subscribeMailForm.controls;
  }


  sendEmail() {
    if (this.checkEmail(this.email)) {

      this.showErrorEmail = false;
      let body = {
        'email': this.email
      };

      this.core_service.subscribeService(body).subscribe(result => {
        if (result) {
          this.email = '';
          this.toastr.success(this.validationHandlerService.subscribeMessage(sessionStorage.getItem('LANG')));
        } else {
          this.email = '';
        }

      }, () => {
        this.email = '';

      });
    } else {
      this.showErrorEmail = true;

    }
  }

  checkEmail(value) {
    let reg = new RegExp(this.validationHandlerService.emailValidation());
    return reg.test(value);
  }

  submitForm() {
    this.sendEmail();
  }

  checkOrderStatus() {
    Swal.fire({
      title: this.validationHandlerService.checkStatus(sessionStorage.getItem('LANG')),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG')),
      confirmButtonText: this.validationHandlerService.checkButton(sessionStorage.getItem('LANG')),
      showLoaderOnConfirm: true,
      preConfirm: (check) => {
        if (check.length > 0) {

          let a = document.createElement('a');
          a.href = this.global.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + check;
          a.click();
        } else {
          Swal.showValidationMessage(
            this.validationHandlerService.checkTransactionID(sessionStorage.getItem('LANG')),
          );
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }
}
