import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MainUrlService {

  constructor() {
  }

  MAIN_URL_PRODUCT = environment.proxyUrl + 'productservice';
  MAIN_URL_FILE = environment.proxyUrl + 'fileservice/file/';
  FILE_UPLOAD = environment.proxyUrl + 'fileservice/upload/';
  MAIN_URL_CORE = environment.proxyUrl + 'coreservice';
  MAIN_URL_PAYMENT = environment.proxyUrl + 'paymentservice';
  MAIN_URL_DELIVERY = environment.proxyUrl + 'deliveryservice';
  MAIN_URL_AUTH = environment.proxyUrl + 'authservice';
  MAIN_URL_TENANT = environment.proxyUrl + 'tenantservice';
  MAIN_URL_ONBOARDING = environment.proxyUrl + 'agriservice/v1';
  MAIN_URL_DYNAMIC_QR_CODE = environment.proxyUrl + 'dynamicqrservice';
  MAIN_URL_REPORT = environment.proxyUrl + 'reportservice';
  MAIN_URL_SMS = environment.proxyUrl + 'smsservice';
  MAIN_URL_RIYAWIRU = environment.riyaviruUrl;

}
