import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GetProductItemDTO {

  public item: any;

  getItem(): any {
    return this.item;
  }

  setItem(item: any) {
    this.item = item;
  }
}
