import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-transport-providers',
  templateUrl: './transport-providers.component.html',
  styleUrls: ['./transport-providers.component.css']
})
export class TransportProvidersComponent implements OnInit {

  tenantValue: any;
  constructor() { }

  ngOnInit() {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

}
