import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../../common/common.service';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {InteractionService} from '../../../../service/interaction.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {MainUrlService} from '../../../../service/main-url.service';
import Swal from 'sweetalert2';
import {TransportDetailsDTO} from '../../../../dto/TransportDetailsDTO';
import {DeliveryDTO} from '../../../../dto/deliveryDTO';
import {CartService} from '../../../../service/cart.service';
import {UserTokenDTO} from '../../../../dto/userTokenDTO';
import {CoreService} from '../../../../service/core.service';
import {RiyawiruService} from '../../../../service/riyawiru.service';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';

@Component({
  selector: 'app-buyer-order-request-bids',
  templateUrl: './buyer-order-request-bids.component.html',
  styleUrls: ['./buyer-order-request-bids.component.css']
})
export class BuyerOrderRequestBidsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['bidder', 'bidAmount', 'fillQty', 'bidPlacementDate', 'status', 'action1', 'action'];
  tenantValue: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  rate: any;

  productName: any;
  unitType: any;
  qty: any;
  bidUsername: any;
  bidValue: any;
  bidDate: any;
  productImage: any;
  bidId: any;

  @ViewChild('success', {static: false}) successButton;
  @ViewChild('decline', {static: false}) rejectButton;
  @ViewChild('clickActionModal', {static: false}) clickActionModal;
  sku: any;
  activeLang: any;

  transportList: any[];
  railwayList: any;
  transportID: any;
  dropStation: any;
  isSellerFixed = false;
  selectedVehicleId: any;
  deliveryOptList: any;


  riyaviruModalData: any;
  riyawiruVehicleTypes: any;
  @ViewChild('transportModal', {static: false}) transportModal;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;
  expectationPrice: any;
  isRequiredExpectation: boolean = false;
  isPickVehicle: boolean = false;

  dto = new DeliveryDTO;
  delivery_price: any;
  isEmptyReviewList: boolean = false;
  delivery_priceFixed: any;
  delivery_desc: any;
  station: any;
  seller: any;
  sellerMobile: any;
  sellerName: any;
  sellerLocation: any;
  isTrain = false;
  isSeller = false;
  imgUrl: any = 'assets/avatar.jpg';
  isBuyer = false;


  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              private router: Router,
              private coreService: CoreService,
              private cartService: CartService,
              private validationHandlerService: ValidationHandlerService,
              private riyawiruService: RiyawiruService,
              private http: HttpClient,
              private interactionService: InteractionService,
              private settingsService: SystemSettingsService,
              private route: ActivatedRoute,
              public mainUrl: MainUrlService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');

    this.interactionService.headerRefresh$.subscribe(message => {
      if (message === 'True') {
        this.activeLang = sessionStorage.getItem('LANG');
        this.loadOrderRequestProductBids(this.sku);
      }
    });
  }

  ngOnInit() {
    this.getRiyawiruVehicleTypes();
    this.route.queryParams.subscribe(params => {
      this.sku = params['sku'];
      this.commonService.processing();
      this.loadOrderRequestProductBids(params['sku']);
    });
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

  loadOrderRequestProductBids(sku) {
    this.dataSource = new MatTableDataSource();
    this.productService.myBidOrderRequestProductDetails(sku).subscribe(getMyBidProducts => {
      let arr = [];
      for (let item of getMyBidProducts) {
        arr.push({
          'id': item.id,
          'bidder': item.bidder,
          'bidderAddress': item.bidderAddress,
          'sku': item.orderRequest.sku,
          'productDetails': item.orderRequest,
          'row': item,
          'bidAmount': item.bidAmount,
          'fillQty': item.fillQuantity,
          'bidPlacementDate': item.created,
          'status': item.status
        });
      }

      this.dataSource = new MatTableDataSource(arr);
      this.dataSource.paginator = this.paginator;
      Swal.close();

    }, () => {
      Swal.close();
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clickRiyaviru(sku: any) {
    this.transportOptionsClose.nativeElement.click();
    this.selectedOption = sku + 1;
  }

  riyawiruModalShow() {
    this.transportModal.nativeElement.click();
  }

  getRiyawiruVehicleTypes() {
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.fetchAllVehicleTypes(createToken['access_token']).subscribe(result => {
        this.riyawiruVehicleTypes = result;
      });
    });
  }

  selectedOption = 0;
  encryptInfoTransport_id: any;
  encryptInfoRiyawiruList: any;
  encryptInfoProductDetails: any;

  buyNow() {
    if (this.selectedOption > 0) {
      this.dto.sku = this.sku;
      this.dto.trnId = this.selectedOption;
      this.prdTransportList.push(this.dto);
    }
    if (this.prdTransportList.length > 0) {

      let isLoggedIn = localStorage.getItem('isLogged');
      if (isLoggedIn === 'True') {

        this.encryptInfoTransport_id = encodeURIComponent(JSON.stringify(this.prdTransportList));
        this.encryptInfoRiyawiruList = encodeURIComponent(JSON.stringify(this.riyawiruSelectedVehicleType));
        this.encryptInfoProductDetails = encodeURIComponent(JSON.stringify(this.productDetails));

        this.router.navigate(['/checkout/' + this.tenantValue], {
          queryParams: {
            sku: this.sku,
            orderRequest: true,
            riyawiruList: this.encryptInfoRiyawiruList,
            transport_id: this.encryptInfoTransport_id,
            productDetails: this.encryptInfoProductDetails
          }
        });


      } else {
        localStorage.setItem('isLogged', 'False');
        this.interactionService.popUpLoginModal('false');
      }

    } else {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: this.validationHandlerService.validateDeliveryMethod(sessionStorage.getItem('LANG')),
        showConfirmButton: false,
        timer: 1500
      });
    }

  }

  selectVehicleType(id, sku) {
    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.vehicleType = id;
      }
    }
    this.selectedVehicleId = id;
    this.isPickVehicle = false;
    this.selectedId = id;
  }

  selectedItem: any;
  selectedId: any;

  isSelectedItem(item) {
    return this.selectedItem === item;
  }

  sendBidRequest(sku: any, expectationPrice: any) {

    if (this.selectedVehicleId === undefined) {
      this.isPickVehicle = true;
      return;
    }
    this.isPickVehicle = false;

    if (expectationPrice === undefined || expectationPrice === 0) {
      this.isRequiredExpectation = true;
      return;
    }
    this.isRequiredExpectation = false;

    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.price = expectationPrice;
      }
    }
    this.expectationPrice = undefined;
    this.selectedVehicleId = undefined;
    this.closeRiyawiruModal.nativeElement.click();
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  bidStatus: any;
  requiredQty: any;
  deliveryName: any;
  address: any;
  bidderAddress: any;
  deliveryCharge: any = 0;

  passProductDetails(row, username, bidderAddress, bidValue, date, id, status, fillQty, data, sku, value) {
    this.productName = row.product.name;
    this.address = row.product.address;
    this.unitType = row.product.unit.unit;
    this.qty = fillQty;
    this.requiredQty = row.requiredQty;
    this.deliveryName = data.deliveryOptions;

    let categoryName;
    categoryName = row.product.subCategory.mainCategory.commonIndex.description;

    if (categoryName === 'Farming Products & Processed Food') {
      this.productImage = 'assets/images/Thumbnails/Processed Products.jpg';
    } else if (categoryName === 'Spices & Cash Crops') {
      this.productImage = 'assets/images/Thumbnails/Spices.jpg';
    } else if (categoryName === 'Services & Information') {
      this.productImage = 'assets/images/Thumbnails/Services.jpg';
    } else if (categoryName === 'Plants & Seeds') {
      this.productImage = 'assets/images/Thumbnails/Seeds.jpg';
    } else if (categoryName === 'Ayurvedic & Herbal Products') {
      this.productImage = 'assets/images/Thumbnails/Ayurveda.jpg';
    } else if (categoryName === 'Equipment and Tools') {
      this.productImage = 'assets/images/Thumbnails/Equipments.jpg';
    } else {
      this.productImage = 'assets/images/Thumbnails/' + categoryName + '.jpg';
    }


    this.bidUsername = username;
    this.bidderAddress = bidderAddress;
    this.bidValue = bidValue;
    this.bidDate = date;
    this.bidId = id;
    this.bidStatus = status;
    if (!value) {
      this.checkout(data);
    }
  }

  statusChange(status: string) {
    this.loading();
    if (status === 'award') {
      this.productService.awardOrderRequestBid(this.bidId).subscribe(isAward => {
        if (isAward) {
          this.commonService.processing();
          this.loadOrderRequestProductBids(this.sku);
          this.successButton.nativeElement.click();
          Swal.close();
        }
      });
    } else {
      this.productService.declineOrderRequestBid(this.bidId).subscribe(isDecline => {
        if (isDecline) {
          this.commonService.processing();
          this.loadOrderRequestProductBids(this.sku);
          this.rejectButton.nativeElement.click();
          Swal.close();
        }
      });
    }
  }

  prdTransportList = [];
  riyawiruSelectedVehicleType = [];
  prdImg: any;
  sellerUsername: any;
  productDetails: any;
  clickedRiyawiruOption: boolean = false;

  checkVal($event, sku, val) {

    this.clickedRiyawiruOption = val === 1;

    for (let delivery of this.prdTransportList) {
      if (sku === delivery.sku) {
        let index = this.prdTransportList.indexOf(delivery);
        if (index !== -1) {
          this.prdTransportList.splice(index, 1);
        }
      }
    }

    for (let delivery of this.riyawiruSelectedVehicleType) {
      if (sku === delivery.sku) {
        let index = this.riyawiruSelectedVehicleType.indexOf(delivery);
        if (index !== -1) {
          this.riyawiruSelectedVehicleType.splice(index, 1);
        }
      }
    }

    this.prdTransportList.push({
      'sku': sku,
      'trnId': val
    });

    this.riyawiruSelectedVehicleType.push({
      'sku': sku,
      'value': 0,
      'paymentMethod': 'Cash On Delivery',
      'vehicleType': null
    });

  }

  checkout(productDetails) {
    this.productDetails = productDetails;
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.transportModal.nativeElement.click();
      this.isBuyer = false;
      this.isSeller = false;
      this.isSellerFixed = false;
      this.isTrain = false;
      this.cartService.fetchOrderRequestTransportDetailsByBidId(this.productDetails.id).subscribe(
        (data: TransportDetailsDTO) => {
          this.sellerMobile = data.sellerMobile;
          this.sellerName = data.sellerName;
          this.prdImg = data.imgUrl;
          this.sellerUsername = data.seller;
          this.deliveryOptList = data.deliveryOpt;
          if (data.deliveryOpt !== null) {
            for (let opt of data.deliveryOpt) {
              this.userRoleService.fetchUserDetails(data.seller).subscribe(result => {
                this.seller = result.name;
              });
              if (opt.deliveryOpt === '2') {
                this.isBuyer = true;
              }
              if (opt.deliveryOpt === '3') {
                this.getUser();
                this.isSeller = true;
                this.delivery_price = opt.deliveryCharges;
              }
              if (opt.deliveryOpt === '4') {
                this.isTrain = true;
                this.station = opt.station;
              }
              if (opt.deliveryOpt === '5') {
                this.isSellerFixed = true;
                this.delivery_priceFixed = opt.deliveryCharges;
                this.delivery_desc = opt.deliveryDesc;
              }
            }
          }
          this.productService.fetchOrderRequestGetDefaultPickupAddress(data.seller).subscribe(sellerAddress => {
            this.sellerLocation = sellerAddress?.addressOne + ' ' + sellerAddress?.addressTwo + ' ' + sellerAddress?.cities?.nameEN;
          });

        }
      );
    } else {
      localStorage.setItem('isLogged', 'False');
      this.interactionService.popUpLoginModal('false');
    }
  }

  userTokenDTO = new UserTokenDTO;

  getUser() {
    this.userTokenDTO.userName = this.sellerUsername;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'assets/avatar.jpg';
      }
    }, () => {
      this.imgUrl = 'assets/avatar.jpg';
    });
  }


  checkExpectationPrice(value: any) {
    if (value <= 0) {
      this.expectationPrice = undefined;
    }
  }

}
