import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {


  private _headerRefreshSource = new Subject<string>();
  headerRefresh$ = this._headerRefreshSource.asObservable();

  private _loginFormSource = new Subject<string>();
  loginForm$ = this._loginFormSource.asObservable();

  private _updateCheckoutItemForm = new Subject<string>();
  checkoutItemForm$ = this._updateCheckoutItemForm.asObservable();

  private _updateActiveLang = new Subject<boolean>();
  activeLang$ = this._updateActiveLang.asObservable();

  private _updateSignUpForm = new Subject<boolean>();
  updateSignUpForm$ = this._updateSignUpForm.asObservable();

  private _updateSignUpCompanyForm = new Subject<boolean>();
  updateSignUpCompanyForm$ = this._updateSignUpCompanyForm.asObservable();

  private _updateSearchKeyWord = new Subject<string>();
  updateSearchBar = this._updateSearchKeyWord.asObservable();

  private _updateRemarkTables = new Subject<boolean>();
  updateRemark = this._updateRemarkTables.asObservable();

  private _redirectHomePage = new Subject<boolean>();
  redirect = this._redirectHomePage.asObservable();

  private _hideHomePageRegistrationButtons = new Subject<boolean>();
  registrationButtons = this._hideHomePageRegistrationButtons.asObservable();

  private _updateProductListingTable = new Subject<boolean>();
  updateProductListingTable = this._updateProductListingTable.asObservable();

  private _agentFarmerProductDetails = new Subject<boolean>();
  agentFarmerProductDetails = this._agentFarmerProductDetails.asObservable();

  private _updateProfileInfo = new Subject<boolean>();
  updateProfileInfo = this._updateProfileInfo.asObservable();

  private _passingFarmerUsername = new Subject<boolean>();
  passingFarmerUsername = this._passingFarmerUsername.asObservable();

  private _updateAgentFarmers = new Subject<boolean>();
  updateAgentFarmers = this._updateAgentFarmers.asObservable();

  private _updateReviews = new Subject<boolean>();
  updateReviews = this._updateReviews.asObservable();

  private _updateBankStatus = new Subject<boolean>();
    updateBankStatus = this._updateBankStatus.asObservable();

  private _openSellAProduct = new Subject<boolean>();
  openSellAProduct = this._openSellAProduct.asObservable();

  private _updateCartQty = new Subject<boolean>();
  updateCart = this._updateCartQty.asObservable();

  private _manageUsernames = new Subject<boolean>();
  usernames = this._manageUsernames.asObservable();

  private _resetSellProductButton = new Subject<boolean>();
  sellProductButton = this._resetSellProductButton.asObservable();

  private _manageSellButtonHomePage = new Subject<boolean>();
  sellButtonHomepage = this._manageSellButtonHomePage.asObservable();

  private _manageSellAProductButton = new Subject<boolean>();
  sellAProductButton = this._manageSellAProductButton.asObservable();

  constructor() {
  }

  openSellAProductModal(status: boolean) {
    this._manageSellAProductButton.next(status);
  }

  updateCartQty(message: any) {
    this._updateCartQty.next(message);
  }

  updateSellButtonStatus(message: any) {
    this._manageSellButtonHomePage.next(message);
  }

  updateSellProductButton(message: any) {
    this._resetSellProductButton.next(message);
  }

  storeUsernames(message: any) {
    this._manageUsernames.next(message);
  }

  updateSellAProduct(message: any) {
    this._openSellAProduct.next(message);
  }

  setBankStatus(message: any) {
    this._updateBankStatus.next(message);
  }

  setUpdateReviews(message: any) {
    this._updateReviews.next(message);
  }

  updateAgentFarmersList(message: boolean) {
    this._updateAgentFarmers.next(message);
  }

  setFarmerUsername(message: any) {
    this._passingFarmerUsername.next(message);
  }

  setProfileInfo(message: boolean) {
    this._updateProfileInfo.next(message);
  }

  setAgentFarmerProductDetails(list: any) {
    this._agentFarmerProductDetails.next(list);
  }

  updateProductListingList(message: boolean) {
    this._updateProductListingTable.next(message);
  }

  updateRegistrationButtons(message: boolean) {
    this._hideHomePageRegistrationButtons.next(message);
  }

  redirectHomePage(message: boolean) {
    this._redirectHomePage.next(message);
  }

  updateTables(message: boolean) {
    this._updateRemarkTables.next(message);
  }

  callBackHeader(message: string) {
    this._headerRefreshSource.next(message);
  }

  updateKeyWord(message: string) {
    this._updateSearchKeyWord.next(message);
  }

  popUpLoginModal(message: string) {
    this._loginFormSource.next(message);
  }

  refreshCart(message: string) {
    this._updateCheckoutItemForm.next(message);
  }

  activeLang(message: boolean) {
    this._updateActiveLang.next(message);
  }

  callSignUpBackButton(message: boolean) {
    this._updateSignUpForm.next(message);
  }

  callSignUpBackButtonCompany(message: boolean) {
    this._updateSignUpCompanyForm.next(message);
  }
}
