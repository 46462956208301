import {Component, OnInit} from '@angular/core';
import {Global} from '../service/global';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemSettingsService} from '../service/system-settings.service';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.css']
})
export class MaintenanceModeComponent implements OnInit {
  tenantValue: any;
  MAINTENANCE_MODE_DESCRIPTION: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private setting_service: SystemSettingsService,
              private global: Global,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.MAINTENANCE_MODE_DESCRIPTION = this.global.MAINTENANCE_MODE_DESCRIPTION;
    this.route.queryParams.subscribe(() => {
      this.tenantValue = this.route.snapshot.paramMap.get('tenant_id');
    });
    this.fetchAll();
  }

  action() {
    this.fetchAll();
    if (this.global.MAINTENANCE_MODE.trim() == 'false') {
      this.router.navigate(['/home/' + this.tenantValue]);
    }
  }


  fetchAll() {
    this.setting_service.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          switch (setting.code) {
            case 'MAINTENANCE_MODE':
              this.global.MAINTENANCE_MODE = setting.value;
              if (this.global.MAINTENANCE_MODE === 'true') {
                this.router.navigate(['/maintenanceMode/' + this.tenantValue]);
              } else {
                this.router.navigate(['/home/' + this.tenantValue]);
              }
              break;


            case 'MAINTENANCE_MODE_DESCRIPTION':
              this.global.MAINTENANCE_MODE_DESCRIPTION = setting.value;
              localStorage.setItem('MAINTENANCE_MODE_DESCRIPTION', this.global.MAINTENANCE_MODE_DESCRIPTION);

              if (localStorage.getItem('MAINTENANCE_MODE_DESCRIPTION') !== this.global.MAINTENANCE_MODE_DESCRIPTION) {
                localStorage.setItem('MAINTENANCE_MODE_DESCRIPTION', this.global.MAINTENANCE_MODE_DESCRIPTION);
              } else {
                this.global.MAINTENANCE_MODE_DESCRIPTION = localStorage.getItem('MAINTENANCE_MODE_DESCRIPTION');
              }
              break;
          }
        }

      }


    });
  }

}
