import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../service/product.service';
import {MainUrlService} from '../service/main-url.service';
import {CartService} from '../service/cart.service';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {Global} from '../service/global';
import {SystemSettingsService} from '../service/system-settings.service';
import {InteractionService} from '../service/interaction.service';
import {SignUpService} from '../service/signUp.service';
import {LoginService} from '../service/login.service';
import {UserRoleService} from '../service/user-role.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {CommonService} from '../common/common.service';
import {Md5} from 'ts-md5';
import {SmsService} from '../service/sms.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  submittedLoginForm = false;
  submittedSignUpForm = false;
  loginForm: FormGroup;
  signUpForm: FormGroup;


  allCategory: any;
  selectItem: any;
  totalAmount: any;
  cartItemCount: any;
  isEmptyCart = 0;
  spinnerTag = 0;
  isLog: boolean = false;
  currency;
  searchLoading = false;
  wrongName = false;
  totalDiscount: any;
  tenantValue: any;
  BASE_URL: string;
  loginUsername: any;
  loginPassword: any;
  attributeColorCode: any;
  language: any = 'English';
  searchText: any;
  showUsername: any;
  imgUrl: any;
  activeLang: string = 'en';
  userName: any;

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;
  session: any;

  @ViewChild('optModal2', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;
  @ViewChild('loginIcon', {static: true}) openLoginForm: ElementRef<HTMLDivElement>;


  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private global: Global,
              private toastr: ToastrService,
              private router: Router,
              private cookie: CookieService,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cartService: CartService,
              private route: ActivatedRoute,
              private setting_service: SystemSettingsService,
              private interactionService: InteractionService,
              private loginService: LoginService,
              private signUpService: SignUpService,
              private userRoleService: UserRoleService,
              private formBuilder: FormBuilder,
              private validationHandlerService: ValidationHandlerService,
              private _translateService: TranslateService,
              private commonService: CommonService,
              private smsService: SmsService
  ) {

    this.activeLang = sessionStorage.getItem('LANG');
    if (this.activeLang !== null) {
      this.language = this.activeLang;
      switch (this.activeLang) {
        case 'en':
          this.language = 'English';

          break;
        case 'si':
          this.language = 'සිංහල';

          break;
        case 'ta':
          this.language = 'தமிழ்';

          break;
      }
    } else {
      this.language = 'English';
    }
  }

  ngOnInit() {
    this.loadCategories();
    this.reactiveLoginForm();
    this.reactiveSignUpForm();


    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    this._common();

    this.interactionService.headerRefresh$.subscribe(message => {
      if (message === 'True') {
        this.common();
      }
    });
    this.interactionService.loginForm$.subscribe(message => {
      if (message === 'true') {
        this.isLog = false;
      } else {
        let temp = document.getElementById('loginIcon');
        if (temp !== null) {
          temp.click();
        }
      }
    });

    this.interactionService.checkoutItemForm$.subscribe(reCall => {
      if (reCall === 'true') {
        this._common();
      }
    });

    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn !== null) {
      this.isLog = isLoggedIn === 'True';
    }

    this.interactionService.loginForm$.subscribe(message => {
      if (message === 'true') {
        this.isLog = false;
      } else {
        let temp = document.getElementById('loginIcon');
        if (temp !== null) {
          temp.click();
        }
      }
    });


    this.interactionService.updateSearchBar.subscribe(message => {
      this.searchText = message;
    });

    this.interactionService.updateCart.subscribe(message => {
      if (message) {
        this.common();
      }
    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  useLanguage(language, value) {
    this.language = value;
    sessionStorage.setItem('LANG', language);
    this.interactionService.activeLang(true);
    this._translateService.use(language);
  }

  reactiveLoginForm() {
    this.loginForm = this.formBuilder.group({
      loginUsername: ['', Validators.required]
    });
  }

  reactiveSignUpForm() {
    this.signUpForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.mobileNumberValidation())]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.validationHandlerService.emailValidation())]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      rePassword: ['', Validators.required]
    }, {
      validator: this.validationHandlerService.MustMatch('password', 'rePassword')
    });
  }

  get f1() {
    return this.loginForm.controls;
  }

  get f2() {
    return this.signUpForm.controls;
  }


  _common() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        localStorage.setItem('USER_ROLE', whoAmIDetails.roles[0].name);
        localStorage.setItem('WHO_AM_I_DETAILS', JSON.stringify(whoAmIDetails));
        this.showUsername = whoAmIDetails['name'];
        this.userName = whoAmIDetails['email'];
        this.cartService.findCartByUsername(this.showUsername, this.tenantValue).subscribe(() => {
          this.common();
        });
        let token = localStorage.getItem('$Token');
        let body = {
          'userName': this.showUsername,
          'token': token
        };
        this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
          if (userResult !== null) {
            let hashStr;
            if (userResult['email'] !== null) {
              hashStr = Md5.hashStr(userResult['email']);
            } else {
              hashStr = Md5.hashStr('');
            }
            this.imgUrl = userResult.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + userResult.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
          }
        });

      });

    }
    this.viewCart();
  }

  loadCategories() {
    this.productService.getActiveProfileCode().subscribe(profileCode => {
      this.productService
        .getAllMainCategoryByProfileId(profileCode.code)
        .subscribe(result => {
          this.allCategory = result;
        });
    });
  }


  common() {
    let isLoggedIn = localStorage.getItem('isLogged');
    let cartID = localStorage.getItem('cartID');
    if (isLoggedIn === 'True') {

      if (cartID !== null) {
        this.productService.cartMerge(this.showUsername, cartID, this.tenantValue).subscribe(() => {
          this.showCartByUsername();
        });

      } else {
        this.showCartByUsername();
      }
    } else {
      this.spinnerTag = 1;
      this.isEmptyCart = 0;


      if (cartID !== null) {
        this.selectItem = '';
        this.cartService.showCartByOwner(cartID, this.tenantValue).subscribe(result => {
          if (Object.keys(result).length > 0) {

            this.isEmptyCart = 0;
            this.spinnerTag = 0;
            this.selectItem = result;

            this.cartItemCount = Object.keys(result).length;
            localStorage.setItem('cartItemCount', this.cartItemCount);
            this.cartService.getPricingByCartID(cartID, this.tenantValue).subscribe(getPricing => {
              if (getPricing) {
                this.totalDiscount = result['totalDiscount'];
                this.totalAmount = result['grandTotal'];
              }
            });
          } else {
            this.selectItem = '';
            this.spinnerTag = 0;
            this.isEmptyCart = 1;
            localStorage.setItem('cartItemCount', '0');
            this.cartItemCount = '0';
          }
        }, error => {
          Swal.close();
          console.log(error);
        });
      } else {
        this.spinnerTag = 0;
        this.isEmptyCart = 1;
        localStorage.setItem('cartItemCount', '0');
        this.cartItemCount = 0;
      }

    }
  }

  viewCart() {
    this.common();
  }

  removeItem(productId) {
    let cartID = localStorage.getItem('cartID');
    let isLoggedIn = localStorage.getItem('isLogged');

    if (isLoggedIn === 'True') {
      this.cartService.deleteItemFromCartUser(this.userName, productId, this.tenantValue).subscribe(res => {
        if (res.success) {
          this.toastr.success(this.validationHandlerService.removedMessage(sessionStorage.getItem('LANG')));
          this.common();
        }
      });

    } else {
      this.cartService.deleteItemFromCartOwner(cartID, productId, this.tenantValue).subscribe(res => {
        if (res.success) {
          this.toastr.success(this.validationHandlerService.removedMessage(sessionStorage.getItem('LANG')), '', {});
          this.common();
        } else {
          this.toastr.error('Oops!');
        }
      }, () => {
        this.toastr.error('Oops!');
      });
    }
    this.interactionService.refreshCart('true');

  }

  logout() {
    Swal.fire({
      title: this.validationHandlerService.logOutMessage(sessionStorage.getItem('LANG')),
      text: this.validationHandlerService.logOutMessages(sessionStorage.getItem('LANG')),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG')),
      confirmButtonText: this.validationHandlerService.logOutMessage(sessionStorage.getItem('LANG'))
    }).then((result) => {
      if (result.value) {
        localStorage.setItem('isLogged', 'False');
        localStorage.removeItem('isLogged');
        localStorage.removeItem('cartID');
        localStorage.removeItem('Access-Token');
        localStorage.removeItem('USERNAME');
        localStorage.removeItem('USER_ROLE');
        this.interactionService.updateSellButtonStatus(true);
        this.router.navigate(['/products/' + this.tenantValue]).then(() => {
          this.router.navigate(['/home/' + this.tenantValue]);
        });
      }
    });
  }

  showCartByUsername() {
    this.spinnerTag = 1;
    this.isEmptyCart = 0;
    this.selectItem = '';
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.cartService.showCartByUsername(whoAmIDetails['email'], this.tenantValue).subscribe(result => {
        if (Object.keys(result).length > 0) {
          this.isEmptyCart = 0;
          this.spinnerTag = 0;
          this.selectItem = result;
          this.cartItemCount = Object.keys(result).length;
          localStorage.setItem('cartItemCount', this.cartItemCount);

          let _getPricingBody = {
            'userName': whoAmIDetails['email'],
            'couponCode': null,
            'startCity': null,
            'endCity': null
          };

          this.cartService.getPricingByUsername(_getPricingBody).subscribe(getPricingByUsername => {
            if (getPricingByUsername) {
              this.totalDiscount = getPricingByUsername['totalDiscount'];
              this.totalAmount = getPricingByUsername['grandTotal'];
            }
          });

        } else {
          this.selectItem = '';
          this.spinnerTag = 0;
          this.isEmptyCart = 1;
          localStorage.setItem('cartItemCount', '0');
          this.cartItemCount = '0';
        }
      }, error => {
        this.selectItem = '';
        this.spinnerTag = 0;
        this.isEmptyCart = 1;
        localStorage.setItem('cartItemCount', '0');
        this.cartItemCount = '0';
        Swal.close();
        console.log(error);
      });
    });

  }

  searchBar() {
    if (this.searchText !== undefined) {
      sessionStorage.setItem('$search', this.searchText);

      this.router.navigate(['/products/' + this.tenantValue], {queryParams: {search: this.searchText}});

    } else {
      this.router.navigate(['/products/' + this.tenantValue]);
    }
  }

  name: any;
  mobile: any;
  email: any;
  password: any;
  rePassword: any;

  userLogin() {

    if (this.loginUsername !== undefined) {
      this.processing();
      localStorage.removeItem('$Token');

      this.isLoading = true;
      let _checkOTP = {
        'username': this.loginUsername,
        'otp': this.OTP_CODE
      };
      this.loginService.checkOtp(_checkOTP).subscribe(token => {

        if (token !== null) {
          this.isInvalid = false;
          this.isLoading = false;
          this.closeModal.nativeElement.click();
          this.closeOTPModal();
          Swal.close();
          this.isLog = true;
          localStorage.setItem('$Token', token['accessToken']);
          localStorage.setItem('isLogged', 'True');
          document.getElementById('modal-dismiss').click();
          this.common();
          this.interactionService.refreshCart('true');
          this.interactionService.redirectHomePage(true);
          this.interactionService.updateRegistrationButtons(true);
          this.commonService.processing();
          this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
            localStorage.setItem('USER_ROLE', whoAmIDetails.roles[0].name);
            this.interactionService.updateSellButtonStatus(true);
            this.fetchPrivileges(whoAmIDetails['id']);
            for (let role of whoAmIDetails.roles) {

              if (role['name'] === 'ROLE_ADMIN') {
                Swal.close();
                this.isLog = false;
                localStorage.setItem('isLogged', 'False');
                localStorage.removeItem('cartID');
                this.router.navigate(['/home/' + this.tenantValue]);
                return;
              }
            }
            Swal.close();
          });

          this.interactionService.checkoutItemForm$.subscribe(reCall => {
            if (reCall === 'true') {
              this._common();
            }
          });
        } else {
          Swal.close();
          this.toastr.error('Invalid OTP!', '', {
            positionClass: 'toast-top-right',
          });
        }
      }, () => {
        Swal.close();
        this.isInvalid = true;
        this.isLoading = false;
        this.toastr.error('Invalid username!', '', {
          positionClass: 'toast-top-right',
        });
      });
    }

  }

  onSubmitLogin() {

    this.submittedLoginForm = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    let _requestOTP = {
      'userNameOrNic': this.loginUsername
    };

    this.loginService.requestOtp(_requestOTP).subscribe(result => {
      Swal.close();
      this.isLoading = false;
      if (result['success']) {

        this.smsService.getPhoneNo(this.loginUsername).subscribe(result_phoneNo => {
          localStorage.setItem('USERNAME', this.loginUsername);
          this.mobile = result_phoneNo;

          if (document.querySelector('#time2') !== null && !this.isOTPModalClose) {
            this.OTPValidation(60 * 3, document.getElementById('time2'));
          }
          if (this.isOTPModalClose) {
            clearInterval(this.session);
            document.getElementById('time2').textContent = '03:00';
            this.OTPValidation(60 * 3, document.getElementById('time2'));
          }
          this.optModal.nativeElement.click();
        });

      } else {
        this.toastr.error('Invalid username!', '', {
          positionClass: 'toast-top-right',
        });
      }


    }, () => {
      this.isLoading = false;
      Swal.close();
    });


  }


  SignUp() {
    if (this.validationHandlerService.filterOnlySpecialCharacters(this.name)) {
      this.wrongName = false;

      this.loginService.checkEmailIsPresent(this.email).subscribe(isPresent => {
        if (!isPresent['success']) {
          let _signUpBody = {
            'name': this.name,
            'mobileNumber': this.mobile,
            'email': this.email,
            'password': this.password
          };
          this.processing();
          this.signUpService.signUp(_signUpBody).subscribe(signUpResult => {
            if (signUpResult['success']) {
              Swal.close();
              this.resetForm();
              document.getElementById('signInId').click();
              this.toastr.success('User Registered Successfully!');
            } else {
              Swal.close();
              this.toastr.error('User Registration Failed!');
            }
          }, () => {
            Swal.close();
            this.toastr.error('User Registration Failed!');
          });
        } else {
          this.toastr.error('The entered email is already taken!');
        }
      }, () => {
        Swal.close();
      });

    } else {
      this.wrongName = true;
    }
  }

  onSubmitSignUp() {
    this.submittedSignUpForm = true;
    if (this.signUpForm.invalid) {
      return;
    }
    this.SignUp();
  }


  processing() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  resetForm() {
    this.loginUsername = '';
    this.loginPassword = '';
    this.name = '';
    this.password = '';
    this.rePassword = '';
    this.mobile = '';
    this.email = '';
  }

  fetchPrivileges(userId) {
    this.userRoleService.fetchPrivilegesByUserId(userId).subscribe(privilegesList => {
      if (privilegesList.length > 0) {
        let privilegesArray = [];
        for (let privilege of privilegesList) {
          privilegesArray.push(privilege.name);
        }
        localStorage.setItem('PRIVILEGES_LIST', JSON.stringify(privilegesArray));
      } else {
        localStorage.setItem('isLogged', 'False');
        localStorage.removeItem('cartID');
        this.router.navigate(['/home/' + this.tenantValue]);
        return;
      }
    });
  }

  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    this.session = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(this.session);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    this.isInvalid = false;
    this.ngOtpInputRef.setValue('');
    clearInterval();
  }

  resendOTPCode() {
    this.ngOtpInputRef.setValue('');
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time2').textContent = '03:00';
    this.OTPValidation(60 * 3, document.getElementById('time2'));
    this.isShowResendButton = false;

    let _requestOTP = {
      'userNameOrNic': this.loginUsername
    };

    this.loginService.requestOtp(_requestOTP).subscribe(() => {

    });
  }

  verifyOTP() {
    this.userLogin();
    this.closeOTPModal();
  }

}
