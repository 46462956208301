
<!--<html>-->
<head>
  <!--  <meta content="text/html; charset=UTF-8" http-equiv="content-type">-->

  <style
    type="text/css">
    @import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');

    ol.lst-kix_list_3-1 {
      list-style-type: none
    }

    ol.lst-kix_list_3-2 {
      list-style-type: none
    }

    .lst-kix_list_3-1 > li {
      counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_3-3 {
      list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
      counter-reset: lst-ctn-kix_list_3-4 0
    }

    ol.lst-kix_list_3-4 {
      list-style-type: none
    }

    .lst-kix_list_2-1 > li {
      counter-increment: lst-ctn-kix_list_2-1
    }

    ol.lst-kix_list_3-0 {
      list-style-type: none
    }

    .lst-kix_list_1-1 > li {
      counter-increment: lst-ctn-kix_list_1-1
    }

    ol.lst-kix_list_2-6.start {
      counter-reset: lst-ctn-kix_list_2-6 0
    }

    .lst-kix_list_3-0 > li:before {
      content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "
    }

    ol.lst-kix_list_3-1.start {
      counter-reset: lst-ctn-kix_list_3-1 0
    }

    .lst-kix_list_3-1 > li:before {
      content: "" counter(lst-ctn-kix_list_3-1, decimal) ". "
    }

    .lst-kix_list_3-2 > li:before {
      content: "" counter(lst-ctn-kix_list_3-2, lower-latin) ". "
    }

    ol.lst-kix_list_1-8.start {
      counter-reset: lst-ctn-kix_list_1-8 0
    }

    ol.lst-kix_list_2-3.start {
      counter-reset: lst-ctn-kix_list_2-3 0
    }

    .lst-kix_list_3-5 > li:before {
      content: "" counter(lst-ctn-kix_list_3-5, decimal) ". "
    }

    .lst-kix_list_3-4 > li:before {
      content: "" counter(lst-ctn-kix_list_3-4, decimal) ". "
    }

    ol.lst-kix_list_1-5.start {
      counter-reset: lst-ctn-kix_list_1-5 0
    }

    .lst-kix_list_3-3 > li:before {
      content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
    }

    ol.lst-kix_list_3-5 {
      list-style-type: none
    }

    ol.lst-kix_list_3-6 {
      list-style-type: none
    }

    ol.lst-kix_list_3-7 {
      list-style-type: none
    }

    ol.lst-kix_list_3-8 {
      list-style-type: none
    }

    .lst-kix_list_3-8 > li:before {
      content: "" counter(lst-ctn-kix_list_3-8, decimal) ". "
    }

    .lst-kix_list_2-0 > li {
      counter-increment: lst-ctn-kix_list_2-0
    }

    .lst-kix_list_2-3 > li {
      counter-increment: lst-ctn-kix_list_2-3
    }

    .lst-kix_list_3-6 > li:before {
      content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
    }

    .lst-kix_list_3-7 > li:before {
      content: "" counter(lst-ctn-kix_list_3-7, decimal) ". "
    }

    .lst-kix_list_1-2 > li {
      counter-increment: lst-ctn-kix_list_1-2
    }

    ol.lst-kix_list_3-7.start {
      counter-reset: lst-ctn-kix_list_3-7 0
    }

    .lst-kix_list_3-2 > li {
      counter-increment: lst-ctn-kix_list_3-2
    }

    ol.lst-kix_list_2-2 {
      list-style-type: none
    }

    ol.lst-kix_list_2-3 {
      list-style-type: none
    }

    ol.lst-kix_list_2-4 {
      list-style-type: none
    }

    ol.lst-kix_list_2-5 {
      list-style-type: none
    }

    .lst-kix_list_1-4 > li {
      counter-increment: lst-ctn-kix_list_1-4
    }

    ol.lst-kix_list_2-0 {
      list-style-type: none
    }

    ol.lst-kix_list_1-6.start {
      counter-reset: lst-ctn-kix_list_1-6 0
    }

    ol.lst-kix_list_2-1 {
      list-style-type: none
    }

    ol.lst-kix_list_3-3.start {
      counter-reset: lst-ctn-kix_list_3-3 0
    }

    ol.lst-kix_list_2-6 {
      list-style-type: none
    }

    ol.lst-kix_list_2-7 {
      list-style-type: none
    }

    ol.lst-kix_list_2-8 {
      list-style-type: none
    }

    ol.lst-kix_list_1-0.start {
      counter-reset: lst-ctn-kix_list_1-0 0
    }

    .lst-kix_list_3-0 > li {
      counter-increment: lst-ctn-kix_list_3-0
    }

    .lst-kix_list_3-3 > li {
      counter-increment: lst-ctn-kix_list_3-3
    }

    .lst-kix_list_3-6 > li {
      counter-increment: lst-ctn-kix_list_3-6
    }

    .lst-kix_list_2-5 > li {
      counter-increment: lst-ctn-kix_list_2-5
    }

    .lst-kix_list_2-8 > li {
      counter-increment: lst-ctn-kix_list_2-8
    }

    ol.lst-kix_list_3-2.start {
      counter-reset: lst-ctn-kix_list_3-2 0
    }

    .lst-kix_list_2-2 > li {
      counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_2-4.start {
      counter-reset: lst-ctn-kix_list_2-4 0
    }

    ol.lst-kix_list_1-3 {
      list-style-type: none
    }

    ol.lst-kix_list_1-4 {
      list-style-type: none
    }

    .lst-kix_list_2-6 > li:before {
      content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
    }

    .lst-kix_list_2-7 > li:before {
      content: "" counter(lst-ctn-kix_list_2-7, decimal) ". "
    }

    .lst-kix_list_2-7 > li {
      counter-increment: lst-ctn-kix_list_2-7
    }

    .lst-kix_list_3-7 > li {
      counter-increment: lst-ctn-kix_list_3-7
    }

    ol.lst-kix_list_1-5 {
      list-style-type: none
    }

    ol.lst-kix_list_1-6 {
      list-style-type: none
    }

    ol.lst-kix_list_1-0 {
      list-style-type: none
    }

    .lst-kix_list_2-4 > li:before {
      content: "" counter(lst-ctn-kix_list_2-4, decimal) ". "
    }

    .lst-kix_list_2-5 > li:before {
      content: "" counter(lst-ctn-kix_list_2-5, decimal) ". "
    }

    .lst-kix_list_2-8 > li:before {
      content: "" counter(lst-ctn-kix_list_2-8, decimal) ". "
    }

    ol.lst-kix_list_1-1 {
      list-style-type: none
    }

    ol.lst-kix_list_1-2 {
      list-style-type: none
    }

    ol.lst-kix_list_3-0.start {
      counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_1-7 {
      list-style-type: none
    }

    .lst-kix_list_1-7 > li {
      counter-increment: lst-ctn-kix_list_1-7
    }

    ol.lst-kix_list_1-8 {
      list-style-type: none
    }

    ol.lst-kix_list_3-8.start {
      counter-reset: lst-ctn-kix_list_3-8 0
    }

    ol.lst-kix_list_2-5.start {
      counter-reset: lst-ctn-kix_list_2-5 0
    }

    .lst-kix_list_2-6 > li {
      counter-increment: lst-ctn-kix_list_2-6
    }

    .lst-kix_list_3-8 > li {
      counter-increment: lst-ctn-kix_list_3-8
    }

    ol.lst-kix_list_1-7.start {
      counter-reset: lst-ctn-kix_list_1-7 0
    }

    ol.lst-kix_list_2-2.start {
      counter-reset: lst-ctn-kix_list_2-2 0
    }

    .lst-kix_list_1-5 > li {
      counter-increment: lst-ctn-kix_list_1-5
    }

    .lst-kix_list_1-8 > li {
      counter-increment: lst-ctn-kix_list_1-8
    }

    ol.lst-kix_list_1-4.start {
      counter-reset: lst-ctn-kix_list_1-4 0
    }

    .lst-kix_list_3-5 > li {
      counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_1-1.start {
      counter-reset: lst-ctn-kix_list_1-1 0
    }

    .lst-kix_list_3-4 > li {
      counter-increment: lst-ctn-kix_list_3-4
    }

    .lst-kix_list_2-4 > li {
      counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_3-6.start {
      counter-reset: lst-ctn-kix_list_3-6 0
    }

    ol.lst-kix_list_1-3.start {
      counter-reset: lst-ctn-kix_list_1-3 0
    }

    ol.lst-kix_list_2-8.start {
      counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_1-2.start {
      counter-reset: lst-ctn-kix_list_1-2 0
    }

    .lst-kix_list_1-0 > li:before {
      content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
    }

    .lst-kix_list_1-1 > li:before {
      content: "" counter(lst-ctn-kix_list_1-1, decimal) ". "
    }

    .lst-kix_list_1-2 > li:before {
      content: "" counter(lst-ctn-kix_list_1-2, lower-latin) ") "
    }

    ol.lst-kix_list_2-0.start {
      counter-reset: lst-ctn-kix_list_2-0 0
    }

    .lst-kix_list_1-3 > li:before {
      content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
    }

    .lst-kix_list_1-4 > li:before {
      content: "" counter(lst-ctn-kix_list_1-4, decimal) ". "
    }

    ol.lst-kix_list_3-5.start {
      counter-reset: lst-ctn-kix_list_3-5 0
    }

    .lst-kix_list_1-0 > li {
      counter-increment: lst-ctn-kix_list_1-0
    }

    .lst-kix_list_1-6 > li {
      counter-increment: lst-ctn-kix_list_1-6
    }

    .lst-kix_list_1-7 > li:before {
      content: "" counter(lst-ctn-kix_list_1-7, decimal) ". "
    }

    ol.lst-kix_list_2-7.start {
      counter-reset: lst-ctn-kix_list_2-7 0
    }

    .lst-kix_list_1-3 > li {
      counter-increment: lst-ctn-kix_list_1-3
    }

    .lst-kix_list_1-5 > li:before {
      content: "" counter(lst-ctn-kix_list_1-5, decimal) ". "
    }

    .lst-kix_list_1-6 > li:before {
      content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
    }

    .lst-kix_list_2-0 > li:before {
      content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
    }

    .lst-kix_list_2-1 > li:before {
      content: "" counter(lst-ctn-kix_list_2-1, decimal) ". "
    }

    ol.lst-kix_list_2-1.start {
      counter-reset: lst-ctn-kix_list_2-1 0
    }

    .lst-kix_list_1-8 > li:before {
      content: "" counter(lst-ctn-kix_list_1-8, decimal) ". "
    }

    .lst-kix_list_2-2 > li:before {
      content: "" counter(lst-ctn-kix_list_2-2, lower-latin) ") "
    }

    .lst-kix_list_2-3 > li:before {
      content: "(" counter(lst-ctn-kix_list_2-3, lower-roman) ") "
    }

    ol {
      margin: 0;
      padding: 0
    }

    table td, table th {
      padding: 0
    }

    .c4 {
      background-color: #ffffff;
      margin-left: 18pt;
      padding-top: 0pt;
      padding-left: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c1 {
      background-color: #ffffff;
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 11pt
    }

    .c17 {
      background-color: #ffffff;
      padding-top: 0pt;
      padding-bottom: 7.5pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: center
    }

    .c8 {
      color: #43474e;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Calibri";
      font-style: normal
    }

    .c6 {
      background-color: #ffffff;
      padding-top: 0pt;
      padding-bottom: 12pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c14 {
      background-color: #ffffff;
      padding-top: 0pt;
      padding-bottom: 7.5pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c15 {
      background-color: #ffffff;
      padding-top: 0pt;
      padding-bottom: 14pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c3 {
      color: #5d646f;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Calibri";
      font-style: normal
    }

    .c12 {
      background-color: #ffffff;
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c0 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Calibri";
      font-style: normal
    }

    .c21 {
      padding-top: 0pt;
      padding-bottom: 8pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c11 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c20 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c9 {
      color: #000000;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-style: normal
    }

    .c13 {
      background-color: #ffffff;
      max-width: 468pt;
      padding: 72pt 72pt 72pt 72pt
    }

    .c10 {
      padding: 0;
      margin: 0
    }

    .c7 {
      margin-left: 36pt;
      padding-left: 0pt
    }

    p {
      margin-bottom: 20px;
    }

    p.c3 {
      display: none;
    }

    .c18 {
      font-weight: 400;
      font-family: "Calibri"
    }

    .c2 {
      font-weight: 700;
      font-family: "Calibri"
    }

    .c16 {
      margin-left: 72pt;
      padding-left: 0pt
    }

    .c19 {
      color: #000000
    }

    .c25 {
      height: 11pt
    }

    .c26 {
      padding-left: 0pt
    }

    .c5 {
      font-weight: 700
    }

    .c22 {
      color: #222222
    }

    .c24 {
      margin-left: 18pt
    }

    .c23 {
      background-color: #ffffff
    }

    .title {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 36pt;
      padding-bottom: 6pt;
      font-family: "Calibri";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .subtitle {
      padding-top: 18pt;
      color: #666666;
      font-size: 24pt;
      padding-bottom: 4pt;
      font-family: "Georgia";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    li {
      color: #000000;
      font-size: 11pt;
      font-family: "Calibri"
    }

    p {
      margin: 0;
      color: #000000;
      font-size: 11pt;
      font-family: "Calibri";
      text-align: justify;
    }

    h1 {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 24pt;
      padding-bottom: 6pt;
      font-family: "Calibri";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h2 {
      padding-top: 0pt;
      color: #000000;
      font-weight: 700;
      font-size: 18pt;
      padding-bottom: 8pt;
      font-family: "Times New Roman";
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h3 {
      padding-top: 14pt;
      color: #000000;
      font-weight: 700;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: "Calibri";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h4 {
      padding-top: 12pt;
      color: #000000;
      font-weight: 700;
      font-size: 12pt;
      padding-bottom: 2pt;
      font-family: "Calibri";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h5 {
      padding-top: 11pt;
      color: #000000;
      font-weight: 700;
      font-size: 11pt;
      padding-bottom: 2pt;
      font-family: "Calibri";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h6 {
      padding-top: 10pt;
      color: #000000;
      font-weight: 700;
      font-size: 10pt;
      padding-bottom: 2pt;
      font-family: "Calibri";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }</style>
</head>
<body class="c13">
<h1 style="padding-top: 0; margin-top: 0;">
  விதிமுறைகள் மற்றும் நிபந்தனைகள்
</h1>
<p>
  <strong>‘Helaviru.lk’ </strong>
  <strong>
    யை உபயோகிப்பதற்கு முன்னர் தயவு செய்து விதிமுறைகள் மற்றும்
    நிபந்தனைகள்கவனமாக வாசிக்கவும்.
  </strong>
</p>
<p>
  2006ஆம் ஆண்டின் 19ஆம் இலக்க இலத்திரனியல் கொடுக்கல் வாங்கல் சட்டத்தின்
  ஏற்புடைத்தான ஏற்பாடுகளுக்கமைய இவ்வாவணமானது ஒரு இலத்திரனியல் பதிவாகும்.
  அத்துடன் 2006ஆம் ஆண்டின் 19ஆம் இலக்க இலத்திரனியல் கொடுக்கல் வாங்கல்
  சட்டத்தின் திருத்தங்களுக்கு அமைவான பல்வேறு நியதிச் சட்டங்களின் ஏற்பாடுகளும்
  இலத்திரனியல் பதிவுகளுடன் சம்பந்தப்பட்டுள்ளன.
</p>
<p>
  இவ்விலத்திரனியல் பதிவானது கணினி முறைமையின் கீழ் உருவாக்கப்பட்டமையினால்
  உடல்சார் அல்லது எண் மின்சார் கையெழுத்துக்களுக்கு எவ்வித தேவையுமில்லை.
</p>
<h2>
  பொதுவான விதிமுறைகள்
</h2>
<h2>
  1.0 அறிமுகம்
</h2>
<p>
  ஹெலவிரு டிஜிட்டல் மார்கெட் பிளேஸிற்கு’ வரவேற்கின்றோம். (இதன் பின்னர்
  “நாங்கள்”,“எங்களுக்கு”,“ஹெலவிரு” அல்லது “ஹெலவிருத் தளம்” எனக் குறிப்பிட்டு
  அழைக்கப்படும்). நாங்கள் இணையத்தில் உள்ள ஒரு சந்தைப் பிரதேசம். உங்களுக்கு
  எங்களது அணுகலையூம், ஹெலவிருதளத்துடன் சம்பந்தப்பட்ட, அதன் உபதளங்கள்,
  தளங்கள், கைத்தொலைபேசி பயன்பாடுகள், சேவைகள் மற்றும் கருவிகள் (இதன் பின்னர்
  ‘site’ தளம் என குறிப்பிட்டு அழைக்கப்படும்) என்பனவற்றுடன் இணைந்த ஹெலவிரு
  தளத்தின் உபயோகங்களையும், ஆளுகின்ற விதிமுறைகளும், நிபந்தனைகளும் கீழே
  தரப்பட்டுள்ளன. இத்தளத்தை உபயோகிப்பதன் மூலம் நீங்கள் இந்த விதிமுறைகளையும்,
  நிபந்தiனைகளையும் இத்தால் ஏற்றுக்கொள்வதுடன் (இதனுடன் இணைக்கப்பட்ட தகவல்
  உட்பட) இவ்விதிமுறைகளையும், நிபந்தனைகளையும் (இதன் பின்னர் ‘பாவனையாளர்
  ஒப்பந்தம்’ என குறிப்பிடப்பட்டு அழைக்கப்படும்) இத்தால் ஏற்றுக்கொள்வதாகப்
  பிரதிநிதித்துவமும் செய்கின்றீர்கள். தளத்தின் உங்களது பாவனையின் மீது இந்தப்
  பாவனையாளர் ஒப்பந்தம் செயல்திறன் பெறுவதுடன் இவ்விதிமுறைகளை நீங்கள்
  ஏற்றுக்கொள்வதையும் கோடிட்டுக் காட்டுகின்றது. இப்பாவனையாளர் ஒப்பந்தத்தைக்
  கடைப்பிடிப்பதற்கு நீங்கள் உடன்படவில்லையாயின் தயவுசெய்து இத்தளத்தை அணுகவும்
  வேண்டாம். இத்தளத்தோடு பதிவு செய்யவோ அல்லது பயன்படுத்தவோ வேண்டாம்.
  இத்தளமானது எபிக் கிறீன் எகோ ஸிஸ்டம்ஸ் (பிரைவேட்) லிமிடெட் (இதன் பின்னர்
  ‘EpicGreen’ எனக் குறிப்பிட்டு அழைக்கப்படும்) மற்றும் ஸ்ரீ லங்கா ரெலிகொம்
  பீ.எல்.சீ (இதன் பின்னர் ‘SLT’ என்று குறிப்பிட்டு அழைக்கப்படும்)
  ஆகியவற்றுக்குச் சொந்தமானதும், அவற்றுடன் இணைந்து செயற்படுவதுமாகும்.
</p>
<p>
  எந்தவிதமான முன்னறிவித்தலுமின்றி இந்த விதிமுறைகளினதும், நிபந்தனைகளினதும்
  பகுதிகளை மாற்றுவதற்கு திருத்துவதற்கு சேர்ப்பதற்கு நீக்குவதற்கு EpicGreen
  இற்கும் SLT யிற்கும் உரிமை உண்டு. இணையத்தளத்தில் இடப்படும் போது மாற்றங்கள்
  செயற்றிறன் பெறுவதுடன் இது பற்றிய வேறு எவ்வித அறிவித்தல்களும் வழங்கப்பட
  மாட்டாது. அவ்வப்போதே அறிந்து கொள்வதற்காக இவ்விதிமுறைகளையும் நிபந்தனைகளையும்
  தயவு செய்து அடிக்கடி கண்காணித்துக் கொள்ளுங்கள். பாவனைக்குரிய
  விதிமுறைகளிலும்இ நிபந்தனைகளிலும் ஏற்படுத்தப்படுகின்ற மாற்றங்களைப்
  பின்பற்றுவதுடன் உங்களது தொடர்ச்சியான இணையத்தளத்தின் பாவனை இம்மாற்றங்களுக்கு
  நீங்கள் உடன்பட்டுள்ளீர்கள் என்பதை வெளிப்படுத்தும்.
</p>
<h2>
  2.0 வாய்ப்பு
</h2>
<p style="margin-left: 20px;">
  2.1. ஹெலவிரு தளமென்பதுஇ ஹெலவிருவில் அதன் இணைப்புச் சந்தைப் பிரதேசத்தினூடாக
  இலத்திரனியல், வர்த்தகத்தை இலகுவாக்குவதற்குச் சேவைகளை வழங்குகின்ற
  வியாபாரமாகும். வாடிக்கையாளர்களுக்கும் வர்த்தகர்களுக்குமிடையில் இணைப்புப்
  பரிவர்த்தனையை ஏற்படுத்துகின்ற ஒரு தளமாகும். வர்த்தகர்கள் எங்களால்
  வழங்கப்படுகின்ற இணைப்பு சந்தைப் பிரதேசத் தளத்தினூடாக பொருட்களை விற்பதற்கு
  விரும்புகின்றனர். இதன் இணைப்புத்தளத்தினூடாக வியாபாரம் செய்வதை
  இலகுபடுத்துவதற்காக ஹெலவிரு பல்வேறு வகையான சேவைகளை வழங்குகின்றது.
  இத்தளத்தினைப் பாவிப்பவர்கள் கீழே குறிப்பிடப்படுகின்ற விதிமுறைகளுக்கும்
  நிபந்தனைகளுக்கும் கட்டுப்பட வேண்டிய கடமைப்பாடு உடையவர்களாக உள்ளனர்.
</p>
<p style="margin-left: 20px;">
  2.2. வர்த்தகருக்கும் வாடிக்கையாளருக்குமிடையில் ஹெலவிரு தளத்தினூடாக
  நடைபெறுகின்ற ஒவ்வொரு பரிவர்த்தனையூம் இங்கு குறிப்பிடப்படுகின்ற அல்லது
  ஹெலவிரு தளத்தில் ‘Helaviru Platform’ ல் காணப்படுகின்ற இப்பாவனையாளர்
  ஒப்பந்தத்தின் விதிமுறைகள் நிபந்தனைகள் விபரங்கள் இணைப்புக்கள்
  பின்னிணைப்புக்கள் தளக்கொள்கைகள் என்பனவற்றினால் ஆளப்படும்.
  வாடிக்கையாளர்களினால் இலத்திரனியலினூடாக ஏற்றுக் கொள்ளப்பட்டவுடனேயே
  இவ்வொப்பந்தம் ஆரம்பமாகும்.
</p>
<p style="margin-left: 20px;">
  2.3. தற்போதைய ஒப்பந்தத்தின் ஏதேனும் ஒரு பகுதியை நிறைவேற்றுவதற்கு அல்லது
  முன்னறிவிப்புக்கள் எதுவுமின்றி வர்த்தகருக்கு ஏற்படுகின்ற ஏதேனும் எதிர்கால
  சேவைகளை நிறைவேற்றுவதற்கு ஹெலவிரு தளமானது உப ஒப்பந்தக்காரர்களின் சேவைகளைப்
  பெறும்.
</p>
<p style="margin-left: 20px;">
  2.4. ஒரு நியதிச்சட்டத்தின் ஏதேனும் ஒரு ஏற்பாட்டுக்கமைவாக இவ்வொப்பந்தத்தில்
  காணப்படுகின்ற ஏதேனும் குறிப்பு அவ்வேற்பாட்டின் திருத்தத்திற்கும் மீள்
  இயற்றலுக்கும் நீடிக்கப்பட்டமைக்கும் பொருத்தமான வேளைகளில் கட்டுப்படும்.
</p>
<p style="margin-left: 20px;">
  2.5. தளக் கொள்கைகள் வாசிக்கப்பட்டு இவ்வொப்பந்தத்தின் ஒரு உட்பாகமாக
  இணைக்கப்படும். ஹெலவிரு தளத்தின் சேவைகளின் மாறாத முன்னேற்றத்தின் காரணமாகஇ
  தளக் கொள்கைகள் காலத்திற்குக் காலம் பரிணாமமும் மாற்றமும் பெறும்.
  வாடிக்கையாளர்களின் தளப்பாவனை மற்றும் வாடிக்கையாளர்களின் இணைய முகப்பின்
  அணுகல் என்பன இவ்வொப்பந்தத்திற்குட்பட்டதாக அமைவதுடன் இறுதியான தளக் கொள்கைகள்
  ஹெலவிரு தளத்தில் பெறக்கூடிய வகையில் அமைந்துள்ளன.
</p>
<p style="margin-left: 20px;">
  2.6. இதன் தரம் உயர் சேவைகள் தராதரம் என்பனவற்றின் நற்பெயரைத் தொடர்ச்சியாகக்
  காப்பாற்றும் வகையில் ஹெலவிரு வாடிக்கையாளர் பாதுகாப்புக் கொள்கையினால்
  ஆளப்படுவதற்கிணங்க வாடிக்கையாளர்களின் மீதான ஹெலவிரு தளத்தின் உள்ளார்ந்த தர
  மதிப்பீட்டின் அடிப்படையில் வாடிக்கையாளரின் உட்புகுதலை முடிவூறுத்துவதற்கான
  உரிமையை ஹெலவிரு தளம் தன்வசம் வைத்துள்ளது.
</p>
<h2>
  3.0 பொருள்கோடலும் வரைவிலக்கணங்களும்
</h2>
<p>
  இந்த உடன்படிக்கையில் கீழே காணப்படுகின்ற சொற்களும் வெளிப்பாடுகளும் பின்வரும்
  கருத்துக்களைக் கொண்டிருக்கும்.
</p>
<p>
  <strong>வங்கிக்</strong>
  <strong> </strong>
  <strong>கணக்கு</strong>
  :- எந்த வங்கிக் கணக்கிற்கு கொடுப்பனவுகள் மேற்கொள்ளப்பட வேண்டுமென்று ஹெலவிரு
  தளம் குறிப்பிட்டுள்ளதோ அந்த வங்கிக் கணக்கு.
</p>
<p>
  <strong>வியாபார</strong>
  <strong> </strong>
  <strong>நாள்</strong>
  :- இலங்கையில் வியாபாரத்திற்காக வங்கிகள் திறந்துள்ள ஒரு நாள் (சனி, ஞாயிறு
  பொது வங்கி விடுமுறைகள் தவிர்ந்த நாட்கள்)
</p>
<p>
  <strong>வாடிக்கையாளர்</strong>
  <strong> </strong>
  <strong>பாதுகாப்புக்</strong>
  <strong> </strong>
  <strong>கொள்கை</strong>
  :- வாடிக்கையாளர் பாதுகாப்பு எக் கொள்கையினால் கண்காணிக்கப்படுகின்றதோ அவ்
  ஹெலவிரு கொள்கை.
</p>
<p>
  <strong>வாடிக்கையாளர்</strong>
  :- ஹெலவிரு தளத்தினூடாக பொருட்களையும் சேவைகளையும் கொள்வனவு செய்கின்ற ஒரு
  நபர்.
</p>
<p>
  <strong>வர்த்தகர்</strong>
  :- ஹெலவிரு தளத்தினூடாக பொருட்களையும் சேவைகளையும் வழங்குகின்ற ஒரு நபர்.
</p>
<p>
  <strong>பொருட்கள்</strong>
  <strong> </strong>
  <strong>பற்றிய</strong>
  <strong> </strong>
  <strong>விளக்கப்பட்டியல்</strong>
  :- பூரணப்படுத்தப்பட்ட மாதிரியாகும். அதாவது ஹெலவிரு தளத்தில் ஏற்கனவே
  உடன்பட்ட விற்பனை முறைகளின் அடிப்படையில் வாடிக்கையாளர்களுக்கு கிடைக்கும்
  வகையில் அவ் விலைப்பட்டியலைத் தனது சொந்த இடத்தில் பாதுகாத்து வைத்தல்
  வர்த்தகரின் பொறுப்பாகும்.
</p>
<p>
  <strong>கட்டணம்</strong>
  :- சேவைகளுக்காக ஹெலவிருவினால் அறவிடப்படுகின்ற ஏதேனும் கட்டணம்
</p>
<p>
  <strong>இறுதியான</strong>
  <strong> </strong>
  <strong>வழங்குதல்</strong>
  :- வர்த்தகரிடமிருந்து இறுதி வாடிக்கையாளருக்கு பொருட்களின் உரிமையை
  இடமாற்றுதல்.
</p>
<p>
  <strong>பொது</strong>
  <strong> </strong>
  <strong>விதிமுறைகள்</strong>
  :- இந்த ஒப்பந்தத்தில் காணப்படுகின்ற விதிமுறைகள்
</p>
<p>
  <strong>தருவித்தல்</strong>
  <strong> </strong>
  <strong>செயன்முறை</strong>
  :- ஒரு தருவித்தலை செயன்முறைப்படுத்துவதற்கு வர்த்தகரினால் ஏற்றுக்
  கொள்ளப்பட்டதும், ஏற்கனவே விபரிக்கப்பட்ட முறையின் அடிப்படையில்
  அனுப்பப்பட்டதுமான செயன்முறை
</p>
<p>
  <strong>செயன்முறைக்</strong>
  <strong> </strong>
  <strong>காலம்</strong>
  :- வர்த்தகருக்கு ஹெலவிரு தளத்தினால் தருவித்தல் வழங்கப்பட்ட காலம் முதல் பொதி
  செய்யப்பட்டு ஏற்கனவே விபரிக்கப்பட்ட இடத்திலிருந்து ஏற்கனவே விபரிக்கப்பட்ட
  முறைமைக்கிணங்க வர்த்தகரினால் பொருட்கள் அனுப்பப்படும் நாள் வரையிலான
  காலமாகும்.
</p>
<p>
  <strong>எழுத்திலானது </strong>
  <strong>/ </strong>
  <strong>எழுதப்பட்டது</strong>
  :- வாடிக்கையாளர் இணைய முகப்பினூடாக அல்லது கூரியர் தபால் குறுஞ்செய்திச் சேவை
  அல்லது மின்னஞ்சலூடாக நடைபெறுகின்ற அனைத்துத் தகவல் பரிமாற்றங்களும்
</p>
<p>
  <strong>புலமைச்</strong>
  <strong> </strong>
  <strong>சொத்து</strong>
  :- ஏதேனும் ஆக்க உரிமை, பதிப்புரிமை, பதிவூ செய்யப்பட்ட அல்லது பதிவு
  செய்யப்படாத வடிவமைப்பு, வடிவமைப்புரிமை, பதிவு செய்யப்பட்ட அல்லது பதிவு
  செய்யப்படாத வியாபாரச் சின்னம், சேவைச் சின்னம் அல்லது வேறு ஏதேனும்
  கைத்தொழில் அல்லது புலமைச் சொத்துரிமை என்பவற்றுடன் இவற்றின்
  ஏற்புடைத்தாக்கமும் உள்ளடங்கும்.
</p>
<p>
  <strong>பட்டியலிடப்பட்ட</strong>
  <strong> </strong>
  <strong>விலை</strong>
  :- தளத்தில் பொருட்களுக்காகப் பட்டியலிடப்பட்ட விலையும், பொருட்களுக்காக
  ஹெலவிருத் தளம் பட்டியலிட்ட விற்பனை விலையும் இதனுடன் ஏதேனும் வரிகளும்
  இலங்கைக்கு ஏற்புடைத்தான சேவைகள் ஏதேனும் இருப்பின் அவையும் அடங்கும்.
</p>
<p>
  <strong>வாடிக்கையாளர்</strong>
  <strong> </strong>
  <strong>தனியூரிமைக்</strong>
  <strong> </strong>
  <strong>கொள்கை</strong>
  :- வாடிக்கையாளர்களை ஆளுகின்ற தனியுரிமைக் கொள்கை
</p>
<p>
  <strong>நிலுவையில்</strong>
  <strong> </strong>
  <strong>நிற்றல்</strong>
  :- தருவித்தல் பெறப்பட்டு வர்த்தகரினால் ஏற்றுக்கொள்ளப்படுவதற்கு
  காத்திருக்கின்றது என்பதை விபரிக்கும் ஹெலவிரு தளத்தின் நிலை
</p>
<p>
  <strong>வாடிக்கையாளர்</strong>
  <strong> </strong>
  <strong>கொடுப்பனவூக்</strong>
  <strong> </strong>
  <strong>கொள்கை</strong>
  :- வாடிக்கையாளர்களை ஆளுகின்ற கொடுப்பனவுக் கொள்கை
</p>
<p>
  <strong>வாடிக்கையாளர்</strong>
  <strong> </strong>
  <strong>பாதுகாப்புக்</strong>
  <strong> </strong>
  <strong>கொள்கை</strong>
  :- வாடிக்கையாளர்களின் தருவித்தலில் இடம்பெறுகின்ற மோசடிகளுக்கு எதிரான
  பாதுகாப்பு
</p>
<p>
  <strong>தளம்</strong>
  :- ஹெலவிருத் தளம் எனப்படும் இணையத்தளம் அல்லது அதனுடன் இணைக்கப்பட்ட
  இணையத்தளம்
</p>
<p>
  <strong>தளக்</strong>
  <strong> </strong>
  <strong>கொள்கைகள்</strong>
  :- ஹெலவிரு இணையத்தளத்தில் காணப்படுகின்ற வாடிக்கையாளர்களுக்கு ஏற்புடைத்தான
  எல்லாக் கொள்கைகளும் வழிகாட்டல்களும்
</p>
<p>
  <strong>பொருட்கள்</strong>
  :- இணையத்தளத்தில் வர்த்தகர் விற்பதற்கு எண்ணியூள்ள பொருட்களும், சேவைகளும்
</p>
<p>
  <strong>வருவாய்க்</strong>
  <strong> </strong>
  <strong>கொள்கை</strong>
  :- வருவாய், பண மீள்பெறுகை, பொருட்களை இரத்துச் செய்தல் அல்லது நிராகரித்தல்
  என்பனவற்றை ஆளுகின்ற கொள்கை. “வாடிக்கையாளர் வருவாயும் பண
  மீள்பெறுகையும்”கோட்பாட்டினுள் இதனை அவதானிக்கலாம்.
</p>
<p>
  <strong>மீளளிக்கப்பட்ட</strong>
  <strong> </strong>
  <strong>பொருட்கள்</strong>
  :- வாடிக்கையாளருக்கு வழங்கப்பட்ட ஆனால் ஏதேனும் ஒரு காரணத்தினால்
  வாடிக்கையாளரினால் மீளளிக்கப்பட்ட ஒரு பொருள்
</p>
<p>
  <strong>வாடிக்கையாளர்</strong>
  <strong> </strong>
  <strong>உதவி</strong>
  <strong> </strong>
  <strong>நிலையம்</strong>
  :- வாடிக்கையாளர்கள் முகம் கொடுக்கின்ற பிரச்சினைகளைத் தீர்ப்பதற்கும்,
  வாடிக்கையாளர்களின் கொள்வனவிற்கு உதவி செய்வதற்கும் ஹெலவிரு தளத்தினால்
  வழங்கப்படுகின்ற ஒத்தாசை சேவைகள்
</p>
<p>
  <strong>அனுப்பப்பட்டமை</strong>
  :- ஏற்கனவே விபரிக்கப்பட்ட இடத்திலிருந்து பொருள் அனுப்பப்பட்டுள்ளதுடன்
  வாடிக்கையாளருக்கு வழங்குவதற்கு நிச்சயிக்கப்பட்டுள்ளது.
</p>
<p>
  <strong>பதிவு</strong>
  <strong> </strong>
  <strong>பெற்ற</strong>
  <strong> </strong>
  <strong>செயன்முறை</strong>
  :- எந்த நபர் அல்லது நபர்கள் அல்லது அமைப்புகளினூடாக ஹெலவிரு தளத்தில்
  வாங்குவதற்கு அல்லது விற்பதற்கு இடம்பெறுகின்ற நடைமுறை
</p>
<h2>
  பாவனைக்குரிய நிபந்தனைகள்
</h2>
<h2>
  4.0 உங்கள் கணக்கு
</h2>
<p>
  இணையத்தளத்தினால் வழங்கப்படுகின்ற குறிப்பிட்ட சேவைகளைப் பெற்றுக் கொள்வதற்கு
  எங்களுடன் ஒரு கணக்கை உருவாக்குமாறு அல்லது உருவாக்கப்படுகின்ற கணக்கை
  பூரணப்படுத்துவதற்கு சுய தகவல்களை வழங்குமாறு நாங்கள் உங்களை வேண்டுவோம்.
  ஏதேனும் காரணத்தை வழங்காமல் அல்லது முன்னறிவித்தல் எதனையும் வழங்காது
  எமக்குரிய தனியான முழுமையான தற்றுணிவின் பிரகாரம் எவ்வேளையிலும் உங்களது
  பாவனையாளர் பெயரை அல்லது கடவூச் சொல்லை அல்லது இரண்டையும்
  செல்லுபடியற்றதாக்குவோம். அத்தகைய தேவைப்பாட்டினால் அல்லது
  செல்லுபடியாக்கலினால் அல்லது அவற்றுடன் சம்பந்தப்பட்டதாக ஏற்படும் உருவாகும்
  தோன்றும் இழப்புக்களுக்கு நாங்கள் எவ்விதத்திலும் பொறுப்புடைமையானவர்கள்
  அல்லர்.
</p>
<p>
  உங்களது பாவனையாளர் அடையாளம், கடவுச் சொல், கணக்கு விபரங்கள் மற்றும் அவை
  சம்பந்தப்பட்ட தனிப்பட்ட தகவல்களின் இரகசியங்களைப் பாதுகாப்பது உங்களது
  பொறுப்பாகும். நீங்கள் இந்தப் பொறுப்புடைமையை ஏற்றுக் கொள்வதற்கு
  உடன்படுவதுடன் எவ்வேளையிலும் உங்களது கணக்கும் அது சம்பந்தப்பட்ட விபரங்களும்
  கவனமாகப் பாதுகாக்கப்படுவதை நீங்கள் நிச்சயப்படுத்திக்கொள்ள வேண்டும். உங்களது
  கணக்கு துஷ்பிரயோகம் செய்யப்படுவதைத் தடுப்பதற்கு அவசியமான அனைத்து
  நடவடிக்கைகளும் மேற்கொள்ளப்பட வேண்டும். உங்களது கடவுச் சொல் வேறொருவரினால்
  உபயோகிக்கப்படுகின்றது அல்லது உங்கள் கடவுச் சொல் அங்கீகாரமளிக்கப்படாத
  வகையில் உபயோகிக்கப்படுகின்றது அல்லது உபயோகிக்கப்படுவது போல் தோன்றுகின்றது
  என நீங்கள் நம்பினால் நீங்கள் எங்களுக்கு உடனடியாக அதனை அறிவிக்க வேண்டும்.
  இணையத்தளத்தின் பாவனையிலும் அது சம்பந்தமாக வழங்கப்படுகின்ற சேவைகளின் அல்லது
  தனிப்பட்ட தகவல்கள் தரவுகள் அல்லது தொடர்பாடல்களின் அணுகுதல் அல்லது
  அவையிரண்டிலும் உங்களது கணக்கும் கடவுச் சொல்லும் உங்களினால் அல்லது
  உங்களினால் அங்கீகாரமளிக்கப்பட்டவரினால் சந்தர்ப்பத்திற்கேற்ற வகையில்
  செயற்படுத்தப்படுமென்பதை நீங்கள் ஏற்று உடன்பட வேண்டும். இணையத்தளத்தின்
  ஏதேனும் அணுகலிற்கு அல்லது இணையத்தளத்தினால் வழங்கப்படுகின்ற ஏதேனும் சேவையைப்
  பாவிப்பதற்கு அல்லது அவையிரண்டுக்கும் கட்டுப்படுவதற்கு நீங்கள் உடன்பட
  வேண்டும். (அவ்வணுகல் அல்லது பாவனையானது உங்களால் அங்கீகரிக்கப்பட்டதாக
  இருப்பினும் சரி, இல்லாவிடினும் சரி) அவை உங்களால் கொண்டு செல்லப்பட்டால்
  அல்லது பரப்பப்பட்டால் அதற்கான முழுப் பொறுப்பும் கடப்பாடும் உங்களையே
  சார்ந்தது என்ற வகையில் அதன் மீது அதன் சார்பாக நடவடிக்கை எடுக்கும் அதிகாரம்
  (ஆனால் கட்டாயக் கடமை அல்ல) எங்களுக்குண்டு என்பதை நீங்கள் ஏற்றுக்கொள்ள
  வேண்டும். உங்களது கணக்கினூடாக இணையத்தளத்தில் நடைபெறுகின்ற பாவனை அல்லது
  அணுகலிலிருந்து அனைத்து இழப்புக்களுக்கும் எதிராக எங்களை முழுமையாக
  விடுவிப்பதற்குப் பூரணமாக உடன்பட்டுக் கட்டுப்படுகின்றீர்கள் என்பதை நீங்கள்
  மேலும் ஏற்றுக்கொண்டு உடன்பட வேண்டும்.
</p>
<p>
  உங்களால் எங்களுக்கு வழங்கப்படுகின்ற தகவல்கள் அனைத்தும் சரியானதும்,
  பூரணமானதும் என்பதைத் தயவுசெய்து நீங்கள் நிச்சயப்படுத்திக் கொள்ளுங்கள்.
  உங்கள் கணக்கு இணைப்பை அணுகுவதன் மூலம் தேவையான நேரங்களில் உங்கள் கணக்குத்
  தொடர்பான விபரங்களைப் புதுப்பித்துக் கொள்வதற்கு நீங்கள்
  கடமைப்பட்டுள்ளீர்கள். இணையத்தளத்தில் உங்களது கணக்கை அணுகுவதன் மூலம் சில
  தகவல்களை உங்களுக்குப் புதுப்பிக்க முடியாதிருந்தால் இத்தகைய மாற்றங்கள்
  தொடர்பில் உங்களுக்கு உதவுவதற்காக எமது வாடிக்கையாளர் சேவைகள் தொடர்பு
  வழிமுறைகளினூடாக எமக்கு அறிவிக்க வேண்டும். எவ்வேளையிலும், முன்னறிவிப்பு
  எதுவுமின்றி இணையத்தளத்தை அணுகுவதை மறுப்பதற்கு, கணக்குகளை
  முடிவுறுத்துவதற்கு, உள்ளடக்கங்களை அகற்றுவதற்கு அல்லது திருத்துவுதற்கு
  எமக்கு உரிமையுண்டு. நாம் எவ்வேளையிலும் எமது தனிப்பட்ட முழுமையான தற்றுணிவின்
  அடிப்படையில் எவ்வித காரணமோ அல்லது முன்னறிவிப்போ வழங்காமல் உங்களது சுய
  தரவுகளைப் புதுப்பிக்க வேண்டுவோம் அல்லது உடனடியாக கணக்கையும் அதனுடன்
  தொடர்பான விபரங்களையும் செல்லுபடியற்றதாக்குவோம். அத்துடன் இவ்வேண்டுதல்
  அல்லது செல்லுபடியாக்குதலின் காரணமாக அல்லது அவற்றுடன் தொடர்பாக ஏற்படுகின்ற
  அல்லது தோன்றுகின்ற அல்லது உருவாகின்ற இழப்புக்களுக்கு நாங்கள் எவ்விதத்திலும்
  பொறுப்புடைமையானவர்கள் அல்லர். உங்களது கணக்கைப் பாதுகாத்து வைத்துக்
  கொள்வதற்காகக் காலத்திற்குக் காலம் நீங்களாகவே அல்லது அமைப்பினால்
  வேண்டப்படும் போது உங்களது கடவுச் சொல்லை மாற்றுவதற்கு இத்தால் உடன்பட
  வேண்டும். அத்துடன் உங்களது கணக்கின் அந்தரங்கத்தைப் பாதுகாப்பது உங்கள்
  பொறுப்பாக அமைவதுடன் பாவனையாளர் பெயர் அல்லது கடவுச் சொல் அவையிரண்டினதும்
  வெளிப்படுத்துகை அல்லது பாவனைக்கு (அப்பாவனை அங்கீகரிக்கப்பட்டதாக இருந்தாலும்
  சரி, அங்கீகரிக்கப்படாததாக இருப்பினும் சரியே) நீங்களே பொறுப்புச் சொல்ல
  வேண்டியவர்களாவர்.
</p>
<h2>
  5.0 அந்தரங்கம்
</h2>
<p>
  தயவுசெய்து எமது வாடிக்கையாளர் தனியுரிமைக் கொள்கையை அவதானிக்கவும். இது
  உங்களது இணையத்தளத்தின் வருகையையும் ஆளும். உங்களால் எங்களுக்கு
  வழங்கப்படுகின்ற சுயதகவல்கள்/தரவுகள் அல்லது இணையத்தளத்தின் உங்கள் பாவனை
  என்பன வாடிக்கையாளரின் தனியுரிமைக் கொள்கைக்கும் ஏற்புடைத்தான சட்டங்கள்
  மற்றும் ஒழுங்கு விதிகளுக்கமைவாகக் கடுமையான அந்தரங்கமாகப் பேணப்படும்.
</p>
<h2>
  6.0 தகவல் பரிமாற்றத்திற்கான தளம்
</h2>
<p>
  ஒரு இணைப்பிலான தளத்தைக் கொண்ட இவ்விணையத் தளமானது உங்களது தேர்வினடிப்படையில்
  உங்களுக்கேற்ற முறையில் கொடுப்பனவூகளை வழங்குவதன் மூலம் எந்த
  இடத்திலிருந்தும், எவ்வேளையிலும் அதில் குறிப்பிடப்பட்டுள்ள விலைக்குப்
  பட்டியிலிடப்பட்ட பொருட்களை வாங்குவதற்கு முடியூமானதாக்குகின்றது என்பதை
  நீங்கள் ஏற்று விளங்கி ஒப்புக் கொண்டுள்ளீர்கள். நாங்கள் இதனை
  எளிதாக்குபவர்ளேயன்றி ஒரு தரப்பினர்களாக முடியாது என்பதையூம் அல்லது
  இணையத்தளத்தில் நடைபெறுகின்ற எந்தவொரு பரிவர்த்தனையையூம் எவ்விதத்திலும்
  கட்டுப்படுத்த முடியாது என்பதையும் உங்களுக்குக் கிடைக்கக் கூடிய ஒரு
  சுதந்திரமான சேவை வழங்குனராக ஒரு கொடுப்பனவூ நுழைவாயிலாக இயங்க முடியாது
  என்பதையூம் நீங்கள் மேலும் ஏற்று ஒப்புக் கொண்டுள்ளீர்கள். அதன் பிரகாரம்
  இணையத்தளத்தில் பொருட்களின் விற்பனைக்கான உடன்படிக்கை எமது இணையத்தளத்தில்
  உங்களுக்கும் உங்களது வர்த்தகருக்குமிடையில் மாத்திரமே காணப்படும். சேவை
  வழங்குனருக்கும் உங்களுக்குமிடையில் நடைபெறுகின்ற கொடுப்பனவு நடைமுறையானது
  இலத்திரனியல் அட்டைகளினூடாக முற்பணக் கொடுப்பனவுகள் நடைபெறும் போது வழங்குனர்
  வங்கியுடன் சம்பந்தப்பட்டதாக அமையும். இதன் பிரகாரம் இணையத்தளத்தினூடாக
  கொடுப்பனவு ஒப்பந்தமானது எங்களது இணையத்தளத்தில் பட்டியலிடப்பட்டதன் பிரகாரம்
  உங்களுக்கும் உங்களது சேவை வழங்குனருக்குமிடையில் மாத்திரமே காணப்படும்.
</p>
<h2>
  7.0 இணையத்தளம் தொடர்ச்சியாகக் கிடைத்தல்
</h2>
<p>
  சேவைகள் இடையூறுகளுக்குட்படமாட்டாது அல்லது தவறுகள் எதுவுமற்றது என நாங்கள்
  உத்தரவாதம் அளிக்கவில்லை. ஆகக் குறைந்தளவிலான சேவை இடையூறுகளுடன் சாத்தியமான
  வகையில் சேவைகளை வழங்குவதற்கு இதயுசுத்திடன் எம்மாலான முயற்சிகளை வழங்குவோம்
  என உறுதி கூறுகின்றோம்.
</p>
<p>
  சேவை இடையூறுகள், சேவை பெறுனரின் தவறுகள், இணையத்தள இணைப்புக்களில் ஏற்படும்
  பிரச்சினைகள், தவறான தகவல்கள்,ஹெலவிரு வழங்கிகளில் அங்கீகரிக்கப்படாத அணுகல்,
  இணையத்தள அலைவரிசை நெருக்கடிகள், மின்சாரத் துண்டிப்பு, காழ்ப்புணர்ச்சியூம்
  இயற்கை அனர்த்தங்களும் உள்ளடங்கிய ஆனால் இவை மாத்திரம்தான் என்ற மட்டுப்பாடற்ற
  பிரச்சினைகள், ஹெலவிரு சேவையை உபயோகிக்கும் போது அல்லது உபயோகிக்க முடியாத
  போது ஏற்படுகின்ற பிரச்சினைகளின் விளைவாகக் கோரப்படும் எந்தக் கோரிக்கைக்கும்
  ஹெலவிரு இணையத்தளம் ஒருபோதும் பொறுப்புடைமையாக மாட்டாது. இத்தகைய
  சேதங்களுக்குரிய வாய்ப்புக்கள் காணப்படுகின்றன என்று ஆலோசனை
  வழங்கப்பட்டிருப்பினும் நட்டம், வியாபாரத்தில் அல்லது வியாபார வாய்ப்பில்
  நஷ்டம், உபயோகிப்பதில் நட்டம், தரவுகளில் நட்டம் போன்றவை அடங்கலாக ஆனால் இவை
  மாத்திரம்தான் என்ற மட்டுப்பாடுகளற்ற இழப்புக்களுக்குரிய நேரடியான, மறைமுகமான,
  தற்செயலான, விஷேட, தொடர்ச்சியான அல்லது தண்டனைக்குரிய இழப்பீடுகளுக்கு ஹெலவிரு
  தளமானது பொறுப்புடைமையானதல்ல.
</p>
<h2>
  8.0 இணையத்தளத்தை அணுகுவதற்குரிய உத்தரவுப் பத்திரம்
</h2>
<p>
  இணையத்தளத்தை அணுகுவதன் மூலம் உங்களால் சட்டத்திற்குக் கட்டுப்படுகின்ற
  உடன்படிக்கைகளை உருவாக்க முடியுமென உறுதிப்படுத்த வேண்டுமென நாம் உங்களை
  வேண்டுகின்றோம். அதனால் நீங்கள் குறைந்தது 18 வயதையுடையவர் அல்லது பெற்றௌரின்
  அல்லது சட்டப் பாதுகாவலரின் கண்காணிப்பின் கீழ்தான் நீங்கள் இணையத்தளத்தை
  அணுகுகின்றீர்கள் என்பதை உறுதிப்படுத்திக் கொள்ள வேண்டும். இணையத்தளத்தில்
  பட்டியலிடப்பட்ட சொந்தப் பொருள்களையும், சேவைகளையும் பெறவேண்டுமென்ற நோக்கில்
  இத்தால் விவரிக்கப்பட்டுள்ள விதிமுறைகள் மற்றும் நிபந்தனைகளின் அடிப்படையில்
  இணையத்தளத்தைப் பாவிப்பதற்காக இடமாற்றப்படாத திரும்பப் பெறக்கூடிய
  பிரத்தியேகமற்ற உத்தரவுப் பத்திரத்தை நாங்கள் உங்களுக்கு வழங்குகின்றோம்.
  வர்த்தகப் பாவனை அல்லது எவரேனும் மூன்றாம் நபர் சார்பில் உபயோகித்தல் என்பன
  தடைசெய்யப்பட்டுள்ளன. ஆனால் முன்கூட்டியே இதற்கான அனுமதியை எம்மிடமிருந்து
  பெற்றிருந்தால் அச்சந்தர்ப்பங்கள் விலக்களிக்கப்படும். இந்த விதிமுறைகளும்,
  நிபந்தனைகளும் மீறப்பட்டால் இப்பந்தியில் குறிப்பிடப்பட்டுள்ள வழங்கப்பட்ட
  உத்தரவுப் பத்திரம் உங்களுக்கு எவ்வித அறிவித்தலுமின்றி மீளப் பெறப்படும்.
</p>
<p>
  இத்தளத்தில் வழங்கப்பட்டுள்ள உள்ளடக்கமானது தகவல் மற்றும் பரிவர்த்தனை
  நோக்கங்களுக்காக வழங்கப்பட்டவையாகும். விலை, கிடைக்கும் அளவு, அம்சங்கள்,
  மேலும் சேர்க்கப்பட வேண்டியவை மற்றும் இணையத்தளத்தில் குறிப்பிடப்பட்டுள்ள
  ஏனைய விபரங்கள் அடங்கிய பொருள் பிரதிநிதித்துவங்கள் விற்பனையாளரின்
  பொறுப்புடைமையாகும். இணையத்தளத்தில் வெளிப்படுத்தப்பட்டுள்ள சமர்ப்பணங்கள்,
  அல்லது கருத்துக்கள் என்பனவற்றின் உள்ளடக்கமானது தனிநபர்களினால்
  வழங்கப்பட்டவையே தவிர அவை எமது கருத்துக்களைப் பிரதிபலிக்காது.
</p>
<p>
  இத்தளத்தை அணுகுவதற்கும், சுயபாவிப்பிற்கும் நாம் வரையறுக்கப்பட்ட உத்தரவுப்
  பத்திரத்தை உங்களுக்கு வழங்குவோம். ஆனால் பதிவிறக்கம் செய்வதற்கோ (தேக்கப்
  பக்கங்கள் தவிர்ந்தவை) ஏதேனும் ஒரு வகையில் இணையத்தளத்தை அல்லது அதன் ஒரு
  பகுதியை மாற்றுவதற்கோ உங்களுக்கு அனுமதி வழங்கப்படமாட்டாது. இந்த அனுமதிப்
  பத்திரத்தில் கீழ்வருவன உள்ளடக்கப்படமாட்டாது.
</p>
<p style="margin-left: 20px;">
  8.1. இவ்விணையத்தளத்தின் அல்லது அதன் உள்ளடக்கத்தின் மீள் விற்பனை அல்லது
  வர்த்தகப் பாவனை
</p>
<p style="margin-left: 20px;">
  8.2. ஏதேனும் பொருட்களின் பட்டியல்கள், விபரங்கள், விலைகள் என்பனவற்றின்
  ஏதேனும் சேகரிப்பும், பாவனையும்
</p>
<p style="margin-left: 20px;">
  8.3. இவ்விணையத்தளத்தின் அல்லது இதன் உள்ளடக்கங்களின் ஏதேனும் அடிப்படைகளை
  உபயோகித்தல்.
</p>
<p style="margin-left: 20px;">
  8.4. மற்றொரு வர்த்தகரின் நன்மை கருதி கணக்குத் தகவல்களைப் பதிவிறக்கம்
  செய்தலும், பிரதி செய்தலும்
</p>
<p style="margin-left: 20px;">
  8.5. தரவுகளைத் தோண்டுவதற்கு ரோபோக்களுக்கு அல்லது ஒத்த தரவுகள், சேகரிப்பு
  மற்றும் கருவிகளைப் பிரித்தெடுப்பதற்கு உபயோகித்தல்.
</p>
<p>
  இத்தளம் அல்லது இத்தளத்தின் ஒரு பாகம் (ஏதேனும் பதிப்புரிமை செய்யப்பட்ட
  பொருட்கள், வியாபாரச் சின்னங்கள் அல்லது வேறு ஏதேனும் தனியுரிமைத் தகவல்கள்
  உள்ளடங்கிய ஆனால் இவை மாத்திரமென மட்டுப்படுத்தப்படவில்லை) ஏற்புடைத்தான
  வகையில் எங்களது வெளிப்படையான எழுத்துமூல சம்மதமின்றி ஏதேனும் வர்த்தக
  நோக்கங்களுக்காக மீள் உருவாக்கப்படவோ, நகலாக்கப்படவோ, பிரதிசெய்யப்படவோ,
  விற்கப்படவோ, மீள் விற்பனைசெய்யப்படவோ, பார்க்கப்படவோ, பகிர்ந்தளிக்கப்படவோ
  அல்லது வேறு ஏதேனும் வகையில் பாவிக்கப்படவோ மாட்டாது.
</p>
<p>
  எமது வெளிப்படையான எழுத்துமூல சம்மதமின்றி ஏதேனும் வியாபாரச் சின்னங்களையோ,
  குறியீடுகளையோ, வேறு ஏதேனும் தனியுரிமைத் தகவல்களையோ (படங்கள், உரை, பக்க
  வடிவமைப்பு அல்லது வடிவம் உள்ளடங்கலாக) இணைப்பதற்கு அல்லது இணைப்பதற்கான
  தொழினுட்பங்களைக் கையாள்வதற்கு முடியாது. ஏற்புடைத்தான வகையில் எமது
  வெளிப்படையான எழுத்துமூல சம்மதமின்றி எமது பெயரை அல்லது வியாபாரச் சின்னத்தை
  உபயோகித்து குறியீடுகளையும், ஏனைய வேறு உரைகளையும் உபயோகிக்க முடியாது.
  ஏதேனும் அங்கீகாரமளிக்கப்படாத பாவனையினால் முன்னறிவித்தல் எதுவுமின்றி எமது
  இணையத்தளத்தை அணுகுவதற்கு உங்களுக்கு எங்களால் வழங்கப்பட்ட அனுமதி அல்லது
  உத்தரவுப் பத்திரம் முடிவுறுத்தப்படும். ஏற்புடைத்தான வகையில் எமது
  வெளிப்படையான எழுத்துமூல அனுமதியின்றி எமது குறியீட்டை அல்லது வேறு தனியுரிமை
  வரைபுகளை அல்லது வியாபாரச் சின்னத்தை வர்த்தக அல்லது வேறு ஏதேனும்
  நோக்கங்களுக்காக ஒரு வெளிவாரி இணைப்பின் பாகமொன்றாகப் பாவிக்க முடியாது.
</p>
<h2>
  9.0 உங்களது நடத்தை
</h2>
<p>
  இப்பிரிவினுள் பட்டியலிடப்பட்டுள்ள தடைசெய்யப்பட்ட செயற்பாடுகளை
  நடைமுறைப்படுத்துவது இல்லையென நீங்கள் உடன்பட்டு பொறுப்பேற்றுள்ளீர்கள்.
  இத்தகைய செயற்பாடுகளைப் பொறுப்பெடுத்தலானது எங்களுடனான எமது கணக்கு, சேவைகள்,
  விமர்சனங்கள், விடுவித்தல்கள் அல்லது ஏனைய நடைமுறையிலுள்ள பூரணப்படுத்தப்படாத
  பரிவர்த்தனைகள் என்பனவற்றை உடனடியாக இரத்துச் செய்வதற்கு வழிவகுக்கும்.
  அத்துடன் இவ் விடயங்களுக்கு எதிராக கடுமையான சட்ட நடவடிக்கைகளும்
  எடுக்கப்படும்.
</p>
<p>
  இவ்விணையத்தளத்தை அல்லது அதன் அணுகலை இடையூறு செய்தல், சேதப்படுத்தல் அல்லது
  ஏதேனும் வகையில் பலவீனப்படுத்தல் அல்லது அவற்றுக்கு வழிவகுத்தலுக்கு
  இவ்விணையத்தளத்தை நீங்கள் பாவிக்கக்கூடாது. இவ்விணையத்தளத்திற்கு அல்லது அதன்
  பணியாளர்களுக்கு, அலுவலர்களுக்கு, பிரதிநிதிகளுக்கு, பங்காளர்களுக்கு அல்லது
  அத்தளத்துடன் அல்லது அதன் அணுகலுடன் நேரடியாக அல்லது மறைமுகமாகத்
  தொடர்புப்பட்ட வேறு எவரேனும் தரப்பினருக்கு ஊறு விளைவிக்கும் அல்லது ஊறு
  விளைவிப்பதற்குச் சாத்தியமான வகையிலான நடவடிக்கைகளில் நீங்கள் ஈடுபடக்கூடாது.
  இடையூறு விளைவிக்கவோ, சேதப்படுத்தவோ அல்லது ஏதேனும் வகையில் பலவீனப்படுத்தவோ
  கூடாது. உங்களது கணினியில் இருந்து எங்களுக்கு அனுப்பப்படுகின்ற எல்லா
  இலத்திரனியல் தொடர்பாடல்களுக்கும், உள்ளடக்கங்களுக்கும் நீங்கள்தான்
  பொறுப்புதாரிகளேயொழிய நாங்கள் அல்ல என்பதை நீங்கள் விளங்கிக்கொள்ள வேண்டும்.
  பின்வரும் நோக்கங்களுக்காக இந்த இணையத்தளத்தை உபயோகிப்பதிலிருந்து நீங்கள்
  தடைசெய்யப்படுகின்றீர்கள்.
</p>
<p style="margin-left: 20px;">
  9.1. மோசடியான நோக்கங்கள், குற்றவியல் குற்றங்களுடன் தொடர்புடைய செயற்பாடுகள்
  அல்லது உங்களுக்குச் சொந்தமல்லாத ஏதேனும் பொருட்களை அனுப்புவதற்கு அல்லது
  பாவிப்பதற்கு அல்லது மீள் உபயோகத்திற்கு செயற்படுகின்ற சட்ட விரோதமான
  நடவடிக்கைகள் சட்டவிரோதமானவை, குற்றத்திற்குரியவை (பாலியல் ரீதியான
  வெளிப்பாடுகளின் உள்ளடக்கம் அல்லது இனவாதத்தையூம் மத வெறியையூம் தூண்டுகின்ற
  செயற்பாடுகள், வெறுக்கத்தக்க உடல் ரீதியான தீங்கு உள்ளடங்களாக ஆனால் இவை
  மாத்திரமென மட்டுப்படுத்தப்பட வில்லை) ஏமாற்றுதல்இ பிழையாக வழிநடத்தல், தவறு,
  அநாகரீகம்,துன்புறுத்தல், நிந்தனை, அவதூறு, ஊறு விளைவித்தல், ஆபாசம், காமம்,
  அச்சப்படுத்தல், அச்சுறுத்தல், இனரீதியாக ஆட்சேபிக்கத்தக்க செயற்பாடுகள்,
  இழிவுபடுத்தல் அல்லது பதிப்புரிமை, வியாபாரச்சின்னம், இரகசியம்,
  அந்தரங்கங்கள், ஏனைய தனியுரிமைத் தகவல்கள் அல்லது உரிமைகளை மீறுதல் அல்லது
  மூன்றாம் தரப்பினர்களுக்கு ஏதேனும் வகையில் ஊறு விளைவித்தல், பணமோசடி அல்லது
  சூதாட்டத்தை ஊக்குவித்தல் அல்லது அவற்றுடன் சம்பந்தப்படுதல் அல்லது பராய வயதை
  அடையாதவர்களுக்கு ஏதேனும் வகையில் ஊறு விளைவித்தல், இன்னொரு மனிதனை
  ஆள்மாறாட்டம் செய்தல் அல்லது இலங்கையின் ஒற்றுமை, ஒருமைப்பாடு, பாதுகாப்பு
  அல்லது இறையாண்மைக்கு அச்சுறுத்தலாக இருத்தல் அல்லது வெளிநாடுகளுடனான நட்பு
  நிலைக்கு அச்சுறுத்தலாக இருத்தல் அல்லது ஏதேனும் ஒரு வகையில் மறுப்பவராகவும்,
  சட்டத்திற்கு முரணானவராகவும் காணப்படுதல் அல்லது மென்பொருள் வைரசுகளை
  உள்ளடக்கியிருத்தல் அல்லது கொண்டிருத்தல், அரசியல் பிரச்சாரம் வர்த்தகப்
  பரிந்துரைகள், தொடர் கடிதங்கள், பாரிய அஞ்சல்கள், சட்ட முரணான நோக்கங்களுக்காக
  தொடர்பற்ற விதத்தில் இணையத்தளத்தை உபயோகித்தல்.
</p>
<p style="margin-left: 20px;">
  9.2. எரிச்சலூட்டுகின்ற அசௌகரியத்தை ஏற்படுத்துகின்ற, தேவையற்ற சிரமங்களை
  ஏற்படுத்தல்.
</p>
<p style="margin-left: 20px;">
  9.3. எங்களால் ஊகிக்கப்பட்டவை தவிர்ந்த வேறு ஏதேனும் நோக்கங்கள்.
</p>
<p style="margin-left: 20px;">
  9.4. எவரேனும் ஒரு நபரை அல்லது நிறுவனத்தை ஆள்மாறாட்டம் செய்தல் அல்லது
  எவரேனும் ஒரு நபர் அல்லது நிறுவனத்துடனான உங்களது இணைப்பை பிழையாகக்
  குறிப்பிடுதல் அல்லது வேறு வகையில் தவறாகப் பிரதிநிதித்துவம் செய்தல்.
</p>
<p style="margin-left: 20px;">
  9.5. அங்கீகாரமளிக்கப்படாத வகையில் அணுக முயற்சித்தல் அல்லது இணையத் தளத்துடன்
  அல்லது சேவைகளுடன் இணைக்கப்பட்டுள்ள ஏனைய கணினி அமைப்புக்களை அல்லது இணையத்
  தளங்களைக் குறுக்கீடு செய்தல் அல்லது இடையூறு செய்தல்.(2007ஆம் ஆண்டின் 24ஆம்
  இலக்க கணினி வழிக் குற்றச் சட்டம்)
</p>
<p style="margin-left: 20px;">
  9.6. வைரசு, சேதப்படுத்துகின்ற கூறுகள், தீங்கிழைக்கக்கூடிய குறியீடுகள்
  அல்லது தீங்கிழைக்கக்கூடிய கூறுகள் உள்ளடங்கிய அல்லது அவை உள்ளடங்கியிருக்கும்
  என நீங்கள் சந்தேகப்படும் வேளையில் அவ்வாறான மென் பொருளையும், பொருட்களையும்
  ஏதேனும் வகையில் பாவித்தலும், பதிவிறக்கலும், இது இணையத் தளத்தின் தரவுகளைப்
  பலவீனப்படுத்தும் அல்லது சிதைக்கும் அல்லது மற்றொரு வாடிக்கையாளரின் கணினி
  அல்லது கைபேசி அல்லது இணையத்தளத்தின் இயக்கப்பாட்டுக்கு இடைஞ்சலை ஏற்படுத்தும்
  மற்றும் சம்பந்தப்பட்ட ஏதேனும் கணினி வலையமைப்பின் ஏற்றுக்கொள்ளப்பட்ட பாவனைக்
  கொள்கைகள் ஏதேனும் ஏற்றுக்கொள்ளப்பட்ட இணையத்தளத் தராதரங்கள் ஏதேனும் ஏற்றுக்
  கொள்ளப்பட்ட சட்டங்கள் என்பவற்றுக்கமைவானதன்றி வேறு ஏதேனும் வகையில்
  இணையத்தளத்தைப் பாவித்தல்.
</p>
<h2>
  10.0 உங்களது சமர்ப்பணம்
</h2>
<p>
  நீங்கள் இணையத்தளத்திற்கு சமர்ப்பிக்கும் அல்லது எங்களுக்கு வழங்கும்
  கேள்விகள், விமர்சனங்கள், கருத்துக்கள் மற்றும் பரிந்துரைகள் (மொத்தமாக
  'சமர்ப்பணங்கள்")போன்றவை. ஆனால் அவை மாத்திரம் என்று மட்டுப்படுத்தப்படவில்லை.
  இவை எங்களுடைய தனித்துவமான பிரத்தியேக சொத்தாக மாறும். அவை மீண்டும்
  உங்களுக்குத் திருப்பியளிக்கப்படமாட்டாது. ஏதேனும் சமர்ப்பணத்திற்கு
  ஏற்புடைத்தான உரிமைகளுக்கு மேலதிகமாக நீங்கள் இணையத்தளத்தில் ஏதேனும்
  கருத்துக்கள் அல்லது விமர்சனங்களைப் பதிவிடும் போது அவ் விமர்சனம் அல்லது
  கருத்து அல்லது ஏதேனும் உள்ளடக்கத்துடன் சம்பந்தப்பட்ட நீங்கள் சமர்ப்பித்த
  பெயரை உபயோகிப்பதற்கு நீங்கள் எங்களுக்கு உரிமையை வழங்குவீர்கள். நீங்கள்
  அன்றி வேறு ஒருவர் என்று சொல்லுமுகமாக அல்லது எந்த சமர்ப்பணங்களினதும்
  தோற்றத்தை எங்களுக்கு அல்லது மூன்றாம் தரப்பினர்களுக்கு தவறாக வழிநடத்தும்
  வகையில், பிழையான மின்னஞ்சல் முகவரியைப் பயன்படுத்தக் கூடாது. எவ்வித
  அறிவித்தலோ அல்லது இது தொடர்பில் எங்களுக்கு ஏற்புடைத்தான சட்ட
  வழிமுறைகளுமின்றி ஏதேனும் சமர்ப்பணங்களை நாங்கள் அகற்றுவோம் அல்லது
  திருத்துவோம். ஆனால் கட்டாயமாக அவ்வாறு செய்வோம் என்பதற்கில்லை.
</p>
<h2>
  11.0 ஆட்சேபிக்கத்தக்க உள்ளடக்கத்திற்கெதிரான கோரிக்கைகள்
</h2>
<p>
  நாம் இணையத்தளத்தில் பல வர்த்தகர்களினால் வழங்கப்படுகின்ற ஆயிரக்கணக்கான
  விற்பனைப் பொருட்களின் பட்டியலை இடுகின்றோம். அத்துடன் அப்பட்டியலிற்கான
  பல்வேறுபட்ட கருத்துக்களையும் தொகுத்து வழங்குகின்றோம். ஆகவே விற்பனைக்காகப்
  பட்டியலிடப்படும் ஒவ்வொரு பொருளின் உள்ளடக்கத்தையும் வெளியிடப்படுகின்ற
  ஒவ்வொரு கருத்து அல்லது விமர்சனத்தையும் பற்றி அறிந்திருப்பதற்கான சாத்தியம்
  எங்களுக்கு இல்லை. இதன் பிரகாரம் ஒரு "கோரிக்கை, விமர்சனம், மற்றும்
  தரமிறக்குதல்" அடிப்படையிலேயே நாம் இயங்குகின்றௌம். இவ்விணையத்தளத்தின்
  ஏதேனும் உள்ளடக்கம் சட்டத்திற்கு முரணானது, குற்றத்திற்குரியது (பாலியல்
  ரீதியான வெளிப்பாடுகளின் உள்ளடக்கம் அல்லது இனவாதத்தையும், மதவெறியையும்
  தூண்டுகின்ற செயற்பாடுகள், வெறுக்கத்தக்க உடல் ரீதியான தீங்கு, ஏமாற்றுதல்,
  பிழையாக வழிநடத்தல், தவறு, அநாகரீகம், துன்புறுத்தல், நிந்தனை, அவதூறு, ஊறு,
  ஆபாசம், காமம், அச்சப்படுத்தல், அச்சுறுத்துதல், இனரீதியாக ஆட்சேபிக்கத்தக்க
  செயற்பாடுகள், இழிவூபடுத்தல் அல்லது பதிப்புரிமை, வியாபாரச் சின்னம்,
  இரகசியம், அந்தரங்கங்கள், ஏனைய தனியூரிமைத் தகவல்கள் அல்லது உரிமைகளை மீறுதல்
  அல்லது மூன்றாம் தரத்தவர்களுக்கு ஏதேனும் வகையில் ஊறு விளைவித்தல், பணமோசடி
  அல்லது சூதாட்டத்தை ஊக்குவித்தல் அல்லது அவற்றுடன் சம்பந்தப்படுதல் அல்லது
  பராய வயதையடையாதவர்களுக்கு ஏதேனும் வகையில் ஊறுவிளைவித்தல், இன்னொரு மனிதனை
  ஆள்மாறாட்டம் செய்தல் அல்லது இலங்கையின் ஒற்றுமைஇ ஒருமைப்பாடு, பாதுகாப்பு
  அல்லது இறையாண்மைக்கு அச்சுறுத்தலாக இருத்தல் அல்லது வெளிநாடுகளுடனான நட்பு
  நிலைக்கு அச்சுறுத்தலாக இருத்தல் அல்லது ஏதேனும் ஒரு வகையில் மறுப்பவராகவும்
  சட்டத்திற்கு முரணானவராகவும் காணப்படுதல் அல்லது மென்பொருள் வைரசுகளை
  உள்ளடக்கியிருத்தல் போன்றவை, ஆனால் இவை மாத்திரமென மட்டுப்படுத்தப்படவில்லை,
  என்பன போன்ற விடயங்கள் (மறுக்கத்தக்க உள்ளடக்கம்) காணப்படுவதாக நீங்கள்
  நம்பினால் legal@ helaviru.lk என்ற மின்னஞ்சல் முகவரியினூடாக எழுத்துமூலம்
  உடனடியாக நீங்கள் எங்களுக்கு அறிவிக்கலாம். முறைப்பாடு செய்யப்பட்ட
  மறுக்கத்தக்க உள்ளடக்கத்தை விசாரணை செய்து அகற்றுவதற்குச் சாத்தியமான எல்லா
  முயற்சிகளையும் நியாயமான காலவரையறைக்குள் நாம் மேற்கொள்வோம்.
</p>
<p>
  உங்களது பெயர், முகவரி, தொடர்புகொள்ளக் கூடிய தகவல்கள் மற்றும் மறுக்கத்தக்க
  உள்ளடக்கத் தரத்தவரின் பெயர், மறுக்கத்தக்க சம்பவங்கள், மறுப்பதற்கான
  ஆதாரங்கள் போன்ற பல்வேறுபட்ட கோரிக்கைகளுடன் சம்பந்தப்பட்ட சாத்தியமான
  விபரங்களை வழங்கியூள்ளதை நிச்சயப்படுத்திக் கொள்ளுங்கள். பூரணப்படுத்தப்படாத
  விபரங்களை வழங்குதல் உங்கள் கோரிக்கையைச் செல்லுபடியற்றதாக்குவதுடன் சட்ட
  நோக்கங்களிற்கு உபயோகிக்க முடியாதவையாகவூம் மாற்றிவிடும் என்பதைத் தயவுசெய்து
  கவனத்திற் கொள்க.
</p>
<h2>
  12.0 வரம்பு மீறத்தக்க உள்ளடக்கத்திற்கெதிரான கோரிக்கைகள்
</h2>
<p>
  மற்றவர்களின் புலமைச் சொத்துக்களை நாம் மதிக்கின்றோம். வரம்பு மீறத்தக்க
  விடயங்களுக்கு உங்களது புலமைச் சொத்துரிமைகள் உபயோகிக்கப்படுவதாக நீங்கள்
  நம்பினால் legal@ helaviru.lk இனூடாக தயவூசெய்து எங்களுக்கு அறிவியூங்கள்.
  கவனமெடுக்க வேண்டிய உங்களது விடயங்களைக் கவனமெடுத்து உரையாற்றுவதற்குரிய
  சாத்தியமான எல்லா நடவடிக்கைகளையும் நியாயமான கால எல்லைக்குள் நாங்கள்
  மேற்கொள்வோம். உங்களது பெயர், முகவரி, தொடர்புகொள்ளக் கூடிய தகவல்கள் மற்றும்
  வரம்பு மீறிய தரத்தவரின் பெயர், வரம்பு மீறப்பட்ட சம்பவங்கள், வரம்பு
  மீறப்பட்டமைக்கான ஆதாரங்கள் போன்ற பல்வேறுபட்ட கோரிக்கைகளுடன் சம்பந்தப்பட்ட
  சாத்தியமான விபரங்களை வழங்கியுள்ளதைத் தயவு செய்து நிச்சயப்படுத்திக்
  கொள்ளுங்கள்.
</p>
<p>
  பூரணப்படுத்தப்படாத விபரங்களை வழங்குதல், உங்கள் கோரிக்கையைச்
  செல்லுபடியற்றதாக்குவதுடன் சட்ட நோக்கங்களிற்கு உபயோகிக்க முடியாதவையாகவும்
  மாற்றிவிடும் என்பதைத் தயவுசெய்து கவனத்திற் கொள்க. மேலும் பொய்யான அல்லது
  பிழையாக வழிநடத்தக் கூடிய தகவல்களை வழங்குதல் சட்டத்தின் பிரகாரம் ஒரு
  குற்றமாகக் கருதப்படுவதுடன் அரசாங்கத்தினால் பிரசுரிக்கப்பட்டதன் பிரகாரம்
  2003ஆம் ஆண்டின் 36ஆம் இலக்க புலமைச் சொத்துக்கள் சட்டத்திற்கிணங்க சட்ட
  நடவடிக்கைகளை மேற்கொள்வதற்கு ஏதுவாகவும் அமையும்.
</p>
<h2>
  13.0 வியாபாரச் சின்னங்களும், பதிப்புரிமைகளும்
</h2>
<p>
  ஹெலவிரு இணையத்தளம், ஹெலவிரு அடிப்படைகள், ஹெலவிரு குறியீடு மற்றும் எமது
  இணையத்தளத்தில் குறிப்பிடப்பட்டுள்ள ஹெலவிருவுடன் சம்பந்தப்பட்ட ஏனைய
  சின்னங்கள் என்பன சம்பந்தப்பட்ட நியாயாதிக்கங்களில் எமது வியாபாரச்
  சின்னங்களாக அடையாளப்படுத்தப்படும். எமது வரைபுகள், குறியீடுகள், பக்கத்
  தலைப்புகள், பொத்தான் சின்னங்கள், கையெழுத்துப் படிவங்கள், சேவைப் பெயர்கள்
  என்பன எமது வியாபாரச் சின்னங்கள் அல்லது வர்த்தக ஆடைகளாக அமையும். அத்துடன்
  இவை எமக்குச் சொந்தமற்ற ஏதேனும் பொருட்கள் சம்பந்தமாகவோ அல்லது
  வாடிக்கையாளர்கள் மத்தியில் ஏதேனும் ஒரு விதத்தில் குழப்பநிலையை
  ஏற்படுத்துமுகமாகவோ எங்களுக்கு ஏற்றத் தாழ்வூகளையும், இழிவையும் ஏற்படுத்தும்
  வகையிலோ பாவிக்கப்பட முடியாது. இணையத்தளத்தில் காணப்படுகின்ற ஏனைய வியாபாரச்
  சின்னங்கள் எம்மோடு இணைந்த அல்லது இணையாத அல்லது தொடர்புபட்ட அல்லது எங்களால்
  விளம்பரப்படுத்தப்பட்ட அந்தந்த சொந்தக்காரர்களின் சொத்துக்களாகும்.
</p>
<p>
  இவ்விணையத் தளத்தின் பதிப்புரிமை, வியாபாரச் சின்னம், சேவைச் சின்னம்,
  காப்புரிமை அல்லது ஏனைய தனியுரிமைகள் சம்பந்தப்பட்ட அனைத்து உள்ளடக்கங்களும்
  சட்டங்களும், ஏற்புடைத்தான சட்டத்தினதும் சர்வதேச உடன்படிக்கைகளினதும்
  அடிப்படையில் பாதுகாக்கப்படுகின்றன. எல்லா உரிமைகளும் ஒதுக்கப்பட்டுள்ளது.
</p>
<p>
  உரை, வரைபு, குறியீடுகள், மென்பொருள், மூலக் குறியீடு, படங்கள், வியாபாரச்
  சின்னங்கள் உள்ளடங்கலாக, ஆனால் இவை மாத்திரமென மட்டுப்படுத்தப்படவில்லை,
  இணையத்தளத்திலும் கைத் தொலைபேசிப் பயன்பாட்டிலும் காட்சிப்படுத்தப்படுகின்ற
  தகவல்கள் சம்பந்தமான பதிப்புரிமைகளையும் ஏனைய புலமைச் சொத்துரிமைகளையும்
  ஹெலவிரு ஒதுக்கி வைத்துள்ளது. ஏதேனும் ஆவணங்களையோ அல்லது தளப் பொருட்களையோ
  பிரதிபண்ணுவதற்கு ஹெலவிரு பாவனையாளர்களுக்கு இடமாற்றுவதில்லை. ஹெலவிரு
  இணையத்தளத்தின் எழுத்துமூல சம்மதமின்றி எதனையும் மீள் உருவாக்கம் செய்தல்
  சட்டவிரோதமான விடயமாகும்.
</p>
<h2>
  14.0 கோரிக்கையைக் கைவிடல்
</h2>
<p>
  இணையத்தளத்தின் சேவைகளையும், பரிவர்த்தனையையும் உங்களது சொந்தத் துணிச்சலின்
  அடிப்படையிலேயே அணுகுவதாக நீங்கள் ஏற்றுப் பொறுப்பெடுத்துள்ளீர்கள். அத்துடன்
  இணையத்தளத்தினூடாக ஏதேனும் பரிவர்த்தனை செய்வதற்கு நுழைய முன்னர் உங்களது
  மிகச் சிறந்த வேகமான தீர்மானத்தை எடுக்க வேண்டும். வர்த்தகர்களின் எவ்விதமான
  நடவடிக்கைகளுக்கும் அல்லது நடவடிக்கை எடுக்காமைக்கும், பொருட்களின்
  வர்த்தகர்களினது அல்லது உற்பத்தியாளர்களினது நிபந்தனைகள்,
  பிரதிநிதித்துவங்கள், உத்தரவாதங்களின் மீறலுக்கும் நாங்கள் எவ்விதத்திலும்
  பொறுப்புடைமையுடையவர்களல்லர். அத்துடன் இது சம்பந்தமான அனைத்துப்
  பொறுப்புடைமைகளில் இருந்தும் நாம் இத்தால் விலகிக் கொள்கிறோம். உங்களுக்கும்,
  பொருட்களின் வர்த்தகர்கள் அல்லது உற்பத்தியாளர்களுக்குமிடையில் நடைபெறுகின்ற
  ஏதேனும் பிணக்குகள் அல்லது கருத்து வேறுபாடுகளை நாம் சமரசப்படுத்தவோ தீர்க்கவோ
  மாட்டோம்.
</p>
<p>
  இணையத்தளத்தில் பட்டியலிடப்பட்டுக் காட்சிப்படுத்தப்பட்டுள்ள பொருட்களின்
  உள்ளடக்கங்களின் (உற்பத்தி அல்லது விலை பற்றிய தகவல்கள் மற்றும் விவரக்
  குறிப்புக்கள் அல்லது அவையிரண்டும் உள்ளிட்ட) ஒரு குறிப்பிட்ட நோக்கத்திற்கான
  தரம், பொருந்தக்கூடிய தன்மை, துல்லியம், நம்பகத்தன்மை, பூரணத்தன்மை, காலம்,
  செயற்பாடு, பாதுகாப்பு, வணிகத்தன்மை, பொருத்தப்பாடு அல்லது பொருட்களின்
  சட்டபூர்வத்தன்மை என்பனவற்றின் உத்தரவாதங்கள் அல்லது
  பிரதிநிதித்துவங்களிலிருந்து நாம் வெளிப்படையாக விலகிக் கொள்கின்றௌம். ஆனால்
  எவ்வகையிலான உத்தரவாதமுமின்றி, உள்ளடக்கம், இணையத் தளம், அனைத்து உள்ளடக்கம்,
  தகவல் (பொருட்களின் விலை உள்ளிட்ட) மென்பொருள்இ பொருட்கள்இ சேவைகள் மற்றும்
  இவற்றுடன் சம்பந்தப்பட்ட வரைபுகள் என்பவற்றின் மீது தவறுகள் ஏற்படாமல்
  தவிர்ப்பதற்குரிய முன்பாதுகாப்புக்களை நாம் மேற்கொண்டுள்ளோம். இணையத் தளத்தில்
  விற்பனை செய்யப்படுகின்ற அல்லது கொள்வனவு செய்யப்படுகின்ற ஏதேனும் பொருட்களின்
  கொள்வனவு அல்லது விற்பனை தொடர்பில் வெளிப்படையாகவோ அல்லது மறைமுகமாகவோ
  ஒத்தாசைகளையோ ஒப்புதல்களையோ நாம் வழங்கமாட்டோம். எந்தவொரு சந்தர்ப்பத்திலும்
  பொருட்களின் உரிமைகள், தலைப்புக்கள் அல்லது தன்மைகள் இணையத்தளத்தில் விற்பனை
  செய்யப்படவோ அல்லது காட்சிப்படுத்தப்படவோ மாட்டாது. அத்துடன் இணையத்தளத்தில்
  இடம்பெறுகின்ற எந்தவொரு பரிவர்த்தனை சம்பந்தப்பட்ட விடயங்களுக்கும் ஹெலவிரு
  இணையத்தளம் பொறுப்புடைமை உடையதல்ல.
</p>
<p>
  கொடுப்பனவு வழங்குனர்கள், தவணை வழங்கல்கள் உத்தரவாத சேவைகள் போன்றவை அடங்கலாக
  ஆனால் இவை மாத்திரமென மட்டுப்படுத்தப்படவில்லை, எமது இணையத்தளத்தில்
  பட்டியலிடப்பட்ட ஏதேனும் சேவை வழங்குனர்களின் செயற்பாடுகள் அல்லது
  செயற்பாடுகளின்மைக்கு நாங்கள் எவ்விதத்திலும் பொறுப்புடைமையுடையவர்களல்லர்.
</p>
<p>
  சேவைகள் தடைகளற்றவை அல்லது தவறுகளற்றவையென நாம் உத்தரவாதம் வழங்கவில்லை.
  குறைந்தளவிலான சேவைத் தடைகளுடனான சேவைகளைச் சாத்தியமானவரையில் வழங்குவதற்கு
  நல்லெண்ணத்துடன் ஒவ்வொரு முயற்சியையும் மேற்கொள்வோம் என்பதை நிச்சயப்படுத்திக்
  கொள்கின்றோம்.
</p>
<p>
  சேவை இடையூறுகள், சேவைபெறுனரின் தவறுகள், இணையத்தள இணைப்புக்களில் ஏற்படும்
  பிரச்சினைகள், தவறான தகவல்கள், ஹெலவிரு வழங்கிகளில் அங்கீகரிக்கப்படாத அணுகல்,
  இணையத்தள அலைவரிசை நெருக்கடிகள், மின்சாரத் துண்டிப்பு, காழ்ப்புணர்ச்சியும்,
  இயற்கை அனர்த்தங்களும் உள்ளடங்கிய ஆனால் இவை மாத்திரமென மட்டுப்படுத்தப்படாத
  பாவனை அல்லது பாவனையின்மையின் காரணமாக ஏற்படும் கோரிக்கைகளுக்கு எந்தவொரு
  சந்தர்ப்பத்திலும் ஹெலவிரு பொறுப்புடைமையை ஏற்காது. இத்தகைய சேதங்களுக்குரிய
  வாய்ப்புக்கள் காணப்படுகின்றன என ஆலோசனை வழங்கப்பட்டிருப்பினும் நட்டம்,
  வியாபாரத்தில் அல்லது வியாபார வாய்ப்பில் நட்டம், உபயோகிப்பதில் நட்டம்
  போன்றவை அடங்கலாக ஆனால் இவை மாத்திரம்தான் என்ற மட்டுப்பாடுகளற்ற
  இழப்புக்களுக்குரிய நேரடியான, மறைமுகமான, தற்செயலான, விஷேட, தொடர்ச்சியான
  அல்லது தண்டனைக்குரிய இழப்பீடுகளுக்கு ஹெலவிரு தளமானது பொறுப்புடைமையானதல்ல.
</p>
<h2>
  15.0 இழப்பீடு
</h2>
<p>
  எவரேனும் மூன்றாம் தரப்பினரால் வழங்கப்பட்ட சட்டத்தரணிகளுக்குரிய நியாயமான
  கொடுப்பனவுகள், இவ்விதிமுறைகளையும்இ நிபந்தனைகளையும் அல்லது குறிப்புகளுடன்
  இணைக்கப்பட்ட ஏதேனும் ஆவணங்களை அல்லது சட்டத்தையும், விதிகளையும்,
  ஒழுங்குமுறைகளையும் அல்லது ஒரு மூன்றாம் தரப்பினரின் உரிமையை மீறியதன் விளைவாக
  உருவான தண்டப்பணம் என்பவற்றுக்கான இழப்புகளிலிருந்தும், தீங்குகளிலிருந்தும்
  ஸ்ரீ லங்கா ரெலிகொம் பீ.எல்.சீ, ஹெலவிரு இணையத்தள உரிமையாளர்கள், அவர்களின்
  துணை நிறுவனங்கள், இணை நிறுவனங்கள், அவர்களின் அலுவலர்கள், பணிப்பாளர்கள்,
  முகவர்கள் மற்றும் ஊழியர்கள் அனைவரையும் இழப்பீடுகளிலிருந்தும்,
  தீங்குகளிலிருந்தும் நீங்கள் பாதுகாக்க வேண்டும்.
</p>
<p>
  ஸ்ரீ லங்கா ரெலிகொம் பீ.எல்.சீ, ஹெலவிரு இணையத்தள உரிமையாளர்கள் மற்றும்
  அல்லது அதன் இணை நிறுவனங்கள் மற்றும் அல்லது அதன் அலுவலர்கள், பிரதிநிதிகள்
  ஆகியோரை, வர்த்தகர்களின் அல்லது சேவை வழங்குனர்களின் ஏதேனும் செயற்பாடுகளினால்
  அல்லது செய்யாமையினால் ஏற்படுகின்ற செலவு, சேதம், பொறுப்புடைமை அல்லது வேறு
  விளைவுகளிலிருந்து நீங்கள் விடுவிக்க வேண்டும். அத்துடன் குறிப்பாக இது
  தொடர்பில் ஏதேனும் நியதிச் சட்டங்கள், ஒப்பந்தங்கள் அல்லது வேறு எவற்றினதும்
  அடிப்படையில் உருவாகின்ற கோரிக்கைகள், தேவைகளிலிருந்து விடுவிக்க வேண்டும்.
  இணையத்தளத்தையும் அதன் சேவைகளையும் துண்டிப்பது மாத்திரமே உங்களுக்குரிய
  நிவாரணமாகும்.
</p>
<h2>
  16.0 மூன்றாம் தரப்பினர் வியாபாரங்கள்
</h2>
<p>
  எபிக் கிறீன், SLT மற்றும் அவற்றின் இணை நிறுவனங்கள் தவிர்ந்த ஏனைய
  தரப்பினரும் இணையத்தளத்தினூடாகக் கடைகளை இயக்கலாம், சேவைகளை வழங்கலாம் அல்லது
  பொருட்களை விற்கலாம். உதாரணமாக சந்தைப் பிரதேசத்தினூடாக வர்த்தகர்களும்
  தனிப்பட்டவர்களும் பொருட்களை வழங்குகின்றார்கள். மேலும் இணையத் தளங்களுடன்
  இணைந்த கம்பனிகளுக்கும் குறிப்பிட்ட வேறு வியாபாரங்களுக்கும் நாங்கள்
  இணைப்புக்களை வழங்குகின்றோம். அவற்றைப் பரிசோதிப்பதற்கும், மதிப்பிடுவதற்கும்
  நாங்கள் பொறுப்பல்ல மற்றும் ஏதேனும் இவ்வாறான வியாபாரங்களுக்கு அல்லது
  தனிப்பட்டவர்களுக்கும் அல்லது அவர்களின் இணையத்தளங்களின் உள்ளடக்கங்களிற்கு
  அவற்றின் வழங்கல்கள் சம்பந்தமான உத்தரவாதம் அல்லது ஒப்புதல் எதனையும் நாம்
  வழங்கமாட்டோம். இவை எவற்றினதும் மற்றும் வேறு மூன்றாம் தரப்பினர்களினதும்
  செயற்பாடுகளுக்கோ பொருட்களுக்கோ அல்லது உள்ளடக்கதத்திற்கோ நாம்
  பொறுப்புடைமையுடையவர்கள் அல்லர். உங்களது பரிவர்த்தனையைக் கவனமாக அவதானிப்பதன்
  மூலம் உங்களது பரிவர்த்தனைகளுடன் ஒரு மூன்றாம் தரப்பினர் சம்மந்தப்படும் போது
  நீங்கள் அதனைத் தெரிவிக்கலாம். வாடிக்கையாளர் தனியுரிமைக் கொள்கைகளுக்கிணங்க
  அப் பரிவர்ததனைகளுடன் சம்பந்தப்பட்ட வாடிக்கையாளர்கள் தகவல்களைக் குறித்த
  மூன்றாம் தரப்பினர்களுடன் நாம் பரிமாறலாம். அவர்களது தனியுரிமை அறிக்கைகளையும்
  அவற்றுடன் தொடர்புடைய விதி முறைகளையும் நிபந்தனைகளையும் நீங்கள் கவனமாக
  அவதானிக்க வேண்டும்.
</p>
<h2>
  17.0 எங்களுடன் தொடர்புகளை மேற்கொள்ளல்
</h2>
<p>
  நீங்கள் எமது இணையத்தளத்தைத் தரிசிக்கும் போது அல்லது மின்னஞ்சல்களை
  எங்களுக்கு அனுப்பும் போது எங்களுடன் இலத்திரனியலூடாக தொடர்பு கொள்கிறீர்கள்.
  எங்களிடம் நீங்கள் தருவித்தல்களை இடும்போது செல்லுபடியான தொலைபேசி இலக்கம்,
  செல்லுபடியான மின்னஞ்சல் முகவரிகளை வழங்குமாறு தேவைப்படுத்தப்படுகின்றீர்கள்.
  மின்னஞ்சல்களினூடாக குறுஞ்செய்திப் பரிமாற்றதினூடாக, தொலைபேசி அழைப்பினூடாக
  அல்லது அறிவித்தல்களை இணையத்தளத்தில் போடுவதனூடாக அல்லது நாங்கள் வேலை
  செய்வதற்குத் தெரிவு செய்துள்ள ஏதேனும் ஒரு தகவல் பரிமாற்ற முறையினூடாக
  உங்களுடன் தொடர்புகளை வைத்துக்கொள்வோம். ஒப்பந்த நோக்கங்களுக்காக
  இணையத்தளத்தின் உங்களது பாவனையுடன் சம்பந்தப்பட்ட விடயங்கள் தொடர்பான தகவல்களை
  (பரிவர்த்தனை விளம்பர மற்றும் அல்லது வர்த்தகசெய்திகள் உட்பட) பெறுவதற்கு
  மற்றும் அல்லது தருவித்தல்களை இடுவதற்கு நீங்கள் சம்மதித்துள்ளீர்கள்.
  அத்துடன் எல்லாவகையான தகவல் முறைமைகளுக்கும் ஓர் அளவிலான முக்கியத்துவம்
  கொடுத்துக் கவனிப்பதற்கும் நீங்கள் உடன்பட்டுள்ளீர்கள்.
</p>
<h2>
  18.0 இழப்புக்கள்
</h2>
<p>
  (எதிர்பார்க்கப்பட்ட சேமிப்புக்கள், எதிர்பார்க்கப்பட்ட வழங்கல் நேரம்,
  நல்லெண்ணம் அல்லது அநாவசிய செலவுகள் உட்பட்ட ஆனால் இவை மாத்திரமென
  மட்டுப்படுத்தப்படவில்லை) ஏதேனும் சொந்த இழப்புகளுக்கு அல்லது ஏதேனும்
  மறைமுகமான அல்லது தொடர்விளைவான இழப்புக்களுக்கு நாங்கள் பொறுப்புடைவர்கள்
  அல்லர்.
</p>
<h2>
  19.0 நிபந்தனைகளின் திருத்தங்கள் அல்லது சேவைகளின் மாற்றீடுகள் அவற்றுடன்
  சம்பந்தப்பட்ட வாக்குறுதி.
</h2>
<p>
  எந்தவேளையிலும் இணையத்தளத்தில் அதன் கொள்கைகளில் இந்த விதிமுறைகளிலும்
  நிபந்தனைகளிலும் மற்றும் பொதுவில் வெளிப்படுத்தப்படுகின்ற ஏதேனும்
  நிபந்தனையில் அல்லது வாக்குறுதி அளித்த சேவையில் மாற்றங்களை
  ஏற்படுத்துவதற்குரிய எமது உரிமைகளை ஒதுக்கியுள்ளோம். கொள்கைகளில் அல்லது
  நிபந்தனைகளில் சட்டத்தினால் அல்லது அரசாங்க அதிகாரத்தினால் ஏதேனும் மாற்றங்கள்
  ஏற்படும் சந்தர்ப்பங்கள் தவிர்ந்த ஏனைய சந்தர்ப்பங்களில் நீங்கள் இணையத்
  தளத்தை உபயோகிக்கும் நேரங்களில் வலுவிலுள்ள கொள்கைகளையும், விதிமுறைகளையும்,
  நிபந்தனைகளையும் கடைப்பிடிக்க கடப்பாடுடையவர்கள் ஆவீர்கள். (இது உங்களால்
  விண்ணப்பிக்கப்பட்ட தருவித்தல்களுக்கும் ஏற்புடையதாகும்.) இந்நிபந்தனைகளில்
  ஏதுனும் நிபந்தனைகள் செல்லுபடியற்றதாக்கப்பட்டிருப்பின்,
  வெறிதாக்கப்பட்டிருப்பின் அல்லது ஏதேனும் காரணங்களினால்
  வலிதற்றதாக்கப்பட்டிருப்பின் அந்நிபந்தனைகள் பறித்தெடுக்கப்படக் கூடியதாக
  அமைவதுடன் மீதமாகவுள்ள ஏனைய நிபந்தனைகளை பாதிக்கவோ வலிவற்றதாக்கவோ மாட்டாது.
</p>
<h2>
  20.0 எங்கள் கட்டுப்பாட்டுக்கு அப்பாற்பட்ட நிகழ்வூகள்
</h2>
<p>
  இந்த நிபந்தனைகளின் கீழான எமது கடப்பாடுகளை நிறைவேற்றுவதற்கு ஏதேனும்
  காலதாமதம் அல்லது நிறைவேற்ற முடியாமை ஏற்படும் போது அத்தாமதம் அல்லது
  இயலாமைக்கான காரணம் எமது நியாயமான கட்டுப்பாட்டுக்கப்பால் ஏற்பட்டிருப்பின்
  அவற்றுக்கு நாங்கள் பொறுப்புடைமையாளர்கள் அல்லர். இந்நிபந்தனையானது உங்களது
  நியதிச்சட்ட உரிமைகளைப் பாதிக்காது.
</p>
<h2>
  21.0 விட்டுவிடுதல்
</h2>
<p>
  நாம் பொருத்தமெனக் கருதும் விதத்தில் எமது நோக்கங்களை நிறைவேற்றும் வகையில்
  வியாபாரத்தை நடத்துவதற்குரிய உரிமைகள் ஒதுக்கப்பட்டுள்ளதை நீங்கள்
  ஏற்றுக்கொள்கிறீர்கள். எமது இணையத்தளத்தில் குறிப்பிடப்பட்டுள்ள நிபந்தனைகளை
  நீங்கள் மீறும்போது நாங்கள் எவ்வித நடவடிக்கைகளையும் எடுக்காதபோதிலும் நீங்கள்
  இந்நிபந்தனைகளை மீறுகின்ற வேறொரு சந்தர்ப்பத்தில் எமது உரிமைகளையும்
  நிவாரணங்களையும் பெறுவதற்கு எமக்கு உரிமை உள்ளது என்பதை நீங்கள்
  ஏற்றுக்கொண்டுள்ளீர்கள்.
</p>
<h2>
  22.0 முடிவூறுத்தல்
</h2>
<p>
  ஏதேனும் சட்ட ரீதியான அல்லது ஒப்புரவு ரீதியான நிவாரணங்களுக்கு மேலதிகமாக
  எந்தவித முன்னறிவித்தலுமின்றி உடனடியாக விதிமுறைகளையும் நிபந்தனைகளையும்
  முடிவுறுத்துவோம் அல்லது விதிமுறைகள் மற்றும் நிபந்தனைகளின் அடிப்படையில்
  உங்களுக்கு வழங்கப்பட்டுள்ள ஏதேனும் அல்லது எல்லா உரிமைகளையும் மீளப்
  பெறுவோம். இவ்வொப்பந்தத்தின் ஏதேனும் முடிவுறுத்தலின் மீது உடனடியாக இணையத்
  தளத்தை அணுகுவதையும் பாவிப்பதையும் நீங்கள் நிறுத்த வேண்டும். அத்துடன் மேலதிக
  சட்ட மற்றும் ஒப்புரவு நிவாரணங்களுக்கு மேலதிகமாக உங்களுக்கு வழங்கப்பட்ட
  கடவுச் சொல்/சொற்கள் கணக்கு அடையாளத்தையும் உடனடியாக மீளப் பெறுவதுடன் நீங்கள்
  இவ்விணையத்தளத்தை முழுமையாக அல்லது ஒரு பகுதியை பாவிப்பதிலிருந்தும்
  அணுகுவதிலிருந்தும் தடுக்கப்படுவீர்கள். இவ் ஒப்பந்தத்தில் ஏதேனும்
  முடிவுறுத்தல், முடிவுறுத்தல் திகதிக்கு முற்பட்ட காலத்தில்
  அத்தரப்பினர்களுக்கு வழங்கப்பட்ட உரிமைகளையும் கடமைகளையும் (எல்லையற்ற
  கொடுப்பனவு கடப்பாடுகள்) பாதிக்காது. ஏதேனும் அத்தைகைய இடைநீக்கம் அல்லது
  முடிவுறுத்தலின் விளைவாக உங்களுக்கோ அல்லது வேறு எவருக்குமோ இணையத்தளம் எவ்வித
  பொறுப்புடைமையும் அற்றது என்பதையும் நீங்கள் மேலும் ஏற்றுக்கொண்டுள்ளீர்கள்.
  இணையத்தளத்தில் அல்லது இணையத்தளத்தைப் பாவிப்பதில் காணப்படுகின்ற ஏதேனும்
  விதிமுறை, நிபந்தனைகள், விதிகள்,கொள்கைகள், வழிகாட்டல்கள், செயற்பாடுகளில்
  உங்களுக்கு அதிருப்தி ஏற்படின் இணையத்தளத்தை உபயோகிப்பதை நிறுத்துவது
  மாத்திரமே உங்களுக்குள்ள ஒரேயொரு பிரத்தியேக நிவாரணமாகும்.
</p>
<h2>
  23.0 சட்டத்தையும் நியாயாதிக்கத்தையும் ஆளுதல்
</h2>
<p>
  இவ் விதிமுறைகளும் நிபந்தனைகளும் இலங்கை ஜனநாயக சோஷலிசக் குடியரசின்
  சட்டங்களினால் ஆளப்படுவதுடன் அச்சட்டங்களுக்குக் கட்டுப்பட்டதாகவும்
  அமைந்துள்ளன. சட்ட நடவடிக்கைகளை நாம் ஆரம்பிக்கும் காலத்தில் எமது பதிவு
  செய்யப்பட்ட அலுவலகம் அமைந்துள்ள பிரதேசத்தின் நியாயாதிக்கமே இலங்கை
  நீதிமன்றங்களின் பிரத்தியேக நியாயாதிக்கத்தைக் கொண்டிருக்கும் என்பதை நீங்கள்
  ஏற்றுக்கொள்கிறீர்கள். இலங்கை அரசாங்கத்தினால் நடைமுறைப்படுத்தப்படுகின்ற
  பின்வரும் நியதிச் சட்டங்களை பொது இணையத்தளம் கடுமையாகப் பின்பற்றுகின்றது.
</p>
<p style="margin-left: 20px;">
  23.1. 2003ஆம் ஆண்டின் 27ஆம் இலக்க தகவல் தொடர்பாடல் தொழினுட்பவியல் சட்டம்.
</p>
<p style="margin-left: 20px;">
  23.2. 2003ஆம் ஆண்டின் 36ஆம் இலக்க புலமைச் சொத்துக்கள் சட்டம்.
</p>
<p style="margin-left: 20px;">
  23.3. 2006ஆம் ஆண்டின் 19அம் இலக்க இலத்திரனியல் கொடுக்கல் வாங்கல் சட்டம்.
</p>
<p style="margin-left: 20px;">
  23.4. 2007ஆம் ஆண்டின் 24ஆம் இலக்க கணினி வழிக் குற்றச் சட்டம்.
</p>
<p style="margin-left: 20px;">
  23.5. 2005ஆம் ஆண்டின் 28ஆம் இலக்க கொடுப்பனவு மற்றும் கொடுத்துத் தீர்த்தல்
  முறைமைகள் சட்டம்.
</p>
<p style="margin-left: 20px;">
  23.6. 2006ஆம் ஆண்டின் 30ஆம் இலக்க கொடுப்பனவு உபாயங்களின் மோசடிகள் சட்டம்.
</p>
<h2>
  24.0 எங்களைத் தொடர்பு கொள்ளல்
</h2>
<p>
  நீங்கள் எங்களை இங்கு தொடர்பு கொள்ளலாம்.
</p>
<h2>
  25.0 எமது மென்பொருள்
</h2>
<p>
  எமது மென்பொருள் எந்தவொரு மென்பொருளையும் (மென்பொருளுக்கான ஏதேனும்
  புதுப்பித்தல்கள், மேம்படுத்தல்கள் உள்ளிட்ட) உள்ளடக்கும் இணையத்தளத்துடன்
  தொடர்புடைய உங்களது பாவனைக்கு எவ்வேளையிலும் நாம் மென்பொருள் உங்களுக்குக்
  கிடைக்கக்கூடிய வகையில் ஒழுங்கு செய்வோம் (“மென்பொருள்”).
</p>
<p>
  விதிமுறைகளினாலும், நிபந்தனைகளினாலும் அனுமதிக்கப்பட்ட மற்றும் அதனுடன்
  சம்பந்தப்பட்ட, இணையத்தளத்தில் கிடைக்கக்கூடிய ஏற்புடைத்தான விதிமுறைகளின்
  பிரகாரம் எமது சேவைகளை உபயோகித்து அனுபவிக்க வேண்டுமென்ற
  நோக்கத்தினடிப்படையில் மாத்திரம் நீங்கள் எமது மென்பொருளை உபயோகிக்கலாம்.
  மென்பொருளின் ஏதேனும் ஒரு பாகத்தை உங்களது சொந்த நிரலுக்குள் இணைக்கவோ அல்லது
  அதன் ஏதேனும் ஒரு பகுதியின் தொகுப்பை உங்களது சொந்த நிரல்களுக்குள் தொகுக்கவோ,
  மற்றொரு சேவைக்குப் பாவிப்பதற்கு இதனை மாற்றவோ, அல்லது விற்கவோ, வாடகைக்கு
  விடவோ, குத்தகைக்கு விடவோ, இரவல் வாங்கவோ, கடன் கொடுக்கவோஇ பகிர்ந்தளிக்கவோ
  அல்லது மென்பொருளை உப உத்தரவிற்கு விடவோ முழு மென்பொருளினதும் அல்லது அதன்
  பகுதிகளினும் உரிமைகளை வேறேதேனும் வகையில் ஒப்புவிக்கவோ முடியாது. ஏதேனும்
  சட்ட விரோதமான நோக்கங்களுக்கு மென்பொருளை நீங்கள் பாவிக்கக்கூடாது.
  எவ்வேளையிலும் நாங்கள் உங்களுக்கு வழங்கும் சேவைகளை நிறுத்துவோம். அத்துடன்
  எவ்வேளையிலும், மென்பொருளை உபயோகிப்பதற்கான உங்களது உரிமைகளை
  முடிவுறுத்துவோம். இங்கு பட்டியல் இடப்பட்டுள்ள அல்லது இணையத் தளத்தில்
  குறிப்பிடப்பட்டுள்ள ஏதேனும் விதிமுறைகளையும், நிபந்தனைகளையும் கடைப்பிடிக்க
  நீங்கள் தவறும் பட்சத்தில் எம்மிடமிருந்து உங்களுக்கு எவ்வித அறிவித்தலுமின்றி
  மென்பொருளைப் பாவிக்கின்ற உங்களது உரிமைகள் தானாகவே முடிவுறுத்தப்படும். இந்த
  விதிமுறைகள், மற்றும் நிபந்தனைகளில் ஏதேனும் பிரச்சினைகள் ஏற்படும்
  பட்சத்தில், இந்த இணையத்தளத்தினுள் காணப்படுகின்ற மேலதிக மூன்றாம் தரப்பினர்
  விதிமுறைகள் அல்லது அவ்வாறு வழங்கப்பட்ட, சம்பந்தப்பட்ட ஆவணங்களில் குறிப்பாக
  அடையாளப்படுத்தப்பட்ட விதிமுறைகள், அத்தகைய மென்பொருளை உபயோகிப்பதை ஆளுவதற்கு
  ஏற்புடைத்தாகும். எமது எந்தவொரு சேவையிலும் பயன்படுத்தப்படுகின்ற எல்லா
  மென்பொருளும் எமது மற்றும் அல்லது எமது இணை நிறுவனங்கள் அல்லது அதன்
  மென்பொருள் விநியோகஸ்தர்களின் சொத்தாகும். அத்துடன் அவை ஏற்புடைத்தான ஏதேனும்
  வேறு பதிப்புரிமைகள் சட்டம் உட்பட ஆனால் மட்டுப்படுத்தப்பட்டதல்ல இலங்கையின்
  சட்டங்களினால் பாதுகாக்கப்பட்டது.
</p>
<p>
  நீங்கள் இணையத்தளத்தை உபயோகிக்கும் போது ஒரு கம்பியில்லாச் காவி அல்லது ஒரு
  கைபேசித் தள வழங்குனர் போன்ற ஒன்று அல்லது ஒன்றுக்கு மேற்பட்ட மூன்றாம்
  தரப்பினர்களின் சேவைகளும் உபயோகிக்கப்படுகின்றன. இந்த மூன்றாம் தரப்பினர்களின்
  சேவைகளின் மீதான உங்களது பாவனை வேறான கொள்கைகளையும், பாவனை விதிமுறைகளையும்,
  மூன்றாம் தரப்பினர்களுக்கான கொடுப்பனவுகளையும் கொண்டுள்ளது.
</p>
<p>
  எமது மென்பொருளை பூரணமாகவோ அல்லது அதன் ஒரு பகுதியாகவோ மற்றொரு நபர் பிரதி
  செய்வதற்கு, திருத்துவதற்கு, திருப்புவதற்கு, இயக்குவதற்கு, மீள்
  தொகுப்பவதற்கு, மீள் ஒழுங்கமைப்பதற்கு அல்லது வேறேதேனும் வகையில்
  சேதப்படுத்துவதற்கு அல்லது மென்பொருளினால் அல்லது மென்பொருளிலிருந்து
  வெவ்வேறான சேவைகளை உருவாக்குவதற்கு ஊக்குவிக்கவோ உதவி செய்யவோ அல்லது
  அங்கீகாரமளிக்கவோ மாட்டீர்கள்.
</p>
<p>
  மென்பொருளை புதுப்பிப்பதற்குஇ தன்னியக்க அல்லது கையியக்க புதுப்பித்தல்களை
  உங்களுக்கு அறிவிக்காமல் எவ்வேளையிலும் வழங்குவோம்.
</p>
</body>
<!--</html>-->
