<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2><i class="fa fa-user-circle"></i>&nbsp; {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.EDIT_PROFILE' |
                translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="profile-img">

            </div>
            <div class="col-md-12 d-flex flex-row">
              <ngx-dropzone [multiple]="false" accept="image/png,image/jpeg" (change)="onSelect($event)">
                <ngx-dropzone-label>
                  <div class="uploaderbox customUploaderBox">
                    <em class="icon fa fa-camera"></em>
                  </div>
                </ngx-dropzone-label>
                <img src="{{imageUrl}}" class="uploadProfileImg">
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                                            [file]="f">
                </ngx-dropzone-image-preview>
              </ngx-dropzone>
              <div class="d-flex justify-content-center flex-column pl-2">
                <h5 class="font-weight-bold">
                  {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.PROFILE_PICTURE' | translate}}</h5>
                <p class="m-0 p-0">{{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.ADD_A_NICE_PHOTO' | translate}}</p>
                <p class="m-0 p-0 green d-flex align-items-center">
                  <i class="material-icons">backup</i> &nbsp;
                  {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.UPDATE_YOUR_PICTURE' | translate}}
                </p>
                <p style="color: #6B6C6F; font-weight: bold; margin: 0; font-size: 14px;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE' | translate}}5MB
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE_ALLOWED' | translate}}</p>
              </div>
            </div>

            <div class="col-md-12">
              <h5 class="mt-4 mb-3 font-weight-bold">
                {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.ACCOUNT_SETTING' | translate}}</h5>
              <div *ngIf="activeTab==1" aria-labelledby="nav-home-tab">
                <form class="default row" [formGroup]="accountSettingForm" (ngSubmit)="submitSetting()">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="form-group col-lg-12">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.FULL_NAME' | translate}}
                          <span style="color: red;margin-left: .5vw">*</span></label>
                        <input autocomplete="nope" type="text" class="form-control" name="Fname"
                               [(ngModel)]="fName"
                               [ngClass]="{ 'is-invalid': submitted && fControls.fName.errors }"
                               formControlName="fName">
                        <div *ngIf="submitted && fControls.fName.errors"
                             class="invalid-feedback">
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.fName.errors.required">
                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.NAME_REQUIRED' | translate}}
                          </div>
                        </div>

                      </div>
                      <div class="form-group col-lg-6">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.USER_NAME' | translate}}
                          <span style="color: red;margin-left: .5vw">*</span></label>
                        <input autocomplete="nope" type="text" class="form-control"
                               name="userName" [(ngModel)]="username"
                               [ngClass]="{ 'is-invalid': submitted && fControls.mobile.errors }"
                               formControlName="userName" disabled>
                        <div *ngIf="submitted && fControls.userName.errors"
                             class="invalid-feedback">
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.userName.errors.required">
                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.USER_NAME_REQUIRED' | translate}}
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-lg-6">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.MOBILE' | translate}}
                          <span style="color: red;margin-left: .5vw">*</span></label>
                        <input (focusin)="removeCheckMobile()" (focusout)="checkExists(mobile,'MOBILE')"
                               class="form-control" type="tel"
                               name="mobile" [(ngModel)]="mobile"
                               [ngClass]="{ 'is-invalid': submitted && fControls.mobile.errors }"
                               formControlName="mobile" disabled>
                        <div *ngIf="submitted && fControls.mobile.errors"
                             class="invalid-feedback">
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.mobile.errors.required">
                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.MOBILE_NUMBER_REQUIRED' | translate}}
                          </div>
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.mobile.errors.pattern">
                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.VALID_NUMBER_REQUIRED' | translate}}
                          </div>
                        </div>
                        <div *ngIf="incorrectMobile" class="inputError">{{'EXISTS.MOBILE' |translate}}</div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.EMAIL' | translate}}
                        </label>
                        <input (focusin)="removeCheckEmail()" (focusout)="checkExists(email,'EMAIL')"
                               [(ngModel)]="email" [ngClass]="{ 'is-invalid': submitted && fControls.email.errors }"
                               autocomplete="nope"
                               class="form-control" formControlName="email"
                               name="email"
                               type="email" disabled>
                        <div *ngIf="submitted && fControls.email.errors" class="invalid-feedback">
                          <div *ngIf="fControls.email.errors.required">{{'COMMON_VALIDATION.EMAIL' | translate}}</div>
                          <div
                            *ngIf="fControls.email.errors.pattern">{{'COMMON_VALIDATION.VALID_EMAIL' | translate}}
                          </div>
                        </div>
                        <div *ngIf="incorrectEmail" class="inputError">{{'EXISTS.EMAIL' |translate}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-12">
                        <h5 class="mt-4 mb-3 font-weight-bold">
                          {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.SHIPPING_DETAILS' | translate}}
                        </h5>
                      </div>
                      <div class="form-group col-lg-6">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.ADDRESS_LINE_1' | translate}}
                          <span style="color: red;margin-left: .5vw">*</span></label>
                        <input autocomplete="nope" type="text" class="form-control"
                               name="address1" [(ngModel)]="address1"
                               [ngClass]="{ 'is-invalid': submitted && fControls.address1.errors }"
                               formControlName="address1">
                        <div *ngIf="submitted && fControls.address1.errors"
                             class="invalid-feedback">
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.address1.errors.required">
                            {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.ADDRESS_REQUIRED' | translate}}
                          </div>
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.address1.errors.pattern">
                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.VALID_ADDRESS_REQUIRED' | translate}}
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.ADDRESS_LINE_2' | translate}}
                        </label>
                        <input autocomplete="nope" type="text" class="form-control"
                               name="address2" [(ngModel)]="address2"
                               [ngClass]="{ 'is-invalid': submitted && fControls.address2.errors }"
                               formControlName="address2">
                        <div *ngIf="submitted && fControls.address2.errors"
                             class="invalid-feedback">
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.address2.errors.required">
                            {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.ADDRESS_REQUIRED' | translate}}
                          </div>
                          <div style="font-size: 10px;color: red"
                               *ngIf="fControls.address2.errors.pattern">
                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.VALID_ADDRESS_REQUIRED' | translate}}
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label style="font-weight: bold!important;">
                          {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.PROVINCE_STATE' | translate}}<span
                          style="color: red;margin-left: .5vw">*</span></label>
                        <select [(ngModel)]="province" (change)="selectProvince($event)"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && fControls.province.errors }"
                                formControlName="province">
                          <option *ngFor="let province of provinceList" [value]="province.id">
                            <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                          </option>
                          <div *ngIf="submitted && fControls.province.errors"
                               class="invalid-feedback">
                            <div style="font-size: 10px;color: red"
                                 *ngIf="fControls.province.errors.required">
                              {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.PROVINCE_REQUIRED' | translate}}
                            </div>
                          </div>
                        </select>
                      </div>


                      <div class="form-group col-lg-6">
                        <label
                          style="font-weight: bold!important;">{{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.DISTRICT' |
                          translate}}
                          <span style="color: red;margin-left: .5vw">*</span></label>
                        <select [(ngModel)]="district" (change)="selectDistrict($event)"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && fControls.district.errors }"
                                formControlName="district">
                          <option *ngFor="let district of districtList" [value]="district.id">
                            <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
                          </option>
                          <div *ngIf="submitted && fControls.district.errors"
                               class="invalid-feedback">
                            <div style="font-size: 10px;color: red"
                                 *ngIf="fControls.district.errors.required">
                              {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.DISTRICT_REQUIRED' | translate}}
                            </div>
                          </div>
                        </select>
                      </div>
                      <div class="form-group col-lg-6">
                        <label
                          style="font-weight: bold!important;">{{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.CITY'
                          | translate}}
                          <span style="color: red;margin-left: .5vw">*</span></label>
                        <select [(ngModel)]="selectedCityId" (change)="selectCity($event)"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && fControls.city.errors }"
                                formControlName="city">
                          <option *ngFor="let city of cityList"
                                  [value]="city.id">
                            <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
                          </option>
                          <div *ngIf="submitted && fControls.city.errors"
                               class="invalid-feedback">
                            <div style="font-size: 10px;color: red"
                                 *ngIf="fControls.city.errors.required">
                              {{'CITY_REQUIRED' | translate}}
                            </div>
                          </div>
                        </select>
                      </div>
<!--                      <div class="form-group col-lg-6">-->
<!--                        <label-->
<!--                          style="font-weight: bold!important;">{{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.GRAMA_AREA' |-->
<!--                          translate}}-->
<!--                          <span style="color: red;margin-left: .5vw">*</span></label>-->
<!--                        <select [(ngModel)]="gramArea" (change)="selectGrama($event)"-->
<!--                                class="form-control"-->
<!--                                [ngClass]="{ 'is-invalid': submitted && fControls.gramArea.errors }"-->
<!--                                formControlName="gramArea">-->
<!--                          <option *ngFor="let gramArea of gramaAreaList"-->
<!--                                  [value]="gramArea.id">-->
<!--                            <span *ngIf="activeLang === 'en'">{{gramArea.nameEN}}</span>-->
<!--                            <span *ngIf="activeLang === 'si'">{{gramArea.nameSI}}</span>-->
<!--                            <span *ngIf="activeLang === 'ta'">{{gramArea.nameTA}}</span>-->
<!--                          </option>-->
<!--                          <div *ngIf="submitted && fControls.gramArea.errors"-->
<!--                               class="invalid-feedback">-->
<!--                            <div style="font-size: 10px;color: red"-->
<!--                                 *ngIf="fControls.gramArea.errors.required">-->
<!--                              {{'MY_ACCOUNT.SETTING.SHIPPING_DETAILS.GRAMA_AREA_REQUIRED' | translate}}-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </select>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success pr-4 pl-4" id="btnType1">
                      {{'MY_ACCOUNT.SELL_A_PRODUCT.SAVE' | translate}}
                    </button>
                  </div>
                </form>

<!--                <form class="default row" [formGroup]="passwordChange"-->
<!--                      (ngSubmit)="updateNewPassword()">-->
<!--&lt;!&ndash;                  <form class="default row" [formGroup]="passwordChange" &ndash;&gt;-->
<!--&lt;!&ndash;                      (ngSubmit)="submitPasswordChange()">&ndash;&gt;-->
<!--                  <div class="col-md-12">-->
<!--                    <h5 class="mt-4 mb-3 font-weight-bold">{{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.SECURITY_INFO' |-->
<!--                      translate}}</h5>-->

<!--                    <div class="row">-->
<!--                      <div class="form-group col-lg-6">-->
<!--                        <label style="font-weight: bold!important;">{{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.CURRENT_PASSWORD'-->
<!--                          | translate}}</label>-->
<!--                        <input formControlName="currentPassword"-->
<!--                               autocomplete="nope" type="password"-->
<!--                               [(ngModel)]="currentPassword" class="form-control"-->
<!--                               [ngClass]="{ 'is-invalid': submittedpw && pwControls.currentPassword.errors }" matInput>-->
<!--                        <div *ngIf="submittedpw && pwControls.currentPassword.errors"-->
<!--                             class="invalid-feedback">-->
<!--                          <div style="font-size: 10px;color: red"-->
<!--                               *ngIf="pwControls.currentPassword.errors.required">-->
<!--                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.CURRENT_PASSWORD_REQUIRED' | translate}}-->
<!--                          </div>-->
<!--                          <div style="font-size: 10px;color: red"-->
<!--                               *ngIf="pwControls.currentPassword.errors.pattern">-->
<!--                            {{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.VALID_CURRENT_PASSWORD_REQUIRED' | translate}}-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div class="row">-->
<!--                      <div class="form-group col-lg-6">-->
<!--                        <label style="font-weight: bold!important;">{{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.PASSWORD' |-->
<!--                          translate}}</label>-->
<!--                        <input formControlName="newPassword"-->
<!--                               autocomplete="nope" type="password"-->
<!--                               [(ngModel)]="newPassword" class="form-control"-->
<!--                               [ngClass]="{ 'is-invalid': submittedpw && pwControls.newPassword.errors }" matInput>-->
<!--                        <div *ngIf="submittedpw && pwControls.newPassword.errors"-->
<!--                             class="invalid-feedback">-->
<!--                          <div style="font-size: 10px;color: red"-->
<!--                               *ngIf="pwControls.newPassword.errors.required">-->
<!--                            {{'COMMON_VALIDATION.PASSWORD' | translate}}-->
<!--                          </div>-->
<!--                          <div style="font-size: 10px;color: red"-->
<!--                               *ngIf="pwControls.newPassword.errors.pattern">-->
<!--                            {{'COMMON_VALIDATION.PASSWORD_PATTERN' | translate}}-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                      <div class="form-group col-lg-6">-->
<!--                        <label style="font-weight: bold!important;">{{'MY_ACCOUNT.SETTING.ACCOUNT_SETTING.RE-PASSWORD' |-->
<!--                          translate}}</label>-->
<!--                        <input formControlName="confirmPassword"-->
<!--                               autocomplete="nope" type="password"-->
<!--                               [(ngModel)]="confirmPassword" class="form-control"-->
<!--                               [ngClass]="{ 'is-invalid': submittedpw && pwControls.confirmPassword.errors }" matInput>-->
<!--                        <div *ngIf="submittedpw && pwControls.confirmPassword.errors"-->
<!--                             class="invalid-feedback">-->
<!--                          <div style="font-size: 10px;color: red"-->
<!--                               *ngIf="pwControls.confirmPassword.errors.required">-->
<!--                            {{'COMMON_VALIDATION.RE_PASSWORD' | translate}}-->
<!--                          </div>-->
<!--                          <div style="font-size: 10px;color: red"-->
<!--                               *ngIf="pwControls.confirmPassword.errors.mustMatch">-->
<!--                            {{'COMMON_VALIDATION.RE_PASSWORD_PATTERN' | translate}}-->
<!--                          </div>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;<div class="col-lg-6">&ndash;&gt;-->
<!--                    &lt;!&ndash;<label> &nbsp;</label>&ndash;&gt;-->
<!--                    &lt;!&ndash;<p class="p-0 m-0 d-flex align-items-center blue">&ndash;&gt;-->
<!--                    &lt;!&ndash;<i class="material-icons">edit</i> &nbsp; Change Password</p>&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="col-12 mt-4">-->
<!--                    <button type="submit" class="btn btn-success pr-4 pl-4" id="btnType">-->
<!--                      {{'MY_ACCOUNT.SELL_A_PRODUCT.CHANGE_PW' | translate}}-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </form>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- OTP modal -->
<button #optModal class="btn btn-primary" data-target="#otpModal" data-toggle="modal" hidden type="button">
</button>

<div aria-hidden="true" aria-labelledby="otpModal" class="modal fade" data-backdrop="static" id="otpModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'SIGN_IN.FORGET_PASSWORD.OTP_HAS_BEEN_SENT' | translate}}
          {{mobile}}</h5><br>
        <button #closeModal (click)="closeOTPModal()" aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{'SIGN_IN.FORGET_PASSWORD.ENTER_OTP_CODE' | translate}}</h3>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                      [config]="{length:6,allowNumbersOnly:true}" id="otpval"></ng-otp-input>
        <div style="color: red;margin-top: 5px">{{'SIGN_IN.FORGET_PASSWORD.CODE_EXPIRES_IN' | translate}} : &nbsp;<span id="time">03:00</span></div>
        <button (click)="resendOTPCode()" *ngIf="isShowResendButton" class="btn btn-primary customBtn"
                style="margin-top: 12px;"
                type="button">{{'SIGN_IN.FORGET_PASSWORD.RESEND_CODE' | translate}}
        </button>
        <span *ngIf="isInvalid"
              style="color: red;font-weight: bold;font-size: 14px;">The OTP you entered is invalid!</span>
      </div>
      <div class="modal-footer">
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        <button (click)="verifyOTP()" class="btn btn-primary customBtn" type="button">
          {{'SIGN_IN.FORGET_PASSWORD.VERIFY' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
