import {Component, OnInit, ViewChild} from '@angular/core';
import {InteractionService} from '../../../service/interaction.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {CommonSignUpService} from '../../../service/common-sign-up.service';
import {UserRoleService} from '../../../service/user-role.service';
import {CoreService} from '../../../service/core.service';
import {CommonService} from '../../../common/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-voice-packages',
  templateUrl: './voice-packages.component.html',
  styleUrls: ['./voice-packages.component.css']
})
export class VoicePackagesComponent implements OnInit {

  tenantValue: any;
  activeLang: any;

  submittedForm: boolean = false;
  form: FormGroup;

  address1: any;
  address2: any;
  selectProvinceId: any;
  provinceList: any;
  districtList: any;
  divisionalSecretariatList: any;
  gramaNiladhariAreaList: any;
  selectDistrictId: any;
  selectDivisionalSecretariatId: any;
  selectGramaNiladhariAreaId: any;
  selectCityId: any;
  city: any;
  spotTypeId: any;
  loading: boolean = false;
  loadingDist: boolean = false;
  loadingDiv: boolean = false;
  loadingGn: boolean = false;
  loadCity: boolean = false;
  allCities: any;
  cityObject: any;
  selectedCityName: any;
  postalCode: any;
  cityName: any;
  addressList: any[] = [];
  NIC: any;
  name: any;
  bod: any;
  todayDate = new Date(Date.now()).toISOString().slice(0, 10);

  selectedTitle: any;
  username: any;

  titleList = [
    {
      'id': 'Mr', 'description': 'Mr'
    },
    {
      'id': 'Mrs', 'description': 'Mrs'
    },
    {
      'id': 'Miss', 'description': 'Miss'
    }
  ];

  @ViewChild('openSuccessModal', {static: true}) openSuccessModal;

  constructor(private interactionService: InteractionService,
              private commonSignUpService: CommonSignUpService,
              private validationHandlerService: ValidationHandlerService,
              public commonService: CommonService,
              private formBuilder: FormBuilder,
              private userRoleService: UserRoleService,
              private coreService: CoreService
  ) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
    this.validationForm();
  }

  ngOnInit(): void {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.username = whoAmI['email'];
      this.loadAddresses(whoAmI['email']);
      this.getAllProvince();
    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  get f1() {
    return this.form.controls;
  }

  validationForm() {
    this.form = this.formBuilder.group({
      address1: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      address2: ['', [Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      nic: ['', [Validators.required, Validators.pattern(this.validationHandlerService.sriLankaNICValidation())]],
      name: ['', Validators.required],
      bod: ['', Validators.required],
      title: ['', Validators.required],
      district: ['', Validators.required],
      province: ['', Validators.required],
      postalCode: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())],
      city: ['', Validators.required]
    });
  }

  selectProvince(event) {
    this.loadingDist = true;
    this.selectProvinceId = event.target.value;
    this.selectDistrictId = undefined;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDistrict(this.selectProvinceId);
  }

  selectDistrict(event) {
    this.loadingDiv = true;
    this.loadCity = true;
    this.selectDistrictId = event.target.value;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDivSec(this.selectDistrictId);
    this.getAllCities(this.selectDistrictId);
  }

  selectCity(event) {
    this.selectCityId = event.target.value;
    this.getCityById(this.selectCityId);
  }


  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.cityObject = result;
        this.postalCode = this.cityObject.postalCode;
      }
    });
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.provinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.districtList = result;
        this.loadingDist = false;
      }
    });

  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
      }
    });
  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.divisionalSecretariatList = result;
        this.loadingDiv = false;
      }
    });
  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.gramaNiladhariAreaList = result;
        this.loadingGn = false;
      }
    });
  }

  loadAddresses(username) {
    this.userRoleService.fetchAddresses(username).subscribe(addresses => {
      this.addressList = addresses;
      for (let add of this.addressList) {
        if (add.status === true) {
          this.address1 = add.addressOne;
          this.address2 = add.addressTwo;

          this.selectProvinceId = add.cities.agriDistrict.province.id;
          this.getAllDistrict(this.selectProvinceId);
          this.selectDistrictId = add.cities.agriDistrict.id;
          this.getAllCities(this.selectDistrictId);
          this.getAllDivSec(this.selectDistrictId);
          this.selectCityId = add.cities.id;
          this.postalCode = add.postalCode;
          this.setSelectedCityName();
        }
      }

    });

    this.userRoleService.fetchUserDetails(username).subscribe(userDetails => {
      if (userDetails) {
        this.name = userDetails.name;
        this.NIC = userDetails.nic;
      }
    });
  }

  setSelectedCityName() {
    if (this.allCities !== undefined) {
      if (this.allCities.length > 0) {
        for (let _city of this.allCities) {
          if (_city.id.toString() === this.selectCityId.toString()) {
            this.selectedCityName = _city.nameEN;
          }
        }
      }
    }
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }

  submitForm() {
    this.submittedForm = true;
    if (this.form.invalid) {
      return;
    }

    this.commonService.processing();

    let body = {
      'userName': this.username,
      'address1': this.address1,
      'address2': this.address2,
      'nic': this.NIC,
      'name': this.name,
      'bod': this.bod,
      'title': this.selectedTitle,
      'district': this.selectDistrictId,
      'province': this.selectProvinceId,
      'postalCode': this.postalCode,
      'city': this.selectCityId
    };

    this.coreService.requestMobilePackage(body).subscribe(result => {
      Swal.close();
      if (result) {
        this.openSuccessModal.nativeElement.click();
      }
    }, () => {
      Swal.close();
    });

  }


}
