<div class="page-contents">
  <div class="packge-form" id="insurnce">
    <div class="top-info-section text-center">
      <img src="../../../../../../../assets/images/payment-types/insuarance/accident.png" alt="" class="sltimg">
      <h4 class="title-pck">
        <span *ngIf="activeLang === 'en'">Helaviru Personal Accident Insurance Benefits</span>
        <span *ngIf="activeLang === 'si'">හෙළවිරු පුද්ගලික අනතුරු රක්‍ෂණ ප්‍රතිලාභ</span>
        <span *ngIf="activeLang === 'ta'">ஹெலவிரு தனிப்பட்ட விபத்து காப்பீட்டு நன்மைகள்</span>
        <br/>
        <span *ngIf="activeLang === 'en'">Personal Accident Insurance</span>
        <span *ngIf="activeLang === 'si'">පුද්ගල හදිසි අනතුරු රක්ෂණාවරණය</span>
        <span *ngIf="activeLang === 'ta'">தனிப்பட்ட விபத்து காப்பீடு</span>
      </h4>
      <h6 class="sub">

        <span *ngIf="activeLang === 'en'">[Accident Death & Permanent Disability Benefit]</span>
        <span *ngIf="activeLang === 'si'">[හදිසි මරණය සහ ස්ථිර ආබාධිත ප්‍රතිලාභ]</span>
        <span *ngIf="activeLang === 'ta'">[விபத்து மரணம் & நிரந்தர இயலாமை நன்மை]</span>
        <br/>
        <span *ngIf="activeLang === 'si'">හදිසි අනතුරු මරණ හා පූර්ණ අකර්මණ්‍යතා සදහා</span>
        <span *ngIf="activeLang === 'en'">For accidental death and total disability</span>
        <span *ngIf="activeLang === 'ta'">விபத்து மரணம் மற்றும் முழு இயலாமைக்காக</span>
      </h6>
      <p class="rednotice">
        <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
        <span *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
        <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
      </p></div>
    <hr>
    <div *ngIf="section == 0" class="form-inputs-section vasforms">
      <form [formGroup]="form" autocomplete="off" class="sms-full-width form-box-panel">
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl">

              <span *ngIf="activeLang === 'si'">1) රක්ෂිතයා</span>
              <span *ngIf="activeLang === 'en'">1) Insured </span>
              <span *ngIf="activeLang === 'ta'">1) ரத்தக்கயா</span>
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="insured" [ngClass]="{ 'is-invalid': submitted && f.insured.errors }"
                     formControlName="insured" id="insured" matInput>
              <div *ngIf="submitted && f.insured.errors" class="inputError">
                <div *ngIf="f.insured.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
                <div
                  *ngIf="f.insured.errors.pattern">{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">2) Address </span>
              <span *ngIf="activeLang === 'si'">2) ලිපිනය</span>
              <span *ngIf="activeLang === 'ta'">2) முகவரி</span>
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <textarea readonly [(ngModel)]="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                        formControlName="address" id="address" matInput></textarea>
              <div *ngIf="submitted && f.address.errors" class="inputError">
                <div *ngIf="f.address.errors.required">{{'COMMON_VALIDATION.ADDRESS' | translate}}</div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">3) NIC </span>
              <span *ngIf="activeLang === 'si'">3) ජාතික හැඳුනුම්පත් අංකය</span>
              <span *ngIf="activeLang === 'ta'">3) என்ஐசி</span>
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="nic" readonly
                     [ngClass]="{ 'is-invalid': submitted && f.nic.errors }" class="form-control"
                     formControlName="nic" id="nic"
                     matInput>
              <div *ngIf="submitted && f.nic.errors" class="inputError">
                <div *ngIf="f.nic.errors.required">{{'COMMON_VALIDATION.NIC' | translate}}</div>
                <div *ngIf="f.nic.errors.pattern">{{'COMMON_VALIDATION.VALID_NIC' | translate}}</div>
              </div>
            </mat-form-field>
          </div>

        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">4) Profession or Occupation </span>
              <span *ngIf="activeLang === 'si'">4) වෘත්තිය හෝ රැකියාව</span>
              <span *ngIf="activeLang === 'ta'">4) தொழில் அல்லது தொழில்</span>
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="professionOrOccupation"
                     [ngClass]="{ 'is-invalid': submitted && f.professionOrOccupation.errors }" class="form-control"
                     formControlName="professionOrOccupation" id="professionOrOccupation"
                     matInput>
              <div *ngIf="submitted && f.professionOrOccupation.errors" class="inputError">

                <span *ngIf="activeLang === 'en'">Profession or Occupation is required!.</span>
                <span *ngIf="activeLang === 'si'">වෘත්තිය හෝ රැකියාව අවශ්‍යයි!</span>
                <span *ngIf="activeLang === 'ta'">தொழில் அல்லது தொழில் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">5) Tel. No </span>
              <span *ngIf="activeLang === 'si'">5) දුරකතන අංකය</span>
              <span *ngIf="activeLang === 'ta'">5) தொலைபேசி எண்</span>
              <sup style="color: red"> *</sup>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="contactNo" readonly
                     [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" class="form-control"
                     formControlName="contactNo" id="contactNo"
                     matInput>
              <div *ngIf="submitted && f.contactNo.errors" class="inputError">

                <span *ngIf="activeLang === 'en'">Tel. No is required!.</span>
                <span *ngIf="activeLang === 'si'">දුරකථන අංකය අවශ්‍යයි !.</span>
                <span *ngIf="activeLang === 'ta'">தொலைபேசி. எண் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>

        </div>
        <div class="d-tables">
          <label>6)</label>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th colspan="4">
                <span *ngIf="activeLang === 'en'">Details of the nominee/s.</span>
                <span *ngIf="activeLang === 'si'">නම් කළ/අයගේ විස්තර.</span>
                <span *ngIf="activeLang === 'ta'">நியமிக்கப்பட்டவர்/களின் விவரங்கள்.</span>
                <sup style="color: red"> *</sup>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th></th>
              <th>
                <span *ngIf="activeLang === 'en'">Name</span>
                <span *ngIf="activeLang === 'si'">නම</span>
                <span *ngIf="activeLang === 'ta'">பெயர்</span>
              </th>
              <th>
                <span *ngIf="activeLang === 'en'">NIC No.</span>
                <span *ngIf="activeLang === 'si'">ජාතික හැඳුනුම්පත් අංකය</span>
                <span *ngIf="activeLang === 'ta'">என்ஐசி இல்லை.</span>
              </th>
              <th>%</th>
            </tr>
            <tr>
              <th>ii</th>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee1Row1" [ngModelOptions]="{standalone: true}"
                         id="nominee1Row1" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee2Row1" [ngModelOptions]="{standalone: true}"
                         id="nominee2Row1" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input min="1" max="100" type="number" [(ngModel)]="nominee3Row1" [ngModelOptions]="{standalone: true}"
                         id="nominee3Row1" matInput>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <th>ii</th>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee1Row2" [ngModelOptions]="{standalone: true}"
                         id="nominee1Row2" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee2Row2" [ngModelOptions]="{standalone: true}"
                         id="nominee2Row2" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input min="1" max="100" type="number" [(ngModel)]="nominee3Row2" [ngModelOptions]="{standalone: true}"
                         id="nominee3Row2" matInput>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <th>iv</th>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee1Row3" [ngModelOptions]="{standalone: true}"
                         id="nominee1Row3" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee2Row3" [ngModelOptions]="{standalone: true}"
                         id="nominee2Row3" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input min="1" max="100" type="number" [(ngModel)]="nominee3Row3" [ngModelOptions]="{standalone: true}"
                         id="nominee3Row3" matInput>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <th>v</th>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee1Row4" [ngModelOptions]="{standalone: true}"
                         id="nominee1Row4" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input [(ngModel)]="nominee2Row4" [ngModelOptions]="{standalone: true}"
                         id="nominee2Row4" matInput>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="inputbox">
                  <input min="1" max="100" type="number" [(ngModel)]="nominee3Row4" [ngModelOptions]="{standalone: true}"
                         id="nominee4Row4" matInput>
                </mat-form-field>
              </td>
            </tr>


            </tbody>

          </table>
          <div *ngIf="invalidTableValues">
            <span *ngIf="activeLang === 'en'" class="inputError">This section is required!.</span>
            <span *ngIf="activeLang === 'si'" class="inputError">මෙම කොටස අවශ්යයි !.</span>
            <span *ngIf="activeLang === 'ta'" class="inputError">இந்த பிரிவு தேவை !.</span>
          </div>
        </div>
        <div class="declaration">

          <div class="row flexrow">
            <div class="col-12">
              <p *ngIf="activeLang === 'en'">7) I am currently in good health and I hereby warrant the truth to the
                above statements
                and I have withhold no
                information whatever which might tend any way to increase the risk of the Sri Lanka
                Insurance Corporation
                Ltd. or influence the acceptance of this proposal shall be the basis of the contract
                between me and Sri Lanka
                Insurance Corporation Ltd. </p>
              <p class="sin" *ngIf="activeLang === 'si'"> මා දැනට කිසිදු ආබාධයකින් තොරව යහපත් සෞඛ්‍යයෙන් පසුවන අතර ඉහත
                සදහන්
                ප්‍රකාශයන්ගේ සත්‍යතාවන ගැන මම
                සහතික වෙමි.තවද මාගේ වෘත්තියේ හෝ රැකියාවේ හෝ කිසියම් වෙනසක් වරින් වර ඇතිවන අවස්ථාවේ දී ද
                සෑම විටම
                එම විස්තර සමාගමට දන්වා සිටීමට එකග වෙමි. මේ ප්‍රතියාව සහ ගිවිසුම ශ්‍රී ලංකා ඉන්ෂුවරන්ස්
                කෝලරේෂන්
                ලිමිටඩ් සහ මා අතර පොරොන්දුවක් වන අතර යෝජිත රක්ෂණ කොන්ත්‍රාත්තුවේ මූලික පදනම වන බවටත් මම
                එකග
                වෙමි. තවද, ශ්‍රී ලංකා ඉන්ෂුවරන්ස් කෝලරේෂන් ලිමිටඩ් විසින් නියමිත මුද්‍රිත පෝරමය මගින්
                නිකුත්
                කරනු ලබන ඔප්පුවක් පිළිගැනීමට ද එකග වෙමි.
              </p>
              <p class="sin" *ngIf="activeLang === 'ta'">
                நான் தற்போது நலமாக உள்ளேன், மேற்கூறப்பட்ட அறிக்கைகளுக்கு நான் உண்மையை உறுதி செய்கிறேன், இலங்கை
                காப்பீட்டு கழகத்தின் அபாயத்தை அதிகரிக்க அல்லது இந்த திட்டத்தை ஏற்றுக்கொள்வதை பாதிக்கும் எந்தவொரு
                தகவலையும் நான் தடுத்து நிறுத்தவில்லை. எனக்கும் இலங்கை காப்புறுதி கூட்டுத்தாபனத்திற்கும் இடையிலான
                ஒப்பந்தம்.
              </p>


            </div>
          </div>
          <div class="row flexrow">
            <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
              <label class="mtlbl">
                <span *ngIf="activeLang === 'en'">Date</span>
                <span *ngIf="activeLang === 'si'">දිනය</span>
                <span *ngIf="activeLang === 'ta'">தேதி</span>
              </label>
              <mat-form-field appearance="outline" class="inputbox">
                <input type="date" [(ngModel)]="applicationDate" readonly
                       [ngClass]="{ 'is-invalid': submitted && f.applicationDate.errors }"
                       formControlName="applicationDate" id="applicationDate" matInput>

              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
              <label class="mtlbl ">
                <span *ngIf="activeLang === 'en'">Signature of the proposer</span>
                <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන</span>
                <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம்</span>
              </label><sup style="color: red"> *</sup>
              <mat-form-field appearance="outline" class="inputbox">
                <input [(ngModel)]="signatureOfProposer"
                       [ngClass]="{ 'is-invalid': submitted && f.signatureOfProposer.errors }"
                       formControlName="signatureOfProposer" id="signatureOfProposer" matInput>
                <div *ngIf="submitted && f.signatureOfProposer.errors" class="inputError">
                  <span *ngIf="activeLang === 'en'">Signature of the proposer is required!.</span>
                  <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන අවශ්‍යයි!</span>
                  <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம் தேவை !.</span>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="form-group btn-sections b-0 p-0 text-right">
          <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="nextPage()"
                  type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="page-contents" *ngIf="section === 1">
  <div class="packge-form">
    <div class="scheme-doc">
      <div class="scheme-details">
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td class="thead">1) Insured - <span class="sin">රක්ෂිතයා</span></td>
            <td>
              <mat-form-field appearance="outline" class="inputbox">
                <input id="lName" matInput [ngModel]="insured" readonly [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td class="thead">2) Period of Insurance - <span class="sin">රක්ෂණ කාලය</span></td>
            <td>One Year [Dates to be agreed] <br/>
              <span class="sin"> වසරකි (දින දිනුම් ඇදීමට යටත්ව)</span>
            </td>
          </tr>
          <tr>
            <td class="thead">3) Sum Insured - <span class="sin">රක්ෂිත මුදල</span></td>
            <td>Rs. 1,000,000/-
            </td>
          </tr>
          <tr>
            <td class="thead">4) Covers - <span class="sin">ආවරණ</span></td>
            <td>
              <ul>
                <li>Accidental Death - <span class="sin">හදිසි අනතුරකින් සිදුවන මරණය</span></li>
                <li>Permanent Total Disablement - <span class="sin">සදාකාලික පූර්ණ
                                            අකර්මණ්‍යතාවය</span></li>
                <li>Permanent Partial Disablement - <span class="sin">සදාකාලික අර්ධ
                                            අකර්මණ්‍යතාවය</span></li>
                <li>Motorcycling - <span class="sin">යතුරුපැදි ආවරණය </span></li>
                <li>24 hours Cover - <span class="sin">දවසේ පැය 24 සදහාම ආවරණය</span></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="thead">5) Annual Premium - <span class="sin">වාර්ෂික වාරිකය</span></td>
            <td>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th colspan="2" class="text-center">Annual Premium Per Person <br/>
                    <span class="sin"> එක් පුද්ගලයකු සදහා වාර්ෂික වාරිකය </span>
                  </th>
                </tr>
                <tr>
                  <td>Net Premium - <span class="sin">මූලික වාරිකය</span></td>
                  <td>Rs.500.00</td>
                </tr>
                <tr>
                  <th>Total Premium with taxes - <span class="sin"> බදු සහිත මුළු
                                                    වාරිකය</span></th>
                  <th>Rs.649.89</th>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-tables">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Event</th>
              <th scope="col" class="b-r">Result</th>
              <th scope="col" class="b-l text-right">Compensation</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="wsmall">Bodily injury, caused
                solely by violent, Accidental, external and
                visible means which
                injury shall
                independently of any
                other cause be the sole
                cause of any of the
                results (a) to (f)
              </td>
              <td colspan="2">
                <div class="d-flex align-items-center">
                  <div class="licol">
                    (a) Death. <br/> <br/>

                    (b) Total and permanent loss of all
                    sight in both eyes <br/> <br/>

                    (c) Total loss by physical severance
                    of two limbs <br/> <br/>

                    (d) Total Loss by physical severance
                    of two limbs together with the
                    total and permanent loss of all
                    sight in one eye <br/> <br/>

                    (e) Total and permanent loss of all
                    sight in one eye <br/> <br/>

                    (f) Total loss by physical severance
                    of one limb
                  </div>
                  <div class="brckets">
                    <div class="d-flex align-items-center">
                      <span>]</span>
                      <p class="text-center m-0">Occurring within
                        three months of
                        happening of the
                        event</p>
                      <span>[</span>
                    </div>
                  </div>
                  <div class="licol">
                    (a) 100% of Sum Insured <br/> <br/>

                    (b) 100% of Sum Insured <br/> <br/>

                    (c) 100% of Sum Insured<br/> <br/>

                    (d) 100% of Sum Insured<br/> <br/>

                    (e) 50% of Sum Insured<br/> <br/>

                    (f) 50% of Sum Insured
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>Compensation shall not be payable for more than one Results (a) to (f) and when
                  payable for one of those Result shall not
                  be payable for any other of the Results caused by the same Event not for any of
                  Results caused by any subsequent Event</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-tables">
          <table class="table table-bordered sin">
            <thead>
            <tr>
              <th scope="col">සිද්ධිය</th>
              <th scope="col" class="b-r">ප්‍රතිඵලය</th>
              <th scope="col" class="b-l text-right">වන්දිය</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="wsmall">හුදෙක් අන් කිසිම හේතුවකින් තොරව (අ) සිට (ඊ) තෙක්ව ප්‍රතිඵල ඇති වුද
                සාහසික වුද හදිසි
                අනතුරුමය වූද බාහිරමය දෘෂයමාන ආකාරයකින් සිදු වූ ශාරීරික තුවාල
              </td>
              <td colspan="2">
                <div class="d-flex align-items-center">
                  <div class="licol">
                    (අ) මරණය <br/> <br/>

                    (ආ) ඇස් දෙකෙහි පෙනීම සදාකාලිකව සම්පූර්ණයෙන්ම නැති වීම.<br/> <br/>

                    (ඇ) අත් දෙකම හෝ පා දෙකම හෝ එක් අතක් හා එක් පාදයක් ශරීරයෙන් වෙන් වීමෙන්
                    සිදුවන සම්පූර්ණ නැති වීම.<br/> <br/>

                    (ඈ) එක් ඇසක පෙනීම සම්පූර්ණයෙන් හා සදාකාලිකව නැති වීමත් සමගම එක් අතක් හෝ එක්
                    පයක් ශරීරයෙන් වෙන් වීමෙන් වූ සම්පූර්ණ නැති වීම.<br/> <br/>

                    (ඉ) එක් ඇසක පෙනීම සදාකාලිකව හා සම්පුර්ණයෙන් නැති වීම. <br/> <br/>

                    (ඊ) එක් අතක් හෝ එක් පයක් ශරීරයෙන් වෙන් වීමෙන් වූ සම්පූර්ණ නැති වීම.
                  </div>
                  <div class="brckets">
                    <div class="d-flex align-items-center">
                      <span>]</span>
                      <p class="sin text-center m-0">සිද්ධිය සිදුවීමෙන් මාස 3ක් ඇතුලත සිදු වී
                        නම්</p>
                      <span>[</span>
                    </div>

                  </div>
                  <div class="licol">
                    100% ආරක්ෂිත මුදලින් <br/> <br/>

                    100% ආරක්ෂිත මුදලින්<br/> <br/>

                    100% ආරක්ෂිත මුදලින්<br/> <br/>

                    100% ආරක්ෂිත මුදලින්<br/> <br/>

                    50% ආරක්ෂිත මුදලින්<br/> <br/>

                    50% ආරක්ෂිත මුදලින්
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p class="sin">මේ ඔප්පුව යටතේ : <br/> <br/>

                  (අ) සිට (ඊ) තෙක් වූ ප්‍රතිඵල එකකට වැඩි ගණනක් වෙනුවෙන් ද ඉහත කී ප්‍රතිඵලයක්
                  වෙනුවෙන් ගෙවිය යුතුවන විට එම සිද්ධිය නිසාම ඇති වූ වෙනත් ප්‍රතිඵලයක් වෙනුවෙන් ද
                  එම සිද්ධියෙන් පසුව ඇති වූ කිසියම් සිද්ධියක් හේතුවෙන් හටගත් ප්‍රතිඵලයක් වෙනුවෙන්
                  ද;</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group btn-sections b-0 p-0 text-right">
        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    float: left;
    margin-right: 10px;
    border-radius: 5px;" (click)="backPage()"
                type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}
        </button>


        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    margin-right: 10px;
    border-radius: 5px;" (click)="submitForm('DRAFT')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.DRAFT' | translate}}</button>

        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="submitForm('PENDING')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SUBMIT' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<button hidden data-target="#successModal" data-toggle="modal" #openSuccessModal></button>
<button hidden data-target="#draftModal" data-toggle="modal" #openDraftModal></button>

<!-- Success Modal -->
<div class="modal fade npa-model" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_save'|translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Draft Modal -->
<div class="modal fade npa-model" id="draftModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_draft_save' | translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



