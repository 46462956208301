<div class="anp-stp-wp stp-1 sellproduct-steps" *ngIf="selectNewProductOptions==0">
  <h4
    *ngIf="(isBankAccAvailable && isBankDetailLoaded)">{{'MY_ACCOUNT.SELL_A_PRODUCT.WHAT_DO_YOU_WANT' | translate}}</h4>

  <div class="anp-select-op" *ngIf="(isBankAccAvailable && isBankDetailLoaded)">
    <div *ngIf="commonService.checkPrivilege(['MANAGE_SPOT_PRODUCT'])" class="flexrow"
         (click)="setProductOption(1)">
      <div class="leftlink"><span>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SPOT_SELLING' | translate}}</span>
        <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SPOT_SELLING_LIST' | translate}}</p>
      </div>
      <i class="fa fa-chevron-right floaticon" aria-hidden="true"></i>
    </div>

    <div *ngIf="commonService.checkPrivilege(['MANAGE_AUCTION_PRODUCT'])" class="flexrow"
         (click)="setProductOption(2)">
      <div class="leftlink"><span>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.AUCTION' | translate}}</span>
        <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.AUCTIONING_LIST' | translate}}</p>
      </div>
      <i class="fa fa-chevron-right floaticon" aria-hidden="true"></i>
    </div>

    <div *ngIf="commonService.checkPrivilege(['MANAGE_FUTURE_PRODUCT'])" class="flexrow disabledbox">
      <div class="leftlink"><span>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.FORWARD_CONTRACT' | translate}}</span>
        <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.FUTURE_PRO_LIST' | translate}}</p>
        <p class="smallred">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.FUTURE_PRO_LIST' | translate}}</p>
      </div>
      <i class="fa fa-chevron-right floaticon" aria-hidden="true"></i>
    </div>
  </div>

  <h4
    *ngIf="isBankOthersAccAvailable === false">{{'MY_ACCOUNT.SELL_A_PRODUCT.NO_BANK_DETAILS' | translate}} </h4>

  <h4
    *ngIf="isFarmerExistBankAccount == false">{{'NO_BANK_DETAILS_FARMER' | translate}} </h4>

  <button *ngIf="isBankOthersAccAvailable === false" class="btn btn-default col-3"
          data-dismiss="modal"
          (click)="clickToGoBankAccount()"> {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_ACCOUNT' | translate}}</button>
</div>

<!-- Spot selling -->
<div class="anp-stp-wp stp-1" *ngIf="selectNewProductOptions===1">
  <app-farmer-spot-selling [isAgentFarmerLogin]="isAgentFarmerLogin"
                           [agentFarmerUsername]="agentFarmerUsername"></app-farmer-spot-selling>
</div>

<!-- Auction selling -->
<div class="anp-stp-wp stp-1" *ngIf="selectNewProductOptions===2">
  <app-farmer-auction [isAgentFarmerLogin]="isAgentFarmerLogin"
                      [agentFarmerUsername]="agentFarmerUsername"></app-farmer-auction>
</div>

<!-- Future selling -->
<div class="anp-stp-wp stp-1" *ngIf="selectNewProductOptions===3">
  <app-farmer-forward-contract></app-farmer-forward-contract>
</div>

