<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_ACCOUNT.PURCHASES.BID_FOR_REQUEST' | translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                <mat-form-field class="matsearch">
                  <input (keyup)="applyFilter($event.target.value)" id="keyUp" matInput
                         placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>

                <table [dataSource]="dataSource" class="table salestable" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="orderTitle">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Order Title
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        {{row.orderTitle}}
                      </div>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="username">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Bidder
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.username}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bidStartAmount">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Bid Amount
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.bidValue| number:'2.2-2'}}
                    </td>
                  </ng-container>

                  <!--                  <ng-container matColumnDef="bidValue">-->
                  <!--                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>-->
                  <!--                      Bid Value-->
                  <!--                    </th>-->
                  <!--                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">-->
                  <!--                      {{row.bidValue| number:'2.2-2'}}-->
                  <!--                    </td>-->
                  <!--                  </ng-container>-->
                  <ng-container matColumnDef="bidPlacementDate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Bid Placement Date
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{convertDate(row.bidPlacementDate)}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Status
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      <img *ngIf="row.bidStatus === 'PENDING'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-status.svg"/>
                      <img *ngIf="row.bidStatus === 'AWARDED'" alt="" class="status-img"
                           src="../../../../assets/images/awarded.svg"/>

                      <img *ngIf="row.bidStatus === 'DECLINED'" alt="" class="status-img"
                           src="../../../../assets/images/declined-status.svg"/>
                      <img *ngIf="row.bidStatus === 'PURCHASED' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/purchased.svg"/>
                      <img *ngIf="row.bidStatus === 'PURCHASED'&& activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/purchased-s.svg"/>
                      <img *ngIf="row.bidStatus === 'PURCHASED'&& activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/purchased-t.svg"/>
                    </td>
                  </ng-container>


                  <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                  </tr>
                  <tr
                    (click)="passProductDetails(row.productDetails,row.username,row.bidValue,row.bidPlacementDate,row.bidId,row.bidStatus)"
                    *matRowDef="let row; columns: displayedColumns"
                    data-target="#viewbidproduct" data-toggle="modal"
                    mat-row></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- order review -->
<div aria-hidden="true" class="modal fade npa-model" id="viewbidproduct" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" id="small-modal1" role="document">
    <div class="modal-content" style="padding:15px;">
      <div class="col-md-12">
        <div class="row order-review">
          <div class="col-md-5 d-flex align-items-center justify-content-center">
            <div>
              <img alt=""
                   src="{{this.mainUrl.MAIN_URL_FILE + productImage + '/?X-TenantID=' + this.tenantValue}}"
                   style="width: 200px;">
            </div>
          </div>
          <div class="col-md-7 product-details pr-4">
            <div class="text-right">
              <i aria-label="Close" class="material-icons graytext-3" data-dismiss="modal"
                 style="cursor: pointer;font-size: 18px;">close</i>
            </div>
            <div class="product-section">
              <h2 class="font-weight-bold graytext m-0">{{productName}}</h2>
            </div>
            <p class="avg-weight">{{qty}}{{unitType}}</p>
            <div class="bid-user">
              <div class="form-group mb-0">
                <label class="graytext m-0 dark-blue person">
                  {{bidUsername}}
                </label>
                <p class="graytext-2 dark-blue">
                  {{convertDate(bidDate)}}
                </p>
              </div>
            </div>
            <div class="price dark-blue font-weight-bold">
              <p>{{'CURRENCY.CURRENCY' | translate}}&nbsp;{{bidValue| number:'2.2-2'}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center modal-btns">
        <div class="btn-section">
          <button [disabled]="bidStatus === 'AWARDED' || bidStatus === 'PURCHASED'" (click)="statusChange('award')"
                  class="btn btn-default next" data-dismiss="modal"><i
            class="fa fa-check"></i>
            &nbsp;Award
          </button>&nbsp;
          <button [disabled]="bidStatus === 'AWARDED' || bidStatus === 'PURCHASED'" (click)="statusChange('decline')"
                  class="btn btn-default next redbtn" data-dismiss="modal"><i
            class="fa fa-times"></i>
            &nbsp;Decline
          </button>
        </div>
        <button #success class="btn-b b-approve" data-dismiss="modal" data-target="#success-modal" data-toggle="modal"
                hidden></button>
        <button #decline class="btn-b b-approve" data-dismiss="modal" data-target="#decline-modal" data-toggle="modal"
                hidden></button>
      </div>
    </div>
  </div>
</div>


<!-- success -->
<div aria-hidden="true" class="modal fade npa-model" id="success-modal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-check"></i>
          <h3>Bid Awarded Successfully</h3>
          <p>{{bidUsername}}’s Bid has been awarded successfully.</p>
          <a class="btn-b b-approve" data-dismiss="modal"
          >{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- declined -->
<div aria-hidden="true" class="modal fade npa-model" id="decline-modal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-times redtxt"></i>
          <h3>Bid Declined</h3>
          <p>{{bidUsername}}’s Bid has been declined successfully.</p>
          <a class="btn-b b-approve redbtn" data-dismiss="modal"
          >{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
