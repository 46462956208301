import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  fetchOverviewDetails(username): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/history/getByUserAndCurrentTerm/' + username);
  }

  fetchReferralEarnings(username, tenantValue): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/referral/getByTenantAndUser/' + username + '/' + tenantValue);
  }

  fetchLeadEarnings(username,role): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/Settlement/getEarnings/' + username+'/'+role);
  }

  fetchAllTransactionSummary(): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getAllSummary');
  }

  printAllTransactionSummary(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_REPORT + '/summary/summaryReport', body, {responseType: 'arraybuffer'});
  }

  fetchPlatformSummary(): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getAllPlatformSummary');
  }

  printPlatformSummary(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_REPORT + '/summary/platformSummaryReport', body, {responseType: 'arraybuffer'});
  }

  fetchAllRefEarnings(): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getAllRefEarnings');
  }

  printAllRefEarnings(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_REPORT + '/referral/referralReport', body, {responseType: 'arraybuffer'});
  }

  fetchAllTransactionHistory(): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getFullHistory');
  }

  printAllTransactionHistory(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_REPORT + '/history/historyReport', body, {responseType: 'arraybuffer'});
  }

  fetchTransactionCount(): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getTransactionCount');
  }

  fetchTotalPurchases(tenant: any): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getTotalPurchases/' + tenant);
  }

  fetchTotalPlatformChargers(tenant: any): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getTotalPlatformChargers/' + tenant);
  }

  fetchRefEarning(tenant: any): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getRefEarning/' + tenant);
  }

  fetchMonthlyTransactionCount(): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getMonthToDateTransactionCount');
  }

  fetchOverviewDetailsByLeadCode(leadCode, username): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/getLeadCounts/' + leadCode + '/' + username);
  }

  fetchTotalSalesForSeller(tenant: any, username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getSalesBySeller/' + tenant + '/' + username);
  }

  fetchTotalPurchasesForBuyers(tenant: any, username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getBuyerByPurchases/' + tenant + '/' + username);
  }

  fetchPurchasesOfMonth(tenant: any, username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getBuyerByPurchasesMonth/' + tenant + '/' + username);
  }

  fetchSalesOfMonth(tenant: any, username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getSalesBySellerMonth/' + tenant + '/' + username);
  }

  fetchEarnings(username: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/outStandingHistory/getEarningHistoryByUser/' + username);
  }

  fetchEarningsByLeadCode(leadCode: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/outStandingHistory/getEarningHistoryByLead/' + leadCode);
  }

  fetchEarningsByShopCode(leadCode: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/outStandingHistory/getEarningHistoryByShop/' + leadCode);
  }

  fetchTotalEarnings(username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/farmerEarnings/getTotal/' + username);
  }

  getEpicEarningExcel() {
    return this.http.get(this.mainUrl.MAIN_URL_REPORT + '/static/getEpicEarningExcel/', {responseType: 'blob'})
      .subscribe(res => {
        const blobb = new Blob([res], {type: res.type});
        saveAs(blobb, 'epic_earnings.xls');
      }, () => {
        alert('Sorry file not available!');
      });
  }
}
