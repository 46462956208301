import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fire-scheme',
  templateUrl: './fire-scheme.component.html',
  styleUrls: ['./fire-scheme.component.css']
})
export class FireSchemeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
