<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-7">
              <h2>{{'MY_ACCOUNT.SALES.MY_PRODUCTS.MY_PRODUCTS' | translate}}</h2>
            </div>
            <div class="col-5 text-right">
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">

                <mat-form-field class="matsearch" data-target="#editProduct" data-toggle="modal">
                  <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>


                <table [dataSource]="dataSource" class="table" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="id">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_ID' | translate}}

                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        <div style="width: 50px;">
                          <img alt=""
                               src="{{this.mainUrl.MAIN_URL_FILE + row.image + '/?X-TenantID=' + this.tenantValue}}"
                               style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                        </div>
                        <div class="rightdiv">
                          <h4>{{ row.name }}</h4>
                          <p>{{ row.sku }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="category">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.CATEGORY' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{row.category}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRICE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <span *ngIf="row.type === 'SPOT'">
                     {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.spotStock| number:'2.2-2'}}
                  </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.QTY' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{row.stockAvailable}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_TYPE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">{{row.type}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="expiredDate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.BEST_BUY_DATE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <span *ngIf="row.type === 'SPOT'">
                      {{convertDate(row.spotExpiredDate)}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="adddate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ADDED_DATE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{convertDate(row.createdDate)}}
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                  <tr (click)="passRowData(row.allItems)"
                      *matRowDef="let row; columns: displayedColumns" data-target="#editProduct"
                      data-toggle="modal" mat-row></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- earnings modal -->
<div aria-hidden="true" class="modal fade npa-model" id="editProduct" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header blank-header">

        <button #closebutton aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="inside-modal">
          <div class="">
            <div class="top-big">
              <div class="d-flex floatbox">
                <img alt="" class="img"
                     src="{{this.mainUrl.MAIN_URL_FILE + farmerImageUrl + '/?X-TenantID=' + this.tenantValue}}"/>
                <div class="rights">
                  <h4>{{farmerName}}<br/>
                    <!--                    <span>(HVF1234567)</span>-->
                  </h4>
                  <p *ngIf="activeLang === 'en'">{{farmerLocationEN}}</p>
                  <p *ngIf="activeLang === 'si'">{{farmerLocationSI}}</p>
                  <p *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</p>
                </div>
              </div>
              <div class="text-right">
                <h5 style="font-size: 14px;color: #828282;">Bank account No <br/>
                  <strong>{{bankAccount}}</strong></h5>

                <div class="sales">
                  <p><span>Orders: {{ordersCount}} &nbsp;<i class="fa fa-caret-up greentext"></i></span> &nbsp;&nbsp;
                    <span>Sales: {{salesCount}}
                      &nbsp;<i class="fa fa-caret-down orangetext"></i></span></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <mat-divider></mat-divider>
            <div class="pro-det">
              <div class="row" style="padding: 30px;">
                <div class="col-md-4">
                  <div class="image-container d-flex align-items-center">
                    <img alt="" class="img"
                         src="{{this.mainUrl.MAIN_URL_FILE + productImage + '/?X-TenantID=' + this.tenantValue}}"
                         style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                  </div>
                </div>
                <div class="col-md-8 right-side">
                  <div class="row">
                    <div class="col-md-12 board-right">

                      <div class="product-info-container">
                        <div class="product-title">
                          {{productName}}
                        </div>
                        <div class="category">
                          <span class="category-item">Category : {{productCategory}}/{{productName}}</span>
                        </div>
                        <div class="category">
                          <span class="category-item">Location :
                             <span *ngIf="activeLang === 'en'">{{farmerLocationEN}}</span>
                             <span *ngIf="activeLang === 'si'">{{farmerLocationSI}}</span>
                             <span *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</span>
                          </span>
                        </div>
                        <div class="d-flex paddingbox">
                          <div class="div-left">
                            <div class="">
                              <p>Selling Price:</p>
                              <h2>{{'CURRENCY.CURRENCY' | translate}} <strong>
                                {{productUnitPrice * productStock| number:'2.2-2'}}
                              </strong></h2>
                            </div>
                          </div>
                          <div class="div-right">
                            <p><strong>{{productAvailableQty}} items available</strong></p>
<!--                            <p>Avg weight per one item: 1KG</p>-->
                          </div>
                        </div>

                        <div class="date">

                          <div class="title">
                            <span class="material-icons">
                              date_range
                            </span> &nbsp; Listed date: <strong>{{convertDate(productCreatedDate)}}</strong>
                          </div>
                        </div>

                      </div>
                    </div>
                    <hr>
                    <div class="price-actions">

                      <div class="desc">
                        <div class="des-title">
                          Description
                        </div>
                        <div class="des-content">
                          {{productDescription}}
                        </div>
<!--                        <a class="bluetxt" data-dismiss="modal" data-target="#viewmodal" data-toggle="modal"-->
<!--                           style="cursor: pointer;">All Products &nbsp;<i aria-hidden="true"-->
<!--                                                                          class="fa fa-long-arrow-right"></i></a>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group btn-sections">
            <button class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-lead-modals></app-lead-modals>
