<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_ACCOUNT.SALES.ORDERS.ORDERS' | translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div>
                <mat-form-field class="matsearch">
                  <input matInput placeholder="{{'SEARCH_BAR' | translate}}" (keyup)="applyFilter($event.target.value)">
                </mat-form-field>

                <table class="table salestable"
                       style="width:100%;max-height: 100vh!important; height: auto; overflow-y: auto;"
                       mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="orderID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.ORDER_ID' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        <div style="width: 50px;" *ngIf="row.type !== 'ORDER_REQUEST'">
                          <img style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;" alt=""
                               src="{{this.mainUrl.MAIN_URL_FILE + row.image + '/?X-TenantID=' + this.tenantValue}}"/>
                        </div>
                        <div style="width: 50px;" *ngIf="row.type === 'ORDER_REQUEST'">
                          <img style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;" alt=""
                               src="{{setOrderRequestImage(row.category)}}"/>
                        </div>
                        <div class="rightdiv">
                          {{this.commonService.orderIdLengthTrim(row.transactionId, transactionTrimValue)}}

                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.ORDER_ITEMS' | translate}}</th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0%;" *matCellDef="let row">
                      {{row.name}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="orderDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.ORDERED_DATE' | translate}}</th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0%;" *matCellDef="let row">
                      {{row.created | date: 'yyyy/MM/dd'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.ORDER_TYPE' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      <span *ngIf="row.type === 'SPOT'">
                        {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.SPOT_BUYING' | translate}}
                      </span>
                      <span *ngIf="row.type === 'AUCTION'">
                        {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.AUCTION' | translate}}
                      </span>
                      <span *ngIf="row.type === 'ORDER_REQUEST'">
                        {{'ORDER_REQUEST' | translate}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.STATUS' | translate}}</th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">

                      <img class="status-img" src="../../../../../assets/images/{{manageStatus(row.status)}}.svg"
                           alt="">
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="paySts">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.PAYMENT' | translate}}</th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                      <img class="status-img" src="../../../../../assets/images/{{manageStatus(row.paymentStatus)}}.svg"
                           alt="">
                    </td>
                  </ng-container>


                  <!--// new columns-->
                  <ng-container matColumnDef="unitPrice">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.UNIT_PRICE' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0%;" *matCellDef="let row">
                      {{row.unitPrice}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.QUANTITY' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0%;" *matCellDef="let row">
                      {{row.quantity}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalPrice">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.TOTAL_PRICE' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0%;" *matCellDef="let row">
                      <span *ngIf="row.type !== 'ORDER_REQUEST'">{{row.amount}}</span>
                      <span *ngIf="row.type === 'ORDER_REQUEST'">{{row.unitPrice}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="buyer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                      {{'MY_ACCOUNT.SALES.ORDERS.BUYER' | translate}}
                    </th>
                    <td mat-cell class="center-table tbl" style="padding-left: 0%;" *matCellDef="let row">
                      {{row.buyerName}}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" data-toggle="modal"
                      (click)="getOrder(row)" data-target="#vieworder"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- order review -->
<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" data-keyboard="false" id="vieworder"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 m-1100 margin-0 profile-edit" role="document">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" hidden (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-content">
      <div class="col-md-12">
        <div class="row order-review">
          <div class="col-md-4 d-flex align-items-start">
            <div class="l-p">
              <div>
                <img *ngIf="productType !== 'ORDER_REQUEST'"
                     style="border-radius: 10px;width: 157px; height: 183px;object-fit: cover;" alt=""
                     src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ image + '/?X-TenantID=' + this.tenantValue}}"/>
                <img *ngIf="productType === 'ORDER_REQUEST'"
                     style="border-radius: 10px;width: 157px; height: 183px;object-fit: cover;" alt=""
                     src="{{setOrderRequestImage(category)}}"/>
              </div>
              <div class="user-d-o">
                <div class="user d-flex align-items-start">
                  <div class="user-img">
                    <img [src]="sellerImage" alt="">
                  </div>
                  <div class="user-details">
                    <h5>{{buyerName}}</h5>
                    <small>{{district}}</small>
                    <p class="contact-user d-flex align-items-center"><span
                      class="material-icons">perm_contact_calendar</span> &nbsp;
                      {{contact}}
                    </p>
                  </div>
                </div>
                <div class="price">
                  <h2
                    *ngIf="productType !== 'ORDER_REQUEST'"> {{'CURRENCY.CURRENCY' | translate}} {{amount | number:'2.2-2'}} </h2>
                  <h2
                    *ngIf="productType === 'ORDER_REQUEST'"> {{'CURRENCY.CURRENCY' | translate}} {{unitPrice | number:'2.2-2'}} </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 product-details pr-4">
            <div class="r-p">
              <div class="text-right">
                <i #closeButton aria-label="Close" class="material-icons graytext-3" data-dismiss="modal"
                   style="cursor: pointer;font-size: 18px;">close</i>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-between">
                <h2 class="font-weight-bold graytext m-0">{{prodName}}</h2>
                <p *ngIf="type === 'SPOT'" class="order-type">{{'MY_ACCOUNT.SALES.ORDERS.ORDER_TYPE' | translate}}
                  : {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SPOT_SELLING' | translate}}</p>
                <p *ngIf="type !== 'SPOT' && type !== 'ORDER_REQUEST'"
                   class="order-type">{{'MY_ACCOUNT.SALES.ORDERS.ORDER_TYPE' | translate}}
                  : {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.AUCTION' | translate}}</p>
                <p *ngIf="type === 'ORDER_REQUEST'"
                   class="order-type">{{'MY_ACCOUNT.SALES.ORDERS.ORDER_TYPE' | translate}}
                  : {{'ORDER_REQUEST' | translate}}</p>
              </div>
              <p class="Order-date">{{'MY_ACCOUNT.SALES.ORDERS.ORDERED_DATE' | translate}}
                : {{orderDate | date: 'yyyy/MM/dd'}}</p>
              <div class="amount">
                <div class="form-group mb-0">
                  <label class="graytext m-0">{{'MY_ACCOUNT.SALES.ORDERS.QUANTITY' | translate}}</label>
                  <h2 class="graytext-2">
                    {{quantity}}{{unit}}
                  </h2>

                  <label class="graytext m-0" *ngIf="deliveryId !== null">{{'DeliveryOption'|translate}}</label>

                  <div *ngIf="deliveryId === 3">
                    <p class="font-weight-bold p-0 m-0 dark-blue">{{'seller_transport'|translate}}
                      - {{'Per_Km_Rate'|translate}}</p>
                  </div>

                  <div *ngIf="deliveryId === 5">
                    <p class="font-weight-bold p-0 m-0 dark-blue">{{'seller_transport'|translate}}
                      - {{'Fixed_Rate'|translate}}</p>
                  </div>

                  <div *ngIf="deliveryId === 3 || deliveryId === 5">
                    <p class="font-weight-bold p-0 m-0 dark-blue">{{'DeliveryCharges'|translate}}
                      - {{'CURRENCY.CURRENCY' |
                        translate}}&nbsp;{{deliveryCharges| number:'2.2-2'}}</p>
                  </div>
                  <div *ngIf="deliveryId === 5">
                    <p class="font-weight-bold p-0 m-0 dark-blue">{{'DeliverareaforFixedRate'|translate}}
                      - {{area}} </p>
                  </div>
                  <div *ngIf="deliveryId === 2">
                    <p class="font-weight-bold p-0 m-0 dark-blue">{{'BUYER_TRANSPORT'|translate}}</p>
                  </div>


                </div>
              </div>
              <div class="d-flex mt-3">
                <div class="w-45" style="word-break: break-all;margin-right: 10%;">
                  <p class="mb-1">{{'MY_ACCOUNT.SALES.ORDERS.PICKUP_ADDRESS' | translate}}</p>
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{pickupAdd}} </p>
                </div>
                <div>
                  <p class="mb-1">{{'MY_ACCOUNT.SALES.ORDERS.DELIVERY_ADDRESS' | translate}}</p>
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{address}} </p>
                </div>
              </div>
              <br>

              <div class="delvery-i"
                   *ngIf="((pySts === 'COMPLETE' && (sts !== 'REJECTED' && sts !== 'APPROVED')) || (pySts === 'COMPLETE' && sts === 'PENDING'))">
                <div class="inputs">
                  <label for="deliveryDate">{{'DELIVERY_DATE'|translate}}<span
                    style="color: red;font-size: 15px;margin: 3px;">*</span>:</label>
                  <input (change)="selectDeliveryDate()" min="{{todayDate}}" [(ngModel)]="deliveryDate"
                         class="form-control odate" id="deliveryDate"
                         type="date">
                  <span style="font-size: 12px;color: red"
                        *ngIf="isDeliveryDate">{{'Deliverydateisrequired'|translate}}</span>
                </div>
                <div class="timeslots">
                  <h6>{{'TIME_SLOT'|translate}}<span style="color: red;font-size: 15px;margin: 3px;">*</span>:</h6>
                  <div class="d-flex">
                    <div class="form-check" (click)="selectTimeSlot('08 - 10 AM')">
                      <input class="form-check-input" id="0812" type="radio" name="select">
                      <label class="form-check-label" for="0812">
                        08 - 12 {{'AM'|translate}}
                      </label>
                    </div>
                    <div class="form-check" (click)="selectTimeSlot('12 - 05 PM')">
                      <input class="form-check-input" id="1205" type="radio" name="select">
                      <label class="form-check-label" for="1205">
                        12 - 05 {{'PM'|translate}}
                      </label>
                    </div>
                    <div class="form-check" (click)="selectTimeSlot('Any time of day')">
                      <input class="form-check-input" id="anytime" type="radio" name="select">
                      <label class="form-check-label" for="anytime">
                        {{'ANY_TIME'|translate}}
                      </label>
                    </div>
                  </div>
                  <span style="font-size: 12px;color: red" *ngIf="isTimeSlot">{{'Timeslotisrequired'|translate}}</span>
                </div>
                <div class="pckge-d">
                  <h6>{{'Packaging_Details'|translate}}<span style="color: red;font-size: 15px;margin: 3px;">*</span>:
                  </h6>
                  <div class="row">
                    <div class="col-lg-6 col-xs-12">
                      <label for="packageDetails">{{'Method'|translate}}</label>
                      <select (change)="selectPackageDetails()" [(ngModel)]="selectedPackageDetails"
                              class="form-control" id="packageDetails">
                        <option *ngFor="let packageDetail of packageDetailsList"
                                [value]="packageValueSet(activeLang,packageDetail)">
                          <span *ngIf="activeLang === 'en'">{{packageDetail.nameEn}}</span>
                          <span *ngIf="activeLang === 'si'">{{packageDetail.nameSi}}</span>
                          <span *ngIf="activeLang === 'ta'">{{packageDetail.nameTm}}</span>
                        </option>
                      </select>
                      <span style="font-size: 12px;color: red"
                            *ngIf="isPackageDetails">{{'Packagingdetailsisrequired'|translate}}</span>
                    </div>
                    <div class="col-lg-6 col-xs-12">
                      <label for="numberPackages">{{'Number_Packaging'|translate}}</label>
                      <div class="number d-flex align-items-center qty-cal justify-content-start">
                        <div class="minus" style="cursor:pointer;" (click)="buttonDown(selectedNumberPackages)">
                          <span>-</span>
                        </div>
                        <input readonly min="0" [(ngModel)]="selectedNumberPackages" class="form-control"
                               id="numberPackages"
                               type="number" placeholder="0">
                        <div class="plus" style="cursor:pointer;" (click)="buttonUp(selectedNumberPackages)">
                          <span>+</span></div>
                      </div>
                      <span style="font-size: 12px;color: red"
                            *ngIf="isNumberOfPackaging">{{'Numberofpackagingisrequired'|translate}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="user-d-o" *ngIf="driverDetails !== null && (sts === 'APPROVED' || sts === 'REJECTED')">
                <h2 style="font-size: 16px">{{'Itemwillbecollectedby'|translate}}:</h2><br>
                <div class="user d-flex align-items-start">
                  <div class="row" style="text-align: center">
                    <div class="col-12">
                      <div class="user-img">
                        <img src="{{driverDetails?.profileImage}}" alt=""
                             style="width: 60px;height: 60px;border-radius: 100%">
                        <br>
                      </div>
                    </div>
                    <div class="col-12">
                      <img src="../../../assets/images/riyaviru.png" alt="" style="height: 60px">
                    </div>
                  </div>
                  <div class="user-details">
                    <h5>{{driverDetails?.firstName}}&nbsp;{{driverDetails?.lastName}}</h5>
                    <small>{{'Vehicle_Type'|translate}} :{{driverDetails?.vehicleType}}</small><br>
                    <small>{{'VehicleRegNo'|translate}} :{{driverDetails?.vehicleName}}</small><br>
                    <small>{{'RiyaviruID'|translate}} :{{driverDetails?.driverId}}</small>
                    <p class="contact-user d-flex align-items-center"><span
                      class="material-icons">perm_contact_calendar</span> &nbsp;
                      {{driverDetails?.phone}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="user-d-o" *ngIf="delivery === 'Buyer' && (sts === 'APPROVED')">
                <h2 style="font-size: 16px">{{'Itemwillbecollectedby'|translate}}:</h2><br>
                <div class="user d-flex align-items-start">
                  <div class="row" style="text-align: center">
                    <div class="col-12">
                      <div class="user-img">
                        <img src="{{sellerImage}}" alt=""
                             style="width: 60px;height: 60px;border-radius: 100%">
                        <br>
                      </div>
                    </div>
                  </div>
                  <div class="user-details">
                    <h5>{{buyerName}}</h5>
                    <small>{{district}}</small>
                    <p class="contact-user d-flex align-items-center"><span
                      class="material-icons">perm_contact_calendar</span> &nbsp;
                      {{contact}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="b-btns-o">
                <button *ngIf="pySts == 'COMPLETE' && sts !== 'REJECTED' " class="btn btn-default danger"
                        (click)="setLang()" data-toggle="modal" data-target="#reject-modal-basic">
                  {{'MY_ACCOUNT.SALES.ORDERS.REJECT_ORDER' | translate}}</button>&nbsp;
                <button *ngIf="pySts == 'COMPLETE' && sts === 'PENDING'" class="btn btn-default next"
                        (click)="updateStatus('APPROVED')">
                  {{'MY_ACCOUNT.SALES.ORDERS.APPROVE_ORDER' | translate}}</button>
                <button [disabled]="isDisabledDispatch" *ngIf="sts === 'APPROVED'" class="btn btn-default next"
                        (click)="updateStatus('SHIPPED')">
                  {{dispatchButtonText | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<!-- reject modal -->
<div data-backdrop="static" data-keyboard="false" class="modal fade npa-model" id="reject-modal-basic" tabindex="-1"
     role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered mw-800 margin-0 payment-confirmation" role="document"
       style="min-width: 1300px;">
    <div class="modal-content">
      <button aria-label="Close" class="close" data-dismiss="modal" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
      <form [formGroup]="rejForm">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="leftmodal">
              <div class="text-center">
                <img style="border-radius: 10px;width: 157px; height: 183px;object-fit: cover;" alt=""
                     src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ image + '/?X-TenantID=' + this.tenantValue}}"/>
              </div>

              <div class="top-info d-flex justify-content-between align-items-center">
                <div class="leftdivs">
                  <h4>{{prodName}}</h4>
                  <p>{{'OrderDate'|translate}} {{orderDate | date: 'yyyy/MM/dd'}}</p>
                </div>
                <div class="rightdivs">
                  <p>{{'Quantity'|translate}}</p>
                  <h2>{{quantity}}{{unit}}</h2>
                </div>
              </div>
              <div class="addressess">
                <div class="w-45">
                  <p class="mb-1">{{'PickupAddress'|translate}}</p>
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{pickupAdd}} </p>
                </div>
                <div>
                  <p class="mb-1">{{'DeliveryAddress'|translate}}</p>
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{address}} </p>
                </div>
              </div>
              <p class="totalamount">{{'CURRENCY.CURRENCY' | translate}} {{amount | number:'2.2-2'}}</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="reject-details rightmodal">
              <h5>{{'Pleasetelluswhyyourejectthisorder'|translate}}</h5>
              <div class="form-group">
                <label>{{'ReasonRejection'|translate}}<span style="color: red;">*</span></label>
                <select class="form-control" name="reason" id="reason" formControlName="reason"
                        [ngClass]="{ 'is-invalid': rejForm && f2.reason.errors }">
                  <option *ngFor="let rej of rejectionCatList" [value]="rej.id">{{rej.description}}</option>

                </select>
                <div *ngIf="submitted && f2.reason.errors" class="inputError">
                  <div *ngIf="f2.reason.errors.required">
                    {{'RejectReasonRequired'|translate}}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="remark">{{'Message'|translate}}</label>
                <textarea class="form-control" formControlName="remark" name="remark" id="remark" rows="5"
                          [ngClass]="{ 'is-invalid': rejForm && f2.remark.errors }"></textarea>
                <div *ngIf="submitted && f2.remark.errors" class="inputError">
                  <div *ngIf="f2.remark.errors.required">
                    {{'RejectRemarkRequired'|translate}}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="color: red;font-weight: bold; margin-bottom: 2%;" *ngIf="activeLang == 'en'"> If you cancel
                this order, a fee of 200 rupees for the canceled order will automatically be charged and will be added
                to your next cash order.
                Are you Sure?
              </div>
              <div style="color: red;font-weight: bold; margin-bottom: 2%;" *ngIf="activeLang == 'si'"> ඔබ මෙම ඇණවුම
                අවලංගු කළහොත්, අවලංගු කරන ලද ඇණවුම සඳහා රුපියල් 200 ක ගාස්තුවක් ස්වයංක්‍රීයව අය කෙරෙන අතර එය ඔබගේ ඊළඟ
                මුදල් ඇණවුමට එකතු වේ.
                ඔබට විශ්වාසද?
              </div>
              <div style="color: red;font-weight: bold; margin-bottom: 2%;" *ngIf="activeLang == 'ta'"> இந்த ஆர்டரை
                நீங்கள் ரத்துசெய்தால், ரத்து செய்யப்பட்ட ஆர்டருக்கு 200 ரூபாய் கட்டணம் தானாகவே வசூலிக்கப்படும், மேலும்
                இது உங்கள் அடுத்த பண ஆர்டரில் சேர்க்கப்படும். நீ சொல்வது உறுதியா?
              </div>
              <a aria-label="Next" data-dismiss="modal" style="color: white" class="btn btn-default danger"
                 data-toggle="modal" (click)="rejectOrder()">Reject Order</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- reject success modals -->
<div data-backdrop="static" data-keyboard="false" class="modal fade npa-model" id="reject-success-modal" tabindex="-1"
     role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered margin-0 profile-edit" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <h3>{{'OrderRejectionSuccessfully'|translate}}</h3>
          <p>{{'Wewilllookintotheproblemandletyouknowwhathappensnext'|translate}}</p>
          <a href="" class="btn next-btn-close" data-dismiss="modal">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>


<button class="btn-b b-approve" #success data-dismiss="modal" data-toggle="modal" hidden
        data-target="#success-modal"></button>

<div data-backdrop="static" data-keyboard="false" class="modal fade npa-model" id="success-modal" tabindex="-1"
     role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered margin-0 profile-edit" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <h3>{{'SUCCESS' | translate}}</h3>
          <p>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_STATUS_UPDATED' | translate}}</p>
          <a href="" class="btn-b b-approve" data-dismiss="modal">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
