<div class="profile-summery">
  <div class="profile-img">
    <img src="{{imgUrl}}" alt="" class="avatarimg">
  </div>
  <div class="profile-summery-rt">
    <h4 style="text-transform: capitalize;">{{name}}</h4>
    <span class="pro-contact">{{mobile}}</span>
    <span class="shopcode" *ngIf="isEconomicCenter && activeLang === 'si'">සාප්පු කේතය: {{refNo}}</span>
    <span class="shopcode" *ngIf="isEconomicCenter && activeLang === 'en'">Shop Code: {{refNo}}</span>
    <span class="shopcode" *ngIf="isEconomicCenter && activeLang === 'ta'">கடை குறியீடு: {{refNo}}</span>
    <span class="pro-contact">
      <span *ngIf="activeLang === 'en'">{{locationEN}}</span>
      <span *ngIf="activeLang === 'si'">{{locationSI}}</span>
      <span *ngIf="activeLang === 'ta'">{{locationTA}}</span>
    </span>
    <span *ngIf="isShowRef" class="pro-contact">{{refNo}}</span>
  </div>
  <div [routerLink]="['/my-account/my-profile/'+ tenantValue]" class="profile-settings-icon" style="outline: 0;">
    <i class="fa fa-cog"></i>
  </div>
</div>

<div class="pro-link" id="sidebar-profile">
  <div class="col-md-12 tabs-for-sidebar">
    <ul class="nav nav-pills justify-content-center" id="pills-tab" role="tablist" *ngIf="isEconomicCenter">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" (click)="proTabItem=1" [ngClass]="{'active':proTabItem==1}"
                [routerLink]="['/my-account/overview-farmer-selling/wholesale']">
          <img src="../../../../../assets/images/icons/Shop.png"
               alt=""><span>Shop</span>
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" (click)="goEcoFarmerOverview()" [ngClass]="{'active':proTabItem==2}"
                [queryParams]="{'isFarmer':true,'shopRef':refNo}"
                [routerLink]="['/my-account/overview-farmer-selling/wholesale']">
          <img src="../../../../../assets/images/icons/Farmer.png"
               alt=""><span>Farmer</span>
        </button>
      </li>
    </ul>
    <div class="tab-content linksoftabs" id="pills-tabContent">
      <div class="tabp fade show active" *ngIf="proTabItem==1">
        <h4 *ngIf="commonService.checkPrivilege(['MY_ACCOUNT_SELLING_BAR'])" class="sb-title">
          <span>{{'MY_ACCOUNT.SELLING' | translate}}</span>
        </h4>

        <a *ngIf="commonService.checkPrivilege(['ADD_AGENT_FARMER'])"
           [queryParams]="{'agentFarmer':true,'agentRef':refNo}"
           [routerLink]="'/sign-up/'+tenantValue" class="btn btn-success sell-productbtn" style="text-align: center;">
          <i aria-hidden="true" class="fa fa-user-o"></i>{{'MY_ACCOUNT.ADD_A_FARMER' | translate}}</a>


        <div *ngIf="commonService.checkPrivilege(['SELL_A_PRODUCT_SECTION'])">
          <a (click)="openModal()" #sellAProductModal
             *ngIf="commonService.checkPrivilege(['MANAGE_SPOT_PRODUCT','MANAGE_AUCTION_PRODUCT','MANAGE_FUTURE_PRODUCT'])"
             class="btn btn-success sell-productbtn" data-target="#addMyProduct" data-toggle="modal"
             style="text-align: center;">
            <i class="fa fa-server"></i>{{'MY_ACCOUNT.SELL_A_PRODUCT.SELL_A_PRODUCT' | translate}}</a>
        </div>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_FARMER'])"
           [routerLink]="['/my-account/overview-farmer-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_FARMER'])"
           [routerLink]="['/my-account/overview-farmer-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_LEAD'])"
           [routerLink]="['/my-account/overview-lead-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_BUYER'])"
           [routerLink]="['/my-account/overview-wholesaler-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_BUYER'])"
           [routerLink]="['/my-account/overview-wholesaler-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_AGGREGATOR'])"
           [routerLink]="['/my-account/overview-aggregator-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_AGGREGATOR'])"
           [routerLink]="['/my-account/overview-aggregator-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_SERVICE_PROVIDER'])"
           [routerLink]="['/my-account/overview-service-provider-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_SERVICE_PROVIDER'])"
           [routerLink]="['/my-account/overview-service-provider-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_FARMERS'])"
           [routerLink]="['/my-account/farmers/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/purchase.svg"/>
          {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.MY_FARMERS' | translate}} <i aria-hidden="true"
                                                                          class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_PRODUCTS'])"
           [routerLink]="['/my-account/product/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/products.svg"/>
          {{'MY_ACCOUNT.SALES.MY_PRODUCTS.MY_PRODUCTS' | translate}}
          <i *ngIf="isUpdateProductsList" aria-hidden="true" class="fa fa-circle"
             style="color: red!important;margin-left: 10px;font-size: 10px;"></i>
          <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_AGENT_PRODUCTS'])"
           [routerLink]="['/my-account/agent-products/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/products.svg"/>
          Products
          <i *ngIf="isUpdateProductsList" aria-hidden="true" class="fa fa-circle"
             style="color: red!important;margin-left: 10px;font-size: 10px;"></i>
          <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_AUCTION_PRODUCTS'])"
           [routerLink]="['/my-account/auction-products/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bids.svg"/>
          {{'MY_AUCTION' | translate}} <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['MY_ORDER_REQUEST_BIDS'])"
           [queryParams]="{'isFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/view-my-order-request-bids/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bids.svg"/>
          {{'MY_ACCOUNT.PURCHASES.BID_FOR_REQUEST' | translate}}<i aria-hidden="true"
                                                                   class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_ORDERS'])" [routerLink]="['/my-account/orders/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/orders.svg"/>
          {{'MY_ACCOUNT.SALES.ORDERS.ORDERS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SALES'])" [routerLink]="['/my-account/sales/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/sales.svg"/>
          {{'MY_ACCOUNT.SALES.SALES.SALES' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_SALE_REVIEW'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/reviews/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/reviews.svg"/>
          {{'MY_ACCOUNT.SALES.REVIEWS.REVIEWS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_REQUEST'])"
           [routerLink]="['/my-account/requests/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/requests.svg"/>
          {{'MY_ACCOUNT.SALES.REQUESTS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['EARNINGS'])" [routerLink]="['/my-account/earnings/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/earn.svg"/>
          {{'MY_ACCOUNT.EARNING.EARNING' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_ORDER_REQUEST_BIDS'])"
           [routerLink]="['/my-account/order-request-bids/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bids.svg"/>
          {{'MY_ACCOUNT.SALES.ODER_REQUEST_BIDS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_DELIVERY_BIDS'])"
           [routerLink]="['/my-account/delivery-bids/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bick.svg"/>
          {{'MY_ACCOUNT.SALES.DELIVERY_BIDS' | translate}} <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_WHOLESALERS'])"
           [routerLink]="['/my-account/wholesaler/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/a-userss.svg"/>
          My Wholesalers <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <h4 *ngIf="commonService.checkPrivilege(['MY_ACCOUNT_BUYING_BAR'])" class="sb-title">
          <span>{{'MY_ACCOUNT.BUYING' | translate}}</span>
        </h4>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_FARMER'])"
           [routerLink]="['/my-account/overview-farmer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_FARMER'])"
           [routerLink]="['/my-account/overview-farmer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_LEAD'])"
           [routerLink]="['/my-account/overview-lead-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_BUYER'])"
           [routerLink]="['/my-account/overview-wholesaler-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_BUYER'])"
           [routerLink]="['/my-account/overview-wholesaler-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_AGGREGATOR'])"
           [routerLink]="['/my-account/overview-aggregator-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_AGGREGATOR'])"
           [routerLink]="['/my-account/overview-aggregator-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_CONSUMER'])"
           [routerLink]="['/my-account/overview-consumer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_CONSUMER'])"
           [routerLink]="['/my-account/overview-consumer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_RETAILER'])"
           [routerLink]="['/my-account/overview-consumer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_RETAILER'])"
           [routerLink]="['/my-account/overview-consumer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_EXPORTER'])"
           [routerLink]="['/my-account/overview-consumer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_EXPORTER'])"
           [routerLink]="['/my-account/overview-consumer-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_SERVICE_PROVIDER'])"
           [routerLink]="['/my-account/overview-service-provider-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BUYING_OVERVIEW_COMPANY_SERVICE_PROVIDER'])"
           [routerLink]="['/my-account/overview-service-provider-buying/wholesale']">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <p *ngIf="commonService.checkPrivilege(['MY_ACCOUNT_PURCHASES_SECTION'])" style="text-align:left">
          <strong>{{'MY_ACCOUNT.PURCHASES.PURCHASES' | translate}}</strong>
        </p>


        <a [routerLink]="['/my-account/delivery-bid-request/'+ tenantValue]">
          <img src="../../../assets/images/icons/bick.svg"/>
          {{'Riyawiru_Bids'|translate}}
          <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_PURCHASES'])"
           [routerLink]="['/my-account/purchases/'+ tenantValue]">
          <img src="../../../assets/images/icons/purchase.svg"/>
          {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.MY_PURCHASES' | translate}} <i aria-hidden="true"
                                                                              class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['MY_ORDER_REQUESTS'])"
           [routerLink]="['/my-account/my-order-requests/'+tenantValue]">
          <img src="../../../assets/images/icons/products.svg"/>
          {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.ORDER_REQUESTS' | translate}}<i aria-hidden="true"
                                                                                   class="fa fa-long-arrow-right"></i>
        </a>

        <a [routerLink]="['/my-account/pending-my-payments/'+ tenantValue]">
          <img src="../../../assets/images/icons/requests.svg"/>
          {{'MY_ACCOUNT.SALES.PENDING_PAYMENTS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BIDS'])"
           [routerLink]="['/my-account/view-my-bids/'+ tenantValue]">
          <img src="../../../assets/images/icons/bids.svg"/>
          {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.VIEW_MY_BIDS' | translate}}<i aria-hidden="true"
                                                                             class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SHOPPING_CART'])" [routerLink]="['/cart/'+ tenantValue]">
          <img src="../../../assets/images/icons/shopping-cart.svg"/>
          {{'MY_ACCOUNT.PURCHASES.SHOPPING_CART' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <h4 class="sb-title"><span>{{'MY_ACCOUNT.ACCOUNT_INFO' | translate}}</span></h4>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_BANK_ACCOUNT'])"
           [routerLink]="['/my-account/bank-acc/'+ tenantValue]">
          <img src="../../../assets/images/icons/bank.svg"/>
          {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.MY_BANK_ACCOUNT' | translate}}<i aria-hidden="true"
                                                                                   class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SUBSCRIPTIONS'])"
           [routerLink]="['/subscription/'+ tenantValue]">
          <img src="../../../assets/images/icons/subscriptions.svg"/>
          {{'MY_ACCOUNT.PURCHASES.MY_SUBSCRIPTION.MY_SUBSCRIPTION' | translate}}<i aria-hidden="true"
                                                                                   class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['FULL_EDIT_MY_PROFILE'])"
           [routerLink]="['/my-account/my-profile/'+ tenantValue]">
          <img src="../../../assets/images/icons/profile.svg"/>
          {{'MY_ACCOUNT.MY_PROFILE.MY_PROFILE' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <!-- Buyer Navigation Item -->
        <a (click)="openModalOrder()" *ngIf="commonService.checkPrivilege(['CREATE_A_NEW_ORDER'])"
           class="btn btn-success sell-productbtn"
           data-target="#createNewOrder" data-toggle="modal">
          <span class="material-icons">verified_user</span>{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.CREATE_NEW_ORDER' |
          translate}}
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_PURCHASES_OVERVIEW'])"
           [routerLink]="['/my-account/buyer-overview/'+tenantValue]">
          <img src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.PURCHASES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_PURCHASES_REVIEWS'])"
           [routerLink]="['/my-account/buyer-reviews/'+ tenantValue]">
          <img src="../../../assets/images/icons/reviews.svg"/>
          {{'MY_ACCOUNT.PURCHASES.REVIEWS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_PURCHASES_ORDER_REQUESTS'])"
           [routerLink]="['/my-account/buyer-order-requests/'+ tenantValue]">
          <img src="../../../assets/images/icons/requests.svg"/>
          {{'MY_ACCOUNT.PURCHASES.ORDER_REQUEST' | translate}} <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a [routerLink]="['/value-added-services/'+ tenantValue]">
          <img src="../../../assets/images/icons/vas.svg"/>
          {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.VALUE_ADDED_SERVICES' | translate}}<i aria-hidden="true"
                                                                                         class="fa fa-long-arrow-right"></i>
        </a>
      </div>

      <div class="tabp fade show" *ngIf="proTabItem==2">
        <h4 *ngIf="commonService.checkPrivilege(['MY_ACCOUNT_SELLING_BAR'])" class="sb-title">
          <span>{{'MY_ACCOUNT.SELLING' | translate}}</span>
        </h4>

        <a *ngIf="commonService.checkPrivilege(['ADD_ECONOMIC_FARMER'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="'/sign-up/'+tenantValue" class="btn btn-success sell-productbtn" style="text-align: center;">
          <i aria-hidden="true" class="fa fa-user-o"></i>{{'MY_ACCOUNT.ADD_A_FARMER' | translate}}</a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_FARMER'])"
           [queryParams]="{'isFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-farmer-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_FARMER'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-farmer-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_LEAD'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-lead-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_BUYER'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-wholesaler-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_BUYER'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-wholesaler-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_AGGREGATOR'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-aggregator-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_AGGREGATOR'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-aggregator-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_SERVICE_PROVIDER'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-service-provider-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SELLING_OVERVIEW_COMPANY_SERVICE_PROVIDER'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/overview-service-provider-selling/wholesale']">
          <img alt="" src="../../../assets/images/icons/overview.svg"/>
          {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_ECONOMIC_FARMERS'])"
           [queryParams]="{'isFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/farmers/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/purchase.svg"/>
          {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.MY_FARMERS' | translate}} <i aria-hidden="true"
                                                                          class="fa fa-long-arrow-right"></i>
        </a>

<!--        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_PRODUCTS'])"-->
<!--           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"-->
<!--           [routerLink]="['/my-account/product/'+ tenantValue]">-->
<!--          <img alt="" src="../../../assets/images/icons/products.svg"/>-->
<!--          {{'MY_ACCOUNT.SALES.MY_PRODUCTS.MY_PRODUCTS' | translate}}-->
<!--          <i *ngIf="isUpdateProductsList" aria-hidden="true" class="fa fa-circle"-->
<!--             style="color: red!important;margin-left: 10px;font-size: 10px;"></i>-->
<!--          <i aria-hidden="true" class="fa fa-long-arrow-right"></i>-->
<!--        </a>-->

        <a *ngIf="commonService.checkPrivilege(['VIEW_AGENT_PRODUCTS'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/agent-products/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/products.svg"/>
          Products
          <i *ngIf="isUpdateProductsList" aria-hidden="true" class="fa fa-circle"
             style="color: red!important;margin-left: 10px;font-size: 10px;"></i>
          <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

<!--        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_AUCTION_PRODUCTS'])"-->
<!--           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"-->
<!--           [routerLink]="['/my-account/auction-products/'+ tenantValue]">-->
<!--          <img alt="" src="../../../assets/images/icons/bids.svg"/>-->
<!--          {{'MY_AUCTION' | translate}} <i aria-hidden="true" class="fa fa-long-arrow-right"></i>-->
<!--        </a>-->

        <a *ngIf="commonService.checkPrivilege(['MY_ORDER_REQUEST_BIDS'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/view-my-order-request-bids/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bids.svg"/>
          {{'MY_ACCOUNT.PURCHASES.BID_FOR_REQUEST' | translate}}<i aria-hidden="true"
                                                                   class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_ORDERS'])" [queryParams]="{'isFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/orders/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/orders.svg"/>
          {{'MY_ACCOUNT.SALES.ORDERS.ORDERS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_SALES'])" [queryParams]="{'isFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/sales/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/sales.svg"/>
          {{'MY_ACCOUNT.SALES.SALES.SALES' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_SALE_REVIEW'])"
           [queryParams]="{'isFarmer':true,'shopRef':refNo}"

           [routerLink]="['/my-account/reviews/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/reviews.svg"/>
          {{'MY_ACCOUNT.SALES.REVIEWS.REVIEWS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_REQUEST'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"

           [routerLink]="['/my-account/requests/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/requests.svg"/>
          {{'MY_ACCOUNT.SALES.REQUESTS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['EARNINGS'])" [queryParams]="{'agentFarmer':true,'shopRef':refNo}"
           [routerLink]="['/my-account/earnings/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/earn.svg"/>
          {{'MY_ACCOUNT.EARNING.EARNING' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>
        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_ORDER_REQUEST_BIDS'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"

           [routerLink]="['/my-account/order-request-bids/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bids.svg"/>
          {{'MY_ACCOUNT.SALES.ODER_REQUEST_BIDS' | translate}}<i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_DELIVERY_BIDS'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"

           [routerLink]="['/my-account/delivery-bids/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/bick.svg"/>
          {{'MY_ACCOUNT.SALES.DELIVERY_BIDS' | translate}} <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>


        <a *ngIf="commonService.checkPrivilege(['VIEW_MY_WHOLESALERS'])"
           [queryParams]="{'agentFarmer':true,'shopRef':refNo}"

           [routerLink]="['/my-account/wholesaler/'+ tenantValue]">
          <img alt="" src="../../../assets/images/icons/a-userss.svg"/>
          My Wholesalers <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
        </a>

      </div>
    </div>
  </div>


</div>
