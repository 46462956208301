import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-agent-terms',
  templateUrl: './agent-terms.component.html',
  styleUrls: ['./agent-terms.component.css']
})
export class AgentTermsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
