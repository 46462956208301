<app-header></app-header>

<div class="main-container">
  <div class="container product-view">
    <div class="row">

      <div class="col-lg-12">
        <div class="pl-content w-full-col">
          <div class="inner-panel">

            <div class="container">

              <div class="row">
                <div class="col-md-4">
                  <div class="image-container">
                    <div class="zoom-popup">
                    </div>

                    <div class="view-product">
                      <div class="image-box p-view">
                        <div class="badge-item" *ngIf="leadLabel !== null">
                          <img
                            src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ leadLabel+ '/?X-TenantID=' + this.tenantValue}}"
                            alt="">
                        </div>
                        <img *ngIf="commonImg" alt="" class="demo-trigger" data-target="#openImage" data-toggle="modal"
                          id="main-image" src="{{this.commonImg}}" style="cursor: pointer" />
                        <img *ngIf="!commonImg" alt="" class="show demo-trigger" src="assets/defaultBrokenImage.jpg">
                      </div>
                      <!-- economy center badge -->
                      <div class="eco-badge-view" *ngIf="economicCenter === 'TABUTTEGAMA'">
                        <h5>
                          <img src="../../assets/images/icons/store.png" alt="">
                          <span style="color: white;font-weight: bold" *ngIf="activeLang === 'en'">Thambuttegama<br> Economic Center</span>
                          <span style="color: white;font-weight: bold" *ngIf="activeLang === 'si'">තඹුත්තේගම<br> ආර්ථික මධ්‍යස්ථානය</span>
                          <span style="color: white;font-weight: bold" *ngIf="activeLang === 'ta'">தம்புத்தேகம<br> பொருளாதார நிலையம்</span>
                        </h5>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 1vh;">

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb">
                          <img (click)="viewImg(img1,1)" *ngIf="img1" [src]="img1" alt="" class="imageTag show"
                            id="img1" style="filter: unset; opacity: 1;">
                          <img *ngIf="!img1" alt="" class="show imageTag" src="../../assets/images/loading/loading.gif">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div *ngIf="img2" class="prd-thumb">
                          <img (click)="viewImg(img2,2)" *ngIf="img2" [src]="img2" alt="" class="imageTag" id="img2">
                          <img *ngIf="!img2" alt="" class="show imageTag" src="../../assets/images/loading/loading.gif">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div *ngIf="img3" class="prd-thumb">
                          <img (click)="viewImg(img3,3)" *ngIf="img3" [src]="img3" alt="" class="imageTag" id="img3">
                          <img *ngIf="!img3" alt="" class="show imageTag" src="../../assets/images/loading/loading.gif">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div *ngIf="img4" class="prd-thumb">
                          <img (click)="viewImg(img4,4)" *ngIf="img4" [src]="img4" alt="" class="imageTag" id="img4">
                          <img *ngIf="!img4" alt="" class="show imageTag" src="../../assets/images/loading/loading.gif">
                        </div>
                      </div>

                    </div>

                    <div class="zoom-popup">
                      <i aria-hidden="true" class="fa fa-search-plus"></i> {{'PRODUCT_VIEW.OPEN_IMAGE' | translate}}
                    </div>
                  </div>


                </div>
                <div class="col-md-8 right-side">
                  <div class="row">
                    <div class="col-md-8 board-right">

                      <div class="product-info-container">
                        <nav aria-label="breadcrumb" class="breadcrumb-nav">
                          <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/products/'+tenantValue]"><i
                                  aria-hidden="true" class="fa fa-home"></i>
                                {{'PRODUCT_VIEW.ALL_CATEGORY' | translate}}</a></li>
                            <li class="breadcrumb-item"><a>
                                <span *ngIf="activeLang === 'en'">{{categoryNameEN}}</span>
                                <span *ngIf="activeLang === 'si'">{{categoryNameSI}}</span>
                                <span *ngIf="activeLang === 'ta'">{{categoryNameTM}}</span>
                              </a></li>
                            <li aria-current="page" class="breadcrumb-item active">
                              <span *ngIf="activeLang === 'en'">{{subCategoryNameEN}}</span>
                              <span *ngIf="activeLang === 'si'">{{subCategoryNameSI}}</span>
                              <span *ngIf="activeLang === 'ta'">{{subCategoryNameTM}}</span>
                            </li>
                          </ol>
                        </nav>

                        <div class="purchase-info">
                          <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.AUCTION' | translate}}</p>
                          <div class="delivery-stats">
                          </div>
                        </div>
                        <div class="product-title">
                          {{name}}
                        </div>
                        <div class="star-icons">
                          <star-rating [value]="rate" checkedcolor="#FFA84C" size="16px" totalstars="5"
                            uncheckedcolor="#999999"></star-rating>
                        </div>
                        <div class="filter-pill viewfill d-flex justify-content-between align-items-center"
                          title="filter products by {{farmerName}}" (click)="filterOn()"
                          [routerLink]="'/products/wholesale'" [queryParams]="{filterUsername:listedBy}"
                          [ngClass]="filstatus ? 'filtered' : 'notfiltered'">
                          <div class="seller-card">
                            <div class="profile-picture">
                              <img src="../../assets/avatar.jpg" alt="">
                            </div>

                            <div class="seller-content">
                              <div class="name">{{farmerName}}</div>
                            </div>
                          </div>
                          <div class="fillicon">
                            <span class="material-icons">
                              filter_list
                            </span>
                          </div>
                        </div>

                        <p id="demo"
                          style="font-size: 35px; text-transform: uppercase; font-weight: 600; color: #474747; margin-top: 15px;">
                        </p>

                        <div dir="rtl">
                        </div>


                        <div class="category">
                          <span class="category-item">{{'PRODUCT_VIEW.CATEGORY' | translate}}
                            <span *ngIf="activeLang === 'en'">{{categoryNameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{categoryNameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{categoryNameTM}}</span>/
                            <span *ngIf="activeLang === 'en'">{{subCategoryNameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{subCategoryNameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{subCategoryNameTM}}</span></span>
                        </div>
                        <div class="best-by-date">
                          <div class="title">
                            {{'PRODUCT_VIEW.LOCATION' | translate}}
                          </div>
                          <div class="info">
                            <span>{{location}}</span>
                          </div>
                        </div>


                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW.LISTED_DATE' | translate}}
                          </div>
                          <div class="info">
                            {{listedDate}}
                          </div>
                        </div>

                        <div class="date">
                          <span class="material-icons">
                            star
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW.BEST_BUY_DATE' | translate}}
                          </div>
                          <div class="info">
                            {{expiryDate}}
                          </div>
                        </div>

                      </div>
                      <div class="price-actions">

                        <div class="min-bid">
                          {{'MY_ACCOUNT.SELL_A_PRODUCT.AUCTION.MINIMUM_BID_VALUE' | translate}}: <span
                            class="partOne">{{'CURRENCY.CURRENCY' | translate}}</span>
                          &nbsp;<span class="partTwo">{{minimumBid}}</span>
                        </div>

                        <div class="row" style="margin-top: 15px;">
                          <div class="col-md-8 aligningg">
                            <div class="input-container">
                              <span class="input-group-addon" style="border-radius: 5px 0 0 5px;">{{'CURRENCY.CURRENCY'
                                | translate}}</span>
                              <input (focusout)="checkEnterBitValue(selectBitValue)" [(ngModel)]="selectBitValue"
                                class="form-control KG-input" style="width: 100%; border-left: 0;" type="number" />

                            </div>
                          </div>
                          <div class="col-md-4 aligningg">
                            <div class="row-space">
                            </div>
                            <div class="input-container">
                              <div class="amount">
                                <input class="form-control KG-input" disabled style="width: 100%" type="number"
                                  value="{{qty}}" />
                              </div>
                              <span class="input-group-addon">{{unitType}}</span>
                            </div>


                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <span (click)="submitBid(true,'suggest1')" class="darkbtn"
                              style="font-size: 12px;color: white;cursor: pointer;padding: unset!important;">
                              {{'CURRENCY.CURRENCY' | translate}}&nbsp;
                              <span id="suggest1" style="font-size: 12px">
                                {{roundBidValue(selectBitValue, 1)}}
                              </span>
                            </span>
                          </div>
                          <div class="col-4">
                            <span (click)="submitBid(true,'suggest2')" class="darkbtn"
                              style="font-size: 12px;color: white;cursor: pointer;padding: unset!important;">
                              {{'CURRENCY.CURRENCY' | translate}}&nbsp;
                              <span id="suggest2" style="font-size: 12px">
                                {{roundBidValue(selectBitValue, 2)}}
                              </span>
                            </span>
                          </div>
                          <div class="col-4">
                            <span (click)="submitBid(true,'suggest3')" class="darkbtn"
                              style="font-size: 12px;color: white;cursor: pointer;padding: unset!important;">
                              {{'CURRENCY.CURRENCY' | translate}}&nbsp;
                              <span id="suggest3" style="font-size: 12px">
                                {{roundBidValue(selectBitValue, 3)}}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12 aligningg">
                            <button (click)="submitBid(false,'suggest0')" class="darkbtn" style="color: white">
                              <span class="material-icons">
                                credit_card
                              </span>{{'Place_the_bid'|translate}}
                            </button>

                            <!-- add new product model -->

                            <!-- <button class="darkbtn" style="color: white" data-toggle="modal"
                              data-target="#placeBidOrderRequest">
                              <span class="material-icons">
                                credit_card
                              </span>{{'Place_the_bid'|translate}}
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="transport-options">
                        <div class="deliver-options-card">
                          <div class="select-delivery">
                            <div class="d-flex justify-content-end">
                              <span class="material-icons hlp">
                                help
                              </span>
                            </div>
                            <p>
                              {{'singleView_line1'|translate}}
                            </p>
                            <div class="option-list">
                              <div class="d-flex align-items-center t-opt"
                                matTooltip="{{'riyaviru_transport_tooltip'| translate}}">
                                <img src="../../assets/images/icons/transport1.png" alt="">
                                <p>{{'riyaviru_transport'|translate}}</p>
                              </div>
                              <div *ngIf="isSeller" class="d-flex align-items-center t-opt"
                                matTooltip="{{'seller_transport_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transport2.png" alt="">
                                <p>{{'seller_transport'|translate}} - {{'Fixed_Rate'|translate}}</p>
                              </div>

                              <div *ngIf=" isSellerFixed" class="d-flex align-items-center t-opt"
                                matTooltip="{{'seller_transport_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transport2.png" alt="">
                                <p>{{'seller_transport'|translate}} - {{'Per_Km_Rate'|translate}}</p>
                              </div>
                              <div *ngIf="this.isTrain" class="d-flex align-items-center t-opt"
                                matTooltip="{{'RailFreightTransport_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transport3.png" alt="">
                                <p>{{'RailFreightTransport'|translate}}</p>
                              </div>
                              <div class="d-flex align-items-center t-opt"
                                matTooltip="{{'SelfPickup_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transpor4.png" alt="">
                                <p>{{'SelfPickup'|translate}}</p>
                              </div>
                            </div>
                            <div class="deliver-cost">
                              <p class="or-text"><span class="material-icons">
                                  local_shipping
                                </span> <span>{{'singleViewDelivery'|translate}}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-4" *ngIf="bidCount > 0">
                    <span data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                      data-toggle="collapse" class="bidbtn viewBids">
                      {{'PRODUCT_VIEW.VIEW_BIDS' | translate}}
                    </span>
                  </div>

                  <div class="collapse" id="collapseExample">
                    <div class="card card-body scroller" style=" height: 200px; overflow-y: scroll;">

                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"> {{'BIDDER' | translate}}</th>
                            <th scope="col"> {{'BID_VALUE' | translate}}</th>
                            <th scope="col"> {{'DATE' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let bid of bidDetails ; let i = index">
                            <td>{{bid.userId}}</td>
                            <td>{{bid.bidValue | number:'2.2-2'}}</td>
                            <td>
                              {{getTime(bid.created)}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>

                  <div class="price-actions">

                    <hr>
                    <div class="desc">
                      <div class="des-title">
                        {{'PRODUCT_VIEW.DESCRIPTION' | translate}}
                      </div>
                      <div class="des-content">
                        {{description}}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="cp-spinner cp-morph"></div>
          </div>
        </div>
        <div class="clear">&nbsp;</div>

        <!-- Product Slider 02 -->
        <div class="w-full-col" style="margin-top: 30px;">
          <h4 style="margin-left: 2vh;">{{'NEW_ARRIVALS' | translate}}</h4>
          <div class="slck-product-slider">
            <ngx-slick-carousel [config]="slideConfig" class="carousel">
              <div *ngFor="let newArrival of allNewArrivalCategory ; let i = index" class="slide ps-item" ngxSlickItem>
                <a [queryParams]="{id:newArrival.id,typeId:newArrival.type.id,typeName:newArrival.type.type}"
                  [routerLink]="['/product-view-'+setType(newArrival.type.type)+'/'+tenantValue]">
                  <div class="ps-inner-wrapper">
                    <div class="img-section-item">
                      <div class="badge-item" *ngIf="newArrival.leadLabel !== null">
                        <img
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.leadLabel+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="">
                      </div>
                      <img *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length > 0"
                        [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }" alt=""
                        class="imageTagNew"
                        src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.spotImages[0].image+ '/?X-TenantID=' + this.tenantValue}}" />
                      <img *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length === 0"
                        [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }" alt=""
                        class="imageTagNew" src="assets/defaultBrokenImage.jpg" />

                      <img *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length > 0"
                        [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }" alt=""
                        class="imageTagNew"
                        src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.futureImages[0].image+ '/?X-TenantID=' + this.tenantValue}}" />

                      <img *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length === 0"
                        [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }" alt=""
                        class="imageTagNew" src="assets/defaultBrokenImage.jpg" />

                      <img *ngIf="newArrival.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length > 0"
                        [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }" alt=""
                        class="imageTagNew"
                        src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.forwardAcuImages[0].image+ '/?X-TenantID=' + this.tenantValue}}" />

                      <img *ngIf="newArrival.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length === 0"
                        [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }" alt=""
                        class="imageTagNew" src="assets/defaultBrokenImage.jpg" />
                      <div class="ps-item-inner">
                        <h5 class="productName">{{newArrival.name}}</h5>
                        <dl style="margin-top: 4px;">
                          <span *ngIf="newArrival.stockAvailable !== 0 && newArrival.type.type !== 'FORWARD_ACU'"
                            class="text" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0); overflow: hidden;
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                            <dt *ngIf="newArrival.sellInSmallerQuantities">{{'CURRENCY.CURRENCY' | translate}}<span
                                style="margin-left: 0.5vw">
                              </span>{{newArrival.spotDTOList[0].unitPrice | number:'2.2-2'}}
                              /{{newArrival.unit.unit}}</dt>
                            <dt *ngIf="!newArrival.sellInSmallerQuantities">{{'CURRENCY.CURRENCY' | translate}}
                              <span style="margin-left: 0.5vw">
                              </span>{{newArrival.spotDTOList[0].unitPrice | number:'2.2-2'}}
                              /{{newArrival.unit.unit}}
                            </dt>
                          </span>
                          <span *ngIf="newArrival.stockAvailable === 0" class="text" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0);
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                            <dt style="color: red">{{'PRODUCT_VIEW.SOLD_OUT' | translate}}</dt>
                          </span>
                        </dl>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ngx-slick-carousel>
          </div>

          <div *ngIf="spinner2 !== 0">
            <div class="sk-cube-grid">
              <div class="sk-cube sk-cube1"></div>
              <div class="sk-cube sk-cube2"></div>
              <div class="sk-cube sk-cube3"></div>
              <div class="sk-cube sk-cube4"></div>
              <div class="sk-cube sk-cube5"></div>
              <div class="sk-cube sk-cube6"></div>
              <div class="sk-cube sk-cube7"></div>
              <div class="sk-cube sk-cube8"></div>
              <div class="sk-cube sk-cube9"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>


<app-footer></app-footer>

<div class="modal fade" id="openImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <img *ngIf="commonImg" class="demo-trigger" src="{{this.commonImg}}" alt="" />
      </div>
    </div>
  </div>
</div>
