import {Component, OnInit} from '@angular/core';
import {CoreService} from '../../../../service/core.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {InteractionService} from '../../../../service/interaction.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-overview-farmer-selling',
  templateUrl: './overview-farmer-selling.component.html',
  styleUrls: ['./overview-farmer-selling.component.css']
})
export class OverviewFarmerSellingComponent implements OnInit {

  userRole: any;
  whoAmIDetails: any;
  activeLang: any;

  dashboardDetails: any;

  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;
  ecoShopCode: any;

  constructor(private coreService: CoreService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              public mainUrl: MainUrlService) {
    this.userRole = localStorage.getItem('USER_ROLE');
    this.activeLang = sessionStorage.getItem('LANG');

    this.whoAmIDetails = JSON.parse(localStorage.getItem('WHO_AM_I_DETAILS'));

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });
  }

  ngOnInit(): void {
    this.loadDashboardDetails();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.route.queryParams.subscribe(params => {
          this.ecoShopCode = params['shopRef'];
          this.isEcoFarmerSection = params['isFarmer'];
          this.activeLang = sessionStorage.getItem('LANG');
          this.loadDashboardDetails();
        });
      }
    });
  }


  loadDashboardDetails() {
    if (this.isEcoFarmerSection) {
      this.coreService.fetchEconomicFarmerSellingDashboard(this.ecoShopCode, this.userRole).subscribe(result => {
        this.dashboardDetails = result;
      });
    } else {
      this.coreService.fetchDashboardSelling(this.whoAmIDetails['email'], this.userRole).subscribe(result => {
        this.dashboardDetails = result;
      });
    }
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

}
