import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {FormBuilder} from '@angular/forms';
import {CommonService} from '../../../common/common.service';
import {ProductService} from '../../../service/product.service';
import {CoreService} from '../../../service/core.service';
import {UserRoleService} from '../../../service/user-role.service';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {MainUrlService} from '../../../service/main-url.service';
import Swal from 'sweetalert2';
import {environment} from '../../../../environments/environment';
import {catchError, last, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {PaymentService} from '../../../service/payment.service';

@Component({
  selector: 'app-pending-my-payments',
  templateUrl: './pending-my-payments.component.html',
  styleUrls: ['./pending-my-payments.component.css']
})
export class PendingMyPaymentsComponent implements OnInit {


  displayedColumns: string[] = ['orderID', 'paymentType', 'slip', 'amount', 'action'];
  tenantValue: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;
  imageArray = [];
  sellerImage: string;
  files: File[] = [];
  referenceNumber: any;
  username: any;
  transactionId: any;
  paymentType: any;

  @ViewChild('closeModal') closeModal;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private coreService: CoreService,
              private userRoleService: UserRoleService,
              private paymentService: PaymentService,
              private router: Router,
              private http: HttpClient,
              private settingsService: SystemSettingsService,
              public mainUrl: MainUrlService,
              private _formBuilder: FormBuilder) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

  ngOnInit() {
    this.fetchAllPendingPayments();
    this.fetchAllGeneralSettings();
  }

  fetchAllGeneralSettings() {
    this.settingsService.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;

          }
        }
      }
    });
  }

  fetchAllPendingPayments() {
    this.commonService.processing();
    this.dataSource = new MatTableDataSource();
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.username = whoAmI['email'];
      this.paymentService.fetchPendingPaymentsByUsername(whoAmI['email']).subscribe(result => {
        let arr = [];
        for (let pendingPayment of result) {

          arr.push({
            'orderID': pendingPayment['transactionId'],
            'paymentType': pendingPayment['paymentType'],
            'bankType': pendingPayment['bankType'],
            'amount': pendingPayment['amount'],
            'status': pendingPayment['status'],
            'referenceNo': pendingPayment['referenceNo'] !== null ? pendingPayment['referenceNo'] : 'N/A',
            'image': pendingPayment['image'] !== null ? this.mainUrl.MAIN_URL_FILE + pendingPayment['image'] + '/?X-TenantID=' + this.tenantValue : false
          });
        }
        this.dataSource = new MatTableDataSource(arr);
        Swal.close();
      });
    });

  }

  commonLogoutURL() {
    Swal.close();
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/' + this.tenantValue]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  onSelect(event) {
    if (this.files.length <= 1 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.uploadImages(event.addedFiles);
        this.files.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files.splice(-1, 1);
        }
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }
  }

  uploadImages(value) {
    this.commonService.processing();
    let formData = new FormData();
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError((error: HttpErrorResponse) => {
        Swal.close();
        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

  updatePendingPaymentDetails() {
    this.loading();
    if (this.imageArray.length > 0) {

      let slipImage;

      if (this.imageArray.length > 0) {
        slipImage = this.imageArray[0]['image'];
      }

      let _body = {
        'transactionId': this.transactionId,
        'userName': this.username,
        'paymentType': this.paymentType,
        'referenceNo': 'N/A',
        'image': slipImage
      };

      this.paymentService.updatePendingPayment(_body).subscribe(() => {
        Swal.close();
        slipImage = undefined;
        this.referenceNumber = undefined;
        this.files = [];
        this.imageArray = [];
        this.closeModal.nativeElement.click();
        this.fetchAllPendingPayments();
      }, () => {
        Swal.close();
      });
    } else {
      Swal.fire({
        text: 'Please submit the reference number or bank slip image.',
        allowOutsideClick: false,
        width: '300px',
        type: 'warning'
      });
    }
  }

  setRowDetails(row: any) {
    this.transactionId = row.orderID;
    this.paymentType = row.paymentType;
  }
}
