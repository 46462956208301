import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {CoreService} from '../../../service/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Global} from '../../../service/global';
import {ProductService} from '../../../service/product.service';
import {MainUrlService} from '../../../service/main-url.service';
import {InteractionService} from '../../../service/interaction.service';
import {TranslateService} from '@ngx-translate/core';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-slider1',
  templateUrl: './slider1.component.html',
  styleUrls: ['./slider1.component.css']
})
export class Slider1Component implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();


  spinner1: number = 1;

  sliderLine1: any;
  defaultImage = '../../assets/images/loadingGIF.gif';

  percentageFormulaLine1: boolean = false;

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    showNavigationIndicators: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  CURRENCY_CODE;
  tenantValue: any;

  PRODUCT_LINE_1_TITLE: any;
  PRODUCT_LINE_1_TITLE_SI: any;
  PRODUCT_LINE_1_TITLE_TA: any;
  PRODUCT_LINE_1_SELECTION: any;
  PRODUCT_LINE_1_SELECTION_CATEGORY: any;
  profileCode: any;
  activeLang: string = 'en';

  constructor(private titleService: Title,
              private core_service: CoreService,
              private router: Router,
              public global: Global,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              private _translateService: TranslateService,
              private setting_service: SystemSettingsService) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }


  ngOnInit() {

    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    if (this.activeLang !== null) {
      sessionStorage.setItem('LANG', this.activeLang);
      this._translateService.use(this.activeLang);
    } else {
      sessionStorage.setItem('LANG', 'en');
      this._translateService.use('en');
    }

    this.fetchAll();
  }

  fetchAll() {
    this.productService.getActiveProfile().subscribe(profileCode => {
      if (profileCode) {
        this.setting_service.fetchAll().subscribe(fetchAll => {
          let tempArray = [];
          tempArray.push(fetchAll);

          for (let settings of tempArray) {
            for (let setting of settings) {


              if ('PRODUCT_LINE_1_TITLE' === setting.code) {
                this.PRODUCT_LINE_1_TITLE = setting.value;
              }
              if ('PRODUCT_LINE_1_TITLE_SI' === setting.code) {
                this.PRODUCT_LINE_1_TITLE_SI = setting.value;
              }
              if ('PRODUCT_LINE_1_TITLE_TA' === setting.code) {
                this.PRODUCT_LINE_1_TITLE_TA = setting.value;
              }

              if ('PRODUCT_LINE_1_SELECTION_CATEGORY' === setting.code) {
                this.PRODUCT_LINE_1_SELECTION_CATEGORY = setting.value;
              }

              if ('PRODUCT_LINE_1_SELECTION' === setting.code) {
                this.PRODUCT_LINE_1_SELECTION = setting.value;
              }

            }


          }
          this.profileCode = profileCode.code;
          if (this.PRODUCT_LINE_1_SELECTION === 'type1') {
            this.productService.fetchAllDiscountSpotProducts(20, profileCode.code, this.tenantValue)
              .subscribe((discountProducts) => {
                if (discountProducts) {
                  this.spinner1 = 0;
                  this.sliderLine1 = discountProducts;
                  this.percentageFormulaLine1 = false;
                }
              });
          } else if (this.PRODUCT_LINE_1_SELECTION === 'type2') {
            this.percentageFormulaLine1 = true;
            this.loadSliderLine1(profileCode.code);
          } else {
            this.productService.fetchAllNewArrivalSpotProducts(20, profileCode.code, this.tenantValue)
              .subscribe(newArrivalProducts => {
                if (newArrivalProducts) {
                  this.spinner1 = 0;
                  this.sliderLine1 = newArrivalProducts;
                }
              });
          }

          this.CURRENCY_CODE = this.global.CURRENCY_CODE;
        });

      }
    });
  }

  loadSliderLine1(profileCode) {
    this.productService
      .slidersByCategory(this.PRODUCT_LINE_1_SELECTION_CATEGORY, profileCode, 0, 30)
      .subscribe((productResult) => {
        if (productResult) {
          this.spinner1 = 0;
          this.sliderLine1 = productResult;
        }
      });
  }

  setType(type: any) {
    switch (type) {
      case 'SPOT':
        return 'spot';

      case 'FORWARD_BN':
        return 'forward';

      default:
        return 'bid';
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
