<div class="container">
  <div class="row pip-title">
    <div class="col-8">
      <h2>My Wholesalers</h2>
    </div>
    <div class="col-12">
      <div class="separator">&nbsp;</div>
    </div>
  </div>
  <div class="item-container">
    <div style="width:100%;max-height: 500px ; height: auto; overflow-y: auto;">
      <mat-form-field class="matsearch">
        <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
      </mat-form-field>

      <table [dataSource]="dataSource" class="table" mat-table matSort style="width:100%">

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.FARMER' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            <div class="d-flex flex-row align-items-center mt-2 mb-2">
              <div style="width: 50px;">
                <img alt=""
                     src="{{ row.userLocationDTO?.imageUrl !==null? this.mainUrl.MAIN_URL_FILE + row.userLocationDTO.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar?d=mp'}}"
                     style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
              </div>
              <div class="rightdiv">
                <h4>{{ row.name }}</h4>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.LOCATION' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;width: 20%;">
            {{row.address}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Products">
          <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PRODUCTS' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            {{row.productCount}}
            <!--            <span-->
            <!--            [routerLink]="['/my-account/agent-products/'+ tenantValue]"-->
            <!--            class="bluetxt">{{row.productCount}}<i class="fa fa-caret-right"></i></span>-->
          </td>
        </ng-container>

        <ng-container matColumnDef="Earnings">
          <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.EARNING' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
            {{'CURRENCY.CURRENCY' | translate}} {{row.totalEarnings | number: '2.2-2'}}
          </td>
        </ng-container>

        <!--<ng-container matColumnDef="referral">-->
        <!--<th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>-->
        <!--{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.REFERRAL_EARNINGS' | translate}}-->
        <!--</th>-->
        <!--<td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">{{'CURRENCY.CURRENCY' | translate}}-->
        <!--&lt;!&ndash;            {{row.totalEarnings}}&ndash;&gt;5,485.00-->
        <!--</td>-->
        <!--</ng-container>-->

        <!--<ng-container matColumnDef="percentage">-->
        <!--<th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>-->
        <!--%-->
        <!--</th>-->
        <!--<td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">40%-->
        <!--</td>-->
        <!--</ng-container>-->

        <ng-container matColumnDef="added">
          <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.ADDED_DATE' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            {{convertDate(row.created)}}
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="loadProducts(row.username)" *matRowDef="let row; columns: displayedColumns"
            data-target="#viewmodal" data-toggle="modal" mat-row></tr>
      </table>

      <mat-paginator #paginator1 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

  </div>
</div>

<!-- approve modal -->
<div aria-hidden="true" class="modal fade npa-model" id="viewmodal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content" style="width: auto!important;">
      <div class="modal-header blank-header">

        <button #closebutton aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="inside-modal">
          <div class="">
            <div class="top-big">
              <div class="d-flex floatbox">
                <img alt=""
                     class="img"
                     src="{{farmerImageUrl}}"/>
                <div class="rights">
                  <h4>{{farmerName}}<br/>
                    <!--                    <span>(HVF1234567)</span>-->
                  </h4>
                  <p *ngIf="activeLang === 'en'">{{farmerLocationEN}}</p>
                  <p *ngIf="activeLang === 'si'">{{farmerLocationSI}}</p>
                  <p *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</p>
                </div>
              </div>
              <div class="text-right">
                <h5 style="font-size: 14px;color: #828282;">{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.ACCOUNT_NO' | translate}}
                  <br/>
                  <strong style="font-size: 18px;">{{bankAccount}}</strong></h5>

                <div class="sales">
                  <p><span>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.ORDERS' | translate}}
                    {{ordersCount}} &nbsp;<i class="fa fa-caret-up greentext"></i></span> &nbsp;&nbsp;
                    <span>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.SALES' | translate}}
                      {{salesCount}}
                      &nbsp;<i class="fa fa-caret-down orangetext"></i></span></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <mat-divider></mat-divider>
            <div class="pro-det">
              <div class="row" style="padding: 30px;">
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.TOTAL_EARNING' | translate}}</h6>
                      <h3 class="greentext" *ngIf="isTotalEarnings">{{'CURRENCY.CURRENCY' | translate}}
                        <!--                        {{totalEarnings}}-->
                        <!--                        {{Earning | number: '2.2-2'}}-->
                        {{totalEarnings | number: '2.2-2'}}
                      </h3>
                      <h3 class="greentext" *ngIf="!isTotalEarnings">{{'CURRENCY.CURRENCY' | translate}}
                        00.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PENDING_PAYMENTS' | translate}}</h6>
                      <!--                      <h3 class="orangetext">{{'CURRENCY.CURRENCY' | translate}} 3,400.00</h3>-->
                      <h3 class="greentext">{{'CURRENCY.CURRENCY' | translate}}
                        00.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.REFERRAL_EARNING' | translate}}
                        <span><strong></strong></span></h6>
                      <h3 class="bluetxt">{{'CURRENCY.CURRENCY' | translate}} 00.00</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tablefor">
            <p class="recentearn">{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PRODUCT_WEEKS' | translate}}<span
              class="text-rights bluetxt"><a
              class=""
              data-dismiss="modal"
              data-target="#earnings"
              data-toggle="modal">{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.EARNING' | translate}}<i
              aria-hidden="true" class="fa fa-long-arrow-right"></i></a></span></p>
            <table [dataSource]="dataSourceViewModal" class="table admin-table" mat-table matSort
                   style="width:100%">

              <ng-container matColumnDef="product">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PRODUCTS' | translate}}
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                  <div class="d-flex flex-row align-items-center mt-2 mb-2">
                    <div style="width: 50px;">
                      <img alt=""
                           src="{{  row.image!==null?  this.mainUrl.MAIN_URL_FILE + row.image + '/?X-TenantID=' + this.tenantValue: 'https://www.gravatar.com/avatar?d=mp'}}"
                           style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                    </div>
                    <div class="rightdiv">
                      <h4>{{ row.name }}</h4>
                      <p>{{ row.sku }}</p>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="category">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  {{'MY_ACCOUNT.SALES.MY_PRODUCTS.CATEGORY' | translate}}
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                  {{row.category}}
                </td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRICE' | translate}}
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                       <span *ngIf="row.type === 'SPOT' && row.isSellInSmallerQty">
                     {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.spotStock| number:'2.2-2'}}
                      </span>
                  <span *ngIf="row.type === 'SPOT' && !row.isSellInSmallerQty">
                     {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.totalQuantity| number:'2.2-2'}}
                      </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="bulkQty">
                <th *matHeaderCellDef mat-header-cell>
                  {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.BULK_QUANTITY' | translate}}</th>
                <td *matCellDef="let element" mat-cell>
                          <span *ngIf="element.type === 'SPOT' && element.isSellInSmallerQty">
                    &nbsp;{{element.spotStock}}{{element.unit}}
                      </span>
                  <span *ngIf="element.type === 'SPOT' && !element.isSellInSmallerQty">
                     {{element.totalQuantity}}{{element.unit}}
                      </span>

                </td>
              </ng-container>

              <ng-container matColumnDef="stockAvailable">
                <th *matHeaderCellDef mat-header-cell>
                  {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.AVAILABLE_QUANTITY' | translate}}</th>
                <td *matCellDef="let element" mat-cell> {{element.stockAvailable}} {{element.unit}}</td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_TYPE' | translate}}
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">{{ row.type }}
                </td>
              </ng-container>

              <ng-container matColumnDef="expiredDate">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  {{'MY_ACCOUNT.SALES.MY_PRODUCTS.BEST_BUY_DATE' | translate}}
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                       <span *ngIf="row.type === 'SPOT'">
                      {{convertDate(row.spotExpiredDate)}}
                      </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="added">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ADDED_DATE' | translate}}
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                  {{convertDate(row.createdDate)}}
                </td>
              </ng-container>

              <ng-container matColumnDef="action1">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                </th>
                <td *matCellDef="let row" class="center-table tbl" data-dismiss="modal" data-target="#editProduct1"
                    mat-cell
                    style="padding-left: 0;">
                  <button (click)="loadDataForUpdate(editProduct1, row.allItems)" class="actionBtn" mat-raised-button>
                    <i aria-hidden="true" class="fa fa-pencil"></i>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="action2">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                </th>
                <td *matCellDef="let row" class="center-table tbl" data-dismiss="modal" data-target="#productview"
                    data-toggle="modal"
                    mat-cell
                    style="padding-left: 0;">
                  <button (click)="setProductDetails(row)" class="actionBtn" mat-raised-button>
                    <i aria-hidden="true" class="fa fa-eye"></i>
                  </button>
                </td>
              </ng-container>

              <tr *matHeaderRowDef="displayedColumnsViewModal" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumnsViewModal"
                  mat-row></tr>
            </table>

            <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

          </div>
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">
              {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.CLOSE' | translate}}</a>
            <a (click)="passingFarmerUsername()" aria-label="Next" class="btn next-btn-half" data-dismiss="modal"
               data-target="#editspotProduct"
               data-toggle="modal"><i class="fa fa-server"></i>
              {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.SELL_A_PRODUCT' | translate}}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<!-- order review -->
<ng-template #editProduct1 let-modal>

  <app-farmer-edit-spot-product [product]="product"></app-farmer-edit-spot-product>

</ng-template>


<!-- earnings modal-->
<div aria-hidden="true" class="modal fade npa-model" id="earnings" role="dialog" style="z-index: 1000!important;"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header blank-header">

        <button #closebutton aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="inside-modal">
          <div class="">
            <div class="top-big">
              <div class="d-flex floatbox">
                <img alt=""
                     class="img"
                     src="{{farmerImageUrl}}"/>
                <div class="rights">
                  <h4>{{farmerName}}<br/>
                    <!--                    <span>(HVF1234567)</span>-->
                  </h4>
                  <p *ngIf="activeLang === 'en'">{{farmerLocationEN}}</p>
                  <p *ngIf="activeLang === 'si'">{{farmerLocationSI}}</p>
                  <p *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</p>
                </div>
              </div>
              <div class="text-right">
                <h5 style="font-size: 14px;color: #828282;">Bank account No <br/>
                  <strong style="font-size: 18px;">{{bankAccount}}</strong></h5>

                <div class="sales">
                  <p><span>Orders: {{ordersCount}} &nbsp;<i class="fa fa-caret-up greentext"></i></span> &nbsp;&nbsp;
                    <span>Sales: {{salesCount}}
                      &nbsp;<i class="fa fa-caret-down orangetext"></i></span></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <mat-divider></mat-divider>
            <div class="pro-det">
              <div class="row" style="padding: 30px;">
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>Total earnings </h6>
                      <h3 class="greentext">{{'CURRENCY.CURRENCY' | translate}} 5,485.00</h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>Pending payments</h6>
                      <h3 class="orangetext">{{'CURRENCY.CURRENCY' | translate}} 3,400.00</h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>Referral Earnings <span><strong>40%</strong></span></h6>
                      <h3 class="bluetxt">{{'CURRENCY.CURRENCY' | translate}} 1,150.00</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tablefor">
            <p class="recentearn">Earnings for
              last 3 weeks <span class="text-rights bluetxt"><a data-dismiss="modal" data-target="#viewmodal"
                                                                data-toggle="modal">All Products <i
                aria-hidden="true"
                class="fa fa-long-arrow-right"></i></a></span></p>
            <table [dataSource]="dataSourceEarnings" class="table admin-table" mat-table matSort style="width:100%">

              <ng-container matColumnDef="tr-ID">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Transaction ID
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  HVF1234567
                </td>
              </ng-container>
              <ng-container matColumnDef="proid">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Product ID
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  1009392
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Product Name
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  Watermelon
                </td>
              </ng-container>
              <ng-container matColumnDef="buyer">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Buyer
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  <span class="bluetxt">Kasun Sameera</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="total">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Total Amount
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  <span class="greentext">{{'CURRENCY.CURRENCY' | translate}} 3400.00</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="referral">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Referal Earnings
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  <span class="bluetxt">{{'CURRENCY.CURRENCY' | translate}} 800.00 (40%)</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Date">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Date
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  10 / 11 / 2019
                </td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumnsEarnings" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumnsEarnings" data-dismiss="modal"
                  data-target="#transaction-modal" data-toggle="modal"
                  mat-row></tr>
            </table>

            <mat-paginator #paginator3 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

          </div>
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">Close</a>
            <a (click)="passingFarmerUsername()" aria-label="Next" class="btn next-btn-half" data-dismiss="modal"
               data-target="#editspotProduct"
               data-toggle="modal"><i class="fa fa-server"></i> &nbsp;Sell a product</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- product modal -->
<div #editProduct aria-hidden="true" class="modal fade npa-model" id="productview" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="w-100 d-flex justify-content-end">
        </div>
        <app-table-product-view></app-table-product-view>
      </div>
    </div>
  </div>
</div>


<!-- transaction modal -->
<div aria-hidden="true" class="modal fade npa-model" id="transaction-modal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header blank-header">

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div> -->
      <div class="modal-body">
        <div class="inside-modal">
          <div class="topprofile">
            <p style="padding: 25px 0; font-weight: bold; font-size: 24px; color: #4F4F4F;">Transaction #1234567890
              <span
                class="text-rights" style="font-size: 14px;"> Referal Earnings (40%) <br/>
                <h2 style="font-weight: bold;font-size: 24px; color: #2F80ED;">{{'CURRENCY.CURRENCY' | translate}}
                  850.00</h2>
              </span></p>
            <div class="bordersides row">
              <div class="imgs-big d-flex col-6">
                <div><img src="../../../assets/images/temp/banana.png"/></div>
                <div class="rightdiv">
                  <h4>Banana</h4>
                  <p>#567890</p>
                </div>
              </div>
              <div class="col-6 listview">
                <ul>
                  <li><span class="boldtxt">Order Approved: </span> <span class="greentext"><i class="fa fa-check"></i>
                      Approved</span></li>
                  <li><span class="boldtxt">Goods Dispatched:</span> <span class="greentext"><i class="fa fa-check"></i>
                      Dispatched</span></li>
                  <li><span class="boldtxt">Goods Recived:</span> <span class="greentext"><i class="fa fa-check"></i>
                      Recieved</span></li>
                </ul>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="bottom-profile row">
            <div class="col-6 listview">
              <p><strong>Order Details</strong></p>
              <ul>
                <li><span class="boldtxt">Transaction ID : </span> <span> #1234567890</span></li>
                <li><span class="boldtxt">Item Price:</span> <span>{{'CURRENCY.CURRENCY' | translate}} 300.00</span>
                </li>
                <li><span class="boldtxt">Quantity:</span> <span>12</span></li>
                <li><span class="boldtxt">Amount:</span> <span>{{'CURRENCY.CURRENCY' | translate}} 3,000.00</span></li>
                <li><span class="boldtxt">Buyer:</span> <span>Jeewan Sachintha</span></li>
                <li><span class="boldtxt">Purchased Date:</span> <span>20 / 07 / 2020</span></li>
              </ul>
            </div>
            <div class="col-6 listview">
              <p><strong>Transaction Details </strong></p>
              <ul>
                <li><span class="boldtxt">Billing Cycle : </span> <span> 15th Billing Cycle </span></li>
                <li><span class="boldtxt">Bank Account No :</span> <span>23428374992</span></li>
                <li><span class="boldtxt">Name of the Bank :</span> <span>Commercial - Batharamulla</span></li>
                <li><span class="boldtxt">Transaction Date :</span> <span>N/A</span></li>
                <li><span class="boldtxt">Payee:</span> <span class="bluetxt">Helaviru</span></li>
                <li><span class="boldtxt">Settlement Status:</span> <span class="orangetext">Pending</span></li>
                <li><span class="boldtxt">Settlement Date :</span> <span>15 / 08 / 2020</span></li>
              </ul>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="text-center" style="padding-top: 20px;">
            <button class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" class="modal fade npa-model" id="editspotProduct" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <app-farmer-sell-a-product [isAgentFarmerLogin]="true"
                                   [agentFarmerUsername]="farmerUsername"></app-farmer-sell-a-product>
      </div>
    </div>
  </div>
</div>
