<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-7">
              <h2>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_ACCOUNT' | translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 bankcardsbox section-transport" *ngFor="let acc of accounts; let i = index;" style="margin-top: 5px;">
              <div class="bank-cards">
                <div class="d-flex justify-content-between align-items-center" style="word-break: break-word">
                  <h3 class="green">{{acc.bankBranch.bank.name}}
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_ACCOUNTS' | translate}}</h3>
                  <div class="radio-container ml-3">
                    <div>
                      <input (click)="setDefault(acc.id)" [id]="'account'+i" class="form-check-input"
                             [value]="acc.id" [name]="'account'" type="radio" [checked]="acc.isDefault">
                      <label [for]="'account'+i" *ngIf="acc.approval === 'ACTIVE'" class="form-check-label">
                      </label>
                    </div>
                  </div>
                </div>
                <h2 *ngIf="acc.accountType==='SAVINGS'" class="font-weight-bold justify-content-between align-items-center" style="font-size: 1.8rem;word-break: break-word">
                  {{acc.accountNo}}({{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.SAVING_ACCOUNT' | translate}})</h2>
                <h2 *ngIf="acc.accountType==='CURRENT'" class="font-weight-bold justify-content-between align-items-center" style="font-size: 1.8rem;word-break: break-word">
                  {{acc.accountNo}}({{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CURRENT_ACCOUNT' | translate}})</h2>
                <h5>{{acc.bankBranch.description}}</h5>
                <p class="gray-light mb-2">{{acc.holderName}}</p>
                <label *ngIf="acc.approval === 'REJECTED' && acc.rejectMessage !== 'null'">
                  <h5>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.REJECT_REASON' | translate}}
                    {{acc.rejectMessage}}</h5>
                </label>
                <br>

                <div class="row">
                  <div class="col-6">
                    <span class="btn btn-success" *ngIf="acc.approval === 'ACTIVE'">
                {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACTIVE' | translate}}</span>
                <span class="btn btn-warning" *ngIf="acc.approval === 'PENDING'">
                {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.PENDING' | translate}}</span>
                <span class="btn btn-danger" *ngIf="acc.approval === 'REJECTED'">
                {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.REJECTED' | translate}}</span>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-danger red" *ngIf="acc.approval !== 'REJECTED'" (click)="deleteAccount(acc.id)">
                      {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DELETE' | translate}}</button>
                    <button class="btn btn-danger red" *ngIf="acc.approval === 'REJECTED'"
                            data-toggle="modal" (click)="getAccountById(acc.id)" data-target="#editOtherBanks">
                      {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.EDIT_ACCOUNT' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex align-items-center" style="padding-top: 5%;">
              <div class="add-new-bank ml-4" data-toggle="modal" data-target="#addOtherBanks">
                <img src="../../../../assets/images/bank-plus.svg" alt="">
                <p class="green mb-0">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_ACCOUNTS' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- add bank model -->
<div class="modal fade npa-model" data-backdrop="static" id="addOtherBanks" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <form [formGroup]="bankAccForm" (ngSubmit)="addAccount()">
        <div class="modal-header">
          <h5>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ADD_BOC_ACCOUNT' | translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" (click)="selectNewProductOptions==0"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_NAME' | translate}}
                  <span class="error">*</span></label>
                <select [ngClass]="{ 'is-invalid': submitted && f.bank.errors }"
                        class="form-control" id="bank" (change)="loadBranch()" formControlName="bank">
                  <option *ngFor="let bank of bankList"
                          [value]="bank.id">{{bank.name}}
                  </option>
                </select>
                <div *ngIf="submitted  && form.bank.errors">
                  <div *ngIf="form.bank.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_NAME_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_BRANCH' | translate}} <span
                    class="error">*</span></label>
                <select [ngClass]="{ 'is-invalid': submitted && f.bankBranch.errors }"
                        formControlName="bankBranch" id="bankBranch" class="form-control">
                  <option value="" >{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.SELECT_BANK_BRANCH' | translate}}</option>
                  <option *ngFor="let brn of branches" [value]="brn.code">{{ brn.description }}</option>
                </select>
                <div *ngIf="submitted  && form.bankBranch.errors">
                  <div *ngIf="form.bankBranch.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BRANCH_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_TYPE' | translate}}<span
                    class="error">*</span></label>
                <select [ngClass]="{ 'is-invalid': submitted && f.accountType.errors }"  formControlName="accountType" id="accountType" class="form-control">
                  <option value="SAVINGS">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.SAVING_ACCOUNT' | translate}}</option>
                  <option value="CURRENT">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CURRENT_ACCOUNT' | translate}}</option>
                </select>
                <div *ngIf="submitted  && form.accountType.errors">
                  <div *ngIf="form.accountType.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.TYPE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_NUMBER' | translate}}<span
                    class="error">*</span></label>
                <input [ngClass]="{ 'is-invalid': submitted && f.accountNo.errors }" type="number" min="0"
                       formControlName="accountNo"
                  name="accountNo" id="accountNo" class="form-control"
                  placeholder="{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.YOUR_ACCOUNT_NUMBER' | translate}}">
                <div *ngIf="submitted  && form.accountNo.errors">
                  <div *ngIf="form.accountNo.errors.required" class="error">

                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label> {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_HOLDER_NAME' | translate}}
                  <span class="error">*</span></label>
                <input [ngClass]="{ 'is-invalid': submitted && f.holderName.errors }" type="text" formControlName="holderName" name="holderName" id="holderName" class="form-control"
                  placeholder="{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.NAME_ON_ACCOUNT' | translate}}">
                <div *ngIf="submitted  && form.holderName.errors">
                  <div *ngIf="form.holderName.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.NAME_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DESCRIPTION' | translate}}</label>
                <textarea [ngClass]="{ 'is-invalid': submitted && f.description.errors }" class="form-control"
                          formControlName="description" name="description" id="description"
                  cols="30" rows="5"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_IMAGE' | translate}}</label>
              <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_IMAGE_SIZE' | translate}}</label><br>
              <label>1.&nbsp; {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_IMAGE_INFO_1' | translate}}</label>
              <ul>
                <li>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_ACCOUNT_NO' | translate}}</li>
                <li>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_ACCOUNT_HOLDER' | translate}}</li>
                <li>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_ADDRESS' | translate}}</li>
                <li>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_BRANCH' | translate}}</li>
                <li>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_NIC_NO' | translate}}</li>
              </ul>
              <label>2.&nbsp; {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CAPTURE_IMAGE_INFO_2' | translate}}</label>
              <p style="
    color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
                {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE' | translate}}5MB
                {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE_ALLOWED' | translate}}</p>
              <p style=" color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
                {{'ALLOWED_FILE_TYPES' | translate}}</p>
              <ngx-dropzone (change)="onSelect($event)"  accept="image/png,image/jpeg,image/jpg" [disableClick]="this.files.length === 1"
                            [multiple]="false">
                <ngx-dropzone-label >
                  <div class="uploaderbox">
                    <em class="icon fa fa-camera"></em>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview (removed)="onRemove(f)" *ngFor="let f of files" [file]="f" [removable]="true"
                                            ngProjectAs="ngx-dropzone-preview">
                </ngx-dropzone-image-preview>
                <div *ngIf="this.files.length  === 0" class="imgadd"><em class="fa fa-plus"></em></div>
              </ngx-dropzone>
            </div>
          </div>
          <div class="row justify-content-between pl-5 pr-5 pt-5">
            <button class="btn btn-default dark" #closeButton data-dismiss="modal" type="button" (click)="clearAddBankFields()">
              {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CANCEL' | translate}}</button>
            <button class="btn btn-default next" type="submit">
              {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ADD_CONTINUE' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- edit bank model -->
<div class="modal fade npa-model" data-backdrop="static" id="editOtherBanks" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <form [formGroup]="editBankAccForm">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Name of the Bank<span class="error">*</span></label><br>
                <select [ngClass]="{ 'is-invalid': submitted && form1.accountBankName.errors }"
                        class="form-control" id="accountBankName" (change)="loadBankBranch($event)"
                        [(ngModel)]="accountBankName" formControlName="accountBankName">
                  <option *ngFor="let bank of bankList"
                          [value]="bank.id">{{bank.name}}
                  </option>
                </select>
                <div *ngIf="submitted  && form1.accountBankName.errors">
                  <div *ngIf="form1.accountBankName.errors.required" class="error">
                    Bank is required.</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_BRANCH' | translate}} <span
                  class="error">*</span></label><br>
                <select [ngClass]="{ 'is-invalid': submitted && form1.accountBankBranch.errors }"
                        [(ngModel)]="accountBankBranch"
                        formControlName="accountBankBranch" (click)="selectBankBranch($event)" class="form-control">
                  <option value="0" disabled >Select a bank branch</option>
                  <option *ngFor="let brn of branchList" [value]="brn.code">{{ brn.description }}</option>
                </select>
                <div *ngIf="submitted  && form1.accountBankBranch.errors">
                  <div *ngIf="form1.accountBankBranch.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BRANCH_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_TYPE' | translate}}<span
                  class="error">*</span></label>
                <select [(ngModel)]="editAccountType" [ngClass]="{ 'is-invalid': submitted && form1.editAccountType.errors }"
                        formControlName="editAccountType" class="form-control">
                  <option value="SAVINGS">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.SAVING_ACCOUNT' | translate}}</option>
                  <option value="CURRENT">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CURRENT_ACCOUNT' | translate}}</option>
                </select>
                <div *ngIf="submitted  && form1.editAccountType.errors">
                  <div *ngIf="form1.editAccountType.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.TYPE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_NUMBER' | translate}}<span
                  class="error">*</span></label>
                <input [(ngModel)]="editAccountNo" [ngClass]="{ 'is-invalid': submitted && form1.editAccountNo.errors }"
                       type="number" min="0"  formControlName="editAccountNo"
                       name="accountNo" class="form-control"
                       placeholder="{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.YOUR_ACCOUNT_NUMBER' | translate}}">
                <div *ngIf="submitted  && form1.editAccountNo.errors">
                  <div *ngIf="form1.editAccountNo.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label> {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACCOUNT_HOLDER_NAME' | translate}}
                  <span class="error">*</span></label>
                <input [(ngModel)]="accountHolder" [ngClass]="{ 'is-invalid': submitted && form1.accountHolder.errors }"
                       type="text" formControlName="accountHolder" name="holderName" class="form-control"
                       placeholder="{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.NAME_ON_ACCOUNT' | translate}}">
                <div *ngIf="submitted  && form1.accountHolder.errors">
                  <div *ngIf="form1.accountHolder.errors.required" class="error">
                    {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.NAME_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DESCRIPTION' | translate}}</label>
                <textarea [(ngModel)]="accountDes" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                          class="form-control" formControlName="description" name="description"
                          cols="30" rows="5"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <label>Previous Passbook Image</label>
              <ng-image-slider [images]="imageObject" [imageSize]="30" #nav></ng-image-slider><br><br>
              <label>{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BANK_IMAGE' | translate}}</label>
              <ngx-dropzone (change)="onSelectEdit($event)" accept="image/png,image/jpeg" [disableClick]="this.files1.length  === 1"
                            [multiple]="false">
                <ngx-dropzone-label >
                  <div class="uploaderbox">
                    <em class="icon fa fa-camera"></em>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview (removed)="onRemoveEdit(f)" *ngFor="let f of files1" [file]="f" [removable]="true"
                                            ngProjectAs="ngx-dropzone-preview">
                </ngx-dropzone-image-preview>
                <div *ngIf="this.files.length  === 0" class="imgadd"><em class="fa fa-plus"></em></div>
              </ngx-dropzone>
            </div>
          </div>
          <div class="row justify-content-between pl-5 pr-5 pt-5">
            <button class="btn btn-default dark" data-dismiss="modal" type="button" (click)="clearAddBankFields()">
              {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.CANCEL' | translate}}</button>
            <button class="btn btn-default next" type="submit" (click)="updateAccount()" data-dismiss="modal">
              {{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ADD_CONTINUE' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
