import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';

const CART_URL = '/cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  addCart(id, body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT +
      CART_URL + '/addToCart/' + id, body);
  }

  findCartByOwner(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT +
      CART_URL + '/findCartByOwner/' + id);
  }

  updateCartQty(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + CART_URL + '/updateCart',body);
  }

  findCartByUsername(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + CART_URL + '/findCartByUsername/' + id);
  }

  showCartByOwner(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + CART_URL + '/showCartByOwner/' + id);
  }

  showCartByUsername(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + CART_URL + '/showCartByUsername/' + id);
  }

  deleteItemFromCartOwner(id, productId, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + CART_URL + '/deleteItemFromCartOwner/' + id + '/' + productId, '');
  }

  deleteItemFromCartUser(id, productId, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + CART_URL + '/deleteItemFromCartUser/' + id + '/' + productId, '');
  }

  fetchAllInsurance(value, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insuranceProvider/newPremium/' + value);
  }

  addInsuranceDetails(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/insuranceProvider/addInsuranceDetails', body);
  }

  addInsuranceDetailsBid(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/insuranceProvider/addInsuranceDetailsForBid', body);
  }


  removeInsuranceDetails(id, tenantValue): Observable<any> {
    return this.http.delete<any>(this.mainUrl.MAIN_URL_CORE + '/insuranceProvider/deleteDetails/' + id);
  }


  getSubTotal(username, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/cart/getSubTotal/' + username);
  }

  getSubTotalBid(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/bidAward/getPricingByUsername/' + id);
  }


  detailsByCartId(cartId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insuranceProvider/detailsByCartId/' + cartId);
  }

  detailsByBidId(bidId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insuranceProvider/detailsByBidId/' + bidId);
  }

  getPricingByCartID(cartId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/cart/getPricingByCartID/' + cartId);
  }

  getPricingByUsername(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/pricing/getDetails',body);
  }

  fetchTransportDetails(sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/spot/getDeliveryDetails/' + sku);
  }

  fetchOrderRequestTransportDetails(sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/orderRequest/getDeliveryDetails/' + sku);
  }

  fetchAuctionTransportDetails(sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/getDeliveryDetails/' + sku);
  }

  fetchOrderRequestTransportDetailsByBidId(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/getDeliveryDetailsByBid/' + id);
  }
}
