<div class="page-contents">
    <div class="packge-form" id="insurnce">
        <div class="top-info-section text-center">
            <img src="../../../../../../../assets/images/payment-types/insuarance/fire.png" alt="" class="sltimg">
            <h4 class="title-pck">Helaviru Fire Insurance Benefit</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu ac egestas elit odio nulla volutpat in. Erat
                et amet morbi sed lectus. Pellentesque id donec platea posuere integer sed sed. Iaculis nulla volutpat
                tempor donec. Nulla enim habitant pretium amet sed posuere.
            </p>
            <p class="rednotice">** You have to provide a valid document proof</p>
        </div>
        <hr>
        <div class="scheme-doc">
            <div class="scheme-details">
                <p>The policy covers both Building and the Contents (Stock in trade, electrical items, furniture etc.).
                    The perils covered are as follows.<br /><br />

                    - Fire & Lightning<br />
                    - Explosion<br />
                    - Aircraft Damage<br />
                    - Impact Damage<br />
                    - Cyclone/Storm/Tempest<br />
                    - Flood<br />
                    - Earthquake (including Fire & Shock)<br />
                    - Bursting & overflowing of water tanks<br />
                    - Spontaneous combustion<br />
                    - Electrical inclusion clause (Maximum limit Up to Rs.100,000/- for building wiring only)
                </p>

                <div class="d-tables">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Policy</th>
                                <th scope="col">Rate Range</th>
                                <th scope="col">Remarks </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Business Premises</th>
                                <td>0.05% - 0.25%
                                </td>
                                <td>Rates depend on type of the risk, claims experience & etc. If there is any doubt,
                                    please contact SLIC Head office </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-tables">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Occupation </th>
                                <th scope="col">Rate Range</th>
                                <th scope="col">Excess applicable on All claims – subject to revision of excesses based
                                    on claim
                                    experience </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Ware house </th>
                                <td>0.1%</td>
                                <td>10% with a minimum of Rs.15,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>Stores</th>
                                <td>0.1%</td>
                                <td>10% with a minimum of Rs.15,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>Show rooms </th>
                                <td>0.05% </td>
                                <td>10% with a minimum of Rs.10,000/- on e.e.l
                                </td>
                            </tr>
                            <tr>
                                <th>Wholesale /Retail Shop</th>
                                <td>0.1% </td>
                                <td>10% with a minimum of Rs.10,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>Office</th>
                                <td>0.05% </td>
                                <td>10% with a minimum of Rs.5,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>Filling stations</th>
                                <td>0.25% </td>
                                <td>15% with a minimum of Rs.15,000/- on e.e.l
                                </td>
                            </tr>
                            <tr>
                                <th>Others</th>
                                <td> </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-tables">
                    <h4>Business premises premium calculation</h4>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Perils covered</th>
                                <th scope="col">Fire & allied perils </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sum insured</td>
                                <td>Building – Rs.3,000,000/- <br />
                                    Contents – Rs.2,000,000/- <br />
                                    Total - Rs.5,000,000/-</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">New Business</th>
                                <th scope="col">Show room</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sum insured</td>
                                <td>Rs.5,000,000.00</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="col">Premium Rate</th>
                                <th scope="col">Premium Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Basic Premium</td>
                                <td>0.050%</td>
                                <td>Rs.2,500.00</td>
                            </tr>
                            <tr>
                                <td>Total Premium (W/O Tax) </td>
                                <td></td>
                                <td>Rs.2,500.00</td>
                            </tr>
                            <tr>
                                <td>Admin Fee</td>
                                <td>0.35%</td>
                                <td>Rs.8.75</td>
                            </tr>
                            <tr>
                                <td>Policy Fee </td>
                                <td></td>
                                <td>Rs.500.00</td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td>8%</td>
                                <td>Rs.240.00</td>
                            </tr>
                            <tr>
                                <th>Total Premium</th>
                                <td></td>
                                <th>Rs.3249.45</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="conditions">
                    <h4>Conditions</h4>
                    <ul>
                        <li>Terms and conditions might vary if adverse risk features were to be identified during a
                            survey or otherwise</li>
                        <li>Terms and conditions will be revised according to the past claim experience</li>
                        <li>Submission of values separately on generators, elevators, escalators to be included under
                            the total sum insured of said items with their Serial No(s), Model No(s), year of
                            manufacture/purchase before confirmation of cover</li>
                        <li>Taxes applicable will be revised based on revision of government tax policy</li>
                    </ul>
                </div>
                <!-- <section class="example-section">
                    <mat-checkbox class="example-margin">I have read and agreed to the tearms and conditions
                    </mat-checkbox>
                </section> -->
            </div>
            <div class="form-group btn-sections b-0 p-0 text-right">
                <a aria-label="Close" class="btn cancel-btn-half">Back</a>
                <a aria-label="Next" class="btn next-btn-half" type="submit">Submit</a>
            </div>
        </div>
    </div>
</div>