import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CoreService} from '../../../../service/core.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {Router} from '@angular/router';
import {InteractionService} from '../../../../service/interaction.service';


@Component({
  selector: 'app-manage-loans',
  templateUrl: './manage-loans.component.html',
  styleUrls: ['./manage-loans.component.css']
})
export class ManageLoansComponent implements OnInit {
  tenantValue: any;
  activeLang: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['LoanID', 'amount', 'datel', 'Status', 'Action'];
  dataSource: MatTableDataSource<any>;
  spinner: boolean = true;

  constructor(private coreService: CoreService,
              private router: Router,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit(): void {
    this.loadLoanRequests();
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  loadLoanRequests() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.coreService.fetchAllPeoplesBankLoanRequests(whoAmI['email']).subscribe(requestList => {
        let ELEMENT_DATA = [];
        for (let request of requestList) {
          ELEMENT_DATA.push({
            id: request.id,
            LoanID: request.refNo,
            row: request,
            amount: request.requirement,
            datel: this.convertDate(request.created),
            Status: request.status
          });
        }
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.spinner = false;
      });
    });
  }

  encryptInfo: any;

  openDraftApplication(row) {

    this.encryptInfo = encodeURIComponent(JSON.stringify(row));

    switch (row.formType) {
      case 'FOR_FARMERS':
        return this.router.navigate(['/value-added-services/loan-forms/wholesale'], {
          queryParams: {
            isDRAFT: true, data: this.encryptInfo
          }
        });

      case 'FOR_SUPPLIERS':
        return this.router.navigate(['/value-added-services/loan-forms/for-suppliers/wholesale'], {
          queryParams: {
            isDRAFT: true, data: this.encryptInfo
          }
        });

      case 'FOR_MANUFACTURES':
        return this.router.navigate(['/value-added-services/loan-forms/for-manufactures/wholesale'], {
          queryParams: {
            isDRAFT: true, data: this.encryptInfo
          }
        });

      case 'FOR_AGGREGATOR':
        return this.router.navigate(['/value-added-services/loan-forms/for-aggregators/wholesale'], {
          queryParams: {
            isDRAFT: true, data: this.encryptInfo
          }
        });

    }
  }
}
