import {Component, OnInit} from '@angular/core';
import {CoreService} from '../service/core.service';
import {MainUrlService} from '../service/main-url.service';
import {ProductService} from '../service/product.service';
import {ActivatedRoute} from '@angular/router';
import {PaymentService} from '../service/payment.service';
import {SystemSettingsService} from '../service/system-settings.service';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from '../common/common.service';
import Swal from 'sweetalert2';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  address1: any;
  address2: any;
  city: any;
  province: any;
  postalCode: any;
  country: any;
  mobile: any;
  qrImage: any;
  status: any;
  grandTotal: any;
  deliveryTotal: any;
  deliveryName: any;
  totalUnitPrice: any;
  totalDiscount: any;
  productList: any;
  tenantValue: any = 'wholesale';
  BASE_URL: any;
  CURRENCY_CODE: any;
  transactionTrimValue: any;
  transactionID: any;
  couponCode: any;
  couponValue: any;
  transDate: any;
  activeLang: string = 'en';

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private route: ActivatedRoute,
              public mainUrl: MainUrlService,
              private coreService: CoreService,
              private productService: ProductService,
              private paymentService: PaymentService,
              private toastr: ToastrService,
              public commonService: CommonService,
              private settingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.loading();
    this.route.queryParams.subscribe(params => {
      localStorage.setItem('TENANT_VALUE', this.tenantValue);
      this.activeLang = sessionStorage.getItem('LANG');
      this.fetchAllGeneralSettings(params['TID']);
    });
  }

  common(TID) {
    this.productService.historyDetailsByTransactionID(TID).subscribe(transactionDetails => {
      if (transactionDetails) {
        Swal.close();

        this.productList = transactionDetails.productDetailsDTOS;

        if (Object.keys(this.productList).length === 0) {
          this.toastr.error('This transaction ID looks invalid. Please double check the transaction ID before attempting again.');
          setTimeout(() => {
            let a = document.createElement('a');
            a.href = '/' + this.tenantValue;
            a.click();
          }, 1500);

        }

        this.paymentMethod = transactionDetails['paymentMethod'];
        this.address1 = transactionDetails['deliveryLine1'];
        this.address2 = transactionDetails['deliveryLine2'];
        this.city = transactionDetails['deliveryCityDTO'];
        this.deliveryTotal = transactionDetails['delivery'];
        this.deliveryName = transactionDetails['deliveryName'];
        this.postalCode = transactionDetails['postalCode'];
        this.province = transactionDetails['province'];
        this.country = transactionDetails['country'];
        this.mobile = transactionDetails['mobile'];
        this.status = transactionDetails['status'];
        this.couponCode = transactionDetails['couponCode'];
        this.couponValue = transactionDetails['couponValue'];
        this.grandTotal = transactionDetails['grandTotal'];
        this.totalUnitPrice = transactionDetails['totalUnitPrice'];
        this.totalDiscount = transactionDetails['totalDiscount'];
        this.transDate = transactionDetails['purchasedDate'];
        this.generateQRCode(TID);
      }
    }, () => {
      Swal.close();
    });
  }

  generateQRCode(transactionId) {
    this.transactionID = transactionId;
    this.paymentService.fetchBarCode(transactionId, {
      'proxyUrl': environment.proxyUrl
    }).subscribe(brCode => {
      if (brCode) {
        let brBody =
          {
            'url': this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + transactionId,
            'proxyUrl': environment.proxyUrl
          };
        this.paymentService.fetchQrCode(brBody).subscribe(qrCode => {
          if (qrCode) {
            this.qrImage = this.mainUrl.MAIN_URL_FILE + qrCode['message'] + '/?X-TenantID=' + this.tenantValue;
          }
        });
      }
    });

  }

  loadImage(el) {
    return this.mainUrl.MAIN_URL_FILE + el + '/?X-TenantID=' + this.tenantValue;
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      width: '300px',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  platformChargers = 0;
  paymentMethod: string;

  fetchAllGeneralSettings(TID) {
    this.settingsService.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          switch (setting.code) {

            case 'BASE_URL':
              this.BASE_URL = setting.value;
              break;

            case 'CURRENCY_CODE':
              this.CURRENCY_CODE = setting.value;
              break;

            case 'TRANSACTION_ID_LENGTH':
              this.transactionTrimValue = setting.value;
              break;

            case 'PLATFORM_CHARGES' :
              this.platformChargers = setting.value;
              break;

          }
        }
      }

      this.common(TID);

    });
  }

  printInvoice() {
    this.loading();
    let body =
      {
        'url': this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + this.transactionID,
        'transactionID': this.transactionID
      };
    this.coreService.printInvoice(body).subscribe(
      (data: any) => {
        Swal.close();
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();

      }, () => {
        Swal.close();
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Something went wrong..!!!',
          showConfirmButton: false,
          timer: 1500
        });
      });
  }

  calGrandTotal(subTotalAmount, platformChargers) {
    if (this.paymentMethod === 'Card') {
      return subTotalAmount / (1 - (platformChargers / 100));
    } else {
      return subTotalAmount;
    }
  }

  calPlatformCharge(subTotalAmount, platformChargers) {
    if (this.paymentMethod === 'Card') {
      return (subTotalAmount / (1 - (platformChargers / 100))) - subTotalAmount;
    } else {
      return 0;
    }
  }

}
