<!DOCTYPE html>
<html lang="en">
<head>
  <title>Payment Authentication</title>
</head>
<body>
<script>
  window.onload = process;

  function process() {
    window.ReactNativeWebView.postMessage("PROCEED")
  }
</script>
</body>
</html>
