import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-riyawiru-payment-authentication',
  templateUrl: './riyawiru-payment-authentication.component.html',
  styleUrls: ['./riyawiru-payment-authentication.component.css']
})
export class RiyawiruPaymentAuthenticationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
