<div class="container">
  <div class="row pip-title">
    <div class="col-8">
      <h2>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.MY_PURCHASES' | translate}}</h2>
    </div>
    <div class="col-12">
      <div class="separator">&nbsp;</div>
    </div>
  </div>
  <section><span style="color: red;margin-right: .5vw">*</span>
    <mat-form-field style="width: 30%;">
      <input (dateChange)="saveDate($event)" [max]="todayDate" [satDatepicker]="picker5"
             matInput placeholder="{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.CHOOSE_DATE' | translate}}">
      <sat-datepicker #picker5 [rangeMode]="true" [selectFirstDateOnClose]="true">
      </sat-datepicker>
      <sat-datepicker-toggle [for]="picker5" matSuffix></sat-datepicker-toggle>
      <div *ngIf="invalidDateRange" style="font-size: 10px;color: red">
        {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.CHOOSE_DATE' | translate}}
      </div>
    </mat-form-field>
    <a (click)="generateReport()" class="btn btn-light generate-btn" style="background-color: #8bc34a;color: white;margin-left: 20px;
    width: 22%;"><i class="fa fa-download" style="margin-right: 7%;"></i>
      {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.GENERATE_REPORT' | translate}}</a>

  </section>
  <div class="item-container">


    <mat-form-field class="matsearch">
      <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
    </mat-form-field>
    <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">


      <table [dataSource]="dataSource" class="table" mat-table matSort style="width:100%">

        <ng-container matColumnDef="product">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PRODUCT_NAME' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            <div class="d-flex flex-row align-items-center mt-2 mb-2">
              <div style="width: 50px;">
                <img *ngIf="row.productType !== 'ORDER_REQUEST'" alt=""
                     src="{{this.mainUrl.MAIN_URL_FILE + row.productImage + '/?X-TenantID=' + this.tenantValue}}"
                     style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                <img *ngIf="row.productType === 'ORDER_REQUEST'" alt="" src="{{row.productImage}}"
                     style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
              </div>
              <div class="rightdiv">
                <h4>{{row.productName}}</h4>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="transactionId">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.TRANSACTION_ID' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            {{this.commonService.orderIdLengthTrim(row.transactionId, transactionTrimValue)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="purchasedDate">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PURCHASED_DATE' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            {{convertDate(row.purchasedDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="productType">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_TYPE' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
          <span *ngIf="row.productType === 'SPOT'">
             {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.SPOT_BUYING' | translate}}
          </span>
            <span *ngIf="row.productType === 'AUCTION'">
              {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.AUCTION' | translate}}
          </span>
            <span *ngIf="row.productType === 'ORDER_REQUEST'">
              {{'ORDER_REQUEST' | translate}}
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentStatus">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'Payment_Status' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            <img alt="" src="../../../../assets/images/{{ manageStatus(row.paymentStatus)}}.svg">
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.STATUS' | translate}}</th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell>
            <img alt="" src="../../../../assets/images/{{ manageStatus(row.status)}}.svg">
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="productView(row)" *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10 ,25, 100]"></mat-paginator>

    </div>

  </div>
</div>

<button class="btn btn-primary" data-target="#detailModal" data-toggle="modal" id="openModelBtn" style="display: none"
        type="button"></button>

<button #viewDetails class="btn btn-primary" data-target="#approve-modal"
        data-toggle="modal" hidden
        type="button"></button>


<div aria-hidden="true" aria-labelledby="myExtraLargeModalLabel" class="modal fade bd-example-modal-xl"
     data-backdrop="static"
     data-keyboard="false" id="approve-modal"
     role="dialog" tabindex="-1">
  <button #closeButton aria-label="Close" class="close" data-dismiss="modal" type="button">
    <span aria-hidden="true" hidden>&times;</span>
  </button>
  <div class="modal-dialog modal-xl modal-dialog-centered  mw-1000" role="document">
    <div class="modal-content">
      <div class="text-right">
        <i class="material-icons graytext-3" data-dismiss="modal" aria-label="Close"
           style="cursor: pointer;font-size: 20px;">close</i>
      </div>
      <div class="main-container purchases-info">

        <div class="pi-main-wrapper">

          <div class="pi-left-col">
            <h3>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_INFO' | translate}}</h3>
            <div class="pimw-qr">
              <div class="left-col">
                <img [src]="qrImage" alt="QR Code" (click)="loadReceipt()" style="cursor: pointer"/>
                <span>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.CLICK_QR_CODE_TO' | translate}}</span>
              </div>

              <div class="right-col">
                <p>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.YOU_WILL_RECEIVE_AN' | translate}}</p>
                <h5>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_DATE' | translate}}</h5>
                <span>{{convertDate(purchasedDate)}}</span>
                <div *ngIf="status==='REJECTED'">
                  <h5>Reject Description:</h5>
                  <span>{{rejectionDescription}}</span>
                  <h5>Reject Reason:</h5>
                  <span>{{rejectionComment}}</span>
                </div>

              </div>
            </div>

            <h4 class="t-underline">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.BUYER_INFOR' | translate}}</h4>
            <div class="bid-table">
              <div class="bid-col-5">
                <div class="bid-profile">
                  <div class="pic"><img alt="" src="{{buyerImgUrl}}"/></div>
                  <div class="info">
                    <h4 style="width: 250px;
                               white-space: nowrap;
                               overflow: hidden;
                               text-overflow: ellipsis;">
                      <span>{{buyerName}}</span><br>
                      <span>{{buyerAddress}}</span><br>
                      <span>{{buyerMobile}}</span>
                    </h4>
                  </div>
                </div>
              </div>

              <div class="bid-col-2">
                <h6>{{'MY_ACCOUNT.EARNING.PAYMENT_METHOD' | translate}}</h6>
                <span>{{paymentMethod}}</span>
              </div>
            </div>
          </div>
          <div class="pi-right-col">
            <h4 class="t-underline">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.SELLER_INFOR' | translate}}</h4>
            <div class="pibit-cart">
              <div class="pibit-col green" style="height: 100px">
                <div class="ppc-cl-1">
                  <div class="bid-profile">
                    <div class="pic"><img alt="" src="{{sellerImage}}"/></div>
                    <div class="info">
                      <h4>{{sellerName}}</h4>
                      <div class="star-icons">
                        <star-rating [value]="rate_S" checkedcolor="#FFA84C" size="16px" totalstars="5"
                                     uncheckedcolor="#999999"></star-rating>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ppc-cl-2">
                  <div class="bid-col-2">
                    <h6>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ADDRESS' | translate}}</h6>
                    <span>{{sellerAddress}}</span>
                  </div>
                  <div class="bid-col-2">
                    <h6>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PHONE_NO' | translate}}</h6>
                    <span>{{sellerMobile}}</span>
                  </div>
                </div>
              </div>
              <div class="pibit-col">
                <div class="bid-profile">
                  <div class="pic"><img alt="" src="{{singleProductImg}}"/></div>
                  <div class="info">
                    <span>{{productName}}</span><br>
                    <span *ngIf="productType === 'AUCTION'">  {{unitPrice | number: '2.2-2'}}({{unitType}})<br> </span>
                    <span *ngIf="productType === 'SPOT'"> {{quantity}}({{unitType}})
                      x {{'CURRENCY.CURRENCY' | translate}} {{unitPrice | number: '2.2-2'}}<br>  </span>
                    <span>{{'DeliveryOption'|translate}} : {{delivery}}</span><br>
                    <span *ngIf="deliveryId === 3 || deliveryId === 5">{{'DeliveryCharges'|translate}}
                      :&nbsp;{{'CURRENCY.CURRENCY' | translate}} {{deliveryCharges}}<br></span>
                    <span *ngIf="deliveryId === 5">{{'DeliverareaforFixedRate'|translate}} : {{area}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="pi-model-summery">
              <dl class="cb-sum total">
                <dt style="font-weight: bold">{{'CHECKOUT.SUBTOTAL' | translate}}</dt>

                <dd
                  *ngIf="productType === 'AUCTION'">{{'CURRENCY.CURRENCY' | translate}} {{unitPrice | number: '2.2-2'}}</dd>
                <dd
                  *ngIf="productType === 'SPOT'">{{'CURRENCY.CURRENCY' | translate}} {{unitPrice * quantity | number: '2.2-2'}}</dd>
                <dd
                  *ngIf="productType === 'ORDER_REQUEST'">{{'CURRENCY.CURRENCY' | translate}} {{unitPrice  | number: '2.2-2'}}</dd>
              </dl>
              <dl class="cb-sum total">
                <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Platform Charges</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'si'">වේදිකා ගාස්තු</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">இயங்குதள கட்டணங்கள்</dt>

                <dd *ngIf="paymentMethod === 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                  <span>&nbsp;</span>
                  <span id="platformCharge">
                    <span
                      *ngIf="productType === 'AUCTION'">{{calPlatformCharge(unitPrice, platformChargers)| number:'2.2-2'}}</span>
                    <span
                      *ngIf="productType === 'SPOT'">{{calPlatformCharge(unitPrice * quantity, platformChargers)| number:'2.2-2'}}</span>
                    <span
                      *ngIf="productType === 'ORDER_REQUEST'">{{platformChargers| number:'2.2-2'}}</span>
                    </span>
                </dd>
                <dd *ngIf="paymentMethod !== 'Card'">
                  <span *ngIf="activeLang === 'en'">Free</span>
                  <span *ngIf="activeLang === 'si'">ගාස්තුවක් අය නොකරේ</span>
                  <span *ngIf="activeLang === 'ta'">கட்டணம் இல்லை</span>
                </dd>
              </dl>
              <dl class="cb-sum total">
                <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Grand Total</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'si'">මුළු එකතුව</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">மொத்த மொத்த தொகை</dt>

                <dd *ngIf="paymentMethod === 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                  <span>&nbsp;</span>
                  <span id="grandTotal">
                    <span
                      *ngIf="productType === 'AUCTION'">{{calGrandTotal(unitPrice, platformChargers)| number:'2.2-2'}}</span>
                    <span
                      *ngIf="productType === 'SPOT'">{{calGrandTotal(unitPrice * quantity, platformChargers)| number:'2.2-2'}}</span>
                       <span
                         *ngIf="productType === 'ORDER_REQUEST'">{{grandTotal| number:'2.2-2'}}</span>
                    </span>
                </dd>
                <dd *ngIf="paymentMethod !== 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                  <span>&nbsp;</span>
                  <span
                    *ngIf="productType === 'AUCTION'">{{unitPrice| number:'2.2-2'}}</span>
                  <span
                    *ngIf="productType === 'SPOT'">{{unitPrice * quantity| number:'2.2-2'}}</span>
                  <span
                    *ngIf="productType === 'ORDER_REQUEST'">{{grandTotal| number:'2.2-2'}}</span>
                </dd>
              </dl>
            </div>

          </div>
        </div>
        <div class="row pim-model-btn-wp" *ngIf="status==='SHIPPED'">
          <div class="col-6 text-center" *ngIf="deliveryId === 1">
            <p style="font-size: 14px;" *ngIf="activeLang === 'en'">Your goods will be dispatched
              on {{convertDate(productList[0].deliveryDate)}}.
              If you do not receive it on the same day please contact us on (070) 642 3520.</p>

            <p style="font-size: 14px;" *ngIf="activeLang === 'si'">ඔබේ
              භාණ්ඩ {{convertDate(productList[0].deliveryDate)}}
              දින බෙදා හරිනු ලැබේ. ඔබට එය එදිනම නොලබුනි නම් වැඩි විස්තර සඳහා කරුණාකර අප අමතන්න (070) 642 3520.

            </p>
            <p style="font-size: 14px;" *ngIf="activeLang === 'ta'">உங்கள் பொருட்கள்
              அனுப்பப்படும் {{convertDate(productList[0].deliveryDate)}}
              . அதே நாளில் நீங்கள் அதைப் பெறவில்லை என்றால் தயவுசெய்து எங்களை தொடர்பு கொள்ளவும் (070) 642 3520.
            </p>

            <button class="btn-green" (click)="complete(orderId)">{{'CONFIRM' | translate }}</button>
          </div>
          <div class="col-6 text-center" *ngIf="deliveryId === 2">
            <p style="font-size: 14px;" *ngIf="activeLang === 'en'">Your goods will be ready to collect
              on {{convertDate(productList[0].deliveryDate)}}. For more information, please contact us on (070) 642
              3520.</p>

            <p style="font-size: 14px;" *ngIf="activeLang === 'si'">ඔබේ
              භාණ්ඩ {{convertDate(productList[0].deliveryDate)}}දින බෙදා හැරීම සදහා සූදානම් වනු ඇත.
              වැඩි විස්තර සඳහා කරුණාකර අප අමතන්න (070) 642 3520.

            </p>
            <p style="font-size: 14px;" *ngIf="activeLang === 'ta'">உங்கள் பொருட்கள்
              அனுப்பப்படும் {{convertDate(productList[0].deliveryDate)}}. மேலும் தகவலுக்கு, எங்களை தொடர்பு கொள்ளவும்
              (070) 642 3520.
            </p>

            <button class="btn-green" (click)="complete(orderId)">{{'CONFIRM' | translate }}</button>
          </div>

          <div class="col-6 text-center" *ngIf="deliveryId === 3 || deliveryId === 5 || deliveryId === 4">
            <p style="font-size: 14px;" *ngIf="activeLang === 'en'">Your goods will be dispatched
              on {{convertDate(productList[0].deliveryDate)}}. If you do not receive it on the same day please contact
              us on (070) 642 3520.</p>
            <p style="font-size: 14px;" *ngIf="activeLang === 'si'">ඔබේ
              භාණ්ඩ {{convertDate(productList[0].deliveryDate)}}  දින බෙදා හරිනු ලැබේ. ඔබට එය එදිනම නොලබුනි නම් වැඩි
              විස්තර සඳහා කරුණාකර අප අමතන්න (070) 642 3520.
            </p>
            <p style="font-size: 14px;" *ngIf="activeLang === 'ta'">உங்கள் பொருட்கள்
              அனுப்பப்படும் {{convertDate(productList[0].deliveryDate)}}. அதே நாளில் நீங்கள் அதைப் பெறவில்லை என்றால்
              தயவுசெய்து எங்களை தொடர்பு கொள்ளவும் (070) 642 3520.
            </p>

            <button class="btn-green" (click)="complete(orderId)">{{'CONFIRM' | translate }}</button>
          </div>
        </div>
        <div class="row pim-model-btn-wp" *ngIf="status!=='SHIPPED'">
          <div class="col-6 text-center">
          </div>
          <div class="col-6 text-center">
            <button data-dismiss="modal"
                    style="width: 120px;float: right;background: #8bc34a!important;  border-radius: 4px; font-size: 16px; border-width: 1px; color: #fff; outline: 0;">
              {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.CLOSE' | translate}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<button #success class="btn-b b-approve" data-dismiss="modal" data-target="#success-modal" data-toggle="modal"
        hidden></button>

<button #feedBack class="btn-b b-approve" data-dismiss="modal" data-target="#feedBack" data-toggle="modal"
        hidden></button>

<div data-backdrop="static" data-keyboard="false" aria-hidden="true" class="modal fade npa-model" id="success-modal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered  margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <h3>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_COMPLETED' | translate}}</h3>
          <p>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_STATUS_UPDATED' | translate}}</p>
          <a class="btn-b b-approve" data-dismiss="modal"
             (click)="openFeedBackModal()">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>


<div data-backdrop="static" data-keyboard="false" aria-hidden="true" class="modal fade" id="feedBack" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-600 margin-0 feedback" role="document">
    <div class="modal-content">
      <div class="fb-top">
        {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PRODUCT_STATUS' | translate}}
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="reviewForm">
        <div class="review-editer">
          <div class="form-group">
            <label>Review</label>
            <textarea [(ngModel)]="review"
                      class="form-control" formControlName="review" rows="5"></textarea>
            <form>
              <star-rating (rate)="onRate($event)" totalstars={{totalstar}} checkedcolor="red" size="24px"
                           readonly="false"
                           uncheckedcolor="black"
                           value="0"></star-rating>
            </form>
          </div>
        </div>
        <div class="cart-btn-wrapper">
          <button class="btn btn-secondary reviewButton" style="margin-top: 1vh;background: rgb(139, 195, 74)!important;"
                  type="submit">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.SUBMIT' | translate}}
          </button>
          <a data-dismiss="modal" id="closeModal" style="display: none"></a>
        </div>
      </form>
    </div>
  </div>
</div>


<button #receipt id="openModel" style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#receipt"></button>

<div data-backdrop="static" data-keyboard="false" aria-hidden="true" class="modal fade bd-example-modal-xl"
     tabindex="-1" role="dialog" id="receipt"
     aria-labelledby="myExtraLargeModalLabel">
  <button aria-label="Close" class="close" data-dismiss="modal" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" style="width:100%;max-height: 650px ; height: auto; overflow-y: scroll;">
      <div class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="w-full-col p-30" id="print-receipt">
                <div class="cfs-title">
                  {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_RECEIPT' | translate}}
                </div>
                <div>
                  <div class="row">
                    <div class="col-4">
                      <h4>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.SHIPPING_ADDRESS' | translate}}</h4>
                      <div class="pc-info-col">
                        <p>
                          <label>{{tempFirstName}}</label><br>
                          <label>{{address}}</label><br>
                          <label>{{endCity}}</label><br>
                          <label>{{province}}</label><br>
                          <label>{{postalCode}}</label><br>
                          <label>{{country}}</label><br>
                          <label>{{mobile}}</label><br>
                        </p>
                      </div>
                    </div>
                    <div class="col-8">
                      <div style=" display: block; position: relative;text-align: right">
                        <img [src]="qrImage" alt="QR Code" style="width: 120px;"/>
                      </div>
                    </div>

                    <div class="col-12">
                      <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                        <span>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.ORDER_STATUS' | translate}}<span
                          style="color: green;font-weight: bold">{{manageStatus(status)}}</span></span>
                      </div>
                    </div>

                    <div class="col-12">
                      <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                        <span>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.TRANSACTION_ID_NEW' | translate}}<span
                          style="color: green;font-weight: bold">{{this.commonService.orderIdLengthTrim(transactionId, transactionTrimValue)}}</span></span>
                      </div>
                    </div>

                  </div>
                </div>
                <div id="table-wrapper">
                  <div id="table-scroll">
                    <table class="table">
                      <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PRODUCT_NAME' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.UNIT_PRICE' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.QUANTITY' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.TOTAL' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.IMAGE' | translate}}</th>
                        <th scope="col" style="text-align: center;">
                          {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.STATUS' | translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let product of productList;let i = index">
                        <th scope="row">{{i + 1}}</th>

                        <td>{{product.productName}}</td>
                        <td>{{CURRENCY_CODE}}&nbsp;{{product.unitPrice| number:'2.2-2'}}</td>
                        <td>{{product.quantity}}</td>
                        <td
                          *ngIf="productType === 'AUCTION'">{{'CURRENCY.CURRENCY' | translate}}{{product.unitPrice | number:'2.2-2'}}</td>
                        <td
                          *ngIf="productType === 'SPOT'">{{'CURRENCY.CURRENCY' | translate}}{{product.unitPrice * product.quantity | number:'2.2-2'}}</td>
                        <td
                          *ngIf="productType === 'ORDER_REQUEST'">{{'CURRENCY.CURRENCY' | translate}}{{grandTotal | number:'2.2-2'}}</td>
                        <td *ngIf="productType !== 'ORDER_REQUEST'">
                          <img *ngIf="product.imageDTOS !== null" style="width: 30px" alt=""
                               src="{{loadImage(product.imageDTOS[0].image)}}">
                          <img *ngIf="product.imageDTOS === null" style="width: 30px" alt=""
                               src="assets/defaultBrokenImage.jpg"></td>
                        <td *ngIf="productType === 'ORDER_REQUEST'">
                          <img style="width: 30px" alt=""
                               src="{{singleProductImg}}"></td>
                        <td style="text-align: center;color: green" *ngIf="product.status !== 'SHIPPED'">
                          {{manageStatus(product.status)}}
                        </td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="cart-summary">
                  <div class="cb-left" style="vertical-align: bottom !important;">
                    <label
                      style="color: black !important; font-size: 13px;"><b>{{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.COMPANY_DETAILS' | translate}}
                    </b></label><br>
                    <label style="color: #4F4F4F !important; font-size: 12px;"> {{PRD_ADDR_CNAME}}</label><br>
                    <label style="color: #4F4F4F !important; font-size: 12px;"> {{com_address1}}</label><br>
                    <label style="color: #4F4F4F !important; font-size: 12px;"> {{com_address2}}</label><br>
                    <label style="color: #4F4F4F !important; font-size: 12px;"> {{com_hotline}}</label><br>
                    <label style="color: #4F4F4F !important; font-size: 12px;"> {{com_email}}</label><br>
                    <label style="color: #4F4F4F !important; font-size: 12px;"> {{com_website}}</label><br><br><br>

                    <span><img alt="" src="../../../assets/images/icons/cart_b1.png"/>
                      {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.INFO_1' | translate}}</span><br>
                    <span style="margin-bottom: 18px!important;">
                      <img src="../../../assets/images/icons/cart_b2.png" alt=""/>
                      {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.INFO_2' | translate}}</span>
                  </div>
                  <div class="cb-right">

                    <dl class="cb-sum">
                      <dt style="font-weight: bold">{{'CHECKOUT.SUBTOTAL' | translate}}</dt>

                      <dd>
                        <span
                          *ngIf="productType !== 'ORDER_REQUEST'">{{'CURRENCY.CURRENCY' | translate}} {{totalUnitPrice | number: '2.2-2'}}</span>
                        <span
                          *ngIf="productType === 'ORDER_REQUEST'">{{'CURRENCY.CURRENCY' | translate}} {{unitPrice | number: '2.2-2'}}</span>
                      </dd>
                    </dl>
                    <dl class="cb-sum">
                      <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Platform Charges</dt>
                      <dt style="font-weight: bold" *ngIf="activeLang === 'si'">වේදිකා ගාස්තු</dt>
                      <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">இயங்குதள கட்டணங்கள்</dt>

                      <dd *ngIf="paymentMethod === 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                        <span>&nbsp;</span>
                        <span id="platformCharge1">
                    <span
                      *ngIf="productType !== 'ORDER_REQUEST'">{{calPlatformCharge(totalUnitPrice, platformChargers)| number:'2.2-2'}}</span>
                    <span *ngIf="productType === 'ORDER_REQUEST'">{{platformChargers| number:'2.2-2'}}</span>
                    </span>
                      </dd>
                      <dd *ngIf="paymentMethod !== 'Card'">
                        <span *ngIf="activeLang === 'en'">Free</span>
                        <span *ngIf="activeLang === 'si'">ගාස්තුවක් අය නොකරේ</span>
                        <span *ngIf="activeLang === 'ta'">கட்டணம் இல்லை</span>
                      </dd>
                    </dl>
                    <dl class="cb-sum total">
                      <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Grand Total</dt>
                      <dt style="font-weight: bold" *ngIf="activeLang === 'si'">මුළු එකතුව</dt>
                      <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">மொத்த மொத்த தொகை</dt>

                      <dd *ngIf="paymentMethod === 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                        <span>&nbsp;</span>
                        <span id="grandTotal1">
                    <span
                      *ngIf="productType !== 'ORDER_REQUEST'">{{calGrandTotal(totalUnitPrice, platformChargers)| number:'2.2-2'}}</span>
                    <span
                      *ngIf="productType === 'ORDER_REQUEST'">{{grandTotal| number:'2.2-2'}}</span>
                    </span>
                      </dd>
                      <dd *ngIf="paymentMethod !== 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                        <span>&nbsp;</span>
                        <span *ngIf="productType !== 'ORDER_REQUEST'">{{totalUnitPrice| number:'2.2-2'}}</span>
                        <span
                          *ngIf="productType === 'ORDER_REQUEST'">{{grandTotal| number:'2.2-2'}}</span>
                      </dd>
                    </dl>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12" style="text-align: right;margin-top: 1vh">
              <button [useExistingCss]="true" printSectionId="print-receipt" ngxPrint
                      style="float:left;width: 150px; background: forestgreen!important; border-radius: 4px; line-height: 42px; font-size: 16px; border-width: 1px; color: #fff; outline: 0;">
                {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PRINT' | translate}}
              </button>
              <button data-dismiss="modal"
                      style="width: 150px;float: right;background: forestgreen!important;  border-radius: 4px; line-height: 42px; font-size: 16px; border-width: 1px; color: #fff; outline: 0;">
                {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.CLOSE' | translate}}
              </button>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
