<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>Pending Payments</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                <mat-form-field class="matsearch">
                  <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>

                <table [dataSource]="dataSource" class="table salestable" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="orderID">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.ORDERS.ORDER_ID' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        {{this.commonService.orderIdLengthTrim(row.orderID, transactionTrimValue)}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="paymentType">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.ORDERS.PAYMENT_TYPE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <span *ngIf="row.paymentType === 'Bank Transfer'">
                        {{row.paymentType}} - {{row.bankType}}
                      </span>
                      <span *ngIf="row.paymentType !== 'Bank Transfer'">
                        {{row.paymentType}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="slip">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.ORDERS.BANK_SLIP' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div>
                        <img *ngIf="row.image" alt="" src="{{row.image}}"
                             style="width: 40px; height: 40px; border-radius: 8px; object-fit: cover;"/><br>
                        <span *ngIf="!row.image">N/A</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.SALES.ORDERS.AMOUNT' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{row.amount| number:'2.2-2'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <button *ngIf="row.paymentType === 'Bank Transfer' && (row.status === null || row.status ==='REJECTED')"
                              (click)="setRowDetails(row)" class="btn-login"
                              type="button"
                              data-target="#vieworder" data-toggle="modal">
                        <i aria-hidden="true" class="fa fa-pencil"></i>
                      </button>
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                  </tr>
                  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10 ,25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- order review -->
<div aria-hidden="true" class="modal fade npa-model" data-keyboard="false" id="vieworder"
     role="dialog" tabindex="-1">
  <div style="width: 50%!important;" class="modal-dialog modal-dialog-centered mw-400 margin-0 profile-edit"
       role="document">
    <button #closeModal aria-label="Close" class="close" data-dismiss="modal" hidden type="button">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-content" style="justify-content: center!important;">
      <div class="col-md-12">
        <span
          style="font-size: 16px;font-weight: bold">{{'MY_ACCOUNT.SALES.ORDERS.BANK_SLIP_IMAGES' | translate}}</span><br>
        <span style="font-size: 14px" >{{'MY_ACCOUNT.SALES.ORDERS.UPLOAD_YOUR_BANK_SLIP' | translate}}.</span>
        <span style="font-size: 14px">{{'MY_ACCOUNT.SALES.ORDERS.SMART_PAY_SLIP' | translate}}.</span>
        <p style="
    color: #6B6C6F;
    font-weight: bold;
    padding: 15px;
    margin: 0;
    font-size: 14px;">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE' | translate}}5MB
          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE_ALLOWED' | translate}}</p>
        <p style=" color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
          {{'ALLOWED_FILE_TYPES' | translate}}</p>
        <ngx-dropzone (change)="onSelect($event)" accept="image/png,image/jpeg" [disableClick]="this.files.length > 0"
                      [multiple]="false">
          <ngx-dropzone-label>
            <div class="uploaderbox">
              <em class="icon fa fa-camera"></em>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview (removed)="onRemove(f)" *ngFor="let f of files" [file]="f" [removable]="true"
                                      ngProjectAs="ngx-dropzone-preview">
          </ngx-dropzone-image-preview>
          <div *ngIf="this.files.length !== 1" class="imgadd"><em class="fa fa-plus"></em></div>
        </ngx-dropzone>
      </div>
      <button class="btn-login" type="button" (click)="updatePendingPaymentDetails()">
        {{'MY_ACCOUNT.SALES.ORDERS.SUBMIT' | translate}}
      </button>
    </div>
  </div>
</div>
