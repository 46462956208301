import {Component, OnInit, ViewChild} from '@angular/core';
import {ProductDetailsDTO} from '../../../../dto/productDetailsDTO';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {MyAccountServiceService} from '../../my-account-service.service';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {InteractionService} from '../../../../service/interaction.service';
import {CommonService} from '../../../../common/common.service';
import Swal from 'sweetalert2';
import {CoreService} from '../../../../service/core.service';
import {CommonSignUpService} from '../../../../service/common-sign-up.service';
import {Md5} from 'ts-md5';

@Component({
  selector: 'app-agent-products',
  templateUrl: './agent-products.component.html',
  styleUrls: ['./agent-products.component.css']
})
export class AgentProductsComponent implements OnInit {

  tenantValue;
  dataSource;
  productDetailsDTO: ProductDetailsDTO[] = [];
  agentRefNo: any;
  product: any;
  displayedColumns: string[] =
    ['id', 'category', 'price', 'qty', 'type', 'expiredDate', 'adddate'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  selectNewProductOptions: number;
  farmerName: any;
  farmerUsername: any;
  ordersCount: number;
  bankAccount: string;
  farmerAddress: any;
  farmerMobile: any;
  farmerImageUrl: any;
  farmerLocationEN: any;
  farmerLocationTA: any;
  farmerLocationSI: any;
  salesCount: number;
  activeLang: any;
  productName: any;
  productCategory: any;
  productSku: any;
  productImage: any;
  productCreatedDate: any;
  productDescription: any;
  productAvailableQty: any;
  productUnitPrice: any;
  productStock: any;

  constructor(private abc: MyAccountServiceService,
              private route: ActivatedRoute,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              public mainUrl: MainUrlService,
              private coreService: CoreService,
              private commonSignUpService: CommonSignUpService,
              private interactionService: InteractionService,
              public commonService: CommonService) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

  ngOnInit() {
    this.loadProducts();
    this.interactionService.updateProductListingList(false);
  }

  loadProducts() {
    this.commonService.processing();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

      this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {
        this.agentRefNo = refNo.message;
        this.productService.leadFarmersProductList(this.agentRefNo).subscribe(result => {

          Swal.close();

          for (let item of result) {


            let sku = '-';
            let image;
            if (item.type.type === 'SPOT') {
              sku = item.spotDTOList[0].sku;
              if (item.spotImages.length > 0) {
                image = item.spotImages[0].image;
              } else {
                image = '00000';
              }

            } else if (item.type.type === 'FORWARD_BN') {
              if (item.futureImages.length > 0) {
                image = item.futureImages[0].image;
              } else {
                image = '00000';
              }
            } else if (item.type.type === 'FORWARD_ACU') {
              if (item.forwardAcuImages.length > 0) {
                image = item.forwardAcuImages[0].image;
              } else {
                image = '00000';
              }
            }

            let _stock;
            let _expireDate;
            if (item.type.type === 'SPOT') {
              _stock = item.spotDTOList[0].stock;
              _expireDate = item.spotDTOList[0].expireDate;
            }

            const obj = new ProductDetailsDTO(
              sku,
              item.name,
              item.subCategory.mainCategory.commonIndex.description,
              item.unitPrice,
              item.unit.unit,
              item.type.type,
              item.stockAvailable,
              item.expireDate,
              item.location,
              image,
              item,
              item.subCategory.mainCategory.commonIndex.description,
              item.created,
              _stock,
              _expireDate,
              item.productStatus.description,
              item.totalEarnings,
              item.totalQuantity,
              item.sellInSmallerQuantities
            );
            this.productDetailsDTO.push(obj);
          }
          this.dataSource = new MatTableDataSource(this.productDetailsDTO);
          this.dataSource.paginator = this.paginator;
        }, () => {
          Swal.close();
        });
      }, () => {
        Swal.close();
      });
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  passRowData(allItems: any) {

    let _stock;
    if (allItems['type']['type'] === 'SPOT') {
      if (allItems['spotDTOList'].length > 0) {
        _stock = allItems['spotDTOList'][0]['stock'];
      }

    }

    this.farmerName = allItems['oauthUserDTO']['name'];
    this.productName = allItems['name'];
    this.productCreatedDate = allItems['created'];
    this.productAvailableQty = allItems['stockAvailable'];
    this.productUnitPrice = allItems['unitPrice'];
    this.productStock = _stock;
    this.productDescription = allItems['description'];
    this.productCategory = allItems['subCategory']['mainCategory']['commonIndex']['description'];

    if (allItems['type']['type'] === 'SPOT') {
      this.productSku = allItems['spotImages']['sku'];
      if (allItems['spotDTOList'].length > 0) {
        this.productImage = allItems['spotDTOList'][0]['spotImages'][0]['image'];
      } else {
        this.productImage = '00000';
      }

    } else if (allItems['type']['type'] === 'FORWARD_BN') {
      if (allItems['futureImages'].length > 0) {
        this.productImage = allItems['futureImages'][0]['image'];
      } else {
        this.productImage = '00000';
      }
    } else if (allItems['type']['type'] === 'FORWARD_ACU') {
      if (allItems['forwardAcuImages'].length > 0) {
        this.productImage = allItems['forwardAcuImages'][0].image;
      } else {
        this.productImage = '00000';
      }
    }

    this.getFarmerDetails(allItems['listedBy']);
  }

  getFarmerDetails(userName) {
    this.farmerUsername = userName;
    let body = {
      'userName': userName,
      'token': 'Bearer ' + localStorage.getItem('$Token')
    };
    this.coreService.fetchUserDetailsByUserName(body).subscribe(details => {
      if (details) {
        this.farmerAddress = details.address;
        this.farmerMobile = details.mobileNumber;
        let hashStr;
        if (userName !== null) {
          hashStr = Md5.hashStr(userName);
        } else {
          hashStr = Md5.hashStr('');
        }
        this.farmerImageUrl = details.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + details.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
        this.commonSignUpService.fetchGramaNiladariById(details.grama_area).subscribe(location => {
          this.farmerLocationEN = location['divisionalSecretariat']['nameEN'];
          this.farmerLocationSI = location['divisionalSecretariat']['nameSI'];
          this.farmerLocationTA = location['divisionalSecretariat']['nameTA'];
        });
      }
    });

    this.productService.fetchOrderByUsername(userName).subscribe(
      ordersList => {
        this.ordersCount = Object.keys(ordersList).length;
      }, () => {
        Swal.close();
      }
    );

    this.productService.fetchSalesByUsername(userName).subscribe(salesList => {
      this.salesCount = Object.keys(salesList).length;
    }, () => {
      Swal.close();
    });

    this.coreService.fetchBankDetails(userName).subscribe(bankDetails => {
      if (bankDetails !== null && bankDetails !== undefined) {
        this.bankAccount = bankDetails['accountNo'];
      } else {
        this.bankAccount = 'XXXXXXXXXX';
      }
    }, () => {
      Swal.close();
    });
  }

}
