<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" style="text-align: right;margin-bottom: 10px">
        <button (click)="printInvoice()"
                style="width: 150px;   background: #8bc34a; border-radius: 4px; line-height: 42px; font-size: 16px; border-width: 1px; color: #fff; outline: 0;">
          Print
        </button>
      </div>
      <div class="col-lg-12">
        <div class="w-full-col p-30" id="print-receipt">
          <div class="cfs-title">
            Order Receipt
          </div>

          <div>
            <div class="row">
              <div class="col-4">
                <h4>Shipping Address</h4>
                <div class="pc-info-col">
                  <p>
                    <label>{{deliveryName}},</label><br>
                    <label>{{address1}},</label><label *ngIf="address2">{{address2}},</label><br>
                    <label>{{city?.nameEN}},</label><br>
                    <label>{{city?.postalCode}}.</label><br>
                    <label>{{city?.agriDistrict.nameEN}}</label><br>
                    <label>{{city?.agriDistrict.province.nameEN}}</label><br>
                    <label>{{mobile}}</label>
                  </p>
                </div>
              </div>

              <div class="col-8">
                <div style=" display: block; position: relative;text-align: right">
                  <img [src]="qrImage" alt="QR Code" style="width: 120px;"/>
                </div>
              </div>

              <div class="col-12">
                <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                  <span>Purchase Date : <span
                    style="color: green;font-weight: bold">{{transDate |date: 'yyyy/MM/dd'}}</span></span>
                </div>
              </div>

              <div class="col-12">
                <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                  <span>Order Status : <span style="color: green;font-weight: bold">{{status}}</span></span>
                </div>
              </div>

              <div class="col-12">
                <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                  <span>Transaction ID : <span
                    style="color: green;font-weight: bold">{{this.commonService.orderIdLengthTrim(transactionID, transactionTrimValue)}}</span></span>
                </div>
              </div>

            </div>
          </div>

          <!-- Cart Table -->
          <div id="table-wrapper" style="overflow-x: scroll; width:auto;">
            <div id="table-scroll">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Image</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let product of productList;let i = index">
                  <th scope="row">{{i + 1}}</th>
                  <td>{{product.sku}}</td>
                  <td>{{CURRENCY_CODE}}&nbsp;{{product.unitPrice| number:'2.2-2'}}</td>
                  <td>{{product.quantity}}</td>
                  <td>{{CURRENCY_CODE}}&nbsp;{{product.discount| number:'2.2-2'}}</td>
                  <td>
                    <img *ngIf="product.imageDTOS.length > 0" style="width: 30px" alt=""
                         src="{{loadImage(product.imageDTOS[0].image)}}">
                    <img *ngIf="product.imageDTOS.length <= 0" style="width: 30px" alt=""
                         src="assets/defaultBrokenImage.jpg">
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div class="cart-summary">
            <div class="cb-left">
              <span><img alt="" src="../../../assets/images/icons/cart_b1.png"/> Safe and Secure Payments</span>
              <span><img alt="" src="../../../assets/images/icons/cart_b2.png"/> 100% Payment Protection, Easy
                                Returns Policy</span>
            </div>
            <div class="cb-right">
              <dl class="cb-sum">
                <dt style="font-weight: bold">{{'CHECKOUT.SUBTOTAL' | translate}}</dt>

                <dd>{{'CURRENCY.CURRENCY' | translate}} {{totalUnitPrice | number: '2.2-2'}}</dd>
              </dl>
              <dl class="cb-sum">
                <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Platform Charges</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'si'">වේදිකා ගාස්තු</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">இயங்குதள கட்டணங்கள்</dt>

                <dd *ngIf="paymentMethod === 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                  <span>&nbsp;</span>
                  <span id="platformCharge1">
                    <span>{{calPlatformCharge(totalUnitPrice, platformChargers)| number:'2.2-2'}}</span>
                    </span>
                </dd>
                <dd *ngIf="paymentMethod !== 'Card'">
                  <span *ngIf="activeLang === 'en'">Free</span>
                  <span *ngIf="activeLang === 'si'">ගාස්තුවක් අය නොකරේ</span>
                  <span *ngIf="activeLang === 'ta'">கட்டணம் இல்லை</span>
                </dd>
              </dl>
              <dl class="cb-sum total">
                <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Grand Total</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'si'">මුළු එකතුව</dt>
                <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">மொத்த மொத்த தொகை</dt>

                <dd *ngIf="paymentMethod === 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                  <span>&nbsp;</span>
                  <span id="grandTotal1">
                    <span>{{calGrandTotal(totalUnitPrice, platformChargers)| number:'2.2-2'}}</span>
                    </span>
                </dd>
                <dd *ngIf="paymentMethod !== 'Card'">{{'CURRENCY.CURRENCY' | translate}}
                  <span>&nbsp;</span>
                  <span>{{totalUnitPrice| number:'2.2-2'}}</span>
                </dd>
              </dl>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
