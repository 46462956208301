<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">
          <div class="voice-pack-page container vas-pages">
            <div class="row">
              <div class="col-md-3 p-0 p0sidebar">
                <div class="form-sidebar">
                  <div class="text-center">
                    <button class="btn btn-success d-green-btn"
                            [routerLink]="'/value-added-services/wholesale'">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.VALUE_ADDED_SERVICES'|translate}}
                    </button>
                  </div>

                  <div class="sidebar-links">
                    <li class="pckge-link active">
                      <a *ngIf="activeLang === 'en'">Application</a>
                      <a *ngIf="activeLang === 'si'">අයදුම්පත</a>
                      <a *ngIf="activeLang === 'ta'">விண்ணப்பம்</a>
                    </li>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="packge-form text-center">
                  <div class="top-info-section">
                    <img src="../../../../assets/images/slt.png" alt="" class="sltimg">
                    <h4 class="title-pck">
                      <span *ngIf="activeLang === 'en'">Get your Mobitel package</span>
                      <span *ngIf="activeLang === 'si'">ඔබේ මොබිටෙල් පැකේජය ලබා ගන්න</span>
                      <span *ngIf="activeLang === 'ta'">உங்கள் மொபிடெல் தொகுப்பைப் பெறுங்கள்</span>
                    </h4>

                    <p *ngIf="activeLang === 'en'">Please fill all the information in the given form to request a
                      Healviru SIM from SLTMobitel. You will be contacted by an officer from SLTMobitel once you submit
                      the complete form.</p>

                    <p *ngIf="activeLang === 'si'">SLTMobitel වෙතින් හෙළවිරු සිම්පතක් ලබා ගැනීම සඳහා කරුණාකර ලබා දී ඇති
                      පෝරමයේ ඇති සියලුම තොරතුරු පුරවන්න. ඔබ සම්පුර්ණ පෝරමය ඉදිරිපත් කළ පසු SLTMobitel හි නිලධාරියෙකු ඔබ
                      හා සම්බන්ධ වේ.</p>


                    <p *ngIf="activeLang === 'ta'">SLTMobitel இலிருந்து Healviru SIM ஐக் கோர கொடுக்கப்பட்ட படிவத்தில்
                      உள்ள அனைத்து தகவல்களையும் நிரப்பவும். நீங்கள் முழுமையான படிவத்தை சமர்ப்பித்தவுடன் SLTMobitel
                      இலிருந்து ஒரு அதிகாரி உங்களைத் தொடர்புகொள்வார்.</p>

                    <p class="rednotice">
                      <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
                      <span
                        *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
                      <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
                    </p>
                  </div>
                  <div class="form-inputs-section categoryform">
                    <form [formGroup]="form" (ngSubmit)="submitForm()">
                      <div class="flex-row col-12">
                        <div class="form-group col-6">
                          <label>{{'Title'|translate}} <span class="validationStar">*</span></label>
                          <select [(ngModel)]="selectedTitle"
                                  class="form-control province"
                                  formControlName="title" id="title">
                            <option disabled>
                              {{'Select title'|translate}}
                            </option>
                            <option *ngFor="let title of titleList" [value]="title.id">
                              <span >{{title.description|translate}}</span>
                            </option>
                          </select>
                          <div *ngIf="submittedForm && f1.title.errors" class="inputError">
                            <div *ngIf="f1.title.errors.required">
                              {{'Title field is required!.'|translate}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-row col-12">
                        <div class="form-group col-6">
                          <label> {{'Name According to the NIC'|translate}}<span class="validationStar">*</span></label>
                          <input [(ngModel)]="name"
                                 [ngClass]="{ 'is-invalid': submittedForm && f1.name.errors }" class="form-control"
                                 formControlName="name" id="name"
                                 placeholder="{{'Name According to the NIC'|translate}}"
                                 type="text">
                          <div *ngIf="submittedForm && f1.name.errors" class="inputError">
                            <div *ngIf="f1.name.errors.required">
                              {{'Name field is required!.'|translate}}
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-6">
                          <label>{{'NIC'|translate}} <span class="validationStar">*</span></label>
                          <input [(ngModel)]="NIC"
                                 [ngClass]="{ 'is-invalid': submittedForm && f1.nic.errors }" class="form-control"
                                 formControlName="nic" id="nic"
                                 placeholder="{{'NIC'|translate}}"
                                 type="text">
                          <div *ngIf="submittedForm && f1.nic.errors" class="inputError">
                            <div *ngIf="f1.nic.errors.required">{{'COMMON_VALIDATION.NIC' | translate}}</div>
                            <div *ngIf="f1.nic.errors.pattern">{{'COMMON_VALIDATION.VALID_NIC' | translate}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-row col-12">
                        <div class="form-group col-6">
                          <label>{{'BOD'|translate}} <span class="validationStar">*</span></label>
                          <input [max]="todayDate" type="date" name="dateofbirth" id="dateofbirth" [(ngModel)]="bod"
                                 class="form-input form-control" formControlName="bod">
                          <div *ngIf="submittedForm && f1.bod.errors" class="inputError">
                            <div *ngIf="f1.bod.errors.required">
                              {{'Date of Birth field is required!.'|translate}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-row col-12">
                        <div class="form-group col-6">
                          <label for="address1">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS_1' | translate}}
                            <span class="validationStar">*</span></label>
                          <input [(ngModel)]="address1"
                                 [ngClass]="{ 'is-invalid': submittedForm && f1.address1.errors }" class="form-control"
                                 formControlName="address1" id="address1"
                                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}"
                                 type="text">
                          <div *ngIf="submittedForm && f1.address1.errors" class="inputError">
                            <div *ngIf="f1.address1.errors.required">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group col-6">
                          <label
                            for="address2">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS_2' | translate}}</label>
                          <input [(ngModel)]="address2"
                                 [ngClass]="{ 'is-invalid': submittedForm && f1.address2.errors }"
                                 class="form-control" formControlName="address2" id="address2"
                                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}"
                                 type="text">
                          <div *ngIf="submittedForm && f1.address2.errors" class="inputError">
                            <div *ngIf="f1.address2.errors.required">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="flex-row col-12">
                        <div class="form-group col-6">
                          <label for="pro">
                            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PROVINCE' | translate}}
                            <span class="validationStar">*</span></label>
                          <select (change)="selectProvince($event)" [(ngModel)]="selectProvinceId"
                                  class="form-control province"
                                  formControlName="province" id="pro">
                            <option disabled>
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PROVINCE' | translate}}
                            </option>
                            <option *ngFor="let province of provinceList" [value]="province.id">
                              <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                              <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                              <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                            </option>
                          </select>
                          <div *ngIf="submittedForm && f1.province.errors" class="inputError">
                            <div *ngIf="f1.province.errors.required">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.PROVINCE_REQUIRED' | translate}}
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-6">
                          <label for="dis">
                            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
                            <span class="validationStar">*</span></label>
                          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
                          <select (change)="selectDistrict($event)" [(ngModel)]="selectDistrictId"
                                  class="form-control province"
                                  formControlName="district" id="dis">
                            <option disabled>
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_DISTRICT' | translate}}
                            </option>
                            <option *ngFor="let district of districtList" [value]="district.id">
                              <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
                              <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
                              <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
                            </option>
                          </select>
                          <div *ngIf="submittedForm && f1.district.errors" class="inputError">
                            <div *ngIf="f1.district.errors.required">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-row col-12">
                        <div class="form-group col-6">
                          <label for="dis">
                            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.CITY' | translate}}
                            <span class="validationStar">*</span></label>
                          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
                          <select (change)="selectCity($event)" [(ngModel)]="selectCityId" class="form-control province"
                                  formControlName="city" id="city">
                            <option disabled>
                              Select City
                            </option>
                            <option *ngFor="let city of allCities" [value]="city.id"
                                    (click)="selectCityEn(city.nameEN)">
                              <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
                              <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
                              <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
                            </option>
                          </select>
                          <div *ngIf="submittedForm && f1.city.errors" class="inputError">
                            <div *ngIf="f1.city.errors.required">
                              City is Required
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-6">
                          <label for="postalCode">
                            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}
                          </label>
                          <input [(ngModel)]="postalCode" disabled
                                 [ngClass]="{ 'is-invalid': submittedForm && f1.postalCode.errors }"
                                 class="form-control" formControlName="postalCode" id="postalCode"
                                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}"
                                 type="text">
                          <div *ngIf="submittedForm && f1.postalCode.errors" class="inputError">
                            <div *ngIf="f1.postalCode.errors.required">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="form-group btn-sections text-right">
                        <button aria-label="Close" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;"
                                class="btn cancel-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</button>
                        <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;"
                                type="submit">{{'MY_ACCOUNT.SELL_A_PRODUCT.SUBMIT' | translate}}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<button hidden data-target="#successModal" data-toggle="modal" #openSuccessModal></button>

<!-- Success Modal -->
<div class="modal fade npa-model" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/>
              {{'Your voice package application details have been submitted successfully'|translate}}</p>
            <a aria-label="Next" class="btn next-btn-close" [routerLink]="'/value-added-services/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
