<div class="header-cust">
  <div class="header-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center p-0">
          <div class="d-flex justify-content-between main-nav" id="navigationheader">
            <div class="brandlogo">
              <a [routerLink]="['/home/'+tenantValue]" class="logo-wp">
                <img src="assets/images/logos/helaviru.png" alt="Logo"/>
              </a>
            </div>

            <div class="header-right">
              <div class="header-right-inner d-flex align-items-center">
                <div class="dropdown profile browseall">

                  <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    <span class="tooltip-bottom flexlist">
                      <span class="nav-link"> <i aria-hidden="true" class="fa fa-bars"></i>
                        {{'Browse_Products' | translate}}
                      </span>
                    </span>
                  </a>

                  <div class="language_section dropdown-menu  dropdown-menu-right">
                    <li *ngFor="let category of allCategory">
                      <a [queryParams]="{mainCatID:category.code}" [routerLink]="['/products/'+tenantValue]"
                         class="d-flex align-items-center">
                        <span class="icon-c">
                          <img
                            src="{{this.mainUrl.MAIN_URL_FILE + category.icon+ '/?X-TenantID=' + this.tenantValue}}"
                            alt="">
                        </span>
                        <div class="name-c">
                          <span class="cat-text" *ngIf="activeLang === 'en'">{{category.commonIndex.description}}</span>
                          <span class="cat-text" *ngIf="activeLang === 'si'">{{category.commonIndex.sinhala}}</span>
                          <span class="cat-text" *ngIf="activeLang === 'ta'">{{category.commonIndex.tamil}}</span>
                        </div>
                      </a>
                    </li>
                  </div>
                </div>



                <a class="nav-link" [routerLink]="['/products/'+tenantValue]" [queryParams]="{ecoNo:2}">
                  {{'ECONOMIC_CENTER_TITLE' | translate}}
                </a>

                <a class="nav-link" [routerLink]="['/about/'+tenantValue]">
                  {{'FOOTER.ABOUT_US' | translate}}
                </a>

<!--                <a class="nav-link" [routerLink]="['/contact/'+tenantValue]">-->
<!--                  {{'FOOTER.CONTACT_US.CONTACT_US' | translate}}-->
<!--                </a>-->

                <div class="align-right-nav d-flex align-items-center">
                  <div class="serach-wp">
                    <div class="search-container">
                      <span>
                        <input [(ngModel)]="searchText" name="search"
                               placeholder="{{'SEARCH_BAR' | translate}}" type="text">
                        <img alt="" *ngIf="searchLoading" class="loadingGif"
                             src="../../assets/images/loading/loading.gif">
                        <button (click)="searchBar()" type="submit"><em class="fa fa-search"></em></button>
                      </span>
                    </div>
                  </div>
                  <div class="dropdown profile loginbtnborders" *ngIf="!isLog">
                    <a data-toggle="modal" data-target="#signInUp" id="loginIcon" class="logiregister"><span
                      class="flexlist">
                        <i class="fa fa-user-circle" aria-hidden="true"></i><span class="loginspan">{{'LOGIN'|
                      translate}}</span>
                      </span>
                    </a>
                    <span class="flexlist borderbtn" data-toggle="dropdown">
                      <span class="">
                        <span>/ {{'REGISTER.REGISTER' | translate}}</span>
                      </span>
                    </span>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"
                         style="left: -9vw;">
                      <a class="dropdown-item" [routerLink]="'/sign-up/'+tenantValue">
                        <span class="droplink">{{'REGISTER.AS_AN_INDIVIDUAL' | translate}}</span>
                      </a>
                      <a class="dropdown-item" [routerLink]="'/sign-up/'+tenantValue" [queryParams]="{company:true}">

                        <span class="droplink">{{'REGISTER.AS_A_COMPANY' | translate}}</span>
                      </a>

                    </div>
                  </div>
                  <div class="dropdown profile" *ngIf="isLog">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                      <div class="header-profile" style="text-transform: capitalize;" matTooltip="{{showUsername}}">
                        <p class="font-weight-bold" *ngIf="showUsername">{{showUsername}}</p>
                        <i class="material-icons">arrow_drop_down</i>
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                      </div>
                    </a>
                    <div class="dropdown-menu  dropdown-menu-right">
                      <a [routerLink]="['/my-account/'+tenantValue]" [queryParams]="{'headerClicked':true}"><i
                        class="fa fa-user-circle"
                        aria-hidden="true"></i>
                        {{'MY_ACCOUNT.MY_ACCOUNT' | translate}}</a>
                      <a [routerLink]="['/cart/'+tenantValue]"><i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        {{'MY_SHOPPING_CART.MY_SHOPPING_CART' | translate}}</a>
                      <a (click)="logout()"><i class="fa fa-times" aria-hidden="true"></i>{{'SIGN_OUT.SIGN_OUT' |
                        translate}}</a>
                    </div>
                  </div>

                  <div class="dropdown cart" *ngIf="isLog">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                      <span class="tooltip-bottom flexlist"
                            matTooltip="{{'REGISTER.TOOLTIP.SHOPPING_CART' | translate}}">
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        <span class="crt-no" *ngIf="isEmptyCart !== 1">{{cartItemCount}}</span>
                      </span>
                    </a>
                    <div class="dropdown-menu  dropdown-menu-right">
                      <div *ngIf="isEmptyCart == 1" style="text-align: center;">
                        <img style="width: 40px; margin-right: 5px; padding: 5px;" src="../../assets/cartEmpty.png"
                             alt=""/>
                        <span style="font-weight: bold">{{'MY_SHOPPING_CART.CART_EMPTY' | translate}}</span>

                        <a class="ssc-btn" [routerLink]="'/products/'+tenantValue">{{'MY_SHOPPING_CART.START_SHOPPING' |
                          translate}}</a>
                      </div>

                      <div *ngIf="spinnerTag == 1">
                        <div class="sk-cube-grid">
                          <div class="sk-cube sk-cube1"></div>
                          <div class="sk-cube sk-cube2"></div>
                          <div class="sk-cube sk-cube3"></div>
                          <div class="sk-cube sk-cube4"></div>
                          <div class="sk-cube sk-cube5"></div>
                          <div class="sk-cube sk-cube6"></div>
                          <div class="sk-cube sk-cube7"></div>
                          <div class="sk-cube sk-cube8"></div>
                          <div class="sk-cube sk-cube9"></div>
                        </div>
                      </div>

                      <div *ngIf="selectItem">
                        <div style="max-height: 300px ; height: auto; overflow-y: scroll;">
                          <div class="dcp-item" *ngFor="let item of selectItem">
                            <span style="cursor: pointer;" class="fa fa-times" aria-hidden="true"
                                  (click)="removeItem(item.productSku)"></span>
                            <div class="dcp-info">
                              <span *ngIf="item.quantity !== 0">{{item.name}}</span>
                              <span *ngIf="item.quantity === 0"
                                    style="text-decoration: line-through;">{{item.name}}</span><br>
                              <span *ngIf="item.quantity !== 0">{{item.quantity}}{{item.unitName}}
                                x {{'CURRENCY.CURRENCY' | translate}}
                                <span>&nbsp;</span>{{item.unitPrice| number:'2.2-2'}}</span>
                              <span style="font-size: 10px;font-weight: bold">(per {{item.unitName}})</span>

                              <span *ngIf="item.quantity === 0" style="text-decoration: line-through;">
                                {{item.quantity}}{{item.unitName}} x {{'CURRENCY.CURRENCY' | translate}}
                                <span>&nbsp;</span>{{item.unitPrice| number:'2.2-2'}}
                                <span style="font-size: 10px;font-weight: bold">(per {{item.unitName}})</span>
                              </span>
                              <br>
                              <span *ngIf="item.quantity === 0" style="color: red">OUT STOCK</span>
                            </div>
                            <div class="dcp-img">
                              <img class="imageSize" *ngIf="item.productImages.length > 0"
                                   src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}"
                                   alt=""/>
                              <img class="imageSize" *ngIf="item.productImages.length <= 0"
                                   src="assets/defaultBrokenImage.jpg" alt=""/>
                            </div>
                          </div>

                        </div>
                        <div class="dcp-sub" *ngIf="totalDiscount > 0">
                          <span style="float: left">{{'DISCOUNT' | translate}}</span> <span
                          style="float: right">{{'CURRENCY.CURRENCY' | translate}}
                          <span>&nbsp;</span>{{totalDiscount| number:'2.2-2'}}</span>
                          <div class="clearfix"></div>
                        </div>
                        <div class="dcp-sub">
                          <span style="float: left">{{'TOTAL' | translate}}</span> <span style="float: right">
                            {{'CURRENCY.CURRENCY' | translate}}
                          <span>&nbsp;</span>{{totalAmount| number:'2.2-2'}}</span>
                          <div class="clearfix"></div>
                        </div>
                        <a [routerLink]="['/cart/'+tenantValue]" class="btn-login drak">{{'CART.VIEW_CART' |
                          translate}}</a>
                      </div>

                    </div>
                  </div>

                  <div class="dropdown profile langauage">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                      <span class="tooltip-bottom flexlist"
                            matTooltip="{{'REGISTER.TOOLTIP.SELECT_LANGUAGE' | translate}}">
                        <span class="material-icons"> language </span> &nbsp;<span>{{language}}</span><i
                        aria-hidden="true" class="fa fa-sort-down downarrow"></i>
                      </span>
                    </a>
                    <div class="language_section dropdown-menu  dropdown-menu-right">
                      <a (click)="useLanguage('en','English')"> English</a>
                      <a (click)="useLanguage('si','සිංහල')"> සිංහල
                      </a>
                      <a (click)="useLanguage('ta','தமிழ்')"> தமிழ்
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
  </div>
</div>


<!-- SignIn / SignUp Modal -->
<div class="modal fade" id="signInUp" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-550 margin-0 signIn-model" role="document">
    <button id="modal-dismiss" type="button" class="close" data-dismiss="modal" aria-label="Close">
    </button>
    <div class="modal-content">
      <div class="login-model-header">
        <div class="logo-wp">
          <img src="../../assets/images/helaviru.png" alt="Helaviru Logo"/>
        </div>
        <div class="lmh-content">
          <h3 style="font-weight: 700;font-size: 22px;">{{'SIGN_IN.SIGN_IN' | translate}}</h3>
          <p>{{'SIGN_IN.SIGN_IN_TO' | translate}}</p>
        </div>
        <span class="otp-step-video" data-toggle="modal" data-target="#otpStepVideo" data-dismiss="modal">
          {{'SIGN_IN.WATCH' | translate}} &nbsp;<i class="fa fa-question-circle" aria-hidden="true"></i>
        </span>
      </div>
      <div class="sst-wrapper">

        <div class="tab-content" style="padding-top:0;">
          <div role="tabpanel" class="tab-pane active" id="In">
            <p>{{'SIGN_IN.OTP_MSG' | translate}}</p>
            <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onSubmitLogin()">
              <div class="form-group">
                <i class="fa fa-user-o" aria-hidden="true"></i>
                <input [ngClass]="{ 'is-invalid': submittedLoginForm && f1.loginUsername.errors }" matInput
                       style="font-size:13px!important;" formControlName="loginUsername" [(ngModel)]="loginUsername"
                       type="email" name="username" class="form-control"
                       placeholder="{{'SIGN_IN.USER_NAME' | translate}}">
                <div *ngIf="submittedLoginForm && f1.loginUsername.errors" class="invalid-feedback">
                  <div style="font-size: 12px;color: red" *ngIf="f1.loginUsername.errors.required">
                    {{'SIGN_IN.VALIDATE.USER_NAME_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div>
                <button style="cursor:pointer;height: 50px;" type="submit" class="btn btn-login">{{'SIGN_IN.SIGN_IN_B'
                  | translate}}
                  <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
                </button>

              </div>
              <br>
              <div class="row justify-content-between c-login-footer" style="margin: unset!important;">
                <button data-dismiss="modal" class="btn btn-secondary col-5" [routerLink]="'/sign-up/'+tenantValue">
                  <i class="fa fa-male" aria-hidden="true"></i> {{'REGISTER.REGISTER_AS_AN_INDIVIDUAL' | translate}}
                </button>
                <button data-dismiss="modal" class="btn btn-secondary col-5" [routerLink]="'/sign-up/'+tenantValue"
                        [queryParams]="{company:true}">
                  <i class="fa fa-building-o" aria-hidden="true"></i> {{'REGISTER.REGISTER_AS_A_COMPANY' | translate}}
                </button>

              </div>
            </form>
          </div>
          <div role="tabpanel" class="tab-pane fade" id="Up">
            <form [formGroup]="signUpForm" class="login-form" (ngSubmit)="onSubmitSignUp()">
              <div class="form-group">
                <i class="fa fa-user" aria-hidden="true"></i>
                <input [ngClass]="{ 'is-invalid': submittedSignUpForm && f2.name.errors }" formControlName="name"
                       [(ngModel)]="name" type="text" class="form-control" placeholder="Name">
                <div *ngIf="submittedSignUpForm && f2.name.errors" class="invalid-feedback">
                  <div style="font-size: 12px;color: red" *ngIf="f2.name.errors.required">
                    Name is required
                  </div>
                </div>
                <div style="font-size: 12px;color: red" *ngIf="wrongName">
                  Name must be a valid
                </div>
              </div>
              <div class="form-group">
                <i class="fa fa-mobile" aria-hidden="true"></i>
                <input [ngClass]="{ 'is-invalid': submittedSignUpForm && f2.mobile.errors }" formControlName="mobile"
                       [(ngModel)]="mobile" type="tel" class="form-control" placeholder="Mobile">
                <div *ngIf="submittedSignUpForm && f2.mobile.errors" class="invalid-feedback">
                  <div style="font-size: 12px;color: red" *ngIf="f2.mobile.errors.required">
                    Mobile is required
                  </div>
                  <div style="font-size: 12px;color: red" *ngIf="f2.mobile.errors.pattern">
                    Mobile must be a valid mobile
                  </div>
                </div>
              </div>
              <div class="form-group">
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                <input [ngClass]="{ 'is-invalid': submittedSignUpForm && f2.email.errors }" type="email"
                       formControlName="email" [(ngModel)]="email" class="form-control" placeholder="Email">
                <div *ngIf="submittedSignUpForm && f2.email.errors" class="invalid-feedback">
                  <div style="font-size: 12px;color: red" *ngIf="f2.email.errors.required">
                    Email is required
                  </div>
                  <div *ngIf="f2.email.errors.pattern">Email must be a valid email address</div>
                </div>
              </div>
              <div class="form-group">
                <i class="fa fa-key" aria-hidden="true"></i>
                <input [ngClass]="{ 'is-invalid': submittedSignUpForm && f2.password.errors }"
                       formControlName="password" [(ngModel)]="password" type="password" class="form-control"
                       placeholder="Password">
                <div *ngIf="submittedSignUpForm && f2.password.errors" class="invalid-feedback">
                  <div style="font-size: 12px;color: red" *ngIf="f2.password.errors.required">
                    Password is required
                  </div>
                  <div style="font-size: 12px;color: red" *ngIf="f2.password.errors.minlength">Password must be at
                    least
                    6 characters
                  </div>
                </div>
              </div>
              <div class="form-group">
                <i class="fa fa-key" aria-hidden="true"></i>
                <input [ngClass]="{ 'is-invalid': submittedSignUpForm && f2.rePassword.errors }"
                       formControlName="rePassword" [(ngModel)]="rePassword" type="password" class="form-control"
                       placeholder="Confirm Password">
                <div *ngIf="submittedSignUpForm && f2.rePassword.errors" class="invalid-feedback">
                  <div style="font-size: 12px;color: red" *ngIf="f2.rePassword.errors.required">
                    Confirm password is required
                  </div>
                  <div style="font-size: 12px;color: red" *ngIf="f2.rePassword.errors.mustMatch">Passwords must match
                  </div>
                </div>
              </div>
              <div>
                <button style="cursor:pointer;height: 50px;" type="submit" class="btn btn-login">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- OTP modal -->
<button #optModal2 class="btn btn-primary" data-target="#otpModal2" data-toggle="modal" hidden type="button">
</button>

<div aria-hidden="true" aria-labelledby="otpModal2" class="modal fade" data-backdrop="static" id="otpModal2"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="login-model-header otp">
        <div class="logo-wp">
          <img src="../../assets/images/helaviru.png" alt="Helaviru Logo"/>
        </div>
      </div>
      <div class="modal-body">
        <h5 class="modal-title"
            id="exampleModalLongTitle">{{'SIGN_IN.FORGET_PASSWORD.OTP_HAS_BEEN_SENT' | translate}}</h5>
        <h3>{{'SIGN_IN.FORGET_PASSWORD.ENTER_OTP_CODE' | translate}}</h3>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6,allowNumbersOnly:true}"
                      id="otpval"></ng-otp-input>
        <div style="color: red;margin-top: 5px">{{'SIGN_IN.FORGET_PASSWORD.CODE_EXPIRES_IN' | translate}} :
          &nbsp;<span id="time2">03:00</span></div>
        <button (click)="resendOTPCode()" *ngIf="isShowResendButton" class="btn btn-primary customBtn"
                style="margin-top: 12px;" type="button">{{'SIGN_IN.FORGET_PASSWORD.RESEND_CODE' | translate}}
        </button>
        <span *ngIf="isInvalid" style="color: red;font-weight: bold;font-size: 14px;">{{'SIGN_IN.OTP_INVALID' |
          translate}} </span>
      </div>
      <div class="modal-footer ft-opt-model">
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        <button #closeModal (click)="closeOTPModal()" class="btn btn-primary customBtn2" aria-label="Close"
                data-dismiss="modal" type="button">
          {{'SIGN_IN.CLOSE' | translate}}
        </button>
        <button (click)="verifyOTP()" class="btn btn-primary customBtn" type="button">
          {{'SIGN_IN.FORGET_PASSWORD.VERIFY' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="otpStepVideo" class="modal fade" data-backdrop="static" id="otpStepVideo"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <video width="100%" height="280" loop autoplay>
          <source src="../../assets/images/OTP-Video.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="modal-footer text-center" style="padding-bottom: 0;">
          <button class="btn btn-primary customBtn" style="display: block; width: 150px; margin: 0 auto;" type="button"
                  data-toggle="modal" data-target="#signInUp" data-dismiss="modal">
            {{'SIGN_IN.CLOSE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
