<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-lg-8 col-xs-12">
              <h2><img src="../../../../assets/images/icons/bike.png" alt="">&nbsp;{{'RiyaviruBidsTitle'|translate}}
              </h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;" id="d-request-tbl">

                <table [dataSource]="dataSource" class="table salestable" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="index">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>

                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="form-check form-check-inline" *ngIf="row['status'] === 'ACCEPTED'">
                        <div class="form-check">
                          <input class="form-check-input checkone" type="checkbox" [id]="row.orderNo"
                                 (click)="addToArray(row.orderNo,row.orderList)">
                          <label class="cust-lable form-check-label" [for]="row.orderNo">
                          </label>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="orderTitle">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'OrderTitle'|translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{row.orderTitle}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bids">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'bids'|translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{row.bids}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="date">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'Date'|translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{convertDate(row.date)}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'Status'|translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">

                      <img *ngIf="(row.status === 'REQUEST_PENDING' ||
                                   row.status === 'AWARDED' ||
                                   row.status === 'BID_PLACED' ||
                                   row.status === 'NOT_AWARDED' ||
                                   row.status === 'REJECTED' ||
                                   row.status === null) && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-status.svg"/>
                      <img *ngIf="(row.status === 'REQUEST_PENDING' ||
                                   row.status === 'AWARDED' ||
                                   row.status === 'NOT_AWARDED' ||
                                   row.status === 'BID_PLACED' ||
                                   row.status === 'REJECTED' ||
                                   row.status === null) && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-sin.svg"/>
                      <img *ngIf="(row.status === 'REQUEST_PENDING' ||
                                   row.status === 'AWARDED' ||
                                   row.status === 'NOT_AWARDED' ||
                                   row.status === 'BID_PLACED' ||
                                   row.status === 'REJECTED' ||
                                   row.status === null) && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-tam.svg"/>

                      <img *ngIf="(row.status === 'STARTED'
                       || row.status === 'REACHED_PICKUP_POINT'
                       || row.status === 'ACCEPTED'
                       || row.status === 'PAYMENT_CONFIRMED'
                       || row.status === 'LOADED_AND_LEAVE'
                       || row.status === 'DISPATCH_CONFIRMED'
                       || row.status === 'REACHED_DROPOFF_POINT'
                       || row.status === 'DROPOFF_CONFIRMED') && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/awarded.svg"/>
                      <img *ngIf="(row.status === 'STARTED'
                       || row.status === 'REACHED_PICKUP_POINT'
                       || row.status === 'ACCEPTED'
                       || row.status === 'PAYMENT_CONFIRMED'
                       || row.status === 'LOADED_AND_LEAVE'
                       || row.status === 'DISPATCH_CONFIRMED'
                       || row.status === 'REACHED_DROPOFF_POINT'
                       || row.status === 'DROPOFF_CONFIRMED') && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/awarded-sin.svg"/>
                      <img *ngIf="(row.status === 'STARTED'
                       || row.status === 'REACHED_PICKUP_POINT'
                       || row.status === 'ACCEPTED'
                       || row.status === 'PAYMENT_CONFIRMED'
                       || row.status === 'LOADED_AND_LEAVE'
                       || row.status === 'DISPATCH_CONFIRMED'
                       || row.status === 'REACHED_DROPOFF_POINT'
                       || row.status === 'DROPOFF_CONFIRMED') && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/awarded-tam.svg"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action1">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>

                    </th>
                    <td *matCellDef="let row" class="center-table tbl"
                        (click)="selectDriverBids(row.orderNo,row.orderList)" mat-cell style="padding-left: 0;"
                        data-toggle="modal" data-target="#orderDetails">
                      <button type="button" class="btn btn-success">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action2">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>

                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <button *ngIf="row['status'] === 'ACCEPTED'" (click)="checkout(row.orderList)" type="button"
                              class="btn btn-success">{{'CHECKOUT.CHECKOUT'|translate}}
                      </button>
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                  </tr>
                  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
              </div>
              <div class="checkout-all text-right" *ngIf="dataSource.data.length > 0">
                <button class="btn-default disabledb" (click)="checkoutAll()">{{'CheckoutAll'|translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--view Order Details -->
<div class="modal fade npa-model" id="orderDetails" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <button type="button" class="close text-right" data-dismiss="modal" (click)="selectNewProductOptions==0"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div style="width:100%;max-height: 80vh ; height: auto; overflow-y: auto;padding: 15px 0;">
        <table class="table modal-table">
          <thead>
          <tr>
            <th scope="col">{{'DeliveryPerson'|translate}}</th>
            <th scope="col">{{'VehicleType'|translate}}</th>
            <th scope="col">{{'TotalBid'|translate}}</th>
            <th scope="col" class="text-right">{{'Action'|translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let bid of driverBidsList;let i = index">
            <td>
              <img alt="" class="vehicle-img" src="{{bid['profileImage']}}"/>
              {{bid['firstName']}} {{bid['lastName']}}
              &nbsp;
              <img *ngIf="bid.status === 'PAYMENT_CONFIRMED' && activeLang === 'en'" alt="" class="vehicle-status"
                   src="../../../../assets/images/awarded.svg"/>
              <img *ngIf="bid.status === 'PAYMENT_CONFIRMED'  && activeLang === 'si'" alt="" class="vehicle-status"
                   src="../../../../assets/images/awarded-sin.svg"/>
              <img *ngIf="bid.status === 'PAYMENT_CONFIRMED'  && activeLang === 'ta'" alt="" class="vehicle-status"
                   src="../../../../assets/images/awarded-tam.svg"/>

            </td>
            <td class="text-center" style="float: left;">
              {{bid['vehicleType']}}
            </td>
            <td class="font-weight-bold"> {{'CURRENCY.CURRENCY' | translate}} {{bid['driversOffer']}}</td>
            <td class="text-right">
              <button [disabled]="disableButton(bid.status)"
                      (click)="viewBid(bid['driverId'],bid['driversOffer'])"
                      class="btn btn-default-2" data-dismiss="modal" data-toggle="modal" data-target="#viewBID">
                {{ViewBidText(bid.status)}}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex dblock justify-content-between  mt-5 mb-3">
          <button class="btn btn-default dark tsmall" data-toggle="modal" #transportModal
                  data-target="#transport-options">{{'pick_diff_method'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Transport options -->
<div aria-hidden="true" class="modal fade overflowvisible npa-model" data-backdrop="static" id="transport-options"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0" role="document">
    <div class="modal-content tcontent">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_OPTIONS' | translate}}</h5>
        <button #transportOptionsClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="opt-row">
          <div class="transport-opt row">
            <div class="products-set col-md-12 col-xs-12 transportmethods" id="nav-tabContent">
              <div class="insidepaddings">
                <mat-radio-group aria-label="Select an option">


                  <!-- riyaviru transport -->
                  <div class="buyertransport rb-cl-wp" data-target="#riyaviruModal" data-toggle="modal"
                       [ngClass]="{'active':selectedOption==sku+1}" (click)="clickRiyaviru(item,sku)">
                    <mat-radio-button [value]="100+1" class="tos-radio" (change)="checkVal($event,sku,1)">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'RIYAVIRU_TRANSPORT1' | translate}}</h5>
                          <p>{{'RIYAVIRU_TRANSPORT2' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <!-- buyer transport -->
                  <div class="buyertransport rb-cl-wp"
                       [ngClass]="{'active':selectedOption==sku+2}">
                    <mat-radio-button [value]="100+2" class="tos-radio" (change)="checkVal($event,sku,2)"
                                      (click)="selectedOption=sku+2">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_B' | translate}}</h5>
                          <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.ORDER_IS_RESPONSIBLE' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <div *ngFor="let trn of deliveryOptList;index as k">


                    <!-- Train transport -->
                    <div class="toptile">
                      <div class="t-title">
                        <div class="ifrailtransport rb-cl-wp" [ngClass]="{'active':selectedOption==sku+4}"
                             *ngIf="trn.deliveryOpt==='4'">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,sku,4)"
                                            (click)="selectedOption=sku+4">
                            <div class="toptile d-flex justify-content-between">
                              <div class="t-title">
                                <h5>Transport Method (Rail Freight Transport) </h5>
                                <p>Select the nearest train station to you for the pickup</p>
                              </div>
                              <div class="d-flex align-items-center rail">
                                <span class="material-icons"> train</span>
                                <p class="font-bold">Rail Freight Transport</p>
                              </div>
                            </div>
                            <div class="pickup-dropof row">
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Pickup Station</label>
                                <input name="pickup" class="form-control" style="width: 50%;" type="text" disabled
                                       value="item.transport.station">
                              </div>
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Dropoff Station <span
                                  class="floatright">Estimated
                                    arrival time: 7-8 hours</span></label>
                                <select (change)="selectRailway($event.target.value)" style="width: 50%;"
                                        class="form-control province" id="railway">
                                  <option disabled>
                                    Select a railway station
                                  </option>
                                  <option *ngFor="let station of railwayList" [value]="station.id">
                                    {{station.descriptionEng}}
                                  </option>

                                </select>
                              </div>
                            </div>

                            <p>Please note that the <strong>Arrival Times</strong> can be vary depending on the weather
                              conditions and other unforeseeable factors. <br>If the delivery is considerably late, or
                              if
                              you need
                              more information, please feel free to <a href="">contact us.</a></p>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>

                    <!-- seller transport -->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='3'"
                         [ngClass]="{'active':selectedOption==sku+3}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,sku,3)"
                                            (click)="selectedOption=sku+3">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_DELIVER' | translate}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGE' |
                              translate}}{{'CURRENCY.CURRENCY' |
                              translate}} {{trn.deliveryCharges| number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{this.sellerName}}</h5>
                          <p>{{this.sellerLocation}}</p>
                        </div>
                      </div>
                    </div>

                    <!-- seller transport fixed price-->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='5'"
                         [ngClass]="{'active':selectedOption==sku+5}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,sku,5)"
                                            (click)="selectedOption=sku+5">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_WILL_DELIVER' | translate}}</p>
                            <p>{{trn.deliveryDesc}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_FIX' | translate}}
                              {{'CURRENCY.CURRENCY' | translate}} {{trn.deliveryCharges | number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{this.sellerName}}</h5>
                          <p>{{this.sellerLocation}}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </mat-radio-group>


              </div>

            </div>
          </div>
        </div>

        <div class="pm-pop-bottom col-12">
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.CANCEL_B' | translate}}</a>
            <a aria-label="Next" class="btn next-btn-half" data-dismiss="modal" (click)="updateDeliveryMethod()">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.PROCEED_B' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Riyaviru transport modal -->
<div aria-hidden="true" data-backdrop="static" data-keyboard="false" class="modal fade overflowvisible npa-model"
     id="riyaviruModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content tcontent">
      <div class="modal-header">
        <h5 class="modal-title"><img src="../../../assets/images/riyaviru.png" alt=""
                                     class="riyviru">{{'riyaviru_transport'|translate}}
        </h5>
        <button #closeRiyawiruModal (click)="riyawiruModalShow()" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="tr-riyaviru">
          <div class="pick-option">
            <p><img src="../../../assets/images/icons/vehicle.png" alt=""> &nbsp;{{'PICK_A_VEHICLE'|translate}}</p>
            <div class="vehicle-list row">
              <div class="col-lg-2 v-i-list" *ngFor="let vehicle of riyawiruVehicleTypes; let i = index"
                   (click)="selectVehicleType(vehicle['typeId'],sku)"
                   [ngClass]="{'active':selectedId==vehicle['typeId']}">
                <div class="row">
                  <div class="col-12">
                    <div class="v-item">
                      <img src="{{vehicle['icons']?.icon}}" alt="">
                    </div>
                  </div>
                  <div class="col-12 vhclname" style="font-size: 12px;margin-bottom: 1vh">
                     <span *ngIf="activeLang === 'en'" title="{{vehicle['name']['nameEN']}}">
                      {{vehicle['name']['nameEN']}}
                    </span>
                    <span *ngIf="activeLang === 'si'" title="{{vehicle['name']['nameSI']}}">
                      {{vehicle['name']['nameSI']}}
                     </span>
                    <span *ngIf="activeLang === 'ta'" title="{{vehicle['name']['nameTM']}}">
                       {{vehicle['name']['nameTM']}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <span style="color: red;font-size: 10px" *ngIf="isPickVehicle">{{'please_pick_the_vehicle'|translate}}
              !</span>
          </div>
          <div class="other-info">
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="dleft">
                  <div class="d-flex align-items-start">
                    <span class="material-icons iconred">
                      location_on
                    </span>
                    <div class="l-info">
                      <h5>{{'Item_Pickup_Location'|translate}}:</h5>
                      <p>{{riyaviruModalData?.location}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-bar">
            <div class="row align-items-center">
              <div class="col-md-8 col-xs-12">
                <div class="dleft">
                  <p>{{'My_Expectation'|translate}} ({{'CURRENCY.CURRENCY' | translate}})</p>
                  <input min="1" type="number" class="binput" [(ngModel)]="expectationPrice"
                         (focusout)="checkExpectationPrice(expectationPrice)">
                  <span style="color: red;font-size: 10px"
                        *ngIf="isRequiredExpectation">{{'required_field'|translate}}</span>
                  <small>
                    {{'RIYAWIRU_BIT_REQUEST_TEXT'|translate}}
                  </small>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="dright">
                  <button (click)="sendBidRequest(sku,expectationPrice)" class="btn btn-default next"
                          aria-label="Close">
                    {{'SEND_BID_REQUEST'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- View bid-->
<div aria-hidden="true" class="modal fade npa-model view-bid overflowvisible" data-backdrop="static"
     data-keyboard="false" id="viewBID"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content p-0">
      <div class="modal-header">
        <h5 class="modal-title">{{'DeliveryBidView'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="m-body">
        <div class="d-view">
          <div class="m-profile" id="mprof">
            <div class="d-flex align-items-center">
              <div class="prela">
                <img src="{{driverDetails?.profileImage}}" alt=""
                     style="width: 80px; height: 80px; border-radius: 100%">
                <img src="../../../../assets/images/temp/star.png" alt="" class="absimg">
              </div>

              <div class="d-details">
                <h4>{{driverDetails?.firstName}} &nbsp; {{driverDetails?.lastName}}</h4>
              </div>
            </div>
          </div>
          <div class="m-vehicle" id="mveh">
            <h4>
              {{driverDetails?.vehicleType}}
            </h4>
            <p><span>From : {{orderDetails?.orderItems[0].pickupAddress.city}}
              </span> <span>To : {{orderDetails?.deliveryAddress.city}}</span>
              <span>Distance: {{distance}}</span>
            </p>
          </div>
          <div class="m-o-products">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex align-items-start">
                  <img style="border-radius: 10px"
                       src="{{this.mainUrl.MAIN_URL_FILE + this.productImage + '/?X-TenantID=' + this.tenantValue}}"
                       alt=""
                       class="p-img">
                  <div class="o-info">
                    <h5>{{orderDetails?.orderItems[0].itemName}}</h5>
                    <p class="o-detail">{{orderDetails?.orderItems[0].quantity}}
                      x {{'CURRENCY.CURRENCY' | translate}} {{orderDetails?.orderItems[0].price}}</p>
                    <div class="destination">
                      <small>From</small>
                      <p>{{orderDetails?.orderItems[0].pickupAddress.street}}
                        {{orderDetails?.orderItems[0].pickupAddress.city}}
                      </p>
                    </div>
                    <p class="fee">
                      <img src="../../../../assets/images/icons/vehicle.png" alt=""> &nbsp;
                      <span>{{'CURRENCY.CURRENCY' | translate}} {{orderDetails?.orderItems[0].price}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right bid-value greybox">
            <h5>{{'BidValue'|translate}} : {{'CURRENCY.CURRENCY' | translate}} {{driverOffer}}</h5>
          </div>
        </div>

        <div class="text-center mt-5 mb-3 btn-box">
          <button class="btn btn-default redb" data-dismiss="modal">{{'Cancel'|translate}}</button> &nbsp;
          <button class="btn btn-default next" data-dismiss="modal" (click)="awardBid()">{{'Award'|translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<button #success class="btn-b b-approve" data-dismiss="modal" data-target="#success-modal" data-toggle="modal"
        hidden></button>

<!-- success -->
<div aria-hidden="true" class="modal fade npa-model" id="success-modal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered  margin-0 profile-edit" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <h3>{{'BidAwardedSuccessfully'|translate}}</h3>
          <a class="btn-b b-approve" data-dismiss="modal">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
