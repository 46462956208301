<div class="container">
  <div class="row pip-title">
    <div class="col-8">
      <h2>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.MY_FARMERS' | translate}}</h2>
    </div>
    <div class="col-12">
      <div class="separator">&nbsp;</div>
    </div>
  </div>
  <div class="item-container">

    <mat-form-field class="matsearch">
      <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
    </mat-form-field>

    <div style="width:100%;max-height: 500px ; height: auto; overflow-y: auto;">


      <table [dataSource]="dataSource" class="table" mat-table matSort style="width:100%">

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.FARMER' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            <div class="d-flex flex-row align-items-center mt-2 mb-2">
              <div style="width: 50px;">
                <img alt=""
                     src="{{ row.userLocationDTO?.imageUrl !==null? this.mainUrl.MAIN_URL_FILE + row.userLocationDTO.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar?d=mp'}}"
                     style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
              </div>
              <div class="rightdiv">
                <h4>{{ row.name }}</h4>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.LOCATION' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;width: 20%;">
            {{row.address}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Products">
          <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PRODUCTS' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            {{row.productCount}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Earnings">
          <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.EARNING' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
            {{'CURRENCY.CURRENCY' | translate}} {{row.totalEarnings | number: '2.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="added">
          <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
            {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.ADDED_DATE' | translate}}
          </th>
          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
            {{convertDate(row.created)}}
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="loadProducts(row.username)" *matRowDef="let row; columns: displayedColumns"
            data-target="#viewmodal" data-toggle="modal" mat-row></tr>
      </table>

      <mat-paginator #paginator1 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

  </div>
</div>

<!-- approve modal -->
<div aria-hidden="true" class="modal fade npa-model" id="viewmodal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content" style="width: auto!important;">
      <div class="modal-header blank-header">

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="inside-modal">
          <div class="">
            <div class="top-big">
              <div class="d-flex floatbox">
                <img alt=""
                     class="img"
                     src="{{farmerImageUrl}}"/>
                <div class="rights">
                  <h4>{{farmerName}}<br/>
                  </h4>
                  <p *ngIf="activeLang === 'en'">{{farmerLocationEN}}</p>
                  <p *ngIf="activeLang === 'si'">{{farmerLocationSI}}</p>
                  <p *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</p>
                </div>
              </div>
              <div class="text-right">
                <h5 style="font-size: 14px;color: #828282;">{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.ACCOUNT_NO' | translate}}
                  <br/>
                  <strong style="font-size: 18px;">{{bankAccount}}</strong></h5>

                <div class="sales">
                  <p><span>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.ORDERS' | translate}}
                    {{ordersCount}} &nbsp;<i class="fa fa-caret-up greentext"></i></span> &nbsp;&nbsp;
                    <span>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.SALES' | translate}}
                      {{salesCount}}
                      &nbsp;<i class="fa fa-caret-down orangetext"></i></span></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <mat-divider></mat-divider>
            <div class="pro-det">
              <div class="row" style="padding: 30px;">
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.TOTAL_EARNING' | translate}}</h6>
                      <h3 class="greentext" *ngIf="isTotalEarnings">{{'CURRENCY.CURRENCY' | translate}}
                        {{totalEarnings | number: '2.2-2'}}
                      </h3>
                      <h3 class="greentext" *ngIf="!isTotalEarnings">{{'CURRENCY.CURRENCY' | translate}}
                        00.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PENDING_PAYMENTS' | translate}}</h6>
                      <h3 class="greentext">{{'CURRENCY.CURRENCY' | translate}}
                        00.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.REFERRAL_EARNING' | translate}}
                        <span><strong></strong></span></h6>
                      <h3 class="bluetxt">{{'CURRENCY.CURRENCY' | translate}} 00.00</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tablefor">
            <p class="recentearn">{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.PRODUCT_WEEKS' | translate}}<span
              class="text-rights bluetxt"><a
              class=""
              data-dismiss="modal"
              data-target="#earnings"
              data-toggle="modal">{{'MY_ACCOUNT.PURCHASES.MY_FARMERS.EARNING' | translate}}<i
              aria-hidden="true" class="fa fa-long-arrow-right"></i></a></span></p>
            <div class="row">
              <div class="col-12">
                <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">

                  <mat-form-field class="matsearch">
                    <input (keyup)="applyFilter($event.target.value)" matInput
                           placeholder="{{'SEARCH_BAR' | translate}}">
                  </mat-form-field>

                  <mat-horizontal-stepper #stepper>
                    <mat-step [stepControl]="firstFormGroup">
                      <form [formGroup]="firstFormGroup" style="overflow-x: scroll!important;">
                        <ng-template matStepLabel>Spot Products</ng-template>

                        <table [dataSource]="dataSourceSpot" class="table" mat-table matSort style="width:100%;">

                          <ng-container matColumnDef="id">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_ID' | translate}}

                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                              <div class="d-flex flex-row align-items-center mt-2 mb-2">
                                <div style="width: 50px;">
                                  <img alt=""
                                       src="{{this.mainUrl.MAIN_URL_FILE + row.image + '/?X-TenantID=' + this.tenantValue}}"
                                       style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                                </div>
                                <div class="rightdiv">
                                  <h4>{{ row.name }}</h4>
                                  <p>{{ row.sku }}</p>
                                </div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="category">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.CATEGORY' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                              <ng-container *ngIf="activeLang == 'en'">{{row.category.description}}</ng-container>
                              <ng-container *ngIf="activeLang == 'si'">{{row.category.sinhala}}</ng-container>
                              <ng-container *ngIf="activeLang == 'ta'">{{row.category.tamil}}</ng-container>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="price">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRICE' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                       <span *ngIf="row.isSellInSmallerQty">
                     {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.spotStock| number:'2.2-2'}}
                      </span>
                              <span *ngIf="!row.isSellInSmallerQty">
                     {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.totalQty| number:'2.2-2'}}
                      </span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="bulkQty">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.BULK_QTY' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell
                                style="padding-left: 0;text-align: right">
                      <span *ngIf="row.isSellInSmallerQty">
                             {{row.spotStock}}{{row.unitType}}
                      </span>
                              <span *ngIf="!row.isSellInSmallerQty">
                              {{row.totalQty}}{{row.unitType}}
                      </span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="qty">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.AVAILABLE_QTY' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell
                                style="padding-left: 0;text-align: right">

                              <span style="color: red;font-weight: bold"
                                    *ngIf="row.stockAvailable === 0">SOLD OUT</span>
                              <span *ngIf="row.stockAvailable > 0"> {{row.stockAvailable}}{{row.unitType}}</span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="expiredDate">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.BEST_BUY_DATE' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <span *ngIf="row.type === 'SPOT'">
                      {{convertDate(row.spotExpiredDate)}}
                      </span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="adddate">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ADDED_DATE' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                              {{convertDate(row.createdDate)}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="status">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.STATUS' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <span
                              *ngIf="row.status === 'Active'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACTIVE' | translate}}</span>
                              <span
                                *ngIf="row.status === 'Blocked'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BLOCKED' | translate}} </span>
                              <span
                                *ngIf="row.status === 'Reject'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.REJECTED' | translate}} </span>
                              <span
                                *ngIf="row.status === 'Pending'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.PENDING' | translate}}</span>
                              <span
                                *ngIf="row.status === 'Disable'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DISABLED' | translate}}</span>
                              <span *ngIf="row.status === 'Expired'">{{'EXPIRED' | translate}}</span>

                            </td>

                          </ng-container>

                          <ng-container matColumnDef="action">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ACTION' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                              <button mat-raised-button (click)="disableProduct(row.sku)" class="actionBtn1"
                                      *ngIf="row.status === 'Active'">DISABLE
                              </button>
                              <button mat-raised-button (click)="enableProduct(row.sku)" class="actionBtn2"
                                      *ngIf="row.status === 'Disable'">ENABLE
                              </button>
                              <button mat-raised-button disabled style="color: black;background: transparent"
                                      *ngIf="row.status !== 'Active' && row.status !== 'Disable'">N/A
                              </button>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="action1">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            </th>
                            <td *matCellDef="let row" data-target="#editProduct" class="center-table tbl" mat-cell
                                style="padding-left: 0;">
                              <button mat-raised-button *ngIf="row.status !== 'Blocked'" data-dismiss="modal"
                                      (click)="loadDataForUpdate(editProduct, row.allItems)" class="actionBtn editb">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                              </button>
                            </td>
                          </ng-container>


                          <tr *matHeaderRowDef="displayedColumnsSpot" mat-header-row></tr>
                          <tr *matRowDef="let row; columns: displayedColumnsSpot"
                              data-toggle="modal" mat-row></tr>
                        </table>

                        <mat-paginator #paginatorSpot [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                      </form>
                    </mat-step>
                    <mat-step [stepControl]="secondFormGroup">
                      <form [formGroup]="secondFormGroup" style="overflow-x: scroll!important;">
                        <ng-template matStepLabel>Auction Products</ng-template>

                        <table [dataSource]="dataSourceAuction" class="table" mat-table matSort style="width:100%">

                          <!-- Position Column -->
                          <ng-container matColumnDef="id">
                            <th *matHeaderCellDef mat-header-cell> Product ID</th>
                            <td *matCellDef="let element" mat-cell> {{element.sku}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="name">
                            <th *matHeaderCellDef mat-header-cell> Product Name</th>
                            <td *matCellDef="let element" mat-cell>
                              <div>
                                <img alt=""
                                     src="{{this.mainUrl.MAIN_URL_FILE + element.image + '/?X-TenantID=' + this.tenantValue}}"
                                     style="width: 40px; height: 40px; border-radius: 8px; object-fit: cover;"/><br>
                                {{element.name}}
                              </div>
                            </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="created">
                            <th *matHeaderCellDef mat-header-cell> Listed Date</th>
                            <td *matCellDef="let element" mat-cell>{{convertDate(element.created)}}</td>
                          </ng-container>
                          <!--                   Symbol Column -->
                          <ng-container matColumnDef="location">
                            <th *matHeaderCellDef mat-header-cell> Location</th>
                            <td *matCellDef="let element" mat-cell> {{element.location}} </td>
                          </ng-container>
                          <!-- Symbol Column -->
                          <ng-container matColumnDef="startPrice">
                            <th *matHeaderCellDef mat-header-cell> Start Price</th>
                            <td *matCellDef="let element"
                                mat-cell> {{'CURRENCY.CURRENCY' | translate}}{{element.startPrice}} </td>
                          </ng-container>

                          <ng-container matColumnDef="stockAvailable">
                            <th *matHeaderCellDef mat-header-cell>Stock Available</th>
                            <td *matCellDef="let element" mat-cell>
                              {{element.stockAvailable}}{{element.unitType}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="endDate">
                            <th *matHeaderCellDef mat-header-cell>End Date</th>
                            <td *matCellDef="let element" mat-cell> {{convertDate(element.endDate)}}</td>
                          </ng-container>

                          <ng-container matColumnDef="status">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.STATUS' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <span
                              *ngIf="row.status === 'Active'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACTIVE' | translate}}</span>
                              <span
                                *ngIf="row.status === 'Blocked'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BLOCKED' | translate}} </span>
                              <span
                                *ngIf="row.status === 'Reject'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.REJECTED' | translate}} </span>
                              <span
                                *ngIf="row.status === 'Pending'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.PENDING' | translate}}</span>
                              <span
                                *ngIf="row.status === 'Disable'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DISABLED' | translate}}</span>
                              <span *ngIf="row.status === 'Expired'">{{'EXPIRED' | translate}}</span>
                              <span *ngIf="row.status === 'Awarded'">{{'AWARDED' | translate}}</span>
                              <span *ngIf="row.status === 'Ended'">Ended</span>
                            </td>

                          </ng-container>

                          <ng-container matColumnDef="action">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                              {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ACTION' | translate}}
                            </th>
                            <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                              <button mat-raised-button (click)="disableProductAuction(row.sku)" class="actionBtn1"
                                      *ngIf="row.status === 'Active'">{{'DISABLE'|translate}}
                              </button>
                              <button mat-raised-button (click)="enableProductAuction(row.sku)" class="actionBtn2"
                                      *ngIf="row.status === 'Disable'">{{'ENABLE'|translate}}
                              </button>
                              <button mat-raised-button disabled style="color: black;background: transparent"
                                      *ngIf="row.status !== 'Active' && row.status !== 'Disable'">N/A
                              </button>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="action1">
                            <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            </th>
                            <td *matCellDef="let row" data-target="#editAuctionProduct" class="center-table tbl"
                                mat-cell
                                style="padding-left: 0;">
                              <button mat-raised-button *ngIf="row.status !== 'Blocked'" data-dismiss="modal"
                                      (click)="loadDataForAuctionUpdate(editAuctionProduct, row.allItems)"
                                      class="actionBtn editb">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                              </button>
                            </td>
                          </ng-container>


                          <tr *matHeaderRowDef="displayedColumnsAuction" mat-header-row></tr>
                          <tr *matRowDef="let row; columns: displayedColumnsAuction"
                              data-toggle="modal" mat-row></tr>
                        </table>

                        <mat-paginator #paginatorAuction [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                      </form>
                    </mat-step>
                  </mat-horizontal-stepper>

                </div>
              </div>
            </div>

          </div>
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">
              {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.CLOSE' | translate}}</a>
            <a (click)="passingFarmerUsername()" aria-label="Next" class="btn next-btn-half" data-dismiss="modal"
               data-target="#editspotProduct"
               data-toggle="modal"><i class="fa fa-server"></i>
              {{'MY_ACCOUNT.PURCHASES.MY_FARMERS.SELL_A_PRODUCT' | translate}}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<!-- order review -->
<ng-template #editProduct let-modal>

  <app-farmer-edit-spot-product [product]="product"></app-farmer-edit-spot-product>

</ng-template>

<!-- order review -->
<ng-template #editAuctionProduct let-modal>

  <app-farmer-edit-auction-product [productAuction]="productAuction"></app-farmer-edit-auction-product>

</ng-template>


<!-- earnings modal-->
<div aria-hidden="true" class="modal fade npa-model" id="earnings" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header blank-header">

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="inside-modal">
          <div class="">
            <div class="top-big">
              <div class="d-flex floatbox">
                <img alt=""
                     class="img"
                     src="{{farmerImageUrl}}"/>
                <div class="rights">
                  <h4>{{farmerName}}<br/>
                  </h4>
                  <p *ngIf="activeLang === 'en'">{{farmerLocationEN}}</p>
                  <p *ngIf="activeLang === 'si'">{{farmerLocationSI}}</p>
                  <p *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</p>
                </div>
              </div>
              <div class="text-right">
                <h5 style="font-size: 14px;color: #828282;">Bank account No <br/>
                  <strong style="font-size: 18px;">{{bankAccount}}</strong></h5>

                <div class="sales">
                  <p><span>Orders: {{ordersCount}} &nbsp;<i class="fa fa-caret-up greentext"></i></span> &nbsp;&nbsp;
                    <span>Sales: {{salesCount}}
                      &nbsp;<i class="fa fa-caret-down orangetext"></i></span></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <mat-divider></mat-divider>
            <div class="pro-det">
              <div class="row" style="padding: 30px;">
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>Total earnings </h6>
                      <h3 class="greentext">{{'CURRENCY.CURRENCY' | translate}} 5,485.00</h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>Pending payments</h6>
                      <h3 class="orangetext">{{'CURRENCY.CURRENCY' | translate}} 3,400.00</h3>
                    </div>
                  </div>
                </div>
                <div class="col-4 cols">
                  <div class="epicbox">
                    <div>
                      <h6>Referral Earnings <span><strong>40%</strong></span></h6>
                      <h3 class="bluetxt">{{'CURRENCY.CURRENCY' | translate}} 1,150.00</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tablefor">
            <p class="recentearn">Earnings for
              last 3 weeks <span class="text-rights bluetxt"><a data-dismiss="modal" data-target="#viewmodal"
                                                                data-toggle="modal">All Products <i
                aria-hidden="true"
                class="fa fa-long-arrow-right"></i></a></span></p>
            <table [dataSource]="dataSourceEarnings" class="table admin-table" mat-table matSort style="width:100%">

              <ng-container matColumnDef="tr-ID">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Transaction ID
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  HVF1234567
                </td>
              </ng-container>
              <ng-container matColumnDef="proid">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Product ID
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  1009392
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Product Name
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  Watermelon
                </td>
              </ng-container>
              <ng-container matColumnDef="buyer">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Buyer
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  <span class="bluetxt">Kasun Sameera</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="total">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Total Amount
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  <span class="greentext">{{'CURRENCY.CURRENCY' | translate}} 3400.00</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="referral">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Referal Earnings
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  <span class="bluetxt">{{'CURRENCY.CURRENCY' | translate}} 800.00 (40%)</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Date">
                <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                  Date
                </th>
                <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                  10 / 11 / 2019
                </td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumnsEarnings" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumnsEarnings" data-dismiss="modal"
                  data-target="#transaction-modal" data-toggle="modal"
                  mat-row></tr>
            </table>

            <mat-paginator #paginator3 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

          </div>
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">Close</a>
            <a (click)="passingFarmerUsername()" aria-label="Next" class="btn next-btn-half" data-dismiss="modal"
               data-target="#editspotProduct"
               data-toggle="modal"><i class="fa fa-server"></i> &nbsp;Sell a product</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- product modal -->
<div #editProduct aria-hidden="true" class="modal fade npa-model" id="productview" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="w-100 d-flex justify-content-end">
        </div>
        <app-table-product-view></app-table-product-view>
      </div>
    </div>
  </div>
</div>


<!-- transaction modal -->
<div aria-hidden="true" class="modal fade npa-model" id="transaction-modal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="inside-modal">
          <div class="topprofile">
            <p style="padding: 25px 0; font-weight: bold; font-size: 24px; color: #4F4F4F;">Transaction #1234567890
              <span
                class="text-rights" style="font-size: 14px;"> Referal Earnings (40%) <br/>
                <span style="font-weight: bold;font-size: 24px; color: #2F80ED;">{{'CURRENCY.CURRENCY' | translate}}
                  850.00</span>
              </span>
            </p>
            <div class="bordersides row">
              <div class="imgs-big d-flex col-6">
                <div><img src="../../../assets/images/temp/banana.png"/></div>
                <div class="rightdiv">
                  <h4>Banana</h4>
                  <p>#567890</p>
                </div>
              </div>
              <div class="col-6 listview">
                <ul>
                  <li><span class="boldtxt">Order Approved: </span> <span class="greentext"><i class="fa fa-check"></i>
                      Approved</span></li>
                  <li><span class="boldtxt">Goods Dispatched:</span> <span class="greentext"><i class="fa fa-check"></i>
                      Dispatched</span></li>
                  <li><span class="boldtxt">Goods Recived:</span> <span class="greentext"><i class="fa fa-check"></i>
                      Recieved</span></li>
                </ul>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="bottom-profile row">
            <div class="col-6 listview">
              <p><strong>Order Details</strong></p>
              <ul>
                <li><span class="boldtxt">Transaction ID : </span> <span> #1234567890</span></li>
                <li><span class="boldtxt">Item Price:</span> <span>{{'CURRENCY.CURRENCY' | translate}} 300.00</span>
                </li>
                <li><span class="boldtxt">Quantity:</span> <span>12</span></li>
                <li><span class="boldtxt">Amount:</span> <span>{{'CURRENCY.CURRENCY' | translate}} 3,000.00</span></li>
                <li><span class="boldtxt">Buyer:</span> <span>Jeewan Sachintha</span></li>
                <li><span class="boldtxt">Purchased Date:</span> <span>20 / 07 / 2020</span></li>
              </ul>
            </div>
            <div class="col-6 listview">
              <p><strong>Transaction Details </strong></p>
              <ul>
                <li><span class="boldtxt">Billing Cycle : </span> <span> 15th Billing Cycle </span></li>
                <li><span class="boldtxt">Bank Account No :</span> <span>23428374992</span></li>
                <li><span class="boldtxt">Name of the Bank :</span> <span>Commercial - Batharamulla</span></li>
                <li><span class="boldtxt">Transaction Date :</span> <span>N/A</span></li>
                <li><span class="boldtxt">Payee:</span> <span class="bluetxt">Helaviru</span></li>
                <li><span class="boldtxt">Settlement Status:</span> <span class="orangetext">Pending</span></li>
                <li><span class="boldtxt">Settlement Date :</span> <span>15 / 08 / 2020</span></li>
              </ul>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="text-center" style="padding-top: 20px;">
            <button class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" class="modal fade npa-model" id="editspotProduct" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <app-farmer-sell-a-product [isAgentFarmerLogin]="true"
                                   [agentFarmerUsername]="farmerUsername"></app-farmer-sell-a-product>
      </div>
    </div>
  </div>
</div>

