<div class="reviews">
    <div class="details">
        <div class="produt-titile">
            <h3 class="green mb-0 font-weight-bold">Bulk order for special event</h3>
            <p class="mb-0">Fixed-price : Budget LKR 30,000.00</p>
        </div>
        <div class="user">
            <img src="../../../../../assets/images/temp/profile.png" alt="">
            <div class="d-flex align-items-start">
                <h6>Jeewan Rathnayaka <br>
                    <small>Battaramulla</small>
                </h6>
                <!-- <i class="material-icons">thumb_up</i>
                <i class="material-icons">favorite</i> -->
                <i class="material-icons green">visibility</i>
            </div>
        </div>
    </div>
    <div class="descriptions">
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus dignissim tortor arcu sollicitudin ipsum
            nibh pulvinar diam donec. Vitae sed faucibus mattis lectus amet a sit nunc, ut. Scelerisque leo elementum
            purus, lectus id ut ut massa lacus. Enim, ac in nisl nisl volutpat lacus. Arcu morbi felis cras nibh id
            neque.
        </p>
        <h5 class="font-weight-bold">Item list</h5>
        <div class="btns">
            <button class="btn btn-default next mr-1 mb-2">Potatos X 1KG</button>
            <button class="btn btn-default next mr-1 mb-2">Tomatos X 1KG</button>
            <button class="btn btn-default next mr-1 mb-2">Onions X 2KG</button>
            <button class="btn btn-default next mr-1 mb-2">Watermellon X 1KG</button>
        </div>
        <hr>
        <!-- <p class="d-flex align-items-center font-weight-bold paytext">
            <i class="material-icons gray">monetization_on</i> &nbsp;
            LKR 30,000.00 - Paid
        </p> -->
        <div class="form-group pt-1 pb-2">
            <label for="reviewReply">Include a note to buyer</label>
            <textarea class="form-control" id="reviewReply" rows="5" placeholder="Your note / message"></textarea>
        </div>
        <button class="btn btn-default next-outline w-100">Send</button>
    </div>
    <hr>
    
    <div class="review-actions">
        <div class="text-left">
            <button class="btn btn-default dark"  data-dismiss="modal">Close</button>
        </div>
        <div class="text-right">
            <button class="btn btn-default next">Ready to Collect</button>
        </div>
    </div>
</div>