<div class="sellproduct-steps" *ngIf="selectForwardNext==0">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.SELECT_PRO_CATEGORY' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="categoryForm" (ngSubmit)="submitCategoryForm()">
      <div class="form-group col-12">
        <label for="selectCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.MAIN_CATEGORY' | translate}}
          <span class="validationStar">*</span></label>
        <select [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.category.errors }"
                class="form-control" id="selectCategory" (change)="selectCategory($event)"
                [(ngModel)]="selectCategoryId" formControlName="category">
          <option disabled [value]="none">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.SELECT_YOUR_CATEGORY' | translate}}</option>
          <option *ngFor="let category of categoryList"
                  [value]="category.code">{{category.commonIndex.description}}</option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.category.errors" class="inputError">
          <div *ngIf="f1.category.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="selectSubCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.SUB_CATEGORY' | translate}}
          <span class="validationStar">*</span></label>
        <select [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.subCategory.errors }"
                class="form-control" id="selectSubCategory" (change)="selectSubCategory($event)"
                [(ngModel)]="selectSubCategoryId" formControlName="subCategory">
          <option disabled [value]="none">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.SELECT_SUB_CATEGORY' | translate}}</option>
          <option *ngFor="let subCategory of subCategoryList"
                  [value]="subCategory.code">{{subCategory.commonIndex.description}}</option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.subCategory.errors" class="inputError">
          <div *ngIf="f1.subCategory.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.SUB_CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label
          for="productAvailableFromDate">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_WILL_AVAILABLE' | translate}}
          <span class="validationStar">*</span></label>
        <input [min]="todayDate"
               [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.productAvailableFromDate.errors }"
               [(ngModel)]="productAvailableFromDate" formControlName="productAvailableFromDate" type="date"
               class="form-control dateinput" id="productAvailableFromDate" onkeydown="return false">
        <div *ngIf="submittedCategoryForm && f1.productAvailableFromDate.errors" class="inputError">
          <div *ngIf="f1.productAvailableFromDate.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.FIELD_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" (click)="selectForwardNext=0"
           aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a class="btn next-btn-half" type="submit" (click)="nextListProductDetailsPage()"
           aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>
    </form>
  </div>
</div>

<div class="sellproduct-steps" *ngIf="selectForwardNext==1">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.LIST_PRODUCT_DETAILS' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="productDetailsForm" (ngSubmit)="productDetailsFormSubmit()">
      <div class="form-group col-12">
        <label for="title">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.TITLE' | translate}}
          <span class="validationStar">*</span></label>
        <input [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.title.errors }"
               [(ngModel)]="title" formControlName="title" type="text" class="form-control" id="title"
               placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_NAME' | translate}}">
        <div *ngIf="submittedProductDetailsForm && f2.title.errors" class="inputError">
          <div *ngIf="f2.title.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.TITLE_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="description">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.DESCRIPTION' | translate}}
          <span class="validationStar">*</span></label>
        <textarea [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.buyDate.errors }"
                  [(ngModel)]="description" formControlName="description" class="form-control" id="description"
                  rows="3"
                  placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.DESCRIPTION' | translate}}"></textarea>
        <div *ngIf="submittedProductDetailsForm && f2.description.errors" class="inputError">
          <div *ngIf="f2.description.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.DESCRIPTION_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-4">
          <label for="buyDate">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.BEST_BUY_DATE' | translate}}
            <span class="validationStar">*</span></label>
          <input [min]="productAvailableFromDate" [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.buyDate.errors }"
                 [(ngModel)]="buyDate" formControlName="buyDate" type="date"
                 class="form-control" id="buyDate" onkeydown="return false">
          <div *ngIf="submittedProductDetailsForm && f2.buyDate.errors" class="inputError">
            <div *ngIf="f2.buyDate.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.BEST_BUY_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-4" style="margin-left: 44px; !important;">
          <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.AVAILABLE_QUANTITY' | translate}}
            <span class="validationStar">*</span></label>
          <input style="width: 200px;" (focusout)="manageAvailableTotalQty(availableTotalQty)"
                 [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.availableTotalQty.errors }"
                 [(ngModel)]="availableTotalQty" formControlName="availableTotalQty" type="number"
                 class="form-control smallinput" id="qty" min="0" value="0"
          placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.AVAILABLE_QUANTITY' | translate}}">
          <div *ngIf="submittedProductDetailsForm && f2.availableTotalQty.errors" class="inputError">
            <div *ngIf="f2.availableTotalQty.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.AVAILABLE_TOTAL_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.UNIT_TYPE' | translate}}
            <span class="validationStar">*</span></label>
          <select style="width: 191px;" [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.unitType.errors }"
                  (change)="selectUnitType($event)"
                  [(ngModel)]="unitType" formControlName="unitType" class="form-control smallselect" id="unitType">
            <option disabled [value]="none">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.UNIT_TYPE' | translate}}
            </option>
            <option *ngFor="let unitType of unitTypeList" [value]="unitType.id">{{unitType.unit}}</option>
          </select>
          <div *ngIf="submittedProductDetailsForm && f2.unitType.errors" class="inputError">
            <div *ngIf="f2.unitType.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.UNIT_TYPE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" (click)="selectForwardNext=0"
           aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a class="btn next-btn-half" type="submit" (click)="productDetailsPageNext()"
           aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>
</div>

<!-- <div class="sellproduct-steps" *ngIf="selectForwardNext==2">
    <h4>List Your Product Details</h4>

    <div class="anp-select-op categoryform row">
        <form action="">
            <div class="form-group col-12 opacitylow active" [ngClass]="{'active':isChecked==false}">
                <label for="exampleInputEmail1">Bulk Selling Price</label>
                <input type="text" class="form-control smallinput" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="0.00">
                    <span>LKR</span>
            </div>

            <div class="form-check">
                <input class="form-check-input checkone" type="checkbox" value="" id="defaultCheck1" (change)="isChecked =! isChecked" [checked] = false>
                <label class="form-check-label" for="defaultCheck1">
                    Sell in Smaller Quantities
                </label>
            </div>
            <div class="opacitylow" [ngClass]="{'active':isChecked==true}">
                <div class="form-group col-12">
                    <label for="province">Quantity</label>
                    <input type="text" class="form-control smallinput" id="province" aria-describedby="emailHelp"
                    placeholder=""><select class="form-control smallselect" id="exampleFormControlSelect1">
                        <option>Kg</option>
                        <option>g</option>
                        <option>ton</option>
                    </select>
                </div>
                <div class="form-group col-12">
                    <label for="province">Selling Unit Price</label>
                    <input type="text" class="form-control smallinput" id="province" aria-describedby="emailHelp"
                    placeholder=""> <span>LKR</span>
                </div>
            </div>
            <div class="form-group btn-sections">
                <a class="btn cancel-btn-half" data-dismiss="modal"
                    aria-label="Close">Cancel</a>
                <a class="btn next-btn-half" data-dismiss="modal" (click)="selectForwardNext=selectForwardNext+1"
                    aria-label="Next">Next</a>
            </div>

        </form>
    </div>



</div> -->

<div class="sellproduct-steps" *ngIf="selectForwardNext==2">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_PIK_L_ADDRESS' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="pickUpAddressForm">
      <div class="form-group col-12">
        <label for="address">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.ADDRESS' | translate}}
<!--          <span class="validationStar">*</span>-->
        </label>
        <input [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.postalCode.errors }"
               [(ngModel)]="address" formControlName="address" type="text" class="form-control" id="address"
        placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.ADDRESS' | translate}}">
        <div *ngIf="submittedPicUpAddressForm && f3.address.errors" class="inputError">
          <div *ngIf="f3.address.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.ADDRESS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="pro">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PROVINCE' | translate}}
            <span class="validationStar">*</span></label>
          <select (change)="selectProvince($event)"
                  [(ngModel)]="selectProvinceId" formControlName="province"
                  class="form-control province" id="pro">
            <option disabled [value]="none">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PROVINCE' | translate}}</option>
            <option *ngFor="let province of provinceList" [value]="province.id">
              <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.province.errors" class="inputError">
            <div *ngIf="f3.province.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.PROVINCE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="dis">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20" ></mat-spinner>
          <select (change)="selectDistrict($event)"
                  [(ngModel)]="selectDistrictId" formControlName="district"
                  class="form-control province" id="dis">
            <option disabled [value]="none">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_DISTRICT' | translate}}</option>
            <option *ngFor="let district of districtList" [value]="district.id">
              <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.district.errors" class="inputError">
            <div *ngIf="f3.district.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="div">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DIVISIONAL_SEC' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDiv" diameter="20" ></mat-spinner>
          <select (change)="selectDivisionalSecretariat($event)"
                  [(ngModel)]="selectDivisionalSecretariatId" formControlName="divisionalSecretariat"
                  class="form-control province" id="div">
            <option disabled [value]="none">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_DIVISIONAL_SEC' | translate}}</option>
            <option *ngFor="let divisional of divisionalSecretariatList"
                    [value]="divisional.id">
              <span *ngIf="activeLang === 'en'">{{divisional.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{divisional.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{divisional.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.divisionalSecretariat.errors" class="inputError">
            <div *ngIf="f3.divisionalSecretariat.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DIVISIONAL_SEC_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="gra">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.GRAMA_AREA' | translate}}
            <span class="validationStar">*</span></label>
<!--          <mat-spinner *ngIf="loadingGn" diameter="20" ></mat-spinner>-->
          <select (change)="selectGramaNiladhariArea($event)"
                  [(ngModel)]="selectGramaNiladhariAreaId" formControlName="gramaNiladhariArea"
                  class="form-control province" id="gra">
            <option disabled [value]="none">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_GRAMA_AREA' | translate}}</option>
            <option *ngFor="let grama of gramaNiladhariAreaList" [value]="grama.id">
              <span *ngIf="activeLang === 'en'">{{grama.nameEN}}</span>
<!--              <span *ngIf="activeLang === 'si'">{{grama.nameSI}}</span>-->
<!--              <span *ngIf="activeLang === 'ta'">{{grama.nameTA}}</span>-->
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.gramaNiladhariArea.errors" class="inputError">
            <div *ngIf="f3.gramaNiladhariArea.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.GRAMA_AREA_REQUIRED' | translate}}
            </div>
          </div>
        </div>

      </div>
      <div class="form-group col-6">
        <label for="postalCode">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.POSTAL_CODE' | translate}}<span
          class="validationStar">*</span></label>
        <input [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.postalCode.errors }"
               [(ngModel)]="postalCode" formControlName="postalCode" type="text"
               class="form-control" id="postalCode"
               placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.POSTAL_CODE' | translate}}">
        <div *ngIf="submittedPicUpAddressForm && f3.postalCode.errors" class="inputError">
          <div *ngIf="f3.postalCode.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" (click)="selectForwardNext=1"
           aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a class="btn next-btn-half" (click)="pickUpLocationAddressNextPage()"
           aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<div class="sellproduct-steps" *ngIf="selectForwardNext==3">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PAYMENT_PLAN' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="paymentPlanOrderForm" (ngSubmit)="submitPaymentPlanOrder()">
      <div class="form-group col-12">

        <label>
          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.TOTAL_AMOUNT' | translate}}
          <span class="validationStar">*</span>
        </label>
        <input [ngClass]="{ 'is-invalid': submittedPaymentPlanForm && f4.contractTotalAmount.errors }"
               [(ngModel)]="contractTotalAmount" formControlName="contractTotalAmount" type="text"
               class="form-control smallinput"  value="0" min="0"
               placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.TOTAL_AMOUNT' | translate}}">
<!--        <span>{{currencyCode}}</span>-->
        <span>{{'CURRENCY.CURRENCY' | translate}}</span>
        <div *ngIf="submittedPaymentPlanForm && f4.contractTotalAmount.errors" class="inputError">
          <div *ngIf="f4.contractTotalAmount.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.TOTAL_AMOUNT_REQUIRED' | translate}}
          </div>
          <div *ngIf="f4.contractTotalAmount.errors.pattern">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.VALID_TOTAL_AMOUNT_REQUIRED' | translate}}
          </div>
        </div>
      </div>

      <div class="form-group col-12">
        <label>
          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.INITIAL_PAYMENT' | translate}}
          <span class="validationStar">*</span>
        </label>
        <input  [ngClass]="{ 'is-invalid': submittedPaymentPlanForm && f4.contractInitialPayment.errors }"
                [(ngModel)]="contractInitialPayment" formControlName="contractInitialPayment"
               type="number" class="form-control smallinput"  value="0" min="0"
                placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.INITIAL_PAYMENT' | translate}}">
        <span>%</span>
        <div *ngIf="submittedPaymentPlanForm && f4.contractInitialPayment.errors" class="inputError">
          <div *ngIf="f4.contractInitialPayment.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.INITIAL_PAYMENT_REQUIRED' | translate}}
          </div>
        </div>
      </div>

      <div class="" style="height: 200px; overflow-y: auto;" [formGroup]="addInstallment">
        <div class="add-installment" (click)="onAddRow()" *ngIf="addInstallment.get('rows')"><a>
          <em class="fa fa-plus"></em>
          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.ADD_NEW_INSTALLMENT' | translate}}</a>
        </div>

        <div class="form-group col-12">
          <table style="width: 100%">
            <thead>
            <tr *ngIf="rows.length > 0">
              <th style="text-align: center;font-size: 15px;">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.INSTALLMENT' | translate}}(%)</th>
              <th style="text-align: center;font-size: 15px;">{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PAYMENT_DATE' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of addInstallment.get('rows')?.controls;let index = index;">
              <td>
                <input type="number" min="0" value="1" class="form-control" [formControl]="row.get('installment')"
                placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.INSTALLMENT' | translate}}">
              </td>
              <td>
                <input [min]="todayDate" [value]="todayDate" type="date" class="form-control dateinput" [formControl]="row.get('paymentDate')">
              </td>
              <td style="text-align: center;">
                <button type="button" (click)="onRemoveRow(index)" class="btn btn-danger"><em class="fa fa-trash"></em>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="form-group col-12">
        <label>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.CLOSING_PAYMENT' | translate}}
          <span class="validationStar">*</span></label>
        <input [ngClass]="{ 'is-invalid': submittedPaymentPlanForm && f4.contractClosingPayment.errors }"
               [(ngModel)]="contractClosingPayment" formControlName="contractClosingPayment" type="number"
               class="form-control smallinput"  value="0" min="0"
               placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.CLOSING_PAYMENT' | translate}}">
        <span>%</span>
        <div *ngIf="submittedPaymentPlanForm && f4.contractClosingPayment.errors" class="inputError">
          <div *ngIf="f4.contractClosingPayment.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.VALIDATE.CLOSING_PAYMENT_REQUIRED' | translate}}
          </div>
        </div>
      </div>

      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" (click)="selectForwardNext=selectForwardNext-1">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}
        </a>
        <a class="btn next-btn-half" type="submit"  (click)="nextDeliveryPage()"
           aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>
    </form>
  </div>


</div>

<!--<div class="sellproduct-steps" *ngIf="selectForwardNext==4">-->
<!--  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.DELIVERY_OPTION' | translate}}</h4>-->

<!--  <div class="anp-select-op categoryform row">-->
<!--    <div class="messgnetext">-->
<!--      <p>-->
<!--        {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.USE_RIYAVIRU_TRANSPORT' | translate}}<span-->
<!--        class="validationStar">*</span>-->
<!--      </p>-->
<!--      <div class="form-check form-check-inline">-->
<!--        <input class="form-check-input" type="radio" name="exampleRadios" id="yesBtn" value="option1"-->
<!--               [checked]="isEnableRiyaviruTransportation" (click)="riyaviruTransportationServiceEnable(true)">-->
<!--        <label class="form-check-label" for="yesBtn">-->
<!--          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.YES' | translate}}-->
<!--        </label>-->
<!--      </div>-->
<!--      <div class="form-check form-check-inline">-->
<!--        <input class="form-check-input" type="radio" name="exampleRadios" id="noBtn" value="option2"-->
<!--               [checked]="!isEnableRiyaviruTransportation" (click)="riyaviruTransportationServiceEnable(false)">-->
<!--        <label class="form-check-label" for="noBtn">-->
<!--          {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.NO' | translate}}-->
<!--        </label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="form-group btn-sections">-->
<!--      <a class="btn cancel-btn-half" (click)="selectForwardNext=3"-->
<!--         aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>-->
<!--      <a class="btn next-btn-half" data-dismiss="modal" (click)="selectForwardNext=selectForwardNext+1"-->
<!--         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="sellproduct-steps" *ngIf="selectForwardNext==4">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_PHOTOS' | translate}}
    <span class="validationStar">*</span></h4>
  <p style="color: #6B6C6F; padding: 0 15px; margin: 0;">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.UPLOAD_HERE' | translate}}</p>
  <p style="
    color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE' | translate}}5MB
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE_ALLOWED' | translate}}</p>
  <div class="anp-select-op categoryform row">
    <div class="img-upload">
      <div class="uploader">
        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" accept="image/png,image/jpeg,image/jpg">
          <ngx-dropzone-label>
            <div class="uploaderbox">
              <em class="icon fa fa-camera"></em>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                                      [removable]="true" (removed)="onRemove(f)">
          </ngx-dropzone-image-preview>
          <div class="imgadd"><em class="fa fa-plus"></em></div>
        </ngx-dropzone>
      </div>
      <p>
        {{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_IDENTIFICATION' | translate}}</p>
      <ol>
        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_IDENTIFICATION_1' | translate}}</li>

        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_IDENTIFICATION_2' | translate}}</li>

        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_IDENTIFICATION_3' | translate}}</li>
      </ol>
    </div>

    <div class="form-group btn-sections">
      <a class="btn cancel-btn-half" (click)="selectForwardNext=3"
         aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
      <a class="btn next-btn-half" (click)="saveProduct()"
         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
    </div>
  </div>
</div>

<div class="sellproduct-steps" *ngIf="selectForwardNext==5">

  <div class="anp-select-op categoryform row">
    <div class="successmsg">
      <em class="fa fa-check"></em>
      <h3>{{'MY_ACCOUNT.SELL_A_PRODUCT.FORWARD_CONTRACT.PRODUCT_SUCCESS_MSG' | translate}}</h3><br><br>
      <a class="btn next-btn-close" data-dismiss="modal"
         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.CLOSE' | translate}}</a>
    </div>
  </div>
</div>

