import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InteractionService} from '../service/interaction.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  selectSignUpOptions = 0;
  tenantValue: any;

  temp = false;
  isAgentFarmer: boolean = false;

  constructor(private _translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private interactionService: InteractionService) {
    // Add languages
    this.temp = true;
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    if (sessionStorage.getItem('LANG') !== null) {

      this._translateService.addLangs([sessionStorage.getItem('LANG')

      ]);
      // Set the default language
      this._translateService.setDefaultLang(sessionStorage.getItem('LANG')
      );
    } else {
      sessionStorage.setItem('LANG', 'en');

      this._translateService.addLangs([sessionStorage.getItem('LANG')]);
      // Set the default language
      this._translateService.setDefaultLang(sessionStorage.getItem('LANG')
      );
    }

  }

  ngOnInit() {
    this.route.queryParams.subscribe(parms => {
      if (parms['company']) {
        this.selectSignUpOptions = 10;
      } else if (parms['agentFarmer']) {
        this.isAgentFarmer = parms['agentFarmer'];
        if (parms['agentRef'] !== undefined) {
          localStorage.setItem('LEAD_CODE', parms['agentRef']);
        }
        if (parms['shopRef'] !== undefined) {
          localStorage.setItem('ECONOMIC_SHOP_CODE', parms['shopRef']);
        }

        this.selectSignUpOptions = 1;
      } else {
        this.selectSignUpOptions = 0;
      }

    });

    this.interactionService.redirect.subscribe(redirect => {
      if (redirect && this.temp) {
        let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
        this.router.navigate(['/home/' + TENANT_VALUE]);
      }
    });
    this.interactionService.updateSignUpForm$.subscribe(result => {
      if (result) {
        this.selectSignUpOptions = 0;
      }
    });

    this.interactionService.updateSignUpCompanyForm$.subscribe(result => {
      if (result) {
        this.selectSignUpOptions = 10;
      }
    });
  }

}
