import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { MainUrlService } from './main-url.service';
import {Observable} from 'rxjs';

const UNIT_TYPE = '/units';
const SETTING = '/settings';

@Injectable({
    providedIn: 'root'
})
export class UnitTypeService {

    constructor(private http: HttpClient, private  mainUrl: MainUrlService,
    ) {
    }

    createUnitType(body): Observable<any> {
        return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + UNIT_TYPE + '/add', body);
    }

    fetchAll(): Observable<any> {
        return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + UNIT_TYPE + '/all');
    }

    updateUnitType(body): Observable<any> {
        return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + UNIT_TYPE + '/edit', body);
    }

    deleteUnitType(unitId): Observable<any> {
        return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + UNIT_TYPE + '/delete/' + unitId, '');
    }

    fetchAllSettings(): Observable<any> {
        return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + SETTING + '/fetchAll');
    }

    settingUpdate(body):Observable<any> {
        return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + SETTING + '/updateAll', body);
    }
}
