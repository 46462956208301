<div>

  <div style="padding-top: 15vh;
    text-align: center;
    font-size: 50px;
    font-family: 'Roboto', sans-serif;" class="container">
    {{MAINTENANCE_MODE_DESCRIPTION}}
    <br>
    <p style="font-size: 18px;">
      We apologize for any inconveniences caused. <br> We've almost done.
    </p>

    <img src="../../assets/mode.jpg" style="height: 40vh;" alt="">
    <br>

    <button type="button" class="btn btn-light" (click)="action()">Try Again</button>


  </div>


</div>

