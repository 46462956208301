<!-- footer -->
<div class="footer">
  <div class="container pbottom" style="margin-top: 5vh;!important;">
    <div class="row">
      <div class="col-md-6">
        <img class="mb-2" src="../../assets/images/footer-2.png" alt="">
        <p *ngIf="activeLang === 'en'">
          HELAVIRU DIGITAL ECONOMIC CENTER is a Cloud-based Digital Marketplace that facilitates trading activities
          (buying & selling) of Agro-Produce and Commodities among diverse stakeholders on a seamlessly connected supply
          chain.
        </p>
        <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
          හෙළවිරු, කෘෂි බෝග සහ ගොවිපල නිෂ්පාදන ආර්ථික මධ්‍යස්ථානයක් වන අතර, එය විවිධ පාර්ශවකරුවන් අතර කෘෂි නිෂ්පාදන,
          ගොවිපල නිෂ්පාදන සහ අනෙකුත් වෙළඳ භාණ්ඩ වල ගැණුම් සහ විකුණුම් සක්‍රීය කිරීම සඳහා නිර්මාණය කරන ලද සැපයුම් දාමයකි.
        </p>
        <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
          ஹெலவிரு டிஜிட்டல் எகனாமிக் சென்டர் என்பது கிளவுட் அடிப்படையிலான டிஜிட்டல் சந்தையாகும், இது வேளாண் உற்பத்தி
          மற்றும் பொருட்களின் வர்த்தக நடவடிக்கைகளை (வாங்குதல் மற்றும் விற்பனை செய்தல்) பல்வேறு பங்குதாரர்களிடையே
          தடையின்றி இணைக்கப்பட்ட விநியோகச் சங்கிலியில் உதவுகிறது.
        </p>
      </div>
      <div class="col-md-5 offset-1 d-flex justify-content-between align-items-center">
        <div style="height: 450px">
          <h5>{{'FOOTER.PRODUCTS' | translate}}</h5>
          <ul>
            <li><a [routerLink]="['/products/'+tenantValue]">{{'FOOTER.ALL_PRODUCT' | translate}}</a></li>
            <!--            <li><a>{{'Browse_Popular_Products' | translate}}</a></li>-->
            <!--            <li><a>{{'Become_a_Seller' | translate}}</a></li>-->
            <!--            <li><a>{{'Open_a_Online_Store'| translate}}</a></li>-->
          </ul>
        </div>
        <div style="height: 450px;">
          <h5>{{'FOOTER.COMPANY' | translate}}</h5>
          <ul>
            <li><a [routerLink]="['/about/'+tenantValue]">{{'FOOTER.ABOUT_US' | translate}}</a></li>
            <li><a [routerLink]="['/faq/'+tenantValue]">{{'FOOTER.FAQ.FAQ' | translate}}</a></li>
            <li><a [routerLink]="['/contact/'+tenantValue]">{{'FOOTER.CONTACT_US.CONTACT_US' | translate}}</a></li>
            <li class="row">
              <a style="font-size: 30px; margin-left: 16px;" target="_blank" href="{{facebookURL}}">
                <i class="fa fa-facebook-square" aria-hidden="true"></i>
              </a>
              <a style="font-size: 30px; margin-left: 1vw;" target="_blank" href="{{twitterURL}}">
                <i class="fa fa-twitter-square" aria-hidden="true"></i>
              </a>
              <a style="font-size: 30px; margin-left:  1vw;" target="_blank" href="{{instagramURL}}">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>

            <li><a
              [routerLink]="['/checkout-terms/'+tenantValue]">{{'FOOTER.TERMS_CONDITION.TERMS_CONDITION' | translate}}</a>
            </li>
            <li><a [routerLink]="['/privacy-policy/'+tenantValue]">{{'FOOTER_HELAVIRU_POLICY' | translate}}</a></li>
            <li><a [routerLink]="['/riyawiru-privacy-policy/'+tenantValue]">{{'FOOTER_RIYAWIRU_POLICY' | translate}}</a>
            </li>
            <li><a
              [routerLink]="['/customer-protection-policy/'+tenantValue]">{{'FOOTER.TERMS_CONDITION.CUSTOMER_POLICY' | translate}}</a>
            </li>
            <li style="margin-top: 10px;!important;">
              <img alt="" src="../../assets/images/visa_mastercard_logo_6.gif">
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="fotter-bottom">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 footerTitle d-flex align-items-center">
          &copy; {{footerText}} {{version}}
        </div>
        <div class="col-lg-6 col-md-6 d-flex align-items-center">
          <div class="w-100">
            <form [formGroup]="subscribeMailForm" (ngSubmit)="submitForm()">
              <div class="footer-nl-form">
                <label style="display: unset!important;">
                  <input [(ngModel)]="email" type="email" formControlName="email"
                         style="font-size: 12px!important; text-transform: none;"
                         placeholder="{{'FOOTER.SUBSCRIBE_NEWSLETTER' | translate}}">
                </label>

                <div style="font-size: 10px;color: red"
                     *ngIf="showErrorEmail">{{'FOOTER.VALID_EMAIL' | translate}}</div>

                <button type="submit"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
