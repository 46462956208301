import {Component, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../../../../service/product.service';
import {SalesDTO} from '../../../../dto/salesDTO';
import {UserRoleService} from '../../../../service/user-role.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {Global} from '../../../../service/global';
import {PaymentService} from '../../../../service/payment.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {CommonService} from '../../../../common/common.service';
import {PurchaseStatusDTO} from '../../../../dto/purchase-statusDTO';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RemarkComponent} from '../../my-purchases/remark/remark.component';
import {InteractionService} from '../../../../service/interaction.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {OrderDto} from '../../../../dto/orderDto';
import {Md5} from 'ts-md5';
import {SellerDto} from '../../../../dto/sellerDto';
import {UserTokenDTO} from '../../../../dto/userTokenDTO';
import {CoreService} from '../../../../service/core.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-farmer-sales',
  templateUrl: './farmer-sales.component.html',
  styleUrls: ['./farmer-sales.component.css']
})
export class FarmerSalesComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  salesDTOs: SalesDTO[] = [];
  dataSource;
  displayedColumns: string[] = ['orderID', 'pro_name', 'pro_price', 'qty', 'productType', 'amount', 'buyer', 'status'];
  tenantValue: any;
  productName: any;
  totalUnitPrice: any;
  grandTotal: any;
  purchasedDate: any;
  firstName: any;
  lastName: any;
  address: any;
  mobile: any;
  postalCode: any;
  country: any;
  province: any;
  sku: any;
  transactionId: any;
  transactionTrimValue: any;
  productList: any;
  name: any;
  deliveryTotal: any;
  status: any;
  BASE_URL: any;
  CURRENCY_CODE: any;
  qrImage: any;
  purchaseStatusDTO = new PurchaseStatusDTO;
  id: any;
  prodName: string;
  orderDate: Date;
  unit: any;
  quantity: any;
  addressPur: any;
  type: any;
  amount: any;
  image: any;
  pickupAdd: any;
  userTokenDTO = new UserTokenDTO;
  buyerName: any;
  contact: any;
  district: any;
  sellerImage: string;
  buyerUn: string;
  delivery: string;
  deliveryCharges: any;
  deliveryFixRate: any;
  area: any;
  deliveryId: any;

  @ViewChild('closeButton') closeButton;
  @ViewChild('success') successButton;
  @ViewChild('Reject') rejectButton;

  approveForm: FormGroup;
  endCity: any;
  userRole: any;
  isLead: boolean = false;
  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;
  ecoShopCode: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private productService: ProductService,
              private router: Router,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private payment_service: PaymentService,
              private interactionService: InteractionService,
              public global: Global,
              public main_url: MainUrlService,
              public commonService: CommonService,
              public mainUrl: MainUrlService,
              private _formBuilder: FormBuilder,
              private modalService: NgbModal,
              private validationHandlerService: ValidationHandlerService,
              private settingsService: SystemSettingsService,
              private coreService: CoreService,) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });

    if (this.userRole === 'LEAD') {
      this.isLead = true;
    }
  }

  ngOnInit() {
    this.approveForm = this._formBuilder.group({
      remark: ['', Validators.required],
    });
    this.loadSales();
    this.fetchAll();

    this.interactionService.updateRemark.subscribe(_remark => {
      if (_remark) {
        this.loadSales();
      }
    });

    this.route.queryParams.subscribe(parms => {
      if (parms['id'] !== undefined) {
        this.searchValue = parms['id'];
      }
    });
  }

  loadSales() {
    this.dataSource = new MatTableDataSource();
    this.salesDTOs = [];
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.commonService.processing();
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

        if (this.isLead || (this.isEconomicCenter && this.isEcoFarmerSection)) {
          this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {
            if (this.isEcoFarmerSection) {
              this.productService.fetchEcoShopSales(refNo.message).subscribe(ecoShopSales => {
                this.commonResponseBody(ecoShopSales);
              }, () => {
                Swal.close();
              });
            } else {
              this.productService.fetchSalesByLeadCode(refNo.message).subscribe(leadSales => {
                this.commonResponseBody(leadSales);
              }, () => {
                Swal.close();
              });
            }

          });

        } else {
          this.productService.fetchSalesByUsername(whoAmIDetails['email']).subscribe(salesDetails => {
            this.commonResponseBody(salesDetails);
          }, () => {
            Swal.close();
          });
        }

      });
    } else {
      this.commonLogoutURL();
    }
  }

  commonResponseBody(values) {
    for (let item of values) {
      if (item.status === 'SHIPPED' || item.status === 'COMPLETED') {
        if (item.productType !== 'ORDER_REQUEST') {
          let sales = new SalesDTO(
            item.product.sku,
            item.product.product.name,
            item.product.product.subCategory.mainCategory.commonIndex.description,
            item.totalPrice,
            item.product.product.address,
            item.created,
            item.id,
            item.status,
            item.transactionId,
            item.product.productImages[0].image,
            item.userName,
            item.quantity,
            item.unitPrice,
            item.buyerName,
            item.seller,
            item.farmerType,
            item.productType,
            item.product.product.unit.unit
          );
          this.salesDTOs.push(sales);

        } else {
          let sales = new SalesDTO(
            item.product.sku,
            item.product.product.name,
            item.product.product.subCategory.mainCategory.commonIndex.description,
            item.totalPrice,
            item.product.product.address,
            item.created,
            item.id,
            item.status,
            item.transactionId,
            item.product.product.subCategory.mainCategory.commonIndex.description,
            item.userName,
            item.quantity,
            item.unitPrice,
            item.buyerName,
            item.seller,
            item.farmerType,
            item.productType,
            item.product.product.unit.unit
          );
          this.salesDTOs.push(sales);
        }

      }

    }
    Swal.close();
    this.dataSource = new MatTableDataSource(this.salesDTOs);
    this.dataSource.paginator = this.paginator;
  }

  setOrderRequestImage(category) {
    let image;
    if (category === 'Farming Products & Processed Food') {
      image = 'assets/images/Thumbnails/Processed Products.jpg';
    } else if (category === 'Spices & Cash Crops') {
      image = 'assets/images/Thumbnails/Spices.jpg';
    } else if (category === 'Services & Information') {
      image = 'assets/images/Thumbnails/Services.jpg';
    } else if (category === 'Plants & Seeds') {
      image = 'assets/images/Thumbnails/Seeds.jpg';
    } else if (category === 'Ayurvedic & Herbal Products') {
      image = 'assets/images/Thumbnails/Ayurveda.jpg';
    } else if (category === 'Equipment and Tools') {
      image = 'assets/images/Thumbnails/Equipments.jpg';
    } else {
      image = 'assets/images/Thumbnails/' + category + '.jpg';
    }
    return image;
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    }, () => {
      Swal.close();
    });
  }

  commonLogoutURL() {
    Swal.close();
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/' + this.tenantValue]);
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDetailsModal(transactionId) {
    this.loading();
    this.transactionId = transactionId;
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.productService.historyDetailsByTransactionIdNUsername(transactionId, whoAmIDetails['email']).subscribe(transactionDetails => {
        if (transactionDetails) {
          Swal.close();
          this.CURRENCY_CODE = this.global.CURRENCY_CODE;
          this.BASE_URL = this.global.BASE_URL;
          this.productList = transactionDetails.productDetailsDTOS;
          this.address = transactionDetails['address'];
          this.name = transactionDetails['firstName'];
          this.deliveryTotal = transactionDetails['delivery'];
          this.postalCode = transactionDetails['postalCode'];
          this.province = transactionDetails['province'];
          this.country = transactionDetails['country'];
          this.mobile = transactionDetails['mobile'];
          this.status = transactionDetails['status'];
          this.grandTotal = transactionDetails['grandTotal'];
          this.totalUnitPrice = transactionDetails['totalUnitPrice'];

          this.generateQRCode(transactionId);

          document.getElementById('openModelBtn').click();

        }
      }, () => {
        Swal.close();
      });
    }, () => {
      Swal.close();
    });

  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }


  generateQRCode(transactionId) {
    this.payment_service.fetchBarCode(transactionId, {
      'proxyUrl': environment.proxyUrl
    }).subscribe(brCode => {
      if (brCode) {
        let brBody =
          {
            'url': this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + transactionId,
            'proxyUrl': environment.proxyUrl
          };
        this.payment_service.fetchQrCode(brBody).subscribe(qrCode => {
          if (qrCode) {
            this.qrImage = this.main_url.MAIN_URL_FILE + qrCode['message'] + '/?X-TenantID=' + this.tenantValue;
          }
        }, () => {
          Swal.close();
        });

      }

    }, () => {
      Swal.close();
    });

  }

  orderIdLengthTrim(orderId, trimLength) {
    if (orderId !== undefined) {
      return orderId.substring(orderId.length - trimLength);
    }
    return false;
  }

  loadImage(el) {
    return this.main_url.MAIN_URL_FILE + el + '/?X-TenantID=' + this.tenantValue;
  }

  reject(data) {
    this.purchaseStatusDTO.id = data.rowId;
    this.purchaseStatusDTO.status = 'REJECTED';
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.purchaseStatusDTO.rejectedBy = whoAmIDetails['email'];
      const modalRef = this.modalService.open(RemarkComponent, {
        size: 'lg', centered: true
      });
      modalRef.componentInstance.fromParent = {
        model: this.purchaseStatusDTO,
      };
      this.commonService.setModel(this.modalService);
    });

  }

  decisionSubmit(reject: string) {

  }

  manageStatus(status: string) {

    switch (sessionStorage.getItem('LANG')) {
      case 'en' :
        if (status === 'REJECTED_SETTLED') {
          return 'REJECTED SETTLED';
        } else {
          return status;
        }

      case 'si' :
        return status + '-sin';

      case 'ta' :
        return status + '-tamil';
    }

  }

  category: any;
  deliveryDate: any;
  searchValue: any;

  getOrder(data) {
    this.category = data.image;
    this.loading();
    this.productService.fetchOrderByID(data.rowId).subscribe(
      (res: OrderDto) => {
        this.getBuyer(data.userName);
        this.buyerUn = data.userName;
        this.prodName = res.name;
        this.orderDate = res.created;
        this.unit = res.unit;
        this.quantity = res.quantity;
        this.addressPur = res.diliveryAddress;
        this.type = res.type;
        this.amount = res.amount;
        this.image = data.image;
        this.pickupAdd = res.pickupAdd;
        this.delivery = res.delivery;
        this.deliveryCharges = res.deliveryCharges;
        this.deliveryFixRate = res.deliveryFixRate;
        this.area = res.area;
        this.deliveryId = res.deliveryId;
        this.deliveryDate = res.updated;

        Swal.close();
      }, () => {
        Swal.close();
      }
    );
  }

  getBuyer(seller) {
    this.userTokenDTO.userName = seller;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.productService.fetchSeller(this.userTokenDTO).subscribe(
      (data: SellerDto) => {
        this.buyerName = data.name;
        this.district = data.address;
        this.contact = data.contactNo;

        let body = {
          'userName': data.username,
          'token': 'Bearer ' + localStorage.getItem('$Token')
        };
        this.coreService.fetchUserDetailsByUserName(body).subscribe(details => {
          if (details) {
            let hashStr;
            if (data.username !== null) {
              hashStr = Md5.hashStr(data.username);
            } else {
              hashStr = Md5.hashStr('');
            }
            this.sellerImage = details.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + details.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
          }
        });

      }, () => {
        Swal.close();
      }
    );
  }
}
