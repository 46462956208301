<div class="p-0 p0sidebar">
  <div class="form-sidebar">
    <div class="text-center">
      <button class="btn btn-success d-green-btn"
              [routerLink]="'/value-added-services/wholesale'">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.VALUE_ADDED_SERVICES'|translate}}
      </button>
    </div>

    <div class="sidebar-links">
      <li class="panel panel-default active" id="dropdowncate">
        <div class="sidebar-navs main-a" style="font-weight: bold">
          <span *ngIf="activeLang === 'en'">Loan Application</span>
          <span *ngIf="activeLang === 'si'">ණය අයදුම්පත</span>
          <span *ngIf="activeLang === 'ta'">கடன் விண்ணப்பம்</span>
        </div>

        <div id="dropdown-lvlcate" class="panel-collapse">
          <div class="panel-body">
            <ul class="nav navbar-nav nav-sub">
              <li (click)="activeTab=1"
                  *ngIf="commonService.checkPrivilege(['LOAN_FOR_FARMERS'])"
                  [routerLink]="['/value-added-services/loan-forms/'+ tenantValue]">
                <a class="sidebar-navs">
                  <span *ngIf="activeLang === 'en'">- for Farmers</span>
                  <span *ngIf="activeLang === 'si'">- ගොවීන් සඳහා</span>
                  <span *ngIf="activeLang === 'ta'">- விவசாயிகளுக்கு</span>
                </a>
              </li>
              <li (click)="activeTab=2"
                  *ngIf="commonService.checkPrivilege(['LOAN_FOR_AGGREGATORS'])"
                  [routerLink]="['/value-added-services/loan-forms/for-aggregators/'+ tenantValue]">
                <a class="sidebar-navs">
                  <span *ngIf="activeLang === 'en'">- for Aggregators</span>
                  <span *ngIf="activeLang === 'si'">- එකතු කරන්නන් සඳහා</span>
                  <span *ngIf="activeLang === 'ta'">- திரட்டிகளுக்காக</span>
                </a>
              </li>
              <li (click)="activeTab=3"
                  *ngIf="commonService.checkPrivilege(['LOAN_FOR_AGRO_BASED'])"
                  [routerLink]="['/value-added-services/loan-forms/for-manufactures/'+ tenantValue]">
                <a class="sidebar-navs">
                  <span *ngIf="activeLang === 'en'">- for Agro Based Manufacturers</span>
                  <span *ngIf="activeLang === 'si'">- කෘෂි පදනම් කරගත් නිෂ්පාදකයින් සඳහා</span>
                  <span *ngIf="activeLang === 'ta'">- வேளாண் சார்ந்த உற்பத்தியாளர்களுக்கு</span>
                </a>
              </li>
              <li (click)="activeTab=4"
                  *ngIf="commonService.checkPrivilege(['LOAN_FOR_SUPPLIERS'])"
                  [routerLink]="['/value-added-services/loan-forms/for-suppliers/'+ tenantValue]">
                <a class="sidebar-navs">
                  <span *ngIf="activeLang === 'en'">- for Input Suppliers</span>
                  <span *ngIf="activeLang === 'si'">- ආදාන සැපයුම්කරුවන් සඳහා</span>
                  <span *ngIf="activeLang === 'ta'">- உள்ளீட்டு சப்ளையர்களுக்கு</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </div>
  </div>
</div>
