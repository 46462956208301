import {Component, OnInit, ViewChild} from '@angular/core';
import {MyAccountServiceService} from '../../my-account-service.service';
import {ProductService} from '../../../../service/product.service';
import {ProductDetailsDTO} from '../../../../dto/productDetailsDTO';
import {MatDialog, MatStepper, MatTableDataSource} from '@angular/material';
import {UserRoleService} from '../../../../service/user-role.service';
import {GetProductItemDTO} from '../../../../dto/getProductItemDTO';
import {CommonService} from '../../../../common/common.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {InteractionService} from '../../../../service/interaction.service';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';

@Component({
  selector: 'app-farmer-my-products',
  templateUrl: './farmer-my-products.component.html',
  styleUrls: ['./farmer-my-products.component.css']
})
export class FarmerMyProductsComponent implements OnInit {

  ELEMENT_DATA = [];
  ELEMENT_DATA_AUCTION = [];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  tenantValue;
  dataSource;
  dataSourceAuction;
  productDetailsDTO: ProductDetailsDTO[] = [];
  product: any;
  productAuction: any;
  activeLang: any;
  displayedColumns: string[] =
    ['id', 'category', 'price', 'bulkQty', 'qty', 'expiredDate', 'adddate', 'status', 'action', 'action1'];
  displayedColumnsAuction: string[] = ['name', 'created', 'location', 'startPrice', 'stockAvailable', 'endDate', 'status', 'action', 'action1'];
  @ViewChild('paginatorSpot', {static: true}) paginatorSpot;
  @ViewChild('paginatorAuction', {static: true}) paginatorAuction;
  @ViewChild('stepper', {static: true}) stepper;
  selectNewProductOptions: number;

  constructor(private abc: MyAccountServiceService,
              private route: ActivatedRoute,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              public mainUrl: MainUrlService,
              private validationHandlerService: ValidationHandlerService,
              private _formBuilder: FormBuilder,
              private interactionService: InteractionService,
              private productItemData: GetProductItemDTO,
              public commonService: CommonService,
              private matDialog: MatDialog) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

  }

  commonImg: any;

  setViewImage(imageURL: string) {
    this.commonImg = imageURL;
  }

  ngOnInit() {
    this.activeLang = sessionStorage.getItem('LANG');
    this.loadProducts();
    this.interactionService.updateProductListingList(false);

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

    this.route.queryParams.subscribe(parms => {
      if (parms['type'] !== undefined) {
        if (parms['type'] === 'AUCTION') {
          this.stepper.selectedIndex = 1;
        }
      }
    });

  }

  loadProducts() {
    this.commonService.processing();
    this.productDetailsDTO = [];

    this.ELEMENT_DATA = [];
    this.ELEMENT_DATA_AUCTION = [];
    this.dataSource = undefined;
    this.dataSourceAuction = undefined;

    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

      this.loadAuctionProducts('AUCTION', whoAmIDetails['email']);
      this.loadSpotProducts('SPOT', whoAmIDetails['email']);

    }, () => {
      Swal.close();
    });
  }

  loadSpotProducts(type, username) {
    let body = {
      'name': null,
      'categoryCode': null,
      'subCategoryCodes': [],
      'minPrice': null,
      'locations': [],
      'maxPrice': null,
      'sortByPrice': null,
      'ratings': null,
      'userName': username,
      'productType': type
    };
    this.productService.fetchAllProductByUser(body).subscribe(result => {

      for (let item of result) {

        let sku = '-';
        let image;
        if (item.type.type === 'SPOT') {
          sku = item.spotDTOList[0].sku;
          if (item.spotImages.length > 0) {
            image = item.spotImages[0].image;
          } else {
            image = '00000';
          }

        }

        let _stock;
        let _expireDate;
        let _totalQty;

        _stock = item.spotDTOList[0].stock;
        _expireDate = item.spotDTOList[0].expireDate;
        _totalQty = item.spotDTOList[0].totalQuantity;

        this.ELEMENT_DATA.push({
          sku: sku,
          name: item.name,
          description: item.subCategory.mainCategory.commonIndex.description,
          createdDate: item.created,
          location: item.location,
          unitPrice: item.unitPrice,
          stockAvailable: item.stockAvailable,
          expireDate: item.expireDate,
          image: image,
          allItems: item,
          category: item.subCategory.mainCategory.commonIndex,
          type: item.type.type,
          spotStock: _stock,
          totalQty: _totalQty,
          spotExpiredDate: _expireDate,
          isSellInSmallerQty: item.sellInSmallerQuantities,
          totalEarnings: item.totalEarnings,
          status: item.productStatus.description,
          unitType: item.unit.unit,
        });

      }

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginatorSpot;
      Swal.close();

    }, () => {
      Swal.close();
    });
  }

  loadAuctionProducts(type, username) {
    let body = {
      'name': null,
      'categoryCode': null,
      'subCategoryCodes': [],
      'minPrice': null,
      'locations': [],
      'maxPrice': null,
      'sortByPrice': null,
      'ratings': null,
      'userName': username,
      'productType': type
    };
    this.productService.fetchAllProductByUser(body).subscribe(result => {

      for (let val of result) {
        let image;
        if (val.forwardAcuImages.length > 0) {
          image = val.forwardAcuImages[0].image;
        } else {
          image = '00000';
        }

        this.ELEMENT_DATA_AUCTION.push({
          id: val.id,
          name: val.name,
          listedBy: val.listedBy,
          created: val.created,
          location: val.location,
          startPrice: val.auctionStartPrice,
          startDate: val.bidStartDate,
          endDate: val.bidEndDate,
          stockAvailable: val.bidQyt,
          allItems: val,
          image: image,
          type: val.type.type,
          status: val.productStatus.description,
          unitType: val.unit.unit,
          sku: val.sku,
        });
      }
      this.dataSourceAuction = new MatTableDataSource(this.ELEMENT_DATA_AUCTION);
      this.dataSourceAuction.paginator = this.paginatorAuction;
      Swal.close();
    }, () => {
      Swal.close();
    });
  }

  loadDataForUpdate(modal: any, item: any) {
    this.product = item;
    this.matDialog.open(modal, {
      width: '80%',
      panelClass: 'custom-dialog-',
      maxHeight: '99%',
      minHeight: '99%'
    }).afterClosed().subscribe(() => {
      this.loadProducts();
    });

  }

  loadDataForAuctionUpdate(modal: any, item: any) {
    this.productService.getAuctionProductBidsCount(item.sku).subscribe(result => {
      if (parseInt(result) === 0) {
        this.productAuction = item;
        this.matDialog.open(modal, {
          width: '80%',
          panelClass: 'custom-dialog-',
          maxHeight: '99%',
          minHeight: '99%'
        }).afterClosed().subscribe(() => {
          this.loadProducts();
        });
      } else {
        Swal.fire({
          position: 'center',
          type: 'warning',
          text: this.validationHandlerService.alreadyBids(sessionStorage.getItem('LANG')),
          showConfirmButton: false,
          timer: 3500
        });
      }
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.dataSourceAuction.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceAuction.paginator) {
      this.dataSourceAuction.paginator.firstPage();
    }
  }

  disableProduct(sku) {
    this.productService.updateMySpotProduct('DISABLE', sku).subscribe(() => {
      this.loadProducts();
    });
  }

  enableProduct(sku) {
    this.productService.updateMySpotProduct('ACTIVE', sku).subscribe(() => {
      this.loadProducts();
    });
  }


  disableProductAuction(sku) {
    this.productService.updateMyAuctionProduct('DISABLE', sku).subscribe(() => {
      this.loadProducts();
    });
  }

  enableProductAuction(sku) {
    this.productService.updateMyAuctionProduct('ACTIVE', sku).subscribe(() => {
      this.loadProducts();
    });
  }

  farmerUsername: any;

  passingFarmerUsername() {
    // sessionStorage.setItem('FARMER_USERNAME', this.farmerUsername);
    // this.interactionService.setFarmerUsername(this.farmerUsername);
  }
}
