import {Component, OnInit} from '@angular/core';
import {CoreService} from '../../../service/core.service';
import {UserRoleService} from '../../../service/user-role.service';
import {UserTokenDTO} from '../../../dto/userTokenDTO';
import {MainUrlService} from '../../../service/main-url.service';
import Swal from 'sweetalert2';
import {SmsService} from '../../../service/sms.service';
import {ToastrService} from 'ngx-toastr';
import {InteractionService} from '../../../service/interaction.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  pendingListDetails: any;
  userDetailsList: any;
  imgUrl: any = 'assets/avatar.jpg';
  userTokenDTO = new UserTokenDTO;
  tenantValue;
  username;
  language: any;
  activeLang: string = 'en';

  constructor(private coreService: CoreService,
              private userRoleService: UserRoleService,
              public mainUrl: MainUrlService,
              public smsService: SmsService,
              private toastr: ToastrService,
              private interactionService: InteractionService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.username =
      localStorage.getItem('USERNAME');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit() {
    this.getUserDetails();
    this.loadLanguage();
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.getUserDetails();
      }
    });
  }

  loadLanguage() {
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.smsService.loadLanguageByUsername(whoAmIDetails['email']).subscribe(language => {
        if (language !== null) {
          this.language = language.language;
        } else {
          this.language = 'EN';
        }
      });
    });
  }

  saveLanguage() {
    Swal.showLoading();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

      let body = {
        'username': whoAmIDetails['email'],
        'language': this.language
      };

      this.smsService.saveLanguage(body).subscribe(language => {
        this.toastr.success('Successfully Updated');
        Swal.close();
      });
    });
  }

  getUserDetails() {
    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      // this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.coreService.findRegistrationDetailsByUser(this.username).subscribe(
        (data) => {
          Swal.close();
          this.pendingListDetails = data;
        }, () => {
          Swal.close();
        });

      this.coreService.findUserDetailsByUsername(this.username).subscribe(
        (data) => {
          Swal.close();
          this.userDetailsList = data;
          this.imgUrl = data.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + data.imageUrl + '/?X-TenantID=' + this.tenantValue : 'assets/avatar.jpg';
        }, () => {
          Swal.close();
        });

      // let body = {
      //   'userName': whoAmIDetails['email'],
      //   'token': 'Bearer ' + localStorage.getItem('$Token')
      // };

      // this.coreService.fetchUserDetailsByUserName(body).subscribe(result => {
      //   if (result) {
      //     Swal.close();
      //
      //     let hashStr;
      //     if (whoAmIDetails['email'] !== null) {
      //       hashStr = Md5.hashStr(whoAmIDetails['email']);
      //     } else {
      //       hashStr = Md5.hashStr('');
      //     }
      //     this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
      //   }
      // }, () => {
      //   Swal.close();
      //   let hashStr;
      //   if (whoAmIDetails['email'] !== null) {
      //     hashStr = Md5.hashStr(whoAmIDetails['email']);
      //   } else {
      //     hashStr = Md5.hashStr('');
      //   }
      //   this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
      // });
      // }, () => {
      //   Swal.close();
      // });


    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

}
