import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MainUrlService} from './main-url.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  login(body):Observable<any>{
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH+'/auth/login',body);
  }


  loginUser():Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH+'/user/me');
  }

  checkEmailIsPresent(email):Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH + '/external/checkEmailIsPresent/'+email);
  }

  fetchCountOfUsers():Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH + '/users/getCount');
  }

  requestOtp(body):Observable<any>{
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH+'/auth/login/requestOtp',body);
  }

  checkOtp(body):Observable<any>{
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH+'/auth/login/checkOtp',body);
  }

}
