<div class="page-contents">
    <div class="packge-form" id="insurnce">
        <div class="top-info-section text-center">
            <img src="../../../../../../../assets/images/payment-types/insuarance/accident.png" alt="" class="sltimg">
            <h4 class="title-pck">Helaviru Personal Accident Insurance Benefits</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu ac egestas elit odio nulla volutpat in. Erat
                et amet morbi sed lectus. Pellentesque id donec platea posuere integer sed sed. Iaculis nulla volutpat
                tempor donec. Nulla enim habitant pretium amet sed posuere.
            </p>
            <p class="rednotice">** You have to provide a valid document proof</p>
        </div>
        <hr>
        <div class="scheme-doc">
            <div class="scheme-details">
                <label for="">1) Proposer's Name in Full & address:</label>
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td class="thead">1) Insured - <span class="sin">රක්ෂිතයා</span> </td>
                            <td>
                                <mat-form-field appearance="outline" class="inputbox">
                                    <input id="lName" matInput>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="thead">2) Period of Insurance - <span class="sin">රක්ෂණ කාලය</span> </td>
                            <td>One Year [Dates to be agreed] <br />
                                <span class="sin"> වසරකි (දින දිනුම් ඇදීමට යටත්ව)</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="thead">3) Sum Insured - <span class="sin">රක්ෂිත මුදල</span> </td>
                            <td>Rs. 1,000,000/-
                            </td>
                        </tr>
                        <tr>
                            <td class="thead">4) Covers - <span class="sin">ආවරණ</span> </td>
                            <td>
                                <ul>
                                    <li>Accidental Death - <span class="sin">හදිසි අනතුරකින් සිදුවන මරණය</span></li>
                                    <li>Permanent Total Disablement - <span class="sin">සදාකාලික පූර්ණ
                                            අකර්මණ්‍යතාවය</span></li>
                                    <li>Permanent Partial Disablement - <span class="sin">සදාකාලික අර්ධ
                                            අකර්මණ්‍යතාවය</span></li>
                                    <li>Motorcycling - <span class="sin">යතුරුපැදි ආවරණය </span></li>
                                    <li>24 hours Cover - <span class="sin">දවසේ පැය 24 සදහාම ආවරණය</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="thead">5) Annual Premium - <span class="sin">වාර්ෂික වාරිකය</span> </td>
                            <td>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th colspan="2" class="text-center">Annual Premium Per Person <br />
                                                <span class="sin"> එක් පුද්ගලයකු සදහා වාර්ෂික වාරිකය </span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>Net Premium - <span class="sin">මූලික වාරිකය</span> </td>
                                            <td>Rs.500.00</td>
                                        </tr>
                                        <tr>
                                            <th>Total Premium with taxes - <span class="sin"> බදු සහිත මුළු
                                                    වාරිකය</span></th>
                                            <th>Rs.649.89</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-tables">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Event </th>
                                <th scope="col" class="b-r">Result</th>
                                <th scope="col" class="b-l text-right">Compensation </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="wsmall">Bodily injury, caused
                                    solely by violent, Accidental, external and
                                    visible means which
                                    injury shall
                                    independently of any
                                    other cause be the sole
                                    cause of any of the
                                    results (a) to (f)</td>
                                <td colspan="2">
                                    <div class="d-flex align-items-center">
                                        <div class="licol">
                                            (a) Death. <br /> <br />

                                            (b) Total and permanent loss of all
                                            sight in both eyes <br /> <br />

                                            (c) Total loss by physical severance
                                            of two limbs <br /> <br />

                                            (d) Total Loss by physical severance
                                            of two limbs together with the
                                            total and permanent loss of all
                                            sight in one eye <br /> <br />

                                            (e) Total and permanent loss of all
                                            sight in one eye <br /> <br />

                                            (f) Total loss by physical severance
                                            of one limb
                                        </div>
                                        <div class="brckets">
                                            <div class="d-flex align-items-center">
                                                <span>]</span>
                                                <p class="text-center m-0">Occurring within
                                                    three months of
                                                    happening of the
                                                    event</p>
                                                <span>[</span>
                                            </div>
                                        </div>
                                        <div class="licol">
                                            (a) 100% of Sum Insured <br /> <br />

                                            (b) 100% of Sum Insured <br /> <br />

                                            (c) 100% of Sum Insured<br /> <br />

                                            (d) 100% of Sum Insured<br /> <br />

                                            (e) 50% of Sum Insured<br /> <br />

                                            (f) 50% of Sum Insured
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <p>Compensation shall not be payable for more than one Results (a) to (f) and when
                                        payable for one of those Result shall not
                                        be payable for any other of the Results caused by the same Event not for any of
                                        Results caused by any subsequent Event</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-tables">
                    <table class="table table-bordered sin">
                        <thead>
                            <tr>
                                <th scope="col">සිද්ධිය </th>
                                <th scope="col" class="b-r">ප්‍රතිඵලය</th>
                                <th scope="col" class="b-l text-right">වන්දිය </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="wsmall">හුදෙක් අන් කිසිම හේතුවකින් තොරව (අ) සිට (ඊ) තෙක්ව ප්‍රතිඵල ඇති වුද
                                    සාහසික වුද හදිසි
                                    අනතුරුමය වූද බාහිරමය දෘෂයමාන ආකාරයකින් සිදු වූ ශාරීරික තුවාල</td>
                                <td colspan="2">
                                    <div class="d-flex align-items-center">
                                        <div class="licol">
                                            (අ) මරණය <br /> <br />

                                            (ආ) ඇස් දෙකෙහි පෙනීම සදාකාලිකව සම්පූර්ණයෙන්ම නැති වීම.<br /> <br />

                                            (ඇ) අත් දෙකම හෝ පා දෙකම හෝ එක් අතක් හා එක් පාදයක් ශරීරයෙන් වෙන් වීමෙන්
                                            සිදුවන සම්පූර්ණ නැති වීම.<br /> <br />

                                            (ඈ) එක් ඇසක පෙනීම සම්පූර්ණයෙන් හා සදාකාලිකව නැති වීමත් සමගම එක් අතක් හෝ එක්
                                            පයක් ශරීරයෙන් වෙන් වීමෙන් වූ සම්පූර්ණ නැති වීම.<br /> <br />

                                            (ඉ) එක් ඇසක පෙනීම සදාකාලිකව හා සම්පුර්ණයෙන් නැති වීම. <br /> <br />

                                            (ඊ) එක් අතක් හෝ එක් පයක් ශරීරයෙන් වෙන් වීමෙන් වූ සම්පූර්ණ නැති වීම.
                                        </div>
                                        <div class="brckets">
                                            <div class="d-flex align-items-center">
                                                <span>]</span>
                                                <p class="sin text-center m-0">සිද්ධිය සිදුවීමෙන් මාස 3ක් ඇතුලත සිදු වී
                                                    නම්</p>
                                                <span>[</span>
                                            </div>

                                        </div>
                                        <div class="licol">
                                            100% ආරක්ෂිත මුදලින් <br /> <br />

                                            100% ආරක්ෂිත මුදලින්<br /> <br />

                                            100% ආරක්ෂිත මුදලින්<br /> <br />

                                            100% ආරක්ෂිත මුදලින්<br /> <br />

                                            50% ආරක්ෂිත මුදලින්<br /> <br />

                                            50% ආරක්ෂිත මුදලින්
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <p class="sin">මේ ඔප්පුව යටතේ : <br /> <br />

                                        (අ) සිට (ඊ) තෙක් වූ ප්‍රතිඵල එකකට වැඩි ගණනක් වෙනුවෙන් ද ඉහත කී ප්‍රතිඵලයක්
                                        වෙනුවෙන් ගෙවිය යුතුවන විට එම සිද්ධිය නිසාම ඇති වූ වෙනත් ප්‍රතිඵලයක් වෙනුවෙන් ද
                                        එම සිද්ධියෙන් පසුව ඇති වූ කිසියම් සිද්ධියක් හේතුවෙන් හටගත් ප්‍රතිඵලයක් වෙනුවෙන්
                                        ද;</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group btn-sections b-0 p-0 text-right">
                <a aria-label="Close" class="btn cancel-btn-half">Back</a>
                <a aria-label="Next" class="btn next-btn-half" type="submit" data-target="#successModal"
                    data-toggle="modal">Submit</a>
            </div>
        </div>
    </div>
</div>