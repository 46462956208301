import {Component, OnInit} from '@angular/core';
import {InteractionService} from '../../../../service/interaction.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {CommonSignUpService} from '../../../../service/common-sign-up.service';
import {CoreService} from '../../../../service/core.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductService} from '../../../../service/product.service';

@Component({
  selector: 'app-table-product-view',
  templateUrl: './table-product-view.component.html',
  styleUrls: ['./table-product-view.component.css']
})
export class TableProductViewComponent implements OnInit {

  tenantValue: any;
  activeLang: any;

  productName: any;
  productSku: any;
  productCreatedDate: any;
  productImage: any;
  productCategory: any;
  productDescription: any;
  productUnitPrice: any;
  productStock: any;
  productAvailableQty: any;
  farmerUsername: any;
  farmerLocationEN: any;
  farmerLocationSI: any;
  farmerLocationTA: any;

  constructor(private interactionService: InteractionService,
              private commonSignUpService: CommonSignUpService,
              private coreService: CoreService,
              private productService:ProductService,
              private _translateService: TranslateService,
              public mainUrl: MainUrlService) {
  }

  ngOnInit() {
    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    this.interactionService.agentFarmerProductDetails.subscribe(productDetails => {
      this.productName = productDetails['name'];
      this.productSku = productDetails['sku'];
      this.productCreatedDate = productDetails['createdDate'];
      this.productImage = productDetails['image'];
      this.productCategory = productDetails['category'];
      this.productUnitPrice = productDetails['unitPrice'];
      this.productStock = productDetails['spotStock'];
      this.productAvailableQty = productDetails['stockAvailable'];
      this.farmerUsername = productDetails['allItems']['oauthUserDTO']['email'];
      this.productDescription = productDetails['allItems']['description'];
      this.loadLocation(this.farmerUsername);
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  loadLocation(userName) {
    let body = {
      'userName': userName,
      'token': 'Bearer ' + localStorage.getItem('$Token')
    };
    this.coreService.fetchUserDetailsByUserName(body).subscribe(details => {
      if (details) {
        this.commonSignUpService.fetchGramaNiladariById(details.grama_area).subscribe(location => {
          this.farmerLocationEN = location['divisionalSecretariat']['nameEN'];
          this.farmerLocationSI = location['divisionalSecretariat']['nameSI'];
          this.farmerLocationTA = location['divisionalSecretariat']['nameTA'];
        });
      }
    });
   }

}
