import {Component, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../service/product.service';
import {MainUrlService} from '../service/main-url.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Global} from '../service/global';
import {Title} from '@angular/platform-browser';
import {Options} from 'ng5-slider';
import {UserRoleService} from '../service/user-role.service';
import {CommonSignUpService} from '../service/common-sign-up.service';
import Swal from 'sweetalert2';
import {CommonService} from '../common/common.service';
import {InteractionService} from '../service/interaction.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  status: boolean = false;

  expand() {
    this.status = !this.status;
  }

  text: any = {
    Days: 'D',
    Hours: 'H',
    Minutes: 'M',
    Seconds: 'S',
  };
  allSpotProducts: any = [];
  allOrderRequestProducts: any = [];
  spinner: number = 1;
  CURRENCY_CODE;
  categoryId: any;
  activeAllProductCount: any = 0;
  allProductCount: any = 0;
  allOrderRequestProductsCount: any = 0;
  showFilter = false;
  activeView = 1;
  filstatus: boolean = false;
  minValue: any;
  maxValue: any;
  options: Options = {
    floor: 0,
    ceil: 1000
  };

  foundResult = false;
  foundResultOrderRequest = false;
  showTopBar = false;
  searchText: any;
  spinnerFilter: boolean = false;
  spinnerFilterOrderRequest: boolean = false;
  resetActiveBtn = false;
  mainCategories: any;
  subCategories: any;
  listShow: boolean = false;
  starArray: any;
  tenantValue: any = 'wholesale';
  paginationNumber: number = 0;
  isOrderShow: boolean;
  allProvinceList: any;
  activeLang: any = 'en';
  p: number = 1;

  searchProvinceList = [];
  searchEcoShopList = [];
  searchEcoShopIdList = [];

  selectProvinceIdList = [];
  searchCategoryList = [];
  selectSubCategoryIdList = [];
  selectCollapseBtn = [];
  sortByOrderValue: any = 'ASC';

  $singleViewBack: boolean = false;
  $hideFilterResetBtn: any = '0';

  todayDate = new Date().toISOString().slice(0, 10);

  bestBuyDateRange: any;
  cityName: any;
  addressArray: any;
  location: any;

  selectedProductType: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  @ViewChild('selectMainCategories', {static: false}) selectAllMainCategories;
  @ViewChild('selectSubCategories', {static: false}) selectAllSubCategories;
  linkEnabled: boolean = false;


  constructor(private titleService: Title,
              private global: Global,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private router: Router,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private commonService: CommonService,
              private interactionService: InteractionService,
              private commonSignUpService: CommonSignUpService) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.getAllProvince();
    this.getAllEconomicShops();
  }


  ngOnInit() {

    // Remove the selected product type
    this.selectedProductType = undefined;

    this.route.queryParams.subscribe(params => {
      if (params['$singleViewBack']) {
        this.$singleViewBack = params['$singleViewBack'];
      }

      if (params['ecoNo']) {
        return this.manageMarketList(2);
      }

      if (params['filterUsername'] !== undefined && params['filterUsername'] !== null) {
        this.sellerUsername = params['filterUsername'];
      }

      this.categoryId = params['mainCatID'];

      this.searchText = params['search'];

      if (this.$singleViewBack) {
        this.$hideFilterResetBtn = '1';
        this.categoryId = sessionStorage.getItem('$mainCatID');
        this.searchText = sessionStorage.getItem('$search');
      } else {
        if (this.categoryId !== undefined) {
          sessionStorage.setItem('$mainCatID', this.categoryId);
        }
      }

      let filterBody = sessionStorage.getItem('filterBody');
      let startId = sessionStorage.getItem('startId');
      let locations = sessionStorage.getItem('locations');
      let sortByPrice = sessionStorage.getItem('sortByPrice');
      let selectSubCategoryIdList = sessionStorage.getItem('selectSubCategoryIdList');
      let selectCollapseBtn = sessionStorage.getItem('selectCollapseBtn');


      this.productService.getActiveProfile().subscribe(profile => {
        if (profile) {
          this.getActiveProductCode(profile.code);
          if (this.searchText !== undefined && this.searchText !== null) {

            this.interactionService.updateKeyWord(this.searchText);

            let body = {
              'name': this.searchText,
              'subCategoryCodes': [],
              'ratings': null,
              'minPrice': null,
              'maxPrice': null,
              'sortByPrice': null,
              'locations': [],
              'categoryCode': null,
              'bestByDate': null,
              'productType': null,
              'centerName': null,
              'shopCodes': []
            };

            this.commonPagination(body, this.paginationNumber);

          } else if ((filterBody !== null) && this.$singleViewBack) {

            this.commonService.processing();
            setTimeout(() => {
              Swal.close();

              if (startId !== null) {
                document.getElementById(startId).click();
              }

              if (sortByPrice !== null) {
                this.setSortByOrder(sortByPrice);
              }

              if (locations !== null) {
                for (let location of JSON.parse(locations)) {
                  document.getElementById('province' + location).click();
                }
              }

              if (selectCollapseBtn !== null) {
                for (let col of JSON.parse(selectCollapseBtn)) {
                  document.getElementById(col).click();
                }
              }

              if (selectSubCategoryIdList !== null) {
                for (let subId of JSON.parse(selectSubCategoryIdList)) {
                  document.getElementById('sub' + subId).click();
                }
              }

            }, 6000);

            this.commonPagination(JSON.parse(filterBody), this.paginationNumber);

          } else if (this.categoryId !== undefined && this.searchText !== null) {

            let body = {
              'name': null,
              'ratings': null,
              'categoryCode': this.categoryId,
              'type': [],
              'minPrice': null,
              'maxPrice': null,
              'sortByPrice': null,
              'subCategoryCodes': [],
              'bestByDate': null,
              'productType': null,
              'centerName': null,
              'shopCodes': []
            };

            this.commonPagination(body, this.paginationNumber);

          } else {
            let body = {
              'name': null,
              'subCategoryCodes': [],
              'ratings': null,
              'minPrice': null,
              'maxPrice': null,
              'categoryCode': null,
              'sortByPrice': null,
              'locations': [],
              'bestByDate': null,
              'productType': null,
              'centerName': null,
              'shopCodes': []
            };

            this.commonPagination(body, this.paginationNumber);
          }
        }
      });


    });

  }

  getActiveProductCode(profile) {

    this.productService.getAllTreeListByProfileId(profile, this.tenantValue).subscribe(treeList => {
      if (treeList) {
        this.mainCategories = treeList;
        this.productService.getAllSubCategoryByProfileId(profile, this.tenantValue).subscribe(subCategories => {
          if (subCategories) {
            this.subCategories = subCategories;
          }
        });
      }
    });


  }

  clickFilter() {
    this.showFilter = !this.showFilter;
  }

  hideListView() {
    this.listShow = false;
    this.activeView = 1;
  }

  showListView() {
    this.listShow = true;
    this.activeView = 2;
  }

  clickStar(number: number, id: any) {
    sessionStorage.setItem('startId', id);
    this.starArray = number;
  }

  commonPagination(body, page) {
    this.foundResult = false;
    this.spinnerFilter = true;
    this.resetActiveBtn = true;
    this.allSpotProducts = [];

    this.productService.getActiveProfile().subscribe(profile => {
      if (profile) {
        this.productService.fetchAllPagination(body, profile.code, page, 1000000).subscribe(productResult => {
          if (productResult) {
            this.allProductCount = Object.keys(productResult).length;
            if (Object.keys(productResult).length >= 20) {
              this.activeAllProductCount = 20;
            } else {
              this.activeAllProductCount = Object.keys(productResult).length;
            }
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;
            this.showTopBar = true;

            if (Object.keys(productResult).length > 0) {
              this.spinner = 0;
              this.foundResult = false;
              this.spinnerFilter = false;
              for (let product of productResult) {
                this.allSpotProducts.push(product);
                if (this.sellerUsername !== undefined) {
                  this.filterOn(this.allSpotProducts, this.sellerUsername);
                }
              }
            } else {
              this.spinner = 0;
              this.spinnerFilter = false;
              this.foundResult = true;
            }
          }
        });
      }
    });
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
        this.getAllProvince();
        this.getAllEconomicShops();
      }
    });
  }

  loadCity(location) {
    if (location !== null) {
      this.cityName = location.substring(location.lastIndexOf(',') + 1);
      return this.cityName;
    }
  }

  pageChanged(p, allProductCount) {
    if (allProductCount < p * 20) {
      this.activeAllProductCount = allProductCount;
    } else {
      this.activeAllProductCount = p * 20;
    }
  }

  setType(type: any) {
    switch (type) {
      case 'SPOT':
        return 'spot';

      case 'FORWARD_BN':
        return 'forward';

      default:
        return 'bid';
    }
  }


  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe(provinceList => {
      if (provinceList) {
        this.allProvinceList = provinceList;
      }
    });
  }

  getAllEconomicShops() {
    this.commonSignUpService.fetchAllEcoCenters().subscribe(ecoShopList => {
      if (ecoShopList) {
        this.ecoShopList = ecoShopList;
        this.ecoShopListCount = this.ecoShopList.length;
      }
    });
  }

  storeProductList: any;
  ecoShopList: any;
  ecoShopListCount: number = 0;
  isFilterBySeller: boolean = false;
  sellerUsername: any;
  marketList: number = 0;
  isShowProductLocation: boolean = true;

  filterOn(list, username) {
    this.sellerUsername = username;
    this.storeProductList = list;
    this.isFilterBySeller = true;
    let arr = [];
    for (let product of list) {
      if (username === product['listedBy']) {
        arr.push(product);
      }
    }
    this.allSpotProducts = arr;
    this.allProductCount = this.allSpotProducts.length;
    this.filstatus = true;
  }


  clearFilterBySeller() {
    this.isFilterBySeller = false;
    this.allSpotProducts = this.storeProductList;
    this.filstatus = false;
    this.router.navigate(['/refresh_products/wholesale']).then(() => {
      this.router.navigate(['/products/wholesale']);
    });
  }


  selectProvinceList(ev, index) {
    let provinceId = ev.target.value;
    let isSpliced = false;
    let isSplicedID = false;

    if (this.searchProvinceList.length > 0) {
      for (let val of this.searchProvinceList) {
        if (val === provinceId) {
          isSpliced = true;
          this.searchProvinceList.splice(this.searchProvinceList.indexOf(val), 1);
        }
      }
      if (!isSpliced) {
        this.searchProvinceList.push(provinceId);
      }
    } else {
      this.searchProvinceList.push(provinceId);
    }

    if (this.selectProvinceIdList.length > 0) {
      for (let id of this.selectProvinceIdList) {
        if (id === index) {
          isSplicedID = true;
          let _index = this.selectProvinceIdList.indexOf(id);
          this.selectProvinceIdList.splice(_index, 1);
        }
      }
      if (!isSplicedID) {
        this.selectProvinceIdList.push(index);
      }
    } else {
      this.selectProvinceIdList.push(index);
    }
  }

  selectEcoShopList(ev, index) {
    let isSpliced = false;
    let isSplicedID = false;

    if (this.searchEcoShopList.length > 0) {
      for (let val of this.searchEcoShopList) {
        if (val === ev.target.value) {
          isSpliced = true;
          this.searchEcoShopList.splice(this.searchEcoShopList.indexOf(val), 1);
        }
      }
      if (!isSpliced) {
        this.searchEcoShopList.push(ev.target.value);
      }
    } else {
      this.searchEcoShopList.push(ev.target.value);
    }

    if (this.searchEcoShopIdList.length > 0) {
      for (let id of this.searchEcoShopIdList) {
        if (id === index) {
          isSplicedID = true;
          let _index = this.searchEcoShopIdList.indexOf(id);
          this.searchEcoShopIdList.splice(_index, 1);
        }
      }
      if (!isSplicedID) {
        this.searchEcoShopIdList.push(index);
      }
    } else {
      this.searchEcoShopIdList.push(index);
    }

  }


  selectedCategoryList(subCategoryId, i, j, collapseBtn) {
    let isSpliced = false;
    let _isSpliced = false;
    let _isSplicedCollapseBtn = false;

    if (this.searchCategoryList.length > 0) {
      for (let val of this.searchCategoryList) {
        if (val === subCategoryId) {
          isSpliced = true;
          let index = this.searchCategoryList.indexOf(val);
          this.searchCategoryList.splice(index, 1);
        }
      }
      if (!isSpliced) {
        this.searchCategoryList.push(subCategoryId);
      }
    } else {
      this.searchCategoryList.push(subCategoryId);
    }

    let _id = i.toString() + j.toString();
    if (this.selectSubCategoryIdList.length > 0) {
      for (let subId of this.selectSubCategoryIdList) {
        if (subId === _id) {
          _isSpliced = true;
          let index = this.selectSubCategoryIdList.indexOf(subId);
          this.selectSubCategoryIdList.splice(index, 1);
        }
      }
      if (!_isSpliced) {
        this.selectSubCategoryIdList.push(_id);
      }
    } else {
      this.selectSubCategoryIdList.push(_id);
    }

    if (this.selectCollapseBtn.length > 0) {
      for (let collapse of this.selectCollapseBtn) {
        if (collapse === collapseBtn) {
          _isSplicedCollapseBtn = true;
          let index = this.selectCollapseBtn.indexOf(collapse);
          this.selectCollapseBtn.splice(index, 1);
        }
      }
      if (!_isSplicedCollapseBtn) {
        this.selectCollapseBtn.push(collapseBtn);
      }
    } else {
      this.selectCollapseBtn.push(collapseBtn);
    }
  }


  searchFilter() {
    sessionStorage.setItem('$hideFilterResetBtn', '1');
    this.$hideFilterResetBtn = '1';

    sessionStorage.removeItem('$mainCatID');
    sessionStorage.removeItem('$search');

    sessionStorage.setItem('sortByPrice', this.sortByOrderValue);
    sessionStorage.setItem('locations', JSON.stringify(this.selectProvinceIdList));
    sessionStorage.setItem('ecoShops', JSON.stringify(this.searchEcoShopList));
    sessionStorage.setItem('selectSubCategoryIdList', JSON.stringify(this.selectSubCategoryIdList));
    sessionStorage.setItem('selectCollapseBtn', JSON.stringify(this.selectCollapseBtn));


    let _bestByDate = null;

    if (this.bestBuyDateRange !== undefined) {
      _bestByDate = this.bestBuyDateRange;
    }

    let _selectedProductType = null;

    if (this.selectedProductType !== undefined) {
      _selectedProductType = this.selectedProductType;
    }

    let centerName = null;

    if (this.searchEcoShopList.length > 0) {
      if (this.searchEcoShopList.includes('TABUTTEGAMA')) {
        centerName = 'TABUTTEGAMA';
        this.searchEcoShopList = [];
        this.searchEcoShopIdList = [];
      }
    }

    let _filterBody = {
      'name': null,
      'categoryCode': null,
      'subCategoryCodes': this.searchCategoryList,
      'ratings': this.starArray,
      'minPrice': this.minValue,
      'locations': this.searchProvinceList,
      'maxPrice': this.maxValue,
      'sortByPrice': this.sortByOrderValue,
      'bestByDate': _bestByDate,
      'productType': _selectedProductType,
      'centerName': centerName,
      'shopCodes': this.searchEcoShopList
    };

    sessionStorage.setItem('filterBody', JSON.stringify(_filterBody));

    this.commonPagination(_filterBody, this.paginationNumber);
  }

  setSortByOrder(value) {
    this.sortByOrderValue = value;
    this.searchFilter();
  }

  restFilter() {
    this.$singleViewBack = false;
    sessionStorage.removeItem('filterBody');
    sessionStorage.removeItem('startId');
    sessionStorage.removeItem('minValue');
    sessionStorage.removeItem('maxValue');
    sessionStorage.removeItem('locations');
    sessionStorage.removeItem('ecoShops');
    sessionStorage.removeItem('sortByPrice');
    sessionStorage.removeItem('selectSubCategoryIdList');
    sessionStorage.removeItem('selectCollapseBtn');
    sessionStorage.removeItem('$mainCatID');
    sessionStorage.removeItem('$search');
    sessionStorage.removeItem('$mainCatID');
    sessionStorage.removeItem('$search');
    this.router.navigate(['/refresh_products/' + this.tenantValue]).then(() => {
      this.router.navigate(['/products/' + this.tenantValue], {queryParams: {filterUsername: this.sellerUsername}});
    });
  }

  selectSpotType() {
    this.selectedProductType = 'SPOT';
    this._commonSelectedProductTypeBody(this.selectedProductType);
  }

  selectAuctionType() {
    this.selectedProductType = 'AUCTION';
    this._commonSelectedProductTypeBody(this.selectedProductType);
  }

  _commonSelectedProductTypeBody(type) {
    let body = {
      'name': null,
      'subCategoryCodes': [],
      'ratings': null,
      'minPrice': null,
      'maxPrice': null,
      'categoryCode': null,
      'sortByPrice': null,
      'locations': [],
      'bestByDate': null,
      'isGenerateExcel': false,
      'productType': type,
      'centerName': null,
      'shopCodes': []
    };

    this.commonPagination(body, this.paginationNumber);
  }

  manageMarketList(number: number) {
    this.marketList = number;

    if (number === 2) {
      this.isShowProductLocation = false;
      this.marketList = 0;
    }
    this.storeProductList = this.allSpotProducts;
    this.allSpotProducts = [];
    this.allOrderRequestProducts = [];
    this.allProductCount = this.allSpotProducts.length;
    this.allOrderRequestProductsCount = this.allOrderRequestProducts.length;
    if (number === 0) {
      let body = {
        'name': null,
        'subCategoryCodes': [],
        'ratings': null,
        'minPrice': null,
        'maxPrice': null,
        'categoryCode': null,
        'sortByPrice': null,
        'locations': [],
        'bestByDate': null,
        'productType': null,
        'centerName': null,
        'shopCodes': []
      };

      this.commonPagination(body, this.paginationNumber);
    } else if (number === 1) {
      this.spinnerFilterOrderRequest = true;
      this.productService.fetchOrderRequestByProductStatus('ACTIVE').subscribe(orderRequestResult => {
        if (orderRequestResult) {
          for (let val of orderRequestResult) {
            let image;
            let category;
            category = val.product.subCategory.mainCategory.commonIndex.description;

            if (this.activeLang === 'si') {
              category = val.product.subCategory.mainCategory.commonIndex.sinhala;
            } else if (this.activeLang === 'ta') {
              category = val.product.subCategory.mainCategory.commonIndex.tamil;
            }

            if (category === 'Farming Products & Processed Food') {
              image = 'assets/images/Thumbnails/Processed Products.jpg';
            } else if (category === 'Spices & Cash Crops') {
              image = 'assets/images/Thumbnails/Spices.jpg';
            } else if (category === 'Services & Information') {
              image = 'assets/images/Thumbnails/Services.jpg';
            } else if (category === 'Plants & Seeds') {
              image = 'assets/images/Thumbnails/Seeds.jpg';
            } else if (category === 'Ayurvedic & Herbal Products') {
              image = 'assets/images/Thumbnails/Ayurveda.jpg';
            } else if (category === 'Equipment and Tools') {
              image = 'assets/images/Thumbnails/Equipments.jpg';
            } else {
              image = 'assets/images/Thumbnails/' + val.product.subCategory.mainCategory.commonIndex.description + '.jpg';
            }

            this.allOrderRequestProducts.push({
              id: val.product.id,
              name: val.product.name,
              category: category,
              listedBy: val.product.listedBy,
              created: val.created,
              requiredQty: val.requiredQty,
              location: val.product.address,
              startDate: val.bidAcceptedDate,
              endDate: val.expectedDeliveryDate,
              image: image,
              unitType: val.product.unit.unit,
              sku: val.sku
            });

          }
          this.spinnerFilterOrderRequest = false;
          this.foundResultOrderRequest = this.allOrderRequestProducts.length === 0;
          this.allProductCount = Object.keys(this.allOrderRequestProducts).length;
          if (Object.keys(this.allOrderRequestProducts).length >= 20) {
            this.allOrderRequestProductsCount = 20;
          } else {
            this.allOrderRequestProductsCount = Object.keys(this.allOrderRequestProducts).length;
          }
          Swal.close();

        }
      });
    } else {
      let body = {
        'name': null,
        'subCategoryCodes': [],
        'ratings': null,
        'minPrice': null,
        'maxPrice': null,
        'categoryCode': null,
        'sortByPrice': null,
        'locations': [],
        'bestByDate': null,
        'productType': null,
        'centerName': 'TABUTTEGAMA',
        'shopCodes': []
      };

      this.commonPagination(body, this.paginationNumber);
    }
  }


  pageOrderRequestChanged(p: number, length) {
    if (length < p * 20) {
      this.allOrderRequestProductsCount = length;
    } else {
      this.allOrderRequestProductsCount = p * 20;
    }
  }
}
