import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../../common/common.service';
import {Router} from '@angular/router';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {RiyawiruService} from '../../../../service/riyawiru.service';
import {HttpClient} from '@angular/common/http';
import {CoreService} from '../../../../service/core.service';
import {CartService} from '../../../../service/cart.service';
import {InteractionService} from '../../../../service/interaction.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {MainUrlService} from '../../../../service/main-url.service';
import Swal from 'sweetalert2';
// @ts-ignore
import moment from 'moment';
import {UserTokenDTO} from '../../../../dto/userTokenDTO';

@Component({
  selector: 'app-edit-order-requests',
  templateUrl: './edit-order-requests.component.html',
  styleUrls: ['./edit-order-requests.component.css']
})
export class EditOrderRequestsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['orderTitle', 'myBidAmount', 'bidPlacementDate', 'bidEndDate', 'status', 'action'];
  tenantValue: any = 'wholesale';
  activeLang: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  imgUrl: any = 'assets/avatar.jpg';
  isBuyer = false;


  riyaviruModalData: any;
  riyawiruVehicleTypes: any;
  @ViewChild('transportModal', {static: false}) transportModal;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;

  prdImg: any;
  myBidValue: any;
  bidStartDate: any;
  bidEndDate: any;
  productName: any;
  sellerUsername: any;


  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              private router: Router,
              private riyawiruService: RiyawiruService,
              private http: HttpClient,
              private coreService: CoreService,
              private cartService: CartService,
              private interactionService: InteractionService,
              private settingsService: SystemSettingsService,
              public mainUrl: MainUrlService) {
    this.activeLang = sessionStorage.getItem('LANG');

  }

  ngOnInit() {
    this.loadOrderRequestProducts();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

  loadOrderRequestProducts() {
    this.commonService.processing();
    this.dataSource = new MatTableDataSource();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.productService.myOrderRequestBidProductDetails(whoAmIDetails['email']).subscribe(getMyBidProducts => {
        let arr = [];
        let bidStartDate;
        for (let item of getMyBidProducts) {

          for (let el of item.product['bidDetails']) {
            if (parseFloat(el.bidValue) === parseFloat(item.bidValue)) {
              bidStartDate = el.created;
            }
          }
          arr.push({
            'orderTitle': item.product.product['name'],
            'sku': item.product['sku'],
            'productId': item.product.product['id'],
            'typeId': item.product.product.type.id,
            'typeName': item.product.product.type.type,
            'myBidAmount': item.bidValue,
            'startBidAmount': item.product['startPrice'],
            'bidPlacementDate': bidStartDate,
            'bidEndDate': item.product['bidEndDate'],
            'status': item.status,
            'bidStatus': item.product['status']
          });
        }
        this.dataSource = new MatTableDataSource(arr);
        this.dataSource.paginator = this.paginator;
        Swal.close();
      }, () => {
        Swal.close();
      });
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  getTime(created) {
    return moment(created).format('LTS');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }


  userTokenDTO = new UserTokenDTO;

  getUser() {
    this.userTokenDTO.userName = this.sellerUsername;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'assets/avatar.jpg';
      }
    }, () => {
      this.imgUrl = 'assets/avatar.jpg';
    });
  }

  sku: any;

  buyNow() {
    this.router.navigate(['/checkout/' + this.tenantValue]);
  }

}
