<div class="p-0 p0sidebar">
    <div class="form-sidebar">
      <div class="text-center">
        <button class="btn btn-success d-green-btn"
                [routerLink]="'/value-added-services/wholesale'">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.VALUE_ADDED_SERVICES'|translate}}
        </button>
      </div>
        <div class="sidebar-links">
            <li class="pckge-link active" (click)="activeTab=1" [ngClass]="{'active':activeTab==1}"
                [routerLink]="['/value-added-services/insurance-plans/'+ tenantValue]">
                <a *ngIf="activeLang === 'en'" class="sidebar-navs">Insurance Application</a>
                <a *ngIf="activeLang === 'si'" class="sidebar-navs">රක්‍ෂණ අයදුම්පත</a>
                <a *ngIf="activeLang === 'ta'" class="sidebar-navs">காப்பீட்டு விண்ணப்பம்</a>
            </li>
        </div>
    </div>
</div>
