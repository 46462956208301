
<div class="container-fluid">
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title></title>
  </head>

  <body>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
    <strong
    ><span
      style='font-size:27px;line-height:115%;font-family:"Times New Roman",serif;'
    >Privacy and Confidentiality</span
    ></strong
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >Welcome to the helaviru.lk website (hereinafter called and referred as
        the &quot;Site&quot;) jointly operated by M/s Epic Green Eco Systems
        (Private) Limited (hereinafter called and referred as &ldquo;Epic
        Green&rdquo;) and M/s Sri Lanka Telecom PLC (hereinafter called and
        referred as &ldquo;SLT&rdquo;). We respect your privacy and therefore we
        would like to protect your personal information. To learn more, please
        read this Privacy Policy.<br />&nbsp;<br />&nbsp;This Privacy Policy
        explains how we collect, use and (under certain conditions) disclose
        your personal information. It also explains the steps we have taken to
        secure your personal information. Further, this Privacy Policy describes
        your options regarding the collection, use and disclosure of your
        personal information. By visiting the Site directly or through another
        site, you accept the practices described in this Policy.<br />&nbsp;<br />&nbsp;Data
        protection is a matter of trust. Therefore, your privacy is of utmost
        important to us. We shall therefore use your personal information in the
        manner set out in this Privacy Policy. We will collect personal
        information where it is necessary and relevant to the transactions on
        the Platform.<br />&nbsp;<br />&nbsp;We will keep your information with
        us as long as we are either required to so by law or as is will be
        relevant for the purposes for which the information was collected.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      ><br />&nbsp;You can visit the Site and browse without having to provide
        personal details. During your visit to the Site you remain anonymous and
        at no instance can we identify you unless you have an account on the
        Site and log on with your username and password.</span
      >
  </p>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'
      >
        <h2
          style='margin-top:2.0pt;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:107%;font-size:17px;font-family:"Calibri Light",sans-serif;color:#2F5496;font-weight:normal;'
        >
          <strong
          ><span
            style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:14.0pt;color:windowtext;'
          >Data that we collect</span
          ></strong
          >
        </h2>
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >We may collect various and relevant information if you seek to place an
        order for a product with us on the Site.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      ><br />&nbsp;We collect, store and process your data for processing your
        purchase on the Site and any possible later claims, and to provide you
        with our services. We may collect personal information including, but
        not limited to, your title, name, email address, postal address,
        delivery address (if different), telephone number, mobile number,
        payment details, payment card details or bank account details.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      ><br />&nbsp;We will use the information you provide to enable us to
        process your orders and to provide you with the services and information
        offered through our website and which you request. Further, we will use
        the information you provide to administer your account with us; verify
        and carry out financial transactions in relation to payments you make;
        audit the downloading of data from our website; improve the layout
        and/or content of the pages of our website and customize them for users;
        identify visitors on our website; carry out research on our users&apos;
        demographics; send you information we think you may find useful or which
        you have requested from us, including information about our products and
        services, provided you have indicated that you have not objected to
        being contacted for these purposes. Subject to obtaining your consent we
        may contact you by email with details of other products and services. If
        you prefer not to receive any marketing communications from us, you can
        opt out at any time.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      ><br />&nbsp;We may pass your name and address on to a third party in
        order to make delivery of the product to you (for example to our courier
        or supplier). You must only submit to us the Site information which is
        accurate and not misleading and you must keep it up to date and inform
        us of changes.<br />&nbsp;<br />&nbsp;Your actual order details may be
        stored with us but for security reasons cannot be retrieved directly by
        us. However, you may access this information by logging into your
        account on the Site. Here you can view the details of your orders that
        have been completed, those which are open and those which are shortly to
        be dispatched and administer your address details, bank details ( for
        refund purposes) and any newsletter to which you may have subscribed.
        You undertake to treat the personal access data confidentially and not
        make it available to unauthorized third parties. We cannot assume any
        liability for misuse of passwords unless this misuse is our fault.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
    <strong
    ><span
      style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
    >Other uses of your Personal Information</span
    ></strong
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >We may use your personal information for opinion and market research.
        Your details are anonymous and will only be used for statistical
        purposes. You can choose to opt out of this at any time. Any answers to
        surveys or opinion polls we may ask you to complete will not be
        forwarded on to third parties. Disclosing your email address is only
        necessary if you would like to take part in competitions. We save the
        answers to our surveys separately from your email address.<br />&nbsp;<br />&nbsp;We
        may also send you other information about us, the Site, our other
        websites, our products, sales promotions, our newsletters, anything
        relating to other companies in our group or our business partners. If
        you would prefer not to receive any of this additional information as
        detailed in this paragraph (or any part of it) please click the
        &apos;unsubscribe&apos; link in any email that we send to you. Within 7
        working days (days which are neither (i) a Sunday, nor (ii) a public
        holiday anywhere in Sri Lanka) of receipt of your instruction we will
        cease to send you information as requested. If your instruction is
        unclear we will contact you for clarification.<br />&nbsp;<br />&nbsp;We
        may further anonymize data about users of the Site generally and use it
        for various purposes, including ascertaining the general location of the
        users and usage of certain aspects of the Site or a link contained in an
        email to those registered to receive them, and supplying that anonymized
        data to third parties such as publishers. However, that anonymized data
        will not be capable of identifying you personally.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
    <strong
    ><span
      style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
    >Competitions</span
    ></strong
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >For any competition we use the data to notify winners and advertise our
        offers. You can find more details where applicable in our participation
        terms for the respective competition.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >&nbsp;</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
    <strong
    ><span
      style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
    >Third Parties and Links</span
    ></strong
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >We may pass your details to other companies in our group. We may also
        pass your details to our agents and subcontractors to help us with any
        of our uses of your data set out in our Privacy Policy. For example, we
        may use third parties to assist us with delivering products to you, to
        help us to collect payments from you, to analyze data and to provide us
        with marketing or customer service assistance.<br />&nbsp;<br />&nbsp;We
        may exchange information with third parties for the purposes of fraud
        protection and credit risk reduction. We may transfer our databases
        containing your personal information if we sell our business or part of
        it. Other than as set out in this Privacy Policy, we shall NOT sell or
        disclose your personal data to third parties without obtaining your
        prior consent unless this is necessary for the purposes set out in this
        Privacy Policy or unless we are required to do so by law. The Site may
        contain advertising of third parties and links to other sites or frames
        of other sites. Please be aware that we are not responsible for the
        privacy practices or content of those third parties or other sites, nor
        for any third party to whom we transfer your data in accordance with our
        Privacy Policy.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      ><br />&nbsp;</span
      ><strong
  ><span
    style='font-size:19px;line-height:115%;font-family:"Times New Roman",serif;'
  >2. Cookies</span
  ></strong
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >The acceptance of cookies is not a requirement for visiting the Site.
        However, we would like to point out that the use of the
        &apos;basket&apos; functionality on the Site and ordering is only
        possible with the activation of cookies. Cookies are tiny text files
        which identify your computer to our server as a unique user when you
        visit certain pages on the Site, and they are stored by your Internet
        browser on your computer&apos;s hard drive. Cookies can be used to
        recognize your Internet Protocol address, saving you time while you are
        on, or want to enter, the Site. We only use cookies for your convenience
        in using the Site (for example to remember who you are when you want to
        amend your shopping cart without having to re-enter your email address)
        and not for obtaining or using any other information about you (for
        example targeted advertising). Your browser can be set to not accept
        cookies, but this would restrict your use of the Site. Please accept our
        assurance that our use of cookies does not contain any personal or
        private details and are free from viruses. If you want to find out more
        information about cookies, go to http://www.allaboutcookies.org or to
        find out about removing them from your browser, go to
        http://www.allaboutcookies.org/manage-cookies/index.html.<br />&nbsp;<br />&nbsp;This
        website uses Google Analytics, a web analytics service provided by
        Google, Inc. (&quot;Google&quot;). Google Analytics uses cookies, which
        are text files placed on your computer, to help the website analyze how
        users use the site. The information generated by the cookie about your
        use of the website (including your IP address) will be transmitted to
        and stored by Google on servers in the United States. Google will use
        this information for the purpose of evaluating your use of the website,
        compiling reports on website activity for website operators and
        providing other services relating to website activity and internet
        usage. Google may also transfer this information to third parties where
        required to do so by law, or where such third parties process the
        information on Google&apos;s behalf. Google will not associate your IP
        address with any other data held by Google. You may refuse the use of
        cookies by selecting the appropriate settings on your browser, however,
        please note that if you do this you may not be able to use the full
        functionality of this website. By using this website, you consent to the
        processing of data about you by Google in the manner and for the
        purposes set out above.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
    <strong
    ><span
      style='font-size:19px;line-height:115%;font-family:"Times New Roman",serif;'
    >3. Security</span
    ></strong
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >We have in place appropriate technical and security measures to prevent
        unauthorized or unlawful access to or accidental loss of or destruction
        or damage to your information. When we collect data through the Site, we
        collect your personal details on a secure server. We use firewalls on
        our servers. Our security procedures mean that we may occasionally
        request proof of identity before we disclose personal information to
        you. You are responsible for protecting against unauthorized access to
        your password and to your computer.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
    <strong
    ><span
      style='font-size:19px;line-height:115%;font-family:"Times New Roman",serif;'
    >4. Your rights</span
    ></strong
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
  >
      <span
        style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'
      >If you are concerned about your data, you have the right to request
        access to the personal data which we may hold or process about you. You
        have the right to require us to correct any inaccuracies in your data
        free of charge. At any stage you also have the right to ask us to stop
        using your personal data for direct marketing purposes.</span
      >
  </p>
  </body>
  </html>
</div>
