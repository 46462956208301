<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12" id="sellerprofile">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_ACCOUNT.MY_ACCOUNT' | translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 d-flex flex-row po-top-info">
              <div class="profile-img">
                <img [src]="imgUrl" alt="" style="height: 100px;">
              </div>
              <div class="d-flex justify-content-center flex-column pl-2">
                <h5 class="font-weight-bold">{{pendingListDetails?.name}} ({{pendingListDetails?.refNo}})</h5>
                <p class="m-0 p-0">
                  <span *ngIf="activeLang === 'en'">{{pendingListDetails?.userTypeDTO?.english}}</span>
                  <span *ngIf="activeLang === 'si'">{{pendingListDetails?.userTypeDTO?.sinhala}}</span>
                  <span *ngIf="activeLang === 'ta'">{{pendingListDetails?.userTypeDTO?.tamil}}</span>
                  -
                  <span *ngIf="activeLang === 'en'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameEN}}</span>
                  <span *ngIf="activeLang === 'si'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameSI}}</span>
                  <span *ngIf="activeLang === 'ta'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameTA}}</span>
                </p>
                <p class="m-0 p-0">
                  {{userDetailsList?.addressesDTO?.addressOne}}&nbsp;{{userDetailsList?.addressesDTO?.addressTwo}}
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <div class="po-table-modal">
                <table>
                  <thead>
                  <tr>
                    <th scope="col">{{'MY_ACCOUNT.MY_PROFILE.TYPE' | translate}}</th>
                    <th scope="col">{{'MY_ACCOUNT.MY_PROFILE.DISTRICT' | translate}}</th>
                    <th scope="col">{{'MY_ACCOUNT.MY_PROFILE.PHONE' | translate}}</th>
                    <th scope="col">{{'MY_ACCOUNT.MY_PROFILE.NIC' | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{pendingListDetails?.type}}
                    </td>
                    <td>
                      <span *ngIf="activeLang === 'en'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameEN}}</span>
                      <span *ngIf="activeLang === 'si'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameSI}}</span>
                      <span *ngIf="activeLang === 'ta'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameTA}}</span>
                    </td>
                    <td>{{userDetailsList?.mobile}}</td>
                    <td>{{userDetailsList?.nic}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12 earning-info" *ngIf="pendingListDetails?.type!=='Buyer'">
              <div class="earnings d-flex">
                <div class="payments total">
                  <h5>{{'MY_ACCOUNT.EARNING.TOTAL_EARNING' | translate}}</h5>
                  <h3>{{'CURRENCY.CURRENCY' | translate}} 00.00</h3>
                </div>
                <div class="payments">
                  <h5>{{'MY_ACCOUNT.EARNING.PENDING_PAYMENT' | translate}}</h5>
                  <h3>{{'CURRENCY.CURRENCY' | translate}} 00.00</h3>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
            <div class="col-md-12 earning-info">
              <div class="d-flex">
                <div class="left-col">
                  <h3>{{'MY_ACCOUNT.MY_PROFILE.PERSONAL_INFO' | translate}}</h3>
                  <ul>
                    <li class="datalist"><span class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.FARMER' | translate}} </span>
                      <span>{{userDetailsList?.name}}</span></li>
                    <li class="datalist"><span class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.NIC' | translate}} </span>
                      <span>{{userDetailsList?.nic}}</span>
                    </li>
                    <li class="datalist"><span class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.PHONE' | translate}}</span>
                      <span>{{userDetailsList?.mobile}}</span>
                    </li>
                    <li class="datalist"><span class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.ADDRESS' | translate}} </span>
                      <span>{{userDetailsList?.addressesDTO?.addressOne}}&nbsp;{{userDetailsList?.addressesDTO?.addressTwo}}</span></li>
                    <li class="datalist"><span
                      class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.SECURE_PIN' | translate}} </span> <span>****</span>
                    </li>
                  </ul>
                  <a [routerLink]="['/my-account/settings/'+ tenantValue]" style="font-size: 14px;"><i
                    class="fa fa-pencil" aria-hidden="true"></i> {{'MY_ACCOUNT.MY_PROFILE.EDIT_PERSONAL_INFO' |
                    translate}}</a>
                </div>
                <div class="right-col">
                  <h3>Land Info</h3>
                  <ul>
                    <li class="datalist"><span class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.PROVINCE' | translate}} </span>
                      <span>
                        <span *ngIf="activeLang === 'en'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.province?.nameEN}}</span>
                        <span *ngIf="activeLang === 'si'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.province?.nameSI}}</span>
                        <span *ngIf="activeLang === 'ta'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.province?.nameTA}}</span>
                      </span>
                    </li>
                    <li class="datalist"><span class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.DISTRICT' | translate}} </span>
                      <span>
                                        <span *ngIf="activeLang === 'en'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameEN}}</span>
                      <span *ngIf="activeLang === 'si'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameSI}}</span>
                      <span *ngIf="activeLang === 'ta'">{{userDetailsList?.addressesDTO?.cities?.agriDistrict?.nameTA}}</span>
                      </span>
                    </li>
<!--                    <li class="datalist"><span-->
<!--                      class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.DIVISIONAL_SEC' | translate}}</span>-->
<!--                      <span>-->
<!--                        <span *ngIf="activeLang === 'en'">{{pendingListDetails?.ds}}</span>-->
<!--                        <span *ngIf="activeLang === 'si'">{{pendingListDetails?.dsSI}}</span>-->
<!--                        <span *ngIf="activeLang === 'ta'">{{pendingListDetails?.dsTA}}</span>-->
<!--                      </span>-->
<!--                    </li>-->
<!--                    <li class="datalist"><span-->
<!--                      class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.GRAMA_AREA' | translate}} </span>-->
<!--                      <span>-->
<!--                        <span *ngIf="activeLang === 'en'">{{pendingListDetails?.gn}}</span>-->
<!--                        <span *ngIf="activeLang === 'si'">{{pendingListDetails?.gnSI}}</span>-->
<!--                        <span *ngIf="activeLang === 'ta'">{{pendingListDetails?.gnTA}}</span>-->
<!--                      </span>-->
<!--                    </li>-->
                    <div *ngIf="pendingListDetails?.type!=='Buyer'">
                      <li class="datalist"><span
                        class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.FARMING_LAND_AREA' | translate}}</span>
                        <span>{{pendingListDetails?.area}}
                                                </span></li>
                      <li class="datalist"><span
                        class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.LAND_UNIT' | translate}}</span>
                        <span>{{pendingListDetails?.unit}}</span>
                      </li>
                      <li class="datalist"><span
                        class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.WATER_RESOURCE' | translate}}</span>
                        <span>{{pendingListDetails?.water}}</span>
                      </li>
                      <li class="datalist"><span
                        class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.OWNERSHIP' | translate}}</span>
                        <span>{{pendingListDetails?.ownership}}</span>
                      </li>
                      <li class="datalist"><span
                        class="boldtxt">{{'MY_ACCOUNT.MY_PROFILE.NATURE_OF_CULTIVATION' | translate}}</span>
                        <span>{{pendingListDetails?.natureOfCultivation}}</span>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>

            <div class="col-md-12 addres-info">
              <div class="addrescards d-flex">
                <div class="addr-item">
                  <h5>{{userDetailsList?.name}} (Primary)
                  </h5>
                  {{userDetailsList?.addressesDTO?.addressOne}}&nbsp;{{userDetailsList?.addressesDTO?.addressTwo}}
                </div>
              </div>
            </div>
            <div *ngIf="pendingListDetails?.leadCode" class="col-12">
              <div class="separator">&nbsp;</div>
            </div>

            <div *ngIf="pendingListDetails?.leadCode" class="col-md-12 addres-info">
              <div class="addrescards d-flex">
                <div class="addr-item">
                  <h5>
                    {{'MY_ACCOUNT.MY_PROFILE.LEAD_INFO' | translate}}
                  </h5>
                  <table>
                    <tr>
                      <td>
                        {{'MY_ACCOUNT.MY_PROFILE.LEAD_CODE' | translate}}
                        {{pendingListDetails?.leadCode}} </td>
                    </tr>
                    <tr>
                      <td>
                        {{'MY_ACCOUNT.MY_PROFILE.LEAD_NAME' | translate}}
                        {{pendingListDetails?.leadName}} </td>
                    </tr>
                    <tr>
                      <td>
                        {{'MY_ACCOUNT.MY_PROFILE.LEAD_CONTACT' | translate}}
                        {{pendingListDetails?.leadMobile}} </td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>

            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>

            <div class="col-md-12 addres-info">
              <div class="addrescards d-flex">
                <div class="addr-item">
                  <span class="boldtxt">
                    {{'MY_ACCOUNT.MY_PROFILE.SMS_LANGUAGES' | translate}}&nbsp;</span>
                  <select [(ngModel)]="language" (change)="saveLanguage()">
                    <option value="EN">English</option>
                    <option value="SI">සිංහල</option>
                    <option value="TA">தமிழ்</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
