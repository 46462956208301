import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../../common/common.service';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RiyawiruService} from '../../../../service/riyawiru.service';
import {HttpClient} from '@angular/common/http';
import {CoreService} from '../../../../service/core.service';
import {CartService} from '../../../../service/cart.service';
import {InteractionService} from '../../../../service/interaction.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {MainUrlService} from '../../../../service/main-url.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-my-order-request-bids',
  templateUrl: './view-my-order-request-bids.component.html',
  styleUrls: ['./view-my-order-request-bids.component.css']
})
export class ViewMyOrderRequestBidsComponent implements OnInit {


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['orderTitle', 'bidAmount', 'placedBidDate', 'bidEndDate', 'status'];
  tenantValue: any = 'wholesale';
  activeLang: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  imgUrl: any = 'assets/avatar.jpg';

  bidStartDate: any;
  bidEndDate: any;
  productName: any;
  userRole: any;
  ecoShopCode: any;
  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;


  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              private router: Router,
              private riyawiruService: RiyawiruService,
              private route: ActivatedRoute,
              private http: HttpClient,
              private coreService: CoreService,
              private cartService: CartService,
              private interactionService: InteractionService,
              private settingsService: SystemSettingsService,
              public mainUrl: MainUrlService) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.commonService.processing();

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });

  }

  ngOnInit() {
    this.loadOrderRequestProducts();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

  loadOrderRequestProducts() {
    this.dataSource = new MatTableDataSource();
    if (this.isEcoFarmerSection && this.isEconomicCenter) {
      this.productService.findAllMyOrderRequestBids(this.ecoShopCode).subscribe(ecoShopBidProducts => {
        this.commonResponseBody(ecoShopBidProducts);
      }, () => {
        Swal.close();
      });
    } else {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.productService.findAllMyOrderRequestBids(whoAmIDetails['email']).subscribe(getMyBidProducts => {
          this.commonResponseBody(getMyBidProducts);
        }, () => {
          Swal.close();
        });
      });
    }

  }

  commonResponseBody(vales) {
    let arr = [];
    for (let item of vales) {

      arr.push({
        'orderTitle': item.orderRequest.product['name'],
        'sku': item.orderRequest.sku,
        'bidAmount': item.bidAmount,
        'placedBidDate': item.created,
        'bidEndDate': item.orderRequest.expectedDeliveryDate,
        'status': item.status
      });
    }
    this.dataSource = new MatTableDataSource(arr);
    this.dataSource.paginator = this.paginator;
    Swal.close();
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

}
