import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MainUrlService} from './main-url.service';


@Injectable({
  providedIn: 'root'
})
export class CommonSignUpService {

  constructor(private http: HttpClient, private  mainUrl: MainUrlService) {
  }

  saveAgent(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/agent/save', body);
  }

  saveEconomicCenterShopOwner(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/economic/shop/save', body);
  }

  saveFarmer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/farmer/save', body);
  }

  fetchAllProvince(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchActiveProvinces');
  }

  fetchAllEcoCenters(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/economic/shop/getShops');
  }

  fetchAllDistrict(id: number): Observable<any> {
    return this.http.get<any>
    (this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchActiveDistrictList/' + id);
  }

  fetchAllDivision(id: number): Observable<any> {
    return this.http.get<any>
    (this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchActiveDivSecList/' + id);
  }

  fetchAllCitiesByDistrictId(id: number): Observable<any> {
    return this.http.get<any>
    (this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchCities/' + id);
  }

  fetchAllGrama(id: number): Observable<any> {
    return this.http.get<any>
    (this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchActiveGramaNilList/' + id);
  }

  fetchAllAgri(id: number): Observable<any> {
    return this.http.get<any>
    (this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchAgriculturalDivisionList/' + id);
  }

  fetchAllInstruct(id: number): Observable<any> {
    return this.http.get<any>
    (this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchAgrarianInstructionalDivList/' + id);
  }

  fetchAllCities(id: number): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchCities/' + id);
  }

  fetchCityById(id: number): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/location/fetchCityById/' + id);
  }

  saveWholesaleSeller(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/saveWholesaleSeller', body);
  }

  saveWholesaleBuyer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/wholesalebuyer/save', body);
  }

  saveCollector(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/aggregator/save', body);
  }

  saveRetailer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/retailer/save', body);
  }

  saveConsumer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/consumer/save', body);
  }

  saveExporter(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/exporter/save', body);
  }


  saveServiceProvider(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/serviceprovider/save', body);
  }

  saveCompanyFarmer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/farmer/registerCompany1', body);
  }

  saveCompanyConsumer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/companyconsumer/save', body);
  }

  saveCompanyExporter(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/exporter/registerCompany', body);
  }

  saveCompanyServiceProvider(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/serviceprovider/registerCompany', body);
  }

  saveCompanyCollector(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/aggregator/registerCompany', body);
  }

  saveCompanyWholesaleBuyer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/wholesalebuyer/saveCompany', body);
  }

  saveCompanyRetailer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/retailer/saveCompany', body);
  }

  fetchGramaNiladariById(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/location/getGramaNiladari/' + id);
  }

  saveNewAddresses(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/address/saveAddress', body);
  }
}
