import {Component, OnInit, ViewChild} from '@angular/core';
import {MyAccountServiceService} from '../../my-account-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../../../common/common.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {CoreService} from '../../../../service/core.service';
import {UserTokenDTO} from '../../../../dto/userTokenDTO';
import {CommonSignUpService} from '../../../../service/common-sign-up.service';
import {InteractionService} from '../../../../service/interaction.service';
import {Md5} from 'ts-md5';
import {MainUrlService} from '../../../../service/main-url.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  tenantValue;
  userTokenDTO = new UserTokenDTO;
  name: any;
  mobile: any;
  imgUrl: any = 'assets/avatar.jpg';
  gramaId: any;
  refNo: any;
  userRole: any;
  divisional_secretary: any;
  loadingData: boolean;
  isUpdateProductsList: boolean = false;
  isEconomicCenter: boolean = false;
  isShowRef: boolean = false;
  locationEN: any;
  locationSI: any;
  locationTA: any;
  activeLang: string;
  tempUserRole: any;
  economicCenterDetails: any;
  proTabItem = 1;

  @ViewChild('sellAProductModal') sellAProductModal;

  constructor(private sellProduct: MyAccountServiceService,
              private route: ActivatedRoute,
              public commonService: CommonService,
              public mainUrl: MainUrlService,
              private router: Router,
              private commonSignUpService: CommonSignUpService,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,
              private orderRequest: MyAccountServiceService,
              private coreService: CoreService) {
    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    sessionStorage.removeItem('FARMER_USERNAME');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit() {
    this.activeLang = sessionStorage.getItem('LANG');
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
    this.loadingData = true;
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      let hashStr;
      if (whoAmIDetails['email'] !== null) {
        hashStr = Md5.hashStr(whoAmIDetails['email']);
      } else {
        hashStr = Md5.hashStr('');
      }

      this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';

      this.name = whoAmIDetails['name'];
      this.mobile = whoAmIDetails['mobileNumber'];

      if (this.userRole === 'LEAD') {
        this.isShowRef = true;
        this.getRefNo(whoAmIDetails['email']);
      } else if (this.userRole === 'ECONOMIC_SHOP') {
        this.getRefNo(whoAmIDetails['email']);
      }

      this.getUser(whoAmIDetails['email']);
    });

    this.loadingData = false;

    this.interactionService.updateProductListingTable.subscribe(isUpdate => {
      if (isUpdate) {
        this.isUpdateProductsList = isUpdate;
      } else {
        this.isUpdateProductsList = false;
      }
    });

    this.interactionService.updateProfileInfo.subscribe(isUpdate => {
      if (isUpdate) {
        this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
          this.getUser(whoAmIDetails['email']);
        });
      }
    });

    this.interactionService.openSellAProduct.subscribe(isUpdate => {
      if (isUpdate) {
        this.sellAProductModal.nativeElement.click();
      }
    });

    this.interactionService.sellAProductButton.subscribe(isUpdate => {
      if (isUpdate && this.sellAProductModal !== undefined) {
        this.sellAProductModal.nativeElement.click();
      }
    });
  }


  openModal() {
    this.sellProduct.toggleModal.emit('0');
  }

  openModalOrder() {
    this.orderRequest.toggleModal.emit('0');
  }

  getRefNo(username) {
    this.coreService.fetchUserRefNo(username).subscribe(result => {
      if (result) {
        this.refNo = result.message;
      }
    });
  }

  getUser(username) {
    let body = {
      'userName': username,
      'token': 'Bearer ' + localStorage.getItem('$Token')
    };
    this.coreService.fetchUserDetailsByUserName(body).subscribe(result => {
      if (result) {
        let hashStr;
        if (username !== null) {
          hashStr = Md5.hashStr(username);
        } else {
          hashStr = Md5.hashStr('');
        }
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
        this.gramaId = result.gramaNil;
        if (this.gramaId !== null) {
          this.commonSignUpService.fetchGramaNiladariById(this.gramaId).subscribe(location => {
            this.locationEN = location['divisionalSecretariat']['nameEN'];
            this.locationSI = location['divisionalSecretariat']['nameSI'];
            this.locationTA = location['divisionalSecretariat']['nameTA'];
          });
        }
      }
    });
  }

  goEcoFarmerOverview() {
    this.proTabItem = 2;
    this.interactionService.activeLang(true);
  }
}
