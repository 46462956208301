<app-header></app-header>

<div class="main-container" style="background-color: #f8f9fa;height: 100%;">
  <div class="container about-page">
    <ngx-doc-viewer [url]="fileURL" viewer="google" style="width:100%;height:80vh;"></ngx-doc-viewer>
  </div>

</div>

<app-footer></app-footer>
