import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ValidationHandlerService} from '../../../../../../validation-handler/validation-handler.service';
import {CoreService} from '../../../../../../service/core.service';
import {ActivatedRoute} from '@angular/router';
import {InteractionService} from '../../../../../../service/interaction.service';
import {CommonService} from '../../../../../../common/common.service';
import {UserRoleService} from '../../../../../../service/user-role.service';

@Component({
  selector: 'app-accident-application',
  templateUrl: './accident-application.component.html',
  styleUrls: ['./accident-application.component.css']
})
export class AccidentApplicationComponent implements OnInit {

  section: number = 0;

  form: FormGroup;
  activeLang: any;
  submitted = false;

  applicationDate: any = new Date().toISOString().slice(0, 10);
  signatureOfProposer: any;
  insured: any;
  address: any;
  nic: any;
  professionOrOccupation: any;
  contactNo: any;

  userDetails: any;

  @ViewChild('openSuccessModal', {static: true}) openSuccessModal;
  @ViewChild('openDraftModal', {static: true}) openDraftModal;

  draftDetails: any;
  id: any;
  userName: any;
  formType: any;
  status: any;
  isDraft: boolean = false;

  decryptedInfo: any;

  nominee0Row1: any = null;
  nominee1Row1: any;
  nominee2Row1: any;
  nominee3Row1: any;

  nominee0Row2: any = null;
  nominee1Row2: any;
  nominee2Row2: any;
  nominee3Row2: any;

  nominee0Row3: any = null;
  nominee1Row3: any;
  nominee2Row3: any;
  nominee3Row3: any;

  nominee0Row4: any = null;
  nominee1Row4: any;
  nominee2Row4: any;
  nominee3Row4: any;

  nomineeList: any = [];
  invalidTableValues: boolean = false;

  constructor(private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              public commonService: CommonService,
              private userRoleService: UserRoleService) {
    this.validationForm();
  }

  get f() {
    return this.form.controls;
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isDraft = params['isDRAFT'];
      if (params['isDRAFT']) {
        this.commonService.processing();

        this.draftDetails = JSON.parse(decodeURIComponent(params['data']));

        this.id = this.draftDetails['id'];

        this.coreService.accidentInsuranceSLICRequestsById(this.id).subscribe(insuranceDetails => {
          Swal.close();

          if (insuranceDetails['nominees'].length > 0) {

            for (let i = 1; i <= insuranceDetails['nominees'].length; i++) {

              switch (i) {

                case 1:

                  this.nominee0Row1 = insuranceDetails['nominees'][0]['id'];
                  this.nominee1Row1 = insuranceDetails['nominees'][0]['name'];
                  this.nominee2Row1 = insuranceDetails['nominees'][0]['nic'];
                  this.nominee3Row1 = insuranceDetails['nominees'][0]['percentage'];
                  break;

                case 2:

                  this.nominee0Row2 = insuranceDetails['nominees'][0]['id'];
                  this.nominee1Row2 = insuranceDetails['nominees'][0]['name'];
                  this.nominee2Row2 = insuranceDetails['nominees'][0]['nic'];
                  this.nominee3Row2 = insuranceDetails['nominees'][0]['percentage'];
                  break;

                case 3:

                  this.nominee0Row3 = insuranceDetails['nominees'][0]['id'];
                  this.nominee1Row3 = insuranceDetails['nominees'][0]['name'];
                  this.nominee2Row3 = insuranceDetails['nominees'][0]['nic'];
                  this.nominee3Row3 = insuranceDetails['nominees'][0]['percentage'];
                  break;

                case  4:

                  this.nominee0Row4 = insuranceDetails['nominees'][0]['id'];
                  this.nominee1Row4 = insuranceDetails['nominees'][0]['name'];
                  this.nominee2Row4 = insuranceDetails['nominees'][0]['nic'];
                  this.nominee3Row4 = insuranceDetails['nominees'][0]['percentage'];
                  break;

              }
            }


          }
          this.userName = insuranceDetails['userName'];
          this.form.patchValue({
            address: insuranceDetails['address'],
            nic: insuranceDetails['nic'],
            insured: insuranceDetails['insured'],
            professionOrOccupation: insuranceDetails['professionOrOccupation'],
            contactNo: insuranceDetails['contactNo'],
            applicationDate: new Date().toISOString().slice(0, 10),
            signatureOfProposer: insuranceDetails['signatureOfProposer'],
            id: insuranceDetails['id'],
            created: insuranceDetails['created'],
            formType: insuranceDetails['formType'],
            status: insuranceDetails['status'],
          });

        });
      }

    });

    if (!this.isDraft) {
      this.fetchUserDetails();
    }

    this.activeLang = sessionStorage.getItem('LANG');
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }


  validationForm() {
    this.form = this.formBuilder.group({
      address: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      nic: ['', [Validators.required]],
      insured: ['', Validators.required],
      professionOrOccupation: ['', Validators.required],
      contactNo: ['', Validators.required],
      applicationDate: ['', Validators.required],
      signatureOfProposer: ['', Validators.required],
      id: [''],
      nominees: [''],
      userName: [''],
      created: [''],
      formType: [''],
      status: ['']
    });
  }


  fetchUserDetails() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.userRoleService.fetchUserDetails(whoAmI['email']).subscribe(userDetails => {
        if (userDetails) {
          this.userDetails = userDetails;
          this.address = this.userDetails.address;
          this.insured = this.userDetails.name;
          this.contactNo = this.userDetails.contactNo;
          this.nic = this.userDetails.nic;
          this.form.patchValue({
            insured: this.insured,
            nic: this.nic,
            address: this.address,
            contactNo: this.contactNo,
            formType: 'FOR_ACCIDENT_INSURANCE',
            userName: whoAmI['email'],
          });
          Swal.close();
        }
      });
    });
  }


  submitForm(status) {

    if (this.isDraft) {
      this.form.patchValue({
        id: this.id,
        formType: 'FOR_ACCIDENT_INSURANCE',
        userName: this.userName,
      });
    }

    if (this.form.invalid && status == 'PENDING') {
      return;
    }
    this.commonService.processing();

    this.form.patchValue({
      status: status
    });


    this.coreService.requestAccidentInsuranceSLIC(this.form.value).subscribe(result => {
      Swal.close();
      if (result['success']) {
        if (status === 'PENDING') {
          this.openSuccessModal.nativeElement.click();
        } else {
          this.openDraftModal.nativeElement.click();
        }
      }
    }, () => {
      Swal.close();
    });

  }

  nextPage() {

    if (this.nomineeList.length === 0) {
      this.invalidTableValues = true;
    }

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.nominee1Row1 !== undefined && this.nominee2Row1 !== undefined && this.nominee3Row1 !== undefined) {
      this.nomineeList.push(this.commonNomineeBody(
        this.nominee0Row1,
        this.nominee1Row1,
        this.nominee2Row1,
        this.nominee3Row1));
    } else {
      this.nominee1Row1 = undefined;
      this.nominee2Row1 = undefined;
      this.nominee3Row1 = undefined;
    }

    if (this.nominee1Row2 !== undefined && this.nominee2Row2 !== undefined && this.nominee3Row2 !== undefined) {
      this.nomineeList.push(this.commonNomineeBody(
        this.nominee0Row2,
        this.nominee1Row2,
        this.nominee2Row2,
        this.nominee3Row2));
    } else {
      this.nominee1Row2 = undefined;
      this.nominee2Row2 = undefined;
      this.nominee3Row2 = undefined;
    }

    if (this.nominee1Row3 !== undefined && this.nominee2Row3 !== undefined && this.nominee3Row3 !== undefined) {
      this.nomineeList.push(this.commonNomineeBody(
        this.nominee0Row3,
        this.nominee1Row3,
        this.nominee2Row3,
        this.nominee3Row3));
    } else {
      this.nominee1Row3 = undefined;
      this.nominee2Row3 = undefined;
      this.nominee3Row3 = undefined;
    }

    if (this.nominee1Row4 !== undefined && this.nominee2Row4 !== undefined && this.nominee3Row4 !== undefined) {
      this.nomineeList.push(this.commonNomineeBody(
        this.nominee0Row4,
        this.nominee1Row4,
        this.nominee2Row4,
        this.nominee3Row4));
    } else {
      this.nominee1Row4 = undefined;
      this.nominee2Row4 = undefined;
      this.nominee3Row4 = undefined;
    }

    if (this.nomineeList.length === 0) {
      this.invalidTableValues = true;
      return;
    }

    this.form.patchValue({
      nominees: this.nomineeList
    });

    this.section = 1;
  }

  commonNomineeBody(id,name, nic, percentage) {
    return {
      'id': id,
      'name': name,
      'nic': nic,
      'percentage': percentage
    };
  }


  backPage() {
    this.section = 0;
  }
}
