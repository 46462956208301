export class RatingDTO {
  public id: number;
  public username: string;
  public rating: number;
  public reviewBy: string;
  public comment: string;
  public show: boolean;
  public status: string;
  public created: string;
  public sku: string;
}
