import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../../../../../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {UserRoleService} from '../../../../../service/user-role.service';
import {CoreService} from '../../../../../service/core.service';
import {CommonService} from '../../../../../common/common.service';
import {ActivatedRoute} from '@angular/router';
import {InteractionService} from '../../../../../service/interaction.service';

@Component({
  selector: 'app-l-for-farmers',
  templateUrl: './l-for-farmers.component.html',
  styleUrls: ['./l-for-farmers.component.css']
})
export class LForFarmersComponent implements OnInit {
  form: FormGroup;
  activeLang: any;
  submitted = false;

  fullName: any;
  mobile: any;
  email: any;
  address: any;
  nic: any;
  contact: any;
  dependents: any;
  location: any;
  busRegNo: any;
  warehouseSize: any;
  factorySize: any;
  noOfVehicles: any;
  extentOfFarm: any;
  noOfEmp: any;
  activeYears: any;
  avgIncome: any;
  purpose: any;
  requirement: any;
  repaymentTenure: any;
  draft: any;
  userDetails: any;
  allOwnershipList: any;
  allPurposeList: any;

  @ViewChild('openSuccessModal', {static: true}) openSuccessModal;
  @ViewChild('openDraftModal', {static: true}) openDraftModal;

  draftDetails: any;
  id: any;
  ownership: any;
  userName: any;
  formType: any;
  status: any;
  isDraft: boolean = false;

  constructor(private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private interactionService: InteractionService,
              private route: ActivatedRoute,
              public commonService: CommonService,
              private userRoleService: UserRoleService) {
    this.validationForm();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isDraft = params['isDRAFT'];
      if (params['isDRAFT']) {
        this.draftDetails = JSON.parse(decodeURIComponent(params['data']));
        this.id = this.draftDetails['id'];
        this.address = this.draftDetails['address'];
        this.nic = this.draftDetails['nic'];
        this.fullName = this.draftDetails['fullName'];
        this.contact = this.draftDetails['contact'];
        this.email = this.draftDetails['email'];
        this.dependents = this.draftDetails['dependents'];
        this.location = this.draftDetails['location'];
        this.ownership = this.draftDetails['ownershipId'];
        this.noOfEmp = this.draftDetails['noOfEmp'];
        this.activeYears = this.draftDetails['activeYears'];
        this.avgIncome = this.draftDetails['avgIncome'];
        this.purpose = this.draftDetails['purposeId'];
        this.requirement = this.draftDetails['requirement'];
        this.repaymentTenure = this.draftDetails['repaymentTenure'];
        this.userName = this.draftDetails['userName'];
        this.formType = this.draftDetails['formType'];
        this.status = this.draftDetails['status'];
        this.extentOfFarm = this.draftDetails['extentOfFarm'];

        this.form.patchValue({
          created: this.draftDetails['created']
        });
      }

    });
    this.activeLang = sessionStorage.getItem('LANG');
    if (!this.isDraft) {
      this.fetchUserDetails();
    }

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

    this.fetchOwnership();
    this.fetchPurpose();
  }

  get f() {
    return this.form.controls;
  }

  validationForm() {
    this.form = this.formBuilder.group({
      address: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      nic: ['', [Validators.required, Validators.pattern(this.validationHandlerService.sriLankaNICValidation())]],
      fullName: ['', Validators.required],
      contact: ['', Validators.required],
      email: ['', Validators.required],
      dependents: ['', Validators.required],
      location: ['', Validators.required],
      ownership: ['', Validators.required],
      noOfEmp: ['', Validators.required],
      activeYears: ['', Validators.required],
      avgIncome: ['', Validators.required],
      purpose: ['', Validators.required],
      requirement: ['', Validators.required],
      repaymentTenure: ['', Validators.required],
      id: [''],
      userName: [''],
      formType: [''],
      status: [''],
      created: [''],
      extentOfFarm: ['', Validators.required]
    });
  }

  fetchUserDetails() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.userRoleService.fetchUserDetails(whoAmI['email']).subscribe(userDetails => {
        if (userDetails) {
          this.userDetails = userDetails;
          this.address = this.userDetails.address;
          this.fullName = this.userDetails.name;
          this.nic = this.userDetails.nic;
          this.email = this.userDetails.email;
          this.mobile = this.userDetails.contactNo;
          this.form.patchValue({
            fullName: this.fullName,
            nic: this.nic,
            address: this.address,
            contact: this.mobile,
            email: this.email,
            formType: 'FOR_FARMERS',
            userName: whoAmI['email'],
          });
          Swal.close();
        }
      });
    });
  }

  fetchOwnership() {
    this.coreService.fetchOwnership().subscribe((result) => {
      if (result) {
        this.allOwnershipList = result;
      }
    });
  }

  fetchPurpose() {
    this.coreService.fetchPurpose().subscribe((result) => {
      if (result) {
        this.allPurposeList = result;
      }
    });
  }

  clickDropdown() {
    this.activeLang = sessionStorage.getItem('LANG');
  }


  submitForm(status) {

    this.submitted = true;

    if (this.isDraft) {
      this.form.patchValue({
        id: this.id,
        formType: 'FOR_FARMERS',
        userName: this.userName,
      });
    }

    if (this.form.invalid && status === 'PENDING') {
      return;
    }
    this.commonService.processing();

    this.form.patchValue({
      status: status
    });


    this.coreService.requestLoanFarmersPeoplesBank(this.form.value).subscribe(result => {
      Swal.close();
      if (result['success']) {
        if (status === 'PENDING') {
          this.openSuccessModal.nativeElement.click();
        } else {
          this.openDraftModal.nativeElement.click();
        }
      }
    }, () => {
      Swal.close();
    });
  }

}
