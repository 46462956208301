import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  fetchAllCities():Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_DELIVERY + '/cities/all');
  }

  fetchAllActiveCities():Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_DELIVERY + '/activeCities/fetchAllActiveCities');
  }


}
