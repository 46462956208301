import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../../common/common.service';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {OrderDto} from '../../../../dto/orderDto';
import {SellerDto} from '../../../../dto/sellerDto';
import {PurchaseStatusDTO} from '../../../../dto/purchase-statusDTO';
import {MainUrlService} from '../../../../service/main-url.service';
import {UserTokenDTO} from '../../../../dto/userTokenDTO';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import Swal from 'sweetalert2';
import {CoreService} from '../../../../service/core.service';
import {Md5} from 'ts-md5';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderRejectDTO} from '../../../../dto/orderRejectDTO';
import {catchError, last, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import {InteractionService} from '../../../../service/interaction.service';
import {RiyawiruService} from '../../../../service/riyawiru.service';

@Component({
  selector: 'app-farmer-orders',
  templateUrl: './farmer-orders.component.html',
  styleUrls: ['./farmer-orders.component.css']
})
export class FarmerOrdersComponent implements OnInit {

  displayedColumns: string[] = ['orderID', 'productName', 'orderDate', 'type', 'paySts', 'status'];
  salesDTOs: OrderDto[] = [];
  tenantValue: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  prodName: string;
  orderDate: Date;
  unit: any;
  quantity: any;
  address: any;
  type: any;
  amount: any;
  buyerName: any;
  files: File[] = [];
  contact: any;
  district: any;
  id: any;
  purchaseStatusDTO = new PurchaseStatusDTO;
  pySts: any;
  sts: any;
  image: any;
  userTokenDTO = new UserTokenDTO;
  pickupAdd: any;
  rejectionCatList: any[] = [];
  rejForm: FormGroup;
  orderRejectDTO = new OrderRejectDTO;
  submitted: boolean = false;

  imageArray = [];

  sellerImage: string;
  delivery: string;
  deliveryCharges: any;
  deliveryFixRate: any;
  area: any;
  ecoShopCode: any;
  userRole: any;
  deliveryId: any;
  activeLang: any;
  todayDate = new Date().toISOString().substr(0, 10);
  deliveryDate: any;
  selectedTimeSlot: string;
  selectedPackageDetails: any;
  selectedNumberPackages: any = 1;

  packageDetailsList = [
    {
      'nameEn': 'Polythene Packaging',
      'nameSi': 'පොලිතින් ඇසුරුම්',
      'nameTm': 'பாலிதீன் பேக்கேஜிங்'
    },
    {
      'nameEn': 'Corrugated Boxes',
      'nameSi': 'රැලි ගැසූ කඩදාසි ඇසුරුම්',
      'nameTm': 'நெளி பெட்டிகள்'
    },
    {
      'nameEn': 'Plastic Boxes',
      'nameSi': 'ප්ලාස්ටික් ඇසුරුම්',
      'nameTm': 'பிளாஸ்டிக் பெட்டிகள்'
    },
    {
      'nameEn': 'Paperboard Boxes',
      'nameSi': 'කඩදාසි ඇසුරුම්',
      'nameTm': 'காகித அட்டை பெட்டிகள்'
    },
    {
      'nameEn': 'Poly bags',
      'nameSi': 'පොලි බෑග්',
      'nameTm': 'பாலி பைகள்'
    },
    {
      'nameEn': 'Sacks',
      'nameSi': 'ගෝනි ඇසුරුම්',
      'nameTm': 'சாக்குகள்'
    },
    {
      'nameEn': 'Wooden Crates',
      'nameSi': 'ලී ඇසුරුම්',
      'nameTm': 'மரத்தாலான கிரேட்சுகள்'
    }
  ];
  isDeliveryDate: boolean = false;
  isTimeSlot: boolean = false;
  isPackageDetails: boolean = false;
  isNumberOfPackaging: boolean = false;
  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;

  signUpCollector: FormGroup;
  submittedForm = false;

  @ViewChild('success') successButton;
  @ViewChild('closeButton') closeButton;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private coreService: CoreService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private router: Router,
              private http: HttpClient,
              private riyawiruService: RiyawiruService,
              private settingsService: SystemSettingsService,
              public mainUrl: MainUrlService,
              private interactionService: InteractionService,
              private validationHandlerService: ValidationHandlerService,
              private _formBuilder: FormBuilder) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });

  }

  ngOnInit() {
    this.fetchAll();
    this.loadOrders();
    this.fetchRejectCat();
    this.rejForm = this._formBuilder.group({
      remark: ['', Validators.required],
      reason: ['', Validators.required],
    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    }, () => {
      Swal.close();
    });
  }

  loadOrders() {
    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

        if (this.userRole === 'LEAD' || (this.isEconomicCenter && this.isEcoFarmerSection)) {
          this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {

            if (this.isEcoFarmerSection) {
              this.productService.fetchOrderByEcoShopCode(refNo.message).subscribe(
                (data: OrderDto[]) => {
                  this.commonResponseBody(data);
                }, () => {
                  Swal.close();
                }
              );
            } else {
              this.productService.fetchOrderByLeadCode(refNo.message).subscribe(
                (data: OrderDto[]) => {
                  this.commonResponseBody(data);
                }, () => {
                  Swal.close();
                }
              );
            }

          }, () => {
            Swal.close();
          });

        } else {
          this.productService.fetchOrderByUsername(whoAmIDetails['email']).subscribe(
            (data: OrderDto[]) => {
              this.commonResponseBody(data);
            }, () => {
              Swal.close();
            }
          );
        }
      });
    } else {
      this.commonLogoutURL();
    }
  }

  commonResponseBody(values) {
    this.salesDTOs = values;
    this.dataSource = new MatTableDataSource(this.salesDTOs);
    this.dataSource.paginator = this.paginator;
    Swal.close();
  }

  commonLogoutURL() {
    Swal.close();
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/' + this.tenantValue]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  _deliveryId: any = 0;
  riyaviruPayment: boolean = false;
  isDisabledDispatch: boolean = false;
  productType: any;
  category: any;
  unitPrice: any;

  getOrder(data) {

    this.pySts = data.paymentStatus;
    this.sts = data.status;
    this.unitPrice = data.unitPrice;
    this.category = data.category;
    this.productType = data.type;
    this.id = data.id;
    this.delivery = data.delivery;
    this._deliveryId = data.deliveryId;

    this.riyaviruPayment = data.riyaviruPayment;

    if (this._deliveryId === 1) {
      this.driverDetails = null;
      this.isDisabledDispatch = true;
      this.fetchDriverDetails(data.id);
      if (this.activeLang == 'en') {
        this.dispatchButtonText = 'Ready to Dispatch';
      } else if (this.activeLang == 'si') {
        this.dispatchButtonText = 'යැවීමට සූදානම්';
      } else {
        this.dispatchButtonText = 'அனுப்ப தயார்';
      }
    } else {
      this.isDisabledDispatch = false;
      this.dispatchButtonText = 'MY_ACCOUNT.SALES.ORDERS.DISPATCH';
    }

    this.loading();
    this.productService.fetchOrderByID(data.id).subscribe(
      (res: OrderDto) => {
        this.getBuyer(data.buyer);
        this.prodName = res.name;
        this.orderDate = res.created;
        this.unit = res.unit;
        this.quantity = res.quantity;
        this.address = res.diliveryAddress;
        this.type = res.type;
        this.amount = res.amount;
        this.image = data.image;
        this.delivery = res.delivery;
        this.deliveryCharges = res.deliveryCharges;
        this.deliveryFixRate = res.deliveryFixRate;
        this.area = res.area;
        this.deliveryId = res.deliveryId;
        Swal.close();
        if (this.productType === 'ORDER_REQUEST') {
          this.productService.fetchOrderRequestGetDefaultPickupAddress(res.seller).subscribe(addressDetails => {
            this.pickupAdd = addressDetails?.addressOne + ' ' + addressDetails?.addressTwo + ' ' + addressDetails?.cities?.nameEN;
          });
        } else {
          this.pickupAdd = res.pickupAdd;
        }
      }, () => {
        Swal.close();
      }
    );


  }

  getBuyer(seller) {
    this.userTokenDTO.userName = seller;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.productService.fetchSeller(this.userTokenDTO).subscribe(
      (data: SellerDto) => {
        this.buyerName = data.name;
        this.district = data.address;
        this.contact = data.contactNo;

        let body = {
          'userName': data.username,
          'token': 'Bearer ' + localStorage.getItem('$Token')
        };
        this.coreService.fetchUserDetailsByUserName(body).subscribe(details => {
          if (details) {
            let hashStr;
            if (data.username !== null) {
              hashStr = Md5.hashStr(data.username);
            } else {
              hashStr = Md5.hashStr('');
            }
            this.sellerImage = details.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + details.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
          }
        });

      }, () => {
        Swal.close();
      }
    );
  }

  updateStatus(status) {

    if (status === 'APPROVED') {

      if (this.deliveryDate === undefined) {
        this.isDeliveryDate = true;
      }

      if (this.selectedTimeSlot === undefined) {
        this.isTimeSlot = true;
      }

      if (this.selectedNumberPackages === undefined) {
        this.isNumberOfPackaging = true;
      }

      if (this.selectedPackageDetails === undefined) {
        this.isPackageDetails = true;
      }

      if (this.deliveryDate === undefined ||
        this.selectedTimeSlot === undefined ||
        this.selectedNumberPackages === undefined ||
        this.selectedPackageDetails === undefined) {
        return;
      }

      this.isDeliveryDate = false;
      this.isTimeSlot = false;
      this.isNumberOfPackaging = false;
      this.isPackageDetails = false;

    }

    this.loading();
    this.purchaseStatusDTO.id = this.id;
    this.purchaseStatusDTO.status = status;
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.purchaseStatusDTO.rejectedBy = whoAmIDetails['email'];
      this.purchaseStatusDTO.deliveryDate = this.deliveryDate;
      this.purchaseStatusDTO.numberOfPacking = this.selectedNumberPackages;
      this.purchaseStatusDTO.packing = this.selectedPackageDetails;
      this.purchaseStatusDTO.timeSlot = this.selectedTimeSlot;
      this.purchaseStatusDTO.proxyUrl = environment.proxyUrl;

      this.productService.updateTransaction(this.purchaseStatusDTO).subscribe(res => {
        if (res) {
          this.loadOrders();
          this.closeButton.nativeElement.click();
          Swal.close();
          this.successButton.nativeElement.click();

          this.deliveryDate = undefined;
          this.selectedNumberPackages = 1;
          this.selectedPackageDetails = undefined;
          this.selectedTimeSlot = undefined;

          let radio1 = document.getElementById('0810') as HTMLInputElement;
          if (radio1 !== null) {
            radio1.checked = false;
          }

          let radio2 = document.getElementById('1205') as HTMLInputElement;
          if (radio2 !== null) {
            radio2.checked = false;
          }

          let radio3 = document.getElementById('anytime') as HTMLInputElement;
          if (radio3 !== null) {
            radio3.checked = false;
          }

        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: res.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, () => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Something went wrong..!!!',
          showConfirmButton: false,
          timer: 1500
        });

      });
    });
  }

  fetchRejectCat() {
    this.productService.fetchRejectReason().subscribe(data => {
      this.rejectionCatList = data;
    });
  }

  rejectOrder() {
    this.submitted = true;
    if (this.rejForm.invalid) {
      return;
    }
    this.loading();
    this.orderRejectDTO.purchaseId = this.id;
    this.orderRejectDTO.reasonId = this.rejForm.value.reason;
    this.orderRejectDTO.remark = this.rejForm.value.remark;

    Swal.fire({
      position: 'center',
      type: 'warning',
      text: this.validationHandlerService.orderRejectionMessage(sessionStorage.getItem('LANG')),
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG')),
      confirmButtonText: this.validationHandlerService.yesButtonMessage(sessionStorage.getItem('LANG'))
    }).then((result) => {
      if (result.value) {

        this.loading();
        this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
          this.orderRejectDTO.rejectedBy = whoAmIDetails['email'];

          this.productService.rejectOrder(this.orderRejectDTO).subscribe(res => {
            if (res) {
              this.loadOrders();
              this.closeButton.nativeElement.click();
              Swal.close();
              this.successButton.nativeElement.click();
            } else {
              Swal.fire({
                position: 'center',
                type: 'error',
                title: res.message,
                showConfirmButton: false,
                timer: 1500
              });
            }
          }, () => {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: 'Something went wrong..!!!',
              showConfirmButton: false,
              timer: 1500
            });

          });
        });
      }
    });

  }

  onSelect(event) {
    if (this.files.length <= 3 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.uploadImages(event.addedFiles);
        this.files.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files.splice(-1, 1);
        }
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }
  }

  uploadImages(value) {
    this.commonService.processing();
    let formData = new FormData();
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

  get f2() {
    return this.rejForm.controls;
  }

  manageStatus(status: string) {
    switch (sessionStorage.getItem('LANG')) {
      case 'en' :
        return status;

      case 'si' :
        return status + '-sin';

      case 'ta' :
        return status + '-tamil';
    }
  }

  setLang() {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  selectTimeSlot(value: string) {
    this.isTimeSlot = false;
    this.selectedTimeSlot = value;
  }

  packageValueSet(activeLang, packageDetail) {
    if (activeLang === 'en') {
      return packageDetail.nameEn;
    } else if (activeLang === 'si') {
      return packageDetail.nameSi;
    } else {
      return packageDetail.nameTm;
    }
  }

  driverDetails: any = null;
  dispatchButtonText: any = 'MY_ACCOUNT.SALES.ORDERS.DISPATCH';

  fetchDriverDetails(orderNo) {
    this.riyawiruService.fetchDriverDetailsByOrderNo(orderNo).subscribe(result => {
      if (result.length > 0) {
        for (let driver of result) {
          if (driver.status === 'PAYMENT_CONFIRMED') {
            this.isDisabledDispatch = false;
            this.riyawiruService.fetchDriverDetails(driver.driverId).subscribe(driverDetails => {
              this.driverDetails = driverDetails;
            }, error => {
              if (error.status === 302) {
                this.driverDetails = error['error'];
              }
            });
          }
        }
      }
    });
  }

  closeModal() {
    this.loadOrders();
  }

  buttonUp(value: any) {
    this.selectedNumberPackages = value + 1;
  }

  buttonDown(value: any) {
    if (value === 1) {
      return;
    } else {
      this.selectedNumberPackages = value - 1;
    }
  }

  selectDeliveryDate() {
    this.isDeliveryDate = false;
  }

  selectPackageDetails() {
    this.isPackageDetails = false;
  }

  setOrderRequestImage(category) {
    let image;
    if (category === 'Farming Products & Processed Food') {
      image = 'assets/images/Thumbnails/Processed Products.jpg';
    } else if (category === 'Spices & Cash Crops') {
      image = 'assets/images/Thumbnails/Spices.jpg';
    } else if (category === 'Services & Information') {
      image = 'assets/images/Thumbnails/Services.jpg';
    } else if (category === 'Plants & Seeds') {
      image = 'assets/images/Thumbnails/Seeds.jpg';
    } else if (category === 'Ayurvedic & Herbal Products') {
      image = 'assets/images/Thumbnails/Ayurveda.jpg';
    } else if (category === 'Equipment and Tools') {
      image = 'assets/images/Thumbnails/Equipments.jpg';
    } else {
      image = 'assets/images/Thumbnails/' + category + '.jpg';
    }
    return image;
  }
}
