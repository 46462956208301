import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-forms',
  templateUrl: './loan-forms.component.html',
  styleUrls: ['./loan-forms.component.css']
})
export class LoanFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
