import {Component, OnInit} from '@angular/core';
import {MainUrlService} from '../service/main-url.service';
import {Title} from '@angular/platform-browser';
import {CoreService} from '../service/core.service';
import {ActivatedRoute} from '@angular/router';
import {InteractionService} from '../service/interaction.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  tenantValue: any;
  activeLang: string = 'en';

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public core_service: CoreService,
              private titleService: Title,
              private route: ActivatedRoute,
              private mainUrl: MainUrlService,
              private interactionService:InteractionService) {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit() {
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

}
