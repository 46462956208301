<app-header></app-header>

<div class="main-container">
  <div class="container">

    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">
          <div class="cfs-title">
            {{'CHECKOUT.CHECKOUT' | translate}} <span> ({{itemLength}})</span>
            <span *ngIf="itemLength == 1 "> {{'CHECKOUT.ITEM' | translate}}</span>
            <span *ngIf="itemLength > 1 "> {{'CHECKOUT.ITEMS' | translate}}</span>
          </div>

          <div class="checkout-container">
            <div class="checkout-form">
              <div class="del-details-box cbox card">

                <h4 class="btitle">{{'CHECKOUT.DELIVERY_DETAILS' | translate}}</h4>

                <div class="transport-section">
                  <div class="saved-address">
                    <div class="d-flex row"
                         style="overflow-y:auto; overflow-x:hidden; max-height:250px;margin-bottom: 1vh;">
                      <div *ngFor="let item of addressList; let i = index" class="section-transport col-lg-6">
                        <input (click)="setDeliveryAddress(i,item)" [id]="'address1'+i" [name]="'address1'"
                               [value]="item.id" class="form-check-input" type="radio" [checked]="item.status">
                        <label [for]="'address1'+i" class="form-check-label">
                          <h4>{{item.name}} <span *ngIf="item.status">({{'CHECKOUT.DEFAULT' | translate}})</span>
                          </h4>
                          <p>{{item.addressOne}} {{item.addressTwo}} ,{{item.cities.nameEN}}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="addnewaddress d-flex justify-content-between align-items-center">
                    <div>
                      <input (click)="setDeliveryAddress('new','')" class="form-check-input" id="newaddesss1"
                             name="address1" type="radio" value="option3">
                      <label class="form-check-label" for="newaddesss1" (click)="deliveryAddressVisible()">
                        <h4>{{'CHECKOUT.ADD_NEW_ADDRESS' | translate}}</h4>
                      </label>
                    </div>
                    <div class="form-check" [hidden]="!isShownDelivery">
                      <input [checked]="isDeliveryAddressSave" class="form-check-input checkone" id="saveAddress1"
                             type="checkbox" (change)="manageDeliveryAddressSave(isDeliveryAddressSave)">
                      <label class="form-check-label" for="saveAddress1">
                        {{'CHECKOUT.SAVE_THIS_ADDRESS' | translate}}
                      </label>
                    </div>
                  </div>
                </div>


                <div [hidden]="!isDeliveryAddressVisible">
                  <form (ngSubmit)="paymentDeliveryFormSubmit()" [formGroup]="paymentDeliveryForm" class="default row">

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.NAME' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="name_d"
                             [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.name_d.errors}" [readOnly]="read_d"
                             autocomplete="nope" class="form-control" formControlName="name_d" type="text">
                      <div *ngIf="submittedDeliveryPayment && f0?.name_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.name_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.NAME_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f0?.name_d.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_NAME_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.CONTACT' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="mobile_d"
                             [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.mobile_d.errors}" [readOnly]="read_d"
                             autocomplete="nope" class="form-control" formControlName="mobile_d" type="tel">
                      <div *ngIf="submittedDeliveryPayment && f0?.mobile_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.mobile_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.CONTACT_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f0?.mobile_d.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_CONTACT_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">
                        {{'CHECKOUT.ADDRESS_1' | translate}}
                        <span style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="address1_d"
                             [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.address1_d.errors}"
                             [readOnly]="read_d" autocomplete="nope" class="form-control" formControlName="address1_d"
                             type="text">
                      <div *ngIf="submittedDeliveryPayment && f0?.address1_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.address1_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.ADDRESS_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f0?.address1_d.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_ADDRESS_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">
                        {{'CHECKOUT.ADDRESS_2' | translate}}
                      </label>
                      <input [(ngModel)]="address2_d"
                             [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.address2_d.errors}"
                             [readOnly]="read_d" autocomplete="nope" class="form-control" formControlName="address2_d"
                             type="text">
                      <div *ngIf="submittedDeliveryPayment && f0?.address2_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.address2_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.ADDRESS_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f0?.address2_d.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_ADDRESS_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.COUNTRY' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="country_d"
                             [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.country_d.errors}" [readOnly]="true"
                             autocomplete="nope" class="form-control" formControlName="country_d" type="text">
                      <div *ngIf="submittedDeliveryPayment && f0?.country_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.country_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.COUNTRY_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.PROVINCE_STATE' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>

                      <ng-template [ngIf]="isShownDelivery" [ngIfElse]="newBillPA">
                        <select (change)="selectDeliveryProvince($event)" [(ngModel)]="province_d"
                                [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.province_d.errors}"
                                class="form-control" formControlName="province_d">
                          <option [value]="none" disabled>{{'CHECKOUT.BILLING_PROVINCE' | translate}}</option>
                          <option *ngFor="let province of allProvinceList" [value]="province.id">
                            <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>
                      <ng-template #newBillPA>
                        <select disabled (change)="selectDeliveryProvince($event)" [(ngModel)]="province_d"
                                [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.province_d.errors}"
                                class="form-control" formControlName="province_d">
                          <option [value]="none" disabled>{{'CHECKOUT.BILLING_PROVINCE' | translate}}</option>
                          <option *ngFor="let province of allProvinceList" [value]="province.id">
                            <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>


                      <div *ngIf="submittedDeliveryPayment && f0?.province_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.province_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.PROVINCE_STATE_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f0?.province_d.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_PROVINCE_STATE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-6">
                      <label for="district_d">
                        {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
                        <span class="validationStar">*</span></label>

                      <ng-template [ngIf]="isShownDelivery" [ngIfElse]="newBillDA">
                        <select (change)="selectDeliveryDistrict($event)" [(ngModel)]="selectDeliveryDistrictId"
                                [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.district_d.errors}"
                                class="form-control" formControlName="district_d" id="district_d">
                          <option [value]="none" disabled>{{'CHECKOUT.SELECT_DISTRICT' | translate}}</option>
                          <option *ngFor="let district of districtList_d" [value]="district.id">
                            <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <ng-template #newBillDA>
                        <select disabled (change)="selectDeliveryDistrict($event)"
                                [(ngModel)]="selectDeliveryDistrictId"
                                [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.district_d.errors}"
                                class="form-control" formControlName="district_d" id="district_d_new">
                          <option [value]="none" disabled>{{'CHECKOUT.SELECT_DISTRICT' | translate}}</option>
                          <option *ngFor="let district of districtList_d" [value]="district.id">
                            <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <div *ngIf="submittedDeliveryPayment && f0?.district_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.district_d.errors?.required" style="font-size: 10px;color: red">
                          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-6">
                      <label for="city_d">
                        {{'CHECKOUT.CITY' | translate}}
                        <span class="validationStar">*</span></label>

                      <ng-template [ngIf]="isShownDelivery" [ngIfElse]="newBillCA">
                        <select (change)="selectDeliveryCity($event)" [(ngModel)]="selectDeliveryCityId"
                                [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.city_d.errors}" class="form-control"
                                formControlName="city_d" id="city_d">
                          <option [value]="none" disabled>{{'CHECKOUT.SELECT_CITY' | translate}}</option>
                          <option *ngFor="let city of allCities_d" [value]="city.id">
                            <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <ng-template #newBillCA>
                        <select disabled (change)="selectDeliveryCity($event)" [(ngModel)]="selectDeliveryCityId"
                                [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.city_d.errors}" class="form-control"
                                formControlName="city_d" id="city_d_new">
                          <option [value]="none" disabled>{{'CHECKOUT.SELECT_CITY' | translate}}</option>
                          <option *ngFor="let city of allCities_d" [value]="city.id">
                            <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <div *ngIf="submittedDeliveryPayment && f0?.city_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.city_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.CITY_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.POSTAL_CODE' | translate}}
                      </label>
                      <input [(ngModel)]="postal_d"
                             [ngClass]="{'is-invalid': submittedDeliveryPayment && f0?.postal_d.errors}" [readOnly]="true"
                             autocomplete="nope" class="form-control" formControlName="postal_d" type="text">
                      <div *ngIf="submittedDeliveryPayment && f0?.postal_d.errors" class="invalid-feedback">
                        <div *ngIf="f0?.postal_d.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f0?.postal_d.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_POSTAL_CODE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>


                  </form>

                </div>
              </div>
              <div class="bill-details-box cbox card">
                <h4 class="btitle">{{'CHECKOUT.BILLING_DETAILS' | translate}}</h4>

                <div class="transport-section">
                  <div class="saved-address">
                    <div class="d-flex row"
                         style="overflow-y:auto; overflow-x:hidden; max-height:250px;margin-bottom: 1vh;">
                      <div *ngFor="let item of addressList; let i = index" class="section-transport col-lg-6">
                        <input (click)="setBillingAddress(i,item)" [id]="'address2'+i" [name]="'address2'"
                               [value]="item.id" class="form-check-input" type="radio" [checked]="item.status">
                        <label [for]="'address2'+i" class="form-check-label">
                          <h4>{{item.name}} <span *ngIf="item.status">(Default)</span></h4>
                          <p>{{item.addressOne}} {{item.addressTwo}},{{item.cities.nameEN}}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="addnewaddress d-flex justify-content-between align-items-center">
                    <div>
                      <input (click)="setBillingAddress('new','')" class="form-check-input" id="newaddesss2"
                             name="address2" type="radio" value="option3">
                      <label class="form-check-label" for="newaddesss2" (click)="billingAddressVisible()">
                        <h4>{{'CHECKOUT.ADD_NEW_ADDRESS' | translate}}</h4>
                      </label>
                    </div>
                    <div class="form-check" [hidden]="!isBillingAddressVisible">
                      <input [checked]="isSameAsBillingAddress" (change)="sameAsBillingVisible()"
                             class="form-check-input checkone" id="sameAsBilling2" name="sameAsBilling2" type="checkbox"
                             value="option4">
                      <label class="form-check-label" for="sameAsBilling2">
                        {{'CHECKOUT.SAME_AS_DELIVERY' | translate}}
                      </label>
                    </div>
                    <div class="form-check" [hidden]="!isBillingAddressVisible">
                      <input [checked]="isBillingAddressSave" class="form-check-input checkone" id="saveAddress2"
                             type="checkbox" (change)="manageBillingAddressSave(isBillingAddressSave)">
                      <label class="form-check-label" for="saveAddress2">
                        {{'CHECKOUT.SAVE_THIS_ADDRESS' | translate}}
                      </label>
                    </div>
                  </div>
                </div>


                <div [hidden]="!isBillingAddressVisible">
                  <form (ngSubmit)="paymentBillingFormSubmit()" [formGroup]="paymentBillingForm" class="default row">

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.NAME' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="name_b"
                             [ngClass]="{'is-invalid': submittedBillingPayment && f1?.name_b.errors}" [readOnly]="read_b"
                             autocomplete="nope" class="form-control" formControlName="name_b" type="text">
                      <div *ngIf="submittedBillingPayment && f1?.name_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.name_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.NAME_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f1?.name_b.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_NAME_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.CONTACT' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="mobile_b"
                             [ngClass]="{'is-invalid': submittedBillingPayment && f1?.mobile_b.errors}" [readOnly]="read_b"
                             autocomplete="nope" class="form-control" formControlName="mobile_b" type="tel">
                      <div *ngIf="submittedBillingPayment && f1?.mobile_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.mobile_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.CONTACT_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f1?.mobile_b.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_CONTACT_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">
                        {{'CHECKOUT.ADDRESS_1' | translate}}
                        <span style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="address1_b"
                             [ngClass]="{'is-invalid': submittedBillingPayment && f1?.address1_b.errors}" [readOnly]="read_b"
                             autocomplete="nope" class="form-control" formControlName="address1_b" type="text">
                      <div *ngIf="submittedBillingPayment && f1?.address1_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.address1_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.ADDRESS_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f1?.address1_b.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_ADDRESS_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">
                        {{'CHECKOUT.ADDRESS_2' | translate}}
                      </label>
                      <input [(ngModel)]="address2_b"
                             [ngClass]="{'is-invalid': submittedBillingPayment && f1?.address2_b.errors}" [readOnly]="read_b"
                             autocomplete="nope" class="form-control" formControlName="address2_b" type="text">
                      <div *ngIf="submittedBillingPayment && f1?.address2_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.address2_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.ADDRESS_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f1?.address2_b.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_ADDRESS_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.COUNTRY' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>
                      <input [(ngModel)]="country_b"
                             [ngClass]="{'is-invalid': submittedBillingPayment && f1?.country_b.errors}" [readOnly]="true"
                             autocomplete="nope" class="form-control" formControlName="country_b" type="text">
                      <div *ngIf="submittedBillingPayment && f1?.country_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.country_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.COUNTRY_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.PROVINCE_STATE' | translate}}<span
                        style="color: red;margin-left: .5vw">*</span></label>

                      <ng-template [ngIf]="isShownBilling" [ngIfElse]="newBillP">
                        <select (change)="selectBillingProvince($event)" [(ngModel)]="province_b"
                                [ngClass]="{'is-invalid': submittedBillingPayment && f1?.province_b.errors}"
                                class="form-control" formControlName="province_b">
                          <option [value]="none">{{'CHECKOUT.BILLING_PROVINCE' | translate}}</option>
                          <option *ngFor="let province of allProvinceList" [value]="province.id">
                            <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <ng-template #newBillP>
                        <select (change)="selectBillingProvince($event)" [(ngModel)]="province_b"
                                [ngClass]="{'is-invalid': submittedBillingPayment && f1?.province_b.errors}"
                                class="form-control" formControlName="province_b">
                          <option [value]="none">{{'CHECKOUT.BILLING_PROVINCE' | translate}}</option>
                          <option *ngFor="let province of allProvinceList" [value]="province.id">
                            <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <div *ngIf="submittedBillingPayment && f1?.province_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.province_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.PROVINCE_STATE_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f1?.province_b.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_PROVINCE_STATE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-6">
                      <label for="district_b_address">
                        {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
                        <span class="validationStar">*</span></label>

                      <ng-template [ngIf]="isShownBilling" [ngIfElse]="newBillD">
                        <select (change)="selectBillingDistrict($event)" [(ngModel)]="selectBillingDistrictId"
                                [ngClass]="{'is-invalid': submittedBillingPayment && f1?.district_b.errors}"
                                class="form-control" formControlName="district_b" id="district_b_address">
                          <option [value]="none">{{'CHECKOUT.SELECT_DISTRICT' | translate}}</option>
                          <option *ngFor="let district of districtList_b" [value]="district.id">
                            <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>
                      <ng-template #newBillD>
                        <select (change)="selectBillingDistrict($event)" [(ngModel)]="selectBillingDistrictId"
                                [ngClass]="{'is-invalid': submittedBillingPayment && f1?.district_b.errors}"
                                class="form-control" formControlName="district_b" id="district_b_district">
                          <option [value]="none">{{'CHECKOUT.SELECT_DISTRICT' | translate}}</option>
                          <option *ngFor="let district of districtList_b" [value]="district.id">
                            <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <div *ngIf="submittedBillingPayment && f1?.district_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.district_b.errors?.required" style="font-size: 10px;color: red">
                          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-6">
                      <label for="city_b_city1">
                        {{'CHECKOUT.CITY' | translate}}
                        <span class="validationStar">*</span></label>
                      <ng-template [ngIf]="isShownBilling" [ngIfElse]="newBillC">
                        <select (change)="selectBillingCity($event)" [(ngModel)]="selectBillingCityId"
                                [ngClass]="{'is-invalid': submittedBillingPayment && f1?.city_b.errors}" class="form-control"
                                formControlName="city_b" id="city_b_city1">
                          <option [value]="none">{{'CHECKOUT.SELECT_CITY' | translate}}</option>
                          <option *ngFor="let city of allCities_b" [value]="city.id">
                            <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>
                      <ng-template #newBillC>
                        <select (change)="selectBillingCity($event)" [(ngModel)]="selectBillingCityId"
                                [ngClass]="{'is-invalid': submittedBillingPayment && f1?.city_b.errors}" class="form-control"
                                formControlName="city_b" id="city_b_city2">
                          <option [value]="none" disabled>{{'CHECKOUT.SELECT_CITY' | translate}}</option>
                          <option *ngFor="let city of allCities_b" [value]="city.id">
                            <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
                          </option>
                        </select>
                      </ng-template>

                      <div *ngIf="submittedBillingPayment && f1?.city_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.city_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.CITY_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label style="font-weight: bold!important;">{{'CHECKOUT.POSTAL_CODE' | translate}}
                      </label>
                      <input [(ngModel)]="postal_b"
                             [ngClass]="{'is-invalid': submittedBillingPayment && f1?.postal_b.errors}" [readOnly]="true"
                             autocomplete="nope" class="form-control" formControlName="postal_b" type="text">
                      <div *ngIf="submittedBillingPayment && f1?.postal_b.errors" class="invalid-feedback">
                        <div *ngIf="f1?.postal_b.errors?.required" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="f1?.postal_b.errors?.pattern" style="font-size: 10px;color: red">
                          {{'CHECKOUT.VALIDATE.VALID_POSTAL_CODE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>

              <div class="pay-method-box cbox card">
                <div class="form-group col-lg-12 mt-4">
                  <h4 class="btitle">{{'CHECKOUT.PAYMENT_METHOD' | translate}}</h4>
                  <p>{{'CHECKOUT.SELECT_PAYMENT_METHOD' | translate}}</p>
                </div>

                <div class="col-12">
                  <div class="radio-container form-group row rpy-wrapper">
                    <div class="col-lg-3">
                      <input [checked]="selectPaymentType==1" id="radio1" name="radio" type="radio" value="1">
                      <label (click)="selectPaymentType=1" for="radio1">{{'CHECKOUT.PAY_WITH_CARD' | translate}}</label>

                      <div [ngClass]="{'active':selectPaymentType==1}" class="payment-optionz active mt-4">
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <input id="radio2" name="radio" type="radio" value="2">
                      <label (click)="selectPaymentType=2" for="radio2">
                        {{'CHECKOUT.BOC_SMART_PAY' | translate}}</label>
                    </div>
                    <div class="col-lg-4">
                      <input id="radio3" name="radio" type="radio" value="3">
                      <label (click)="selectPaymentType=3" for="radio3">
                        {{'CHECKOUT.BANK_TRANSFER.BANK_TRANSFER' | translate}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div *ngIf="selectPaymentType==2" class="row payment-optionz mt-4">
                    <p class="qrtext-desc text-center">
                      {{'CHECKOUT.QR_CODE_MESSAGE' | translate}}</p>
                    <div class="col-12 text-center">
                      <div class="qrImageWp">
                        <img
                          [src]="mainUrl.MAIN_URL_DYNAMIC_QR_CODE+'/qr/get/'+this.commonService.orderIdLengthTrim(this.paymentId,10)+'/'+this.subTotalAmount"
                          alt="" style="height: 200px;">
                      </div>
                    </div>
                  </div>

                  <div *ngIf="selectPaymentType==3" class="row payment-optionz mt-4">

                    <div class="pmt-tab-wrapper">
                      <ul class="pmt-tab-nav">
                        <li (click)="pmtTabItem=1" [ngClass]="{'active':pmtTabItem==1}"><i class="fa fa-globe"
                                                                                           aria-hidden="true"></i> {{'CHECKOUT.ONLINE_BANK_TRANSFER' | translate}}
                        </li>
                        <li (click)="pmtTabItem=2" [ngClass]="{'active':pmtTabItem==2}"><i class="fa fa-money"
                                                                                           aria-hidden="true"></i> {{'CHECKOUT.CASH_DEPOSIT' | translate}}
                        </li>
                      </ul>
                      <div class="pmt-tab-item" *ngIf="pmtTabItem==1">
                        <div class="col-12 text-center">
                          <h4>{{'CHECKOUT.ONLINE_BANK_TRANSFER' | translate}}</h4>
                          <p>{{'CHECKOUT.BANK_TRANSFER.LINK_1' | translate}}</p>
                          <div class="bank-wrapper">
                            <div (click)="selectedBank=1" [ngClass]="{'active':selectedBank==1}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/clc.png" />
                            </div>
                            <div (click)="selectedBank=2" [ngClass]="{'active':selectedBank==2}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/cargills.png" />
                            </div>
                            <div (click)="selectedBank=3" [ngClass]="{'active':selectedBank==3}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/sampath.png" />
                            </div>
                            <div (click)="selectedBank=4" [ngClass]="{'active':selectedBank==4}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/ntb.png" />
                            </div>
                            <div (click)="selectedBank=5" [ngClass]="{'active':selectedBank==5}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/commcial.png" />
                            </div>
                            <div (click)="selectedBank=6" [ngClass]="{'active':selectedBank==6}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/boc.png" />
                            </div>
                            <div (click)="selectedBank=7" [ngClass]="{'active':selectedBank==7}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/people.png" />
                            </div>
                            <div (click)="selectedBank=8" [ngClass]="{'active':selectedBank==8}" class="bank-name">
                              <img alt="" src="../../../assets/images/payment-types/hnb.png" />
                            </div>
                          </div>

                          <div *ngIf="selectedBank==1" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://clc.lk/clc-online/" rel="noopener noreferrer"
                               target="_blank"><i class="fa fa-credit-card" aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==2" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://onlinebanking.cargillsbank.com/banking-internet-cargills/login"
                               rel="noopener noreferrer" target="_blank"><i class="fa fa-credit-card"
                                                                            aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==3" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://www.sampathvishwa.com/SVRClientWeb/ActionController"
                               rel="noopener noreferrer" target="_blank"><i class="fa fa-credit-card"
                                                                            aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==4" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://mybank.nationstrust.com/corp/AuthenticationController"
                               rel="noopener noreferrer" target="_blank"><i class="fa fa-credit-card"
                                                                            aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==5" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://www.commercialbk.com/online/2/2/22001.aspx"
                               rel="noopener noreferrer" target="_blank"><i class="fa fa-credit-card"
                                                                            aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==6" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn"
                               href="https://web.boc.lk/boc/index.php?route=product/category&path=87_95_96&desc=1"
                               rel="noopener noreferrer" target="_blank"><i class="fa fa-credit-card"
                                                                            aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==7" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://www.ebank.peoplesbank.lk/" rel="noopener noreferrer"
                               target="_blank"><i class="fa fa-credit-card" aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>
                          </div>

                          <div *ngIf="selectedBank==8" class="bank-account-details">
                            <div class="bi-info-wrapper">
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                  <button (click)="copyMessage('0085759053')" type="button"><i aria-hidden="true"
                                                                                               class="fa fa-clone"></i>
                                    copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                  <button (click)="copyMessage('Personal Branch')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                  <button (click)="copyMessage('Epic Green Ecosystems (Pvt) Ltd')" type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                  <button
                                    (click)="copyMessage(this.commonService.orderIdLengthTrim(paymentId,transactionTrimValue))"
                                    type="button">
                                    <i aria-hidden="true" class="fa fa-clone"></i> Copy
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                              <button style="background: none;font-size: 15px;" (click)="copyMessage(subTotalAmount+'')"
                                      type="button">
                                <i aria-hidden="true" class="fa fa-clone"></i> Copy
                              </button>
                            </p>
                            <a class="btn" href="https://ebanking.hnb.lk/corp/AuthenticationController?"
                               rel="noopener noreferrer" target="_blank"><i class="fa fa-credit-card"
                                                                            aria-hidden="true"></i>
                              {{'CHECKOUT.BANK_TRANSFER.GO_TO_PORTAL' | translate}}</a>

                          </div>
                          <p class="text-center mt-3">
                            <strong> {{'CHECKOUT.WHEN_THE_PAYMENT_TRANSFER' | translate}}</strong>
                          </p>

                        </div>
                      </div>


                      <div class="pmt-tab-item" *ngIf="pmtTabItem==2">
                        <div class="col-12 text-center">
                          <h4>{{'CHECKOUT.CASH_DEPOSIT' | translate}}<span class="pmt-print"></span></h4>
                          <p>{{'CHECKOUT.BANK_TRANSFER.LINK_2' | translate}}</p>

                          <div class="bank-account-details cashPayment">
                            <div class="bi-info-wrapper">
                              <img alt="" src="../../../assets/images/payment-types/boc.png" />
                              <div class="bi-inner">
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.BOC_ACCOUNT' | translate}} : <span>0085759053</span>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_BRANCH' | translate}} :
                                  <span>{{'CHECKOUT.PERSONAL_BRANCH' | translate}}</span>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.ACCOUNT_HOLDER' | translate}} :
                                  <span>{{'CHECKOUT.EPIC_GREEN_ECO' | translate}}</span>
                                </div>
                                <div class="bi-item">
                                  {{'CHECKOUT.BANK_TRANSFER.TRANSACTION_ID' | translate}} :
                                  <span>{{this.commonService.orderIdLengthTrim(paymentId, transactionTrimValue)}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="clear">&nbsp;</div>
                            <p class="bi-total">{{'CHECKOUT.AMOUNT_TO_DEPOSIT' | translate}} :
                              <span>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</span>
                            </p>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="pbsp-raw">
                  <div class="row">
                    <div class="col-md-4">
                      <h5 *ngIf="activeLang === 'en'">Powered by:</h5>
                      <h5 *ngIf="activeLang === 'si'">බල ගැන්වුයේ:</h5>
                      <h5 *ngIf="activeLang === 'ta'">மூலம் இயக்கப்படுகிறது:</h5>
                      <img alt="" src="../../../assets/images/powered-by.png" />
                    </div>
                    <div class="col-md-8">
                      <h5 *ngIf="activeLang === 'en'">Secure Payment:</h5>
                      <h5 *ngIf="activeLang === 'si'">ආරක්ෂිත ගෙවීම:</h5>
                      <h5 *ngIf="activeLang === 'ta'">பாதுகாப்பான கட்டணம்:</h5>
                      <img alt="" src="../../../assets/images/secure-payment.png" />
                    </div>
                  </div>
                </div>
              </div>


              <div class="testimonials-sl">
                <h4 *ngIf="activeLang === 'en'">Testimonials</h4>
                <h4 *ngIf="activeLang === 'si'">අදහස්</h4>
                <h4 *ngIf="activeLang === 'ta'">கருத்துரைகள்</h4>
                <ngx-slick-carousel [config]="coTestimonialConfig" class="carousel">
                  <div ngxSlickItem class="slide ps-item titem">
                    <div class="t-header d-flex">
                      <div class="imgt">
                        <img src="assets/images/testimonials/Sewwandi.jpeg" alt="">
                      </div>
                      <div class="textt">
                        <h5 *ngIf="activeLang === 'en'">Sewwandi Gunawardana</h5>
                        <h5 *ngIf="activeLang === 'si'">සෙව්වන්දි ගුණවර්ධන</h5>
                        <h5 *ngIf="activeLang === 'ta'">சேவாண்டி குணவர்தன</h5>
                        <p *ngIf="activeLang === 'en'">Gampaha</p>
                        <p *ngIf="activeLang === 'si'">ගම්පහ</p>
                        <p *ngIf="activeLang === 'ta'">கம்பாஹா</p>
                      </div>
                    </div>
                    <div class="t-message">
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="tmsghead" *ngIf="activeLang === 'en'">Quality Products</p>
                        <p class="tmsghead" *ngIf="activeLang === 'si'">ගුණාත්මක නිෂ්පාදන</p>
                        <p class="tmsghead" *ngIf="activeLang === 'ta'">தரமான தயாரிப்புகள்</p>
                        <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black" size="16px"
                                     readonly="true"></star-rating>
                      </div>
                      <p class="msg-full" *ngIf="activeLang === 'en'">
                        Helaviru Digital Agriculture Center provided me a very good service. The goods I ordered
                        received in very good quality as expected. The seller was responsive, and I must say the
                        Helaviru Call Center agents were supportive to make sure I received the order on time in
                        expected Quality. I will continuously purchase goods from Helaviru Digital Market.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'si'">
                        හෙළවිරු ඩිජිටල් කෘෂිකාර්මික මධ්‍යස්ථානය මට ඉතා හොඳ සේවාවක් ලබා දෙන ලදී. මා ඇණවුම් කළ භාණ්ඩ
                        අපේක්ෂිත පරිදි ඉතා හොඳ තත්ත්වයේ ලැබුණි. විකුණුම්කරු ප්‍රතිචාර දැක්වූ අතර, නියමිත වේලාවට මට
                        ඇණවුම ලැබුනේද යන්න සහ ගුණාත්මක බව සහතික කර ගැනීම සඳහා හෙළවිරු මධ්‍යස්ථානයේ පාරිභෝගික සේවා අංශය
                        දිගින් දිගටම සහය වූ බව මා පැවසිය යුතුමය. එබැවින් මා දිගින් දිගටම හෙළවිරු ඩිජිටල් වෙළඳපොළෙන්
                        භාණ්ඩ මිලදී ගන්නෙමි.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'ta'">
                        ஹெலவிரு டிஜிட்டல் வேளாண்மை மையம் எனக்கு மிகச் சிறந்த சேவையை வழங்கியது. நான் ஆர்டர் செய்த
                        பொருட்கள் எதிர்பார்த்தபடி நல்ல தரத்தில் கிடைத்தன. விற்பனையாளர் பதிலளித்தார், நான் எதிர்பார்த்த
                        தரத்தில் சரியான நேரத்தில் ஆர்டரைப் பெற்றேன் என்பதை உறுதிப்படுத்த ஹீல்விரு கால் சென்டர்
                        முகவர்கள் ஆதரவளித்தார்கள் என்று நான் சொல்ல வேண்டும். நான் தொடர்ந்து ஹெலவீரு டிஜிட்டல்
                        சந்தையில் இருந்து பொருட்களை வாங்குவேன்.
                      </p>
                    </div>
                  </div>
                  <div ngxSlickItem class="slide ps-item titem">
                    <div class="t-header d-flex">
                      <div class="imgt">
                        <img src="assets/images/testimonials/dummy450x450.jpg" alt="">
                      </div>
                      <div class="textt">
                        <h5 *ngIf="activeLang === 'en'">Malith Malan Dabarera</h5>
                        <h5 *ngIf="activeLang === 'si'">මලිත් මාලන් දබරෙරා</h5>
                        <h5 *ngIf="activeLang === 'ta'">மலித் மாலன் தபரேரா</h5>
                        <p *ngIf="activeLang === 'en'">Anuradhapura</p>
                        <p *ngIf="activeLang === 'si'">අනුරාධපුර</p>
                        <p *ngIf="activeLang === 'ta'">அனுராதபுரம்</p>
                      </div>
                    </div>
                    <div class="t-message">
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="tmsghead" *ngIf="activeLang === 'en'">On time Settlements for Farmers</p>
                        <p class="tmsghead" *ngIf="activeLang === 'si'">නියමිත වේලාවට ගොවීන් සඳහා පියවීම්</p>
                        <p class="tmsghead" *ngIf="activeLang === 'ta'">சரியான நேரத்தில் விவசாயிகளுக்கான தீர்வுகள்</p>
                        <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black" size="16px"
                                     readonly="true"></star-rating>
                      </div>
                      <p class="msg-full" *ngIf="activeLang === 'en'">
                        This is a very good concept. Even this solution is recently introduced, all the payments and
                        settlements were done very quickly. I request the Helaviru Digital Market to implement the
                        Riyawiru Solution immediately so the Transport process can be faster. I am ready to sell all
                        most all my products in Helaviru and I confidently recommend the Agriculture market to other
                        farmers too.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'si'">
                        මෙය ඉතා හොඳ සංකල්පයකි. මෙම විසඳුම මෑතකදී හඳුන්වා දී ඇතත්, සියලු ගෙවීම් සහ පියවීම් ඉතා ඉක්මණින්
                        සිදු කරන ලදී. රියාවිරු විසඳුම හැකි ඉක්මනින් ක්‍රියාත්මක කරන ලෙස මම හෙළවිරු ඩිජිටල් වෙළඳපොළෙන්
                        ඉල්ලා සිටිමි. මගේ සියලු නිෂ්පාදන හෙළවිරු හි විකිණීමට මම සුදානම් වන අතර කෘෂිකාර්මික වෙළඳපොළ
                        අනෙකුත් ගොවීන්ට ද නිර්දේශ කරමි.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'ta'">
                        இது ஒரு நல்ல கருத்து. இந்த தீர்வு கூட சமீபத்தில் அறிமுகப்படுத்தப்பட்டது, அனைத்து
                        கொடுப்பனவுகளும் தீர்வுகளும் மிக விரைவாக செய்யப்பட்டன. ரியாவிரு தீர்வை உடனடியாக செயல்படுத்த
                        ஹெலவீரு டிஜிட்டல் சந்தையை நான் கேட்டுக்கொள்கிறேன், எனவே போக்குவரத்து செயல்முறை வேகமாக
                        இருக்கும். எனது அனைத்து தயாரிப்புகளையும் ஹெலவிருவில் விற்க நான் தயாராக இருக்கிறேன், வேளாண்
                        சந்தையை மற்ற விவசாயிகளுக்கும் நம்பிக்கையுடன் பரிந்துரைக்கிறேன்
                      </p>
                    </div>
                  </div>
                  <div ngxSlickItem class="slide ps-item titem">
                    <div class="t-header d-flex">
                      <div class="imgt">
                        <img src="assets/images/testimonials/dummy450x450.jpg" alt="">
                      </div>
                      <div class="textt">
                        <h5 *ngIf="activeLang === 'en'">K.H Sanjeewani</h5>
                        <h5 *ngIf="activeLang === 'si'">කේ.එච්. සංජීවනි</h5>
                        <h5 *ngIf="activeLang === 'ta'">கே.எச். சஞ்சீவானி</h5>
                        <p *ngIf="activeLang === 'en'">Hambantota</p>
                        <p *ngIf="activeLang === 'si'">හම්බන්තොට</p>
                        <p *ngIf="activeLang === 'ta'">ஹம்பாந்தோட்டா</p>
                      </div>
                    </div>
                    <div class="t-message">
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="tmsghead" *ngIf="activeLang === 'en'">Happy to be a Seller in Helaviru!</p>
                        <p class="tmsghead" *ngIf="activeLang === 'si'">හෙළවිරු හි විකුණුම්කරුවෙකු වීම සතුටක්!</p>
                        <p class="tmsghead" *ngIf="activeLang === 'ta'">ஹெலவிருவில் விற்பனையாளராக இருப்பதில்
                          மகிழ்ச்சி!</p>
                        <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black" size="16px"
                                     readonly="true"></star-rating>
                      </div>
                      <p class="msg-full" *ngIf="activeLang === 'en'">
                        My first sale from Helaviru was an order worth of 90,000LKR. This sale was done very quickly
                        with the support of the Helaviru Call center Agents’ coordination with the buyer. I joined
                        Helaviru without knowing the opportunities it provides us but now I know Helaviru can help the
                        sellers / farmers like us to improve our sales with direct buyers. I am now a happy and an
                        active seller in Helaviru Digital Market from Hambantota and ready to deliver my products all
                        over Sri Lanka from Helaviru.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'si'">
                        හෙළවිරු මධ්‍යස්ථානයේ මාගේ පළමු ඇනවුම රු.90,000 වටිනාකමකින් යුක්ත විය. හෙළවිරු පාරිභෝගික සේවා
                        අංශයේ සහය ඇතිව මෙම ඇනවුම ඉතාම ඉක්මනින් මා හට සම්පුර්ණ කිරීමට හැකි විය. මා හෙළවිරු හා සම්බන්ධ
                        වුයේ මෙය මගින් අප හට ඇති අවස්ථාවන් නොදැන නමුත් හෙළවිරු මගින් සෘජු ගැනුම්කරුවන් සමඟ අපගේ
                        විකුණුම් වැඩි දියුණු කිරීමට, විකුණුම්කරුවන්ට හා ගොවියන්ට සහය වන බව මා දැන් දනිමි. හම්බන්තොට
                        සිට හෙළවිරු ඩිජිටල් වෙළඳපොලේ සතුටින් හා ක්‍රියාකාරී අලෙවිකරුවෙකු වන මම, මගේ නිෂ්පාදන ශ්‍රී
                        ලංකාව පුරා හෙළවිරු වෙතින් ලබා දීමට සූදානම්ය.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'ta'">
                        ஹெலவீருவிலிருந்து எனது முதல் விற்பனை 90,000LKR மதிப்புள்ள ஆர்டர். வாங்குபவருடனான ஹெலவிரு கால்
                        சென்டர் முகவர்களின் ஒருங்கிணைப்பின் ஆதரவுடன் இந்த விற்பனை மிக விரைவாக செய்யப்பட்டது. இது
                        எங்களுக்கு வழங்கும் வாய்ப்புகளை அறியாமல் நான் ஹெலவீருவில் சேர்ந்தேன், ஆனால் இப்போது ஹெலவீரு
                        எங்களைப் போன்ற விற்பனையாளர்கள் / விவசாயிகளுக்கு நேரடி வாங்குபவர்களுடன் எங்கள் விற்பனையை
                        மேம்படுத்த உதவ முடியும் என்று எனக்குத் தெரியும். நான் இப்போது ஹம்பாந்தோட்டாவிலிருந்து ஹெலவிரு
                        டிஜிட்டல் சந்தையில் மகிழ்ச்சியான மற்றும் சுறுசுறுப்பான விற்பனையாளராக உள்ளேன், எனது
                        தயாரிப்புகளை இலங்கை முழுவதும் ஹெலவீருவிலிருந்து வழங்க தயாராக இருக்கிறேன்.
                      </p>
                    </div>
                  </div>
                  <div ngxSlickItem class="slide ps-item titem">
                    <div class="t-header d-flex">
                      <div class="imgt">
                        <img src="assets/images/testimonials/Suneth.jpeg" alt="">
                      </div>
                      <div class="textt">
                        <h5 *ngIf="activeLang === 'en'">Suneth Bandara</h5>
                        <h5 *ngIf="activeLang === 'si'">සුනෙත් බන්ඩාර</h5>
                        <h5 *ngIf="activeLang === 'ta'">சுனேத் பண்டாரா</h5>
                        <p *ngIf="activeLang === 'en'">Kurunegala</p>
                        <p *ngIf="activeLang === 'si'">කුරුණෑගල</p>
                        <p *ngIf="activeLang === 'ta'">குருநாகல்</p>
                      </div>
                    </div>
                    <div class="t-message">
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="tmsghead" *ngIf="activeLang === 'en'">A very good initiative!</p>
                        <p class="tmsghead" *ngIf="activeLang === 'si'">ඉතා හොඳ ආරම්භයක්!</p>
                        <p class="tmsghead" *ngIf="activeLang === 'ta'">ஒரு நல்ல ஆரம்பம்!</p>
                        <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black" size="16px"
                                     readonly="true"></star-rating>
                      </div>
                      <p class="msg-full" *ngIf="activeLang === 'en'">
                        I was very happy to see an Online platform for agricultural products in Sri Lanka with new
                        technologies. This is a very good initiative during this Pandemic time where farmers seem
                        helpless without sales. Also, the impact of middlemen seems very low and this will give the
                        farmers to earn their real value and the consumer to pay the actual cost of the fresh
                        products. I wish all the very best for both EPIC and SLTMobitel for a long journey by
                        developing and improving Helaviru Digital Market continuously.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'si'">
                        නව තාක්‍ෂණයන්ගෙන් යුත් ශ්‍රී ලංකාවේ කෘෂිකාර්මික නිෂ්පාදන සඳහා ඔන්ලයින් වෙළදපොලක් දැකීම මට මහත්
                        සතුටක් විය. විකුණුම් නොමැතිව ගොවීන් අසරණව සිටින මෙම වසංගත කාලය තුළ මෙය ඉතා හොඳ පියවරකි. එසේම,
                        මෙහිදී අතරමැදියන්ගේ බලපෑම ඉතා අඩු බව පෙනේ. මෙය ගොවීන්ට ඔවුන්ගේ සැබෑ වටිනාකම උපයා ගැනීමටත්,
                        පාරිභෝගිකයාට නැවුම් නිෂ්පාදනවල සැබෑ පිරිවැය ගෙවීමටත් හැකි වනු ඇත. හෙළවිරු ඩිජිටල් වෙළඳපල
                        අඛණ්ඩව සංවර්ධනය කිරීම හා වැඩිදියුණු කිරීම තුළින් එපික් සහ එස්එල්ටී මොබිටෙල් යන ආයතන දෙකටම දිගු
                        ගමනක් යාමට මම සුබ පතමි.
                      </p>
                      <p class="msg-full" *ngIf="activeLang === 'ta'">
                        புதிய தொழில்நுட்பங்களுடன் இலங்கையில் விவசாய பொருட்களுக்கான ஆன்லைன் பால்ட்ஃபார்மைப் பார்த்ததில்
                        நான் மிகவும் மகிழ்ச்சியடைந்தேன். விற்பனையாளர்கள் இல்லாமல் விவசாயிகள் உதவியற்றவர்களாகத்
                        தோன்றும் இந்த தொற்றுநோய்களின் போது இது ஒரு நல்ல முயற்சி. மேலும், இடைத்தரகர்களின் தாக்கம் மிகக்
                        குறைவாகத் தெரிகிறது, இது விவசாயிகளுக்கு அவர்களின் உண்மையான மதிப்பைப் பெறவும், நுகர்வோர் புதிய
                        பொருட்களின் உண்மையான செலவைச் செலுத்தவும் உதவும். ஹெலவிரு டிஜிட்டல் சந்தையை தொடர்ச்சியாக
                        அபிவிருத்தி செய்வதன் மூலமும் மேம்படுத்துவதன் மூலமும் ஒரு நீண்ட பயணத்திற்கு ஈபிஐசி மற்றும்
                        எஸ்எல்டி இருவருக்கும் நான் வாழ்த்துகிறேன்.
                      </p>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <a data-target="#paymentConfirmation" data-toggle="modal" id="PAYCORPMODEL" style="display: none"></a>
                  <p style="font-size: 13px">
                    <span *ngIf="selectPaymentType==1">{{'CHECKOUT.AGREE_MESSAGE' | translate}}</span>
                    <span *ngIf="selectPaymentType==2 || selectPaymentType==3">{{'CHECKOUT.AGREE_MESSAGE_PROCEED' |
                      translate}}</span>

                    <a href="/checkout-terms/{{tenantValue}}" target="_blank">{{'CHECKOUT.TERMS_CONDITIONS' |
                      translate}}</a>.
                  </p>
                </div>
                <div class="col-lg-6">
                  <button (click)="cardPayment()" *ngIf="selectPaymentType==1" class="btn-login"
                          style="width: 100%; max-width: 200px; float: right;" type="submit"><i aria-hidden="true"
                                                                                                class="fa fa-credit-card"></i>
                    {{'CHECKOUT.PAY_NOW.PAY_NOW' | translate}}
                  </button>
                  <a #cardPaymentConfirmation data-target="#cardPaymentConfirmation" data-toggle="modal"
                     style="display: none"></a>
                  <button (click)="smartPayment()" *ngIf="selectPaymentType==2" class="btn-login"
                          style="width: 100%; max-width: 200px; float: right;" type="submit">{{'CHECKOUT.PROCEED.PROCEED' |
                    translate}}
                  </button>
                  <a #smartPayConfirmation data-target="#enterReferenceNumber" data-toggle="modal"
                     style="display: none"></a>
                  <button *ngIf="selectPaymentType==3" (click)="selectBankTransferOption()" class="btn-login"
                          style="width: 100%; max-width: 200px; float: right;" type="submit">{{'CHECKOUT.PROCEED.PROCEED' |
                    translate}}</button>
                </div>
              </div>

              <button #openBankTransferModal data-target="#bankTransferProceed" data-toggle="modal" hidden></button>
            </div>
            <div class="checkout-summery">
              <div class="c-products-sum" style=" overflow-y: scroll; margin-bottom: 8vh;">

                <div *ngFor="let item of selectItem" class="dcp-item">
                  <div class="dcp-img">
                    <div class="dcp-img" *ngIf="_productType !== 'ORDER_REQUEST'">
                      <img *ngIf="item.productImages.length > 0" alt="" class="imageSize"
                           src="{{this.mainUrl.MAIN_URL_FILE + item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}" />
                      <img *ngIf="item.productImages.length <= 0" alt="" class="imageSize"
                           src="assets/defaultBrokenImage.jpg" />
                    </div>
                    <div class="dcp-img" *ngIf="_productType === 'ORDER_REQUEST'">
                      <img alt="" class="imageSize" src="{{image_orderRequest}}" />
                    </div>
                    <div class="dcp-info" *ngIf="_productType !== 'ORDER_REQUEST'">
                      <span class="p-name">{{item.name}}</span>
                      <span class="p-qty">{{item.quantity}}{{item.unitName}} x
                        {{'CURRENCY.CURRENCY' | translate}}{{item.unitPrice| number:'2.2-2'}}
                      </span><br>
                      <span *ngIf="item.quantity === 0" style="color: red">{{'CHECKOUT.OUT_OF_STOCK' |
                        translate}}</span>
                    </div>
                    <div class="dcp-info" *ngIf="_productType === 'ORDER_REQUEST'">
                      <span class="p-name">{{item.name}}</span>
                      <span class="p-qty">
                        {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{item.unitPrice| number:'2.2-2'}}
                      </span>
                      <span class="p-qty">
                        {{item.quantity}}{{item.unit}}
                      </span><br>
                      <span *ngIf="item.quantity === 0" style="color: red">{{'CHECKOUT.OUT_OF_STOCK' |
                        translate}}</span>
                    </div>
                  </div>

                  <ngx-content-loading *ngIf="!selectItem">
                    <svg:g ngx-code-preset></svg:g>
                  </ngx-content-loading>
                </div>

                <div class="checkout-summery-midle-box" style=" text-align: center;">
                </div>

                <div *ngIf="selectItem" class="checkout-summery-bottom">
                  <dl class="cb-sum">

                  </dl>
                  <dl class="cb-sum total">
                    <dt style="font-weight: bold">{{'CHECKOUT.SUBTOTAL' | translate}}</dt>

                    <dd>{{'CURRENCY.CURRENCY' | translate}}<span>&nbsp;</span>{{subTotalAmount| number:'2.2-2'}}</dd>
                  </dl>
                  <dl class="cb-sum total">
                    <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Platform Charges</dt>
                    <dt style="font-weight: bold" *ngIf="activeLang === 'si'">වේදිකා ගාස්තු</dt>
                    <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">இயங்குதள கட்டணங்கள்</dt>

                    <dd *ngIf="selectPaymentType === 1">{{'CURRENCY.CURRENCY' | translate}}
                      <span>&nbsp;</span>
                      <span id="platformCharge">
                        {{calPlatformCharge(subTotalAmount, platformChargers)| number:'2.2-2'}}
                      </span>
                    </dd>
                    <dd *ngIf="selectPaymentType !== 1">
                      <span *ngIf="activeLang === 'en'" style="font-size: 20px!important;">Free</span>
                      <span *ngIf="activeLang === 'si'" style="font-size: 16px!important;">ගාස්තුවක් අය නොකරේ</span>
                      <span *ngIf="activeLang === 'ta'" style="font-size: 20px!important;">கட்டணம் இல்லை</span>
                    </dd>
                  </dl>
                  <dl class="cb-sum total">
                    <dt style="font-weight: bold" *ngIf="activeLang === 'en'">Grand Total</dt>
                    <dt style="font-weight: bold" *ngIf="activeLang === 'si'">මුළු එකතුව</dt>
                    <dt style="font-weight: bold" *ngIf="activeLang === 'ta'">மொத்த மொத்த தொகை</dt>

                    <dd *ngIf="selectPaymentType === 1">{{'CURRENCY.CURRENCY' | translate}}
                      <span>&nbsp;</span>
                      <span id="grandTotal">
                        {{calGrandTotal(subTotalAmount, platformChargers)| number:'2.2-2'}}
                      </span>
                    </dd>
                    <dd *ngIf="selectPaymentType !== 1">{{'CURRENCY.CURRENCY' | translate}}
                      <span>&nbsp;</span>{{subTotalAmount| number:'2.2-2'}}
                    </dd>
                  </dl>
                </div>
                <ngx-content-loading *ngIf="!selectItem">
                  <svg:g ngx-code-preset></svg:g>
                </ngx-content-loading>
              </div>
              <div *ngIf="bidProduct" class="checkout-summery">
                <div class="c-products-sum">
                  <div *ngFor="let item of selectItem" class="dcp-item">
                    <div class="dcp-img">
                      <img *ngIf="item.productImages.length > 0" alt="" class="imageSize"
                           src="{{this.mainUrl.MAIN_URL_FILE + item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}" />
                      <img *ngIf="item.productImages.length <= 0" alt="" class="imageSize"
                           src="assets/defaultBrokenImage.jpg" />
                    </div>
                    <div class="dcp-info">
                      <span>{{item.name}}</span>

                      <span>{{item.currency}}<span>&nbsp;</span>{{item.unitPrice| number:'2.2-2'}}</span>

                    </div>
                  </div>
                  <ngx-content-loading *ngIf="!selectItem">
                    <svg:g ngx-code-preset></svg:g>
                  </ngx-content-loading>
                </div>

                <div *ngIf="selectItem" class="checkout-summery-bottom">
                  <dl class="cb-sum total">
                    <dt>{{'CHECKOUT.TOTAL' | translate}}</dt>
                    <dd>{{'CURRENCY.CURRENCY' | translate}}{{subTotalAmount| number:'2.2-2'}}</dd>
                  </dl>
                </div>
                <ngx-content-loading *ngIf="!selectItem">
                  <svg:g ngx-code-preset></svg:g>
                </ngx-content-loading>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <app-footer></app-footer>

  <!-- Card Payment Confirmation -->
  <div aria-hidden="true" class="modal fade" data-backdrop="static" id="cardPaymentConfirmation" role="dialog"
       tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-1000 margin-0 payment-confirmation" role="document">
      <div class="modal-content">
        <button aria-label="Close" class="model-close-btn" data-dismiss="modal">
          <i aria-hidden="true" class="fa fa-times"></i>
        </button>

        <div class="row">
          <h3 style="padding: 30px 20px 0 60px;
        margin: 0;">{{'CHECKOUT.PAY_NOW.PAYMENT_CONFIRM' | translate}}</h3>
        </div>


        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="pca-col" style="margin-top: 50px;">
              <h5>{{'CHECKOUT.PAY_NOW.SHIPPING_ADDRESS' | translate}}</h5>
              <img alt="" src="../../../assets/images/icons/pa-icon.png" />
              {{name_d}},<br>
              {{address1_d}},<br>
              {{selectDeliveryCityName}},<br>
              {{postal_d}},<br>
              {{country_d}}.<br>
              {{mobile_d}}<br>
            </div>
            <div class="pca-col" style="margin-top: 97px;">
              <h5>{{'CHECKOUT.PAY_NOW.PAYMENT_METHOD' | translate}}</h5>
              <label *ngIf="selectPaymentType===1">{{'CHECKOUT.PAY_NOW.PAY_WITH_CARD' | translate}}</label><br>
              <label *ngIf="selectPaymentType===2">{{'CHECKOUT.PAY_NOW.PAY_NOW' | translate}}</label>
            </div>
            <p style="color: red;
             display: block;
             position: relative;
             padding-left: 70px;
             margin-bottom: 50px;
             font-size: 13px;
             line-height: 20px;">
              {{'CHECKOUT.PAY_NOW.ALERT_1' | translate}}
              {{checkoutExpiryTime}}
              {{'CHECKOUT.PAY_NOW.ALERT_2' | translate}}

            </p>
          </div>
          <div class="col-md-6 col-sm-12">

            <div class="psmrc-wp">
              <div class="psmrc-inner" style="height: 260px; overflow-y: scroll; margin-bottom: 8vh;">
                <div *ngFor="let item of selectItem" class="dcp-item">
                  <div class="dcp-img" *ngIf="_productType !== 'ORDER_REQUEST'">
                    <img *ngIf="item.productImages.length > 0" alt="" class="imageSize"
                         src="{{this.mainUrl.MAIN_URL_FILE + item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}" />
                    <img *ngIf="item.productImages.length <= 0" alt="" class="imageSize"
                         src="assets/defaultBrokenImage.jpg" />
                  </div>
                  <div class="dcp-img" *ngIf="_productType === 'ORDER_REQUEST'">
                    <img alt="" class="imageSize" src="{{image_orderRequest}}" />
                  </div>
                  <div class="dcp-info" style="padding-left: 20px;">
                    <span class="p-name">{{item.name}}</span>
                    <span class="p-qty">{{item.quantity}} x
                      {{'CURRENCY.CURRENCY' | translate}}{{item.unitPrice| number:'2.2-2'}}</span>
                  </div>
                </div>

              </div>

              <div class="checkout-summery-bottom">
                <dl class="cb-sum total">
                  <dt style="font-weight: bold">{{'CHECKOUT.TOTAL' | translate}}</dt>
                  <dd style="font-weight: 700;">
                    {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{subTotalAmount| number:'2.2-2'}}
                  </dd>
                </dl>
                <p style="padding-top: 10px; font-size: 13px;color: red;">
                  {{'PAYMENT.h1' | translate}}
                </p>
              </div>
            </div>

            <button (click)="confirmPayment()" class="btn-login" data-dismiss="modal"
                    type="button">{{'CHECKOUT.PAY_NOW.CONFIRM' | translate}}
            </button>
            <a #paymentSuccess data-target="#paymentSuccess" data-toggle="modal" style="display: none"></a>
          </div>
        </div>

      </div>
    </div>
  </div>


  <!-- Payment Success -->
  <div aria-hidden="true" class="modal fade" data-backdrop="static" id="paymentSuccess" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-1000 margin-0 payment-confirmation" role="document">
      <div class="modal-content">
        <div class="row">
          <h3>{{'CHECKOUT.PAY_NOW.PAYMENT_SUCCESS' | translate}}</h3>
          <img class="helaviruLogo" src="../../../assets/helaviru.png" />
        </div>

        <div class="row apsm-wrapper">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12" style="font-size: 14px;font-weight: bold;">
                {{'CHECKOUT.ODER_CONFIRM_MSG_1' | translate}}
                {{this.commonService.orderIdLengthTrim(this.orderId, transactionTrimValue)}}
                {{'CHECKOUT.ODER_CONFIRM_MSG_2' | translate}}


              </div>
              <div class="col-md-12">
                <div class="pcs-qr" data-dismiss="modal" data-target="#itemRecipt" data-toggle="modal">
                  <img [src]="qrImage" alt="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="pcs-info">
                  <br>
                  <p>{{'CHECKOUT.PAY_NOW.TRACKING_INFO' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">

            <div class="psmrc-wp">
              <div class="psmrc-inner" style="height: 260px; overflow-y: scroll; margin-bottom: 8vh;">

                <div *ngFor="let item of selectItem" class="dcp-item">
                  <div class="dcp-img" *ngIf="_productType !== 'ORDER_REQUEST'">
                    <img alt="" class="imageSize" src="{{item.productImages[0]?.image}}" />
                  </div>
                  <div class="dcp-img" *ngIf="_productType === 'ORDER_REQUEST'">
                    <img alt="" class="imageSize" src="{{image_orderRequest}}" />
                  </div>
                  <div class="dcp-info">
                    <span class="p-name">{{item.name}}</span>
                    <span class="p-qty">{{item.quantity}}{{item.unitType}}
                      x {{currency}}&nbsp;{{item.unitPrice| number:'2.2-2'}}</span>
                    <span class="u-item" *ngIf="_productType !== 'ORDER_REQUEST'">{{'CURRENCY.CURRENCY' |
                      translate}}&nbsp;
                      {{item.quantity * item.unitPrice| number:'2.2-2'}}</span>
                  </div>
                </div>
              </div>

              <div class="checkout-summery-bottom">
                <dl class="cb-sum total">
                  <dt style="font-weight: bold">{{'CHECKOUT.SUBTOTAL' | translate}}</dt>

                  <dd>{{'CURRENCY.CURRENCY' | translate}}<span>&nbsp;</span>{{subTotalAmount| number:'2.2-2'}}</dd>
                </dl>
                <dl class="cb-sum total">
                  <dt style="font-weight: bold">Platform Charges</dt>

                  <dd>{{'CURRENCY.CURRENCY' | translate}}
                    <span>&nbsp;</span>
                    <span>
                      {{calPlatformCharge(subTotalAmount, platformChargers)| number:'2.2-2'}}
                    </span>
                  </dd>
                </dl>
                <dl class="cb-sum total">
                  <dt style="font-weight: bold">Grand Total</dt>

                  <dd>{{'CURRENCY.CURRENCY' | translate}}
                    <span>&nbsp;</span>
                    <span>
                      {{calGrandTotal(subTotalAmount, platformChargers)| number:'2.2-2'}}
                    </span>
                  </dd>
                </dl>

              </div>
            </div>

            <button [routerLink]="'/my-account/purchases/'+tenantValue" aria-label="Close" class="btn-login"
                    data-dismiss="modal" type="button">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.CLOSE' | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Enter the reference number -->
  <div aria-hidden="true" class="modal fade" data-backdrop="static" id="enterReferenceNumber" role="dialog"
       tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-500 margin-0" role="document">
      <div class="modal-content">
        <button #closeReferenceInputForm aria-label="Close" class="model-close-btn" data-dismiss="modal"
                (click)="clearInputFields()">
          <i aria-hidden="true" class="fa fa-times"></i>
        </button>
        <form (ngSubmit)="submitReferenceForm('NOW')" [formGroup]="referenceNoForm">
          <div class="reference-no">
            <h2>{{'CHECKOUT.PROCEED.ENTER_QR_CODE' | translate}}</h2>
            <div class="form-group">
              <input [(ngModel)]="referenceNumber"
                     [ngClass]="{'is-invalid': submittedReferenceNumber && f2.referenceNumber.errors}" class="qrinput"
                     formControlName="referenceNumber" placeholder="{{'CHECKOUT.PROCEED.REFERENCE_NUMBER' | translate}}"
                     type="text">
              <div *ngIf="submittedReferenceNumber && f2.referenceNumber.errors" class="inputError">
                <div *ngIf="f2.referenceNumber.errors.required" style="font-size: 10px;color: red">
                  {{'CHECKOUT.PROCEED.REFERENCE_NUMBER_REQUIRED' | translate}}
                </div>
                <div *ngIf="f2.referenceNumber.errors.pattern" style="font-size: 10px;color: red">
                  {{'Invalid_reference_number'|translate}}
                </div>
              </div>

            </div>
            <p>{{'CHECKOUT.PROCEED.MOVE_FORWARD' | translate}}</p>
            <button class="btn-login" type="submit">{{'CHECKOUT.PROCEED.DONE' | translate}}
            </button>
          </div>

        </form>
      </div>
    </div>
  </div>


  <!-- Delivery options -->
  <div aria-hidden="true" class="modal fade npa-model" id="deliveryoptions" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'CHECKOUT.DELIVERY_OPTION' | translate}}</h5>
          <button (click)="selectNewProductOptions==0" aria-label="Close" class="close" data-dismiss="modal"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <p>{{'CHECKOUT.USE_RIYAVIRU_TRANSPORT' | translate}}</p>

            <div class="radio-container">
              <div class="co-check">
                <input checked id="radio4" name="radio" type="radio" value="3">
                <label (click)="selectDeliveryOptions=1" for="radio4">Yes</label>
              </div>
              <div class="co-check">
                <input id="radio5" name="radio" type="radio" value="4">
                <label (click)="selectDeliveryOptions=0" for="radio5">No</label>
              </div>
            </div>

            <div *ngIf="selectDeliveryOptions==1" class="mt-4">
              <dl class="das-sum">
                <dt>
                  {{'CHECKOUT.DELIVERY_ADDRESS' | translate}}
                  <span>{{'CHECKOUT.IF_ADDRESS_CHANGE' | translate}}</span>
                </dt>
                <dd style="text-align: left;">
                  12/45 jayawadanagama / batharamulla
                </dd>
              </dl>
              <dl class="das-sum">
                <dt>
                  {{'CHECKOUT.PICKUP_ADDRESS' | translate}}
                </dt>
                <dd style="text-align: left;">
                  12/45 jayawadanagama / batharamulla
                </dd>
              </dl>
              <dl class="das-sum">
                <dt>{{'CHECKOUT.DELIVERY_DISTANCE' | translate}}
                </dt>
                <dd style="text-align: left;">
                  10KM
                </dd>
              </dl>
              <div class="selectdelivery">
                <h4>{{'CHECKOUT.SELECT_DELIVERY_VEHICLE' | translate}}</h4>

                <div (click)="selectDeliveryType=1" [ngClass]="{'active':selectDeliveryType==1}" class="dly-type">
                  <img src="../../../assets/images/icons/three-wheler.png">
                  <span>Three wheeler</span>
                  <i aria-hidden="true" class="fa fa-check-circle"></i>
                </div>
                <div (click)="selectDeliveryType=2" [ngClass]="{'active':selectDeliveryType==2}" class="dly-type">
                  <img src="../../../assets/images/icons/van.png">
                  <span>Van</span>
                  <i aria-hidden="true" class="fa fa-check-circle"></i>
                </div>
                <div (click)="selectDeliveryType=3" [ngClass]="{'active':selectDeliveryType==3}" class="dly-type">
                  <img src="../../../assets/images/icons/truck.png">
                  <span>Truck</span>
                  <i aria-hidden="true" class="fa fa-check-circle"></i>
                </div>
              </div>


              <dl class="das-sum">
                <dt>{{'CHECKOUT.AVERAGE_TRANSPORT' | translate}}
                </dt>
                <dd style="text-align: left;">
                  {{'CURRENCY.CURRENCY' | translate}} 500.00
                </dd>
              </dl>

              <p style="color: red;">{{'CHECKOUT.TRANSPORT_ALERT_1' | translate}}</p>
              <p>{{'CHECKOUT.TRANSPORT_ALERT_2' | translate}}</p>
            </div>

          </div>
          <button aria-label="Close" class="btn-login" data-dismiss="modal" type="button">{{'CHECKOUT.CLOSE' |
            translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <button #pendingBankTransfer class="btn-login" data-target="#pendingBankTransfer" data-toggle="modal" hidden
          style="width: 100%; max-width: 200px; float: right;" type="submit"></button>

  <!-- Bank transfer options -->
  <div data-backdrop="static" data-keyboard="false" aria-hidden="true" class="modal fade npa-model"
       id="pendingBankTransfer" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-500 margin-0 profile-edit" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <h3>{{'CHECKOUT.THANKS' | translate}}</h3>
            <p>{{'CHECKOUT.THANKS_MSG' | translate}}</p>
          </div>
          <button [routerLink]="['/my-account/purchases/'+tenantValue]" aria-label="Close" class="btn-login"
                  data-dismiss="modal" type="button">{{'CHECKOUT.CLOSE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Bank Transfer Form -->
  <div aria-hidden="true" class="modal fade" data-backdrop="static" id="bankTransferProceed" role="dialog"
       tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-500 margin-0" role="document">
      <div class="modal-content" style="justify-content: center!important;">
        <button #closeBankTransferForm aria-label="Close" class="model-close-btn" data-dismiss="modal"
                (click)="clearInputFields()">
          <i aria-hidden="true" class="fa fa-times"></i>
        </button>
        <form (ngSubmit)="submitReferenceForm('NOW')" [formGroup]="bankTransferForm">
          <div class="reference-no">
            <h3 style="font-size: 16px;font-weight: bold">{{'MY_ACCOUNT.SALES.ORDERS.BANK_SLIP_IMAGES' | translate}}
            </h3>
            <br>
            <h3 style="font-size: 14px" *ngIf="pmtTabItem === 2">{{'MY_ACCOUNT.SALES.ORDERS.UPLOAD_YOUR_BANK_SLIP' |
              translate}}.</h3>
            <h3 style="font-size: 14px" *ngIf="pmtTabItem === 1">{{'MY_ACCOUNT.SALES.ORDERS.SMART_PAY_SLIP' |
              translate}}
              .</h3>
            <div class="form-group">
              <ngx-dropzone style="display: unset!important;" (change)="onSelect($event)" accept="image/png,image/jpeg"
                            [disableClick]="this.files.length > 0" [multiple]="false">
                <ngx-dropzone-image-preview (removed)="onRemove(f)" *ngFor="let f of files" [file]="f"
                                            [removable]="true" ngProjectAs="ngx-dropzone-preview">
                </ngx-dropzone-image-preview>
                <div *ngIf="this.files.length !== 1" class="imgadd" style="text-align: center!important;"><em
                  class="fa fa-plus"></em></div>
              </ngx-dropzone>

            </div>
            <p>{{'CHECKOUT.PROCEED.MOVE_FORWARD' | translate}}</p>
            <div class="row">
              <button class="btn-login" type="button"
                      (click)="bankTransferSubmitLater('LATER')">{{'IWILLSUBMITLATER'|translate}}
              </button>
              &nbsp;&nbsp;
              <button class="btn-login" type="submit">{{'CHECKOUT.PAY_NOW.CONFIRM' | translate}}
              </button>
            </div>

          </div>

        </form>
      </div>
    </div>
  </div>
</div>
