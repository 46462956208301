import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../../../common/common.service';
import {InteractionService} from '../../../../../service/interaction.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-l-sidebar',
  templateUrl: './l-sidebar.component.html',
  styleUrls: ['./l-sidebar.component.css']
})
export class LSidebarComponent implements OnInit {

  activeTab = 1;
  tenantValue: any;
  activeLang: any;
  userRole: any;

  constructor(public commonService: CommonService,
              private route: Router,
              private interactionService: InteractionService) {

    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');

  }

  ngOnInit(): void {
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

    this.userRole = localStorage.getItem('USER_ROLE');

    if (this.userRole === 'SERVICE_PROVIDER' || this.userRole === 'COMPANY_SERVICE_PROVIDER') {
      this.route.navigate(['/value-added-services/loan-forms/for-suppliers/wholesale']);
    }

    if (this.userRole === 'AGGREGATOR' || this.userRole === 'COMPANY_AGGREGATOR') {
      this.route.navigate(['/value-added-services/loan-forms/for-aggregators/wholesale']);
    }
  }

}
