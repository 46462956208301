<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row pip-title">
        <div class="col-12">
          <h2><img src="../../../../../assets/images/icons/overview.svg" style="margin-top: -3px;"/>
            &nbsp;
            <span *ngIf="activeLang === 'en'">Overview - {{'MY_ACCOUNT.SELLING' | translate}}</span>
            <span *ngIf="activeLang === 'si'">දළ විශ්ලේෂණය - {{'MY_ACCOUNT.SELLING' | translate}}</span>
            <span *ngIf="activeLang === 'ta'">கண்ணோட்டம் - {{'MY_ACCOUNT.SELLING' | translate}}</span></h2>
        </div>
        <div class="col-12">
          <div class="separator">&nbsp;</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="pic-main-wp">
            <div class="row">
              <div class="col-md-3">
                <a href="javascript:void(0)">
                  <div class="pic-wp-2 background" [routerLink]="'/my-account/product/wholesale'">
                    <h6 *ngIf="activeLang === 'en'">Products</h6>
                    <h6 *ngIf="activeLang === 'si'">නිෂ්පාදන</h6>
                    <h6 *ngIf="activeLang === 'ta'">தயாரிப்புகள்</h6>
                    <span style="font-size: 20px">{{dashboardDetails?.products}}</span>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a href="javascript:void(0)">
                  <div class="pic-wp-2 background" [routerLink]="'/my-account/orders/wholesale'">
                    <h6 *ngIf="activeLang === 'en'">Orders</h6>
                    <h6 *ngIf="activeLang === 'si'">ඇණවුම්</h6>
                    <h6 *ngIf="activeLang === 'ta'">உத்தரவுகள்</h6>
                    <span style="font-size: 20px">{{dashboardDetails?.orders}}</span>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a href="javascript:void(0)">
                  <div class="pic-wp-2 background" [routerLink]="'/my-account/sales/wholesale'">
                    <h6 *ngIf="activeLang === 'en'">Sales</h6>
                    <h6 *ngIf="activeLang === 'si'">විකුණුම්</h6>
                    <h6 *ngIf="activeLang === 'ta'">விற்பனை</h6>
                    <span style="font-size: 20px">{{dashboardDetails?.sales}}</span>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a href="javascript:void(0)">
                  <div class="pic-wp-2 background" [routerLink]="'/my-account/earnings/wholesale'">
                    <h6 *ngIf="activeLang === 'en'">Total Earnings</h6>
                    <h6 *ngIf="activeLang === 'si'">මුළු ඉපැයීම්</h6>
                    <h6 *ngIf="activeLang === 'ta'">மொத்த வருவாய்</h6>
                    <span style="font-size: 20px">{{dashboardDetails?.totalEarnings | number:'2.2-2'}}</span>
                  </div>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="separator">&nbsp;</div>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h4 *ngIf="activeLang === 'en'">Spot Products</h4>
          <h4 *ngIf="activeLang === 'si'">ක්ෂණික නිෂ්පාදන</h4>
          <h4 *ngIf="activeLang === 'ta'">ஸ்பாட் தயாரிப்புகள்</h4>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <a href="javascript:void(0)">
                <div class="pic-wp-2 ssp-green" [routerLink]="'/my-account/product/wholesale'"
                     [queryParams]="{type:'SPOT'}">
                  <h6 *ngIf="activeLang === 'en'">Active</h6>
                  <h6 *ngIf="activeLang === 'si'">සක්‍රියයි</h6>
                  <h6 *ngIf="activeLang === 'ta'">செயலில்</h6>
                  <span style="font-size: 18px">{{dashboardDetails?.spotActive}}</span>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-md-12">
              <a href="javascript:void(0)">
                <div class="pic-wp-2 ssp-green" [routerLink]="'/my-account/product/wholesale'"
                     [queryParams]="{type:'SPOT'}">
                  <h6 *ngIf="activeLang === 'en'">Expired</h6>
                  <h6 *ngIf="activeLang === 'si'">කල් ඉකුත් වී ඇත</h6>
                  <h6 *ngIf="activeLang === 'ta'">காலாவதியான</h6>
                  <span style="font-size: 18px">{{dashboardDetails?.spotExpired}}</span>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-md-12">
              <a href="javascript:void(0)">
                <div class="pic-wp-2 ssp-green" [routerLink]="'/my-account/product/wholesale'"
                     [queryParams]="{type:'SPOT'}">
                  <h6 *ngIf="activeLang === 'en'">Disabled</h6>
                  <h6 *ngIf="activeLang === 'si'">ආබාධිතයි</h6>
                  <h6 *ngIf="activeLang === 'ta'">முடக்கப்பட்டது</h6>
                  <span style="font-size: 18px">{{dashboardDetails?.spotDisabled}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="row">
            <div class="col-8">
              <h4 *ngIf="activeLang === 'en'">Auction Products</h4>
              <h4 *ngIf="activeLang === 'si'">වෙන්දේසි නිෂ්පාදන</h4>
              <h4 *ngIf="activeLang === 'ta'">ஏல தயாரிப்புகள்</h4>
            </div>
            <!--            <div class="col-4 text-right">-->
            <!--              <a [routerLink]="'/my-account/product/wholesale'">-->
            <!--                <span *ngIf="activeLang === 'en'">View All</span>-->
            <!--                <span *ngIf="activeLang === 'si'">සියල්ල බලන්න</span>-->
            <!--                <span *ngIf="activeLang === 'ta'">அனைத்தையும் காட்டு</span>-->
            <!--                <i aria-hidden="true"-->
            <!--                   class="fa fa-long-arrow-right"></i></a>-->
            <!--            </div>-->
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <a href="javascript:void(0)">
                <div class="pic-wp-2 ssp-green" [routerLink]="'/my-account/product/wholesale'"
                     [queryParams]="{type:'AUCTION'}">
                  <h6 *ngIf="activeLang === 'en'">Active</h6>
                  <h6 *ngIf="activeLang === 'si'">සක්‍රියයි</h6>
                  <h6 *ngIf="activeLang === 'ta'">செயலில்</h6>
                  <span style="font-size: 18px">{{dashboardDetails?.acuActive}}</span>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-md-12">
              <a href="javascript:void(0)">
                <div class="pic-wp-2 ssp-green" [routerLink]="'/my-account/product/wholesale'"
                     [queryParams]="{type:'AUCTION'}">
                  <h6 *ngIf="activeLang === 'en'">Expired</h6>
                  <h6 *ngIf="activeLang === 'si'">කල් ඉකුත් වී ඇත</h6>
                  <h6 *ngIf="activeLang === 'ta'">காலாவதியான</h6>
                  <span style="font-size: 18px">{{dashboardDetails?.acuExpired}}</span>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-md-12">
              <a href="javascript:void(0)">
                <div class="pic-wp-2 ssp-green" [routerLink]="'/my-account/product/wholesale'"
                     [queryParams]="{type:'AUCTION'}">
                  <h6 *ngIf="activeLang === 'en'">Disabled</h6>
                  <h6 *ngIf="activeLang === 'si'">ආබාධිතයි</h6>
                  <h6 *ngIf="activeLang === 'ta'">முடக்கப்பட்டது</h6>
                  <span style="font-size: 18px">{{dashboardDetails?.acuDisabled}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="separator">&nbsp;</div>
        </div>
      </div>


      <div class="row">
        <div class="col-8">
          <h6 *ngIf="activeLang === 'en'">Settlements</h6>
          <h6 *ngIf="activeLang === 'si'">පියවීම්</h6>
          <h6 *ngIf="activeLang === 'ta'">குடியேற்றங்கள்</h6>
        </div>
        <div class="col-4 text-right">
          <a [routerLink]="'/my-account/earnings/wholesale'">
            <span *ngIf="activeLang === 'en'">View All</span>
            <span *ngIf="activeLang === 'si'">සියල්ල බලන්න</span>
            <span *ngIf="activeLang === 'ta'">அனைத்தையும் காட்டு</span>
            <i aria-hidden="true"
               class="fa fa-long-arrow-right"></i></a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3">
          <div class="oea-col green">
            <h6 *ngIf="activeLang === 'en'">Completed Settlements</h6>
            <h6 *ngIf="activeLang === 'si'">සම්පූර්ණ පියවීම්</h6>
            <h6 *ngIf="activeLang === 'ta'">முடிக்கப்பட்ட தீர்வுகள்</h6>
            <span
              style="font-size: 20px"> {{'CURRENCY.CURRENCY' | translate}}&nbsp; {{dashboardDetails?.completeSettlement | number:'2.2-2'}}</span>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="oea-col">
            <h6 *ngIf="activeLang === 'en'">Pending Settlements</h6>
            <h6 *ngIf="activeLang === 'si'">අපේක්ෂිත පියවීම්</h6>
            <h6 *ngIf="activeLang === 'ta'">நிலுவையில் உள்ள தீர்வுகள்</h6>
            <span
              style="font-size: 20px"> {{'CURRENCY.CURRENCY' | translate}}&nbsp; {{dashboardDetails?.pendingSettlement | number:'2.2-2'}}</span>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">

        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3" *ngFor="let salesDetails of dashboardDetails?.orderDTOS">
          <div class="ovps-box" style="padding: 10px;">
            <div class="onpc-img">
              <img *ngIf="salesDetails?.productImage !== null" alt="" src="{{this.mainUrl.MAIN_URL_FILE +
                            salesDetails?.productImage + '/?X-TenantID=wholesale'}}"
                   style="border-radius: 10px;width:50px; height: 50px;object-fit: cover;"/>
              <img *ngIf="salesDetails?.productImage === null"
                   src="assets/defaultBrokenImage.jpg" alt=""
                   style="border-radius: 10px;width:50px; height: 50px;object-fit: cover;"/>
            </div>
            <div class="details">
              <span>{{salesDetails?.transactionId}}</span>
              <h6>{{salesDetails?.productName}}</h6>
              <span>{{convertDate(salesDetails?.created)}}</span>
              <span class="price">+ {{salesDetails?.price | number:'2.2-2'}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-right">
          <h6 *ngIf="activeLang === 'en'" class="oetw-label">Earnings this week: <span
            style="font-size: 20px"> {{'CURRENCY.CURRENCY' | translate}}&nbsp; {{dashboardDetails?.weekEarning | number:'2.2-2'}}</span>
          </h6>
          <h6 *ngIf="activeLang === 'si'" class="oetw-label">මේ සතියේ ඉපැයීම්: <span
            style="font-size: 20px"> {{'CURRENCY.CURRENCY' | translate}}&nbsp; {{dashboardDetails?.weekEarning | number:'2.2-2'}}</span>
          </h6>
          <h6 *ngIf="activeLang === 'ta'" class="oetw-label">இந்த வாரம் வருமானம்: <span
            style="font-size: 20px"> {{'CURRENCY.CURRENCY' | translate}}&nbsp; {{dashboardDetails?.weekEarning | number:'2.2-2'}}</span>
          </h6>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="separator" style="margin: 25px 0;">&nbsp;</div>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <h4 *ngIf="activeLang === 'en'">New Products</h4>
          <h4 *ngIf="activeLang === 'si'">නව නිෂ්පාදන</h4>
          <h4 *ngIf="activeLang === 'ta'">புதிய தயாரிப்புகள்</h4>
        </div>
        <div class="col-4 text-right">
          <a [routerLink]="'/products/wholesale'">
            <span *ngIf="activeLang === 'en'">View All</span>
            <span *ngIf="activeLang === 'si'">සියල්ල බලන්න</span>
            <span *ngIf="activeLang === 'ta'">அனைத்தையும் காட்டு</span>
            <i aria-hidden="true" class="fa fa-long-arrow-right"></i></a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-12" *ngFor="let products of dashboardDetails?.productDTOS">
          <div class="onp-cart" [routerLink]="['/product-view-spot/wholesale']" style="cursor: pointer"
               [queryParams]="{id:products.id,typeId:1,typeName:'SPOT'}">
            <div class="onpc-img">
              <img *ngIf="products?.image !== null" alt="" src="{{this.mainUrl.MAIN_URL_FILE +
                            products?.image + '/?X-TenantID=wholesale'}}"
                   style="border-radius: 10px;width:50px; height: 50px;object-fit: cover;"/>
              <img *ngIf="products?.image === null"
                   src="assets/defaultBrokenImage.jpg" alt=""
                   style="border-radius: 10px;width:50px; height: 50px;object-fit: cover;"/>
            </div>
            <div class="onpc-detail">
              <h6>{{products?.productName}}</h6>
              <span>
                                <span>
                                    {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{products?.unitPrice}}
                                </span>
                            </span>
            </div>
            <div class="link">
              <i aria-hidden="true" class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <div class="separator" style="margin: 10px 0 25px 0;">&nbsp;</div>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <h4 *ngIf="activeLang === 'en'">Review</h4>
          <h4 *ngIf="activeLang === 'si'">සමාලෝචනය</h4>
          <h4 *ngIf="activeLang === 'ta'">விமர்சனம்</h4>
        </div>
        <div class="col-4 text-right">
          <a [routerLink]="'/my-account/reviews/wholesale'">
            <span *ngIf="activeLang === 'en'">View All</span>
            <span *ngIf="activeLang === 'si'">සියල්ල බලන්න</span>
            <span *ngIf="activeLang === 'ta'">அனைத்தையும் காட்டு</span>
            <i aria-hidden="true"
               class="fa fa-long-arrow-right"></i></a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6" *ngFor="let review of dashboardDetails?.reviewDTOS">
          <div class="o-review-cart">
            <div class="row">
              <div class="col-8">
                <dl class="orp-info">
                  <dt>
                    <img *ngIf="review?.imgUrl !== null && review?.imgUrl !== undefined" alt="" src="{{this.mainUrl.MAIN_URL_FILE +
                            review?.imgUrl + '/?X-TenantID=wholesale'}}"
                         style="width: 70px; height: 70px">
                    <img *ngIf="review?.imgUrl === null || review?.imgUrl === undefined" alt="" src="assets/avatar.jpg"
                         style="width: 70px; height: 70px">
                  </dt>
                  <dd>
                    <span>{{review?.reviewedBy}}</span>
                    <star-rating checkedcolor="#FFA84C" readonly="true" size="20px" totalstars="5"
                                 uncheckedcolor="#999999" value="{{review?.rating}}"></star-rating>
                  </dd>
                </dl>
              </div>
              <div class="col-4 text-right">
                <span class="date">{{convertDate(review?.created)}}</span>
              </div>
              <div class="col-12">
                <p>{{review?.comment}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
