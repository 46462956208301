<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.MY_BIDS_ACTION' | translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                <mat-form-field class="matsearch">
                  <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>

                <table [dataSource]="dataSource" class="table salestable" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="orderTitle">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.ORDER_TITLE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        {{row.orderTitle}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="myBidAmount">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.MY_BID' | translate}}({{'CURRENCY.CURRENCY' | translate}})
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{row.myBidAmount| number:'2.2-2'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bidPlacementDate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.DATE_BID' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{convertDate(row.bidPlacementDate)}}
                      ( {{getTime(row.bidPlacementDate)}})
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bidEndDate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.BID_END_DATE' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      {{convertDate(row.bidEndDate)}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
                      {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.STATUS' | translate}}
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <img *ngIf="row.status === 'PENDING' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-status.svg"/>
                      <img *ngIf="row.status === 'PENDING' && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-sin.svg"/>
                      <img *ngIf="row.status === 'PENDING' && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-tam.svg"/>


                      <img *ngIf="row.status === 'AWARDED' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/awarded.svg"/>
                      <img *ngIf="row.status === 'AWARDED' && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/awarded-sin.svg"/>
                      <img *ngIf="row.status === 'AWARDED' && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/awarded-tam.svg"/>


                      <img *ngIf="row.status === 'DECLINED'  && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/declined-status.svg"/>
                      <img *ngIf="row.status === 'DECLINED'  && activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/declined-sin.svg"/>
                      <img *ngIf="row.status === 'DECLINED'  && activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/declined-tam.svg"/>

                      <img *ngIf="row.status === 'PURCHASED' && activeLang === 'en'" alt="" class="status-img"
                           src="../../../../assets/images/purchased.svg"/>
                      <img *ngIf="row.status === 'PURCHASED'&& activeLang === 'si'" alt="" class="status-img"
                           src="../../../../assets/images/purchased-s.svg"/>
                      <img *ngIf="row.status === 'PURCHASED'&& activeLang === 'ta'" alt="" class="status-img"
                           src="../../../../assets/images/purchased-t.svg"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <button
                        (click)="checkout(row.sku,row.myBidAmount,row.bidPlacementDate,row.bidEndDate,row.orderTitle)"
                        *ngIf="row.status === 'AWARDED'" color="primary" mat-raised-button>
                        {{'MY_ACCOUNT.PURCHASES.VIEW_MY_BIDS.CHECKOUT' | translate}}
                      </button>
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                  </tr>
                  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button hidden data-target="#transport-options" data-toggle="modal" #transportModal></button>


<!-- Transport options -->
<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" id="transport-options" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_OPTIONS' | translate}}</h5>
        <button #transportOptionsClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="opt-row">
          <div class="transport-opt row">
            <div class="products-set pset-a col-md-4 col-xs-12">
              <div class="c-products-sum">

                <div class="dcp-item pro-items d-flex align-items-start">
                  <div class="dcp-img">
                    <img src="{{this.mainUrl.MAIN_URL_FILE + this.prdImg  + '/?X-TenantID=' + this.tenantValue}}"
                         alt=""/>
                  </div>
                  <div class="dcp-info">
                    <h6 class="p-name">{{productName}}</h6>
                    <span class="p-qty">
                      {{'CURRENCY.CURRENCY' | translate}} {{myBidValue| number:'2.2-2'}}
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <hr>
            <div class="products-set col-md-8 col-xs-12 transportmethods" id="nav-tabContent">
              <div class="insidepaddings">
                <mat-radio-group aria-label="Select an option">


                  <!-- riyaviru transport -->
                  <div class="buyertransport rb-cl-wp" data-target="#riyaviruModal" data-toggle="modal"
                       [ngClass]="{'active':selectedOption==this.sku+1}" (click)="clickRiyaviru(this.sku)">
                    <mat-radio-button [value]="100+1" class="tos-radio" (change)="checkVal($event,this.sku,1)">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'RIYAVIRU_TRANSPORT1' | translate}}</h5>
                          <p>{{'RIYAVIRU_TRANSPORT2' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <!-- buyer transport -->
                  <div class="buyertransport rb-cl-wp"
                       [ngClass]="{'active':selectedOption==this.sku+2}">
                    <mat-radio-button [value]="100+2" class="tos-radio" (change)="checkVal($event,this.sku,2)"
                                      (click)="selectedOption=this.sku+2">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_B' | translate}}</h5>
                          <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.ORDER_IS_RESPONSIBLE' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <div *ngFor="let trn of deliveryOptList;index as k">


                    <!-- Train transport -->
                    <div class="toptile">
                      <div class="t-title">
                        <div class="ifrailtransport rb-cl-wp" [ngClass]="{'active':selectedOption==this.sku+4}"
                             *ngIf="trn.deliveryOpt==='4'">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,this.sku,4)"
                                            (click)="selectedOption=this.sku+4">
                            <div class="toptile d-flex justify-content-between">
                              <div class="t-title">
                                <h5>Transport Method (Rail Freight Transport) </h5>
                                <p>Select the nearest train station to you for the pickup</p>
                              </div>
                              <div class="d-flex align-items-center rail">
                                <span class="material-icons"> train</span>
                                <p class="font-bold">Rail Freight Transport</p>
                              </div>
                            </div>
                            <div class="pickup-dropof row">
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Pickup Station</label>
                                <input name="pickup" class="form-control" style="width: 50%;" type="text" disabled
                                       value="item.transport.station">
                              </div>
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Drop Station <span
                                  class="floatright">Estimated
                                    arrival time: 7-8 hours</span></label>
                                <select (change)="selectRailway($event.target.value)" style="width: 50%;"
                                        class="form-control province" id="railway">
                                  <option [value]="none" disabled>
                                    Select a railway station
                                  </option>
                                  <option *ngFor="let station of railwayList" [value]="station.id">
                                    {{station.descriptionEng}}
                                  </option>

                                </select>
                              </div>
                            </div>

                            <p>Please note that the <strong>Arrival Times</strong> can be vary depending on the
                              weather
                              conditions and other unforeseeable factors. <br>If the delivery is considerably late, or
                              if
                              you need
                              more information, please feel free to <a href="">contact us.</a></p>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>

                    <!-- seller transport -->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='3'"
                         [ngClass]="{'active':selectedOption==this.sku+3}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,this.sku,3)"
                                            (click)="selectedOption=this.sku+3">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_DELIVER' | translate}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGE' |
                              translate}}{{'CURRENCY.CURRENCY' |
                              translate}} {{trn.deliveryCharges| number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{this.sellerName}}</h5>
                          <p>{{this.sellerLocation}}</p>
                        </div>
                      </div>
                      <i class="fa fa-truck"></i>
                      &nbsp;{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_PAID_S' | translate}}
                    </div>

                    <!-- seller transport fixed price-->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='5'"
                         [ngClass]="{'active':selectedOption==this.sku+5}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,this.sku,5)"
                                            (click)="selectedOption=this.sku+5">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_WILL_DELIVER' | translate}}</p>
                            <p>{{trn.deliveryDesc}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_FIX' | translate}}
                              {{'CURRENCY.CURRENCY' | translate}} {{trn.deliveryCharges | number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{this.sellerName}}</h5>
                          <p>{{this.sellerLocation}}</p>
                        </div>
                      </div>
                      <i class="fa fa-truck"></i>
                      &nbsp;{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_PAID_S' | translate}}
                    </div>

                  </div>
                </mat-radio-group>

              </div>

            </div>
          </div>

        </div>
        <div class="pm-pop-bottom col-12">
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.CANCEL_B' | translate}}</a>
            <a (click)="buyNow()" aria-label="Next" class="btn next-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.PROCEED_B' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Riyaviru transport modal -->
<div aria-hidden="true" data-backdrop="static" data-keyboard="false" class="modal fade npa-model" id="riyaviruModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><img src="../../../assets/images/riyaviru.png" alt="" class="riyviru">Riyaviru Transport
        </h5>
        <button #closeRiyawiruModal (click)="riyawiruModalShow()" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="tr-riyaviru">
          <div class="d-flex align-items-center justify-content-between top-bar">
            <div class="dleft d-flex align-items-center">
              <div class="dcp-img">
                <img src="{{this.mainUrl.MAIN_URL_FILE + this.prdImg  + '/?X-TenantID=' + this.tenantValue}}" alt=""/>
              </div>
              <div>
                <h5>{{productName}}</h5>
                <span class="p-qty">
                  {{'CURRENCY.CURRENCY' | translate}} {{myBidValue| number:'2.2-2'}}
                </span>
              </div>
            </div>
          </div>
          <div class="pick-option">
            <p><img src="../../../assets/images/icons/vehicle.png" alt=""> &nbsp;{{'PICK_A_VEHICLE'|translate}}</p>
            <div class="vehicle-list row">
              <div class="col-lg-2" *ngFor="let vehicle of riyawiruVehicleTypes;let i = index"
                   (click)="selectVehicleType(vehicle['typeId'],sku)"
                   [ngClass]="{'active':selectedId==vehicle['typeId']}">
                <div class="row">
                  <div class="col-12">
                    <div class="v-item">
                      <img src="{{vehicle['icons']?.icon}}" alt="">
                    </div>
                  </div>
                  <div class="col-12" style="font-size: 12px;margin-bottom: 1vh">
                     <span *ngIf="activeLang === 'en'">
                      {{vehicle['name']['nameEN']}}
                    </span>
                    <span *ngIf="activeLang === 'si'">
                      {{vehicle['name']['nameSI']}}
                     </span>
                    <span *ngIf="activeLang === 'ta'">
                       {{vehicle['name']['nameTM']}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <span style="color: red;font-size: 10px" *ngIf="isPickVehicle">Please pick a vehicle!</span>
          </div>
          <div class="other-info">
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="dleft">
                  <div class="d-flex align-items-start">
                    <span class="material-icons iconred">
                      location_on
                    </span>
                    <div class="l-info">
                      <h5>{{'Item_Pickup_Location'|translate}}:</h5>
                      <p>{{sellerLocation}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-bar">
            <div class="row align-items-center">
              <div class="col-md-8 col-xs-12">
                <div class="dleft">
                  <p>{{'My_Expectation'|translate}} ({{'CURRENCY.CURRENCY' | translate}})</p>
                  <input min="1" type="number" class="binput" [(ngModel)]="expectationPrice">
                  <span style="color: red;font-size: 10px" *ngIf="isRequiredExpectation">This field is required!</span>
                  <small>
                    {{'RIYAWIRU_BIT_REQUEST_TEXT'|translate}}
                  </small>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="dright">
                  <button (click)="sendBidRequest(sku,expectationPrice)" class="btn btn-default next"
                          aria-label="Close">
                    {{'SEND_BID_REQUEST'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
