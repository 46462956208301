<app-header></app-header>


<div class="home-slider-wrapper" id="sliderimgs">
  <div class="slider-row">
    <div class="slck-product-slider">
      <ngx-slick-carousel [config]="homeSlideConfig" class="carousel">
        <div ngxSlickItem class="slide ps-item">
          <img alt="First slide" class="d-block w-100 homeSliderImage" src="assets/images/temp/slider1.jpg">
        </div>
        <div ngxSlickItem class="slide ps-item">
          <img alt="Second slide" class="d-block w-100 homeSliderImage" src="assets/images/temp/slider2.jpg">
        </div>
        <div ngxSlickItem class="slide ps-item">
          <img alt="third slide" class="d-block w-100 homeSliderImage" src="assets/images/temp/slider3.jpg">
        </div>
        <div ngxSlickItem class="slide ps-item">
          <img alt="fourth slide" class="d-block w-100 homeSliderImage" src="assets/images/temp/slider4.jpg">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

  <div class="hs-item-slider" id="sliderheader">
    <div class="hs-content-sec browseAllBtn centercovertext">
      <div class="border-around">
        <img class="toplogo" src="../../assets/images/helaviru_logo.png" alt="" style="max-width: 150px;" />
        <div class="logo-imgs">
          <img src="../../assets/images/logos/sponsor.png" alt="">
        </div>
        <h2 class="sliderTitle" *ngIf="activeLang === 'si'">හෙළවිරු – ඩිජිටල් ආර්ථික මධ්‍යස්ථානය</h2>
        <h2 class="sliderTitle" *ngIf="activeLang === 'en'">Helaviru - Digital Economic Center</h2>
        <h2 class="sliderTitle" *ngIf="activeLang === 'ta'">ஹெலவிரு - டிஜிட்டல் பொருளாதார மையம்</h2>
        <div class="d-flex flex-column">
          <div class="f-buttns">
            <button [routerLink]="'/products/'+tenantValue"><span class="material-icons"> shopping_basket</span>
              <span>{{'buy' | translate}}</span></button>
            &nbsp;
            <button (click)="clickSellButton()" *ngIf="isSellButtonShow" [disabled]="!isSellButtonShow"><span
                class="material-icons">store</span>
              <span>{{'sell' | translate}}</span></button>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="main-container homepage-main" id="homemain">
  <div class="inner-page" id="innerpage">
    <div class="row">
      <div class="col-12 top-rows">
        <div class="container">
          <section class="daily-view-section" *ngIf="newArrival.length > 0">
            <div class="sliders-section">
              <ngx-slick-carousel [config]="dealSlideConfig" class="carousel">
                <div ngxSlickItem class="slide" *ngFor="let newArrival of newArrivalList">
                  <a [routerLink]="['/product-view-'+setType(newArrival.type.type)+'/'+tenantValue]"
                    [queryParams]="{id:newArrival.id,typeId:newArrival.type.id,typeName:newArrival.type.type}"
                    class="carditem">
                    <div class="pro-card d-flex align-items-center">
                      <div class="product-img">

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length > 0"
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.spotImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="" />
                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length === 0"
                          src="assets/defaultBrokenImage.jpg" alt="" />

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length > 0"
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.futureImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="" />

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length === 0"
                          src="assets/defaultBrokenImage.jpg" alt="" />

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length > 0"
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.forwardAcuImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="" />

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length === 0"
                          src="assets/defaultBrokenImage.jpg" alt="" />

                      </div>

                      <div class="product-info">
                        <h3 class="pro-name">{{newArrival.name}}</h3>
                        <div class="greentxt">
                          <span *ngIf="newArrival.stockAvailable !== 0 && newArrival.type.type !== 'FORWARD_ACU'">
                            <span *ngIf="newArrival.sellInSmallerQuantities" style="color: #8bc34a">
                              {{'CURRENCY.CURRENCY' | translate}}
                              {{newArrival.spotDTOList[0].unitPrice | number:'2.2-2'}}
                            </span>

                            <span *ngIf="!newArrival.sellInSmallerQuantities"
                              style="color: #8bc34a">{{'CURRENCY.CURRENCY' | translate}}
                              {{newArrival.spotDTOList[0].unitPrice | number:'2.2-2'}}
                            </span>
                          </span>
                          <span *ngIf="newArrival.stockAvailable === 0" class="text" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0);
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                            <span style="color: red">{{'PRODUCT_VIEW.SOLD_OUT' | translate}}</span>
                          </span>
                        </div>
                        <small>per {{newArrival.unit.unit}}</small>
                      </div>
                    </div>
                  </a>
                </div>
              </ngx-slick-carousel>
            </div>
          </section>
        </div>
      </div>
      <div class="col-12">
        <div>
          <section class="slider-section1">
            <div class="container">
              <!-- Product Slider 02 -->
              <app-slider1></app-slider1>
            </div>

          </section>

          <section class="categories">
            <div class="container">
              <div class="row">
                <div class="col" *ngFor="let category of allCategory">
                  <a [queryParams]="{mainCatID:category.code}" [routerLink]="['/products/'+tenantValue]">
                    <div class="c-item">
                      <img src="{{this.mainUrl.MAIN_URL_FILE + category.icon+ '/?X-TenantID=' + this.tenantValue}}"
                        alt="">
                      <p>
                        <span *ngIf="activeLang === 'en'">{{category.commonIndex.description}}</span>
                        <span *ngIf="activeLang === 'si'">{{category.commonIndex.sinhala}}</span>
                        <span *ngIf="activeLang === 'ta'">{{category.commonIndex.tamil}}</span>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div class="container">
            <section class="sponsor-section">
              <div class="bg-sp">
                <div class="sp-text">
                  <div class="inner-sp-text">
                    <div>
                      <span *ngIf="activeLang === 'en'"> <img src="../../assets/images/temp/home-sec-text-e.png"
                          alt=""></span>
                      <span *ngIf="activeLang === 'si'"> <img src="../../assets/images/temp/home-sec-text.png"
                          alt=""></span>
                      <span *ngIf="activeLang === 'ta'"> <img src="../../assets/images/temp/home-sec-text-t.png"
                          alt=""></span>
                    </div>
                    <div class="reg-btns">
                      <button class="b-btn" [routerLink]="'/sign-up/wholesale'">{{'REGISTER.REGISTER_AS_AN_INDIVIDUAL'|
                        translate}}</button>
                      &nbsp;
                      <button class="b-btn" [routerLink]="'/sign-up/wholesale'"
                        [queryParams]="{company:true}">{{'REGISTER.REGISTER_AS_A_COMPANY'| translate}}</button>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section class="slider-section2">
            <div class="container">
              <!-- Product Slider 03 -->
              <h4 class="slidetitle">{{'NEW_ARR'|translate}}</h4>
              <app-slider2></app-slider2>
            </div>

          </section>

          <section class="slider-section3">
            <div class="container">
              <!-- Product Slider 03 -->
              <h4 class="slidetitle">{{'Popular_Products'|translate}}</h4>
              <app-slider3></app-slider3>
            </div>
          </section>


          <!--          <section class="slider-section4">-->
          <!--            <div class="container">-->
          <!--              <div class="row">-->
          <!--                <div class="col-lg-12">-->
          <!--                  <h2 class="abttitle" *ngIf="activeLang === 'en'">-->
          <!--                    Value Added Services-->
          <!--                    <br/>-->
          <!--                    <span>Exclusive services for Helaviru Users</span>-->
          <!--                  </h2>-->

          <!--                  <h2 class="abttitle" *ngIf="activeLang === 'si'">-->
          <!--                    අගය එකතු කළ සේවාවන් <br/>-->
          <!--                    <span>හෙළවිරු භාවිතා කරන්නන් සඳහා වූ සුවිශේෂී සේවාවන්</span>-->
          <!--                  </h2>-->

          <!--                  <h2 class="abttitle" *ngIf="activeLang === 'ta'">-->
          <!--                    மதிப்பு கூட்டப்பட்ட சேவைகள்-->
          <!--                    <br/>-->
          <!--                    <span>ஹெலவிரு பயனர்களுக்கான பிரத்யேக சேவைகள்</span>-->
          <!--                  </h2>-->

          <!--                  <div class="slck-vas-slider">-->
          <!--                    <ngx-slick-carousel class="carousel" [config]="vasSlideConfig">-->
          <!--                      <div ngxSlickItem class="slide vas-item">-->
          <!--                        <div class="biv-card bank-loan">-->
          <!--                          <div class="vas-item-top">-->
          <!--                            <img alt="" src="../../assets/images/value_added_services/peoplesbank 1.png"/>-->
          <!--                          </div>-->
          <!--                          <div class="vas-item-bottom">-->
          <!--                            <span *ngIf="activeLang === 'en'">Bank Loan</span>-->
          <!--                            <span *ngIf="activeLang === 'si'">බැංකු ණය</span>-->
          <!--                            <span *ngIf="activeLang === 'ta'">வங்கி கடன்</span>-->
          <!--                          </div>-->
          <!--                          <div class="biv-content">-->
          <!--                            <h3 *ngIf="activeLang === 'en'">People's bank</h3>-->
          <!--                            <h3 *ngIf="activeLang === 'si'">මහජන බැංකුව</h3>-->
          <!--                            <h3 *ngIf="activeLang === 'ta'">மக்கள் வங்கி</h3>-->
          <!--                            <span class="biv-link" (click)="applyLoan()">-->
          <!--                                     <span *ngIf="activeLang === 'en'">Apply for a Loan</span>-->
          <!--                                     <span *ngIf="activeLang === 'si'">ණයක් සඳහා අයදුම් කරන්න</span>-->
          <!--                                     <span *ngIf="activeLang === 'ta'">கடனுக்கு விண்ணப்பிக்கவும்</span>-->
          <!--                              <i aria-hidden="true" class="fa fa-long-arrow-right"></i>-->
          <!--                            </span>-->
          <!--                          </div>-->
          <!--                        </div>-->
          <!--                      </div>-->

          <!--                      <div ngxSlickItem class="slide vas-item">-->
          <!--                        <div class="biv-card insurance">-->
          <!--                          <div class="vas-item-top">-->
          <!--                            <img alt="" src="../../assets/images/value_added_services/logo 1.png"/>-->
          <!--                          </div>-->
          <!--                          <div class="vas-item-bottom">-->

          <!--                            <span *ngIf="activeLang === 'en'">Insurance</span>-->
          <!--                            <span *ngIf="activeLang === 'si'">රක්ෂණය</span>-->
          <!--                            <span *ngIf="activeLang === 'ta'">காப்பீடு</span>-->
          <!--                          </div>-->
          <!--                          <div class="biv-content">-->
          <!--                            <h3 *ngIf="activeLang === 'en'">Sri lanka insurance</h3>-->
          <!--                            <h3 *ngIf="activeLang === 'si'">ශ්‍රී ලංකා රක්‍ෂණය</h3>-->
          <!--                            <h3 *ngIf="activeLang === 'ta'">இலங்கை காப்பீடு</h3>-->
          <!--                            <span class="biv-link" (click)="applyInsurance()">-->
          <!--                               <span *ngIf="activeLang === 'en'">Apply for an Insurance</span>-->
          <!--                               <span *ngIf="activeLang === 'si'">රක්ෂණයක් සඳහා අයදුම් කරන්න</span>-->
          <!--                              <span *ngIf="activeLang === 'ta'">காப்பீட்டிற்கு விண்ணப்பிக்கவும்</span>-->
          <!--                              <i aria-hidden="true" class="fa fa-long-arrow-right"></i>-->
          <!--                            </span>-->
          <!--                          </div>-->
          <!--                        </div>-->
          <!--                      </div>-->

          <!--                      <div ngxSlickItem class="slide vas-item">-->
          <!--                        <div class="biv-card voice-packages">-->
          <!--                          <div class="vas-item-top">-->
          <!--                            <img alt="" src="../../assets/images/value_added_services/e9lGX-dn_400x400 1.png"/>-->
          <!--                          </div>-->
          <!--                          <div class="vas-item-bottom">-->

          <!--                            <span *ngIf="activeLang === 'en'">Voice Packages</span>-->
          <!--                            <span *ngIf="activeLang === 'si'">හඬ පැකේජ</span>-->
          <!--                            <span *ngIf="activeLang === 'ta'">ஹඬ பேக்கேஜ</span>-->
          <!--                          </div>-->
          <!--                          <div class="biv-content">-->
          <!--                            <h3 *ngIf="activeLang === 'en'">SLT-MOBITEL</h3>-->
          <!--                            <h3 *ngIf="activeLang === 'si'">SLT- මොබිටෙල්</h3>-->
          <!--                            <h3 *ngIf="activeLang === 'ta'">SLT- மொபிடெல்</h3>-->
          <!--                            <span class="biv-link" (click)="applyVoicePackage()">-->
          <!--                                 <span *ngIf="activeLang === 'en'">Apply for a voice package</span>-->
          <!--                                 <span *ngIf="activeLang === 'si'">හඬ පැකේජයක් සඳහා අයදුම් කරන්න</span>-->
          <!--                                 <span *ngIf="activeLang === 'ta'">குரல் தொகுப்புக்கு விண்ணப்பிக்கவும்</span>-->
          <!--                                 <i aria-hidden="true" class="fa fa-long-arrow-right"></i>-->
          <!--                            </span>-->

          <!--                          </div>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </ngx-slick-carousel>-->
          <!--                  </div>-->

          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </section>-->


          <section class="testmonials">
            <div class="container">
              <div class="inner-test text-center">
                <div class="sec-title">
                  <h3 *ngIf="activeLang === 'en'">Testimonials</h3>
                  <h3 *ngIf="activeLang === 'si'">අදහස්</h3>
                  <h3 *ngIf="activeLang === 'ta'">கருத்துரைகள்</h3>
                  <p *ngIf="activeLang === 'en'">What our customers say about us</p>
                  <p *ngIf="activeLang === 'si'">අපගේ ගනුදෙනුකරුවන් අප ගැන පවසන දේ</p>
                  <p *ngIf="activeLang === 'ta'">எங்கள் வாடிக்கையாளர்கள் எங்களைப் பற்றி என்ன சொல்கிறார்கள்</p>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <div class="t-box-white">
                      <div class="t-header d-flex align-items-center">
                        <div class="imgt">
                          <img src="assets/images/testimonials/Sewwandi.jpeg" alt="">
                        </div>
                        <div class="textt">
                          <h5 *ngIf="activeLang === 'en'">Sewwandi Gunawardana</h5>
                          <h5 *ngIf="activeLang === 'si'">සෙව්වන්දි ගුණවර්ධන</h5>
                          <h5 *ngIf="activeLang === 'ta'">சேவாண்டி குணவர்தன</h5>
                          <p *ngIf="activeLang === 'en'">Gampaha</p>
                          <p *ngIf="activeLang === 'si'">ගම්පහ</p>
                          <p *ngIf="activeLang === 'ta'">கம்பாஹா</p>
                        </div>
                      </div>
                      <div class="t-message">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="tmsghead" *ngIf="activeLang === 'en'">Quality Products</p>
                          <p class="tmsghead" *ngIf="activeLang === 'si'">ගුණාත්මක නිෂ්පාදන</p>
                          <p class="tmsghead" *ngIf="activeLang === 'ta'">தரமான தயாரிப்புகள்</p>
                          <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black"
                            size="16px" readonly="true"></star-rating>
                        </div>
                        <p class="msg-full" *ngIf="activeLang === 'en'">
                          Helaviru Digital Agriculture Center provided me a very good service. The goods I ordered
                          received in very good quality as expected. The seller was responsive, and I must say the
                          Helaviru Call Center agents were supportive to make sure I received the order on time in
                          expected Quality. I will continuously purchase goods from Helaviru Digital Market.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'si'" style="font-size: 13px;">
                          හෙළවිරු ඩිජිටල් කෘෂිකාර්මික මධ්‍යස්ථානය මට ඉතා හොඳ සේවාවක් ලබා දෙන ලදී. මා ඇණවුම් කළ භාණ්ඩ
                          අපේක්ෂිත පරිදි ඉතා හොඳ තත්ත්වයේ ලැබුණි. විකුණුම්කරු ප්‍රතිචාර දැක්වූ අතර, නියමිත වේලාවට මට
                          ඇණවුම ලැබුනේද යන්න සහ ගුණාත්මක බව සහතික කර ගැනීම සඳහා හෙළවිරු මධ්‍යස්ථානයේ පාරිභෝගික සේවා අංශය
                          දිගින් දිගටම සහය වූ බව මා පැවසිය යුතුමය. එබැවින් මා දිගින් දිගටම හෙළවිරු ඩිජිටල් වෙළඳපොළෙන්
                          භාණ්ඩ මිලදී ගන්නෙමි.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                          ஹெலவிரு டிஜிட்டல் வேளாண்மை மையம் எனக்கு மிகச் சிறந்த சேவையை வழங்கியது. நான் ஆர்டர் செய்த
                          பொருட்கள் எதிர்பார்த்தபடி நல்ல தரத்தில் கிடைத்தன. விற்பனையாளர் பதிலளித்தார், நான் எதிர்பார்த்த
                          தரத்தில் சரியான நேரத்தில் ஆர்டரைப் பெற்றேன் என்பதை உறுதிப்படுத்த ஹீல்விரு கால் சென்டர்
                          முகவர்கள் ஆதரவளித்தார்கள் என்று நான் சொல்ல வேண்டும். நான் தொடர்ந்து ஹெலவீரு டிஜிட்டல்
                          சந்தையில் இருந்து பொருட்களை வாங்குவேன்.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <div class="t-box-white">
                      <div class="t-header d-flex align-items-center">
                        <div class="imgt">
                          <img src="assets/images/testimonials/dummy450x450.jpg" alt="">
                        </div>
                        <div class="textt">
                          <h5 *ngIf="activeLang === 'en'">Malith Malan Dabarera</h5>
                          <h5 *ngIf="activeLang === 'si'">මලිත් මාලන් දබරෙරා</h5>
                          <h5 *ngIf="activeLang === 'ta'">மலித் மாலன் தபரேரா</h5>
                          <p *ngIf="activeLang === 'en'">Anuradhapura</p>
                          <p *ngIf="activeLang === 'si'">අනුරාධපුර</p>
                          <p *ngIf="activeLang === 'ta'">அனுராதபுரம்</p>
                        </div>
                      </div>
                      <div class="t-message">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="tmsghead" *ngIf="activeLang === 'en'">On time Settlements for Farmers</p>
                          <p class="tmsghead" *ngIf="activeLang === 'si'">නියමිත වේලාවට ගොවීන් සඳහා පියවීම්</p>
                          <p class="tmsghead" *ngIf="activeLang === 'ta'">சரியான நேரத்தில் விவசாயிகளுக்கான தீர்வுகள்</p>
                          <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black"
                            size="16px" readonly="true"></star-rating>
                        </div>
                        <p class="msg-full" *ngIf="activeLang === 'en'">
                          This is a very good concept. Even this solution is recently introduced, all the payments and
                          settlements were done very quickly. I request the Helaviru Digital Market to implement the
                          Riyawiru Solution immediately so the Transport process can be faster. I am ready to sell all
                          most all my products in Helaviru and I confidently recommend the Agriculture market to other
                          farmers too.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'si'" style="font-size: 13px;">
                          මෙය ඉතා හොඳ සංකල්පයකි. මෙම විසඳුම මෑතකදී හඳුන්වා දී ඇතත්, සියලු ගෙවීම් සහ පියවීම් ඉතා ඉක්මණින්
                          සිදු කරන ලදී. රියාවිරු විසඳුම හැකි ඉක්මනින් ක්‍රියාත්මක කරන ලෙස මම හෙළවිරු ඩිජිටල් වෙළඳපොළෙන්
                          ඉල්ලා සිටිමි. මගේ සියලු නිෂ්පාදන හෙළවිරු හි විකිණීමට මම සුදානම් වන අතර කෘෂිකාර්මික වෙළඳපොළ
                          අනෙකුත් ගොවීන්ට ද නිර්දේශ කරමි.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                          இது ஒரு நல்ல கருத்து. இந்த தீர்வு கூட சமீபத்தில் அறிமுகப்படுத்தப்பட்டது, அனைத்து
                          கொடுப்பனவுகளும் தீர்வுகளும் மிக விரைவாக செய்யப்பட்டன. ரியாவிரு தீர்வை உடனடியாக செயல்படுத்த
                          ஹெலவீரு டிஜிட்டல் சந்தையை நான் கேட்டுக்கொள்கிறேன், எனவே போக்குவரத்து செயல்முறை வேகமாக
                          இருக்கும். எனது அனைத்து தயாரிப்புகளையும் ஹெலவிருவில் விற்க நான் தயாராக இருக்கிறேன், வேளாண்
                          சந்தையை மற்ற விவசாயிகளுக்கும் நம்பிக்கையுடன் பரிந்துரைக்கிறேன்
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <div class="t-box-white">
                      <div class="t-header d-flex align-items-center">
                        <div class="imgt">
                          <img src="assets/images/testimonials/dummy450x450.jpg" alt="">
                        </div>
                        <div class="textt">
                          <h5 *ngIf="activeLang === 'en'">K.H Sanjeewani</h5>
                          <h5 *ngIf="activeLang === 'si'">කේ.එච්. සංජීවනි</h5>
                          <h5 *ngIf="activeLang === 'ta'">கே.எச். சஞ்சீவானி</h5>
                          <p *ngIf="activeLang === 'en'">Hambantota</p>
                          <p *ngIf="activeLang === 'si'">හම්බන්තොට</p>
                          <p *ngIf="activeLang === 'ta'">ஹம்பாந்தோட்டா</p>
                        </div>
                      </div>
                      <div class="t-message">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="tmsghead" *ngIf="activeLang === 'en'">Happy to be a Seller in Helaviru!</p>
                          <p class="tmsghead" *ngIf="activeLang === 'si'">හෙළවිරු හි විකුණුම්කරුවෙකු වීම සතුටක්!</p>
                          <p class="tmsghead" *ngIf="activeLang === 'ta'">ஹெலவிருவில் விற்பனையாளராக இருப்பதில்
                            மகிழ்ச்சி!</p>
                          <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black"
                            size="16px" readonly="true"></star-rating>
                        </div>
                        <p class="msg-full" *ngIf="activeLang === 'en'">
                          My first sale from Helaviru was an order worth of 90,000LKR. This sale was done very quickly
                          with the support of the Helaviru Call center Agents’ coordination with the buyer. I joined
                          Helaviru without knowing the opportunities it provides us but now I know Helaviru can help the
                          sellers / farmers like us to improve our sales with direct buyers. I am now a happy and an
                          active seller in Helaviru Digital Market from Hambantota and ready to deliver my products all
                          over Sri Lanka from Helaviru.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'si'" style="font-size: 13px;">
                          හෙළවිරු මධ්‍යස්ථානයේ මාගේ පළමු ඇනවුම රු.90,000 වටිනාකමකින් යුක්ත විය. හෙළවිරු පාරිභෝගික සේවා
                          අංශයේ සහය ඇතිව මෙම ඇනවුම ඉතාම ඉක්මනින් මා හට සම්පුර්ණ කිරීමට හැකි විය. මා හෙළවිරු හා සම්බන්ධ
                          වුයේ මෙය මගින් අප හට ඇති අවස්ථාවන් නොදැන නමුත් හෙළවිරු මගින් සෘජු ගැනුම්කරුවන් සමඟ අපගේ
                          විකුණුම් වැඩි දියුණු කිරීමට, විකුණුම්කරුවන්ට හා ගොවියන්ට සහය වන බව මා දැන් දනිමි. හම්බන්තොට
                          සිට හෙළවිරු ඩිජිටල් වෙළඳපොලේ සතුටින් හා ක්‍රියාකාරී අලෙවිකරුවෙකු වන මම, මගේ නිෂ්පාදන ශ්‍රී
                          ලංකාව පුරා හෙළවිරු වෙතින් ලබා දීමට සූදානම්ය.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                          ஹெலவீருவிலிருந்து எனது முதல் விற்பனை 90,000LKR மதிப்புள்ள ஆர்டர். வாங்குபவருடனான ஹெலவிரு கால்
                          சென்டர் முகவர்களின் ஒருங்கிணைப்பின் ஆதரவுடன் இந்த விற்பனை மிக விரைவாக செய்யப்பட்டது. இது
                          எங்களுக்கு வழங்கும் வாய்ப்புகளை அறியாமல் நான் ஹெலவீருவில் சேர்ந்தேன், ஆனால் இப்போது ஹெலவீரு
                          எங்களைப் போன்ற விற்பனையாளர்கள் / விவசாயிகளுக்கு நேரடி வாங்குபவர்களுடன் எங்கள் விற்பனையை
                          மேம்படுத்த உதவ முடியும் என்று எனக்குத் தெரியும். நான் இப்போது ஹம்பாந்தோட்டாவிலிருந்து ஹெலவிரு
                          டிஜிட்டல் சந்தையில் மகிழ்ச்சியான மற்றும் சுறுசுறுப்பான விற்பனையாளராக உள்ளேன், எனது
                          தயாரிப்புகளை இலங்கை முழுவதும் ஹெலவீருவிலிருந்து வழங்க தயாராக இருக்கிறேன்.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <div class="t-box-white">
                      <div class="t-header d-flex align-items-center">
                        <div class="imgt">
                          <img src="assets/images/testimonials/Suneth.jpeg" alt="">
                        </div>
                        <div class="textt">
                          <h5 *ngIf="activeLang === 'en'">Suneth Bandara</h5>
                          <h5 *ngIf="activeLang === 'si'">සුනෙත් බන්ඩාර</h5>
                          <h5 *ngIf="activeLang === 'ta'">சுனேத் பண்டாரா</h5>
                          <p *ngIf="activeLang === 'en'">Kurunegala</p>
                          <p *ngIf="activeLang === 'si'">කුරුණෑගල</p>
                          <p *ngIf="activeLang === 'ta'">குருநாகல்</p>
                        </div>
                      </div>
                      <div class="t-message">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="tmsghead" *ngIf="activeLang === 'en'">A very good initiative!</p>
                          <p class="tmsghead" *ngIf="activeLang === 'si'">ඉතා හොඳ ආරම්භයක්!</p>
                          <p class="tmsghead" *ngIf="activeLang === 'ta'">ஒரு நல்ல ஆரம்பம்!</p>
                          <star-rating value="4" totalstars="5" checkedcolor="#dfaf00" uncheckedcolor="black"
                            size="16px" readonly="true"></star-rating>
                        </div>
                        <p class="msg-full" *ngIf="activeLang === 'en'">
                          I was very happy to see an Online platform for agricultural products in Sri Lanka with new
                          technologies. This is a very good initiative during this Pandemic time where farmers seem
                          helpless without sales. Also, the impact of middlemen seems very low and this will give the
                          farmers to earn their real value and the consumer to pay the actual cost of the fresh
                          products. I wish all the very best for both EPIC and SLTMobitel for a long journey by
                          developing and improving Helaviru Digital Market continuously.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'si'" style="font-size: 13px;">
                          නව තාක්‍ෂණයන්ගෙන් යුත් ශ්‍රී ලංකාවේ කෘෂිකාර්මික නිෂ්පාදන සඳහා ඔන්ලයින් වෙළදපොලක් දැකීම මට මහත්
                          සතුටක් විය. විකුණුම් නොමැතිව ගොවීන් අසරණව සිටින මෙම වසංගත කාලය තුළ මෙය ඉතා හොඳ පියවරකි. එසේම,
                          මෙහිදී අතරමැදියන්ගේ බලපෑම ඉතා අඩු බව පෙනේ. මෙය ගොවීන්ට ඔවුන්ගේ සැබෑ වටිනාකම උපයා ගැනීමටත්,
                          පාරිභෝගිකයාට නැවුම් නිෂ්පාදනවල සැබෑ පිරිවැය ගෙවීමටත් හැකි වනු ඇත. හෙළවිරු ඩිජිටල් වෙළඳපල
                          අඛණ්ඩව සංවර්ධනය කිරීම හා වැඩිදියුණු කිරීම තුළින් එපික් සහ එස්එල්ටී මොබිටෙල් යන ආයතන දෙකටම දිගු
                          ගමනක් යාමට මම සුබ පතමි.
                        </p>
                        <p class="msg-full" *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                          புதிய தொழில்நுட்பங்களுடன் இலங்கையில் விவசாய பொருட்களுக்கான ஆன்லைன் பால்ட்ஃபார்மைப் பார்த்ததில்
                          நான் மிகவும் மகிழ்ச்சியடைந்தேன். விற்பனையாளர்கள் இல்லாமல் விவசாயிகள் உதவியற்றவர்களாகத்
                          தோன்றும் இந்த தொற்றுநோய்களின் போது இது ஒரு நல்ல முயற்சி. மேலும், இடைத்தரகர்களின் தாக்கம் மிகக்
                          குறைவாகத் தெரிகிறது, இது விவசாயிகளுக்கு அவர்களின் உண்மையான மதிப்பைப் பெறவும், நுகர்வோர் புதிய
                          பொருட்களின் உண்மையான செலவைச் செலுத்தவும் உதவும். ஹெலவிரு டிஜிட்டல் சந்தையை தொடர்ச்சியாக
                          அபிவிருத்தி செய்வதன் மூலமும் மேம்படுத்துவதன் மூலமும் ஒரு நீண்ட பயணத்திற்கு ஈபிஐசி மற்றும்
                          எஸ்எல்டி இருவருக்கும் நான் வாழ்த்துகிறேன்.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </section>

          <section class="our-partners">
            <div class="container">
              <div class="inner-s">
                <div class="row">
                  <div class="col-lg-4 col-xs-12 col-sm-12">
                    <div class="leftinfo d-flex align-items-center justify-content-start">
                      <div class="sec-title">
                        <h3>{{'Our_Partners'|translate}}</h3>
                        <p>{{'Companies_we_work_with'|translate}}</p>
                        <span class="greenborder aleft"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-xs-12 col-sm-12">
                    <div class="rightinfo">
                      <div class="row">
                        <div class="col-4 cols">
                          <img src="../../assets/images/logos/pbank.png" alt="">
                        </div>
                        <div class="col-4 cols">
                          <img src="../../assets/images/logos/slic.png" alt="">
                        </div>
                        <div class="col-4 cols">
                          <img src="../../assets/images/logos/laugfs.png" alt="">
                        </div>
                        <div class="col-4 cols colsbrow">
                          <img src="../../assets/images/logos/boc.png" alt="">
                        </div>
                        <div class="col-4 cols colsbrow">
                          <img src="../../assets/images/logos/isb.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </section>

          <section class="ceo-msg">
            <div class="container">
              <div class="msg-setion">
                <div class="ceo-1 ceomsg" *ngIf="showmsg==1">
                  <h5 *ngIf="activeLang === 'en'">A message from Dr. Nayana Dehigama</h5>
                  <h5 *ngIf="activeLang === 'si'">ආචාර්ය නයන දෙහිගමගෙන් පණිවිඩයක්</h5>
                  <h5 *ngIf="activeLang === 'ta'">திரு. ரோஹன் பெர்னாண்டோவின் செய்தி</h5>
                  <h6 *ngIf="activeLang === 'en'">Executive Chairman - Epic Technology Group</h6>
                  <h6 *ngIf="activeLang === 'si'">විධායක සභාපති - එපික් ටෙක්නොලොජීස් සමුහය</h6>
                  <h6 *ngIf="activeLang === 'ta'">நிர்வாகத் தலைவர் - காவிய தொழில்நுட்பக் குழு</h6>
                  <p *ngIf="activeLang === 'en'">
                    Our innovative Helaviru Digital Platform was created with the intention of effectively eliminating
                    the
                    current burning issues faced by the Agro-producers and Farmers especially with regards to the
                    difficulties
                    in finding proper and timely markets to sell their produce at a reasonable price. It will also help
                    to
                    eliminate the unwarranted manipulations by the middlemen involved in the trading process between
                    farm-gate
                    to the plate, and increase the revenue and profitability of the Agro-producers, while reducing the
                    cost to
                    the end consumers. Our Digital Platform will no doubt significantly reduce the wastage of the scarce
                    Agro
                    and Farm produce too by facilitating the stakeholders to sell their produce directly and on-time to
                    the end-buyers conveniently.
                    In that sense, we believe that our HELAVIRU Platform will address a national need of the hour as it
                    provides an easy, fast,
                    and secure channel for the unprivileged farmers and growers to find the right market that they can
                    timely sell their produce
                    at the best possible price without any difficulties or undue influence by middlemen, while
                    significantly reducing waste.
                  </p>
                  <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                    අපගේ නවෝත්පාදක හෙළවිරු ඩිජිටල් ආර්ථික මධ්‍යස්ථානය නිර්මාණය කරන ලද්දේ කෘෂි නිෂ්පාදකයින් සහ ගොවීන්
                    මුහුණ දෙන වර්තමාන ගැටලු ඵලදායී ලෙස තුරන් කිරීමේ අරමුණින් ය.
                    විශේෂයෙන්ම ඔවුන්ගේ නිෂ්පාදන සාධාරණ මිලකට විකිණීම සඳහා නිසි සහ කාලෝචිත වෙළඳපොලවල් සොයා ගැනීමේ
                    දුෂ්කරතා වැනි ගැටලු සම්බන්ධයෙන්ය.
                    ගොවියා සහ පාරිභෝගිකයා අතර වෙළඳ ක්‍රියාවලියට සම්බන්ධ වන අතරමැදියන්ගේ අනවශ්‍ය ක්‍රියාවන් අවම කිරීම,
                    කෘෂි නිෂ්පාදකයින්ගේ ආදායම සහ ලාභදායීතාවය ඉහළ නැංවීම සහ පාරිභෝගිකයින්ගේ පිරිවැය අඩු කිරීමටද හෙලවිරු
                    ආර්ථික මධ්‍යස්ථානය උපකාරී වනු ඇත. අපගේ ඩිජිටල් වේදිකාව මඟින් කෘෂි හා ගොවිපල නිෂ්පාදන නාස්තිය සැලකිය
                    යුතු
                    ලෙස අවම කරනු ඇති අතර පාර්ශවකරුවන්ට තම නිෂ්පාදන සෘජුවම හා නියමිත වේලාවට අවසන් ගැනුම්කරුවන්ට පහසුවෙන්
                    විකිණීමට හැකි වනු ඇත. ඒ අනුව, හෙළවිරු වේදිකාව කාලින ජාතික අවශ්‍යතාවයක් සපුරාලනු ඇතැයි අපි විශ්වාස
                    කරන කරන අතරම වරප්‍රසාද
                    නොලද ගොවීන්ට සහ වගාකරුවන්ට තම නිෂ්පාදන කාලෝචිත ලෙස හොඳම මිලට අලෙවි කළ හැකි නිවැරදි වෙළඳපොලක් සොයා
                    ගැනීම පහසු කරවන,
                    වේගවත් හා ආරක්ෂිත නාලිකාවක් සපයනු ඇත.
                    නිෂ්පාදන නාස්තිය සැලකිය යුතු ලෙස අඩු කරමින් අතරමැදියන්ගේ කිසිදු දුෂ්කරතාවයකින් හෝ අනවශ්‍ය බලපෑමකින්
                    තොරව තම නිෂ්පාදන කාලෝචිත ලෙස උපරිම මිලට අලෙවි කිරීමට හැකි වනු ඇත.
                  </p>
                  <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                    வேளாண் உற்பத்தியாளர்கள் மற்றும் விவசாயிகள் எதிர்கொள்ளும் தற்போதைய எரியும் சிக்கல்களை திறம்பட
                    அகற்றும் நோக்கத்துடன் எங்கள் புதுமையான ஹெலவிரு டிஜிட்டல் இயங்குதளம் உருவாக்கப்பட்டது, குறிப்பாக
                    தங்கள் தயாரிப்புகளை நியாயமான விலையில் விற்க சரியான மற்றும் சரியான நேரத்தில் சந்தைகளைக்
                    கண்டுபிடிப்பதில் உள்ள சிரமங்கள் குறித்து. பண்ணை வாயிலுக்கு இடையேயான வர்த்தக செயல்பாட்டில்
                    ஈடுபட்டுள்ள இடைத்தரகர்களின் தேவையற்ற கையாளுதல்களை அகற்றவும், வேளாண் உற்பத்தியாளர்களின் வருவாய்
                    மற்றும் லாபத்தை அதிகரிக்கவும், இறுதி நுகர்வோருக்கு செலவைக் குறைக்கவும் இது உதவும். எங்கள் டிஜிட்டல்
                    இயங்குதளம் பற்றாக்குறை வேளாண் மற்றும் பண்ணை உற்பத்திகளின் வீணையும் கணிசமாகக் குறைக்கும் என்பதில்
                    சந்தேகமில்லை, பங்குதாரர்கள் தங்கள் தயாரிப்புகளை நேரடியாகவும் சரியான நேரத்திலும் இறுதி
                    வாங்குபவர்களுக்கு வசதியாக விற்க உதவுகிறது. அந்த வகையில், எங்கள் ஹெலவீரு இயங்குதளம் ஒரு மணி நேரத்தின்
                    தேசிய தேவையை நிவர்த்தி செய்யும் என்று நம்புகிறோம், ஏனெனில் இது தகுதியற்ற விவசாயிகளுக்கும்
                    விவசாயிகளுக்கும் சரியான சந்தையை கண்டுபிடிப்பதற்கான எளிதான, வேகமான மற்றும் பாதுகாப்பான சேனலை
                    வழங்குகிறது, ஏனெனில் அவர்கள் சரியான நேரத்தில் தங்கள் விளைபொருட்களை சிறந்த முறையில் விற்க முடியும்
                    எந்தவொரு சிரமமும் இல்லாமல் அல்லது இடைத்தரகர்களின் தேவையற்ற செல்வாக்கு இல்லாமல் சாத்தியமான விலை,
                    கழிவுகளை கணிசமாகக் குறைக்கும்.
                  </p>
                </div>
                <div class="ceo-2 ceomsg" *ngIf="showmsg==2">
                  <h5 *ngIf="activeLang === 'en'">A message from Mr. Rohan Fernando</h5>
                  <h5 *ngIf="activeLang === 'si'">රොහාන් ප්‍රනාන්දු මහතාගෙන් පණිවිඩයක්</h5>
                  <h5 *ngIf="activeLang === 'ta'">திரு. ரோஹன் பெர்னாண்டோவின் செய்தி</h5>
                  <h6 *ngIf="activeLang === 'en'">Chairman/Director - SLT Mobitel</h6>
                  <h6 *ngIf="activeLang === 'si'">සභාපති / අධ්‍යක්ෂ - එස්එල්ටී මොබිටෙල්</h6>
                  <h6 *ngIf="activeLang === 'ta'">தலைவர் / இயக்குனர் - எஸ்.எல்.டி மொபிடெல்</h6>
                  <p *ngIf="activeLang === 'en'">
                    Since time immemorial, agriculture has been the identity of the Sri Lankan people and the backbone
                    of our economy. HE President Gotabaya Rajapaksa envisions Sri Lanka’s return to a production
                    economy, and SLT-MOBITEL is proud to support the nation as the flag bearer of the Digitized Sri
                    Lankan Agri Value Chain by powering the HELAVIRU DIGITAL ECONOMIC CENTER at Helaviru.lk with our
                    partners Epic Technology Group.
                    The traditional supply chain is highly inefficient, unorganized, and has a high rate of food wastage
                    which undermines the hard work of our local farmers. Helaviru.lk will rectify these issues by
                    creating a digital marketplace, integrating all stakeholders of the agro-produce and commodities
                    supply-chain and minimising wastage, ultimately uplifting Sri Lankan agriculture.
                    As the national ICT serviceprovider, SLT-MOBITEL is proud to partner this venture, hosting the
                    platform and supporting the farming community with communication solutions and information support,
                    enabling them to access and utilise the digital marketplace.
                    We welcome all stakeholders to become partners of the HELAVIRU DIGITAL ECONOMIC CENTER and power Sri
                    Lanka’s re-emergence as the 'Granary of the East'!
                  </p>
                  <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                    ඈත අතීතයේ සිටම කෘෂිකර්මාන්තය ශ්‍රී ලාංකික ජනයාගේ අනන්‍යතාවය සහ ආර්ථිකයේ කොඳු නාරටියයි.
                    ශ්‍රී ලංකාව නිෂ්පාදන ආර්ථිකයක් කරා නැවත පැමිණීම ජනාධිපති ගෝඨාබය රාජපක්ෂ මහතාගේ අපේක්ෂාව වන අතර අපගේ
                    හවුල්කරුවන්
                    වන එපික් ලංකා ආයතනය සමග එක්ව හෙළවිරු ඩිජිටල් ආර්ථික මධ්‍යස්ථානය බල ගැන්වීමෙන්,
                    ඩිජිටල්කරනය වූ ශ්‍රී ලංකා කෘෂි වටිනාකම් දාමයේ ධජය දරන්නා ලෙස ජාතියට සහයෝගය දැක්වීමට
                    අවස්තාව ලැබීම SLT-MOBITEL අප හට ආඩම්බරයකි. සාම්ප්‍රදායික සැපයුම් දාමය ඉතා කාර්යක්ෂම සහ
                    සංවිධානාත්මක නොවන අතර ඉහළ ආහාර නාස්තියකින් යුක්ත වේ. මෙය අපගේ දේශීය ගොවීන්ගේ
                    වෙහෙස මහන්සි වී වැඩ කිරීම අඩපණ වීමට ද හේතු වේ. හෙළවිරු ඩිජිටල් ආර්ථික මධ්‍යස්ථානය විසින් ඩිජිටල්
                    වෙළඳපොළක් නිර්මාණය කිරීම, කෘෂි නිෂ්පාදන හා වෙළඳ සැපයුම් දාමයේ සියලුම පාර්ශවකරුවන් ඒකාබද්ධ කිරීම,
                    නාස්තිය අවම කිරීම
                    මගින් සහ මෙම ගැටළු නිරාකරණය වීම මගින් අවසානයේදී ශ්‍රී ලංකා කෘෂිකර්මාන්තය නංවාලීම සිදු වනු ඇත. ගොවි
                    ප්‍රජාවට සන්නිවේදන
                    විසඳුම් සහ තොරතුරු සහාය ලබා දෙමින් ඔවුන්ට ඩිජිටල් වෙළඳපොළට ප්‍රවේශ වීමට සහාය ලබා දෙමින් හෙළවිරු
                    වේදිකාවට සත්කාරය සපයමින්,
                    ජාතික තොරතුරු හා සන්නිවේදන තාක්ෂණ සේවා සපයන්නා ලෙස SLT-MOBITEL මෙම ව්‍යාපාරයට හවුල් වීමට ලැබීම ගැන
                    ආඩම්බර වෙමු.
                    ශ්‍රී ලංකාව 'නැගෙනහිර ධාන්‍යාගාරය' ලෙස නැවත බලගැන්වීමට එක්වන හෙළවිරු ඩිජිටල් ආර්ථික
                    මධ්‍යස්ථානයේ සියළුම හවුල්කරුවන් සහ පාර්ශවකරුවන් සාදරයෙන් පිළිගනිමු.
                  </p>

                  <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                    பழங்காலத்திலிருந்தே, விவசாயம் என்பது இலங்கை மக்களின் அடையாளமாகவும், நமது பொருளாதாரத்தின்
                    முதுகெலும்பாகவும் இருந்து வருகிறது. அவர் தலைவரான கோட்டபய ராஜபக்ஷ, இலங்கை உற்பத்தி பொருளாதாரத்திற்கு
                    திரும்புவதைக் கருதுகிறார், மேலும் எங்கள் கூட்டாளர்களான Epic தொழில்நுட்ப குழு.
                    பாரம்பரிய விநியோகச் சங்கிலி மிகவும் திறமையற்றது, ஒழுங்கமைக்கப்படாதது மற்றும் அதிக உணவு வீணாக உள்ளது,
                    இது நமது உள்ளூர் விவசாயிகளின் கடின உழைப்பைக் குறைமதிப்பிற்கு உட்படுத்துகிறது. டிஜிட்டல் சந்தையை
                    உருவாக்குவதன் மூலமும், வேளாண் உற்பத்திகள் மற்றும் பொருட்களின் விநியோகச் சங்கிலியின் அனைத்து
                    பங்குதாரர்களையும் ஒருங்கிணைத்து, வீணாகக் குறைப்பதன் மூலமும், இறுதியில் இலங்கை விவசாயத்தை
                    மேம்படுத்துவதன் மூலமும் ஹெலவிரு.எல்.கே இந்த சிக்கல்களைச் சரிசெய்யும்.
                    தேசிய ஐ.சி.டி சேவை வழங்குநராக, எஸ்.எல்.டி-மொபிடெல் இந்த முயற்சியில் பங்குதாரர் என்பதில் பெருமிதம்
                    கொள்கிறது, தளத்தை ஹோஸ்ட் செய்கிறது மற்றும் விவசாய சமூகத்தை தகவல்தொடர்பு தீர்வுகள் மற்றும் தகவல்
                    ஆதரவுடன் ஆதரிக்கிறது, டிஜிட்டல் சந்தையை அணுகவும் பயன்படுத்தவும் அவர்களுக்கு உதவுகிறது.
                    அனைத்து பங்குதாரர்களும் ஹெலவீரு டிஜிட்டல் பொருளாதார மையத்தின் பங்காளிகளாக மாறுவதை நாங்கள்
                    வரவேற்கிறோம், மேலும் இலங்கையின் 'கிழக்கின் களஞ்சியமாக' மீண்டும் தோன்றுவதற்கு சக்தி!
                  </p>
                </div>
              </div>
              <div class="ceo-info row">
                <div class="ceo1-info col-6 text-center faded" (click)="showmsg=1" [ngClass]="{'active':showmsg==1}">
                  <img src="../../assets/images/logos/epic-ceo.png" alt="">
                  <div class="details">
                    <h4 *ngIf="activeLang === 'en'">Dr. Nayana Dehigama</h4>
                    <h4 *ngIf="activeLang === 'ta'">டாக்டர் நயனா தேஹிகாமா</h4>
                    <h4 *ngIf="activeLang === 'si'">ආචාර්ය නයන දෙහිගම</h4>
                    <p *ngIf="activeLang === 'en'">Executive Chairman - Epic Technology Group</p>
                    <p *ngIf="activeLang === 'si'">විධායක සභාපති - එපික් ටෙක්නොලොජීස් සමුහය</p>
                    <p *ngIf="activeLang === 'ta'">நிர்வாகத் தலைவர் - காவிய தொழில்நுட்பக் குழு</p>
                    <span class="greenborder"></span>
                  </div>
                </div>
                <div class="ceo2-info col-6 text-center faded" (click)="showmsg=2" [ngClass]="{'active':showmsg==2}">
                  <img src="../../assets/images/logos/slt-ceo.png" alt="">
                  <div class="details">
                    <h4 *ngIf="activeLang === 'en'">Rohan Fernando</h4>
                    <h4 *ngIf="activeLang === 'si'">රොහාන් ප්‍රනාන්දු</h4>
                    <h4 *ngIf="activeLang === 'ta'">ரோஹன் பெர்னாண்டோ</h4>
                    <p *ngIf="activeLang === 'en'">Chairman/Director - SLT Mobitel</p>
                    <p *ngIf="activeLang === 'si'">සභාපති / අධ්‍යක්ෂ - එස්එල්ටී මොබිටෙල්</p>
                    <p *ngIf="activeLang === 'ta'">தலைவர் / இயக்குனர் - எஸ்.எல்.டி மொபிடெல்</p>
                    <span class="greenborder"></span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="home-bottom">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <h2 class="abttitle">{{'FOOTER.ABOUT_US' | translate}}<br />
                    <span>{{'What_Helaviru' | translate}}</span>
                  </h2>
                  <p *ngIf="activeLang === 'en'">
                    HELAVIRU DIGITAL ECONOMIC CENTER is a Cloud-based Digital Marketplace that facilitates trading
                    activities (buying & selling) of Agro-Produce and Commodities among diverse stakeholders on a
                    seamlessly connected supply chain. Helaviru Platform will create an ecosystem of digitally connected
                    Agro-producers (Farmers & Growers), small-scale collectors, wholesalers, retailers, large consumers
                    (supermarkets, food processing industries, hotels, etc.) and Agro-produce exporters to provide them
                    with an easy and secure channel to carry out all kinds of commercial and trading activities. In
                    addition, diverse service providers such as Transport & Delivery Service providers, Fertilizer
                    Suppliers, Plants & Seeds Suppliers, Auxiliary Products and Services Suppliers, Agro-Insurance
                    Providers, Banks and Government Institutes too will be connected to facilitate the main stakeholders
                    and their commercial transactions on the Platform. It offers immense benefits to the agro and
                    farming community in Sri Lanka enabling them to timely sell their products at reasonable prices,
                    while substantially improving bottom-line profitability of their cultivation.
                    <br>
                    Helaviru Platform operates as an independently driven private initiative to help the agrarian
                    community of Sri Lanka.
                  </p>
                  <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                    හෙළවිරු, කෘෂි බෝග සහ ගොවිපල නිෂ්පාදන ආර්ථික මධ්‍යස්ථානයක් වන අතර, එය විවිධ පාර්ශවකරුවන් අතර කෘෂි
                    නිෂ්පාදන, ගොවිපල නිෂ්පාදන සහ අනෙකුත් වෙළඳ භාණ්ඩ වල ගැණුම් සහ විකුණුම් සක්‍රීය කිරීම සඳහා නිර්මාණය
                    කරන ලද සැපයුම් දාමයකි.
                    <br>
                    කෘෂි නිෂ්පාදකයින් (ගොවීන් සහ වගාකරුවන්), කෘෂි නිෂ්පාදන එකතු කරන්නන්, තොග වෙළඳුන්, සිල්ලර වෙළඳුන්,
                    මහා පරිමාණ පාරිභෝගිකයින් (සුපිරි වෙළඳපොලවල්, ආහාර සැකසුම් කර්මාන්ත, හෝටල් ආදිය) සහ කෘෂි නිෂ්පාදන
                    අපනයනකරුවන් යන ඉහතින් සඳහන් වූ විවිධ පාර්ශවකරුවන්ගේ එදිනෙදා වෙළඳ කටයුතු වල කාර්යක්‍ෂමතාවය සහ
                    සුරක්ෂිතතාවය මෙමගින් තහවුරු කරයි. ප්‍රධාන පාර්ශවකරුවන්ට, ඔවුන්ගේ කාර්යයන් සහ වාණිජ ගණුදෙනු පහසු
                    කිරීමට උපකාරී වන අනෙකුත් සේවා ලෙස, ප්‍රවාහන හා බෙදාහැරීමේ සේවා සපයන්නන්, පොහොර සැපයුම්කරුවන්, පැලෑටි
                    සහ බීජ සැපයුම්කරුවන්, සහායක නිෂ්පාදන හා සේවා සැපයුම්කරුවන්, කෘෂි රක්‍ෂණ සේවා සපයන්නන්, බැංකු සහ
                    රාජ්‍ය ආයතන වැනි විවිධ සේවා සපයන්නන් ද මෙම ආර්ථික මධ්‍යස්ථානය මගින් සම්බන්දීකරණය වෙයි. එමඟින් ශ්‍රී
                    ලංකාවේ කෘෂි හා ගොවි ප්‍රජාවට ඉමහත් ප්‍රතිලාභ ලබා දෙන අතර ඔවුන්ගේ නිෂ්පාදන කාලානුරූපව සාධාරණ මිලකට
                    අලෙවි කිරීමට හැකි වන අතර ඔවුන්ගේ වගාවේ පහළ මට්ටමේ ලාභදායීතාවය සැලකිය යුතු ලෙස වැඩි දියුණු කරයි.
                    හෙළවිරු වේදිකාව ශ්‍රී ලංකාවේ ගොවිජන ප්‍රජාවට උපකාර කිරීම සඳහා ස්වාධීනව ක්‍රියාත්මක වන පෞද්ගලික
                    මුලපිරීමක් ලෙස ක්‍රියාත්මක වේ.
                  </p>
                  <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                    ஹெலவிரு டிஜிட்டல் எகனாமிக் சென்டர் என்பது கிளவுட் அடிப்படையிலான டிஜிட்டல் சந்தையாகும், இது வேளாண்
                    உற்பத்தி மற்றும் பொருட்களின் வர்த்தக நடவடிக்கைகளை (வாங்குதல் மற்றும் விற்பனை செய்தல்) பல்வேறு
                    பங்குதாரர்களிடையே தடையின்றி இணைக்கப்பட்ட விநியோகச் சங்கிலியில் உதவுகிறது. டிஜிட்டல் முறையில்
                    இணைக்கப்பட்ட வேளாண் உற்பத்தியாளர்கள் (விவசாயிகள் மற்றும் விவசாயிகள்), சிறிய அளவிலான சேகரிப்பாளர்கள்,
                    மொத்த விற்பனையாளர்கள், சில்லறை விற்பனையாளர்கள், பெரிய நுகர்வோர் (பல்பொருள் அங்காடிகள், உணவு
                    பதப்படுத்தும் தொழில்கள், ஹோட்டல்கள் போன்றவை) மற்றும் வேளாண் உற்பத்தி ஏற்றுமதியாளர்களின்
                    சுற்றுச்சூழல் அமைப்பை ஹெலவிரு தளம் உருவாக்கும். அனைத்து வகையான வணிக மற்றும் வர்த்தக நடவடிக்கைகளையும்
                    மேற்கொள்ள எளிதான மற்றும் பாதுகாப்பான சேனல். கூடுதலாக, போக்குவரத்து மற்றும் விநியோக சேவை
                    வழங்குநர்கள், உர சப்ளையர்கள், தாவரங்கள் மற்றும் விதை சப்ளையர்கள், துணை தயாரிப்புகள் மற்றும் சேவைகள்
                    சப்ளையர்கள், வேளாண் காப்பீட்டு வழங்குநர்கள், வங்கிகள் மற்றும் அரசு நிறுவனங்கள் போன்ற பல்வேறு சேவை
                    வழங்குநர்களும் முக்கிய பங்குதாரர்களுக்கும் அவர்களின் வணிக பரிவர்த்தனைகளுக்கும் வசதியாக
                    இணைக்கப்படுவார்கள். மேடையில். இது இலங்கையில் உள்ள வேளாண் மற்றும் விவசாய சமூகத்திற்கு மகத்தான
                    நன்மைகளை அளிக்கிறது, சரியான நேரத்தில் தங்கள் தயாரிப்புகளை சரியான விலையில் விற்க உதவுகிறது, அதே
                    நேரத்தில் அவர்களின் சாகுபடியின் கீழ்நிலை லாபத்தை கணிசமாக மேம்படுத்துகிறது.
                    <br>
                    இலங்கையின் விவசாய சமூகத்திற்கு உதவுவதற்காக சுயாதீனமாக இயக்கப்படும் தனியார் முயற்சியாக ஹெலவிரு தளம்
                    செயல்படுகிறது.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="summery-icons">
            <div>
              <div class="hmb-icon-wp">
                <div class="hmb-icon">
                  <div class="icon-inside">
                    <div>
                      <svg fill="none" height="58" viewBox="0 0 48 58" width="48" xmlns="http://www.w3.org/2000/svg">
                        <path class="iconFooter"
                          d="M24 0.583374L0.75 10.9167V26.4167C0.75 40.7542 10.67 54.1617 24 57.4167C37.33 54.1617 47.25 40.7542 47.25 26.4167V10.9167L24 0.583374ZM24 28.9742H42.0833C40.7142 39.6175 33.61 49.0984 24 52.0692V29H5.91667V14.275L24 6.24087V28.9742Z"
                          fill="#2A2F6C" />
                      </svg>
                      <span>{{'SECURE_PAYMENT' | translate}}</span>
                    </div>
                  </div>
                </div>

                <div class="hmb-icon">
                  <div class="icon-inside">
                    <div>
                      <svg fill="none" height="52" viewBox="0 0 45 52" width="45" xmlns="http://www.w3.org/2000/svg">
                        <path class="iconFooter"
                          d="M22.7083 0.166626C10.5925 0.166626 0.75 10.0091 0.75 22.125C0.75 34.2408 10.5925 44.0833 22.7083 44.0833H24V51.8333C36.555 45.7883 44.6667 33.75 44.6667 22.125C44.6667 10.0091 34.8242 0.166626 22.7083 0.166626ZM25.2917 37.625H20.125V32.4583H25.2917V37.625ZM25.2917 28.5833H20.125C20.125 20.1875 27.875 20.8333 27.875 15.6666C27.875 12.825 25.55 10.5 22.7083 10.5C19.8667 10.5 17.5417 12.825 17.5417 15.6666H12.375C12.375 9.95746 16.9992 5.33329 22.7083 5.33329C28.4175 5.33329 33.0417 9.95746 33.0417 15.6666C33.0417 22.125 25.2917 22.7708 25.2917 28.5833Z"
                          fill="#2A2F6C" />
                      </svg>
                      <span>{{'HELP' | translate}}</span>
                    </div>

                  </div>
                </div>
                <div class="hmb-icon">
                  <div class="icon-inside">
                    <div>
                      <svg fill="none" height="58" viewBox="0 0 47 58" width="47" xmlns="http://www.w3.org/2000/svg">
                        <path class="iconFooter"
                          d="M41.0833 0.583374H15.25C12.4083 0.583374 10.0833 2.90837 10.0833 5.75004V13.5H15.25V8.33337H41.0833V49.6667H15.25V44.5H10.0833V52.25C10.0833 55.0917 12.4083 57.4167 15.25 57.4167H41.0833C43.925 57.4167 46.25 55.0917 46.25 52.25V5.75004C46.25 2.90837 43.925 0.583374 41.0833 0.583374ZM10.1092 32.7975L3.52166 26.21L0.240829 29.4909L10.0833 39.3334L28.6575 20.7592L25.3767 17.4784L10.1092 32.7975Z"
                          fill="#2A2F6C" />
                      </svg>
                      <span>{{'SHOP_ON_GO' | translate}}</span>
                    </div>

                  </div>

                </div>
              </div>
              <div class="col-12">
                <div class="swichto-aboutus text-center">
                  <img src="../../assets/images/logos/sponsor.png" alt="">
                  <div class="readmorebtn">
                    <button class="btn-default" [routerLink]="['/about/'+tenantValue]">
                      {{'Read_More'|translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="app-download">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 home-slider-wrapper">
                  <div class="slck-product-slider app-slider">
                    <ngx-slick-carousel [config]="appSlideConfig" class="carousel">
                      <div ngxSlickItem class="slide app-item">
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="app-details">
                            <div class="d-flex align-items-center justify-content-center d-m-block">
                              <img src="../../assets/images/temp/helaviru-app.png" alt="" class="mobileimg">
                              <div class="download-d">
                                <img src="../../assets/images/helaviru.png" alt="" class="w100">
                                <div class="d-text">
                                  <h5>Download the Helaviru mobile app</h5>
                                  <span class="whiteborder"></span>
                                  <p>Use Helaviru mobile app to Sell from anywhere.</p>
                                </div>
                                <div class="download-btns">
                                  <a href="https://play.google.com/store/apps/details?id=com.helavirufarmermobi"
                                    target="blank"><img src="../../assets/images/temp/Playstore.png" alt=""></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ngxSlickItem class="slide app-item">
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="app-details">
                            <div class="d-flex align-items-center justify-content-center d-m-block">
                              <img src="../../assets/images/temp/riyaviru-app.png" alt="" class="mobileimg">
                              <div class="download-d">
                                <img src="../../assets/images/riyaviru.png" alt="" class="w100">
                                <div class="d-text">
                                  <h5>Download the Riyaviru mobile app</h5>
                                  <span class="whiteborder"></span>
                                  <p>Be a part of the Riyaviru fleet and drive the economy forward.</p>
                                </div>
                                <div class="download-btns">
                                  <a href="https://play.google.com/store/apps/details?id=com.riyaviru"
                                    target="blank"><img src="../../assets/images/temp/Playstore.png" alt=""></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<app-footer></app-footer>
