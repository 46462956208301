import { Component,OnInit } from '@angular/core';
import { MyAccountServiceService } from '../../my-account-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-delivery-bids',
  templateUrl: './delivery-bids.component.html',
  styleUrls: ['./delivery-bids.component.css']
})
export class DeliveryBidsComponent implements OnInit {
  tenantValue;
  selectNewProductOptions: number;

  constructor(private abc: MyAccountServiceService,private route: ActivatedRoute) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }
  ngOnInit() {
  }

}
