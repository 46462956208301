import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../../common/common.service';
import {ProductService} from '../../../../service/product.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {MainUrlService} from '../../../../service/main-url.service';
import Swal from 'sweetalert2';
import {InteractionService} from '../../../../service/interaction.service';

@Component({
  selector: 'app-buyer-bids-auctions',
  templateUrl: './buyer-bids-auctions.component.html',
  styleUrls: ['./buyer-bids-auctions.component.css']
})
export class BuyerBidsAuctionsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['orderTitle', 'username', 'bidStartAmount', 'bidPlacementDate', 'status'];
  tenantValue: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  rate: any;

  productName: any;
  unitType: any;
  qty: any;
  bidUsername: any;
  bidValue: any;
  bidDate: any;
  productImage: any;
  bidId: any;

  @ViewChild('success', {static: false}) successButton;
  @ViewChild('decline', {static: false}) rejectButton;
  @ViewChild('clickActionModal', {static: false}) clickActionModal;
  inputValue: any;
  activeLang: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              private router: Router,
              private http: HttpClient,
              private interactionService: InteractionService,
              private settingsService: SystemSettingsService,
              private route: ActivatedRoute,
              public mainUrl: MainUrlService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');

    this.interactionService.headerRefresh$.subscribe(message => {
      if (message === 'True') {
        this.activeLang = sessionStorage.getItem('LANG');
        this.loadBidProducts(this.inputValue);
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.inputValue = params['name'];
      this.commonService.processing();
      this.loadBidProducts(params['name']);
    });
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

  loadBidProducts(name) {
    this.dataSource = new MatTableDataSource();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.productService.fetchAllBidProductByUsername(whoAmIDetails['email']).subscribe(getMyBidProducts => {
        let arr = [];

        for (let y of getMyBidProducts) {
          if (y.product['name'] === name) {
            for (let item of y.bidDetails) {
              arr.push({
                'productDetails': y,
                'orderTitle': y.product['name'],
                'bidStartAmount': y.startPrice,
                'bidId': item.id,
                'username': item.userId,
                'bidValue': item.bidValue,
                'bidStatus': item.status,
                'bidPlacementDate': item.created
              });
            }
          }
        }

        this.dataSource = new MatTableDataSource(arr);
        this.dataSource.paginator = this.paginator;
        Swal.close();

      }, () => {
        Swal.close();
      });
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  bidStatus:any;

  passProductDetails(row, username, bidValue, date, id,bidStatus) {
    let arr = [];
    arr.push(row);
    for (let item of arr) {
      this.productName = item.product.name;
      this.unitType = item.product.unit.unit;
      this.qty = item.quantity;
      this.productImage = item.productImages[0].image;
    }
    this.bidUsername = username;
    this.bidValue = bidValue;
    this.bidDate = date;
    this.bidId = id;
    this.bidStatus = bidStatus;
  }

  statusChange(status: string) {
    this.loading()
    if (status === 'award') {
      this.productService.awardBid(this.bidId).subscribe(isAward => {
        if (isAward) {
          this.commonService.processing();
          this.loadBidProducts(this.inputValue);
          this.successButton.nativeElement.click();
          Swal.close();
        }
      });
    } else {
      this.productService.declineBid(this.bidId).subscribe(isDecline => {
        if (isDecline) {
          this.commonService.processing();
          this.loadBidProducts(this.inputValue);
          this.rejectButton.nativeElement.click();
          Swal.close();
        }
      });
    }
  }
}
