import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ProductsComponent} from './products/products.component';
import {CartComponent} from './loged/cart/cart.component';
import {CheckoutComponent} from './loged/checkout/checkout.component';
import {MaintenanceModeComponent} from './maintenance-mode/maintenance-mode.component';
import {ViewBidsComponent} from './view-bids/view-bids.component';
import {FaqComponent} from './faq/faq.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {ReceiptComponent} from './receipt/receipt.component';
import {AwardBidComponent} from './loged/award-bid/award-bid.component';
import {HeaderComponent} from './header/header.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {RiyaviruAppTermsComponent} from './terms-conditions/riyaviru-app-terms/riyaviru-app-terms.component';
import {AgentTermsComponent} from './terms-conditions/agent-terms/agent-terms.component';
import {OtherTermsComponent} from './terms-conditions/other-terms/other-terms.component';
import {RiyaviruTermsComponent} from './terms-conditions/riyaviru-terms/riyaviru-terms.component';
import {MyAccountComponent} from './loged/my-account/my-account.component';
import {FarmerOverviewComponent} from './loged/my-account/farmer/farmer-overview/farmer-overview.component';
import {FarmerMyProductsComponent} from './loged/my-account/farmer/farmer-my-products/farmer-my-products.component';
import {FarmerReviewsComponent} from './loged/my-account/farmer/farmer-reviews/farmer-reviews.component';
import {FarmerSalesComponent} from './loged/my-account/farmer/farmer-sales/farmer-sales.component';
import {FarmerOrdersComponent} from './loged/my-account/farmer/farmer-orders/farmer-orders.component';
import {BidsAuctionsComponent} from './loged/my-account/bids-auctions/bids-auctions.component';
import {FarmerEarningsComponent} from './loged/my-account/farmer/farmer-earnings/farmer-earnings.component';
import {MyPurchasesComponent} from './loged/my-account/my-purchases/my-purchases.component';
import {BankAccountComponent} from './loged/my-account/bank-account/bank-account.component';
import {ProductViewSpotComponent} from './product-view-spot/product-view-spot.component';
import {ProductViewBidComponent} from './product-view-bid/product-view-bid.component';
import {ProductViewFowardComponent} from './product-view-foward/product-view-foward.component';
import {BuyerBidsAuctionsComponent} from './loged/my-account/buyer/buyer-bids-auctions/buyer-bids-auctions.component';
import {DeliveryBidsComponent} from './loged/my-account/shared/delivery-bids/delivery-bids.component';
import {MyFarmersComponent} from './loged/my-account/my-farmers/my-farmers.component';
import {TermsConditionsComponent} from './terms-conditions/checkout-terms/terms-conditions.component';
import {AccountSettingsComponent} from './loged/my-account/shared/account-settings/account-settings.component';
import {SettingOverviewComponent} from './loged/my-account/shared/setting-overview/setting-overview.component';
import {AgentProductsComponent} from './loged/my-account/agent/agent-products/agent-products.component';
import {ProfileComponent} from './loged/my-account/profile/profile.component';
import {AppAuthGuard} from './auth/app-auth-guard.service';
import {MyBidsComponent} from './loged/my-account/my-bids/my-bids.component';
import {PendingMyPaymentsComponent} from './loged/my-account/pending-my-payments/pending-my-payments.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {CustomerProtectionPolicyComponent} from './customer-protection-policy/customer-protection-policy.component';
import {MyWholesalersComponent} from './loged/my-account/my-wholesalers/my-wholesalers.component';
import {DeliveryBidRequestComponent} from './loged/my-account/delivery-bid-request/delivery-bid-request.component';
import {RiyaviruCheckoutComponent} from './loged/riyaviru-checkout/riyaviru-checkout.component';
import {RiyawiruPrivacyPolicyComponent} from './riyawiru-privacy-policy/riyawiru-privacy-policy.component';
import {ValueAddedServicesComponent} from './loged/value-added-services/value-added-services.component';
import {VoicePackagesComponent} from './loged/value-added-services/voice-packages/voice-packages.component';
import {ManageLoansComponent} from './loged/value-added-services/apply-loans/manage-loans/manage-loans.component';
import {LoanFormsComponent} from './loged/value-added-services/apply-loans/loan-forms/loan-forms.component';
import {LForFarmersComponent} from './loged/value-added-services/apply-loans/loan-forms/l-for-farmers/l-for-farmers.component';
import {LForAggregatorsComponent} from './loged/value-added-services/apply-loans/loan-forms/l-for-aggregators/l-for-aggregators.component';
import {LForManufacturesComponent} from './loged/value-added-services/apply-loans/loan-forms/l-for-manufactures/l-for-manufactures.component';
import {LForSuppliersComponent} from './loged/value-added-services/apply-loans/loan-forms/l-for-suppliers/l-for-suppliers.component';
import {ManageInsuranceComponent} from './loged/value-added-services/apply-insurance/manage-insurance/manage-insurance.component';
import {InsurancePlansComponent} from './loged/value-added-services/apply-insurance/insurance-plans/insurance-plans.component';
import {VehicleInsurance} from './loged/value-added-services/apply-insurance/insurance-plans/vehicle-insurance/vehicle-insurance';
import {MyOrderRequestsComponent} from './loged/my-account/buyer/my-order-requests/my-order-requests.component';
import {EditOrderRequestsComponent} from './loged/my-account/buyer/edit-order-requests/edit-order-requests.component';
import {ProductViewOrderRequestComponent} from './product-view-order-request/product-view-order-request.component';
import {BuyerOrderRequestBidsComponent} from './loged/my-account/buyer/buyer-order-request-bids/buyer-order-request-bids.component';
import {ViewMyOrderRequestBidsComponent} from './loged/my-account/buyer/view-my-order-request-bids/view-my-order-request-bids.component';
import {PlantMachineryApplicationComponent} from './loged/value-added-services/apply-insurance/insurance-plans/plants-machinery/plant-machinery-application/plant-machinery-application.component';
import {FireApplicationComponent} from './loged/value-added-services/apply-insurance/insurance-plans/fire/fire-application/fire-application.component';
import {AccidentApplicationComponent} from './loged/value-added-services/apply-insurance/insurance-plans/personal-accident/accident-application/accident-application.component';
import {RiyawiruMobilePrivacyPolicyComponent} from './riyawiru-documents/riyawiru-mobile-privacy-policy/riyawiru-mobile-privacy-policy.component';
import {RiyawiruMobileUserAgreementComponent} from './riyawiru-documents/riyawiru-mobile-user-agreement/riyawiru-mobile-user-agreement.component';
import {RiyawiruPaymentAuthenticationComponent} from './riyawiru-documents/riyawiru-payment-authentication/riyawiru-payment-authentication.component';
import {OverviewFarmerSellingComponent} from './loged/my-account/overview/overview-farmer-selling/overview-farmer-selling.component';
import {OverviewFarmerBuyingComponent} from './loged/my-account/overview/overview-farmer-buying/overview-farmer-buying.component';
import {OverviewWholesalerSellingComponent} from './loged/my-account/overview/overview-wholesaler-selling/overview-wholesaler-selling.component';
import {OverviewWholesalerBuyingComponent} from './loged/my-account/overview/overview-wholesaler-buying/overview-wholesaler-buying.component';
import {OverviewAggregatorSellingComponent} from './loged/my-account/overview/overview-aggregator-selling/overview-aggregator-selling.component';
import {OverviewAggregatorBuyingComponent} from './loged/my-account/overview/overview-aggregator-buying/overview-aggregator-buying.component';
import {OverviewLeadSellingComponent} from './loged/my-account/overview/overview-lead-selling/overview-lead-selling.component';
import {OverviewLeadBuyingComponent} from './loged/my-account/overview/overview-lead-buying/overview-lead-buying.component';
import {OverviewRetailerExporterConsumerComponent} from './loged/my-account/overview/overview-retailer-exporter-consumer/overview-retailer-exporter-consumer.component';
import {OverviewServiceProviderSellingComponent} from './loged/my-account/overview/overview-service-provider-selling/overview-service-provider-selling.component';
import {OverviewServiceProviderBuyingComponent} from './loged/my-account/overview/overview-service-provider-buying/overview-service-provider-buying.component';
import {NotFoundVASComponent} from './loged/value-added-services/not-found-vas/not-found-vas.component';

const routes: Routes = [
  {path: '', component: HomeComponent, data: {title: 'Home Page'}},
  {path: 'home/:tenant_id', component: HomeComponent, data: {title: 'Home Page'}},
  {path: '', redirectTo: '/home/:tenant_id', pathMatch: 'full'},
  {path: 'sign-up/:tenant_id', component: SignUpComponent},
  {path: ':tenant_id', component: HomeComponent, data: {title: 'Home Page'}},
  {path: 'products/:tenant_id', component: ProductsComponent, data: {title: 'Products'}},
  {path: 'refresh_products/:tenant_id', component: ProductsComponent, data: {title: 'Products'}},
  {path: 'cart/:tenant_id', component: CartComponent, data: {title: 'Cart'}},
  {path: 'checkout/:tenant_id', component: CheckoutComponent, data: {title: 'Checkout', roles: ['USER']}},
  {path: 'bitItem/:tenant_id', redirectTo: 'product-view', data: {title: 'Bid Items', roles: ['USER']}},
  {path: 'maintenanceMode/:tenant_id', component: MaintenanceModeComponent},
  {path: 'view-bids/:tenant_id', component: ViewBidsComponent},
  {path: 'faq/:tenant_id', component: FaqComponent},
  {path: 'contact/:tenant_id', component: ContactComponent},
  {path: 'about/:tenant_id', component: AboutComponent},
  {path: 'receipt/:tenant_id', component: ReceiptComponent},
  {path: 'award-bid/:tenant_id', component: AwardBidComponent, data: {title: 'Award Bids', roles: ['USER']}},
  {path: 'redirect/:tenant_id', component: HeaderComponent},
  {path: 'forgot-password/:tenant_id', component: ForgotPasswordComponent},
  {path: 'reset-password/:tenant_id', component: ResetPasswordComponent},
  {path: 'apps-store-terms/:tenant_id', component: RiyaviruAppTermsComponent},
  {path: 'riyaviru-terms/:tenant_id', component: RiyaviruTermsComponent},
  {path: 'agent-terms/:tenant_id', component: AgentTermsComponent},
  {path: 'checkout-terms/:tenant_id', component: TermsConditionsComponent},
  {path: 'other-terms/:tenant_id', component: OtherTermsComponent},
  {path: 'privacy-policy/:tenant_id', component: PrivacyPolicyComponent},
  {path: 'riyawiru-privacy-policy/:tenant_id', component: RiyawiruPrivacyPolicyComponent},
  {path: 'customer-protection-policy/:tenant_id', component: CustomerProtectionPolicyComponent},
  {path: 'not-found-vas/:tenant_id', component: NotFoundVASComponent},

  {path: 'product-view-bid/:tenant_id', component: ProductViewBidComponent, data: {title: 'Product View'}},
  {path: 'product-view-order-request/:tenant_id', component: ProductViewOrderRequestComponent, data: {title: 'Product View'}},
  {path: 'refresh-product-view-order-request/:tenant_id', component: ProductViewOrderRequestComponent, data: {title: 'Product View'}},
  {path: 'refresh-product-view-bid/:tenant_id', component: ProductViewBidComponent, data: {title: 'Product View'}},
  {path: 'product-view-forward/:tenant_id', component: ProductViewFowardComponent, data: {title: 'Product View'}},
  {path: 'product-view-spot/:tenant_id', component: ProductViewSpotComponent, data: {title: 'Product View'}},

  {path: 'riyawiru-mobile-privacy-policy/:tenant_id', component: RiyawiruMobilePrivacyPolicyComponent},
  {path: 'riyawiru-mobile-user-agreement/:tenant_id', component: RiyawiruMobileUserAgreementComponent},
  {
    path: 'riyawiru-mobile-payment-authentication/:tenant_id', component: RiyawiruPaymentAuthenticationComponent, data: {
      title: 'Payment Authentication'
    }
  },


  {path: 'riyaviru-payment-checkout/:tenant_id', component: RiyaviruCheckoutComponent},
  {path: 'value-added-services/:tenant_id', component: ValueAddedServicesComponent},
  {path: 'value-added-services/voice-packages/:tenant_id', component: VoicePackagesComponent},
  {path: 'value-added-services/manage-loans/:tenant_id', component: ManageLoansComponent},
  {path: 'value-added-services/manage-insurance/:tenant_id', component: ManageInsuranceComponent},
  {
    path: 'value-added-services/loan-forms', component: LoanFormsComponent,
    children: [
      {path: ':tenant_id', component: LForFarmersComponent},
      {path: 'for-aggregators/:tenant_id', component: LForAggregatorsComponent},
      {path: 'for-manufactures/:tenant_id', component: LForManufacturesComponent},
      {path: 'for-suppliers/:tenant_id', component: LForSuppliersComponent},
    ]
  },
  {
    path: 'value-added-services/insurance-plans', component: InsurancePlansComponent,
    children: [
      {path: ':tenant_id', component: VehicleInsurance},
      {path: 'plants-machinery/:tenant_id', component: PlantMachineryApplicationComponent},
      {path: 'fire/:tenant_id', component: FireApplicationComponent},
      {path: 'personal-accident/:tenant_id', component: AccidentApplicationComponent}
    ]
  },
  {
    path: 'my-account', component: MyAccountComponent, data: {title: 'MyAccount'}, canActivate: [AppAuthGuard],
    children: [
      {path: ':tenant_id', component: SettingOverviewComponent, canActivate: [AppAuthGuard]},
      {path: 'overview/:tenant_id', component: FarmerOverviewComponent, canActivate: [AppAuthGuard]},
      {path: 'refresh_my_account/:tenant_id', component: FarmerOverviewComponent, canActivate: [AppAuthGuard]},
      {path: 'pending-my-payments/:tenant_id', component: PendingMyPaymentsComponent, canActivate: [AppAuthGuard]},
      {path: 'product/:tenant_id', component: FarmerMyProductsComponent, canActivate: [AppAuthGuard]},
      {path: 'delivery-bid-request/:tenant_id', component: DeliveryBidRequestComponent, canActivate: [AppAuthGuard]},
      {path: 'agent-products/:tenant_id', component: AgentProductsComponent, canActivate: [AppAuthGuard]},
      {path: 'reviews/:tenant_id', component: FarmerReviewsComponent, canActivate: [AppAuthGuard]},
      {path: 'sales/:tenant_id', component: FarmerSalesComponent, canActivate: [AppAuthGuard]},
      {path: 'orders/:tenant_id', component: FarmerOrdersComponent, canActivate: [AppAuthGuard]},
      {path: 'settings/:tenant_id', component: AccountSettingsComponent, canActivate: [AppAuthGuard]},
      {path: 'settings-overview/:tenant_id', component: SettingOverviewComponent, canActivate: [AppAuthGuard]},
      {path: 'earnings/:tenant_id', component: FarmerEarningsComponent, canActivate: [AppAuthGuard]},
      {path: 'auction-products/:tenant_id', component: BidsAuctionsComponent, canActivate: [AppAuthGuard]},
      {path: 'purchases/:tenant_id', component: MyPurchasesComponent, canActivate: [AppAuthGuard]},
      {path: 'bank-acc/:tenant_id', component: BankAccountComponent, canActivate: [AppAuthGuard]},
      {path: 'buyer-bids-auctions/:tenant_id', component: BuyerBidsAuctionsComponent, canActivate: [AppAuthGuard]},
      {path: 'buyer-order-request-bids/:tenant_id', component: BuyerOrderRequestBidsComponent, canActivate: [AppAuthGuard]},
      {path: 'view-my-bids/:tenant_id', component: MyBidsComponent, canActivate: [AppAuthGuard]},
      {path: 'view-my-order-request-bids/:tenant_id', component: ViewMyOrderRequestBidsComponent, canActivate: [AppAuthGuard]},
      {path: 'delivery-bids/:tenant_id', component: DeliveryBidsComponent, canActivate: [AppAuthGuard]},
      {path: 'farmers/:tenant_id', component: MyFarmersComponent, canActivate: [AppAuthGuard]},
      {path: 'wholesaler/:tenant_id', component: MyWholesalersComponent, canActivate: [AppAuthGuard]},
      {path: 'my-profile/:tenant_id', component: ProfileComponent, canActivate: [AppAuthGuard]},
      {path: 'my-order-requests/:tenant_id', component: MyOrderRequestsComponent, canActivate: [AppAuthGuard]},
      {path: 'refresh-my-order-requests/:tenant_id', component: MyOrderRequestsComponent, canActivate: [AppAuthGuard]},
      {path: 'view-order-requests/:tenant_id', component: EditOrderRequestsComponent, canActivate: [AppAuthGuard]},

      {path: 'overview-farmer-selling/:tenant_id', component: OverviewFarmerSellingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-farmer-buying/:tenant_id', component: OverviewFarmerBuyingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-wholesaler-selling/:tenant_id', component: OverviewWholesalerSellingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-wholesaler-buying/:tenant_id', component: OverviewWholesalerBuyingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-aggregator-selling/:tenant_id', component: OverviewAggregatorSellingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-aggregator-buying/:tenant_id', component: OverviewAggregatorBuyingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-lead-selling/:tenant_id', component: OverviewLeadSellingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-lead-buying/:tenant_id', component: OverviewLeadBuyingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-consumer-buying/:tenant_id', component: OverviewRetailerExporterConsumerComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-service-provider-selling/:tenant_id', component: OverviewServiceProviderSellingComponent, canActivate: [AppAuthGuard]},
      {path: 'overview-service-provider-buying/:tenant_id', component: OverviewServiceProviderBuyingComponent, canActivate: [AppAuthGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
