<div class="row">
    <div class="col-lg-12">
        <div class="pro-right-col blockdiv">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row pip-title">
                        <div class="col-7">
                            <h2>{{'MY_ACCOUNT.EARNING.EARNING' | translate}}</h2>
                        </div>
                        <div class="col-5 text-right">

                        </div>
                        <div class="col-12">
                            <div class="separator">&nbsp;</div>
                        </div>
                    </div>
<!--                    <div class="row">-->
<!--                        <div class="col-lg-3 col-xs-12">-->
<!--                            <div class="earnbox">-->
<!--                                <div>-->
<!--                                    <h6>{{'MY_ACCOUNT.EARNING.TOTAL_EARNING' | translate}} </h6>-->
<!--                                    <h3>{{'CURRENCY.CURRENCY' | translate}} 5,890.00</h3>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-lg-3 col-xs-12">-->
<!--                            <div class="earnbox nobgcolor">-->
<!--                                <div>-->
<!--                                    <h6>{{'MY_ACCOUNT.EARNING.PENDING_PAYMENT' | translate}}</h6>-->
<!--                                    <h3>{{'CURRENCY.CURRENCY' | translate}} 3,400.00</h3>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-6 col-xs-12 billcol">-->
<!--                            <div class="billinginfo listview">-->
<!--                                <ul>-->
<!--                                    <li><span class="boldtxt">{{'MY_ACCOUNT.EARNING.CURRENT_BILL_CYCLE' | translate}}-->
<!--                                        </span> <span>15th-->
<!--                                            {{'MY_ACCOUNT.EARNING.CYCLE' | translate}}</span></li>-->
<!--                                    <li><span class="boldtxt">{{'MY_ACCOUNT.EARNING.TOTAL_EARNING_1' | translate}}-->
<!--                                        </span> <span class="greentext">{{'CURRENCY.CURRENCY' | translate}}-->
<!--                                            5,890.00</span> <a href="" data-toggle="modal"-->
<!--                                            data-target="#viewmore-modal">{{'MY_ACCOUNT.EARNING.VIEW_MORE.VIEW_MORE' | translate}}-->
<!--                                            <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></li>-->
<!--                                    <li><span class="boldtxt">{{'MY_ACCOUNT.EARNING.BALANCED_TO_SETTLE' | translate}}-->
<!--                                        </span> <span class="greentext">{{'CURRENCY.CURRENCY' | translate}}-->
<!--                                            3,400.00</span> <a href="" data-toggle="modal"-->
<!--                                            data-target="#balance-modal">{{'MY_ACCOUNT.EARNING.VIEW_MORE.VIEW_MORE' | translate}}-->
<!--                                            <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-12">-->
<!--                            <div class="separator">&nbsp;</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="row">
<!--                        <div class="col-12">-->
<!--                            <div class="row pip-title earntable">-->
<!--                                <div class="col-5 col-xs-12">-->
<!--                                    <h2>{{'MY_ACCOUNT.EARNING.RECENT_TRANSACTION' | translate}}</h2>-->
<!--                                </div>-->
<!--                                <div class="col-7 col-xs-12 text-right">-->
<!--                                    <div class="form-inline">-->
<!--                                        <input class="form-check-input checkone" type="checkbox" id="all">-->
<!--                                        <label class="cust-lable form-check-label" for="all">-->
<!--                                            {{'MY_ACCOUNT.EARNING.ALL' | translate}}-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                    <div class="form-inline">-->
<!--                                        <input class="form-check-input checkone" type="checkbox" id="approve">-->
<!--                                        <label class="cust-lable form-check-label" for="approve">-->
<!--                                            {{'MY_ACCOUNT.EARNING.APPROVED' | translate}}-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                    <div class="form-inline">-->
<!--                                        <input class="form-check-input checkone" type="checkbox" id="pending">-->
<!--                                        <label class="cust-lable form-check-label" for="pending">-->
<!--                                            {{'MY_ACCOUNT.EARNING.PENDING' | translate}}-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                    <div class="form-inline">-->
<!--                                        <input class="form-check-input checkone" type="checkbox" id="reject">-->
<!--                                        <label class="cust-lable form-check-label" for="reject">-->
<!--                                            {{'MY_ACCOUNT.EARNING.REJECTED' | translate}}-->
<!--                                        </label>-->
<!--                                    </div>-->

<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
                        <div class="col-12">
                            <div  class="table-box" style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">

                                <mat-form-field class="matsearch">
                                    <input matInput placeholder="{{'SEARCH_BAR' | translate}}"
                                        (keyup)="applyFilter($event.target.value)">
                                </mat-form-field>

                                <table [dataSource]="dataSource" class="table" style="width:100%" mat-table matSort>

                                    <ng-container matColumnDef="transactionId">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.TRANSACTION_ID' | translate}}

                                        </th>
                                        <td mat-cell class="center-table tbl flexcol" style="padding-left: 0;"
                                            *matCellDef="let row">
                                          {{this.commonService.orderIdLengthTrim(row.transactionId,transactionTrimValue)}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="productName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.PRODUCT_NAME' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">{{row.productName}}<br />
                                        </td>
                                    </ng-container>



                                    <ng-container matColumnDef="paymentMethod">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.PAYMENT_METHOD' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">{{row.paymentMethod}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.AMOUNT' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">
                                          {{'CURRENCY.CURRENCY' | translate}} {{row.amount}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.DATE' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">
                                            {{convertDate(row.date)}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="accountNo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.ACCOUNT_NUMBER' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">
                                            {{row.accountNo}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="commissionTerm">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.BILL_CYCLE' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">
                                            {{row.commissionTerm}} cycle
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="center-table tbl td">
                                          {{'MY_ACCOUNT.EARNING.STATUS' | translate}}
                                        </th>
                                        <td mat-cell class="center-table tbl" style="padding-left: 0%;"
                                            *matCellDef="let row">
                                            <span class="orangetext">{{row.status}}</span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                </table>

                              <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- view more -->
<div class="modal fade npa-model" id="viewmore-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
        <div class="modal-content modalpadding">
            <div class="col-md-12">
                <div class="row order-review">
                    <div class="center-info col-md-12">
                        <div class="user-img">
                            <img src="../../../../../assets/images/temp/profile.png" alt="">
                            <h5>Appuhami Senanayake </h5>
                            <small>(HVF1234567)</small>
                        </div>
                        <p class="banckacc">Bank account No - 8847736213</p>
                        <div class="earnings d-flex">
                            <div class="payments total">
                                <h5>{{'MY_ACCOUNT.EARNING.TOTAL_EARNING' | translate}}</h5>
                                <h3>{{'CURRENCY.CURRENCY' | translate}} 5890.00</h3>
                            </div>
                            <div class="payments">
                                <h5>{{'MY_ACCOUNT.EARNING.PENDING_PAYMENT' | translate}}</h5>
                                <h3>{{'CURRENCY.CURRENCY' | translate}} 3400.00</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-4">
                <div class="bottom-box">
                    <p class="text-center tabletxt">{{'MY_ACCOUNT.EARNING.VIEW_MORE.EARNING_PER_WEEK' | translate}}</p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">{{'MY_ACCOUNT.EARNING.VIEW_MORE.TRANSACTION_ID' | translate}}</th>
                                <th scope="col">{{'MY_ACCOUNT.EARNING.VIEW_MORE.PRODUCT_ID' | translate}}</th>
                                <th scope="col">{{'MY_ACCOUNT.EARNING.VIEW_MORE.PRODUCT_NAME' | translate}}</th>
                                <th scope="col">{{'MY_ACCOUNT.EARNING.VIEW_MORE.BUYER' | translate}}</th>
                                <th scope="col">{{'MY_ACCOUNT.EARNING.VIEW_MORE.TOTAL_AMOUNT' | translate}}</th>
                                <th scope="col">{{'MY_ACCOUNT.EARNING.VIEW_MORE.COMPLETED_DATE' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    HVF1234567
                                </td>
                                <td>1009392</td>
                                <td>Watermelon</td>
                                <td class="bluetxt">Kasoon Sameera</td>
                                <td class="greentext">{{'CURRENCY.CURRENCY' | translate}} 3400.00</td>
                                <td>08/21/2018</td>
                            </tr>
                            <tr>
                                <td>
                                    HVF1234567
                                </td>
                                <td>1009392</td>
                                <td>Watermelon</td>
                                <td class="bluetxt">Kasoon Sameera</td>
                                <td class="greentext">{{'CURRENCY.CURRENCY' | translate}} 3400.00</td>
                                <td>08/21/2018</td>
                            </tr>
                            <tr>
                                <td>
                                    HVF1234567
                                </td>
                                <td>1009392</td>
                                <td>Watermelon</td>
                                <td class="bluetxt">Kasoon Sameera</td>
                                <td class="greentext">{{'CURRENCY.CURRENCY' | translate}} 3400.00</td>
                                <td>08/21/2018</td>
                            </tr>
                        </tbody>
                    </table>
                  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
                <div class="text-center">
                    <button class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- approve modal -->
<div class="modal fade npa-model" id="status-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header blank-header">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <div class="inside-modal">
                    <div class="topprofile">
                        <p style="font-weight: bold; font-size: 24px; color: #4F4F4F;">Transaction #1234567890
                            <span class="text-rights" style="font-size: 14px;"> Referal Earnings (40%) <br />
                                <h2 style="font-weight: bold;font-size: 24px; color: #2F80ED;">{{'CURRENCY.CURRENCY' | translate}} 850.00</h2>
                            </span></p>
                        <div class="clearfix"></div>
                        <div class="bordersides row">
                            <div class="imgs-big d-flex col-6">
                                <div><img src="../../../assets/images/temp/banana.png" /></div>
                                <div class="rightdiv">
                                    <h4>Banana</h4>
                                    <p>#567890</p>
                                </div>
                            </div>
                            <div class="col-6 listview">
                                <ul>
                                    <li><span class="boldtxt">Order Approved: </span> <span class="greentext"><i
                                                class="fa fa-check"></i> Approved</span></li>
                                    <li><span class="boldtxt">Goods Dispatched:</span> <span class="greentext"><i
                                                class="fa fa-check"></i> Dispatched</span></li>
                                    <li><span class="boldtxt">Goods Recived:</span> <span class="greentext"><i
                                                class="fa fa-check"></i> Recieved</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-profile row">
                        <div class="col-6 listview">
                            <p><strong>Order Details</strong> </p>
                            <ul>
                                <li><span class="boldtxt">Transaction ID : </span> <span> #1234567890</span></li>
                                <li><span class="boldtxt">Item Price:</span> <span>LKR 300.00</span></li>
                                <li><span class="boldtxt">Quantity:</span> <span>12</span></li>
                                <li><span class="boldtxt">Amount:</span> <span>LKR 3,000.00</span></li>
                                <li><span class="boldtxt">Buyer:</span> <span>Jeewan Sachintha</span></li>
                                <li><span class="boldtxt">Purchased Date:</span> <span>20 / 07 / 2020</span></li>
                            </ul>
                        </div>
                        <div class="col-6 listview">
                            <p><strong>Transaction Details </strong> </p>
                            <ul>
                                <li><span class="boldtxt">Billing Cycle : </span> <span> 15th Billing Cycle </span></li>
                                <li><span class="boldtxt">Bank Account No :</span> <span>23428374992</span></li>
                                <li><span class="boldtxt">Name of the Bank :</span> <span>Commercial -
                                        Batharamulla</span></li>
                                <li><span class="boldtxt">Transaction Date :</span> <span>N/A</span></li>
                                <li><span class="boldtxt">Payee:</span> <span class="bluetxt">Helaviru</span></li>
                                <li><span class="boldtxt">Settlement Status:</span> <span
                                        class="orangetext">Pending</span></li>
                                <li><span class="boldtxt">Settlement Date :</span> <span>15 / 08 / 2020</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- balance modal -->
<div class="modal fade npa-model" id="balance-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header blank-header">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <div class="inside-modal">
                    <div class="topprofile">
                        <p style="font-weight: bold; font-size: 24px; color: #4F4F4F;">Transaction #1234567890
                            <span class="text-rights" style="font-size: 14px;"> Referal Earnings (40%) <br />
                                <h2 style="font-weight: bold;font-size: 24px; color: #2F80ED;">LKR 850.00</h2>
                            </span></p>
                        <div class="clearfix"></div>
                    </div>
                    <div class="bottom-profile row">
                        <div class="col-6 listview">
                            <div class="d-flex justify-content-between align-items-center titleorder">
                                <p><strong>Orders</strong></p>
                                <p class="numberordr"><strong>4</strong></p>
                            </div>
                            <div class="order-list">
                                <div class="item-order">
                                    <div class="row whitetooltip">
                                        <div class="imgs-big d-flex col-6">
                                            <img src="../../../assets/images/temp/banana.png" />
                                            <div class="rightdiv">
                                                <h4>Banana</h4>
                                                <p>#567890</p>
                                            </div>
                                        </div>
                                        <div class="col-6 text-right">
                                            <p class="graytxt">20 / 07 / 2020</p>
                                            <p class="greentext">LKR 3,000.00</p>

                                        </div>
                                        <div class="right">
                                            <p style="padding-bottom: 10px;"><strong>Order Details</strong> </p>
                                            <ul>
                                                <li><span class="boldtxt">Transaction ID : </span> <span>
                                                        #1234567890</span></li>
                                                <li><span class="boldtxt">Item Price:</span> <span>LKR 300.00</span>
                                                </li>
                                                <li><span class="boldtxt">Quantity:</span> <span>12</span></li>
                                                <li><span class="boldtxt">Amount:</span> <span>LKR 3,000.00</span></li>
                                                <li><span class="boldtxt">Buyer:</span> <span>Jeewan Sachintha</span>
                                                </li>
                                                <li><span class="boldtxt">Payment Method:</span> <span>Bank Transfer</span>
                                                </li>
                                                <li><span class="boldtxt">Purchased Date:</span> <span>20 / 07 /
                                                        2020</span></li>
                                            </ul>
                                            <i></i>
                                        </div>
                                    </div>
                                    <div class="row whitetooltip">
                                        <div class="imgs-big d-flex col-6">
                                            <img src="../../../assets/images/temp/watermellon.png" />
                                            <div class="rightdiv">
                                                <h4>Watermelon</h4>
                                                <p>#557890</p>
                                            </div>
                                        </div>
                                        <div class="col-6 text-right">
                                            <p class="graytxt">20 / 07 / 2020</p>
                                            <p class="greentext">LKR 3,000.00</p>
                                        </div>
                                        <div class="right">
                                            <p style="padding-bottom: 10px;"><strong>Order Details</strong> </p>
                                            <ul>
                                                <li><span class="boldtxt">Transaction ID : </span> <span>
                                                        #1234567890</span></li>
                                                <li><span class="boldtxt">Item Price:</span> <span>LKR 300.00</span>
                                                </li>
                                                <li><span class="boldtxt">Quantity:</span> <span>12</span></li>
                                                <li><span class="boldtxt">Amount:</span> <span>LKR 3,000.00</span></li>
                                                <li><span class="boldtxt">Buyer:</span> <span>Jeewan Sachintha</span>
                                                </li>
                                                <li><span class="boldtxt">Payment Method:</span> <span>Bank Transfer</span>
                                                </li>
                                                <li><span class="boldtxt">Purchased Date:</span> <span>20 / 07 /
                                                        2020</span></li>
                                            </ul>
                                            <i></i>
                                        </div>
                                    </div>
                                    <div class="row whitetooltip">
                                        <div class="imgs-big d-flex col-6">
                                            <img src="../../../assets/images/temp/banana.png" />
                                            <div class="rightdiv">
                                                <h4>Avocado</h4>
                                                <p>#567890</p>
                                            </div>
                                        </div>
                                        <div class="col-6 text-right">
                                            <p class="graytxt">20 / 07 / 2020</p>
                                            <p class="greentext">LKR 3,000.00</p>
                                        </div>
                                        <div class="right">
                                            <p style="padding-bottom: 10px;"><strong>Order Details</strong> </p>
                                            <ul>
                                                <li><span class="boldtxt">Transaction ID : </span> <span>
                                                        #1234567890</span></li>
                                                <li><span class="boldtxt">Item Price:</span> <span>LKR 300.00</span>
                                                </li>
                                                <li><span class="boldtxt">Quantity:</span> <span>12</span></li>
                                                <li><span class="boldtxt">Amount:</span> <span>LKR 3,000.00</span></li>
                                                <li><span class="boldtxt">Buyer:</span> <span>Jeewan Sachintha</span>
                                                </li>
                                                <li><span class="boldtxt">Payment Method:</span> <span>Bank Transfer</span>
                                                </li>
                                                <li><span class="boldtxt">Purchased Date:</span> <span>20 / 07 /
                                                        2020</span></li>
                                            </ul>
                                            <i></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 listview">
                            <p><strong>Transaction Details </strong> </p>
                            <ul>
                                <li><span class="boldtxt">Billing Cycle : </span> <span> 15th Billing Cycle </span></li>
                                <li><span class="boldtxt">Bank Account No :</span> <span>23428374992</span></li>
                                <li><span class="boldtxt">Name of the Bank :</span> <span>Commercial -
                                        Batharamulla</span></li>
                                <li><span class="boldtxt">Transaction Date :</span> <span>N/A</span></li>
                                <li><span class="boldtxt">Payee:</span> <span class="bluetxt">Helaviru</span></li>
                                <li><span class="boldtxt">Settlement Status:</span> <span
                                        class="orangetext">Pending</span></li>
                                <li><span class="boldtxt">Settlement Date :</span> <span>15 / 08 / 2020</span></li>
                            </ul>
                            <div class="bordertop">
                                <dl class="cb-sum total">
                                    <dt>Total Amount :</dt>
                                    <dd><span class="greentext">+ 15,000.00</span></dd>
                                </dl>
                                <dl class="cb-sum total">
                                    <dt>Helaviru (25%) :</dt>
                                    <dd><span class="redlabel">- 1,850.00</span></dd>
                                </dl>
                                <dl class="cb-sum total">
                                    <dt>Helaviru Lead (40%) :</dt>
                                    <dd><span class="redlabel">- 850.00</span></dd>
                                </dl>
                                <dl class="cb-sum total-bottom">
                                    <dt>Total :</dt>
                                    <dd><span>13,150.00</span></dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
