<p>
  <strong>HELAVIRU TERMS OF SERVICE</strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong>
    TERMS OF SERVICE FOR BUYERS AND SELLERS OF OTHER PRODUCTS AND SERVICES
  </strong>
</p>
<p>
  Welcome to Helaviru! Thank you for using our online platform (“Helaviru
  Platform”). The Helaviru Platform is provided by <strong>Epic Green Eco Systems (Private) Limited</strong>, a company
  incorporated in the Democratic Socialist Republic of Sri Lanka under the
  Companies Act No. 07 of 2007 and having its registered office at Epic
  Techno-Village, 158/1/A, Kaduwela Road, Thalangama, Battaramulla, 10120,
  Sri Lanka (hereinafter referred to as “EPIC”)
</p>
<p>
  By accessing and using this Helaviru Platform, you accept and agree to be
  bound by the terms and provisions of this agreement, provided by EPIC.
</p>
<p>
  Please read carefully the present Terms of Service:
</p>
<p>
  EPIC (“We”, “Our”, “Us”) reserves the rights to make any alterations to the
  present Terms of Service (the “Terms of Service”, “Agreement”) at any time
  by way of posting an amended version on our Platform. If the User (you)
  does not agree with the conditions of the present Terms of Service, such
  User is obliged to discontinue using this Platform.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Facilitation of Services</strong>
  </li>
</ul>
<p>
  EPIC provides individual and business users with access to the online
  Helaviru Platform developed by it for the purchasing and selling of
  <strong>
    other products such as seeds, fertilizer, agricultural tools and
    services such as insurance and consultancy services
  </strong>
  in addition to agricultural products and commodities <strong>and providing logistics</strong> and agricultural
  products
  transportation services, in a wide variety of formats, prices and
  locations.
</p>
<p>
  The User understands and agrees that EPIC is not a party to the
  transactions made between the Users. All deals shall be made directly
  between the Users. EPIC shall not carry responsibility for any disputes
  between Users.
</p>
<p>
  Subject to acceptance by the User of these Terms and Conditions, EPIC
  offers the User a limited, non-exclusive, non-sublicensable, revocable and
  non-transferable license to: (a) commercial use; (b) access to the platform
  on his/her personal equipment, solely for the purpose of using the platform
  services; and (c) access to and use any content, information and related
  materials that may be made available through the services of the platform.
  Any rights not expressly granted in these terms are reserved to EPIC.
</p>
<p>
  All rights on the Helaviru Platform are and remain the property of EPIC or
  its licensors. Neither these Terms and Conditions, nor the use of the
  services of the platform, gives the User any rights: (a) on or related to
  the services, except for the limited license granted above; or (b) to use
  or, in any way, to refer to the company names of EPIC, its affiliates or,
  where applicable, its licensors, its logos, product and service names,
  trademarks or other distinctive elements of EPIC ownership.
</p>
<p>
  The User is not authorized: (a) to remove any indication of intellectual
  property, including, without limitation, any trademark or other notices
  related to the intellectual property of any space of the services of the
  platform; b) to reproduce, modify, perform derivative works, destroy,
  license, rent, sell, resell, transfer, display or publicly perform,
  transmit, reproduce, broadcast, or otherwise exploit the services of the
  platform, except to the extent expressly permitted by EPIC; (c) to
  decompile, reverse engineer or disassemble the platform, except to the
  extent permitted by applicable law; (d) to create links or frame any part
  of the platform; (e) to create or launch any programs or scripts to
  capture, index, collect or otherwise extract data from any part of the
  platform, or unduly overloading and / or preventing the operation and /or
  functionality of the platform; or (f) to attempt to get unauthorized access
  to the platform or impair any aspect of platform or its systems or
  networks.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Registration of User Account</strong>
  </li>
</ul>
<p>
  The Helaviru Platform offers to create a free User Account by way of
  requesting personal information about the User. The User should be above 18
  years of age and shall proceed with the registration process on the
  Platform. Upon registering, the User will obtain access to their User
  account.
</p>
<p>
  The User shall independently choose their password upon registering on the
  Platform. The password may be changed by the User at any time after
  registration. The User is obligated and shall not transfer, sell or
  disclose the password to any third parties. At the same time the User shall
  independently determine the procedure for the safety of their password and
  execute all measures to prevent the compromise of their password. In the
  case of any loss of the password, as well as in the case of any illegal
  obtainment of the password by any third parties to the User Account, the
  User is obligated to urgently inform EPIC Client Support; otherwise all
  actions executed from the User Account will be considered as executed by
  the User.
</p>
<p>
  Using more than one User Account by one User will be considered as breach
  of the Terms of Service.
</p>
<p>
  The User is obligated to provide true, accurate, current and complete
  information during the process of registration of their User Account. If
  the User provides any information that is untrue or inaccurate, or if we
  have reasonable grounds to suspect that such information is untrue, we have
  the right to immediately terminate the account of the User.
</p>
<ul>
  <li>
    <strong>Method of Operation</strong>
  </li>
</ul>
<p>
  The platform allows: (a) the placement of information regarding the sale;
  (b) to make orders for purchase to the Sellers; and (c) intermediation by
  EPIC of any payments to be made by the Buyer to the Seller against the
  payment of a commission to EPIC.
</p>
<p>
  Through the platform, the Seller may (i) place any information on the <strong>selling of products and provision of
  services</strong>, and / or
  (ii) release on the platform their intention to <strong>sell products and provide services</strong>.
</p>
<p>
  In turn, the Buyer may: (i) launch in the platform his/her intention to
  request the purchase of <strong>products and services</strong>, and / or
  (ii) request the purchase of <strong>products and services</strong> from
  the Sellers registered therein. To this end, the Buyer shall indicate in
  the platform any data requested, namely the <strong>product or service</strong> that he/she intends to purchase, the
  price, location and intended date for delivery.
</p>
<p>
  The intention of the Seller or Buyer to request, respectively, the sale or
  purchase of <strong>products and services</strong> shall be valid for the
  period indicated upon the launch of intention to sell / purchase or until
  such time that the sale takes place or the Seller or Buyer withdraws their
  intent to sell or request.
</p>
<p>
  In the case referred to in the preceding paragraph, the Seller and the
  Buyer interested in the sale or purchase shall respond to the request of
  the Seller or Buyer, and the provisions of paragraphs below, shall apply.
</p>
<p>
  In the event that the Buyer places the request, the Seller shall confirm
  through the platform its intention to sell, within a reasonable period, the
  acceptance of the order and inform the Buyer of the estimated time for <strong>delivery of the goods or supply of
  services</strong>.
</p>
<p>
  Upon receipt of the confirmation and the estimated time for <strong>delivery of the goods or supply of
  services</strong>, the Buyer
  decides whether to accept the order under the proposed conditions; in which
  case the Buyer shall select the option to proceed with the payment in the
  platform.
</p>
<p>
  In the event that the Seller places the request to sell, the Buyer shall
  confirm through the platform its intention to buy, within a reasonable
  period, the acceptance of the sales request and inform the Seller of the
  estimated time for <strong>collection of goods or supply of services</strong>.
</p>
<p>
  Upon receipt of the confirmation and the estimated time for <strong>collection of the goods or supply of
  services</strong>, the Seller
  decides whether to accept the order under the proposed conditions; in which
  case the Seller shall request the Buyer to select the option to proceed
  with the payment in the Helaviru Platform.
</p>
<p>
  The Seller and Buyer shall receive the information of the offer and
  acceptance of the order of the <strong>goods or services</strong> in the
  conditions presented, thus initiating the conclusion of the process.
</p>
<p>
  <strong>3.1 Provision of Services</strong>
</p>
<p>
  If a Buyer and Service Provider decide to enter into a Service Contract,
  the Service Contract is a contractual relationship directly between them.
  They have complete discretion both with regard to whether to enter into a
  Service Contract with each other and with regard to the terms of any
  Service Contract. You acknowledge, agree, and understand that EPIC is not a
  party to any Service Contract, that the formation of a Service Contract
  between Users will not, under any circumstance, create an employment or
  other service relationship between EPIC and any User or a partnership or
  joint venture between EPIC and any User.
</p>
<p>
  With respect to any Service Contract, Buyers and Service Providers may
  enter into any written agreements that they deem appropriate, provided that
  any such agreements do not conflict with, narrow or expand EPIC’s rights
  and obligations under the Terms of Service, including this Agreement.
</p>
<p>
  Users are responsible for complying with any requirements, including
  applicable laws, rules, and regulations.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Closing the User Account</strong>
  </li>
</ul>
<p>
  If the User closes the User Account, we will cancel any scheduled or
  incomplete transactions.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Responsibility</strong>
  </li>
</ul>
<p>
  The User agrees that all data, information and other materials whether
  publicly posted or privately transmitted, are the sole liability of the
  individual from whom such information originated. The User understands that
  EPIC shall not carry any responsibility for all the information that the
  Users upload, post, email, transmit or otherwise make available via the
  Platform.
</p>
<p>
  The User agrees to upload, post, transmit or otherwise make available only
  data that the User has a right to make available in accordance with the
  law.
</p>
<p>
  The User agrees not to collect personal data in any prohibited way.
</p>
<p>
  In case of a breach of the Terms of Service, EPIC has the right to block or
  delete the User Account and to forbid the User’s access to the Platform.
</p>
<p>
  The User shall use the Helaviru Platform at his/her own risk. EPIC does not
  guarantee the achievement of any results by using the present Platform.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Indemnity</strong>
  </li>
</ul>
<p>
  The User covenants and undertakes with EPIC to indemnify EPIC for, and hold
  EPIC harmless against, any and all actions, claims and proceedings made at
  any time against EPIC or any of its Affiliates and against any and all
  costs, expenses, losses, damages, liabilities, fines and penalties, and
  reasonable legal and professional fees of consultants involved, and
  including any direct or indirect loss of profit, any indirect, incidental,
  or consequential loss or damage, or any unrealized capital gains, lost
  business opportunities, any loss of business, goodwill, opportunity or
  profit and the like, whether actual or perceivable, suffered or incurred by
  the Platform or EPIC arising out of or in connection with any breach by any
  User of any of the User Warranties set out in the present Terms of Service
  and, without restricting the rights of EPIC or its ability to claim damages
  for any such breach by any User of any of such User Warranties, agrees to
  pay EPIC an amount equal to the amount which is necessary to restore EPIC
  and its Affiliates to the financial position which would have been present
  had there been no such breach.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Warranties</strong>
  </li>
</ul>
<p>
  The User warrants to EPIC that:
</p>
<ul>
  <ul>
    <li>
      The User is duly organised, validly existing and in good standing
      in accordance with the laws of Sri Lanka having all requisite
      corporate or otherwise power and authority to carry on conducting
      their business as currently being conducted.
    </li>
    <li>
      Use of the present Platform will not:
    </li>
  </ul>
</ul>
<ul>
  <ul>
    <li>
      result in a breach of any provision of the constitutional documents
      of the User;
    </li>
    <li>
      require the consent of any Person under any agreement or
      arrangement to which the User is a party, save for corporate
      consents or other action on the part of itself and, if relevant,
      its equity holders; and
    </li>
    <li>
      result in a breach of, or constitute a default under, any
      instrument to which the User is a party or by which the User is
      bound.
    </li>
  </ul>
</ul>
<ul>
  <li>
    Charges and Payments
  </li>
</ul>
<p>
  EPIC shall be entitled to a certain percentage of the revenue generated
  through the sales via the Helaviru Platform by the User, as may be decided
  by EPIC at its discretion, which percentage shall be communicated to you
  from time to time. EPIC shall deduct the said percentage of revenue from
  each transaction and remit the balance to the User within two (2) days of
  each transaction.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Governing Law</strong>
  </li>
</ul>
<p>
  This Agreement and any non-contractual obligations arising out of or in
  connection with it shall be governed by and construed in accordance with
  the Sri Lankan law.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Dispute Resolution</strong>
  </li>
</ul>
<p>
  If any dispute arises under or in relation to this Terms of Service,
  including any question as to its existence, validity or termination, such
  dispute shall be referred to and finally resolved by arbitration in
  accordance with the Rules (the “Rules”) of Arbitration of the Sri Lankan
  National Arbitration Centre that are applicable at the time of reference to
  the arbitration. The number of arbitrators shall be one (1). The seat, or
  legal place, of arbitration shall be Colombo. The language to be used in
  the arbitral proceedings shall be English.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Intellectual Property and Restrictions</strong>
  </li>
</ul>
<p>
  The Platform consists of intellectual property, belonging to the EPIC and
  its affiliates.
</p>
<p>
  By using the Platform, the User agrees that all information contained in
  the Platform is protected by copyright, trademark rights and other rights
  for intellectual property and all indicated rights are real and protected
  in all forms, on all carriers and regarding all technologies existing in
  the present time or developed further in the future. Any rights for content
  of the Platform shall not be transferred to the User as a result of using
  the Platform.
</p>
<p>
  The User agrees that any information, including but not limited to
  discoveries, inventions, copyright, design rights, patents, innovations,
  suggestions, know-how, ideas and reports published on the Platform is
  treated by the User as the sole property of EPIC.
</p>
<ul>
  <li>
    <strong></strong>
    <strong>Confidentiality, Data Protection</strong>
  </li>
</ul>
<p>
  In the course of using the Platform, it is anticipated that the User will
  learn information that EPIC regards as confidential or proprietary,
  (“Confidential Information”). The User shall keep all Confidential
  Information as confidential and such confidentiality obligations apply
  without limitation to written documentation, oral disclosures, disclosures
  made by visual observation and disclosures in electronic form.
</p>
<p>
  Confidential Information shall not include any information that:
</p>
<ul>
  <ul>
    <li>
      is already known to the User at the time of disclosure; or
    </li>
    <li>
      is generally available to the public or becomes publicly known
      through no wrongful act of the User; or
    </li>
    <li>
      is received by the User from a third-party who had a legal right to
      provide it; or
    </li>
    <li>
      if the User is required to disclose Confidential Information by
      order of a court of law, administrative agency, or other
      governmental body, the user agrees to provide a reasonable advance
      written notice to EPIC in order to allow EPIC the opportunity to
      seek a protective order or otherwise limit such disclosure, and
      then the User shall cooperate with EPIC to further limit any such
      disclosure or seek a protective order.
    </li>
  </ul>
</ul>
<p>
  <strong>By clicking “I accept”, </strong>
  <strong>
    you confirm that you have read and clearly understood the content of
    these Terms of Service and will use the said Helaviru Platform on the
    terms and conditions set forth herein and will abide by the same at all
    times.
  </strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  HELAVIRU LEAD AGREEMENT
</p>
<p>
  This agreement (hereinafter called the “Agreement”) is made by and between <strong>YOU and Epic Green Eco Systems
  (Private) Limited</strong>, a
  company incorporated in the Democratic Socialist Republic of Sri Lanka
  under the Companies Act No. 07 of 2007 and having its registered office at
  Epic Techno-Village, 158/1/A, Kaduwela Road, Thalangama, Battaramulla,
  10120, Sri Lanka (hereinafter referred to as “EPIC”)
</p>
<p>
  <strong>WHEREAS</strong>
  EPIC is a specialist in technology solutions and has developed the Helaviru
  e-commerce platform and You acknowledge the same. You are desirous of being
  appointed as a Helaviru Lead on a voluntary basis to promote the use of the
  Helaviru Platform by the cultivators, wholesalers, retailers, transport
  providers, etc. in your area.
</p>
<ul>
  <li>
    <strong>Services</strong>
  </li>
</ul>
<p>
  You are hereby joining as a “Lead” of the Helaviru Platform (Helaviru Lead)
  purely on a voluntary basis and shall promote the use of the Helaviru
  Platform by the cultivators, wholesalers, retailers, transport providers,
  etc. in your area and generate sales through the said Platform.
</p>
<p>
  In addition, EPIC grants you a license that is non-exclusive and
  non-transferable, to use the Helaviru Platform for the purpose of
  connecting with users of the Helaviru Platform (hereinafter referred to as
  “Users”) during the term of this Agreement.
</p>
<p>
  EPIC has the right to update and/or modify the Helaviru Platform from time
  to time at its discretion.
</p>
<p>
  You shall not send spam or unsolicited messages to the Users or
  inconvenience them in any manner.
</p>
<p>
  Continuation of your membership as a Helaviru Lead will solely depend on
  your satisfactory performance and adherence to the terms and conditions as
  set out by EPIC, which include achieving the applicable revenue targets.
</p>
<p>
  <strong>1.1 Your Obligations</strong>
</p>
<p>
  You shall onboard a minimum of 50 farmers in your area to the Helaviru
  Platform, generate business worth the minimum Rupee value as may be
  stipulated by EPIC from time to time, visit each such farmer for a minimum
  of two times each month and provide a report thereon to EPIC on a monthly
  basis, not engage in any illegal activities or any activity that may harm
  the reputation of EPIC and/or the Helaviru Platform, not make any
  unilateral changes to the services which are agreed upon without written
  consent of EPIC.
</p>
<p>
  You shall be fully responsible for all services and shall ensure
  performance of the services in an appropriate manner and in accordance with
  EPIC’s requirement, ensure that the services shall be rendered with
  adequate skill, care and diligence and in accordance with the best industry
  practices and with best professional efforts and you shall attend any
  training and familiarization programs conducted or organized by EPIC with
  regard to the Helaviru Platform and the services expected from you.
</p>
<p>
  You shall ensure observance and conformation to all applicable laws,
  registrations and licenses and shall ensure that you shall not act, and
  shall refrain from acting, in any manner that could materially harm or
  tarnish the reputation or the goodwill of EPIC and/or the Helaviru Platform <strong>.</strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  2. No assignment of Intellectual Property Rights
</p>
<p>
  Nothing in this Agreement shall operate to assign or transfer any
  Intellectual Property Rights from EPIC to you, or from you to EPIC. " <strong>Intellectual Property Rights</strong>"
  means all intellectual
  property rights wherever in the world, whether registrable or
  unregistrable, registered or unregistered, including any application or
  right of application for such rights (and these "intellectual property
  rights" include copyright and related rights, database rights, confidential
  information, trade secrets, know-how, business names, trade names, trade
  marks, service marks, passing off rights, unfair competition rights,
  patents, petty patents, utility models, semi-conductor topography rights
  and rights in designs);
</p>
<p>
  3. Payments
</p>
<p>
  <strong>
    You will be paid a commission, as may be decided by EPIC at its sole
    discretion, for the voluntary services rendered in promoting the
    Helaviru Platform and assisting the stakeholders of Helaviru Platform
    to carry out transactions. Provided that the said commission shall not
    be construed as a right accrued to you.
  </strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong>
    The quantum and/or percentage of commission payable, which will be
    decided at the discretion of EPIC, will be informed to you by EPIC,
    from time to time.
  </strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong>
    The commission applicable will be paid to you by EPIC on a monthly
    basis.
  </strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  4 Confidentiality Obligations
</p>
<p>
  4.1 EPIC may disclose the Confidential Information to EPIC's officers,
  employees, professional advisers, insurers, agents and subcontractors who
  have a need to access Confidential Information for the performance of their
  work with respect to this Agreement and who are bound by a written
  agreement or professional obligation to protect the confidentiality of the
  Confidential Information. "<strong>Confidential Information</strong>" means
  any information disclosed by or on behalf of either party during the term
  whether disclosed in writing, orally or otherwise, that at the time of
  disclosure:
</p>
<p>
  (i) was marked or described as "confidential"; or
</p>
<p>
  (ii) should have been reasonably understood to be confidential.
</p>
<p>
  4.2 This Clause imposes no obligations with respect to Confidential
  Information that:
</p>
<p>
  (a) is known before disclosure under this Agreement and is not subject to
  any other obligation of confidentiality;
</p>
<p>
  (b) is or becomes publicly known through no act or default of either party;
  or
</p>
<p>
  (c) is obtained from a third party in circumstances where such party has no
  reason to believe that there has been a breach of an obligation of
  confidentiality.
</p>
<p>
  4.3 The restrictions in this Clause do not apply to the extent that any
  Confidential Information is required to be disclosed by any law or
  regulation, by any judicial or governmental order or request.
</p>
<p>
  4.4 The provisions of this Clause shall continue in force indefinitely
  following the termination of this Agreement.
</p>
<p>
  5. Limitations and Exclusions of Liability
</p>
<p>
  5.1 Nothing in this Agreement will:
</p>
<p>
  (a) limit or exclude your liability for death or personal injury resulting
  from negligence;
</p>
<p>
  (b) limit or exclude your liability for fraud or fraudulent
  misrepresentation;
</p>
<p>
  (c) limit any liabilities in any way that is not permitted under applicable
  law; or
</p>
<p>
  (d) exclude any liabilities that may not be excluded under applicable law.
</p>
<p>
  5.2 Neither party shall be liable to the other party in respect of any
  losses arising out of a Force Majeure Event. " <strong>Force Majeure Event</strong>" means an event, or a series of
  related events, that is outside the reasonable control of the party
  affected (including failures of the internet or any public
  telecommunications network, hacker attacks, denial of service attacks,
  virus or other malicious software attacks or infections, power failures,
  industrial disputes affecting any third party, changes to the laws,
  disasters, explosions, fires, floods, riots, terrorist attacks, wars,
  curfew, lockdowns and quarantine measures);
</p>
<p>
  5.3 EPIC shall not be liable to you in respect of any loss of profits or
  anticipated savings, in respect of any loss of revenue or income, any loss
  of business, contracts or opportunities.
</p>
<p>
  5.4 EPIC shall not be liable for any disruption of service done in terms of
  payments, trading, delivery or any other business activity.
</p>
<p>
  5.5 EPIC shall not be liable to any end customer in terms of service,
  delivery, etc.
</p>
<p>
  5.6 EPIC makes no representations, warranties or guarantees as to the
  actions or inactions of the Users.
</p>
<p>
  6. Force Majeure Event
</p>
<p>
  6.1 If a Force Majeure Event gives rise to a failure or delay in either
  party performing any obligation under this Agreement that obligation will
  be suspended for the duration of the Force Majeure Event.
</p>
<p>
  6.2 A party that becomes aware of a Force Majeure Event which gives rise
  to, or which is likely to give rise to, any failure or delay in that party
  performing any obligation under this Agreement, must:
</p>
<p>
  (a) promptly notify the other; and
</p>
<p>
  (b) inform the other of the period for which it is estimated that such
  failure or delay will continue.
</p>
<p>
  6.3 A party whose performance of its obligations under this Agreement is
  affected by a Force Majeure Event must take reasonable steps to mitigate
  the effects of the Force Majeure Event.
</p>
<p>
  7. Termination
</p>
<p>
  <strong>
    EPIC has the right to cancel your membership as a Helaviru Lead and
    terminate this Agreement at its discretion at any time with or without
    giving reasons therefor.
  </strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  8. Effects of termination
</p>
<p>
  Upon the termination of this Agreement, all of the provisions of this
  Agreement shall cease to have effect. Except to the extent that this
  Agreement expressly provides otherwise, the termination of this Agreement
  shall not affect the accrued rights of either party. Within 30 days
  following the termination of this Agreement for any reason you must pay to
  EPIC any payments that have been accrued before the termination of this
  Agreement without prejudice to the parties' other legal rights.
</p>
<p>
  9. General
</p>
<p>
  9.1 Entire Agreement
</p>
<p>
  This Agreement contains all covenants, stipulations and provisions agreed
  by the Parties. No agent or representative of either Party has authority to
  make, and the Parties shall not be bound by or be liable for, any other
  statement, representation, promise or agreement not set forth herein.
</p>
<p>
  Modifications or Variations: Any modification or variation of the terms and
  conditions of this Agreement, as well as any modification or variation in
  the scope of work, may only be made by a written agreement duly signed by
  both the Parties.
</p>
<p>
  9.2 Applicable Law
</p>
<p>
  Applicable Law means and includes the laws and any other instruments having
  the force of law in Sri Lanka as may be issued and in force from time to
  time. This Agreement shall be interpreted in accordance with the laws of
  Sri Lanka.
</p>
<p>
  9.3 Survival
</p>
<p>
  The clauses of this Agreement, which by nature are intended to survive
  termination of this Agreement, shall remain in effect after such
  termination.
</p>
<p>
  9.4 Settlement of Disputes
</p>
<p>
  In case, a dispute arises between parties, then there would be two ways for
  resolution of the dispute under this Agreement viz:
</p>
<ul>
  <li>
    Amicable Settlement: Performance of this Agreement is governed by the
    terms and conditions of this Agreement. However, at times dispute may
    arise about any interpretation of any term or condition of this
    Agreement, including but not limited to, the scope of work, the clauses
    of payments etc. In such a situation either party to this Agreement may
    send a written notice of dispute to the other party. The party
    receiving the notice of dispute will consider the notice and respond to
    it in writing within 30 days after receipt. If that party fails to
    respond within 30 days, or the dispute cannot be amicably settled
    within 30 days following the response of that party, then sub-clause
    (ii) shall become applicable.
  </li>
  <li>
    Arbitration: Differences and/or disputes remaining unresolved amicably
    shall be settled through arbitration in accordance with the rules of
    Arbitration of the Sri Lanka National Arbitration Centre and the award
    made in pursuance thereof shall be binding on the parties. The venue
    for Arbitration proceedings shall be Colombo and the language of the
    arbitration proceedings and that of all documents and communications
    between you and the EPIC shall be English. Arbitrator(s) shall give
    reasons for the award and the award shall be binding on you and EPIC
    and enforceable at the courts of jurisdiction in Sri Lanka, in
    accordance with the provisions of the Arbitration Act in force. The
    services under this Agreement shall be continued during the arbitration
    proceedings, unless otherwise agreed in writing by you and EPIC or
    unless it is proved that the services cannot possibly be continued
    during the arbitration proceedings.
  </li>
</ul>
<p>
  9.5 Indemnification
</p>
<p>
  If any action in court is brought against EPIC or an agent or an employee
  or a representative of EPIC by a Third Party pursuant to this Agreement on
  account of any act/omission/negligence on your part under this Agreement,
  or for any damage or injury caused by the alleged act, omission or
  negligence on your part to any Third Party, you shall in all such cases
  indemnify and keep EPIC, its agents, employees and representatives,
  harmless and indemnified from all such losses, damages, expenses, etc.
  arising out of any decrees or Court orders, etc. or otherwise arising out
  of any such act or omission.
</p>
<p>
  9.6 Notices
</p>
<p>
  All notices and other communications under this Agreement must be in
  writing, and must either be mailed by registered post with acknowledgement
  due or hand delivered with proof of it having been received or sent by
  email or fax. If posted, all notices will be considered as delivered after
  three days, of the notice having been posted. If hand delivered, all
  notices will be considered as delivered, when received by the party to whom
  the notice is sent. If the notice is faxed and /or e-mailed, it would be
  considered as delivered on the same day; this will be followed by a
  delivery of hardcopy within five days. All notices under this Agreement
  shall be sent to or delivered at the address as specified by the parties. A
  Notice shall be effective when delivered.
</p>
<p>
  9.7 Waiver
</p>
<p>
  Save as where this Agreement expressly provides, neither party shall be
  deemed to have waived any right, power, privilege or remedy under this
  Agreement unless such party shall have delivered to the other party a
  written waiver signed by an authorized official of such waiving party. No
  failure or delay on the part of either party in exercising any right,
  power, privilege or remedy hereunder shall operate as a waiver, default or
  acquiescence thereof. Any waiver on the part of either party of any right,
  power, privilege or remedy hereunder shall not preclude any other or
  further exercise thereof. Any single or partial exercise of any right,
  power, privileges or remedy hereunder shall not preclude any other or
  further exercise thereof.
</p>
<p>
  9.8 Severability
</p>
<p>
  If any provision or condition of this Agreement is prohibited or rendered
  invalid or unenforceable, such prohibition, invalidity or unenforceability
  shall not affect the validity or enforceability of any other provisions and
  conditions of this Agreement or this Agreement as a whole and the remaining
  provisions of this Agreement shall remain in full force and effect.
</p>
<p>
  9.9 Relationship of Parties
</p>
<p>
  You acknowledge and agree that this is not a permanent employment. You will
  render your voluntary services at your own will. You are an independent
  contractor and not an employee or agent of EPIC. You shall not have
  authority to commit or create any liability on the part of EPIC in any
  manner whatsoever.
</p>
<p>
  9.10 Legal Authority
</p>
<p>
  Either Party has the legal right and authority to enter into this Agreement
  and to perform its obligations under this Agreement.
</p>
<p>
  <strong>By clicking “I accept”, </strong>
  <strong>
    you confirm that you have read and clearly understood the content of
    this Agreement and will use the said Helaviru Platform and act as a
    Helaviru Lead on the terms and conditions set forth in this Agreement
    and will abide by the same at all times.
  </strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  HELAVIRU AGREEMENT WITH TRANSPORT PROVIDERS
</p>
<p>
  This agreement (hereinafter called the “Agreement”) is made by and between
  YOU (“Customer”) and Epic Green Eco Systems (Private) Limited , a company
  incorporated in the Democratic Socialist Republic of Sri Lanka under the
  Companies Act No. 07 of 2007 and having its registered office at Epic
  Techno-Village, 158/1/A, Kaduwela Road, Thalangama, Battaramulla, 10120,
  Sri Lanka (hereinafter referred to as “EPIC”)
</p>
<p>
  WHEREAS EPIC is a specialist in technology solutions and has developed the
  Helaviru e-commerce platform and You acknowledge the same and that EPIC
  does not provide transportation services, function as a carrier or agent
  for the transportation of goods. EPIC is willing to facilitate the
  provision of goods transportation services by You relating to the Helaviru
  Platform developed by it and You are desirous of obtaining the said
  services through the Helaviru Platform.
</p>
<p>
  1. Facilitation of Services
</p>
<p>
  EPIC grants the Customer a license that is non-exclusive and
  non-transferable, to use the Helaviru Platform for the purpose of
  connecting with users of the Helaviru Platform (hereinafter referred to as
  “Users”) during the term of this Agreement.
</p>
<p>
  EPIC has the right to update and/or modify the Helaviru Platform from time
  to time at its discretion.
</p>
<p>
  You shall not send spam or unsolicited messages to the Users or
  inconvenience them in any manner.
</p>
<p>
  2. No assignment of Intellectual Property Rights
</p>
<p>
  Nothing in this Agreement shall operate to assign or transfer any
  Intellectual Property Rights from EPIC to the Customer, or from the
  Customer to EPIC. "Intellectual Property Rights" means all intellectual
  property rights wherever in the world, whether registrable or
  unregistrable, registered or unregistered, including any application or
  right of application for such rights (and these "intellectual property
  rights" include copyright and related rights, database rights, confidential
  information, trade secrets, know-how, business names, trade names, trade
  marks, service marks, passing off rights, unfair competition rights,
  patents, petty patents, utility models, semi-conductor topography rights
  and rights in designs);
</p>
<p>
  3. Charges and Payments
</p>
<p>
  EPIC shall be entitled to a certain percentage of the revenue generated
  through the sales via the Helaviru Platform by the Customer, as may be
  decided by EPIC at its discretion which percentage shall be communicated to
  you from time to time. EPIC shall deduct the said percentage of revenue
  from each transaction and remit the balance to the Customer within two (2)
  working days of each transaction.
</p>
<p>
  4 Confidentiality Obligations
</p>
<p>
  4.1 EPIC may disclose the Confidential Information to EPIC's officers,
  employees, professional advisers, insurers, agents and subcontractors who
  have a need to access the Customer Confidential Information for the
  performance of their work with respect to this Agreement and who are bound
  by a written agreement or professional obligation to protect the
  confidentiality of the Customer Confidential Information. " Confidential
  Information" means any information disclosed by or on behalf of either
  party during the term whether disclosed in writing, orally or otherwise,
  that at the time of disclosure:
</p>
<p>
  (i) was marked or described as "confidential"; or
</p>
<p>
  (ii) should have been reasonably understood to be confidential.
</p>
<p>
  4.2 This Clause imposes no obligations with respect to Confidential
  Information that:
</p>
<p>
  (a) is known before disclosure under this Agreement and is not subject to
  any other obligation of confidentiality;
</p>
<p>
  (b) is or becomes publicly known through no act or default of either party;
  or
</p>
<p>
  (c) is obtained from a third party in circumstances where such party has no
  reason to believe that there has been a breach of an obligation of
  confidentiality.
</p>
<p>
  4.3 The restrictions in this Clause do not apply to the extent that any
  Confidential Information is required to be disclosed by any law or
  regulation, by any judicial or governmental order or request.
</p>
<p>
  4.4 The provisions of this Clause shall continue in force indefinitely
  following the termination of this Agreement.
</p>
<p>
  5. Limitations and Exclusions of Liability
</p>
<p>
  5.1 Nothing in this Agreement will:
</p>
<p>
  (a) limit or exclude your liability for death or personal injury resulting
  from negligence;
</p>
<p>
  (b) limit or exclude your liability for fraud or fraudulent
  misrepresentation;
</p>
<p>
  (c) limit any liabilities in any way that is not permitted under applicable
  law; or
</p>
<p>
  (d) exclude any liabilities that may not be excluded under applicable law.
</p>
<p>
  5.2 Neither party shall be liable to the other party in respect of any
  losses arising out of a Force Majeure Event. " Force Majeure Event" means
  an event, or a series of related events, that is outside the reasonable
  control of the party affected (including failures of the internet or any
  public telecommunications network, hacker attacks, denial of service
  attacks, virus or other malicious software attacks or infections, power
  failures, industrial disputes affecting any third party, changes to the
  laws, disasters, explosions, fires, floods, riots, terrorist attacks, wars,
  curfew, lockdowns and quarantine measures);
</p>
<p>
  5.3 EPIC shall not be liable to the Customer in respect of any loss of
  profits or anticipated savings, EPIC in respect of any loss of revenue or
  income, any loss of business, contracts or opportunities.
</p>
<p>
  5.4 EPIC shall not be liable for any disruption of service done in terms of
  payments, trading, delivery or any other business activity.
</p>
<p>
  5.5 EPIC shall not be liable to any end customer in terms of service,
  delivery, etc.
</p>
<p>
  5.6 EPIC makes no representations, warranties or guarantees as to the
  actions or inactions of the Users.
</p>
<p>
  6. Force Majeure Event
</p>
<p>
  6.1 If a Force Majeure Event gives rise to a failure or delay in either
  party performing any obligation under this Agreement that obligation will
  be suspended for the duration of the Force Majeure Event.
</p>
<p>
  6.2 A party that becomes aware of a Force Majeure Event which gives rise
  to, or which is likely to give rise to, any failure or delay in that party
  performing any obligation under this Agreement, must:
</p>
<p>
  (a) promptly notify the other; and
</p>
<p>
  (b) inform the other of the period for which it is estimated that such
  failure or delay will continue.
</p>
<p>
  6.3 A party whose performance of its obligations under this Agreement is
  affected by a Force Majeure Event must take reasonable steps to mitigate
  the effects of the Force Majeure Event.
</p>
<p>
  7. Termination
</p>
<p>
  7.1 Either party may terminate this Agreement by giving to the other party
  at least ………. days' written notice of termination.
</p>
<p>
  7.2 Either party may terminate this Agreement immediately by giving written
  notice of termination to the other party if the other party commits a
  material breach of this Agreement.
</p>
<p>
  7.3 Either party may terminate this Agreement immediately by giving written
  notice of termination to the other party if:
</p>
<p>
  (a) the other party:
</p>
<p>
  (i) is dissolved;
</p>
<p>
  (ii) ceases to conduct all of its business;
</p>
<p>
  (iii) is or becomes unable to pay its debts as they fall due;
</p>
<p>
  (iv) is or becomes insolvent or is declared insolvent; or
</p>
<p>
  (v) convenes a meeting or makes or proposes to make any arrangement or
  composition with its creditors;
</p>
<p>
  (b) an administrator, administrative receiver, liquidator, receiver,
  trustee, manager or similar is appointed over any of the assets of the
  other party;
</p>
<p>
  (c) an order is made for the winding up of the other party, or the other
  party passes a resolution for its winding up; or
</p>
<p>
  (d) if that other party is an individual:
</p>
<p>
  (i) that other party dies;
</p>
<p>
  (ii) as a result of illness or incapacity, that other party becomes
  incapable of managing his or her own affairs; or
</p>
<p>
  (iii) that other party is the subject of a bankruptcy petition or order.
</p>
<p>
  8. Effects of termination
</p>
<p>
  Upon the termination of this Agreement, all of the provisions of this
  Agreement shall cease to have effect. Except to the extent that this
  Agreement expressly provides otherwise, the termination of this Agreement
  shall not affect the accrued rights of either party. Within 30 days
  following the termination of this Agreement for any reason the Customer
  must pay to EPIC any payments that have been accrued before the termination
  of this Agreement without prejudice to the parties' other legal rights.
</p>
<p>
  9. General
</p>
<p>
  9.1 Entire Agreement
</p>
<p>
  This Agreement contains all covenants, stipulations and provisions agreed
  by the Parties. No agent or representative of either Party has authority to
  make, and the Parties shall not be bound by or be liable for, any other
  statement, representation, promise or agreement not set forth herein.
</p>
<p>
  Modifications or Variations: Any modification or variation of the terms and
  conditions of this Agreement, as well as any modification or variation in
  the scope of work, may only be made by a written agreement duly signed by
  both the Parties.
</p>
<p>
  9.2 Applicable Law
</p>
<p>
  Applicable Law means and includes the laws and any other instruments having
  the force of law in Sri Lanka as may be issued and in force from time to
  time. This Agreement shall be interpreted in accordance with the laws of
  Sri Lanka.
</p>
<p>
  9.3 Survival
</p>
<p>
  The clauses of this Agreement, which by nature are intended to survive
  termination of this Agreement, shall remain in effect after such
  termination.
</p>
<p>
  9.4 Settlement of Disputes
</p>
<p>
  In case, a dispute arises between parties, then there would be two ways for
  resolution of the dispute under this Agreement viz:
</p>
<p>
  (i) Amicable Settlement: Performance of this Agreement is governed by the
  terms and conditions of this Agreement. However, at times dispute may arise
  about any interpretation of any term or condition of this Agreement,
  including but not limited to, the scope of work, the clauses of payments
  etc. In such a situation either party to this Agreement may send a written
  notice of dispute to the other party. The party receiving the notice of
  dispute will consider the notice and respond to it in writing within 30
  days after receipt. If that party fails to respond within 30 days, or the
  dispute cannot be amicably settled within 30 days following the response of
  that party, then sub-clause (ii) shall become applicable.
</p>
<p>
  (ii) Arbitration: Differences and/or disputes remaining unresolved amicably
  shall be settled through arbitration in accordance with the rules of
  Arbitration of the Sri Lanka National Arbitration Centre and the award made
  in pursuance thereof shall be binding on the parties. The venue for
  Arbitration proceedings shall be Colombo and the language of the
  arbitration proceedings and that of all documents and communications
  between the Customer and the EPIC shall be English. Arbitrator(s) shall
  give reasons for the award and the award shall be binding on the Customer
  and EPIC and enforceable at the courts of jurisdiction in Sri Lanka, in
  accordance with the provisions of the Arbitration Act in force. The
  services under this Agreement shall be continued during the arbitration
  proceedings, unless otherwise agreed in writing by the Customer and EPIC or
  unless it is proved that the services cannot possibly be continued during
  the arbitration proceedings.
</p>
<p>
  9.5 Indemnification
</p>
<p>
  If any action in court is brought against EPIC or an agent or an employee
  or a representative of EPIC by a Third Party pursuant to this Agreement on
  account of any act/omission/negligence on the part of the Customer under
  this Agreement, or for any damage or injury caused by the alleged act,
  omission or negligence on the part of the Customer, its agents,
  representatives or sub-contractors or employees to any Third Party, the
  Customer shall in all such cases indemnify and keep EPIC, its agents,
  employees and representatives, harmless and indemnified from all such
  losses, damages, expenses, etc. arising out of any decrees or Court orders,
  etc. or otherwise arising out of any such act or omission.
</p>
<p>
  9.6 Notices
</p>
<p>
  All notices and other communications under this Agreement must be in
  writing, and must either be mailed by registered post with acknowledgement
  due or hand delivered with proof of it having been received or sent by
  email or fax. If posted, all notices will be considered as delivered after
  three days, of the notice having been posted. If hand delivered, all
  notices will be considered as delivered, when received by the party to whom
  the notice is sent. If the notice is faxed and /or e-mailed, it would be
  considered as delivered on the same day; this will be followed by a
  delivery of hardcopy within five days. All notices under this Agreement
  shall be sent to or delivered at the address as specified by the parties. A
  Notice shall be effective when delivered.
</p>
<p>
  9.7 Waiver
</p>
<p>
  Save as where this Agreement expressly provides, neither party shall be
  deemed to have waived any right, power, privilege or remedy under this
  Agreement unless such party shall have delivered to the other party a
  written waiver signed by an authorized official of such waiving party. No
  failure or delay on the part of either party in exercising any right,
  power, privilege or remedy hereunder shall operate as a waiver, default or
  acquiescence thereof. Any waiver on the part of either party of any right,
  power, privilege or remedy hereunder shall not preclude any other or
  further exercise thereof. Any single or partial exercise of any right,
  power, privileges or remedy hereunder shall not preclude any other or
  further exercise thereof.
</p>
<p>
  9.8 Severability
</p>
<p>
  If any provision or condition of this Agreement is prohibited or rendered
  invalid or unenforceable, such prohibition, invalidity or unenforceability
  shall not affect the validity or enforceability of any other provisions and
  conditions of this Agreement or this Agreement as a whole and the remaining
  provisions of this Agreement shall remain in full force and effect.
</p>
<p>
  9.9 Relationship of Parties
</p>
<p>
  Each Party is an independent contractor and not an employee or agent of the
  other Party. Neither Party shall have authority to commit or create any
  liability on the part of the other in any manner whatsoever. Personnel
  retained or assigned by one Party to perform work under this Agreement
  shall at all times be considered employees, agents, or contractor of that
  Party only.
</p>
<p>
  9.10 Legal Authority
</p>
<p>
  Either Party has the legal right and authority to enter into this Agreement
  and to perform its obligations under this Agreement.
</p>
<p>
  By clicking the “checkbox” to proceed, you confirm that you have read and clearly understood the content of these Terms of Service and will use the said Helaviru Platform on the terms and conditions set forth herein and will abide by the same at all times. 
</p>
