import { Component, OnInit } from '@angular/core';
import {SystemSettingsService} from '../service/system-settings.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-riyawiru-privacy-policy',
  templateUrl: './riyawiru-privacy-policy.component.html',
  styleUrls: ['./riyawiru-privacy-policy.component.css']
})
export class RiyawiruPrivacyPolicyComponent implements OnInit {

  fileURL: string;

  constructor(private settingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'RIYAWIRU_PRIVACY') {
            return this.fileURL = environment.proxyUrl + 'fileservice/file/' + setting.value + '/?X-TenantID=wholesale';
          }
        }
      }

    });
  }

}
