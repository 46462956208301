import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {UserRoleService} from '../service/user-role.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  submitted = false;
  forgotPasswordForm: FormGroup;
  email: any;
  password: any;
  rePassword: any;
  requestID: any;
  tenantValue: any;

  constructor(private formBuilder: FormBuilder,
              private userRoleService: UserRoleService,
              private toastr: ToastrService,
              private validationHandlerService: ValidationHandlerService,
              private activatedRoute: ActivatedRoute,
              private _router: Router
  ) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(param => {
      this.requestID = param['uid'];
    });
    this.forgotPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.validationHandlerService.passwordValidation())]],
      rePassword: ['', Validators.required]
    }, {
      validator: this.validationHandlerService.MustMatch('password', 'rePassword')
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  resetPassword() {
    this.loading();

    let _resetPasswordBody = {
      'id': parseInt(this.requestID),
      'newPassword': this.password
    };

    this.userRoleService.resetPassword(_resetPasswordBody).subscribe(result => {
      Swal.close();
      if (result['message'] === 'PASSWORD_CHANGED') {
        this.password = '';
        this.rePassword = '';
        Swal.fire({
          position: 'center',
          type: 'success',
          title: this.validationHandlerService.passwordReset(sessionStorage.getItem('LANG')),
          text: this.validationHandlerService.passwordResetMsg(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        }).then(() => {
          this._router.navigate(['/' + this.tenantValue]);
        });
      }
    }, () => {
      Swal.close();
    });
  }

  submit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.resetPassword();
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }
}
