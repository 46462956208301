<div class="page-contents">
    <div class="packge-form" id="insurnce">
      <div class="top-info-section text-center">
        <img src="../../../../../../../assets/images/payment-types/insuarance/plant-machinery.png" alt=""
             class="sltimg">
        <h4 class="title-pck">
          <span *ngIf="activeLang === 'en'">Contractor's Plant and Machinery Insurance Benefit</span>
          <span *ngIf="activeLang === 'si'">කොන්ත්‍රාත්කරුගේ පැලෑටි සහ යන්ත්‍රෝපකරණ රක්‍ෂණ ප්‍රතිලාභ</span>
          <span *ngIf="activeLang === 'ta'">ஒப்பந்ததாரர் ஆலை மற்றும் இயந்திர காப்பீட்டு பலன்</span>
        </h4>

        <p class="rednotice">
          <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
          <span *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
          <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
        </p></div>
        <hr>
        <div class="scheme-doc">
            <div class="scheme-details">
                <h4>Contractor’s Plant and Machinery</h4>
                <p>This is an All Risks policy issued on annual basis to cover Contractor’s Plant & Machinery such as
                    Backhoe Loaders, Excavators, Cranes, Forklifts etc. whilst at site anywhere in Sri Lanka.
                    <br />
                    This policy will indemnify the insured against any unforeseen and sudden physical loss or damage
                    from any cause (including natural perils) subject to exclusions stipulated in the policy, within the
                    geographical limits of Sri Lanka. The cover applies for the insured items whilst they are at work or
                    at rest or being dismantled for the purpose of cleaning or overhauling.
                </p>

                <div class="d-tables">
                    <h4>Additional Covers: Inland transit, SRCC and Terrorism</h4>
                    <p>Brand new machinery - Backhoe Loaders, Excavators, Cranes, Forklifts & Harvester machine etc</p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Year of Manufacture as brand new </th>
                                <th scope="col">Rate Range</th>
                                <th scope="col">Excess applicable on All claims – subject to revision of excesses based
                                    on claim experience </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1 - 3</th>
                                <td>0.5%</td>
                                <td>10% with a minimum of Rs.15,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>3 - 5</th>
                                <td>0.6%</td>
                                <td>10% with a minimum of Rs.15,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>5 - 10</th>
                                <td>0.8%</td>
                                <td>15% with a minimum of Rs.20,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>10 - 15</th>
                                <td>1%</td>
                                <td>20% with a minimum of Rs.25,000/- on e.e.l </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-tables">
                    <p>Recondition machinery - Backhoe Loaders, Excavators, Cranes, Forklifts & Harvester machine etc.
                    </p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Year of purchase </th>
                                <th scope="col">Rate Range</th>
                                <th scope="col">Excess applicable on All claims – subject to
                                    revision of excesses based on claim
                                    experience </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1 - 3</th>
                                <td>0.5%</td>
                                <td>10% with a minimum of Rs.15,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>3 - 5</th>
                                <td>0.6%</td>
                                <td>10% with a minimum of Rs.15,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>5 - 10</th>
                                <td>0.8%</td>
                                <td>15% with a minimum of Rs.20,000/- on e.e.l </td>
                            </tr>
                            <tr>
                                <th>10 - 15</th>
                                <td>1%</td>
                                <td>20% with a minimum of Rs.25,000/- on e.e.l </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="form-group btn-sections b-0 p-0 text-right">
                <a aria-label="Close" class="btn cancel-btn-half">Back</a>
                <a aria-label="Next" class="btn next-btn-half" type="submit" data-target="#successModal"
                    data-toggle="modal">Submit</a>
            </div>
        </div>
    </div>
</div>
