<div class="page-contents" xmlns="http://www.w3.org/1999/html">
  <div class="packge-form" id="insurnce">
    <div class="top-info-section text-center">
      <img src="../../../../../../../assets/images/payment-types/insuarance/plant-machinery.png" alt=""
           class="sltimg">
      <h4 class="title-pck">
        <span *ngIf="activeLang === 'en'">Contractor's Plant and Machinery Insurance Benefit</span>
        <span *ngIf="activeLang === 'si'">කොන්ත්‍රාත්කරුගේ පැලෑටි සහ යන්ත්‍රෝපකරණ රක්‍ෂණ ප්‍රතිලාභ</span>
        <span *ngIf="activeLang === 'ta'">ஒப்பந்ததாரர் ஆலை மற்றும் இயந்திர காப்பீட்டு பலன்</span>
      </h4>

      <p class="rednotice">
        <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
        <span *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
        <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
      </p></div>
    <hr>
    <div *ngIf="section == 0" class="form-inputs-section vasforms">
      <h4 class="title-pck">
        <span *ngIf="activeLang === 'en'">Questionnaire for Contractors Plant and Machinery</span>
        <span *ngIf="activeLang === 'si'">කොන්ත්‍රාත්කරුවන්ගේ කම්හල් සහ යන්ත්‍රෝපකරණ සඳහා ප්‍රශ්නාවලිය</span>
        <span *ngIf="activeLang === 'ta'">ஒப்பந்ததாரர்கள் ஆலை மற்றும் இயந்திரங்களுக்கான கேள்வித்தாள்</span>
      </h4>
      <form [formGroup]="form" autocomplete="off" class="sms-full-width form-box-panel">
        <div class="row flexrow">
          <div class="col-lg-5 col-md-12 col-xs-12">
            <label class="numbr">
              <span *ngIf="activeLang === 'en'">1) Name of Proposer:</span>
              <span *ngIf="activeLang === 'si'">1) යෝජකයාගේ නම:</span>
              <span *ngIf="activeLang === 'ta'">1) முன்மொழிபவரின் பெயர்</span>
            </label><sup style="color: red"> *</sup>
          </div>
          <div class="col-lg-7 col-md-12 col-xs-12">
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
                     formControlName="fullName" id="fullName" matInput>
              <div *ngIf="submitted && f.fullName.errors" class="inputError">
                <div *ngIf="f.fullName.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
                <div
                  *ngIf="f.fullName.errors.pattern">{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-5 col-md-12 col-xs-12">
            <label class="numbr">
              <span *ngIf="activeLang === 'en'">2) Address:</span>
              <span *ngIf="activeLang === 'si'">2) ලිපිනය:</span>
              <span *ngIf="activeLang === 'ta'">2) முகவரி</span>
            </label><sup style="color: red"> *</sup>
          </div>
          <div class="col-lg-7 col-md-12 col-xs-12">
            <mat-form-field appearance="outline" class="inputbox">
              <textarea readonly [(ngModel)]="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                        formControlName="address" id="address" matInput></textarea>
              <div *ngIf="submitted && f.address.errors" class="inputError">
                <div *ngIf="f.address.errors.required">{{'COMMON_VALIDATION.ADDRESS' | translate}}</div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-5 col-md-12 col-xs-12">
            <label class="numbr">
              <span *ngIf="activeLang === 'en'">3) NIC No :/ Business Registration No:</span>
              <span *ngIf="activeLang === 'si'">3) ජාතික හැඳුනුම්පත්/ ව්‍යාපාර ලියාපදිංචි අංකය:</span>
              <span *ngIf="activeLang === 'ta'">3) என்ஐசி/ வணிக பதிவு எண்</span>
              <sup style="color: red"> *</sup>
            </label>
          </div>
          <div class="col-lg-7 col-md-12 col-xs-12">
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="nicOrBusNo" [ngClass]="{ 'is-invalid': submitted && f.nicOrBusNo.errors }"
                     formControlName="nicOrBusNo" id="nicOrBusNo" matInput>
              <div *ngIf="submitted && f.nicOrBusNo.errors" class="inputError">

                <span *ngIf="activeLang === 'en'">NIC/ Business Registration Number is required!.</span>
                <span *ngIf="activeLang === 'si'">ජාතික හැඳුනුම්පත්/ ව්‍යාපාර ලියාපදිංචි අංකය අවශ්‍යයි!</span>
                <span *ngIf="activeLang === 'ta'">என்ஐசி/ வணிக பதிவு எண் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-5 col-md-12 col-xs-12">
            <label class="numbr">
              <span *ngIf="activeLang === 'en'">4) Purpose of Use:</span>
              <span *ngIf="activeLang === 'si'">4) භාවිතයේ අරමුණ:</span>
              <span *ngIf="activeLang === 'ta'">4) பயன்பாட்டின் நோக்கம்:</span>
              <sup style="color: red"> *</sup>
            </label>
          </div>
          <div class="col-lg-7 col-md-12 col-xs-12">
            <mat-form-field appearance="outline" class="inputbox">
              <textarea [(ngModel)]="purpose" [ngClass]="{ 'is-invalid': submitted && f.purpose.errors }"
                        formControlName="purpose" id="purpose" matInput></textarea>
              <div *ngIf="submitted && f.purpose.errors" class="inputError">

                <span *ngIf="activeLang === 'en'">Purpose is required!.</span>
                <span *ngIf="activeLang === 'si'">අරමුණ අවශ්‍යයි !.</span>
                <span *ngIf="activeLang === 'ta'">நோக்கம் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="inputsections">
          <div class="row flexrow">
            <div class="col-lg-5 col-md-12 col-xs-12">
              <label class="numbr">
                <span *ngIf="activeLang === 'en'">5) Description of Item</span>
                <span *ngIf="activeLang === 'si'">5) අයිතමය පිළිබඳ විස්තරය</span>
                <span *ngIf="activeLang === 'ta'">5) பொருளின் விளக்கம்</span>
                <sup style="color: red"> *</sup>
              </label>
            </div>
            <div class="col-lg-7 col-md-12 col-xs-12">

            </div>
          </div>
          <div class="pleft">
            <div class="row flexrow">
              <div class="col-lg-5 col-md-12 col-xs-12">
                <label class="numbr">
                  <span *ngIf="activeLang === 'en'">i) Type and Name of manufacturer</span>
                  <span *ngIf="activeLang === 'si'">i) නිෂ්පාදකයාගේ වර්ගය සහ නම</span>
                  <span *ngIf="activeLang === 'ta'">i) உற்பத்தியாளரின் வகை மற்றும் பெயர்</span>
                  <sup style="color: red"> *</sup>
                </label>
              </div>
              <div class="col-lg-7 col-md-12 col-xs-12">
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="manufacturerName"
                         [ngClass]="{ 'is-invalid': submitted && f.manufacturerName.errors }"
                         formControlName="manufacturerName" id="manufacturerName" matInput/>
                  <div *ngIf="submitted && f.manufacturerName.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">Name of manufacturer is required!.</span>
                    <span *ngIf="activeLang === 'si'">නිෂ්පාදකයාගේ නම අවශ්‍යයි!</span>
                    <span *ngIf="activeLang === 'ta'">உற்பத்தியாளரின் பெயர் தேவை!</span>
                  </div>
                </mat-form-field>
              </div>
            </div>
            <div class="row flexrow">
              <div class="col-lg-5 col-md-12 col-xs-12">
                <label class="numbr">
                  <span *ngIf="activeLang === 'en'">ii) Model No., Serial number</span>
                  <span *ngIf="activeLang === 'si'">ii) ආදර්ශ  අංකය, අනුක්‍රමික අංකය</span>
                  <span *ngIf="activeLang === 'ta'">ii) மாதிரி எண், வரிசை எண்</span>
                  <sup style="color: red"> *</sup>
                </label>
              </div>
              <div class="col-lg-7 col-md-12 col-xs-12">
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="modelOrSerialNumber"
                         [ngClass]="{ 'is-invalid': submitted && f.modelOrSerialNumber.errors }"
                         formControlName="modelOrSerialNumber" id="modelOrSerialNumber" matInput/>
                  <div *ngIf="submitted && f.modelOrSerialNumber.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">Model No., Serial number is required!.</span>
                    <span *ngIf="activeLang === 'si'">ආදර්ශ අංකය, අනුක්‍රමික අංකය අවශ්‍යයි!</span>
                    <span *ngIf="activeLang === 'ta'">மாதிரி எண், வரிசை எண் தேவை !.</span>
                  </div>
                </mat-form-field>
              </div>
            </div>
            <div class="row flexrow">
              <div class="col-lg-5 col-md-12 col-xs-12">
                <label class="numbr">
                  <span *ngIf="activeLang === 'en'">iii) Year of Manufacture</span>
                  <span *ngIf="activeLang === 'si'">iii) නිෂ්පාදන වර්ෂය</span>
                  <span *ngIf="activeLang === 'ta'">iii) உற்பத்தி செய்த வருடம்</span>
                  <sup style="color: red"> *</sup>
                </label>
              </div>
              <div class="col-lg-7 col-md-12 col-xs-12">
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="manufactureYear"
                         [ngClass]="{ 'is-invalid': submitted && f.manufactureYear.errors }"
                         formControlName="manufactureYear" id="manufactureYear" matInput/>
                  <div *ngIf="submitted && f.manufactureYear.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">iii) Year of Manufacture is required!.</span>
                    <span *ngIf="activeLang === 'si'">iii) නිෂ්පාදන වර්ෂය අවශ්‍යයි!</span>
                    <span *ngIf="activeLang === 'ta'">iii) உற்பத்தி ஆண்டு தேவை !.</span>
                  </div>
                </mat-form-field>
              </div>
            </div>
            <div class="row flexrow">
              <div class="col-lg-5 col-md-12 col-xs-12">
                <label class="numbr">
                  <span *ngIf="activeLang === 'en'">iv) Item value</span>
                  <span *ngIf="activeLang === 'si'">iv) අයිතමයේ වටිනාකම</span>
                  <span *ngIf="activeLang === 'ta'">iv) பொருளின் மதிப்பு</span>
                  <sup style="color: red"> *</sup>
                </label>
              </div>
              <div class="col-lg-7 col-md-12 col-xs-12">
                <mat-form-field appearance="outline" class="inputbox">
                  <input min="0" type="number" [(ngModel)]="itemValue"
                         [ngClass]="{ 'is-invalid': submitted && f.itemValue.errors }"
                         formControlName="itemValue" id="itemValue" matInput/>
                  <div *ngIf="submitted && f.itemValue.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">Item value is required!.</span>
                    <span *ngIf="activeLang === 'si'">අයිතමයේ වටිනාකම අවශ්‍යයි!</span>
                    <span *ngIf="activeLang === 'ta'">பொருளின் மதிப்பு தேவை !.</span>
                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-5 col-md-12 col-xs-12">
            <label class="numbr">
              <span *ngIf="activeLang === 'en'">6) Previous loss experience:</span>
              <span *ngIf="activeLang === 'si'">6) පෙර අලාභ අත්දැකීම:</span>
              <span *ngIf="activeLang === 'ta'">6) முந்தைய இழப்பு அனுபவம்:</span>
              <sup style="color: red"> *</sup>
            </label>
          </div>
          <div class="col-lg-7 col-md-12 col-xs-12">
            <mat-form-field appearance="outline" class="inputbox">
              <textarea [(ngModel)]="lossExperience" [ngClass]="{ 'is-invalid': submitted && f.lossExperience.errors }"
                        formControlName="lossExperience" id="lossExperience" matInput></textarea>
              <div *ngIf="submitted && f.lossExperience.errors" class="inputError">

                <span *ngIf="activeLang === 'en'">Previous loss experience is required!.</span>
                <span *ngIf="activeLang === 'si'">කලින් අලාභ අත්දැකීම අවශ්‍යයි!</span>
                <span *ngIf="activeLang === 'ta'">முந்தைய இழப்பு அனுபவம் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="declaration">
          <p *ngIf="activeLang === 'en'" class="text-center">
            I hereby warrant the truth of the above statements, and I declare that I have withheld no
            information whatsoever which might tend in any way to increase the risk of Sri Lanka Insurance.
            I agree that, this proposal shall be the basis of the contract of between me and Sri Lanka
            Insurance, and the Liability of Sri Lanka Insurance shall not commence until the proposal is
            accepted and premium paid in full.
          </p>
          <p *ngIf="activeLang === 'si'" class="text-center">
            ඉහත ප්‍රකාශ වල සත්‍යතාව මම මෙයින් සහතික කරන අතර, මම ප්‍රකාශ කර සිටියේ නැතැයි මම ප්‍රකාශ කළෙමි
            ශ්‍රී ලංකා රක්‍ෂණ අවදානම වැඩි කිරීම සඳහා කවර ආකාරයකින් හෝ නැඹුරු විය හැකි තොරතුරු.
            මෙම යෝජනාව මා සහ ශ්‍රී ලංකාව අතර ගිවිසුමේ පදනම විය යුතු බවට මම එකඟ වෙමි
            යෝජනාව ලැබෙන තුරු රක්‍ෂණය සහ ශ්‍රී ලංකා රක්‍ෂණයේ වගකීම ආරම්භ නොවේ
            පිළිගෙන වාරිකය සම්පූර්ණයෙන් ගෙවා ඇත.
          </p>
          <p *ngIf="activeLang === 'ta'" class="text-center">
            மேற்கூறிய அறிக்கைகளின் உண்மைக்கு நான் இதன்மூலம் உத்தரவாதம் அளிக்கிறேன், நான் இல்லை என்று கூறிவிட்டேன்
            இலங்கை காப்பீட்டின் அபாயத்தை அதிகரிக்க எந்த வகையிலும் வழிவகை செய்யக்கூடிய தகவல்கள்.
            இந்த முன்மொழிவு எனக்கும் இலங்கைக்கும் இடையிலான ஒப்பந்தத்தின் அடிப்படையாகும் என்பதை நான் ஒப்புக்கொள்கிறேன்
            காப்பீடு மற்றும் இலங்கை காப்பீட்டின் பொறுப்பு முன்மொழிவு வரும் வரை தொடங்கப்படாது
            ஏற்றுக்கொள்ளப்பட்டது மற்றும் பிரீமியம் முழுமையாக செலுத்தப்பட்டது.
          </p>
          <div class="write">
            <div class="lft">
              <div class="row flexrow">
                <div class="col-lg-6 col-md-12 col-xs-12">
                  <label class="mtlbl">
                    <span *ngIf="activeLang === 'en'">Date</span>
                    <span *ngIf="activeLang === 'si'">දිනය</span>
                    <span *ngIf="activeLang === 'ta'">தேதி</span>
                  </label>
                  <mat-form-field appearance="outline" class="inputbox">
                    <input type="date" [(ngModel)]="applicationDate" readonly
                           [ngClass]="{ 'is-invalid': submitted && f.applicationDate.errors }"
                           formControlName="applicationDate" id="applicationDate" matInput>

                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12">
                  <label class="mtlbl ">
                    <span *ngIf="activeLang === 'en'">Signature of the proposer</span>
                    <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන</span>
                    <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம்</span>
                  </label><sup style="color: red"> *</sup>
                  <mat-form-field appearance="outline" class="inputbox">
                    <input [(ngModel)]="proposerSignature"
                           [ngClass]="{ 'is-invalid': submitted && f.proposerSignature.errors }"
                           formControlName="proposerSignature" id="proposerSignature" matInput>
                    <div *ngIf="submitted && f.proposerSignature.errors" class="inputError">
                      <span *ngIf="activeLang === 'en'">Signature of the proposer is required!.</span>
                      <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන අවශ්‍යයි!</span>
                      <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம் தேவை !.</span>
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="form-group btn-sections b-0 p-0 text-right">


          <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="nextPage()"
                  type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="section == 1" class="scheme-doc">
      <div class="scheme-details">
        <h4>Contractor’s Plant and Machinery</h4>
        <p>This is an All Risks policy issued on annual basis to cover Contractor’s Plant & Machinery such as
          Backhoe Loaders, Excavators, Cranes, Forklifts etc. whilst at site anywhere in Sri Lanka.
          <br/>
          This policy will indemnify the insured against any unforeseen and sudden physical loss or damage
          from any cause (including natural perils) subject to exclusions stipulated in the policy, within the
          geographical limits of Sri Lanka. The cover applies for the insured items whilst they are at work or
          at rest or being dismantled for the purpose of cleaning or overhauling.
        </p>

        <div class="d-tables">
          <h4>Additional Covers: Inland transit, SRCC and Terrorism</h4>
          <p>Brand new machinery - Backhoe Loaders, Excavators, Cranes, Forklifts & Harvester machine etc</p>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Year of Manufacture as brand new</th>
              <th scope="col">Rate Range</th>
              <th scope="col">Excess applicable on All claims – subject to revision of excesses based
                on claim experience
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>1 - 3</th>
              <td>0.5%</td>
              <td>10% with a minimum of Rs.15,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>3 - 5</th>
              <td>0.6%</td>
              <td>10% with a minimum of Rs.15,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>5 - 10</th>
              <td>0.8%</td>
              <td>15% with a minimum of Rs.20,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>10 - 15</th>
              <td>1%</td>
              <td>20% with a minimum of Rs.25,000/- on e.e.l</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-tables">
          <p>Recondition machinery - Backhoe Loaders, Excavators, Cranes, Forklifts & Harvester machine etc.
          </p>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Year of purchase</th>
              <th scope="col">Rate Range</th>
              <th scope="col">Excess applicable on All claims – subject to
                revision of excesses based on claim
                experience
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>1 - 3</th>
              <td>0.5%</td>
              <td>10% with a minimum of Rs.15,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>3 - 5</th>
              <td>0.6%</td>
              <td>10% with a minimum of Rs.15,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>5 - 10</th>
              <td>0.8%</td>
              <td>15% with a minimum of Rs.20,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>10 - 15</th>
              <td>1%</td>
              <td>20% with a minimum of Rs.25,000/- on e.e.l</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="form-group btn-sections b-0 p-0 text-right">
        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    float: left;
    margin-right: 10px;
    border-radius: 5px;" (click)="backPage()"
                type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}
        </button>


        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    margin-right: 10px;
    border-radius: 5px;" (click)="submitForm('DRAFT')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.DRAFT' | translate}}</button>

        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="submitForm('PENDING')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SUBMIT' | translate}}</button>
      </div>
    </div>

  </div>
</div>


<button hidden data-target="#successModal" data-toggle="modal" #openSuccessModal></button>
<button hidden data-target="#draftModal" data-toggle="modal" #openDraftModal></button>

<!-- Success Modal -->
<div class="modal fade npa-model" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_save'|translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Draft Modal -->
<div class="modal fade npa-model" id="draftModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_draft_save' | translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



