import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../../../../../service/product.service';
import {SystemSettingsService} from '../../../../../service/system-settings.service';
import {CoreService} from '../../../../../service/core.service';
import {UserRoleService} from '../../../../../service/user-role.service';
import {CommonService} from '../../../../../common/common.service';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {CommonSignUpService} from '../../../../../service/common-sign-up.service';
import {ValidationHandlerService} from '../../../../../validation-handler/validation-handler.service';
import {environment} from '../../../../../../environments/environment';
import {catchError, last, map} from 'rxjs/operators';
import {InteractionService} from '../../../../../service/interaction.service';
import {of} from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-farmer-forward-contract',
  templateUrl: './farmer-forward-contract.component.html',
  styleUrls: ['./farmer-forward-contract.component.css']
})
export class FarmerForwardContractComponent implements OnInit {
  categoryForm: FormGroup;
  submittedCategoryForm: boolean = false;

  productDetailsForm: FormGroup;
  submittedProductDetailsForm: boolean = false;

  submittedPicUpAddressForm: boolean = false;
  pickUpAddressForm: FormGroup;

  submittedPaymentPlanForm: boolean = false;
  paymentPlanOrderForm: FormGroup;

  addInstallment: FormGroup;

  selectForwardNext = 0;
  selectNewProductOptions = 0;
  files: File[] = [];
  categoryList: any;
  subCategoryList: any;
  selectSubCategoryId: any;

  activeProfile: any;

  todayDate = new Date().toISOString().slice(0, 10);
  selectCategoryId: any;
  title: any;
  description: any;
  buyDate: any = new Date().toISOString().slice(0, 10);
  availableTotalQty: any;
  unitType: any;
  unitTypeId: any;
  unitTypeList: any;

  provinceList: any;
  districtList: any;
  divisionalSecretariatList: any;
  gramaNiladhariAreaList: any;
  selectDistrictId: any;
  selectDivisionalSecretariatId: any;
  selectGramaNiladhariAreaId: any;
  selectProvinceId: any;
  postalCode: any;
  address: any;
  currencyCode: any;

  rows: FormArray;
  contractTotalAmount: any;
  contractInitialPayment: any;
  contractClosingPayment: any;
  forwardTypeId: any;
  isEnableRiyaviruTransportation: boolean = false;

  imageArray = [];
  productAvailableFromDate: any = new Date().toISOString().slice(0, 10);
  activeLang: string = 'en';
  isAgentFarmer: boolean = false;
  listByUsername: any;

  constructor(private productService: ProductService,
              private systemSettingsService: SystemSettingsService,
              private coreService: CoreService,
              private userRoleService: UserRoleService,
              private commonService: CommonService,
              private interactionService: InteractionService,
              private http: HttpClient,
              private commonSignUpService: CommonSignUpService,
              private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder) {
    this.activeLang = sessionStorage.getItem('LANG');

    this.addInstallment = this.formBuilder.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required]
    });

    this.rows = this.formBuilder.array([]);
    this.systemSettingsService.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if ('CURRENCY_CODE' === setting.code) {
            this.currencyCode = setting.value;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.addInstallment.addControl('rows', this.rows);

    this.fetchAllCategories();
    this.categoryFormValidation();
    this.fetchAllUnitTypes();
    this.productDetailsFormValidation();
    this.pickUpAddressFormValidation();
    this.paymentPlanOrderFormValidation();
    this.getAllProvince();
    this.loadProductTypes();

    this.interactionService.passingFarmerUsername.subscribe(farmerUsername => {
      this.isAgentFarmer = true;
      this.listByUsername = farmerUsername;
    });
  }

  categoryFormValidation() {
    this.categoryForm = this.formBuilder.group({
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      productAvailableFromDate: ['', Validators.required]
    });
  }

  productDetailsFormValidation() {
    this.productDetailsForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      description: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      buyDate: ['', Validators.required],
      availableTotalQty: ['', Validators.required],
      unitType: ['', Validators.required],
    });
  }

  pickUpAddressFormValidation() {
    this.pickUpAddressForm = this.formBuilder.group({
      address: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      district: ['', Validators.required],
      province: ['', Validators.required],
      divisionalSecretariat: ['', Validators.required],
      gramaNiladhariArea: ['', Validators.required],
      postalCode: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]
    });
  }

  paymentPlanOrderFormValidation() {
    this.paymentPlanOrderForm = this.formBuilder.group({
      contractTotalAmount: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyNumbersAllowed())]],
      contractInitialPayment: ['', Validators.required],
      contractClosingPayment: ['', Validators.required],
    });
  }

  get f1() {
    return this.categoryForm.controls;
  }

  get f2() {
    return this.productDetailsForm.controls;
  }

  get f3() {
    return this.pickUpAddressForm.controls;
  }

  get f4() {
    return this.paymentPlanOrderForm.controls;
  }


  uploadImages(value) {
    this.commonService.processing();
    let formData = new FormData();

    if (value[0].size > 5006708) {
      Swal.close();
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });

      return false;
    }
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        //Remove last image from files array
        this.files.splice(-1, 1);

        Swal.fire({
          title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
        });

        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

  onSelect(event) {
    if (this.files.length <= 3) {
      this.uploadImages(event.addedFiles);
      this.files.push(...event.addedFiles);
      if (event.addedFiles[0].size > 1006708) {
        this.files.splice(-1, 1);
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }
  }

  submitCategoryForm() {
    this.submittedCategoryForm = true;
    if (this.categoryForm.invalid) {
      return;
    }
    if (this.selectSubCategoryId !== 'none') {
      this.selectForwardNext = 1;
    }
  }


  fetchAllCategories() {
    this.productService.getActiveProfile().subscribe(activeProfile => {
      this.activeProfile = activeProfile['code'];
      this.productService.getAllMainCategoryByProfileId(this.activeProfile).subscribe(categories => {
        if (categories) {
          this.categoryList = categories;
        }
      });
    });
  }

  selectSubCategory(event) {
    if (event.target.value !== 'none') {
      this.selectSubCategoryId = event.target.value;
    } else {
      this.selectSubCategoryId = undefined;
    }
  }

  nextListProductDetailsPage() {
    this.submitCategoryForm();
  }

  selectCategory(event) {
    this.selectSubCategoryId = undefined;
    if (event.target.value !== 'none') {
      this.selectCategoryId = event.target.value;
      this.productService.fetchAllSubCategory(this.selectCategoryId).subscribe(subCategories => {
        if (subCategories) {
          this.subCategoryList = subCategories;
        }
      });
    } else {
      this.selectCategoryId = undefined;
    }
  }

  productDetailsFormSubmit() {
    this.submittedProductDetailsForm = true;
    if (this.productDetailsForm.invalid) {
      return;
    }
    this.selectForwardNext = 2;
  }

  productDetailsPageNext() {
    this.productDetailsFormSubmit();
  }

  manageAvailableTotalQty(qty) {
    sessionStorage.setItem('totalAvailableQty', qty);
  }

  selectUnitType(event) {
    this.unitTypeId = event.target.value;
  }

  fetchAllUnitTypes() {
    this.coreService.fetchAllUnitTypes().subscribe(unitTypes => {
      if (unitTypes) {
        this.unitTypeList = unitTypes;
      }
    });
  }

  pickUpLocationAddressNextPage() {
    this.submittedPicUpAddressForm = true;
    if (this.pickUpAddressForm.invalid) {
      return;
    }
    this.selectForwardNext = 3;
  }

  selectProvince(event) {
    this.selectProvinceId = event.target.value;
    this.getAllDistrict(this.selectProvinceId);
  }

  selectDistrict(event) {
    this.selectDistrictId = event.target.value;
    this.getAllDivSec(this.selectDistrictId);
  }

  selectDivisionalSecretariat(event) {
    this.selectDivisionalSecretariatId = event.target.value;
    this.getAllGramaNiladari(this.selectDivisionalSecretariatId);
  }

  selectGramaNiladhariArea(event) {
    this.selectGramaNiladhariAreaId = event.target.value;
  }


  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.provinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.districtList = result;
      }
    });

  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.divisionalSecretariatList = result;
      }
    });
  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.gramaNiladhariAreaList = result;
      }
    });
  }

  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex: number) {
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      installment: null,
      paymentDate: null,
    });
  }

  submitPaymentPlanOrder() {
    this.submittedPaymentPlanForm = true;
    if (this.paymentPlanOrderForm.invalid) {
      return;
    }
    this.selectForwardNext = 4;
  }

  nextDeliveryPage() {
    let x = 0;
    if (this.rows.value.length > 0) {
      for (let row of this.rows.value) {
        x = x + row.installment;
      }
    }
    x = x + parseInt(this.contractInitialPayment) + parseInt(this.contractClosingPayment);
    if (x === 100) {
      this.submitPaymentPlanOrder();
    } else {
      Swal.fire({
        title: this.validationHandlerService.percentageMessage(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });
    }
  }

  riyaviruTransportationServiceEnable(b: boolean) {
    this.isEnableRiyaviruTransportation = b;
  }

  saveProduct() {
    if (this.files.length >= 1 && this.files.length <= 4) {
      this.commonService.processing();
      let imageList = [];
      for (let image of this.imageArray) {
        imageList.push({
          'image': image['image']
        });
      }

      this.userRoleService.whoAmI().subscribe(whoAmI => {

        let arr = [];
        let loop = 1;
        if (this.rows.value.length > 0) {
          for (let row of this.rows.value) {
            let temp = [];
            temp.push('M' + loop);
            arr.push(this.installmentCollectionCommonBody(temp, row.installment, row.paymentDate));
            loop = loop + 1;
          }
        }

        let farmerUsername = sessionStorage.getItem('FARMER_USERNAME');

        let username;
        if (this.isAgentFarmer) {
          username = this.listByUsername;
        } else if (farmerUsername !== null) {
          username = farmerUsername;
        } else {
          username = whoAmI['email'];
        }


        let futureProductDTO = {
          'product': {
            'profile': {
              'code': this.activeProfile
            },
            'name': this.title,
            'description': this.description,
            'listedBy': username,
            'type': {
              'id': this.forwardTypeId
            },
            'unit': {
              'id': this.unitType
            },
            'productStatus': {
              'id': 1,
              'code': '1x'
            },
            'subCategory': {
              'code': this.selectSubCategoryId,
              'mainCategory': {
                'code': this.selectCategoryId
              }
            },
            'address': this.address,
            'gramaNiladari': this.selectGramaNiladhariAreaId,
            'province': this.selectProvinceId,
            'district': this.selectDistrictId,
            'divisional': this.selectDistrictId,
            'postalCode': this.postalCode,
            'riyaviru': this.isEnableRiyaviruTransportation
          },
          'expireDate': this.buyDate,
          'startDate': this.productAvailableFromDate,
          'availableTotalQty': this.availableTotalQty,
          'unitType': this.unitType,
          'contractTotalAmount': this.contractTotalAmount,
          'contractInitialPayment': this.contractInitialPayment,
          'installmentCollection': arr,
          'contractClosingPayment': this.contractClosingPayment,
          'productImages': imageList
        };

        this.productService.createFutureProduct(futureProductDTO).subscribe(saveFutureProduct => {
          Swal.close();
          if (saveFutureProduct['success']) {
            sessionStorage.removeItem('FARMER_USERNAME');
            this.interactionService.updateAgentFarmersList(true);
            this.selectForwardNext = 5;
          }
        }, () => {
          Swal.close();
        });
      });

    } else {
      // Swal.fire('Must include 4 product photos!', '', 'warning');
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage(sessionStorage.getItem('LANG')),
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });
    }
  }

  installmentCollectionCommonBody(specCode, installment, date) {
    return {
      'specs': specCode,
      'installment': installment,
      'paymentDate': date
    };
  }

  loadProductTypes() {
    this.coreService.fetchAllProductTypes().subscribe(types => {
      if (types) {
        for (let type of types) {
          if (type.type === 'FORWARD_BN') {
            this.forwardTypeId = type.id;
            return;
          }
        }
      }
    });
  }
}
