import {Component, OnInit} from '@angular/core';
import {UserRoleService} from '../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InteractionService} from '../../service/interaction.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private userRoleService: UserRoleService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              private router: Router) {
  }

  temp2: boolean = true;
  isLead: boolean = true;
  isClickSellButton: boolean = false;
  headerClicked: boolean = false;
  userRole: any;

  ngOnInit() {
    this.route.queryParams.subscribe(parms => {
      if (parms['isClickedSellButton'] === 'true') {
        this.isClickSellButton = true;
        this.headerClicked = true;
      }

      if (parms['headerClicked'] === 'true') {
        this.headerClicked = true;
      }

      if (parms['isRouteSellAProduct']) {
        this.headerClicked = true;
      }


      if (this.headerClicked) {
        this.headerClicked = false;

        this.userRole = localStorage.getItem('USER_ROLE');

        if (this.userRole === 'FARMER' || this.userRole === 'COMPANY_FARMER' || this.userRole === 'ECONOMIC_SHOP') {
          this.router.navigate(['/my-account/overview-farmer-selling/wholesale'],
            {queryParams: {'isClickedSellButton': this.isClickSellButton}});
        }

        if (this.userRole === 'LEAD') {
          this.router.navigate(['/my-account/overview-lead-selling/wholesale'],
            {queryParams: {'isClickedSellButton': this.isClickSellButton}});
        }

        if (this.userRole === 'BUYER' || this.userRole === 'COMPANY_BUYER') {
          this.router.navigate(['/my-account/overview-wholesaler-selling/wholesale'],
            {queryParams: {'isClickedSellButton': this.isClickSellButton}});
        }

        if (this.userRole === 'AGGREGATOR' || this.userRole === 'COMPANY_AGGREGATOR') {
          this.router.navigate(['/my-account/overview-aggregator-selling/wholesale'],
            {queryParams: {'isClickedSellButton': this.isClickSellButton}});
        }

        if (this.userRole === 'COMPANY_EXPORTER' || this.userRole === 'EXPORTER' || this.userRole === 'CONSUMER' ||
          this.userRole === 'COMPANY_CONSUMER' || this.userRole === 'RETAILER' || this.userRole === 'COMPANY_RETAILER') {
          this.router.navigate(['/my-account/overview-consumer-buying/wholesale'],
            {queryParams: {'isClickedSellButton': this.isClickSellButton}});
        }

        if (this.userRole === 'SERVICE_PROVIDER' || this.userRole === 'COMPANY_SERVICE_PROVIDER') {
          this.router.navigate(['/my-account/overview-service-provider-selling/wholesale'],
            {queryParams: {'isClickedSellButton': this.isClickSellButton}});
        }
      }

    });


  }


}
