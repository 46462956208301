import {Component, Input, OnInit} from '@angular/core';
import {MyAccountServiceService} from '../../my-account-service.service';
import {CommonService} from '../../../../common/common.service';
import {CoreService} from '../../../../service/core.service';
import {UserRoleService} from '../../../../service/user-role.service';
import {Router} from '@angular/router';
import {InteractionService} from '../../../../service/interaction.service';

@Component({
  selector: 'app-farmer-sell-a-product',
  templateUrl: './farmer-sell-a-product.component.html',
  styleUrls: ['./farmer-sell-a-product.component.css']
})
export class FarmerSellAProductComponent implements OnInit {
  @Input() isAgentFarmerLogin: boolean;
  @Input() agentFarmerUsername: string;

  selectNewProductOptions = 0;
  isBankAccAvailable: boolean = false;
  isBankOthersAccAvailable: boolean = false;
  isBankDetailLoaded: boolean = false;
  tenantValue: any = 'wholesale';
  isFarmerExistBankAccount: boolean = true;
  isLeaderExistBankAccount: boolean = true;

  constructor(private myAccountServiceService: MyAccountServiceService,
              private coreService: CoreService,
              private userRoleService: UserRoleService,
              private interactionService: InteractionService,
              public commonService: CommonService,
              private router: Router) {

    myAccountServiceService.toggleModal.subscribe(res => {
      this.selectNewProductOptions = parseInt(res);
    });

  }

  ngOnInit() {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.loadStatusIsExist();

    this.interactionService.updateBankStatus.subscribe(updateBankStatus => {
      if (updateBankStatus) {
        this.loadStatusIsExist();
      }
    });

    this.interactionService.usernames.subscribe(getUsernames => {
      this.selectNewProductOptions = 0;
      this.loadStatusIsExistForLeadNFarmer(getUsernames);
    });

  }

  loadStatusIsExistForLeadNFarmer(usernames) {

    let isLeader = false;
    let isFarmer = false;

    this.coreService.isExistBank(usernames['lead']).subscribe(isValueLead => {

      this.coreService.isExistBank(usernames['farmer']).subscribe(isValueFarmer => {
        isFarmer = isValueFarmer;
        isLeader = isValueLead;

        this.isLeaderExistBankAccount = isValueLead;
        this.isBankOthersAccAvailable = isValueLead;
        this.isFarmerExistBankAccount = isValueFarmer;

        this.isBankDetailLoaded = isFarmer && isLeader;
        this.isBankAccAvailable = isFarmer && isLeader;
      });
    });

  }

  loadStatusIsExist() {
    this.coreService.isExistBank(
localStorage.getItem('USERNAME')).subscribe(result => {
      this.isFarmerExistBankAccount = true;
      this.isLeaderExistBankAccount = true;
      this.isBankOthersAccAvailable = result;
      this.isBankDetailLoaded = result;
      this.isBankAccAvailable = result;
    });
  }

  clickToGoBankAccount() {
    this.router.navigate(['/my-account/bank-acc/' + this.tenantValue], {queryParams: {'bankAccount': true}});
  }

  setProductOption(number: number) {
    this.selectNewProductOptions = number;
  }
}
