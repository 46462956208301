<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">

          <div class="container">
            <div class="vas-plans">
              <div class="page-title text-center">
                <h3>{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.VALUE_ADDED_SERVICES'|translate}}</h3>
                <div class="page-sub">
                  <p *ngIf="activeLang === 'en'">Pick a plan suited for you</p>
                  <p *ngIf="activeLang === 'si'">ඔබට ගැලපෙන සැලැස්මක් තෝරන්න</p>
                  <p *ngIf="activeLang === 'ta'">உங்களுக்கு ஏற்ற திட்டத்தை தேர்வு செய்யவும்</p>
                </div>
              </div>

              <hr>

              <div class="card-container vas-cards">
                <div class="container">
                  <div class="row">
                    <div class="col-md-4 col-xs-12">
                      <a class="card-sub vas-item">
                        <div class="select-icon vas-choice text-center">
                          <div class="d-flex align-items-center">
                            <div style="height: 410px;">
                              <div class="item-img">
                                <img src="../../../assets/images/icons/vas-loan.png"
                                     alt="" class="vasimg">
                              </div>
                              <div class="item-info">

                                <p *ngIf="activeLang === 'en'">Obtain Agriculture Loans and financial assistance through
                                  legitimate institutions at government approved interest rates. As the Banks and
                                  authorized lending organizations are listed in the service layer of
                                  the Platform, Farmers and other Platform Stakeholders can request financial assistance
                                  from the listed Banks depending on the buying-selling contracts they execute on the
                                  Helaviru Platform.</p>

                                <p *ngIf="activeLang === 'si'">කෘෂිකාර්මික ණය සහ මූල්‍ය ආධාර නීත්‍යානුකූල ආයතන හරහා රජයේ
                                  අනුමත පොලී අනුපාත යටතේ ලබා ගන්න.
                                  බැංකු සහ බලයලත් ණය දෙන සංවිධාන වේදිකාවේ සේවා ස්ථරයේ ලැයිස්තුගත කර ඇති හෙයින්, ගොවීන්ට
                                  සහ අනෙකුත් වේදිකා පාර්ශවකරුවන්ට හෙළවිරු වේදිකාවේ දී
                                  සිදු කෙරෙන මිලදී ගැනීමේ-විකිණීමේ කොන්ත්‍රාත් මත පදනම්ව ලැයිස්තුගත බැංකු වලින් මූල්‍ය
                                  ආධාර ඉල්ලා සිටිය හැකිය.</p>


                                <p *ngIf="activeLang === 'ta'">அரசாங்கத்தால் அங்கீகரிக்கப்பட்ட வட்டி விகிதத்தில் முறையான
                                  நிறுவனங்கள் மூலம் விவசாயக் கடன்கள் மற்றும் நிதி உதவிகளைப் பெறுங்கள்.
                                  வங்கிகள் மற்றும் அங்கீகரிக்கப்பட்ட கடன் நிறுவனங்கள் மேடையின் சேவை அடுக்கில்
                                  பட்டியலிடப்பட்டுள்ளதால், விவசாயிகள் மற்றும் பிற மேடை பங்குதாரர்கள் ஹெலவிரு தளத்தில்
                                  அவர்கள் வாங்கும்-விற்பனை ஒப்பந்தங்களைப் பொறுத்து பட்டியலிடப்பட்ட வங்கிகளிடமிருந்து
                                  நிதி உதவியை கோரலாம்.</p>


                              </div>
                            </div>

                          </div>
                          <button [disabled]="!(userRole === 'AGGREGATOR' || userRole === 'FARMER' || userRole === 'SERVICE_PROVIDER' ||
                                        userRole === 'COMPANY_AGGREGATOR' || userRole === 'COMPANY_FARMER' || userRole === 'COMPANY_SERVICE_PROVIDER')"
                                  class="btn btn-success d-green-btn" data-target="#loanModal"
                                  data-toggle="modal">
                            <span *ngIf="activeLang === 'en'">Apply for a Loan</span>
                            <span *ngIf="activeLang === 'si'">ණයක් සඳහා අයදුම් කරන්න</span>
                            <span *ngIf="activeLang === 'ta'">கடனுக்கு விண்ணப்பிக்கவும்</span>
                          </button>
                        </div>
                      </a>
                    </div>

                    <div class="col-md-4 col-xs-12">
                      <a class="card-sub vas-item">
                        <div class="select-icon vas-choice text-center">
                          <div class="d-flex align-items-center">
                            <div style="height: 410px;">
                              <div class="item-img">
                                <img src="../../../assets/images/icons/vas-insuarance.png"
                                     alt="" class="vasimg">
                              </div>
                              <div class="item-info">

                                <p *ngIf="activeLang === 'en'">Obtain Agriculture Insurance for your crops and machinery
                                  along with the assistance through legitimate institutions. As the authorized Insurance
                                  Service Providers are listed in the Service Layer of the Platform, Farmers and other
                                  Platform Stakeholders can request Insurance from listed Insurance Institutions
                                  depending on the buying-selling contracts they execute on the Helaviru Platform.</p>

                                <p *ngIf="activeLang === 'si'">නීත්‍යානුකූල ආයතන හරහා ඔබේ භෝග සහ යන්ත්‍රෝපකරණ සඳහා කෘෂි
                                  රක්‍ෂණ රක්‍ෂණයක් ලබා ගන්න. බලයලත් රක්‍ෂණ සේවා සපයන්නන් වේදිකාවේ සේවා ස්ථරයේ ලැයිස්තුගත
                                  කර ඇති හෙයින්, ගොවීන්ට සහ අනෙකුත් වේදිකා හිමියන්ට හෙළවිරු වේදිකාවේ ක්‍රියාත්මක කරන
                                  ගැනුම්-විකිණුම් කොන්ත්‍රාත් මත පදනම්ව ලැයිස්තුගත රක්ෂණ ආයතන වලින් රක්‍ෂණයක් ඉල්ලා
                                  සිටිය හැකිය.</p>


                                <p *ngIf="activeLang === 'ta'">முறையான நிறுவனங்களின் உதவியுடன் உங்கள் பயிர்கள் மற்றும்
                                  இயந்திரங்களுக்கான விவசாய காப்பீட்டைப் பெறுங்கள். அங்கீகரிக்கப்பட்ட காப்பீட்டு சேவை
                                  வழங்குநர்கள் தளத்தின் சேவை அடுக்கில் பட்டியலிடப்பட்டுள்ளதால், விவசாயிகள் மற்றும் பிற
                                  மேடை பங்குதாரர்கள் ஹெலவிரு தளத்தில் அவர்கள் வாங்கும்-விற்பனை ஒப்பந்தங்களைப் பொறுத்து
                                  பட்டியலிடப்பட்ட காப்பீட்டு நிறுவனங்களிடமிருந்து காப்பீட்டை கோரலாம்.</p>

                              </div>
                            </div>

                          </div>

                          <button class="btn btn-success d-green-btn" data-target="#insuaranceModal"
                                  data-toggle="modal">
                            <span *ngIf="activeLang === 'en'">Apply for an Insurance</span>
                            <span *ngIf="activeLang === 'si'">රක්ෂණයක් සඳහා අයදුම් කරන්න</span>
                            <span *ngIf="activeLang === 'ta'">காப்பீட்டிற்கு விண்ணப்பிக்கவும்</span>
                          </button>
                        </div>
                      </a>
                    </div>

                    <div class="col-md-4 col-xs-12">
                      <a class="card-sub vas-item">
                        <div class="select-icon vas-choice text-center">
                          <div class="d-flex align-items-center">
                            <div style="height: 410px;">
                              <div class="item-img">
                                <img src="../../../assets/images/icons/vas-voice.png"
                                     alt="" class="vasimg">
                              </div>
                              <div class="item-info">

                                <p *ngIf="activeLang === 'en'">Obtain Helaviru voice Package for your communication in
                                  the Farming groups. As Authorized Communication Service Provider SLT Mobitel is listed
                                  in the Service Layer of the Platform, Farmers and other Stakeholders can apply for the
                                  Helaviru SIM on the Helaviru Platform.</p>

                                <p *ngIf="activeLang === 'si'">ගොවිජන කණ්ඩායම් තුළ ඔබේ සන්නිවේදනය සඳහා හෙළවිරු හඬ පැකේජය
                                  ලබා ගන්න. බලයලත් සන්නිවේදන සේවා සැපයුම්කරු එස්එල්ටී මොබිටෙල් වේදිකාවේ සේවා ස්ථරයේ
                                  ලැයිස්තුගත කර ඇති හෙයින්, ගොවීන්ට සහ අනෙකුත් පාර්ශවකරුවන්ට හෙළවිරු සිම් පත සඳහා
                                  හෙලවිරු වේදිකාවේ සිට අයදුම් කළ හැකිය.</p>


                                <p *ngIf="activeLang === 'ta'">விவசாய குழுக்களில் உங்கள் தொடர்புக்கு ஹெலவிரு குரல்
                                  தொகுப்பைப் பெறுங்கள். அங்கீகரிக்கப்பட்ட தகவல் தொடர்பு சேவை வழங்குநர் எஸ்எல்டி மொபிடெல்
                                  தளத்தின் சேவை அடுக்கில் பட்டியலிடப்பட்டுள்ளதால், விவசாயிகள் மற்றும் பிற பங்குதாரர்கள்
                                  ஹெலவிரு சிம் -க்கு ஹெலவிரு தளத்தில் இருந்து விண்ணப்பிக்கலாம்.</p>


                              </div>
                            </div>
                          </div>
                          <button [disabled]="!(userRole === 'FARMER' || userRole === 'COMPANY_FARMER')" class="btn btn-success d-green-btn"
                                  data-target="#voicepackageModal"
                                  data-toggle="modal">
                            <span *ngIf="activeLang === 'en'">Apply for a voice package</span>
                            <span *ngIf="activeLang === 'si'">හඬ පැකේජයක් සඳහා අයදුම් කරන්න</span>
                            <span *ngIf="activeLang === 'ta'">குரல் தொகுப்புக்கு விண்ணப்பிக்கவும்</span>
                          </button>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>

<!-- Get your Voice Package Modal -->
<div class="modal fade npa-model" id="voicepackageModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-header green-hd">
        <img src="../../../assets/helaviru.png" alt="">
      </div>
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="page-title-modl text-center">
            <h3>
              <span *ngIf="activeLang === 'en'">Apply for a voice package</span>
              <span *ngIf="activeLang === 'si'">හඬ පැකේජයක් සඳහා අයදුම් කරන්න</span>
              <span *ngIf="activeLang === 'ta'">குரல் தொகுப்புக்கு விண்ணப்பிக்கவும்</span>
            </h3>
            <div class="page-sub">
              <p *ngIf="activeLang === 'en'">Pick a voice package suited for you</p>
              <p *ngIf="activeLang === 'si'">ඔබට ගැලපෙන හඬ පැකේජයක් තෝරන්න</p>
              <p *ngIf="activeLang === 'ta'">உங்களுக்கு ஏற்ற குரல் தொகுப்பைத் தேர்ந்தெடுக்கவும்</p>
            </div>
          </div>
          <hr>
          <div class="container-modal">
            <div class="selection-box row">
              <div class="col-md-12 col-xs-12 text-center">
                <div class="select-card" [routerLink]="['/value-added-services/voice-packages/'+ tenantValue]"
                     data-dismiss="modal">
                  <img src="../../../assets/images/slt.png" alt="">
                </div>
              </div>
              <!--              <div class="col-md-6 col-xs-12 text-center">-->
              <!--                <div class="select-card" [routerLink]="['/value-added-services/voice-packages/'+ tenantValue]"-->
              <!--                     data-dismiss="modal">-->
              <!--                  <img src="../../../assets/images/dialog.png" alt="">-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- Apply for an Insurance Modal -->
<div class="modal fade npa-model" id="insuaranceModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-header green-hd">
        <img src="../../../assets/helaviru.png" alt="">
      </div>
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="page-title-modl text-center">
            <h3>
              <span *ngIf="activeLang === 'en'">Apply for an Insurance</span>
              <span *ngIf="activeLang === 'si'">රක්ෂණයක් සඳහා අයදුම් කරන්න</span>
              <span *ngIf="activeLang === 'ta'">காப்பீட்டிற்கு விண்ணப்பிக்கவும்</span>
            </h3>
            <div class="page-sub">
              <p *ngIf="activeLang === 'en'">Pick an insurance suited for you</p>
              <p *ngIf="activeLang === 'si'">ඔබට ගැලපෙන රක්‍ෂණයක් තෝරන්න</p>
              <p *ngIf="activeLang === 'ta'">உங்களுக்கு ஏற்ற காப்பீட்டைத் தேர்ந்தெடுக்கவும்</p>
            </div>
          </div>
          <hr>
          <div class="container-modal">
            <div class="selection-box row">
              <div class="col-md-12 col-xs-12 text-center">
                <div class="select-card" [routerLink]="['/value-added-services/manage-insurance/'+ tenantValue]"
                     data-dismiss="modal">
                  <img src="../../../assets/images/payment-types/insuarance/slic.png" alt="">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Apply for a Loan Modal -->
<div class="modal fade npa-model" id="loanModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-header green-hd">
        <img src="../../../assets/helaviru.png" alt="">
      </div>
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="page-title-modl text-center">
            <h3>
              <span *ngIf="activeLang === 'en'">Apply for a Loan</span>
              <span *ngIf="activeLang === 'si'">ණයක් සඳහා අයදුම් කරන්න</span>
              <span *ngIf="activeLang === 'ta'">கடனுக்கு விண்ணப்பிக்கவும்</span>
            </h3>
            <div class="page-sub">
              <p *ngIf="activeLang === 'en'">Pick a bank suited for you</p>
              <p *ngIf="activeLang === 'si'">ඔබට ගැලපෙන බැංකුවක් තෝරන්න</p>
              <p *ngIf="activeLang === 'ta'">உங்களுக்கு ஏற்ற வங்கியைத் தேர்ந்தெடுக்கவும்</p>
            </div>
          </div>
          <hr>
          <div class="container-modal">
            <div class="selection-box row">
              <div class="col-md-12 col-xs-12 text-center">
                <div class="select-card" [routerLink]="['/value-added-services/manage-loans/'+ tenantValue]"
                     data-dismiss="modal">
                  <img src="../../../assets/images/payment-types/pb.png" alt="">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
