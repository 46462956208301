import {Component, OnInit, ViewChild} from '@angular/core';
import {CoreService} from '../../../service/core.service';
import {FarmersByLeadCodeDTO} from '../../../dto/farmersByLeadCodeDTO';
import {MatDialog, MatPaginator, MatTableDataSource} from '@angular/material';
import {UserRoleService} from '../../../service/user-role.service';
import {CommonService} from '../../../common/common.service';
import {MainUrlService} from '../../../service/main-url.service';
import {ProductDetailsDTO} from '../../../dto/productDetailsDTO';
import {ProductService} from '../../../service/product.service';
import {InteractionService} from '../../../service/interaction.service';
import {CommonSignUpService} from '../../../service/common-sign-up.service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {Md5} from 'ts-md5';
import {ReportService} from '../../../service/report.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';

@Component({
  selector: 'app-my-farmers',
  templateUrl: './my-farmers.component.html',
  styleUrls: ['./my-farmers.component.css']
})
export class MyFarmersComponent implements OnInit {

  @ViewChild('paginator1', {static: true}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: true}) paginator2: MatPaginator;
  @ViewChild('paginator3', {static: true}) paginator3: MatPaginator;
  product: any;

  farmersByLeadCodeDTO: FarmersByLeadCodeDTO[] = [];
  productDetailsDTO: ProductDetailsDTO[] = [];

  dataSourceViewModal;
  dataSourceEarnings;
  displayedColumns: string[] = ['name', 'location', 'Products', 'Earnings', 'added'];
  displayedColumnsEarnings: string[] = ['tr-ID', 'proid', 'name', 'buyer', 'total', 'referral', 'Date'];

  ELEMENT_DATA = [];
  ELEMENT_DATA_AUCTION = [];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  dataSource;
  dataSourceSpot;
  dataSourceAuction;
  productAuction: any;
  displayedColumnsSpot: string[] =
    ['id', 'category', 'price', 'bulkQty', 'qty', 'expiredDate', 'adddate', 'status', 'action', 'action1'];
  displayedColumnsAuction: string[] = ['id', 'name', 'created', 'location', 'startPrice', 'stockAvailable', 'endDate', 'status', 'action', 'action1'];
  // 'startDate',
  @ViewChild('paginatorSpot', {static: true}) paginatorSpot;
  @ViewChild('paginatorAuction', {static: true}) paginatorAuction;

  tenantValue: any;
  agentRefNo: any;
  farmerUsername: any;
  farmerAddress: any;
  farmerName: any;
  farmerMobile: any;
  farmerImageUrl: any;
  farmerGramaId: any;
  farmerLocationEN: any;
  farmerLocationSI: any;
  farmerLocationTA: any;
  activeLang: any;
  bankAccount: any;
  ordersCount: number = 0;
  salesCount: number = 0;
  totalEarnings: any = 0;
  isTotalEarnings = false;
  Earning: any;
  username: any;
  userRole: any;
  ecoShopCode: any;

  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private coreService: CoreService,
              public mainUrl: MainUrlService,
              private router: Router,
              private route: ActivatedRoute,
              private matDialog: MatDialog,
              private _formBuilder: FormBuilder,
              private productService: ProductService,
              private validationHandlerService: ValidationHandlerService,
              private commonSignUpService: CommonSignUpService,
              private commonService: CommonService,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,
              private reportService: ReportService) {

    sessionStorage.removeItem('FARMER_USERNAME');
    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });
  }

  ngOnInit() {

    this.getFarmersByLeadCode();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.interactionService.updateAgentFarmers.subscribe(isUpdate => {
      if (isUpdate) {
        this.getFarmersByLeadCode();
      }
    });
  }

  getFarmersByLeadCode() {
    this.commonService.processing();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {

      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.username = whoAmIDetails['email'];
        this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {
          this.agentRefNo = refNo.message;

          if (this.isEconomicCenter && this.isEcoFarmerSection) {
            this.coreService.fetchFarmersByEcoShopCode(refNo.message).subscribe(myFarmerList => {
              this.commonResponseBody(myFarmerList);
            }, () => {
              Swal.close();
            });
          } else {
            this.coreService.fetchFarmersByLeadCode(refNo.message).subscribe(myFarmerList => {
              this.commonResponseBody(myFarmerList);
            }, () => {
              Swal.close();
            });
          }


        }, () => {
          Swal.close();
        });
      }, () => {
        Swal.close();
      });
    } else {
      this.commonLogoutURL();
    }

  }

  commonResponseBody(values) {
    this.farmersByLeadCodeDTO = values;
    this.dataSource = new MatTableDataSource(this.farmersByLeadCodeDTO);
    this.dataSource.paginator = this.paginator1;
    Swal.close();
  }

  loadProducts(userName) {

    this.farmerUsername = userName;
    this.getTotalEarnings(userName);
    this.getFarmerDetails(userName);
    this.dataSourceViewModal = undefined;

    this.commonService.processing();
    this.productDetailsDTO = [];

    this.ELEMENT_DATA = [];
    this.ELEMENT_DATA_AUCTION = [];
    this.dataSourceSpot = undefined;
    this.dataSourceAuction = undefined;


    this.reportService.fetchTotalEarnings(userName).subscribe(getTotalEarnings => {
      if (getTotalEarnings) {
        this.Earning = getTotalEarnings;
      }
    });

    this.loadAuctionProducts('AUCTION', userName);
    this.loadSpotProducts('SPOT', userName);
  }

  loadSpotProducts(type, username) {
    let body = {
      'name': null,
      'categoryCode': null,
      'subCategoryCodes': [],
      'minPrice': null,
      'locations': [],
      'maxPrice': null,
      'sortByPrice': null,
      'ratings': null,
      'userName': username,
      'productType': type
    };
    this.productService.fetchAllProductByUser(body).subscribe(result => {

      for (let item of result) {

        let sku = '-';
        let image;
        if (item.type.type === 'SPOT') {
          sku = item.spotDTOList[0].sku;
          if (item.spotImages.length > 0) {
            image = item.spotImages[0].image;
          } else {
            image = '00000';
          }

        }

        let _stock;
        let _expireDate;
        let _totalQty;

        _stock = item.spotDTOList[0].stock;
        _expireDate = item.spotDTOList[0].expireDate;
        _totalQty = item.spotDTOList[0].totalQuantity;

        this.ELEMENT_DATA.push({
          sku: sku,
          name: item.name,
          description: item.subCategory.mainCategory.commonIndex.description,
          createdDate: item.created,
          location: item.location,
          unitPrice: item.unitPrice,
          stockAvailable: item.stockAvailable,
          expireDate: item.expireDate,
          image: image,
          allItems: item,
          category: item.subCategory.mainCategory.commonIndex,
          type: item.type.type,
          spotStock: _stock,
          totalQty: _totalQty,
          spotExpiredDate: _expireDate,
          isSellInSmallerQty: item.sellInSmallerQuantities,
          totalEarnings: item.totalEarnings,
          status: item.productStatus.description,
          unitType: item.unit.unit,
        });

      }

      this.dataSourceSpot = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSourceSpot.paginator = this.paginatorSpot;
      Swal.close();

    }, () => {
      Swal.close();
    });
  }

  loadAuctionProducts(type, username) {
    let body = {
      'name': null,
      'categoryCode': null,
      'subCategoryCodes': [],
      'minPrice': null,
      'locations': [],
      'maxPrice': null,
      'sortByPrice': null,
      'ratings': null,
      'userName': username,
      'productType': type
    };
    this.productService.fetchAllProductByUser(body).subscribe(result => {

      for (let val of result) {
        let image;
        if (val.forwardAcuImages.length > 0) {
          image = val.forwardAcuImages[0].image;
        } else {
          image = '00000';
        }

        this.ELEMENT_DATA_AUCTION.push({
          id: val.id,
          name: val.name,
          listedBy: val.listedBy,
          created: val.created,
          location: val.location,
          startPrice: val.auctionStartPrice,
          startDate: val.bidStartDate,
          endDate: val.bidEndDate,
          stockAvailable: val.bidQyt,
          allItems: val,
          image: image,
          type: val.type.type,
          status: val.productStatus.description,
          unitType: val.unit.unit,
          sku: val.sku,
        });
      }
      this.dataSourceAuction = new MatTableDataSource(this.ELEMENT_DATA_AUCTION);
      this.dataSourceAuction.paginator = this.paginatorAuction;
      Swal.close();
    }, () => {
      Swal.close();
    });
  }

  loadDataForUpdate(modal: any, item: any) {
    this.passingFarmerUsername();
    this.product = item;
    this.matDialog.open(modal, {
      width: '80%',
      panelClass: 'custom-dialog-',
      maxHeight: '99%',
      minHeight: '99%'
    }).afterClosed().subscribe(() => {
      this.loadProducts(this.farmerUsername);
      this.getFarmersByLeadCode();
    });

  }

  loadDataForAuctionUpdate(modal: any, item: any) {
    this.passingFarmerUsername();
    this.productService.getAuctionProductBidsCount(item.sku).subscribe(result => {
      if (parseInt(result) === 0) {
        this.productAuction = item;
        this.matDialog.open(modal, {
          width: '80%',
          panelClass: 'custom-dialog-',
          maxHeight: '99%',
          minHeight: '99%'
        }).afterClosed().subscribe(() => {
          this.loadProducts(this.farmerUsername);
        });
      } else {
        Swal.fire({
          position: 'center',
          type: 'warning',
          text: this.validationHandlerService.alreadyBids(sessionStorage.getItem('LANG')),
          showConfirmButton: false,
          timer: 3500
        });
      }
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSourceSpot.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceSpot.paginator) {
      this.dataSourceSpot.paginator.firstPage();
    }

    this.dataSourceAuction.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceAuction.paginator) {
      this.dataSourceAuction.paginator.firstPage();
    }
  }

  disableProduct(sku) {
    this.productService.updateMySpotProduct('DISABLE', sku).subscribe(() => {
      this.loadProducts(this.farmerUsername);
    });
  }

  enableProduct(sku) {
    this.productService.updateMySpotProduct('ACTIVE', sku).subscribe(() => {
      this.loadProducts(this.farmerUsername);
    });
  }


  disableProductAuction(sku) {
    this.productService.updateMyAuctionProduct('DISABLE', sku).subscribe(() => {
      this.loadProducts(this.farmerUsername);
    });
  }

  enableProductAuction(sku) {
    this.productService.updateMyAuctionProduct('ACTIVE', sku).subscribe(() => {
      this.loadProducts(this.farmerUsername);
    });
  }

  commonLogoutURL() {
    Swal.close();
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/' + this.tenantValue]);
  }

  getFarmerDetails(userName) {
    this.farmerUsername = userName;

    let body = {
      'userName': userName,
      'token': 'Bearer ' + localStorage.getItem('$Token')
    };
    this.coreService.fetchUserDetailsByUserName(body).subscribe(details => {
      if (details) {
        this.farmerAddress = details.address;
        this.farmerName = details.name;
        this.farmerMobile = details.mobileNumber;
        let hashStr;
        if (userName !== null) {
          hashStr = Md5.hashStr(userName);
        } else {
          hashStr = Md5.hashStr('');
        }
        this.farmerImageUrl = details.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + details.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
        this.farmerGramaId = details.gramaNil;
        this.commonSignUpService.fetchGramaNiladariById(this.farmerGramaId).subscribe(location => {
          this.farmerLocationEN = location['divisionalSecretariat']['nameEN'];
          this.farmerLocationSI = location['divisionalSecretariat']['nameSI'];
          this.farmerLocationTA = location['divisionalSecretariat']['nameTA'];
        });
      }
    });

    this.productService.fetchOrderByUsername(userName).subscribe(
      ordersList => {
        this.ordersCount = Object.keys(ordersList).length;
      }, () => {
        Swal.close();
      }
    );

    this.productService.fetchSalesByUsername(userName).subscribe(salesList => {
      this.salesCount = Object.keys(salesList).length;
    }, () => {
      Swal.close();
    });

    this.coreService.fetchBankDetails(userName).subscribe(bankDetails => {
      if (bankDetails !== null && bankDetails !== undefined) {
        this.bankAccount = bankDetails['accountNo'];
      } else {
        this.bankAccount = 'XXXXXXXXXX';
      }
    }, () => {
      Swal.close();
    });
  }

  getTotalEarnings(username) {
    this.reportService.fetchTotalEarnings(username).subscribe(results => {
      if (results >= 0) {
        this.isTotalEarnings = true;
      }
      this.totalEarnings = results;
    });
  }

  setProductDetails(data) {
    this.interactionService.setAgentFarmerProductDetails(data);
  }

  passingFarmerUsername() {
    sessionStorage.setItem('FARMER_USERNAME', this.farmerUsername);
    this.interactionService.setFarmerUsername(this.farmerUsername);
    this.manageUsernames();
  }

  manageUsernames() {
    this.interactionService.storeUsernames({
      'lead': this.username,
      'farmer': this.farmerUsername
    });
  }

}
