<app-header></app-header>
<div class="main-container" style="background-color: #f8f9fa">
  <div class="container faq-content">
    <div class="row">
      <div class="col-12">
        <h3>{{'FOOTER.FAQ.HELP_MESSAGE' | translate}}</h3>
        <div class="faq-search">
          <label for="searchInput"></label><input id="searchInput" type="search"
                                                  placeholder="{{'FOOTER.FAQ.FIND_ANSWER' | translate}}"
                                                  aria-describedby="button-addon5" class="form-control searchbox"
                                                  [(ngModel)]="searchText">
          <button (click)="search()" id="button-addon5" type="submit" class="btn btn-primary searchicon">
            <em class="fa fa-search"></em></button>
        </div>
      </div>
    </div>


    <div class="card faq-panel" *ngIf="showCart">
      <div class="card-body">
        <ul class="list-group" *ngIf="!noResult">
          <li class="list-group-item" *ngFor="let faq of faqs; let i = index">
            <p  class="enableCopy" data-toggle="collapse" [attr.href]="'#collapseExample' + i" role="button" aria-expanded="false"
               aria-controls="collapseExample">
              <span *ngIf="activeLang === 'en'" class="enableCopy"> {{faq.questionEN}}</span>
              <span *ngIf="activeLang === 'si'" class="enableCopy"> {{faq.questionSI}}</span>
              <span *ngIf="activeLang === 'ta'" class="enableCopy"> {{faq.questionTM}}</span>
              <span class="floatright">
                  <em class="fa fa-angle-down arrowbtn"></em>
                </span>
            </p>
            <div class="collapse showdown" [id]="'collapseExample' + i">
              <div class="card card-body cardFaqAnswer" [id]="'carLoop' + i">
                <span *ngIf="activeLang === 'en'" class="enableCopy"> {{faq.answerEN}}</span>
                <span *ngIf="activeLang === 'si'" class="enableCopy"> {{faq.answerSI}}</span>
                <span *ngIf="activeLang === 'ta'" class="enableCopy"> {{faq.answerTM}}</span>
              </div>
              <div class="feedback">
                <div class="row">
                  <div *ngIf=" faq.enabled">
                    <span style="margin-left: 1vw;margin-right: 1vw;">{{'FOOTER.FAQ.FAQ_HELPFUL' | translate}}</span>

                    <button style="margin-right: 1vw" id="yesBtn" type="button" class="btn btn-outline-primary tick"
                            (click)="voteYes(faq.id)">
                      <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>

                    </button>
                    <button id="noBtn" type="button" class="btn btn-outline-primary tick" (click)="voteNo(faq.id)">
                      <i class="fa fa-thumbs-o-down" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div *ngIf="!faq.enabled">
                    <span class="feedback-recieved"><em
                      class="fa fa-check-circle greencheck"></em>{{'FOOTER.FAQ.THANK_MESSAGE' | translate}}</span>
                  </div>

                </div>


              </div>
            </div>
          </li>

        </ul>

        <div *ngIf="noResult" class="faqresult">
          <label style="font-size: 25px; margin-bottom: 10px;">{{'FOOTER.FAQ.SEARCH_FAQ' | translate}} '{{searchText}}
            '</label><br>
          <span>{{'FOOTER.FAQ.NO_RESULT' | translate}}</span><br>
          <span>
            {{'FOOTER.FAQ.CHECK_TERM' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div class="sk-cube-grid" *ngIf="!showCart">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>

  </div>
</div>

<app-footer></app-footer>
