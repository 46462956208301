<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row pip-title">
        <div class="col-12">
          <h2><img src="../../../../../assets/images/icons/overview.svg" style="margin-top: -3px;"/>
            {{'MY_ACCOUNT.SALES.OVERVIEW' | translate}}</h2>
        </div>
        <div class="col-12">
          <div class="separator">&nbsp;</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="pic-main-wp">
            <div class="row">
              <div class="col-md-3">
                <a [routerLink]="['/my-account/sales/'+ tenantValue]" href="javascript:void(0)">
                  <div class="pic-wp-2 background">
                    <h6>{{'MY_ACCOUNT.SALES.SALES.SALES' | translate}}</h6>
                    <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}}
                      {{saleAmount | number:'2.2-2'}}</span>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a [hidden]="isLead" [routerLink]="['/my-account/product/'+ tenantValue]" href="javascript:void(0)">
                  <div class="pic-wp-2 background">
                    <h6>{{'MY_ACCOUNT.PRODUCT_COUNT' | translate}}</h6>
                    <span style="font-size: 20px">{{productCount}}</span>
                  </div>
                </a>
                <a *ngIf="isLead" [routerLink]="['/my-account/farmers/'+ tenantValue]" href="javascript:void(0)">
                  <div class="pic-wp-2 background">
                    <h6>{{'MY_ACCOUNT.PRODUCT_COUNT' | translate}}</h6>
                    <span style="font-size: 20px">{{productCount}}</span>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a [routerLink]="['/my-account/purchases/'+ tenantValue]" href="javascript:void(0)">
                  <div class="pic-wp-2 background">
                    <h6>{{'MY_ACCOUNT.TOTAL_PURCHASES' | translate}}</h6>
                    <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}}
                      {{totalPurchases | number:'2.2-2'}}</span>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a [routerLink]="['/my-account/earnings/'+ tenantValue]" href="javascript:void(0)">
                  <div class="pic-wp-2 background">
                    <h6>{{'MY_ACCOUNT.EARNING.EARNING' | translate}}</h6>
                    <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}}
                      {{earnings | number:'2.2-2'}}</span>
                  </div>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="separator">&nbsp;</div>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <h4>{{'MY_ACCOUNT.EARNING.EARNING' | translate}}</h4>
        </div>
        <div class="col-4 text-right">
          <a [routerLink]="['/my-account/earnings/'+ tenantValue]">{{'MY_ACCOUNT.VIEW_ALL' | translate}}<i
            aria-hidden="true" class="fa fa-long-arrow-right"></i></a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3">
          <div class="oea-col green">
            <h5>{{'MY_ACCOUNT.TOTAL_EARNING' | translate}}</h5>
            <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}}
              {{earnings | number:'2.2-2'}}</span>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="oea-col">
            <h5>{{'MY_ACCOUNT.PENDING_PAYMENT' | translate}}</h5>
            <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}} 0.00</span>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <table class="oea-table">
            <tr>
              <td>
                <label class="font-weight-bold">{{'MY_ACCOUNT.CURRENT_BILL_CYCLE' | translate}}</label>
              </td>
              <td>
                1{{'MY_ACCOUNT.ST' | translate}} {{'MY_ACCOUNT.CYCLE' | translate}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <label class="font-weight-bold">{{'MY_ACCOUNT.TOTAL_EARNING' | translate}}</label>
              </td>
              <td>
                <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}}
                  {{earnings | number:'2.2-2'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <label class="font-weight-bold">{{'MY_ACCOUNT.BALANCED_TO_SETTLE' | translate}}</label>
              </td>
              <td>
               <span>{{'CURRENCY.CURRENCY' | translate}}
                 {{referralAmount | number:'2.2-2'}}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3" *ngFor="let sales of salesDTOs">
          <div class="ovps-box" style="padding: 10px;">
            <div class="onpc-img">
              <img alt=""
                   src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ sales.image + '/?X-TenantID=' + this.tenantValue}}"
                   style="border-radius: 10px;width:50px; height: 50px;object-fit: cover;margin-top: 19%;margin-right: 10px;"/>
            </div>
            <div class="details">
              <span>{{sales.id}}</span>
              <h6>{{sales.productName}}</h6>
              <span>{{sales.created | date: 'yyyy/MM/dd'}}</span>
              <span class="price">+ {{'CURRENCY.CURRENCY' | translate}} {{sales.totalPrice | number: '2.2-2'}}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-12 text-right">
          <h6 class="oetw-label">{{'MY_ACCOUNT.EARNINGS_THIS_WEEK' | translate}}
            <span style="font-size: 20px">{{'CURRENCY.CURRENCY' | translate}}
              {{earnings | number:'2.2-2'}}</span>
          </h6>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="separator" style="margin: 25px 0;">&nbsp;</div>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <h4>{{'MY_ACCOUNT.NEW_PRODUCT' | translate}}</h4>
        </div>
        <div class="col-4 text-right">
          <a *ngIf="!isLeadAccount" [routerLink]="['/my-account/product/'+ tenantValue]">{{'MY_ACCOUNT.VIEW_ALL' | translate}}<i
            aria-hidden="true" class="fa fa-long-arrow-right"></i></a>
          <a *ngIf="isLeadAccount" [routerLink]="['/my-account/farmers/'+ tenantValue]">{{'MY_ACCOUNT.VIEW_ALL' | translate}}<i
            aria-hidden="true" class="fa fa-long-arrow-right"></i></a>
        </div>
      </div>

      <div class="row">
        <div *ngFor="let product of productList" class="col-lg-4 col-md-12">
          <div class="onp-cart">
            <div class="onpc-img">
              <img *ngIf="product.type.type === 'SPOT'" alt=""
                   src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ product.spotImages[0].image + '/?X-TenantID=' + this.tenantValue}}"
                   style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
            </div>
            <div class="onpc-detail">
              <h6>{{product.name}}</h6>
              <span>
                <span *ngIf="product.type.type === 'SPOT'">
                     {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{product.unitPrice * product.spotDTOList[0].stock| number:'2.2-2'}}
                </span>
              </span>
            </div>
            <div class="link">
              <i aria-hidden="true" class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <div class="separator" style="margin: 10px 0 25px 0;">&nbsp;</div>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <h4>{{'MY_ACCOUNT.SALES.REVIEWS.REVIEWS' | translate}}</h4>
        </div>
        <div class="col-4 text-right">
          <a [routerLink]="['/my-account/reviews/'+ tenantValue]">{{'MY_ACCOUNT.VIEW_ALL' | translate}}<i
            aria-hidden="true" class="fa fa-long-arrow-right"></i></a>
        </div>
      </div>

      <div class="row">
        <div (click)="loadReviewModal(review)" *ngFor="let review of reviewsData" class="col-lg-6"
             data-target="#productReviews"
             data-toggle="modal">
          <div class="o-review-cart">
            <div class="row">
              <div class="col-8">
                <dl class="orp-info">
                  <dt>
                    <img alt="" src="{{review['userLocationDTO']['imageUrl'] !== null ?
        this.mainUrl.MAIN_URL_FILE + review['userLocationDTO']['imageUrl'] + '/?X-TenantID=' +
        this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStrGet(review.reviewBy) + '?d=mp'}}"
                         style="width: 70px; height: 70px">
                  </dt>
                  <dd>
                    <span>{{review.userLocationDTO.name}}</span>
                    <star-rating checkedcolor="#FFA84C" readonly="true" size="20px" totalstars="5"
                                 uncheckedcolor="#999999"
                                 value="{{review.rating}}"></star-rating>
                  </dd>
                </dl>
              </div>
              <div class="col-4 text-right">
                <span class="date">{{convertDate(review.created)}}</span>
              </div>
              <div class="col-12">
                <p>{{review.comment}}.....<a href="javascript:void(0)">MORE</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<!-- review popup -->
<div aria-hidden="true" class="modal fade npa-model" id="productReviews" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title d-flex align-items-center">
          <i class="material-icons">edit</i> &nbsp;
          Review</h5>
        <button (click)="selectNewProductOptions==0" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-reviews></app-reviews>
      </div>
    </div>
  </div>
</div>
