import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-other-terms',
  templateUrl: './other-terms.component.html',
  styleUrls: ['./other-terms.component.css']
})
export class OtherTermsComponent implements OnInit {
  doc: any;

  constructor() {
  }

  ngOnInit() {
    this.doc = 'src/assets/Agreement for Buyers and Sellers_V2.docx.pdf';
  }

}
