<h3>{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.REGISTER_AS_FARM_COMPANY' | translate}}</h3>
<p>{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FIELD' | translate}}</p>
<form (ngSubmit)="submitFarmer()" [formGroup]="signUpFarmer" autocomplete="off" class="sms-full-width form-box-panel">

  <h5>{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.COMPANY_DETAILS' | translate}}</h5>


  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">
      {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.COMPANY_NAME' | translate}}<sup>*</sup></mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" [(ngModel)]="companyName" matInput
      formControlName="companyName">
    <div *ngIf="submitted && f.companyName.errors" class="inputError">
      <div *ngIf="f.companyName.errors.required">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.COMPANY_NAME_REQUIRED' | translate}}</div>
      <div *ngIf="f.companyName.errors.pattern">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VALID_COMPANY_NAME_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.BUSINESS_REG_NO' | translate}}
      <sup>*</sup>
    </mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.companyRegistrationNumber.errors }"
           [(ngModel)]="companyRegistrationNumber" matInput formControlName="companyRegistrationNumber">
    <div *ngIf="submitted && f.companyRegistrationNumber.errors" class="inputError">
      <div *ngIf="f.companyRegistrationNumber.errors.required">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.BUSINESS_REG_NO_REQUIRED' | translate}}</div>
      <div *ngIf="f.companyRegistrationNumber.errors.pattern">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VALID_BUSINESS_REG_NO_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">
      {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.BUSINESS_MODEL' | translate}}<sup>*</sup></mat-label>
    <mat-select (valueChange)="selectBusinessModel($event)"
      [ngClass]="{ 'is-invalid': submitted && f.businessModel.errors }" formControlName="businessModel">
      <mat-option *ngFor="let model of businessModel" [value]="model.description">
        <span>{{model.description |translate}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.businessModel.errors" class="inputError">
      <div *ngIf="f.businessModel.errors.required" style="font-size: 10px;color: red">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.BUSINESS_MODEL_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <div class="inputbox text-left">
    <br>
    <mat-checkbox (change)="changeRegisteredVat($event)" style="margin: 0 10px 0 0;" [checked]="isRegisteredVat">
    </mat-checkbox>
    <mat-label style="float: none; margin-top: 30px; margin-bottom: 0;" class="floatlbl">
      {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.REGISTER_FOR_VAT' | translate}}</mat-label>
  </div>
  <br>

  <mat-form-field appearance="outline" class="inputbox" *ngIf="isRegisteredVat">
    <mat-label class="floatlbl">
      {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VAT_REG_NO' | translate}}</mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.companyVATRegistrationNumber.errors }"
      [(ngModel)]="companyVATRegistrationNumber" matInput formControlName="companyVATRegistrationNumber">
    <div *ngIf="submitted && f.companyVATRegistrationNumber.errors" class="inputError">
      <div *ngIf="f.companyVATRegistrationNumber.errors.required">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VAT_REG_NO_REQUIRED' | translate}}</div>
      <div *ngIf="f.companyVATRegistrationNumber.errors.pattern">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VALID_VAT_REG_NO_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <hr />

  <h5>{{'ADDRESS_INFO'|translate}}</h5>
  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">
      {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.ADDRESS_1' | translate}}
      <sup>*</sup>
    </mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" [(ngModel)]="address1" matInput
      formControlName="address1">
    <div *ngIf="submitted && f.address1.errors" class="inputError">
      <div *ngIf="f.address1.errors.required">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.POSTAL_ADDRESS_REQUIRED' | translate}}</div>
      <div *ngIf="f.address1.errors.pattern">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VALID_POSTAL_ADDRESS_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.ADDRESS_2' | translate}}
      <sup>*</sup>
    </mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" [(ngModel)]="address2" matInput
      formControlName="address2">
    <div *ngIf="submitted && f.address2.errors" class="inputError">
      <div *ngIf="f.address2.errors.required">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.POSTAL_ADDRESS_REQUIRED' | translate}}</div>
      <div *ngIf="f.address2.errors.pattern">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VALID_POSTAL_ADDRESS_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.PROVINCE' | translate}}<sup>*</sup></mat-label>
    <mat-select  (valueChange)="selectProvince($event)"
      [ngClass]="{ 'is-invalid': submitted && f.province.errors }" formControlName="province">
      <mat-option *ngFor="let province of allProvinceList" [value]="province.id">
        <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.province.errors" class="inputError">
      <div *ngIf="f.province.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.PROVINCE' |
        translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox" *ngIf="this.selectedProvinceId">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.DISTRICT' | translate}}<sup>*</sup></mat-label>
    <mat-select  (valueChange)="selectDistrict($event)"
      [ngClass]="{ 'is-invalid': submitted && f.district.errors }" formControlName="district">
      <mat-option *ngFor="let district of allDistrictList" [value]="district.id">
        <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.district.errors" class="inputError">
      <div *ngIf="f.district.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.DISTRICT' |
        translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field *ngIf="this.selectedDistrictId" appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.CITY' | translate}}<sup>*</sup>
    </mat-label>
    <mat-select  (valueChange)="selectCity($event)"
      [ngClass]="{ 'is-invalid': submitted && f.city.errors }" formControlName="city">
      <mat-option *ngFor="let city of allCities" [value]="city.id" (onSelectionChange)="selectCityEn(city.nameEN)">
        <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.city.errors" class="inputError">
      <div *ngIf="f.city.errors.required" style="font-size: 10px;color: red">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.VALIDATE_CITY_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>


  <mat-form-field appearance="outline" class="inputbox" *ngIf="this.selectedDistrictId">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.DIVISIONAL_SECRETARY' | translate}}<sup>*</sup></mat-label>
    <mat-select (valueChange)="selectDivisional($event)" [ngClass]="{ 'is-invalid': submitted && f.division.errors }"
      formControlName="division">
      <mat-option *ngFor="let division of allDivisionList" [value]="division.id">
        <span *ngIf="activeLang === 'en'">{{division.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{division.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{division.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.division.errors" class="inputError">
      <div *ngIf="f.division.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.DIVISION' |
        translate}}</div>
    </div>
  </mat-form-field>


  <mat-form-field appearance="outline" class="inputbox" *ngIf="this.selectedDivisionalId">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.GRAMA_AREA' | translate}}<sup>*</sup></mat-label>
    <mat-select (valueChange)="selectGrama($event)" [ngClass]="{ 'is-invalid': submitted && f.gramaList.errors }"
      formControlName="gramaList">
      <mat-option *ngFor="let grama of allGramaList" [value]="grama.id">
        <span *ngIf="activeLang === 'en'">{{grama.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{grama.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{grama.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.gramaList.errors" class="inputError">
      <div *ngIf="f.gramaList.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.GRAMA_AREA' |
        translate}}</div>
    </div>
  </mat-form-field>


  <!--Filter Agricultural Service Area-->
  <mat-form-field appearance="outline" class="inputbox" *ngIf="this.selectedDivisionalId">
<!--    <mat-label class="floatlbl">{{'REGISTER.FARMER.GRAMA_AREA' | translate}}<sup>*</sup></mat-label>-->
<!--    <mat-label class="floatlbl">Agricultural Service Area<sup>*</sup></mat-label>-->
    <mat-label class="floatlbl">{{'REGISTER.FARMER.SERVICE_AREA' | translate}}<sup></sup></mat-label>
    <mat-select (valueChange)="selectAgri($event)" [ngClass]="{ 'is-invalid': submitted && f.agriList.errors }"
                formControlName="agriList">
      <mat-option *ngFor="let agri of allAgriList" [value]="agri.id">
        <span *ngIf="activeLang === 'en'">{{agri.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{agri.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{agri.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.agriList.errors" class="inputError">
      <div *ngIf="f.agriList.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.GRAMA_AREA' |
        translate}}</div>
    </div>
  </mat-form-field>


  <!--Filter Instruct Division -->
  <mat-form-field appearance="outline" class="inputbox" *ngIf="this.selectedDivisionalId">
    <!--    <mat-label class="floatlbl">{{'REGISTER.FARMER.GRAMA_AREA' | translate}}<sup>*</sup></mat-label>-->
<!--    <mat-label class="floatlbl">Agrarian Instructional Division<sup>*</sup></mat-label>-->
    <mat-label class="floatlbl">{{'REGISTER.FARMER.INSTRUCTIONAL_DIVISION' | translate}}<sup></sup></mat-label>
    <mat-select (valueChange)="selectIntruct($event)" [ngClass]="{ 'is-invalid': submitted && f.instructList.errors }"
                formControlName="instructList">
      <mat-option *ngFor="let ins of allInstructList" [value]="ins.id">
        <span *ngIf="activeLang === 'en'">{{ins.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{ins.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{ins.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.instructList.errors" class="inputError">
      <div *ngIf="f.instructList.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.GRAMA_AREA' |
        translate}}</div>
    </div>
  </mat-form-field>


  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.POSTAL_CODE' | translate}}<sup>*</sup>
    </mat-label>
    <input autocomplete="off" [(ngModel)]="postalCode" readonly
      [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" formControlName="postalCode" id="postalCode"
      matInput>
    <div *ngIf="submitted && f.postalCode.errors" class="inputError">
      <div *ngIf="f.postalCode.errors.required">
        {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.POSTAL_CODE_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <hr />

  <h5>{{'COMPANY_REGISTRATION_FORM.PERSONAL_INFO' | translate}}</h5>
  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.NAME' | translate}}<sup>*</sup></mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.farmerName.errors }" id="_name" [(ngModel)]="farmerName" matInput
      formControlName="farmerName">
    <div *ngIf="submitted && f.farmerName.errors" class="inputError">
      <div *ngIf="f.farmerName.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
      <div *ngIf="f.farmerName.errors.pattern">{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.NIC' | translate}}<sup>*</sup></mat-label>
    <input (input)="checkExists(farmerNic,'NIC')" (focusout)="checkExists(farmerNic,'NIC')" [(ngModel)]="farmerNic"
      [ngClass]="{ 'is-invalid': submitted && f.farmerNic.errors }" formControlName="farmerNic" id="_nic" matInput>
    <div *ngIf="submitted && f.farmerNic.errors" class="inputError">
      <div *ngIf="f.farmerNic.errors.required">{{'COMMON_VALIDATION.NIC' | translate}}</div>
      <div *ngIf="f.farmerNic.errors.pattern">{{'COMMON_VALIDATION.VALID_NIC' | translate}}</div>
    </div>
    <div *ngIf="incorrectNIC" class="inputError">{{'EXISTS.NIC' |translate}}</div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.MOBILE' | translate}}<sup>*</sup></mat-label>
    <input (input)="checkExists(farmerMobile,'MOBILE')" (focusout)="checkExists(farmerMobile,'MOBILE')"
      [(ngModel)]="farmerMobile" [ngClass]="{ 'is-invalid': submitted && f.farmerMobile.errors }" id="_mobile" matInput
      type="tel" formControlName="farmerMobile">
    <div *ngIf="submitted && f.farmerMobile.errors" class="inputError">
      <div *ngIf="f.farmerMobile.errors.required">{{'COMMON_VALIDATION.MOBILE' | translate}}</div>
      <div *ngIf="f.farmerMobile.errors.pattern">{{'COMMON_VALIDATION.VALID_MOBILE' | translate}}
        {{'COMMON_VALIDATION.VALID_MOBILE_MSG' | translate}}</div>
    </div>
    <div *ngIf="incorrectMobile" class="inputError">{{'EXISTS.MOBILE' |translate}}</div>

  </mat-form-field>

  <hr />

  <h5>{{'REGISTER.FARMER.SYSTEM_CREDENTIALS' | translate}}</h5>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.EMAIL_OPTIONAL' | translate}}</mat-label>
    <input (input)="checkExists(farmerEmail,'EMAIL')" (focusout)="checkExists(farmerEmail,'EMAIL')"
      [(ngModel)]="farmerEmail" formControlName="farmerEmail" matInput type="email">
    <div *ngIf="submitted && f.farmerEmail.errors" class="inputError">
      <div *ngIf="f.farmerEmail.errors.pattern">{{'COMMON_VALIDATION.VALID_EMAIL' | translate}}</div>
    </div>
    <div *ngIf="incorrectEmail" class="inputError">{{'EXISTS.EMAIL' |translate}}</div>

  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox tooltip-bottom flexlist"
    matTooltip="{{'REGISTER.TOOLTIP.USER_NAME' | translate}}">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.DEFINE_A_USERNAME' | translate}}<sup>*</sup></mat-label>
    <input (input)="checkExists(farmerUsername,'USERNAME')" (focusout)="checkExists(farmerUsername,'USERNAME')"
      [(ngModel)]="farmerUsername" [ngClass]="{ 'is-invalid': submitted && f.farmerUsername.errors }"
      formControlName="farmerUsername" matInput type="text">
    <div *ngIf="incorrectUsernamePattern" class="inputError">{{'USERNAME_PATTER'|translate}}</div>
    <div *ngIf="incorrectUsername" class="inputError">{{'EXISTS.USERNAME' |translate}}</div>
  </mat-form-field>

  <hr />

  <h5>{{'REGISTER.FARMER.FARMER_TYPE' | translate}}</h5>

  <div class="row" style="margin-bottom: 20px;">
    <div *ngFor="let farmerType of farmerTypes" class="col-md-12">
      <div  class="inputbox text-left">
        <br>
        <mat-checkbox style="margin: 0 10px 0 0;" (change)="selectFarmerType($event,farmerType.id)"
          [checked]="farmerTypeEnable"></mat-checkbox>
        <mat-label style="float: none; margin-top: 30px; margin-bottom: 0;" class="floatlbl">
          {{farmerType.description | translate}}
        </mat-label>
      </div>
    </div>
  </div>

  <hr />

  <!--  <h5>{{'REGISTER.FARMER.FARMER_TYPE' | translate}}</h5>-->
  <div class="row" style="margin-bottom: 20px;">
    <mat-form-field appearance="outline" class="inputbox">
<!--      <mat-label class="floatlbl">Your Cultivation Area<sup>*</sup></mat-label>-->
      <mat-label class="floatlbl">{{'REGISTER.FARMER.CULTIVATION_AREA' | translate}}<sup>*</sup></mat-label>
      <mat-select (valueChange)="selectCultivationArea($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.cultivationArea.errors }" formControlName="cultivationArea">
        <mat-option *ngFor="let model of cultivationAreaModel" [value]="model.id">
          <span>{{model.description |translate}}</span>
        </mat-option>
      </mat-select>
      <div *ngIf="submitted && f.cultivationArea.errors" class="inputError">
        <div *ngIf="f.cultivationArea.errors.required" style="font-size: 10px;color: red">
          {{'REGISTER.COMPANY_REGISTRATION.FARMING_COMPANY.BUSINESS_MODEL_REQUIRED' | translate}}</div>
      </div>
    </mat-form-field>
  </div>

  <hr/>

  <h5>{{'REGISTER.FARMER.LAND_INFO' | translate}}</h5>

  <div class="row">
    <div class="col-8">
      <mat-form-field appearance="outline" class="inputbox">
        <mat-label class="floatlbl">{{'COMPANY_REGISTRATION_FORM.ENTER_FARMING_ARE' | translate}}</mat-label>
        <input [(ngModel)]="farmerLandArea" [ngClass]="{ 'is-invalid':invalidArea }" formControlName="farmerLandArea"
          id="_landArea" matInput min="0" type="number" (keyup)="clearErr()">
        <div *ngIf="submitted && invalidArea" class="inputErrorDiv">
          <div style="font-size: 10px;color: red;margin-top: 11%;">{{'COMMON_VALIDATION.FARMING_LAND_AREA' | translate}}
          </div>
        </div>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline" class="inputbox">
        <mat-label class="floatlbl">{{'SIGN_UP.LAND_UNIT.LAND_UNIT' | translate}}</mat-label>
        <mat-select formControlName="landUnitTypeList" [ngClass]="{ 'is-invalid': invalidUnit }"
          (selectionChange)="selectLandUnit($event.value)">
          <mat-option *ngFor="let landUnit of landUnitTypes" [value]="landUnit.description">
            {{landUnit.description | translate}}
          </mat-option>
        </mat-select>
        <div *ngIf="submitted && invalidUnit" class="inputErrorDiv">
          <div style="font-size: 10px;color: red;margin-top: 10%;">{{'COMMON_VALIDATION.VALID_UNIT' | translate}}</div>
        </div>
      </mat-form-field>
    </div>
  </div>

  <hr />

  <h5>{{'REGISTER.FARMER.WATER_RESOURCE.WATER_RESOURCE' | translate}}</h5>

  <div class="row" style="margin-bottom: 20px;">
    <div *ngFor="let waterResource of waterResources" class="col-md-12">
      <div class="inputbox text-left">
        <br>
        <mat-checkbox (change)="selectWaterResource($event,waterResource.description)" [checked]="waterResourceEnabled"
          style="margin: 0 10px 0 0;"></mat-checkbox>
        <mat-label class="floatlbl" style="float: none; margin-top: 30px; margin-bottom: 0;">
          {{waterResource.description | translate}}
        </mat-label>
      </div>
    </div>
  </div>

  <hr />

  <div  class="inputbox text-left">
    <br>
    <mat-checkbox (change)="changeLandBeLongs($event)" style="margin: 0 10px 0 0;" [checked]="landBeLongs">
    </mat-checkbox>
    <mat-label style="float: none; margin-top: 30px; margin-bottom: 0;" class="floatlbl">
      {{'COMPANY_REGISTRATION_FORM.LAND_BELONGS' | translate}}</mat-label>
  </div>
  <br>

  <hr />

  <h5>{{'REGISTER.FARMER.NATURE_OF_CULTIVATION.NATURE_OF_CULTIVATION' | translate}}</h5>

  <div class="row" style="margin-bottom: 20px;">
    <div *ngFor="let cultivation of cultivations" class="col-md-12">
      <div  class="inputbox text-left">
        <br>
        <mat-checkbox (change)="selectCultivation($event,cultivation.description)" [checked]="cultivationEnabled"
          style="margin: 0 10px 0 0;"></mat-checkbox>
        <mat-label class="floatlbl" style="float: none; margin-top: 30px; margin-bottom: 0;">
          {{cultivation.description | translate}}
        </mat-label>
      </div>
    </div>
  </div>

  <hr />

  <h5>{{'REGISTER.FARMER.REFERRAL_NUMBER' | translate}}</h5>
  <mat-form-field appearance="outline" class="inputbox tooltip-bottom flexlist"
    matTooltip="{{'REGISTER.TOOLTIP.REFERRAL_NUMBER' | translate}}">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.HELAVIRU_AGENT_REFERRAL_NUMBER' | translate}}</mat-label>
    <input [(ngModel)]="referralNumber" formControlName="referralNumber" matInput type="text">
  </mat-form-field>

  <hr />

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'MY_ACCOUNT.MY_PROFILE.SMS_LANGUAGES' | translate}}<sup></sup></mat-label>
    <mat-select [(ngModel)]="language" (valueChange)="saveLanguage($event)"
                [ngClass]="{ 'is-invalid': submitted && f.language.errors }" formControlName="province">
      <mat-option value="EN">English</mat-option>
      <mat-option value="SI">සිංහල</mat-option>
      <mat-option value="TA">தமிழ்</mat-option>
    </mat-select>
    <div *ngIf="submitted && f.language.errors" class="inputError">
      <div *ngIf="f.language.errors.required" style="font-size: 10px;color: red">language selection is required</div>
    </div>
  </mat-form-field>

  <div  class="inputbox text-left">
    <mat-checkbox (change)="agreeWithAgreement($event)" [checked]="agreeWithUs" style="margin: 0 10px 0 0;">
    </mat-checkbox>
    <mat-label class="floatlbl" style="float: none; margin-top: 30px; margin-bottom: 0;">
      {{'Terms1'|translate}} <a data-target=".bd-example-modal-lg" data-toggle="modal"
        href="">{{'Terms2'|translate}}</a>&nbsp;{{'Terms3'|translate}}
    </mat-label>
  </div>
  <br />
  <button [disabled]="!agreeWithUs" color="accent" mat-raised-button style="font-size: 12px" type="submit"
    class="submit-button mat-raised-button" aria-label="LOG IN">{{'SIGN_UP.SIGN_UP' | translate}}</button>
</form>


<div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" role="dialog"
  tabindex="-1">
  <div class="modal-dialog modal-lg">

    <app-other-terms *ngIf="activeLang==='en'"></app-other-terms>
    <app-other-terms-si *ngIf="activeLang==='si'"></app-other-terms-si>
    <app-other-terms-ta *ngIf="activeLang==='ta'"></app-other-terms-ta>
  </div>
</div>

<!-- OTP modal -->
<button #optModal class="btn btn-primary" data-target="#otpModal" data-toggle="modal" hidden type="button">
</button>

<!-- Modal -->
<div aria-hidden="true" aria-labelledby="otpModal" class="modal fade" data-backdrop="static" id="otpModal" role="dialog"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'SIGN_IN.FORGET_PASSWORD.OTP_HAS_BEEN_SENT' | translate}}
          {{farmerMobile}}</h5><br>
        <button #closeModal (click)="closeOTPModal()" aria-label="Close" class="close" data-dismiss="modal"
          type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{'SIGN_IN.FORGET_PASSWORD.ENTER_OTP_CODE' | translate}}</h3>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6,allowNumbersOnly:true}">
        </ng-otp-input>

        <div style="color: red;margin-top: 5px">{{'SIGN_IN.FORGET_PASSWORD.CODE_EXPIRES_IN' | translate}} : &nbsp;<span
            id="time">03:00</span></div>
        <button (click)="resendOTPCode()" *ngIf="isShowResendButton" class="btn btn-primary customBtn"
          style="margin-top: 12px;" type="button">{{'SIGN_IN.FORGET_PASSWORD.RESEND_CODE' | translate}}
        </button>

        <br>
        <span *ngIf="isInvalid" style="color: red;font-weight: bold;font-size: 14px;">The OTP you entered is
          invalid!</span>
      </div>
      <div class="modal-footer">
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        <button (click)="verifyOTP()" class="btn btn-primary customBtn" type="button">
          {{'SIGN_IN.FORGET_PASSWORD.VERIFY' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
