<app-header></app-header>
<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="w-full-col p-30">
          <div class="pro-container">
            <div class="profile-info">
              <div class="profile-summery">
                <div class="profile-img">
                  <img *ngIf="imgUrl" src="{{imgUrl}}" alt="">
                  <img *ngIf="!imgUrl" src="../../../assets/images/loading/loading.gif" alt="">
                </div>
                <h4>{{userName}}</h4>
                <span class="pro-contact" *ngIf="showDetails">{{this.displayMobile}}</span>
                <span class="pro-contact" *ngIf="!showDetails">+XX XXX XXX XXX</span>
                <span class="pro-country" *ngIf="showDetails">{{displayCountry}}</span>
                <span class="pro-country" *ngIf="!showDetails">XXXXXX</span>

              </div>
              <div class="pro-link">
                <a [routerLink]="['/my-order/'+tenantValue]">
                  <i aria-hidden="true" class="fa fa-shopping-bag"></i> My Orders <i class="fa fa-long-arrow-right"
                                                                                     aria-hidden="true"></i>
                </a>
                <a [routerLink]="['/cart/'+tenantValue]">
                  <i aria-hidden="true" class="fa fa-shopping-cart"></i> My Shopping Cart <i
                  class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </a>

                <a [routerLink]="['/view-bids/'+tenantValue]">
                  <i class="fa fa-bitbucket" aria-hidden="true"></i>View Bids<i class="fa fa-long-arrow-right"
                                                                                aria-hidden="true"></i>
                </a>

                <a [routerLink]="['/award-bid/'+tenantValue]">
                  <i class="fa fa-trophy" aria-hidden="true"></i>View Award Bids<i class="fa fa-long-arrow-right"
                                                                                   aria-hidden="true"></i></a>
                <a *ngIf="FORWARD_ORDER_ENABLE" [routerLink]="['/add-product/'+tenantValue]">
                  <i class="fa fa-plus" aria-hidden="true"></i>Place Forward Order<i class="fa fa-long-arrow-right"
                                                                                     aria-hidden="true"></i>
                </a>
                <a *ngIf="FORWARD_ORDER_ENABLE" [routerLink]="['/product-future/'+tenantValue]">
                  <i class="fa fa-eye" aria-hidden="true"></i>Forward Order Bids <i class="fa fa-long-arrow-right"
                                                                                    aria-hidden="true"></i>
                </a>

              </div>
            </div>

            <div class="pro-right-col my-order">


              <div *ngIf="!bitBn || !bitAcu ">
                <div class="sk-cube-grid">
                  <div class="sk-cube sk-cube1"></div>
                  <div class="sk-cube sk-cube2"></div>
                  <div class="sk-cube sk-cube3"></div>
                  <div class="sk-cube sk-cube4"></div>
                  <div class="sk-cube sk-cube5"></div>
                  <div class="sk-cube sk-cube6"></div>
                  <div class="sk-cube sk-cube7"></div>
                  <div class="sk-cube sk-cube8"></div>
                  <div class="sk-cube sk-cube9"></div>
                </div>
              </div>

              <div class="row">

                <div class="col-md-4" *ngFor="let bn of bitBn">

                  <div class="ps-item" style="width: 100%;">
                    <div class="ps-inner-wrapper">
                      <a [routerLink]="['/product-view/'+tenantValue]"
                         [queryParams]="{id:bn.product.product.id,typeId:bn.product.product.type.id,typeName:bn.product.product.type.type}">
                        <img class="imageTag"
                             [src]="this.mainUrl.MAIN_URL_FILE + bn.product.productImages[0].image+'/?X-TenantID=' + this.tenantValue"
                             alt=""/>
                      </a>
                      <div class="ps-item-inner">
                        <h5 [routerLink]="['/product-view/'+tenantValue]">{{bn.product.product.name}}</h5>
                        <star-rating value="{{bn.product.product.avgRating}}" totalstars="5" checkedcolor="#dfaf00"
                                     uncheckedcolor="black"
                                     size="20px" readonly="true"></star-rating>
                      </div>
                      <dl class="ps-item-date">
                        <dt>Bid Value</dt>
                        <dd>{{bn.bidValue}}</dd>
                      </dl>
                      <button style="margin-top: 1vh; width: 100%;" type="button"
                              class="btn btn-secondary reviewButton"
                              data-target="#bidValueModel" data-toggle="modal" (click)="viewBids(bn.product.sku)">View
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-4" *ngFor="let fn of bitAcu">

                  <div class="ps-item" style="width: 100%;">
                    <div class="ps-inner-wrapper">
                      <a [routerLink]="['/product-view/'+tenantValue]"
                         [queryParams]="{id:fn.product.product.id,typeId:fn.product.product.type.id,typeName:fn.product.product.type.type}">
                        <img class="imageTag"
                             [src]="this.mainUrl.MAIN_URL_FILE + fn.product.productImages[0].image+'/?X-TenantID=' + this.tenantValue"
                             alt=""/>
                      </a>
                      <div class="ps-item-inner">
                        <h5 [routerLink]="['/product-view/'+tenantValue]">{{fn.product.product.name}}</h5>
                        <star-rating value="{{fn.product.product.avgRating}}" totalstars="5" checkedcolor="#dfaf00"
                                     uncheckedcolor="black"
                                     size="20px" readonly="true"></star-rating>
                      </div>
                      <dl class="ps-item-date">
                        <dt>Bid Value</dt>
                        <dd>{{fn.bidValue}}</dd>
                      </dl>
                      <button style="margin-top: 1vh; width: 100%;" type="button"
                              class="btn btn-secondary reviewButton"
                              data-target="#bidValueModel" data-toggle="modal" (click)="viewBids(fn.product.sku)">View
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="foundResult" style="border: 1px solid;height: 100px;width: 100%;">
                  <label style="
                   width: 100%;
                   margin-top: 5vh;
                   margin-left: 1vw;">Oops! That's not available in our catalogue. Please try again with different
                    search criteria.</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- Bid value model -->
<div class="modal fade" id="bidValueModel" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-600 margin-0 feedback" role="document">
    <div class="modal-content">
      <button id="modal-dismiss" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <table class="table cart m-table">
        <thead>
        <tr style=" background: #e6e6e6;">
          <th>Username</th>
          <th>Bid value</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let b1 of allBids1">
          <td>{{b1.userId}}</td>
          <td>{{b1.bidValue}}</td>
        </tr>
        <tr *ngFor="let b2 of allBids2">
          <td>{{b2.userId}}</td>
          <td>{{b2.bidValue}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
