import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../common/common.service';
import {ProductService} from '../../../service/product.service';
import {UserRoleService} from '../../../service/user-role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {MainUrlService} from '../../../service/main-url.service';
import Swal from 'sweetalert2';
import {DeliveryDTO} from '../../../dto/deliveryDTO';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {TransportDetailsDTO} from '../../../dto/TransportDetailsDTO';
import {UserTokenDTO} from '../../../dto/userTokenDTO';
import {CoreService} from '../../../service/core.service';
import {CartService} from '../../../service/cart.service';
import {InteractionService} from '../../../service/interaction.service';
// @ts-ignore
import moment from 'moment';
import {RiyawiruService} from '../../../service/riyawiru.service';

moment().format();

@Component({
  selector: 'app-bids-auctions',
  templateUrl: './my-bids.component.html',
  styleUrls: ['./my-bids.component.css']
})
export class MyBidsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['orderTitle', 'myBidAmount', 'bidPlacementDate', 'bidEndDate', 'status', 'action'];
  tenantValue: any = 'wholesale';
  activeLang: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  selectedOption = 0;
  dto = new DeliveryDTO;
  delivery_price: any;
  isEmptyReviewList: boolean = false;
  delivery_priceFixed: any;
  delivery_desc: any;
  station: any;
  seller: any;
  sellerMobile: any;
  sellerName: any;
  sellerLocation: any;
  isTrain = false;
  isSeller = false;
  imgUrl: any = 'assets/avatar.jpg';
  isBuyer = false;

  rate: any;

  transportList: any[];
  railwayList: any;
  transportID: any;
  dropStation: any;
  prdTransportList = [];
  isSellerFixed = false;
  selectedVehicleId: any;
  riyawiruSelectedVehicleType = [];
  deliveryOptList: any;


  riyaviruModalData: any;
  riyawiruVehicleTypes: any;
  @ViewChild('transportModal', {static: false}) transportModal;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;
  expectationPrice: any;
  isRequiredExpectation: boolean = false;
  isPickVehicle: boolean = false;

  prdImg: any;
  myBidValue: any;
  bidStartDate: any;
  bidEndDate: any;
  productName: any;
  sellerUsername: any;
  userRole: any;
  ecoShopCode: any;

  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              private router: Router,
              private route: ActivatedRoute,
              private riyawiruService: RiyawiruService,
              private http: HttpClient,
              private coreService: CoreService,
              private cartService: CartService,
              private interactionService: InteractionService,
              private settingsService: SystemSettingsService,
              private validationHandlerService: ValidationHandlerService,
              public mainUrl: MainUrlService) {
    this.activeLang = sessionStorage.getItem('LANG');

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });


  }

  ngOnInit() {
    this.loadBidProducts();
    this.getRiyawiruVehicleTypes();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

  loadBidProducts() {
    this.commonService.processing();
    this.dataSource = new MatTableDataSource();
    if(this.isEconomicCenter && this.isEcoFarmerSection){
        this.productService.myEcoBidProductDetails(this.ecoShopCode).subscribe(getMyBidProducts => {
          this.commonResponseBody(getMyBidProducts);
        }, () => {
          Swal.close();
        });

    }else {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.productService.myBidProductDetails(whoAmIDetails['email']).subscribe(getMyBidProducts => {
          this.commonResponseBody(getMyBidProducts);
        }, () => {
          Swal.close();
        });
      });
    }
  }

  commonResponseBody(values){
    let arr = [];
    let bidStartDate;
    for (let item of values) {

      for (let el of item.product['bidDetails']) {
        if (parseFloat(el.bidValue) === parseFloat(item.bidValue)) {
          bidStartDate = el.created;
        }
      }
      arr.push({
        'orderTitle': item.product.product['name'],
        'sku': item.product['sku'],
        'productId': item.product.product['id'],
        'typeId': item.product.product.type.id,
        'typeName': item.product.product.type.type,
        'myBidAmount': item.bidValue,
        'startBidAmount': item.product['startPrice'],
        'bidPlacementDate': bidStartDate,
        'bidEndDate': item.product['bidEndDate'],
        'status': item.status,
        'bidStatus': item.product['status']
      });
    }
    this.dataSource = new MatTableDataSource(arr);
    this.dataSource.paginator = this.paginator;
    Swal.close();
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  getTime(created) {
    return moment(created).format('LTS');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  checkout(sku, myBidValue, bidStartDate, bidEndDate, productName) {
    this.sku = sku;
    this.myBidValue = myBidValue;
    this.bidStartDate = bidStartDate;
    this.bidEndDate = bidEndDate;
    this.productName = productName;

    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.transportModal.nativeElement.click();
      this.isBuyer = false;
      this.isSeller = false;
      this.isSellerFixed = false;
      this.isTrain = false;
      this.cartService.fetchAuctionTransportDetails(this.sku).subscribe(
        (data: TransportDetailsDTO) => {
          this.sellerMobile = data.sellerMobile;
          this.sellerLocation = data.sellerLocation;
          this.sellerName = data.sellerName;
          this.prdImg = data.imgUrl;
          this.sellerUsername = data.seller;
          this.deliveryOptList = data.deliveryOpt;
          if (data.deliveryOpt !== null) {
            for (let opt of data.deliveryOpt) {
              this.userRoleService.fetchUserDetails(data.seller).subscribe(result => {
                this.seller = result.name;
              });
              if (opt.deliveryOpt === '2') {
                this.isBuyer = true;
              }
              if (opt.deliveryOpt === '3') {
                this.getUser();
                this.isSeller = true;
                this.delivery_price = opt.deliveryCharges;
              }
              if (opt.deliveryOpt === '4') {
                this.isTrain = true;
                this.station = opt.station;
              }
              if (opt.deliveryOpt === '5') {
                this.isSellerFixed = true;
                this.delivery_priceFixed = opt.deliveryCharges;
                this.delivery_desc = opt.deliveryDesc;
              }
            }
          }
        }
      );
    } else {
      localStorage.setItem('isLogged', 'False');
      this.interactionService.popUpLoginModal('false');
    }

  }

  userTokenDTO = new UserTokenDTO;

  checkVal($event, sku, val) {
    for (let delivery of this.prdTransportList) {
      if (sku === delivery.sku) {
        let index = this.prdTransportList.indexOf(delivery);
        if (index !== -1) {
          this.prdTransportList.splice(index, 1);
        }
      }
    }

    for (let delivery of this.riyawiruSelectedVehicleType) {
      if (sku === delivery.sku) {
        let index = this.riyawiruSelectedVehicleType.indexOf(delivery);
        if (index !== -1) {
          this.riyawiruSelectedVehicleType.splice(index, 1);
        }
      }
    }

    this.prdTransportList.push({
      'sku': sku,
      'trnId': val
    });

    this.riyawiruSelectedVehicleType.push({
      'sku': sku,
      'value': 0,
      'paymentMethod': 'Cash On Delivery',
      'vehicleType': null
    });

  }

  getUser() {
    this.userTokenDTO.userName = this.sellerUsername;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'assets/avatar.jpg';
      }
    }, () => {
      this.imgUrl = 'assets/avatar.jpg';
    });
  }

  selectRailway(value: any) {

  }

  sku: any;
  encryptInfoTransport_id: any;
  encryptInfoRiyawiruList: any;

  buyNow() {
    if (this.selectedOption > 0) {
      this.dto.sku = this.sku;
      this.dto.trnId = this.selectedOption;
      this.prdTransportList.push(this.dto);
    }
    if (this.prdTransportList.length > 0) {

      this.encryptInfoTransport_id = encodeURIComponent(JSON.stringify(this.prdTransportList));
      this.encryptInfoRiyawiruList = encodeURIComponent(JSON.stringify(this.riyawiruSelectedVehicleType));

      this.router.navigate(['/checkout/' + this.tenantValue], {
        queryParams: {
          transport_id: this.encryptInfoTransport_id,
          riyawiruList: this.encryptInfoRiyawiruList,
          sku: this.sku
        }
      });

    } else {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: this.validationHandlerService.validateDeliveryMethod(sessionStorage.getItem('LANG')),
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  clickRiyaviru(sku: any) {
    this.transportOptionsClose.nativeElement.click();
    this.selectedOption = sku + 1;
  }

  riyawiruModalShow() {
    this.transportModal.nativeElement.click();
  }

  getRiyawiruVehicleTypes() {
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.fetchAllVehicleTypes(createToken['access_token']).subscribe(result => {
        this.riyawiruVehicleTypes = result;
      });
    });
  }

  selectVehicleType(id, sku) {
    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.vehicleType = id;
      }
    }
    this.selectedVehicleId = id;
    this.isPickVehicle = false;
    this.selectedId = id;
  }

  selectedItem: any;
  selectedId: any;

  isSelectedItem(item) {
    return this.selectedItem === item;
  }


  sendBidRequest(sku: any, expectationPrice: any) {

    if (this.selectedVehicleId === undefined) {
      this.isPickVehicle = true;
      return;
    }
    this.isPickVehicle = false;

    if (expectationPrice === undefined || expectationPrice === 0) {
      this.isRequiredExpectation = true;
      return;
    }
    this.isRequiredExpectation = false;

    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.price = expectationPrice;
      }
    }
    this.expectationPrice = undefined;
    this.selectedVehicleId = undefined;
    this.closeRiyawiruModal.nativeElement.click();
  }
}
