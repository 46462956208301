import {Component, OnInit, ViewChild} from '@angular/core';
import {UserRoleService} from '../../../../service/user-role.service';
import {CoreService} from '../../../../service/core.service';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonSignUpService} from '../../../../service/common-sign-up.service';
import {catchError, last, map} from 'rxjs/operators';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {MainUrlService} from '../../../../service/main-url.service';
import {InteractionService} from '../../../../service/interaction.service';
import {of} from 'rxjs';
import Swal from 'sweetalert2';
import {IdentityService} from '../../../../service/identity.service';
import {SmsService} from '../../../../service/sms.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {


  activeTab = 1;
  files: File[] = [];
  imageArray = [];

  userRole: any;
  fName: any;
  tenantValue: any;
  username: any;
  address: any;
  email: any;
  imageUrl: any = 'assets/avatar.jpg';
  mobile: any;
  exmobile: any;
  province: any;
  district: any;
  city: any;
  divisionalSec: any;
  selectedCityId: any;
  gramArea: any;
  postalCode: any;
  accountSettingForm: FormGroup;
  passwordChange: FormGroup;
  submitted = false;
  submittedpw = false;

  provinceList: any;
  districtList: any;
  cityList: any;
  divisionList: any;
  userInfoDistrict: boolean = false;
  userInfoDivisional: boolean = false;
  userInfoCity: boolean = false;
  gramaAreaList: any;
  currentPassword: any;
  newPassword: any;
  confirmPassword: any;

  activeLang: string = 'en';
  incorrectNIC: boolean = false;
  incorrectMobile: boolean = false;
  incorrectEmail: boolean = false;
  incorrectUsername: boolean = false;
  address1: any;
  address2: any;

  emailSt: any;
  mobileSt: any;

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;
  session: any;

  userDetails: any;


  @ViewChild('optModal', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;

  constructor(private userRoleService: UserRoleService,
              private coreService: CoreService,
              private router: Router,
              private validationHandlerService: ValidationHandlerService,
              private interactionService: InteractionService,
              private formBuilder: FormBuilder,
              private http: HttpClient,
              private identityService: IdentityService,
              public mainUrl: MainUrlService,
              private commonSignUpService: CommonSignUpService,
              private smsService: SmsService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.username = localStorage.getItem('USERNAME');
    this.getAllProvince();
    this.validateAccountForm();
    this.getUserInfo();
  }


  ngOnInit() {
    this.activeLang = sessionStorage.getItem('LANG');

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }


  validateAccountForm() {
    this.accountSettingForm = this.formBuilder.group({
      fName: ['', Validators.required],
      userName: [''],
      address1: ['', [Validators.required, Validators.pattern(this.validationHandlerService.addressValidation())]],
      address2: ['', [Validators.pattern(this.validationHandlerService.addressValidation())]],
      mobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.mobileNumberValidation())]],
      province: ['', Validators.required],
      district: ['', Validators.required],
      city: ['', Validators.required],
      email: ['']
    });
  }

  get fControls() {
    return this.accountSettingForm.controls;
  }

  onSelect(event) {
    const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
    if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
      if (this.files.length > 0) {
        this.files = [];
        this.imageArray = [];
      }
      this.uploadImages(event.addedFiles);
      this.files.push(...event.addedFiles);
      if (event.addedFiles[0].size / 1024 / 1024 > 5) {
        this.files.splice(-1, 1);
      }
    }
  }

  getUserInfo() {

    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      // this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      // this.coreService.findRegistrationDetailsByUser(this.username).subscribe(
      //   (data) => {
      //     Swal.close();
      //     this.pendingListDetails = data;
      //   }, () => {
      //     Swal.close();
      //   });

      this.coreService.findUserDetailsByUsername(this.username).subscribe(
        (data) => {

          Swal.close();
          this.userDetails = data;
          this.fName = data['name'];
          this.username = data['username'];
          this.mobile = data['mobile'];
          this.email = data['email'];
          this.imageUrl = data.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + data.imageUrl + '/?X-TenantID=' + this.tenantValue : 'assets/avatar.jpg';
          this.address1 = data['addressesDTO']['addressOne'];
          this.address2 = data['addressesDTO']['addressTwo'];
          this.province = data['addressesDTO']['cities']['agriDistrict']['province']['id'];
          this.district = data['addressesDTO']['cities']['agriDistrict']['id'];
          this.selectedCityId = data['addressesDTO']['cities']['id'];

          this.getAllDistrict(parseInt(this.province), true);

        }, () => {
          Swal.close();
        });

      // let body = {
      //   'userName': whoAmIDetails['email'],
      //   'token': 'Bearer ' + localStorage.getItem('$Token')
      // };

      // this.coreService.fetchUserDetailsByUserName(body).subscribe(result => {
      //   if (result) {
      //     Swal.close();
      //
      //     let hashStr;
      //     if (whoAmIDetails['email'] !== null) {
      //       hashStr = Md5.hashStr(whoAmIDetails['email']);
      //     } else {
      //       hashStr = Md5.hashStr('');
      //     }
      //     this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
      //   }
      // }, () => {
      //   Swal.close();
      //   let hashStr;
      //   if (whoAmIDetails['email'] !== null) {
      //     hashStr = Md5.hashStr(whoAmIDetails['email']);
      //   } else {
      //     hashStr = Md5.hashStr('');
      //   }
      //   this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
      // });
      // }, () => {
      //   Swal.close();
      // });


    }
    // this.userRoleService.whoAmI().subscribe(userDetails => {
    //   this.userName = userDetails['email'];
    //   let token = localStorage.getItem('$Token');
    //   let body = {
    //     'userName': userDetails['email'],
    //     'token': token
    //   };
    //
    //   this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
    //     if (userResult !== null) {
    //       this.fName = userResult['name'];
    //       let hashStr;
    //       if (userDetails['email'] !== null) {
    //         hashStr = Md5.hashStr(userDetails['email']);
    //       } else {
    //         hashStr = Md5.hashStr('');
    //       }
    //       this.imgUrl = userResult.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + userResult.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
    //       this.address = userResult['address'];
    //       this.address1 = userResult['address1'];
    //       this.address2 = userResult['address2'];
    //       this.mobile = userResult['mobileNumber'];
    //       this.exmobile = userResult['mobileNumber'];
    //       this.email = userResult['email'];
    //       this.mobileSt = userResult['mobileNumber'];
    //       this.emailSt = userResult['email'];
    //       this.province = parseInt(userResult['province']);
    //       if (Object.keys(this.provinceList).length > 0) {
    //         for (let pro of this.provinceList) {
    //           if (this.province === parseInt(pro.id)) {
    //             this.district = parseInt(userResult['district']);
    //             this.divisionalSec = parseInt(userResult['divisional_secretary']);
    //             this.gramArea = parseInt(userResult['grama_area']);
    //           }
    //         }
    //       }
    //
    //       this.postalCode = userResult['postalCode'];
    //       this.userRole = userResult['userRole'];
    //     }
    //   });
    // });


  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.provinceList = result;
      }
    });
  }

  selectProvince(event) {
    this.district = undefined;
    this.districtList = [];
    this.selectedCityId = undefined;
    this.userInfoDistrict = false;
    this.userInfoCity = false;

    this.province = parseInt(event.target.value);
    this.getAllDistrict(event.target.value, false);
  }

  getAllDistrict(id, status) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.districtList = result;
        this.userInfoDistrict = status;
        if (Object.keys(this.districtList).length > 0 && this.userInfoDistrict) {
          for (let dis of this.districtList) {
            if (this.district === parseInt(dis.id)) {
              this.userInfoDistrict = false;
              this.getAllCities(parseInt(this.district), true);
            }
          }
        }
      }
    });
  }

  selectDistrict(event) {
    this.selectedCityId = undefined;
    this.cityList = [];
    this.district = parseInt(event.target.value);
    this.getAllCities(parseInt(this.district), true);
  }


  getAllCities(id, status) {
    this.commonSignUpService.fetchAllCitiesByDistrictId(id).subscribe((result) => {
      if (result) {
        this.cityList = result;
        this.userInfoCity = status;
        if (Object.keys(this.cityList).length > 0 && this.userInfoCity) {
          for (let city of this.cityList) {
            if (this.divisionalSec === parseInt(city.id)) {
              this.userInfoCity = false;
            }
          }
        }
      }
    });
  }

  selectDivisional(event: any) {
    this.gramArea = undefined;
    this.divisionalSec = parseInt(event.target.value);
    this.getAllGramaNiladari(event.target.value);

  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.gramaAreaList = result;
      }
    });
  }

  selectGrama(event: any) {
    this.gramArea = event.target.value;
  }


  submitSetting() {
    this.submitted = true;
    if (this.accountSettingForm.invalid) {
      return;
    }
    if (this.exmobile !== this.mobile) {
      this.loading();
      let _requestOTP = {
        'telNo': [
          this.mobile
        ],
        'username': this.username,
        'code': 'OTP'
      };
      this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
        Swal.close();
        if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
          this.OTPValidation(60 * 3, document.getElementById('time'));
        }
        if (this.isOTPModalClose) {
          clearInterval(this.session);
          document.getElementById('time').textContent = '03:00';
          this.OTPValidation(60 * 3, document.getElementById('time'));
        }


        this.optModal.nativeElement.click();
      }, () => {
        Swal.close();
      });
    } else {
      this.updateSetting();
    }

  }

  updateSetting() {

    let imageList = null;
    if (this.imageArray.length >= 1) {
      for (let image of this.imageArray) {
        imageList = image['image'];
      }
    }

    this.address = this.address1 + ' ' + this.address2;

    // this.userRoleService.whoAmI().subscribe(userResult => {
    //   let userLocationDTO = {
    //     'userName': userResult['email'],
    //     'name': this.fName,
    //     'mobileNumber': this.mobile,
    //     'email': this.email,
    //     'imageUrl': imageList,
    //     'province': this.province,
    //     'district': this.district,
    //     'divisional_secretary': this.divisionalSec,
    //     'gramaNil': this.gramArea,
    //     'address': this.address,
    //     'address1': this.address1,
    //     'address2': this.address2,
    //     'userRole': this.userRole,
    //   };

    let userLocationDTO = {
      'username': this.username,
      'mobile': this.mobile,
      'name': this.fName,
      'nic': this.userDetails['nic'],
      'email': this.email,
      'imageUrl': imageList,
      'type': this.userDetails['type'],
      'addressesDTO': {
        'id': this.userDetails['addressesDTO']['id'],
        'username': this.username,
        'name': this.fName,
        'status': true,
        'addressOne': this.address1,
        'addressTwo': this.address2,
        'addressType': {
          'id': 1,
          'typeEN': 'Billing'
        },
        'country': {
          'id': 1
        },
        'cities': {
          'id': this.selectedCityId,
          'agriDistrict': {
            'id': this.district,
            'province': {
              'id': this.province
            }
          }
        },
        'postalCode': this.userDetails['postalCode'],
        'activation': true
      }
    };

    this.userRoleService.updateUserSetting(userLocationDTO).subscribe(result => {
      Swal.close();
      if (Object.keys(result).length > 0) {
        this.files = [];
        this.interactionService.setProfileInfo(true);

        // this.userRoleService.updateBoardingUserSetting(userLocationDTO).subscribe(() => {
        // });

        Swal.fire({
          position: 'center',
          type: 'success',
          text: this.validationHandlerService.accountSettingMessage(sessionStorage.getItem('LANG')),
          customClass: 'swal-text',
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        }).then(() => {
          this.router.navigate(['/my-account/my-profile/wholesale']);
        });
      } else {
        Swal.fire({
          type: 'error',
          title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        });
      }
    });
    // }, () => {
    //   Swal.close();
    // }, () => {
    //   this.getUserInfo();
    // });

  }


  updateNewPassword() {
    this.loading();
    this.userRoleService.whoAmI().subscribe(userResult => {
      let body = {
        'id': userResult['id'],
        'oldPassword': this.currentPassword,
        'password': this.newPassword,
        'username': userResult['email']
      };
      this.userRoleService.updatePassword(body).subscribe(() => {
          Swal.close();
          Swal.fire({
            position: 'center',
            type: 'success',
            text: 'Successfully Updated',
            customClass: 'swal-text',
            showConfirmButton: true,
            confirmButtonText: 'ok'
          });

        },
        (error1 => {
          Swal.close();
          if (error1.error.message == 'WRONG_PW') {
            Swal.fire({
              type: 'warning',
              title: 'Wrong Current Password',
              showConfirmButton: true,
              confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
            });
          } else {
            Swal.fire({
              type: 'error',
              title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
              showConfirmButton: true,
              confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
            });
          }
        })
      );

    });
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      width: '300px',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  uploadImages(value) {
    this.loading();
    let formData = new FormData();

    if (value[0].size > 5006708) {
      Swal.close();
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });

      return false;
    }
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        //Remove last image from files array
        this.files.splice(-1, 1);

        Swal.fire({
          title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
        });

        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          this.imageUrl = this.mainUrl.MAIN_URL_FILE + event.body.message + '/?X-TenantID=' + this.tenantValue;
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

  removeCheckNIC() {
    this.incorrectNIC = false;
  }

  removeCheckUsername() {
    this.incorrectUsername = false;
  }

  removeCheckEmail() {
    this.incorrectEmail = false;
  }

  removeCheckMobile() {
    this.incorrectMobile = false;
  }

  checkExists(value, type) {

    switch (type) {

      case 'MOBILE':
        if (value !== undefined || true) {
          if (value !== this.mobileSt) {
            this.identityService.checkContactNoExists(value).subscribe(isExists => {
              if (isExists['message'] === 'CONTACT_NO_NOT_EXISTS') {
                this.incorrectMobile = false;
                this.accountSettingForm.updateValueAndValidity();
              } else if (isExists['message'] === 'CONTACT_NO_EXISTS') {
                this.incorrectMobile = true;
                this.accountSettingForm.controls['mobile'].setErrors(value);
              }
            });
          } else {
            this.incorrectMobile = false;
            this.accountSettingForm.updateValueAndValidity();
          }
        }
        break;

      case 'NIC':
        if (value !== undefined || true) {
          this.identityService.checkNICExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'NIC_NOT_EXISTS') {
              this.incorrectNIC = false;
              this.accountSettingForm.updateValueAndValidity();
            } else if (isExists['message'] === 'NIC_EXISTS') {
              Swal.fire({
                position: 'center',
                type: 'error',
                text: this.validationHandlerService.commonExistsMessage(sessionStorage.getItem('LANG')),
                showConfirmButton: true,
                confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
              }).then(() => {
                this.accountSettingForm.reset();
                this.interactionService.callSignUpBackButton(true);

              });
            }
          });
        }

        break;

      case 'USERNAME':
        if (value !== undefined || true) {
          this.identityService.checkUsernameExists(value).subscribe(isExists => {
            if (isExists['message'] === 'USERNAME_NOT_EXISTS') {
              this.incorrectUsername = false;
              this.accountSettingForm.updateValueAndValidity();
            } else if (isExists['message'] === 'USERNAME_EXISTS') {
              this.incorrectUsername = true;
              this.accountSettingForm.controls['username'].setErrors(value);
            }
          });
        }
        break;

      case 'EMAIL':
        if (value !== undefined || true) {
          if (value !== this.emailSt) {
            this.identityService.checkEmailExists(value).subscribe(isExists => {
              if (isExists['message'] === 'EMAIL_NOT_EXISTS') {
                this.incorrectEmail = false;
                this.accountSettingForm.updateValueAndValidity();
              } else if (isExists['message'] === 'EMAIL_EXISTS') {
                this.incorrectEmail = true;
                this.accountSettingForm.controls['email'].setErrors(value);
              }
            });
          } else {
            this.incorrectEmail = false;
            this.accountSettingForm.updateValueAndValidity();
          }
        }
        break;

    }

  }

  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    this.session = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(this.session);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    this.isInvalid = false;
    this.ngOtpInputRef.setValue('');
    clearInterval();
  }

  resendOTPCode() {
    this.ngOtpInputRef.setValue('');
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time').textContent = '03:00';
    this.OTPValidation(60 * 3, document.getElementById('time'));
    this.isShowResendButton = false;
  }

  verifyOTP() {
    this.isLoading = true;
    let verifyOTPBody = {
      'username': this.username,
      'otp': this.OTP_CODE
    };
    this.smsService.verifyOTPCode(verifyOTPBody).subscribe(isVerify => {
      if (isVerify) {
        this.isInvalid = false;
        this.isLoading = false;
        this.closeModal.nativeElement.click();
        this.updateSetting();
        this.closeOTPModal();
      } else {
        this.isInvalid = true;
        this.isLoading = false;
      }
    }, () => {
      this.isInvalid = true;
      this.isLoading = false;
    });
  }

  selectCity(event) {
    this.selectedCityId = parseInt(event.target.value);

  }
}
