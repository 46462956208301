export class SalesDTO {
  constructor(
    public id: any,
    public productName: any,
    public mainCategory: any,
    public totalPrice: any,
    public address: any,
    public created: any,
    public rowId: any,
    public status: any,
    public transactionId: any,
    public image:any,
    public userName: any,
    public quantity: any,
    public unitPrice: any,
    public buyerName: any,
    public seller: any,
    public farmerType: any,
    public productType: any,
    public productUnitType:any

  ) {
  }

}
