import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-riyaviru-terms',
  templateUrl: './riyaviru-terms.component.html',
  styleUrls: ['./riyaviru-terms.component.css']
})
export class RiyaviruTermsComponent implements OnInit {
  doc: any;

  constructor() {
  }

  ngOnInit() {
  }

}
