import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MainUrlService} from './main-url.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(private http: HttpClient, private  mainUrl: MainUrlService) {

  }

  whoAmI(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH + '/users/whoAmI');
  }

  fetchUserDetails(username): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_ONBOARDING + '/userDetails/getByUserName/' + username);
  }

  saveUser(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH + '/users/save', body);
  }

  fetchRoles(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH + '/roles/all');
  }

  fetchPrivilegesByUserId(userId) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH + '/privileges/getByUser/' + userId);
  }

  fetchRefNo(userName): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/userDetails/getRefNo/' + userName);
  }

  fetchUserAndLocation(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH + '/users/location', body);
  }

  updateUserSetting(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/userDetails/updateUserProfile', body);
  }

  fetchAllRejectedUsers(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/fetchAllUser/Reject');
  }

  fetchBayerLocation(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/fetchAddress/' + username);
  }

  updatePassword(body): Observable<any>{
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH + '/users/updatePassword', body);
  }

  resetPassword(body): Observable<any>{
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH + '/auth/user/changePassword', body);
  }

  fetchAddresses(username): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_ONBOARDING + '/address/fetchAddressByUsername/' + username);
  }

  updateBoardingUserSetting(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/updateUserDetails', body);
  }
}
