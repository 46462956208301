import {Component, OnInit} from '@angular/core';
import {CoreService} from '../service/core.service';
import {Title} from '@angular/platform-browser';
import {InteractionService} from '../service/interaction.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqs: any;
  showVote = true;
  searchText: any;
  noResult = false;
  showCart = false;
  tenantValue: any;
  activeLang: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private titleService: Title,
              private core_service: CoreService,
              private interactionService: InteractionService) {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit() {
    this.loadAllFaqs();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.loadAllFaqs();
      }
    });
  }

  loadAllFaqs() {
    this.core_service.fetchAllFaqs().subscribe(fetchAllFaqs => {
      if (Object.keys(fetchAllFaqs).length > 0) {
        this.showCart = true;
        let faqList = [];
        for (let faq of fetchAllFaqs) {
          if (faq['enabled']) {
            faqList.push(faq);
          }
        }
        this.faqs = faqList;
      } else {
        this.showCart = false;
      }
    });
  }

  voteYes(id) {
    this.showVote = false;
    for (const value of this.faqs) {
      if (value.id === id) {
        value.enabled = false;
      }
    }


    this.core_service.helpfulFaq(id).subscribe(() => {

    });
  }

  voteNo(id) {
    this.showVote = false;
    for (const value of this.faqs) {
      if (value.id === id) {
        value.enabled = false;
      }
    }
    this.core_service.notHelpfulFaq(id).subscribe(() => {

    });
  }

  search() {
    if (Object.keys(this.searchText).length > 0) {
      this.core_service.searchFaq(this.searchText).subscribe(result => {
        if (Object.keys(result).length > 0) {
          this.faqs = result;
        } else {
          this.noResult = true;
        }
      });
    } else {
      this.noResult = false;
      this.loadAllFaqs();
    }

  }

}
