import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MainUrlService} from './main-url.service';
import {OrderRequestDTO} from '../dto/orderRequestDTO';
import {RatingDTO} from '../dto/ratingDTO';
import {PurchaseStatusDTO} from '../dto/purchase-statusDTO';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TransactionDTO} from '../dto/transactionDTO';
import {UserTokenDTO} from '../dto/userTokenDTO';
import {OrderRejectDTO} from '../dto/orderRejectDTO';
import {saveAs} from 'file-saver';

const PRODUCT_SPOT_URL = '/spot';
const CATEGORY_URL = '/mainCategory';
const SUB_CATEGORY_URL = '/newSubCategory';
const ACU_PRODUCT = '/forwardacu';
const BN_PRODUCT = '/forwardbn';
const BID_ACU_PRODUCT = '/forwardAcuBid';
const BID_BN_PRODUCT = '/forwardBnBid';
const PRODUCT_COMMENT = '/comment';
const PRODUCT_COMMENT_REPLY = '/commentreply';
const CART = '/cart';
const FUTURE_PRODUCT = '/future';
const PRODUCT_FORWARD_ACU = '/forwardacu';
const REPORT_PRODUCT = '/report';
const PURCHASE = '/purchase';
const REPORT_SEARCH = '/productSearch';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public model = new EventEmitter<NgbModal>();

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  createOrderRequestBid(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/save',body);
  }

  fetchOrderRequestByProductId(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/orderRequest/getOrderRequestById/' + id);
  }

  fetchOrderRequestGetDefaultPickupAddress(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/address/getDefaultPickupAddress/'+username);
  }

  fetchOrderRequestByProductStatus(status): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/orderRequest/getAllActiveRequest/' + status);
  }

  createOrderRequest(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/orderRequest/save', body);
  }

  currentCommission(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/commission/getCurrentCommissionTerm');
  }

  updateTransportMethod(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/setTransportId', body);
  }

  productImageBySku(sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/image/getProductImageBySku/' + sku);
  }

  saveRiyawiruBid(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/riyaviru/makeBid', body);
  }

  fetchBidAwardBySku(username, sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/checkOut/' + username + '/' + sku);
  }

  fetchOrderRequestBidAwardBySku(username, sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/checkout/' + sku);
  }

  saveAuctionCheckoutDetails(sku, username, transactionId): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/saveCheckout/' + sku + '/' + username + '/' + transactionId, '');
  }

  saveOrderRequestCheckoutDetails(sku, username, transactionId): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/saveCheckout/' + sku + '/' + username + '/' + transactionId, '');
  }

  addPenalty(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/addPenalty', body);
  }

  fetchAllRejectedOrder(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getStatus/REJECTED');
  }

  getRejectProjectDetails(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getDetailById/' + id);
  }

  getPurchaseProductDetails(transactionId): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/fetchTransactionDetails/' + transactionId);
  }

  fetchAllSubCategoryByMainCategory(code): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/newSubCategory/getAll/' + code);
  }

  fetchAllDiscountSpotProducts(value, profileCode, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/discounts/' + profileCode + '?page=0&size=' + value + '',
    );
  }

  fetchAllNewArrivalSpotProducts(value, profileCode, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/recent/' + profileCode + '?page=0&size=' + value + '',
    );
  }

  fetchAllCategory(profileCode, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + CATEGORY_URL + '/all/' + profileCode,
    );
  }

  fetchSpotProduct(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/getProduct/id/' + id,
    );
  }

  fetchForwardContract(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/future/getProduct/' + id,
    );
  }

  fetchAuctionProduct(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/getProduct/' + id);
  }

  getActiveProfileCode(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/profile/getActiveProfile',
    );
  }

  fetchSelectAcuProduct(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + ACU_PRODUCT + '/getProduct/' + id,
    );
  }

  fetchSelectBnProduct(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BN_PRODUCT + '/getProduct/' + id,
    );
  }

  createBidBn(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_BN_PRODUCT + '/add', body,
    );
  }

  createBidAcu(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_ACU_PRODUCT + '/add', body,
    );
  }

  getBidBnDetails(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_BN_PRODUCT + '/getBidDetails/' + id,
    );
  }

  getBidAcuDetails(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_ACU_PRODUCT + '/getBidDetails/' + id,
    );
  }

  getTopBidAcuDetails(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/getTopBidDetails/' + id,
    );
  }

  getBidBnDetail(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_BN_PRODUCT + '/getBidDetail/' + id,
    );
  }

  getBidAcuDetail(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_ACU_PRODUCT + '/getBidDetail/' + id,
    );
  }

  createComment(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_COMMENT + '/add', body,
    );
  }

  fetchAllComments(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_COMMENT + '/all/' + id,
    );
  }

  replayComment(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_COMMENT_REPLY + '/add', body,
    );
  }

  fetchAllReply(commentId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_COMMENT_REPLY + '/getCommentReplies/' + commentId,
    );
  }

  cartMerge(user, owner, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + CART + '/mergeCart/' + user + '/' + owner, '',
    );
  }

  fetchAllSubCategory(category): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + SUB_CATEGORY_URL + '/getAll/' + category,
    );
  }

  createFutureProduct(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + FUTURE_PRODUCT + '/add', body,
    );
  }

  fetchAllAcuBidByUsername(username, tenantValue) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_ACU_PRODUCT + '/getBidDetail/' + username,
    );
  }

  fetchAllBnBidByUsername(username, tenantValue) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + BID_BN_PRODUCT + '/getBidDetail/' + username,
    );
  }

  fetchAllFutureProduct(tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + FUTURE_PRODUCT + '/all',
    );
  }

  fetchSelectFutureProduct(id, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + FUTURE_PRODUCT + '/getProduct/' + id,
    );
  }

  futureProductBid(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/futurebid/add', body,
    );
  }


  filterActiveProduct(body, profileCode, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/search/' + profileCode, body,
    );
  }


  getHighest(tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/spot/getHighest',
    );
  }

  getHighestForwardBnBid(productId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardBnBid/currentHighest/' + productId,
    );
  }

  getHighestForwardAcuBid(productId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/currentHighest/' + productId,
    );
  }


  getProductViews(sku, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productViews/getProductViews/' + sku,
    );
  }

  addProductView(sku, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productViews/add/' + sku, '',
    );
  }


  getActiveProfile(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/profile/getActiveProfile',
    );
  }

  getAllMainCategoryByProfileId(profileCode): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/mainCategory/all/' + profileCode,
    );
  }

  getAllTreeListByProfileId(profileCode, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/mainCategory/all/treeList/' + profileCode,
    );
  }

  getAllSubCategoryByProfileId(profileCode, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/newSubCategory/all/' + profileCode,
    );
  }

  getAllSpecByProfileId(profileCode, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/newSpec/all/' + profileCode,
    );
  }

  getSpecsByProductId(proId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/sku/getSpecs/productID/' + proId,
    );
  }

  getSpecsOrderBySubCategory(subId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/newSpecOrder/all/' + subId,
    );
  }

  checkSpecAvailability(body, profileCode, ProductId, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/cart/checkSpecAvailability/' + profileCode + '/' + ProductId, body,
    );
  }

  historyByUserOrders(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/fetchTransactionDetailsByUser/' + username);
  }

  historyByEcoUserOrders(shopCode): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/fetchTransactionDetailsByEcoShop/' + shopCode);
  }

  historyByUser(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/historyByUser/' + username);
  }

  historyDetailsByTransactionID(transactionId): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/fetchTransactionDetails/' + transactionId);
  }

  historyDetailsByTransactionIdNUsername(transactionId, username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getTransaction/' + transactionId + '/' + username);
  }

  feedback(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/feedback/add', body,
    );
  }

  createSportProduct(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/add', body);
  }

  createAuctionProduct(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_FORWARD_ACU + '/add', body);
  }

  updateAuctionProduct(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/update', body);
  }


  fetchAllBidAwards(username, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/bidAward/showByUser/' + username,
    );
  }

  fetchAllAdditionalMedataByProductId(productId, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productMetaData/getEnable/' + productId,
    );
  }

  getProductDetailsBySku(sku, tenantValue): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/spot/getProduct/sku/' + sku,
    );
  }

  fetchAllAdverts(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/adverts/fetchActiveAdverts');
  }

  fetchAllPagination(body, profileCode, page, size): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/search/' + profileCode + '?page=' + page + '&size=' + size, body);
  }


  slidersByCategory(category, profileCode, page, size): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/slidersByCategory/' + category + '/' + profileCode + '?page=' + page + '&size=' + size);
  }


  fetchAllMainCategory(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/mainCategory/all');
  }

  getActiveSKU(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/profile/getActiveProfile');

  }

  saveOrderRequest(requestDto: OrderRequestDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/orderRequest/save', requestDto);
  }

  reportProduct(body, tenantValue): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + REPORT_PRODUCT + '/saveReport', body);
  }

  getAllReportProduct(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + REPORT_PRODUCT + '/getAllReports');
  }

  updateStatusOfReport(body): Observable<any> {
    return this.http.put<any>(this.mainUrl.MAIN_URL_PRODUCT + REPORT_PRODUCT + '/updateStatus', body);
  }

  saveFeedBackOfReport(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + REPORT_PRODUCT + '/saveFeedback', body);
  }

  saveRating(dto: RatingDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/rating/add', dto,
    );
  }

  updateRating(dto: RatingDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/rating/update', dto);
  }

  fetchSalesByUsername(username: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PURCHASE + '/getDetailsBySeller/' + username);
  }

  fetchSalesByLeadCode(leadCode: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PURCHASE + '/getLeadSales/' + leadCode);
  }

  fetchEcoShopSales(ecoCode: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PURCHASE + '/getEcoShopSales/' + ecoCode);
  }

  updateTransaction(purchaseStatusDTO: PurchaseStatusDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PURCHASE + '/updateStatus', purchaseStatusDTO);
  }

  fetchAllProductByUsername(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + REPORT_SEARCH + '/search/001?page=0&size=500', body);
  }

  fetchAllProductByUser(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + REPORT_SEARCH + '/getByUser/001?page=0&size=500', body);
  }

  fetchSingleSPOTView(sku: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/getProduct/sku/' + sku);
  }

  fetchSingleAuctionView(sku: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/getProductBySku/' + sku);
  }

  updateSportProduct(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/updateSpot', body);
  }

  updateMySpotProduct(status, sku): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + PRODUCT_SPOT_URL + '/updateMySpotProduct/' + status + '/' + sku, '');
  }

  updateMyAuctionProduct(status, sku): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/updateMyAuctionProduct/' + status + '/' + sku, '');
  }

  printBuyerReport(transactionDTO: TransactionDTO): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_REPORT + '/buyer/buyerReport', transactionDTO, {responseType: 'arraybuffer'});
  }

  getHistory(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/history/all');
  }

  printHistoryReport(transactionDTO: TransactionDTO): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_REPORT + '/history/historyReport', transactionDTO, {responseType: 'arraybuffer'});
  }

  fetchProductCount(username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productStatistics/fetchProduct/' + username);
  }

  fetchRating(username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/review/avarageRating/' + username);
  }

  getTransactionDetails(userTokenDTO: UserTokenDTO) {
    return this.http.post<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getAllSettlement', userTokenDTO);
  }

  markAsTransfer(id: any): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/transferred', id);
  }

  printTransactionReport(transactionDTO: TransactionDTO): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/printTransDetailReport', transactionDTO, {responseType: 'arraybuffer'});
  }

  fetchAllRatingByUsername(username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/rating/getRatingDetailsByUser/' + username);
  }

  fetchAllRatingByEcoShopCode(shopCode: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/rating/getRatingDetailsByEcoShop/' + shopCode);
  }

  fetchOrderByUsername(username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getOrders/' + username);
  }

  fetchOrderByLeadCode(leadCode: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getOrdersByLead/' + leadCode);
  }

  fetchOrderByEcoShopCode(ecoShop: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getOrdersByEcoShop/' + ecoShop);
  }

  fetchOrderByID(id: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getOrdersById/' + id);
  }

  fetchSeller(user: UserTokenDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH + '/users/getUser', user);
  }

  fetchRate(user: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/rating/getMyReview/' + user);
  }

  fetchBlockListing(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getBlockedPrd');
  }

  fetchPickupAddress(userName: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/fetchAddress/' + userName);
  }

  changeProductStatus(id: any, status: any): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productReview/updateProductStatus/' + id + '/' + status, '');
  }

  leadFarmersProductList(leadCode: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/getProductsByLead/' + leadCode);
  }

  getFarmetEarnings(tenantId: String) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getFarmerEarnings/' + tenantId);
  }

  getRefEarnings() {
    return this.http.get<any>(this.mainUrl.MAIN_URL_REPORT + '/static/getAllRefEarnings');
  }

  purchaseStatusUpdate(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/updateStatus/', body);
  }

  getOrderDetails() {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/getAllOrder');
  }

  fetchAllBidProductByUsername(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/find/' + username);
  }

  fetchAllOrderRequestBidProductByUsername(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/findAllByBidder/' + username);
  }

  myBidProductDetails(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/getBidDetail/' + username);
  }

  myEcoBidProductDetails(ecoShop): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/getBidDetailByEcoShop/' + ecoShop);
  }

  myBidOrderRequestProductDetails(sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/findAllByProduct/' + sku);
  }


  myOrderRequestBidProductDetails(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/orderRequest/findByListed/' + username);
  }

  findAllMyOrderRequestBids(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/findAllByBidder/' + username);
  }

  findAllMyEcoOrderRequestBids(ecoCode): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/findAllByEcoShop/' + ecoCode);
  }

  declineBid(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/declineBid/' + id, '');
  }

  awardBid(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/awardBid/' + id, '');
  }

  awardOrderRequestBid(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/statusChange/' + id +'/AWARDED', '');
  }

  declineOrderRequestBid(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/OrderRequestBid/statusChange/' + id +'/DECLINED', '');
  }

  getPendingProducts(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/fetchAllPendingProducts');
  }

  getPendingReviews(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/rating/getAllRating');
  }

  activateProduct(id: any) {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/activateProduct/' + id, '');
  }

  rejectProduct(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/rejectProduct/', body);
  }

  fetchRejectReason(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/fetchAllRejectCategory',);
  }

  rejectOrder(orderRejectDTO: OrderRejectDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/rejectOrder/', orderRejectDTO);
  }

  purchaseOrderStatusUpdate(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/updateOrderStatus', body);
  }

  generateExcelFile(body, profileCode, page, size): any {
    return this.http.get(this.mainUrl.MAIN_URL_PRODUCT + '/productSearch/generateExcel/' + profileCode, {responseType: 'blob'})
      .subscribe(res => {
        const blobb = new Blob([res], {type: res.type});
        saveAs(blobb, 'users.xls');
      }, () => {
        alert('Sorry file not available!');
      });
  }

  fetchAllReviewBySku(sku): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_CORE + '/rating/getRatingBySku/' + sku);
  }

  fetchAllForwardContract(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardacu/all/');
  }

  fetchAllTransactions(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/allTransactions');
  }

  getAuctionProductBidsCount(sku): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/forwardAcuBid/bidCount/' + sku);
  }
}

