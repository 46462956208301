<app-header xmlns:svg="svg"></app-header>

<div class="main-container">
  <div class="container product-view">
    <div class="row">
      <div class="col-lg-12">
        <div class="pl-content w-full-col">
          <div class="inner-panel">

            <div class="container">
              <div class="row">
                <div class="col-12">
                  <button type="button" class="btn btn-success singleViewBackBtn" (click)="back()">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="col-md-4">
                  <div class="image-container">
                    <div class="zoom-popup">
                      <!--                      Zoom Image-->
                    </div>

                    <div class="view-product">
                      <div class="image-box">
                        <img style="cursor: pointer" data-toggle="modal" data-target="#openImage" *ngIf="commonImg"
                          class="demo-trigger" id="main-image" src="{{this.commonImg}}" alt="" />
                        <img *ngIf="!commonImg" class="show demo-trigger" src="assets/defaultBrokenImage.jpg" alt="">
                      </div>
                      <!-- economy center badge -->
                      <div class="eco-badge-view">
                        <h5>
                          <img src="../../assets/images/icons/store.png" alt="">
                          Thambuttegama<br> Economic Center
                        </h5>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 1vh;">

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb">
                          <img *ngIf="img1" class="imageTag show" style="filter: unset; opacity: 1;" [src]="img1"
                            id="img1" (click)="viewImg(img1,1)" alt="">
                          <img *ngIf="!img1" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb" *ngIf="img2">
                          <img *ngIf="img2" class="imageTag" [src]="img2" id="img2" (click)="viewImg(img2,2)" alt="">
                          <img *ngIf="!img2" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb" *ngIf="img3">
                          <img *ngIf="img3" class="imageTag" [src]="img3" id="img3" (click)="viewImg(img3,3)" alt="">
                          <img *ngIf="!img3" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb" *ngIf="img4">
                          <img *ngIf="img4" class="imageTag" [src]="img4" id="img4" (click)="viewImg(img4,4)" alt="">
                          <img *ngIf="!img4" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                    </div>

                    <!--                    <div class="zoom-popup">-->
                    <!--                      Click Image to open expanded view-->
                    <!--                    </div>-->
                  </div>


                </div>
                <div class="col-md-8 right-side">
                  <div class="row">
                    <div class="col-md-8 board-right">

                      <div class="product-info-container">
                        <div class="purchase-info">
                          <p>{{'PRODUCT_VIEW_FORWARD.FORWARD_PURCHASE' | translate}}</p>
                          <!--                          <div class="delivery-stats">-->
                          <!--                            <span class="material-icons">-->
                          <!--                              airport_shuttle-->
                          <!--                            </span>-->
                          <!--                            <p>Delivery Included</p>-->
                          <!--                          </div>-->
                        </div>
                        <div class="product-title">
                          {{name}}
                        </div>
                        <div class="filter-pill viewfill d-flex justify-content-between align-items-center"
                          title="filter products by {{farmerUsername}}" (click)="filterOn()"
                          [ngClass]="filstatus ? 'filtered' : 'notfiltered'">
                          <div class="seller-card">
                            <div class="profile-picture">
                              <img src="../../assets/avatar.jpg" alt="">
                            </div>

                            <div class="seller-content">
                              <div class="name">{{farmerUsername}}</div>
                            </div>
                          </div>
                          <div class="fillicon">
                            <span class="material-icons">
                              filter_list
                            </span>
                          </div>
                        </div>
                        <!-- <div>
                          <i aria-hidden="true" class="fa fa-user-o"></i>&nbsp;&nbsp;&nbsp;{{farmerUsername}}
                        </div> -->
                        <div class="category">
                          {{categoryName}}/{{subCategoryName}}
                        </div>
                        <div class="date">
                          <div class="title">
                            {{'PRODUCT_VIEW_FORWARD.LOCATION' | translate}}:
                          </div>
                          <div class="info">
                            {{location}}
                          </div>
                        </div>
                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW_FORWARD.LISTED_DATE' | translate}}:
                          </div>
                          <div class="info">
                            {{listedDate}}
                          </div>
                        </div>
                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW_FORWARD.BEST_BUY_DATE' | translate}} :
                          </div>
                          <div class="info">
                            {{expiryDate}}
                          </div>
                        </div>

                        <div class="price">
                          <div class="s">
                            {{'PRODUCT_VIEW_FORWARD.TOTAL_PRICE' | translate}}:
                            <!--                            <span>{{CURRENCY_CODE}} {{contractTotalAmount| number:'2.2-2'}}</span>-->
                            <span>{{'CURRENCY.CURRENCY' | translate}} {{contractTotalAmount| number:'2.2-2'}}</span>
                          </div>
                          <div class="s">
                            {{'PRODUCT_VIEW_FORWARD.INITIAL_PAYMENT' | translate}}:
                            <!--                            <span>{{CURRENCY_CODE}} {{(contractTotalAmount * contractInitialPayment) / 100| number:'2.2-2'}}</span>-->
                            <span>{{'CURRENCY.CURRENCY' | translate}} {{(contractTotalAmount * contractInitialPayment) /
                              100| number:'2.2-2'}}</span>
                          </div>
                        </div>

                      </div>

                      <div class="seller-info">

                      </div>

                      <a data-toggle="modal" data-target="#pMilestones"
                        style="font-weight: 900; text-decoration: underline; cursor: pointer;">{{'PRODUCT_VIEW_FORWARD.PRICE_MILESTONES'
                        | translate}}</a>

                      <!-- <div id="accordion" *ngIf="installmentCollection">
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                      aria-expanded="true" aria-controls="collapseOne">
                                Installment Payments
                              </button>
                            </h5>
                          </div>

                          <div id="collapseOne" class="collapse hide">
                            <div class="card-body">
                              <table class="table">
                                <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Installment (%)</th>
                                  <th scope="col">Last</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let installment of installmentCollection;let i = index">
                                  <th>{{i + 1}}</th>
                                  <td>{{installment.installment}}</td>
                                  <td>{{commonService.convertDate(installment.paymentDate)}}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                      </div> -->

                    </div>
                    <div class="col-md-4">

                      <div class="container">
                        <div class="seller-card">
                          <div class="profile-picture">
                            <img src="../../assets/avatar.jpg" alt="">
                          </div>
                          <div class="seller-content">
                            <div class="name" style="width: unset!important;">{{farmerUsername}}</div>
                            <!--                            <div class="area">-->
                            <!--                              Bandarawela-->
                            <!--                            </div>-->
                            <div class="star-icons">
                              <star-rating value="2" totalstars="5" checkedcolor="#FFA84C" uncheckedcolor="#999999"
                                size="16px" readonly="true"></star-rating>
                            </div>
                          </div>
                        </div>

                        <!--                        <div class="link-parts">-->
                        <!--                          <div class="row">-->
                        <!--                            <span class="material-icons icoon2">-->
                        <!--                              store-->
                        <!--                            </span>-->
                        <!--                            <div class="title">-->
                        <!--                              View other products-->
                        <!--                            </div>-->
                        <!--                          </div>-->
                        <!--                          <div class="row">-->
                        <!--                            <span class="material-icons icoon2">-->
                        <!--                              perm_contact_calendar-->
                        <!--                            </span>-->
                        <!--                            <div class="title">-->
                        <!--                              Seller profile-->
                        <!--                            </div>-->
                        <!--                          </div>-->

                        <!--                        </div>-->

                      </div>

                    </div>
                  </div>

                  <div class="price-actions">

                    <div class="min-bid">
                      {{'PRODUCT_VIEW_FORWARD.QUANTITY' | translate}}
                    </div>

                    <div class="row-cotnainers">
                      <div class="input-container">
                        <input disabled type="text" class="form-control KG-input" value="{{stockAvailable}}" />
                        <span class="input-group-addon">{{unitType}}</span>
                      </div>

                    </div>


                    <div class="row mg-20">
                      <div class="col-md-4">
                        <button class="darkbtn2" style="color: white" (click)="addCart()">
                          <i class="fa fa-cart-plus icontbtn"></i>{{'PRODUCT_VIEW_FORWARD.ADD_TO_CART' | translate}}
                        </button>

                      </div>
                      <div class="col-md-4">
                        <button class="darkbtn" style="color: white" (click)="payNow()">
                          <i class="fa fa-credit-card icontbtn"></i>
                          {{'PRODUCT_VIEW_FORWARD.PAY_NOW' | translate}}
                        </button>
                      </div>
                    </div>

                    <hr>

                    <div class="desc">
                      <div class="des-title">
                        {{'PRODUCT_VIEW_FORWARD.DESCRIPTION' | translate}}
                      </div>
                      <div class="des-content">
                        {{description}}
                      </div>

                      <!--                      <div class="report-this" data-toggle="modal" data-target="#feedBack">-->
                      <!--                        <span class="material-icons">-->
                      <!--                          perm_contact_calendar-->
                      <!--                        </span>-->
                      <!--                        <p>-->
                      <!--                          Report this-->
                      <!--                        </p>-->
                      <!--                      </div>-->

                    </div>

                  </div>

                </div>
              </div>

            </div>
            <div class="cp-spinner cp-morph"></div>
          </div>
        </div>
        <div class="clear">&nbsp;</div>

        <!--        <div class="w-full-col" style="margin-top: 20px;">-->
        <!--          <div>-->
        <!--            <div style="margin-left: 10px;cursor: pointer" (click)="openCommentBox()">-->
        <!--              <a id="showCommentBox" data-toggle="collapse" href="#review" role="button" aria-expanded="false"-->
        <!--                aria-controls="review">-->
        <!--                <i class="fa fa-comments" aria-hidden="true">-->
        <!--                  Ask Questions-->
        <!--                </i>-->
        <!--              </a>-->
        <!--            </div>-->
        <!--            <div class="collapse" id="review" style="margin-top: 20px;">-->
        <!--              <div class="card card-body">-->
        <!--                <div class="inner-cards"-->
        <!--                  style=" height:auto; max-height:230px; overflow-y: scroll; padding: 10px; background: #f5f5f5; margin-bottom: 20px; border-bottom: 15px solid #f5f5f5;"-->
        <!--                  *ngIf="hideAllComments">-->
        <!--                  <div style="margin: 10px;">-->
        <!--                    <div class="card review-card" *ngFor="let comment of allComments ; let i = index">-->
        <!--                      <div>-->
        <!--                        <div class="top-panel">-->
        <!--                          <div class="left">-->
        <!--                            <span>{{comment.userName}}</span>-->
        <!--                          </div>-->
        <!--                        </div>-->
        <!--                        <div class="bottom-panel">-->
        <!--                          <div class="centers">-->
        <!--                            <div class="reviewtxt">-->
        <!--                              {{comment.commentDescription}}-->
        <!--                            </div>-->
        <!--                          </div>-->
        <!--                          <br>-->
        <!--                          <a>-->
        <!--                            <i class="fa fa-reply" aria-hidden="true" data-toggle="collapse"-->
        <!--                              [attr.data-target]="'#Replay' + i" aria-expanded="false" aria-controls="Replay"-->
        <!--                              (click)="loadReply(comment.id)">-->
        <!--                              Reply-->
        <!--                            </i>-->
        <!--                          </a>-->
        <!--                          <div class="collapse" [id]="'Replay' + i" style="margin-top: 1vh;">-->
        <!--                            <div *ngIf="hideAllReply">-->
        <!--                              <div class="card card-body" style="background-color: #C4C4C4"-->
        <!--                                *ngFor="let reply of loadReplyComment">-->
        <!--                                <span>{{reply.reply}}</span>-->
        <!--                              </div>-->
        <!--                              <br>-->
        <!--                            </div>-->
        <!--                            <form [formGroup]="replyForm" (ngSubmit)="replyFormSubmit()">-->
        <!--                              <div class="card card-body">-->
        <!--                                <textarea formControlName="reply" class="form-control"-->
        <!--                                  placeholder="Write your comment here!"-->
        <!--                                  [ngClass]="{'is-invalid': submittedReply && replyForm.controls.reply.errors}">-->
        <!--                                </textarea>-->
        <!--                                <div *ngIf="submittedReply && replyForm.controls.reply.errors">-->
        <!--                                  <div style="font-size: 10px;color: red"-->
        <!--                                    *ngIf="replyForm.controls.reply.errors.required">-->
        <!--                                    Comment is required.-->
        <!--                                  </div>-->
        <!--                                </div>-->
        <!--                              </div>-->
        <!--                              .-->
        <!--                              <br>-->
        <!--                              <button type="submit" class="btn btn-secondary">Post</button>-->
        <!--                            </form>-->

        <!--                          </div>-->
        <!--                        </div>-->
        <!--                      </div>-->

        <!--                    </div>-->
        <!--                  </div>-->

        <!--                </div>-->
        <!--                <div class="card review-card">-->
        <!--                  <form [formGroup]="commentForm" (ngSubmit)="commentFormSubmit()">-->
        <!--                    <div class="form-group">-->
        <!--                      <textarea formControlName="comment" class="form-control" id="exampleFormControlTextarea1" rows="3"-->
        <!--                        placeholder="Tell others what you think about this item or what do you want to know about this item ?"-->
        <!--                        [ngClass]="{'is-invalid': submittedComment && commentForm.controls.comment.errors}">-->
        <!--                      </textarea>-->
        <!--                      <div *ngIf="submittedComment && commentForm.controls.comment.errors">-->
        <!--                        <div style="font-size: 10px;color: red" *ngIf="commentForm.controls.comment.errors.required">-->
        <!--                          Comment is required.-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <br>-->
        <!--                    <button style="cursor: pointer" type="submit" class="btn btn-secondary">Submit-->
        <!--                    </button>-->
        <!--                  </form>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--          </div>-->
        <!--        </div>-->

        <!-- Product Slider 02 -->
        <div class="w-full-col" style="margin-top: 30px;">
          <h4 style="margin-left: 2vh;">{{'NEW_ARRIVALS' | translate}}</h4>
          <div class="slck-product-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
              <div ngxSlickItem *ngFor="let newArrival of allNewArrivalCategory ; let i = index" class="slide ps-item">
                <a [routerLink]="['/product-view-'+setType(newArrival.type.type)+'/'+tenantValue]"
                  [queryParams]="{id:newArrival.id,typeId:newArrival.type.id,typeName:newArrival.type.type}">
                  <div class="ps-inner-wrapper">
                    <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                      *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length > 0" class="imageTagNew"
                      src="{{this.mainUrl.MAIN_URL_FILE + newArrival.spotImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                      alt="" />
                    <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                      *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length === 0" class="imageTagNew"
                      src="assets/defaultBrokenImage.jpg" alt="" />

                    <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                      *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length > 0"
                      class="imageTagNew"
                      src="{{this.mainUrl.MAIN_URL_FILE + newArrival.futureImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                      alt="" />

                    <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                      *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length === 0"
                      class="imageTagNew" src="assets/defaultBrokenImage.jpg" alt="" />

                    <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                      *ngIf="newArrival.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length > 0"
                      class="imageTagNew"
                      src="{{this.mainUrl.MAIN_URL_FILE + newArrival.forwardAcuImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                      alt="" />

                    <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                      *ngIf="newArrival.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length === 0"
                      class="imageTagNew" src="assets/defaultBrokenImage.jpg" alt="" />
                    <div class="ps-item-inner">
                      <h5 class="productName">{{newArrival.name}}</h5>
                      <dl style="margin-top: 4px;">
                        <span
                          *ngIf="newArrival.stockAvailable !== 0 && newArrival.type.type !== 'FORWARD_ACU' && newArrival.type.type !== 'FORWARD_BN'"
                          class="text" numberoflines="1" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0); overflow: hidden;
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                          <!--                                <dt *ngIf="newArrival.sellInSmallerQuantities">{{CURRENCY_CODE}}<span-->
                          <!--                                  style="margin-left: 0.5vw">-->
                          <!--                                  </span>{{newArrival.unitPrice * newArrival.spotDTOList[0].stock | number:'2.2-2'}}</dt>-->
                          <dt *ngIf="newArrival.sellInSmallerQuantities">{{'CURRENCY.CURRENCY' | translate}}<span
                              style="margin-left: 0.5vw">
                            </span>{{newArrival.unitPrice * newArrival.spotDTOList[0].stock | number:'2.2-2'}}</dt>

                          <!--                                     <dt *ngIf="!newArrival.sellInSmallerQuantities">{{CURRENCY_CODE}}<span-->
                          <!--                                       style="margin-left: 0.5vw">-->
                          <!--                                    </span>{{newArrival.unitPrice * newArrival.stockAvailable | number:'2.2-2'}}</dt>-->
                          <dt *ngIf="!newArrival.sellInSmallerQuantities">{{'CURRENCY.CURRENCY' | translate}}<span
                              style="margin-left: 0.5vw">
                            </span>{{newArrival.unitPrice * newArrival.stockAvailable | number:'2.2-2'}}</dt>
                        </span>
                        <span *ngIf="newArrival.stockAvailable === 0" class="text" numberoflines="1" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0); overflow: hidden;
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                          <dt style="color: red">{{'PRODUCT_VIEW_FORWARD.SOLD_OUT' | translate}}</dt>
                        </span>
                      </dl>
                    </div>
                  </div>

                </a>
              </div>
            </ngx-slick-carousel>
          </div>

          <div *ngIf="spinner2 !== 0">
            <div class="sk-cube-grid">
              <div class="sk-cube sk-cube1"></div>
              <div class="sk-cube sk-cube2"></div>
              <div class="sk-cube sk-cube3"></div>
              <div class="sk-cube sk-cube4"></div>
              <div class="sk-cube sk-cube5"></div>
              <div class="sk-cube sk-cube6"></div>
              <div class="sk-cube sk-cube7"></div>
              <div class="sk-cube sk-cube8"></div>
              <div class="sk-cube sk-cube9"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>


<app-footer></app-footer>

<div class="modal fade" id="openImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="border: unset;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="commonImg" class="demo-trigger" src="{{this.commonImg}}" alt="" />
      </div>
    </div>
  </div>
</div>

<!-- Report -->
<div class="modal fade npa-model" id="feedBack" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title d-flex align-items-center">Report This Product</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="reportForm" (ngSubmit)="submitReportForm()">
          <div class="reviews">
            <div class="descriptions">
              <div class="form-group pt-1 pb-2">
                <label for="commentReport">{{'PRODUCT_VIEW_FORWARD.REASON' | translate}}</label>
                <textarea formControlName="commentReport" class="form-control" id="commentReport" rows="5"
                  placeholder="{{'PRODUCT_VIEW_FORWARD.COMMENT' | translate}}"></textarea>
              </div>
            </div>
            <div class="review-actions">
              <div class="text-right">
                <button class="btn btn-default dark" data-dismiss="modal" aria-label="Close">
                  {{'PRODUCT_VIEW_FORWARD.COMMENT' | translate}}</button>
                <button class="btn btn-default next" style="margin-left: 5px;" type="submit">
                  {{'PRODUCT_VIEW_FORWARD.REPORT' | translate}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Milestones -->
<div class="modal fade" id="pMilestones" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 feedback" role="document">
    <div class="modal-content">
      <div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="fb-top">
        {{'PRODUCT_VIEW_FORWARD.PRICE_MILESTONES' | translate}}
      </div>
      <form>
        <div class="review-editer">
          <div class="mailstones-list">
            <!--            <div class="mailstones-item dp">-->
            <!--              <i class="fa fa-check" aria-hidden="true"></i>-->
            <!--              <h4>Down-Payment-->
            <!--                <span><i class="fa fa-star" aria-hidden="true"></i></span>-->
            <!--              </h4>-->
            <!--              <span class="price">LKR 500.00</span>-->
            <!--              <span class="date">15/05/2020</span>-->
            <!--            </div>-->
            <div *ngFor="let installment of installmentCollection;let i = index">
              <div class="mailstones-item" *ngIf="(contractTotalAmount * installment['installment']) / 100 !== 0">
                <!--              <i class="fa fa-check" aria-hidden="true"></i>-->

                <h4>{{'PRODUCT_VIEW_FORWARD.MILESTONE' | translate}} 0{{i + 1}}</h4>
                <!--                <span-->
                <!--                  class="price">{{CURRENCY_CODE}}&nbsp;{{[(contractTotalAmount * installment['installment']) / 100 | number:'2.2-2']}}&nbsp;-->
                <!--                ({{installment['installment']}}%)</span>-->
                <span class="price">{{'CURRENCY.CURRENCY' | translate}}&nbsp;{{[(contractTotalAmount *
                  installment['installment']) / 100 | number:'2.2-2']}}&nbsp;
                  ({{installment['installment']}}%)</span>
                <span class="date">{{commonService.convertDate(installment['paymentDate'])}}</span>
              </div>
            </div>


            <div class="mailstones-item">
              <!--              <i class="fa fa-check" aria-hidden="true"></i>-->
              <h4>{{'PRODUCT_VIEW_FORWARD.CLOSING_PAYMENT' | translate}}</h4>
              <!--              <span-->
              <!--                class="price">{{CURRENCY_CODE}}&nbsp;{{[(contractTotalAmount * contractClosingPayment) / 100 | number:'2.2-2']}}&nbsp;-->
              <!--                ({{contractClosingPayment}}%)</span>-->
              <span class="price">{{'CURRENCY.CURRENCY' | translate}}&nbsp;{{[(contractTotalAmount *
                contractClosingPayment) / 100 | number:'2.2-2']}}&nbsp;
                ({{contractClosingPayment}}%)</span>
            </div>
            <!--            <div class="mailstones-item active">-->
            <!--              <i class="fa fa-check" aria-hidden="true"></i>-->
            <!--              <h4>Milestone 02</h4>-->
            <!--              <span class="price">LKR 500.00</span>-->
            <!--              <span class="date">15/05/2020</span>-->
            <!--            </div>-->
            <!--            <div class="mailstones-item future">-->
            <!--              <i class="fa fa-circle-thin" aria-hidden="true"></i>-->
            <!--              <h4>Milestone 03</h4>-->
            <!--              <span class="price">LKR 500.00</span>-->
            <!--              &lt;!&ndash; <span class="date">15/05/2020</span> &ndash;&gt;-->
            <!--            </div>-->
          </div>
        </div>
        <div class="cart-btn-wrapper">
          <button data-dismiss="modal" aria-label="Close" style="margin-top: 1vh;" type="button"
            class="btn btn-success">{{'PRODUCT_VIEW_FORWARD.CLOSE' | translate}}
          </button>
        </div>
      </form>

    </div>
  </div>
</div>