import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MainUrlService} from './main-url.service';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient, private  mainUrl: MainUrlService) {

  }
  signUp(body):Observable<any>{
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH+'/auth/signup',body);
  }
}
