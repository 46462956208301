import {Component, OnInit, ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../service/product.service';
import {CommonService} from '../common/common.service';
import {DeliveryDTO} from '../dto/deliveryDTO';
import {CartService} from '../service/cart.service';
import {CoreService} from '../service/core.service';
import {RiyawiruService} from '../service/riyawiru.service';
import {UserRoleService} from '../service/user-role.service';
import {UserTokenDTO} from '../dto/userTokenDTO';
import {MainUrlService} from '../service/main-url.service';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {InteractionService} from '../service/interaction.service';
// @ts-ignore
import moment from 'moment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProductDeliveryDTO} from '../dto/ProductDeliveryDTO';

@Component({
  selector: 'app-product-view-order-request',
  templateUrl: './product-view-order-request.component.html',
  styleUrls: ['./product-view-order-request.component.css']
})
export class ProductViewOrderRequestComponent implements OnInit {

  activeLang: any = 'en';
  productId: any;
  stockAvailable: any;
  selectBitValue: any;
  bidAcceptedDate: any;
  expectedDeliveryDate: string;
  type: any;
  categoryName: any;
  categoryId: any;
  subCategoryName: any;
  subCatId: any;
  name: any;
  requiredQty: any;
  description: any;
  avgRating: any;
  unitType: any;
  listedDate: any;
  location: any;
  img1: string;

  bidAmount: any = 0;
  bidQty: any = 0;

  selectedRoption = 0;
  dto = new DeliveryDTO;

  transportList: any[];
  railwayList: any;
  transportID: any;
  dropStation: any;
  prdTransportList = [];
  isSellerFixed = false;
  selectedVehicleId: any;
  riyawiruSelectedVehicleType = [];

  sellerName: any;
  deliveryOptList: any = [];
  sku: any;
  listedBy: any;
  categoryNameEN: any;
  categoryNameSI: any;
  categoryNameTM: any;
  listedByName: any;
  subCategoryNameEN: any;
  subCategoryNameSI: any;
  subCategoryNameTM: any;


  riyawiruVehicleTypes: any;
  expectationPrice: any;
  isRequiredExpectation: boolean = false;
  isPickVehicle: boolean = false;
  selectedId: any;
  isTrain = false;
  isSeller = false;
  isBuyer = false;
  station: any;
  seller: any;
  sellerUsername: any;
  sellerMobile: any;
  sellerLocation: any;
  prdImg: any;
  isEmptyReviewList: boolean = false;
  filstatus: boolean = false;

  deliveryForm: FormGroup;
  deliveryFormFixed: FormGroup;
  deliverCharge: any;
  areas: any;
  loggedEmail: any;
  isChargeReq: boolean = false;
  isAreaReq = false;
  areaDisable = true;

  @ViewChild('transportModal', {static: false}) transportModal;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('openRiyawiruModal', {static: false}) openRiyawiruModal;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;
  @ViewChild('transportOptions') transportOptions;

  constructor(private route: ActivatedRoute,
              private commonService: CommonService,
              private cartService: CartService,
              private formBuilder: FormBuilder,
              private riyawiruService: RiyawiruService,
              private validationHandlerService: ValidationHandlerService,
              public mainUrl: MainUrlService,
              private interaction: InteractionService,
              private router: Router,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,
              private coreService: CoreService,
              private productService: ProductService) {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit() {
    this.common();

    this.route.queryParams.subscribe(params => {
      this.productId = params['id'];
      this.loadOrderRequestDetails(params['id']);
    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.setCategoryNames(
          this.categoryNameEN,
          this.categoryNameSI,
          this.categoryNameTM,
          this.subCategoryNameEN,
          this.subCategoryNameSI,
          this.subCategoryNameTM);
      }
    });

    this.interactionService.registrationButtons.subscribe(() => {
      this.common();
    });


    this.deliveryForm = this.formBuilder.group({
      deliverCharge: '',
    });
    this.deliveryFormFixed = this.formBuilder.group({
      deliverChargeFixed: '',
      areas: '',
    });
  }

  setCategoryNames(catEN, catSI, catTM, subCatEn, SubCatSI, SubCatTM) {
    this.categoryNameEN = catEN;
    this.categoryNameSI = catSI;
    this.categoryNameTM = catTM;
    this.subCategoryNameEN = subCatEn;
    this.subCategoryNameSI = SubCatSI;
    this.subCategoryNameTM = SubCatTM;
  }

  common() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn !== 'True') {
      this.isDisabled = false;
    } else {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        Swal.close();
        let arr = [];
        this.loggedEmail = whoAmIDetails['email'];
        arr.push(whoAmIDetails);
        for (let role of arr) {
          this.isDisabled = !(role.roles[0].name === 'BUYER'
            || role.roles[0].name === 'FARMER'
            || role.roles[0].name === 'AGGREGATOR');
        }
      });

    }

  }

  loadOrderRequestDetails(id) {
    this.commonService.processing();

    this.productService.fetchOrderRequestByProductId(id).subscribe(result => {
      this.sku = result.sku;
      this.listedBy = result.product.listedBy;
      this.stockAvailable = result.quantity;
      this.productId = result.product.id;
      this.selectBitValue = result.startPrice;
      this.listedByName = result.listedByName;
      this.categoryNameEN = result.product.subCategory.mainCategory.commonIndex.description;
      this.categoryNameSI = result.product.subCategory.mainCategory.commonIndex.sinhala;
      this.categoryNameTM = result.product.subCategory.mainCategory.commonIndex.tamil;
      this.subCategoryNameEN = result.product.subCategory.commonIndex.description;
      this.subCategoryNameSI = result.product.subCategory.commonIndex.sinhala;
      this.subCategoryNameTM = result.product.subCategory.commonIndex.tamil;
      this.bidAcceptedDate = this.commonService.convertDate(result.bidAcceptedDate);
      this.expectedDeliveryDate = this.commonService.convertDate(result.expectedDeliveryDate);
      this.type = result.product.type.type;
      this.categoryName = result.product.subCategory.mainCategory.commonIndex.description;
      this.categoryId = result.product.subCategory.mainCategory.commonIndex.id;
      this.subCategoryName = result.product.subCategory.commonIndex.description;
      this.subCatId = result.product.subCategory.code;
      this.name = result.product.name;
      this.requiredQty = result.requiredQty;
      this.bidQty = result.requiredQty;
      this.description = result.product['description'];
      this.avgRating = result.product.avgRating;
      this.unitType = result.product.unit.unit;
      this.location = result.product.address;
      this.listedDate = this.commonService.convertDate(result.created);

      this.bidTimeCountDown(result.bidAcceptedDate, true);

      this.categoryName = result.product.subCategory.mainCategory.commonIndex.description;

      if (this.categoryName === 'Farming Products & Processed Food') {
        this.img1 = 'assets/images/Thumbnails/Processed Products.jpg';
      } else if (this.categoryName === 'Spices & Cash Crops') {
        this.img1 = 'assets/images/Thumbnails/Spices.jpg';
      } else if (this.categoryName === 'Services & Information') {
        this.img1 = 'assets/images/Thumbnails/Services.jpg';
      } else if (this.categoryName === 'Plants & Seeds') {
        this.img1 = 'assets/images/Thumbnails/Seeds.jpg';
      } else if (this.categoryName === 'Ayurvedic & Herbal Products') {
        this.img1 = 'assets/images/Thumbnails/Ayurveda.jpg';
      } else if (this.categoryName === 'Equipment and Tools') {
        this.img1 = 'assets/images/Thumbnails/Equipments.jpg';
      } else {
        this.img1 = 'assets/images/Thumbnails/' + result.product.subCategory.mainCategory.commonIndex.description + '.jpg';
      }

      this.deliveryMethod();
      this.getRiyawiruVehicleTypes();
      Swal.close();
    });
  }

  deliveryMethod() {
    this.coreService.getDeliveryMethod().subscribe(
      (data) => {
        this.transportList = data;
      }, () => {
        Swal.close();
      }
    );
  }

  filterOn() {
    this.filstatus = !this.filstatus;
  }

  clickRiyaviru(sku: any) {
    this.transportOptionsClose.nativeElement.click();
    this.selectedRoption = sku + 1;
  }

  riyawiruModalShow() {
    this.transportOptions.nativeElement.click();
  }

  selectVehicleType(id, sku) {
    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.vehicleType = id;
      }
    }
    this.selectedVehicleId = id;
    this.isPickVehicle = false;
    this.selectedId = id;
  }

  getRiyawiruVehicleTypes() {
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.fetchAllVehicleTypes(createToken['access_token']).subscribe(result => {
        this.riyawiruVehicleTypes = result;
      });
    });
  }

  userTokenDTO = new UserTokenDTO;
  imgUrl: any = 'assets/avatar.jpg';

  checkValRiyawiru(sku, val) {
    for (let delivery of this.prdTransportList) {
      if (sku === delivery.sku) {
        let index = this.prdTransportList.indexOf(delivery);
        if (index !== -1) {
          this.prdTransportList.splice(index, 1);
        }
      }
    }

    for (let delivery of this.riyawiruSelectedVehicleType) {
      if (sku === delivery.sku) {
        let index = this.riyawiruSelectedVehicleType.indexOf(delivery);
        if (index !== -1) {
          this.riyawiruSelectedVehicleType.splice(index, 1);
        }
      }
    }

    this.prdTransportList.push({
      'sku': sku,
      'trnId': val
    });

    this.riyawiruSelectedVehicleType.push({
      'sku': sku,
      'value': 0,
      'paymentMethod': 'Cash On Delivery',
      'vehicleType': null
    });

  }

  isSellerDeliver: boolean = false;
  isRiyawiruDeliver: boolean = false;
  isSellerDeliverFixed: boolean = false;
  buyerDeliver: boolean = false;
  train: boolean = false;
  railwayReq: boolean = false;
  deliveryReq: boolean = false;
  isFixedChargeReq = false;
  deliveryOptions = 2;


  checkVal($event, val) {
    if ($event.checked) {
      this.deliveryOptList.push(val);
      if (val === 1) {
        this.openRiyawiruModal.nativeElement.click();
        this.isRiyawiruDeliver = true;
        this.checkValRiyawiru(this.sku, val);
        this.deliveryReq = false;
      } else if (val === 3) {
        this.isSellerDeliver = true;
        this.deliveryReq = false;
      } else if (val === 4) {
        this.train = true;
        this.deliveryReq = false;
      } else if (val === 2) {
        this.buyerDeliver = true;
        this.deliveryReq = false;
      } else if (val === 5) {
        this.isSellerDeliverFixed = true;
        this.deliveryReq = false;
      }
    } else {
      if (this.deliveryOptList.length > 0) {
        for (let delivery of this.deliveryOptList) {
          if (delivery === val) {
            let index = this.deliveryOptList.indexOf(delivery);
            if (val === 1) {
              this.isRiyawiruDeliver = false;
              this.checkValRiyawiru(this.sku, val);
            } else if (val === 3) {
              this.isSellerDeliver = false;
            } else if (val === 4) {
              this.train = false;
            } else if (val === 2) {
              this.buyerDeliver = false;
            } else if (val === 5) {
              this.isSellerDeliverFixed = false;
            }
            if (index !== -1) {
              this.deliveryOptList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  getUser() {
    this.userTokenDTO.userName = this.sellerUsername;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=wholesale' : 'assets/avatar.jpg';
      }
    }, () => {
      this.imgUrl = 'assets/avatar.jpg';
    });
  }

  successAlert() {
    Swal.fire({
      position: 'center',
      type: 'success',
      title: 'Bid placed successfully',
      html: '<p>We will let you know when your bid is awarded.</p>',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonClass: 'sweet-alert-close',
      cancelButtonText: 'Close',
    });
  }

  sendBidRequest(sku: any, expectationPrice: any) {

    if (this.selectedVehicleId === undefined) {
      return this.isPickVehicle = true;
    }
    this.isPickVehicle = false;

    if (expectationPrice === undefined || expectationPrice === 0) {
      this.isRequiredExpectation = true;
      return;
    }
    this.isRequiredExpectation = false;

    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.price = expectationPrice;
      }
    }
    this.expectationPrice = undefined;
    this.selectedVehicleId = undefined;
    this.closeRiyawiruModal.nativeElement.click();
  }

  deliverChargeFixed: any;
  productDelivery: ProductDeliveryDTO[] = [];

  bidOrderRequest() {
    let isValid = false;
    if (this.isSellerDeliver || this.train || this.buyerDeliver || this.isSellerDeliverFixed || this.isRiyawiruDeliver) {

      if (this.train) {
        if (this.station === null || this.station === undefined || this.station === '') {
          this.railwayReq = true;
        } else {
          this.railwayReq = false;
          isValid = true;
        }
      } else if (this.isSellerDeliver) {
        if (this.deliveryForm.value.deliverCharge === null || this.deliveryForm.value.deliverCharge === undefined
          || this.deliveryForm.value.deliverCharge === '') {
          this.isChargeReq = true;
        } else {
          this.isChargeReq = false;
          isValid = true;
        }

      } else if (this.isSellerDeliverFixed) {

        if (!(this.deliveryFormFixed.value.deliverChargeFixed === null || this.deliveryFormFixed.value.deliverChargeFixed === undefined
          || this.deliveryFormFixed.value.deliverChargeFixed === '')) {

          if (!this.areaDisable) {
            if (this.deliveryFormFixed.value.areas === null || this.deliveryFormFixed.value.areas === undefined
              || this.deliveryFormFixed.value.areas === '') {
              isValid = false;
              this.isAreaReq = true;
            } else {
              isValid = true;
              this.isAreaReq = false;
            }
          } else {
            isValid = true;
            this.isFixedChargeReq = false;
          }

        } else {
          this.isFixedChargeReq = true;
          isValid = false;
        }


      } else if (this.buyerDeliver) {
        isValid = true;
      } else {
        isValid = true;
      }
    } else {
      this.deliveryReq = true;
    }

    if (isValid) {
      this.deliveryReq = false;
      this.isRiyawiruDeliver = false;
      this.railwayReq = false;
      this.isChargeReq = false;
      this.isFixedChargeReq = false;
      this.isAreaReq = false;
      this.deliverCharge = this.deliveryForm.value.deliverCharge;
      this.deliverChargeFixed = this.deliveryFormFixed.value.deliverChargeFixed;
      this.areas = this.areaDisable ? 'All Island delivery' : this.deliveryFormFixed.value.areas;

      for (let opt of this.deliveryOptList) {
        let doption: ProductDeliveryDTO = new ProductDeliveryDTO;
        doption.deliveryOpt = opt;
        doption.sku = this.sku;
        doption.paymentMethod = 'Cash On Delivery';
        if (opt === 1) {
          for (let riyawiru of this.riyawiruSelectedVehicleType) {
            doption.deliveryCharges = riyawiru.price;
            doption.vehicleType = riyawiru.vehicleType;
            doption.deliveryDesc = null;
          }
        }
        if (opt === 2) {
          doption.deliveryCharges = null;
          doption.deliveryDesc = null;
          doption.vehicleType = null;
        }
        if (opt === 3) {
          doption.deliveryCharges = this.deliverCharge;
          doption.deliveryDesc = null;
          doption.vehicleType = null;

        }
        if (opt === 4) {
          doption.station = this.station;
          doption.deliveryCharges = null;
          doption.deliveryDesc = null;
          doption.vehicleType = null;

        }
        if (opt === 5) {
          doption.deliveryCharges = this.deliverChargeFixed;
          doption.deliveryDesc = this.areas;
          doption.vehicleType = null;

        }
        this.productDelivery.push(doption);
      }

      if (this.bidAmount > 0 && this.bidQty > 0) {
        this.commonService.processing();
        let body = {
          'bidder': this.username,
          'bidAmount': this.bidAmount,
          'fillQuantity': this.bidQty,
          'deliveryOptions': this.productDelivery,
          'orderRequest': {
            'sku': this.sku
          }
        };
        this.productService.createOrderRequestBid(body).subscribe(() => {
          Swal.close();
          this.transportOptionsClose.nativeElement.click();
          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.orderRequestBidSuccess(this.activeLang),
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            this.router.navigate(['/refresh-product-view-order-request/wholesale']).then(() => {
              this.router.navigate(['/product-view-order-request/wholesale'], {
                queryParams: {
                  'id': this.productId
                }
              });
            });
          });

        });

      }
    }


  }

  username: any;
  isDisabled: boolean = false;

  placeBid() {
    if (this.bidAmount > 0) {
      let isLoggedIn = localStorage.getItem('isLogged');
      if (isLoggedIn === 'True') {
        if (this.loggedEmail !== this.listedBy && !this.isDisabled) {
          this.username = this.loggedEmail;
          this.transportOptions.nativeElement.click();
        } else {
          Swal.fire({
            text: this.validationHandlerService.ownAuctionProductMessage(sessionStorage.getItem('LANG')),
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG'))
          });
        }
      } else {
        this.popUpLoginFormModal();
      }
    }
  }

  popUpLoginFormModal() {
    Swal.close();
    localStorage.setItem('isLogged', 'False');
    this.interaction.popUpLoginModal('false');
  }

  bidTimeCountDown(endTime, status) {
    let x = setInterval(() => {
      let countDownDate = new Date(moment(endTime).format('MMMM DD YYYY, h:mm:ss'));
      countDownDate.setHours(24, 0, 0, 0);
      const endTimenew = countDownDate.getTime();
      const now = new Date().getTime();
      const distance = endTimenew - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      let temp = document.getElementById('orderRequestTimer');
      if (temp !== null) {
        temp.innerHTML = '<strong>' + days + '</strong> d <em>' + ':' + '</em>' +
          '<strong>' + hours + '</strong> h <em>' + ':' + '</em>' +
          '<strong>' + minutes + '</strong> m <em>' + ':' + '</em>' +
          '<strong>' + seconds + '</strong> s'
        ;
      }
      if (distance < 0) {
        clearInterval(x);
        document.getElementById('orderRequestTimer').innerHTML = 'EXPIRED';
      }
    }, 1000);

    if (status) {
      for (let i = 1; i < x; i++) {
        window.clearInterval(i);
      }
      return;
    }
  }

  resetTransportOptions() {
    this.deliveryOptList = [];
    this.productDelivery = [];
    this.prdTransportList = [];
    this.riyawiruSelectedVehicleType = [];
    this.expectationPrice = undefined;
    this.selectedId = undefined;
    this.deliveryReq = false;
    this.isRiyawiruDeliver = false;
    this.railwayReq = false;
    this.isChargeReq = false;
    this.isFixedChargeReq = false;
    this.isAreaReq = false;
    this.isSellerDeliver = false;
    this.isSellerDeliverFixed = false;
    this.buyerDeliver = false;
    this.train = false;
    this.areaDisable = false;
    this.deliveryForm.reset();
    this.deliveryFormFixed.reset();
  }
}

