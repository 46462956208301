<!-- WHOLESALE BUYER SIGN UP -->
<h3>{{'REGISTER.WHOLESALE_BUYER.AS_WHOLESALE_BUYER' | translate}}</h3>
<p>{{'REGISTER.WHOLESALE_BUYER.VALIDATE_FIELD.VALIDATE_FIELD' | translate}}</p>
<form [formGroup]="signUpBuyer" (ngSubmit)="submitBuyer()" class="sms-full-width form-box-panel">

  <h5>{{'REGISTER.WHOLESALE_BUYER.PERSONAL_INFO' | translate}}</h5>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl ">{{'REGISTER.WHOLESALE_BUYER.NAME' | translate}}<sup>*</sup></mat-label>
    <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
           [(ngModel)]="name" formControlName="name" id="_name" matInput>
    <div *ngIf="submitted && f.name.errors" class="inputError">
      <div *ngIf="f.name.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
      <div
        *ngIf="f.name.errors.pattern">{{'REGISTER.WHOLESALE_BUYER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}</div>
    </div>
  </mat-form-field>


  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.NIC' | translate}}<sup>*</sup></mat-label>
    <input (input)="checkExists(nic,'NIC')" (focusout)="checkExists(nic,'NIC')" [(ngModel)]="nic"
           [ngClass]="{ 'is-invalid': submitted && f.nic.errors }" formControlName="nic" id="_nic"
           matInput>
    <div *ngIf="submitted && f.nic.errors" class="inputError">
      <div *ngIf="f.nic.errors.required">{{'COMMON_VALIDATION.NIC' | translate}}</div>
      <div *ngIf="f.nic.errors.pattern">{{'COMMON_VALIDATION.VALID_NIC' | translate}}</div>
    </div>
    <div *ngIf="incorrectNIC" class="inputError">{{'EXISTS.NIC' |translate}}</div>

  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.MOBILE' | translate}}<sup>*</sup></mat-label>
    <input (input)="checkExists(mobile,'MOBILE')" (focusout)="checkExists(mobile,'MOBILE')" [(ngModel)]="mobile"
           [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" formControlName="mobile" id="_mobile" matInput
           type="tel">
    <div *ngIf="submitted && f.mobile.errors" class="inputError">
      <div *ngIf="f.mobile.errors.required">{{'COMMON_VALIDATION.MOBILE' | translate}}</div>
      <div *ngIf="f.mobile.errors.pattern">{{'COMMON_VALIDATION.VALID_MOBILE' | translate}}
        {{'COMMON_VALIDATION.VALID_MOBILE_MSG' | translate}}</div>
    </div>
    <div *ngIf="incorrectMobile" class="inputError">{{'EXISTS.MOBILE' |translate}}</div>
  </mat-form-field>

  <hr/>

  <h5>{{'ADDRESS_INFO'|translate}}</h5>
  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.ADDRESS_1' | translate}}<sup>*</sup></mat-label>
    <input [(ngModel)]="address1"
           [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" formControlName="address1" id="_address1"
           matInput>
    <div *ngIf="submitted && f.address1.errors" class="inputError">
      <div *ngIf="f.address1.errors.required">{{'COMMON_VALIDATION.ADDRESS' | translate}}</div>
      <div
        *ngIf="f.address1.errors.pattern">{{'REGISTER.WHOLESALE_BUYER.VALIDATE_FIELD.VALIDATE_ADDRESS_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>
  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.ADDRESS_2' | translate}}<sup></sup></mat-label>
    <input [(ngModel)]="address2"
           [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" formControlName="address2" id="_address2"
           matInput>
    <div *ngIf="submitted && f.address2.errors" class="inputError">
      <div *ngIf="f.address2.errors.required">{{'COMMON_VALIDATION.ADDRESS' | translate}}</div>
      <div
        *ngIf="f.address2.errors.pattern">{{'REGISTER.WHOLESALE_BUYER.VALIDATE_FIELD.VALIDATE_ADDRESS_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.PROVINCE' | translate}}<sup>*</sup></mat-label>
    <mat-select (valueChange)="selectProvince($event)" [ngClass]="{ 'is-invalid': submitted && f.province.errors }"
                formControlName="province">
      <mat-option *ngFor="let province of allProvinceList" [value]="province.id">
        <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.province.errors" class="inputError">
      <div *ngIf="f.province.errors.required"
           style="font-size: 10px;color: red">{{'COMMON_VALIDATION.PROVINCE' | translate}}</div>
    </div>
  </mat-form-field>

  <mat-form-field *ngIf="this.selectedProvinceId" appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.DISTRICT' | translate}}<sup>*</sup></mat-label>
    <mat-select (valueChange)="selectDistrict($event)" [ngClass]="{ 'is-invalid': submitted && f.district.errors }"
                formControlName="district">
      <mat-option *ngFor="let district of allDistrictList" [value]="district.id">
        <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
      </mat-option>
    </mat-select>
    <div *ngIf="submitted && f.district.errors" class="inputError">
      <div *ngIf="f.district.errors.required"
           style="font-size: 10px;color: red">{{'COMMON_VALIDATION.DISTRICT' | translate}}</div>
    </div>
  </mat-form-field>


  <mat-form-field *ngIf="this.selectedDistrictId" appearance="outline" class="inputbox" >
    <mat-label class="floatlbl">{{'REGISTER.FARMER.CITY' | translate}}<sup>*</sup></mat-label>
    <input autocomplete="new-city" [formControl]="myControl" [(ngModel)]="city" formControlName="city" type="text" matInput
           [matAutocomplete]="auto" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control">
    <mat-autocomplete (optionSelected)="selectCity($event.option.value)" #auto="matAutocomplete">
      <mat-option style="z-index: 20000" *ngFor="let city of filteredOptions | async" [value]="city">
        <span style="color: #0e132f" *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
        <span style="color: #0e132f" *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
        <span style="color: #0e132f" *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
      </mat-option>
    </mat-autocomplete>
    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
      <div *ngIf="f.city.errors.required" style="font-size: 10px;color: red">
        {{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_CITY_REQUIRED' | translate}}</div>
    </div>
  </mat-form-field>

  <!--division filter-->
  <mat-form-field *ngIf="this.selectedDistrictId" appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.DIVISIONAL_SECRETARY' | translate}}<sup>*</sup></mat-label>
    <input autocomplete="new-division" [formControl]="myControlDevi" [(ngModel)]="divi" formControlName="division" type="text" matInput
           [matAutocomplete]="autoDivi" [ngClass]="{ 'is-invalid': submitted && f.division.errors }"
           class="form-control">
    <mat-autocomplete (optionSelected)="selectDivisional($event.option.value)" #autoDivi="matAutocomplete">
      <mat-option style="z-index: 20000" *ngFor="let division of filteredOptionsDivi | async" [value]="division">
        <span *ngIf="activeLang === 'en'">{{division.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{division.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{division.nameTA}}</span>
      </mat-option>
    </mat-autocomplete>
    <div *ngIf="submitted && f.division.errors" class="inputError">
      <div *ngIf="f.division.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.DIVISION' |
        translate}}</div>
    </div>
  </mat-form-field>


  <!--gramaNiladariFilter-->
  <mat-form-field *ngIf="this.selectedDistrictId" appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.GRAMA_AREA' | translate}}<sup>*</sup></mat-label>
    <input autocomplete="new-grama" [formControl]="myControlGrama" [(ngModel)]="grama" formControlName="gramaList" type="text" matInput
           [matAutocomplete]="autoGrama" [ngClass]="{ 'is-invalid': submitted && f.gramaList.errors }"
           class="form-control">
    <mat-autocomplete (optionSelected)="selectGrama($event.option.value)" #autoGrama="matAutocomplete">
      <mat-option style="z-index: 20000" *ngFor="let grama of filteredOptionsGrama | async" [value]="grama">
        <span *ngIf="activeLang === 'en'">{{grama.nameEN}}</span>
        <span *ngIf="activeLang === 'si'">{{grama.nameSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{grama.nameTA}}</span>
      </mat-option>
    </mat-autocomplete>
    <div *ngIf="submitted && f.gramaList.errors" class="inputError">
      <div *ngIf="f.gramaList.errors.required" style="font-size: 10px;color: red">{{'COMMON_VALIDATION.GRAMA_AREA' |
        translate}}</div>
    </div>
  </mat-form-field>


  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.POSTAL_CODE' | translate}}<sup>*</sup></mat-label>
    <input autocomplete="off" [(ngModel)]="postalCode" readonly
           [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" formControlName="postalCode" id="postalCode"
           matInput>
    <div *ngIf="submitted && f.postalCode.errors" class="inputError">
      <div *ngIf="f.postalCode.errors.required">
        {{'REGISTER.WHOLESALE_BUYER.VALIDATE_FIELD.VALIDATE_CITY_REQUIRED' | translate}}
      </div>
    </div>
  </mat-form-field>

  <hr/>

  <h5>{{'REGISTER.AGGRO_PRODUCE.SYSTEM_CREDENTIAL' | translate}}</h5>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.EMAIL' | translate}}</mat-label>
    <input (input)="checkExists(email,'EMAIL')" (focusout)="checkExists(email,'EMAIL')" [(ngModel)]="email"
           [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" id="_email" matInput>
    <div *ngIf="submitted && f.email.errors" class="inputError">
      <div *ngIf="f.email.errors.pattern">{{'COMMON_VALIDATION.VALID_EMAIL' | translate}}</div>
    </div>
    <div *ngIf="incorrectEmail" class="inputError">{{'EXISTS.EMAIL' |translate}}</div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox tooltip-bottom flexlist">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.DEFINE_A_USERNAME' | translate}}<sup>*</sup></mat-label>
    <input (input)="checkExists(username,'USERNAME')" (focusout)="checkExists(username,'USERNAME')"
           [(ngModel)]="username"
           [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autoComplete="none"
           formControlName="username" matInput type="text">
    <div *ngIf="incorrectUsernamePattern" class="inputError">{{'USERNAME_PATTER'|translate}}</div>
    <div *ngIf="incorrectUsername" class="inputError">{{'EXISTS.USERNAME' |translate}}</div>
  </mat-form-field>


  <hr/>


  <div class="inputbox text-left">
    <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.NATURE_OF_GOODS' | translate}}</mat-label>
    <mat-selection-list (ngModelChange)="wholesaleNatureGoods($event)" [(ngModel)]="selectedNatureGoods"
                        [ngModelOptions]="{standalone: true}">
      <mat-list-option *ngFor="let category of allCategories" [value]="category.description">
        {{category.description | translate}}
      </mat-list-option>
    </mat-selection-list>
  </div>

  <hr>

  <p>
    <mat-checkbox (change)="selectIsExport($event)"
                  [checked]="isExport">{{'REGISTER.WHOLESALE_BUYER.EXPORT_TIK' | translate}}</mat-checkbox>
  </p>

  <div class="row">
    <div class="col-8">
      <mat-form-field appearance="outline" class="inputbox">
        <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.PURCHASE_VOLUME' | translate}}</mat-label>
        <input [(ngModel)]="purchaseVolumePerWeek" (keyup)="clearErr()"
               formControlName="purchaseVolumePerWeek" id="_purchaseVolume" matInput>
        <div *ngIf="((f.purchaseVolumePerWeek.errors || isInValidVolume)&& submitted)" class="inputErrorDiv">
          <div *ngIf="isInValidVolume"
               style="font-size: 10px;color: red;margin-top: 11%;">Please Enter Volume
          </div>
          <div *ngIf="f.purchaseVolumePerWeek.errors.pattern"
               style="font-size: 10px;color: red;margin-top: 11%;">Please Enter Valid Volume
          </div>
        </div>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline" class="inputbox">
        <mat-label class="floatlbl">{{'REGISTER.WHOLESALE_BUYER.UNIT' | translate}}</mat-label>
        <mat-select [(ngModel)]="purchaseVolumeUnit" formControlName="purchaseVolumeUnit"
                    (valueChange)="selectUnitType($event.value)" [(value)]="selectedUnitType">
          <mat-option *ngFor="let unit of landUnit" [value]="unit.id">{{unit.description | translate}}</mat-option>
        </mat-select>
        <div *ngIf="submitted && isInValidUnit" class="inputErrorDiv">
          <div
            style="font-size: 10px;color: red;margin-top: 11%;">Please Select Units
          </div>
        </div>
      </mat-form-field>
    </div>
  </div>

  <h5>{{'REGISTER.FARMER.REFERRAL_NUMBER' | translate}}</h5>
  <mat-form-field appearance="outline" class="inputbox tooltip-bottom flexlist"
                  matTooltip="{{'REGISTER.TOOLTIP.REFERRAL_NUMBER' | translate}}">
    <mat-label class="floatlbl">{{'REGISTER.FARMER.HELAVIRU_AGENT_REFERRAL_NUMBER' | translate}}</mat-label>
    <input [readonly]="isAgentFarmer" [(ngModel)]="referralNumber" formControlName="referralNumber" matInput
           type="text">
  </mat-form-field>

  <mat-form-field appearance="outline" class="inputbox">
    <mat-label class="floatlbl">{{'MY_ACCOUNT.MY_PROFILE.SMS_LANGUAGES' | translate}}<sup></sup></mat-label>
    <mat-select [(ngModel)]="language" (valueChange)="saveLanguage($event)"
                [ngClass]="{ 'is-invalid': submitted && f.language.errors }"
                formControlName="province">
      <mat-option value="EN">English</mat-option>
      <mat-option value="SI">සිංහල</mat-option>
      <mat-option value="TA">தமிழ்</mat-option>
    </mat-select>
    <div *ngIf="submitted && f.language.errors" class="inputError">
      <div *ngIf="f.language.errors.required"
           style="font-size: 10px;color: red">language selection is required
      </div>
    </div>
  </mat-form-field>

  <div class="inputbox text-left">
    <mat-checkbox (change)="agreeWithAgreement($event)" [checked]="agreeWithUs"
                  style="margin: 0 10px 0 0;"></mat-checkbox>
    <mat-label class="floatlbl" style="float: none; margin-top: 30px; margin-bottom: 0;">
      {{'Terms1'|translate}} <a data-target=".bd-example-modal-lg" data-toggle="modal"
                                href="">{{'Terms2'|translate}}</a>&nbsp;{{'Terms3'|translate}}
    </mat-label>
  </div>
  <br/>
  <button [disabled]="!agreeWithUs" color="accent" mat-raised-button style="font-size: 12px"
          type="submit"
          class="submit-button mat-raised-button" aria-label="LOG IN">{{'SIGN_UP.SIGN_UP' | translate}}</button>
</form>


<div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-lg">

    <app-other-terms *ngIf="activeLang==='en'"></app-other-terms>
    <app-other-terms-si *ngIf="activeLang==='si'"></app-other-terms-si>
    <app-other-terms-ta *ngIf="activeLang==='ta'"></app-other-terms-ta>
  </div>
</div>

<!-- OTP modal -->
<button #optModal class="btn btn-primary" data-target="#otpModal" data-toggle="modal" hidden type="button">
</button>

<!-- Modal -->
<div aria-hidden="true" aria-labelledby="otpModal" class="modal fade" data-backdrop="static" id="otpModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'SIGN_IN.FORGET_PASSWORD.OTP_HAS_BEEN_SENT' | translate}}
          {{mobile}}</h5><br>
        <button #closeModal (click)="closeOTPModal()" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{'SIGN_IN.FORGET_PASSWORD.ENTER_OTP_CODE' | translate}}</h3>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                      [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>

        <div style="color: red;margin-top: 5px">{{'SIGN_IN.FORGET_PASSWORD.CODE_EXPIRES_IN' | translate}} : &nbsp;<span
          id="time">03:00</span></div>
        <button (click)="resendOTPCode()" *ngIf="isShowResendButton" class="btn btn-primary customBtn"
                style="margin-top: 12px;"
                type="button">{{'SIGN_IN.FORGET_PASSWORD.RESEND_CODE' | translate}}
        </button>

        <br>
        <span *ngIf="isInvalid"
              style="color: red;font-weight: bold;font-size: 14px;">The OTP you entered is invalid!</span>
      </div>
      <div class="modal-footer">
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        <button (click)="verifyOTP()" class="btn btn-primary customBtn" type="button">
          {{'SIGN_IN.FORGET_PASSWORD.VERIFY' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
