import { Component, OnInit } from '@angular/core';
import {CoreService} from '../../../../service/core.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {InteractionService} from '../../../../service/interaction.service';

@Component({
  selector: 'app-overview-lead-selling',
  templateUrl: './overview-lead-selling.component.html',
  styleUrls: ['./overview-lead-selling.component.css']
})
export class OverviewLeadSellingComponent implements OnInit {

  userRole: any;
  whoAmIDetails: any;
  activeLang: any;

  dashboardDetails: any;

  constructor(private coreService: CoreService,
              private interactionService: InteractionService,
              public mainUrl: MainUrlService) {
    this.userRole = localStorage.getItem('USER_ROLE');
    this.activeLang = sessionStorage.getItem('LANG');
    this.whoAmIDetails = JSON.parse(localStorage.getItem('WHO_AM_I_DETAILS'));
  }

  ngOnInit(): void {
    this.loadDashboardDetails();
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }


  loadDashboardDetails() {
    this.coreService.fetchDashboardSelling(this.whoAmIDetails['email'], this.userRole).subscribe(result => {
      this.dashboardDetails = result;
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

}
