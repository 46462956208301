<!--<html>-->
<head>
  <!--  <meta content="text/html; charset=UTF-8" http-equiv="content-type">-->
  <style
    type="text/css">@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');

  ol.lst-kix_list_3-1 {
    list-style-type: none
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
  }

  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, decimal) ". "
  }

  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-2, lower-latin) ". "
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  .lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, decimal) ". "
  }

  .lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-4, decimal) ". "
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  .lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, decimal) ". "
  }

  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
  }

  .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, decimal) ". "
  }

  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
  }

  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0
  }

  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3
  }

  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6
  }

  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
  }

  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
  }

  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, decimal) ". "
  }

  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, decimal) ". "
  }

  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, decimal) ". "
  }

  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, decimal) ". "
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4
  }

  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
  }

  .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, decimal) ". "
  }

  .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-2, lower-latin) ") "
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
  }

  .lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-4, decimal) ". "
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
  }

  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, decimal) ". "
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, decimal) ". "
  }

  .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
  }

  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
  }

  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, decimal) ". "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, decimal) ". "
  }

  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-latin) ") "
  }

  .lst-kix_list_2-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-3, lower-roman) ") "
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c4 {
    background-color: #ffffff;
    margin-left: 18pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c1 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
  }

  .c17 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 7.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c8 {
    color: #43474e;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
  }

  .c6 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c14 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 7.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c15 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c3 {
    color: #5d646f;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
  }

  .c12 {
    background-color: #ffffff;
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
  }

  .c21 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c20 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c9 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c13 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c10 {
    padding: 0;
    margin: 0
  }

  .c7 {
    margin-left: 36pt;
    padding-left: 0pt
  }

  p {
    margin-bottom: 20px;
  }

  p.c3 {
    display: none;
  }

  .c18 {
    font-weight: 400;
    font-family: "Calibri"
  }

  .c2 {
    font-weight: 700;
    font-family: "Calibri"
  }

  .c16 {
    margin-left: 72pt;
    padding-left: 0pt
  }

  .c19 {
    color: #000000
  }

  .c25 {
    height: 11pt
  }

  .c26 {
    padding-left: 0pt
  }

  .c5 {
    font-weight: 700
  }

  .c22 {
    color: #222222
  }

  .c24 {
    margin-left: 18pt
  }

  .c23 {
    background-color: #ffffff
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
    text-align: justify;
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }</style>
</head>
<body class="c13">
<h4>Terms and Conditions</h4>
<h1>Please read the Terms and Conditions carefully before using Helaviru.lk</h1>
<p>This document is an electronic record in terms of the provisions under Electronic Transactions Act. No 19 of 2006 as applicable and provisions pertaining to electronic records in various statutes as amended by the <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ElectronicTransactionActNo19of2006.pdf">Electronic Transactions Act No. 19 of 2006</a>.</p>
<p>This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
<h1>1. INTRODUCTION</h1>
<p>Welcome to Helaviru Digital Market Place (hereinafter called and referred as &ldquo;we", "us", "Helaviru" or &ldquo;Helaviru Platform&rdquo;. We are an online marketplace and below are the terms and conditions governing your access and use of Helaviru Platform along with its related sub-domains, sites, mobile app, services and tools (hereinafter called and referred as "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (hereinafter called and referred as "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement, please do not access, register with or use this Site. This Site is owned and operated jointly by Epic Green Eco Systems (Private) Limited (hereinafter called and referred as &ldquo;Epic Green&rdquo;) and Sri Lanka Telecom PLC (PQ 7) (hereinafter called and referred as &ldquo;SLT&rdquo;).</p>
<p>Epic Green and SLT reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes. <br /> </p>
<h1>GENERAL TERMS</h1>
<p><u>2.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SCOPE</u></p>
<p><u>3.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; INTERPRETATION &amp; DEFINITIONS</u></p>
<p><u>4.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; YOUR ACCOUNT</u></p>
<p><u>5.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PRIVACY</u></p>
<p><u>6.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PLATFORM FOR COMMUNICATION</u></p>
<p><u>7.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONTINUED AVAILABILITY OF THE SITE</u></p>
<p><u>8.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LICENSE TO ACCESS THE SITE</u></p>
<p><u>9.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; YOUR CONDUCT</u></p>
<p><u>10.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; YOUR SUBMISSION</u></p>
<p><u>11.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CLAIMS AGAINST OBJECTIONABLE CONTENT</u></p>
<p><u>12.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CLAIMS AGAINST INFRINGING CONTENT</u></p>
<p><u>13.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; TRADEMARKS AND COPYRIGHTS</u></p>
<p><u>14.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DISCLAIMER</u></p>
<p><u>15.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; INDEMNITY</u></p>
<p><u>16.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; THIRD PARTY BUSINESSES</u></p>
<p><u>17.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMMUNICATING WITH US</u></p>
<p><u>18.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LOSSES</u></p>
<p><u>19.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AMENDMENTS TO CONDITIONS OR ALTERATIONS OF SERVICE AND RELATED PROMISE</u></p>
<p><u>20.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; EVENTS BEYOND OUR CONTROL</u></p>
<p><u>21.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; WAIVER</u></p>
<p><u>22.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; TERMINATION</u></p>
<p><u>23.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GOVERNING LAW AND JURISDICTION</u></p>
<p><u>24.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONTACT US</u></p>
<p><u>25.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OUR SOFTWARE</u></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<h1>2.0&nbsp;&nbsp;&nbsp;&nbsp; SCOPE</h1>
<ul>
  <li>Helaviru Platform is in the business of providing services to facilitate E-commerce via its online marketplace at Helaviru - a platform that enables Customers and Merchants to transact online. The Merchant wishes to sell Products on the online marketplace platform provided by us. Helaviru offers multiple services to facilitate sales via its online platform. The Users of this site should comply with the terms &amp; conditions set out herein below.</li>
</ul>
<p>&nbsp;</p>
<ul>
  <li>Every transaction between the Merchant and the Customer on Helaviru Platform shall be governed by the terms and conditions of this User Agreement and details, annexes, appendices, platform policies referred herein and available with <a href="https://www.helaviru.lk">Helaviru Platform</a>. This agreement shall come into effect&nbsp;&nbsp; as soon as it is electronically accepted by the Customer.</li>
</ul>
<p>&nbsp;</p>
<ul>
  <li>Helaviru Platform may use the services of subcontractors to execute any part of the present agreement or any future services made available to the Merchant without any prior intimation.</li>
</ul>
<p>&nbsp;</p>
<ul>
  <li>Any reference in this Agreement to any provision of a statute shall be construed as a reference to that provision as amended, re-enacted or extended at the relevant time.</li>
</ul>
<p>&nbsp;</p>
<ul>
  <li>The platform policies are to be read and incorporated as an integral part of this agreement. Due to constant improvement of Helaviru Platform&rsquo;s services, platform policies will evolve and change over time. The Customer&rsquo;s use of the platform and access to the Customer portal is subject to this agreement and the latest platform policies available on Helaviru Platform.</li>
</ul>
<p>&nbsp;</p>
<ul>
  <li>In order to maintain its reputation for quality and high service standards, Helaviru Platform reserves the right to terminate the Customer log-in based on Helaviru Platform&rsquo;s internal quality assessment of the Customer as governed by Helaviru <a href="https://www.helaviru.lk/CustomerProtectionPolicy.html">Customer Protection Policy</a>.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h1>3.0&nbsp;&nbsp;&nbsp;&nbsp; INTERPRETATION &amp; DEFINITIONS</h1>
<p>In this agreement, the words and expressions below shall have the following meanings:</p>
<table>
  <tbody>
  <tr>
    <td width="153">
      <p>Bank Account</p>
    </td>
    <td width="519">
      <p>The bank account specified by the Helaviru Platform to which payments are to be made.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Business Day</p>
    </td>
    <td width="519">
      <p>A day (excluding Saturday and Sunday and public and bank holidays) on which banks generally are open for business in Sri Lanka.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Customer Protection Policy</p>
    </td>
    <td width="519">
      <p>Helaviru policy via which Customer Protection is kept in check.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Customer</p>
    </td>
    <td width="519">
      <p>A person, who purchases Goods or Services on the Helaviru Platform.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Merchant</p>
    </td>
    <td width="519">
      <p>A person, who is offering Goods or services on the Helaviru Platform.</p>
    </td>
  </tr>
  <tr>
    <td width="153">&nbsp;</td>
    <td width="519">&nbsp;</td>
  </tr>
  <tr>
    <td width="153">
      <p>Inventory</p>
    </td>
    <td width="519">
      <p>Fulfillment model whereby the Merchant is responsible for maintaining inventory of Products at own premises and making available for the Customers based on pre agreed delivery method in the Helaviru Platform.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Fee(s)</p>
    </td>
    <td width="519">
      <p>Any fees charged by Helaviru for services.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Final Delivery</p>
    </td>
    <td width="519">
      <p>The transfer of ownership of the Product from the Merchant to the end customer.</p>
    </td>
  </tr>
  <tr>
    <td width="153">&nbsp;</td>
    <td width="519">&nbsp;</td>
  </tr>
  <tr>
    <td width="153">&nbsp;</td>
    <td width="519">&nbsp;</td>
  </tr>
  <tr>
    <td width="153">
      <p>General Terms</p>
    </td>
    <td width="519">
      <p>The terms set out in this agreement.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Processing (Order)</p>
    </td>
    <td width="519">
      <p>An order, which had been accepted by the Merchant to Process and to be Dispatched according to the predefined delivery method.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Processing Time</p>
    </td>
    <td width="519">
      <p>The time from forwarding of the order by Helaviru Platform to the Merchant till packing and dispatch of the Product from the predefined location by the Merchant according to the predefined delivery method.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>In writing/written</p>
    </td>
    <td width="519">
      <p>All communications made through the Customer Portal or sent by Helaviru Platform through courier, post, SMS or email.</p>
    </td>
  </tr>
  <tr>
    <td width="153">&nbsp;</td>
    <td width="519">&nbsp;</td>
  </tr>
  <tr>
    <td width="153">
      <p>Intellectual Property</p>
    </td>
    <td width="519">
      <p>Any patent, copyright, registered or unregistered design, design right, registered or unregistered trademark, service mark or other industrial or intellectual property right and includes applications for any of them.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Listed Price</p>
    </td>
    <td width="519">
      <p>The listing price of the Product on the Platform and shall be the price at which the Helaviru Platform list the selling Price of the Product, which shall include any taxes, whether duties applicable in Sri Lanka.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Customer Privacy Policy</p>
    </td>
    <td width="519">
      <p>Privacy policy governing the Customers</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Pending</p>
    </td>
    <td width="519">
      <p>The status on the Helaviru Platform depicting that an order has been received and awaiting Acceptance of the Merchant.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Customer Payment Policy</p>
    </td>
    <td width="519">
      <p>Payment policy governing the Customers.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Customer Protection Policy</p>
    </td>
    <td width="519">
      <p>Protection against the order frauds to the customers.</p>
      <p>&nbsp;</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Platform</p>
    </td>
    <td width="519">
      <p>The website <a href="https://www.helaviru.lk">Helaviru Platform</a> or any affiliate website.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Platform Policies</p>
    </td>
    <td width="519">
      <p>All the policies and guidelines applicable to Customers available on Helaviru Platform.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Product(s)</p>
    </td>
    <td width="519">
      <p>The Goods or Services of which the Merchant intends to sell on the Platform.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Return Policy</p>
    </td>
    <td width="519">
      <p>The policy governing the return, refund, cancellation or rejection of Products and that can be viewed within Customer Return &amp; Refund Policy.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Returned Product</p>
    </td>
    <td width="519">
      <p>A Product that had been delivered but has been returned by the Customer, for any reason whatsoever.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Customer Support Center</p>
    </td>
    <td width="519">
      <p>The support service provided by Helaviru Platform to solve the issues faced by Customers as well as help Customers on their purchases.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Shipped</p>
    </td>
    <td width="519">
      <p>The Product dispatched from its Pre-defined location and on course for delivery to the Customer.</p>
    </td>
  </tr>
  <tr>
    <td width="153">
      <p>Signup Process</p>
    </td>
    <td width="519">
      <p>Process via which person(s) or entities sign up to buy or sell on Helaviru Platform.</p>
    </td>
  </tr>
  <tr>
    <td width="153">&nbsp;</td>
    <td width="519">&nbsp;</td>
  </tr>
  </tbody>
</table>
<h1>CONDITIONS OF USE</h1>
<h1>4.0&nbsp;&nbsp;&nbsp;&nbsp; YOUR ACCOUNT</h1>
<p>To access certain services offered by the platform, we may require that you create an account with us or provide personal information to complete the creation of an account. We may at any time at our sole and absolute discretion, invalidate the username and/or password without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by, caused by, arising out of, in connection with or by reason of such request or invalidation.</p>
<p>You are responsible for maintaining the confidentiality of your user identification, password, account details and related private information. You agree to accept this responsibility and ensure your account and its related details are maintained securely at all times and all necessary steps are taken to prevent misuse of your account. You should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner. You agree and acknowledge that any use of the Site and related services offered and/or any access to private information, data or communications using your account and password shall be deemed to be either performed by you or authorized by you as the case may be. You agree to be bound by any access of the Site and/or use of any services offered by the Site (whether such access or use are authorized by you or not). You agree that we shall be entitled (but not obliged) to act upon, rely on or hold you solely responsible and liable in respect thereof as if the same were carried out or transmitted by you. You further agree and acknowledge that you shall be bound by and agree to fully indemnify us against any and all losses arising from the use of or access to the Site through your account.</p>
<p>Please ensure that the details you provide us with are correct and complete at all times. You are obligated to update details about your account in real time by accessing your account online. For pieces of information you are not able to update by accessing Your Account on the Site, you must inform us via our customer service communication channels to assist you with these changes. We reserve the right to refuse access to the Site, terminate accounts, remove or edit content at any time without prior notice to you. We may at any time at our sole and absolute discretion, request that you update your Personal Data or forthwith invalidate the account or related details without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by or caused to you or arising out of or in connection with or by reason of such request or invalidation. You hereby agree to change your password from time to time on your own or when prompted by the system to keep your account secure and also shall be responsible for the confidentiality of your account and liable for any disclosure or use (whether such use is authorized or not) of the username and/or password.</p>
<p><a href="#_top">Back to Top</a></p>
<p><br /> &nbsp;</p>
<h1>5.0&nbsp;&nbsp;&nbsp;&nbsp; PRIVACY</h1>
<p>Please review our Customer Privacy Policy, which also governs your visit to the Site. The personal information / data provided to us by you or your use of the Site will be treated as strictly confidential, in accordance with the Customer Privacy Policy and applicable laws and regulations</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>6.0&nbsp;&nbsp;&nbsp;&nbsp; PLATFORM FOR COMMUNICATION&nbsp;</h1>
<p>You agree, understand and acknowledge that the Site is an online platform that enables you to purchase products listed at the price indicated therein at any time from any location using any available payment methods of your choice. You further agree and acknowledge that we are only a facilitator and cannot be a party to or control in any manner any transactions on the Site or on a payment gateway as made available to you by an independent service provider. Accordingly, the contract of sale of products on the Site shall be a strictly bipartite contract between you and the Merchants on our Site while the payment processing occurs between you, the service provider and in case of prepayments with electronic cards your issuer bank. Accordingly, the contract of payment on the Site shall be strictly a bipartite contract between you and the service provider as listed on our Site.</p>
<p><a href="#_top">Back to Top</a></p>
<p><u>&nbsp;</u></p>
<p><u>&nbsp;</u></p>
<h1>7.0&nbsp;&nbsp;&nbsp;&nbsp; CONTINUED AVAILABILITY OF THE SITE</h1>
<p>We do not guarantee that the service will be uninterrupted or error free. We will provide every effort in good faith to ensure that its services are available as much as possible with minimal service interruptions.</p>
<p>In no circumstance will Helaviru Platform be liable for any claims resulting from the use or inability to use Helaviru services- including, but not limited to service interruptions, client errors, internet connectivity problems, miscommunications, unauthorized access to Helaviru servers, Internet bandwidth congestion, power failures, vandalism and natural disasters. HELAVIRU PLATFORM SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT , LOSS OF BUSINESS OR BUSINESS OPPORTUNITY, LOSS OF USE, LOSS OF DATA ETC. even if advised of the possibility of such damages.</p>
<p><a href="#_top">Back to Top</a>&nbsp;</p>
<h1>8.0&nbsp;&nbsp;&nbsp;&nbsp; LICENSE TO ACCESS THE SITE</h1>
<p>We require that by accessing the Site, you confirm that you can form legally binding contracts and therefore you confirm that you are at least 18 years of age or are accessing the Site under the supervision of a parent or legal guardian. We grant you a non-transferable, revocable and non-exclusive license to use the Site, in accordance with the Terms and Conditions described herein, for the purposes of shopping for personal items and services as listed on the Site. Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by us in advance. Any breach of these Terms and Conditions shall result in the immediate revocation of the license granted in this paragraph without notice to you.</p>
<p>Content provided on this Site is for informational and transactional purposes. Product representations including price, available stock, features, add-ons and any other details as expressed on this Site are the responsibility of the vendors. Submissions or opinions expressed on this Site are those of the individual(s) posting such content and may not reflect our opinions.</p>
<p>We grant you a limited license to access and make personal use of this Site, but not to download (excluding page caches) or modify the Site or any portion of it in any manner. This license does not include;</p>
<ul>
  <li>any resale or commercial use of this Site or its contents;</li>
  <li>any collection and use of any product listings, descriptions, or prices;</li>
  <li>any derivative use of this Site or its contents;</li>
  <li>any downloading or copying of account information for the benefit of another Merchant;</li>
  <li>any use of data mining, robots, or similar data gathering and extraction tools.</li>
</ul>
<p>&nbsp;</p>
<p>This Site or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent by us as may be applicable.<br /> <br /> You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) without our express written consent. You may not use any meta tags or any other text utilizing our name or trademark without our express written consent, as applicable. Any unauthorized use terminates the permission or license granted by us to you for access to the Site with no prior notice. You may not use our logo or other proprietary graphic or trademark as part of an external link for commercial or other purposes without our express written consent, as may be applicable.</p>
<p><br /> <a href="#_top">Back to Top</a></p>
<p><br /> </p>
<h1>9.0&nbsp;&nbsp;&nbsp;&nbsp; YOUR CONDUCT&nbsp;</h1>
<p>You agree and undertake not to perform restricted activities listed within this section; undertaking these activities will result in an immediate cancellation of your account, services, reviews, orders or any existing incomplete transaction with us and in severe cases may also result in legal action.</p>
<p>You must not use the website in any way that causes, or is likely to cause, the Site or access to it to be interrupted, damaged or impaired in any way. You must not engage in activities that could harm or potentially harm the Site, its employees, officers, representatives, stakeholders or any other party directly or indirectly associated with the Site or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not us, are responsible for all electronic communications and content sent from your computer to us and you must use the Site for lawful purposes only. You are strictly prohibited from using the Site for;</p>
<p>9.1 fraudulent purposes, or in connection with a criminal offense or other unlawful activity to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, religious, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Sri Lanka or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam&rdquo; Use the Site for illegal purposes.</p>
<ul>
  <li>To cause annoyance, inconvenience or needless anxiety.</li>
</ul>
<p>9.3 for any other purposes that is other than what is intended by us.</p>
<p>9.4 Impersonate any person or entity or to falsely state or otherwise misrepresent your affiliation with any</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; person or entity.</p>
<ul>
  <li>Attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Platform or Services. (<a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ComputerCrimesActNo24of2007.pdf">Computer Crimes Act No. 24 of 2007</a>)</li>
  <li>Use or upload, in any way, any software or material that contains, or which you have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Site&rsquo;s data or damage or interfere with the operation of another Customer&rsquo;s computer or mobile device or the Site and use the Site other than in conformance with the acceptable use policies of any connected computer networks, any applicable Internet standards and any other applicable laws.</li>
</ul>
<p>&nbsp;</p>
<p><a href="#_top">Back to Top</a></p>
<h1>10.0&nbsp;&nbsp; YOUR SUBMISSION</h1>
<p>Anything that you submit to the Site and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, "Submissions") will become our sole and exclusive property and shall not be returned to you. In addition to the rights applicable to any Submission, when you post comments or reviews to the Site, you also grant us the right to use the name that you submit, in connection with such review, comment, or other content. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may, but shall not be obligated to, remove or edit any Submissions without any notice or legal course applicable to us in this regard.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>11.0&nbsp;&nbsp; CLAIMS AGAINST OBJECTIONABLE CONTENT</h1>
<p>We list thousands of products for sale offered by numerous Merchants on the Site and host multiple comments on listings. Therefore, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, we operate on a "claim, review and takedown" basis. If you believe that any content on the Site is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, religious, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Sri Lanka or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (" objectionable content "), you may notify us immediately in writing via the email address: legal@helaviru.lk . We will make all practical endeavors to investigate and remove the objectionable content so complained about within a reasonable period of time.</p>
<p>Please ensure to provide your name, address, contact information and as many relevant details of the claim as possible including name of objectionable content party, instances of objection, proof of objection amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes.<br /> <br /> <a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>12.0&nbsp;&nbsp; CLAIMS AGAINST INFRINGING CONTENT</h1>
<p>We respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please inform us via legal@helaviru.lk . We will make all reasonable efforts to address your concern within a reasonable period of time. Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of infringing party, instances of infringement, proof of infringement amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes. In addition, providing false or misleading information may be considered a legal offense and may be followed by legal proceedings in accordance with <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/03/IntellectualPropertyActNo.36of2003Sectionsr.pdf">Intellectual Property Act no. 36 of 2003</a> which is published by the government.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>13.0&nbsp;&nbsp; TRADEMARKS AND COPYRIGHTS</h1>
<p>Helaviru Platform, Helaviru Basics, Helaviru logo, and other Helaviru related marks indicated on our Site are trademarks or registered trademarks in the relevant jurisdiction(s). Our graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress and may not be used in connection with any product or service that does not belong to us or in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits us. All other trademarks that appear on this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.</p>
<p>The entire contents of the Site also are protected by copyright, trademark, service mark, patent or other proprietary rights and laws as under applicable laws and international conventions. All rights are reserved.</p>
<p>Helaviru reserves the copyrights and other intellectual property rights pertaining to the information displayed on the Website and the mobile app including, but not limiting to text, graphics, logos, software, source code, images, trademarks. Helaviru does not transfer to the user to copy any documents or any other site materials. Any reproduction without Helaviru Platform&rsquo;s written consent is illegal.</p>
<p><a href="#_top">Back to Top</a></p>
<p><span style="text-decoration: line-through;">&nbsp;</span></p>
<p><span style="text-decoration: line-through;">&nbsp;</span></p>
<h1>14.0&nbsp;&nbsp; DISCLAIMER</h1>
<p>You acknowledge and undertake that you are accessing the services on the Site and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the Site. We shall neither be liable nor responsible for any actions or inactions of Merchants nor any breach of conditions, representations or warranties by the Merchants or manufacturers of the products and hereby expressly disclaim and any all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between you and the Merchants or manufacturers of the products.</p>
<p>We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content (including product or pricing information and/or specifications) on the Site. While we have taken precautions to avoid inaccuracies in content, this Site, all content, information (including the price of products), software, products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the Site. At no time shall any right, title or interest in the products sold through or displayed on the Site vest with us nor shall Helaviru Platform have any obligations or liabilities in respect of any transactions on the Site.</p>
<p>We shall neither be liable or responsible for any actions or inactions of any other service provider as listed on our Site which includes but is not limited to payment providers, instalment offerings, warranty services amongst others.</p>
<p>We do not guarantee that the service will be uninterrupted or error free. We will provide every effort in good faith to ensure that its services are available as much as possible with minimal service interruptions.</p>
<p>In no circumstance will Helaviru Platform be liable for any claims resulting from the use or inability to use Helaviru services- including, but not limited to service interruptions, client errors, internet connectivity problems, miscommunications, unauthorized access to Helaviru Platform servers, Internet bandwidth congestion, power failures, vandalism and natural disasters. HELAVIRU PLATFROM SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT , LOSS OF BUSINESS OR BUSINESS OPPORTUNITY, LOSS OF USE ETC. even if advised of the possibility of such damages.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>15.0&nbsp;&nbsp; INDEMNITY</h1>
<p>You shall indemnify and hold harmless Sri Lanka Telecom PLC, the owner of the Helaviru Platform, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms and Conditions or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.</p>
<p>You hereby expressly release Sri Lanka Telecom PLC, the owner of Helaviru Platform and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the Merchants or other service providers and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise. Your only remedy is to discontinue using the Site and its services.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>16.0&nbsp;&nbsp; THIRD PARTY BUSINESSES</h1>
<p>Parties other than Epic Green, SLT and their affiliates may operate stores, provide services, or sell product lines on the Site. For example, businesses and individuals offer products via Marketplace. In addition, we provide links to the websites of affiliated companies and certain other businesses. We are not responsible for examining or evaluating, and we do not warrant or endorse the offerings of any of these businesses or individuals, or the content of their websites. We do not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. You can tell when a third-party is involved in your transactions by reviewing your transaction carefully, and we may share customer information related to those transactions with that third-party incompliance with Customer Privacy Policy. You should carefully review their privacy statements and related terms and conditions.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>17.0&nbsp;&nbsp; COMMUNICATING WITH US</h1>
<p>When you visit the Site, or send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number and a valid email address while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the Site or by any other mode of communication we choose to employ. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website (and/or placement of your order) and agree to treat all modes of communication with the same importance.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h1>18.0&nbsp;&nbsp; LOSSES</h1>
<p>We will not be responsible for any personal losses (including but not limited to anticipated savings, expected delivery time, goodwill, or wasted expenditure) or any other indirect or consequential loss .</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>19.0&nbsp;&nbsp; AMENDMENTS TO CONDITIONS OR ALTERATIONS OF SERVICE AND RELATED PROMISE</h1>
<p>We reserve the right to make changes to the Site, its policies, these terms and conditions and any other publicly displayed condition or service promise at any time. You will be subject to the policies and terms and conditions in force at the time you used the Site unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>20.0&nbsp;&nbsp; EVENTS BEYOND OUR CONTROL</h1>
<p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1>21.0&nbsp;&nbsp; WAIVER</h1>
<p>You acknowledge and reserve the right to conduct business to achieve our objectives in a manner we deem fit. You also acknowledge that if you breach the conditions stated on our Site and we take no action, we are still entitled to use our rights and remedies in any other situation where you breach these conditions.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h1>22.0&nbsp;&nbsp; TERMINATION</h1>
<p>In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions. Upon any termination of this Agreement, you shall immediately cease all access to and use of the Site and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Site in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Site shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the Site or with any terms, conditions, rules, policies, guidelines, or practices in operating the Site, your sole and exclusive remedy is to discontinue using the Site.</p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h1>23.0&nbsp;&nbsp; GOVERNING LAW AND JURISDICTION</h1>
<p>These terms and conditions are governed by and construed in accordance with the laws of The Democratic Socialist Republic of Sri Lanka. You agree, as we do, to submit to the exclusive jurisdiction of the courts, of Sri Lanka having jurisdiction over the place on which our Registered Office is situated at the time of institution of legal proceedings. Our site strictly adheres to the below mentioned statutes which are enforced by the Sri Lankan Government.</p>
<p>23.1&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/Information_and_Communication_Technology_Act_No.27.pdf">Information and Communication Technology Act No.27 of 2003</a></p>
<p>23.2&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/03/IntellectualPropertyActNo.36of2003Sectionsr.pdf">Intellectual Property Act no. 36 of 2003</a></p>
<p>23.3&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ElectronicTransactionActNo19of2006.pdf">Electronic Transactions Act No. 19 of 2006</a></p>
<p>23.4&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ComputerCrimesActNo24of2007.pdf">Computer Crimes Act No. 24 of 2007</a></p>
<p>23.5&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/PaymentandSettlementSystemActNo.28of2005.pdf">Payment and Settlement Systems Act, No. 28 of 2005</a></p>
<p>23.6&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/Payment_devices_FraudsActNo.30of2006.pdf">Payment Devices Frauds Act No.30 of 2006</a></p>
<p><a href="#_top">Back to Top</a></p>
<p>&nbsp;</p>
<h1><br /> 24.0&nbsp;&nbsp; CONTACT US</h1>
<p>You may reach us&nbsp;<a href="https://www.helaviru.lk/contact-us">here</a></p>
<p><br /> </p>
<h1>25.0&nbsp;&nbsp; OUR SOFTWARE</h1>
<p>Our software includes any software (including any updates or upgrades to the software and any related documentation) that we make available to you from time to time for your use in connection with the Site (the "Software").</p>
<p>You may use the software solely for purposes of enabling you to use and enjoy our services as permitted by the Terms and Conditions and any related applicable terms as available on the Site. You may not incorporate any portion of the Software into your own programs or compile any portion of it in combination with your own programs, transfer it for use with another service, or sell, rent, lease, lend, loan, distribute or sub-license the Software or otherwise assign any rights to the Software in whole or in part. You may not use the Software for any illegal purpose. We may cease providing you service and we may terminate your right to use the Software at any time. Your rights to use the Software will automatically terminate without notice from us if you fail to comply with any of the Terms and Conditions listed here or across the Site. Additional third-party terms contained within the Site or distributed as such that are specifically identified in related documentation may apply and will govern the use of such software in the event of a conflict with these Terms and Conditions. All software used in any of our services is our property and/or our affiliates or its software suppliers and protected by the laws of Sri Lanka including but not limited to any other applicable copyright laws.</p>
<p>When you use the Site, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile platform provider. Your use of these third-party services may be subject to separate policies, terms of use, and fees of these third parties.</p>
<p>You may not, and you will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with our software whether in whole or in part, or create any derivative works from or of the Software.</p>
<p>In order to keep the Software up to date, we may offer automatic or manual updates at any time and without notice to you.</p>
<p><a href="#_top">Back to Top</a></p>
<p><span style="text-decoration: line-through;">&nbsp;</span></p>
</body>
<!--</html>-->
