<div class="container-fluid">
  <!DOCTYPE html>
  <html>
  <head>
    <title></title>
  </head>

  <body>
  <h4
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <span id="_top" style="font-size:27px;">Terms and Conditions</span>
  </h4>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    Please read the Terms and Conditions carefully before using Helaviru.lk
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >This document is an electronic record in terms of the provisions under
        Electronic Transactions Act. No 19 of 2006 as applicable and provisions
        pertaining to electronic records in various statutes as amended by
        the&nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ElectronicTransactionActNo19of2006.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Electronic Transactions Act No. 19 of 2006</span
  ></a
  ><span style="line-height:115%;">.</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >This electronic record is generated by a computer system and does not
        require any physical or digital signatures.</span
      >
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    1. INTRODUCTION
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    Welcome to Helaviru Digital Market Place (hereinafter called and referred
    as &ldquo;we&quot;, &quot;us&quot;, &quot;Helaviru&quot; or
    &ldquo;Helaviru Platform&rdquo;. We are an online marketplace and below
    are the terms and conditions governing your access and use of Helaviru
    Platform along with its related sub-domains, sites, mobile app, services
    and tools (hereinafter called and referred as &quot;Site&quot;). By using
    the Site, you hereby accept these terms and conditions (including the
    linked information herein) and represent that you agree to comply with
    these terms and conditions (hereinafter called and referred as &quot;User
    Agreement&quot;). This User Agreement is deemed effective upon your use of
    the Site which signifies your acceptance of these terms. If you do not
    agree to be bound by this User Agreement, please do not access, register
    with or use this Site. This Site is owned and operated jointly by Epic
    Green Eco Systems (Private) Limited (hereinafter called and referred as
    &ldquo;Epic Green&rdquo;) and Sri Lanka Telecom PLC (PQ 7) (hereinafter
    called and referred as &ldquo;SLT&rdquo;).
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    Epic Green and SLT reserves the right to change, modify, add, or remove
    portions of these Terms and Conditions at any time without any prior
    notification. Changes will be effective when posted on the Site with no
    other notice provided. Please check these Terms and Conditions regularly
    for updates. Your continued use of the Site following the posting of
    changes to Terms and Conditions of use constitutes your acceptance of
    those changes.
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    GENERAL TERMS
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >2.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;SCOPE</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >3.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span
      style='font-family:"Times New Roman",serif;font-weight:bold;'
    ><span
      style='line-height:107%;font-family:"Calibri Light",sans-serif;font-weight:normal;'
    >INTERPRETATION &amp; DEFINITIONS</span
    ></span
    ></span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >4.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;YOUR ACCOUNT</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >5.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;PRIVACY</span
    ></u
    ><u
  ><span
    style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
  >&nbsp;</span
  ></u
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >6.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;PLATFORM FOR
          COMMUNICATION</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >7.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;CONTINUED AVAILABILITY OF
          THE SITE</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >8.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;LICENSE TO ACCESS THE
          SITE</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >9.0&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;YOUR CONDUCT</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >10.0&nbsp; &nbsp; &nbsp; &nbsp;YOUR SUBMISSION</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >11.0&nbsp; &nbsp; &nbsp; &nbsp;CLAIMS AGAINST OBJECTIONABLE
          CONTENT</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >12.0&nbsp; &nbsp; &nbsp; &nbsp;CLAIMS AGAINST INFRINGING
          CONTENT</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >13.0&nbsp; &nbsp; &nbsp; &nbsp;TRADEMARKS AND COPYRIGHTS</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >14.0&nbsp; &nbsp; &nbsp; &nbsp;DISCLAIMER</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >15.0&nbsp; &nbsp; &nbsp; &nbsp;INDEMNITY</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >16.0&nbsp; &nbsp; &nbsp; &nbsp;THIRD PARTY BUSINESSES</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >17.0&nbsp; &nbsp; &nbsp; &nbsp;COMMUNICATING WITH US</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >18.0&nbsp; &nbsp; &nbsp; &nbsp;LOSSES</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >19.0&nbsp; &nbsp; &nbsp; &nbsp;AMENDMENTS TO CONDITIONS OR
          ALTERATIONS OF SERVICE AND RELATED PROMISE</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >20.0&nbsp; &nbsp; &nbsp; &nbsp;EVENTS BEYOND OUR CONTROL</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >21.0&nbsp; &nbsp; &nbsp; &nbsp;WAIVER</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >22.0&nbsp; &nbsp; &nbsp; &nbsp;TERMINATION</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >23.0&nbsp; &nbsp; &nbsp; &nbsp;GOVERNING LAW AND JURISDICTION</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >24.0&nbsp; &nbsp; &nbsp; &nbsp;CONTACT US</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <u
    ><span
      style='font-size:15px;line-height:107%;font-family:"Calibri Light",sans-serif;'
    >25.0&nbsp; &nbsp; &nbsp; &nbsp;OUR SOFTWARE</span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    2.0&nbsp; &nbsp; &nbsp;SCOPE
  </h1>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >Helaviru Platform is in the business of providing services to
            facilitate E-commerce via its online marketplace at Helaviru - a
            platform that enables Customers and Merchants to transact online.
            The Merchant wishes to sell Products on the online marketplace
            platform provided by us. Helaviru offers multiple services to
            facilitate sales via its online platform. The Users of this site
            should comply with the terms &amp; conditions set out herein
            below.</span
          >
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.25in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >Every transaction between the Merchant and the Customer on Helaviru
            Platform shall be governed by the terms and conditions of this User
            Agreement and &nbsp;details, annexes, appendices, platform policies
            referred herein and available with&nbsp;</span
          ><a href="https://www.helaviru.lk"
      ><span style="line-height:115%;color:windowtext;"
      >Helaviru Platform</span
      ></a
      ><span
        style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
      >. This agreement shall come into effect &nbsp; as soon as it is
            electronically accepted by the Customer.</span
      >
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >Helaviru Platform may use the services of subcontractors to execute
            any part of the present agreement or any future services made
            available to the Merchant without any prior intimation.</span
          >
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >Any reference in this Agreement to any provision of a statute shall
            be construed as a reference to that provision as amended, re-enacted
            or extended at the relevant time.</span
          >
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >The platform policies are to be read and incorporated as an
            integral part of this agreement. Due to constant improvement of
            Helaviru Platform&rsquo;s services, platform policies will evolve
            and change over time. The Customer&rsquo;s use of the platform and
            access to the Customer<s>&nbsp;</s>portal is subject to this
            agreement and the latest platform policies available on Helaviru
            Platform.</span
          >
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <div
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >In order to maintain its reputation for quality and high service
            standards, Helaviru Platform reserves the right to terminate the
            Customer log-in based on Helaviru Platform&rsquo;s internal quality
            assessment of the Customer as governed by Helaviru&nbsp;</span
          ><a href="https://www.helaviru.lk/CustomerProtectionPolicy.html"
      ><span
        style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
      >Customer Protection Policy</span
      ></a
      ><span
        style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
      >.</span
      >
      </li>
    </ol>
  </div>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:.5in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;'
  >
    &nbsp;
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:.5in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;'
  >
    &nbsp;
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:.5in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;'
  >
    &nbsp;
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    3.0&nbsp; &nbsp; &nbsp;<span
    style='font-family:"Times New Roman",serif;font-weight:bold;'
  >INTERPRETATION &amp; DEFINITIONS</span
  >
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
  >
      <span style="line-height:115%;"
      >In this agreement, the words and expressions below shall have the
        following meanings:</span
      >
  </p>
  <table cellspacing="3" style="border: none;background:#FDFDFD;">
    <tbody>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Bank Account</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The bank account specified by the Helaviru Platform to which
                payments are to be made.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Business Day</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >A day (excluding Saturday and Sunday and public and bank
                holidays) on which banks generally are open for business in Sri
                Lanka.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Customer Protection Policy</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Helaviru policy via which Customer Protection is kept in
                check.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Customer</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >A person, who purchases Goods or Services on the Helaviru
                Platform.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Merchant</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >A person, who is offering Goods or services on the Helaviru
                Platform.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Inventory</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Fulfillment model whereby the Merchant is responsible for
                maintaining inventory of Products at own premises and making
                available for the Customers based on pre agreed delivery method
                in the Helaviru Platform.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Fee(s)</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Any fees charged by Helaviru for services.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Final Delivery</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The transfer of ownership of the Product from the Merchant to
                the end customer.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">General Terms</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The terms set out in this agreement.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Processing (Order)</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >An order, which had been accepted by the Merchant to Process
                and to be Dispatched according to the predefined delivery
                method.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Processing Time</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The time from forwarding of the order by Helaviru Platform to
                the Merchant till packing and dispatch of the Product from the
                predefined location by the Merchant according to the predefined
                delivery method.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">In writing/written</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >All communications made through the Customer Portal or sent by
                Helaviru Platform through courier, post, SMS or email.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Intellectual Property</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Any patent, copyright, registered or unregistered design,
                design right, registered or unregistered trademark, service mark
                or other industrial or intellectual property right and includes
                applications for any of them.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Listed Price</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The listing price of the Product on the Platform and shall be
                the price at which the Helaviru Platform list the selling Price
                of the Product, which shall include any taxes, whether duties
                applicable in Sri Lanka.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Customer Privacy Policy</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:  115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Privacy policy governing the Customers</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Pending</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The status on the Helaviru Platform depicting that an order has
                been received and awaiting Acceptance of the Merchant.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Customer Payment Policy</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Payment policy governing the Customers.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Customer Protection Policy</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Protection against the order frauds to the customers.</span
              >
        </p>
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">&nbsp;</span>
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Platform</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;">The website&nbsp;</span
              ><a href="https://www.helaviru.lk"
        ><span style="line-height:115%;">Helaviru Platform</span></a
        ><span style="line-height:115%;"
        >&nbsp;or any affiliate website.</span
        >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Platform Policies</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >All the policies and guidelines applicable to Customers
                available on Helaviru Platform.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Product(s)</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The Goods or Services of which the Merchant intends to sell on
                the Platform.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Return Policy</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The policy governing the return, refund, cancellation or
                rejection of Products and that can be viewed within&nbsp;</span
              ><a
          href="file:///C%3A/Users/007178/AppData/Local/Temp/7zOC58D8C13/Customer%20Return%20and%20Refund%20Policy.docx"
        ><span style="line-height:115%;"
        >Customer Return &amp; Refund Policy</span
        ></a
        ><span style="color:blue;text-decoration:underline;"
        ><span style="line-height:115%;">.</span></span
        >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Returned Product</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >A Product that had been delivered but has been returned by the
                Customer, for any reason whatsoever.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Customer Support Center</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The support service provided by Helaviru Platform to solve the
                issues faced by Customers as well as help Customers on their
                purchases.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Shipped</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >The Product dispatched from its Pre-defined location and on
                course for delivery to the Customer.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
          <span style="line-height:115%;">Signup Process</span>
        </p>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <p
          style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:left;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;'
        >
              <span style="line-height:115%;"
              >Process via which person(s) or entities sign up to buy or sell
                on Helaviru Platform.</span
              >
        </p>
      </td>
    </tr>
    <tr>
      <td style="width: 114.75pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
      <td style="width: 389.25pt;padding: 0.75pt;vertical-align: top;">
        <br/>
      </td>
    </tr>
    </tbody>
  </table>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    CONDITIONS OF USE
  </h1>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    4.0&nbsp; &nbsp; &nbsp;YOUR ACCOUNT
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >To access certain services offered by the platform, we may require that
        you create an account with us or provide personal information to
        complete the creation of an account. We may at any time at our sole and
        absolute discretion, invalidate the username and/or password without
        giving any reason or prior notice and shall not be liable or responsible
        for any losses suffered by, caused by, arising out of, in connection
        with or by reason of such request or invalidation.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You are responsible for maintaining the confidentiality of your user
        identification, password, account details and related private
        information. You agree to accept this responsibility and ensure your
        account and its related details are maintained securely at all times and
        all necessary steps are taken to prevent misuse of your account. You
        should inform us immediately if you have any reason to believe that your
        password has become known to anyone else, or if the password is being,
        or is likely to be, used in an unauthorized manner. You agree and
        acknowledge that any use of the Site and related services offered and/or
        any access to private information, data or communications using your
        account and password shall be deemed to be either performed by you or
        authorized by you as the case may be. You agree to be bound by any
        access of the Site and/or use of any services offered by the Site
        (whether such access or use are authorized by you or not). You agree
        that we shall be entitled (but not obliged) to act upon, rely on or hold
        you solely responsible and liable in respect thereof as if the same were
        carried out or transmitted by you. You further agree and acknowledge
        that you shall be bound by and agree to fully indemnify us against any
        and all losses arising from the use of or access to the Site through
        your account.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Please ensure that the details you provide us with are correct and
        complete at all times. You are obligated to update details about your
        account in real time by accessing your account online. For pieces of
        information you are not able to update by accessing Your Account on the
        Site, you must inform us via our customer service communication channels
        to assist you with these changes. We reserve the right to refuse access
        to the Site, terminate accounts, remove or edit content at any time
        without prior notice to you. We may at any time at our sole and absolute
        discretion, request that you update your Personal Data or forthwith
        invalidate the account or related details without giving any reason or
        prior notice and shall not be liable or responsible for any losses
        suffered by or caused to you or arising out of or in connection with or
        by reason of such request or invalidation. You hereby agree to change
        your password from time to time on your own or when prompted by the
        system to keep your account secure and also shall be responsible for the
        confidentiality of your account and liable for any disclosure or use
        (whether such use is authorized or not) of the username and/or
        password.&nbsp;</span
      >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;"><br/>&nbsp;&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    5.0&nbsp; &nbsp; &nbsp;PRIVACY
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Please review our Customer Privacy Policy, which also governs your
        visit to the Site. The personal information / data provided to us by you
        or your use of the Site will be treated as strictly confidential, in
        accordance with the&nbsp;</span
      ><a
    href="file:///C%3A/Users/isuru_b/Downloads/C_Annex_Customer%20payment%20policy%2001-05-2020.docx"
  ><span style="line-height:115%;">Customer Privacy Policy</span></a
  ><span style="line-height:115%;"
  >&nbsp;and applicable laws and regulations</span
  >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    6.0&nbsp; &nbsp; &nbsp;PLATFORM FOR COMMUNICATION&nbsp;
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You agree, understand and acknowledge that the Site is an online
        platform that enables you to purchase products listed at the price
        indicated therein at any time from any location using any available
        payment methods of your choice. You further agree and acknowledge that
        we are only a facilitator and cannot be a party to or control in any
        manner any transactions on the Site or on a payment gateway as made
        available to you by an independent service provider. Accordingly, the
        contract of sale of products on the Site shall be a strictly bipartite
        contract between you and the Merchants on our Site while the payment
        processing occurs between you, the service provider and in case of
        prepayments with electronic cards your issuer bank. Accordingly, the
        contract of payment on the Site shall be strictly a bipartite contract
        between you and the service provider as listed on our Site.</span
      >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <u
    ><span style="font-size:14px;"
    ><span style="text-decoration:none;">&nbsp;</span></span
    ></u
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <u
    ><span style="font-size:14px;"
    ><span style="text-decoration:none;">&nbsp;</span></span
    ></u
    >
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    7.0&nbsp; &nbsp; &nbsp;CONTINUED AVAILABILITY OF THE SITE
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;vertical-align:baseline;'
  >
      <span style="line-height:115%;"
      >We do not guarantee that the service will be uninterrupted or error
        free. We will provide every effort in good faith to ensure that its
        services are available as much as possible with minimal service
        interruptions.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;vertical-align:baseline;'
  >
      <span style="line-height:115%;"
      >In no circumstance will Helaviru Platform be liable for any claims
        resulting from the use or inability to use Helaviru services- including,
        but not limited to service interruptions, client errors, internet
        connectivity problems, miscommunications, unauthorized access to
        Helaviru servers, Internet bandwidth congestion, power failures,
        vandalism and natural disasters.&nbsp;HELAVIRU PLATFORM SHALL NOT BE
        LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
        PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT , LOSS OF
        BUSINESS OR BUSINESS OPPORTUNITY, LOSS OF USE, LOSS OF DATA ETC. even if
        advised of the possibility of such damages.&nbsp;</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;vertical-align:baseline;'
  >

    ><span style="font-size:14px;line-height:115%;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    8.0&nbsp; &nbsp; &nbsp;LICENSE TO ACCESS THE SITE
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We require that by accessing the Site, you confirm that you can form
        legally binding contracts and therefore you confirm that you are at
        least 18 years of age or are accessing the Site under the supervision of
        a parent or legal guardian. We grant you a non-transferable, revocable
        and non-exclusive license to use the Site, in accordance with the Terms
        and Conditions described herein, for the purposes of shopping for
        personal items and services as listed on the Site. Commercial use or use
        on behalf of any third party is prohibited, except as explicitly
        permitted by us in advance. Any breach of these Terms and Conditions
        shall result in the immediate revocation of the license granted in this
        paragraph without notice to you.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Content provided on this Site is for informational and transactional
        purposes. Product representations including price, available stock,
        features, add-ons and any other details as expressed on this Site are
        the responsibility of the vendors. Submissions or opinions expressed on
        this Site are those of the individual(s) posting such content and may
        not reflect our opinions.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We grant you a limited license to access and make personal use of this
        Site, but not to download (excluding page caches) or modify the Site or
        any portion of it in any manner. This license does not include;</span
      >
  </p>
  <ol class="decimal_type" style="list-style-type: undefined;">
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
        >any resale or commercial use of this Site or its
          contents;&nbsp;</span
        >
    </li>
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
        >any collection and use of any product listings, descriptions, or
          prices;</span
        >
    </li>
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
        >any derivative use of this Site or its contents;</span
        >
    </li>
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
        >any downloading or copying of account information for the benefit of
          another Merchant;</span
        >
    </li>
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
        >any use of data mining, robots, or similar data gathering and
          extraction tools.</span
        >
    </li>
  </ol>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >This Site or any portion of it (including but not limited to any
        copyrighted material, trademarks, or other proprietary information) may
        not be reproduced, duplicated, copied, sold, resold, visited,
        distributed or otherwise exploited for any commercial purpose without
        express written consent by us as may be applicable.<br/>&nbsp;<br/>&nbsp;You
        may not frame or use framing techniques to enclose any trademark, logo,
        or other proprietary information (including images, text, page layout,
        or form) without our express written consent. You may not use any meta
        tags or any other text utilizing our name or trademark without our
        express written consent, as applicable. Any unauthorized use terminates
        the permission or license granted by us to you for access to the Site
        with no prior notice. You may not use our logo or other proprietary
        graphic or trademark as part of an external link for commercial or other
        purposes without our express written consent, as may be
        applicable.</span
      >
  </p>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"><br/>&nbsp;</span
      ><a href="#_top" target="_self"
  ><span style="line-height:115%;color:windowtext;">Back to Top</span></a
  >
  </p>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;line-height:115%;"><br/>&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    9.0&nbsp; &nbsp; &nbsp;YOUR CONDUCT&nbsp;
  </h1>
  <p
    style='margin: 0in 0in 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You agree and undertake not to perform restricted activities listed
        within this section; undertaking these activities will result in an
        immediate cancellation of your account, services, reviews, orders or any
        existing incomplete transaction with us and in severe cases may also
        result in legal action.</span
      >
  </p>
  <p
    style='margin: 0in 0in 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You must not use the website in any way that causes, or is likely to
        cause, the Site or access to it to be interrupted, damaged or impaired
        in any way. You must not engage in activities that could harm or
        potentially harm the Site, its employees, officers, representatives,
        stakeholders or any other party directly or indirectly associated with
        the Site or access to it to be interrupted, damaged or impaired in any
        way. You understand that you, and not us, are responsible for all
        electronic communications and content sent from your computer to us and
        you must use the Site for lawful purposes only. You are strictly
        prohibited from using the Site for;</span
      >
  </p>
  <p
    style='margin: 0in 0in 8.0pt .25in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;text-indent:-.25in;background:white;'
  >
      <span style="line-height:115%;"
      >9.1 fraudulent purposes, or in connection with a criminal offense or
        other unlawful activity to send, use or reuse any material that does not
        belong to you; or is illegal, offensive (including but not limited to
        material that is sexually explicit content or which promotes racism,
        religious, bigotry, hatred or physical harm), deceptive, misleading,
        abusive, indecent, harassing, blasphemous, defamatory, libellous,
        obscene, pornographic, paedophilia or menacing; ethnically
        objectionable, disparaging or in breach of copyright, trademark,
        confidentiality, privacy or any other proprietary information or right;
        or is otherwise injurious to third parties; or relates to or promotes
        money laundering or gambling; or is harmful to minors in any way; or
        impersonates another person; or threatens the unity, integrity, security
        or sovereignty of Sri Lanka or friendly relations with foreign States;
        or objectionable or otherwise unlawful in any manner whatsoever; or
        which consists of or contains software viruses, political campaigning,
        commercial solicitation, chain letters, mass mailings or any
        &quot;spam&rdquo; Use the Site for illegal purposes.</span
      >
  </p>
  <div
    style='margin: 0in 0in 8.0pt;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
  >
    <ol style="margin-bottom:0in;list-style-type: undefined;">
      <li
        style='margin: 0in 0in 8.0pt;text-align:justify;line-height:107%;font-size:16px;font-family:"Times New Roman",serif;'
      >
          <span
            style='line-height:115%;font-family:"Times New Roman";font-size:12.0pt;'
          >To cause annoyance, inconvenience or needless anxiety.</span
          >
      </li>
    </ol>
  </div>
  <p
    style='margin: 0in 0in 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >9.3 for any other purposes that is other than what is intended by
        us.</span
      >
  </p>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >9.4 Impersonate any person or entity or to falsely state or otherwise
        misrepresent your affiliation with any&nbsp;</span
      >
  </p>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >&nbsp; &nbsp; &nbsp; person or entity.</span
      >
  </p>
  <ol class="decimal_type" style="list-style-type: undefined;">
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman",serif;font-size:12.0pt;'
        >Attempt to gain unauthorized access to or otherwise interfere or
          disrupt other computer systems or networks connected to the Platform
          or Services. (</span
        ><a
      href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ComputerCrimesActNo24of2007.pdf"
      target="_blank"
    ><span
      style='line-height:115%;font-family:"Times New Roman",serif;border:none windowtext 1.0pt;background:white;'
    >Computer Crimes Act No. 24 of 2007</span
    ></a
    ><span
      style='line-height:115%;font-family:"Times New Roman",serif;font-size:16px;'
    >)</span
    >
    </li>
    <li>
        <span
          style='line-height:115%;font-family:"Times New Roman",serif;font-size:12.0pt;'
        >Use or upload, in any way, any software or material that contains, or
          which you have reason to suspect that contains, viruses, damaging
          components, malicious code or harmful components which may impair or
          corrupt the Site&rsquo;s data or damage or interfere with the
          operation of another Customer&rsquo;s computer or mobile device or the
          Site and use the Site other than in conformance with the acceptable
          use policies of any connected computer networks, any applicable
          Internet standards and any other applicable laws.</span
        >
    </li>
  </ol>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <p
    style='margin: 0in 0in .0001pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >

    >
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    10.0&nbsp; &nbsp;YOUR SUBMISSION
  </h1>
  <p
    style='margin: 0in 0in 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Anything that you submit to the Site and/or provide to us, including
        but not limited to, questions, reviews, comments, and suggestions
        (collectively, &quot;Submissions&quot;) will become our sole and
        exclusive property and shall not be returned to you. In addition to the
        rights applicable to any Submission, when you post comments or reviews
        to the Site, you also grant us the right to use the name that you
        submit, in connection with such review, comment, or other content. You
        shall not use a false e-mail address, pretend to be someone other than
        yourself or otherwise mislead us or third parties as to the origin of
        any Submissions. We may, but shall not be obligated to, remove or edit
        any Submissions without any notice or legal course applicable to us in
        this regard.</span
      >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    11.0&nbsp; &nbsp;CLAIMS AGAINST OBJECTIONABLE CONTENT
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We list thousands of products for sale offered by numerous Merchants on
        the Site and host multiple comments on listings. Therefore, it is not
        possible for us to be aware of the contents of each product listed for
        sale, or each comment or review that is displayed. Accordingly, we
        operate on a &quot;claim, review and takedown&quot; basis. If you
        believe that any content on the Site is illegal, offensive (including
        but not limited to material that is sexually explicit content or which
        promotes racism, religious, bigotry, hatred or physical harm),
        deceptive, misleading, abusive, indecent, harassing, blasphemous,
        defamatory, libelous, obscene, pornographic, pedophilic or menacing;
        ethnically objectionable, disparaging; or is otherwise injurious to
        third parties; or relates to or promotes money laundering or gambling;
        or is harmful to minors in any way; or impersonates another person; or
        threatens the unity, integrity, security or sovereignty of Sri Lanka or
        friendly relations with foreign States; or objectionable or otherwise
        unlawful in any manner whatsoever; or which consists of or contains
        software viruses, (&quot; objectionable content &quot;), you may notify
        us immediately in writing via the email address: legal@helaviru.lk . We
        will make all practical endeavors to investigate and remove the
        objectionable content so complained about within a reasonable period of
        time.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Please ensure to provide your name, address, contact information and as
        many relevant details of the claim as possible including name of
        objectionable content party, instances of objection, proof of objection
        amongst other. Please note that providing incomplete details will render
        your claim invalid and unusable for legal purposes.<br/>&nbsp;<br/>&nbsp;</span
      ><a href="#_top" target="_self"
  ><span style="line-height:115%;color:windowtext;">Back to Top</span></a
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    12.0&nbsp; &nbsp;CLAIMS AGAINST INFRINGING CONTENT
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We respect the intellectual property of others. If you believe that
        your intellectual property rights have been used in a way that gives
        rise to concerns of infringement, please inform us via legal@helaviru.lk
        . We will make all reasonable efforts to address your concern within a
        reasonable period of time. Please ensure to provide your name, address,
        contact information and as many relevant details of the claim including
        name of infringing party, instances of infringement, proof of
        infringement amongst other. Please note that providing incomplete
        details will render your claim invalid and unusable for legal purposes.
        In addition, providing false or misleading information may be considered
        a legal offense and may be followed by legal proceedings in accordance
        with&nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/03/IntellectualPropertyActNo.36of2003Sectionsr.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Intellectual Property Act no. 36 of 2003</span
  ></a
  ><span
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >&nbsp;which is published by the government.</span
  ></span
  >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    13.0&nbsp; &nbsp;TRADEMARKS AND COPYRIGHTS
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Helaviru Platform, Helaviru Basics, Helaviru logo, and other Helaviru
        related marks indicated on our Site are trademarks or registered
        trademarks in the relevant jurisdiction(s). Our graphics, logos, page
        headers, button icons, scripts and service names are the trademarks or
        trade dress and may not be used in connection with any product or
        service that does not belong to us or in any manner that is likely to
        cause confusion among customers, or in any manner that disparages or
        discredits us. All other trademarks that appear on this Site are the
        property of their respective owners, who may or may not be affiliated
        with, connected to, or sponsored by us.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >The entire contents of the Site also are protected by copyright,
        trademark, service mark, patent or other proprietary rights and laws as
        under applicable laws and international conventions. All rights are
        reserved.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;background:white;"
      >Helaviru reserves the copyrights and other intellectual property rights
        pertaining to the information displayed on the Website and the mobile
        app including, but not limiting to text, graphics, logos, software,
        source code, images, trademarks. Helaviru does not transfer to the user
        to copy any documents or any other site materials. Any reproduction
        without Helaviru Platform&rsquo;s written consent is illegal.</span
      >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <s
    ><span style="font-size:14px;"
    ><span style="text-decoration:none;">&nbsp;</span></span
    ></s
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <s
    ><span style="font-size:14px;"
    ><span style="text-decoration:none;">&nbsp;</span></span
    ></s
    >
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    14.0&nbsp; &nbsp;DISCLAIMER
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    You acknowledge and undertake that you are accessing the services on the
    Site and transacting at your own risk and are using your best and prudent
    judgment before entering into any transactions through the Site. We shall
    neither be liable nor responsible for any actions or inactions of
    Merchants nor any breach of conditions, representations or warranties by
    the Merchants or manufacturers of the products and hereby expressly
    disclaim and any all responsibility and liability in that regard. We shall
    not mediate or resolve any dispute or disagreement between you and the
    Merchants or manufacturers of the products.
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    We further expressly disclaim any warranties or representations (express
    or implied) in respect of quality, suitability, accuracy, reliability,
    completeness, timeliness, performance, safety, merchantability, fitness
    for a particular purpose, or legality of the products listed or displayed
    or transacted or the content (including product or pricing information
    and/or specifications) on the Site. While we have taken precautions to
    avoid inaccuracies in content, this Site, all content, information
    (including the price of products), software, products, services and
    related graphics are provided as is, without warranty of any kind. We do
    not implicitly or explicitly support or endorse the sale or purchase of
    any products on the Site. At no time shall any right, title or interest in
    the products sold through or displayed on the Site vest with us nor shall
    Helaviru Platform have any obligations or liabilities in respect of any
    transactions on the Site.
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    We shall neither be liable or responsible for any actions or inactions of
    any other service provider as listed on our Site which includes but is not
    limited to payment providers, instalment offerings, warranty services
    amongst others.
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;vertical-align:baseline;'
  >
    We do not guarantee that the service will be uninterrupted or error free.
    We will provide every effort in good faith to ensure that its services are
    available as much as possible with minimal service interruptions.
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;vertical-align:baseline;'
  >
    In no circumstance will Helaviru Platform be liable for any claims
    resulting from the use or inability to use Helaviru services- including,
    but not limited to service interruptions, client errors, internet
    connectivity problems, miscommunications, unauthorized access to Helaviru
    Platform servers, Internet bandwidth congestion, power failures, vandalism
    and natural disasters. HELAVIRU PLATFROM SHALL NOT BE LIABLE FOR ANY
    INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
    INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT , LOSS OF BUSINESS OR BUSINESS
    OPPORTUNITY, LOSS OF USE ETC. even if advised of the possibility of such
    damages.
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    &nbsp;
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    15.0&nbsp; &nbsp;INDEMNITY
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You shall indemnify and hold harmless Sri Lanka Telecom PLC, the owner
        of the Helaviru Platform, its subsidiaries, affiliates and their
        respective officers, directors, agents and employees, from any claim or
        demand, or actions including reasonable attorney&apos;s fees, made by
        any third party or penalty imposed due to or arising out of your breach
        of these Terms and Conditions or any document incorporated by reference,
        or your violation of any law, rules, regulations or the rights of a
        third party.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You hereby expressly release Sri Lanka Telecom PLC, the owner of
        Helaviru Platform and/or its affiliates and/or any of its officers and
        representatives from any cost, damage, liability or other consequence of
        any of the actions/inactions of the Merchants or other service providers
        and specifically waiver any claims or demands that you may have in this
        behalf under any statute, contract or otherwise. Your only remedy is to
        discontinue using the Site and its services.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    16.0&nbsp; &nbsp;THIRD PARTY BUSINESSES
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Parties other than Epic Green, SLT and their affiliates may operate
        stores, provide services, or sell product lines on the Site. For
        example, businesses and individuals offer products via Marketplace. In
        addition, we provide links to the websites of affiliated companies and
        certain other businesses. We are not responsible for examining or
        evaluating, and we do not warrant or endorse the offerings of any of
        these businesses or individuals, or the content of their websites. We do
        not assume any responsibility or liability for the actions, products,
        and content of any of these and any other third-parties. You can tell
        when a third-party is involved in your transactions by reviewing your
        transaction carefully, and we may share customer information related to
        those transactions with that third-party incompliance with Customer
        Privacy Policy. You should carefully review their privacy statements and
        related terms and conditions.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    17.0&nbsp; &nbsp;COMMUNICATING WITH US
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >When you visit the Site, or send e-mails to us, you are communicating
        with us electronically. You will be required to provide a valid phone
        number and a valid email address while placing an order with us. We may
        communicate with you by e-mail, SMS, phone call or by posting notices on
        the Site or by any other mode of communication we choose to employ. For
        contractual purposes, you consent to receive communications (including
        transactional, promotional and/or commercial messages), from us with
        respect to your use of the website (and/or placement of your order) and
        agree to treat all modes of communication with the same
        importance.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    18.0&nbsp; &nbsp;LOSSES
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We will not be responsible for any personal losses (including but not
        limited to anticipated savings, expected delivery time, goodwill, or
        wasted expenditure) or any other indirect or consequential loss .</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    19.0&nbsp; &nbsp;AMENDMENTS TO CONDITIONS OR ALTERATIONS OF SERVICE AND
    RELATED PROMISE
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We reserve the right to make changes to the Site, its policies, these
        terms and conditions and any other publicly displayed condition or
        service promise at any time. You will be subject to the policies and
        terms and conditions in force at the time you used the Site unless any
        change to those policies or these conditions is required to be made by
        law or government authority (in which case it will apply to orders
        placed by you). If any of these conditions is deemed invalid, void, or
        for any reason unenforceable, that condition will be deemed severable
        and will not affect the validity and enforceability of any remaining
        condition.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    20.0&nbsp; &nbsp;EVENTS BEYOND OUR CONTROL
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >We will not be held responsible for any delay or failure to comply with
        our obligations under these conditions if the delay or failure arises
        from any cause which is beyond our reasonable control. This condition
        does not affect your statutory rights.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <a href="#_top" target="_self"
    ><span style="font-size:14px;line-height:115%;color:windowtext;"
    >Back to Top</span
    ></a
    >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    21.0&nbsp; &nbsp;WAIVER
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You acknowledge and reserve the right to conduct business to achieve
        our objectives in a manner we deem fit. You also acknowledge that if you
        breach the conditions stated on our Site and we take no action, we are
        still entitled to use our rights and remedies in any other situation
        where you breach these conditions.</span
      >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    22.0&nbsp; &nbsp;TERMINATION
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >In addition to any other legal or equitable remedies, we may, without
        prior notice to you, immediately terminate the Terms and Conditions or
        revoke any or all of your rights granted under the Terms and Conditions.
        Upon any termination of this Agreement, you shall immediately cease all
        access to and use of the Site and we shall, in addition to any other
        legal or equitable remedies, immediately revoke all password(s) and
        account identification issued to you and deny your access to and use of
        this Site in whole or in part. Any termination of this agreement shall
        not affect the respective rights and obligations (including without
        limitation, payment obligations) of the parties arising before the date
        of termination. You furthermore agree that the Site shall not be liable
        to you or to any other person as a result of any such suspension or
        termination. If you are dissatisfied with the Site or with any terms,
        conditions, rules, policies, guidelines, or practices in operating the
        Site, your sole and exclusive remedy is to discontinue using the
        Site.</span
      >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="font-size:14px;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    23.0&nbsp; &nbsp;GOVERNING LAW AND JURISDICTION
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >These terms and conditions are governed by and construed in accordance
        with the laws of The Democratic Socialist Republic of Sri Lanka. You
        agree, as we do, to submit to the exclusive jurisdiction of the courts,
        of Sri Lanka having jurisdiction over the place on which our Registered
        Office is situated at the time of institution of legal proceedings. Our
        site strictly adheres to the below mentioned statutes which are enforced
        by the Sri Lankan Government.</span
      >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;">23.1&nbsp; &nbsp; &nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/Information_and_Communication_Technology_Act_No.27.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Information and Communication Technology Act No.27 of 2003</span
  ></a
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;">23.2&nbsp; &nbsp; &nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/03/IntellectualPropertyActNo.36of2003Sectionsr.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Intellectual Property Act no. 36 of 2003</span
  ></a
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;">23.3&nbsp; &nbsp; &nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ElectronicTransactionActNo19of2006.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Electronic Transactions Act No. 19 of 2006</span
  ></a
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;">23.4&nbsp; &nbsp; &nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/ComputerCrimesActNo24of2007.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Computer Crimes Act No. 24 of 2007</span
  ></a
  ><span style="line-height:115%;">&nbsp;</span>
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;">23.5&nbsp; &nbsp; &nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/PaymentandSettlementSystemActNo.28of2005.pdf"
    target="_blank"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Payment and Settlement Systems Act, No. 28 of 2005</span
  ></a
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;">23.6&nbsp; &nbsp; &nbsp;</span
      ><a
    href="https://www.gov.lk/elaws/wordpress/wp-content/uploads/2015/07/Payment_devices_FraudsActNo.30of2006.pdf"
    target="_blank"
    title="e-Laws Project of ICTA web site"
  ><span
    style="line-height:115%;border:none windowtext 1.0pt;padding:0in;background:white;"
  >Payment Devices Frauds Act No.30 of 2006</span
  ></a
  >
  </p>

  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <span style="line-height:115%;">&nbsp;</span>
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    <span style="font-size:14px;"><br/>&nbsp;</span>24.0&nbsp; &nbsp;CONTACT
    US
  </h1>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    You may reach us&nbsp;<a href="https://www.helaviru.lk/contact-us"
  ><span style="color:windowtext;">here</span></a
  >
  </p>
  <p
    style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <br/>&nbsp;
  </p>
  <h1
    style='margin-right:0in;margin-left:0in;text-align:justify;font-size:19px;font-family:"Times New Roman",serif;'
  >
    25.0&nbsp; &nbsp;OUR SOFTWARE
  </h1>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >Our software includes any software (including any updates or upgrades
        to the software and any related documentation) that we make available to
        you from time to time for your use in connection with the Site (the
        &quot;Software&quot;).</span
      >
  </p>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You may use the software solely for purposes of enabling you to use and
        enjoy our services as permitted by the Terms and Conditions and any
        related applicable terms as available on the Site. You may not
        incorporate any portion of the Software into your own programs or
        compile any portion of it in combination with your own programs,
        transfer it for use with another service, or sell, rent, lease, lend,
        loan, distribute or sub-license the Software or otherwise assign any
        rights to the Software in whole or in part. You may not use the Software
        for any illegal purpose. We may cease providing you service and we may
        terminate your right to use the Software at any time. Your rights to use
        the Software will automatically terminate without notice from us if you
        fail to comply with any of the Terms and Conditions listed here or
        across the Site. Additional third-party terms contained within the Site
        or distributed as such that are specifically identified in related
        documentation may apply and will govern the use of such software in the
        event of a conflict with these Terms and Conditions. All software used
        in any of our services is our property and/or our affiliates or its
        software suppliers and protected by the laws of Sri Lanka including but
        not limited to any other applicable copyright laws.</span
      >
  </p>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >When you use the Site, you may also be using the services of one or
        more third parties, such as a wireless carrier or a mobile platform
        provider. Your use of these third-party services may be subject to
        separate policies, terms of use, and fees of these third parties.</span
      >
  </p>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >You may not, and you will not encourage, assist or authorize any other
        person to copy, modify, reverse engineer, decompile or disassemble, or
        otherwise tamper with our software whether in whole or in part, or
        create any derivative works from or of the Software.</span
      >
  </p>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
      <span style="line-height:115%;"
      >In order to keep the Software up to date, we may offer automatic or
        manual updates at any time and without notice to you.</span
      >
  </p>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:115%;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >

    >
  </p>
  <p
    style='margin: 0 0 8.0pt;text-align:justify;line-height:normal;font-size:16px;font-family:"Times New Roman",serif;background:white;'
  >
    <s><span style="text-decoration: none;">&nbsp;</span></s>
  </p>
  </body>
  </html>

</div>
