<div class="reviews">
    <div class="details">
        <div class="user">
            <img src="{{imageUrl}}" alt="" style="width: 100px; height: 100px">
            <h6>{{name}}</h6>
        </div>
        <div class="ratings">
          <star-rating [value]="rate" totalstars="5" checkedcolor="#FFA84C" uncheckedcolor="#999999"
                       size="16px"></star-rating>
        </div>
    </div>
    <div class="descriptions">
        <p>
          {{comment}}
        </p>
    </div>
    <div class="review-actions d-flex justify-content-center mt-3">
        <button class="btn btn-default dark" data-dismiss="modal">Cancel</button>
    </div>
</div>
