import {Component, OnInit} from '@angular/core';
import {CoreService} from '../service/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {UserRoleService} from '../service/user-role.service';
import {CommonService} from '../common/common.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  email: any;
  mapUrl: any;
  address1: any;
  address2: any;
  hotline: any;
  website: any;
  name: any;
  emailAddress: any;
  address: any = '';
  message: any;
  mailText: string;
  mobile: any = '';
  spinner = false;
  tenantValue: string;

  submitted = false;
  wrongName = false;
  wrongAddress = false;
  wrongAMessage = false;
  contactUsForm: FormGroup;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public coreService: CoreService,
              private formBuilder: FormBuilder,
              private commonService: CommonService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private validationHandlerService: ValidationHandlerService,
              private router: Router) {
  }

  ngOnInit() {
    this.tenantValue = this.route.snapshot.paramMap.get('tenant_id');
    this.fetchContactUsDetails();
    this.reactiveForm();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.coreService.findRegistrationDetailsByUser(whoAmIDetails['email']).subscribe(result => {
        this.name = result['name'];
        this.mobile = result['mobile'];
        this.address = result['address'];
        this.emailAddress = result['email'];
      });
    });
  }

  reactiveForm() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', Validators.required],
      address: ['', Validators.required],
      message: ['', Validators.required],
      emailAddress: ['']
    });
  }

  get f() {
    return this.contactUsForm.controls;
  }

  fetchContactUsDetails() {
    this.coreService.fetchContactUsPageDetails().subscribe(result => {
      if (result) {
        if (Object.keys(result).length > 0) {
          this.spinner = true;
          this.email = result[0].email;
          this.mapUrl = result[0].mapUrl;
          this.address1 = result[0].address1;
          this.address2 = result[0].address2;
          this.hotline = result[0].hotLine;
          this.website = result[0].webSite;
          setTimeout(() => {
            document.getElementById('location-map').innerHTML = this.mapUrl;
          }, 100);
        }
      }
    });
  }


  onSubmit() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }
    this.sendContactUsMail();

  }

  sendContactUsMail() {
    this.commonService.processing();

    let contactUsBody = {
      'address1': this.name,
      'email': this.email,
      'hotLine': this.mobile,
      'address2': this.address,
      'webSite': this.message
    };
    this.coreService.sendContactUsMail(contactUsBody).subscribe((result) => {
      if (result) {
        Swal.close();
        Swal.fire({
          position: 'center',
          type: 'success',
          title: this.validationHandlerService.manageLocalizationContactUSSuccessTittle(sessionStorage.getItem('LANG')),
          text: this.validationHandlerService.manageLocalizationContactUsSuccessBodyMessage(sessionStorage.getItem('LANG')),
          customClass: 'swal-text',
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        }).then(() => {
          this.router.navigate(['/home/' + localStorage.getItem('TENANT_VALUE')]);
        });
        const title = Swal.getContent();
        title.style.textAlign = 'center';
      } else {
        Swal.close();
        Swal.fire({
          type: 'error',
          title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        });
      }
    }, (error) => {
      Swal.close();
      Swal.fire({
        type: 'error',
        title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
        showConfirmButton: true,
        confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
      });
      console.log('Send ContactUs mail function :' + error);
    });
  }

}
