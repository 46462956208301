import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PurchaseStatusDTO} from '../../../../dto/purchase-statusDTO';
import {ProductService} from '../../../../service/product.service';
import {CommonService} from '../../../../common/common.service';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {InteractionService} from '../../../../service/interaction.service';

@Component({
  selector: 'app-remark',
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.css']
})
export class RemarkComponent implements OnInit {
  @Input() fromParent;
  @ViewChild('Reject', {static: true}) rejectButton;

  submitted = false;
  remarkForm: FormGroup;
  purchaseModel: PurchaseStatusDTO = new PurchaseStatusDTO();
  remark: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public productService: ProductService,
              public commonService: CommonService,
              private _formBuilder: FormBuilder,
              private interactionService:InteractionService,
              private validationHandlerService: ValidationHandlerService,
              private _changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.purchaseModel = this.fromParent.model;
    });

    this.remarkForm = this._formBuilder.group({
      remark: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
    });
  }

  ngAfterViewChecked(): void {
    this._changeDetectionRef.detectChanges();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._changeDetectionRef.detectChanges();
    });
  }

  get f() {
    return this.remarkForm.controls;
  }

  submitRequest() {
    this.loading();
    this.purchaseModel.comment = this.remark;
    this.productService.updateTransaction(this.purchaseModel).subscribe(res => {
      Swal.close();
      if (res) {
        this.interactionService.updateTables(true);
        this.commonService.close();
        this.rejectButton.nativeElement.click();
      } else {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    }, () => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Something went wrong..!!!',
        showConfirmButton: false,
        timer: 1500
      });
    });
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  submit() {
    this.submitted = true;
    if (this.remarkForm.invalid) {
      return;
    }
    this.submitRequest();
  }

}
