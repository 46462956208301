<app-header></app-header>
<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">
          <div class="pro-container">
            <div class="profile-info">
              <app-sidebar></app-sidebar>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>


<!-- sell product -->
<div class="modal fade npa-model" id="addMyProduct" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fa fa-pencil" aria-hidden="true"></i>
          {{'MY_ACCOUNT.SELL_A_PRODUCT.ADD_NEW_PRODUCT' | translate}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-farmer-sell-a-product [isAgentFarmerLogin]="false" [agentFarmerUsername]=""></app-farmer-sell-a-product>
      </div>
    </div>
  </div>
</div>

<!-- profile settings -->
<div class="modal fade npa-model" id="profile-settings" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <app-account-settings></app-account-settings>
  </div>
</div>


<!-- Create a new order -->
<div class="modal fade npa-model" id="createNewOrder" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fa fa-pencil" aria-hidden="true"></i>
          {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.NEW_ORDER_REQUEST' | translate}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-buyer-create-new-order></app-buyer-create-new-order>
      </div>
    </div>
  </div>
</div>
