<div class="">
  <div class="top-big">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="graytext font-weight-bold">{{productName}}</h3>
      <p>
        <span class="graytext-2"><i class="fa fa-calendar"></i> &nbsp; Listed date:</span>
        <span class="font-weight-bold">{{convertDate(productCreatedDate)}}</span>
      </p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="pro-div">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-row align-items-center mt-2 mb-2">
        <div style="width: 150px;">
          <img alt=""
               src="{{this.mainUrl.MAIN_URL_FILE + productImage + '/?X-TenantID=' + this.tenantValue}}"
               style="border-radius: 10px; width: 150px; height: 150px;object-fit: cover; border-radius: 50%;"/>
        </div>
        <div class="rightdiv">
          <h2 class="graytext mb-0 font-weight-bold">{{productName}}</h2>
          <p class="graytext font-weight-bold">{{productSku}}</p>
        </div>
      </div>
      <div class="text-right">
        <p class="mb-0 graytext">Selling Price: </p>
        <h4 class="d-flex flex-row align-items-center font-weight-bold graytext">
          {{'CURRENCY.CURRENCY' | translate}}
          <h1 class="font-weight-bold graytext">
            {{productUnitPrice * productStock| number:'2.2-2'}}
          </h1>
        </h4>
      </div>
    </div>

  </div>
  <mat-divider></mat-divider>
  <div class="d-flex align-items-center justify-content-between pt-4 pb-4">
    <div>
      <p class="mb-0 graytext-2">Category : {{productCategory}}/{{productName}}</p>
      <p class="mb-0 graytext-2">Location :
        <span *ngIf="activeLang === 'en'">{{farmerLocationEN}}</span>
        <span *ngIf="activeLang === 'si'">{{farmerLocationSI}}</span>
        <span *ngIf="activeLang === 'ta'">{{farmerLocationTA}}</span>
      </p>
    </div>
    <div class="d-flex align-items-end flex-column">
      <p class="mb-0 font-weight-bold graytext">{{productAvailableQty}} items available</p>
<!--      <p class="mb-0 graytext-2">Avg weight per one item: <span class="font-weight-bold graytext">1KG</span>-->
<!--      </p>-->
    </div>
  </div>
  <div class="description-box">
    <h5 class="graytext font-weight-bold">Description</h5>
    <p class="graytext">{{productDescription}}</p>
  </div>
  <mat-divider></mat-divider>
  <div class="d-flex justify-content-center mt-3">
    <a aria-label="Close" class="btn btn-success w-20" data-dismiss="modal">Close</a>
  </div>
</div>
