<app-header></app-header>

<div class="main-container">
  <div class="container product-view">
    <div class="row">
      <div class="col-lg-12">
        <div class="pl-content w-full-col">
          <div class="inner-panel">

            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <div class="image-container proview-img">
                    <div class="zoom-popup">
                    </div>

                    <div class="view-product">
                      <div class="image-box p-view">
                        <div class="badge-item" *ngIf="leadLabel !== null">
                          <img
                            src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ leadLabel+ '/?X-TenantID=' + this.tenantValue}}"
                            alt="">
                        </div>
                        <img style="cursor: pointer" data-toggle="modal" data-target="#openImage" *ngIf="commonImg"
                          class="demo-trigger" id="main-image" src="{{this.commonImg}}" alt="" />
                        <img *ngIf="!commonImg" class="show demo-trigger" src="assets/defaultBrokenImage.jpg" alt="">
                      </div>
                      <!-- economy center badge -->
                      <div class="eco-badge-tview" *ngIf="economicCenter === 'TABUTTEGAMA'">
                        <div class="ecobadge">
                          <img src="../../assets/images/icons/thambuththegama.png" alt="">
                        </div>
                      </div>
                    </div>

                    <div class="row" style="margin-top: 1vh;">

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb">
                          <img *ngIf="img1" class="imageTag show" style="filter: unset; opacity: 1;" [src]="img1"
                            id="img1" (click)="viewImg(img1,1)" alt="">
                          <img *ngIf="!img1" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb" *ngIf="img2">
                          <img *ngIf="img2" class="imageTag" [src]="img2" id="img2" (click)="viewImg(img2,2)" alt="">
                          <img *ngIf="!img2" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb" *ngIf="img3">
                          <img *ngIf="img3" class="imageTag" [src]="img3" id="img3" (click)="viewImg(img3,3)" alt="">
                          <img *ngIf="!img3" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                      <div class="col-sm-3 col3product">
                        <div class="prd-thumb" *ngIf="img4">
                          <img *ngIf="img4" class="imageTag" [src]="img4" id="img4" (click)="viewImg(img4,4)" alt="">
                          <img *ngIf="!img4" class="show imageTag" src="../../assets/images/loading/loading.gif" alt="">
                        </div>
                      </div>

                    </div>

                    <div class="zoom-popup">
                      <i class="fa fa-search-plus" aria-hidden="true"></i> {{'PRODUCT_VIEW.OPEN_IMAGE' | translate}}
                    </div>
                  </div>


                </div>
                <div class="col-md-8 right-side">
                  <div class="row">

                    <div class="col-lg-8 board-right" style="margin-bottom: 20px;">

                      <div class="product-info-container">
                        <nav aria-label="breadcrumb" class="breadcrumb-nav">
                          <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/products/'+tenantValue]"><i
                                  aria-hidden="true" class="fa fa-home"></i>
                                {{'PRODUCT_VIEW.ALL_CATEGORY' | translate}}</a></li>
                            <li class="breadcrumb-item"><a>
                                <span *ngIf="activeLang === 'en'">{{categoryNameEN}}</span>
                                <span *ngIf="activeLang === 'si'">{{categoryNameSI}}</span>
                                <span *ngIf="activeLang === 'ta'">{{categoryNameTM}}</span>
                              </a></li>
                            <li aria-current="page" class="breadcrumb-item active">
                              <span *ngIf="activeLang === 'en'">{{subCategoryNameEN}}</span>
                              <span *ngIf="activeLang === 'si'">{{subCategoryNameSI}}</span>
                              <span *ngIf="activeLang === 'ta'">{{subCategoryNameTM}}</span>
                            </li>
                          </ol>
                        </nav>

                        <div class="purchase-info">
                          <p>{{'PRODUCT_VIEW.SPOT_PURCHASE' | translate}}</p>
                          <div class="delivery-stats">
                          </div>

                        </div>
                        <div class="product-title">
                          {{name}}
                        </div>
                        <div class="star-icons">
                          <star-rating [value]="rate" checkedcolor="#FFA84C" size="16px" uncheckedcolor="#999999">
                          </star-rating>
                        </div>
                        <div class="filter-pill viewfill d-flex justify-content-between align-items-center"
                          title="filter products by {{farmerName}}" (click)="filterOn()"
                          [routerLink]="'/products/wholesale'" [queryParams]="{filterUsername:farmerUsername}"
                          [ngClass]="filstatus ? 'filtered' : 'notfiltered'">
                          <div class="seller-card">
                            <div class="profile-picture">
                              <img src="../../assets/avatar.jpg" alt="">
                            </div>

                            <div class="seller-content">
                              <div class="name">{{farmerName}}</div>
                            </div>
                          </div>
                          <div class="fillicon">
                            <span class="material-icons">
                              filter_list
                            </span>
                          </div>
                        </div>
                        <!-- <div>
                          <i aria-hidden="true" class="fa fa-user-o"></i>&nbsp;&nbsp;&nbsp;{{farmerName}}
                        </div> -->
                        <div class="category">
                          <span class="category-item">{{'PRODUCT_VIEW.CATEGORY' | translate}}
                            <span *ngIf="activeLang === 'en'">{{categoryNameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{categoryNameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{categoryNameTM}}</span>/
                            <span *ngIf="activeLang === 'en'">{{subCategoryNameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{subCategoryNameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{subCategoryNameTM}}</span></span>
                        </div>
                        <div class="best-by-date" style="margin-bottom: 10px;">
                          <div class="title">
                            {{'PRODUCT_VIEW.LOCATION' | translate}}
                          </div>
                          <div class="info">
                            <span>{{location}}</span>
                            <!--                            <span *ngIf="activeLang === 'en'">{{city}}</span>-->
                            <!--                            <span *ngIf="activeLang === 'si'">{{citySin}}</span>-->
                            <!--                            <span *ngIf="activeLang === 'ta'">{{cityTam}}</span>-->
                          </div>
                        </div>
                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW.LISTED_DATE' | translate}}
                          </div>
                          <div class="info">
                            {{listedDate}}
                          </div>
                        </div>

                        <div class="date">
                          <span class="material-icons">
                            star
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW.BEST_BUY_DATE' | translate}}
                          </div>
                          <div class="info">
                            {{bestBuyDate}}
                          </div>
                        </div>
                        <div class="best-by-date totalqty">
                          <div class="title">
                            {{'PRODUCT_VIEW.TOTAL_AVAILABLE_QTY' | translate}}
                          </div>
                          <div class="info">
                            {{availableTotalQty}} {{unitType}}
                          </div>
                        </div>
                        <div class="title" *ngIf="sellInSmallerQuantities">
                          {{'PRODUCT_VIEW.SMALLER_BULK' | translate}} {{stock}} {{unitType}}
                        </div>
                        <div class="price" *ngIf="sellInSmallerQuantities && availableTotalQty !== 0">
                          {{'PRODUCT_VIEW.TOTAL_PRICE' | translate}}
                          <span style="color: black;">{{'CURRENCY.CURRENCY' | translate}}&nbsp;{{unitPrice * stock *
                            _tempQty| number:'2.2-2'}}
                          </span>
                        </div>
                        <div class="price" *ngIf="!sellInSmallerQuantities && availableTotalQty !== 0">
                          {{'PRODUCT_VIEW.TOTAL_PRICE' | translate}}
                          <span style="color: black;">{{'CURRENCY.CURRENCY' | translate}}&nbsp;{{unitPrice *
                            availableTotalQty | number:'2.2-2'}}</span>
                        </div>
                        <div class="price" *ngIf="availableTotalQty === 0">
                          <span style="color: red;font-size: 45px;">{{'PRODUCT_VIEW.SOLD_OUT' | translate}}</span>
                        </div>
                      </div>

                      <div class="row" style="margin-top: 20px;">
                        <div class="col-md-12 alignn" *ngIf="sellInSmallerQuantities">
                          <div class="add-container">
                            <div class="qty-title">
                              {{'PRODUCT_VIEW.UNITS' | translate}}
                            </div>
                            <div class="number">
                              <div class="minus" style="cursor:pointer;" (click)="qtyDown()"><span>-</span></div>
                              <input [disabled]="isDisabledSelectQty" [min]="setMinSelectQty(selectQTYMIN)"
                                (change)="validateMaxQnt(selectQty)" type="number" [(ngModel)]="selectQty"
                                (focusout)="editQty(selectQty)" [value]="selectQty * _tempQty" />
                              <div class="plus" style="cursor:pointer;" (click)="qtyUp()">+</div>
                              <span
                                style="display: inline-block; font-size: 25px; font-weight: 600; line-height: 50px; padding: 0 10px; text-transform: uppercase;">
                                {{unitType}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <button [ngStyle]="{'opacity': availableTotalQty === 0 ? '0.5' : '1' }"
                            [disabled]="addToCart || availableTotalQty === 0" class="darkbtn2" style="color: white"
                            (click)="addCart('ADD_TO_CART')">
                            <i class="fa fa-cart-plus icontbtn"></i>{{'PRODUCT_VIEW.ADD_TO_CARD' | translate}}
                          </button>
                        </div>
                        <div class="col-md-6">
                          <button #transportModal [ngStyle]="{'opacity': availableTotalQty === 0 ? '0.5' : '1' }"
                            [disabled]="addToCart || availableTotalQty === 0" class="darkbtn" style="color: white"
                            (click)="loadTab()">
                            <i class="fa fa-credit-card"></i> &nbsp; {{'PRODUCT_VIEW.BUY_NOW' | translate}}
                          </button>
                        </div>
                      </div>
                    </div>

                    <button hidden data-target="#transport-options" data-toggle="modal" #transportOptions></button>

                    <div class="col-lg-4">

                      <div class="transport-options">
                        <div class="deliver-options-card">
                          <div class="select-delivery">
                            <div class="d-flex justify-content-end">
                              <span class="material-icons hlp">
                                help
                              </span>
                            </div>
                            <p>
                              {{'singleView_line1'|translate}}
                            </p>
                            <div class="option-list">
                              <div class="d-flex align-items-center t-opt"
                                matTooltip="{{'riyaviru_transport_tooltip'| translate}}">
                                <img src="../../assets/images/icons/transport1.png" alt="">
                                <p>{{'riyaviru_transport'|translate}}</p>
                              </div>
                              <div *ngIf="isSeller" class="d-flex align-items-center t-opt"
                                matTooltip="{{'seller_transport_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transport2.png" alt="">
                                <p>{{'seller_transport'|translate}} - {{'Fixed_Rate'|translate}}</p>
                              </div>

                              <div *ngIf=" isSellerFixed" class="d-flex align-items-center t-opt"
                                matTooltip="{{'seller_transport_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transport2.png" alt="">
                                <p>{{'seller_transport'|translate}} - {{'Per_Km_Rate'|translate}}</p>
                              </div>
                              <div *ngIf="this.isTrain" class="d-flex align-items-center t-opt"
                                matTooltip="{{'RailFreightTransport_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transport3.png" alt="">
                                <p>{{'RailFreightTransport'|translate}}</p>
                              </div>
                              <div class="d-flex align-items-center t-opt"
                                matTooltip="{{'SelfPickup_tooltip'|translate}}">
                                <img src="../../assets/images/icons/transpor4.png" alt="">
                                <p>{{'SelfPickup'|translate}}</p>
                              </div>
                            </div>
                            <div class="deliver-cost">
                              <p class="or-text"><span class="material-icons">
                                  local_shipping
                                </span> <span>{{'singleViewDelivery'|translate}}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="price-actions">
                      <!--                      <div class="row">-->

                      <!--                      </div>-->
                      <div *ngIf="isEmptyReviewList" class="customer-reviews" (click)="clickReviews()"
                        [ngClass]="{'active': showReviews==false}">
                        <p class="bluetxt"> {{reviewList.length}} customer reviews</p>
                      </div>

                      <div class="desc description-panel" id="showDescription"
                        [ngClass]="{'active': showReviews==false}">
                        <div class="des-title">
                          {{'PRODUCT_VIEW.DESCRIPTION' | translate}}
                        </div>
                        <div class="des-content">
                          {{description}}
                        </div>
                      </div>
                      <div class="reviews-panel" id="showreviews" [ngClass]="{'active': showReviews==true}">
                        <p class="bluetxt descpadding" (click)="clickDesc()"> Description <i aria-hidden="true"
                            class="fa fa-long-arrow-right"></i>
                        </p>
                        <hr>
                        <div class="inner-cards">
                          <div class="card review-card" *ngFor="let review of reviewList">
                            <div class="top-panel">
                              <div class="left">
                                <img src="../../assets/avatar.jpg" alt="">
                                <span>{{hideReviewName(review.reviewBy)}}</span>
                              </div>
                              <div class="right">
                                <star-rating [value]="review.rating" checkedcolor="#FFA84C" size="16px"
                                  uncheckedcolor="#999999"></star-rating>
                              </div>
                            </div>
                            <div class="bottom-panel">
                              <div class="centers">
                                <div class="reviewtxt">
                                  <p>{{review.comment}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

              </div>

              <div class="cp-spinner cp-morph"></div>
            </div>
          </div>
          <div class="clear">&nbsp;</div>


          <!-- Product Slider 02 -->
          <div class="w-full-col" style="margin-top: 30px;">
            <h4 style="margin-left: 2vh;">{{'NEW_ARRIVALS' | translate}}</h4>
            <div class="slck-product-slider">
              <ngx-slick-carousel class="carousel" [config]="slide1Config">
                <div ngxSlickItem *ngFor="let newArrival of allNewArrivalCategory ; let i = index"
                  class="slide ps-item">
                  <a [routerLink]="['/product-view-'+setType(newArrival.type.type)+'/'+tenantValue]"
                    [queryParams]="{id:newArrival.id,typeId:newArrival.type.id,typeName:newArrival.type.type}">
                    <div class="ps-inner-wrapper">
                      <div class="img-section-item">
                        <div class="badge-item" *ngIf="newArrival.leadLabel !== null">
                          <img
                            src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.leadLabel+ '/?X-TenantID=' + this.tenantValue}}"
                            alt="">
                        </div>
                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length > 0"
                          class="imageTagNew"
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.spotImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="" />
                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'SPOT' && newArrival?.spotImages.length === 0"
                          class="imageTagNew" src="assets/defaultBrokenImage.jpg" alt="" />

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length > 0"
                          class="imageTagNew"
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival.futureImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="" />

                        <img [ngStyle]="{'opacity': newArrival.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival.type.type === 'FORWARD_BN' && newArrival?.futureImages.length === 0"
                          class="imageTagNew" src="assets/defaultBrokenImage.jpg" alt="" />

                        <img [ngStyle]="{'opacity': newArrival?.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival?.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length > 0"
                          class="imageTagNew"
                          src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ newArrival?.forwardAcuImages[0].image+ '/?X-TenantID=' + this.tenantValue}}"
                          alt="" />

                        <img [ngStyle]="{'opacity': newArrival?.stockAvailable === 0 ? '0.5' : '1' }"
                          *ngIf="newArrival?.type.type === 'FORWARD_ACU' && newArrival?.forwardAcuImages.length === 0"
                          class="imageTagNew" src="assets/defaultBrokenImage.jpg" alt="" />
                        <div class="ps-item-inner">
                          <h5 class="productName">{{newArrival.name}}</h5>
                          <dl style="margin-top: 4px;">
                            <span *ngIf="newArrival?.stockAvailable !== 0 && newArrival.type.type === 'SPOT'"
                              class="text" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0); overflow: hidden;
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                              <dt *ngIf="newArrival?.sellInSmallerQuantities">
                                {{'CURRENCY.CURRENCY' | translate}}
                                <span style="margin-left: 0.5vw">
                                </span>{{newArrival?.spotDTOList[0].unitPrice | number:'2.2-2'}}
                                /{{newArrival?.unit.unit}}
                              </dt>
                              <dt *ngIf="!newArrival?.sellInSmallerQuantities">{{'CURRENCY.CURRENCY' | translate}}
                                <span style="margin-left: 0.5vw">
                                </span>{{newArrival?.spotDTOList[0]?.unitPrice | number:'2.2-2'}}
                                /{{newArrival?.unit.unit}}
                              </dt>
                            </span>
                            <span *ngIf="newArrival?.stockAvailable === 0" class="text" style="border-width: 0; border-style: solid;
                         border-color: black; position: relative; box-sizing: border-box;
                         display: block; -webkit-box-orient: vertical; flex-direction: column;
                          align-content: flex-start; flex-shrink: 0; font-size: 16px; white-space: nowrap;
                           font-weight: 800; font-style: italic; line-height: 24px; color: rgb(0, 0, 0); overflow: hidden;
                           text-overflow: ellipsis; margin-bottom: .5vh;">
                              <dt style="color: red">{{'PRODUCT_VIEW.SOLD_OUT' | translate}}</dt>
                            </span>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>
              </ngx-slick-carousel>
            </div>

            <div *ngIf="spinner2 !== 0">
              <div class="sk-cube-grid">
                <div class="sk-cube sk-cube1"></div>
                <div class="sk-cube sk-cube2"></div>
                <div class="sk-cube sk-cube3"></div>
                <div class="sk-cube sk-cube4"></div>
                <div class="sk-cube sk-cube5"></div>
                <div class="sk-cube sk-cube6"></div>
                <div class="sk-cube sk-cube7"></div>
                <div class="sk-cube sk-cube8"></div>
                <div class="sk-cube sk-cube9"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>


  <app-footer></app-footer>


  <div class="modal fade" id="openImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <img *ngIf="commonImg" class="demo-trigger" src="{{this.commonImg}}" alt="" />
        </div>
      </div>
    </div>
  </div>


  <!-- Report -->
  <div class="modal fade npa-model" id="feedBack" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title d-flex align-items-center">Report This Product</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="reportForm" (ngSubmit)="submitReportForm()">
            <div class="reviews">
              <div class="descriptions">
                <div class="form-group pt-1 pb-2">
                  <label for="commentReport">Reason</label>
                  <textarea formControlName="commentReport" class="form-control" id="commentReport" rows="5"
                    placeholder="Your Comment"></textarea>
                </div>
              </div>
              <div class="review-actions">
                <div class="text-right">
                  <button class="btn btn-default dark" data-dismiss="modal" aria-label="Close">Cancel</button>
                  <button class="btn btn-default next" style="margin-left: 5px;" type="submit">Report
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Transport options -->
<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" id="transport-options" role="dialog"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_OPTIONS' | translate}}</h5>
        <button #transportOptionsClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="opt-row">
          <div class="transport-opt row">
            <div class="products-set pset-a col-md-4 col-xs-12">
              <div class="c-products-sum">

                <div class="dcp-item pro-items d-flex align-items-start">
                  <div class="dcp-img">
                    <img src="{{this.commonImg}}" alt="" />
                  </div>
                  <div class="dcp-info">
                    <h6 class="p-name">{{this.name}}</h6>
                    <span class="p-qty">{{this._tempQty}}{{this.unitType}} x {{'CURRENCY.CURRENCY' | translate}}
                      {{this.unitPrice| number:'2.2-2'}} </span>
                    <p>
                      <span class="u-item"
                        *ngIf="sellInSmallerQuantities && availableTotalQty !== 0">{{'CURRENCY.CURRENCY' |
                        translate}}&nbsp;{{unitPrice * stock *
                        _tempQty| number:'2.2-2'}}</span>
                      <span class="u-item"
                        *ngIf="!sellInSmallerQuantities && availableTotalQty !== 0">{{'CURRENCY.CURRENCY' |
                        translate}}&nbsp;{{unitPrice *
                        availableTotalQty | number:'2.2-2'}}</span><br />
                      <span class="u-loc breakall">{{this.location}}</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <hr>
            <div class="products-set col-md-8 col-xs-12 transportmethods" id="nav-tabContent">
              <div class="insidepaddings">
                <mat-radio-group aria-label="Select an option">


                  <!-- riyaviru transport -->
                  <div class="buyertransport rb-cl-wp" data-target="#riyaviruModal" data-toggle="modal"
                    [ngClass]="{'active':selectedOption==this.SKU+1}" (click)="clickRiyaviru(this.SKU)">
                    <mat-radio-button [value]="100+1" class="tos-radio" (change)="checkVal($event,this.SKU,1)">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'RIYAVIRU_TRANSPORT1' | translate}}</h5>
                          <p>{{'RIYAVIRU_TRANSPORT2' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <!-- buyer transport -->
                  <div class="buyertransport rb-cl-wp" [ngClass]="{'active':selectedOption==this.SKU+2}">
                    <mat-radio-button [value]="100+2" class="tos-radio" (change)="checkVal($event,this.SKU,2)"
                      (click)="selectedOption=this.SKU+2">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_B' | translate}}</h5>
                          <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.ORDER_IS_RESPONSIBLE' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <div *ngFor="let trn of deliveryOptList;index as k">

                    <!-- Train transport -->
                    <div class="toptile">
                      <div class="t-title">
                        <div class="ifrailtransport rb-cl-wp" [ngClass]="{'active':selectedOption==this.SKU+4}"
                          *ngIf="trn.deliveryOpt==='4'">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,this.SKU,4)"
                            (click)="selectedOption=this.SKU+4">
                            <div class="toptile d-flex justify-content-between">
                              <div class="t-title">
                                <h5>Transport Method (Rail Freight Transport) </h5>
                                <p>Select the nearest train station to you for the pickup</p>
                              </div>
                              <div class="d-flex align-items-center rail">
                                <span class="material-icons"> train</span>
                                <p class="font-bold">Rail Freight Transport</p>
                              </div>
                            </div>
                            <div class="pickup-dropof row">
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Pickup Station</label>
                                <input name="pickup" class="form-control" style="width: 50%;" type="text" disabled
                                  value="item.transport.station">
                              </div>
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Dropoff Station <span
                                    class="floatright">Estimated
                                    arrival time: 7-8 hours</span></label>
                                <select style="width: 50%;" class="form-control province" id="railway">
                                  <option>
                                    Select a railway station
                                  </option>
                                  <option *ngFor="let station of railwayList" [value]="station.id">
                                    {{station.descriptionEng}}
                                  </option>

                                </select>
                              </div>
                            </div>

                            <p>Please note that the <strong>Arrival Times</strong> can be vary depending on the
                              weather
                              conditions and other unforeseeable factors. <br>If the delivery is considerably late, or
                              if
                              you need
                              more information, please feel free to <a href="">contact us.</a></p>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>

                    <!-- seller transport -->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='3'"
                      [ngClass]="{'active':selectedOption==this.SKU+3}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,this.SKU,3)"
                            (click)="selectedOption=this.SKU+3">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_DELIVER' | translate}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGE' |
                              translate}}{{'CURRENCY.CURRENCY' |
                              translate}} {{trn.deliveryCharges| number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{this.sellerName}}</h5>
                          <p>{{this.sellerLocation}}</p>
                        </div>
                      </div>
                      <i class="fa fa-truck"></i> &nbsp;{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_PAID_S' |
                      translate}}
                    </div>

                    <!-- seller transport fixed price-->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='5'"
                      [ngClass]="{'active':selectedOption==this.SKU+5}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k" class="tos-radio" (change)="checkVal($event,this.SKU,5)"
                            (click)="selectedOption=this.SKU+5">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_WILL_DELIVER' | translate}}</p>
                            <p>{{trn.deliveryDesc}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_FIX' | translate}}
                              {{'CURRENCY.CURRENCY' | translate}} {{trn.deliveryCharges | number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{this.sellerName}}</h5>
                          <p>{{this.sellerLocation}}</p>
                        </div>
                      </div>
                      <i class="fa fa-truck"></i> &nbsp;{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_PAID_S' |
                      translate}}
                    </div>

                  </div>
                </mat-radio-group>


              </div>

            </div>
          </div>


        </div>
        <div class="pm-pop-bottom col-12">
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.CANCEL_B' | translate}}</a>
            <a (click)="buyNow('BUY_NOW')" aria-label="Next" class="btn next-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.PROCEED_B' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--   Riyaviru transport modal -->
<div aria-hidden="true" data-backdrop="static" data-keyboard="false" class="modal overflowvisible fade npa-model"
  id="riyaviruModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content tcontent">
      <div class="modal-header">
        <h5 class="modal-title"><img src="../../../assets/images/riyaviru.png" alt="" class="riyviru">
          {{'riyaviru_transport'|translate}}
        </h5>
        <button #closeRiyawiruModal (click)="riyawiruModalShow()" aria-label="Close" class="close" data-dismiss="modal"
          type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="tr-riyaviru">
          <div class="d-flex align-items-center dblock justify-content-between top-bar">
            <div class="dleft d-flex align-items-center">
              <div class="dcp-img">
                <img src="{{this.commonImg}}" alt="" />
              </div>
              <div>
                <h5>{{name}}</h5>
                <span class="p-qty">{{selectQty}} x {{'CURRENCY.CURRENCY' | translate}}
                  {{unitPrice| number:'2.2-2'}} </span>
                <p>
                  <span class="u-item">(per {{unitType}})</span> <br />
                </p>
              </div>

            </div>
            <div class="dright">
              <p>{{'PRODUCT_WEIGHT'|translate}}
                <span>{{this.quantity}}{{unitType}}</span>
              </p>
            </div>
          </div>
          <div class="pick-option">
            <p><img src="../../../assets/images/icons/vehicle.png" alt=""> &nbsp;{{'PICK_A_VEHICLE'|translate}}</p>
            <div class="vehicle-list row">
              <div class="col-lg-2 v-i-list" *ngFor="let vehicle of riyawiruVehicleTypes;let i = index"
                (click)="selectVehicleType(vehicle['typeId'],SKU)" [ngClass]="{'active':selectedId==vehicle['typeId']}">
                <div class="row">
                  <div class="col-12">
                    <div class="v-item">
                      <img src="{{vehicle['icons']?.icon}}" alt="">
                    </div>
                  </div>
                  <div class="col-12 vhclname" style="font-size: 12px;margin-bottom: 1vh">
                    <span *ngIf="activeLang === 'en'" title=" {{vehicle['name']['nameEN']}}">
                      {{vehicle['name']['nameEN']}}
                    </span>
                    <span *ngIf="activeLang === 'si'" title="{{vehicle['name']['nameSI']}}">
                      {{vehicle['name']['nameSI']}}
                    </span>
                    <span *ngIf="activeLang === 'ta'" title="{{vehicle['name']['nameTM']}}">
                      {{vehicle['name']['nameTM']}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span style="color: red;font-size: 10px" *ngIf="isPickVehicle">{{'please_pick_the_vehicle'|translate}}
              !</span>
          </div>
          <div class="other-info">
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="dleft">
                  <div class="d-flex align-items-start">
                    <span class="material-icons iconred">
                      location_on
                    </span>
                    <div class="l-info">
                      <h5>{{'Item_Pickup_Location'|translate}}:</h5>
                      <p>{{location}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-bar">
            <div class="row align-items-center">
              <div class="col-md-8 col-xs-12">
                <div class="dleft">
                  <p>{{'My_Expectation'|translate}} ({{'CURRENCY.CURRENCY' | translate}})</p>
                  <input style="text-align: left!important;" min="1" type="number" class="binput"
                    [(ngModel)]="expectationPrice" (focusout)="checkExpectationPrice(expectationPrice)">
                  <span style="color: red;font-size: 10px"
                    *ngIf="isRequiredExpectation">{{'required_field'|translate}}</span>
                  <small>
                    {{'RIYAWIRU_BIT_REQUEST_TEXT'|translate}}
                  </small>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="dright">
                  <button (click)="sendBidRequest(SKU,expectationPrice)" class="btn btn-default next"
                    aria-label="Close">
                    {{'SEND_BID_REQUEST'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>