import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(private http: HttpClient, private  mainUrl: MainUrlService) {
  }

  checkEmailExists(email): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/identity/checkEmailExists/' + email);
  }

  checkContactNoExists(contactNo): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/identity/checkContactNoExists/' + contactNo);
  }

  checkNICExists(nic): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/identity/checkNICExists/' + nic);
  }

  checkUsernameExists(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/identity/checkUsernameExists/' + username);
  }

}

