<app-header></app-header>

<div class="main-container">
  <div class="container product-view">
    <div class="row">

      <div class="col-lg-12">
        <div class="pl-content w-full-col">
          <div class="inner-panel">

            <div class="container">

              <div class="row">
                <div class="col-md-4">
                  <div class="image-container">
                    <div class="zoom-popup">
                    </div>

                    <div class="view-product">
                      <div class="image-box p-view">
                        <img src="{{img1}}" alt="" data-target="#openImage" data-toggle="modal"
                             class="show demo-trigger">
                      </div>

                    </div>

                    <div class="zoom-popup">
                      <i aria-hidden="true" class="fa fa-search-plus"></i>
                      {{'PRODUCT_VIEW.OPEN_IMAGE' | translate}}
                    </div>
                  </div>


                </div>
                <div class="col-md-8 right-side">
                  <div class="row">
                    <!--                    board-right-->
                    <div class="col-md-8">

                      <div class="product-info-container">
                        <div class="product-title">
                          {{name}}
                        </div>
                        <div class="best-by-date" style="margin-bottom: 10px;">
                          <div class="title">
                            {{listedByName}}
                          </div>
                        </div>

                        <div class="best-by-date" style="margin-bottom: 10px;">
                          <div class="title">
                            {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.QUANTITY_REQUIRED' | translate}}
                          </div>
                          <div class="info">
                            <span>{{requiredQty}}{{unitType}}</span>
                          </div>
                        </div>
                        <div class="category">
                          <span class="category-item">{{'PRODUCT_VIEW.CATEGORY' | translate}}
                            <span *ngIf="activeLang === 'en'">{{categoryNameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{categoryNameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{categoryNameTM}}</span>/
                            <span *ngIf="activeLang === 'en'">{{subCategoryNameEN}}</span>
                            <span *ngIf="activeLang === 'si'">{{subCategoryNameSI}}</span>
                            <span *ngIf="activeLang === 'ta'">{{subCategoryNameTM}}</span></span>
                        </div>
                        <div class="best-by-date" style="margin-bottom: 10px;">
                          <div class="title">
                            {{'PRODUCT_VIEW.LOCATION' | translate}}
                          </div>
                          <div class="info">
                            <span>{{location}}</span>
                          </div>
                        </div>

                        <p id="orderRequestTimer"
                           style="font-size: 35px; text-transform: uppercase; font-weight: 600; color: #474747; margin-top: 15px;">
                        </p>

                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'PRODUCT_VIEW.LISTED_DATE' | translate}}
                          </div>
                          <div class="info">
                            {{listedDate}}
                          </div>
                        </div>
                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.BIDS_ACCEPTED_TILL' | translate}}:
                          </div>
                          <div class="info">
                            {{bidAcceptedDate}}
                          </div>
                        </div>
                        <div class="date">
                          <span class="material-icons">
                            date_range
                          </span>
                          <div class="title">
                            {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.ORDER_EXPECTED_DATE' | translate}}:
                          </div>
                          <div class="info">
                            {{expectedDeliveryDate}}
                          </div>
                        </div>

                      </div>
                      <div class="price-actions">

                        <div class="row" style="margin-top: 15px;">
                          <div class="col-md-7 aligningg">
                            <div class="input-container">
                              <span class="input-group-addon" style="border-radius: 5px 0 0 5px;">{{'CURRENCY.CURRENCY'|
                                translate}}</span>
                              <input [(ngModel)]="bidAmount" class="form-control KG-input" min="1"
                                     [disabled]="isDisabled"
                                     style="width: 100%; border-left: 0;" type="number"/>

                            </div>
                          </div>
                          <div class="col-md-1 d-flex">
                            <div class="d-flex align-items-center justify-content-center">
                              <h4 class="m-0 fortxt">{{'FOR'|translate}}</h4>
                            </div>

                          </div>
                          <div class="col-md-4 aligningg">
                            <div class="row-space">
                            </div>
                            <div class="input-container">
                              <div class="amount">
                                <input class="form-control KG-input" min="1" style="width: 100%" type="number"
                                       [disabled]="isDisabled"
                                       [(ngModel)]="bidQty"/>
                              </div>
                              <span class="input-group-addon">{{unitType}}</span>
                            </div>


                          </div>
                        </div>
                        <br>
                        <p><span class="material-icons hlp">
                            help
                          </span>
                          &nbsp; {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.YOUR_EARNING_FROM' | translate}}
                        </p>

                        <div class="row">
                          <div class="col-md-12 aligningg">
                            <button (click)="placeBid()" class="darkbtn" style="color: white">
                              <span class="material-icons">
                                credit_card
                              </span>
                              &nbsp; {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.PLACE_BID' | translate}}
                            </button>
                            <button hidden data-target="#transport-options" data-toggle="modal"
                                    #transportOptions></button>
                          </div>
                          <span *ngIf="isDisabled"
                            style="color: red;margin-left: 11px;font-weight: bold">{{'OrderRequestNotValid'|translate}}</span>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="col-md-4">-->
                    <!--                      <div class="transport-options">-->
                    <!--                        <div class="deliver-options-card">-->
                    <!--                          <div class="select-delivery">-->
                    <!--                            <div class="d-flex justify-content-end">-->
                    <!--                              <span class="material-icons hlp">-->
                    <!--                                help-->
                    <!--                              </span>-->
                    <!--                            </div>-->
                    <!--                            <p>-->
                    <!--                              {{'singleView_line1'|translate}}-->
                    <!--                            </p>-->
                    <!--                            <div class="option-list">-->
                    <!--                              <div class="d-flex align-items-center t-opt"-->
                    <!--                                   matTooltip="{{'riyaviru_transport_tooltip'| translate}}">-->
                    <!--                                <img src="../../assets/images/icons/transport1.png" alt="">-->
                    <!--                                <p>{{'riyaviru_transport'|translate}}</p>-->
                    <!--                              </div>-->
                    <!--                              <div *ngIf="isSeller" class="d-flex align-items-center t-opt"-->
                    <!--                                   matTooltip="{{'seller_transport_tooltip'|translate}}">-->
                    <!--                                <img src="../../assets/images/icons/transport2.png" alt="">-->
                    <!--                                <p>{{'seller_transport'|translate}} - {{'Fixed_Rate'|translate}}</p>-->
                    <!--                              </div>-->

                    <!--                              <div *ngIf=" isSellerFixed" class="d-flex align-items-center t-opt"-->
                    <!--                                   matTooltip="{{'seller_transport_tooltip'|translate}}">-->
                    <!--                                <img src="../../assets/images/icons/transport2.png" alt="">-->
                    <!--                                <p>{{'seller_transport'|translate}} - {{'Per_Km_Rate'|translate}}</p>-->
                    <!--                              </div>-->
                    <!--                              <div *ngIf="this.isTrain" class="d-flex align-items-center t-opt"-->
                    <!--                                   matTooltip="{{'RailFreightTransport_tooltip'|translate}}">-->
                    <!--                                <img src="../../assets/images/icons/transport3.png" alt="">-->
                    <!--                                <p>{{'RailFreightTransport'|translate}}</p>-->
                    <!--                              </div>-->
                    <!--                              <div class="d-flex align-items-center t-opt"-->
                    <!--                                   matTooltip="{{'SelfPickup_tooltip'|translate}}">-->
                    <!--                                <img src="../../assets/images/icons/transpor4.png" alt="">-->
                    <!--                                <p>{{'SelfPickup'|translate}}</p>-->
                    <!--                              </div>-->
                    <!--                            </div>-->
                    <!--                            <div class="deliver-cost">-->
                    <!--                              <p class="or-text"><span class="material-icons">-->
                    <!--                                  local_shipping-->
                    <!--                                </span> <span>{{'singleViewDelivery'|translate}}</span></p>-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->

                    <!--                    </div>-->
                  </div>

                  <div class="col-sm-12 col-lg-4">

                  </div>

                  <div class="price-actions">

                    <hr>
                    <div class="desc">
                      <div class="des-title">
                        {{'PRODUCT_VIEW.DESCRIPTION' | translate}}
                      </div>
                      <div class="des-content">
                        {{description}}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="cp-spinner cp-morph"></div>
          </div>
        </div>
        <div class="clear">&nbsp;</div>


      </div>
    </div>
  </div>

</div>


<app-footer></app-footer>


<div class="modal fade" id="openImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <img *ngIf="img1" class="demo-trigger" src="{{this.img1}}" alt=""/>
      </div>
    </div>
  </div>
</div>


<!-- Transport options -->
<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" id="transport-options" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_OPTIONS' | translate}}</h5>
        <button #transportOptionsClose data-dismiss="modal" type="button" class="close"
                (click)="resetTransportOptions()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="opt-row">
          <div class="transport-opt row">
            <div class="products-set pset-a col-md-4 col-xs-12">
              <div class="c-products-sum">

                <div class="dcp-item pro-items d-flex align-items-start">
                  <div class="dcp-img">
                    <img src="{{this.img1}}" alt=""/>
                  </div>
                  <div class="dcp-info">
                    <h6 class="p-name">{{this.name}}</h6>
                    <span class="p-qty">{{'CURRENCY.CURRENCY' | translate}}
                      {{this.bidAmount| number:'2.2-2'}}( {{this.bidQty}}{{this.unitType}} ) </span>
                    <p><br/>
                      <span class="u-loc breakall">{{this.location}}</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <hr>
            <div class="products-set col-md-8 col-xs-12 transportmethods" id="nav-tabContent">
              <div class="anp-select-op categoryform row">

                <div class="messgnetext tr-options">

                  <form [formGroup]="deliveryForm">
                    <div class="buyertransport rb-cl-wp" [ngClass]="{'activated':deliveryOptions==1}">
                      <div class="form-check dcheck">
                        <mat-checkbox (change)="checkVal($event,3)" [checked]="isSellerDeliver"></mat-checkbox>
                        <label class="form-check-label">
                          <div class="toptile">
                            <div class="t-title">
                              <h5>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGE' |
                                translate}}
                              </h5>
                              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' |
                                translate}}</p>
                            </div>
                          </div>

                          <div class="row extrad">
                            <label
                              class="exlabel">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_PER'
                              | translate}}
                              ({{'CURRENCY.CURRENCY' | translate}})</label>
                            <input class="form-control" style="margin-left: 6%" formControlName="deliverCharge"
                                   type="number">
                          </div>
                          <div *ngIf="isChargeReq"
                               style="font-size: 10px;color: red;margin-left: 15px;margin-top: 1vh;">
                            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' |
                            translate}}
                          </div>
                        </label>
                      </div>
                    </div>
                  </form>
                  <form [formGroup]="deliveryFormFixed">
                    <div class="buyertransport rb-cl-wp" [ngClass]="{'activated':deliveryOptions==5}">
                      <div class="form-check dcheck">
                        <mat-checkbox (change)="checkVal($event,5)" [checked]="isSellerDeliverFixed"></mat-checkbox>
                        <label class="form-check-label" style="color: black;">
                          <div class="toptile">
                            <div class="t-title">
                              <h5>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_FINED_RATE' |
                                translate}}</h5>
                              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' |
                                translate}}</p>
                            </div>
                          </div>
                          <br/>
                          <div class="row extrad">
                            <mat-radio-button [checked]="areaDisable" value="1" (change)="areaDisable=true"
                                              class="tos-radio">
                              <p style="margin-left: 0">
                                {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.ISLAND_DELIVERY' | translate}}
                              </p>
                            </mat-radio-button>
                            <mat-radio-button value="1" [checked]="!areaDisable" (change)="areaDisable=false"
                                              class="tos-radio">
                              <p style="margin-left: 0">
                                {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_DELIVERY' |
                                translate}}</p>
                            </mat-radio-button>
                          </div>
                          <div [hidden]="areaDisable">
                            <div class="row extrad">
                              <label class="exlabel" style="margin-bottom: 1vh">
                                {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA' | translate}}
                              </label>
                              <textarea class="form-control" maxlength="100" formControlName="areas"></textarea>

                            </div>
                            <div *ngIf="isAreaReq" style="font-size: 10px;color: red">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA_REQUIRED' |
                              translate}}
                            </div>
                          </div>
                          <div class="row extrad">
                            <label class="exlabel">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGED' | translate}}
                              ({{'CURRENCY.CURRENCY' | translate}}) </label>
                            <input class="form-control" style="margin-left: 6%" formControlName="deliverChargeFixed"
                                   type="number">
                          </div>
                          <div *ngIf="isFixedChargeReq"
                               style="font-size: 10px;color: red; margin-left: 15px;margin-top: 1vh;">
                            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' |
                            translate}}
                          </div>
                        </label>
                      </div>
                    </div>
                  </form>
                  <div class="buyertransport rb-cl-wp">
                    <div class="form-check dcheck">
                      <mat-checkbox (change)="checkVal($event,4)" [disabled]="true" [checked]="train"></mat-checkbox>
                      <label class="form-check-label">
                        <div class="toptile">
                          <div class="t-title">
                            <h5>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.USE_RAIL_TRANSPORT' |
                              translate}}
                            </h5>
                            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY_BUYER'
                              |
                              translate}}</p>
                          </div>
                        </div>
                        <div class="selects row extrad">
                          <div class="form-group">
                            <label for="railway" style="margin-bottom: 10px;">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.NEAREST_STATION' | translate}}
                            </label>
                            <select disabled class="form-control province" id="railway">
                              <option disabled>
                                {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SELECT_STATION' | translate}}
                              </option>
                              <option *ngFor="let station of railwayList" [value]="station.id">
                                {{station.descriptionEng}}
                              </option>

                            </select>
                            <div class="inputError" *ngIf="railwayReq" style="margin-left: 4vw;margin-top: 1vh;">
                              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.STATION_REQUIRED' | translate}}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="buyertransport rb-cl-wp" [ngClass]="{'activated':deliveryOptions==3}">
                    <div class="form-check dcheck">
                      <mat-checkbox (change)="checkVal($event,2)" [checked]="buyerDeliver"></mat-checkbox>
                      <label class="form-check-label">
                        <div class="toptile">
                          <div class="t-title">
                            <h5>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_TRANSPORT' | translate}}
                            </h5>
                            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_RESPONSIBILITY' |
                              translate}}
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>


        </div>
        <div class="pm-pop-bottom col-12">
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal" (click)="resetTransportOptions()">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.CANCEL_B' | translate}}</a>
            <a (click)="bidOrderRequest()" aria-label="Next" class="btn next-btn-half">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.PROCEED_B' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--   Riyaviru transport modal -->
<div aria-hidden="true" data-backdrop="static" data-keyboard="false" class="modal overflowvisible fade npa-model"
     id="riyaviruModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content tcontent">
      <div class="modal-header">
        <h5 class="modal-title"><img src="../../../assets/images/riyaviru.png" alt="" class="riyviru">
          {{'riyaviru_transport'|translate}}
        </h5>
        <button #closeRiyawiruModal (click)="riyawiruModalShow()" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="tr-riyaviru">
          <div class="d-flex align-items-center dblock justify-content-between top-bar">
            <div class="dleft d-flex align-items-center">
              <div class="dcp-img">
                <img src="{{this.img1}}" alt=""/>
              </div>
              <div>
                <h5>{{name}}</h5>
                <span class="p-qty">{{bidQty}} x {{'CURRENCY.CURRENCY' | translate}}
                  {{bidAmount| number:'2.2-2'}} </span>
                <p>
                  <span class="u-item">(per {{unitType}})</span> <br/>
                </p>
              </div>

            </div>
            <div class="dright">
              <p>{{'PRODUCT_WEIGHT'|translate}}
                <span>{{this.bidQty}}{{unitType}}</span>
              </p>
            </div>
          </div>
          <div class="pick-option">
            <p><img src="../../../assets/images/icons/vehicle.png" alt=""> &nbsp;{{'PICK_A_VEHICLE'|translate}}</p>
            <div class="vehicle-list row">
              <div class="col-lg-2 v-i-list" *ngFor="let vehicle of riyawiruVehicleTypes;let i = index"
                   (click)="selectVehicleType(vehicle['typeId'],sku)"
                   [ngClass]="{'active':selectedId==vehicle['typeId']}">
                <div class="row">
                  <div class="col-12">
                    <div class="v-item">
                      <img src="{{vehicle['icons']?.icon}}" alt="">
                    </div>
                  </div>
                  <div class="col-12 vhclname" style="font-size: 12px;margin-bottom: 1vh">
                    <span *ngIf="activeLang === 'en'" title="{{vehicle['name']['nameEN']}}">
                      {{vehicle['name']['nameEN']}}
                    </span>
                    <span *ngIf="activeLang === 'si'" title="{{vehicle['name']['nameSI']}}">
                      {{vehicle['name']['nameSI']}}
                    </span>
                    <span *ngIf="activeLang === 'ta'" title=" {{vehicle['name']['nameTM']}}">
                      {{vehicle['name']['nameTM']}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span style="color: red;font-size: 10px" *ngIf="isPickVehicle">{{'please_pick_the_vehicle'|translate}}
              !</span>
          </div>
          <div class="other-info">
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="dleft">
                  <div class="d-flex align-items-start">
                    <span class="material-icons iconred">
                      location_on
                    </span>
                    <div class="l-info">
                      <h5>{{'Item_Pickup_Location'|translate}}:</h5>
                      <p>{{location}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-bar">
            <div class="row align-items-center">
              <div class="col-md-8 col-xs-12">
                <div class="dleft">
                  <p>{{'My_Expectation'|translate}} ({{'CURRENCY.CURRENCY' | translate}})</p>
                  <input style="text-align: left!important;" min="1" type="number" class="binput"
                         [(ngModel)]="expectationPrice">
                  <span style="color: red;font-size: 10px"
                        *ngIf="isRequiredExpectation">{{'required_field'|translate}}</span>
                  <small>
                    {{'RIYAWIRU_BIT_REQUEST_TEXT'|translate}}
                  </small>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="dright">
                  <button (click)="sendBidRequest(sku,expectationPrice)" class="btn btn-default next"
                          aria-label="Close">
                    {{'SEND_BID_REQUEST'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
