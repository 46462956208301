import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';
import {BankAccountDTO} from '../dto/bankAccountDTO';
import {ApproveDTO} from '../dto/approveDTO';
import {saveAs} from 'file-saver';
import {UserTokenDTO} from '../dto/userTokenDTO';
import {WelcomeDTO} from '../dto/welcomeDTO';
import {LoanApplicationDto} from '../dto/loanApplicationDto';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  fetchDashboardSelling(username, userType): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/overview/selling/getByUser/' + username + '/' + userType + '?X-TenantID=wholesale');
  }

  fetchEconomicFarmerSellingDashboard(username, userType): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/overview/selling/forShopFarmers/' + username + '/' + userType + '?X-TenantID=wholesale');
  }

  fetchDashboardBuying(username, userType): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/overview/buying/getByUser/' + username + '/' + userType + '?X-TenantID=wholesale');
  }

  fetchAllUnitTypes(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/units/all');
  }

  fetchAllFaqs(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/faq/all');
  }

  helpfulFaq(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/faq/helpful/' + id, '');
  }

  notHelpfulFaq(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/faq/notHelpful/' + id, '');
  }

  searchFaq(term): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/faq/search/' + term);
  }

  fetchUserDetails(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/userDetails/details/' + id);
  }

  fetchAllBanners(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/banner/all');
  }

  subscribeService(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/newsLetter/subscribe', body);
  }

  fetchContactUsPageDetails(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/contactUs/all');
  }

  getBankBranch(bank: number): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/getAllBranch/' + bank);
  }

  saveAccount(accountDto: BankAccountDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/bank/saveAccount', accountDto);
  }

  getBankAccount(userName) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/getAllAccount/' + userName);
  }

  setDefaultAccount(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/bank/setDefault/' + id, '');
  }

  getBankAccountById(id) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/getById/' + id);
  }

  updateBankAccount(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/bank/updateAccount', body);
  }

  getPendingAccount() {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/getAllPendingAccounts/');
  }

  getPendingDetails(id): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/bank/approveAccount/' + id, '');
  }

  rejectPendingAccount(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/bank/rejectAccount/', body);
  }

  getAccountDetail(id) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/getById/' + id);
  }

  deleteAccount(id: any) {
    return this.http.delete<any>(this.mainUrl.MAIN_URL_CORE + '/bank/deleteAccount/' + id);
  }

  getPendingUsers(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/fetchAllUser/' + 'PENDING');
  }

  getPendingUserDetails(dto: ApproveDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/getUser/', dto);
  }

  saveDecision(approveDTO: ApproveDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/approveOrReject', approveDTO);
  }

  generateExcelFile(type): any {
    return this.http.get(this.mainUrl.MAIN_URL_ONBOARDING + '/user/generateExcel/' + type, {responseType: 'blob'})
      .subscribe(res => {
        const blob = new Blob([res], {type: res.type});
        saveAs(blob, 'users.xls');
      }, () => {
        alert('Sorry file not available!');
      });
  }

  fetchAllProductTypes(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/type/all');
  }

  fetchUserRatings(userName: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/rating/getMyReview/' + userName);
  }

  getRating(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/rating/getAllRating/');
  }

  deleteRate(id: any) {
    return this.http.delete<any>(this.mainUrl.MAIN_URL_CORE + '/rating/delete/' + id);
  }

  fetchUserDetailsByUserName(userTokenDTO: UserTokenDTO): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_AUTH + '/users/location', userTokenDTO);
  }

  fetchUserRefNo(userName: any) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/userDetails/getRefNo/' + userName);
  }

  fetchFarmersByLeadCode(lead_code: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/agent/getFarmerList/' + lead_code);
  }

  fetchFarmersByEcoShopCode(lead_code: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/farmer/getFarmerList/' + lead_code);
  }

  fetchEconomicCenterDetails(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/economicCenter/getDetails/' + username);
  }

  checkLeadCodeExist(lead_code: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/agent/checkLeadCodeExist/' + lead_code);
  }

  checkEconomicShopCodeExist(shopCode: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/economic/shop/checkShopCodeExist/' + shopCode);
  }


  printLetter(welcomeDTO: WelcomeDTO): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_ONBOARDING + '/agent/welcomeLetter', welcomeDTO, {responseType: 'arraybuffer'});
  }

  fetchBankDetails(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/activeAccountByUser/' + username);
  }

  findRegistrationDetailsByUser(user: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/user/registrationDetailsByUser/' + user);
  }

  findUserDetailsByUsername(username: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/userDetails/getUserProfile/' + username);
  }

  getBank(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/getAllBank');
  }

  isExistBank(user: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/bank/isExistBank/' + user);
  }

  getStation(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/railway/fetchStation');
  }

  getDeliveryMethod(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/fetchDeliveryOption');
  }

  fetchOwnership(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/loan/fetchOwnership');
  }

  fetchPurpose(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/loan/getAllLoanPurposes');
  }

  saveLoanApplication(loanApplicationDto: LoanApplicationDto): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/loan/saveApplication', loanApplicationDto);
  }

  getLoanApplication(user: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/loan/getAllLoanRequest/' + user);
  }

  loadDraftData(id: number) {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/loan/fetchLoanRequestById/' + id);
  }

  updateLoanApplication(loanApplicationDto: LoanApplicationDto): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/loan/updateApplication', loanApplicationDto);
  }

  sendContactUsMail(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/agent/sendContactUsMail', body);
  }

  printInvoice(body: any): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/printInvoice', body, {responseType: 'arraybuffer'});
  }

  fetchWholesalersByLeadCode(lead_code: string): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING + '/agent/getWholesalerList/' + lead_code);
  }

  requestMobilePackage(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/value_added/mobilePackage/save', body);
  }

  requestLoanFarmersPeoplesBank(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/loan/peoples/save', body);
  }

  fetchAllPeoplesBankLoanRequests(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/loan/peoples/getByUsername/' + username);
  }

  requestVehicleInsuranceSLIC(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/vehicle/save', body);
  }

  requestMachineryInsuranceSLIC(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/machinery/save', body);
  }

  requestFireInsuranceSLIC(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/fire/save', body);
  }

  requestAccidentInsuranceSLIC(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/accident/save', body);
  }

  fetchAllVehicleInsuranceSLICRequests(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/getAllByUsername/' + username);
  }

  vehicleInsuranceSLICRequestsById(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/vehicle/getById/' + id);
  }

  machineryInsuranceSLICRequestsById(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/machinery/getById/' + id);
  }

  fireInsuranceSLICRequestsById(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/fire/getById/' + id);
  }

  accidentInsuranceSLICRequestsById(id): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + '/insurance/accident/getById/' + id);
  }


}
