import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreService} from '../../../service/core.service';
import {BankBranchDTO} from '../../../dto/bankBranchDTO';
import {BankAccountDTO} from '../../../dto/bankAccountDTO';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRoleService} from '../../../service/user-role.service';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {BankDto} from '../../../dto/bankDto';
import {InteractionService} from '../../../service/interaction.service';
import Swal from 'sweetalert2';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, last, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {CommonService} from '../../../common/common.service';
import {MainUrlService} from '../../../service/main-url.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnInit {
  bankAccForm: FormGroup;
  editBankAccForm: FormGroup;
  public bankList: BankDto[];
  public branches: BankBranchDTO[];
  public branchList: BankBranchDTO[];
  public accounts: BankAccountDTO[];
  accountDto: BankAccountDTO = new BankAccountDTO();
  submitted: boolean = false;
  tenantValue: any;
  userName: string;
  selectNewProductOptions: number;
  isRouteSellAProduct: boolean = false;
  selectedBrn: any = 0;
  files: File[] = [];
  imageArray = [];
  files1: File[] = [];
  imageArray1 = [];

  accountId: any;
  accountUser: any;
  accountType: any;
  editAccountType: any;
  accountUserName: any;
  accountNo: any;
  editAccountNo: any;
  accountHolder: any;
  accountDes: any;
  accountImg: any;
  accountBankBranch: any;
  accountBankName: any;
  accountDetails: any;
  imageObject: Array<object> = [];
  setDefaultId: any;

  @ViewChild('closeButton') closeButton;


  constructor(private coreService: CoreService,
              private router: Router,
              private route: ActivatedRoute,
              private _formBuilder: FormBuilder,
              private userRoleService: UserRoleService,
              private interactionService: InteractionService,
              private validationHandlerService: ValidationHandlerService,
              private commonService: CommonService,
              private http: HttpClient,
              public mainUrl: MainUrlService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.userName =
localStorage.getItem('USERNAME');
    this.reactiveForm();

  }

  ngOnInit() {
    this.route.queryParams.subscribe(parms => {
      if (parms['bankAccount']) {
        this.isRouteSellAProduct = true;
      }
    });
    this.getAccounts();
    this.getBank();
  }

  reactiveForm() {
    this.bankAccForm = this._formBuilder.group({
      bankBranch: ['', Validators.required],
      bank: ['', Validators.required],
      accountNo: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyNumbersAllowed())]],
      holderName: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      accountType: ['', Validators.required],
      description: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]
    });
    this.editBankAccForm = this._formBuilder.group({
      accountBankBranch: ['', Validators.required],
      accountUser: ['', Validators.required],
      accountBankName: ['', Validators.required],
      editAccountType: ['', Validators.required],
      accountDes: ['', Validators.required],
      editAccountNo: ['', Validators.required],
      accountNo: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyNumbersAllowed())]],
      accountHolder: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      accountType: ['', Validators.required],
      description: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]
    });
  }

  get f() {
    return this.bankAccForm.controls;
  }

  getBank() {
    this.coreService.getBank().subscribe(
      (data: BankDto[]) => {
        this.bankList = data;
      }
    );
  }

  loadBranch(): void {
    this.selectedBrn = 0;
    this.coreService.getBankBranch(this.bankAccForm.value.bank).subscribe(
      (data: BankBranchDTO[]) => {
        this.branches = data;
      }
    );
  }

  loadBankBranch(event) {
    this.editBankAccForm.value.accountBankName = undefined;
    if (event.target.value !== 'none') {
      this.editBankAccForm.value.accountBankName = event.target.value;
      this.coreService.getBankBranch(this.editBankAccForm.value.accountBankName).subscribe(resultList => {
        this.branchList = resultList;
      });
      this.accountBankName = event.target.value;

    } else {
      this.editBankAccForm.value.accountBankName = event.target.value;
    }
  }

  selectBankBranch(event) {
    if (event.target.value !== 'none') {
      this.accountBankBranch = event.target.value;
    } else {
      this.accountBankBranch = undefined;
    }
  }

  addAccount() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.submitted = true;
      if (this.bankAccForm.invalid) {
        return;
      }
      this.loading();
      this.accountDto = this.bankAccForm.value;
      this.accountDto.userName = this.userName;

      if (this.files.length >= 1 && this.files.length <= 4) {
        for (let image of this.imageArray) {
          this.accountDto.image = image.image;
        }
        this.coreService.saveAccount(this.accountDto).subscribe(res => {
          if (res.success) {
            Swal.close();
            this.bankAccForm.reset();
            this.interactionService.setBankStatus(true);
            this.closeButton.nativeElement.click();
            this.submitted = false;
            this.getAccounts();
            Swal.fire({
              position: 'center',
              type: 'success',
              title: this.validationHandlerService.bankSaveMessage(sessionStorage.getItem('LANG')),
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              if (this.isRouteSellAProduct) {
                Swal.close();
                this.router.navigate(['/my-account/' + this.tenantValue], {queryParams: {'isRouteSellAProduct': true}});
              }
            });
          } else {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: res.message,
              showConfirmButton: false,
              timer: 1500
            });
          }
        });
      } else {
        Swal.fire({
          title: this.validationHandlerService.imageValidate(sessionStorage.getItem('LANG')),
          type: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
        });
      }
    } else {
      this.commonLogoutURL();
    }
  }

  getAccounts() {
    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.coreService.getBankAccount(this.userName).subscribe(
        (data: BankAccountDTO[]) => {
          Swal.close();
          this.accounts = data;
        }
      );
    } else {
      this.commonLogoutURL();
    }
  }

  setDefault(value) {
    this.setDefaultId = value;
    this.coreService.setDefaultAccount(this.setDefaultId).subscribe(result => {
      if (result) {
        this.getAccounts();
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Bank Account is set to default',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          Swal.close();
        });
      } else {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: result.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    }, () => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Something went wrong..!!!',
        showConfirmButton: false,
        timer: 1500
      });

    });
  }

  getAccountById(id) {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.coreService.getBankAccountById(id).subscribe(details => {
        if (Object.keys(details).length > 0) {
          this.accountDetails = details;
          this.accountId = details['id'];
          this.accountUser = details['userName'];
          this.editAccountType = details['accountType'];
          this.accountBankBranch = details.bankBranch['description'];
          this.editAccountNo = details['accountNo'];
          this.accountHolder = details['holderName'];
          this.accountDes = details['description'];
          this.accountBankName = details['bankName'];
          this.getBankId();
          this.accountImg = details.image;
          this.loadImages(this.accountImg);
        }
      });
    }
  }

  getBankId() {
    if (this.bankList.length > 0) {
      for (let bank of this.bankList) {
        if (bank.name === this.accountBankName) {
          this.accountBankName = bank.id;
          this.getBankBranchId(bank.id);
          return;
        }
      }
    }
  }

  getBankBranchId(id) {
    this.coreService.getBankBranch(id).subscribe(resultList => {
      this.branchList = resultList;
      if (this.branchList.length > 0) {
        for (let branch of this.branchList) {
          if (branch.description === this.accountBankBranch) {
            this.accountBankBranch = branch.code;
            return;
          }
        }
      }
    });
  }

  updateAccount() {
    this.loading();
    this.accountDto.id = this.accountId;
    this.accountDto.userName = this.accountUser;
    this.accountDto.bankBranch = this.accountBankBranch;
    this.accountDto.accountNo = this.editAccountNo;
    this.accountDto.holderName = this.accountHolder;
    this.accountDto.description = this.accountDes;
    this.accountDto.accountType = this.editAccountType;
    this.accountDto.bank = this.accountBankName;
    if (this.files1.length >= 1 && this.files1.length <= 4) {
      for (let image of this.imageArray1) {
        this.accountDto.image = image.image;
      }

      this.coreService.updateBankAccount(this.accountDto).subscribe(result => {
        if (result.success) {
          this.getAccounts();
          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.bankUpdateMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            Swal.close();
          });
        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: result.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, () => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Something went wrong..!!!',
          showConfirmButton: false,
          timer: 1500
        });

      });
    } else {
      Swal.fire({
        title: this.validationHandlerService.imageValidate(sessionStorage.getItem('LANG')),
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });
    }
  }

  loadImages(value: any) {
    this.imageObject = [];
    this.imageObject.push({
      image: environment.proxyUrl + 'fileservice/file/' + value + '/?X-TenantID=' + this.tenantValue,
      thumbImage: environment.proxyUrl + 'fileservice/file/' + value + '/?X-TenantID=' + this.tenantValue,
      alt: ''
    });
  }

  deleteAccount(id) {
    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.coreService.deleteAccount(id).subscribe(res => {
        Swal.close();
        if (res) {
          this.getAccounts();
          this.interactionService.setBankStatus(true);
          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.bankDeleteMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Account not found',
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    } else {
      this.commonLogoutURL();
    }
  }

  get form() {
    return this.bankAccForm.controls;
  }

  get form1() {
    return this.editBankAccForm.controls;
  }

  commonLogoutURL() {
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/' + this.tenantValue]);
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  clearAddBankFields() {
    this.bankAccForm.reset();
  }

  onSelect(event) {
    if (this.files.length <= 3 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.imageUploads(event.addedFiles);
        this.files.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files.splice(-1, 1);
        }
      }
    }
  }

  onSelectEdit(event) {
    if (this.files1.length <= 3 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.imageEditUploads(event.addedFiles);
        this.files1.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files1.splice(-1, 1);
        }
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }
  }

  onRemoveEdit(event) {
    for (let image of this.imageArray1) {
      if (image.event === event) {
        let index = this.imageArray1.indexOf(image);
        if (index !== -1) {
          this.imageArray1.splice(index, 1);
        }
        this.files1.splice(this.files1.indexOf(event), 1);
      }
    }
  }

  imageUploads(value) {
    this.commonService.processing();
    let formData = new FormData();
    if (value[0].size > 5000000) {
      Swal.close();
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });

      return false;
    }
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        this.files.splice(-1, 1);
        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

  imageEditUploads(value) {
    this.commonService.processing();
    let formData = new FormData();
    if (value[0].size > 5000000) {
      Swal.close();
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });

      return false;
    }
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        this.files.splice(-1, 1);
        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray1.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

}

