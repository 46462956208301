import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../service/product.service';
import {MainUrlService} from '../service/main-url.service';
import {CartService} from '../service/cart.service';
import {Global} from '../service/global';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {SystemSettingsService} from '../service/system-settings.service';
import {InteractionService} from '../service/interaction.service';
import {UserRoleService} from '../service/user-role.service';
import {CommonService} from '../common/common.service';
import Swal from 'sweetalert2';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {CoreService} from '../service/core.service';
import {TransportDetailsDTO} from '../dto/TransportDetailsDTO';
// @ts-ignore
import moment from 'moment';

@Component({
  selector: 'app-product-view-bid',
  templateUrl: './product-view-bid.component.html',
  styleUrls: ['./product-view-bid.component.css']
})
export class ProductViewBidComponent implements OnInit {

  specAttributeRankOrder = [];
  specAttributes = [];
  filstatus: boolean = false;

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  spinner: number = 0;
  prodId: any;
  description: any;
  unitPrice: any;
  discount: any;
  location: any;
  bidEndDate: any;
  bidStartDate: any;
  listedDate: any;
  expiryDate: any;
  bestBuyDate: any;
  sellInSmallerQuantities: boolean = false;
  name: any;
  availableTotalQty: any;
  img1: any;
  img2: any;
  img3: any;
  img4: any;
  stockAvailable: any;
  installmentCollection: any;
  contractTotalAmount: any;
  categoryName: any;
  subCategoryName: any;
  farmerUsername: any;
  cartItemCount: any;
  typeId: any;
  typeName: any;
  selectQtyPanel = false;
  hideReviews = false;
  CURRENCY_CODE: any;
  commonImg: any;
  unitType: any;
  qty: any;
  SpecAll: any;
  subCatId: number;
  attributeRanks: any;
  profileId: any;
  SKU: any;
  getProductView: any;
  contractInitialPayment: any;
  contractClosingPayment: any;
  startDate: any;
  addToCart: boolean;
  spinner2: number = 1;
  spinner0: number = 1;

  allCategory: any;
  allNewArrivalCategory: any;
  load = 0;
  avgRating: any;
  tenantValue: any = 'wholesale';
  farmerName: any;
  farmerLocation: any;
  Type: string;
  metadataList: any = [];
  CHECKOUT_ENABLE: boolean = false;
  rate: any;
  similarProductList: any;
  categoryId: any;
  minimumBid: any;

  selectBitValue: any;
  timeStamp: any;
  bidCount = 0;
  endTime = '';
  bidDetails: any;

  activeLang: string = 'en';
  categoryNameEN: any;
  categoryNameSI: any;
  categoryNameTM: any;
  subCategoryNameEN: any;
  subCategoryNameSI: any;
  subCategoryNameTM: any;
  citySin: any;
  cityTam: any;
  city: any;
  addressArray = [];
  validUser = true;
  userName: any;
  listedBy: any;

  station: any;
  seller: any;
  sellerUsername: any;
  delivery_price: any;
  sellerMobile: any;
  sellerName: any;
  prdImg: any;
  sellerLocation: any;
  delivery_priceFixed: any;
  delivery_desc: any;
  isTrain = false;
  isSeller = false;
  isSellerFixed = false;
  isBuyer = false;
  leadLabel: any = null;
  economicCenter: any = null;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private cookie: CookieService,
              private route: ActivatedRoute,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cartService: CartService,
              private router: Router,
              private coreService: CoreService,
              private global: Global,
              private validationHandlerService: ValidationHandlerService,
              private toastr: ToastrService,
              private titleService: Title,
              private setting_service: SystemSettingsService,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,
              private interaction: InteractionService,
              public commonService: CommonService,
  ) {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit() {
    this.getUserInfo();
    this.route.queryParams.subscribe(params => {
      this.prodId = params['id'];
      this.typeId = params['typeId'];
      this.typeName = params['typeName'];

      this.getSpecByProductId(this.prodId);
      this.fetchAllAdditionalMetaData(this.prodId);

      this.selectQtyPanel = true;
      this.hideReviews = true;

      this.interactionService.activeLang$.subscribe(activeResult => {
        if (activeResult) {
          this.activeLang = sessionStorage.getItem('LANG');
          this.setCategoryNames(
            this.categoryNameEN,
            this.categoryNameSI,
            this.categoryNameTM,
            this.subCategoryNameEN,
            this.subCategoryNameSI,
            this.subCategoryNameTM);
        }
      });
      this.productService.fetchAuctionProduct(this.prodId).subscribe(result => {

        this.CURRENCY_CODE = this.global.CURRENCY_CODE;
        this.setting_service.fetchAll().subscribe(fetchAll => {
          let tempArray = [];

          tempArray.push(fetchAll);

          for (let settings of tempArray) {
            for (let setting of settings) {

              switch (setting.code) {

                case 'BASE_COLOUR':
                  this.global.BASE_COLOUR = setting.value;

                  if (localStorage.getItem('--primary-color') !== this.global.BASE_COLOUR) {
                    localStorage.setItem('--primary-color', this.global.BASE_COLOUR);
                    document.documentElement.style.setProperty('--primary-color',
                      this.global.BASE_COLOUR);
                  } else {
                    document.documentElement.style.setProperty('--primary-color',
                      localStorage.getItem('--primary-color'));
                  }

                  break;

                case 'BASE_LANGUAGE':
                  this.global.BASE_LANGUAGE = setting.value;
                  localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);

                  if (localStorage.getItem('BASE_LANGUAGE') !== this.global.BASE_LANGUAGE) {
                    localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);
                  } else {
                    this.global.BASE_LANGUAGE = localStorage.getItem('BASE_LANGUAGE');
                  }

                  break;

                case 'BASE_URL':
                  this.global.BASE_URL = setting.value;
                  localStorage.setItem('BASE_URL', this.global.BASE_URL);

                  if (localStorage.getItem('BASE_URL') !== this.global.BASE_URL) {
                    localStorage.setItem('BASE_URL', this.global.BASE_URL);
                  } else {
                    this.global.BASE_URL = localStorage.getItem('BASE_URL');
                  }
                  break;

                case 'CURRENCY_CODE':
                  this.global.CURRENCY_CODE = setting.value;
                  this.CURRENCY_CODE = setting.value;
                  localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);

                  if (localStorage.getItem('CURRENCY_CODE') !== this.global.CURRENCY_CODE) {
                    localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);
                  } else {
                    this.global.CURRENCY_CODE = localStorage.getItem('CURRENCY_CODE');
                  }
                  break;

                case 'LOGO_WHITE':
                  this.global.LOGO_WHITE = setting.value;
                  localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);

                  if (localStorage.getItem('LOGO_WHITE') !== this.global.LOGO_WHITE) {
                    localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);
                  } else {
                    this.global.LOGO_WHITE = localStorage.getItem('LOGO_WHITE');
                  }
                  break;

                case 'MAINTENANCE_MODE':
                  this.global.MAINTENANCE_MODE = setting.value;
                  break;

                case 'CHECKOUT_ENABLE':
                  this.CHECKOUT_ENABLE = setting.value === 'true';
                  break;

              }
            }

          }
        });

        this.stockAvailable = result.availableTotalQty;
        this.minimumBid = result.startPrice;
        this.leadLabel = result.leadLabel;
        this.economicCenter = result.economicCenter;
        this.selectBitValue = result.startPrice;
        this.qty = result.quantity;
        this.installmentCollection = result.futureProductDTOList;
        this.contractTotalAmount = result.contractTotalAmount;
        this.contractInitialPayment = result.contractInitialPayment;
        this.contractClosingPayment = result.contractClosingPayment;
        this.startDate = result.startDate;
        this.addToCart = false;
        this.categoryNameEN = result.product.subCategory.mainCategory.commonIndex.description;
        this.categoryNameSI = result.product.subCategory.mainCategory.commonIndex.sinhala;
        this.categoryNameTM = result.product.subCategory.mainCategory.commonIndex.tamil;
        this.categoryId = result.product.subCategory.mainCategory.commonIndex.id;
        this.subCategoryNameEN = result.product.subCategory.commonIndex.description;
        this.subCategoryNameSI = result.product.subCategory.commonIndex.sinhala;
        this.subCategoryNameTM = result.product.subCategory.commonIndex.tamil;
        this.subCatId = result.product.subCategory.code;
        this.name = result.product.name;
        this.avgRating = result.product.avgRating;
        this.unitType = result.product.unit.unit;
        this.location = result.product.address;
        this.addressArray = this.location.split(',');
        this.city = this.addressArray[this.addressArray.length - 1];
        this.citySin = result.citySin;
        this.cityTam = result.cityTam;
        this.bidEndDate = this.commonService.convertDate(result.bidEndDate);
        this.bidStartDate = this.commonService.convertDate(result.bidStartDate);
        this.listedDate = this.commonService.convertDate(result.created);
        this.expiryDate = this.commonService.convertDate(result.expiredDate);
        this.endTime = result.bidEndDate.split('.', 1) + '';
        this.SKU = result.sku;
        this.getProductViewsCount(result.sku);
        this.getSpecAttributeRankOrder(this.subCatId);
        this.fetchUserDetails(result.product.listedBy);
        this.fetchRatings(result.product.listedBy);

        this.bidTimeCountDown(result.bidEndDate, false);

        this.getBidAcuDetails(this.SKU);

        this.listedBy = result.product.listedBy;

        if (result.productImages.length > 0) {
          this.img1 = this.mainUrl.MAIN_URL_FILE + result.productImages[0].image + '/?X-TenantID=' +
            this.tenantValue;
          this.commonImg = this.img1;
        } else {
          this.commonImg = undefined;
        }

        this.description = result.product.description;
        if (result.productImages.length >= 2) {
          this.img2 = this.mainUrl.MAIN_URL_FILE + result.productImages[1].image + '/?X-TenantID=' +
            this.tenantValue;
        } else {
          this.img2 = false;
        }

        if (result.productImages.length >= 3) {
          this.img3 = this.mainUrl.MAIN_URL_FILE + result.productImages[2].image + '/?X-TenantID=' +
            this.tenantValue;
        } else {
          this.img3 = false;
        }

        if (result.productImages.length === 4) {
          this.img4 = this.mainUrl.MAIN_URL_FILE + result.productImages[3].image + '/?X-TenantID=' +
            this.tenantValue;
        } else {
          this.img4 = false;
        }
        this.loadDeliveryTransports();
        Swal.close();
      });
      this.fetchAll();
    });

  }

  setCategoryNames(catEN, catSI, catTM, subCatEn, SubCatSI, SubCatTM) {
    this.categoryNameEN = catEN;
    this.categoryNameSI = catSI;
    this.categoryNameTM = catTM;
    this.subCategoryNameEN = subCatEn;
    this.subCategoryNameSI = SubCatSI;
    this.subCategoryNameTM = SubCatTM;
  }

  fetchRatings(username) {
    this.coreService.fetchUserRatings(username).subscribe(rate => {
      if (rate) {
        this.rate = rate;
      }
    });
  }

  getTime(created) {
    return moment(created).format('MMMM Do YYYY, h:mm:ss a');
  }

  fetchUserDetails(username) {
    this.userRoleService.fetchUserDetails(username).subscribe(userDetails => {
      if (userDetails) {
        this.farmerName = userDetails['name'];
        this.farmerLocation = userDetails['address'];
      }
    });
  }

  temp(value) {
    this.cartService.showCartByOwner(value, this.tenantValue).subscribe(result => {
      this.cartItemCount = Object.keys(result).length;
      localStorage.setItem('cartItemCount', this.cartItemCount);
    });
  }

  getProductViewsCount(sku) {
    this.productService.addProductView(sku, this.tenantValue);
    this.productService.getProductViews(sku, this.tenantValue).subscribe(getProductViews => {
      this.getProductView = getProductViews;
    });
  }


  getSpecByProductId(proId) {
    this.productService.getSpecsByProductId(proId, this.tenantValue).subscribe(specList => {
      if (specList) {
        if (Object.keys(specList).length > 0) {
          for (let spec of specList) {
            if (spec.specCode === 'XXX') {
              this.SpecAll = 0;
              return;
            }
          }
          this.SpecAll = specList;

        } else {
          this.SpecAll = 0;
        }
      }
    });
  }

  getSpecAttributeRankOrder(code) {
    this.productService.getSpecsOrderBySubCategory(code, this.tenantValue).subscribe(result => {
      if (result) {
        this.attributeRanks = result;
        for (let loop of result) {
          this.profileId = loop.profile.code;
          this.specAttributeRankOrder.push({
            'specId': loop.spec.code,
            'specOrder': loop.specOrder,
            'attributeId': ''
          });
        }
      }
    });
  }

  fetchAll() {
    this.productService.getActiveProfile().subscribe(activeProfile => {
      if (activeProfile) {
        this.productService.getAllMainCategoryByProfileId
        (activeProfile.code).subscribe(getAllMainCategoryByProfileId => {
          if (getAllMainCategoryByProfileId) {
            this.spinner0 = 0;
            this.allCategory = getAllMainCategoryByProfileId;
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;

            this.productService.fetchAllNewArrivalSpotProducts(35, activeProfile.code,
              this.tenantValue).subscribe(fetchAllNewArrivalSpotProducts => {
              if (fetchAllNewArrivalSpotProducts) {
                this.spinner2 = 0;
                this.allNewArrivalCategory = fetchAllNewArrivalSpotProducts;
              }
            });
          }
        });
      }
    });
  }

  fetchAllAdditionalMetaData(prodId) {
    this.productService.fetchAllAdditionalMedataByProductId(prodId, this.tenantValue).subscribe(result => {
      if (result) {

        if (Object.keys(result).length > 0) {
          for (let loop of result) {

            if (loop.metaDataId.visible === true && loop.value !== '' && loop.value !== null) {
              this.metadataList.push(loop);
            }
          }
        }
      }
    });
  }


  setType(type: any) {
    switch (type) {
      case 'SPOT':
        return 'spot';

      case 'FORWARD_ACU':
        return 'bid';

      default:
        return 'forward';

    }
  }

  viewImg(img, i) {
    this.commonImg = img;

    switch (i) {

      case 1 :

        document.getElementById('img' + 1).style.opacity = '1';
        document.getElementById('img' + 1).style.filter = 'unset';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 2 :

        document.getElementById('img' + 2).style.opacity = '1';
        document.getElementById('img' + 2).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 3 :

        document.getElementById('img' + 3).style.opacity = '1';
        document.getElementById('img' + 3).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 4 :

        document.getElementById('img' + 4).style.opacity = '1';
        document.getElementById('img' + 4).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        break;
      default:
        break;

    }

  }

  loadDeliveryTransports() {
    this.isSeller = false;
    this.isSellerFixed = false;
    this.cartService.fetchAuctionTransportDetails(this.SKU).subscribe(
      (data: TransportDetailsDTO) => {
        this.sellerMobile = data.sellerMobile;
        this.sellerLocation = data.sellerLocation;
        this.sellerName = data.sellerName;
        this.prdImg = data.imgUrl;
        this.sellerUsername = data.seller;
        if (data.deliveryOpt !== null) {
          for (let opt of data.deliveryOpt) {
            this.userRoleService.fetchUserDetails(data.seller).subscribe(result => {
              this.seller = result.name;
            });
            if (opt.deliveryOpt === '2') {
              this.isBuyer = true;
            }
            if (opt.deliveryOpt === '3') {
              this.isSeller = true;
              this.delivery_price = opt.deliveryCharges;
            }
            if (opt.deliveryOpt === '4') {
              this.isTrain = true;
              this.station = opt.station;
            }
            if (opt.deliveryOpt === '5') {
              this.isSellerFixed = true;
              this.delivery_priceFixed = opt.deliveryCharges;
              this.delivery_desc = opt.deliveryDesc;
            }
          }

        }
      }
    );
  }

  submitBid(status, index) {

    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.commonService.processing();

      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        Swal.close();
        this.userName = whoAmIDetails['email'];

        if (this.userName !== this.listedBy) {
          Swal.fire({
            text: this.validationHandlerService.customBidTitle(sessionStorage.getItem('LANG')),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG')),
            confirmButtonText: this.validationHandlerService.yesButtonMessage(sessionStorage.getItem('LANG'))
          }).then((result) => {
            if (result.value) {
              this.commonService.processing();

              if (this.selectBitValue !== undefined) {

                if (status) {
                  let value = (<HTMLInputElement>document.getElementById(index)).textContent;
                  this.selectBitValue = parseFloat(value).toFixed(2);
                }

                if (this.minimumBid < this.selectBitValue) {
                  let _forwardAcuBody = {
                    'bidValue': parseFloat(this.selectBitValue),
                    'timestamp': this.timeStamp,
                    'userId': this.userName,
                    'product': {
                      'sku': this.SKU,
                    }
                  };
                  this.productService.createBidAcu(_forwardAcuBody, this.tenantValue).subscribe(createBidAcu => {
                    if (createBidAcu.success) {
                      this.selectBitValue = this.minimumBid;
                      this.getBidAcuDetails(this.SKU);
                      Swal.close();
                      Swal.fire({
                        position: 'center',
                        type: 'success',
                        title: this.validationHandlerService.auctionBidSuccess(this.activeLang),
                        showConfirmButton: false,
                        timer: 3500
                      }).then(() => {
                        this.router.navigate(['/refresh-product-view-bid/' + this.tenantValue]).then(() => {
                          this.router.navigate(['/product-view-bid/' + this.tenantValue], {
                            queryParams: {
                              'id': this.prodId,
                              'typeId': this.typeId,
                              'typeName': this.typeName
                            }
                          });
                        });
                      });
                    } else {
                      Swal.close();
                    }
                  }, () => {
                    Swal.close();
                  });
                } else {
                  Swal.fire(this.validationHandlerService.auctionBidError1(this.activeLang), '', 'error');
                }
              } else {
                Swal.fire(this.validationHandlerService.auctionBidError2(this.activeLang), '', 'error');
              }
            }
          });
        } else {
          Swal.fire({
            text: this.validationHandlerService.ownAuctionProductMessage(sessionStorage.getItem('LANG')),
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG'))
          });
        }

      }, () => {
        Swal.close();
      });


    } else {
      this.popUpLoginFormModal();
    }
  }

  popUpLoginFormModal() {
    Swal.close();
    localStorage.setItem('isLogged', 'False');
    this.interaction.popUpLoginModal('false');
  }

  getBidAcuDetails(sku) {
    this.productService.getBidAcuDetails(sku).subscribe(getBidAcuDetails => {
      this.bidCount = Object.keys(getBidAcuDetails).length;
      this.productService.getTopBidAcuDetails(sku).subscribe(result => {
        this.bidDetails = result;
      });

      if (this.bidCount > 0) {
        this.productService.getHighestForwardAcuBid(sku, this.tenantValue).subscribe(value => {
          if (value !== 0) {
            this.minimumBid = value;
          }
          this.selectBitValue = this.minimumBid;
        });
      }

    });
  }

  filterOn() {
    this.filstatus = !this.filstatus;
  }

  checkEnterBitValue(selectBitValue: any) {
    if (selectBitValue <= 0) {
      this.selectBitValue = this.minimumBid;
      return;
    }
  }

  bidTimeCountDown(endTime, status) {
    let x = setInterval(() => {
      let countDownDate = new Date(moment(endTime).format('MMMM DD YYYY, h:mm:ss'));
      countDownDate.setHours(24, 0, 0, 0);
      const endTimenew = countDownDate.getTime();
      const now = new Date().getTime();
      const distance = endTimenew - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      let temp = document.getElementById('demo');
      if (temp !== null) {
        temp.innerHTML = '<strong>' + days + '</strong> d <em>' + ':' + '</em>' +
          '<strong>' + hours + '</strong> h <em>' + ':' + '</em>' +
          '<strong>' + minutes + '</strong> m <em>' + ':' + '</em>' +
          '<strong>' + seconds + '</strong> s'
        ;
      }

      if (distance < 0) {
        clearInterval(x);
        document.getElementById('demo').innerHTML = 'EXPIRED';
      }
    }, 1000);

    if (status) {
      for (let i = 1; i < x; i++) {
        window.clearInterval(i);
      }
      return;
    }
  }

  roundBidValue(value: number, type: number) {
    return (value + (type * (value * 0.1))).toFixed(2);
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getUserInfo() {
    this.userRoleService.whoAmI().subscribe(userDetails => {
      this.userName = userDetails['email'];
    });
  }
}
