import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Global} from '../../service/global';
import {ToastrService} from 'ngx-toastr';
import {ProductService} from '../../service/product.service';
import {MainUrlService} from '../../service/main-url.service';
import {CoreService} from '../../service/core.service';
import {Md5} from 'ts-md5';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRoleService} from '../../service/user-role.service';
import {SystemSettingsService} from '../../service/system-settings.service';

@Component({
  selector: 'app-award-bid',
  templateUrl: './award-bid.component.html',
  styleUrls: ['./award-bid.component.css']
})
export class AwardBidComponent implements OnInit {

  allOrders: any;

  CURRENCY_CODE: any;
  review: any;
  rate: any;
  sku: any;
  reviewForm: FormGroup;

  spinner1: number = 1;
  spinner2: number = 1;
  spinner0: number = 1;

  allCategory: any;
  allDisCategory: any;
  allNewArrivalCategory: any;
  load = 0;

  userName: any;
  showDetails: any;
  displayMobile: any;
  displayCountry: any;
  imgUrl: string;
  tenantValue: any;
  BASE_URL: string;
  foundResult = false;
  FORWARD_ORDER_ENABLE = false;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(public global: Global,
              private toastr: ToastrService,
              public productService: ProductService,
              public mainUrl: MainUrlService,
              private core_service: CoreService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private settingsService: SystemSettingsService) {
  }

  ngOnInit() {
      this.tenantValue = this.route.snapshot.paramMap.get('tenant_id');
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.productService.fetchAllBidAwards(whoAmIDetails['email'], this.tenantValue).subscribe(result => {
          this.allOrders = result;
          if (Object.keys(result).length > 0) {
            this.foundResult = true;
          }
          this.CURRENCY_CODE = this.global.CURRENCY_CODE;
          this.BASE_URL = this.global.BASE_URL;
        });
      });

      this.reviewForm = new FormGroup({
        'review': new FormControl(null, Validators.required),
      });
      this.loadProducts();
      this.loadProducts();
      this.getUserDetails();
      this.fetchAll();
  }

  loadProducts() {
    this.productService.getActiveProfile().subscribe(profileCode => {
      if (profileCode) {
        this.productService.getAllMainCategoryByProfileId(profileCode.code).subscribe(re => {
          if (re) {
            this.spinner0 = 0;
            this.allCategory = re;
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;

            this.productService.fetchAllDiscountSpotProducts(6, profileCode.code, this.tenantValue).subscribe(result => {
              if (result) {
                this.spinner1 = 0;
                this.allDisCategory = result;
                this.productService.fetchAllNewArrivalSpotProducts(6, profileCode.code, this.tenantValue).subscribe(result => {
                  if (result) {
                    this.spinner2 = 0;
                    this.allNewArrivalCategory = result;
                  }
                });
              }
            });
          }
        });

      }
    }, () => {

    });

  }

  getUserDetails() {

    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {


      this.core_service.fetchUserDetails(whoAmIDetails['email']).subscribe(result => {
        if (result) {

          if (result.id !== null) {

            let hashStr;
            if (whoAmIDetails['email'] !== null) {
              hashStr = Md5.hashStr(whoAmIDetails['email']);
            } else {
              hashStr = Md5.hashStr('');
            }

            this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '';
            this.userName = result.firstName + ' ' + result.lastName;
            this.displayMobile = result.phone;
            this.displayCountry = result.addressCollection[0].country;

            this.showDetails = true;
          } else {
            this.showDetails = true;

            this.userName = whoAmIDetails['name'];
            this.displayMobile = whoAmIDetails['mobileNumber'];
            let hashStr;
            if (whoAmIDetails['email'] !== null) {
              hashStr = Md5.hashStr(whoAmIDetails['email']);
            } else {
              hashStr = Md5.hashStr('');
            }
            this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '';
          }
        }else{
          this.showDetails = false;

        }
      });
    });
  }

  convertDate(created: any) {
    let fullYear = new Date(created).getFullYear();
    let month = new Date(created).getMonth();
    let date = new Date(created).getDate();
    return month + '/' + date + '/' + fullYear;
  }


  submit() {
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      if (this.review !== undefined && this.rate > 0) {

        let body = {
          'productId': this.sku,
          'description': this.review,
          'stars': parseInt(this.rate),
          'userName': whoAmIDetails['email']
        };
        this.productService.feedback(body, this.tenantValue).subscribe(result => {
          if (result) {
            this.toastr.success('Submitted!');
          }
        });
      } else {
        let body = {
          'productId': this.sku,
          'description': this.review,
          'stars': 0,
          'userName': whoAmIDetails['email']
        };
        this.productService.feedback(body, this.tenantValue).subscribe(result => {
          if (result) {
            this.toastr.success('Submitted!');
          }
        });
      }
    });


  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          switch (setting.code) {

            case 'FORWARD_ORDER_ENABLE':
              this.FORWARD_ORDER_ENABLE = setting.value === 'true';
              break;
          }
        }
      }
    });
  }

}
