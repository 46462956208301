import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Global} from '../../service/global';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ProductService} from '../../service/product.service';
import {MainUrlService} from '../../service/main-url.service';
import {CartService} from '../../service/cart.service';
import {PaymentService} from '../../service/payment.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {CoreService} from '../../service/core.service';
import {DeliveryService} from '../../service/delivery.service';
import {UserRoleService} from '../../service/user-role.service';
import {InteractionService} from '../../service/interaction.service';
import {SystemSettingsService} from '../../service/system-settings.service';
import {ValidationHandlerService} from '../../validation-handler/validation-handler.service';
import {CommonSignUpService} from '../../service/common-sign-up.service';
import {UserTokenDTO} from '../../dto/userTokenDTO';
import {CommonService} from '../../common/common.service';
import Swal from 'sweetalert2';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {catchError, last, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnChanges {

  coTestimonialConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    showNavigationIndicators: true,
  };

  selectPaymentType: number = 1;
  selectDeliveryOptions = 1;
  selectDeliveryType = 0;
  selectedBank = 0;
  pmtTabItem = 1;

  totalAmount: any;
  selectItem: any;
  currency;
  checkoutExpiryTime;
  paymentId;

  paymentDeliveryForm: FormGroup;
  paymentBillingForm: FormGroup;
  referenceNoForm: FormGroup;

  submittedDeliveryPayment = false;
  submittedBillingPayment = false;
  submittedReferenceNumber = false;
  isDeliveryAddressVisible = false;
  isBillingAddressVisible = false;

  address1_d: any;
  address1_b: any;

  address2_d: any;
  address2_b: any;

  city_d: any;
  city_b: any;

  province_d: any;
  province_b: any;

  district_d: any;
  district_b: any;


  country_d: any;
  country_b: any;

  postal_d: any;
  postal_b: any;

  mobile_d: any;
  mobile_b: any;

  name_d: any;
  name_b: any;

  username;
  itemLength: number;
  subTotalAmount: any;
  qrImage: any;
  cartId: any;
  stockOut: boolean = false;
  showDiscount: boolean = false;
  discountValue: any;
  district: any;
  qrCode: any;
  bidProduct: boolean = false;
  tenantValue: any;

  _activePaymentMethod: any;
  BASE_URL: string;

  public userTokenDTO = new UserTokenDTO;

  selectDeliveryDistrictId: any;

  resultIndicator: any;
  sessionVersion: any;

  selectNewProductOptions: number;

  referenceNumber: any;
  bankTransferForm: any;
  allProvinceList: any;
  activeLang: string = 'en';
  bidId: any;
  productSku: any;
  orderRequest: any;
  transactionTrimValue: any;
  platformChargers: any;
  addressList: any[] = [];
  mobile_b_temp: any;
  mobile_d_temp: any;

  read_d: boolean = true;
  read_b: boolean = true;
  selectDeliveryCityId: any;
  selectDeliveryCityName: any;
  selectBillingCityId: any;

  districtList_d: any;
  districtList_b: any;
  allCities_d: any;
  allCities_b: any;
  selectBillingDistrictId: any;
  selectBillingCityName: any;
  none: any;
  orderId: string;
  transport: any;
  isDeliveryAddressSave: boolean = false;
  isBillingAddressSave: boolean = false;
  isSameAsBillingAddress: boolean = false;
  isShownDelivery: boolean = false;
  isShownBilling: boolean = false;

  _productType: any = 'SPOT';
  riyawiruList: any;
  orderRequestList: any;
  riyawiruArray = [];

  imageArray = [];
  files: File[] = [];
  image_orderRequest: any;
  whoAmIDetails: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */


  @ViewChild('cardPaymentConfirmation') cardPaymentModal;
  @ViewChild('smartPayConfirmation') smartPay;
  @ViewChild('clickCardPayment') clickCartPay;
  @ViewChild('clickSmartPay') clickSmartPay;
  @ViewChild('paymentSuccess') paymentSuccess;
  @ViewChild('closeReferenceInputForm') closeModal;
  @ViewChild('closeBankTransferForm') closeBankTransferInputModal;
  @ViewChild('pendingBankTransfer') pendingBankModal;
  @ViewChild('openBankTransferModal') openBankTransferModal;


  constructor(private global: Global,
              private toastr: ToastrService,
              private router: Router,
              private http: HttpClient,
              private cookie: CookieService,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cartService: CartService,
              private coreService: CoreService,
              private paymentService: PaymentService,
              private titleService: Title,
              private route: ActivatedRoute,
              private deliveryService: DeliveryService,
              private userRoleService: UserRoleService,
              private interaction: InteractionService,
              private generalSettings: SystemSettingsService,
              private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private commonSignUpService: CommonSignUpService,
              public commonService: CommonService,
              private interactionService: InteractionService
  ) {

    this.validationPersonalDetails();
    this.validationReferenceNumber();

  }

  ngOnInit() {

    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.whoAmIDetails = JSON.parse(localStorage.getItem('WHO_AM_I_DETAILS'));
    this.username = this.whoAmIDetails?.email;
    this.getTotal(this.username);
    this.loadAddresses(this.username);

    this.loading();
    this.interactionService.checkoutItemForm$.subscribe(reCall => {
      if (reCall === 'true') {
        if (this.productSku === undefined && window.location.href.includes('checkout')) {
          this.loading();
          this.common();
        }
      }
    });

    this.route.queryParams.subscribe(params => {

      this.resultIndicator = params['resultIndicator'];
      this.productSku = params['sku'];
      this.orderRequest = params['orderRequest'];
      this.sessionVersion = params['sessionVersion'];
      this.transport = decodeURIComponent(params['transport_id']);

      if (params['riyawiruList'] !== undefined) {
        this.riyawiruList = decodeURIComponent(params['riyawiruList']);

        for (let riyawiru of JSON.parse(this.riyawiruList)) {
          if (riyawiru['vehicleType'] !== null) {
            this.riyawiruArray.push({
              'sku': riyawiru['sku'],
              'price': riyawiru['price'],
              'paymentMethod': 'Cash On Delivery',
              'vehicleType': riyawiru['vehicleType']
            });
          }
        }
        localStorage.setItem('riyawiruList', JSON.stringify(this.riyawiruArray));
      }

      if (params['productDetails'] !== undefined) {
        this.orderRequestList = JSON.parse(decodeURIComponent(params['productDetails']));
        localStorage.setItem('productDetails', JSON.stringify(this.orderRequestList));
      }

      this.fetchAllSettings();

      // this.getUserDetails();


      if (this.resultIndicator === null || this.resultIndicator === 'null' || this.resultIndicator === undefined) {
        this.generatePaymentId();
        this.getUser();
        if (this.productSku !== undefined && this.orderRequest !== 'true') {
          let isLoggedIn = localStorage.getItem('isLogged');
          if (isLoggedIn === 'True') {

            this.mobile_b = this.whoAmIDetails?.mobileNumber;
            this.mobile_d = this.whoAmIDetails?.mobileNumber;
            this.productService.fetchBidAwardBySku(this.username, this.productSku).subscribe(result => {
              this._productType = 'AUCTION';
              if (result) {
                this.saveAuctionDetails();
                let temp = [];
                temp.push(result);
                this.selectItem = temp;
                this.itemLength = 1;
                for (let item of temp) {
                  this.totalAmount = item.unitPrice;
                  this.subTotalAmount = item.unitPrice;
                  this.currency = item.currency;
                  this.bidId = item.id;
                }
                Swal.close();
              } else {
                Swal.close();
              }
            }, () => {
              Swal.close();
            });

          }

        } else if (this.productSku !== undefined && this.orderRequest === 'true') {
          let isLoggedIn = localStorage.getItem('isLogged');
          if (isLoggedIn === 'True') {
            this.mobile_b = this.whoAmIDetails?.mobileNumber;
            this.mobile_d = this.whoAmIDetails?.mobileNumber;
            this.productService.fetchOrderRequestBidAwardBySku(this.username, this.productSku).subscribe(result => {
              this._productType = 'ORDER_REQUEST';
              if (result) {
                this.saveOrderRequestDetails();
                let temp = [];
                temp.push(result);
                this.selectItem = temp;
                this.itemLength = 1;
                for (let item of temp) {
                  this.totalAmount = item.unitPrice;
                  this.subTotalAmount = item.unitPrice;
                  this.currency = item.currency;
                  this.bidId = item.id;

                  if (item.category === 'Farming Products & Processed Food') {
                    this.image_orderRequest = 'assets/images/Thumbnails/Processed Products.jpg';
                  } else if (item.category === 'Spices & Cash Crops') {
                    this.image_orderRequest = 'assets/images/Thumbnails/Spices.jpg';
                  } else if (item.category === 'Services & Information') {
                    this.image_orderRequest = 'assets/images/Thumbnails/Services.jpg';
                  } else if (item.category === 'Plants & Seeds') {
                    this.image_orderRequest = 'assets/images/Thumbnails/Seeds.jpg';
                  } else if (item.category === 'Ayurvedic & Herbal Products') {
                    this.image_orderRequest = 'assets/images/Thumbnails/Ayurveda.jpg';
                  } else if (item.category === 'Equipment and Tools') {
                    this.image_orderRequest = 'assets/images/Thumbnails/Equipments.jpg';
                  } else {
                    this.image_orderRequest = 'assets/images/Thumbnails/' + item.category + '.jpg';
                  }
                }
                Swal.close();
              } else {
                Swal.close();
              }
            }, () => {
              Swal.close();
            });

          }
        } else {
          this.common();
        }
      }
    });

    if (this.resultIndicator === null || this.resultIndicator === 'null' || this.resultIndicator === undefined) {
      this.interaction.checkoutItemForm$.subscribe(reCall => {
        if (reCall === 'true') {
          if (this.productSku === undefined) {
            this.showCartByUsername();
          }
        }
      });
    }
    this.activeLang = sessionStorage.getItem('LANG');
    this.allProvinceList = undefined;
    this.getAllProvince();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
        this.getAllProvince();
      }
    });

  }

  //save auction checkout details
  saveAuctionDetails() {
    this.productService.saveAuctionCheckoutDetails(this.productSku, this.username, this.paymentId).subscribe(() => {
    });
  }

  //save order request checkout details
  saveOrderRequestDetails() {
    this.productService.saveOrderRequestCheckoutDetails(this.productSku, this.username, this.paymentId).subscribe(() => {
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mobile_b_temp'] || changes['mobile_d_temp']) {
      this.mobile_b = this.mobile_b_temp;
      this.mobile_d = this.mobile_d_temp;
    }
  }

  validationPersonalDetails() {
    this.paymentDeliveryForm = this.formBuilder.group({
      name_d: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      address1_d: ['', Validators.required],
      address2_d: [''],
      district_d: ['', Validators.required],
      province_d: ['', Validators.required],
      city_d: ['', Validators.required],
      country_d: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      postal_d: [''],
      mobile_d: ['', [Validators.required, Validators.pattern(this.validationHandlerService.mobileNumberValidation())]]
    });

    this.paymentBillingForm = this.formBuilder.group({
      name_b: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      address1_b: ['', Validators.required],
      address2_b: [''],
      province_b: ['', Validators.required],
      district_b: ['', Validators.required],
      city_b: ['', Validators.required],
      country_b: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      postal_b: [''],
      mobile_b: ['', [Validators.required, Validators.pattern(this.validationHandlerService.mobileNumberValidation())]]
    });

  }

  validationReferenceNumber() {
    this.referenceNoForm = this.formBuilder.group({
      referenceNumber: ['', [Validators.required, Validators.pattern(this.validationHandlerService.referenceNumberValidation())]]
    });

    this.bankTransferForm = this.formBuilder.group({
      referenceNumber: ['', [Validators.required]]
    });
  }

  get f0() {
    return this.paymentDeliveryForm.controls;
  }

  get f1() {
    return this.paymentBillingForm.controls;
  }

  get f2() {
    return this.referenceNoForm.controls;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.allProvinceList = result;
      }
    }, () => {
      Swal.close();
    });
  }

  selectDeliveryProvince(event) {
    if (event.target.value !== 'none') {
      this.province_d = event.target.value;
      this.selectDeliveryDistrictId = undefined;
      this.selectDeliveryCityId = undefined;
      this.postal_d = undefined;
      this.getAllDistrict(this.province_d, 'DELIVERY');
    } else {
      this.province_d = undefined;
    }
  }

  selectDeliveryDistrict(event) {
    if (event.target.value !== 'none') {
      this.district_d = event.target.value;
      this.selectDeliveryCityId = undefined;
      this.postal_d = undefined;
      this.getAllCities(this.district_d, 'DELIVERY');
    } else {
      this.district_d = undefined;
    }
  }

  selectBillingProvince(event) {
    if (event.target.value !== 'none') {
      this.province_b = event.target.value;
      this.selectBillingDistrictId = undefined;
      this.selectBillingCityId = undefined;
      this.postal_b = undefined;
      this.getAllDistrict(this.province_b, 'BILLING');
    } else {
      this.province_b = undefined;
    }
  }


  selectBillingDistrict(event) {
    if (event.target.value !== 'none') {
      this.postal_b = undefined;
      this.district_b = event.target.value;
      this.getAllCities(this.district_b, 'BILLING');
    } else {
      this.district_b = undefined;
    }
  }

  getAllCities(id, type) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        if (type === 'DELIVERY') {
          this.allCities_d = result;
        } else {
          this.allCities_b = result;
        }
      }
    });
  }

  paymentDeliveryFormSubmit() {
    this.submittedDeliveryPayment = true;
    if (this.paymentDeliveryForm.invalid) {
      return;
    }
  }

  paymentBillingFormSubmit() {
    this.submittedBillingPayment = true;
    if (this.paymentBillingForm.invalid) {
      return;
    }
  }


  cardPayment() {
    this.submitForm();
  }

  smartPayment() {
    this.submitForm();
  }

  // getUserDetails() {
  //   let body = {
  //     'userName': this.whoAmIDetails?.email,
  //     'token': this.tenantValue
  //   };
  //   this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
  //     this.userInfo = userResult;
  //   });
  // }

  submitForm() {

    this.submittedDeliveryPayment = true;
    this.submittedBillingPayment = true;

    if (this.paymentDeliveryForm.invalid || this.paymentBillingForm.invalid) {
      return;
    }

    if (this.selectPaymentType === 1) {

      this.confirmPayment();

    } else if (this.selectPaymentType === 2) {
      this.smartPay.nativeElement.click();
    }
  }

  common() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.cartService.findCartByUsername(this.username, this.tenantValue).subscribe(result => {
        if (result) {
          this.cartId = result['id'];
          this.productService.cartMerge(this.username, this.cartId, this.tenantValue).subscribe(() => {
            this.showCartByUsername();
          }, () => {
            Swal.close();
            this.selectItem = '';
            localStorage.removeItem('cardId');
            location.reload();
          });
        }
      }, () => {
        Swal.close();
      });

    } else {
      this.popUpLoginFormModal();
    }
  }

  showCartByUsername() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.cartService.showCartByUsername(this.username, this.tenantValue).subscribe(result => {
        if (Object.keys(result).length > 0) {

          let tempResult = [];
          this.itemLength = Object.keys(result).length;

          for (let res of result) {
            if (res.quantity === 0) {
              this.stockOut = true;
            }
            tempResult.push(res);
          }
          this.selectItem = tempResult;

          this.currency = this.global.CURRENCY_CODE;

          let total = 0;
          for (let item of result) {
            if (item.quantity > 0) {
              total = total + (item.unitPrice * item.quantity);
            }
          }
          if (total !== 0) {
            this.totalAmount = total;
          }
          Swal.close();

        } else {
          this.popUpLoginFormModal();
        }
      }, () => {
        Swal.close();
      });

    } else {
      this.popUpLoginFormModal();
    }

  }

  async checkTransactionCommonBody(email, qrCode, userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
                                   deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
                                   billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict,
                                   billingCity, billingPostalCode,
                                   billingFlag, paymentId, transport, platformCharge) {

    this.loading();

    let address;
    if (deliveryLine2 === null || deliveryLine2 === undefined || deliveryLine2 === '') {
      deliveryLine2 = '';
      address = deliveryLine1;
    } else {
      address = deliveryLine1 + ',' + deliveryLine2;
    }
    let sendConfirmationEmailBody = {
      'email': email,
      'transactionID': paymentId,
      'qrCodeID': qrCode,
      'placedON': new Date(),
      'addressL1': address,
      'addressL2': '',
      'addressL3': '',
      'postalCode': deliveryPostalCode,
      'country': deliveryCountry
    };

    let _paymentSuccessDetailsBody = {
      'userName': userName,
      'transactionId': paymentId,
      'deliveryName': deliveryName,
      'deliveryContactNo': deliveryContactNo,
      'deliveryLine1': deliveryLine1,
      'deliveryLine2': deliveryLine2,
      'deliveryCountry': deliveryCountry,
      'deliveryProvince': deliveryProvince,
      'deliveryDistrict': deliveryDistrict,
      'deliveryCity': deliveryCity,
      'deliveryPostalCode': deliveryPostalCode,
      'deliveryFlag': deliveryFlag,
      'billingName': billingName,
      'billingContactNo': billingContactNo,
      'billingLine1': billingLine1,
      'billingLine2': billingLine2,
      'billingCountry': billingCountry,
      'billingProvince': billingProvince,
      'billingDistrict': billingDistrict,
      'billingCity': billingCity,
      'billingPostalCode': billingPostalCode,
      'billingFlag': billingFlag,
      'transport': transport,
      'platformCharge': platformCharge
    };

    if (this.riyawiruArray?.length > 0) {
      let body = {
        'transactionId': paymentId,
        'buyer': userName,
        'buyerLanguage': this.activeLang,
        'items': this.riyawiruArray,
        'deliveryOption': 'bidding',
        'biddingOption': 'buyer decide',
        'street': deliveryLine1 + ' ' + deliveryLine2,
        'city': deliveryCity,
        'state': deliveryProvince,
        'country': deliveryCountry,
        'postalCode': deliveryPostalCode
      };

      this.productService.saveRiyawiruBid(body).subscribe(() => {
        console.log('$RIYAWIRU_$SUCCESS');
      });
    }

    this.paymentService.savePaymentSuccessDetails(_paymentSuccessDetailsBody).subscribe(() => {
      console.log('$PAYMENT_$SUCCESS');
    });

    if (this.selectPaymentType === 1) {
      this.paymentService.sendPurchaseConfirmationEmail(sendConfirmationEmailBody).subscribe(() => {
        console.log('$SEND_$MAIL');
      });
    }

    return;
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      text: this.validationHandlerService.manageCheckoutMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      width: '300px',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  getUser() {
    let isLoggedIn = localStorage.getItem('isLogged');

    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');

    if (isLoggedIn === 'True') {
      this.userTokenDTO.userName = this.whoAmIDetails?.email;
      this.mobile_b_temp = this.whoAmIDetails?.mobileNumber;
      this.mobile_d_temp = this.whoAmIDetails?.mobileNumber;
      this.mobile_d = this.whoAmIDetails?.mobileNumber;
      this.mobile_b = this.whoAmIDetails?.mobileNumber;
    }
  }

  fetchAllSettings() {
    this.generalSettings.fetchAll().subscribe(results => {
      let arr = [];
      arr.push(results);
      for (let settings of arr) {
        for (let setting of settings) {

          if (setting.code === 'ACTIVE_PAYMENT_METHOD') {
            this._activePaymentMethod = setting.value;
          }

          if (setting.code === 'BASE_URL') {
            this.BASE_URL = setting.value;
          }

          if (setting.code === 'CURRENCY_CODE') {
            this.currency = setting.value;
          }

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }

          if (setting.code === 'PLATFORM_CHARGES') {
            this.platformChargers = setting.value;
          }

          if (setting.code === 'CHECKOUT_EXPIRY_TIME') {
            let ms = setting.value;
            ms = 1000 * Math.round(ms / 1000);
            const d = new Date(ms);
            this.checkoutExpiryTime = d.getUTCMinutes();
          }
        }
      }

      if (this.resultIndicator !== null && this.resultIndicator !== 'null' && this.resultIndicator !== undefined) {
        this.loading();
        this.paymentService.checkBOCPaymentStatus(this.resultIndicator).subscribe(paymentStatus => {
          if (paymentStatus['success']) {
            if (paymentStatus['message'] === 'PAYMENT SUCCESS') {

              let userName =
                localStorage.getItem('username');
              let deliveryName = localStorage.getItem('deliveryName');
              let deliveryContactNo = localStorage.getItem('deliveryContactNo');
              let deliveryLine1 = localStorage.getItem('deliveryLine1');
              let deliveryLine2 = localStorage.getItem('deliveryLine2');
              let deliveryCountry = localStorage.getItem('deliveryCountry');
              let deliveryProvince = localStorage.getItem('deliveryProvince');
              let deliveryDistrict = localStorage.getItem('deliveryDistrict');
              let deliveryCity = localStorage.getItem('deliveryCity');
              let deliveryPostalCode = localStorage.getItem('deliveryPostalCode');
              let deliveryFlag = localStorage.getItem('deliveryFlag');

              let billingName = localStorage.getItem('billingName');
              let billingContactNo = localStorage.getItem('billingContactNo');
              let billingLine1 = localStorage.getItem('billingLine1');
              let billingLine2 = localStorage.getItem('billingLine2');
              let billingCountry = localStorage.getItem('billingCountry');
              let billingProvince = localStorage.getItem('billingProvince');
              let billingDistrict = localStorage.getItem('billingDistrict');
              let billingCity = localStorage.getItem('billingCity');
              let billingPostalCode = localStorage.getItem('billingPostalCode');
              let billingFlag = localStorage.getItem('billingFlag');
              let paymentId = localStorage.getItem('paymentId');
              let platformCharge = localStorage.getItem('platformChargers');
              this.orderId = localStorage.getItem('paymentId');

              this.riyawiruArray = JSON.parse(localStorage.getItem('riyawiruList'));

              let transport;
              if (localStorage.getItem('transport') !== null) {
                transport = JSON.parse(localStorage.getItem('transport'));
              }

              // this.getTotal(userName);

              this.commonPaymentSection(userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
                deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
                billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict, billingCity,
                billingPostalCode, billingFlag, paymentId, transport, platformCharge);

            }
          }
        }, (error) => {
          console.error(error);
        });
      }


    });
  }


  popUpLoginFormModal() {
    Swal.close();
    this.router.navigate(['/home/' + this.tenantValue]);
    this.interaction.popUpLoginModal('false');
  }

  fetchPurchaseHistoryDetails(TID) {
    this.productService.historyDetailsByTransactionID(TID).subscribe(transactionDetails => {

      this.selectItem = transactionDetails.productDetailsDTOS;

      let purchaseHistory = [];
      for (let transaction of transactionDetails.productDetailsDTOS) {
        let image = [];
        this._productType = transaction.productType;
        if (transaction.imageDTOS !== null && transaction.productType !== 'ORDER_REQUEST') {
          image.push({
            'image': this.mainUrl.MAIN_URL_FILE + transaction.imageDTOS[0].image + '/?X-TenantID=' + this.tenantValue
          });
        } else if (transaction.imageDTOS === null && transaction.productType === 'ORDER_REQUEST') {
          let image;
          if (transaction.categoryName === 'Farming Products & Processed Food') {
            image = 'assets/images/Thumbnails/Processed Products.jpg';
          } else if (transaction.categoryName === 'Spices & Cash Crops') {
            image = 'assets/images/Thumbnails/Spices.jpg';
          } else if (transaction.categoryName === 'Services & Information') {
            image = 'assets/images/Thumbnails/Services.jpg';
          } else if (transaction.categoryName === 'Plants & Seeds') {
            image = 'assets/images/Thumbnails/Seeds.jpg';
          } else if (transaction.categoryName === 'Ayurvedic & Herbal Products') {
            image = 'assets/images/Thumbnails/Ayurveda.jpg';
          } else if (transaction.categoryName === 'Equipment and Tools') {
            image = 'assets/images/Thumbnails/Equipments.jpg';
          } else {
            image = 'assets/images/Thumbnails/' + transaction.categoryName + '.jpg';
          }
          this.image_orderRequest = image;
        } else {
          image.push({
            'image': 'assets/defaultBrokenImage.jpg'
          });
        }

        purchaseHistory.push(this.commonFetchPurchaseHistoryDetails(image, transaction.productName,
          transaction.quantity, transaction.unitPrice, transaction.unitType));
      }
      this.selectItem = purchaseHistory;
      this.subTotalAmount = transactionDetails['grandTotal'];
    });
  }

  commonFetchPurchaseHistoryDetails(imageList, name, quantity, unitPrice, unitType) {
    return {
      'productImages': imageList,
      'name': name,
      'quantity': quantity,
      'unitPrice': unitPrice,
      'unitType': unitType
    };
  }

  getTotal(email) {
    this.cartService.getSubTotal(email, this.tenantValue).subscribe(subTotal => {
      this.subTotalAmount = subTotal;
      if (this.totalAmount > this.subTotalAmount) {
        this.discountValue = this.totalAmount - this.subTotalAmount;
        this.showDiscount = true;
      } else {
        this.showDiscount = false;
      }
      // this.paymentService.dynamicQrCode(this.commonService
      //   .orderIdLengthTrim(this.orderId, 10), subTotal).subscribe(dynamicQrCode => {
      //   this.dynamicQR = dynamicQrCode;
      // });
    });
  }

  generatePaymentId() {
    this.paymentService.fetchPaymentId().subscribe(_paymentId => {
      if (_paymentId['success']) {
        this.paymentId = _paymentId['body'];
        this.orderId = _paymentId['body'];
        localStorage.setItem('paymentId', this.paymentId);
      }
    }, () => {
      Swal.close();
    });
  }


  confirmPayment() {
    if (Object.keys(this.selectItem).length > 0) {


      if (this.address2_d === '' || this.address2_d === undefined || this.address2_d === null) {
        this.address2_d = '';
      }

      this.loading();

      if (this.isDeliveryAddressSave) {


        let locationBody_d = {
          'username': this.username,
          'name': this.name_d,
          'status': false,
          'addressOne': this.address1_d,
          'addressTwo': this.address2_d,
          'addressType': {
            'id': 1
          },
          'country': {
            'id': 1
          },
          'cities': {
            'id': this.selectDeliveryCityId
          },
          'postalCode': this.postal_d
        };
        this.commonSignUpService.saveNewAddresses(locationBody_d).subscribe(() => {
        });
      }


      if (this.isBillingAddressSave) {

        let locationBody_b = {
          'username': this.username,
          'name': this.name_b,
          'status': false,
          'addressOne': this.address1_b,
          'addressTwo': this.address2_b,
          'addressType': {
            'id': 1
          },
          'country': {
            'id': 1
          },
          'cities': {
            'id': this.selectDeliveryCityId
          },
          'postalCode': this.postal_d
        };
        this.commonSignUpService.saveNewAddresses(locationBody_b).subscribe(() => {
        });
      }

      localStorage.setItem('username', this.username);
      localStorage.setItem('deliveryName', this.name_d);
      localStorage.setItem('deliveryContactNo', this.mobile_d);
      localStorage.setItem('deliveryLine1', this.address1_d);
      localStorage.setItem('deliveryLine2', this.address2_d);
      localStorage.setItem('deliveryCountry', this.country_d);
      localStorage.setItem('deliveryProvince', this.province_d);
      localStorage.setItem('deliveryDistrict', this.district_d);
      localStorage.setItem('deliveryCity', this.selectDeliveryCityId);
      localStorage.setItem('deliveryPostalCode', this.postal_d);
      localStorage.setItem('deliveryFlag', JSON.stringify(this.isDeliveryAddressSave));

      localStorage.setItem('billingName', this.username);
      localStorage.setItem('billingContactNo', this.mobile_b);
      localStorage.setItem('billingLine1', this.address1_b);
      localStorage.setItem('billingLine2', this.address2_b);
      localStorage.setItem('billingCountry', this.country_b);
      localStorage.setItem('billingProvince', this.province_b);
      localStorage.setItem('billingDistrict', this.district_b);
      localStorage.setItem('billingCity', this.selectBillingCityId);
      localStorage.setItem('billingPostalCode', this.postal_b);
      localStorage.setItem('deliveryFlag', JSON.stringify(this.isBillingAddressSave));
      localStorage.setItem('paymentId', this.paymentId);

      localStorage.setItem('transport', this.transport);

      let _platFee = document.getElementById('platformCharge').textContent;
      localStorage.setItem('platformChargers', _platFee);

      let productType;
      if (this._productType === 'SPOT') {
        productType = 'SPOT';
      } else if (this._productType === 'AUCTION') {
        productType = 'AUCTION';
      } else if (this._productType === 'ORDER_REQUEST') {
        productType = 'ORDER_REQUEST';
      } else {
        productType = 'FORWARD';
      }

      let a = document.createElement('a');
      a.href = this.mainUrl.MAIN_URL_PAYMENT + '/boc/checkoutV1/' + this.paymentId + '/' +
        productType + '/?name=' + this.name_d + '&address=' + this.address1_d + '&city=' + this.selectDeliveryCityName +
        '&postal=' + this.postal_d + '&country=' + this.country_d + '&mobile=' + this.mobile_d + '&userName=' + this.username +
        '&proxyUrl=' + environment.proxyUrl + '&lang=' + this.activeLang +
        '&X-TenantID=' + this.tenantValue;
      a.click();

    }
  }

  calGrandTotal(subTotalAmount, platformChargers) {
    if (this.selectPaymentType === 1) {
      return subTotalAmount / (1 - (platformChargers / 100));
    } else {
      return subTotalAmount;
    }
  }

  calPlatformCharge(subTotalAmount, platformChargers) {
    if (this.selectPaymentType === 1) {
      return (subTotalAmount / (1 - (platformChargers / 100))) - subTotalAmount;
    } else {
      return 0;
    }
  }

  clearStorageValues() {

    localStorage.removeItem('deliveryName');
    localStorage.removeItem('deliveryContactNo');
    localStorage.removeItem('deliveryLine1');
    localStorage.removeItem('deliveryLine2');
    localStorage.removeItem('deliveryCountry');
    localStorage.removeItem('deliveryProvince');
    localStorage.removeItem('deliveryDistrict');
    localStorage.removeItem('deliveryCity');
    localStorage.removeItem('deliveryPostalCode');
    localStorage.removeItem('deliveryFlag');
    localStorage.removeItem('platformCharge');

    localStorage.removeItem('billingName');
    localStorage.removeItem('billingContactNo');
    localStorage.removeItem('billingLine1');
    localStorage.removeItem('billingLine2');
    localStorage.removeItem('billingCountry');
    localStorage.removeItem('billingProvince');
    localStorage.removeItem('billingDistrict');
    localStorage.removeItem('billingCity');
    localStorage.removeItem('billingPostalCode');
    localStorage.removeItem('deliveryFlag');
    localStorage.removeItem('paymentId');

    localStorage.removeItem('transport');
    localStorage.removeItem('riyawiruArray');
  }

  submitReferenceForm(type) {
    if (this.selectPaymentType === 2) {
      this.submittedReferenceNumber = true;
      if (this.referenceNoForm.invalid) {
        return;
      }

      if (this.address2_d === '' || this.address2_d === undefined || this.address2_d === null) {
        this.address2_d = '';
      }

      this.loading();

      let _smartPay = {
        'transactionId': this.paymentId,
        'qrReferenceNo': this.referenceNumber,
        'userName': this.username,
      };

      this.closeModal.nativeElement.click();

      let productType;
      if (this._productType === 'SPOT') {
        productType = 'SPOT';
      } else if (this._productType === 'AUCTION') {
        productType = 'AUCTION';
      } else if (this._productType === 'ORDER_REQUEST') {
        productType = 'ORDER_REQUEST';
      } else {
        productType = 'FORWARD';
      }

      this.paymentService.markAsPaymentBOCSmartPay(_smartPay, productType).subscribe(result => {
        if (result['success']) {
          this.referenceNoForm.reset();
          this.commonPaymentSection(this.username, this.name_d, this.mobile_d, this.address1_d, this.address2_d, this.country_d,
            this.province_d, this.district_d, this.selectDeliveryCityId, this.postal_d,
            this.isDeliveryAddressSave, this.name_b, this.mobile_b, this.address1_b, this.address2_b, this.country_b, this.province_b,
            this.district_b, this.city_b, this.postal_b, this.isBillingAddressSave, this.paymentId, JSON.parse(this.transport), 0);
        }
      }, () => {
        Swal.close();
      });
    } else if (this.selectPaymentType === 3 && this.imageArray.length !== 0) {

      this.commonBankTransfer(type);

    }

  }

  bankTransferSubmitLater(type) {
    this.commonBankTransfer(type);
  }

  commonBankTransfer(type) {

    let _status = null;
    let _image = null;
    let _bankType;

    if (this.pmtTabItem === 1) {
      _bankType = 'Online';

    } else {
      _bankType = 'Offline';
    }


    if (type === 'NOW') {
      _status = 'PENDING';
      _image = this.imageArray[0].image;
    }

    this.loading();

    if (this.address2_d === '' || this.address2_d === undefined || this.address2_d === null) {
      this.address2_d = '';
    }

    let _bankTransfer = {
      'transactionId': this.paymentId,
      'referenceNo': 'N/A',
      'userName': this.username,
      'status': _status,
      'bankType': _bankType,
      'image': _image
    };

    let productType;
    if (this._productType === 'SPOT') {
      productType = 'SPOT';
    } else if (this._productType === 'AUCTION') {
      productType = 'AUCTION';
    } else if (this._productType === 'ORDER_REQUEST') {
      productType = 'ORDER_REQUEST';
    } else {
      productType = 'FORWARD';
    }

    this.paymentService.bankTransfer(_bankTransfer, productType).subscribe(() => {
      this.referenceNoForm.reset();
      this.closeBankTransferInputModal.nativeElement.click();
      this.commonPaymentSection(this.username, this.name_d, this.mobile_d, this.address1_d, this.address2_d, this.country_d,
        this.province_d, this.district_d, this.selectDeliveryCityId, this.postal_d,
        this.isDeliveryAddressSave, this.name_b, this.mobile_b, this.address1_b, this.address2_b, this.country_b, this.province_b,
        this.district_b, this.city_b, this.postal_b, this.isBillingAddressSave, this.paymentId, JSON.parse(this.transport), 0);
    }, () => {
      Swal.close();
    });
  }

  commonPaymentSection(userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
                       deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
                       billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict, billingCity, billingPostalCode,
                       billingFlag, paymentId, transport, platformCharge) {

    let brBody =
      {
        'url': this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + paymentId,
        'proxyUrl': environment.proxyUrl
      };

    this.loading();

    this.paymentService.fetchQrCode(brBody).subscribe(qrCode => {
      if (qrCode) {
        this.checkTransactionCommonBody
        (this.username, qrCode['message'], userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
          deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
          billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict, billingCity, billingPostalCode,
          billingFlag, paymentId, transport, platformCharge).then(() => {
          if (this.selectPaymentType === 1) {
            this.fetchPurchaseHistoryDetails(paymentId);
            this.qrCode = qrCode['message'];
            this.qrImage = this.mainUrl.MAIN_URL_FILE + qrCode['message'] + '/?X-TenantID=' + this.tenantValue;
            Swal.close();
            this.paymentSuccess.nativeElement.click();
          } else {
            Swal.close();
            this.pendingBankModal.nativeElement.click();
          }
          // this.clearStorageValues();
        });
      }
    }, () => {
      Swal.close();
    });
  }

  copyMessage(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied!');
  }

  loadAddresses(username) {
    this.userRoleService.fetchAddresses(username).subscribe(addresses => {
      this.addressList = addresses;
      for (let add of this.addressList) {
        if (add.status) {
          this.read_d = true;
          this.province_d = add['cities']['agriDistrict']['province']['id'];
          this.getAllDistrict(this.province_d, 'DELIVERY');
          this.address1_d = add['addressOne'];
          this.address2_d = add['addressTwo'];
          this.name_d = add['name'];
          this.postal_d = add['postalCode'];
          this.selectDeliveryDistrictId = add['cities']['agriDistrict']['id'];
          this.getAllCities(this.selectDeliveryDistrictId, 'DELIVERY');
          this.country_d = add['country']['nameEN'];
          this.mobile_d = this.mobile_d_temp;
          this.selectDeliveryCityId = add['cities']['id'];
          if (this.activeLang === 'en') {
            this.selectDeliveryCityName = add['cities']['nameEN'];
          } else if (this.activeLang === 'si') {
            this.selectDeliveryCityName = add['cities']['nameSI'];
          } else {
            this.selectDeliveryCityName = add['cities']['nameTA'];
          }
          this.read_b = true;
          this.province_b = add['cities']['agriDistrict']['province']['id'];
          this.getAllDistrict(this.province_b, 'BILLING');
          this.address1_b = add['addressOne'];
          this.address2_b = add['addressTwo'];
          this.name_b = add['name'];
          this.postal_b = add['postalCode'];
          this.country_b = add['country']['nameEN'];
          this.city_b = add['cities']['id'];
          this.selectBillingDistrictId = add['cities']['agriDistrict']['id'];
          this.getAllCities(this.selectBillingDistrictId, 'BILLING');
          this.country_b = add['country']['nameEN'];
          this.mobile_b = this.mobile_b_temp;
          this.selectBillingCityId = add['cities']['id'];
        }
      }
    });
  }

  setDeliveryAddress(index, value) {
    this.isShownDelivery = index === 'new';
    if (index === 'new') {
      this.read_d = false;
      this.province_d = undefined;
      this.address1_d = undefined;
      this.address2_d = undefined;
      this.name_d = undefined;
      this.postal_d = undefined;
      this.country_d = 'Sri Lanka';
      this.city_d = undefined;
      this.mobile_d = undefined;
      this.selectDeliveryDistrictId = undefined;
      this.selectDeliveryCityId = undefined;
    } else {
      this.isDeliveryAddressVisible = false;
      this.read_d = true;
      this.province_d = value['cities']['agriDistrict']['province']['id'];
      this.getAllDistrict(this.province_d, 'DELIVERY');
      this.address1_d = value['addressOne'];
      this.address2_d = value['addressTwo'];
      this.name_d = value['name'];
      this.postal_d = value['postalCode'];
      this.selectDeliveryDistrictId = value['cities']['agriDistrict']['id'];
      this.getAllCities(this.selectDeliveryDistrictId, 'DELIVERY');
      this.country_d = value['country']['nameEN'];
      this.mobile_d = this.mobile_d_temp;
      this.selectDeliveryCityId = value['cities']['id'];
    }
  }

  setBillingAddress(index, value) {
    this.isShownBilling = index === 'new';
    if (index === 'new') {
      this.isShownBilling = false;
      this.read_b = false;
      this.province_b = undefined;
      this.address1_b = undefined;
      this.address2_b = undefined;
      this.country_b = 'Sri Lanka';
      this.name_b = undefined;
      this.postal_b = undefined;
      this.city_b = undefined;
      this.mobile_b = undefined;
      this.selectBillingDistrictId = undefined;
      this.selectBillingCityId = undefined;
    } else {
      let el = document.getElementById('sameAsBilling2') as HTMLInputElement;
      el.checked = false;
      this.isSameAsBillingAddress = false;
      this.read_b = true;
      this.isBillingAddressVisible = false;
      this.isShownBilling = false;
      this.province_b = value['cities']['agriDistrict']['province']['id'];
      this.getAllDistrict(this.province_b, 'BILLING');
      this.address1_b = value['addressOne'];
      this.address2_b = value['addressTwo'];
      this.name_b = value['name'];
      this.postal_b = value['postalCode'];
      this.country_b = value['country']['nameEN'];
      this.city_b = value['cities']['id'];
      this.selectBillingDistrictId = value['cities']['agriDistrict']['id'];
      this.getAllCities(this.selectBillingDistrictId, 'BILLING');
      this.country_b = value['country']['nameEN'];
      this.mobile_b = this.mobile_b_temp;
      this.selectBillingCityId = value['cities']['id'];
    }
  }

  manageDeliveryAddressSave(status) {
    this.isDeliveryAddressSave = !status;
  }

  selectDeliveryCity(event) {
    this.selectDeliveryCityId = event.target.value;
    if (this.allCities_d.length > 0) {
      for (let city of this.allCities_d) {
        if (parseInt(this.selectDeliveryCityId) === parseInt(city.id)) {
          this.postal_d = city['postalCode'];
          if (this.activeLang === 'en') {
            this.selectDeliveryCityName = city.nameEN;
          } else if (this.activeLang === 'si') {
            this.selectDeliveryCityName = city.nameSI;
          } else {
            this.selectDeliveryCityName = city.nameTA;
          }
        }
      }
    }
  }

  selectBillingCity(event) {
    this.selectBillingCityId = event.target.value;
    if (this.allCities_b.length > 0) {
      for (let city of this.allCities_b) {
        if (parseInt(this.selectBillingCityId) === parseInt(city.id)) {
          this.postal_b = city['postalCode'];
          if (this.activeLang === 'en') {
            this.selectBillingCityName = city.nameEN;
          } else if (this.activeLang === 'si') {
            this.selectBillingCityName = city.nameSI;
          } else {
            this.selectBillingCityName = city.nameTA;
          }
        }
      }
    }
  }

  getAllDistrict(id, type) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        if (type === 'DELIVERY') {
          this.districtList_d = result;
        } else {
          this.districtList_b = result;
        }
      }
    });
  }

  manageBillingAddressSave(status) {
    this.isBillingAddressSave = !status;
  }


  deliveryAddressVisible() {
    this.isDeliveryAddressVisible = true;
  }

  billingAddressVisible() {
    this.isBillingAddressVisible = true;
  }

  sameAsBillingVisible() {
    let el = document.getElementById('sameAsBilling2') as HTMLInputElement;
    if (!el.checked) {
      this.isShownBilling = false;
      this.read_b = false;
      this.province_b = undefined;
      this.address1_b = undefined;
      this.address2_b = undefined;
      this.country_b = 'Sri Lanka';
      this.name_b = undefined;
      this.postal_b = undefined;
      this.city_b = undefined;
      this.mobile_b = undefined;
      this.selectBillingDistrictId = undefined;
      this.selectBillingCityId = undefined;
    } else {
      this.name_b = this.name_d;
      this.mobile_b = this.mobile_d;
      this.address1_b = this.address1_d;
      this.address2_b = this.address2_d;
      this.postal_b = this.postal_d;
      this.province_b = this.province_d;
      this.getAllDistrict(this.province_b, 'BILLING');
      this.selectBillingDistrictId = this.selectDeliveryDistrictId;
      this.getAllCities(this.district_d, 'BILLING');
      this.selectBillingCityId = this.selectDeliveryCityId;
    }
  }

  clearInputFields() {
    this.referenceNumber = undefined;
  }

  onSelect(event) {
    if (this.files.length <= 1 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.uploadImages(event.addedFiles);
        this.files.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files.splice(-1, 1);
        }
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }
  }

  uploadImages(value) {
    this.commonService.processing();
    let formData = new FormData();
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
        }
      }
    );
  }

  selectBankTransferOption() {
    if (this.pmtTabItem === 1) {
      if (this.selectedBank === 0) {
        return;
      }
    }
    this.openBankTransferModal.nativeElement.click();
  }
}
