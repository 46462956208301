import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-riyaviru-app-terms',
  templateUrl: './riyaviru-app-terms.component.html',
  styleUrls: ['./riyaviru-app-terms.component.css']
})
export class RiyaviruAppTermsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
