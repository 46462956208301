import {Component, OnInit} from '@angular/core';
import {InteractionService} from '../../../../service/interaction.service';
import {MainUrlService} from '../../../../service/main-url.service';
import {Md5} from 'ts-md5';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {

  tenantValue: any;
  imageUrl: any;
  comment: any;
  name: any;
  rate: any = 0;

  constructor(
    private interactionService: InteractionService,
    private mainUrl: MainUrlService
  ) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

  ngOnInit() {

    this.interactionService.updateReviews.subscribe(reviewDetails => {
      let hashStr;
      if (reviewDetails['reviewBy'] !== null) {
        hashStr = Md5.hashStr(reviewDetails['reviewBy']);
      } else {
        hashStr = Md5.hashStr('');
      }

      this.imageUrl = reviewDetails['userLocationDTO']['imageUrl'] !== null ?
        this.mainUrl.MAIN_URL_FILE + reviewDetails['userLocationDTO']['imageUrl'] + '/?X-TenantID=' +
        this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';

      this.comment = reviewDetails['comment'];
      this.rate = reviewDetails['rating'];
      this.name = reviewDetails['userLocationDTO']['name'];

    });
  }

}
