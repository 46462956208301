import {Component, OnInit} from '@angular/core';
import {InteractionService} from '../../service/interaction.service';

@Component({
  selector: 'app-value-added-services',
  templateUrl: './value-added-services.component.html',
  styleUrls: ['./value-added-services.component.css']
})
export class ValueAddedServicesComponent implements OnInit {

  tenantValue: any;
  activeLang: any;
  userRole: any;

  constructor(private interactionService: InteractionService) {
    this.userRole = localStorage.getItem('USER_ROLE');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit(): void {
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

}
