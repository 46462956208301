import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuard implements CanActivate {

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  
  constructor(private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean
    | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let _isLogged = localStorage.getItem('isLogged');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');

    if (_isLogged === 'True') {
      return true;
    } else {
      this._router.navigate(['/home/' + TENANT_VALUE]);
      return false;
    }
  }

}
