<div class="container">
  <div class="card" style="border: none!important;">
    <div class="card-body">
      <form style="margin-top: 10vh">

      </form>
      <div class="row">
        <div class="col-md-4 col-md-offset-4">
        </div>
        <div class="col-md-4 col-md-offset-4">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="text-center">
                <h2 class="text-center">{{'SIGN_IN.RESET_PASSWORD.RESET_PASSWORD_H' | translate}}</h2>
                <p>{{'SIGN_IN.RESET_PASSWORD.RESET_PASSWORD_HERE' | translate}}</p>
                <div class="panel-body">

                  <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()" id="register-form" role="form" autocomplete="off" class="form" method="post">

                    <div class="form-group">
                      <mat-form-field>
                        <input [(ngModel)]="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                               class="form-control" formControlName="password" id="password" matInput
                               placeholder="{{'SIGN_IN.RESET_PASSWORD.PASSWORD' | translate}}" showHideInput type="password">
                        <div style="    margin-left: 363px;margin-top: -27px;z-index: 10000">
                          <i [showHideStatus]="{ materialIcon: true, id: 'password' }" class="material-icons" matSuffix
                             showHideTrigger="password"></i>
                        </div>
                      </mat-form-field>

                      <div *ngIf="submitted && f.password.errors" class="inputError">
                        <div *ngIf="f.password.errors.required">{{'COMMON_VALIDATION.PASSWORD' | translate}}</div>
                        <div
                          *ngIf="f.password.errors.pattern">{{'COMMON_VALIDATION.PASSWORD_PATTERN' | translate}}</div>
                      </div>

                    </div>
                    <br>
                    <div class="form-group">
                      <mat-form-field>
                        <input [ngClass]="{ 'is-invalid': submitted && f.rePassword.errors }" id="rePassword"
                               formControlName="rePassword" [(ngModel)]="rePassword" type="password" class="form-control"
                               placeholder="{{'SIGN_IN.RESET_PASSWORD.CONFIRM_PASSWORD' | translate}}" matInput showHideInput>
                        <div style="margin-left: 363px;margin-top: -27px;z-index: 10000">
                          <i [showHideStatus]="{ materialIcon: true, id: 'rePassword' }" class="material-icons" matSuffix
                             showHideTrigger="rePassword"></i>
                        </div>
                        <div *ngIf="submitted && f.rePassword.errors" class="invalid-feedback">
                          <div style="font-size: 10px;color: red" *ngIf="f.rePassword.errors.required">
                            {{'SIGN_IN.RESET_PASSWORD.CONFIRM_PASSWORD_REQUIRED' | translate}}
                          </div>
                          <div style="font-size: 10px;color: red" *ngIf="f.rePassword.errors.mustMatch">
                            {{'SIGN_IN.RESET_PASSWORD.PASSWORD_MATCH' | translate}}
                          </div>
                        </div>
                      </mat-form-field>

                    </div>
                    <div class="form-group">
                      <button id="resetBtn" name="recover-submit" class="btn btn-lg btn-light btn-block buttonColor"
                              type="submit" >{{'SIGN_IN.RESET_PASSWORD.RESET_PASSWORD' | translate}}</button>
                    </div>

                    <input type="hidden" class="hide" name="token" id="token" value="">
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-md-offset-4">
        </div>
      </div>
      <form style="margin-top: 10vh">

      </form>
    </div>
  </div>

</div>
