<div class="sellproduct-steps" *ngIf="selectOrderNext==0">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PRO_CATEGORY' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="categoryForm">
      <div class="form-group col-12">
        <label for="selectCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.MAIN_CATEGORY' | translate}}
          <span class="validationStar">*</span></label>
        <select (change)="selectCategory($event)" [(ngModel)]="selectCategoryId"
                [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.category.errors }" class="form-control"
                formControlName="category" id="selectCategory">
          <option [value]="none" disabled>
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_YOUR_CATEGORY' | translate}}
          </option>
          <option *ngFor="let category of categoryList" [value]="category.code">
            <span *ngIf="activeLang === 'en'">{{category.commonIndex.description}}</span>
            <span *ngIf="activeLang === 'si'">{{category.commonIndex.sinhala}}</span>
            <span *ngIf="activeLang === 'ta'">{{category.commonIndex.tamil}}</span>
          </option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.category.errors" class="inputError">
          <div *ngIf="f1.category.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SUB_CATEGORY' | translate}}
          <span class="validationStar">*</span> </label>
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <select (change)="selectSubCategory($event)" [(ngModel)]="selectSubCategoryId"
                [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.subCategory.errors }" class="form-control"
                formControlName="subCategory">
          <option [value]="none" disabled>
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_SUB_CATEGORY' | translate}}
          </option>
          <option *ngFor="let subCategory of subCategoryList" [value]="subCategory.code">
            <span *ngIf="activeLang === 'en'">{{subCategory.commonIndex.description}}</span>
            <span *ngIf="activeLang === 'si'">{{subCategory.commonIndex.sinhala}}</span>
            <span *ngIf="activeLang === 'ta'">{{subCategory.commonIndex.tamil}}</span>
          </option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.subCategory.errors" class="inputError">
          <div *ngIf="f1.subCategory.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.SUB_CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-6">
            <label for="qty">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.QUANTITY_REQUIRED' | translate}}
              <span class="validationStar">*</span></label>
            <input (focusout)="manageAvailableTotalQty(availableTotalQty)" [(ngModel)]="availableTotalQty"
                   [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.availableTotalQty.errors }"
                   class="form-control smallinput" formControlName="availableTotalQty" id="qty" min="0"
                   placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.AVAILABLE_QUANTITY' | translate}}"
                   style="width: 200px;" type="number" value="0">
            <div *ngIf="validAvailableTotalQty" class="inputError">
              {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.QUANTITY_REQUIRED_REQUIRED' | translate}}
            </div>
          </div>
          <div class="col-6">
            <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.UNIT_TYPE' | translate}}
              <span class="validationStar">*</span></label>
            <select (change)="selectUnitType($event)" [(ngModel)]="unitType"
                    [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.unitType.errors }"
                    class="form-control smallinput" formControlName="unitType" id="unitType" style="width: 200px;">
              <option [value]="none" disabled>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.UNIT_TYPE' | translate}}
              </option>
              <option *ngFor="let unitType of unitTypeList" [value]="unitType.id">{{unitType.unit}}</option>
            </select>
            <div *ngIf="validUnitType" class="inputError">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.UNIT_TYPE_REQUIRED' | translate}}
            </div>

          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">{{'MY_ACCOUNT.SELL_A_PRODUCT.CANCEL' |
          translate}}</a>
        <a (click)="categoryPageNext()" aria-label="Next" class="btn next-btn-half">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>

</div>

<div class="sellproduct-steps" *ngIf="selectOrderNext==1">
  <h4 class="sttitle">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.PLACE_AN_FORWARD' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="productDetailsForm">
      <div class="form-group col-12">
        <label for="title">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.TITLE' | translate}}
          <span class="validationStar">*</span></label>
        <input [(ngModel)]="title" [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.title.errors }"
               class="form-control" formControlName="title" id="title"
               placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.TITLE_NAME' | translate}}" type="text">
        <div *ngIf="submittedProductDetailsForm && f2.title.errors" class="inputError">
          <div *ngIf="f2.title.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.TITLE_REQUIRED' | translate}}
          </div>
          <div *ngIf="f2.title.errors.pattern">
            {{'INVALID_NAME' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="description">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DESCRIPTION' | translate}}
          <span class="validationStar">*</span></label>
        <textarea [(ngModel)]="description"
                  [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.description.errors }"
                  class="form-control"
                  formControlName="description" id="description" maxlength="550" minlength="1"
                  placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DESCRIPTION' | translate}}"
                  rows="3"></textarea>
        <div *ngIf="submittedProductDetailsForm && f2.description.errors" class="inputError">
          <div *ngIf="f2.description.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DESCRIPTION_REQUIRED' | translate}}
          </div>
          <div *ngIf="f2.description.errors.pattern">
            {{'INVALID_NAME' | translate}}
          </div>
        </div>
        <label style="margin-top: 2vh">{{'OTHER.Characters_Remaining' | translate}} : {{description.length}}/550</label>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="bidsAcceptedTill">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.BIDS_ACCEPTED_TILL' | translate}}
            <span class="validationStar">*</span>
            <span class="validationStar"
                  style="margin-left: unset!important;font-weight: unset!important;font-size: 11px!important;">({{'ORDER_REQUEST_DESC' | translate}}
              )</span>
          </label>
          <input [(ngModel)]="bidsAcceptedTill" (change)="setExpectedMinDate(bidsAcceptedTill)"
                 [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.bidsAcceptedTill.errors }"
                 class="form-control" formControlName="bidsAcceptedTill" id="bidsAcceptedTill" min="{{todayDate}}"
                 type="date">
          <div *ngIf="submittedProductDetailsForm && f2.bidsAcceptedTill.errors" class="inputError">
            <div *ngIf="f2.bidsAcceptedTill.errors.required">
              {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.BIDS_ACCEPTED_TILL_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="expectedDeliveryDate"
                 style="margin-bottom: 22px">{{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.ORDER_EXPECTED_DATE' | translate}}
            <span class="validationStar">*</span>
          </label>
          <input [(ngModel)]="expectedDeliveryDate"
                 [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.expectedDeliveryDate.errors }"
                 class="form-control" formControlName="expectedDeliveryDate" id="expectedDeliveryDate"
                 min="{{expectedMinDate}}"
                 type="date">
          <div *ngIf="submittedProductDetailsForm && f2.expectedDeliveryDate.errors" class="inputError">
            <div *ngIf="f2.expectedDeliveryDate.errors.required">
              {{'MY_ACCOUNT.PURCHASES.CREATE_NEW_ORDER.ORDER_EXPECTED_DATE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a (click)="selectOrderNext=0" aria-label="Close" class="btn cancel-btn-half"
           data-dismiss="modal">{{'MY_ACCOUNT.SELL_A_PRODUCT.CANCEL' |
          translate}}</a>
        <a (click)="productDetailsPageNext()" aria-label="Next" class="btn next-btn-half">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>

</div>

<div *ngIf="selectOrderNext==2" class="sellproduct-steps"
     style="max-height: 500px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
  <h4 style="margin: 0 0 15px 0;">{{'PRODUCT_PIK_ADDRESS_ORDER_REQUEST' | translate}}</h4>

  <div class="col-12">
    <p>{{'PRODUCT_PIK_ADDRESS_ORDER_REQUEST_MSG' | translate}}</p>
  </div>
  <div class="anp-select-op categoryform row">
    <form [formGroup]="pickUpAddressForm">

      <div class="transport-section">
        <div class="saved-address">
          <div class="d-flex row" style="max-height: 650px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
            <div class="col-6" *ngFor="let item of addressList; let i = index">
              <div class="section-transport">
                <input class="form-check-input" (click)="setAddress(i)" type="radio"
                       [id]="'address'+i" [name]="'address'" [value]="item.id"
                       [checked]="item.status && !isAgentFarmerCheck">
                <label class="form-check-label" [for]="'address'+i">
                  <h4>{{item.name}} <span *ngIf="item.status === true">(Default)</span></h4>
                  <p>{{item.addressOne}} {{item.addressTwo}} ,{{item.cities.nameEN}}</p>
                </label>
              </div>
            </div>
            <div class="section-transport" *ngIf="isAgentFarmerLogin">
              <input class="form-check-input" (click)="setAddress('FM')" type="radio"
                     [id]="'FM'" [name]="'FM'" [value]="'FM'" [checked]="isAgentFarmerCheck">
              <label class="form-check-label" [for]="'FM'">
                <h4>{{agentFarmerName}} <span>(Farmer)</span></h4>
                <p>{{agentFarmerAddress1}} {{agentFarmerAddress2}},{{agentFarmerCityName}} </p>
              </label>
            </div>
          </div>
        </div>
        <div class="addnewaddress d-flex justify-content-between align-items-center">
          <div>
            <input class="form-check-input" type="radio" name="address" id="newaddesss" value="option3"
                   (click)="setAddress('new')">
            <label class="form-check-label" for="newaddesss">
              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADD_NEW_ADDRESS' | translate}}</h4>
            </label>
          </div>
          <div class="form-check" [hidden]="!isItNewAddress">
            <input [checked]="isAddressSave" class="form-check-input checkone" (change)="manageAddressSave()"
                   id="saveAddress" type="checkbox">
            <label class="form-check-label" for="saveAddress">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SAVE_NEW_ADDRESS' | translate}}
            </label>
          </div>
        </div>
      </div>

      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="address1">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS_1' | translate}}
            <span class="validationStar">*</span></label>
          <input [(ngModel)]="address1"
                 [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.address1.errors }" class="form-control"
                 formControlName="address1" id="address1"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}"
                 type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.address1.errors" class="inputError">
            <div *ngIf="f3.address1.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="address2">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS_2' | translate}}</label>
          <input [(ngModel)]="address2" [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.address2.errors }"
                 class="form-control" formControlName="address2" id="address2"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}" type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.address2.errors" class="inputError">
            <div *ngIf="f3.address2.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="pro">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PROVINCE' | translate}}
            <span class="validationStar">*</span></label>
          <select (change)="selectProvince($event)" [(ngModel)]="selectProvinceId" class="form-control province"
                  formControlName="province" id="pro">
            <option [value]="none" disabled>
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PROVINCE' | translate}}
            </option>
            <option *ngFor="let province of provinceList" [value]="province.id">
              <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.province.errors" class="inputError">
            <div *ngIf="f3.province.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.PROVINCE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="dis">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
          <select (change)="selectDistrict($event)" [(ngModel)]="selectDistrictId" class="form-control province"
                  formControlName="district" id="dis">
            <option [value]="none" disabled>
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_DISTRICT' | translate}}
            </option>
            <option *ngFor="let district of districtList" [value]="district.id">
              <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.district.errors" class="inputError">
            <div *ngIf="f3.district.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">

        <div class="form-group col-6">
          <label for="dis">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.CITY' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
          <select (change)="selectCity($event)" [(ngModel)]="selectCityId" class="form-control province"
                  formControlName="city" id="city">
            <option [value]="none" disabled>
              Select City
            </option>
            <option *ngFor="let city of allCities" [value]="city.id" (click)="selectCityEn(city.nameEN)">
              <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.city.errors" class="inputError">
            <div *ngIf="f3.city.errors.required">
              This field is required!
            </div>
          </div>
        </div>


        <div class="form-group col-6">
          <label for="postalCode">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}
          </label>
          <input [(ngModel)]="postalCode" disabled
                 [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.postalCode.errors }"
                 class="form-control" formControlName="postalCode" id="postalCode"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}" type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.postalCode.errors" class="inputError">
            <div *ngIf="f3.postalCode.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a (click)="selectOrderNext=1" aria-label="Close"
           class="btn cancel-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a (click)="pickUpLocationAddressNextPage()" aria-label="Next"
           class="btn next-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<!--<div class="sellproduct-steps" *ngIf="selectOrderNext==3"-->
<!--     style="max-height: 500px ; height: auto; overflow-y: scroll; overflow-x: hidden;">-->
<!--  <h4 class="margin0">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_OPTION' | translate}}</h4>-->
<!--  <p class="subtext">-->
<!--    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.TRANSPORT_STATUS' | translate}}-->
<!--  </p>-->
<!--  <div class="anp-select-op categoryform row">-->
<!--    <div class="messgnetext">-->
<!--      <form [formGroup]="deliveryForm">-->
<!--        <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==1}">-->
<!--          <div class="form-check">-->
<!--            <mat-checkbox (change)="checkVal($event,3)" [checked]="isSellerDeliver"></mat-checkbox>-->
<!--            <label class="form-check-label" style="color: black;">-->
<!--              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGE' | translate}}-->
<!--              </h4>-->
<!--              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' | translate}}</p>-->

<!--              <div class="row">-->
<!--                <label-->
<!--                  style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_PER' | translate}}-->
<!--                  ({{'CURRENCY.CURRENCY' | translate}})</label>-->
<!--                <input class="form-control" style="margin-left: 6%" formControlName="deliverCharge" type="number">-->
<!--                <div *ngIf="isChargeReq" style="font-size: 10px;color: red;margin-left: 4vw;margin-top: 1vh;">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' | translate}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--      <form [formGroup]="deliveryFormFixed">-->
<!--        <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==5}">-->
<!--          <div class="form-check">-->
<!--            <mat-checkbox (change)="checkVal($event,5)" [checked]="isSellerDeliverFixed"></mat-checkbox>-->
<!--            <label class="form-check-label" style="color: black;">-->
<!--              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_FINED_RATE' | translate}}</h4>-->
<!--              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' | translate}}</p>-->
<!--              <br/>-->
<!--              <div class="row" style="margin-left: 6%">-->
<!--                <mat-radio-button [checked]="areaDisable" value="1" (change)="areaDisable=true" class="tos-radio">-->
<!--                  <p style="margin-left: 0">-->
<!--                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.ISLAND_DELIVERY' | translate}}</p>-->
<!--                </mat-radio-button>-->
<!--                <mat-radio-button value="1" [checked]="!areaDisable" (change)="areaDisable=false" class="tos-radio">-->
<!--                  <p style="margin-left: 0">-->
<!--                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_DELIVERY' | translate}}</p>-->
<!--                </mat-radio-button>-->
<!--              </div>-->
<!--              <div [hidden]="areaDisable" class="row">-->
<!--                <label style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA' | translate}}-->
<!--                </label>-->
<!--                <textarea class="form-control" maxlength="100" style="margin-left: 6%"-->
<!--                          formControlName="areas"></textarea>-->
<!--                <div *ngIf="isAreaReq" style="font-size: 10px;color: red">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA_REQUIRED' | translate}}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <label style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGED' | translate}}-->
<!--                  ({{'CURRENCY.CURRENCY' | translate}}) </label>-->
<!--                <input class="form-control" style="margin-left: 6%" formControlName="deliverChargeFixed" type="number">-->
<!--                <div *ngIf="isFixedChargeReq" style="font-size: 10px;color: red; margin-left: 4vw;margin-top: 1vh;">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' | translate}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--      <div class="optionbox section-transport">-->
<!--        <div class="form-check">-->
<!--          <mat-checkbox (change)="checkVal($event,4)" disabled="" [checked]="train"></mat-checkbox>-->
<!--          <label class="form-check-label">-->
<!--            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.USE_RAIL_TRANSPORT' | translate}}</h4>-->
<!--            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY_BUYER' | translate}}</p>-->
<!--            <div class="selects">-->
<!--              <div class="form-group">-->
<!--                <label for="railway">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.NEAREST_STATION' | translate}}-->
<!--                </label>-->
<!--                <select disabled (change)="selectRailway($event.target.value)" class="form-control province"-->
<!--                        id="railway">-->
<!--                  <option [value]="none" disabled>-->
<!--                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SELECT_STATION' | translate}}-->
<!--                  </option>-->
<!--                  <option *ngFor="let station of railwayList" [value]="station.id">{{station.descriptionEng}}-->
<!--                  </option>-->

<!--                </select>-->
<!--                <div class="inputError" *ngIf="railwayReq" style="margin-left: 4vw;margin-top: 1vh;">-->
<!--                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.STATION_REQUIRED' | translate}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </label>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==3}">-->
<!--        <div class="form-check">-->
<!--          <mat-checkbox (change)="checkVal($event,2)" [checked]="buyerDeliver"></mat-checkbox>-->
<!--          <label class="form-check-label">-->
<!--            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_TRANSPORT' | translate}}</h4>-->
<!--            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_RESPONSIBILITY' | translate}}</p>-->
<!--          </label>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="inputError" *ngIf="deliveryReq" style="margin-left: 1vw;">-->
<!--      {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_TYPE_REQUIRED' | translate}}</div>-->
<!--    <div class="form-group btn-sections">-->
<!--      <a class="btn cancel-btn-half" (click)="selectOrderNext=2"-->
<!--         aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>-->
<!--      <a class="btn next-btn-half" (click)="next()"-->
<!--         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="sellproduct-steps" *ngIf="selectOrderNext==3">

  <div class="anp-select-op categoryform row">
    <div class="successmsg">
      <h3>{{'ORDER_REQUEST_SUCCESS_MSG' | translate}}</h3><br><br>
      <a class="btn next-btn-close" (click)="resetWindow()"
         data-dismiss="modal">{{'MY_ACCOUNT.SELL_A_PRODUCT.CLOSE' | translate}}</a>
    </div>
  </div>
</div>

<button hidden #closeModal data-dismiss="modal"></button>
