import {Component, OnInit} from '@angular/core';
import {InteractionService} from '../../../../../service/interaction.service';

@Component({
  selector: 'app-i-sidebar',
  templateUrl: './i-sidebar.component.html',
  styleUrls: ['./i-sidebar.component.css']
})
export class ISidebarComponent implements OnInit {
  activeTab = 1;
  activeLang: any;
  tenantValue: any;

  constructor(private interactionService: InteractionService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }


  ngOnInit(): void {
    this.activeLang = sessionStorage.getItem('LANG');
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

}
