import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductDeliveryDTO} from '../../../../dto/ProductDeliveryDTO';
import {CommonService} from '../../../../common/common.service';
import {ProductService} from '../../../../service/product.service';
import {CoreService} from '../../../../service/core.service';
import {UserService} from '../../../../service/user.service';
import {HttpClient} from '@angular/common/http';
import {CommonSignUpService} from '../../../../service/common-sign-up.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import {InteractionService} from '../../../../service/interaction.service';
import {UserRoleService} from '../../../../service/user-role.service';
import Swal from 'sweetalert2';
import {environment} from '../../../../../environments/environment';
import {Railway} from '../../../../dto/RailwayDTO';
import {Router} from '@angular/router';

@Component({
  selector: 'app-buyer-create-new-order',
  templateUrl: './buyer-create-new-order.component.html',
  styleUrls: ['./buyer-create-new-order.component.css']
})
export class BuyerCreateNewOrderComponent implements OnInit {
  @Input() isAgentFarmerLogin: boolean = false;
  @Input() agentFarmerUsername: string;

  submittedCategoryForm: boolean = false;
  categoryForm: FormGroup;

  submittedProductDetailsForm: boolean = false;
  productDetailsForm: FormGroup;

  submittedPicUpAddressForm: boolean = false;
  pickUpAddressForm: FormGroup;

  selectOrderNext = 0;

  categoryList: any;
  subCategoryList: any;
  activeProfile: any;
  selectCategoryId: any;
  selectSubCategoryId: any;
  deliveryOptions = 2;

  title: any;
  description: any = '';
  availableTotalQty: number = 0;
  unitTypeId: any;
  unitTypeList: any;
  unitType: any;
  buyDate: any;
  todayDate = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10);

  address: any;
  province: any;
  postalCode: any;
  country: any = 'Sri Lanka';
  address1: any;
  address2: any;

  isEnableRiyaviruTransportation: boolean = false;

  validSellingUnitPrice: boolean = false;
  selectProvinceId: any;

  provinceList: any;
  districtList: any;
  divisionalSecretariatList: any;
  gramaNiladhariAreaList: any;
  selectDistrictId: any;
  selectDivisionalSecretariatId: any;
  selectGramaNiladhariAreaId: any;
  selectCityId: any;
  city: any;
  orderRequestId: any;
  loading: boolean = false;
  loadingDist: boolean = false;
  loadingDiv: boolean = false;
  loadingGn: boolean = false;
  loadCity: boolean = false;
  activeLang: string = 'en';
  isAddressSave: boolean = false;
  isItNewAddress: boolean = false;

  userDetails: any;
  isRegAddress: boolean = false;
  isAgentFarmer: boolean = false;
  listByUsername: any;
  railwayList: any;
  station: any;
  isSellerDeliver: boolean = false;
  isSellerDeliverFixed: boolean = false;
  buyerDeliver: boolean = false;
  train: boolean = false;
  diliveryOptList: any = [];
  railwayReq: boolean = false;
  deliveryReq: boolean = false;

  addressList: any[] = [];
  addressListAgentFarmer: any[] = [];
  addressObject: any;
  allCities: any;
  tenantValue: any = 'wholesale';
  userInfo: any;
  locationBody: any;
  cityObject: any;
  selectedCityName: any;
  leadCommission: number = (1 / 100);
  platformCommission: number = (1.5 / 100);
  farmerCommission: number = (97.5 / 100);
  deliveryForm: FormGroup;
  deliveryFormFixed: FormGroup;
  deliverCharge: any;
  areas: any;
  isChargeReq: boolean = false;
  isAreaReq = false;
  areaDisable = true;
  addressConcat: any;
  cityName: any;
  isFixedChargeReq = false;
  productDelivery: ProductDeliveryDTO[] = [];
  deliverChargeFixed: any;
  agentFarmerAddress1: any;
  agentFarmerAddress2: any;
  agentFarmerName: any;
  agentFamrerProvinceId: any;
  agentFamrerDisctrictId: any;
  agentFarmerCity: any;
  agentFarmerCityName: any;
  agentFarmerPostal: any;
  isAgentFarmerCheck: boolean = false;
  bulkQty: any = 0;
  username: any;
  isBulkQty: boolean = true;
  validAvailableTotalQty: boolean = false;
  validUnitType: boolean = false;

  isLeaderFarmer: boolean = false;
  isLeadCommissionVisible: boolean = false;
  bidsAcceptedTill: any;
  expectedDeliveryDate: any;

  @ViewChild('closeModal') closeModal;

  constructor(private commonService: CommonService,
              private productService: ProductService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private userService: UserService,
              private route:Router,
              private http: HttpClient,
              private commonSignUpService: CommonSignUpService,
              private systemSettingsService: SystemSettingsService,
              private validationHandlerService: ValidationHandlerService,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService) {
    this.username = sessionStorage.getItem('FARMER_USERNAME');
    if (this.username !== null && this.username !== undefined) {
      this.isLeaderFarmer = true;
      this.common(this.username);
      this.getUserDetails(this.username);
    } else {
      this.userRoleService.whoAmI().subscribe(whoAmI => {
        this.isLeaderFarmer = false;
        this.username = whoAmI['email'];
        this.common(this.username);
        this.getUserDetails(this.username);
      });
    }
    this.activeLang = sessionStorage.getItem('LANG');
  }

  common(username) {
    this.loadAddresses(username);
    this.userService.fetchLeadDetailsByFarmer(username).subscribe(isAgent => {
      this.isLeadCommissionVisible = isAgent['success'] && (isAgent['message'] !== null);
    });
  }

  ngOnInit() {
    this.interactionService.passingFarmerUsername.subscribe(farmerUsername => {
      this.isAgentFarmer = true;
      this.listByUsername = farmerUsername;
    });

    this.categoryFormValidation();
    this.productDetailsFormValidation();
    this.pickUpAddressFormValidation();
    this.fetchAllCategories();
    this.fetchAllUnitTypes();
    this.getAllProvince();
    this.loadProductTypes();
    this.getStation();
    this.loadAgentFarmerDetails();


    this.deliveryForm = this.formBuilder.group({
      deliverCharge: '',
    });
    this.deliveryFormFixed = this.formBuilder.group({
      deliverChargeFixed: '',
      areas: '',
    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  get f1() {
    return this.categoryForm.controls;
  }

  get f2() {
    return this.productDetailsForm.controls;
  }

  get f3() {
    return this.pickUpAddressForm.controls;
  }


  loadAddresses(username) {
    this.userRoleService.fetchAddresses(username).subscribe(addresses => {


      this.addressList = addresses;
      for (let add of this.addressList) {
        if (add.status === true && !this.isAgentFarmerLogin) {
          this.address1 = add.addressOne;
          this.address2 = add.addressTwo;

          this.selectProvinceId = add.cities.agriDistrict.province.id;
          this.getAllDistrict(this.selectProvinceId);
          this.selectDistrictId = add.cities.agriDistrict.id;
          this.getAllCities(this.selectDistrictId);
          this.getAllDivSec(this.selectDistrictId);
          this.selectCityId = add.cities.id;
          this.postalCode = add.postalCode;
          this.setSelectedCityName();
        }
      }

    });
  }

  setSelectedCityName() {
    if (this.allCities !== undefined) {
      if (this.allCities.length > 0) {
        for (let _city of this.allCities) {
          if (_city.id.toString() === this.selectCityId.toString()) {
            this.selectedCityName = _city.nameEN;
          }
        }
      }
    }
  }


  fetchUserDetails() {
    this.commonService.processing();
    let farmerUsername = sessionStorage.getItem('FARMER_USERNAME');

    let username;
    if (this.isAgentFarmer) {
      username = this.listByUsername;
    } else if (farmerUsername !== null) {
      username = farmerUsername;
    } else {
      username = this.username;
    }
    this.userRoleService.fetchUserDetails(username).subscribe(userDetails => {
      if (userDetails) {
        this.userDetails = userDetails;
        this.address = this.userDetails.address;

        this.selectProvinceId = this.userDetails.gramaNiladari.divisionalSecretariat.district.province.id;
        this.getAllDistrict(this.selectProvinceId);

        this.selectDistrictId = this.userDetails.gramaNiladari.divisionalSecretariat.district.id;
        this.getAllDivSec(this.selectDistrictId);

        this.selectDivisionalSecretariatId = this.userDetails.gramaNiladari.divisionalSecretariat.id;
        this.getAllGramaNiladari(this.selectDivisionalSecretariatId);

        this.selectGramaNiladhariAreaId = this.userDetails.gramaNiladari.id;

        Swal.close();
      }
    });
  }

  loadAgentFarmerDetails() {
    if (this.isAgentFarmerLogin) {
      this.userRoleService.fetchAddresses(this.agentFarmerUsername).subscribe(addresses => {
        this.addressListAgentFarmer = addresses;
        for (let add of this.addressListAgentFarmer) {
          if (add.status) {
            this.isAgentFarmerCheck = true;
            this.address1 = add.addressOne;
            this.address2 = add.addressTwo;
            this.selectProvinceId = add.cities.agriDistrict.province.id;
            this.getAllDistrict(this.selectProvinceId);
            this.selectDistrictId = add.cities.agriDistrict.id;
            this.getAllCities(this.selectDistrictId);
            this.getAllDivSec(this.selectDistrictId);
            this.selectCityId = add.cities.id;
            this.postalCode = add.postalCode;

            this.agentFarmerAddress1 = add.addressOne;
            this.agentFarmerAddress2 = add.addressTwo;
            this.agentFarmerName = add.name;
            this.agentFamrerProvinceId = add.cities.agriDistrict.province.id;
            this.agentFamrerDisctrictId = add.cities.agriDistrict.id;
            this.agentFarmerCity = add.cities.id;
            this.agentFarmerCityName = add.cities.nameEN;
            this.agentFarmerPostal = add.postalCode;
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isAgentFarmerLogin'] || changes['agentFarmerUsername']) {
      this.loadAgentFarmerDetails();
    }
  }

  categoryFormValidation() {
    this.categoryForm = this.formBuilder.group({
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      availableTotalQty: ['', Validators.required],
      unitType: ['', Validators.required]
    });
  }

  productDetailsFormValidation() {
    this.productDetailsForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      description: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      bidsAcceptedTill: ['', Validators.required],
      expectedDeliveryDate: ['', Validators.required]
    });
  }

  pickUpAddressFormValidation() {
    this.pickUpAddressForm = this.formBuilder.group({
      address1: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      address2: ['', [Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      district: ['', Validators.required],
      province: ['', Validators.required],
      divisionalSecretariat: [''],
      gramaNiladhariArea: [''],
      postalCode: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())],
      city: ['', Validators.required]
    });
  }


  fetchAllCategories() {
    this.productService.getActiveProfile().subscribe(activeProfile => {
      this.activeProfile = activeProfile['code'];
      this.productService.getAllMainCategoryByProfileId(this.activeProfile).subscribe(categories => {
        if (categories) {
          this.categoryList = categories;
        }
      });
    });
  }

  fetchAllUnitTypes() {
    this.coreService.fetchAllUnitTypes().subscribe(unitTypes => {
      if (unitTypes) {
        this.unitTypeList = unitTypes;
      }
    });
  }

  selectCategory(event) {
    this.loading = true;
    this.selectSubCategoryId = undefined;
    if (event.target.value !== 'none') {
      this.selectCategoryId = event.target.value;
      this.productService.fetchAllSubCategory(this.selectCategoryId).subscribe(subCategories => {
        if (subCategories) {
          this.subCategoryList = subCategories;
          this.loading = false;
        }
      });
    } else {
      this.selectCategoryId = undefined;
    }
  }

  selectSubCategory(event) {
    if (event.target.value !== 'none') {
      this.selectSubCategoryId = event.target.value;
    } else {
      this.selectSubCategoryId = undefined;
    }
  }

  categoryPageNext() {
    this.submitCategoryForm();
  }

  submitCategoryForm() {
    this.submittedCategoryForm = true;
    this.validAvailableTotalQty = this.availableTotalQty <= 0;
    this.validUnitType = this.unitType === undefined;

    if (this.categoryForm.invalid) {
      return;
    }

    if (this.availableTotalQty <= 0) {
      this.validAvailableTotalQty = true;
      return;
    }

    if (this.selectSubCategoryId !== 'none') {
      this.selectOrderNext = 1;
    }
  }

  selectUnitType(event) {
    this.validUnitType = false;
    this.unitTypeId = event.target.value;
  }

  productDetailsFormSubmit() {
    this.submittedProductDetailsForm = true;
    if (this.productDetailsForm.invalid) {
      return;
    }
    this.selectOrderNext = 2;
  }

  productDetailsPageNext() {
    this.productDetailsFormSubmit();
  }

  getUserDetails(username) {
    let body = {
      'userName': username,
      'token': 'wholesale'
    };
    this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
      this.userInfo = userResult;
    });
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }

  saveProduct() {
    this.commonService.processing();

    let farmerUsername = sessionStorage.getItem('FARMER_USERNAME');

    let username;
    if (this.isAgentFarmer) {
      username = this.listByUsername;
    } else if (farmerUsername !== null) {
      username = farmerUsername;
    } else {
      username = this.username;
    }

    if (this.isAddressSave) {
      this.locationBody = {
        'username': this.username,
        'name': this.userInfo.name,
        'status': false,
        'addressOne': this.address1,
        'addressTwo': this.address2,
        'addressType': {
          'id': 1
        },
        'country': {
          'id': 1
        },
        'cities': {
          'id': this.selectCityId
        },
        'postalCode': this.postalCode
      };
    }

    if (this.address2 === null || this.address2 === undefined || this.address2 === '') {
      this.address2 = '';
      this.addressConcat = this.address1 + ',' + this.selectedCityName;
    } else {
      this.addressConcat = this.address1 + ',' + this.address2 + ',' + this.selectedCityName;
    }

    const orderRequestProductDTO = {
      'product': {
        'profile': {
          'code': this.activeProfile
        },
        'name': this.title,
        'description': this.description,
        'listedBy': username,
        'type': {
          'id': this.orderRequestId
        },
        'unit': {
          'id': this.unitType
        },
        'productStatus': {
          'id': 1,
          'code': '1x'
        },
        'subCategory': {
          'code': this.selectSubCategoryId,
          'mainCategory': {
            'code': this.selectCategoryId
          }
        },
        'address': this.addressConcat,
        'province': this.selectProvinceId,
        'district': this.selectDistrictId,
        'postalCode': this.postalCode,
        'riyaviru': this.isEnableRiyaviruTransportation
      },
      'specAttributes': [[environment.specCode]],
      'deliveryList': [],
      'requiredQty': this.availableTotalQty,
      'bidAcceptedDate': this.bidsAcceptedTill,
      'expectedDeliveryDate': this.expectedDeliveryDate
    };

    this.productService.createOrderRequest(orderRequestProductDTO).subscribe(orderRequest => {
      Swal.close();
      if (orderRequest) {

        if (this.isAddressSave) {
          this.commonSignUpService.saveNewAddresses(this.locationBody).subscribe(() => {
            sessionStorage.removeItem('FARMER_USERNAME');
            this.selectOrderNext = 3;
          });
        } else {
          sessionStorage.removeItem('FARMER_USERNAME');
          this.selectOrderNext = 3;
        }
      }
    }, () => {
      Swal.close();
    });

  }

  pickUpLocationAddressNextPage() {
    this.submittedPicUpAddressForm = true;
    if (this.pickUpAddressForm.invalid) {
      return;
    }
    this.setSelectedCityName();
    this.selectOrderNext = 3;
    this.deliveryReq = false;
    this.railwayReq = false;
    this.isChargeReq = false;
    this.isFixedChargeReq = false;
    this.isAreaReq = false;
    this.deliverCharge = this.deliveryForm.value.deliverCharge;
    this.deliverChargeFixed = this.deliveryFormFixed.value.deliverChargeFixed;
    this.areas = this.areaDisable ? 'All Island delivery' : this.deliveryFormFixed.value.areas;

    this.saveProduct();
  }

  manageAddressSave() {
    this.isAddressSave = !this.isAddressSave;
  }

  manageAvailableTotalQty(qty) {
    this.validAvailableTotalQty = qty <= 0;
    if (qty === null) {
      this.availableTotalQty = 0;
      return;
    }

    sessionStorage.setItem('totalAvailableQty', qty);
  }

  selectProvince(event) {
    this.loadingDist = true;
    this.selectProvinceId = event.target.value;
    this.selectDistrictId = undefined;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDistrict(this.selectProvinceId);
  }

  selectDistrict(event) {
    this.loadingDiv = true;
    this.loadCity = true;
    this.selectDistrictId = event.target.value;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDivSec(this.selectDistrictId);
    this.getAllCities(this.selectDistrictId);
  }

  selectCity(event) {
    this.selectCityId = event.target.value;
    this.getCityById(this.selectCityId);
  }


  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.cityObject = result;
        this.postalCode = this.cityObject.postalCode;
      }
    });
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.provinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.districtList = result;
        this.loadingDist = false;
      }
    });

  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
      }
    });
  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.divisionalSecretariatList = result;
        this.loadingDiv = false;
      }
    });
  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.gramaNiladhariAreaList = result;
        this.loadingGn = false;
      }
    });
  }

  setUnitType(id: any) {
    for (let unit of this.unitTypeList) {
      if (unit.id.toString() === id.toString()) {
        return unit.unit;
      }
    }
  }

  loadProductTypes() {
    this.coreService.fetchAllProductTypes().subscribe(types => {
      if (types) {
        for (let type of types) {
          if (type.type === 'ORDER_REQUEST') {
            this.orderRequestId = type.id;
            return;
          }
        }
      }
    });
  }


  getStation() {
    this.coreService.getStation().subscribe(
      (data: Railway[]) => {
        Swal.close();
        this.railwayList = data;
      }
    );
  }

  selectRailway(event) {
    this.station = event;
  }

  checkVal($event, val) {
    if ($event.checked) {
      this.diliveryOptList.push(val);
      if (val === 3) {
        this.isSellerDeliver = true;
        this.deliveryReq = false;
      } else if (val === 4) {
        this.train = true;
        this.deliveryReq = false;
      } else if (val === 2) {
        this.buyerDeliver = true;
        this.deliveryReq = false;
      } else if (val === 5) {
        this.isSellerDeliverFixed = true;
        this.deliveryReq = false;
      }
    } else {
      if (this.diliveryOptList.length > 0) {
        for (let delivery of this.diliveryOptList) {
          if (delivery === val) {
            let index = this.diliveryOptList.indexOf(delivery);
            if (val === 3) {
              this.isSellerDeliver = false;
            } else if (val === 4) {
              this.train = false;
            } else if (val === 2) {
              this.buyerDeliver = false;
            } else if (val === 5) {
              this.isSellerDeliverFixed = false;
            }
            if (index !== -1) {
              this.diliveryOptList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  // next() {
  //   let isValid = false;
  //   if (this.isSellerDeliver === true || this.train === true || this.buyerDeliver === true || this.isSellerDeliverFixed) {
  //
  //     if (this.train === true) {
  //       if (this.station === null || this.station === undefined || this.station === '') {
  //         this.railwayReq = true;
  //       } else {
  //         this.railwayReq = false;
  //         isValid = true;
  //       }
  //     } else if (this.isSellerDeliver === true) {
  //       if (this.deliveryForm.value.deliverCharge === null || this.deliveryForm.value.deliverCharge === undefined
  //         || this.deliveryForm.value.deliverCharge === '') {
  //         this.isChargeReq = true;
  //       } else {
  //         this.isChargeReq = false;
  //         isValid = true;
  //       }
  //
  //     } else if (this.isSellerDeliverFixed === true) {
  //
  //       if (!(this.deliveryFormFixed.value.deliverChargeFixed === null || this.deliveryFormFixed.value.deliverChargeFixed === undefined
  //         || this.deliveryFormFixed.value.deliverChargeFixed === '')) {
  //
  //         if (!this.areaDisable) {
  //           if (this.deliveryFormFixed.value.areas === null || this.deliveryFormFixed.value.areas === undefined
  //             || this.deliveryFormFixed.value.areas === '') {
  //             isValid = false;
  //             this.isAreaReq = true;
  //           } else {
  //             isValid = true;
  //             this.isAreaReq = false;
  //           }
  //         } else {
  //           isValid = true;
  //           this.isFixedChargeReq = false;
  //         }
  //
  //       } else {
  //         this.isFixedChargeReq = true;
  //         isValid = false;
  //       }
  //
  //
  //     } else if (this.buyerDeliver === true) {
  //       isValid = true;
  //     }
  //   } else {
  //     this.deliveryReq = true;
  //   }
  //
  //   if (isValid) {
  //     this.deliveryReq = false;
  //     this.railwayReq = false;
  //     this.isChargeReq = false;
  //     this.isFixedChargeReq = false;
  //     this.isAreaReq = false;
  //     this.deliverCharge = this.deliveryForm.value.deliverCharge;
  //     this.deliverChargeFixed = this.deliveryFormFixed.value.deliverChargeFixed;
  //     this.areas = this.areaDisable ? 'All Island delivery' : this.deliveryFormFixed.value.areas;
  //
  //     this.saveProduct();
  //   }
  //
  //
  // }

  setAddress(index) {
    if (index === 'new') {
      this.isAgentFarmerCheck = false;
      this.addressObject = undefined;
      this.address1 = undefined;
      this.address2 = undefined;
      this.address = undefined;
      this.selectProvinceId = undefined;
      this.selectDistrictId = undefined;
      this.selectCityId = undefined;
      this.postalCode = undefined;
      this.isItNewAddress = true;
    } else if (index === 'FM') {
      this.isAgentFarmerCheck = true;
      this.address1 = this.agentFarmerAddress1;
      this.address2 = this.agentFarmerAddress2;
      this.selectProvinceId = this.agentFamrerProvinceId;
      this.getAllDistrict(this.selectProvinceId);
      this.selectDistrictId = this.agentFamrerDisctrictId;
      this.getAllCities(this.selectDistrictId);
      this.getAllDivSec(this.selectDistrictId);
      this.selectCityId = this.agentFarmerCity;
      this.postalCode = this.agentFarmerPostal;
      this.isItNewAddress = false;

    } else {
      this.isAgentFarmerCheck = false;
      this.addressObject = this.addressList[index];

      this.address1 = this.addressObject.addressOne;
      this.address2 = this.addressObject.addressTwo;

      this.selectProvinceId = this.addressObject.cities.agriDistrict.province.id;
      this.getAllDistrict(this.selectProvinceId);
      this.selectDistrictId = this.addressObject.cities.agriDistrict.id;
      this.getAllCities(this.selectDistrictId);
      this.getAllDivSec(this.selectDistrictId);
      this.selectCityId = this.addressObject.cities.id;
      this.postalCode = this.addressObject.postalCode;
      this.isItNewAddress = false;
    }

  }

  expectedMinDate = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10);

  setExpectedMinDate(bidsAcceptedTill) {
    this.expectedMinDate = new Date(bidsAcceptedTill).toISOString().slice(0, 10);
  }

  resetWindow() {
    this.closeModal.nativeElement.click();
    this.selectOrderNext = 0;
    this.route.navigate(['/my-account/refresh-my-order-requests/wholesale']).then(()=>{
      this.route.navigate(['/my-account/my-order-requests/wholesale']);
    });
  }
}

