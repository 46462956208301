/**
 * PROJECT BUILD :   ng build --configuration=helaviruLive
 * DOCKER BUILD  :   docker image build -t eltpl/epic:helaviru-public-demo-v4.4.66.3 .
 * DOCKER PUSH   :   docker push eltpl/epic:helaviru-public-demo-v4.4.66.3
 * DOCKER RUN    :   sudo docker run -p 4200:80 -d eltpl/epic:helaviru-public-demo-v4.4.66.3
 */

export const environment = {
  production: true,
  proxyUrl: 'http://sltapi.helaviru.com/',
  riyaviruUrl: 'https://riyavirumobile.helaviru.com',
  platform: 'COMEX',
  specCode: '999',
  version: '4.4.66.3',
  tenantID: 'wholesale',
  footerText: '2021 Powered By DOXPRO'
};


// ng build --prod=true
