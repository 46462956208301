import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {SystemSettingsService} from '../service/system-settings.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  fileURL: string;

  constructor(private settingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'HELAVIRU_PRIVACY') {
            return this.fileURL = environment.proxyUrl + 'fileservice/file/' + setting.value + '/?X-TenantID=wholesale';
          }
        }
      }

    });
  }

}
