import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {SmsService} from '../service/sms.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  forgotPasswordForm: FormGroup;
  mobile: any;
  userId: any;
  username: any;
  tenantValue: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  @ViewChild('optModal', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;

  constructor(private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private route: Router,
              private smsService: SmsService,
              private validationHandlerService: ValidationHandlerService
  ) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      mobile: ['', [Validators.required]]
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  resetPassword() {
    if (this.mobile !== undefined) {
      this.loading();
      this.smsService.forgetPasswordOTP(this.mobile).subscribe(result => {
        Swal.close();
        if (result['userName'] !== null) {
          this.isNotAvailable = false;
          this.username = result['userName'];
          this.userId = result['uid'];
          if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
            this.OTPValidation(60 * 3, document.getElementById('time'));
          }
          this.optModal.nativeElement.click();
        } else {
          this.isNotAvailable = true;
        }
      },()=>{
        Swal.close();
      });
    }
  }

  submit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.resetPassword();
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }


  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;
  isNotAvailable: boolean = false;


  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    let x = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (this.isOTPModalClose) {
        clearInterval(x);
        document.getElementById('time').textContent = '03:00';
        return;
      }

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(x);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    clearInterval();
  }

  resendOTPCode() {
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time').textContent = '03:00';
    if (this.mobile !== undefined) {
      this.loading();
      this.smsService.forgetPasswordOTP(this.mobile).subscribe(result => {
        Swal.close();
        if (result['userName'] !== null) {
          this.isNotAvailable = false;
          this.username = result['userName'];
          this.userId = result['uid'];
          if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
            this.OTPValidation(60 * 3, document.getElementById('time'));
          }
        } else {
          this.isNotAvailable = true;
        }
      },()=>{
        Swal.close();
      });
    }




    this.isShowResendButton = false;
  }

  verifyOTP() {
    this.isLoading = true;
    let verifyOTPBody = {
      'username': this.username,
      'otp': this.OTP_CODE
    };
    this.smsService.verifyOTPCode(verifyOTPBody).subscribe(isVerify => {
      if (isVerify) {
        this.isInvalid = false;
        this.isLoading = false;
        this.closeModal.nativeElement.click();
        this.route.navigate(['/reset-password/' + this.tenantValue], {queryParams: {uid: this.userId}});
      } else {
        this.isInvalid = true;
        this.isLoading = false;
      }
    }, () => {
      this.isInvalid = true;
      this.isLoading = false;
    });
  }

}
