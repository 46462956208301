import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {SystemSettingsService} from '../../service/system-settings.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  fileURL: string;
  lastModifiedDate: any;

  constructor(private settingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'CUSTOMER_TERM_CONDITION') {
            this.lastModifiedDate = setting.updated;
            return this.fileURL = environment.proxyUrl + 'fileservice/file/' + setting.value + '/?X-TenantID=wholesale';
          }
        }
      }

    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }
}
