<app-header></app-header>
<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">
          <div class="pro-container vas-pages">
            <div class="sidebar-info">
              <app-i-sidebar></app-i-sidebar>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
