import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SystemSettingsService} from '../service/system-settings.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService implements CanActivate {

  _tenantValue;

  constructor(private _router: Router,
              private systemSettingsService: SystemSettingsService,
              private _translateService: TranslateService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean
    | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      localStorage.setItem('TENANT_VALUE', environment.tenantID);
      this._tenantValue = localStorage.getItem('TENANT_VALUE');

          sessionStorage.setItem('LANG', 'en');
          this._router.navigate(['/home/'+this._tenantValue])

          return true;


  }


}


