<!--<html>-->
<head>
  <!--  <meta content="text/html; charset=UTF-8" http-equiv="content-type">-->
  <style
    type="text/css">@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLYkgs1JSG8vWJ4-dVTsuHbsy05Ujw2ac-azvRHhTTh9C');

  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1
  }

  .lst-kix_list_14-1 > li:before {
    content: "" counter(lst-ctn-kix_list_14-1, lower-latin) ". "
  }

  .lst-kix_list_14-3 > li:before {
    content: "" counter(lst-ctn-kix_list_14-3, decimal) ". "
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none
  }

  ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 11
  }

  .lst-kix_list_14-0 > li:before {
    content: "" counter(lst-ctn-kix_list_14-0, decimal) ". "
  }

  .lst-kix_list_14-4 > li:before {
    content: "" counter(lst-ctn-kix_list_14-4, lower-latin) ". "
  }

  .lst-kix_list_14-5 > li:before {
    content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". "
  }

  .lst-kix_list_14-7 > li:before {
    content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". "
  }

  ol.lst-kix_list_13-4.start {
    counter-reset: lst-ctn-kix_list_13-4 0
  }

  .lst-kix_list_14-6 > li:before {
    content: "" counter(lst-ctn-kix_list_14-6, decimal) ". "
  }

  .lst-kix_list_13-0 > li {
    counter-increment: lst-ctn-kix_list_13-0
  }

  .lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0
  }

  .lst-kix_list_9-0 > li {
    counter-increment: lst-ctn-kix_list_9-0
  }

  .lst-kix_list_11-0 > li {
    counter-increment: lst-ctn-kix_list_11-0
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none
  }

  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none
  }

  .lst-kix_list_14-2 > li:before {
    content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ". "
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none
  }

  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0
  }

  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
  }

  ol.lst-kix_list_14-7.start {
    counter-reset: lst-ctn-kix_list_14-7 0
  }

  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0
  }

  .lst-kix_list_14-8 > li:before {
    content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". "
  }

  .lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2
  }

  ol.lst-kix_list_10-4.start {
    counter-reset: lst-ctn-kix_list_10-4 0
  }

  .lst-kix_list_9-2 > li {
    counter-increment: lst-ctn-kix_list_9-2
  }

  .lst-kix_list_5-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_5-0, lower-roman) ") "
  }

  .lst-kix_list_14-8 > li {
    counter-increment: lst-ctn-kix_list_14-8
  }

  .lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4
  }

  .lst-kix_list_5-3 > li:before {
    content: "" counter(lst-ctn-kix_list_5-3, decimal) ". "
  }

  ol.lst-kix_list_9-5.start {
    counter-reset: lst-ctn-kix_list_9-5 0
  }

  .lst-kix_list_13-2 > li {
    counter-increment: lst-ctn-kix_list_13-2
  }

  .lst-kix_list_5-2 > li:before {
    content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". "
  }

  .lst-kix_list_5-1 > li:before {
    content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". "
  }

  .lst-kix_list_5-7 > li:before {
    content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". "
  }

  ul.lst-kix_list_8-4 {
    list-style-type: none
  }

  .lst-kix_list_14-3 > li {
    counter-increment: lst-ctn-kix_list_14-3
  }

  ul.lst-kix_list_8-5 {
    list-style-type: none
  }

  .lst-kix_list_5-6 > li:before {
    content: "" counter(lst-ctn-kix_list_5-6, decimal) ". "
  }

  .lst-kix_list_5-8 > li:before {
    content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". "
  }

  ul.lst-kix_list_8-2 {
    list-style-type: none
  }

  ul.lst-kix_list_8-3 {
    list-style-type: none
  }

  ul.lst-kix_list_8-8 {
    list-style-type: none
  }

  ul.lst-kix_list_8-6 {
    list-style-type: none
  }

  ul.lst-kix_list_8-7 {
    list-style-type: none
  }

  .lst-kix_list_9-4 > li {
    counter-increment: lst-ctn-kix_list_9-4
  }

  .lst-kix_list_5-4 > li:before {
    content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". "
  }

  .lst-kix_list_5-5 > li:before {
    content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". "
  }

  ul.lst-kix_list_8-0 {
    list-style-type: none
  }

  ul.lst-kix_list_8-1 {
    list-style-type: none
  }

  .lst-kix_list_10-3 > li {
    counter-increment: lst-ctn-kix_list_10-3
  }

  ol.lst-kix_list_14-1.start {
    counter-reset: lst-ctn-kix_list_14-1 0
  }

  .lst-kix_list_6-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_6-3 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_13-3.start {
    counter-reset: lst-ctn-kix_list_13-3 0
  }

  .lst-kix_list_6-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_6-4 > li:before {
    content: "o  "
  }

  ol.lst-kix_list_14-8.start {
    counter-reset: lst-ctn-kix_list_14-8 0
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
  }

  .lst-kix_list_6-2 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_6-8 > li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0
  }

  ol.lst-kix_list_14-2.start {
    counter-reset: lst-ctn-kix_list_14-2 0
  }

  .lst-kix_list_6-5 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_6-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_6-6 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0
  }

  .lst-kix_list_2-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_7-4 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_7-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-5 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_7-2 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_10-3.start {
    counter-reset: lst-ctn-kix_list_10-3 0
  }

  ol.lst-kix_list_9-4.start {
    counter-reset: lst-ctn-kix_list_9-4 0
  }

  ul.lst-kix_list_3-7 {
    list-style-type: none
  }

  .lst-kix_list_14-6 > li {
    counter-increment: lst-ctn-kix_list_14-6
  }

  ul.lst-kix_list_3-8 {
    list-style-type: none
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
  }

  ol.lst-kix_list_9-7 {
    list-style-type: none
  }

  .lst-kix_list_10-1 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) " "
  }

  ol.lst-kix_list_9-8 {
    list-style-type: none
  }

  .lst-kix_list_13-7 > li:before {
    content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". "
  }

  ol.lst-kix_list_9-3 {
    list-style-type: none
  }

  ul.lst-kix_list_3-1 {
    list-style-type: none
  }

  ol.lst-kix_list_9-4 {
    list-style-type: none
  }

  ul.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7
  }

  ol.lst-kix_list_9-5 {
    list-style-type: none
  }

  .lst-kix_list_7-8 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_9-6 {
    list-style-type: none
  }

  ul.lst-kix_list_3-0 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
  }

  ul.lst-kix_list_3-5 {
    list-style-type: none
  }

  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7
  }

  ol.lst-kix_list_9-0 {
    list-style-type: none
  }

  ul.lst-kix_list_3-6 {
    list-style-type: none
  }

  ol.lst-kix_list_9-1 {
    list-style-type: none
  }

  ul.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_9-2 {
    list-style-type: none
  }

  ul.lst-kix_list_3-4 {
    list-style-type: none
  }

  .lst-kix_list_10-7 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) "." counter(lst-ctn-kix_list_10-3, decimal) "." counter(lst-ctn-kix_list_10-4, decimal) "." counter(lst-ctn-kix_list_10-5, decimal) "." counter(lst-ctn-kix_list_10-6, decimal) "." counter(lst-ctn-kix_list_10-7, decimal) " "
  }

  .lst-kix_list_10-5 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) "." counter(lst-ctn-kix_list_10-3, decimal) "." counter(lst-ctn-kix_list_10-4, decimal) "." counter(lst-ctn-kix_list_10-5, decimal) " "
  }

  .lst-kix_list_9-8 > li {
    counter-increment: lst-ctn-kix_list_9-8
  }

  .lst-kix_list_13-4 > li {
    counter-increment: lst-ctn-kix_list_13-4
  }

  ol.lst-kix_list_13-5.start {
    counter-reset: lst-ctn-kix_list_13-5 0
  }

  .lst-kix_list_10-3 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) "." counter(lst-ctn-kix_list_10-3, decimal) " "
  }

  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
  }

  ol.lst-kix_list_13-8.start {
    counter-reset: lst-ctn-kix_list_13-8 0
  }

  .lst-kix_list_11-7 > li {
    counter-increment: lst-ctn-kix_list_11-7
  }

  .lst-kix_list_9-2 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) " "
  }

  .lst-kix_list_4-3 > li:before {
    content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "
  }

  .lst-kix_list_4-5 > li:before {
    content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0
  }

  ol.lst-kix_list_14-0.start {
    counter-reset: lst-ctn-kix_list_14-0 0
  }

  .lst-kix_list_10-5 > li {
    counter-increment: lst-ctn-kix_list_10-5
  }

  .lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5
  }

  .lst-kix_list_9-0 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) " "
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
  }

  ol.lst-kix_list_10-7 {
    list-style-type: none
  }

  ol.lst-kix_list_9-2.start {
    counter-reset: lst-ctn-kix_list_9-2 0
  }

  .lst-kix_list_9-6 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) "." counter(lst-ctn-kix_list_9-3, decimal) "." counter(lst-ctn-kix_list_9-4, decimal) "." counter(lst-ctn-kix_list_9-5, decimal) "." counter(lst-ctn-kix_list_9-6, decimal) " "
  }

  ol.lst-kix_list_10-8 {
    list-style-type: none
  }

  .lst-kix_list_9-3 > li {
    counter-increment: lst-ctn-kix_list_9-3
  }

  ol.lst-kix_list_10-3 {
    list-style-type: none
  }

  .lst-kix_list_11-2 > li {
    counter-increment: lst-ctn-kix_list_11-2
  }

  .lst-kix_list_9-4 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) "." counter(lst-ctn-kix_list_9-3, decimal) "." counter(lst-ctn-kix_list_9-4, decimal) " "
  }

  ol.lst-kix_list_10-4 {
    list-style-type: none
  }

  ol.lst-kix_list_10-5 {
    list-style-type: none
  }

  .lst-kix_list_11-3 > li:before {
    content: "" counter(lst-ctn-kix_list_11-3, decimal) ". "
  }

  ol.lst-kix_list_10-6 {
    list-style-type: none
  }

  ol.lst-kix_list_10-0 {
    list-style-type: none
  }

  ol.lst-kix_list_10-1 {
    list-style-type: none
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none
  }

  ol.lst-kix_list_10-2 {
    list-style-type: none
  }

  .lst-kix_list_12-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_11-5 > li:before {
    content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". "
  }

  .lst-kix_list_12-1 > li:before {
    content: "o  "
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_9-8 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) "." counter(lst-ctn-kix_list_9-3, decimal) "." counter(lst-ctn-kix_list_9-4, decimal) "." counter(lst-ctn-kix_list_9-5, decimal) "." counter(lst-ctn-kix_list_9-6, decimal) "." counter(lst-ctn-kix_list_9-7, decimal) "." counter(lst-ctn-kix_list_9-8, decimal) " "
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li:before {
    content: "o  "
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none
  }

  .lst-kix_list_11-7 > li:before {
    content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". "
  }

  .lst-kix_list_13-3 > li {
    counter-increment: lst-ctn-kix_list_13-3
  }

  ol.lst-kix_list_13-6.start {
    counter-reset: lst-ctn-kix_list_13-6 0
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_10-4 > li {
    counter-increment: lst-ctn-kix_list_10-4
  }

  .lst-kix_list_14-1 > li {
    counter-increment: lst-ctn-kix_list_14-1
  }

  ol.lst-kix_list_10-5.start {
    counter-reset: lst-ctn-kix_list_10-5 0
  }

  .lst-kix_list_13-3 > li:before {
    content: "" counter(lst-ctn-kix_list_13-3, decimal) ". "
  }

  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8
  }

  .lst-kix_list_1-7 > li:before {
    content: "o  "
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_9-1.start {
    counter-reset: lst-ctn-kix_list_9-1 5
  }

  .lst-kix_list_13-5 > li:before {
    content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". "
  }

  .lst-kix_list_14-7 > li {
    counter-increment: lst-ctn-kix_list_14-7
  }

  .lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6
  }

  .lst-kix_list_12-5 > li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_13-7.start {
    counter-reset: lst-ctn-kix_list_13-7 0
  }

  .lst-kix_list_12-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_2-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_11-8 > li {
    counter-increment: lst-ctn-kix_list_11-8
  }

  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2
  }

  .lst-kix_list_13-1 > li:before {
    content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". "
  }

  .lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1
  }

  ol.lst-kix_list_11-6 {
    list-style-type: none
  }

  ol.lst-kix_list_11-7 {
    list-style-type: none
  }

  ol.lst-kix_list_11-8 {
    list-style-type: none
  }

  .lst-kix_list_9-1 > li {
    counter-increment: lst-ctn-kix_list_9-1
  }

  ol.lst-kix_list_11-2 {
    list-style-type: none
  }

  ol.lst-kix_list_11-3 {
    list-style-type: none
  }

  .lst-kix_list_3-0 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_11-4 {
    list-style-type: none
  }

  ol.lst-kix_list_11-5 {
    list-style-type: none
  }

  ol.lst-kix_list_13-1.start {
    counter-reset: lst-ctn-kix_list_13-1 0
  }

  .lst-kix_list_3-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_3-2 > li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_11-0 {
    list-style-type: none
  }

  .lst-kix_list_14-0 > li {
    counter-increment: lst-ctn-kix_list_14-0
  }

  ol.lst-kix_list_11-1 {
    list-style-type: none
  }

  .lst-kix_list_8-1 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0
  }

  .lst-kix_list_8-2 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-5 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_10-0 > li {
    counter-increment: lst-ctn-kix_list_10-0
  }

  .lst-kix_list_3-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_3-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_8-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_8-7 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0
  }

  .lst-kix_list_3-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_8-5 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0
  }

  .lst-kix_list_11-1 > li {
    counter-increment: lst-ctn-kix_list_11-1
  }

  .lst-kix_list_8-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_13-1 > li {
    counter-increment: lst-ctn-kix_list_13-1
  }

  .lst-kix_list_8-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_8-4 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
  }

  .lst-kix_list_11-2 > li:before {
    content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". "
  }

  .lst-kix_list_10-2 > li {
    counter-increment: lst-ctn-kix_list_10-2
  }

  .lst-kix_list_11-1 > li:before {
    content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". "
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
  }

  .lst-kix_list_11-0 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, upper-latin) ". "
  }

  ol.lst-kix_list_9-3.start {
    counter-reset: lst-ctn-kix_list_9-3 0
  }

  .lst-kix_list_8-8 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0
  }

  ol.lst-kix_list_10-1.start {
    counter-reset: lst-ctn-kix_list_10-1 6
  }

  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4
  }

  .lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
  }

  .lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
  }

  .lst-kix_list_14-2 > li {
    counter-increment: lst-ctn-kix_list_14-2
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
  }

  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0
  }

  .lst-kix_list_11-3 > li {
    counter-increment: lst-ctn-kix_list_11-3
  }

  ol.lst-kix_list_11-0.start {
    counter-reset: lst-ctn-kix_list_11-0 0
  }

  ul.lst-kix_list_12-6 {
    list-style-type: none
  }

  ul.lst-kix_list_12-5 {
    list-style-type: none
  }

  ul.lst-kix_list_12-4 {
    list-style-type: none
  }

  ul.lst-kix_list_12-3 {
    list-style-type: none
  }

  ul.lst-kix_list_12-2 {
    list-style-type: none
  }

  ul.lst-kix_list_12-1 {
    list-style-type: none
  }

  ol.lst-kix_list_10-2.start {
    counter-reset: lst-ctn-kix_list_10-2 0
  }

  ul.lst-kix_list_12-0 {
    list-style-type: none
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0
  }

  ul.lst-kix_list_12-8 {
    list-style-type: none
  }

  ul.lst-kix_list_12-7 {
    list-style-type: none
  }

  .lst-kix_list_7-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_13-8 > li {
    counter-increment: lst-ctn-kix_list_13-8
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none
  }

  .lst-kix_list_2-6 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none
  }

  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none
  }

  ol.lst-kix_list_13-8 {
    list-style-type: none
  }

  .lst-kix_list_14-5 > li {
    counter-increment: lst-ctn-kix_list_14-5
  }

  .lst-kix_list_2-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_2-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_7-1 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_7-5 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_13-5 > li {
    counter-increment: lst-ctn-kix_list_13-5
  }

  .lst-kix_list_9-6 > li {
    counter-increment: lst-ctn-kix_list_9-6
  }

  ol.lst-kix_list_13-4 {
    list-style-type: none
  }

  ol.lst-kix_list_13-5 {
    list-style-type: none
  }

  ol.lst-kix_list_13-6 {
    list-style-type: none
  }

  ol.lst-kix_list_13-7 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0 {
    list-style-type: none
  }

  ol.lst-kix_list_13-1 {
    list-style-type: none
  }

  ol.lst-kix_list_13-2 {
    list-style-type: none
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0
  }

  .lst-kix_list_7-3 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0
  }

  ol.lst-kix_list_13-3 {
    list-style-type: none
  }

  ul.lst-kix_list_7-5 {
    list-style-type: none
  }

  .lst-kix_list_10-0 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) " "
  }

  ul.lst-kix_list_7-6 {
    list-style-type: none
  }

  ul.lst-kix_list_7-3 {
    list-style-type: none
  }

  .lst-kix_list_9-7 > li {
    counter-increment: lst-ctn-kix_list_9-7
  }

  ul.lst-kix_list_7-4 {
    list-style-type: none
  }

  .lst-kix_list_13-6 > li:before {
    content: "" counter(lst-ctn-kix_list_13-6, decimal) ". "
  }

  .lst-kix_list_13-6 > li {
    counter-increment: lst-ctn-kix_list_13-6
  }

  .lst-kix_list_13-8 > li:before {
    content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". "
  }

  ol.lst-kix_list_14-6.start {
    counter-reset: lst-ctn-kix_list_14-6 0
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0
  }

  ul.lst-kix_list_7-7 {
    list-style-type: none
  }

  ul.lst-kix_list_7-8 {
    list-style-type: none
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none
  }

  .lst-kix_list_10-6 > li {
    counter-increment: lst-ctn-kix_list_10-6
  }

  .lst-kix_list_11-6 > li {
    counter-increment: lst-ctn-kix_list_11-6
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none
  }

  ul.lst-kix_list_7-1 {
    list-style-type: none
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none
  }

  ul.lst-kix_list_7-2 {
    list-style-type: none
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none
  }

  ul.lst-kix_list_7-0 {
    list-style-type: none
  }

  .lst-kix_list_7-7 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_10-0.start {
    counter-reset: lst-ctn-kix_list_10-0 11
  }

  .lst-kix_list_11-4 > li {
    counter-increment: lst-ctn-kix_list_11-4
  }

  .lst-kix_list_9-5 > li {
    counter-increment: lst-ctn-kix_list_9-5
  }

  .lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8
  }

  .lst-kix_list_10-4 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) "." counter(lst-ctn-kix_list_10-3, decimal) "." counter(lst-ctn-kix_list_10-4, decimal) " "
  }

  .lst-kix_list_10-8 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) "." counter(lst-ctn-kix_list_10-3, decimal) "." counter(lst-ctn-kix_list_10-4, decimal) "." counter(lst-ctn-kix_list_10-5, decimal) "." counter(lst-ctn-kix_list_10-6, decimal) "." counter(lst-ctn-kix_list_10-7, decimal) "." counter(lst-ctn-kix_list_10-8, decimal) " "
  }

  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, lower-roman) ". "
  }

  .lst-kix_list_10-2 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) " "
  }

  ol.lst-kix_list_14-3.start {
    counter-reset: lst-ctn-kix_list_14-3 0
  }

  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6
  }

  .lst-kix_list_13-7 > li {
    counter-increment: lst-ctn-kix_list_13-7
  }

  .lst-kix_list_4-4 > li:before {
    content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
  }

  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". "
  }

  .lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
  }

  .lst-kix_list_9-3 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) "." counter(lst-ctn-kix_list_9-3, decimal) " "
  }

  .lst-kix_list_10-8 > li {
    counter-increment: lst-ctn-kix_list_10-8
  }

  ol.lst-kix_list_13-2.start {
    counter-reset: lst-ctn-kix_list_13-2 0
  }

  .lst-kix_list_10-6 > li:before {
    content: "" counter(lst-ctn-kix_list_10-0, decimal) "." counter(lst-ctn-kix_list_10-1, decimal) "." counter(lst-ctn-kix_list_10-2, decimal) "." counter(lst-ctn-kix_list_10-3, decimal) "." counter(lst-ctn-kix_list_10-4, decimal) "." counter(lst-ctn-kix_list_10-5, decimal) "." counter(lst-ctn-kix_list_10-6, decimal) " "
  }

  .lst-kix_list_9-1 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) " "
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none
  }

  ol.lst-kix_list_14-7 {
    list-style-type: none
  }

  ol.lst-kix_list_14-4.start {
    counter-reset: lst-ctn-kix_list_14-4 0
  }

  ol.lst-kix_list_14-8 {
    list-style-type: none
  }

  .lst-kix_list_9-7 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) "." counter(lst-ctn-kix_list_9-3, decimal) "." counter(lst-ctn-kix_list_9-4, decimal) "." counter(lst-ctn-kix_list_9-5, decimal) "." counter(lst-ctn-kix_list_9-6, decimal) "." counter(lst-ctn-kix_list_9-7, decimal) " "
  }

  ol.lst-kix_list_14-3 {
    list-style-type: none
  }

  ol.lst-kix_list_14-4 {
    list-style-type: none
  }

  ol.lst-kix_list_14-5 {
    list-style-type: none
  }

  ol.lst-kix_list_14-6 {
    list-style-type: none
  }

  .lst-kix_list_11-4 > li:before {
    content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". "
  }

  ol.lst-kix_list_14-0 {
    list-style-type: none
  }

  .lst-kix_list_12-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_9-5 > li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) "." counter(lst-ctn-kix_list_9-1, decimal) "." counter(lst-ctn-kix_list_9-2, decimal) "." counter(lst-ctn-kix_list_9-3, decimal) "." counter(lst-ctn-kix_list_9-4, decimal) "." counter(lst-ctn-kix_list_9-5, decimal) " "
  }

  ol.lst-kix_list_14-1 {
    list-style-type: none
  }

  ol.lst-kix_list_14-2 {
    list-style-type: none
  }

  ul.lst-kix_list_6-6 {
    list-style-type: none
  }

  ul.lst-kix_list_6-7 {
    list-style-type: none
  }

  .lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3
  }

  ul.lst-kix_list_6-4 {
    list-style-type: none
  }

  ul.lst-kix_list_6-5 {
    list-style-type: none
  }

  ul.lst-kix_list_6-8 {
    list-style-type: none
  }

  .lst-kix_list_12-2 > li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_11-6 > li:before {
    content: "" counter(lst-ctn-kix_list_11-6, decimal) ". "
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none
  }

  ul.lst-kix_list_6-2 {
    list-style-type: none
  }

  .lst-kix_list_11-8 > li:before {
    content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". "
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none
  }

  ul.lst-kix_list_6-3 {
    list-style-type: none
  }

  ol.lst-kix_list_11-3.start {
    counter-reset: lst-ctn-kix_list_11-3 0
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none
  }

  ul.lst-kix_list_6-0 {
    list-style-type: none
  }

  .lst-kix_list_12-0 > li:before {
    content: "-  "
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none
  }

  ul.lst-kix_list_6-1 {
    list-style-type: none
  }

  .lst-kix_list_1-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_13-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_13-0, lower-roman) ") "
  }

  .lst-kix_list_10-1 > li {
    counter-increment: lst-ctn-kix_list_10-1
  }

  ol.lst-kix_list_13-0.start {
    counter-reset: lst-ctn-kix_list_13-0 0
  }

  .lst-kix_list_14-4 > li {
    counter-increment: lst-ctn-kix_list_14-4
  }

  .lst-kix_list_13-4 > li:before {
    content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". "
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_10-7 > li {
    counter-increment: lst-ctn-kix_list_10-7
  }

  .lst-kix_list_2-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_12-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_11-5 > li {
    counter-increment: lst-ctn-kix_list_11-5
  }

  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5
  }

  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0
  }

  ol.lst-kix_list_14-5.start {
    counter-reset: lst-ctn-kix_list_14-5 0
  }

  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_13-2 > li:before {
    content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0
  }

  .lst-kix_list_12-8 > li:before {
    content: "\0025aa  "
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c15 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-style: normal
  }

  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c19 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c27 {
    color: #a6a6a6;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c6 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c25 {
    color: #5b9bd5;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-style: normal
  }

  .c26 {
    color: #222222;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c13 {
    font-family: "Calibri";
    color: #000000;
    font-weight: 400
  }

  .c18 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 40px;
  }

  .c12 {
    padding: 0;
    margin: 0
  }

  .c2 {
    font-weight: 700;
    font-family: "Calibri"
  }

  .c7 {
    margin-left: 90pt;
    text-indent: -30pt
  }

  .c22 {
    margin-left: 31.5pt;
    text-indent: -31.5pt
  }

  .c14 {
    margin-left: 36pt;
    text-indent: -36pt
  }

  .c5 {
    margin-left: 30pt;
    text-indent: -30pt
  }

  .c20 {
    margin-left: 36pt;
    padding-left: 18pt
  }

  .c23 {
    font-weight: 400;
    font-family: "Calibri"
  }

  .c4 {
    margin-left: 60pt;
    text-indent: -30pt
  }

  .c21 {
    margin-left: 36pt
  }

  .c24 {
    background-color: #ffff00
  }

  .c8 {
    font-weight: 700
  }

  .c1 {
    height: 11pt
  }

  .c10 {
    padding-left: 0pt
  }

  .c9 {
    margin-left: 18pt
  }

  .c17 {
    /* text-indent: -30pt */
  }

  .title {
    padding-top: 0pt;
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    font-size: 12pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-decoration-skip-ink: none;
    font-family: "Garamond";
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }</style>
</head>
<body class="c18"><p class="c16 title"><span class="c2 c15">HELAVIRU LEAD AGREEMENT </span></p>
<p class="c0 c1"><span class="c15 c2"></span></p>
<p class="c0"><span>This agreement (hereinafter called the &ldquo;Agreement&rdquo;) is made by and between </span><span
  class="c8">YOU and Epic Green Eco Systems (Private) Limited</span><span>, a company incorporated in the Democratic Socialist Republic of Sri Lanka under the Companies Act No. 07 of 2007 and having its registered office at </span><span
  class="c13">Epic Techno-Village, 158/1/A, Kaduwela Road, Thalangama, Battaramulla, 10120, Sri Lanka</span><span
  class="c3">&nbsp;(hereinafter referred to as &ldquo;EPIC&rdquo;) </span></p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c19"><span class="c2">WHEREAS</span><span class="c3">&nbsp;EPIC is a specialist in technology solutions and has developed the Helaviru e-commerce platform and You acknowledge the same. You are desirous of being appointed as a Helaviru Lead on a voluntary basis to promote the use of the Helaviru Platform by the cultivators, wholesalers, retailers, transport providers, etc. in your area. </span>
</p>
<ol class="c12 lst-kix_list_14-0 start" start="1">
  <li class="c0 c9 c10"><span class="c6 c2">Services</span></li>
</ol>
<p class="c19"><span class="c13">You are hereby joining as a &ldquo;Lead&rdquo; of the Helaviru Platform (Helaviru Lead) purely on a voluntary basis and shall promote </span><span
  class="c23">the use of the Helaviru Platform by the cultivators, wholesalers, retailers, transport providers, etc. in your area and generate sales through the said Platform</span><span
  class="c3">.</span></p>
<p class="c0"><span class="c3">In addition, EPIC grants you a license that is non-exclusive and non-transferable, to use the Helaviru Platform for the purpose of connecting with users of the Helaviru Platform (hereinafter referred to as &ldquo;Users&rdquo;) during the term of this Agreement. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">EPIC has the right to update and/or modify the Helaviru Platform from time to time at its discretion.</span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">You shall not send spam or unsolicited messages to the Users or inconvenience them in any manner. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">Continuation of your membership as a Helaviru Lead will solely depend on your satisfactory performance and adherence to the terms and conditions&nbsp;as set out by EPIC, which include achieving the applicable revenue targets.</span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c6 c2">1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your Obligations</span><span
  class="c3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p class="c0"><span class="c3">You shall onboard a minimum of 50 farmers in your area to the Helaviru Platform, generate business worth the minimum Rupee value as may be stipulated by EPIC from time to time, visit each such farmer for a minimum of two times each month and provide a report thereon to EPIC on a monthly basis, not engage in any illegal activities or any activity that may harm the reputation of EPIC and/or the Helaviru Platform, not make any unilateral changes to the services which are agreed upon without written consent of EPIC.</span>
</p>
<p class="c0 c1 c14"><span class="c3"></span></p>
<p class="c0"><span class="c3">You shall be fully responsible for all services and shall ensure performance of the services in an appropriate manner and in accordance with EPIC&rsquo;s requirement, ensure that the services shall be rendered with adequate skill, care and diligence and in accordance with the best industry practices and with best professional efforts and you shall attend any training and familiarization programs conducted or organized by EPIC with regard to the Helaviru Platform and the services expected from you. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c19"><span class="c13">You shall ensure observance and conformation to all applicable laws, registrations and licenses and shall ensure that you shall not act, and shall refrain from acting, in any manner that could materially harm or tarnish the reputation or the goodwill of EPIC and/or the Helaviru Platform</span><span
  class="c6 c2">.</span></p>
<p class="c0 c1"><span class="c6 c2"></span></p>
<p class="c0"><span class="c6 c2">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No assignment of Intellectual Property Rights</span>
</p>
<p class="c0 c17">Nothing in this Agreement shall operate to assign or transfer any Intellectual Property Rights from EPIC to you, or from you to EPIC.<b>Intellectual Property Rights</b> means all intellectual property rights wherever in the world, whether registrable or unregistrable, registered or unregistered, including any application or right of application for such rights (and these &quot;intellectual property rights&quot; include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trade marks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, semi-conductor topography rights and rights in designs);
</p>
<p class="c0 c1"><span class="c6 c2"></span></p>
<p class="c0"><span class="c6 c2">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payments</span></p>
<p class="c0"><span class="c3">You will be paid a commission, as may be decided by EPIC at its sole discretion, for the voluntary services rendered in promoting the Helaviru Platform and assisting the stakeholders of Helaviru Platform to carry out&nbsp;transactions. Provided that the said commission shall not be construed as a right accrued to you.</span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">The quantum and/or percentage of commission payable, which will be decided at the discretion of EPIC, will be informed to you by EPIC, from time to time.</span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">The commission applicable will be paid to you by EPIC on a monthly basis.</span></p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c6 c2">4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Confidentiality Obligations</span>
</p>
<p class="c0 c22"><span class="c3">4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EPIC may disclose the Confidential Information to EPIC&#39;s officers, employees, professional advisers, insurers, agents and subcontractors who have a need to access Confidential Information for the performance of their work with respect to this Agreement and who are bound by a written agreement or professional obligation to protect the confidentiality of the Confidential Information. &quot;</span><span
  class="c6 c2">Confidential Information</span><span class="c3">&quot; means any information disclosed by or on behalf of either party during the term whether disclosed in writing, orally or otherwise, that at the time of disclosure:</span>
</p>
<p class="c0 c7"><span class="c3">(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;was marked or described as &quot;confidential&quot;; or</span>
</p>
<p class="c0 c7"><span class="c3">(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;should have been reasonably understood to be confidential.</span>
</p>
<p class="c0 c5"><span class="c3">4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Clause imposes no obligations with respect to Confidential Information that:</span>
</p>
<p class="c0 c4"><span class="c3">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is known before disclosure under this Agreement and is not subject to any other obligation of confidentiality;</span>
</p>
<p class="c0 c4"><span class="c3">(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is or becomes publicly known through no act or default of either party; or</span>
</p>
<p class="c0 c4"><span class="c3">(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is obtained from a third party in circumstances where such party has no reason to believe that there has been a breach of an obligation of confidentiality.</span>
</p>
<p class="c0 c5"><span class="c3">4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The restrictions in this Clause do not apply to the extent that any Confidential Information is required to be disclosed by any law or regulation, by any judicial or governmental order or request.</span>
</p>
<p class="c0 c5"><span class="c3">4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The provisions of this Clause shall continue in force indefinitely following the termination of this Agreement.</span>
</p>
<p class="c0 c1"><span class="c2 c6"></span></p>
<p class="c0"><span class="c6 c2">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Limitations and Exclusions of Liability</span>
</p>
<p class="c0 c5"><span
  class="c3">5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nothing in this Agreement will:</span></p>
<p class="c0 c4"><span class="c3">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude your liability for death or personal injury resulting from negligence;</span>
</p>
<p class="c0 c4"><span class="c3">(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude your liability for fraud or fraudulent misrepresentation;</span>
</p>
<p class="c0 c4"><span class="c3">(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit any liabilities in any way that is not permitted under applicable law; or</span>
</p>
<p class="c0 c4"><span class="c3">(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;exclude any liabilities that may not be excluded under applicable law.</span>
</p>
<p class="c0 c22"><span class="c3">5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Neither party shall be liable to the other party in respect of any losses arising out of a Force Majeure Event. &quot;</span><span
  class="c6 c2">Force Majeure Event</span><span class="c3">&quot; means an event, or a series of related events, that is outside the reasonable control of the party affected (including failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the laws, disasters, explosions, fires, floods, riots, terrorist attacks, wars, curfew, lockdowns and quarantine measures);</span>
</p>
<p class="c0 c5"><span class="c3">5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EPIC shall not be liable to you in respect of any loss of profits or anticipated savings, in respect of any loss of revenue or income, any loss of business, contracts or opportunities.</span>
</p>
<p class="c0 c5"><span class="c3">5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EPIC shall not be liable for any disruption of service done in terms of payments, trading, delivery or any other business activity.</span>
</p>
<p class="c0 c5"><span class="c3">5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c23 c26">EPIC shall not be liable to any end customer in terms of service, delivery, etc.</span>
</p>
<p class="c0 c22"><span class="c3">5.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EPIC makes no representations, warranties or guarantees as to the actions or inactions of the Users.</span>
</p>
<p class="c0 c1"><span class="c6 c2"></span></p>
<p class="c0"><span class="c6 c2">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Force Majeure Event</span></p>
<p class="c0 c5"><span class="c3">6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under this Agreement that obligation will be suspended for the duration of the Force Majeure Event.</span>
</p>
<p class="c0 c5"><span class="c3">6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A party that becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under this Agreement, must:</span>
</p>
<p class="c0 c4"><span
  class="c3">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;promptly notify the other; and</span></p>
<p class="c0 c4"><span class="c3">(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inform the other of the period for which it is estimated that such failure or delay will continue.</span>
</p>
<p class="c0 c5"><span class="c3">6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A party whose performance of its obligations under this Agreement is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force Majeure Event.</span>
</p>
<p class="c0 c1"><span class="c6 c2"></span></p>
<p class="c0"><span class="c6 c2">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Termination</span></p>
<p class="c0"><span class="c3">EPIC has the right to cancel your membership as a Helaviru Lead and terminate this Agreement at its discretion&nbsp;at any time with or without&nbsp;giving reasons therefor.</span>
</p>
<p class="c0 c1"><span class="c6 c2"></span></p>
<p class="c0"><span class="c6 c2">8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Effects of termination</span></p>
<p class="c0"><span class="c3">Upon the termination of this Agreement, all of the provisions of this Agreement shall cease to have effect. Except to the extent that this Agreement expressly provides otherwise, the termination of this Agreement shall not affect the accrued rights of either party. Within 30 days following the termination of this Agreement for any reason you must pay to EPIC any payments that have been accrued before the termination of this Agreement without prejudice to the parties&#39; other legal rights.</span>
</p>
<p class="c0 c1"><span class="c6 c2"></span></p>
<p class="c0"><span class="c6 c2">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General</span></p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entire Agreement </span></p>
<p class="c0"><span class="c3">This Agreement contains all covenants, stipulations and provisions agreed by the Parties. No agent or representative of either Party has authority to make, and the Parties shall not be bound by or be liable for, any other statement, representation, promise or agreement not set forth herein. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">Modifications or Variations: Any modification or variation of the terms and conditions of this Agreement, as well as any modification or variation in the scope of work, may only be made by a written agreement duly signed by both the Parties. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Applicable Law </span></p>
<p class="c0"><span class="c3">Applicable Law means and includes the laws and any other instruments having the force of law in Sri Lanka as may be issued and in force from time to time. This Agreement shall be interpreted in accordance with the laws of Sri Lanka. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Survival </span></p>
<p class="c0"><span class="c3">The clauses of this Agreement, which by nature are intended to survive termination of this Agreement, shall remain in effect after such termination. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Settlement of Disputes </span></p>
<p class="c0"><span class="c3">In case, a dispute arises between parties, then there would be two ways for resolution of the dispute under this Agreement viz: </span>
</p>
<ol class="c12 lst-kix_list_5-0 start" start="1">
  <li class="c0 c20"><span class="c3">Amicable Settlement: Performance of this Agreement is governed by the terms and conditions of this Agreement. However, at times dispute may arise about any interpretation of any term or condition of this Agreement, including but not limited to, the scope of work, the clauses of payments etc. In such a situation either party to this Agreement may send a written notice of dispute to the other party. The party receiving the notice of dispute will consider the notice and respond to it in writing within 30 days after receipt. If that party fails to respond within 30 days, or the dispute cannot be amicably settled within 30 days following the response of that party, then sub-clause (ii) shall become applicable. </span>
  </li>
  <li class="c0 c20"><span class="c3">Arbitration: Differences and/or disputes remaining unresolved amicably shall be settled through arbitration in accordance with the rules of Arbitration of the Sri Lanka National Arbitration Centre and the award made in pursuance thereof shall be binding on the parties. The venue for Arbitration proceedings shall be Colombo and the language of the arbitration proceedings and that of all documents and communications between you and the EPIC shall be English. Arbitrator(s) shall give reasons for the award and the award shall be binding on you and EPIC and enforceable at the courts of jurisdiction in Sri Lanka, in accordance with the provisions of the Arbitration Act in force. The services under this Agreement shall be continued during the arbitration proceedings, unless otherwise agreed in writing by you and EPIC or unless it is proved that the services cannot possibly be continued during the arbitration proceedings. </span>
  </li>
</ol>
<p class="c0 c1 c9"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indemnification </span></p>
<p class="c0"><span class="c3">If any action in court is brought against EPIC or an agent or an employee or a representative of EPIC by a Third Party pursuant to this Agreement on account of any act/omission/negligence on your part under this Agreement, or for any damage or injury caused by the alleged act, omission or negligence on your part to any Third Party, you shall in all such cases indemnify and keep EPIC, its agents, employees and representatives, harmless and indemnified from all such losses, damages, expenses, etc. arising out of any decrees or Court orders, etc. or otherwise arising out of any such act or omission. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notices </span></p>
<p class="c0"><span class="c3">All notices and other communications under this Agreement must be in writing, and must either be mailed by registered post with acknowledgement due or hand delivered with proof of it having been received or sent by email or fax. If posted, all notices will be considered as delivered after three days, of the notice having been posted. If hand delivered, all notices will be considered as delivered, when received by the party to whom the notice is sent. If the notice is faxed and /or e-mailed, it would be considered as delivered on the same day; this will be followed by a delivery of hardcopy within five days. All notices under this Agreement shall be sent to or delivered at the address as specified by the parties. A Notice shall be effective when delivered. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waiver </span></p>
<p class="c0"><span class="c3">Save as where this Agreement expressly provides, neither party shall be deemed to have waived any right, power, privilege or remedy under this Agreement unless such party shall have delivered to the other party a written waiver signed by an authorized official of such waiving party. No failure or delay on the part of either party in exercising any right, power, privilege or remedy hereunder shall operate as a waiver, default or acquiescence thereof. Any waiver on the part of either party of any right, power, privilege or remedy hereunder shall not preclude any other or further exercise thereof. Any single or partial exercise of any right, power, privileges or remedy hereunder shall not preclude any other or further exercise thereof. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Severability </span></p>
<p class="c0"><span class="c3">If any provision or condition of this Agreement is prohibited or rendered invalid or unenforceable, such prohibition, invalidity or unenforceability shall not affect the validity or enforceability of any other provisions and conditions of this Agreement or this Agreement as a whole and the remaining provisions of this Agreement shall remain in full force and effect. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Relationship of Parties</span></p>
<p class="c0"><span>You acknowledge and agree that this is not a permanent&nbsp;employment. You will render your voluntary services at your own will. </span><span
  class="c3">You are an independent contractor and not an employee or agent of EPIC. You shall not have authority to commit or create any liability on the part of EPIC in any manner whatsoever. </span>
</p>
<p class="c0 c1"><span class="c3"></span></p>
<p class="c0"><span class="c3">9.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Legal Authority</span></p>
<p class="c0"><span class="c3">Either Party has the legal right and authority to enter into this Agreement and to perform its obligations under this Agreement.</span>
</p>
<p class="c0 c1 c21"><span class="c3"></span></p>
<p class="c0 c21 c1"><span class="c3"></span></p>
<p class="c0"><span class="c6 c2">By clicking the &ldquo;checkbox&rdquo; to proceed, you confirm that you have read and clearly understood the content of this Agreement and will use the said Helaviru Platform and act as a Helaviru Lead on the terms and conditions set forth in this Agreement and will abide by the same at all times. </span>
</p>
<div><p class="c11"><span class="c23 c27">Agreement with Helaviru Leads</span>
</p>
  <p class="c1 c11"><span class="c3"></span></p></div>
<!--</body>-->
<!--</html>-->
