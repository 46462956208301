import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  riyawiruPaymentSaveDetails(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/riyaviru/saveDetails', body);
  }

  checkProductQtyStatus(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/productQuantity/reduce', body);
  }

  fetchPendingPaymentsByUsername(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/getPendingByUserName/' + username);
  }

  updatePendingPayment(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/updateBankSlip', body);
  }

  dynamicQrCode(reference, amount): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_DYNAMIC_QR_CODE + '/qr/get/' + reference + '/' + amount);
  }

  loadBOCIframe(paymentId, body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/checkoutV2/' + paymentId, body);
  }

  loadBOCTest(paymentId, amount): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/checkoutV1/' + paymentId + '/' + amount);
  }

  fetchPaymentId(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/common/fetchPaymentID/' + environment.platform);
  }

  fetchBarCode(tranId,body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/barcode/generate/' + tranId,body);
  }

  fetchQrCode(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/qr/generate', body);
  }

  sendPurchaseConfirmationEmail(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/sendPurchaseConfirmationEmail', body);
  }


  savePaymentSuccessDetails(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PRODUCT + '/purchase/saveTransactionDetails', body);
  }

  checkBOCPaymentStatus(resultIndicator): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/checkStatus/' + resultIndicator);
  }

  checkRiyawiruBOCPaymentStatus(resultIndicator): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/riyaviru/checkStatus/' + resultIndicator);
  }

  markAsPaymentBOCSmartPay(body, productType): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/markPendingPayment/' + productType, body);
  }

  fetchPendingPayments(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/getPendingPayments');
  }

  markAsBOCSmartPaid(transactionId): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/markAsPaid/' + transactionId, '');
  }

  bankTransfer(body, productType): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/saveTransfer/' + productType, body);
  }

  getPendingBankTransfer(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/getAllPendingBankPayments');
  }

  confirmPendingBankTransfer(confirmBankTransfer): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/confirmBankTransfer/' + confirmBankTransfer, '');
  }

  rejectPendingBankTransfer(body): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_PAYMENT + '/boc/rejectPendingPayment', body);
  }
}
