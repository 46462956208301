<app-header></app-header>
<div class="container">
  <form style="margin-top: 10vh">

  </form>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
    </div>
    <div class="col-md-4 col-md-offset-4">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="text-center">
            <h3><i class="fa fa-lock fa-4x lockColor"></i></h3>
            <h2 class="text-center">{{'SIGN_IN.FORGET_PASSWORD.FORGET_PASSWORD' | translate}}</h2>
            <p>{{'SIGN_IN.FORGET_PASSWORD.RESET_PASSWORD_MSG' | translate}}</p>
            <div class="panel-body">

              <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()" id="register-form" role="form" autocomplete="off" class="form" method="post">

                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-addon"><em class="glyphicon glyphicon-envelope color-blue"></em></span>
                    <input [(ngModel)]="mobile" [ngClass]="{'is-invalid': submitted && f.mobile.errors }"
                           class="form-control" formControlName="mobile" id="mobile"
                           name="mobile" placeholder="{{'SIGN_IN.FORGET_PASSWORD.USER_NAME_OR_MOBILE' | translate}}"
                           type="text">
                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                      <div *ngIf="f.mobile.errors.required" style="font-size: 12px;color: red">
                        {{'SIGN_IN.FORGET_PASSWORD.FILED_REQUIRED' | translate}}
                      </div>
                    </div>
                    <br>
                  </div>
                  <div style="font-size: 12px;color: red;margin-top: 5px" *ngIf="isNotAvailable">
                    {{'SIGN_IN.FORGET_PASSWORD.USER_PASSWORD_NOT_AVAILABLE' | translate}}
                  </div>

                </div>
                <div class="form-group">
                  <input name="recover-submit" class="btn btn-lg btn-light btn-block buttonColor"
                         value="{{'SIGN_IN.FORGET_PASSWORD.RESET_PASSWORD' | translate}}"
                         type="submit">
                </div>

                <input type="hidden" class="hide" name="token" id="token" value="">
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-md-offset-4">
    </div>
  </div>

  <form style="margin-top: 10vh">

  </form>
</div>
<!--<app-footer></app-footer>-->

<!-- OTP modal -->
<button #optModal class="btn btn-primary" data-target="#otpModal" data-toggle="modal" hidden type="button">
</button>

<!-- Modal -->
<div aria-hidden="true" aria-labelledby="otpModal" class="modal fade" data-backdrop="static"
     id="otpModal" role="dialog" style="text-align: center!important;"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'SIGN_IN.FORGET_PASSWORD.OTP_HAS_BEEN_SENT' | translate}}
          {{mobile}}</h5><br>
        <button #closeModal (click)="closeOTPModal()" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{'SIGN_IN.FORGET_PASSWORD.ENTER_OTP_CODE' | translate}}</h3>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                      [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>

        <div style="color: red;margin-top: 5px">{{'SIGN_IN.FORGET_PASSWORD.CODE_EXPIRES_IN' | translate}} : &nbsp;<span id="time">03:00</span></div>
        <button (click)="resendOTPCode()" *ngIf="isShowResendButton" class="btn btn-primary customBtn"
                style="margin-top: 12px;"
                type="button">{{'SIGN_IN.FORGET_PASSWORD.RESEND_CODE' | translate}}
        </button>
        <br>
        <span *ngIf="isInvalid"
              style="color: red;font-weight: bold;font-size: 14px;">{{'SIGN_IN.FORGET_PASSWORD.OTP_INVALID' | translate}}</span>

      </div>
      <div class="modal-footer">
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        <button (click)="verifyOTP()" class="btn btn-primary customBtn" type="button">
          {{'SIGN_IN.FORGET_PASSWORD.VERIFY' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
