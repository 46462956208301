import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BnNgIdleService} from 'bn-ng-idle';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {InteractionService} from './service/interaction.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  tenantValue: any;

  constructor(private bnIdle: BnNgIdleService,
              private router: Router,
              private interactionService: InteractionService,
              private toastr: ToastrService,
              private _translateService: TranslateService) {
  }


  ngOnInit(): void {

    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        let isLoggedIn = localStorage.getItem('isLogged');
        if (isLoggedIn === 'True') {
          this.clearSessionStorage(true);
        }
      }
    });

    sessionStorage.setItem('LANG', 'en');
    this._translateService.setDefaultLang('en');
    this._translateService.use('en');

  }

  clearSessionStorage(value) {
    Swal.close();
    localStorage.setItem('isLogged', 'False');
    localStorage.removeItem('isLogged');
    localStorage.removeItem('cartID');
    localStorage.removeItem('Access-Token');
    localStorage.removeItem('USERNAME');
    localStorage.removeItem('USER_ROLE');
    this.interactionService.updateSellButtonStatus(true);
    if (value) {
      this.toastr.error('session expired!');
      this.router.navigate(['/home/wholesale']);
    }
  }


}
