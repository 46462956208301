<div class="page-contents">
  <div class="packge-form" id="insurnce">
    <div class="top-info-section text-center">
      <img src="../../../../../../../assets/images/payment-types/insuarance/fire.png" alt="" class="sltimg">
      <h4 class="title-pck">
        <span *ngIf="activeLang === 'en'">Helaviru Fire Insurance Benefit</span>
        <span *ngIf="activeLang === 'si'">හෙළවිරු ගිනි රක්‍ෂණ ප්‍රතිලාභ</span>
        <span *ngIf="activeLang === 'ta'">ஹெலவிரு தீ காப்பீட்டு பலன்</span>
      </h4>

      <hr>

      <h4 class="title-pck" *ngIf="activeLang === 'en'"> Questionnaire for Fire business premises insurance</h4>
      <h4 class="title-pck" *ngIf="activeLang === 'si'"> ගිනි ව්‍යාපාර පරිශ්‍ර රක්‍ෂණය සඳහා ප්‍රශ්නාවලිය</h4>
      <h4 class="title-pck" *ngIf="activeLang === 'ta'"> தீ வணிக வளாக காப்பீட்டுக்கான கேள்வித்தாள்</h4>

      <p *ngIf="activeLang === 'en'">
        Any mis-description or mis-representation made in this proposal from liable to
        render the policy void.
      </p>
      <p *ngIf="activeLang === 'si'">
        ප්‍රතිපත්ති අවලංගු කිරීමට වගකිව යුතු තැනැත්තාගෙන් මෙම යෝජනාවේ වැරදි හෝ විස්තර
        කිරීමක් හෝ වැරදි නිරූපණයක් සිදු වේ. </p>
      <p *ngIf="activeLang === 'ta'">
        இந்த முன்மொழிவில் ஏதேனும் தவறான விளக்கம் அல்லது தவறான பிரதிநிதித்துவம் கொள்கை
        செல்லுபடியாகாது.
      </p>

      <p class="rednotice">
        <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
        <span *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
        <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
      </p>
    </div>
    <hr>
    <div class="form-inputs-section vasforms" *ngIf="section == 0">
      <form [formGroup]="form" autocomplete="off" class="sms-full-width form-box-panel">
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">Proposer's Name in Full:</span>
              <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ නම සම්පුර්ණයෙන්ම:</span>
              <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் பெயர் முழுமையாக:</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
                     formControlName="fullName" id="fullName" matInput>
              <div *ngIf="submitted && f.fullName.errors" class="inputError">
                <div *ngIf="f.fullName.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
                <div
                  *ngIf="f.fullName.errors.pattern">{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">NIC No :/ Business Registration No:</span>
              <span *ngIf="activeLang === 'si'">ජාතික හැඳුනුම්පත්/ ව්‍යාපාර ලියාපදිංචි අංකය:</span>
              <span *ngIf="activeLang === 'ta'">என்ஐசி/ வணிக பதிவு எண்</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="nicOrBusNo" [ngClass]="{ 'is-invalid': submitted && f.nicOrBusNo.errors }"
                     formControlName="nicOrBusNo" id="nicOrBusNo" matInput>
              <div *ngIf="submitted && f.nicOrBusNo.errors" class="inputError">
                <span *ngIf="activeLang === 'en'">NIC/ Business Registration Number is required!.</span>
                <span *ngIf="activeLang === 'si'">ජාතික හැඳුනුම්පත්/ ව්‍යාපාර ලියාපදිංචි අංකය අවශ්‍යයි!</span>
                <span *ngIf="activeLang === 'ta'">என்ஐசி/ வணிக பதிவு எண் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">Business of Proposer: (If more than one, please Name all)</span>
              <span *ngIf="activeLang === 'si'">යෝජකයාගේ ව්‍යාපාරය: (එකකට වඩා වැඩි නම් කරුණාකර සියල්ල නම් කරන්න)</span>
              <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் வணிகம்: (ஒன்றுக்கு மேற்பட்டவை இருந்தால், தயவுசெய்து அனைவருக்கும் பெயரிடுங்கள்)</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <textarea [(ngModel)]="proposer" [ngClass]="{ 'is-invalid': submitted && f.proposer.errors }"
                        formControlName="proposer" id="proposer" matInput></textarea>
              <div *ngIf="submitted && f.proposer.errors" class="inputError">
                <span *ngIf="activeLang === 'en'">Business of Proposer is required!.</span>
                <span *ngIf="activeLang === 'si'">යෝජකයාගේ ව්‍යාපාරය අවශ්‍යයි!</span>
                <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் வணிகம் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl ">
              <span
                *ngIf="activeLang === 'en'">Address of the premises in respect of which this Insurance is required</span>
              <span *ngIf="activeLang === 'si'">මෙම රක්‍ෂණය අවශ්‍ය වන පරිශ්‍රයේ ලිපිනය</span>
              <span *ngIf="activeLang === 'ta'">இந்த காப்பீடு தேவைப்படும் வளாகத்தின் முகவரி</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
                  <textarea readonly [(ngModel)]="addressPremises"
                            [ngClass]="{ 'is-invalid': submitted && f.addressPremises.errors }"
                            formControlName="addressPremises" id="addressPremises" matInput></textarea>
              <div *ngIf="submitted && f.addressPremises.errors" class="inputError">
                <span *ngIf="activeLang === 'en'">Address of the premises is required!.</span>
                <span *ngIf="activeLang === 'si'">පරිශ්රයේ ලිපිනය අවශ්යයි !.</span>
                <span *ngIf="activeLang === 'ta'">வளாகத்தின் முகவரி தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-12">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">Occupation details:</span>
              <span *ngIf="activeLang === 'si'">රැකියා විස්තර:</span>
              <span *ngIf="activeLang === 'ta'">தொழில் விவரங்கள்:</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <mat-select (openedChange)="clickDropdown()" formControlName="occupation"
                          [(ngModel)]="occupation"
                          [ngClass]="{ 'is-invalid': submitted && f.occupation.errors }">
                <mat-option *ngFor="let occ of occupationList" [value]="occ.id">
                  {{occ.description | translate}}
                </mat-option>
              </mat-select>
              <div *ngIf="submitted && f.buildingAmount.errors" class="inputError">
                <span *ngIf="activeLang === 'en'">Occupation details are required!.</span>
                <span *ngIf="activeLang === 'si'">රැකියා විස්තර අවශ්‍යයි !.</span>
                <span *ngIf="activeLang === 'ta'">தொழில் விவரங்கள் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>
        <h5>
          <span *ngIf="activeLang === 'en'">Amount to be insured</span>
          <span *ngIf="activeLang === 'si'">රක්‍ෂණය කළ යුතු මුදල</span>
          <span *ngIf="activeLang === 'ta'">காப்பீடு செய்ய வேண்டிய தொகை</span>
        </h5>

        <div class="row flexrow">

          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl">

              <span *ngIf="activeLang === 'en'">Building(LKR):</span>
              <span *ngIf="activeLang === 'si'">ගොඩනැගිල්ල(රුපියල්):</span>
              <span *ngIf="activeLang === 'ta'">கட்டிடம்(ரூ):</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <input [(ngModel)]="buildingAmount" type="number"
                     [ngClass]="{ 'is-invalid': submitted && f.buildingAmount.errors }"
                     formControlName="buildingAmount" id="buildingAmount" matInput>

              <div *ngIf="submitted && f.buildingAmount.errors" class="inputError">
                <span *ngIf="activeLang === 'en'">This field is required!.</span>
                <span *ngIf="activeLang === 'si'">මෙම ක්ෂේත්රය අවශ්යයි!.</span>
                <span *ngIf="activeLang === 'ta'">இந்த புலம் தேவை !.</span>
              </div>
            </mat-form-field>

          </div>

          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl ">

              <span *ngIf="activeLang === 'en'">Contents:</span>
              <span *ngIf="activeLang === 'si'">අන්තර්ගතය:</span>
              <span *ngIf="activeLang === 'ta'">உள்ளடக்கங்கள்:</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <mat-select (openedChange)="clickDropdown()" formControlName="buildingContents"
                          [(ngModel)]="buildingContents"
                          [ngClass]="{ 'is-invalid': submitted && f.buildingContents.errors }">
                <mat-option *ngFor="let content of buildingContentList" [value]="content.id">
                  {{content.description | translate}}
                </mat-option>
              </mat-select>
              <div *ngIf="submitted && f.buildingContents.errors" class="inputError">
                <span *ngIf="activeLang === 'en'">This field is required!.</span>
                <span *ngIf="activeLang === 'si'">මෙම ක්ෂේත්රය අවශ්යයි!.</span>
                <span *ngIf="activeLang === 'ta'">இந்த புலம் தேவை !.</span>
              </div>
            </mat-form-field>
          </div>
        </div>
        <h5>
          <span *ngIf="activeLang === 'en'">Has there been any damage to your property during last 5 Years?</span>
          <span *ngIf="activeLang === 'si'">පසුගිය වසර 5 තුළ ඔබේ දේපල වලට හානි සිදුවී තිබේද?</span>
          <span *ngIf="activeLang === 'ta'">கடந்த 5 ஆண்டுகளில் உங்கள் சொத்துக்களுக்கு ஏதேனும் சேதம் ஏற்பட்டதா?</span>
          <sup style="color: red"> *</sup>
        </h5>
        <div class="row flexrow">
          <div class="col-12">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button value="1" (click)="selectAnyDamage(true)"
                                [checked]="isSelectDamage1">
                <span *ngIf="activeLang === 'en'">Yes</span>
                <span *ngIf="activeLang === 'si'">ඔව්</span>
                <span *ngIf="activeLang === 'ta'">ஆம்</span>
              </mat-radio-button>
              <mat-radio-button value="2" (click)="selectAnyDamage(false)"
                                [checked]="isSelectDamage2">
                <span *ngIf="activeLang === 'en'">No</span>
                <span *ngIf="activeLang === 'si'">නැත</span>
                <span *ngIf="activeLang === 'ta'">இல்லை</span>
              </mat-radio-button>

              <div *ngIf="invalidRadioOption" class="inputError">
                <span *ngIf="activeLang === 'en'">This section is required!.</span>
                <span *ngIf="activeLang === 'si'">මෙම කොටස අවශ්යයි!.</span>
                <span *ngIf="activeLang === 'ta'">இந்த பிரிவு தேவை!.</span>
              </div>
            </mat-radio-group>

          </div>
        </div>
        <div class="row flexrow" [hidden]="!isSelectDamage1">
          <div class="col-lg-6 col-md-12 col-xs-12">
            <label class="mtlbl ">

              <span *ngIf="activeLang === 'en'">If yes, what is the perils / Reason</span>
              <span *ngIf="activeLang === 'si'">ඔව් නම්, අන්තරාය / හේතුව කුමක්ද?</span>
              <span *ngIf="activeLang === 'ta'">ஆம் என்றால், ஆபத்துகள் / காரணம் என்ன</span>
            </label>
            <mat-form-field appearance="outline" class="inputbox">
              <textarea [(ngModel)]="damageReason"
                        formControlName="damageReason" id="damageReason" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="declaration">
          <p *ngIf="activeLang === 'en'">I hereby warrant the truth of the above statements, and I declare thatI have
            withheld no information
            whatsoever which might tend in any way to increase the risk of Sri Lanka Insurance. I agree that, this
            proposal shall be the basis of the contract of between me and Sri Lanka Insurance, and the Liability of Sri
            Lanka Insurance shall not commence until the proposal is accepted and premium paid in full.</p>

          <p *ngIf="activeLang === 'si'">ඉහත ප්‍රකාශ වල සත්‍යතාව මම මෙයින් සහතික කරන අතර, ශ්‍රී ලංකා රක්‍ෂණ අවදානම වැඩි
            කිරීමේ කිසිදු ආකාරයකින් නැඹුරු විය හැකි කිසිදු තොරතුරක් මා විසින් රඳවා නොගත් බව ප්‍රකාශ කරමි.
            මෙම යෝජනාව මා සහ ශ්‍රී ලංකා රක්‍ෂණ සමාගම අතර ගිවිසුමේ පදනම විය යුතු අතර යෝජනාව පිළිගෙන වාරිකය සම්පූර්ණයෙන්
            ගෙවන තුරු ශ්‍රී ලංකා රක්‍ෂණයේ වගකීම ආරම්භ නොවිය යුතු බවට මා මෙයින් එකග වෙමි</p>

          <p *ngIf="activeLang === 'ta'">மேற்கண்ட அறிக்கைகளின் உண்மைக்கு நான் உத்தரவாதம் அளிக்கிறேன், நான் எந்த
            தகவலையும் தடுத்து வைக்கவில்லை என்று அறிவிக்கிறேன்
            ஸ்ரீலங்கா இன்சூரன்ஸ் அபாயத்தை அதிகரிக்க எந்த வகையிலும் முனையும். நான் ஒப்புக்கொள்கிறேன், இது
            இந்த முன்மொழிவு எனக்கும் ஸ்ரீலங்கா இன்சூரன்ஸ் மற்றும் ஸ்ரீயின் பொறுப்புக்கும் இடையிலான ஒப்பந்தத்தின்
            அடிப்படையாகும்
            முன்மொழிவு ஏற்றுக்கொள்ளப்பட்டு பிரீமியம் முழுமையாக செலுத்தப்படும் வரை லங்கா காப்பீடு தொடங்கப்படாது. </p>

          <div class="row flexrow">
            <div class="col-lg-6 col-md-12 col-xs-12">
              <label class="mtlbl">

                <span *ngIf="activeLang === 'en'">Date</span>
                <span *ngIf="activeLang === 'si'">දිනය</span>
                <span *ngIf="activeLang === 'ta'">தேதி</span>
              </label>
              <mat-form-field appearance="outline" class="inputbox">
                <input type="date" [(ngModel)]="applicationDate" readonly
                       [ngClass]="{ 'is-invalid': submitted && f.applicationDate.errors }"
                       formControlName="applicationDate" id="applicationDate" matInput>

              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">
              <label class="mtlbl ">

                <span *ngIf="activeLang === 'en'">Signature of the proposer</span>
                <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන</span>
                <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம்</span>
              </label><sup style="color: red"> *</sup>
              <mat-form-field appearance="outline" class="inputbox">
                <input [(ngModel)]="proposerSignature"
                       [ngClass]="{ 'is-invalid': submitted && f.proposerSignature.errors }"
                       formControlName="proposerSignature" id="proposerSignature" matInput>
                <div *ngIf="submitted && f.proposerSignature.errors" class="inputError">

                  <span *ngIf="activeLang === 'en'">Signature of the proposer is required!.</span>
                  <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන අවශ්‍යයි!</span>
                  <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம் தேவை !.</span>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="form-group btn-sections b-0 p-0 text-right">
          <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="nextPage()"
                  type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


<div *ngIf="section == 1" class="page-contents">
  <div class="packge-form">
    <div class="scheme-doc">
      <div class="scheme-details">
        <p>The policy covers both Building and the Contents (Stock in trade, electrical items, furniture etc.).
          The perils covered are as follows.<br/><br/>

          - Fire & Lightning<br/>
          - Explosion<br/>
          - Aircraft Damage<br/>
          - Impact Damage<br/>
          - Cyclone/Storm/Tempest<br/>
          - Flood<br/>
          - Earthquake (including Fire & Shock)<br/>
          - Bursting & overflowing of water tanks<br/>
          - Spontaneous combustion<br/>
          - Electrical inclusion clause (Maximum limit Up to Rs.100,000/- for building wiring only)
        </p>

        <div class="d-tables">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Policy</th>
              <th scope="col">Rate Range</th>
              <th scope="col">Remarks</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>Business Premises</th>
              <td>0.05% - 0.25%
              </td>
              <td>Rates depend on type of the risk, claims experience & etc. If there is any doubt,
                please contact SLIC Head office
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-tables">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Occupation</th>
              <th scope="col">Rate Range</th>
              <th scope="col">Excess applicable on All claims – subject to revision of excesses based
                on claim
                experience
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>Ware house</th>
              <td>0.1%</td>
              <td>10% with a minimum of Rs.15,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>Stores</th>
              <td>0.1%</td>
              <td>10% with a minimum of Rs.15,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>Show rooms</th>
              <td>0.05%</td>
              <td>10% with a minimum of Rs.10,000/- on e.e.l
              </td>
            </tr>
            <tr>
              <th>Wholesale /Retail Shop</th>
              <td>0.1%</td>
              <td>10% with a minimum of Rs.10,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>Office</th>
              <td>0.05%</td>
              <td>10% with a minimum of Rs.5,000/- on e.e.l</td>
            </tr>
            <tr>
              <th>Filling stations</th>
              <td>0.25%</td>
              <td>15% with a minimum of Rs.15,000/- on e.e.l
              </td>
            </tr>
            <tr>
              <th>Others</th>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-tables">
          <h4>Business premises premium calculation</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Perils covered</th>
              <th scope="col">Fire & allied perils</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Sum insured</td>
              <td>Building – Rs.3,000,000/- <br/>
                Contents – Rs.2,000,000/- <br/>
                Total - Rs.5,000,000/-
              </td>
            </tr>
            </tbody>
          </table>
          <br>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">New Business</th>
              <th scope="col">Show room</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Sum insured</td>
              <td>Rs.5,000,000.00</td>
            </tr>
            </tbody>
          </table>
          <br>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th></th>
              <th scope="col">Premium Rate</th>
              <th scope="col">Premium Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Basic Premium</td>
              <td>0.050%</td>
              <td>Rs.2,500.00</td>
            </tr>
            <tr>
              <td>Total Premium (W/O Tax)</td>
              <td></td>
              <td>Rs.2,500.00</td>
            </tr>
            <tr>
              <td>Admin Fee</td>
              <td>0.35%</td>
              <td>Rs.8.75</td>
            </tr>
            <tr>
              <td>Policy Fee</td>
              <td></td>
              <td>Rs.500.00</td>
            </tr>
            <tr>
              <td>Tax</td>
              <td>8%</td>
              <td>Rs.240.00</td>
            </tr>
            <tr>
              <th>Total Premium</th>
              <td></td>
              <th>Rs.3249.45</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="conditions">
          <h4>Conditions</h4>
          <ul>
            <li>Terms and conditions might vary if adverse risk features were to be identified during a
              survey or otherwise
            </li>
            <li>Terms and conditions will be revised according to the past claim experience</li>
            <li>Submission of values separately on generators, elevators, escalators to be included under
              the total sum insured of said items with their Serial No(s), Model No(s), year of
              manufacture/purchase before confirmation of cover
            </li>
            <li>Taxes applicable will be revised based on revision of government tax policy</li>
          </ul>
        </div>
      </div>
      <div class="form-group btn-sections b-0 p-0 text-right">
        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    float: left;
    margin-right: 10px;
    border-radius: 5px;" (click)="backPage()"
                type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}
        </button>


        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    margin-right: 10px;
    border-radius: 5px;" (click)="submitForm('DRAFT')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.DRAFT' | translate}}</button>

        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="submitForm('PENDING')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SUBMIT' | translate}}</button>
      </div>

    </div>
  </div>
</div>


<button hidden data-target="#successModal" data-toggle="modal" #openSuccessModal></button>
<button hidden data-target="#draftModal" data-toggle="modal" #openDraftModal></button>

<!-- Success Modal -->
<div class="modal fade npa-model" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_save'|translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Draft Modal -->
<div class="modal fade npa-model" id="draftModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_draft_save' | translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
