import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../service/product.service';
import {MainUrlService} from '../service/main-url.service';
import {CartService} from '../service/cart.service';
import {Global} from '../service/global';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {SystemSettingsService} from '../service/system-settings.service';
import {InteractionService} from '../service/interaction.service';
import {UserRoleService} from '../service/user-role.service';
import {CommonService} from '../common/common.service';
import Swal from 'sweetalert2';
import {CoreService} from '../service/core.service';
import {TransportDetailsDTO} from '../dto/TransportDetailsDTO';
import {UserTokenDTO} from '../dto/userTokenDTO';
import {DeliveryDTO} from '../dto/deliveryDTO';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {RiyawiruService} from '../service/riyawiru.service';

@Component({
  selector: 'app-product-view-spot',
  templateUrl: './product-view-spot.component.html',
  styleUrls: ['./product-view-spot.component.css']
})
export class ProductViewSpotComponent implements OnInit {

  showReviews = false;

  specAttributeRankOrder = [];
  specAttributes = [];
  filstatus: boolean = false;

  slide1Config = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  spinner: number = 0;
  prodId: any;
  reviewList: any;
  description: any;
  unitPrice: any;
  farmerUsername: any;
  discount: any;
  location: any;
  listedDate: any;
  bestBuyDate: any;
  sellInSmallerQuantities: boolean = false;
  name: any;
  availableTotalQty: any;
  img1: any;
  img2: any;
  img3: any;
  img4: any;
  selectQty: any = 1;
  selectQTYMIN: any = 1;
  stockAvailable: any;
  stock: any;
  categoryNameEN: any;
  categoryNameSI: any;
  categoryNameTM: any;
  categoryId: any;
  subCategoryNameEN: any;
  subCategoryNameSI: any;
  subCategoryNameTM: any;
  cartItemCount: any;
  typeId: any;
  typeName: any;
  selectQtyPanel = false;
  spotBtn = false;
  forwardBtn = false;
  hideReviews = false;
  bidEndDate: any;
  CURRENCY_CODE: any;
  reportForm: FormGroup;
  commonImg: any;
  unitType: any;
  SpecAll: any;
  subCatId: number;
  attributeRanks: any;
  profileId: any;
  SKU: any;
  getProductView: any;
  addToCart: boolean;
  addToCartAlert: boolean;

  sku: any;

  spinner2: number = 1;
  spinner0: number = 1;

  allCategory: any;
  allNewArrivalCategory: any;
  load = 0;
  avgRating: any;
  tenantValue: any;
  farmerName: any;
  farmerLocation: any;
  Type: string;
  metadataList: any = [];
  CHECKOUT_ENABLE: boolean = false;
  rate: any;
  isDisabledSelectQty: boolean = false;
  similarProductList: any;
  station: any;
  seller: any;
  sellerUsername: any;
  sellerMobile: any;
  sellerLocation: any;
  isTrain = false;
  isSeller = false;
  isBuyer = false;
  imgUrl: any = 'assets/avatar.jpg';
  prdName: any;
  quantity: any;
  prdImg: any;
  userTokenDTO = new UserTokenDTO;
  address1: any;
  address2: any;
  city: any;
  addressArray = [];
  selectedOption = 0;
  dto = new DeliveryDTO;
  delivery_price: any;
  isEmptyReviewList: boolean = false;
  delivery_priceFixed: any;
  delivery_desc: any;
  activeLang: string = 'en';
  citySin: any;
  cityTam: any;

  transportList: any[];
  railwayList: any;
  transportID: any;
  dropStation: any;
  prdTransportList = [];
  isSellerFixed = false;
  selectedVehicleId: any;
  riyawiruSelectedVehicleType = [];
  sellerName: any;
  deliveryOptList: any;
  riyawiruVehicleTypes: any;
  expectationPrice: any;
  isRequiredExpectation: boolean = false;
  isPickVehicle: boolean = false;
  economicCenter: any = null;

  @ViewChild('transportModal', {static: false}) transportModal;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;
  @ViewChild('transportOptions') transportOptions;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private cookie: CookieService,
              private route: ActivatedRoute,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cartService: CartService,
              private router: Router,
              private global: Global,
              private riyawiruService: RiyawiruService,
              private toastr: ToastrService,
              private titleService: Title,
              private settingService: SystemSettingsService,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,
              private interaction: InteractionService,
              private formBuilder: FormBuilder,
              private commonService: CommonService,
              private coreService: CoreService,
              private validationHandlerService: ValidationHandlerService
  ) {
    this.activeLang = sessionStorage.getItem('LANG');

  }

  ngOnInit() {

    this.reportForm = new FormGroup({
      'commentReport': new FormControl(null, Validators.required)
    });

    this.route.queryParams.subscribe(params => {
      this.prodId = params['id'];
      this.typeId = params['typeId'];
      this.typeName = params['typeName'];
      this.tenantValue = this.route.snapshot.paramMap.get('tenant_id');

      this.getSpecByProductId(this.prodId);
      this.fetchAllAdditionalMetaData(this.prodId);

      if ('SPOT' === this.typeName) {
        this.selectQtyPanel = true;
        this.spotBtn = true;
        this.forwardBtn = false;
        this.hideReviews = true;
        this.commonProductDetails();
        this.Type = 'SPOT';
      }

      this.fetchAll();
    });


    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.setCategoryNames(
          this.categoryNameEN,
          this.categoryNameSI,
          this.categoryNameTM,
          this.subCategoryNameEN,
          this.subCategoryNameSI,
          this.subCategoryNameTM);
      }
    });

  }

  setCategoryNames(catEN, catSI, catTM, subCatEn, SubCatSI, SubCatTM) {
    this.categoryNameEN = catEN;
    this.categoryNameSI = catSI;
    this.categoryNameTM = catTM;
    this.subCategoryNameEN = subCatEn;
    this.subCategoryNameSI = SubCatSI;
    this.subCategoryNameTM = SubCatTM;
  }


  commonProductDetails() {
    this.productService.fetchSpotProduct(this.prodId, this.tenantValue).subscribe(result => {
      this.CURRENCY_CODE = this.global.CURRENCY_CODE;
      this.settingService.fetchAll().subscribe(fetchAll => {
        let tempArray = [];

        tempArray.push(fetchAll);

        for (let settings of tempArray) {
          for (let setting of settings) {

            switch (setting.code) {

              case 'BASE_COLOUR':
                this.global.BASE_COLOUR = setting.value;

                if (localStorage.getItem('--primary-color') !== this.global.BASE_COLOUR) {
                  localStorage.setItem('--primary-color', this.global.BASE_COLOUR);
                  document.documentElement.style.setProperty('--primary-color',
                    this.global.BASE_COLOUR);
                } else {
                  document.documentElement.style.setProperty('--primary-color',
                    localStorage.getItem('--primary-color'));
                }

                break;

              case 'BASE_LANGUAGE':
                this.global.BASE_LANGUAGE = setting.value;
                localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);

                if (localStorage.getItem('BASE_LANGUAGE') !== this.global.BASE_LANGUAGE) {
                  localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);
                } else {
                  this.global.BASE_LANGUAGE = localStorage.getItem('BASE_LANGUAGE');
                }

                break;

              case 'BASE_URL':
                this.global.BASE_URL = setting.value;
                localStorage.setItem('BASE_URL', this.global.BASE_URL);

                if (localStorage.getItem('BASE_URL') !== this.global.BASE_URL) {
                  localStorage.setItem('BASE_URL', this.global.BASE_URL);
                } else {
                  this.global.BASE_URL = localStorage.getItem('BASE_URL');
                }
                break;

              case 'CURRENCY_CODE':
                this.global.CURRENCY_CODE = setting.value;
                this.CURRENCY_CODE = setting.value;
                localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);

                if (localStorage.getItem('CURRENCY_CODE') !== this.global.CURRENCY_CODE) {
                  localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);
                } else {
                  this.global.CURRENCY_CODE = localStorage.getItem('CURRENCY_CODE');
                }
                break;

              case 'LOGO_WHITE':
                this.global.LOGO_WHITE = setting.value;
                localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);

                if (localStorage.getItem('LOGO_WHITE') !== this.global.LOGO_WHITE) {
                  localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);
                } else {
                  this.global.LOGO_WHITE = localStorage.getItem('LOGO_WHITE');
                }
                break;

              case 'MAINTENANCE_MODE':
                this.global.MAINTENANCE_MODE = setting.value;
                break;

              case 'CHECKOUT_ENABLE':
                this.CHECKOUT_ENABLE = setting.value === 'true';
                break;

            }
          }

        }
      });
      this.stockAvailable = result.stockAvailable;
      this.leadLabel = result.leadLabel;
      this.economicCenter = result.economicCenter;
      this.addToCart = false;
      this.categoryNameEN = result.subCategory.mainCategory.commonIndex.description;
      this.categoryNameSI = result.subCategory.mainCategory.commonIndex.sinhala;
      this.categoryNameTM = result.subCategory.mainCategory.commonIndex.tamil;
      this.categoryId = result.subCategory.mainCategory.commonIndex.id;
      this.subCategoryNameEN = result.subCategory.commonIndex.description;
      this.subCategoryNameSI = result.subCategory.commonIndex.sinhala;
      this.subCategoryNameTM = result.subCategory.commonIndex.tamil;
      this.subCatId = result.subCategory.code;
      this.name = result.name;
      this.availableTotalQty = result['spotList'][0].stockAvailable;
      this.stock = result['spotList'][0].stock;
      this.avgRating = result.avgRating;
      this.unitType = result.unit.unit;
      this.discount = result.discount;
      this.location = result.address;
      this.addressArray = this.location.split(',');
      this.city = this.addressArray[this.addressArray.length - 1];
      this.citySin = result.citySin;
      this.cityTam = result.cityTam;
      this.listedDate = this.commonService.convertDate(result['spotList'][0].created);
      this.bestBuyDate = this.commonService.convertDate(result['spotList'][0].expireDate);
      this.sellInSmallerQuantities = result['spotList'][0].sellInSmallerQuantities;
      if (this.sellInSmallerQuantities) {
        this.isDisabledSelectQty = true;
        this.selectQty = 0;
        this.selectQTYMIN = this.stock;
        this.selectQty = this.selectQty + this.stock;
      }
      this.unitPrice = result['spotList'][0].unitPrice;
      this.SKU = result['spotList'][0].sku;
      this.fetchAllReviewsBySku(this.SKU);
      this.getProductViewsCount(result['spotList'][0].sku);
      this.getSpecAttributeRankOrder(this.subCatId);
      this.fetchUserDetails(result.listedBy);
      this.fetchRatings(result.listedBy);
      this.farmerUsername = result.listedBy;
      if (result['spotList'][0].productImages.length > 0) {
        this.img1 = this.mainUrl.MAIN_URL_FILE + result['spotList'][0].productImages[0].image + '/?X-TenantID=' +
          this.tenantValue;
        this.commonImg = this.img1;
      }

      this.description = result['description'];
      if (result['spotList'][0].productImages.length >= 2) {
        this.img2 = this.mainUrl.MAIN_URL_FILE + result['spotList'][0].productImages[1].image + '/?X-TenantID=' +
          this.tenantValue;
      }
      if (result['spotList'][0].productImages.length >= 3) {
        this.img3 = this.mainUrl.MAIN_URL_FILE + result['spotList'][0].productImages[2].image + '/?X-TenantID=' +
          this.tenantValue;
      }

      if (result['spotList'][0].productImages.length === 4) {
        this.img4 = this.mainUrl.MAIN_URL_FILE + result['spotList'][0].productImages[3].image + '/?X-TenantID=' +
          this.tenantValue;
      }
      this.deliveryMethod();
      this.getRiyawiruVehicleTypes();
      this.loadDeliveryTransports();

      Swal.close();
    });

  }

  deliveryMethod() {
    this.coreService.getDeliveryMethod().subscribe(
      (data) => {
        this.transportList = data;
      }, () => {
        Swal.close();
      }
    );
  }

  getRiyawiruVehicleTypes() {
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.fetchAllVehicleTypes(createToken['access_token']).subscribe(result => {
        this.riyawiruVehicleTypes = result;
      });
    });
  }

  fetchAllReviewsBySku(sku) {
    this.productService.fetchAllReviewBySku(sku).subscribe(result => {
      this.isEmptyReviewList = Object.keys(result).length > 0;
      this.reviewList = result;
    });
  }


  fetchUserDetails(username) {
    this.userRoleService.fetchUserDetails(username).subscribe(userDetails => {
      if (userDetails) {
        this.farmerName = userDetails['name'];
        this.farmerLocation = userDetails['address'];
      }
    });
  }

  fetchRatings(username) {
    this.coreService.fetchUserRatings(username).subscribe(rate => {
      if (rate) {
        this.rate = rate;
      }
    });
  }

  clickReviews() {
    this.showReviews = !this.showReviews;
  }

  clickDesc() {
    this.showReviews = false;
  }

  addCart(purchase_type) {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      if (this.availableTotalQty > 0) {
        let cartID = localStorage.getItem('cartID');
        if (cartID !== null) {
          this.processing();
          this.common(cartID,purchase_type);
        } else {
          this.processing();
          if (this.selectQty !== undefined) {
            let UUID = this.create_UUID().toString();
            localStorage.setItem('cartID', UUID);
            this.common(UUID,purchase_type);
          }
        }
      }
    } else {
      localStorage.setItem('isLogged', 'False');
      this.interaction.popUpLoginModal('false');
    }


  }

  common(value,type) {

    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

        if (this.SpecAll === 0) {

          if (this.selectQty > 0 && this.selectQty !== null) {

            this.cartService.findCartByUsername(whoAmIDetails['email'],
              this.tenantValue).subscribe(findCartByUsernameRes => {

              this.commonAddToCart(this.SKU, findCartByUsernameRes.id,type);

            });

          } else {
            Swal.close();
          }

        } else {
          if (this.specAttributes.length > 0) {
            this.productService.checkSpecAvailability(this.specAttributes, this.profileId,
              this.prodId, this.tenantValue).subscribe(checkSpecAvailability => {
              if (checkSpecAvailability.success) {

                if (this.selectQty > 0 && this.selectQty !== null) {

                  this.cartService.findCartByUsername(whoAmIDetails['email'],
                    this.tenantValue).subscribe(findCartByUsernameRes => {

                    this.commonAddToCart(checkSpecAvailability.message, findCartByUsernameRes.id,type);

                  });

                } else {
                  Swal.close();
                }

              } else {
                Swal.close();
              }

            });
          } else {
            Swal.close();
          }

        }


      });
    } else {

      if (this.SpecAll === 0) {
        if ((this.selectQty * this._tempQty) > 0 && this.selectQty !== null) {

          this.cartService.findCartByOwner(value, this.tenantValue).subscribe(findCartByOwner => {

            this.commonAddToCart(this.SKU, findCartByOwner.id,type);

          });

        } else {
          Swal.close();
        }
      } else {
        if (this.specAttributes.length > 0) {
          this.productService.checkSpecAvailability(this.specAttributes, this.profileId, this.prodId,
            this.tenantValue).subscribe(checkSpecAvailability => {

            if (checkSpecAvailability.success) {

              if (this.selectQty > 0 && this.selectQty !== null) {

                this.cartService.findCartByOwner(value, this.tenantValue).subscribe(findCartByOwner => {

                  this.commonAddToCart(checkSpecAvailability.message, findCartByOwner.id,type);

                });

              } else {
                Swal.close();
              }
            } else {
              Swal.close();
            }

          });
        } else {
          Swal.close();
        }

      }
    }

  }

  commonAddToCart(productSku, id,type) {

    let qty;

    if (!this.sellInSmallerQuantities) {
      qty = this.availableTotalQty;
    } else {
      qty = this.selectQty * this._tempQty;
    }

    let body = {
      'productSku': productSku,
      'quantity': qty
    };
    this.cartService.addCart(id, body).subscribe(addCart => {
      if (addCart.success) {
        Swal.close();
        this.checkCart();
        this.interactionService.callBackHeader('True');

        if (type === 'ADD_TO_CART') {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.itemAddedMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: false,
            timer: 1500
          });
        }else {
          this.encryptInfoTransport_id = encodeURIComponent(JSON.stringify(this.prdTransportList));
          this.encryptInfoRiyawiruList = encodeURIComponent(JSON.stringify(this.riyawiruSelectedVehicleType));

          this.router.navigate(['/checkout/' + this.tenantValue],
            {
              queryParams: {
                transport_id: this.encryptInfoTransport_id,
                riyawiruList: this.encryptInfoRiyawiruList
              }
            });
        }


      } else {
        Swal.close();
      }
    }, () => {
      Swal.close();
    });
  }

  create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  processing() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  loading() {
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  checkCart() {
    let cartID = localStorage.getItem('cartID');
    if (cartID !== null) {
      this.temp(cartID);
    } else {
      localStorage.setItem('cartItemCount', '0');
    }
  }

  temp(value) {
    this.cartService.showCartByOwner(value, this.tenantValue).subscribe(result => {
      this.cartItemCount = Object.keys(result).length;
      localStorage.setItem('cartItemCount', this.cartItemCount);
    });
  }

  getProductViewsCount(sku) {
    this.productService.addProductView(sku, this.tenantValue);
    this.productService.getProductViews(sku, this.tenantValue).subscribe(getProductViews => {
      this.getProductView = getProductViews;
    });
  }


  getSpecByProductId(proId) {
    this.productService.getSpecsByProductId(proId, this.tenantValue).subscribe(specList => {
      if (specList) {
        if (Object.keys(specList).length > 0) {
          for (let spec of specList) {
            if (spec.specCode === 'XXX') {
              this.SpecAll = 0;
              return;
            }
          }
          this.SpecAll = specList;

        } else {
          this.SpecAll = 0;
        }
      }
    });
  }

  getSpecAttributeRankOrder(code) {
    this.productService.getSpecsOrderBySubCategory(code, this.tenantValue).subscribe(result => {
      if (result) {
        this.attributeRanks = result;
        for (let loop of result) {
          this.profileId = loop.profile.code;
          this.specAttributeRankOrder.push({
            'specId': loop.spec.code,
            'specOrder': loop.specOrder,
            'attributeId': ''
          });
        }
      }
    });
  }

  fetchAll() {
    this.productService.getActiveProfile().subscribe(profileCode => {
      if (profileCode) {
        this.productService.getAllMainCategoryByProfileId
        (profileCode.code).subscribe(getAllMainCategoryByProfileId => {
          if (getAllMainCategoryByProfileId) {
            this.spinner0 = 0;
            this.allCategory = getAllMainCategoryByProfileId;
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;

            this.productService.fetchAllNewArrivalSpotProducts(35, profileCode.code,
              this.tenantValue).subscribe(fetchAllNewArrivalSpotProducts => {
              if (fetchAllNewArrivalSpotProducts) {
                this.spinner2 = 0;
                this.allNewArrivalCategory = fetchAllNewArrivalSpotProducts;
              }
            });
          }
        });

        this.loadSimilarProducts(profileCode.code);
      }
    });
  }

  fetchAllAdditionalMetaData(prodId) {
    this.productService.fetchAllAdditionalMedataByProductId(prodId, this.tenantValue).subscribe(metaDataList => {
      if (metaDataList) {

        if (Object.keys(metaDataList).length > 0) {
          for (let loop of metaDataList) {

            if (loop.metaDataId.visible === true && loop.value !== '' && loop.value !== null) {
              this.metadataList.push(loop);
            }
          }
        }
      }
    });
  }

  _tempQty = 1;

  qtyUp() {
    if ((this.selectQty * this._tempQty) > 0 && (this.selectQty * this._tempQty) < this.availableTotalQty) {
      if (this.sellInSmallerQuantities) {
        this._tempQty = this._tempQty + 1;
      } else {
        if (this._tempQty > 0) {
          this._tempQty = this._tempQty - 1;
        }
      }
    }
  }

  qtyDown() {
    if (this.sellInSmallerQuantities) {
      if ((this.selectQty * this._tempQty) > this.stock) {
        if (this._tempQty > 1) {
          this._tempQty = this._tempQty - 1;
        }
      } else {
        this._tempQty = 1;
      }
    } else {
      if (this._tempQty > 1) {
        this._tempQty = this._tempQty - 1;
      } else {
        this._tempQty = 1;
      }
    }

  }

  editQty(qty) {
    if (qty < 0 || qty === 0) {
      this.selectQty = 1;
    }
  }


  filterOn() {
    this.filstatus = !this.filstatus;
  }

  encryptInfoTransport_id: any;
  encryptInfoRiyawiruList: any;

  buyNow(type) {

    if (this.clickedRiyawiruOption && this.expectationPrice === undefined) {
      return;
    }

    if (this.prdTransportList.length === 0) {
      return;
    }
    if (this.prdTransportList.length > 0) {
      if (this.availableTotalQty > 0) {
        let isLoggedIn = localStorage.getItem('isLogged');
        if (isLoggedIn === 'True') {
          this.addCart(type);
        } else {
          localStorage.setItem('isLogged', 'False');
          this.interaction.popUpLoginModal('false');
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: this.validationHandlerService.validateDeliveryMethod(sessionStorage.getItem('LANG')),
        showConfirmButton: false,
        timer: 1500
      });
    }

  }

  clickedRiyawiruOption: boolean = false;

  checkVal($event, sku, val) {

    this.clickedRiyawiruOption = val === 1;

    for (let delivery of this.prdTransportList) {
      if (sku === delivery.sku) {
        let index = this.prdTransportList.indexOf(delivery);
        if (index !== -1) {
          this.prdTransportList.splice(index, 1);
        }
      }
    }

    for (let delivery of this.riyawiruSelectedVehicleType) {
      if (sku === delivery.sku) {
        let index = this.riyawiruSelectedVehicleType.indexOf(delivery);
        if (index !== -1) {
          this.riyawiruSelectedVehicleType.splice(index, 1);
        }
      }
    }

    this.prdTransportList.push({
      'sku': sku,
      'trnId': val
    });

    this.riyawiruSelectedVehicleType.push({
      'sku': sku,
      'value': 0,
      'paymentMethod': 'Cash On Delivery',
      'vehicleType': null
    });

  }

  setType(type: any) {
    switch (type) {
      case 'SPOT':
        return 'spot';

      case 'FORWARD_BN':
        return 'forward';

      default:
        return 'bid';
    }
  }

  validateMaxQnt(val) {
    if (val > this.availableTotalQty) {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Invalid quantity',
        showConfirmButton: false,
        timer: 1500
      });
      this.selectQty = this.availableTotalQty;
    }
  }

  submitReportForm() {
    if (this.reportForm.invalid) {
      return;
    }
    let comment = this.reportForm.value['commentReport'];
    Swal.fire({
      title: 'Are you sure, do you want to report?',
      text: 'Comment: ' + comment,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value === true) {
        this.reportFunction(comment);
      }
    });
  }

  reportFunction(comment: string) {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
          let body = {
            'reportedBy': whoAmIDetails['email'],
            'productId': this.prodId,
            'type': this.typeName,
            'commentReport': comment,
            'status': 'PENDING'
          };
          this.callReportFunction(body);
        }
      );
    } else {
      let body = {
        'reportedBy': 'GUEST',
        'productId': this.prodId,
        'type': this.typeName,
        'commentReport': comment,
        'status': 'PENDING'
      };
      this.callReportFunction(body);
    }
  }

  callReportFunction(body) {
    this.productService.reportProduct(body, this.tenantValue).subscribe(result => {
        if (result.success) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Reported Successfully',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, (() => {
        Swal.fire({
          position: 'center',
          type: 'warning',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        });
      })
    );
  }

  setMinSelectQty(selectQTYMIN: any) {
    return selectQTYMIN.toString();
  }

  back() {
    this.router.navigate(['/products/' + this.tenantValue], {
      queryParams: {
        $singleViewBack: true
      }
    });
  }

  viewImg(img, i) {
    this.commonImg = img;

    switch (i) {

      case 1 :

        document.getElementById('img' + 1).style.opacity = '1';
        document.getElementById('img' + 1).style.filter = 'unset';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 2 :

        document.getElementById('img' + 2).style.opacity = '1';
        document.getElementById('img' + 2).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 3 :

        document.getElementById('img' + 3).style.opacity = '1';
        document.getElementById('img' + 3).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 4 :

        document.getElementById('img' + 4).style.opacity = '1';
        document.getElementById('img' + 4).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        break;
      default:
        break;

    }

  }


  loadSimilarProducts(profileCode) {
    let body = {
      name: null,
      ratings: null,
      categoryCode: this.categoryId,
      type: [],
      minPrice: null,
      maxPrice: null,
      sortByPrice: null,
      specAttributes: [],
    };
    this.productService
      .fetchAllPagination(body, profileCode, 0, 80)
      .subscribe((productResult) => {
        if (productResult) {
          this.similarProductList = productResult;
        }
      });
  }

  loadTab() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.transportOptions.nativeElement.click();
      this.isBuyer = false;
      this.isSeller = false;
      this.isSellerFixed = false;
      this.isTrain = false;
      this.loadDeliveryTransports();
    } else {
      localStorage.setItem('isLogged', 'False');
      this.interaction.popUpLoginModal('false');
    }

  }

  loadDeliveryTransports() {
    this.isSeller = false;
    this.isSellerFixed = false;
    this.cartService.fetchTransportDetails(this.SKU).subscribe(
      (data: TransportDetailsDTO) => {
        this.sellerMobile = data.sellerMobile;
        this.sellerLocation = data.sellerLocation;
        this.prdImg = data.imgUrl;
        this.sellerUsername = data.seller;
        this.sellerName = data.sellerName;
        this.deliveryOptList = data.deliveryOpt;

        if (data.deliveryOpt !== null) {
          for (let opt of data.deliveryOpt) {
            this.userRoleService.fetchUserDetails(data.seller).subscribe(result => {
              this.seller = result.name;
            });
            if (opt.deliveryOpt === '2') {
              this.isBuyer = true;
            }
            if (opt.deliveryOpt === '3') {
              this.getUser();
              this.isSeller = true;
              this.delivery_price = opt.deliveryCharges;
            }
            if (opt.deliveryOpt === '4') {
              this.isTrain = true;
              this.station = opt.station;
            }
            if (opt.deliveryOpt === '5') {
              this.isSellerFixed = true;
              this.delivery_priceFixed = opt.deliveryCharges;
              this.delivery_desc = opt.deliveryDesc;
            }
          }
        }
      }
    );
  }

  getUser() {
    this.userTokenDTO.userName = this.sellerUsername;
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'assets/avatar.jpg';
      }
    }, () => {
      this.imgUrl = 'assets/avatar.jpg';
    });
  }

  hideReviewName(reviewBy: any) {
    let x = reviewBy.substring(0, 3);
    return x + '***';
  }

  clickRiyaviru(sku: any) {
    this.transportOptionsClose.nativeElement.click();
    this.selectedOption = sku + 1;
  }

  riyawiruModalShow() {
    this.transportModal.nativeElement.click();
  }

  selectVehicleType(id, sku) {
    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.vehicleType = id;
      }
    }
    this.selectedVehicleId = id;
    this.isPickVehicle = false;
    this.selectedId = id;
  }

  selectedItem: any;
  selectedId: any;
  leadLabel: any = null;

  isSelectedItem(item) {
    return this.selectedItem === item;
  }

  sendBidRequest(sku: any, expectationPrice: any) {

    if (this.selectedVehicleId === undefined) {
      this.isPickVehicle = true;
      return;
    }

    this.isPickVehicle = false;

    if (expectationPrice === undefined || expectationPrice === 0) {
      this.isRequiredExpectation = true;
      return;
    }
    this.isRequiredExpectation = false;

    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.price = expectationPrice;
      }
    }
    this.expectationPrice = undefined;
    this.selectedVehicleId = undefined;
    this.closeRiyawiruModal.nativeElement.click();
  }

  checkExpectationPrice(value: any) {
    if (value <= 0) {
      this.expectationPrice = undefined;
    }
  }
}
