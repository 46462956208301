import { Injectable } from "@angular/core";
@Injectable()
export class Global {
  BASE_COLOUR;
  BASE_LANGUAGE;
  BASE_URL;
  CURRENCY_CODE;
  LOGO_BLACK;
  LOGO_WHITE;
  BANNER;
  MAINTENANCE_MODE;
  PAYCORP_CLIENT_ID;
  PRD_ADDR_CNAME;
  PRD_ADDR_CNAME_SI;
  PRD_ADDR_CNAME_TA;
  FOOTER_INTRO;
  FOOTER_INTRO_SI;
  FOOTER_INTRO_TA;
  SLIDER_TITLE;
  SLIDER1;
  SLIDER2;
  SLIDER3;
  SLIDER4;
  MAINTENANCE_MODE_DESCRIPTION;
  DELIVERY_RESTRICTION_MANDATORY;
  DELIVERY_RESTRICTION;
  ACTIVE_PAYMENT_METHOD;
  FACEBOOK_URL;
  TWITTER_URL;
  INSTAGRAM_URL;
  FORWARD_ORDER_ENABLE;
}
