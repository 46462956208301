import {Component, OnInit} from '@angular/core';
import {InteractionService} from '../../../service/interaction.service';

@Component({
  selector: 'app-not-found-vas',
  templateUrl: './not-found-vas.component.html',
  styleUrls: ['./not-found-vas.component.css']
})
export class NotFoundVASComponent implements OnInit {

  activeLang: string = 'en';

  constructor(private interactionService: InteractionService) {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  ngOnInit(): void {
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

}
