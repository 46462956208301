export class BankAccountDTO {
  id: number;
  userName: string;
  bankBranch: string;
  accountNo: string;
  holderName: string;
  description: string;
  accountType: string;
  bank: string;
  image: any;
}
