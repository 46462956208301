import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';

const SETTINGS_URL = '/settings';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  fetchAll():Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_CORE + SETTINGS_URL + '/fetchAll');
  }


  domainConfig(hostName):Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_TENANT + '/domainConfig/findTenantIDByHostName/'+ hostName);
  }


}
