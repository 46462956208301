import {Component, OnInit, ViewChild} from '@angular/core';
import {Global} from '../../service/global';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ProductService} from '../../service/product.service';
import {MainUrlService} from '../../service/main-url.service';
import {CartService} from '../../service/cart.service';
import {PaymentService} from '../../service/payment.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {CoreService} from '../../service/core.service';
import {DeliveryService} from '../../service/delivery.service';
import {UserRoleService} from '../../service/user-role.service';
import {InteractionService} from '../../service/interaction.service';
import {SystemSettingsService} from '../../service/system-settings.service';
import {ValidationHandlerService} from '../../validation-handler/validation-handler.service';
import {CommonSignUpService} from '../../service/common-sign-up.service';
import {UserTokenDTO} from '../../dto/userTokenDTO';
import {CommonService} from '../../common/common.service';
import Swal from 'sweetalert2';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-riyaviru-checkout',
  templateUrl: './riyaviru-checkout.component.html',
  styleUrls: ['./riyaviru-checkout.component.css']
})
export class RiyaviruCheckoutComponent implements OnInit {

  coTestimonialConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    showNavigationIndicators: true,
  };

  selectPaymentType: number = 1;
  selectDeliveryOptions = 1;
  selectDeliveryType = 0;
  selectedBank = 0;
  pmtTabItem = 1;

  totalAmount: any;
  selectItem: any;
  currency;
  checkoutExpiryTime;
  paymentId;

  paymentDeliveryForm: FormGroup;
  paymentBillingForm: FormGroup;
  referenceNoForm: FormGroup;

  username;
  itemLength: number;
  subTotalAmount: any;
  qrImage: any;
  cartId: any;
  stockOut: boolean = false;
  showDiscount: boolean = false;
  discountValue: any;
  displayCountry: any;
  district: any;
  qrCode: any;
  bidProduct: boolean = false;
  tenantValue: any;

  _activePaymentMethod: any;
  payCorpClientID: any;
  payHereTenantID: any;
  BASE_URL: string;

  public userTokenDTO = new UserTokenDTO;

  selectDeliveryDistrictId: any;

  @ViewChild('cardPaymentConfirmation') cardPaymentModal;
  @ViewChild('smartPayConfirmation') smartPay;
  @ViewChild('clickCardPayment') clickCartPay;
  @ViewChild('clickSmartPay') clickSmartPay;
  @ViewChild('paymentSuccess') paymentSuccess;
  @ViewChild('closeReferenceInputForm') closeModal;
  @ViewChild('closeBankTransferForm') closeBankTransferInputModal;
  @ViewChild('pendingBankTransfer') pendingBankModal;

  bocURL: any;
  resultIndicator: any;
  sessionVersion: any;

  selectNewProductOptions: number;

  referenceNumber: any;
  bankTransferForm: any;
  allProvinceList: any;
  activeLang: string = 'en';
  bidId: any;
  productSku: any;
  transactionTrimValue: any;
  platformChargers: any;
  addressList: any[] = [];

  read_d: boolean = true;
  selectDeliveryCityId: any;
  selectDeliveryCityName: any;
  selectBillingCityId: any;

  selectBillingDistrictId: any;
  selectBillingCityName: any;
  none: any;
  orderId: string;
  transport: any;
  isDeliveryAddressSave: boolean = false;
  isBillingAddressSave: boolean = false;
  isSameAsBillingAddress: boolean = false;
  userInfo: any;
  isShownDelivery: boolean = false;
  isShownBilling: boolean = false;

  _productType: any = 'SPOT';
  riyawiruList: any;
  riyawiruArray = [];

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  productList = [];
  detailsList = [];

  paymentDetails = [];
  paymentTotal: any = 0;

  constructor(private global: Global,
              private toastr: ToastrService,
              private router: Router,
              private cookie: CookieService,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cartService: CartService,
              private coreService: CoreService,
              private paymentService: PaymentService,
              private titleService: Title,
              private route: ActivatedRoute,
              private deliveryService: DeliveryService,
              private userRoleService: UserRoleService,
              private interaction: InteractionService,
              private generalSettings: SystemSettingsService,
              private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private commonSignUpService: CommonSignUpService,
              public commonService: CommonService,
              private interactionService: InteractionService
  ) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

  ngOnInit() {
    this.loading();

    this.route.queryParams.subscribe(params => {

      this.resultIndicator = params['resultIndicator'];
      if (params['data'] !== undefined) {
        this.paymentService.fetchPaymentId().subscribe(_paymentId => {
          if (_paymentId['success']) {
            this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
              this.username = whoAmIDetails['email'];
              this.paymentId = _paymentId['body'];
              for (let riyawiru of JSON.parse(params['data'])) {
                this.detailsList.push(riyawiru['data']);
                let _driverId = null;
                let _driverOffer = 0;
                if (riyawiru['data']['bidDrivers'] !== null) {
                  for (let driver of riyawiru['data']['bidDrivers']) {
                    if (driver['status'] === 'ACCEPTED') {
                      _driverId = driver['driverId'];
                      _driverOffer = driver['driversOffer'];
                      this.paymentTotal = this.paymentTotal + parseFloat(driver['driversOffer']);
                    }
                  }
                  this.paymentDetails.push(
                    {
                      'transactionId': this.paymentId,
                      'bidRequestId': riyawiru['data']['bidRequestId'],
                      'buyerName': whoAmIDetails['email'],
                      'orderNo': riyawiru['data']['orderNo'],
                      'totalPayment': _driverOffer,
                      'sku': riyawiru['data']['orderItems'][0]['itemNo'],
                      'language': this.activeLang,
                      'imageId': riyawiru['data']['orderItems'][0]['itemImageUrl'],
                      'proxyUrl': environment.proxyUrl,
                      'driverId': _driverId
                    }
                  );
                }
              }
              Swal.close();
              sessionStorage.setItem('riyawiruList', JSON.stringify(this.detailsList));
              localStorage.setItem('USERNAME', this.username);
              sessionStorage.setItem('paymentTotal', this.paymentTotal);
              sessionStorage.setItem('paymentDetails', JSON.stringify(this.paymentDetails));
            });
          }
        }, () => {
          Swal.close();
        });
      }
      this.fetchAllSettings();

    });

    this.activeLang = sessionStorage.getItem('LANG');
    this.allProvinceList = undefined;

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
      }
    });

  }

  submitForm() {

    if (this.paymentDeliveryForm.invalid || this.paymentBillingForm.invalid) {
      return;
    }

    if (this.selectPaymentType === 1) {

      this.confirmPayment();

    } else if (this.selectPaymentType === 2) {
      this.smartPay.nativeElement.click();
    }
  }

  async checkTransactionCommonBody(email, qrCode, userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
                                   deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
                                   billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict,
                                   billingCity, billingPostalCode,
                                   billingFlag, paymentId, transport, platformCharge) {
    this.loading();

    let sendConfirmationEmailBody = {
      'email': email,
      'transactionID': paymentId,
      'qrCodeID': qrCode,
      'placedON': new Date(),
      'addressL2': '',
      'addressL3': '',
      'postalCode': deliveryPostalCode,
      'country': deliveryCountry
    };

    let _paymentSuccessDetailsBody = {
      'userName': userName,
      'transactionId': paymentId,
      'deliveryName': deliveryName,
      'deliveryContactNo': deliveryContactNo,
      'deliveryLine1': deliveryLine1,
      'deliveryLine2': deliveryLine2,
      'deliveryCountry': deliveryCountry,
      'deliveryProvince': deliveryProvince,
      'deliveryDistrict': deliveryDistrict,
      'deliveryCity': deliveryCity,
      'deliveryPostalCode': deliveryPostalCode,
      'deliveryFlag': deliveryFlag,
      'billingName': billingName,
      'billingContactNo': billingContactNo,
      'billingLine1': billingLine1,
      'billingLine2': billingLine2,
      'billingCountry': billingCountry,
      'billingProvince': billingProvince,
      'billingDistrict': billingDistrict,
      'billingCity': billingCity,
      'billingPostalCode': billingPostalCode,
      'billingFlag': billingFlag,
      'transport': transport,
      'platformCharge': platformCharge
    };

    if (this.riyawiruArray.length > 0) {
      let body = {
        'transactionId': paymentId,
        'buyer': userName,
        'buyerLanguage': this.activeLang,
        'items': this.riyawiruArray,
        'deliveryOption': 'bidding',
        'biddingOption': 'buyer decide',
        'street': deliveryLine1 + ' ' + deliveryLine2,
        'city': deliveryCity,
        'state': deliveryProvince,
        'country': deliveryCountry,
        'postalCode': deliveryPostalCode
      };

      this.productService.saveRiyawiruBid(body).subscribe(() => {
        console.log('$RIYAWIRU_$SUCCESS');
      });
    }

    this.paymentService.savePaymentSuccessDetails(_paymentSuccessDetailsBody).subscribe(() => {
      console.log('$PAYMENT_$SUCCESS');
    });

    if (this.selectPaymentType === 1) {
      this.paymentService.sendPurchaseConfirmationEmail(sendConfirmationEmailBody).subscribe(() => {
        console.log('$SEND_$MAIL');
      });
    }

  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      text: this.validationHandlerService.manageCheckoutMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      width: '300px',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  fetchAllSettings() {
    this.generalSettings.fetchAll().subscribe(fetchAll => {
      let tempArray = [];
      tempArray.push(fetchAll);
      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'ACTIVE_PAYMENT_METHOD') {
            this._activePaymentMethod = setting.value;
          }

          if (setting.code === 'PAYCORP_CLIENT_ID') {
            this.payCorpClientID = setting.value;
          }

          if (setting.code === 'PAYHERE_TENANT_ID') {
            this.payHereTenantID = setting.value;
          }
          if (setting.code === 'BASE_URL') {
            this.BASE_URL = setting.value;
          }

          if (setting.code === 'CURRENCY_CODE') {
            this.currency = setting.value;
          }

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }

          if (setting.code === 'PLATFORM_CHARGES') {
            this.platformChargers = setting.value;
          }


          if (setting.code === 'CHECKOUT_EXPIRY_TIME') {
            let ms = setting.value;
            ms = 1000 * Math.round(ms / 1000);
            const d = new Date(ms);
            this.checkoutExpiryTime = d.getUTCMinutes();
          }
        }
      }

      if (this.resultIndicator !== null && this.resultIndicator !== 'null' && this.resultIndicator !== undefined) {
        this.loading();
        this.paymentService.checkRiyawiruBOCPaymentStatus(this.resultIndicator).subscribe(paymentStatus => {
          if (paymentStatus['success']) {
            if (paymentStatus['message'] === 'PAYMENT SUCCESS') {

              Swal.close();

localStorage.getItem('USERNAME');
              this.orderId = sessionStorage.getItem('paymentId');

              this.riyawiruArray = JSON.parse(sessionStorage.getItem('riyawiruList'));
              this.paymentDetails = JSON.parse(sessionStorage.getItem('paymentDetails'));

              this.paymentSuccess.nativeElement.click();

            } else {
              this.popUpLoginFormModal();
            }
          } else {
            Swal.close();
            this.popUpLoginFormModal();
          }
        }, () => {
          this.popUpLoginFormModal();
        });
      }
    });
  }


  popUpLoginFormModal() {
    Swal.close();
    this.router.navigate(['/home/' + this.tenantValue]);
    this.interaction.popUpLoginModal('false');
  }

  fetchPurchaseHistoryDetails(TID) {
    this.productService.historyDetailsByTransactionID(TID).subscribe(transactionDetails => {
      this.selectItem = transactionDetails.productDetailsDTOS;
      let purchaseHistory = [];
      for (let transaction of transactionDetails.productDetailsDTOS) {
        let image = [];
        if (transaction.imageDTOS.length > 0) {
          image.push({
            'image': this.mainUrl.MAIN_URL_FILE + transaction.imageDTOS[0].image + '/?X-TenantID=' + this.tenantValue
          });
        } else {
          image.push({
            'image': 'assets/defaultBrokenImage.jpg'
          });
        }

        purchaseHistory.push(this.commonFetchPurchaseHistoryDetails(image, transaction.productName,
          transaction.quantity, transaction.unitPrice));
      }
      this.selectItem = purchaseHistory;
      this.subTotalAmount = transactionDetails['grandTotal'];
    });
  }

  commonFetchPurchaseHistoryDetails(imageList, name, quantity, unitPrice) {
    return {
      'productImages': imageList,
      'name': name,
      'quantity': quantity,
      'unitPrice': unitPrice
    };
  }

  getTotal(email) {
    this.cartService.getSubTotal(email, this.tenantValue).subscribe(subTotal => {
      this.subTotalAmount = subTotal;
      if (this.totalAmount > this.subTotalAmount) {
        this.discountValue = this.totalAmount - this.subTotalAmount;
        this.showDiscount = true;
      } else {
        this.showDiscount = false;
      }
    });
  }

  confirmPayment() {

    if (Object.keys(this.selectItem).length > 0) {

      this.loading();

      localStorage.setItem('USERNAME', this.username);
      sessionStorage.setItem('deliveryCity', this.selectDeliveryCityId);
      sessionStorage.setItem('deliveryFlag', JSON.stringify(this.isDeliveryAddressSave));

      sessionStorage.setItem('billingName', this.username);
      sessionStorage.setItem('billingCity', this.selectBillingCityId);
      sessionStorage.setItem('deliveryFlag', JSON.stringify(this.isBillingAddressSave));
      sessionStorage.setItem('paymentId', this.paymentId);

      sessionStorage.setItem('transport', this.transport);

      let _platFee = document.getElementById('platformCharge').textContent;
      sessionStorage.setItem('platformChargers', _platFee);

      let productType;
      if (this._productType === 'SPOT') {
        productType = 'SPOT';
      } else if (this._productType === 'AUCTION') {
        productType = 'AUCTION';
      } else {
        productType = 'FORWARD';
      }

      let a = document.createElement('a');
      a.href = this.mainUrl.MAIN_URL_PAYMENT + '/boc/checkoutV1/' + this.paymentId + '/' +
        productType + '&city=' + this.selectDeliveryCityName +
        '&userName=' + this.username +
        '&proxyUrl=' + environment.proxyUrl + '&lang=' + this.activeLang +
        '&X-TenantID=' + this.tenantValue;
      a.click();

    }
  }

  calGrandTotal(subTotalAmount, platformChargers) {
    if (this.selectPaymentType === 1) {
      return subTotalAmount / (1 - (platformChargers / 100));
    } else {
      return subTotalAmount;
    }
  }

  calPlatformCharge(subTotalAmount, platformChargers) {
    if (this.selectPaymentType === 1) {
      return (subTotalAmount / (1 - (platformChargers / 100))) - subTotalAmount;
    } else {
      return 0;
    }
  }

  commonIsExitsQty(sku, transactionId, quantity) {
    return {
      'sku': sku,
      'userName': this.username,
      'transactionId': transactionId,
      'quantity': quantity
    };
  }

  clearStorageValues() {

    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('deliveryName');
    sessionStorage.removeItem('deliveryContactNo');
    sessionStorage.removeItem('deliveryLine1');
    sessionStorage.removeItem('deliveryLine2');
    sessionStorage.removeItem('deliveryCountry');
    sessionStorage.removeItem('deliveryProvince');
    sessionStorage.removeItem('deliveryDistrict');
    sessionStorage.removeItem('deliveryCity');
    sessionStorage.removeItem('deliveryPostalCode');
    sessionStorage.removeItem('deliveryFlag');
    sessionStorage.removeItem('platformCharge');

    sessionStorage.removeItem('billingName');
    sessionStorage.removeItem('billingContactNo');
    sessionStorage.removeItem('billingLine1');
    sessionStorage.removeItem('billingLine2');
    sessionStorage.removeItem('billingCountry');
    sessionStorage.removeItem('billingProvince');
    sessionStorage.removeItem('billingDistrict');
    sessionStorage.removeItem('billingCity');
    sessionStorage.removeItem('billingPostalCode');
    sessionStorage.removeItem('deliveryFlag');
    sessionStorage.removeItem('paymentId');

    sessionStorage.removeItem('transport');
    sessionStorage.removeItem('riyawiruArray');
  }

  submitReferenceForm() {

    if (this.selectPaymentType === 2) {

      if (this.referenceNoForm.invalid) {
        return;
      }

      this.loading();

      let _smartPay = {
        'transactionId': this.paymentId,
        'qrReferenceNo': this.referenceNumber,
        'userName': this.username
      };

      this.closeModal.nativeElement.click();

      let productType;
      if (this._productType === 'SPOT') {
        productType = 'SPOT';
      } else if (this._productType === 'AUCTION') {
        productType = 'AUCTION';
      } else {
        productType = 'FORWARD';
      }

      this.paymentService.markAsPaymentBOCSmartPay(_smartPay, productType).subscribe(result => {
        if (result['success']) {
          this.referenceNoForm.reset();
          // this.commonPaymentSection(this.username,
          //   this.province_d, this.district_d, this.selectDeliveryCityId, this.postal_d,
          //   this.isDeliveryAddressSave, this.name_b, this.mobile_b, this.address1_b, this.address2_b, this.country_b, this.province_b,
          //   this.district_b, this.city_b, this.postal_b, this.isBillingAddressSave, this.paymentId, JSON.parse(this.transport), 0);
        }
      }, () => {
        Swal.close();
      });
    }

  }

  commonPaymentSection(userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
                       deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
                       billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict, billingCity, billingPostalCode,
                       billingFlag, paymentId, transport, platformCharge) {

    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      let brBody =
        {
          'url': this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + paymentId,
          'proxyUrl': environment.proxyUrl
        };
      this.loading();

      this.paymentService.fetchQrCode(brBody).subscribe(qrCode => {
        if (qrCode) {
          this.checkTransactionCommonBody
          (whoAmIDetails['email'], qrCode['message'], userName, deliveryName, deliveryContactNo, deliveryLine1, deliveryLine2, deliveryCountry,
            deliveryProvince, deliveryDistrict, deliveryCity, deliveryPostalCode, deliveryFlag, billingName,
            billingContactNo, billingLine1, billingLine2, billingCountry, billingProvince, billingDistrict, billingCity, billingPostalCode,
            billingFlag, paymentId, transport, platformCharge).then(() => {
            if (this.selectPaymentType === 1) {
              this.fetchPurchaseHistoryDetails(paymentId);
              this.qrCode = qrCode['message'];
              this.qrImage = this.mainUrl.MAIN_URL_FILE + qrCode['message'] + '/?X-TenantID=' + this.tenantValue;
              this.paymentSuccess.nativeElement.click();
            } else {
              this.pendingBankModal.nativeElement.click();
            }

            this.clearStorageValues();

            Swal.close();

          });

        }

      }, () => {
        Swal.close();
      });
    });
  }

  clearInputFields() {
    this.referenceNumber = undefined;
  }

  cardPayment() {
    this.loading();
    this.paymentService.riyawiruPaymentSaveDetails(this.paymentDetails).subscribe(() => {
      let a = document.createElement('a');
      a.href = this.mainUrl.MAIN_URL_PAYMENT + '/boc/riyaviru/checkout?transactionId=' +
        this.paymentId + '&buyerId=' + this.username + '&X-TenantID=' + this.tenantValue;
      a.click();
    }, () => {
      Swal.close();
    });
  }

  loadProductImage(sku: any) {
    this.productService.productImageBySku(sku).subscribe(result => {
      return this.mainUrl.MAIN_URL_FILE + result[0]['image'] + '/?X-TenantID=' + this.tenantValue;
    });
  }
}
