<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-7">
              <h2>{{'MY_ACCOUNT.SALES.MY_PRODUCTS.MY_PRODUCTS' | translate}}</h2>
            </div>
            <div class="col-5 text-right">
              <a (click)="selectNewProductOptions=0" class="btn btn-success" data-target="#addMyProduct"
                 data-toggle="modal">
                {{'MY_ACCOUNT.SELL_A_PRODUCT.SELL_A_PRODUCT' | translate}}
                <i aria-hidden="true" class="fa fa-long-arrow-right"></i>
              </a>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="width: 100px;">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">

                <mat-form-field class="matsearch">
                  <input (keyup)="applyFilter($event.target.value)" matInput
                         placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>

                <mat-horizontal-stepper  #stepper>
                  <mat-step [stepControl]="firstFormGroup">
                    <form [formGroup]="firstFormGroup" style="overflow-x: scroll!important;">
                      <ng-template matStepLabel>{{'SPOT_PRODUCT'|translate}}</ng-template>

                      <table [dataSource]="dataSource" class="table" mat-table matSort style="width:100%;">

                        <ng-container matColumnDef="id">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_ID' | translate}}

                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <div class="d-flex flex-row align-items-center mt-2 mb-2">
                              <div style="width: 50px;">
                                <img alt=""
                                     src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ row.image + '/?X-TenantID=' + this.tenantValue}}"
                                     style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                              </div>
                              <div class="rightdiv">
                                <h4>{{ row.name }}</h4>
                                <p>{{ row.sku }}</p>
                              </div>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="category">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.CATEGORY' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <ng-container *ngIf="activeLang == 'en'">{{row.category.description}}</ng-container>
                            <ng-container *ngIf="activeLang == 'si'">{{row.category.sinhala}}</ng-container>
                            <ng-container *ngIf="activeLang == 'ta'">{{row.category.tamil}}</ng-container>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="price">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRICE' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <span *ngIf="row.isSellInSmallerQty">
                              {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.spotStock| number:'2.2-2'}}
                            </span>
                            <span *ngIf="!row.isSellInSmallerQty">
                              {{'CURRENCY.CURRENCY' | translate}}&nbsp;{{row.unitPrice * row.totalQty| number:'2.2-2'}}
                            </span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="bulkQty">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.BULK_QTY' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell
                              style="padding-left: 0;text-align: right">
                            <span *ngIf="row.isSellInSmallerQty">
                              {{row.spotStock}}{{row.unitType}}
                            </span>
                            <span *ngIf="!row.isSellInSmallerQty">
                              {{row.totalQty}}{{row.unitType}}
                            </span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="qty">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.AVAILABLE_QTY' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell
                              style="padding-left: 0;text-align: right">

                            <span style="color: red;font-weight: bold" *ngIf="row.stockAvailable === 0">SOLD OUT</span>
                            <span *ngIf="row.stockAvailable > 0"> {{row.stockAvailable}}{{row.unitType}}</span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="expiredDate">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.BEST_BUY_DATE' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <span *ngIf="row.type === 'SPOT'">
                              {{convertDate(row.spotExpiredDate)}}
                            </span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="adddate">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ADDED_DATE' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            {{convertDate(row.createdDate)}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.STATUS' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <span *ngIf="row.status === 'Active'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACTIVE' |
                              translate}}</span>
                            <span
                              *ngIf="row.status === 'Blocked'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BLOCKED' | translate}} </span>
                            <span
                              *ngIf="row.status === 'Reject'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.REJECTED' | translate}} </span>
                            <span
                              *ngIf="row.status === 'Pending'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.PENDING' | translate}}</span>
                            <span
                              *ngIf="row.status === 'Disable'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DISABLED' | translate}}</span>
                            <span *ngIf="row.status === 'Expired'">{{'EXPIRED' | translate}}</span>

                          </td>

                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ACTION' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <button mat-raised-button (click)="disableProduct(row.sku)" class="actionBtn1"
                                    *ngIf="row.status === 'Active'">DISABLE
                            </button>
                            <button mat-raised-button (click)="enableProduct(row.sku)" class="actionBtn2"
                                    *ngIf="row.status === 'Disable'">ENABLE
                            </button>
                            <button mat-raised-button disabled style="color: black;background: transparent"
                                    *ngIf="row.status !== 'Active' && row.status !== 'Disable'">N/A
                            </button>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="action1">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                          </th>
                          <td *matCellDef="let row" data-target="#editProduct" class="center-table tbl" mat-cell
                              style="padding-left: 0;">
                            <button mat-raised-button *ngIf="row.status !== 'Blocked'"
                                    (click)="loadDataForUpdate(editProduct, row.allItems)" title="Edit Product"
                                    class="actionBtn editb">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                          </td>
                        </ng-container>


                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: displayedColumns" data-toggle="modal" mat-row></tr>
                      </table>

                      <mat-paginator #paginatorSpot [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                    </form>
                  </mat-step>
                  <mat-step [stepControl]="secondFormGroup">
                    <form [formGroup]="secondFormGroup" style="overflow-x: scroll!important;">
                      <ng-template matStepLabel>{{'AUCTION_PRODUCT'|translate}}</ng-template>

                      <table [dataSource]="dataSourceAuction" class="table" mat-table matSort style="width:100%">

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                          <th *matHeaderCellDef
                              mat-header-cell> {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_ID' | translate}}</th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <div class="d-flex flex-row align-items-center mt-2 mb-2">
                              <div style="width: 50px;">
                                <img alt=""
                                     src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ row.image + '/?X-TenantID=' + this.tenantValue}}"
                                     style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;"/>
                              </div>
                              <div class="rightdiv">
                                <h4>{{ row.name }}</h4>
                                <p>{{ row.sku }}</p>
                              </div>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="created">
                          <th *matHeaderCellDef mat-header-cell>{{'PRODUCT_VIEW.LISTED_DATE'|translate}}</th>
                          <td *matCellDef="let element" mat-cell>{{convertDate(element.created)}}</td>
                        </ng-container>

                        <ng-container matColumnDef="location">
                          <th *matHeaderCellDef mat-header-cell> {{'PRODUCT_VIEW.LOCATION'|translate}}</th>
                          <td *matCellDef="let element" mat-cell> {{element.location}} </td>
                        </ng-container>

                        <ng-container matColumnDef="startPrice">
                          <th *matHeaderCellDef mat-header-cell> {{'Start_Price'|translate}}</th>
                          <td *matCellDef="let element" mat-cell> {{'CURRENCY.CURRENCY' |
                            translate}}{{element.startPrice}} </td>
                        </ng-container>

                        <ng-container matColumnDef="stockAvailable">
                          <th *matHeaderCellDef
                              mat-header-cell> {{'MY_ACCOUNT.SALES.MY_PRODUCTS.AVAILABLE_QTY' | translate}}</th>
                          <td *matCellDef="let element" mat-cell>
                            {{element.stockAvailable}}{{element.unitType}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="endDate">
                          <th *matHeaderCellDef mat-header-cell>{{'BID_END_DATE'|translate}}</th>
                          <td *matCellDef="let element" mat-cell> {{convertDate(element.endDate)}}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.STATUS' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <span *ngIf="row.status === 'Active'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.ACTIVE' |
                              translate}}</span>
                            <span *ngIf="row.status === 'Blocked'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.BLOCKED' |
                              translate}} </span>
                            <span *ngIf="row.status === 'Reject'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.REJECTED' |
                              translate}} </span>
                            <span *ngIf="row.status === 'Pending'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.PENDING' |
                              translate}}</span>
                            <span *ngIf="row.status === 'Disable'">{{'MY_ACCOUNT.PURCHASES.MY_BANK_ACCOUNT.DISABLED' |
                              translate}}</span>
                            <span *ngIf="row.status === 'Expired'">{{'EXPIRED' | translate}}</span>
                            <span *ngIf="row.status === 'Awarded'">{{'AWARDED' | translate}}</span>
                            <span *ngIf="row.status === 'Ended'">Ended</span>
                          </td>

                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                            {{'MY_ACCOUNT.SALES.MY_PRODUCTS.ACTION' | translate}}
                          </th>
                          <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                            <button mat-raised-button (click)="disableProductAuction(row.sku)" class="actionBtn1"
                                    *ngIf="row.status === 'Active'">{{'DISABLE'|translate}}
                            </button>
                            <button mat-raised-button (click)="enableProductAuction(row.sku)" class="actionBtn2"
                                    *ngIf="row.status === 'Disable'">{{'ENABLE'|translate}}
                            </button>
                            <button mat-raised-button disabled style="color: black;background: transparent"
                                    *ngIf="row.status !== 'Active' && row.status !== 'Disable'">N/A
                            </button>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="action1">
                          <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                          </th>
                          <td *matCellDef="let row" data-target="#editAuctionProduct" class="center-table tbl" mat-cell
                              style="padding-left: 0;">
                            <button mat-raised-button *ngIf="row.status !== 'Blocked'"
                                    (click)="loadDataForAuctionUpdate(editAuctionProduct, row.allItems)"
                                    title="Edit Product" class="actionBtn editb">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                          </td>
                        </ng-container>


                        <tr *matHeaderRowDef="displayedColumnsAuction" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: displayedColumnsAuction" data-toggle="modal" mat-row></tr>
                      </table>

                      <mat-paginator #paginatorAuction [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>


                    </form>
                  </mat-step>
                </mat-horizontal-stepper>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- order review -->
<ng-template #editProduct>

  <app-farmer-edit-spot-product [product]="product"></app-farmer-edit-spot-product>

</ng-template>

<!-- order review -->
<ng-template #editAuctionProduct>

  <app-farmer-edit-auction-product [productAuction]="productAuction"></app-farmer-edit-auction-product>

</ng-template>
