import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class RiyawiruService {

  constructor(private http: HttpClient, private mainUrl: MainUrlService) {
  }

  createToken() {

    const headers = new HttpHeaders({
      'Authorization': 'Basic YWxlemF3ZWJjbGllbnQ6YWxlemFzZWNyZXRjbGllbnQ='
    });

    let options = {
      headers: headers
    };

    let formData = new FormData();
    formData.append('username', 'admin');
    formData.append('password', 'abcd@123');
    formData.append('grant_type', 'password');
    formData.append('scope', 'all');

    return this.http.post(this.mainUrl.MAIN_URL_RIYAWIRU + '/alezacoreservice/oauth/token',
      formData, options);
  }

  fetchAllVehicleTypes(token) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    let options = {
      headers: headers
    };
    return this.http.get(this.mainUrl.MAIN_URL_RIYAWIRU + '/riyaviruservice/vehicles/getAllVehicles', options);
  }

  fetchRiyawiruBidRequests(username): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_RIYAWIRU + '/riyaviruservice/bid-request/findAllByBuyerId/' + username);
  }

  fetchDriverDetails(driverId): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_RIYAWIRU + '/riyaviruservice/riyaviru-rider/getUserDetails?userId=' + driverId);
  }

  fetchOrderDetailsByOrderNo(orderNo): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_RIYAWIRU + '/riyaviruservice/bid-request/findByOrderNo/' + orderNo);
  }

  fetchDriverDetailsByOrderNo(orderNo): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_RIYAWIRU + '/riyaviruservice/bid-response/getDiliveryResponse?orderId=' + orderNo, '');
  }

  bidAwarding(orderId, driverId): Observable<any> {
    return this.http.post<any>(this.mainUrl.MAIN_URL_RIYAWIRU +
      '/riyaviruservice/bid-response/bid-confirmation?orderId=' + orderId + '&driverId=' + driverId, '');
  }

  getDistance(startLat, startLong, endLat, endLong,token): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    let options = {
      headers: headers
    };
    return this.http.get<any>(this.mainUrl.MAIN_URL_RIYAWIRU +
      '/riyaviruservice/distanceapi/getDistanceFromLstAndLon/' + startLat + ',' + startLong + '/' + endLat + ',' + endLong,options);
  }
}
