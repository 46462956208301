import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyAccountServiceService {

  toggleModal = new EventEmitter();
  farmerUsername = new EventEmitter();

  constructor() {
   }
}
