export class ProductDetailsDTO {
  constructor(
    public sku: any,
    public name: any,
    public description: any,
    public unitPrice: any,
    public unit: any,
    public type: any,
    public stockAvailable: any,
    public expiredDate: any,
    public location: any,
    public image: any,
    public allItems: any,
    public category:any,
    public createdDate,
    public spotStock,
    public spotExpiredDate,
    public status,
    public totalEarnings: any,
    public totalQuantity: any,
    public isSellInSmallerQty:any
  ) {
  }
}
