import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../../../../../service/product.service';
import {SystemSettingsService} from '../../../../../service/system-settings.service';
import {CoreService} from '../../../../../service/core.service';
import {UserRoleService} from '../../../../../service/user-role.service';
import {CommonService} from '../../../../../common/common.service';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {InteractionService} from '../../../../../service/interaction.service';
import {CommonSignUpService} from '../../../../../service/common-sign-up.service';
import {ValidationHandlerService} from '../../../../../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {environment} from '../../../../../../environments/environment';
import {catchError, last, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {ProductDeliveryDTO} from '../../../../../dto/ProductDeliveryDTO';
import {Railway} from '../../../../../dto/RailwayDTO';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-farmer-edit-auction-product',
  templateUrl: './farmer-edit-auction-product.component.html',
  styleUrls: ['./farmer-edit-auction-product.component.css']
})
export class FarmerEditAuctionProductComponent implements OnInit {
  @Input() productAuction: any;

  categoryForm: FormGroup;

  tenantValue;
  submittedCategoryForm: boolean = false;

  submittedProductDetailsForm: boolean = false;
  productDetailsForm: FormGroup;

  submittedPicUpAddressForm: boolean = false;
  pickUpAddressForm: FormGroup;

  selectAuctionNext = 0;
  selectNewProductOptions = 0;
  selectCategoryId: any;
  selectSubCategoryId: any;
  subCategoryList: any;
  categoryList: any;
  activeProfile: any;
  currencyCode: any;

  files: File[] = [];
  // auctionPeriodFrom: any;
  auctionPeriodTo: any;
  minimumBidValue: any;

  todayDate = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10);

  title: any;
  description: any;
  buyDate: any;
  unitTypeList: any;
  unitTypeId: any;
  availableTotalQty: any;
  unitType: any;

  address: any;

  provinceList: any;
  districtList: any;
  divisionalSecretariatList: any;
  gramaNiladhariAreaList: any;
  selectDistrictId: any;
  selectDivisionalSecretariatId: any;
  selectGramaNiladhariAreaId: any;
  selectProvinceId: any;
  postalCode: any;
  auctionTypeId: any;

  isEnableRiyaviruTransportation: boolean = false;

  imageArray = [];
  isAgentFarmer: boolean = false;
  listByUsername: any;
  loading: boolean = false;
  loadingDist: boolean = false;
  loadingDiv: boolean = false;
  loadingGn: boolean = false;
  loadCity: boolean = false;
  activeLang: string = 'en';
  @Input() isAgentFarmerLogin: boolean = false;
  @Input() agentFarmerUsername: string;

  constructor(private productService: ProductService,
              private systemSettingsService: SystemSettingsService,
              private coreService: CoreService,
              private userRoleService: UserRoleService,
              private commonService: CommonService,
              private matDialog: MatDialog,
              private http: HttpClient,
              private interactionService: InteractionService,
              private commonSignUpService: CommonSignUpService,
              private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder) {
    this.activeLang = sessionStorage.getItem('LANG');

    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.systemSettingsService.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if ('CURRENCY_CODE' === setting.code) {
            this.currencyCode = setting.value;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.fetchAllCategories();
    this.categoryFormValidation();
    this.productDetailsFormValidation();
    this.pickUpAddressFormValidation();
    this.fetchAuctionDetailsBySku(this.productAuction.sku);

    this.fetchAllUnitTypes();
    this.getAllProvince();
    this.loadProductTypes();
    this.loadAddresses();
    this.loadAgentFarmerDetails();
    this.getStation();

    this.fetchAuctionDetailsBySku(this.productAuction.sku);

    this.interactionService.passingFarmerUsername.subscribe(farmerUsername => {
      this.isAgentFarmer = true;
      this.listByUsername = farmerUsername;
    });

    this.deliveryForm = this.formBuilder.group({
      deliverCharge: '',
    });
    this.deliveryFormFixed = this.formBuilder.group({
      deliverChargeFixed: '',
      areas: '',

    });
  }

  loadSubCategory(id) {
    this.productService.fetchAllSubCategory(id).subscribe(subCategories => {
      this.subCategoryList = subCategories;
      this.fetchAllUnitTypes();
    });
  }

  productItem: any;
  imageObject = [];

  fetchAuctionDetailsBySku(sku) {
    this.productService.fetchSingleAuctionView(sku).subscribe(result => {
      this.productItem = result;
      this.buyDate = new Date(result['expiredDate']).toISOString().slice(0, 10);
      this.loadImages();
      this.loadSubCategory(this.selectCategoryId);
      this.selectSubCategoryId = this.productItem.product.subCategory.code;


      this.selectCategoryId = this.productItem.product.subCategory.mainCategory.code;
      this.title = this.productItem.product.name;
      this.description = this.productItem.product.description;
      this.auctionPeriodTo = new Date(result['bidEndDate']).toISOString().slice(0, 10);
      this.minimumBidValue = this.productItem['startPrice'];

      this.availableTotalQty = this.productItem['quantity'];
      this.unitType = this.productItem['product']['unit']['id'];
      this.address = this.productItem.product.address;

      this.getAllProvince();
      this.selectProvinceId = this.productItem.product.province;
      this.getAllDistrict(this.selectProvinceId);

      this.selectDistrictId = this.productItem.product.district;
      this.getAllDivSec(this.selectDistrictId);
      this.selectDivisionalSecretariatId = this.productItem.product.divisional;

      this.selectGramaNiladhariAreaId = this.productItem.product.gramaNiladari;
      this.postalCode = this.productItem.product.postalCode;

      this.diliveryOptList = this.productItem.deliveryList;
      if (this.productItem.deliveryList.length > 0) {
        for (let delivery of this.productItem.deliveryList) {
          if (delivery.deliveryOpt === '2') {
            this.buyerDeliver = true;
          } else if (delivery.deliveryOpt === '4') {
            this.train = true;
          } else if (delivery.deliveryOpt === '5') {
            this.isSellerDeliverFixed = true;
          } else if (delivery.deliveryOpt === '3') {
            this.isSellerDeliver = true;
          }
        }
      }
    });
  }

  loadImages() {
    this.imageObject = [];

    for (let images of this.productItem.productImages) {
      this.imageObject.push({
        imageURL: environment.proxyUrl + 'fileservice/file/' + images.image + '/?X-TenantID=' + this.tenantValue,
        imageId: images.image
      });
    }
  }

  deliveryOptions = 2;


  categoryFormValidation() {
    this.categoryForm = this.formBuilder.group({
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      auctionPeriodTo: ['', Validators.required],
      minimumBidValue: ['', Validators.required],
    });
  }

  productDetailsFormValidation() {
    this.productDetailsForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      description: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      availableTotalQty: ['', Validators.required],
      buyDate: ['', Validators.required],
      unitType: ['', Validators.required],
    });
  }

  pickUpAddressFormValidation() {
    this.pickUpAddressForm = this.formBuilder.group({
      address1: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      address2: ['', [Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      district: ['', Validators.required],
      province: ['', Validators.required],
      divisionalSecretariat: [''],
      gramaNiladhariArea: [''],
      postalCode: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())],
      city: ['', Validators.required]
    });
  }

  get f1() {
    return this.categoryForm.controls;
  }

  get f2() {
    return this.productDetailsForm.controls;
  }

  get f3() {
    return this.pickUpAddressForm.controls;
  }

  selectCategory(event) {
    this.selectSubCategoryId = undefined;
    if (event.target.value !== 'none') {
      this.selectCategoryId = event.target.value;
      this.productService.fetchAllSubCategory(this.selectCategoryId).subscribe(subCategories => {
        if (subCategories) {
          this.subCategoryList = subCategories;
        }
      });
    } else {
      this.selectCategoryId = undefined;
    }
  }

  fetchAllCategories() {
    this.productService.getActiveProfile().subscribe(activeProfile => {
      this.activeProfile = activeProfile['code'];
      this.productService.getAllMainCategoryByProfileId(this.activeProfile).subscribe(categories => {
        if (categories) {
          this.categoryList = categories;
        }
      });
    });
  }

  selectSubCategory(event) {
    if (event.target.value !== 'none') {
      this.selectSubCategoryId = event.target.value;
    } else {
      this.selectSubCategoryId = undefined;
    }
  }

  submitCategoryForm() {
    this.submittedCategoryForm = true;
    if (this.categoryForm.invalid) {
      return;
    }
    if (this.selectSubCategoryId !== 'none') {
      this.selectAuctionNext = 1;
    }
  }

  uploadImages(value) {
    this.commonService.processing();
    let formData = new FormData();

    if (value[0].size > 5006708) {
      Swal.close();
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });

      return false;
    }
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        //Remove last image from files array
        this.files.splice(-1, 1);

        Swal.fire({
          title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
        });

        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
          this.imageObject.push({
            imageURL: environment.proxyUrl + 'fileservice/file/' + event.body.message + '/?X-TenantID=' + this.tenantValue,
            imageId: event.body.message,
            event: value[0]
          });
        }
      }
    );
  }

  onSelect(event) {
    if (this.imageObject.length <= 3 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.uploadImages(event.addedFiles);
        this.files.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files.splice(-1, 1);
        }
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }

    for (let image of this.imageObject) {
      if (image.event === event) {
        let index = this.imageObject.indexOf(image);
        if (index !== -1) {
          this.imageObject.splice(index, 1);
        }
      }
    }

  }

  nextListProductDetailsPage() {
    this.submitCategoryForm();
  }

  closeModal() {
    this.matDialog.closeAll();
  }


  productDetailsFormSubmit() {
    this.submittedProductDetailsForm = true;
    if (this.productDetailsForm.invalid) {
      return;
    }
    this.selectAuctionNext = 2;
  }

  selectUnitType(event) {
    this.unitTypeId = event.target.value;
  }

  productDetailsPageNext() {
    this.productDetailsFormSubmit();
  }

  manageAvailableTotalQty(qty) {
    sessionStorage.setItem('totalAvailableQty', qty);
  }

  fetchAllUnitTypes() {
    this.coreService.fetchAllUnitTypes().subscribe(unitTypes => {
      if (unitTypes) {
        this.unitTypeList = unitTypes;
      }
    });
  }

  selectProvince(event) {
    this.loadingDist = true;
    this.loadCity = true;
    this.loadingDiv = true;
    this.selectProvinceId = event.target.value;
    this.selectDistrictId = undefined;
    this.selectCityId = undefined;
    this.postalCode = undefined;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDistrict(this.selectProvinceId);
  }

  selectDistrict(event) {
    this.loadingDiv = true;
    this.loadCity = true;
    this.selectDistrictId = event.target.value;
    this.selectDivisionalSecretariatId = undefined;
    this.selectCityId = undefined;
    this.allCities = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDivSec(this.selectDistrictId);
    this.getAllCities(this.selectDistrictId);
  }

  selectDivisionalSecretariat(event) {
    this.loadingGn = true;
    this.selectDivisionalSecretariatId = event.target.value;
    this.selectGramaNiladhariAreaId = undefined;
  }

  selectGramaNiladhariArea(event) {
    this.selectGramaNiladhariAreaId = event.target.value;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.provinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.districtList = result;
        this.loadingDist = false;
      }
    });

  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.divisionalSecretariatList = result;
        this.loadingDiv = false;
      }
    });
  }

  pickUpLocationAddressNextPage() {
    this.submittedPicUpAddressForm = true;
    if (this.pickUpAddressForm.invalid) {
      return;
    }
    this.selectAuctionNext = 3;
  }

  riyaviruTransportationServiceEnable(b: boolean) {
    this.isEnableRiyaviruTransportation = b;
  }

  locationBody: any;
  addressConcat: any;
  userInfo: any;
  diliveryOptList: any = [];
  deliverCharge: any;
  railwayList: any;
  station: any;
  deliverChargeFixed: any;
  areas: any;
  productDelivery = [];

  getStation() {
    this.coreService.getStation().subscribe(
      (data: Railway[]) => {
        Swal.close();
        this.railwayList = data;
      }
    );
  }

  selectRailway(event) {
    this.station = event;
  }


  commonSpecPriceDTO(price, stock, unitType, images) {
    let specAttributeList = [];

    specAttributeList.push({
      specs: [environment.specCode],
      price: price,
      weight: 0,
      stock: stock,
      unitType: unitType,
      productImages: images
    });

    return specAttributeList;
  }

  loadProductTypes() {
    this.coreService.fetchAllProductTypes().subscribe(types => {
      if (types) {
        for (let type of types) {
          if (type.type === 'FORWARD_ACU') {
            this.auctionTypeId = type.id;
            return;
          }
        }
      }
    });
  }

  selectCityId: any;
  cityObject: any;
  allCities: any;
  cityName: any;
  addressList: any[] = [];
  address1: any;
  address2: any;
  selectedCityName: any;
  isAddressSave: boolean = false;

  selectCity(event) {
    this.selectCityId = event.target.value;
    this.getCityById(this.selectCityId);
    this.setSelectedCityName();
  }

  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.cityObject = result;
        this.postalCode = this.cityObject.postalCode;
      }
    });
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }

  loadAddresses() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.userRoleService.fetchAddresses(whoAmI['email']).subscribe(addresses => {
        this.addressList = addresses;
        for (let add of this.addressList) {
          if (add.status && !this.isAgentFarmerLogin) {
            this.address1 = add.addressOne;
            this.address2 = add.addressTwo;
            this.selectCityId = add.cities.id;
            this.postalCode = add.postalCode;
            this.selectProvinceId = add.cities.agriDistrict.province.id;
            this.getAllDistrict(this.selectProvinceId);
            this.selectDistrictId = add.cities.agriDistrict.id;
            this.getAllDivSec(this.selectDistrictId);
            this.getAllCities(this.selectDistrictId);
          }
        }

      });
    });
  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
        this.setSelectedCityName();
      }
    });
  }

  setSelectedCityName() {
    if (this.allCities.length > 0) {
      for (let _city of this.allCities) {
        if (_city.id.toString() === this.selectCityId.toString()) {
          this.selectedCityName = _city.nameEN;
        }
      }
    }
  }

  isAgentFarmerCheck: boolean = false;
  addressObject: any;
  isItNewAddress: boolean = false;
  agentFarmerAddress1: any;
  agentFarmerAddress2: any;
  agentFamrerProvinceId: any;
  agentFamrerDisctrictId: any;
  agentFarmerCity: any;
  agentFarmerCityName: any;
  agentFarmerPostal: any;
  agentFarmerName: any;
  addressListAgentFarmer: any[] = [];

  manageAddressSave() {
    this.isAddressSave = !this.isAddressSave;
  }

  setAddress(index) {
    if (index === 'new') {
      this.isAgentFarmerCheck = false;
      this.addressObject = undefined;
      this.address1 = undefined;
      this.address2 = undefined;
      this.address = undefined;
      this.selectProvinceId = undefined;
      this.selectDistrictId = undefined;
      this.selectCityId = undefined;
      this.postalCode = undefined;
      this.isItNewAddress = true;
    } else if (index === 'FM') {
      this.isAgentFarmerCheck = true;
      this.address1 = this.agentFarmerAddress1;
      this.address2 = this.agentFarmerAddress2;
      this.selectProvinceId = this.agentFamrerProvinceId;
      this.getAllDistrict(this.selectProvinceId);
      this.selectDistrictId = this.agentFamrerDisctrictId;
      this.getAllCities(this.selectDistrictId);
      this.getAllDivSec(this.selectDistrictId);
      this.selectCityId = this.agentFarmerCity;
      this.postalCode = this.agentFarmerPostal;
      this.isItNewAddress = false;

    } else {
      this.isAgentFarmerCheck = false;
      this.addressObject = this.addressList[index];

      this.address1 = this.addressObject.addressOne;
      this.address2 = this.addressObject.addressTwo;

      this.selectProvinceId = this.addressObject.cities.agriDistrict.province.id;
      this.getAllDistrict(this.selectProvinceId);
      this.selectDistrictId = this.addressObject.cities.agriDistrict.id;
      this.getAllCities(this.selectDistrictId);
      this.getAllDivSec(this.selectDistrictId);
      this.selectCityId = this.addressObject.cities.id;
      this.postalCode = this.addressObject.postalCode;
      this.isItNewAddress = false;
    }

  }

  loadAgentFarmerDetails() {
    if (this.isAgentFarmerLogin) {
      this.userRoleService.fetchAddresses(this.agentFarmerUsername).subscribe(addresses => {
        this.addressListAgentFarmer = addresses;
        for (let add of this.addressListAgentFarmer) {
          if (add.status) {
            this.isAgentFarmerCheck = true;
            this.address1 = add.addressOne;
            this.address2 = add.addressTwo;
            this.selectProvinceId = add.cities.agriDistrict.province.id;
            this.getAllDistrict(this.selectProvinceId);
            this.selectDistrictId = add.cities.agriDistrict.id;
            this.getAllCities(this.selectDistrictId);
            this.getAllDivSec(this.selectDistrictId);
            this.selectCityId = add.cities.id;
            this.postalCode = add.postalCode;

            this.agentFarmerAddress1 = add.addressOne;
            this.agentFarmerAddress2 = add.addressTwo;
            this.agentFarmerName = add.name;
            this.agentFamrerProvinceId = add.cities.agriDistrict.province.id;
            this.agentFamrerDisctrictId = add.cities.agriDistrict.id;
            this.agentFarmerCity = add.cities.id;
            this.agentFarmerCityName = add.cities.nameEN;
            this.agentFarmerPostal = add.postalCode;
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isAgentFarmerLogin'] || changes['agentFarmerUsername']) {
      this.loadAgentFarmerDetails();
    }
  }

  isSellerDeliver: boolean = false;
  isSellerDeliverFixed: boolean = false;
  buyerDeliver: boolean = false;
  train: boolean = false;
  railwayReq: boolean = false;
  deliveryReq: boolean = false;

  checkVal($event, val) {
    if ($event.checked) {
      this.diliveryOptList.push(val);
      if (val === 3) {
        this.isSellerDeliver = true;
      } else if (val === 4) {
        this.train = true;
      } else if (val === 2) {
        this.buyerDeliver = true;
      } else if (val === 5) {
        this.isSellerDeliverFixed = true;
      }
    } else {
      if (this.diliveryOptList.length > 0) {
        for (let delivery of this.diliveryOptList) {
          if (delivery.deliveryOpt === val.toString()) {
            let index = this.diliveryOptList.indexOf(delivery);
            if (val === '3') {
              this.isSellerDeliver = false;
            } else if (val === '4') {
              this.train = false;
            } else if (val === '2') {
              this.buyerDeliver = false;
            } else if (val === '5') {
              this.isSellerDeliverFixed = false;
            }
            if (index !== -1) {
              this.diliveryOptList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  deliveryForm: FormGroup;
  deliveryFormFixed: FormGroup;
  isChargeReq: boolean = false;
  areaDisable = true;
  isAreaReq = false;
  isFixedChargeReq = false;
  commonImg: any;

  next() {
    let isValid = false;
    if (this.isSellerDeliver === true || this.train === true || this.buyerDeliver === true || this.isSellerDeliverFixed) {

      if (this.train === true) {
        if (this.station === null || this.station === undefined || this.station === '') {
          this.railwayReq = true;
        } else {
          this.railwayReq = false;
          isValid = true;
        }
      } else if (this.isSellerDeliver === true) {
        if (this.deliveryForm.value.deliverCharge === null || this.deliveryForm.value.deliverCharge === undefined
          || this.deliveryForm.value.deliverCharge === '') {
          this.isChargeReq = true;
        } else {
          this.isChargeReq = false;
          isValid = true;
        }

      } else if (this.isSellerDeliverFixed === true) {

        if (!(this.deliveryFormFixed.value.deliverChargeFixed === null || this.deliveryFormFixed.value.deliverChargeFixed === undefined
          || this.deliveryFormFixed.value.deliverChargeFixed === '')) {

          if (!this.areaDisable) {
            if (this.deliveryFormFixed.value.areas === null || this.deliveryFormFixed.value.areas === undefined
              || this.deliveryFormFixed.value.areas === '') {
              isValid = false;
              this.isAreaReq = true;
            } else {
              isValid = true;
              this.isAreaReq = false;
            }
          } else {
            isValid = true;
            this.isFixedChargeReq = false;
          }

        } else {
          this.isFixedChargeReq = true;
          isValid = false;
        }


      } else if (this.buyerDeliver === true) {
        isValid = true;
      }
    } else {
      this.deliveryReq = true;
    }

    if (isValid) {
      this.deliveryReq = false;
      this.railwayReq = false;
      this.isChargeReq = false;
      this.isFixedChargeReq = false;
      this.isAreaReq = false;
      this.deliverCharge = this.deliveryForm.value.deliverCharge;
      this.deliverChargeFixed = this.deliveryFormFixed.value.deliverChargeFixed;
      this.areas = this.areaDisable ? 'All Island delivery' : this.deliveryFormFixed.value.areas;

      this.selectAuctionNext = this.selectAuctionNext + 1;
    }


  }

  updateProduct() {

    if ((this.files.length >= 1 && this.files.length <= 4) ||
      (this.imageObject.length >= 1 && this.imageObject.length <= 4)) {
      this.commonService.processing();
      let imageList = [];
      for (let image of this.imageObject) {
        imageList.push({
          'image': image['imageId']
        });
      }

      this.userRoleService.whoAmI().subscribe(whoAmI => {

        if (this.isAddressSave) {
          this.locationBody = {
            'username': whoAmI['email'],
            'name': this.userInfo.name,
            'status': false,
            'addressOne': this.address1,
            'addressTwo': this.address2,
            'addressType': {
              'id': 1
            },
            'country': {
              'id': 1
            },
            'cities': {
              'id': this.selectCityId
            },
            'postalCode': this.postalCode
          };
        }

        if (this.address2 === null || this.address2 === undefined || this.address2 === '') {
          this.address2 = '';
          this.addressConcat = this.address1 + ',' + this.selectedCityName;
        } else {
          this.addressConcat = this.address1 + ',' + this.address2 + ',' + this.selectedCityName;
        }


        for (let opt of this.diliveryOptList) {
          let option: ProductDeliveryDTO = new ProductDeliveryDTO;
          option.deliveryOpt = opt;
          if (opt === 3) {
            option.deliveryCharges = this.deliverCharge;
          }
          if (opt === 4) {
            option.station = this.station;
          }
          if (opt === 5) {
            option.deliveryCharges = this.deliverChargeFixed;
            option.deliveryDesc = this.areas;
          }
          this.productDelivery.push({
            'deliveryOpt': option.deliveryOpt,
            'station': option.station,
            'deliveryCharges': option.deliveryCharges,
            'deliveryDesc': option.deliveryDesc
          });
        }

        let _tempDeliveryOptions = [];

        if (this.productDelivery.length > 0) {
          for (let delivery of this.productDelivery) {
            _tempDeliveryOptions.push({
              'deliveryOpt': delivery['deliveryOpt'].deliveryOpt,
              'station': delivery['deliveryOpt'].station,
              'deliveryCharges': delivery['deliveryOpt'].deliveryCharges,
              'deliveryDesc': delivery['deliveryOpt'].deliveryDesc
            });
          }
        }

        let auctionProductDTO = {
          'sku': this.productItem.sku,
          'startPrice': this.minimumBidValue,
          'bidEndDate': this.auctionPeriodTo,
          'expireDate': this.buyDate,
          'minAcceptancePrice': this.minimumBidValue,
          'bidStatus': false,
          'unit': {
            'id': this.unitType
          },
          'quantity': this.availableTotalQty,
          'address': this.address,
          'province': this.selectProvinceId,
          'district': this.selectDistrictId,
          'divisional': this.selectDivisionalSecretariatId,
          'gramaNiladari': this.selectGramaNiladhariAreaId,
          'postalCode': this.postalCode,
          'name': this.title,
          'description': this.description,
          'deliveryList': _tempDeliveryOptions,
          'productImages': imageList
        };

        this.productService.updateAuctionProduct(auctionProductDTO).subscribe(updateAuctionProduct => {
          Swal.close();
          if (updateAuctionProduct) {

            if (this.isAddressSave) {
              this.commonSignUpService.saveNewAddresses(this.locationBody).subscribe(() => {
                sessionStorage.removeItem('FARMER_USERNAME');
                this.interactionService.updateAgentFarmersList(true);
                this.interactionService.updateProductListingList(true);
              });
            } else {
              sessionStorage.removeItem('FARMER_USERNAME');
              this.interactionService.updateAgentFarmersList(true);
              this.interactionService.updateProductListingList(true);
            }

            Swal.fire({
              position: 'center',
              type: 'success',
              title: 'Update Successfully,Your Product sent to the Review Section to get the verification!',
              showConfirmButton: false,
              timer: 3500
            }).then(() => {
              this.closeModal();
            });
          }
        }, () => {
          Swal.close();
        });
      });
    } else {
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage(sessionStorage.getItem('LANG')),
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });
    }

  }

  removeImage(imageId: any) {
    let temp = [];
    if (this.imageObject.length > 0) {
      for (let image of this.imageObject) {
        if (image['imageId'] !== imageId) {
          temp.push(image);
        }
      }
      this.imageObject = temp;
    }

    if (this.imageObject.length === 0) {
      this.files = [];
      this.imageArray = [];
    }
  }

  setViewImage(imageURL: string) {
    this.commonImg = imageURL;
  }
}
