<head>
  <!--  <meta content="text/html; charset=UTF-8" http-equiv="content-type">-->
  <style type="text/css">ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c6 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c3 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
  }

  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: center
  }

  .c4 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 40px;
  }

  .c7 {
    height: 11pt
  }

  p {
    margin-bottom: 20px;
  }

  p.c3 {
      display: none;
  }
  
  .c2 {
    font-weight: 700
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.0;
    page-break-after: avoid;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 12pt;
    font-family: "Arial";
    line-height: 1.0;
    text-align: left
  }

  h2 {
    padding-top: 11.2pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 11.2pt;
    font-family: "Arial";
    line-height: 1.0;
    text-align: left
  }

  h3 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 12pt;
    font-family: "Arial";
    line-height: 1.0;
    text-align: left
  }

  h4 {
    padding-top: 12.8pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 12.8pt;
    font-family: "Arial";
    line-height: 1.0;
    text-align: left
  }

  h5 {
    padding-top: 12.8pt;
    color: #000000;
    font-weight: 700;
    font-size: 9pt;
    padding-bottom: 12.8pt;
    font-family: "Arial";
    line-height: 1.0;
    text-align: left
  }

  h6 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 8pt;
    padding-bottom: 18pt;
    font-family: "Arial";
    line-height: 1.0;
    text-align: left
  }</style>
</head>
<body class="c4"><p class="c5"><span class="c2 c6">HELAVIRU AGREEMENT WITH TRANSPORT PROVIDERS</span></p>
<p class="c1 c7"><span class="c6 c2"></span></p>
<p class="c1"><span>This agreement (hereinafter called the &ldquo;Agreement&rdquo;) is made by and between </span><span
  class="c2">&nbsp;YOU (&ldquo;Customer&rdquo;) and Epic Green Eco Systems (Private) Limited </span><span class="c0">&nbsp;, a company incorporated in the Democratic Socialist Republic of Sri Lanka under the Companies Act No. 07 of 2007 and having its registered office at Epic Techno-Village, 158/1/A, Kaduwela Road, Thalangama, Battaramulla, 10120, Sri Lanka (hereinafter referred to as &ldquo;EPIC&rdquo;)</span>
</p>
<p class="c1"><span class="c2">WHEREAS</span><span class="c0">&nbsp;EPIC is a specialist in technology solutions and has developed the Helaviru e-commerce platform and You acknowledge the same and that EPIC does not provide transportation services, function as a carrier or agent for the transportation of goods. EPIC is willing to facilitate the provision of goods transportation services by You relating to the Helaviru Platform developed by it and You are desirous of obtaining the said services through the Helaviru Platform.</span>
</p>
<p class="c1"><span class="c2">1. </span><span>&nbsp;</span><span class="c3 c2">Facilitation of Services</span></p>
<p class="c1"><span class="c0">EPIC grants the Customer a license that is non-exclusive and non-transferable, to use the Helaviru Platform for the purpose of connecting with users of the Helaviru Platform (hereinafter referred to as &ldquo;Users&rdquo;) during the term of this Agreement.</span>
</p>
<p class="c1"><span class="c0">EPIC has the right to update and/or modify the Helaviru Platform from time to time at its discretion.</span>
</p>
<p class="c1"><span class="c0">You shall not send spam or unsolicited messages to the Users or inconvenience them in any manner.</span>
</p>
<p class="c1"><span class="c0">2. No assignment of Intellectual Property Rights</span></p>
<p class="c1"><span>Nothing in this Agreement shall operate to assign or transfer any Intellectual Property Rights from EPIC to the Customer, or from the Customer to EPIC. &quot;</span><span
  class="c2">Intellectual Property Rights</span><span class="c0">&quot; means all intellectual property rights wherever in the world, whether registrable or unregistrable, registered or unregistered, including any application or right of application for such rights (and these &quot;intellectual property rights&quot; include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trade marks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, semi-conductor topography rights and rights in designs);</span>
</p>
<p class="c1"><span class="c0">3. Charges and Payments</span></p>
<p class="c1"><span class="c0">EPIC shall be entitled to a certain percentage of the revenue generated through the sales via the Helaviru Platform by the Customer, as may be decided by EPIC at its discretion which percentage shall be communicated to you from time to time. EPIC shall deduct the said percentage of revenue from each transaction and remit the balance to the Customer within two (2) working days of each transaction.</span>
</p>
<p class="c1"><span class="c0">4 Confidentiality Obligations</span></p>
<p class="c1"><span>4.1 EPIC may disclose the Confidential Information to EPIC&#39;s officers, employees, professional advisers, insurers, agents and subcontractors who have a need to access the Customer Confidential Information for the performance of their work with respect to this Agreement and who are bound by a written agreement or professional obligation to protect the confidentiality of the Customer Confidential Information. &quot; </span><span
  class="c2">&nbsp;Confidential Information</span><span class="c0">&quot; means any information disclosed by or on behalf of either party during the term whether disclosed in writing, orally or otherwise, that at the time of disclosure:</span>
</p>
<p class="c1"><span class="c0">(i) was marked or described as &quot;confidential&quot;; or</span></p>
<p class="c1"><span class="c0">(ii) should have been reasonably understood to be confidential.</span></p>
<p class="c1"><span
  class="c0">4.2 This Clause imposes no obligations with respect to Confidential Information that:</span></p>
<p class="c1"><span class="c0">(a) is known before disclosure under this Agreement and is not subject to any other obligation of confidentiality;</span>
</p>
<p class="c1"><span class="c0">(b) is or becomes publicly known through no act or default of either party; or</span></p>
<p class="c1"><span class="c0">(c) is obtained from a third party in circumstances where such party has no reason to believe that there has been a breach of an obligation of confidentiality.</span>
</p>
<p class="c1"><span class="c0">4.3 The restrictions in this Clause do not apply to the extent that any Confidential Information is required to be disclosed by any law or regulation, by any judicial or governmental order or request.</span>
</p>
<p class="c1"><span class="c0">4.4 The provisions of this Clause shall continue in force indefinitely following the termination of this Agreement.</span>
</p>
<p class="c1"><span class="c0">5. Limitations and Exclusions of Liability</span></p>
<p class="c1"><span class="c0">5.1 Nothing in this Agreement will:</span></p>
<p class="c1"><span class="c0">(a) limit or exclude your liability for death or personal injury resulting from negligence;</span>
</p>
<p class="c1"><span class="c0">(b) limit or exclude your liability for fraud or fraudulent misrepresentation;</span></p>
<p class="c1"><span
  class="c0">(c) limit any liabilities in any way that is not permitted under applicable law; or</span></p>
<p class="c1"><span class="c0">(d) exclude any liabilities that may not be excluded under applicable law.</span></p>
<p class="c1"><span>5.2 Neither party shall be liable to the other party in respect of any losses arising out of a Force Majeure Event. &quot; </span><span
  class="c2">Force Majeure Event</span><span class="c0">&quot; means an event, or a series of related events, that is outside the reasonable control of the party affected (including failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the laws, disasters, explosions, fires, floods, riots, terrorist attacks, wars, curfew, lockdowns and quarantine measures);</span>
</p>
<p class="c1"><span class="c0">5.3 EPIC shall not be liable to the Customer in respect of any loss of profits or anticipated savings, EPIC in respect of any loss of revenue or income, any loss of business, contracts or opportunities.</span>
</p>
<p class="c1"><span class="c0">5.4 EPIC shall not be liable for any disruption of service done in terms of payments, trading, delivery or any other business activity.</span>
</p>
<p class="c1"><span
  class="c0">5.5 EPIC shall not be liable to any end customer in terms of service, delivery, etc.</span></p>
<p class="c1"><span class="c0">5.6 EPIC makes no representations, warranties or guarantees as to the actions or inactions of the Users.</span>
</p>
<p class="c1"><span class="c0">6. Force Majeure Event</span></p>
<p class="c1"><span class="c0">6.1 If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under this Agreement that obligation will be suspended for the duration of the Force Majeure Event.</span>
</p>
<p class="c1"><span class="c0">6.2 A party that becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under this Agreement, must:</span>
</p>
<p class="c1"><span class="c0">(a) promptly notify the other; and</span></p>
<p class="c1"><span class="c0">(b) inform the other of the period for which it is estimated that such failure or delay will continue.</span>
</p>
<p class="c1"><span class="c0">6.3 A party whose performance of its obligations under this Agreement is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force Majeure Event.</span>
</p>
<p class="c1"><span class="c0">7. Termination</span></p>
<p class="c1"><span class="c0">7.1 Either party may terminate this Agreement by giving to the other party at least &hellip;&hellip;&hellip;. days&#39; written notice of termination.</span>
</p>
<p class="c1"><span class="c0">7.2 Either party may terminate this Agreement immediately by giving written notice of termination to the other party if the other party commits a material breach of this Agreement.</span>
</p>
<p class="c1"><span class="c0">7.3 Either party may terminate this Agreement immediately by giving written notice of termination to the other party if:</span>
</p>
<p class="c1"><span class="c0">(a) the other party:</span></p>
<p class="c1"><span class="c0">(i) is dissolved;</span></p>
<p class="c1"><span class="c0">(ii) ceases to conduct all of its business;</span></p>
<p class="c1"><span class="c0">(iii) is or becomes unable to pay its debts as they fall due;</span></p>
<p class="c1"><span class="c0">(iv) is or becomes insolvent or is declared insolvent; or</span></p>
<p class="c1"><span class="c0">(v) convenes a meeting or makes or proposes to make any arrangement or composition with its creditors;</span>
</p>
<p class="c1"><span class="c0">(b) an administrator, administrative receiver, liquidator, receiver, trustee, manager or similar is appointed over any of the assets of the other party;</span>
</p>
<p class="c1"><span class="c0">(c) an order is made for the winding up of the other party, or the other party passes a resolution for its winding up; or</span>
</p>
<p class="c1"><span class="c0">(d) if that other party is an individual:</span></p>
<p class="c1"><span class="c0">(i) that other party dies;</span></p>
<p class="c1"><span class="c0">(ii) as a result of illness or incapacity, that other party becomes incapable of managing his or her own affairs; or</span>
</p>
<p class="c1"><span class="c0">(iii) that other party is the subject of a bankruptcy petition or order.</span></p>
<p class="c1"><span class="c0">8. Effects of termination</span></p>
<p class="c1"><span class="c0">Upon the termination of this Agreement, all of the provisions of this Agreement shall cease to have effect. Except to the extent that this Agreement expressly provides otherwise, the termination of this Agreement shall not affect the accrued rights of either party. Within 30 days following the termination of this Agreement for any reason the Customer must pay to EPIC any payments that have been accrued before the termination of this Agreement without prejudice to the parties&#39; other legal rights.</span>
</p>
<p class="c1"><span class="c0">9. General</span></p>
<p class="c1"><span class="c0">9.1 Entire Agreement</span></p>
<p class="c1"><span class="c0">This Agreement contains all covenants, stipulations and provisions agreed by the Parties. No agent or representative of either Party has authority to make, and the Parties shall not be bound by or be liable for, any other statement, representation, promise or agreement not set forth herein.</span>
</p>
<p class="c1"><span class="c0">Modifications or Variations: Any modification or variation of the terms and conditions of this Agreement, as well as any modification or variation in the scope of work, may only be made by a written agreement duly signed by both the Parties.</span>
</p>
<p class="c1"><span class="c0">9.2 Applicable Law</span></p>
<p class="c1"><span class="c0">Applicable Law means and includes the laws and any other instruments having the force of law in Sri Lanka as may be issued and in force from time to time. This Agreement shall be interpreted in accordance with the laws of Sri Lanka.</span>
</p>
<p class="c1"><span class="c0">9.3 Survival</span></p>
<p class="c1"><span class="c0">The clauses of this Agreement, which by nature are intended to survive termination of this Agreement, shall remain in effect after such termination.</span>
</p>
<p class="c1"><span class="c0">9.4 Settlement of Disputes</span></p>
<p class="c1"><span class="c0">In case, a dispute arises between parties, then there would be two ways for resolution of the dispute under this Agreement viz:</span>
</p>
<p class="c1"><span class="c0">(i) Amicable Settlement: Performance of this Agreement is governed by the terms and conditions of this Agreement. However, at times dispute may arise about any interpretation of any term or condition of this Agreement, including but not limited to, the scope of work, the clauses of payments etc. In such a situation either party to this Agreement may send a written notice of dispute to the other party. The party receiving the notice of dispute will consider the notice and respond to it in writing within 30 days after receipt. If that party fails to respond within 30 days, or the dispute cannot be amicably settled within 30 days following the response of that party, then sub-clause (ii) shall become applicable.</span>
</p>
<p class="c1"><span class="c0">(ii) Arbitration: Differences and/or disputes remaining unresolved amicably shall be settled through arbitration in accordance with the rules of Arbitration of the Sri Lanka National Arbitration Centre and the award made in pursuance thereof shall be binding on the parties. The venue for Arbitration proceedings shall be Colombo and the language of the arbitration proceedings and that of all documents and communications between the Customer and the EPIC shall be English. Arbitrator(s) shall give reasons for the award and the award shall be binding on the Customer and EPIC and enforceable at the courts of jurisdiction in Sri Lanka, in accordance with the provisions of the Arbitration Act in force. The services under this Agreement shall be continued during the arbitration proceedings, unless otherwise agreed in writing by the Customer and EPIC or unless it is proved that the services cannot possibly be continued during the arbitration proceedings.</span>
</p>
<p class="c1"><span class="c0">9.5 Indemnification</span></p>
<p class="c1"><span class="c0">If any action in court is brought against EPIC or an agent or an employee or a representative of EPIC by a Third Party pursuant to this Agreement on account of any act/omission/negligence on the part of the Customer under this Agreement, or for any damage or injury caused by the alleged act, omission or negligence on the part of the Customer, its agents, representatives or sub-contractors or employees to any Third Party, the Customer shall in all such cases indemnify and keep EPIC, its agents, employees and representatives, harmless and indemnified from all such losses, damages, expenses, etc. arising out of any decrees or Court orders, etc. or otherwise arising out of any such act or omission.</span>
</p>
<p class="c1"><span class="c0">9.6 Notices</span></p>
<p class="c1"><span class="c0">All notices and other communications under this Agreement must be in writing, and must either be mailed by registered post with acknowledgement due or hand delivered with proof of it having been received or sent by email or fax. If posted, all notices will be considered as delivered after three days, of the notice having been posted. If hand delivered, all notices will be considered as delivered, when received by the party to whom the notice is sent. If the notice is faxed and /or e-mailed, it would be considered as delivered on the same day; this will be followed by a delivery of hardcopy within five days. All notices under this Agreement shall be sent to or delivered at the address as specified by the parties. A Notice shall be effective when delivered.</span>
</p>
<p class="c1"><span class="c0">9.7 Waiver</span></p>
<p class="c1"><span class="c0">Save as where this Agreement expressly provides, neither party shall be deemed to have waived any right, power, privilege or remedy under this Agreement unless such party shall have delivered to the other party a written waiver signed by an authorized official of such waiving party. No failure or delay on the part of either party in exercising any right, power, privilege or remedy hereunder shall operate as a waiver, default or acquiescence thereof. Any waiver on the part of either party of any right, power, privilege or remedy hereunder shall not preclude any other or further exercise thereof. Any single or partial exercise of any right, power, privileges or remedy hereunder shall not preclude any other or further exercise thereof.</span>
</p>
<p class="c1"><span class="c0">9.8 Severability</span></p>
<p class="c1"><span class="c0">If any provision or condition of this Agreement is prohibited or rendered invalid or unenforceable, such prohibition, invalidity or unenforceability shall not affect the validity or enforceability of any other provisions and conditions of this Agreement or this Agreement as a whole and the remaining provisions of this Agreement shall remain in full force and effect.</span>
</p>
<p class="c1"><span class="c0">9.9 Relationship of Parties</span></p>
<p class="c1"><span class="c0">Each Party is an independent contractor and not an employee or agent of the other Party. Neither Party shall have authority to commit or create any liability on the part of the other in any manner whatsoever. Personnel retained or assigned by one Party to perform work under this Agreement shall at all times be considered employees, agents, or contractor of that Party only.</span>
</p>
<p class="c1"><span class="c0">9.10 Legal Authority</span></p>
<p class="c1"><span class="c0">Either Party has the legal right and authority to enter into this Agreement and to perform its obligations under this Agreement.</span>
</p>
<p class="c1"><span class="c2">By clicking the “checkbox” to proceed, you confirm that you have read and clearly understood the content of this Agreement and will use the said Helaviru Platform and act as a transport services provider therein on the terms and conditions set forth in this Agreement and will abide by the same at all times.</span>
</p>
<div><p class="c11"><span class="c23 c27">Agreement with Transport Providers</span>
</p>
  <p class="c1 c11"><span class="c3"></span></p></div>
</body>
