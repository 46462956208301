import {Component, OnInit, ViewChild} from '@angular/core';
import {ValidationHandlerService} from '../../../../../../validation-handler/validation-handler.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreService} from '../../../../../../service/core.service';
import {ActivatedRoute} from '@angular/router';
import {InteractionService} from '../../../../../../service/interaction.service';
import {CommonService} from '../../../../../../common/common.service';
import {UserRoleService} from '../../../../../../service/user-role.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fire-application',
  templateUrl: './fire-application.component.html',
  styleUrls: ['./fire-application.component.css']
})
export class FireApplicationComponent implements OnInit {

  form: FormGroup;
  activeLang: any;
  submitted = false;

  occupationList = [
    {
      'id': 'Ware house', 'description': 'Occupation_WareHouse'
    },
    {
      'id': 'Stores', 'description': 'Occupation_Stores'
    },
    {
      'id': 'Show rooms', 'description': 'Occupation_ShowRooms'
    },
    {
      'id': 'Wholesale/Retail Shop', 'description': 'Occupation_Wholesale'
    },
    {
      'id': 'Office', 'description': 'Occupation_Office'
    },
    {
      'id': 'Filling stations', 'description': 'Occupation_Filling_Station'
    },
    {
      'id': 'Others', 'description': 'Occupation_Others'
    }
  ];

  buildingContentList = [
    {
      'id': 'On Electrical items', 'description': 'buildingContents_Electrical'
    },
    {
      'id': 'On Office Furniture', 'description': 'buildingContents_Office'
    },
    {
      'id': 'On Stock in Trade, Work-in Progress', 'description': 'buildingContents_Stock'
    },
    {
      'id': 'Wholesale/Retail Shop', 'description': 'buildingContents_Wholesale'
    },
    {
      'id': 'On Machinery & Utensils', 'description': 'buildingContents_Machinery'
    },
    {
      'id': 'On lifts/Generator/Escalator', 'description': 'buildingContents_lifts'
    }
  ];

  fullName: any;
  nicOrBusNo: any;
  proposer: any;
  addressPremises: any;
  occupation: any;
  buildingAmount: any;
  buildingContents: any;
  isSelectDamage1: any;
  isSelectDamage2: any;
  damageReason: any;
  applicationDate: any = new Date().toISOString().slice(0, 10);
  proposerSignature: any;
  section: number = 0;

  userDetails: any;

  @ViewChild('openSuccessModal', {static: true}) openSuccessModal;
  @ViewChild('openDraftModal', {static: true}) openDraftModal;

  draftDetails: any;
  id: any;
  userName: any;
  formType: any;
  status: any;
  isDraft: boolean = false;

  decryptedInfo: any;

  tempDamageReason: any;

  constructor(private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              public commonService: CommonService,
              private userRoleService: UserRoleService) {
    this.validationForm();
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.isDraft = params['isDRAFT'];
      if (params['isDRAFT']) {
        this.commonService.processing();

        this.draftDetails = JSON.parse(decodeURIComponent(params['data']));

        this.id = this.draftDetails['id'];

        this.coreService.fireInsuranceSLICRequestsById(this.id).subscribe(insuranceDetails => {
          Swal.close();

          if (insuranceDetails['isDamageProperty']) {
            this.isSelectDamage1 = true;
            this.isSelectDamage2 = false;
          } else {
            this.isSelectDamage2 = true;
            this.isSelectDamage1 = false;
          }

          this.userName = insuranceDetails['userName'];
          this.tempDamageReason = insuranceDetails['damageReason'];

          this.form.patchValue({
            addressPremises: insuranceDetails['addressPremises'],
            nicOrBusNo: insuranceDetails['nicOrBusNo'],
            fullName: insuranceDetails['fullName'],
            proposer: insuranceDetails['proposer'],
            occupation: insuranceDetails['occupation'],
            buildingAmount: insuranceDetails['buildingAmount'],
            buildingContents: insuranceDetails['buildingContents'],
            damageReason: insuranceDetails['damageReason'],
            applicationDate: new Date().toISOString().slice(0, 10),
            proposerSignature: insuranceDetails['proposerSignature'],
            isDamageProperty: insuranceDetails['isDamageProperty'],
            id: insuranceDetails['id'],
            created: insuranceDetails['created'],
            formType: insuranceDetails['formType'],
            status: insuranceDetails['status'],
          });

        });
      }

    });

    this.activeLang = sessionStorage.getItem('LANG');


    if (!this.isDraft) {
      this.fetchUserDetails();
    }

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }


  get f() {
    return this.form.controls;
  }

  validationForm() {
    this.form = this.formBuilder.group({
      addressPremises: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      nicOrBusNo: ['', [Validators.required]],
      fullName: ['', Validators.required],
      proposer: ['', Validators.required],
      occupation: ['', Validators.required],
      buildingAmount: ['', Validators.required],
      buildingContents: ['', Validators.required],
      isDamageProperty: [''],
      damageReason: [''],
      applicationDate: ['', Validators.required],
      proposerSignature: ['', Validators.required],
      id: [''],
      userName: [''],
      created: [''],
      formType: [''],
      status: ['']
    });
  }

  fetchUserDetails() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.userRoleService.fetchUserDetails(whoAmI['email']).subscribe(userDetails => {
        if (userDetails) {
          this.userDetails = userDetails;
          this.addressPremises = this.userDetails.address;
          this.fullName = this.userDetails.name;
          this.nicOrBusNo = this.userDetails.nic;
          this.form.patchValue({
            fullName: this.fullName,
            nicOrBusNo: this.nicOrBusNo,
            address: this.addressPremises,
            formType: 'FOR_FIRE_INSURANCE',
            userName: whoAmI['email'],
          });
          Swal.close();
        }
      });
    });
  }

  clickDropdown() {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  selectAnyDamage(b: boolean) {
    if (b) {
      this.isSelectDamage1 = true;
      this.isSelectDamage2 = false;
      if (this.tempDamageReason !== undefined) {
        this.damageReason = this.tempDamageReason;
      }
    } else {
      this.isSelectDamage2 = true;
      this.isSelectDamage1 = false;
      this.damageReason = undefined;
    }
    this.form.patchValue({
      isDamageProperty: b
    });
  }

  invalidRadioOption: boolean = false;

  submitForm(status) {

    if (this.isDraft) {
      this.form.patchValue({
        id: this.id,
        formType: 'FOR_FIRE_INSURANCE',
        userName: this.userName,
      });
    }

    if (this.form.invalid && status == 'PENDING') {
      return;
    }
    this.commonService.processing();

    this.form.patchValue({
      status: status
    });


    this.coreService.requestFireInsuranceSLIC(this.form.value).subscribe(result => {
      Swal.close();
      if (result['success']) {
        if (status === 'PENDING') {
          this.openSuccessModal.nativeElement.click();
        } else {
          this.openDraftModal.nativeElement.click();
        }
      }
    }, () => {
      Swal.close();
    });

  }

  nextPage() {

    if (!this.isSelectDamage1 && !this.isSelectDamage2) {
      this.invalidRadioOption = true;
    }

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.section = 1;
  }


  backPage() {
    this.section = 0;
  }

}
