import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../../../../../validation-handler/validation-handler.service';
import {CoreService} from '../../../../../service/core.service';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../../../../common/common.service';
import {UserRoleService} from '../../../../../service/user-role.service';
import Swal from 'sweetalert2';
import {InteractionService} from '../../../../../service/interaction.service';

@Component({
  selector: 'app-vehicle-insurance',
  templateUrl: './vehicle-insurance.html',
  styleUrls: ['./vehicle-insurance.css']
})
export class VehicleInsurance implements OnInit {
  section: number = 0;

  form: FormGroup;
  activeLang: any;
  submitted = false;

  fullName: any;
  address: any;
  nicOrBusNo: any;
  mobileNumber: any;
  email: any;
  stateTheNatureOfGood: any;
  ordinaryTransitRouters: any;
  isAnyVehiclesGaragedOrParked: boolean;
  isAnyVehiclesGaragedOrParkedButton1: boolean;
  isAnyVehiclesGaragedOrParkedButton2: boolean;
  vehiclesStateClosedBody: any;
  vehiclesCover: any = [];
  corporationInsurances: any;
  insurancePeriod: any;
  applicationDate: any = new Date().toISOString().slice(0, 10);
  proposerSignature: any;
  tradeOrBusiness: any;

  userDetails: any;
  insurancePeriodFrom: any;
  insurancePeriodTo: any;

  businessModel = [
    {
      'id': 'Join Venture', 'description': 'COMPANY.Join_Venture'
    },
    {
      'id': 'Partnership', 'description': 'COMPANY.Partnership'
    },
    {
      'id': 'Private Limited Company', 'description': 'COMPANY.Private_Limited_Company'
    },
    {
      'id': 'Public Limited Company', 'description': 'COMPANY.Public_Limited_Company'
    },
    {
      'id': 'Sole Proprietorship', 'description': 'COMPANY.Sole_Proprietorship'
    }
  ];

  @ViewChild('openSuccessModal', {static: true}) openSuccessModal;
  @ViewChild('openDraftModal', {static: true}) openDraftModal;

  draftDetails: any;
  id: any;
  userName: any;
  formType: any;
  status: any;
  isDraft: boolean = false;

  decryptedInfo: any;

  constructor(private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              public commonService: CommonService,
              private userRoleService: UserRoleService) {
    this.validationForm();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isDraft = params['isDRAFT'];
      if (params['isDRAFT']) {
        this.commonService.processing();

        this.draftDetails = JSON.parse(decodeURIComponent(params['data']));

        this.id = this.draftDetails['id'];

        this.coreService.vehicleInsuranceSLICRequestsById(this.id).subscribe(insuranceDetails => {
          Swal.close();

          if (insuranceDetails['isAnyVehiclesGaragedOrParked']) {
            this.isAnyVehiclesGaragedOrParkedButton1 = true;
            this.isAnyVehiclesGaragedOrParkedButton2 = false;
          } else {
            this.isAnyVehiclesGaragedOrParkedButton2 = true;
            this.isAnyVehiclesGaragedOrParkedButton1 = false;
          }

          if (insuranceDetails['vehiclesCover'].length > 0) {

            for (let i = 1; i <= insuranceDetails['vehiclesCover'].length; i++) {

              switch (i) {

                case 1:

                  this.vehiclesCover1Row0 = insuranceDetails['vehiclesCover'][0]['id'];
                  this.vehiclesCover1Row1 = insuranceDetails['vehiclesCover'][0]['itemNo'];
                  this.vehiclesCover1Row2 = insuranceDetails['vehiclesCover'][0]['registeredLetterNumber'];
                  this.vehiclesCover1Row3 = insuranceDetails['vehiclesCover'][0]['makeOfVehicle'];
                  this.vehiclesCover1Row4 = insuranceDetails['vehiclesCover'][0]['capacity'];
                  this.vehiclesCover1Row5 = insuranceDetails['vehiclesCover'][0]['minimumSumPerVehicle'];
                  break;

                case 2:

                  this.vehiclesCover2Row0 = insuranceDetails['vehiclesCover'][1]['id'];
                  this.vehiclesCover2Row1 = insuranceDetails['vehiclesCover'][1]['itemNo'];
                  this.vehiclesCover2Row2 = insuranceDetails['vehiclesCover'][1]['registeredLetterNumber'];
                  this.vehiclesCover2Row3 = insuranceDetails['vehiclesCover'][1]['makeOfVehicle'];
                  this.vehiclesCover2Row4 = insuranceDetails['vehiclesCover'][1]['capacity'];
                  this.vehiclesCover2Row5 = insuranceDetails['vehiclesCover'][1]['minimumSumPerVehicle'];
                  break;

                case 3:

                  this.vehiclesCover3Row0 = insuranceDetails['vehiclesCover'][2]['id'];
                  this.vehiclesCover3Row1 = insuranceDetails['vehiclesCover'][2]['itemNo'];
                  this.vehiclesCover3Row2 = insuranceDetails['vehiclesCover'][2]['registeredLetterNumber'];
                  this.vehiclesCover3Row3 = insuranceDetails['vehiclesCover'][2]['makeOfVehicle'];
                  this.vehiclesCover3Row4 = insuranceDetails['vehiclesCover'][2]['capacity'];
                  this.vehiclesCover3Row5 = insuranceDetails['vehiclesCover'][2]['minimumSumPerVehicle'];
                  break;

                case  4:

                  this.vehiclesCover4Row0 = insuranceDetails['vehiclesCover'][3]['id'];
                  this.vehiclesCover4Row1 = insuranceDetails['vehiclesCover'][3]['itemNo'];
                  this.vehiclesCover4Row2 = insuranceDetails['vehiclesCover'][3]['registeredLetterNumber'];
                  this.vehiclesCover4Row3 = insuranceDetails['vehiclesCover'][3]['makeOfVehicle'];
                  this.vehiclesCover4Row4 = insuranceDetails['vehiclesCover'][3]['capacity'];
                  this.vehiclesCover4Row5 = insuranceDetails['vehiclesCover'][3]['minimumSumPerVehicle'];
                  break;

                case  5:

                  this.vehiclesCover5Row0 = insuranceDetails['vehiclesCover'][4]['id'];
                  this.vehiclesCover5Row1 = insuranceDetails['vehiclesCover'][4]['itemNo'];
                  this.vehiclesCover5Row2 = insuranceDetails['vehiclesCover'][4]['registeredLetterNumber'];
                  this.vehiclesCover5Row3 = insuranceDetails['vehiclesCover'][4]['makeOfVehicle'];
                  this.vehiclesCover5Row4 = insuranceDetails['vehiclesCover'][4]['capacity'];
                  this.vehiclesCover5Row5 = insuranceDetails['vehiclesCover'][4]['minimumSumPerVehicle'];
                  break;

              }
            }


          }
          this.userName = insuranceDetails['userName'];
          this.form.patchValue({
            address: insuranceDetails['address'],
            nicOrBusNo: insuranceDetails['nicOrBusNo'],
            fullName: insuranceDetails['fullName'],
            mobileNumber: insuranceDetails['mobileNumber'],
            email: insuranceDetails['email'],
            tradeOrBusiness: insuranceDetails['tradeOrBusiness'],
            stateTheNatureOfGood: insuranceDetails['stateTheNatureOfGood'],
            ordinaryTransitRouters: insuranceDetails['ordinaryTransitRouters'],
            isAnyVehiclesGaragedOrParked: insuranceDetails['isAnyVehiclesGaragedOrParked'],
            vehiclesStateClosedBody: insuranceDetails['vehiclesStateClosedBody'],
            vehiclesCover: [''],
            corporationInsurances: insuranceDetails['corporationInsurances'],
            insurancePeriodFrom: new Date(insuranceDetails['insurancePeriodFrom']).toISOString().slice(0, 10),
            insurancePeriodTo: new Date(insuranceDetails['insurancePeriodTo']).toISOString().slice(0, 10),
            applicationDate: new Date().toISOString().slice(0, 10),
            proposerSignature: insuranceDetails['proposerSignature'],
            id: insuranceDetails['id'],
            created: insuranceDetails['created'],
            formType: insuranceDetails['formType'],
            status: insuranceDetails['status'],
          });

        }, () => {
          Swal.close();
        });

      }
    });
    this.activeLang = sessionStorage.getItem('LANG');
    if (!this.isDraft) {
      this.fetchUserDetails();
    }

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  get f() {
    return this.form.controls;
  }


  vehiclesCover1Row0: any = null;
  vehiclesCover1Row1: any;
  vehiclesCover1Row2: any;
  vehiclesCover1Row3: any;
  vehiclesCover1Row4: any;
  vehiclesCover1Row5: any;

  vehiclesCover2Row0: any = null;
  vehiclesCover2Row1: any;
  vehiclesCover2Row2: any;
  vehiclesCover2Row3: any;
  vehiclesCover2Row4: any;
  vehiclesCover2Row5: any;

  vehiclesCover3Row0: any = null;
  vehiclesCover3Row1: any;
  vehiclesCover3Row2: any;
  vehiclesCover3Row3: any;
  vehiclesCover3Row4: any;
  vehiclesCover3Row5: any;

  vehiclesCover4Row0: any = null;
  vehiclesCover4Row1: any;
  vehiclesCover4Row2: any;
  vehiclesCover4Row3: any;
  vehiclesCover4Row4: any;
  vehiclesCover4Row5: any;

  vehiclesCover5Row0: any = null;
  vehiclesCover5Row1: any;
  vehiclesCover5Row2: any;
  vehiclesCover5Row3: any;
  vehiclesCover5Row4: any;
  vehiclesCover5Row5: any;
  invalidRadioOption: boolean = false;

  validationForm() {
    this.form = this.formBuilder.group({
      address: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      nicOrBusNo: ['', [Validators.required]],
      fullName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      email: ['', Validators.required],
      tradeOrBusiness: ['', Validators.required],
      stateTheNatureOfGood: ['', Validators.required],
      ordinaryTransitRouters: ['', Validators.required],
      isAnyVehiclesGaragedOrParked: ['', Validators.required],
      vehiclesStateClosedBody: ['', Validators.required],
      vehiclesCover: [''],
      corporationInsurances: [''],
      insurancePeriodFrom: ['', Validators.required],
      insurancePeriodTo: ['', Validators.required],
      applicationDate: ['', Validators.required],
      proposerSignature: ['', Validators.required],
      id: [''],
      userName: [''],
      created: [''],
      formType: [''],
      status: ['']
    });
  }

  fetchUserDetails() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.userRoleService.fetchUserDetails(whoAmI['email']).subscribe(userDetails => {
        if (userDetails) {
          this.userDetails = userDetails;
          this.address = this.userDetails.address;
          this.fullName = this.userDetails.name;
          this.nicOrBusNo = this.userDetails.nic;
          this.email = this.userDetails.email;
          this.mobileNumber = this.userDetails.contactNo;
          this.form.patchValue({
            fullName: this.fullName,
            nicOrBusNo: this.nicOrBusNo,
            address: this.address,
            mobileNumber: this.mobileNumber,
            email: this.email,
            formType: 'FOR_VEHICLE_INSURANCE',
            userName: whoAmI['email'],
          });
          Swal.close();
        }
      });
    });
  }


  clickDropdown() {
    this.activeLang = sessionStorage.getItem('LANG');
  }

  setVehicleOrGaraged(status) {
    this.isAnyVehiclesGaragedOrParked = status;
    this.form.patchValue({
      isAnyVehiclesGaragedOrParked: this.isAnyVehiclesGaragedOrParked
    });
  }

  commonVehiclesCoverBody(id, itemNo, registeredLetterNumber, makeOfVehicle, capacity, minimumSumPerVehicle) {
    return {
      'id': id,
      'itemNo': itemNo,
      'registeredLetterNumber': registeredLetterNumber,
      'makeOfVehicle': makeOfVehicle,
      'capacity': capacity,
      'minimumSumPerVehicle': minimumSumPerVehicle
    };
  }

  submitForm(status) {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.vehiclesCover1Row1 !== undefined && this.vehiclesCover1Row2 !== undefined && this.vehiclesCover1Row3 !== undefined
      && this.vehiclesCover1Row4 !== undefined && this.vehiclesCover1Row5 !== undefined) {
      this.vehiclesCover.push(this.commonVehiclesCoverBody(this.vehiclesCover1Row0,
        this.vehiclesCover1Row1,
        this.vehiclesCover1Row2,
        this.vehiclesCover1Row3,
        this.vehiclesCover1Row4,
        this.vehiclesCover1Row5));
    } else {
      this.vehiclesCover1Row1 = undefined;
      this.vehiclesCover1Row2 = undefined;
      this.vehiclesCover1Row3 = undefined;
      this.vehiclesCover1Row4 = undefined;
      this.vehiclesCover1Row5 = undefined;
    }

    if (this.vehiclesCover2Row1 !== undefined && this.vehiclesCover2Row2 !== undefined && this.vehiclesCover2Row3 !== undefined
      && this.vehiclesCover2Row4 !== undefined && this.vehiclesCover2Row5 !== undefined) {
      this.vehiclesCover.push(this.commonVehiclesCoverBody(this.vehiclesCover2Row0,
        this.vehiclesCover2Row1,
        this.vehiclesCover2Row2,
        this.vehiclesCover2Row3,
        this.vehiclesCover2Row4,
        this.vehiclesCover2Row5));
    } else {
      this.vehiclesCover2Row1 = undefined;
      this.vehiclesCover2Row2 = undefined;
      this.vehiclesCover2Row3 = undefined;
      this.vehiclesCover2Row4 = undefined;
      this.vehiclesCover2Row5 = undefined;
    }

    if (this.vehiclesCover3Row1 !== undefined && this.vehiclesCover3Row2 !== undefined && this.vehiclesCover3Row3 !== undefined
      && this.vehiclesCover3Row4 !== undefined && this.vehiclesCover3Row5 !== undefined) {
      this.vehiclesCover.push(this.commonVehiclesCoverBody(this.vehiclesCover3Row0,
        this.vehiclesCover3Row1,
        this.vehiclesCover3Row2,
        this.vehiclesCover3Row3,
        this.vehiclesCover3Row4,
        this.vehiclesCover3Row5));
    } else {
      this.vehiclesCover3Row1 = undefined;
      this.vehiclesCover3Row2 = undefined;
      this.vehiclesCover3Row3 = undefined;
      this.vehiclesCover3Row4 = undefined;
      this.vehiclesCover3Row5 = undefined;
    }

    if (this.vehiclesCover4Row1 !== undefined && this.vehiclesCover4Row2 !== undefined && this.vehiclesCover4Row3 !== undefined
      && this.vehiclesCover4Row4 !== undefined && this.vehiclesCover4Row5 !== undefined) {
      this.vehiclesCover.push(this.commonVehiclesCoverBody(this.vehiclesCover4Row0,
        this.vehiclesCover4Row1,
        this.vehiclesCover4Row2,
        this.vehiclesCover4Row3,
        this.vehiclesCover4Row4,
        this.vehiclesCover4Row5));
    } else {
      this.vehiclesCover4Row1 = undefined;
      this.vehiclesCover4Row2 = undefined;
      this.vehiclesCover4Row3 = undefined;
      this.vehiclesCover4Row4 = undefined;
      this.vehiclesCover4Row5 = undefined;
    }

    if (this.vehiclesCover5Row1 !== undefined && this.vehiclesCover5Row2 !== undefined && this.vehiclesCover5Row3 !== undefined
      && this.vehiclesCover5Row4 !== undefined && this.vehiclesCover5Row5 !== undefined) {
      this.vehiclesCover.push(this.commonVehiclesCoverBody(this.vehiclesCover5Row0,
        this.vehiclesCover5Row1,
        this.vehiclesCover5Row2,
        this.vehiclesCover5Row3,
        this.vehiclesCover5Row4,
        this.vehiclesCover5Row5));
    } else {
      this.vehiclesCover5Row1 = undefined;
      this.vehiclesCover5Row2 = undefined;
      this.vehiclesCover5Row3 = undefined;
      this.vehiclesCover5Row4 = undefined;
      this.vehiclesCover5Row5 = undefined;
    }

    if (this.vehiclesCover.length === 0) {
      return;
    }

    this.form.patchValue({
      'vehiclesCover': this.vehiclesCover
    });


    if (this.isDraft) {
      this.form.patchValue({
        id: this.id,
        formType: 'FOR_VEHICLE_INSURANCE',
        userName: this.userName,
      });
    }

    if (this.form.invalid && status == 'PENDING') {
      return;
    }
    this.commonService.processing();

    this.form.patchValue({
      status: status
    });


    this.coreService.requestVehicleInsuranceSLIC(this.form.value).subscribe(result => {
      Swal.close();
      if (result['success']) {
        if (status === 'PENDING') {
          this.openSuccessModal.nativeElement.click();
        } else {
          this.openDraftModal.nativeElement.click();
        }
      }
    }, () => {
      Swal.close();
    });
  }

  calTotal() {
    let total = 0;

    if (this.vehiclesCover1Row5 !== undefined && this.vehiclesCover1Row5 !== null) {
      total = total + parseFloat(this.vehiclesCover1Row5);
    }
    if (this.vehiclesCover2Row5 !== undefined && this.vehiclesCover2Row5 !== null) {
      total = total + parseFloat(this.vehiclesCover2Row5);
    }
    if (this.vehiclesCover3Row5 !== undefined && this.vehiclesCover3Row5 !== null) {
      total = total + parseFloat(this.vehiclesCover3Row5);
    }
    if (this.vehiclesCover4Row5 !== undefined && this.vehiclesCover4Row5 !== null) {
      total = total + parseFloat(this.vehiclesCover4Row5);
    }
    if (this.vehiclesCover5Row5 !== undefined && this.vehiclesCover5Row5 !== null) {
      total = total + parseFloat(this.vehiclesCover5Row5);
    }

    return total;
  }


  nextPage() {

    if(!this.isAnyVehiclesGaragedOrParkedButton1 && !this.isAnyVehiclesGaragedOrParkedButton2){
      this.invalidRadioOption = true;
    }

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.section = 1;
  }


  backPage() {
    this.section = 0;
  }
}
