import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {WholesaleBuyerCountryDTO} from '../../../dto/registration/WholesaleBuyerCountryDTO';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {CommonSignUpService} from '../../../service/common-sign-up.service';
import {ProductService} from '../../../service/product.service';
import {Router} from '@angular/router';
import {MatChipInputEvent} from '@angular/material/chips';
import {InteractionService} from '../../../service/interaction.service';
import {MatCheckboxChange} from '@angular/material';
import {IdentityService} from '../../../service/identity.service';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {CityFilter} from '../../../dto/filter/cityFilter';
import {DivSecFilter} from '../../../dto/filter/divSecFilter';
import {GramaNilFilter} from '../../../dto/filter/gramaNilFilter';
import {map, startWith} from 'rxjs/operators';
import {SmsService} from '../../../service/sms.service';
import {TranslateService} from '@ngx-translate/core';
import {CoreService} from '../../../service/core.service';

@Component({
  selector: 'app-sign-up-whole-sale-buyer',
  templateUrl: './sign-up-whole-sale-buyer.component.html',
  styleUrls: ['./sign-up-whole-sale-buyer.component.css']
})
export class SignUpWholeSaleBuyerComponent implements OnInit {

  signUpBuyer: FormGroup;
  submitted = false;

  name: any;
  email: any;
  username: any;
  password: any;
  rePassword: any;
  mobile: any;
  nic: any;
  address: any;

  visible = true;
  tenantValue: any;

  selectedNatureGoods = [];
  selectedUnitType: any;

  allProvinceList: any;
  allDistrictList: any;
  allDivisionList: any;
  allGramaList: any;

  selectedProvinceId: any;
  selectedDistrictId: any;
  selectedDivisionalId: any;
  selectedGramaId: any;

  purchaseVolumePerWeek: any;
  purchaseVolumeUnit: any;

  isExport: boolean = false;


  activeLang: string = 'en';
  agreeWithUs: boolean = false;

  incorrectNIC: boolean = false;
  incorrectMobile: boolean = false;
  incorrectEmail: boolean = false;
  incorrectUsername: boolean = false;
  incorrectUsernamePattern: boolean = false;
  isInValidVolume = false;
  isInValidUnit = false;
  allCities: any;
  selectedCity: any;
  address1: any;
  address2: any;
  postalCode: any;
  cityName: any;

  isAgentFarmer: boolean = false;
  referralNumber: any;

  landUnit: any = [
    {'id': 52, 'description': 'UNIT_TYPE.Kg'},
    {'id': 53, 'description': 'UNIT_TYPE.Tons'},
  ];

  countries = [
    {buyerCountryId: 0, countryCode: 'Sri Lanka'},
    {buyerCountryId: 1, countryCode: 'Japan'},
    {buyerCountryId: 2, countryCode: 'Australia'},
  ];

  allCategories: any = [
    {'id': 1, 'description': 'CATEGORY.FRUITS'},
    {'id': 2, 'description': 'CATEGORY.VEGETABLES'},
    {'id': 3, 'description': 'CATEGORY.PLANTS_SEEDS'},
    {'id': 4, 'description': 'CATEGORY.FERTILIZER'},
    {'id': 5, 'description': 'CATEGORY.SERVICES'},
    {'id': 6, 'description': 'CATEGORY.OTHER_SUPPLIES'}
  ];
  cityObject: any;

  // CityFilter
  allCitiesUD: CityFilter[] = [];
  myControl = new FormControl();
  filteredOptions: Observable<CityFilter[]>;
  city: any = '';
  isCitySelected: boolean = false;
  cityUDObject: CityFilter;

  // DeviFilter
  myControlDevi = new FormControl();
  divi: any = '';
  filteredOptionsDivi: Observable<DivSecFilter[]>;
  allDivSecUD: DivSecFilter[] = [];
  divSecObject: DivSecFilter;
  isDiviSelected: boolean = false;

  // GramaFilter
  myControlGrama = new FormControl();
  grama: any = '';
  filteredOptionsGrama: Observable<GramaNilFilter[]>;
  allGramaUD: GramaNilFilter[] = [];
  gramaNilObject: GramaNilFilter;
  isGramaSelected: boolean = false;

  language: any = 'EN';

  @ViewChild('optModal', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;

  constructor(private _formBuilder: FormBuilder,
              private validationHandlerService: ValidationHandlerService,
              private commonSignUpService: CommonSignUpService,
              private productService: ProductService,
              private smsService: SmsService,
              private _translateService: TranslateService,
              private identityService: IdentityService,
              private coreService: CoreService,
              private interactionService: InteractionService,
              private router: Router
  ) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

  }

  ngOnInit() {
    this.getAllProvince();
    this.reactiveFormBuyer();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
        this.getAllProvince();
      }
    });
  }

  reactiveFormBuyer() {
    this.signUpBuyer = this._formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(this.validationHandlerService.nameValidation())]],
      email: ['', [Validators.pattern(this.validationHandlerService.emailValidation())]],
      // password: ['', [Validators.required, Validators.pattern(this.validationHandlerService.passwordValidation())]],
      // rePassword: ['', Validators.required],
      // mobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.mobileNumberValidation())]],
      mobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyTenNumberValidation())]],
      nic: ['', [Validators.required, Validators.pattern(this.validationHandlerService.sriLankaNICValidation())]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      purchaseVolumeUnit: [''],
      province: ['', Validators.required],
      district: ['', Validators.required],
      division: ['', Validators.required],
      gramaList: ['', Validators.required],
      referralNumber: [''],
      language: [''],
      username: ['', [Validators.required, Validators.pattern(this.validationHandlerService.userNameVerification())]],
      // exportCountries: [''],
      purchaseVolumePerWeek: ['', [Validators.pattern(this.validationHandlerService.purchaseVolumeValidation())]],
    }, {
      // validator: this.validationHandlerService.MustMatch('password', 'rePassword')
    });
  }

  get f() {
    return this.signUpBuyer.controls;
  }

  submitBuyer() {
    this.submitted = true;
    this.isInValidUnit = false;
    this.isInValidVolume = false;

    if (this.signUpBuyer.invalid) {
      return;
    }

    if ((this.purchaseVolumePerWeek !== undefined && this.purchaseVolumePerWeek !== null) &&
      (this.purchaseVolumeUnit === undefined || this.purchaseVolumeUnit === null)) {
      this.isInValidUnit = true;
      return;
    }

    if ((this.purchaseVolumePerWeek === undefined || this.purchaseVolumePerWeek === null || this.purchaseVolumePerWeek === '') &&
      (this.purchaseVolumeUnit !== undefined && this.purchaseVolumeUnit !== null)) {
      this.isInValidVolume = true;
      return;
    }


    if (this.isCitySelected === false) {
      Swal.fire({
        type: 'error',
        title: 'please select city correctly'
      });
    } else if (this.isDiviSelected === false) {
      Swal.fire({
        type: 'error',
        title: 'please select divisional secretariat correctly'
      });
    } else if (this.isGramaSelected === false) {
      Swal.fire({
        type: 'error',
        title: 'please select Grama niladhari area correctly'
      });
    } else {
      if (this.referralNumber !== undefined && this.referralNumber !== '') {
        this.coreService.checkLeadCodeExist(this.referralNumber).subscribe(result => {
          if (result.success === true) {
            this.loading();
            this.isOTPModalClose = false;
            let _requestOTP = {
              'telNo': [
                this.mobile
              ],
              'username': this.username,
              'systemUsername': this.name,
              'code': 'OTP',
              'language': this.language
            };
            this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
              Swal.close();
              if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
                this.OTPValidation(60 * 3, document.getElementById('time'));
              }
              this.optModal.nativeElement.click();
            }, () => {
              Swal.close();
            });
          } else {
            Swal.fire({
              type: 'error',
              title: 'Please enter valid lead reference code'
            });
          }

        }, () => {
          Swal.fire({
            type: 'error',
            title: 'Please enter valid lead reference code'
          });
        });
      } else {
        this.loading();
        this.isOTPModalClose = false;
        let _requestOTP = {
          'telNo': [
            this.mobile
          ],
          'username': this.username,
          'systemUsername': this.name,
          'code': 'OTP',
          'language': this.language
        };
        this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
          Swal.close();
          if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
            this.OTPValidation(60 * 3, document.getElementById('time'));
          }
          this.optModal.nativeElement.click();
        }, () => {
          Swal.close();
        });
      }

    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.countries.push({buyerCountryId: 0, countryCode: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  remove(country: WholesaleBuyerCountryDTO): void {
    const index = this.countries.indexOf(country);

    if (index >= 0) {
      this.countries.splice(index, 1);
    }
  }

  saveWholesaleBuyer() {
    this.loading();

    let _tempNatureGoods = [];
    if (this.selectedNatureGoods.length > 0) {
      for (let waterResource of this.selectedNatureGoods) {
        this._translateService.get(waterResource).subscribe(lang => {
          _tempNatureGoods.push(lang);
        });
      }
    }

    let arr = [];
    for (let x of _tempNatureGoods) {
      arr.push({
        'mainCategoryCode': x
      });
    }

    if (this.address2 === '' || this.address2 === undefined || this.address2 === null) {
      this.address2 = '';
      this.address = this.address1 + ',' + this.cityName;
    } else {
      this.address = this.address1 + ',' + this.address2 + ',' + this.cityName;
    }

    if (this.email === '' || this.email === null || this.email === undefined) {
      this.email = null;
    }

    let wholesaleBuyerBody = {
      'nic': this.nic,
      'email': this.email,
      'name': this.name,
      'address': this.address,
      'pin': '',
      'active': false,
      'contactNo': this.mobile,
      'username': this.username,
      'isExport': this.isExport,
      'purchaseVolumePerWeek': this.purchaseVolumePerWeek,
      'purchaseVolumeUnit': this.purchaseVolumeUnit,
      'gramaNiladari': {
        'id': this.selectedGramaId
      },
      'wholesaleBuyerCountryCollection': [],
      'wholesaleBuyerCategoryCollection': arr,
      'addressOne': this.address1,
      'addressTwo': this.address2,
      'addressType': 1,
      'cityId': this.selectedCity,
      'agentRef': this.referralNumber,
      'countryId': 1,
      'postalCode': this.postalCode

    };

    // to the update sms sending language
    let body = {
      'username': this.username,
      'language': this.language
    };

    this.smsService.saveLanguage(body).subscribe(() => {

      this.commonSignUpService.saveWholesaleBuyer(wholesaleBuyerBody).subscribe((result) => {
        if (result) {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.manageLocalizationSuccessTittle(sessionStorage.getItem('LANG')),
            text: this.validationHandlerService.manageLocalizationSuccessBodyMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          }).then(() => {
            this.signUpBuyer.reset();
            this.router.navigate(['/home/' + localStorage.getItem('TENANT_VALUE')]);

          });
          const title = Swal.getContent();
          title.style.textAlign = 'center';
        } else {
          Swal.fire({
            type: 'error',
            title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          });
        }
      }, (error) => {
        Swal.fire({
          type: 'error',
          title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        });
        console.log('Wholesale buyer save function :' + error);
      });
    });
  }

  clearErr() {
    this.isInValidVolume = false;
  }

  selectIsExport(event) {
    this.isExport = !!event.checked;
  }

  wholesaleNatureGoods($event) {
    // do something
  }

  selectUnitType(ev) {
    this.selectedUnitType = ev;
    this.isInValidUnit = false;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.allProvinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.allDistrictList = result;
      }
    });

  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
        this.allCitiesUD = [];
        for (let city of result) {
          this.cityUDObject = new CityFilter();
          this.cityUDObject.id = city.id;
          this.cityUDObject.cityName = city.nameEN;
          this.cityUDObject.nameEN = city.nameEN;
          this.cityUDObject.nameSI = city.nameSI;
          this.cityUDObject.nameTA = city.nameTA;
          this.allCitiesUD.push(this.cityUDObject);
        }

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filter(state) : this.allCitiesUD.slice())
          );
      }
    });
  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.allDivisionList = result;
        this.allDivSecUD = [];
        for (let div of result) {
          this.divSecObject = new DivSecFilter();
          this.divSecObject.id = div.id;
          this.divSecObject.name = div.nameEN;
          this.divSecObject.nameEN = div.nameEN;
          this.divSecObject.nameSI = div.nameSI;
          this.divSecObject.nameTA = div.nameTA;

          this.allDivSecUD.push(this.divSecObject);
        }

        this.filteredOptionsDivi = this.myControlDevi.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterDivi(state) : this.allDivSecUD.slice())
          );
      }
    });
  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.allGramaList = result;
        this.allGramaUD = [];
        for (let div of result) {
          this.gramaNilObject = new GramaNilFilter();
          this.gramaNilObject.id = div.id;
          this.gramaNilObject.name = div.nameEN;
          this.gramaNilObject.nameEN = div.nameEN;
          this.gramaNilObject.nameSI = div.nameSI;
          this.gramaNilObject.nameTA = div.nameTA;

          this.allGramaUD.push(this.gramaNilObject);
        }

        this.filteredOptionsGrama = this.myControlGrama.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterGrama(state) : this.allGramaUD.slice())
          );
      }
    });
  }


  private _filter(value: string): CityFilter[] {
    if (value != '[object Object]') {
      this.isCitySelected = value === this.city;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allCitiesUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allCitiesUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allCitiesUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isCitySelected = true;
    }

  }


  private _filterDivi(value: string): DivSecFilter[] {
    if (value != '[object Object]') {
      this.isDiviSelected = value === this.divi;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allDivSecUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allDivSecUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allDivSecUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isDiviSelected = true;
    }

  }

  private _filterGrama(value: string): GramaNilFilter[] {
    if (value != '[object Object]') {
      this.isGramaSelected = value === this.grama;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allGramaUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allGramaUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allGramaUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isGramaSelected = true;
    }

  }

  selectProvince(event: any) {
    this.selectedProvinceId = event;
    this.getAllDistrict(event);
    this.allDivisionList = [];
    this.allGramaList = [];
  }

  selectDivisional(event: any) {
    if (this.activeLang === 'en') {
      this.divi = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.divi = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.divi = event.nameTA;
    }
    this.isDiviSelected = true;
    this.selectedDivisionalId = event.id;
    this.getAllGramaNiladari(event.id);
  }

  selectDistrict(event: any) {
    this.selectedDistrictId = event;
    this.getAllCities(event);
    this.getAllDivSec(event);
    this.allGramaList = [];
  }

  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.postalCode = result['postalCode'];
      }
    });
  }

  selectCity(event: any) {

    if (this.activeLang === 'en') {
      this.city = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.city = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.city = event.nameTA;
    }
    this.cityName = event.nameEN;

    this.isCitySelected = true;
    this.selectedCity = event.id;
    this.getCityById(event.id);
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }

  selectGrama(event: any) {
    if (this.activeLang === 'en') {
      this.grama = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.grama = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.grama = event.nameTA;
    }
    this.isGramaSelected = true;
    this.selectedGramaId = event.id;
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  agreeWithAgreement($event: MatCheckboxChange) {
    this.agreeWithUs = $event.checked;
  }

  checkExists(value, type) {
    switch (type) {

      case 'MOBILE':
        if (value !== undefined) {
          this.identityService.checkContactNoExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'CONTACT_NO_NOT_EXISTS') {
              this.incorrectMobile = false;
              this.signUpBuyer.updateValueAndValidity();
            } else if (isExists['message'] === 'CONTACT_NO_EXISTS') {
              this.incorrectMobile = true;
              this.signUpBuyer.controls['mobile'].setErrors(value);
            }
          });
        }

        break;

      case 'NIC':
        if (value !== undefined) {
          this.identityService.checkNICExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'NIC_NOT_EXISTS') {
              this.incorrectNIC = false;
              this.signUpBuyer.updateValueAndValidity();
            } else if (isExists['message'] === 'NIC_EXISTS') {
              this.incorrectNIC = true;
              this.signUpBuyer.controls['nic'].setErrors(value);
            }
          });
        }


        break;

      case 'USERNAME':
        if (value !== undefined) {

          // Cannot be only numbers ( Username should include letters and cannot be only numbers)
          //
          // Letters must be included ( Username should include letters)
          //
          // No Spaces and it has to be one word. (Username cannot have any spaces)

          let boolean = this.validationHandlerService.usernameValidationPattern(value);

          if (boolean) {
            this.incorrectUsernamePattern = true;
            this.signUpBuyer.controls['username'].setErrors(value);
          } else {
            this.incorrectUsernamePattern = false;
            this.signUpBuyer.updateValueAndValidity();
          }

          this.identityService.checkUsernameExists(value).subscribe(isExists => {
            if (isExists['message'] === 'USERNAME_NOT_EXISTS') {
              this.incorrectUsername = false;
              this.signUpBuyer.updateValueAndValidity();
            } else if (isExists['message'] === 'USERNAME_EXISTS') {
              this.incorrectUsername = true;
              this.signUpBuyer.controls['username'].setErrors(value);
            }
          });

        }

        break;

      case 'EMAIL':
        if (value !== undefined) {
          this.identityService.checkEmailExists(value).subscribe(isExists => {
            if (isExists['message'] === 'EMAIL_NOT_EXISTS') {
              this.incorrectEmail = false;
              this.signUpBuyer.updateValueAndValidity();
            } else if (isExists['message'] === 'EMAIL_EXISTS') {
              this.incorrectEmail = true;
              this.signUpBuyer.controls['email'].setErrors(value);
            }
          });
        }
        break;

    }

  }


  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;

  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    let x = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (this.isOTPModalClose) {
        clearInterval(x);
        document.getElementById('time').textContent = '03:00';
        return;
      }

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(x);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    this.isInvalid = false;
    this.ngOtpInputRef.setValue('');
    clearInterval();
  }

  resendOTPCode() {
    this.ngOtpInputRef.setValue('');
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time').textContent = '03:00';
    this.OTPValidation(60 * 3, document.getElementById('time'));
    this.isShowResendButton = false;

    let _requestOTP = {
      'telNo': [
        this.mobile
      ],
      'username': this.username,
      'systemUsername': this.name,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
    });
  }

  verifyOTP() {
    this.isLoading = true;
    let verifyOTPBody = {
      'username': this.username,
      'otp': this.OTP_CODE
    };
    this.smsService.verifyOTPCode(verifyOTPBody).subscribe(isVerify => {
      if (isVerify) {
        this.isInvalid = false;
        this.isLoading = false;
        this.closeModal.nativeElement.click();
        this.saveWholesaleBuyer();
      } else {
        this.isInvalid = true;
        this.isLoading = false;
      }
    }, () => {
      this.isInvalid = true;
      this.isLoading = false;
    });
  }

  saveLanguage(event) {
    this.language = event;
  }

}
