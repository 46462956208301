import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {CommonService} from '../../../common/common.service';
import {ProductService} from '../../../service/product.service';
import {UserRoleService} from '../../../service/user-role.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {MainUrlService} from '../../../service/main-url.service';
import Swal from 'sweetalert2';
import {MyBidProductsDTO} from '../../../dto/myBidProductsDTO';
import {InteractionService} from '../../../service/interaction.service';

@Component({
  selector: 'app-bids-auctions',
  templateUrl: './bids-auctions.component.html',
  styleUrls: ['./bids-auctions.component.css']
})
export class BidsAuctionsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['orderTitle', 'numberOfBids', 'bidAmount', 'bidEndDate', 'status'];
  tenantValue: any;
  transactionTrimValue: any;
  dataSource: MatTableDataSource<any>;

  myBidProducts: MyBidProductsDTO[] = [];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  rate: any;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(public commonService: CommonService,
              private productService: ProductService,
              private userRoleService: UserRoleService,
              private router: Router,
              private interaction:InteractionService,
              private http: HttpClient,
              private settingsService: SystemSettingsService,
              public mainUrl: MainUrlService) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

  ngOnInit() {
    this.loadBidProducts();
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'TRANSACTION_ID_LENGTH') {
            this.transactionTrimValue = setting.value;
          }
        }
      }
    });
  }

  loadBidProducts() {
    this.commonService.processing();
    this.dataSource = new MatTableDataSource();
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.productService.fetchAllBidProductByUsername(whoAmIDetails['email']).subscribe(getMyBidProducts => {
        Swal.close();
        for (let item of getMyBidProducts) {
          let _amount;
          if (item.bidDetails.length > 0) {
            _amount = item.bidDetails[0].bidValue;
          } else {
            _amount = item.startPrice;
          }
          let myBid = new MyBidProductsDTO(
            item.product['name'],
            item.bidDetails.length,
            _amount,
            item.bidStartDate,
            item.bidEndDate,
            item.bidStatus
          );
          this.myBidProducts.push(myBid);
        }
        this.dataSource = new MatTableDataSource(this.myBidProducts);
        this.dataSource.paginator = this.paginator;
      }, () => {
        Swal.close();
      });
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

}
