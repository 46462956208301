<div class="tp-wrapper">
  <h2>{{'REGISTER.TRANSPORT_PROVIDER.TRANSPORT_PROVIDER_1' |translate}}</h2>
  <p>{{'REGISTER.TRANSPORT_PROVIDER.DOWNLOAD_LINK' |translate}}</p>
  <div class="img-wp">
    <img alt="" src="../../../../assets/images/temp/app-view.png" style="width: 100%; max-width: 320px;"/>
  </div>
  <h3>{{'REGISTER.TRANSPORT_PROVIDER.HELAVIRU_APP' |translate}}</h3>

  <div class="tp-store-links">
    <a href="https://play.google.com/store/apps/details?id=com.riyaviru&hl=en">
      <img alt="Playstore" src="../../../../assets/images/playstore.svg"/>
    </a>
    <a href="https://helaviru.lk/">
      <img alt="Appstore" src="../../../../assets/images/appstore.svg"/>
    </a>
  </div>

  <a class="tph-link" href="https://helaviru.lk/">www.helaviru.lk/apps</a>

  <p style="font-size: 13px;">{{'REGISTER.TRANSPORT_PROVIDER.AGREE_CONDITIONS.AGREE_CONDITIONS' |translate}}<br/>
    {{'REGISTER.TRANSPORT_PROVIDER.AGREE_CONDITIONS.AGREE_CONDITIONS_1' |translate}}<a
      data-target=".bd-example-modal-lg"
      data-toggle="modal"
      href="">
      {{'REGISTER.TRANSPORT_PROVIDER.AGREE_CONDITIONS.AGREE_CONDITIONS_2' |translate}}
      {{'REGISTER.TRANSPORT_PROVIDER.AGREE_CONDITIONS.AGREE_CONDITIONS_3' |translate}}
      {{'REGISTER.TRANSPORT_PROVIDER.AGREE_CONDITIONS.AGREE_CONDITIONS_4' |translate}}</a>.</p>
</div>


<div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-lg">
    <app-riyaviru-terms></app-riyaviru-terms>
  </div>
</div>
