import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductService} from '../service/product.service';
import {MainUrlService} from '../service/main-url.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../service/core.service';
import {InteractionService} from '../service/interaction.service';
import {SystemSettingsService} from '../service/system-settings.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {CommonService} from '../common/common.service';
import {Subject} from 'rxjs';
import {UserRoleService} from '../service/user-role.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  weatherRahu: any;
  weatherBadDirection: any;
  weatherGoodDirection: any;

  spinner0: number = 1;
  spinner2: number = 1;

  allCategory: any;

  load = 0;
  showmsg = 1;

  homeSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    showNavigationIndicators: true,
  };

  appSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    showNavigationIndicators: true,
  };

  dealSlideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 8000,
    showNavigationIndicators: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  vasSlideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    showNavigationIndicators: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  CURRENCY_CODE;
  allBanners: any;
  tenantValue: any;
  sliderTitle: any;
  slider1: any;
  slider2: any;
  slider3: any;
  slider4: any;
  allAdverts: any;

  profileCode: any;
  isLog: boolean = false;
  activeLang: string = 'en';
  PRD_ADDR_CNAME: any;
  FOOTER_INTRO: any;
  newArrivalList: any;
  userRole: any;
  newArrival = [];
  isSellButtonShow: boolean = true;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private titleService: Title,
              private core_service: CoreService,
              private router: Router,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private interactionService: InteractionService,
              private _translateService: TranslateService,
              private setting_service: SystemSettingsService,
              private commonService: CommonService) {

    this.slider1 = 'assets/defaultSlider.jpg';
    this.slider2 = 'assets/defaultSlider.jpg';
    this.slider3 = 'assets/defaultSlider.jpg';
    this.slider4 = 'assets/defaultSlider.jpg';

    this.tenantValue = localStorage.getItem('TENANT_VALUE');
    this.activeLang = sessionStorage.getItem('LANG');
    this.userRole = localStorage.getItem('USER_ROLE');

    this.manageSellButton();

    if (this.tenantValue === null) {
      let a = document.createElement('a');
      a.href = '/';
      a.click();
    }
    this.fetchAll();
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.fetchAll();
      }
    });
  }

  ngOnInit() {
    this.commonService.adminStatusManage();

    this.interactionService.sellButtonHomepage.subscribe(() => {
      this.manageSellButton();
    });

    this.interactionService.registrationButtons.subscribe(buttonStatus => {
      this.isLog = buttonStatus;
    });
  }


  manageSellButton() {
    let isLoggedIn = localStorage.getItem('isLogged');
    this.userRole = localStorage.getItem('USER_ROLE');

    if (isLoggedIn !== null && isLoggedIn === 'True') {
      this.isLog = isLoggedIn === 'True';

      this.isSellButtonShow = this.userRole === 'FARMER'
        || this.userRole === 'SERVICE_PROVIDER'
        || this.userRole === 'COMPANY_FARMER'
        || this.userRole === 'ECONOMIC_SHOP'
        || this.userRole === 'COMPANY_AGGREGATOR'
        || this.userRole === 'COMPANY_BUYER'
        || this.userRole === 'BUYER'
        || this.userRole === 'AGGREGATOR';
    } else {
      this.isSellButtonShow = true;
    }
  }

  fetchAll() {
    this.productService.getActiveProfile().subscribe((profileCode) => {
      if (profileCode) {
        this.productService.fetchAllNewArrivalSpotProducts(20, profileCode.code, this.tenantValue)
          .subscribe(newArrivalProducts => {
            if (newArrivalProducts) {
              this.newArrival = [];
              for (let product of newArrivalProducts) {
                if (product.stockAvailable !== 0 && product.type.type === 'SPOT') {
                  this.newArrival.push(product);
                }
              }
              this.newArrivalList = this.newArrival;
            }
          });

      }
    });
  }

  fetchAllBanners() {
    this.core_service.fetchAllBanners().subscribe((result) => {
      if (result) {
        this.allBanners = result;
      }
    });
  }


  setType(type: any) {
    switch (type) {
      case 'SPOT':
        return 'spot';

      case 'FORWARD_BN':
        return 'forward';

      default:
        return 'bid';
    }
  }

  clickSellButton() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.router.navigate(['/my-account/' + this.tenantValue], {queryParams: {'isClickedSellButton': true}});
    } else {
      this.interactionService.popUpLoginModal('false');
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  applyLoan() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      if (this.userRole === 'AGGREGATOR' || this.userRole === 'FARMER' ||  this.userRole === 'SERVICE_PROVIDER' ||
        this.userRole === 'COMPANY_AGGREGATOR' || this.userRole === 'COMPANY_FARMER' || this.userRole === 'COMPANY_SERVICE_PROVIDER') {
        this.router.navigate(['/value-added-services/loan-forms/wholesale']);
      }else {
        this.router.navigate(['/not-found-vas/wholesale']);
      }
    } else {
      this.interactionService.popUpLoginModal('false');
    }
  }

  applyInsurance() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.router.navigate(['/value-added-services/manage-insurance/wholesale']);
    } else {
      this.interactionService.popUpLoginModal('false');
    }
  }

  applyVoicePackage() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      if (this.userRole === 'FARMER' || this.userRole === 'COMPANY_FARMER') {
        this.router.navigate(['/value-added-services/voice-packages/wholesale']);
      }else {
        this.router.navigate(['/not-found-vas/wholesale']);
      }
    } else {
      this.interactionService.popUpLoginModal('false');
    }
  }
}

