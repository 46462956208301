import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {SystemSettingsService} from '../../../../../service/system-settings.service';
import Swal from 'sweetalert2';
import {CoreService} from '../../../../../service/core.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../../../../../service/product.service';
import {environment} from '../../../../../../environments/environment';
import {catchError, last, map} from 'rxjs/operators';
import {CommonSignUpService} from '../../../../../service/common-sign-up.service';
import {ValidationHandlerService} from '../../../../../validation-handler/validation-handler.service';
import {CommonService} from '../../../../../common/common.service';
import {UserRoleService} from '../../../../../service/user-role.service';
import {of} from 'rxjs';
import {GetProductItemDTO} from '../../../../../dto/getProductItemDTO';
import {MatDialog} from '@angular/material';
import {MainUrlService} from '../../../../../service/main-url.service';
import {UserService} from '../../../../../service/user.service';
import {ProductDeliveryDTO} from '../../../../../dto/ProductDeliveryDTO';
import {InteractionService} from '../../../../../service/interaction.service';

@Component({
  selector: 'app-farmer-edit-spot-product',
  templateUrl: './farmer-edit-spot-product.component.html',
  styleUrls: ['./farmer-edit-spot-product.component.css']
})
export class FarmerEditSpotProductComponent implements OnInit {
  @Input() isAgentFarmerLogin: boolean = false;
  @Input() product: any;
  @Input() agentFarmerUsername: string;

  submittedCategoryForm: boolean = false;
  categoryForm: FormGroup;

  submittedProductDetailsForm: boolean = false;
  productDetailsForm: FormGroup;

  submittedPicUpAddressForm: boolean = false;
  pickUpAddressForm: FormGroup;

  listProductDetailsForm: FormGroup;

  selectSpotNext = 0;

  files: File[] = [];
  imageArray = [];
  imageObject = [];
  categoryList: any;
  subCategoryList: any;
  activeProfile: any;
  selectCategoryId: any;
  selectSubCategoryId: any;

  title: any;
  description: any = '';
  availableTotalQty: any;
  unitTypeId: any;
  unitTypeList: any;
  unitType: any;
  buyDate: any;
  todayDate = new Date().toISOString().slice(0, 10);

  address: any;
  city: any;
  province: any;
  postalCode: any;
  country: any = 'Sri Lanka';

  isEnableRiyaviruTransportation: boolean = false;

  currencyCode: any;
  bulkSellingPrice: any;
  isSellSmallerQty: boolean = false;
  tempIsSellSmallerQty: boolean = false;
  sellSmallerQty: any;
  sellSmallerQtySellingUnitPrice: any;
  validBulkSellingPrice: boolean = false;
  validSellSmallerQty: boolean = false;
  validSellingUnitPrice: boolean = false;
  selectProvinceId: any;

  provinceList: any;
  districtList: any;
  divisionalSecretariatList: any;
  gramaNiladhariAreaList: any;
  selectDistrictId: any;
  selectDivisionalSecretariatId: any;
  selectGramaNiladhariAreaId: any;
  spotTypeId: any;
  loading: boolean = false;
  loadingDist: boolean = false;
  loadingDiv: boolean = false;
  loadingGn: boolean = false;
  activeLang: string = 'en';

  productStatusList: any[] = [];
  productStatus: any;
  productItem: any;
  expiredDate: any;
  storeUnitPrice: any;
  storeQty: any;
  storeBulkPrice: any;
  tenantValue: any;
  leadCommission: number = (1 / 100);
  platformCommission: number = (1.5 / 100);
  farmerCommission: number = (97.5 / 100);
  storeQtyInit = 0;
  addressList: any[] = [];
  username: any;
  isLeaderFarmer: boolean = false;
  islandCommissionVisible: boolean = false;
  address1: any;
  address2: any;

  isLeadCommissionVisible: boolean = false;


  isAgentFarmerCheck: boolean = false;
  addressObject: any;
  isAddressSave: boolean = false;
  isItNewAddress: boolean = false;
  agentFarmerAddress1: any;
  agentFarmerAddress2: any;
  agentFamrerProvinceId: any;
  agentFamrerDisctrictId: any;
  agentFarmerCity: any;
  agentFarmerCityName: any;
  agentFarmerPostal: any;
  agentFarmerName: any;
  addressListAgentFarmer: any[] = [];

  constructor(private commonService: CommonService,
              private productService: ProductService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private http: HttpClient,
              private userService: UserService,
              private commonSignUpService: CommonSignUpService,
              private interactionService: InteractionService,
              private systemSettingsService: SystemSettingsService,
              private validationHandlerService: ValidationHandlerService,
              private userRoleService: UserRoleService,
              private productItemData: GetProductItemDTO,
              private matDialog: MatDialog,
              public mainUrl: MainUrlService,) {

    this.activeLang = sessionStorage.getItem('LANG');
    this.systemSettingsService.fetchAll().subscribe(result => {
      let tempArray = [];

      tempArray.push(result);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if ('CURRENCY_CODE' === setting.code) {
            this.currencyCode = setting.value;
          }
        }
      }
    });

    this.username = sessionStorage.getItem('FARMER_USERNAME');
    if (this.username !== null && this.username !== undefined) {
      this.isLeaderFarmer = true;
      this.common(this.username);
      this.getUserDetails(this.username);
    } else {
      this.userRoleService.whoAmI().subscribe(whoAmI => {
        this.isLeaderFarmer = false;
        this.username = whoAmI['email'];
        this.common(this.username);
        this.getUserDetails(this.username);
      });
    }

  }

  common(username) {
    this.loadAddresses(username);
    this.userService.fetchLeadDetailsByFarmer(username).subscribe(isAgent => {
      this.isLeadCommissionVisible = isAgent['success'] && (isAgent['message'] !== null);
    });
  }

  ngOnInit() {

    this.tenantValue = 'wholesale';

    this.loadStatusDetails();
    this.loadFetchSingleSPOTView(this.product);
    this.categoryFormValidation();
    this.productDetailsFormValidation();
    this.pickUpAddressFormValidation();
    this.productListDetailsFormValidation();


    this.deliveryForm = this.formBuilder.group({
      deliverCharge: '',
    });
    this.deliveryFormFixed = this.formBuilder.group({
      deliverChargeFixed: '',
      areas: '',
    });

  }

  get f1() {
    return this.categoryForm.controls;
  }

  get f2() {
    return this.productDetailsForm.controls;
  }

  get f3() {
    return this.pickUpAddressForm.controls;
  }

  get f4() {
    return this.listProductDetailsForm.controls;
  }

  closeModal() {
    this.matDialog.closeAll();
  }

  loadStatusDetails() {
    this.productStatusList.push(
      {
        'id': 1,
        'statusCode': 'ACTIVE',
        'description': 'Active'
      },
      {
        'id': 2,
        'statusCode': 'DISABLE',
        'description': 'Disable'
      },
      {
        'id': 3,
        'statusCode': 'PENDING',
        'description': 'Pending'
      });
  }

  loadFetchSingleSPOTView(data) {

    this.productService.fetchSingleSPOTView(data.spotDTOList[0].sku).subscribe(result => {

      this.productItem = result;
      this.loadImages();
      this.selectSubCategoryId = this.productItem.product.subCategory.code;
      this.selectCategoryId = this.productItem.product.subCategory.mainCategory.code;
      this.title = this.productItem.product.name;
      this.productStatus = data.status;
      this.description = this.productItem.product.description;
      this.buyDate = new Date(result['expireDate']).toISOString().slice(0, 10);
      this.todayDate = new Date(result['expireDate']).toISOString().slice(0, 10);

      this.availableTotalQty = this.productItem.stockAvailable;
      this.address = this.productItem.product.address;

      this.getAllProvince();
      this.selectProvinceId = this.productItem.product.province;
      this.getAllDistrict(this.selectProvinceId);

      this.selectDistrictId = this.productItem.product.district;
      this.getAllDivSec(this.selectDistrictId);
      this.selectDivisionalSecretariatId = this.productItem.product.divisional;

      this.selectGramaNiladhariAreaId = this.productItem.product.gramaNiladari;
      this.postalCode = this.productItem.product.postalCode;

      if (result['sellInSmallerQuantities']) {
        this.isSellSmallerQty = true;
        this.tempIsSellSmallerQty = true;
        this.storeQty = parseFloat(result['stock']);
        this.storeUnitPrice = parseFloat(result['unitPrice']);
        this.sellSmallerQty = this.storeQty;
        this.sellSmallerQtySellingUnitPrice = (this.storeUnitPrice * this.sellSmallerQty).toFixed(2);

      } else {
        this.isSellSmallerQty = false;
        this.storeBulkPrice = result['unitPrice'];
        this.storeQty = result['stock'];
        this.storeQtyInit = result['totalQuantity'];
        this.bulkSellingPrice = this.storeBulkPrice * this.storeQtyInit;
      }


      this.deliveryOptList = this.productItem.deliveryList;
      if (this.productItem.deliveryList.length > 0) {
        for (let delivery of this.productItem.deliveryList) {
          if (delivery.deliveryOpt === '2') {
            this.buyerDeliver = true;
          } else if (delivery.deliveryOpt === '4') {
            this.train = true;
          } else if (delivery.deliveryOpt === '5') {
            this.isSellerDeliverFixed = true;
            this.deliveryFormFixed.patchValue({
              deliverChargeFixed: delivery.deliveryCharges,
              areas: delivery.deliveryDesc,
            });
            this.areas = delivery.deliveryDesc;
          } else if (delivery.deliveryOpt === '3') {
            this.isSellerDeliver = true;
            this.deliveryForm.patchValue({
              deliverCharge: delivery.deliveryCharges,
            });
          }
        }
      }


      this.fetchAllCategories();
      this.fetchAllUnitTypes();
      this.getAllProvince();
      this.loadProductTypes();

    });
  }

  loadAddresses(username) {
    this.userRoleService.fetchAddresses(username).subscribe(addresses => {


      this.addressList = addresses;
      for (let add of this.addressList) {
        if (add.status === true && !this.isAgentFarmerLogin) {
          this.address1 = add.addressOne;
          this.address2 = add.addressTwo;

          this.selectProvinceId = add.cities.agriDistrict.province.id;
          this.getAllDistrict(this.selectProvinceId);
          this.selectDistrictId = add.cities.agriDistrict.id;
          this.getAllCities(this.selectDistrictId);
          this.getAllDivSec(this.selectDistrictId);
          this.selectCityId = add.cities.id;
          this.postalCode = add.postalCode;
          this.setSelectedCityName();
        }
      }

    });
  }

  allCities: any;
  selectCityId: any;
  selectedCityName: any;

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
      }
    });
  }

  setSelectedCityName() {
    if (this.allCities !== undefined) {
      if (this.allCities.length > 0) {
        for (let _city of this.allCities) {
          if (_city.id.toString() === this.selectCityId.toString()) {
            this.selectedCityName = _city.nameEN;
          }
        }
      }
    }
  }

  loadImages() {
    this.imageObject = [];

    for (let images of this.productItem.productImages) {
      this.imageObject.push({
        imageURL: environment.proxyUrl + 'fileservice/file/' + images.image + '/?X-TenantID=' + this.tenantValue,
        imageId: images.image
      });
    }
  }

  commonImg: any;

  setViewImage(imageURL: string) {
    this.commonImg = imageURL;
  }

  categoryFormValidation() {
    this.categoryForm = this.formBuilder.group({
      category: ['', Validators.required],
      subCategory: ['', Validators.required]
    });
  }

  productDetailsFormValidation() {
    this.productDetailsForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      description: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())]],
      buyDate: ['', Validators.required],
      availableTotalQty: ['', Validators.required],
      unitType: ['', Validators.required],
      status: ['']
    });
  }

  pickUpAddressFormValidation() {
    this.pickUpAddressForm = this.formBuilder.group({
      address1: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      address2: ['', [Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      district: ['', Validators.required],
      province: ['', Validators.required],
      divisionalSecretariat: [''],
      gramaNiladhariArea: [''],
      postalCode: ['', Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowed())],
      city: ['', Validators.required]
    });
  }

  productListDetailsFormValidation() {
    this.listProductDetailsForm = this.formBuilder.group({
      bulkSellingPrice: [''],
      sellSmallerQty: [''],
      sellSmallerQtyUnitType: [''],
      sellSmallerQtySellingUnitPrice: ['']
    });
  }

  onSelect(event) {
    if (this.imageObject.length <= 3 && event.addedFiles.length !== 0) {
      const ext = event.addedFiles[0].name.split('.').pop().toLowerCase();
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        this.uploadImages(event.addedFiles);
        this.files.push(...event.addedFiles);
        if (event.addedFiles[0].size / 1024 / 1024 > 5) {
          this.files.splice(-1, 1);
        }
      }
    }
  }

  onRemove(event) {
    for (let image of this.imageArray) {
      if (image.event === event) {
        let index = this.imageArray.indexOf(image);
        if (index !== -1) {
          this.imageArray.splice(index, 1);
        }
        this.files.splice(this.files.indexOf(event), 1);
      }
    }

    for (let image of this.imageObject) {
      if (image.event === event) {
        let index = this.imageObject.indexOf(image);
        if (index !== -1) {
          this.imageObject.splice(index, 1);
        }
      }
    }
  }

  setAddress(index) {
    if (index === 'new') {
      this.isAgentFarmerCheck = false;
      this.addressObject = undefined;
      this.address1 = undefined;
      this.address2 = undefined;
      this.address = undefined;
      this.selectProvinceId = undefined;
      this.selectDistrictId = undefined;
      this.selectCityId = undefined;
      this.postalCode = undefined;
      this.isItNewAddress = true;
    } else if (index === 'FM') {
      this.isAgentFarmerCheck = true;
      this.address1 = this.agentFarmerAddress1;
      this.address2 = this.agentFarmerAddress2;
      this.selectProvinceId = this.agentFamrerProvinceId;
      this.getAllDistrict(this.selectProvinceId);
      this.selectDistrictId = this.agentFamrerDisctrictId;
      this.getAllCities(this.selectDistrictId);
      this.getAllDivSec(this.selectDistrictId);
      this.selectCityId = this.agentFarmerCity;
      this.postalCode = this.agentFarmerPostal;
      this.isItNewAddress = false;

    } else {
      this.isAgentFarmerCheck = false;
      this.addressObject = this.addressList[index];

      this.address1 = this.addressObject.addressOne;
      this.address2 = this.addressObject.addressTwo;

      this.selectProvinceId = this.addressObject.cities.agriDistrict.province.id;
      this.getAllDistrict(this.selectProvinceId);
      this.selectDistrictId = this.addressObject.cities.agriDistrict.id;
      this.getAllCities(this.selectDistrictId);
      this.getAllDivSec(this.selectDistrictId);
      this.selectCityId = this.addressObject.cities.id;
      this.postalCode = this.addressObject.postalCode;
      this.isItNewAddress = false;
    }

  }

  cityObject: any;
  cityName: any;

  selectCity(event) {
    this.selectCityId = event.target.value;
    this.getCityById(this.selectCityId);
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }


  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.cityObject = result;
        this.postalCode = this.cityObject.postalCode;
      }
    });
  }

  manageAddressSave() {
    this.isAddressSave = !this.isAddressSave;
  }

  loadAgentFarmerDetails() {
    if (this.isAgentFarmerLogin) {
      this.userRoleService.fetchAddresses(this.agentFarmerUsername).subscribe(addresses => {
        this.addressListAgentFarmer = addresses;
        for (let add of this.addressListAgentFarmer) {
          if (add.status) {
            this.isAgentFarmerCheck = true;
            this.address1 = add.addressOne;
            this.address2 = add.addressTwo;
            this.selectProvinceId = add.cities.agriDistrict.province.id;
            this.getAllDistrict(this.selectProvinceId);
            this.selectDistrictId = add.cities.agriDistrict.id;
            this.getAllCities(this.selectDistrictId);
            this.getAllDivSec(this.selectDistrictId);
            this.selectCityId = add.cities.id;
            this.postalCode = add.postalCode;

            this.agentFarmerAddress1 = add.addressOne;
            this.agentFarmerAddress2 = add.addressTwo;
            this.agentFarmerName = add.name;
            this.agentFamrerProvinceId = add.cities.agriDistrict.province.id;
            this.agentFamrerDisctrictId = add.cities.agriDistrict.id;
            this.agentFarmerCity = add.cities.id;
            this.agentFarmerCityName = add.cities.nameEN;
            this.agentFarmerPostal = add.postalCode;
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isAgentFarmerLogin'] || changes['agentFarmerUsername']) {
      this.loadAgentFarmerDetails();
    }
  }

  fetchAllCategories() {
    this.productService.getActiveProfile().subscribe(activeProfile => {
      this.activeProfile = activeProfile['code'];
      this.productService.getAllMainCategoryByProfileId(this.activeProfile).subscribe(categories => {
        if (categories) {
          this.categoryList = categories;
          this.loadSubCategory();
        }
      });
    });
  }

  fetchAllUnitTypes() {
    this.coreService.fetchAllUnitTypes().subscribe(unitTypes => {
      this.unitTypeList = unitTypes;
      this.unitType = this.productItem.product.unit.id;
    }, error1 => {
      console.error(error1);
    });
  }

  loadSubCategory() {
    let mainCatCode = this.productItem.product.subCategory.mainCategory.code;
    this.productService.fetchAllSubCategory(mainCatCode).subscribe(subCategories => {
      this.subCategoryList = subCategories;
      this.fetchAllUnitTypes();
    });
  }

  selectCategory(event) {
    this.loading = true;
    this.selectSubCategoryId = undefined;
    if (event.target.value !== 'none') {
      this.selectCategoryId = event.target.value;
      this.productService.fetchAllSubCategory(this.selectCategoryId).subscribe(subCategories => {
        if (subCategories) {
          this.subCategoryList = subCategories;
          this.loading = false;
        }
      });
    } else {
      this.selectCategoryId = event.target.value;
    }
  }

  selectSubCategory(event) {
    if (event.target.value !== 'none') {
      this.selectSubCategoryId = event.target.value;
    } else {
      this.selectSubCategoryId = undefined;
    }
  }

  categoryPageNext() {
    this.submitCategoryForm();
  }

  submitCategoryForm() {
    this.submittedCategoryForm = true;
    if (this.categoryForm.invalid) {
      return;
    }
    if (this.selectSubCategoryId !== 'none') {
      this.selectSpotNext = 1;
    }
  }

  selectUnitType(event) {
    this.unitTypeId = event.target.value;
  }

  productDetailsFormSubmit() {
    this.submittedProductDetailsForm = true;
    if (this.productDetailsForm.invalid) {
      return;
    }
    this.selectSpotNext = 2;
  }

  productDetailsPageNext() {
    this.productDetailsFormSubmit();
  }

  closeSuccessMsg() {
    Swal.close();
  }

  isRegAddress: boolean = false;
  isAgentFarmer: boolean = false;
  listByUsername: any;
  locationBody: any;
  userInfo: any;
  addressConcat: any;
  deliveryOptList: any = [];

  updateProduct() {
    if ((this.files.length >= 1 && this.files.length <= 4) ||
      (this.imageObject.length >= 1 && this.imageObject.length <= 4)) {

      this.commonService.processing();
      let imageList = [];
      for (let image of this.imageObject) {
        imageList.push({
          'image': image['imageId']
        });
      }

      let unitPrice;
      if (this.isSellSmallerQty) {
        unitPrice = this.sellSmallerQtySellingUnitPrice / this.sellSmallerQty;
      } else {
        unitPrice = this.bulkSellingPrice / this.availableTotalQty;
      }

      let farmerUsername = sessionStorage.getItem('FARMER_USERNAME');

      let username;
      if (this.isAgentFarmer) {
        username = this.listByUsername;
      } else if (farmerUsername !== null) {
        username = farmerUsername;
      } else {
        username = this.username;
      }

      if (this.isAddressSave) {
        this.locationBody = {
          'username': this.username,
          'name': this.userInfo.name,
          'status': false,
          'addressOne': this.address1,
          'addressTwo': this.address2,
          'addressType': {
            'id': 1
          },
          'country': {
            'id': 1
          },
          'cities': {
            'id': this.selectCityId
          },
          'postalCode': this.postalCode
        };
      }

      if (this.address2 === null || this.address2 === undefined || this.address2 === '') {
        this.address2 = '';
        this.addressConcat = this.address1 + ',' + this.selectedCityName;
      } else {
        this.addressConcat = this.address1 + ',' + this.address2 + ',' + this.selectedCityName;
      }

      for (let opt of this.deliveryOptList) {
        let option: ProductDeliveryDTO = new ProductDeliveryDTO;
        option.deliveryOpt = opt;

        if (opt.deliveryOpt === 3 + '') {
          option.deliveryCharges = this.deliverCharge;
          opt.deliveryCharges = this.deliverCharge;
        }
        if (opt.deliveryOpt === 4 + '') {
          option.station = this.station;
          opt.station = this.station;
        }
        if (opt.deliveryOpt === 5 + '') {
          option.deliveryCharges = this.deliverChargeFixed;
          opt.deliveryCharges = this.deliverChargeFixed;
          option.deliveryDesc = this.areas;
          opt.deliveryDesc = this.areas;
        }
        this.productDelivery.push({
          'deliveryOpt': option.deliveryOpt,
          'station': option.station,
          'deliveryCharges': option.deliveryCharges,
          'deliveryDesc': option.deliveryDesc
        });
      }

      let _tempDeliveryOptions = [];

      if (this.productDelivery.length > 0) {
        for (let delivery of this.productDelivery) {
          _tempDeliveryOptions.push({
            'deliveryOpt': delivery['deliveryOpt'].deliveryOpt,
            'station': delivery.station,
            'deliveryCharges': delivery.deliveryCharges,
            'deliveryDesc': delivery.deliveryDesc
          });
        }
      }

      let spotProductDTO = {
        'sku': this.productItem.sku,
        'unitPrice': unitPrice,
        'discount': this.productItem.discount,
        'unit': {
          'id': this.unitType
        },
        'smallerQuantity': this.sellSmallerQty,
        'stockAvailable': this.availableTotalQty,
        'expireDate': this.buyDate,
        'address': this.address,
        'province': this.selectProvinceId,
        'district': this.selectDistrictId,
        'divisional': this.selectDivisionalSecretariatId,
        'gramaNiladari': this.selectGramaNiladhariAreaId,
        'postalCode': this.postalCode,
        'isSellInSmallerQty': this.isSellSmallerQty,
        'status': 'PENDING',
        'name': this.title,
        'description': this.description,
        'deliveryList': _tempDeliveryOptions,
        'productImages': imageList
      };

      this.productService.updateSportProduct(spotProductDTO).subscribe(saveSpotProduct => {
        Swal.close();
        if (saveSpotProduct) {

          if (this.isAddressSave) {
            this.commonSignUpService.saveNewAddresses(this.locationBody).subscribe(() => {
              sessionStorage.removeItem('FARMER_USERNAME');
              this.interactionService.updateAgentFarmersList(true);
              this.interactionService.updateProductListingList(true);
            });
          } else {
            sessionStorage.removeItem('FARMER_USERNAME');
            this.interactionService.updateAgentFarmersList(true);
            this.interactionService.updateProductListingList(true);
          }

          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Update Successfully,Your Product sent to the Review Section to get the verification!',
            showConfirmButton: false,
            timer: 3500
          }).then(() => {
            this.closeModal();
          });
        }
      }, () => {
        Swal.close();
      });

    } else {
      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage(sessionStorage.getItem('LANG')),
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });
    }
  }

  deliverCharge: any;
  deliverChargeFixed: any;
  areas: any;
  productDelivery = [];
  deliveryForm: FormGroup;
  deliveryFormFixed: FormGroup;
  isChargeReq: boolean = false;
  isAreaReq = false;
  areaDisable = true;
  isFixedChargeReq = false;


  next() {
    let isValid = false;
    if (this.isSellerDeliver === true || this.train === true || this.buyerDeliver === true || this.isSellerDeliverFixed) {

      if (this.train === true) {
        if (this.station === null || this.station === undefined || this.station === '') {
          this.railwayReq = true;
        } else {
          this.railwayReq = false;
          isValid = true;
        }
      } else if (this.isSellerDeliver === true) {
        if (this.deliveryForm.value.deliverCharge === null || this.deliveryForm.value.deliverCharge === undefined
          || this.deliveryForm.value.deliverCharge === '') {
          this.isChargeReq = true;
        } else {
          this.isChargeReq = false;
          isValid = true;
        }

      } else if (this.isSellerDeliverFixed === true) {

        if (!(this.deliveryFormFixed.value.deliverChargeFixed === null || this.deliveryFormFixed.value.deliverChargeFixed === undefined
          || this.deliveryFormFixed.value.deliverChargeFixed === '')) {

          if (!this.areaDisable) {
            if (this.deliveryFormFixed.value.areas === null || this.deliveryFormFixed.value.areas === undefined
              || this.deliveryFormFixed.value.areas === '') {
              isValid = false;
              this.isAreaReq = true;
            } else {
              isValid = true;
              this.isAreaReq = false;
            }
          } else {
            isValid = true;
            this.isFixedChargeReq = false;
          }

        } else {
          this.isFixedChargeReq = true;
          isValid = false;
        }


      } else if (this.buyerDeliver === true) {
        isValid = true;
      }
    } else {
      this.deliveryReq = true;
    }

    if (isValid) {
      this.deliveryReq = false;
      this.railwayReq = false;
      this.isChargeReq = false;
      this.isFixedChargeReq = false;
      this.isAreaReq = false;
      this.deliverCharge = this.deliveryForm.value.deliverCharge;
      this.deliverChargeFixed = this.deliveryFormFixed.value.deliverChargeFixed;
      this.areas = this.areaDisable ? 'All Island delivery' : this.deliveryFormFixed.value.areas;

      this.selectSpotNext = this.selectSpotNext + 1;
    }


  }

  deliveryOptions = 2;

  selectRailway(event) {
    this.station = event;
  }

  getUserDetails(username) {
    let body = {
      'userName': username,
      'token': 'wholesale'
    };
    this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
      this.userInfo = userResult;
    });
  }

  railwayList: any;
  station: any;
  isSellerDeliver: boolean = false;
  isSellerDeliverFixed: boolean = false;
  buyerDeliver: boolean = false;
  train: boolean = false;
  railwayReq: boolean = false;
  deliveryReq: boolean = false;

  checkVal($event, val) {
    if ($event.checked) {
      this.deliveryOptList.push(val);
      if (val === 3) {
        this.isSellerDeliver = true;
        this.deliveryReq = false;
      } else if (val === 4) {
        this.train = true;
        this.deliveryReq = false;
      } else if (val === 2) {
        this.buyerDeliver = true;
        this.deliveryReq = false;
      } else if (val === 5) {
        this.isSellerDeliverFixed = true;
        this.deliveryReq = false;
      }
    } else {
      if (this.deliveryOptList.length > 0) {
        for (let delivery of this.deliveryOptList) {
          if (delivery.deliveryOpt === val.toString()) {
            let index = this.deliveryOptList.indexOf(delivery);
            if (val === '3') {
              this.isSellerDeliver = false;
            } else if (val === '4') {
              this.train = false;
            } else if (val === '2') {
              this.buyerDeliver = false;
            } else if (val === '5') {
              this.isSellerDeliverFixed = false;
            }
            if (index !== -1) {
              this.deliveryOptList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  uploadImages(value) {
    this.commonService.processing();
    let formData = new FormData();

    if (value[0].size > 5006708) {
      Swal.close();
      //Remove last image from files array
      this.files.splice(-1, 1);

      Swal.fire({
        title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
        type: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
      });

      return false;
    }
    formData.append('file', value[0]);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict');
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');
    headers = headers.append('X-TenantID', TENANT_VALUE);
    let token = localStorage.getItem('$Token');
    headers = headers.append('Access-Token', token);

    let req = new HttpRequest('POST', environment.proxyUrl + 'fileservice/upload/', formData, {
      headers: headers,
      reportProgress: true,
    });
    this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      last(),
      catchError(() => {
        Swal.close();
        //Remove last image from files array
        this.files.splice(-1, 1);

        Swal.fire({
          title: this.validationHandlerService.imageValidateMessage1MB(sessionStorage.getItem('LANG')),
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.validationHandlerService.okMessage(sessionStorage.getItem('LANG'))
        });

        return of('upload failed.');
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          Swal.close();
          this.imageArray.push({
            'event': value[0],
            'image': event.body.message
          });
          this.imageObject.push({
            imageURL: environment.proxyUrl + 'fileservice/file/' + event.body.message + '/?X-TenantID=' + this.tenantValue,
            imageId: event.body.message,
            event: value[0]
          });
        }
      }
    );
  }

  productListNextPage() {
    if (this.isSellSmallerQty) {
      this.validBulkSellingPrice = false;

      this.validSellSmallerQty = !(this.sellSmallerQty !== null && this.sellSmallerQty !== undefined);

      this.validSellingUnitPrice = !(this.sellSmallerQtySellingUnitPrice !== null && this.sellSmallerQtySellingUnitPrice !== undefined);

      if ((this.sellSmallerQty !== null && this.sellSmallerQty !== undefined) &&
        (this.sellSmallerQtySellingUnitPrice !== null && this.sellSmallerQtySellingUnitPrice !== undefined)) {

        if ((this.availableTotalQty % this.sellSmallerQty) === 0 && this.availableTotalQty > this.sellSmallerQty) {
          this.validSellSmallerQty = false;
          this.validSellingUnitPrice = false;
          this.selectSpotNext = 3;
        } else {
          Swal.fire({
            title: 'If you use sell in small qty can enter the smaller quantity but should divided by available total quantity. Cannot be balance',
            type: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        }
      }

    } else {
      this.validSellSmallerQty = false;
      this.validSellingUnitPrice = false;
      if (this.bulkSellingPrice !== null && this.bulkSellingPrice !== undefined) {
        this.validBulkSellingPrice = false;
        this.selectSpotNext = 3;
      } else {
        this.validBulkSellingPrice = true;
      }
    }
  }


  pickUpLocationAddressNextPage() {
    this.submittedPicUpAddressForm = true;
    if (this.pickUpAddressForm.invalid) {
      return;
    }
    this.selectSpotNext = 4;
  }

  manageSellInSmallerQty() {
    this.isSellSmallerQty = !this.isSellSmallerQty;
    if (this.isSellSmallerQty) {
      if (this.tempIsSellSmallerQty) {
        this.sellSmallerQty = this.storeQty;

      } else {
        this.sellSmallerQty = 0;
      }
      this.bulkSellingPrice = 0;
      this.sellSmallerQtySellingUnitPrice = (this.storeUnitPrice).toFixed(2);

      this.validBulkSellingPrice = false;
    } else {
      this.sellSmallerQty = 0;
      this.sellSmallerQtySellingUnitPrice = 0;
      this.bulkSellingPrice = this.storeBulkPrice * this.storeQty;

      this.validSellSmallerQty = false;
      this.validSellingUnitPrice = false;
    }
  }

  manageAvailableTotalQty(qty) {
    sessionStorage.setItem('totalAvailableQty', qty);
  }

  selectProvince(event) {
    this.loadingDist = true;
    this.selectProvinceId = event.target.value;
    this.selectDistrictId = undefined;
    this.postalCode = undefined;
    this.selectCityId = undefined;
    this.cityObject = undefined;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.getAllDistrict(this.selectProvinceId);
  }

  selectDistrict(event) {
    this.loadingDiv = true;
    this.selectDistrictId = event.target.value;
    this.selectDivisionalSecretariatId = undefined;
    this.selectGramaNiladhariAreaId = undefined;
    this.postalCode = undefined;
    this.selectCityId = undefined;
    this.cityObject = undefined;
    this.getAllDivSec(this.selectDistrictId);
    this.getAllCities(this.selectDistrictId);
  }


  selectDivisionalSecretariat(event) {
    this.loadingGn = true;
    this.selectDivisionalSecretariatId = event.target.value;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.provinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.districtList = result;
        this.loadingDist = false;
      }
    });

  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.divisionalSecretariatList = result;
        this.selectDivisionalSecretariatId = this.productItem.product.divisional;
        this.selectGramaNiladhariAreaId = this.productItem.product.gramaNiladari;
        this.loadingDiv = false;
      }
    });
  }

  setUnitType(id: any) {
    for (let unit of this.unitTypeList) {
      if (unit.id.toString() === id.toString()) {
        return unit.unit;
      }
    }
  }

  loadProductTypes() {
    this.coreService.fetchAllProductTypes().subscribe(types => {
      if (types) {
        for (let type of types) {
          if (type.type === 'SPOT') {
            this.spotTypeId = type.id;
            return;
          }
        }
      }
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }


  removeImage(imageId: any) {
    let temp = [];
    if (this.imageObject.length > 0) {
      for (let image of this.imageObject) {
        if (image['imageId'] !== imageId) {
          temp.push(image);
        }
      }
      this.imageObject = temp;
    }

    if (this.imageObject.length === 0) {
      this.files = [];
      this.imageArray = [];
    }
  }
}
