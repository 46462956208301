import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Global} from '../../../service/global';
import {ToastrService} from 'ngx-toastr';
import {ProductService} from '../../../service/product.service';
import {PaymentService} from '../../../service/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InteractionService} from '../../../service/interaction.service';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {UserRoleService} from '../../../service/user-role.service';
import {CoreService} from '../../../service/core.service';
import {MainUrlService} from '../../../service/main-url.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {RatingDTO} from '../../../dto/ratingDTO';
import {CommonService} from '../../../common/common.service';
import {PurchaseStatusDTO} from '../../../dto/purchase-statusDTO';
import {RemarkComponent} from './remark/remark.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TransactionDTO} from '../../../dto/transactionDTO';
import {AuthDTO} from '../../../dto/authDTO';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {UserTokenDTO} from '../../../dto/userTokenDTO';
import {Md5} from 'ts-md5';
import Swal from 'sweetalert2';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-my-purchases',
  templateUrl: './my-purchases.component.html',
  styleUrls: ['./my-purchases.component.css']
})
export class MyPurchasesComponent implements OnInit {
  submitted = false;

  CURRENCY_CODE: any;
  review: any;
  rate: any = 0;
  sku: any;
  reviewForm: FormGroup;

  spinner1: number = 1;
  spinner2: number = 1;
  spinner0: number = 1;

  allCategory: any;
  allDisCategory: any;
  allNewArrivalCategory: any;
  load = 0;

  userName: any;
  showDetails: any;
  displayMobile: any;
  displayCountry: any;
  imgUrl: string;
  tenantValue: any = 'wholesale';
  BASE_URL: any;
  spinner: boolean = true;
  address: any;
  deliveryTotal: any;
  province: any;
  postalCode: any;
  country: any;
  mobile: any;
  qrImage: any;
  status: any;
  grandTotal: any;
  startCity: any;
  endCity: any;
  totalUnitPrice: any;
  couponCode: any;
  couponValue: any;
  totalDiscount: any;
  transactionId: any;
  productList: any;
  tempFirstName: any;

  listedBy: any;
  orderId: any;
  FORWARD_ORDER_ENABLE: boolean = false;

  PRD_ADDR_CNAME: any;

  ELEMENT_DATA = [];
  dataSource;
  displayedColumns: string[] = ['product', 'transactionId', 'purchasedDate', 'productType', 'paymentStatus', 'status'];

  ratingDTO = new RatingDTO;
  transactionTrimValue: any;
  purchaseStatusDTO = new PurchaseStatusDTO;
  transactionDTO = new TransactionDTO;
  authDTO = new AuthDTO;
  todayDate = new Date();

  fromDate: Date;
  toDate: Date;
  invalidDateRange: boolean = false;

  buyerFirstName: any;
  buyerLastName: any;
  buyerAddress: any;
  buyerMobile: any;
  singleProductImg: any;
  commonImg: any;
  productName: any;
  productUpdateTime: any;
  userTokenDTO = new UserTokenDTO;
  sellerName: any;
  sellerAddress: any;
  sellerImage: any;
  sellerMobile: any;
  unitPrice: any;
  quantity: any;
  unitType: any;
  purchasedDate: any;
  paymentMethod: any;
  productId: any;
  comment = '';
  rate_B: any;
  rate_S: any;
  productSeller: any;
  buyerName: any;
  buyerImgUrl: any;
  com_address1: any;
  com_address2: any;
  com_hotline: any;
  com_email: any;
  com_website: any;
  QR_URL: string;
  delivery: string;
  rejectionComment: any;
  rejectionDescription: any;
  deliveryCharges: any;
  deliveryFixRate: any;
  area: any;
  deliveryId: any;
  activeLang: string = 'en';
  productType: any;
  userRole: any;
  ecoShopCode: any;

  isEconomicCenter: boolean = false;
  isEcoFarmerSection: boolean = false;

  @ViewChild('closeButton') closeButton;
  @ViewChild('receipt') openModel;
  @ViewChild('success') successButton;
  @ViewChild('feedBack') feedBack;
  @ViewChild('Reject') rejectButton;
  @ViewChild('viewDetails') viewDetails;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  totalstar = 5;

  constructor(public global: Global,
              private toastr: ToastrService,
              public productService: ProductService,
              public main_url: MainUrlService,
              private core_service: CoreService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private settingsService: SystemSettingsService,
              private interaction: InteractionService,
              private formBuilder: FormBuilder,
              public commonService: CommonService,
              private router: Router,
              public mainUrl: MainUrlService,
              private paymentService: PaymentService,
              private interactionService: InteractionService,
              private modalService: NgbModal,
              private _changeDetectionRef: ChangeDetectorRef,
              private validationHandlerService: ValidationHandlerService,
              private coreService: CoreService) {

    this.userRole = localStorage.getItem('USER_ROLE');

    this.isEconomicCenter = this.userRole === 'ECONOMIC_SHOP';

    this.route.queryParams.subscribe(params => {
      this.ecoShopCode = params['shopRef'];
      this.isEcoFarmerSection = params['isFarmer'];
    });

    this.reactiveForm();

  }

  ngOnInit() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.common();
    } else {
      this.commonLogoutURL();
    }

    this.interactionService.updateRemark.subscribe(_remark => {
      if (_remark) {
        this.common();
      }
    });
    this.getBuyerDetails();
  }

  common() {
    this.loading();
    this.ELEMENT_DATA = [];
    if (this.isEconomicCenter && this.isEcoFarmerSection) {
      this.productService.historyByEcoUserOrders(this.ecoShopCode).subscribe(orderList => {
        this.commonResponseBody(orderList);
      }, () => {
        Swal.close();
      });
    } else {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.productService.historyByUserOrders(whoAmIDetails['email']).subscribe(orderList => {
          this.commonResponseBody(orderList);
        }, () => {
          Swal.close();
        });
      }, () => {
        Swal.close();
      });
    }

  }

  commonResponseBody(values) {
    let arr = [];
    for (let order of values) {
      if (order.rejection !== null) {
        arr.push(
          order.rejection
        );
      }
      let image;
      let rejectComment = null;
      let rejectDescription = null;
      if (arr.length > 0) {
        for (let reject of arr) {
          rejectComment = reject.comment;
          rejectDescription = reject.rejectionCategory.description;
        }
      }
      for (let prod of order.productDetailsDTOS) {
        if (prod.productType === 'ORDER_REQUEST') {
          if (prod.categoryName === 'Farming Products & Processed Food') {
            image = 'assets/images/Thumbnails/Processed Products.jpg';
          } else if (prod.categoryName === 'Spices & Cash Crops') {
            image = 'assets/images/Thumbnails/Spices.jpg';
          } else if (prod.categoryName === 'Services & Information') {
            image = 'assets/images/Thumbnails/Services.jpg';
          } else if (prod.categoryName === 'Plants & Seeds') {
            image = 'assets/images/Thumbnails/Seeds.jpg';
          } else if (prod.categoryName === 'Ayurvedic & Herbal Products') {
            image = 'assets/images/Thumbnails/Ayurveda.jpg';
          } else if (prod.categoryName === 'Equipment and Tools') {
            image = 'assets/images/Thumbnails/Equipments.jpg';
          } else {
            image = 'assets/images/Thumbnails/' + prod.categoryName + '.jpg';
          }
        } else {
          image = prod.imageDTOS !== null ? prod.imageDTOS[0].image : '';
        }
        this.ELEMENT_DATA.push({
          id: prod.id,
          sku: prod.sku,
          productName: prod.productName,
          productType: prod.productType,
          productImage: image,
          transactionId: order.transactionId,
          paymentStatus: prod.paymentStatus,
          purchasedDate: order.purchasedDate,
          status: prod.status,
          rejectComment: rejectComment,
          rejectDescription: rejectDescription
        });
      }

    }
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;

    this.CURRENCY_CODE = this.global.CURRENCY_CODE;
    this.BASE_URL = this.global.BASE_URL;
    this.fetchAll();
    this.loadProducts();
  }

  reactiveForm() {
    this.reviewForm = this.formBuilder.group({
      review: ['', Validators.pattern(this.validationHandlerService.addressValidation())]
    });
  }

  get f() {
    return this.reviewForm.controls;
  }

  loadProducts() {
    this.productService.getActiveProfile().subscribe(profileCode => {
      if (profileCode) {
        this.productService.getAllMainCategoryByProfileId(profileCode.code).subscribe(re => {
          if (re) {
            this.spinner0 = 0;
            this.allCategory = re;
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;
            this.productService.fetchAllDiscountSpotProducts(6, profileCode.code, this.tenantValue).subscribe(discountSpotProducts => {
              if (discountSpotProducts) {
                this.spinner1 = 0;
                this.allDisCategory = discountSpotProducts;
                this.productService.fetchAllNewArrivalSpotProducts(6, profileCode.code, this.tenantValue).subscribe(newArrivalSpotProducts => {
                  if (newArrivalSpotProducts) {
                    this.spinner2 = 0;
                    this.allNewArrivalCategory = newArrivalSpotProducts;
                    Swal.close();
                  }
                }, () => {
                  Swal.close();
                });
              }
            }, () => {
              Swal.close();
            });

          }
        }, () => {
          Swal.close();
        });
      }
    }, () => {
      Swal.close();
    });
  }

  productView(row) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.loading();
    this.transactionId = row.transactionId;
    this.productService.getPurchaseProductDetails(row.transactionId).subscribe(productDetails => {
      let arr = productDetails.productDetailsDTOS;
      if (arr.length > 0) {
        for(let productDetail of arr){
          if(productDetail['sku'] === row.sku){
            this.orderId = productDetail.id;
          }
        }
      }

      if (productDetails) {

        this.sku = row.sku;
        this.productType = row.productType;
        if (row.productType === 'SPOT') {
          this.productService.getProductDetailsBySku(row.sku, this.tenantValue).subscribe(result => {
            let product = result['product'];
            this.sellerAddress = product.address;

            this.userName = productDetails.userName;
            this.productList = productDetails.productDetailsDTOS;
            let _buyerAddress;
            if (productDetails['deliveryLine2'] === null || productDetails['deliveryLine2'] === undefined || productDetails['deliveryLine2'] === '') {
              if (productDetails['deliveryCityDTO'] !== null) {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryCityDTO'].nameEN;
              } else {
                _buyerAddress = productDetails['deliveryLine1'];
              }
            } else {
              if (productDetails['deliveryCityDTO'] !== null) {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryLine2'] + ',' + productDetails['deliveryCityDTO'].nameEN;
              } else {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryLine2'];
              }
            }
            this.buyerAddress = _buyerAddress;
            this.buyerFirstName = productDetails['firstName'];
            this.buyerLastName = productDetails['lastName'];
            this.deliveryTotal = productDetails['delivery'];
            this.postalCode = productDetails['postalCode'];
            this.province = productDetails['province'];
            this.country = productDetails['country'];
            this.mobile = productDetails['mobile'];
            this.status = productDetails['status'];
            this.grandTotal = productDetails['grandTotal'];
            this.startCity = productDetails['startCity'];
            this.endCity = productDetails['endCity'];
            this.totalUnitPrice = productDetails['totalUnitPrice'];
            this.couponCode = productDetails['couponCode'];
            this.couponValue = productDetails['couponValue'];
            this.totalDiscount = productDetails['totalDiscount'];
            this.paymentMethod = productDetails['paymentMethod'];
            let arr = [];
            if (productDetails.rejection !== null) {
              arr.push(
                productDetails.rejection
              );
            }
            if (arr.length > 0) {
              for (let reject of arr) {
                this.rejectionComment = reject.comment;
                this.rejectionDescription = reject.rejectionCategory.description;
              }
            }

            for (let _temp of productDetails.productDetailsDTOS) {
              if (_temp.sku === this.sku) {
                this.productName = _temp.productName;
                this.productUpdateTime = _temp.updated;
                this.unitPrice = _temp.unitPrice;
                this.quantity = _temp.quantity;
                this.unitType = _temp.unitType;
                this.productId = _temp.id;
                this.purchasedDate = productDetails.purchasedDate;
                this.productSeller = _temp.listedBy;
                this.delivery = _temp.delivery;
                this.deliveryCharges = _temp.deliveryCharges;
                this.deliveryFixRate = _temp.deliveryFixRate;
                this.area = _temp.area;
                this.deliveryId = _temp.deliveryId;

                this.getSellerDetails(this.productSeller, 'SPOT');
                if (this.couponValue !== null && this.couponValue !== undefined) {
                  this.totalDiscount = this.totalDiscount + this.couponValue;
                }
                if (_temp.imageDTOS[0].image !== undefined && _temp.imageDTOS[0].image.length > 0) {
                  this.singleProductImg = this.mainUrl.MAIN_URL_FILE + _temp.imageDTOS[0].image + '/?X-TenantID=' +
                    this.tenantValue;
                  this.commonImg = this.singleProductImg;
                } else {
                  this.commonImg = undefined;
                }
              }
            }


            this.fetchSellerRatings(this.productSeller);
            this.generateQRCode(row.transactionId);
            this.viewDetails.nativeElement.click();
            Swal.close();
          });
        } else if (row.productType === 'AUCTION') {
          this.productService.fetchSingleAuctionView(row.sku).subscribe(auctionProduct => {
            this.sellerAddress = auctionProduct.product.address;

            this.userName = productDetails.userName;
            this.productList = productDetails.productDetailsDTOS;
            let _buyerAddress;
            if (productDetails['deliveryLine2'] === null || productDetails['deliveryLine2'] === undefined || productDetails['deliveryLine2'] === '') {
              if (productDetails['deliveryCityDTO'] !== null) {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryCityDTO'].nameEN;
              } else {
                _buyerAddress = productDetails['deliveryLine1'];
              }
            } else {
              if (productDetails['deliveryCityDTO'] !== null) {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryLine2'] + ',' + productDetails['deliveryCityDTO'].nameEN;
              } else {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryLine2'];
              }
            }
            this.buyerAddress = _buyerAddress;
            this.buyerFirstName = productDetails['firstName'];
            this.buyerLastName = productDetails['lastName'];
            this.deliveryTotal = productDetails['delivery'];
            this.postalCode = productDetails['postalCode'];
            this.province = productDetails['province'];
            this.country = productDetails['country'];
            this.mobile = productDetails['mobile'];
            this.status = productDetails['status'];
            this.grandTotal = productDetails['grandTotal'];
            this.startCity = productDetails['startCity'];
            this.endCity = productDetails['endCity'];
            this.totalUnitPrice = productDetails['totalUnitPrice'];
            this.couponCode = productDetails['couponCode'];
            this.couponValue = productDetails['couponValue'];
            this.totalDiscount = productDetails['totalDiscount'];
            this.paymentMethod = productDetails['paymentMethod'];
            let arr = [];
            if (productDetails.rejection !== null) {
              arr.push(
                productDetails.rejection
              );
            }
            if (arr.length > 0) {
              for (let reject of arr) {
                this.rejectionComment = reject.comment;
                this.rejectionDescription = reject.rejectionCategory.description;
              }
            }

            for (let _temp of productDetails.productDetailsDTOS) {
              if (_temp.sku === this.sku) {
                this.productName = _temp.productName;
                this.productUpdateTime = _temp.updated;
                this.unitPrice = _temp.unitPrice;
                this.quantity = _temp.quantity;
                this.unitType = _temp.unitType;
                this.productId = _temp.id;
                this.purchasedDate = productDetails.purchasedDate;
                this.productSeller = _temp.listedBy;
                this.delivery = _temp.delivery;
                this.deliveryCharges = _temp.deliveryCharges;
                this.deliveryFixRate = _temp.deliveryFixRate;
                this.area = _temp.area;
                this.deliveryId = _temp.deliveryId;

                this.getSellerDetails(this.productSeller, 'AUCTION');
                if (this.couponValue !== null && this.couponValue !== undefined) {
                  this.totalDiscount = this.totalDiscount + this.couponValue;
                }
                if (_temp.imageDTOS[0].image !== undefined && _temp.imageDTOS[0].image.length > 0) {
                  this.singleProductImg = this.mainUrl.MAIN_URL_FILE + _temp.imageDTOS[0].image + '/?X-TenantID=' +
                    this.tenantValue;
                  this.commonImg = this.singleProductImg;
                } else {
                  this.commonImg = undefined;
                }
              }
            }


            this.fetchSellerRatings(this.productSeller);
            this.generateQRCode(row.transactionId);
            this.viewDetails.nativeElement.click();
            Swal.close();
          });
        } else if (row.productType === 'ORDER_REQUEST') {
          this.productService.fetchOrderRequestByProductId(productDetails.productDetailsDTOS[0].productId).subscribe(result => {
            this.sku = result.sku;
            this.listedBy = result.product.listedBy;

            this.productId = result.product.id;

            this.userName = productDetails.userName;
            this.productList = productDetails.productDetailsDTOS;
            let _buyerAddress;
            if (productDetails['deliveryLine2'] === null || productDetails['deliveryLine2'] === undefined || productDetails['deliveryLine2'] === '') {
              if (productDetails['deliveryCityDTO'] !== null) {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryCityDTO'].nameEN;
              } else {
                _buyerAddress = productDetails['deliveryLine1'];
              }
            } else {
              if (productDetails['deliveryCityDTO'] !== null) {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryLine2'] + ',' + productDetails['deliveryCityDTO'].nameEN;
              } else {
                _buyerAddress = productDetails['deliveryLine1'] + ',' + productDetails['deliveryLine2'];
              }
            }
            this.buyerAddress = _buyerAddress;
            this.buyerFirstName = productDetails['firstName'];
            this.buyerLastName = productDetails['lastName'];
            this.deliveryTotal = productDetails['delivery'];
            this.postalCode = productDetails['postalCode'];
            this.province = productDetails['province'];
            this.country = productDetails['country'];
            this.mobile = productDetails['mobile'];
            this.status = productDetails['status'];
            this.grandTotal = productDetails['grandTotal'];
            this.startCity = productDetails['startCity'];
            this.endCity = productDetails['endCity'];
            this.totalUnitPrice = productDetails['totalUnitPrice'];
            this.couponCode = productDetails['couponCode'];
            this.couponValue = productDetails['couponValue'];
            this.totalDiscount = productDetails['totalDiscount'];
            this.paymentMethod = productDetails['paymentMethod'];

            let arr = [];
            if (productDetails.rejection !== null) {
              arr.push(
                productDetails.rejection
              );
            }
            if (arr.length > 0) {
              for (let reject of arr) {
                this.rejectionComment = reject.comment;
                this.rejectionDescription = reject.rejectionCategory.description;
              }
            }

            this.categoryName = result.product.subCategory.mainCategory.commonIndex.description;
            this.unitType = result.product.unit.unit;

            this.categoryName = result.product.subCategory.mainCategory.commonIndex.description;

            this.productName = productDetails.productDetailsDTOS[0].productName;
            this.productUpdateTime = productDetails.productDetailsDTOS[0].updated;
            this.unitPrice = productDetails.productDetailsDTOS[0].unitPrice;
            this.platformChargers = productDetails.productDetailsDTOS[0].platformCharge;
            this.quantity = productDetails.productDetailsDTOS[0].quantity;
            this.unitType = productDetails.productDetailsDTOS[0].unitType;
            this.productId = productDetails.productDetailsDTOS[0].productId;
            this.purchasedDate = productDetails.purchasedDate;
            this.productSeller = productDetails.productDetailsDTOS[0].orderRequestSeller;
            this.delivery = productDetails.productDetailsDTOS[0].delivery;
            this.deliveryCharges = productDetails.productDetailsDTOS[0].deliveryCharges;
            this.deliveryFixRate = productDetails.productDetailsDTOS[0].deliveryFixRate;
            this.area = productDetails.productDetailsDTOS[0].area === null ? 'All Island delivery' : productDetails.productDetailsDTOS[0].area;
            this.deliveryId = productDetails.productDetailsDTOS[0].deliveryId;
            this.grandTotal = parseFloat(this.unitPrice + this.platformChargers);

            this.getSellerDetails(this.productSeller, 'ORDER_REQUEST');

            if (this.categoryName === 'Farming Products & Processed Food') {
              this.singleProductImg = 'assets/images/Thumbnails/Processed Products.jpg';
            } else if (this.categoryName === 'Spices & Cash Crops') {
              this.singleProductImg = 'assets/images/Thumbnails/Spices.jpg';
            } else if (this.categoryName === 'Services & Information') {
              this.singleProductImg = 'assets/images/Thumbnails/Services.jpg';
            } else if (this.categoryName === 'Plants & Seeds') {
              this.singleProductImg = 'assets/images/Thumbnails/Seeds.jpg';
            } else if (this.categoryName === 'Ayurvedic & Herbal Products') {
              this.singleProductImg = 'assets/images/Thumbnails/Ayurveda.jpg';
            } else if (this.categoryName === 'Equipment and Tools') {
              this.singleProductImg = 'assets/images/Thumbnails/Equipments.jpg';
            } else {
              this.singleProductImg = 'assets/images/Thumbnails/' + result.product.subCategory.mainCategory.commonIndex.description + '.jpg';
            }
            this.fetchSellerRatings(this.listedBy);
            this.generateQRCode(row.transactionId);
            this.viewDetails.nativeElement.click();
            Swal.close();
          });
        }
      }
    }, () => {
      Swal.close();
    });

  }

  categoryName: any;

  fetchSellerRatings(username) {
    this.coreService.fetchUserRatings(username).subscribe(rate => {
      if (rate) {
        this.rate_S = rate;
      }
    }, () => {
      Swal.close();
    });
  }

  fetchBuyerRatings(username) {
    this.coreService.fetchUserRatings(username).subscribe(rate => {
      if (rate) {
        this.rate_B = rate;
      }
    }, () => {
      Swal.close();
    });
  }

  getBuyerDetails() {
    this.userRoleService.whoAmI().subscribe(userDetails => {
      this.userName = userDetails['email'];
      let token = localStorage.getItem('$Token');
      let body = {
        'userName': userDetails['email'],
        'token': token
      };
      this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
        if (userResult !== null) {

          this.buyerName = userResult['name'];

          let hashStr;
          if (userResult['email'] !== null) {
            hashStr = Md5.hashStr(userResult['email']);
          } else {
            hashStr = Md5.hashStr('');
          }

          this.buyerImgUrl = userResult.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + userResult.imageUrl +
            '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
          this.buyerAddress = userResult['address1'];
          this.buyerMobile = userResult['mobileNumber'];
          this.fetchBuyerRatings(this.buyerName);
        }
      }, () => {
        Swal.close();
      });
    }, () => {
      Swal.close();
    });
  }

  getSellerDetails(sellerName, orderType) {
    let token = localStorage.getItem('$Token');
    let body = {
      'userName': sellerName,
      'token': token
    };
    this.userRoleService.fetchUserAndLocation(body).subscribe(userResult => {
      if (userResult !== null) {
        this.sellerName = userResult['name'];
        let hashStr;
        if (userResult['email'] !== null) {
          hashStr = Md5.hashStr(userResult['email']);
        } else {
          hashStr = Md5.hashStr('');
        }
        this.sellerImage = userResult.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE + userResult.imageUrl +
          '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
        this.sellerMobile = userResult['mobileNumber'];
      }
    }, () => {
      Swal.close();
    });

    if (orderType === 'ORDER_REQUEST') {
      this.productService.fetchOrderRequestGetDefaultPickupAddress(sellerName).subscribe(addressDetails => {
        this.sellerAddress = addressDetails?.addressOne + ' ' + addressDetails?.addressTwo + ' ' + addressDetails?.cities?.nameEN;
      });
    }
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  convertDateTemp(created: any) {
    let date = new Date(new Date(created));
    return this.convertDate(new Date(new Date().setDate(date.getDate() + 2)));

  }

  onRate($event) {
    this.rate = $event.newValue;
  }

  submit() {
    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        let rate;
        if (this.rate > 0) {
          rate = parseInt(this.rate);
        } else {
          rate = 0;
        }
        this.ratingDTO.rating = rate;
        this.ratingDTO.sku = this.sku;
        this.ratingDTO.reviewBy = whoAmIDetails['email'];
        this.ratingDTO.username = this.productSeller;
        this.ratingDTO.comment = this.review;
        this.ratingDTO.status = 'PENDING';

        this.productService.saveRating(this.ratingDTO).subscribe(result => {
          if (result) {
            Swal.close();
            this.afterSubmit();
          }
        }, () => {
          Swal.close();
        });
      }, () => {
        Swal.close();
        this.commonLogoutURL();
      });
    } else {
      Swal.close();
      this.commonLogoutURL();
    }
  }

  afterSubmit() {
    document.getElementById('closeModal').click();
    this.manageReview(this.sku, this.listedBy);
    this.rate = 0;
    this.reviewForm.reset();
    this.toastr.success('Submitted!');
  }

  commonLogoutURL() {
    Swal.close();
    localStorage.removeItem('Access-Token');
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/' + this.tenantValue]);
  }

  onSubmit() {
    this.submitted = true;
    if (this.reviewForm.invalid) {
      return;
    }
    if (this.filterOnlySpecialCharacters(this.review)) {
      this.submit();
    }
  }

  filterOnlySpecialCharacters(word) {
    let filter = new RegExp('[a-zA-Z]');
    return filter.test(word) == true;
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  generateQRCode(transactionId) {
    let brBody =
      {
        'url': this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + transactionId,
        'proxyUrl': environment.proxyUrl
      };
    this.QR_URL = this.BASE_URL + 'receipt/' + this.tenantValue + '?TID=' + transactionId;
    this.paymentService.fetchQrCode(brBody).subscribe(qrCode => {
      if (qrCode) {
        this.qrImage = this.main_url.MAIN_URL_FILE + qrCode['message'] + '/?X-TenantID=' + this.tenantValue;
      }
    }, () => {
      Swal.close();
    });
  }

  loadImage(el) {
    return this.main_url.MAIN_URL_FILE + el + '/?X-TenantID=' + this.tenantValue;
  }

  manageReview(sku, listedBy) {
    this.sku = sku;
    this.listedBy = listedBy;
    document.getElementById('openModelBtn').click();
  }

  platformChargers = 0;

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];
      Swal.close();

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          switch (setting.code) {

            case 'FORWARD_ORDER_ENABLE':
              this.FORWARD_ORDER_ENABLE = setting.value === 'true';
              break;

            case 'TRANSACTION_ID_LENGTH':
              this.transactionTrimValue = setting.value;
              break;

            case 'PLATFORM_CHARGES' :
              this.platformChargers = setting.value;
              break;

          }

          if (setting.code === 'PRD_ADDR_CNAME') {
            this.PRD_ADDR_CNAME = setting.value;
          }
        }
      }
    }, () => {
      Swal.close();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  complete(id) {
    this.loading();
    this.purchaseStatusDTO.id = id;
    this.purchaseStatusDTO.status = 'COMPLETED';
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.purchaseStatusDTO.rejectedBy = whoAmIDetails['email'];

      this.productService.updateTransaction(this.purchaseStatusDTO).subscribe(res => {
        if (res) {
          Swal.close();
          this.common();
          this.closeButton.nativeElement.click();
          this.successButton.nativeElement.click();
        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: res.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, () => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Something went wrong..!!!',
          showConfirmButton: false,
          timer: 1500
        });

      });
    });
  }

  reject(id) {
    this.purchaseStatusDTO.id = id;
    this.purchaseStatusDTO.status = 'REJECTED';
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
      this.purchaseStatusDTO.rejectedBy = whoAmIDetails['email'];
      let modalRef = this.modalService.open(RemarkComponent, {
        size: 'lg', centered: true, keyboard: true
      });
      modalRef.componentInstance.fromParent = {
        model: this.purchaseStatusDTO,
      };
      this.commonService.setModel(this.modalService);
    });

  }

  saveDate(event: any) {
    this.invalidDateRange = false;
    this.fromDate = new Date(event.target.value.begin);
    this.toDate = new Date(event.target.value.end);
    this.fromDate.setDate(this.fromDate.getDate());
    this.toDate.setDate(this.toDate.getDate());
  }

  generateReport() {
    if (this.fromDate !== null && this.fromDate !== undefined || this.toDate !== null && this.toDate !== undefined) {
      this.invalidDateRange = false;
      this.loading();
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.authDTO.accessToken = 'Bearer ' + localStorage.getItem('$Token');
        this.authDTO.tenantID = this.tenantValue;
        this.transactionDTO.auth = this.authDTO;
        this.transactionDTO.fromDate = this.fromDate;
        this.transactionDTO.toDate = this.toDate;
        this.transactionDTO.tenantID = this.tenantValue;
        this.transactionDTO.username = whoAmIDetails['email'];

        this.productService.printBuyerReport(this.transactionDTO).subscribe(
          (data: any) => {
            Swal.close();
            const file = new Blob([data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);

            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          }, () => {
            Swal.close();
            Swal.fire({
              position: 'center',
              type: 'error',
              title: 'Something went wrong..!!!',
              showConfirmButton: false,
              timer: 1500
            });
          });
      });

    } else {
      this.invalidDateRange = true;
    }

  }

  manageStatus(status: string) {
    switch (sessionStorage.getItem('LANG')) {
      case 'en' :
        if (status === 'REJECTED_SETTLED') {
          return 'REJECTED';
        } else {
          return status;
        }

      case 'si' :
        if (status === 'REJECTED_SETTLED') {
          return 'REJECTED-sin';
        } else {
          return status + '-sin';
        }

      case 'ta' :
        if (status === 'REJECTED_SETTLED') {
          return 'REJECTED-tamil';
        } else {
          return status + '-tamil';
        }
    }
  }

  loadReceipt() {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = this.QR_URL;
    a.click();
  }

  openFeedBackModal() {
    this.feedBack.nativeElement.click();
  }

  calGrandTotal(subTotalAmount, platformChargers) {
    if (this.paymentMethod === 'Card') {
      return subTotalAmount / (1 - (platformChargers / 100));
    } else {
      return subTotalAmount;
    }
  }

  calPlatformCharge(subTotalAmount, platformChargers) {
    if (this.paymentMethod === 'Card') {
      return (subTotalAmount / (1 - (platformChargers / 100))) - subTotalAmount;
    } else {
      return 0;
    }
  }
}
