import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../service/product.service';
import {Md5} from 'ts-md5';
import {CoreService} from '../service/core.service';
import {MainUrlService} from '../service/main-url.service';
import {Global} from '../service/global';
import Swal from 'sweetalert2';
import {UserRoleService} from '../service/user-role.service';
import {SystemSettingsService} from '../service/system-settings.service';

@Component({
  selector: 'app-view-bids',
  templateUrl: './view-bids.component.html',
  styleUrls: ['./view-bids.component.css']
})
export class ViewBidsComponent implements OnInit {
  allAcuResult: any;
  allBnResult: any;
  userName: any;
  showDetails: any;
  displayMobile: any;
  displayCountry: any;
  imgUrl: string;

  CURRENCY_CODE: any;
  sku: any;

  spinner1: number = 1;
  spinner2: number = 1;
  spinner0: number = 1;

  allCategory: any;
  allDisCategory: any;
  allNewArrivalCategory: any;
  load = 0;
  bitAcu: any;
  bitBn: any;
  allBids1: any;
  allBids2: any;
  tenantValue: any;
  BASE_URL: string;
  foundResult = false;
  FORWARD_ORDER_ENABLE = false;
  temp = [];

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(public global: Global,
              public mainUrl: MainUrlService,
              public core_service: CoreService,
              private router: Router,
              private productService: ProductService,
              private route: ActivatedRoute,
              private userRoleService: UserRoleService,
              private settingsService: SystemSettingsService) {
  }

  ngOnInit() {
      this.tenantValue = this.route.snapshot.paramMap.get('tenant_id');
      this.loadBids();
      this.getUserDetails();
      this.loadProducts();
      this.getBidAcuDetails();
      this.fetchAll();
  }


  loadBids() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.productService.fetchAllAcuBidByUsername(whoAmIDetails['email'], this.tenantValue).subscribe(acuResult => {
          this.allAcuResult = acuResult;

          this.productService.fetchAllBnBidByUsername(whoAmIDetails['email'], this.tenantValue).subscribe(bnResult => {
            this.allBnResult = bnResult;
          });
        });
      });
    }
  }

  getUserDetails() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.core_service.fetchUserDetails(whoAmIDetails['email']).subscribe(result => {
          if (result) {
            if (result.id !== null) {
              let hashStr;
              if (whoAmIDetails['email'] !== null) {
                hashStr = Md5.hashStr(whoAmIDetails['email']);
              } else {
                hashStr = Md5.hashStr('');
              }
              this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '';
              this.userName = result.firstName + ' ' + result.lastName;
              this.displayMobile = result.phone;
              this.displayCountry = result.addressCollection[0].country;

              this.showDetails = true;
            } else {

              this.showDetails = true;
              this.userName = whoAmIDetails['name'];
              this.displayMobile = whoAmIDetails['mobileNumber'];
              let hashStr;
              if (whoAmIDetails['email'] !== null) {
                hashStr = Md5.hashStr(whoAmIDetails['email']);
              } else {
                hashStr = Md5.hashStr('');
              }
              this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '';
            }
          } else {
            this.showDetails = false;

          }
        });
      });
    }
  }

  loadProducts() {
    this.productService.getActiveProfile().subscribe(profileCode => {
      if (profileCode) {
        this.productService.getAllMainCategoryByProfileId(profileCode.code).subscribe(re => {
          if (re) {
            this.spinner0 = 0;
            this.allCategory = re;
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;
            this.BASE_URL = this.global.BASE_URL;

            this.productService.fetchAllDiscountSpotProducts(10, profileCode.code, this.tenantValue).subscribe(result => {
              if (result) {
                this.spinner1 = 0;
                this.allDisCategory = result;
                this.productService.fetchAllNewArrivalSpotProducts(10, profileCode.code, this.tenantValue).subscribe(result => {
                  if (result) {
                    this.spinner2 = 0;
                    this.allNewArrivalCategory = result;
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  getBidAcuDetails() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.productService.getBidAcuDetail(whoAmIDetails['email'], this.tenantValue).subscribe(getBidAcuDetail => {
          this.bitAcu = getBidAcuDetail;
          if (Object.keys(getBidAcuDetail).length > 0) {
            this.temp.push(getBidAcuDetail);
          }
          this.productService.getBidBnDetail(whoAmIDetails['email'], this.tenantValue).subscribe(getBidBnDetail => {
            this.bitBn = getBidBnDetail;
            if (Object.keys(getBidBnDetail).length > 0) {
              this.temp.push(getBidBnDetail);
            }
            if (this.temp.length === 0) {
              this.foundResult = true;
            }
          });
        });
      });
    }
  }

  viewBids(id: any) {
    this.loading();
    this.productService.getBidAcuDetails(id).subscribe(getBidAcuDetails => {
      Swal.close();
      this.allBids1 = getBidAcuDetails;
    }, () => {
      Swal.close();
    });

    this.productService.getBidBnDetails(id, this.tenantValue).subscribe(getBidBnDetails => {
      Swal.close();
      this.allBids2 = getBidBnDetails;
    }, () => {
      Swal.close();
    });
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      width: '300px',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).then(r => {
      console.log(r);
    });
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          switch (setting.code) {

            case 'FORWARD_ORDER_ENABLE':
              this.FORWARD_ORDER_ENABLE = setting.value === 'true';
              break;
          }
        }
      }
    });
  }


}
