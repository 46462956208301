import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private  mainUrl: MainUrlService) {

  }
  fetchAllUsers(): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_AUTH + '/users/all');
  }

  generateExcelFile(): any {
    return this.http.get(this.mainUrl.MAIN_URL_AUTH + '/users/generateExcel/', {responseType: 'blob'})
      .subscribe(res => {
        const blobb = new Blob([res], {type: res.type});
        saveAs(blobb, 'users.xls');
      }, () => {
        alert('Sorry file not available!');
      });
  }

  fetchAllFarmers(lead: any): Observable<any> {
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING +'/userDetails/fetchFaremerList/' + lead);
  }

  fetchLeadDetailsByFarmer(farmer):Observable<any>{
    return this.http.get<any>(this.mainUrl.MAIN_URL_ONBOARDING +'/farmer/lead/' + farmer);
  }

  

}
