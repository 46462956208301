<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_AUCTION'| translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                <mat-form-field class="matsearch">
                  <input (keyup)="applyFilter($event.target.value)" matInput placeholder="{{'SEARCH_BAR' | translate}}">
                </mat-form-field>

                <table [dataSource]="dataSource" class="table salestable" mat-table matSort style="width:100%">

                  <ng-container matColumnDef="orderTitle">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Order Title
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0;">
                      <div class="d-flex flex-row align-items-center mt-2 mb-2">
                        {{row.orderTitle}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="numberOfBids">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Number Of Bids
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.numberOfBids}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bidAmount">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Bid Amount
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.bidAmount| number:'2.2-2'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bidEndDate">
                    <th *matHeaderCellDef class="center-table tbl td" mat-header-cell mat-sort-header>
                      Bid End Date
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      {{row.bidEndDate |date:'MM/dd/yyyy'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th *matHeaderCellDef class="center-table centertd tbl td" mat-header-cell mat-sort-header>
                      Status
                    </th>
                    <td *matCellDef="let row" class="center-table tbl" mat-cell style="padding-left: 0%;">
                      <img *ngIf="!row.status" alt="" class="status-img"
                           src="../../../../assets/images/ongoing-status.svg"/>
                      <img *ngIf="row.status" alt="" class="status-img" src="../../../../assets/images/awarded.svg"/>
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                  </tr>
                  <tr *matRowDef="let row; columns: displayedColumns" mat-row
                      [routerLink]="['/my-account/buyer-bids-auctions/wholesale']"
                      [queryParams]="{name:row.orderTitle}"
                  ></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10 ,25, 100]"></mat-paginator>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- order review -->
<div aria-hidden="true" class="modal fade npa-model" id="viewbidproduct" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" id="small-modal" role="document">
    <!-- <button type="button" class="close" #closebutton data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button> -->
    <div class="modal-content" style="padding:15px;">
      <div class="col-md-12">
        <div class="row order-review">
          <div class="col-md-5 d-flex align-items-center justify-content-center">
            <div>
              <img alt="" src="../../../../../assets/images/temp/watermellon.png" style="width: 200px;">
            </div>
          </div>
          <div class="col-md-7 product-details pr-4">
            <div class="text-right">
              <i aria-label="Close" class="material-icons graytext-3" data-dismiss="modal"
                 style="cursor: pointer;font-size: 18px;">close</i>
            </div>
            <div class="product-section">
              <h2 class="font-weight-bold graytext m-0">Watermelon</h2>
              <p class="ratings">
                <star-rating [value]="rate" checkedcolor="#FFA84C" size="16px" totalstars="5" uncheckedcolor="#999999">
                </star-rating>
              </p>
            </div>
            <p class="avg-weight">Avg weight per one item: 1KG</p>
            <div class="bid-user">
              <div class="form-group mb-0">
                <label class="graytext m-0 dark-blue person">Jeewan Ratnayaka</label>
                <p class="graytext-2 dark-blue">
                  05/05/2020
                </p>
              </div>
            </div>
            <div class="price dark-blue font-weight-bold">
              <p>600.00</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center modal-btns">
        <div class="btn-section">
          <button class="btn btn-default next" data-dismiss="modal" data-target="#success"
                  data-toggle="modal"><i class="fa fa-check"></i> &nbsp;Award
          </button>&nbsp;
          <button class="btn btn-default next redbtn" data-dismiss="modal" data-target="#decline"
                  data-toggle="modal"><i class="fa fa-times"></i> &nbsp;Decline
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- success -->
<div aria-hidden="true" class="modal fade npa-model" id="success" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" id="small-modal" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-check"></i>
          <h3>Bid Awarded Successfully</h3>
          <p>Jeewan’s Bid has been awarded successfully.</p>
          <a class="btn-b b-approve width100" data-dismiss="modal"
             href="">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- declined -->
<div aria-hidden="true" class="modal fade npa-model" id="decline" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" id="small-modal" role="document">

    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-times redtxt"></i>
          <h3>Bid Declined</h3>
          <p>Jeewan’s Bid has been declined successfully.</p>
          <a class="btn-b b-approve redbtn width100" data-dismiss="modal"
             href="">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
