import {Component, OnInit, ViewChild} from '@angular/core';
import {ValidationHandlerService} from '../../../../../../validation-handler/validation-handler.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreService} from '../../../../../../service/core.service';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../../../../../common/common.service';
import {UserRoleService} from '../../../../../../service/user-role.service';
import Swal from 'sweetalert2';
import {InteractionService} from '../../../../../../service/interaction.service';

@Component({
  selector: 'app-plant-machinery-application',
  templateUrl: './plant-machinery-application.component.html',
  styleUrls: ['./plant-machinery-application.component.css']
})
export class PlantMachineryApplicationComponent implements OnInit {

  section: number = 0;

  form: FormGroup;
  activeLang: any;
  submitted = false;

  fullName: any;
  address: any;
  nicOrBusNo: any;
  purpose: any;
  manufacturerName: any;
  modelOrSerialNumber: any;
  manufactureYear: any;
  itemValue: any;
  lossExperience: any;
  applicationDate: any = new Date().toISOString().slice(0, 10);
  proposerSignature: any;

  userDetails: any;

  @ViewChild('openSuccessModal', {static: true}) openSuccessModal;
  @ViewChild('openDraftModal', {static: true}) openDraftModal;

  draftDetails: any;
  id: any;
  userName: any;
  formType: any;
  status: any;
  isDraft: boolean = false;

  decryptedInfo: any;

  constructor(private validationHandlerService: ValidationHandlerService,
              private formBuilder: FormBuilder,
              private coreService: CoreService,
              private route: ActivatedRoute,
              private interactionService: InteractionService,
              public commonService: CommonService,
              private userRoleService: UserRoleService) {
    this.validationForm();


  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.isDraft = params['isDRAFT'];
      if (params['isDRAFT']) {
        this.commonService.processing();

        this.draftDetails = JSON.parse(decodeURIComponent(params['data']));

        this.id = this.draftDetails['id'];

        this.coreService.machineryInsuranceSLICRequestsById(this.id).subscribe(insuranceDetails => {
          Swal.close();

          this.userName = insuranceDetails['userName'];
          this.form.patchValue({
            address: insuranceDetails['address'],
            nicOrBusNo: insuranceDetails['nicOrBusNo'],
            fullName: insuranceDetails['fullName'],
            purpose: insuranceDetails['purpose'],
            manufacturerName: insuranceDetails['manufacturerName'],
            modelOrSerialNumber: insuranceDetails['modelOrSerialNumber'],
            manufactureYear: insuranceDetails['manufactureYear'],
            itemValue: insuranceDetails['itemValue'],
            lossExperience: insuranceDetails['lossExperience'],
            applicationDate: new Date().toISOString().slice(0, 10),
            proposerSignature: insuranceDetails['proposerSignature'],
            id: insuranceDetails['id'],
            created: insuranceDetails['created'],
            formType: insuranceDetails['formType'],
            status: insuranceDetails['status'],
          });

        });
      }

    });

    if (!this.isDraft) {
      this.fetchUserDetails();
    }

    this.activeLang = sessionStorage.getItem('LANG');
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  validationForm() {
    this.form = this.formBuilder.group({
      address: ['', [Validators.required, Validators.pattern(this.validationHandlerService.whiteSpaceNotAllowedForSinTam())]],
      nicOrBusNo: ['', [Validators.required]],
      fullName: ['', Validators.required],
      purpose: ['', Validators.required],
      manufacturerName: ['', Validators.required],
      modelOrSerialNumber: ['', Validators.required],
      manufactureYear: ['', Validators.required],
      itemValue: ['', Validators.required],
      lossExperience: ['', Validators.required],
      applicationDate: ['', Validators.required],
      proposerSignature: ['', Validators.required],
      id: [''],
      userName: [''],
      created: [''],
      formType: [''],
      status: ['']
    });
  }

  fetchUserDetails() {
    this.userRoleService.whoAmI().subscribe(whoAmI => {
      this.userRoleService.fetchUserDetails(whoAmI['email']).subscribe(userDetails => {
        if (userDetails) {
          this.userDetails = userDetails;
          this.address = this.userDetails.address;
          this.fullName = this.userDetails.name;
          this.nicOrBusNo = this.userDetails.nic;
          this.form.patchValue({
            fullName: this.fullName,
            nicOrBusNo: this.nicOrBusNo,
            address: this.address,
            formType: 'FOR_MACHINERY_INSURANCE',
            userName: whoAmI['email'],
          });
          Swal.close();
        }
      });
    });
  }

  get f() {
    return this.form.controls;
  }


  submitForm(status) {

    if (this.isDraft) {
      this.form.patchValue({
        id: this.id,
        formType: 'FOR_MACHINERY_INSURANCE',
        userName: this.userName,
      });
    }

    if (this.form.invalid && status == 'PENDING') {
      return;
    }
    this.commonService.processing();

    this.form.patchValue({
      status: status
    });


    this.coreService.requestMachineryInsuranceSLIC(this.form.value).subscribe(result => {
      Swal.close();
      if (result['success']) {
        if (status === 'PENDING') {
          this.openSuccessModal.nativeElement.click();
        } else {
          this.openDraftModal.nativeElement.click();
        }
      }
    }, () => {
      Swal.close();
    });

  }

  nextPage() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.section = 1;
  }


  backPage() {
    this.section = 0;
  }
}
