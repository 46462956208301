import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {ProductsComponent} from './products/products.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {CartComponent} from './loged/cart/cart.component';
import {CheckoutComponent} from './loged/checkout/checkout.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {CookieService} from 'ngx-cookie-service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Global} from './service/global';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxDropzoneModule} from 'ngx-dropzone';

import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSortModule, MatStepperModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import {ToastrModule} from 'ngx-toastr';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxContentLoadingModule} from 'ngx-content-loading';
import {MaintenanceModeComponent} from './maintenance-mode/maintenance-mode.component';
import {ViewBidsComponent} from './view-bids/view-bids.component';
import {FaqComponent} from './faq/faq.component';
import {AboutComponent} from './about/about.component';
import {ChartsModule} from 'ng2-charts';
import {Ng5SliderModule} from 'ng5-slider';
import {AngularCountdownDateTimeModule} from 'angular-countdown-date-time';
import {NgxSimpleCountdownModule} from 'ngx-simple-countdown';
import {CountDownModule} from 'ng6-countdown';
import {ContactComponent} from './contact/contact.component';
import {ReceiptComponent} from './receipt/receipt.component';
import {NgxPrintModule} from 'ngx-print';
import {AwardBidComponent} from './loged/award-bid/award-bid.component';
import {AuthInterceptor} from './auth/auth-interceptor';
import {ErrorInterceptor} from './auth/error-interceptor';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {TenantService} from './tenant/tenant.service';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {SignUpAgentComponent} from './sign-up/forms/sign-up-agent/sign-up-agent.component';
import {SignUpCollectorComponent} from './sign-up/forms/sign-up-collector/sign-up-collector.component';
import {SignUpConsumerComponent} from './sign-up/forms/sign-up-consumer/sign-up-consumer.component';
import {SignUpExporterComponent} from './sign-up/forms/sign-up-exporter/sign-up-exporter.component';
import {SignUpFarmerComponent} from './sign-up/forms/sign-up-farmer/sign-up-farmer.component';
import {SignUpWholeSaleBuyerComponent} from './sign-up/forms/sign-up-whole-sale-buyer/sign-up-whole-sale-buyer.component';
import {ServiceProviderComponent} from './sign-up/forms/service-provider/service-provider.component';
import {TransportProvidersComponent} from './sign-up/forms/transport-providers/transport-providers.component';
import {RetailerComponent} from './sign-up/forms/retailer/retailer.component';
import {RiyaviruTermsComponent} from './terms-conditions/riyaviru-terms/riyaviru-terms.component';
import {AgentTermsComponent} from './terms-conditions/agent-terms/agent-terms.component';
import {OtherTermsComponent} from './terms-conditions/other-terms/other-terms.component';
import {RiyaviruAppTermsComponent} from './terms-conditions/riyaviru-app-terms/riyaviru-app-terms.component';
import {MyAccountComponent} from './loged/my-account/my-account.component';
import {BidsAuctionsComponent} from './loged/my-account/bids-auctions/bids-auctions.component';
import {SidebarComponent} from './loged/my-account/shared/sidebar/sidebar.component';
import {FarmerOverviewComponent} from './loged/my-account/farmer/farmer-overview/farmer-overview.component';
import {FarmerMyProductsComponent} from './loged/my-account/farmer/farmer-my-products/farmer-my-products.component';
import {FarmerReviewsComponent} from './loged/my-account/farmer/farmer-reviews/farmer-reviews.component';
import {FarmerSalesComponent} from './loged/my-account/farmer/farmer-sales/farmer-sales.component';
import {FarmerOrdersComponent} from './loged/my-account/farmer/farmer-orders/farmer-orders.component';
import {FarmerSellAProductComponent} from './loged/my-account/farmer/farmer-sell-a-product/farmer-sell-a-product.component';
import {FarmerSpotSellingComponent} from './loged/my-account/farmer/farmer-sell-a-product/farmer-spot-selling/farmer-spot-selling.component';
import {FarmerAuctionComponent} from './loged/my-account/farmer/farmer-sell-a-product/farmer-auction/farmer-auction.component';
import {FarmerForwardContractComponent} from './loged/my-account/farmer/farmer-sell-a-product/farmer-forward-contract/farmer-forward-contract.component';
import {ProductViewSpotComponent} from './product-view-spot/product-view-spot.component';
import {ProductViewBidComponent} from './product-view-bid/product-view-bid.component';
import {ProductViewFowardComponent} from './product-view-foward/product-view-foward.component';
import {BankAccountComponent} from './loged/my-account/bank-account/bank-account.component';
import {ReviewsComponent} from './loged/my-account/shared/reviews/reviews.component';
import {OrderRequestComponent} from './loged/my-account/shared/order-request/order-request.component';
import {DeliveryBidsComponent} from './loged/my-account/shared/delivery-bids/delivery-bids.component';
import {AccountSettingsComponent} from './loged/my-account/shared/account-settings/account-settings.component';
import {MyPurchasesComponent} from './loged/my-account/my-purchases/my-purchases.component';
import {BuyerBidsAuctionsComponent} from './loged/my-account/buyer/buyer-bids-auctions/buyer-bids-auctions.component';
import {BuyerCreateNewOrderComponent} from './loged/my-account/buyer/buyer-create-new-order/buyer-create-new-order.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {ExportAsModule} from 'ngx-export-as';
import {MyFarmersComponent} from './loged/my-account/my-farmers/my-farmers.component';
import {RemarkComponent} from './loged/my-account/my-purchases/remark/remark.component';
import {FarmerEditSpotProductComponent} from './loged/my-account/farmer/farmer-my-products/farmer-edit-spot-product/farmer-edit-spot-product.component';
import {NgImageSliderModule} from 'ng-image-slider';
import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {TermsConditionsComponent} from './terms-conditions/checkout-terms/terms-conditions.component';
import {FarmingCompanyComponent} from './sign-up/forms/company/farming-company/farming-company.component';
import {AggregatorCompanyComponent} from './sign-up/forms/company/aggregator-company/aggregator-company.component';
import {WholesaleBuyerCompanyComponent} from './sign-up/forms/company/wholesale-buyer-company/wholesale-buyer-company.component';
import {ConsumerCompanyComponent} from './sign-up/forms/company/consumer-company/consumer-company.component';
import {ExporterCompanyComponent} from './sign-up/forms/company/exporter-company/exporter-company.component';
import {ServiceProviderCompanyComponent} from './sign-up/forms/company/service-provider-company/service-provider-company.component';
import {RetailerCompanyComponent} from './sign-up/forms/company/retailer-company/retailer-company.component';
import {Slider1Component} from './home/sliders/slider1/slider1.component';
import {Slider2Component} from './home/sliders/slider2/slider2.component';
import {Slider3Component} from './home/sliders/slider3/slider3.component';
import {CookieLawModule} from 'angular2-cookie-law';
import {ProfileComponent} from './loged/my-account/profile/profile.component';
import {FarmerEarningsComponent} from './loged/my-account/farmer/farmer-earnings/farmer-earnings.component';
import {SettingOverviewComponent} from './loged/my-account/shared/setting-overview/setting-overview.component';
import {CollapseModule} from 'ngx-bootstrap';

import {AgentProductsComponent} from './loged/my-account/agent/agent-products/agent-products.component';
import {TableProductViewComponent} from './loged/my-account/shared/table-product-view/table-product-view.component';
import {AppAuthGuard} from './auth/app-auth-guard.service';
import {MyBidsComponent} from './loged/my-account/my-bids/my-bids.component';
import {ShowHidePasswordModule} from 'ngx-show-hide-password';
import {NgOtpInputModule} from 'ng-otp-input';
import {PendingMyPaymentsComponent} from './loged/my-account/pending-my-payments/pending-my-payments.component';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from 'ng-lazyload-image';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {CustomerProtectionPolicyComponent} from './customer-protection-policy/customer-protection-policy.component';
import {OtherTermsSiComponent} from './other-terms-si/other-terms-si.component';
import {OtherTermsTaComponent} from './other-terms-ta/other-terms-ta.component';
import {MyWholesalersComponent} from './loged/my-account/my-wholesalers/my-wholesalers.component';
import {FarmerEditAuctionProductComponent} from './loged/my-account/farmer/farmer-my-products/farmer-edit-auction-product/farmer-edit-auction-product.component';
import { DeliveryBidRequestComponent } from './loged/my-account/delivery-bid-request/delivery-bid-request.component';
import { RiyaviruCheckoutComponent } from './loged/riyaviru-checkout/riyaviru-checkout.component';
import { RiyawiruPrivacyPolicyComponent } from './riyawiru-privacy-policy/riyawiru-privacy-policy.component';
import { ValueAddedServicesComponent } from './loged/value-added-services/value-added-services.component';
import { VoicePackagesComponent } from './loged/value-added-services/voice-packages/voice-packages.component';
import { ManageLoansComponent } from './loged/value-added-services/apply-loans/manage-loans/manage-loans.component';
import { LoanFormsComponent } from './loged/value-added-services/apply-loans/loan-forms/loan-forms.component';
import { LSidebarComponent } from './loged/value-added-services/apply-loans/loan-forms/l-sidebar/l-sidebar.component';
import { LForFarmersComponent } from './loged/value-added-services/apply-loans/loan-forms/l-for-farmers/l-for-farmers.component';
import { LForAggregatorsComponent } from './loged/value-added-services/apply-loans/loan-forms/l-for-aggregators/l-for-aggregators.component';
import { LForManufacturesComponent } from './loged/value-added-services/apply-loans/loan-forms/l-for-manufactures/l-for-manufactures.component';
import { LForSuppliersComponent } from './loged/value-added-services/apply-loans/loan-forms/l-for-suppliers/l-for-suppliers.component';
import { ManageInsuranceComponent } from './loged/value-added-services/apply-insurance/manage-insurance/manage-insurance.component';
import { InsurancePlansComponent } from './loged/value-added-services/apply-insurance/insurance-plans/insurance-plans.component';
import { VehicleInsurance } from './loged/value-added-services/apply-insurance/insurance-plans/vehicle-insurance/vehicle-insurance';
import { ISidebarComponent } from './loged/value-added-services/apply-insurance/insurance-plans/i-sidebar/i-sidebar.component';
import { PlantMachineryApplicationComponent } from './loged/value-added-services/apply-insurance/insurance-plans/plants-machinery/plant-machinery-application/plant-machinery-application.component';
import { PlantMachinerySchemeComponent } from './loged/value-added-services/apply-insurance/insurance-plans/plants-machinery/plant-machinery-scheme/plant-machinery-scheme.component';
import { FireApplicationComponent } from './loged/value-added-services/apply-insurance/insurance-plans/fire/fire-application/fire-application.component';
import { FireSchemeComponent } from './loged/value-added-services/apply-insurance/insurance-plans/fire/fire-scheme/fire-scheme.component';
import { AccidentApplicationComponent } from './loged/value-added-services/apply-insurance/insurance-plans/personal-accident/accident-application/accident-application.component';
import { AccidentSchemeComponent } from './loged/value-added-services/apply-insurance/insurance-plans/personal-accident/accident-scheme/accident-scheme.component';
import { ProductViewOrderRequestComponent } from './product-view-order-request/product-view-order-request.component';
import { MyOrderRequestsComponent } from './loged/my-account/buyer/my-order-requests/my-order-requests.component';
import { EditOrderRequestsComponent } from './loged/my-account/buyer/edit-order-requests/edit-order-requests.component';
import { BuyerOrderRequestBidsComponent } from './loged/my-account/buyer/buyer-order-request-bids/buyer-order-request-bids.component';
import { ViewMyOrderRequestBidsComponent } from './loged/my-account/buyer/view-my-order-request-bids/view-my-order-request-bids.component';
import { RiyawiruMobilePrivacyPolicyComponent } from './riyawiru-documents/riyawiru-mobile-privacy-policy/riyawiru-mobile-privacy-policy.component';
import { RiyawiruMobileUserAgreementComponent } from './riyawiru-documents/riyawiru-mobile-user-agreement/riyawiru-mobile-user-agreement.component';
import { RiyawiruPaymentAuthenticationComponent } from './riyawiru-documents/riyawiru-payment-authentication/riyawiru-payment-authentication.component';
import {OverviewFarmerSellingComponent} from './loged/my-account/overview/overview-farmer-selling/overview-farmer-selling.component';
import {OverviewFarmerBuyingComponent} from './loged/my-account/overview/overview-farmer-buying/overview-farmer-buying.component';
import {OverviewWholesalerSellingComponent} from './loged/my-account/overview/overview-wholesaler-selling/overview-wholesaler-selling.component';
import {OverviewWholesalerBuyingComponent} from './loged/my-account/overview/overview-wholesaler-buying/overview-wholesaler-buying.component';
import {OverviewAggregatorSellingComponent} from './loged/my-account/overview/overview-aggregator-selling/overview-aggregator-selling.component';
import {OverviewAggregatorBuyingComponent} from './loged/my-account/overview/overview-aggregator-buying/overview-aggregator-buying.component';
import {OverviewLeadSellingComponent} from './loged/my-account/overview/overview-lead-selling/overview-lead-selling.component';
import {OverviewLeadBuyingComponent} from './loged/my-account/overview/overview-lead-buying/overview-lead-buying.component';
import {OverviewRetailerExporterConsumerComponent} from './loged/my-account/overview/overview-retailer-exporter-consumer/overview-retailer-exporter-consumer.component';
import {OverviewServiceProviderSellingComponent} from './loged/my-account/overview/overview-service-provider-selling/overview-service-provider-selling.component';
import {OverviewServiceProviderBuyingComponent} from './loged/my-account/overview/overview-service-provider-buying/overview-service-provider-buying.component';
import { NotFoundVASComponent } from './loged/value-added-services/not-found-vas/not-found-vas.component';
import { EconomicCenterShopOwnerComponent } from './sign-up/forms/economic-center-shop-owner/economic-center-shop-owner.component';
import {RatingModule} from 'ng-starrating';
import {BarRatingModule} from 'ngx-bar-rating';
import {BnNgIdleService} from 'bn-ng-idle';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    CheckoutComponent,
    MaintenanceModeComponent,
    ViewBidsComponent,
    FaqComponent,
    AboutComponent,
    ContactComponent,
    ReceiptComponent,
    AwardBidComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignUpComponent,
    SignUpAgentComponent,
    SignUpCollectorComponent,
    SignUpConsumerComponent,
    SignUpExporterComponent,
    SignUpFarmerComponent,
    SignUpWholeSaleBuyerComponent,
    MyPurchasesComponent,
    ServiceProviderComponent,
    TransportProvidersComponent,
    RetailerComponent,
    RiyaviruTermsComponent,
    AgentTermsComponent,
    OtherTermsComponent,
    RiyaviruAppTermsComponent,
    MyAccountComponent,
    BidsAuctionsComponent,
    SidebarComponent,
    FarmerOverviewComponent,
    FarmerMyProductsComponent,
    FarmerReviewsComponent,
    FarmerSalesComponent,
    FarmerOrdersComponent,
    FarmerSellAProductComponent,
    FarmerSpotSellingComponent,
    FarmerAuctionComponent,
    FarmerForwardContractComponent,
    ProductViewSpotComponent,
    ProductViewBidComponent,
    ProductViewFowardComponent,
    BankAccountComponent,
    ReviewsComponent,
    OrderRequestComponent,
    DeliveryBidsComponent,
    AccountSettingsComponent,
    BuyerBidsAuctionsComponent,
    BuyerCreateNewOrderComponent,
    MyFarmersComponent,
    RemarkComponent,
    FarmerEditSpotProductComponent,
    TermsConditionsComponent,
    FarmingCompanyComponent,
    AggregatorCompanyComponent,
    WholesaleBuyerCompanyComponent,
    ConsumerCompanyComponent,
    ExporterCompanyComponent,
    RetailerCompanyComponent,
    ServiceProviderCompanyComponent,
    Slider1Component,
    Slider2Component,
    Slider3Component,
    ProfileComponent,
    FarmerEarningsComponent,
    SettingOverviewComponent,
    AgentProductsComponent,
    TableProductViewComponent,
    MyBidsComponent,
    PendingMyPaymentsComponent,
    PendingMyPaymentsComponent,
    PrivacyPolicyComponent,
    CustomerProtectionPolicyComponent,
    OtherTermsSiComponent,
    OtherTermsTaComponent,
    MyWholesalersComponent,
    FarmerEditAuctionProductComponent,
    DeliveryBidRequestComponent,
    RiyaviruCheckoutComponent,
    RiyawiruPrivacyPolicyComponent,
    ValueAddedServicesComponent,
    VoicePackagesComponent,
    ManageLoansComponent,
    LoanFormsComponent,
    LSidebarComponent,
    LForFarmersComponent,
    LForAggregatorsComponent,
    LForManufacturesComponent,
    LForSuppliersComponent,
    ManageInsuranceComponent,
    InsurancePlansComponent,
    VehicleInsurance,
    ISidebarComponent,
    PlantMachineryApplicationComponent,
    PlantMachinerySchemeComponent,
    FireApplicationComponent,
    FireSchemeComponent,
    AccidentApplicationComponent,
    AccidentSchemeComponent,
    ProductViewOrderRequestComponent,
    MyOrderRequestsComponent,
    EditOrderRequestsComponent,
    BuyerOrderRequestBidsComponent,
    ViewMyOrderRequestBidsComponent,
    RiyawiruMobilePrivacyPolicyComponent,
    RiyawiruMobileUserAgreementComponent,
    RiyawiruPaymentAuthenticationComponent,
    OverviewFarmerSellingComponent,
    OverviewFarmerBuyingComponent,
    OverviewWholesalerSellingComponent,
    OverviewWholesalerBuyingComponent,
    OverviewAggregatorSellingComponent,
    OverviewAggregatorBuyingComponent,
    OverviewLeadSellingComponent,
    OverviewLeadBuyingComponent,
    OverviewRetailerExporterConsumerComponent,
    OverviewServiceProviderSellingComponent,
    OverviewServiceProviderBuyingComponent,
    NotFoundVASComponent,
    EconomicCenterShopOwnerComponent,
  ],
  imports: [
    NgxPrintModule,
    NgOtpInputModule,
    NgImageSliderModule,
    NgxPaginationModule,
    NgxDropzoneModule,
    ExportAsModule,
    NgxDocViewerModule,
    TranslateModule.forRoot(),
    TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}}),
    NgxSimpleCountdownModule,
    AngularCountdownDateTimeModule,
    ChartsModule,
    Ng5SliderModule,
    NgbModule,
    NgxContentLoadingModule,
    MatSliderModule,
    BrowserModule,
    SlickCarouselModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatNativeDateModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(
      {
        positionClass: 'toast-bottom-right',
      }
    ),
    ReactiveFormsModule,
    MatGridListModule,
    MatProgressBarModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    CountDownModule,
    NgxPrintModule,
    MatListModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    MatPaginatorModule,
    SatDatepickerModule,
    SatNativeDateModule,
    BrowserAnimationsModule, // BrowserAnimationsModule is required
    CookieLawModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    CollapseModule,
    MatSortModule,
    ShowHidePasswordModule,
    LazyLoadImageModule,
    MatStepperModule,
    RatingModule,
    BarRatingModule
  ],
  providers: [
    CookieService,
    TenantService,
    BnNgIdleService,
    Global,
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    RemarkComponent,
    FarmerEditSpotProductComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
