import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';

/**
 * @author Sachin Dilshan
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})
export class ValidationHandlerService {

  constructor() {
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }


  filterOnlySpecialCharacters(word) {
    let filter = new RegExp('[a-zA-Z]');
    return filter.test(word) == true;
  }

  emailValidation() {
    return '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  }

  nameValidation() {
    return '^[^\\s][@.a-z A-Z  \u0D80-\u0DFF \u0B80-\u0BFF]+$';
  }

  companyNameValidation() {
    return '^[^\\s][A-Za-z().,& \u0D80-\u0DFF \u0B80-\u0BFF]+$';
  }

  companyRegistrationNoValidation() {
    return '^[^\\s][A-Za-z0-9 ().,-\\\\ //]+$';
  }

  companyVATNoValidation() {
    return '^[0-9]{9}[-][0-9]{4}$';
  }

  companyPostalAddressValidation() {
    return '^[A-Za-z0-9\\()\'/\\.\\-\\s\\,\u0D80-\u0DFF \u0B80-\u0BFF]+$';
  }

  addressValidation() {
    return '^[A-Za-z0-9\\()\'/\\.\\-\\s\\,\u0D80-\u0DFF \u0B80-\u0BFF]+$';
  }

  purchaseVolumeValidation() {
    return '^[0-9.]*$';
  }

  checkValidColorCode(code) {
    const filter = new RegExp('^#(?:[0-9a-fA-F]){1,6}$');
    return filter.test(code) === true;
  }

  referenceNumberValidation() {
    return '^[A-Z0-9]{10}$';
  }

  mobileNumberValidation() {
    return '^[+]*[(]{0,1}(?=(?:\\D*\\d){9,11}\\D*$)\\+?[0-9]{1,3}[\\s-]?(?:\\(0?[0-9]{1,5}\\)|[0-9]{1,5})[-\\s]?[0-9][\\d\\s-]{5,7}\\s?(?:x[\\d-]{0,4})?$';

  }

  onlyTenNumberValidation() {
    return '^\\d{10}$';
  }

  mobileNumberValidationFor10Digits() {
    return '^[+]*[(]{0,1}(?=(?:\\D*\\d){9,10}\\D*$)\\+?[0-9]{1,3}[\\s-]?(?:\\(0?[0-9]{1,5}\\)|[0-9]{1,5})[-\\s]?[0-9][\\d\\s-]{5,7}\\s?(?:x[\\d-]{0,4})?$';
  }

  pinVerification() {
    return '^[0-9]{4}$';
  }

  passwordValidation() {
    return '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&#^?])[A-Za-z\\d$@$!%*?&#^?]{8,15}';
  }

  userNameVerification() {
    return '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{5,9})$';
  }

  sriLankaNICValidation() {
    return '^((?!(0))[0-9]{9}[x|X|v|V]|(?!(0))[0-9]{12})$';
  }

  whiteSpaceNotAllowed() {
    return '^[^\\s][A-Za-z0-9 \u0D80-\u0DFF \u0B80-\u0BFF \\r\\n ().,-\\\\ #&\'"%=_+]+$';
  }

  whiteSpaceNotAllowedForSinTam() {
    return '^[^\\s][A-Za-z0-9 \u0D80-\u0DFF \u0B80-\u0BFF ().,-\\\\ //#&]+$';
  }

  onlyNumbersAllowed() {
    return '^[0-9]*$';
  }


  manageLocalizationSuccessTittle(language) {
    switch (language) {
      case 'en' :
        return 'Congratulations! Now you are registered with Helaviru';

      case 'si' :
        return 'අපේ සුභ පැතුම්! ඔබ හෙළවිරු සමඟ ලියාපදිංචි වී ඇත';

      case 'ta' :
        return 'வாழ்த்துக்கள்! நீங்கள் ஹெலவிருவில் பதிவு செய்துள்ளீர்கள்';
    }
  }

  manageLocalizationContactUSSuccessTittle(language) {
    switch (language) {
      case 'en' :
        return 'Congratulations! Your message is submitted to Helaviru.';

      case 'si' :
        return 'සුභ පැතුම්! ඔබේ පණිවිඩය හෙළවිරු වෙත ඉදිරිපත් කර ඇත.';

      case 'ta' :
        return 'வாழ்த்துக்கள்! உங்கள் செய்தி ஹெலவிருக்கு சமர்ப்பிக்கப்பட்டுள்ளது.';
    }
  }

  manageLocalizationSuccessBodyMessage(language) {
    switch (language) {
      case 'en' :
        return 'Thank you for registering with Helaviru. Your account will be reviewed and approved shortly.\n';

      case 'si' :
        return 'හෙළවිරු සමඟ ලියාපදිංචිවූ ඔබට ස්තූතියි. ඔබගේ ගිණුම ඉක්මනින් සමාලෝචනය කර අනුමත කරනු ඇත.';

      case 'ta' :
        return 'வாழ்த்துக்கள்! உங்கள் செய்தி\n' +
          'ஹெலவிருக்கு\n' +
          'சமர்ப்பிக்கப்பட்டுள்ளது.';
    }
  }

  manageLocalizationContactUsSuccessBodyMessage(language) {
    switch (language) {
      case 'en' :
        return 'You will be contacted shortly by a Helaviru Agent.';

      case 'si' :
        return 'හෙළවිරු නියෝජිතයෙකු විසින් ඔබව අමතනු ඇත.';

      case 'ta' :
        return 'உங்களை விரைவில் ஒரு ஹெலவிரு முகவர் தொடர்பு கொள்வார்.';
    }
  }

  manageLocalizationSuccessButton(language) {
    switch (language) {
      case 'en' :
        return 'Close';

      case 'si' :
        return 'වසන්න';

      case 'ta' :
        return 'மூட';
    }
  }

  passwordReset(language) {
    switch (language) {
      case 'en' :
        return 'Password Reset';

      case 'si' :
        return 'මුරපදය වෙනස් කිරීම.';

      case 'ta' :
        return 'கடவுச்சொல்லை மீட்டமை';
    }
  }

  passwordResetMsg(language) {
    switch (language) {
      case 'en' :
        return 'Your password has been reset. Please login using your new password now.';

      case 'si' :
        return 'ඔබගේ මුරපදය යළි වෙනස් කරන ලදි. කරුණාකර ඔබගේ නව මුරපදය භාවිතා කර පුරනය වන්න.';

      case 'ta' :
        return 'உங்களுடைய கடவுச்சொல் மாற்றப்பட்டது. உங்கள் புதிய கடவுச்சொல்லைப் பயன்படுத்தி இப்போது உள்நுழைக.';
    }
  }


  manageLoadingMessage(language) {
    switch (language) {
      case 'en' :
        return 'Processing...';

      case 'si' :
        return 'සැකසෙමින් පවතී...';

      case 'ta' :

        return 'செயலாகுகின்றது...';
    }
  }

  validateDeliveryMethod(language) {
    switch (language) {
      case 'en' :
        return 'Please select delivery option.';

      case 'si' :
        return 'කරුණාකර බෙදා හැරීමේ විකල්ප ප්\u200Dරවාහන සේවාව තෝරන්න';

      case 'ta' :

        return 'விநியோக விருப்பத்தைத் தேர்ந்தெடுக்கவும்';
    }
  }

  manageCheckoutMessage(language) {
    switch (language) {
      case 'en' :
        return 'Please do not refresh or close the window until payment is processed.';

      case 'si' :
        return 'ගෙවීම ඉදිරියට යන තුරු කරුණාකර කවුළුව වසා නොදමන්න.';

      case 'ta' :

        return 'கட்டணம் செலுத்தும் வரை சாளரத்தை புதுப்பிக்கவோ அல்லது மூடவோ வேண்டாம்.';
    }
  }

  manageErrorMessage(language) {
    switch (language) {
      case 'en' :
        return 'Something went wrong. Please try again.';

      case 'si' :
        return 'වැරදීමක් සිදුවී ඇත. කරුණාකර නැවත උත්සාහ කරන්න.';

      case 'ta' :

        return 'ஏதோ தவறு நடைபெற்றிருக்கிறது, மீண்டும் முயற்சி செய்க!';
    }
  }

  commonExistsMessage(language) {
    switch (language) {
      case 'en' :
        return 'We have noted that you have already registered on the Helaviru Agro-produce & commodities platform. If you wish to add multiple roles, you may do so by upgrading your Helaviru profile once our operation starts on the platform.';

      case 'si' :
        return 'ඔබ දැනටමත් ඩිජිටල් කෘෂි භෝග සහ ගොවිපල නිෂ්පාදන ආර්ථික මධස්ථානයෙහි ලියාපදිංචි වී ඇති බව අපි සටහන් කර ඇත්තෙමු. ඔබට තවත් භූමිකාවන් එක් කිරීමට අවශ්‍ය නම්, අපගේ ගණුදෙනු ආරම්භ වූ වහාම ඔබේ හෙළවිරු ගිණුම යාවත්කාලීන කිරීමෙන් ඔබට එසේ කළ හැකිය.';

      case 'ta' :

        return 'நீங்கள் ஏற்கனவே ஹெலவிரு வேளாண்மை உற்பத்தி மற்றும் பொருட்கள் தளத்திற்க்கு  பதிவு செய்துள்ளதை நாங்கள் குறிப்பிட்டுள்ளோம். நீங்கள் பல பாத்திரங்களைச் சேர்க்க விரும்பினால், எங்கள் செயல்பாடு தளம் தொடங்கியதும் உங்கள் ஹெலவிரு சுயவிவரத்தை மேம்படுத்துவதன் மூலம் நீங்கள் அவ்வாறு செய்யலாம்.';
    }
  }

  logOutMessage(language) {
    switch (language) {
      case 'en' :
        return 'Sign Out';

      case 'si' :
        return 'ඉවත්වන්න';

      case 'ta' :

        return 'வெளியேறு';
    }
  }

  logOutMessages(language) {
    switch (language) {
      case 'en' :
        return 'You will be returned to the login screen';

      case 'si' :
        return 'ඔබ නැවත පිවිසුම් තිරයට පැමිණෙනු ඇත';

      case 'ta' :

        return 'நீங்கள் உள்நுழைவுத் திரையில் திரும்பப்படுவீர்கள்';
    }
  }

  cancelMessage(language) {
    switch (language) {
      case 'en' :
        return 'Cancel';

      case 'si' :
        return 'අවලංගු කරන්න';

      case 'ta' :

        return 'ரத்துசெய்';
    }
  }

  yesButtonMessage(language) {
    switch (language) {
      case 'en' :
        return 'Yes';

      case 'si' :
        return 'ඔව්';

      case 'ta' :

        return 'ஆம்';
    }
  }

  ownAuctionProductMessage(language) {
    switch (language) {
      case 'en' :
        return 'You cannot bid on your own product.';

      case 'si' :
        return 'ඔබට ඔබේම නිෂ්පාදනයක් සඳහා ලංසු තැබිය නොහැක.';

      case 'ta' :

        return 'உங்கள் சொந்த தயாரிப்புக்கு நீங்கள் ஏலம் எடுக்க முடியாது.';
    }
  }

  customBidTitle(language) {
    switch (language) {
      case 'en' :
        return 'Do you want to confirm the value of this bid?';

      case 'si' :
        return 'මෙම ලංසුවෙහි වටිනාකම තහවුරු කිරීමට ඔබට අවශ්‍යද?';

      case 'ta' :

        return 'இந்த முயற்சியின் மதிப்பை உறுதிப்படுத்த விரும்புகிறீர்களா?';
    }
  }

  alreadyBids(language) {
    switch (language) {
      case 'en' :
        return 'This product has already been bid!';

      case 'si' :
        return 'මෙම නිෂ්පාදනය දැනටමත් ලංසු කර ඇත!';

      case 'ta' :

        return 'இந்த தயாரிப்பு ஏற்கனவே ஏலம் விடப்பட்டுள்ளது!';
    }
  }

  orderRejectionMessage(language) {
    switch (language) {
      case 'en' :
        return 'If you cancel this order, a fee of LKR200 for the canceled order will automatically be charged and will be added to your next cash order. Are you Sure?';

      case 'si' :
        return 'ඔබ මෙම ඇණවුම අවලංගු කළහොත්, අවලංගු කරන ලද ඇණවුම සඳහා රුපියල් 200 ක ගාස්තුවක් ස්වයංක්‍රීයව අය කෙරෙන අතර එය ඔබගේ ඊළඟ මුදල් ඇණවුමට එකතු වේ. ඔබට විශ්වාසද?';

      case 'ta' :

        return 'இந்த ஆர்டரை நீங்கள் ரத்துசெய்தால், ரத்து செய்யப்பட்ட ஆர்டருக்கு 200 ரூபாய் கட்டணம் தானாகவே வசூலிக்கப்படும், மேலும் இது உங்கள் அடுத்த பண ஆர்டரில் சேர்க்கப்படும். நீ சொல்வது உறுதியா?\n';
    }
  }

  imageValidateMessage(language) {
    switch (language) {
      case 'en' :
        return 'Minimum 1 and maximum 4 product images required';

      case 'si' :
        return 'අවම 1 සහ උපරිම නිෂ්පාදන ඡායාරූප 4 ක් අවශ්‍ය වේ';

      case 'ta' :
        return 'குறைந்தபட்சம் 1 மற்றும் அதிகபட்சம் 4 தயாரிப்பு படங்கள் தேவை';
    }
  }

  imageValidate(language) {
    switch (language) {
      case 'en' :
        return 'Passbook images are required';

      case 'si' :
        return 'පාස් පොත් ඡායාරූප අවශ්‍ය වේ';

      case 'ta' :
        return 'பாஸ்புக் படங்கள் தேவை';
    }
  }

  okMessage(language) {
    switch (language) {
      case 'en' :
        return 'ok';

      case 'si' :
        return 'හරි';

      case 'ta' :

        return 'சரி';
    }
  }

  imageValidateMessage1MB(language) {
    switch (language) {
      case 'en' :
        return 'Image file size is greater than 5MB. Please upload file below 5MB';

      case 'si' :
        return 'රූපයේ ප්\u200Dරමාණය 5MB ට වඩා වැඩිය. කරුණාකර 5MB ට අඩු රූපයක් ඉදිරිපත් කරන්න';

      case 'ta' :

        return 'படக் கோப்பு அளவு 5MB ஐ விட அதிகமாக உள்ளது. 5MB க்கு கீழே உள்ள கோப்பை பதிவேற்றவும்';
    }
  }

  checkTransactionID(language) {
    switch (language) {
      case 'en' :
        return 'Transaction ID is required.';

      case 'si' :
        return 'ගණුදෙනු අංකය අවශ්‍ය වේ';

      case 'ta' :

        return 'காசோலை';
    }
  }

  percentageMessage(language) {
    switch (language) {
      case 'en' :
        return 'Percentage should add up to 100%';

      case 'si' :
        return 'ප්‍රතිශතය 100% දක්වා එකතු කළ යුතුය';

      case 'ta' :

        return 'சதவீதம் 100% வரை சேர்க்க வேண்டும்';
    }
  }

  auctionBidSuccess(language) {
    switch (language) {
      case 'en' :
        return 'Your bid has been placed successfully.';

      case 'si' :
        return 'ඔබගේ ලංසුව සාර්ථකව තබා ඇත.';

      case 'ta' :

        return 'உங்கள் ஏலம் வெற்றிகரமாக வைக்கப்பட்டுள்ளது.';
    }
  }

  orderRequestBidSuccess(language) {
    switch (language) {
      case 'en' :
        return 'Thank you! We will let you know once your bid is awarded by the Buyer.';

      case 'si' :
        return 'ඔබට ස්තුතියි! ඔබේ ලංසුව ගැනුම්කරු විසින් ප්‍රදානය කළ පසු අපි ඔබට දන්වන්නෙමු.';

      case 'ta' :

        return 'நன்றி! உங்கள் ஏலத்தை வாங்குபவர் வழங்கியவுடன் நாங்கள் உங்களுக்குத் தெரியப்படுத்துவோம்.';
    }
  }

  auctionBidError1(language) {
    switch (language) {
      case 'en' :
        return 'Your bid must have to greater than the current bid!';

      case 'si' :
        return 'ඔබගේ ලංසුව වත්මන් ලංසුවට වඩා වැඩි විය යුතුය!';

      case 'ta' :

        return 'உங்கள் ஏலம் தற்போதைய ஏலத்தை விட அதிகமாக இருக்க வேண்டும்!';
    }
  }

  auctionBidError2(language) {
    switch (language) {
      case 'en' :
        return 'Please input your bid value!';

      case 'si' :
        return 'කරුණාකර ඔබේ ලංසු අගය ඇතුළත් කරන්න!';

      case 'ta' :

        return 'உங்கள் ஏல மதிப்பை உள்ளிடுக!';
    }
  }

  quantityMessage(language) {
    switch (language) {
      case 'en':
        return 'Selling smaller quantity should be divisible by total available quantity!';

      case 'si':
        return 'කුඩා ප්‍රමාණයක් විකිණීමට ලබා ගත හැකි මුළු ප්‍රමාණයෙන් බෙදිය යුතුය';

      case  'ta':
        return 'சிறிய அளவை விற்பது மொத்த அளவு மூலம் வகுக்கப்பட வேண்டும்';

    }
  }

  accountSettingMessage(language) {
    switch (language) {
      case 'en':
        return 'Account setting updated successfully!';

      case 'si':
        return 'ගිණුම් සැකසුම සාර්ථකව යාවත්කාලීන කරන ලදි';

      case  'ta':
        return 'கணக்கு அமைப்பு வெற்றிகரமாக புதுப்பிக்கப்பட்டது';

    }
  }

  checkoutMessage(language) {
    switch (language) {
      case 'en':
        return 'Ops! Some items in your checkout list has been sold out. We will redirect you to your cart now to see what’s missing.';

      case 'si':
        return 'ඔබගේ ලැයිස්තුවේ ඇති සමහර අයිතම දැනටමත් විකිණී ඇත. එම අතපසු වූ භාණ්ඩ ගැන දැනගැනීමට, ඔබගේ කාට් එක වෙත  නැවත යොමු කරනු ලැබේ.';

      case  'ta':
        return 'அச்சச்சோ! உங்கள் புதுப்பித்து பட்டியலில் உள்ள சில பொருட்கள் விற்றுவிட்டன. \n' +
          'காணாமல் போனதைக் காண நீங்கள் இப்போது உங்கள் கொள்முதல் பெட்டகத்தில் காணுங்கள்.';

    }
  }

  checkStatus(language) {
    switch (language) {
      case 'en':
        return 'Transaction ID Status Check';

      case 'si':
        return 'ගනුදෙනු හැඳුනුම්පත් තත්ව පරීක්ෂාව';

      case  'ta':
        return 'பரிவர்த்தனை ஐடி நிலை சோதனை';

    }
  }

  checkButton(language) {
    switch (language) {
      case 'en':
        return 'Check';

      case 'si':
        return 'පිරික්සීම';

      case  'ta':
        return 'சரிபார்க்கிறது';

    }
  }

  subscribeMessage(language) {
    switch (language) {
      case 'en':
        return 'Subscribed!';

      case 'si':
        return 'ඇතුලත් වී ඇත.';

      case  'ta':
        return 'சந்தா!';

    }
  }

  removedMessage(language) {
    switch (language) {
      case 'en':
        return 'Item is removed!';

      case 'si':
        return 'අයිතමය ඉවත් කර ඇත';

      case  'ta':
        return 'பொருள் அகற்றப்பட்டது!';

    }
  }

  itemAddedMessage(language) {
    switch (language) {
      case 'en':
        return 'Item added successfully';

      case 'si':
        return 'අයිතමය සාර්ථකව එකතු කරන ලදි';

      case  'ta':
        return '\n' +
          'உருப்படி வெற்றிகரமாக சேர்க்கப்பட்டது';

    }
  }

  bankSaveMessage(language) {
    switch (language) {
      case 'en':
        return 'Bank Account Saved Successfully';

      case 'si':
        return 'බැංකු ගිණුම සාර්ථකව සුරකින ලදි';

      case  'ta':
        return 'வங்கி கணக்கு வெற்றிகரமாக சேமிக்கப்பட்டது';

    }
  }

  bankDeleteMessage(language) {
    switch (language) {
      case 'en':
        return 'Bank Account Deleted Successfully.';

      case 'si':
        return 'බැංකු ගිණුම සාර්ථකව මකා දමන ලදි';

      case  'ta':
        return 'வங்கி கணக்கு வெற்றிகரமாக நீக்கப்பட்டது';

    }
  }

  bankUpdateMessage(language) {
    switch (language) {
      case 'en':
        return 'Bank Account is Updated.';

      case 'si':
        return 'බැංකු ගිණුම යාවත්කාලීන කර ඇත.';

      case  'ta':
        return 'வங்கி கணக்கு புதுப்பிக்கப்பட்டது.';

    }
  }


  usernameValidationPattern(value) {

    if (/^[a-zA-Z]+$/.test(value)) {
      return true;
    } else if (/^[0-9]+$/.test(value)) {
      return true;
    } else if (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{5,9})$/.test(value)) {
      return false;
    } else if (!/^\S*$/.test(value)) {
      return true;
    } else if (value.length > 9 || value.length < 5) {
      return true;
    } else if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return true;
    }
    return false;

  }

}
