import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {HttpInterceptor} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {environment} from '../../environments/environment';

/**
 * @author Sachin Dilshan
 * @version 1.0.0
 */

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  tenantValue: any;

  constructor(private _router: Router) {

  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token = localStorage.getItem('$Token');
    localStorage.setItem('TENANT_VALUE', environment.tenantID);
    let TENANT_VALUE = localStorage.getItem('TENANT_VALUE');

    if (token !== null) {
      const tokenReq: HttpRequest<any> = req.clone({
        setHeaders: {
          'Access-Token': `Bearer ${token}`,
          'X-TenantID': TENANT_VALUE,
          'Content-Encoding': 'gzip'
        }
      });
      return next.handle(tokenReq);
    } else if (TENANT_VALUE !== null || TENANT_VALUE !== 'null') {
      const tokenReq: HttpRequest<any> = req.clone({
        setHeaders: {
          'X-TenantID': TENANT_VALUE,
          'Content-Encoding': 'gzip'
        }
      });
      return next.handle(tokenReq);
    }
  }

}
