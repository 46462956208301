<div>
  <div class="lbl hding">Remark</div>
  <form [formGroup]="remarkForm" (ngSubmit)="submit()">
      <textarea  style="margin: 5%;width: 87%;color: black" rows="4" [(ngModel)]="remark" formControlName="remark" class="form-control" id="remark"
                [ngClass]="{ 'is-invalid': submitted && f.remark.errors }"></textarea>
    <div style="margin-left: 3vw;    margin-top: -2vh;" *ngIf="submitted && f.remark.errors" class="inputError">
      <div *ngIf="f.remark.errors.required">
        Remark field is required!
      </div>
    </div>
    <button style="    margin-bottom: 2vh;
    float: right;
    margin-right: 4vw;" class="btn btn-primary btn submit-btn remarkBtn"
            type="submit">
      Submit
    </button>
  </form>
</div>
<button id="RejectBtn" #Reject style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#Reject"></button>
<div class="modal fade npa-model" id="Reject" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-check"></i>
          <h3>Rejected</h3>
          <p>Order Status Updated.</p>
          <a href="" class="btn-b b-approve" data-dismiss="modal">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
