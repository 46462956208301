<div class="page-contents">
  <div class="packge-form" id="insurnce">
    <div class="top-info-section text-center">
      <img src="../../../../../../assets/images/payment-types/insuarance/slic.png" alt="" class="sltimg">
      <h4 class="title-pck">
        <span *ngIf="activeLang === 'en'">Proposal form for Insurance of own goods of vehicles</span>
        <span *ngIf="activeLang === 'si'">තමන්ගේම වාහන වල රක්‍ෂණ සඳහා වන යෝජනා පත්‍රය</span>
        <span *ngIf="activeLang === 'ta'">வாகனங்களின் சொந்த பொருட்களின் காப்பீட்டுக்கான முன்மொழிவு படிவம்</span>
      </h4>
      <p *ngIf="activeLang === 'en'">(This proposal form is applicable for Goods transported by farmers to the Market.
        Perishable nature goods
        transported in refer containers, freezer trucks are not intended to be covered and such Goods could be
        insured separately.)
      </p>
      <p *ngIf="activeLang === 'si'">(මෙම යෝජනා පත්‍රය අදාළ වන්නේ ගොවීන් විසින් වෙළඳපොළට ප්‍රවාහනය කරන භාණ්ඩ සඳහා ය.
        දිරාපත් වන ස්වාභාවික භාණ්ඩ
        යොමු කරන ලද බහාලුම්වල ප්‍රවාහනය කරන ලද, ශීතකරණ ට්‍රක් රථ ආවරණය කිරීමට අදහස් නොකරන අතර එවැනි භාණ්ඩ විය හැකිය
        වෙනම රක්‍ෂණය කර ඇත.)
      </p>
      <p *ngIf="activeLang === 'ta'">(இந்த முன்மொழிவு படிவம் விவசாயிகள் சந்தைக்கு கொண்டு செல்லும் பொருட்களுக்கு
        பொருந்தும். அழியும் இயற்கை பொருட்கள்
        குறிப்பு கொள்கலன்களில் கொண்டு செல்லப்படுகிறது, உறைவிப்பான் லாரிகள் மூடப்படாது, அத்தகைய பொருட்கள் இருக்கலாம்
        தனித்தனியாக காப்பீடு செய்யப்பட்டுள்ளது.)
      </p>
      <p class="rednotice">
        <span *ngIf="activeLang === 'en'">** You have to provide a valid document proof at the time of issuing the connection</span>
        <span
          *ngIf="activeLang === 'si'">** සම්බන්ධතාවය ලබා දීමේදී ඔබ වලංගු ලේඛන සාක්‍ෂියක් ලබා දිය යුතුය</span>
        <span *ngIf="activeLang === 'ta'">** இணைப்பை வழங்கும்போது சரியான ஆவணச் சான்றை நீங்கள் வழங்க வேண்டும்</span>
      </p>
    </div>
    <div *ngIf="section == 0" class="form-inputs-section vasforms">
      <form [formGroup]="form" autocomplete="off" class="sms-full-width form-box-panel">
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
            <label class="numbr">1)</label>
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">(a) Name of Proposer</span>
              <span *ngIf="activeLang === 'si'">(a) යෝජකයාගේ නම</span>
              <span *ngIf="activeLang === 'ta'">(a) முன்மொழிபவரின் பெயர்</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
                     formControlName="fullName" id="fullName" matInput>
              <div *ngIf="submitted && f.fullName.errors" class="inputError">
                <div *ngIf="f.fullName.errors.required">{{'COMMON_VALIDATION.NAME' | translate}}</div>
                <div
                  *ngIf="f.fullName.errors.pattern">{{'REGISTER.FARMER.VALIDATE_FIELD.VALIDATE_FULL_NAME' | translate}}
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <label class="mtlbl">
              <span *ngIf="activeLang === 'en'">(b) Address</span>
              <span *ngIf="activeLang === 'si'">(b) ලිපිනය</span>
              <span *ngIf="activeLang === 'ta'">(b) முகவரி</span>
            </label><sup style="color: red"> *</sup>
            <mat-form-field appearance="outline" class="inputbox">
              <input readonly [(ngModel)]="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                     formControlName="address" id="address" matInput>
              <div *ngIf="submitted && f.address.errors" class="inputError">
                <div *ngIf="f.address.errors.required">{{'COMMON_VALIDATION.ADDRESS' | translate}}</div>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <div class="row m-0">
              <div class="col-lg-6 col-md-12 col-xs-12 p-l-0">
                <label class="mtlbl">
                  <span *ngIf="activeLang === 'en'">(c) Trade or Business</span>
                  <span *ngIf="activeLang === 'si'">(c) වෙළඳාම හෝ ව්‍යාපාර</span>
                  <span *ngIf="activeLang === 'ta'">(c) வர்த்தகம் அல்லது வணிகம்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <mat-select (openedChange)="clickDropdown()" formControlName="tradeOrBusiness"
                              [(ngModel)]="tradeOrBusiness"
                              [ngClass]="{ 'is-invalid': submitted && f.tradeOrBusiness.errors }">
                    <mat-option *ngFor="let modal of businessModel" [value]="modal.id">
                      {{modal.description | translate}}
                    </mat-option>
                  </mat-select>
                  <div *ngIf="submitted && f.tradeOrBusiness.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">Trade or Business is required!.</span>
                    <span *ngIf="activeLang === 'si'">වෙළඳාම හෝ ව්‍යාපාර අවශ්‍යයි !.</span>
                    <span *ngIf="activeLang === 'ta'">வர்த்தகம் அல்லது வியாபாரம் தேவை !.</span>
                  </div>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
                <label class="mtlbl">
                  <span *ngIf="activeLang === 'en'">(d) NIC/ Business Registration Number</span>
                  <span *ngIf="activeLang === 'si'">(d) ජාතික හැඳුනුම්පත්/ ව්‍යාපාර ලියාපදිංචි අංකය</span>
                  <span *ngIf="activeLang === 'ta'">(d) என்ஐசி/ வணிக பதிவு எண்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <input readonly [(ngModel)]="nicOrBusNo"
                         [ngClass]="{ 'is-invalid': submitted && f.nicOrBusNo.errors }"
                         formControlName="nicOrBusNo" id="nicOrBusNo" matInput>
                  <div *ngIf="submitted && f.nicOrBusNo.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">NIC/ Business Registration Number is required!.</span>
                    <span *ngIf="activeLang === 'si'">ජාතික හැඳුනුම්පත්/ ව්‍යාපාර ලියාපදිංචි අංකය අවශ්‍යයි!</span>
                    <span *ngIf="activeLang === 'ta'">என்ஐசி/ வணிக பதிவு எண் தேவை !.</span>
                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <div class="row m-0">
              <div class="col-lg-6 col-md-12 col-xs-12 p-l-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">(e) Tele/Mobile Number</span>
                  <span *ngIf="activeLang === 'si'">(e) දුරකථන/ජංගම දුරකථන අංකය</span>
                  <span *ngIf="activeLang === 'ta'">(e) தொலைபேசி/மொபைல் எண்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <input readonly [(ngModel)]="mobileNumber"
                         [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }"
                         formControlName="mobileNumber" id="mobileNumber" matInput>
                  <div *ngIf="submitted && f.mobileNumber.errors" class="inputError">
                    <div *ngIf="f.mobileNumber.errors.required">{{'COMMON_VALIDATION.MOBILE' | translate}}</div>
                    <div *ngIf="f.mobileNumber.errors.pattern">{{'COMMON_VALIDATION.VALID_MOBILE' | translate}}
                      {{'COMMON_VALIDATION.VALID_MOBILE_MSG' | translate}}
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">(f) E Mail</span>
                  <span *ngIf="activeLang === 'si'">(f) ඊමේල්</span>
                  <span *ngIf="activeLang === 'ta'">(f) மின் அஞ்சல்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <input  [(ngModel)]="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                         formControlName="email" id="email" matInput>
                  <div *ngIf="submitted && f.email.errors" class="inputError">
                    <div *ngIf="f.email.errors.pattern">{{'COMMON_VALIDATION.VALID_EMAIL' | translate}}</div>
                    <div *ngIf="f.email.errors.required">
                      <span *ngIf="activeLang === 'en'">E Mail is required!.</span>
                      <span *ngIf="activeLang === 'si'">විද්‍යුත් තැපෑල අවශ්‍යයි!</span>
                      <span *ngIf="activeLang === 'ta'">மின் அஞ்சல் தேவை !.</span>
                    </div>

                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
            <label class="numbr">2)</label>
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <div class="row m-0">
              <div class="col-lg-6 col-md-12 col-xs-12 p-l-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">(a) State the nature of goods to be carried</span>
                  <span *ngIf="activeLang === 'si'">(a) රැගෙන යා යුතු භාණ්ඩ වල ස්වභාවය සඳහන් කරන්න</span>
                  <span
                    *ngIf="activeLang === 'ta'">(a) எடுத்துச் செல்லப்படும் பொருட்களின் தன்மையைக் குறிப்பிடவும்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="stateTheNatureOfGood"
                         [ngClass]="{ 'is-invalid': submitted && f.stateTheNatureOfGood.errors }"
                         formControlName="stateTheNatureOfGood" id="stateTheNatureOfGood" matInput>
                  <div *ngIf="submitted && f.stateTheNatureOfGood.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">State the nature of goods to be carried is required!.</span>
                    <span *ngIf="activeLang === 'si'">රැගෙන යා යුතු භාණ්ඩ වල ස්වභාවය සඳහන් කිරීම අවශ්‍යය !.</span>
                    <span
                      *ngIf="activeLang === 'ta'">எடுத்துச் செல்லப்படும் பொருட்களின் இயல்பு தேவை என்று கூறுங்கள்!</span>
                  </div>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">(b) Ordinary Transit Routes/Districts</span>
                  <span *ngIf="activeLang === 'si'">(b) සාමාන්‍ය සංක්‍රාන්ති මාර්ග/දිස්ත්‍රික්ක</span>
                  <span *ngIf="activeLang === 'ta'">(b) சாதாரண போக்குவரத்து வழிகள்/மாவட்டங்கள்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="ordinaryTransitRouters"
                         [ngClass]="{ 'is-invalid': submitted && f.ordinaryTransitRouters.errors }"
                         formControlName="ordinaryTransitRouters" id="ordinaryTransitRouters" matInput>
                  <div *ngIf="submitted && f.ordinaryTransitRouters.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">Ordinary Transit Routes/Districts is required!.</span>
                    <span *ngIf="activeLang === 'si'">සාමාන්‍ය සංක්‍රාන්ති මාර්ග/දිස්ත්‍රික්ක අවශ්‍යයි!.</span>
                    <span *ngIf="activeLang === 'ta'">சாதாரண போக்குவரத்து வழிகள்/மாவட்டங்கள் தேவை!.</span>
                  </div>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>
        <hr>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
            <label class="numbr">3)</label>
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <div class="row m-0">
              <div class="col-lg-6 col-md-12 col-xs-12 p-l-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">(a) Are any vehicles garaged/parked overnight whilst loaded?</span>
                  <span
                    *ngIf="activeLang === 'si'">(a) පටවන අතරතුර කිසියම් වාහනයක් ගරාජ් කර/එක රැයකින් නවතා තිබේද?</span>
                  <span *ngIf="activeLang === 'ta'">(a) எந்த வாகனங்களும் ஏற்றப்படும் போது ஒரே இரவில் கேரேஜ்/நிறுத்தப்படுகின்றனவா?</span>
                  <sup style="color: red"> *</sup>
                </label>
                <mat-radio-group>
                  <mat-radio-button value="1" (click)="setVehicleOrGaraged(true)"
                                    [checked]="isAnyVehiclesGaragedOrParkedButton1">
                    <span *ngIf="activeLang === 'en'">Yes</span>
                    <span *ngIf="activeLang === 'si'">ඔව්</span>
                    <span *ngIf="activeLang === 'ta'">ஆம்</span>
                  </mat-radio-button>
                  <mat-radio-button value="2" (click)="setVehicleOrGaraged(false)"
                                    [checked]="isAnyVehiclesGaragedOrParkedButton2">
                    <span *ngIf="activeLang === 'en'">No</span>
                    <span *ngIf="activeLang === 'si'">නැත</span>
                    <span *ngIf="activeLang === 'ta'">இல்லை</span>
                  </mat-radio-button>

                  <div *ngIf="invalidRadioOption" class="inputError">
                    <span *ngIf="activeLang === 'en'">This section is required!.</span>
                    <span *ngIf="activeLang === 'si'">මෙම කොටස අවශ්යයි!.</span>
                    <span *ngIf="activeLang === 'ta'">இந்த பிரிவு தேவை!.</span>
                  </div>

                </mat-radio-group>
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">(b) If so state whether such vehicles are closed body</span>
                  <span *ngIf="activeLang === 'si'">(b) එසේ නම් එම වාහන වසා දමා තිබේද යන්න සඳහන් කරන්න</span>
                  <span *ngIf="activeLang === 'ta'">(b) அப்படியானால், அத்தகைய வாகனங்கள் மூடப்பட்ட உடலா என்று குறிப்பிடவும்</span>
                </label><sup style="color: red"> *</sup>
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="vehiclesStateClosedBody"
                         [ngClass]="{ 'is-invalid': submitted && f.vehiclesStateClosedBody.errors }"
                         formControlName="vehiclesStateClosedBody" id="vehiclesStateClosedBody" matInput>
                  <div *ngIf="submitted && f.vehiclesStateClosedBody.errors" class="inputError">

                    <span *ngIf="activeLang === 'en'">This field is required!.</span>
                    <span *ngIf="activeLang === 'si'">මෙම ක්ෂේත්රය අවශ්යයි!.</span>
                    <span *ngIf="activeLang === 'ta'">இந்த புலம் தேவை !.</span>
                  </div>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>
        <hr>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
            <label class="numbr">4)</label>
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <p>
              <span *ngIf="activeLang === 'en'">Particulars of Vehicles for which cover is required</span>
              <span *ngIf="activeLang === 'si'">ආවරණය අවශ්‍ය වන වාහන වල විස්තර</span>
              <span *ngIf="activeLang === 'ta'">கவர் தேவைப்படும் வாகனங்களின் விவரங்கள்</span>
              <sup style="color: red"> *</sup></p>
            <table class="table table-bordered vehiclestbl">
              <thead>
              <tr>
                <th scope="col">
                  <span *ngIf="activeLang === 'en'">Item No.</span>
                  <span *ngIf="activeLang === 'si'">අයිතමය අංකය</span>
                  <span *ngIf="activeLang === 'ta'">பொருள் எண்.</span>
                </th>
                <th scope="col">
                  <span *ngIf="activeLang === 'en'">Registered Letters and Numbers</span>
                  <span *ngIf="activeLang === 'si'">ලියාපදිංචි ලිපි සහ අංක</span>
                  <span *ngIf="activeLang === 'ta'">பதிவு செய்யப்பட்ட கடிதங்கள் மற்றும் எண்கள்</span>
                </th>
                <th scope="col">
                  <span *ngIf="activeLang === 'en'">Make of Vehicle</span>
                  <span *ngIf="activeLang === 'si'">වාහන සෑදීම</span>
                  <span *ngIf="activeLang === 'ta'">வாகனத்தை உருவாக்குங்கள்</span>
                </th>
                <th scope="col">
                  <span *ngIf="activeLang === 'en'">Carrying capacity</span>
                  <span *ngIf="activeLang === 'si'">රැගෙන යා හැකි ධාරිතාව</span>
                  <span *ngIf="activeLang === 'ta'">தாங்கும் திறன்</span>
                </th>
                <th scope="col">
                  <span *ngIf="activeLang === 'en'">Maximum Sum Insured of goods Per Vehicle / Carrying</span>
                  <span *ngIf="activeLang === 'si'">වාහනයකට / රැගෙන යන භාණ්ඩ සඳහා උපරිම රක්‍ෂණ මුදල</span>
                  <span *ngIf="activeLang === 'ta'">ஒரு வாகனத்திற்கு / எடுத்துச் செல்லும் பொருட்களின் அதிகபட்ச காப்பீடு தொகை</span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <div class="d-flex">
                    1.
                    <mat-form-field class="inputbox">
                      <input [(ngModel)]="vehiclesCover1Row1" [ngModelOptions]="{standalone: true}"
                             id="vehiclesCover1Row1" matInput>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover1Row2" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover1Row2" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover1Row3" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover1Row3" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover1Row4" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover1Row4" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input type="number" [(ngModel)]="vehiclesCover1Row5" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover1Row5" matInput>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex">
                    2.
                    <mat-form-field class="inputbox">
                      <input [(ngModel)]="vehiclesCover2Row1" [ngModelOptions]="{standalone: true}"
                             id="vehiclesCover2Row1" matInput>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover2Row2" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover2Row2" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover2Row3" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover2Row3" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover2Row4" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover2Row4" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input type="number" [(ngModel)]="vehiclesCover2Row5" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover2Row5" matInput>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex">
                    3.
                    <mat-form-field class="inputbox">
                      <input [(ngModel)]="vehiclesCover3Row1" [ngModelOptions]="{standalone: true}"
                             id="vehiclesCover3Row1" matInput>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover3Row2" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover3Row2" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover3Row3" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover3Row3" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover3Row4" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover3Row4" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input type="number" [(ngModel)]="vehiclesCover3Row5" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover3Row5" matInput>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex">
                    4.
                    <mat-form-field class="inputbox">
                      <input [(ngModel)]="vehiclesCover4Row1" [ngModelOptions]="{standalone: true}"
                             id="vehiclesCover4Row1" matInput>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover4Row2" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover4Row2" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover4Row3" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover4Row3" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover4Row4" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover4Row4" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input type="number" [(ngModel)]="vehiclesCover4Row5" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover4Row5" matInput>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex">
                    5.
                    <mat-form-field class="inputbox">
                      <input [(ngModel)]="vehiclesCover5Row1" [ngModelOptions]="{standalone: true}"
                             id="vehiclesCover5Row1" matInput>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover5Row2" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover5Row2" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover5Row3" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover5Row3" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input [(ngModel)]="vehiclesCover5Row4" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover5Row4" matInput>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input type="number" [(ngModel)]="vehiclesCover5Row5" [ngModelOptions]="{standalone: true}"
                           id="vehiclesCover5Row5" matInput>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h4>
                    <span *ngIf="activeLang === 'en'">TOTAL Rs.</span>
                    <span *ngIf="activeLang === 'si'">එකතුව රු.</span>
                    <span *ngIf="activeLang === 'ta'">மொத்தம் ரூ.</span>
                  </h4>
                </td>
                <td>
                  <mat-form-field class="inputbox">
                    <input matInput disabled value="{{calTotal()}}">
                  </mat-form-field>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr>
        <div class="row flexrow">
          <div class="col-lg-1 col-md-2 col-xs-1">
            <label class="numbr">5)</label>
          </div>
          <div class="col-lg-11 col-md-10 col-xs-11">
            <div class="row m-0">
              <div class="col-lg-6 col-md-12 col-xs-12 p-l-0">
                <label class="mtlbl">

                  <span
                    *ngIf="activeLang === 'en'">Have you any insurances with this Corporation ? If so please state</span>
                  <span *ngIf="activeLang === 'si'">මෙම සංස්ථාව සමඟ ඔබට කිසියම් රක්‍ෂණයක් තිබේද? එසේ නම් කරුණාකර සඳහන් කරන්න</span>
                  <span *ngIf="activeLang === 'ta'">இந்தக் கூட்டுத்தாபனத்தில் ஏதேனும் காப்பீடு உள்ளதா? அப்படியானால் தயவுசெய்து தெரிவிக்கவும்</span>
                </label>
                <mat-form-field appearance="outline" class="inputbox">
                  <input [(ngModel)]="corporationInsurances"
                         [ngClass]="{ 'is-invalid': submitted && f.corporationInsurances.errors }"
                         formControlName="corporationInsurances" id="corporationInsurances" matInput>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12 p-r-0">
                <label class="mtlbl">

                  <span *ngIf="activeLang === 'en'">Period of Insurance</span>
                  <span *ngIf="activeLang === 'si'">රක්‍ෂණ  කාලය</span>
                  <span *ngIf="activeLang === 'ta'">காப்பீட்டு காலம்</span>
                </label><sup style="color: red"> *</sup>
                <div class="d-flex">
                  <mat-form-field appearance="outline" class="inputbox hlfinput">
                    <input type="date" [(ngModel)]="insurancePeriodFrom"
                           [ngClass]="{ 'is-invalid': submitted && f.insurancePeriodFrom.errors }"
                           formControlName="insurancePeriodFrom" id="insurancePeriodFrom" matInput>
                    <div *ngIf="submitted && f.insurancePeriodFrom.errors" class="inputError">

                      <span *ngIf="activeLang === 'en'">This field is required!.</span>
                      <span *ngIf="activeLang === 'si'">මෙම ක්ෂේත්රය අවශ්යයි!.</span>
                      <span *ngIf="activeLang === 'ta'">இந்த புலம் தேவை !.</span>
                    </div>
                  </mat-form-field> &nbsp;
                  <mat-form-field appearance="outline" class="inputbox hlfinput">
                    <input type="date" [(ngModel)]="insurancePeriodTo" [min]="insurancePeriodFrom"
                           [ngClass]="{ 'is-invalid': submitted && f.insurancePeriodTo.errors }"
                           formControlName="insurancePeriodTo" id="insurancePeriodTo" matInput>
                    <div *ngIf="submitted && f.insurancePeriodTo.errors" class="inputError">
                      <span *ngIf="activeLang === 'en'">This field is required!.</span>
                      <span *ngIf="activeLang === 'si'">මෙම ක්ෂේත්රය අවශ්යයි!.</span>
                      <span *ngIf="activeLang === 'ta'">இந்த புலம் தேவை !.</span>
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="declaration">
          <div class="text-center">
            <h5>
              <span *ngIf="activeLang === 'en'">DECLARATION</span>
              <span *ngIf="activeLang === 'si'">විස්තර කිරීම</span>
              <span *ngIf="activeLang === 'ta'">வெளியேற்றம்</span>
            </h5>
            <p *ngIf="activeLang === 'en'">I warrant that the Statements and particulars are true and that I hereby
              agree that this
              Declaration shall be held to be promissory and shall form the basis of the contract between
              me and the Sri Lanka Insurance Corporation Limited and I am willing to accept a Policy subject
              to the terms, exceptions, and conditions prescribed by the Corporation therein, and to pay the
              premiums thereon</p>
            <p *ngIf="activeLang === 'si'">ප්‍රකාශ හා විස්තර සත්‍ය බවත්, මෙය සිදු කරන බවට මම මෙයින් එකඟ වන බවත් මම සහතික
              වෙමි
              ප්‍රකාශය පොරොන්දු විය යුතු අතර අතර කොන්ත්‍රාත්තුවේ පදනම විය යුතුය
              මම සහ ශ්‍රී ලංකා රක්‍ෂණ සංස්ථාව සහ මම සහ ප්‍රතිපත්ති විෂය පිළිගැනීමට කැමැත්තෙමි
              එහි සංස්ථාව විසින් නියම කර ඇති කොන්දේසි, ව්‍යතිරේක සහ කොන්දේසි වලට සහ ගෙවීමට
              එහි වාරික </p>
            <p *ngIf="activeLang === 'ta'">அறிக்கைகள் மற்றும் விவரங்கள் உண்மை என்பதையும், இதை நான் ஒப்புக்கொள்கிறேன்
              என்பதையும் நான் உத்தரவாதம் செய்கிறேன்
              பிரகடனம் உறுதிமொழியாக இருக்க வேண்டும் மற்றும் இடையில் ஒப்பந்தத்தின் அடிப்படையை உருவாக்கும்
              நானும் இலங்கை காப்புறுதி கூட்டுத்தாபன லிமிடெட் மற்றும் நான் ஒரு கொள்கை பாடத்தை ஏற்க தயாராக இருக்கிறேன்
              விதிமுறைகள், விதிவிலக்குகள், மற்றும் மாநகராட்சியால் பரிந்துரைக்கப்பட்ட நிபந்தனைகளுக்கு, மற்றும் செலுத்த
              வேண்டும்
              அதற்கான பிரீமியங்கள்
            </p>
          </div>

          <div class="row flexrow">
            <div class="col-lg-6 col-md-12 col-xs-12">
              <label class="mtlbl">

                <span *ngIf="activeLang === 'en'">Date</span>
                <span *ngIf="activeLang === 'si'">දිනය</span>
                <span *ngIf="activeLang === 'ta'">தேதி</span>
              </label>
              <mat-form-field appearance="outline" class="inputbox">
                <input type="date" [(ngModel)]="applicationDate" readonly
                       [ngClass]="{ 'is-invalid': submitted && f.applicationDate.errors }"
                       formControlName="applicationDate" id="applicationDate" matInput>

              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">
              <label class="mtlbl ">

                <span *ngIf="activeLang === 'en'">Signature of the proposer</span>
                <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන</span>
                <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம்</span>
              </label><sup style="color: red"> *</sup>
              <mat-form-field appearance="outline" class="inputbox">
                <input [(ngModel)]="proposerSignature"
                       [ngClass]="{ 'is-invalid': submitted && f.proposerSignature.errors }"
                       formControlName="proposerSignature" id="proposerSignature" matInput>
                <div *ngIf="submitted && f.proposerSignature.errors" class="inputError">

                  <span *ngIf="activeLang === 'en'">Signature of the proposer is required!.</span>
                  <span *ngIf="activeLang === 'si'">යෝජනා කරන්නාගේ අත්සන අවශ්‍යයි!</span>
                  <span *ngIf="activeLang === 'ta'">முன்மொழிபவரின் கையொப்பம் தேவை !.</span>
                </div>
              </mat-form-field>
            </div>
          </div>

          <div class="">
            <p *ngIf="activeLang === 'en'" class="greytxt m-0">The liability of the Corporation does not commence until
              acceptance of
              the proposal has been intimated by the Corporation.
              21, Vauxhall Street, Colombo 2. Tel : 2357151, 2357157 Fax : 2357183</p>
            <p *ngIf="activeLang === 'si'" class="greytxt m-0">යෝජනාව පිළිගැනීම සංස්ථාව දැනුම් දෙන තුරු සංස්ථාවේ වගකීම
              ආරම්භ නොවේ.
              21, වෝක්ෂෝල් වීදිය, කොළඹ 2. දුරකථන: 2357151, 2357157 ෆැක්ස්: 2357183
            </p>
            <p *ngIf="activeLang === 'ta'" class="greytxt m-0">முன்மொழிவை ஏற்றுக்கொள்ளும் வரை மாநகராட்சியின் பொறுப்பு
              தொடங்கப்படாது.
              21, வாக்ஸ்ஹால் தெரு, கொழும்பு 2. தொலைபேசி: 2357151, 2357157 தொலைநகல்: 2357183
            </p>
          </div>
        </div>
        <div class="form-group btn-sections b-0 p-0 text-right">


          <button aria-label="Next" class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="nextPage()"
                  type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<button hidden data-target="#successModal" data-toggle="modal" #openSuccessModal></button>
<button hidden data-target="#draftModal" data-toggle="modal" #openDraftModal></button>

<!-- Success Modal -->
<div class="modal fade npa-model" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_save'|translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Draft Modal -->
<div class="modal fade npa-model" id="draftModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-700 margin-0 vas-m" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="vas-modals">
          <div class="successmsg">
            <img src="../../../../assets/images/mdi_check.png" alt="">
            <h3>{{'SUBMITTED SUCCESSFULLY'|translate}}</h3>
            <p>{{'Thank you!'|translate}} <br/> {{'insurance_draft_save' | translate}}</p>
            <a aria-label="Next" class="btn next-btn-close"
               [routerLink]="'/value-added-services/manage-insurance/wholesale'"
               data-dismiss="modal">{{'SIGN_IN.CLOSE' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="page-contents" *ngIf="section === 1">
  <div class="packge-form">
    <div class="scheme-doc">
      <h4>Goods in Transit Insurance for Farmers</h4>
      <div class="scheme-details">
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td class="thead">1) Nature of goods transported</td>
            <td>Vegetables & Foods (with suitable packing arrangements)</td>
          </tr>
          <tr>
            <td class="thead">2) Sum Insured
              (Value of goods)
            </td>
            <td>Option I : 1Mn per vehicle <br/>
              Option II : 2Mn per vehicle <br/>
              Option III : 5Mn per vehicle
            </td>
          </tr>
          <tr>
            <td class="thead">3) Risk Covered</td>
            <td>Loss of or destruction or damage to goods due to <br/>
              <li>Accident to carrying conveyance (vehicle)</li>
              <li>Loading & unloading from vehicle</li>
              <li>Theft of or burglary of goods</li>
              <li> Fire, Explosion, Natural Disaster, Flood</li>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-tables">
          <label>4)</label>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Annual Premium</th>
              <th scope="col">Option I (Rs.) (per vehicle)</th>
              <th scope="col">Option II (Rs.) (per vehicle)</th>
              <th scope="col">Option III (Rs.) (per vehicle)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>Net premium</th>
              <td>3,000.00</td>
              <td>5,000.00</td>
              <td>8,000.00</td>
            </tr>
            <tr>
              <th>Total with taxes & other payment</th>
              <td>3,791.34</td>
              <td>5,958.90</td>
              <td>9,210.24</td>
            </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td class="thead">5) Excess</td>
            <td>Rs.5,000/- of each and every claim</td>
          </tr>
          <tr>
            <td class="thead">2) Sum Insured
              (Value of goods)
            </td>
            <td>Option I : 1Mn per vehicle <br/>
              Option II : 2Mn per vehicle <br/>
              Option III : 5Mn per vehicle
            </td>
          </tr>
          <tr>
            <td class="thead">6) Loss or damage not payable</td>
            <td>
              <ol>
                <li>Goods transported in freezer bodies & refrigerated cargo.</li>
                <li>Theft of goods by insured’s agent, representative, employees or driver/ cleaner
                  of the hired vehicle.
                </li>
                <li>Damage due to deterioration of (discoloring, not fit for consultant or used
                  etc…) due to weather conditions or else.
                </li>
                <li>Loss or damage due to use of vehicle not fit for the road use or unsuitable
                  packing or stacking of goods.
                </li>
                <li>Loss or damage due to delay howsoever forced & loss of price of the market</li>
              </ol>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group btn-sections b-0 p-0 text-right">
        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    float: left;
    margin-right: 10px;
    border-radius: 5px;" (click)="backPage()"
                type="submit"> {{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}
        </button>


        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    margin-right: 10px;
    border-radius: 5px;" (click)="submitForm('DRAFT')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.DRAFT' | translate}}</button>

        <button class="btn next-btn-half" style="color: #fff !important;
    padding: 10px 25px;
    min-width: 200px;
    border-radius: 5px;" (click)="submitForm('PENDING')"
                type="submit"> {{'MY_ACCOUNT.LOAN_REQUEST.NEW_LOAN_REQUEST.SUBMIT' | translate}}</button>
      </div>
    </div>
  </div>
</div>
