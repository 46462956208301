import {Component, OnInit} from '@angular/core';
import {Md5} from 'ts-md5';
import {Title} from '@angular/platform-browser';
import {CoreService} from '../../../../service/core.service';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from '../../../../service/main-url.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {Global} from '../../../../service/global';
import {UserRoleService} from '../../../../service/user-role.service';
import {InteractionService} from '../../../../service/interaction.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DeliveryService} from '../../../../service/delivery.service';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import {UserTokenDTO} from '../../../../dto/userTokenDTO';
import {ReportService} from '../../../../service/report.service';
import {map, startWith} from 'rxjs/operators';
import {ProductService} from '../../../../service/product.service';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {SalesDTO} from '../../../../dto/salesDTO';
import {MyAccountServiceService} from '../../my-account-service.service';

export interface City {
  id: string;
  cityName: string;
}

@Component({
  selector: 'app-farmer-overview',
  templateUrl: './farmer-overview.component.html',
  styleUrls: ['./farmer-overview.component.css']
})
export class FarmerOverviewComponent implements OnInit {

  accountForm: FormGroup;

  userName = '';
  imgUrl: string = '';
  Fname: any = '';
  Lname: any = '';
  Email: any = '';
  mobile: any = '';
  address: any = '';
  city: any = '';
  province: any = '';
  country: any = '';
  postal: any = '';
  displayPostalCode: any;
  displayProvince: any;
  displayCity: any;
  displayEmail: any;
  displayLname: any;
  displayFname: any;
  refNo: any;

  addressId: any;
  latitude: any;
  longitude: number;
  displayMobile: any;
  showDetails: boolean = false;
  showMobile: boolean = false;
  FORWARD_ORDER_ENABLE: boolean = false;
  displayCountry: any = 'Sri Lanka';
  displayAddress: any;
  tenantValue: any = 'wholesale';
  BASE_URL: string;
  loadingUser: boolean;

  allCities: City[] = [];
  myControl = new FormControl();
  filteredOptions: Observable<City[]>;
  userTokenDTO = new UserTokenDTO;


  saleAmount: any = 0;
  refundAmount: any = 0;
  deductionAmount: any = 0;
  referralAmount: any = 0;

  loggedUser: any;

  totalSales: any = 0;
  totalPurchases: any = 0;
  productCount: any = 0;
  purchasesOfMonth: any = 0;
  salesOfMonth: any = 0;
  ratingOfProfile: any = 0;
  reviewsData: any;
  selectNewProductOptions: number;
  productList: any;
  salesDTOs: SalesDTO[] = [];
  i: number = 0;
  earnings: any = 0;
  isLead: boolean = false;


  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */
  constructor(private http: HttpClient,
              private titleService: Title,
              private core_service: CoreService,
              public mainUrl: MainUrlService,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              public global: Global,
              private userRoleService: UserRoleService,
              private settingsService: SystemSettingsService,
              private router: Router,
              private reportService: ReportService,
              private deliveryService: DeliveryService,
              private sellProduct: MyAccountServiceService,
              private interactionService: InteractionService,
              private formBuilder: FormBuilder,
              private validationHandlerService: ValidationHandlerService,
              private coreService: CoreService,
              private productService: ProductService) {
    this.validateAccountForm();
    this.loadOverviewDetails();
  }

  ngOnInit() {
    this.loadingUser = true;

    this.route.queryParams.subscribe(parms => {
      if (parms['isRouteSellAProduct']) {
        this.interactionService.updateSellAProduct(true);
      }

      if (parms['isClickedSellButton'] === 'true') {
        this.interactionService.openSellAProductModal(true);
      }

    });

    this.getAllCities();
  }

  getLoadDataForDashboard() {
    this.loggedUser = this.whoAmIDetails['email'];
    this.fetchTotalSales(this.whoAmIDetails['email']);
    this.fetchTotalPurchases(this.whoAmIDetails['email']);
    this.fetchProductCount(this.whoAmIDetails['email']);
    this.fetchPurchasesOfMonth(this.whoAmIDetails['email']);
    this.fetchSalesOfMonth(this.whoAmIDetails['email']);
    this.fetchRating(this.whoAmIDetails['email']);
  }


  fetchTotalSales(loggedUser: any) {
    this.reportService.fetchTotalSalesForSeller(this.tenantValue, loggedUser).subscribe(result => {
      if (result) {
        this.totalSales = result;
      }
    });
  }

  fetchTotalPurchases(loggedUser: any) {
    this.reportService.fetchTotalPurchasesForBuyers(this.tenantValue, loggedUser).subscribe(result => {
      if (result) {
        this.totalPurchases = result;
      }
    });
  }

  fetchProductCount(loggedUser: any) {
    this.productService.fetchProductCount(loggedUser).subscribe(result => {
      if (result) {
        this.productCount = result;
      }
    });
  }

  fetchPurchasesOfMonth(loggedUser: any) {
    this.reportService.fetchPurchasesOfMonth(this.tenantValue, loggedUser).subscribe(result => {
      if (result) {
        this.purchasesOfMonth = result;
      }
    });
  }

  fetchSalesOfMonth(loggedUser: any) {
    this.reportService.fetchSalesOfMonth(this.tenantValue, loggedUser).subscribe(result => {
      if (result) {
        this.salesOfMonth = result;
      }
    });
  }

  fetchRating(loggedUser: any) {
    this.productService.fetchRating(loggedUser).subscribe(result => {
      if (result) {
        this.ratingOfProfile = result;
      }
    });
  }

  validateAccountForm() {
    this.accountForm = this.formBuilder.group({
      Fname: ['', [Validators.required, Validators.pattern(this.validationHandlerService.nameValidation())]],
      Lname: ['', [Validators.required, Validators.pattern(this.validationHandlerService.nameValidation())]],
      address: ['', [Validators.required]],
      mobile: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      country: ['', Validators.required],
      postal: ['', Validators.required]
    });
  }

  get f() {
    return this.accountForm.controls;
  }

  isLeadAccount: boolean = false;
  whoAmIDetails: any;

  loadOverviewDetails() {
    this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

      let increment1 = true;
      let increment2 = true;

      this.userName = whoAmIDetails['email'];
      this.whoAmIDetails = whoAmIDetails;

      for (let role of whoAmIDetails['roles']) {
        if (role['name'] === 'LEAD' && increment1) {
          increment1 = false;
          this.isLeadAccount = true;
          this.reportService.fetchLeadEarnings(whoAmIDetails['email'], role['name']).subscribe(earning => {
            this.earnings = earning.totalEarnings;
            this.referralAmount = earning.pendingSettlement;
          });

          this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {
            this.reportService.fetchOverviewDetailsByLeadCode(refNo.message, whoAmIDetails['email']).subscribe(result => {
              this.saleAmount = result['agentTotalSales'];
              this.productCount = result['productCount'];
              this.totalPurchases = result['agentTotalPurchase'];
            });
          });
        }

        if (role['name'] !== 'LEAD' && increment2) {
          increment2 = false;
          this.isLeadAccount = false;
          this.getLoadDataForDashboard();
          this.reportService.fetchLeadEarnings(whoAmIDetails['email'], role['name']).subscribe(earning => {
            this.earnings = earning.totalEarnings;
            this.referralAmount = earning.pendingSettlement;
          });
        }

      }

      if (increment2 && increment1) {
        let body = {
          'name': null,
          'categoryCode': null,
          'subCategoryCodes': [],
          'minPrice': null,
          'locations': [],
          'maxPrice': null,
          'sortByPrice': null,
          'ratings': null,
          'userName': whoAmIDetails['email']
        };
        this.productService.fetchAllProductByUser(body).subscribe(resultByUsers => {
          Swal.close();

          let arr = [];

          for (let item of resultByUsers) {

            if (arr.length < 3) {
              let sku = '-';
              let image;
              if (item.type.type === 'SPOT') {
                sku = item.spotDTOList[0].sku;
                if (item.spotImages.length > 0) {
                  image = item.spotImages[0].image;
                } else {
                  image = '00000';
                }

              } else if (item.type.type === 'FORWARD_BN') {
                if (item.futureImages.length > 0) {
                  image = item.futureImages[0].image;
                } else {
                  image = '00000';
                }
              } else if (item.type.type === 'FORWARD_ACU') {
                if (item.forwardAcuImages.length > 0) {
                  image = item.forwardAcuImages[0].image;
                } else {
                  image = '00000';
                }
              }

              let _stock;
              let _expireDate;
              if (item.type.type === 'SPOT') {
                _stock = item.spotDTOList[0].stock;
                _expireDate = item.spotDTOList[0].expireDate;
              }

              arr.push(item);
            }

          }

          this.productList = arr;
        });
      } else {
        this.userRoleService.fetchRefNo(whoAmIDetails['email']).subscribe(refNo => {
          this.productService.leadFarmersProductList(refNo?.message).subscribe(result => {

            Swal.close();

            let arr = [];

            for (let item of result) {

              if (arr.length < 3) {
                let sku = '-';
                let image;
                if (item.type.type === 'SPOT') {
                  sku = item.spotDTOList[0].sku;
                  if (item.spotImages.length > 0) {
                    image = item.spotImages[0].image;
                  } else {
                    image = '00000';
                  }

                } else if (item.type.type === 'FORWARD_BN') {
                  if (item.futureImages.length > 0) {
                    image = item.futureImages[0].image;
                  } else {
                    image = '00000';
                  }
                } else if (item.type.type === 'FORWARD_ACU') {
                  if (item.forwardAcuImages.length > 0) {
                    image = item.forwardAcuImages[0].image;
                  } else {
                    image = '00000';
                  }
                }

                let _stock;
                let _expireDate;
                if (item.type.type === 'SPOT') {
                  _stock = item.spotDTOList[0].stock;
                  _expireDate = item.spotDTOList[0].expireDate;
                }

                arr.push(item);
              }

            }

            this.productList = arr;
          });

        });
      }

      this.reportService.fetchOverviewDetails(whoAmIDetails['email']).subscribe(overviewDetails => {
        this.deductionAmount = overviewDetails['deductionAmount'] ? overviewDetails['deductionAmount'] : 0;
        this.refundAmount = overviewDetails['refundAmount'] ? overviewDetails['refundAmount'] : 0;
        this.saleAmount = overviewDetails['saleAmount'] ? overviewDetails['saleAmount'] : 0;
      });

      this.fetchAllReviews();
      this.loadSales();
      this.getUserDetails();
      this.fetchAll();
      this.getUser();
    });
  }

  getAllCities() {
    this.deliveryService.fetchAllActiveCities().subscribe(activeCities => {
      if (activeCities) {
        for (let city of activeCities) {
          this.allCities.push({
            'id': city.id,
            'cityName': city.cityName
          });
        }
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filter(state) : this.allCities.slice())
          );
      }
    });
  }

  private _filter(value: string): City[] {
    const filterValue = value.toLowerCase();
    return this.allCities.filter(option => option.cityName.toLowerCase().includes(filterValue));
  }

  getUser() {
    let isLoggedIn = localStorage.getItem('isLogged');
    this.userTokenDTO.token = 'Bearer ' + localStorage.getItem('$Token');

    if (isLoggedIn === 'True') {
      this.userTokenDTO.userName = this.userName;
      this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
        if (result) {
          this.showDetails = true;
          this.displayAddress = result.address;
          this.mobile = result.mobileNumber;
          this.displayProvince = result.province;
          this.loadingUser = false;
        }
      });
    } else {
      this.popUpLoginFormModal();
    }
  }

  getUserDetails() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.core_service.fetchUserDetails(this.userName).subscribe(result => {
        if (result) {
          this.BASE_URL = this.global.BASE_URL;
          this.showMobile = true;
          if (result['id'] !== null) {
            let hashStr;
            if (this.userName !== null) {
              hashStr = Md5.hashStr(this.userName);
            } else {
              hashStr = Md5.hashStr('');
            }
            this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '';

            this.displayMobile = result.phone;
            this.mobile = result.phone;
            this.displayCountry = result.addressCollection[0].country;
            this.country = result.addressCollection[0].country;
            this.latitude = result.addressCollection[0].latitude;
            this.longitude = result.addressCollection[0].longitude;
            this.displayPostalCode = result.addressCollection[0].postalCode;
            this.postal = result.addressCollection[0].postalCode;
            this.displayCity = result.addressCollection[0].city;
            this.city = result.addressCollection[0].city;
            this.address = result.addressCollection[0].addressTemp;
            this.displayAddress = result.addressCollection[0].addressTemp;
            this.address = result.addressCollection[0].addressTemp;
            this.displayEmail = result.email;
            this.Email = result.email;
            this.displayFname = result.firstName;
            this.Fname = result.firstName;
            this.displayLname = result.lastName;
            this.Lname = result.lastName;
            this.displayProvince = result.addressCollection[0].province;
            this.province = result.addressCollection[0].province;
            this.addressId = result.addressCollection[0].id;
            this.showDetails = true;
          } else {

            this.showDetails = false;
            this.Email = this.whoAmIDetails['email'];
            this.Fname = this.whoAmIDetails['name'].split(' ').slice(0, -1).join(' ');
            this.Lname = this.whoAmIDetails['name'].split(' ').slice(-1).join(' ');
            this.displayMobile = this.whoAmIDetails['mobileNumber'];
            this.mobile = this.whoAmIDetails['mobileNumber'];
            let hashStr;
            if (this.whoAmIDetails['email'] !== null) {
              hashStr = Md5.hashStr(this.whoAmIDetails['email']);
            } else {
              hashStr = Md5.hashStr('');
            }
            this.imgUrl = 'https://www.gravatar.com/avatar/' + hashStr + '';
          }


        }
      });
    } else {
      this.popUpLoginFormModal();
    }
  }

  loading() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      width: '300px',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  logout() {
    Swal.fire({
      title: 'Sign Out',
      text: 'You will be returned to the login screen',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log out'
    }).then(() => {
      this.popUpLoginFormModal();
    });
  }

  popUpLoginFormModal() {
    localStorage.setItem('isLogged', 'False');
    this.router.navigate(['/home/' + this.tenantValue]);
    this.interactionService.popUpLoginModal('false');
  }

  fetchAll() {
    this.settingsService.fetchAll().subscribe(fetchAll => {
      let tempArray = [];

      tempArray.push(fetchAll);

      for (let settings of tempArray) {
        for (let setting of settings) {

          if (setting.code === 'FORWARD_ORDER_ENABLE') {
            this.FORWARD_ORDER_ENABLE = setting.value === 'true';
          }
        }
      }
    });
  }

  fetchAllReviews() {
    this.productService.fetchAllRatingByUsername(this.userName).subscribe(
      result => {
        Swal.close();
        let arr = [];
        for (let rev of result) {
          if (arr.length < 2 && rev.status === 'APPROVED') {
            arr.push(rev);
          }
        }
        this.reviewsData = arr;
      }
    );
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('-');
  }

  loadReviewModal(review: any) {
    this.interactionService.setUpdateReviews(review);
  }

  hashStrGet(reviewBy) {
    let hashStr;
    if (reviewBy !== null) {
      hashStr = Md5.hashStr(reviewBy);
    } else {
      hashStr = Md5.hashStr('');
    }

    return Md5.hashStr(hashStr);
  }

  loadSales() {
    this.productService.fetchSalesByUsername(this.userName).subscribe(result => {
      Swal.close();

      for (let item of result) {
        if (item.status === 'COMPLETED' && item.paymentStatus === 'COMPLETE' && item.productType !== 'ORDER_REQUEST') {
          this.i++;
          let sales = new SalesDTO(
            item.product.sku,
            item.product.product.name,
            item.product.product.subCategory.mainCategory.commonIndex.description,
            item.totalPrice,
            item.product.product.address,
            item.created,
            item.id,
            item.status,
            item.transactionId,
            item.product.productImages[0].image,
            item.userName,
            item.quantity,
            item.unitPrice,
            item.buyerName,
            item.seller,
            item.farmerType,
            '', ''
          );
          this.salesDTOs.push(sales);
          if (this.i == 4) {
            break;
          }
        }
      }
    });
  }

}
