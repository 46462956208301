import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FarmerType} from '../../../../dto/farmerType';
import {ValidationHandlerService} from '../../../../validation-handler/validation-handler.service';
import {IdentityService} from '../../../../service/identity.service';
import {ToastrService} from 'ngx-toastr';
import {InteractionService} from '../../../../service/interaction.service';
import {CommonSignUpService} from '../../../../service/common-sign-up.service';
import {MatCheckboxChange} from '@angular/material';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {SmsService} from '../../../../service/sms.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-farming-company',
  templateUrl: './farming-company.component.html',
  styleUrls: ['./farming-company.component.css']
})
export class FarmingCompanyComponent implements OnInit {

  signUpFarmer: FormGroup;
  submitted = false;

  landBeLongs: boolean = false;
  isRegisteredVat: boolean = false;
  farmerTypeEnable: boolean = false;


  farmerName: any;
  companyRegistrationNumber: any;
  companyVATRegistrationNumber: any;
  postalAddress: any;
  companyName: any;
  farmerNic: any;
  farmerMobile: any;
  farmerEmail: any;
  farmerUsername: any;
  password: any;
  rePassword: any;
  referralNumber: any;

  allProvinceList: any;
  allDistrictList: any;
  allDivisionList: any;
  allGramaList: any;
  allAgriList: any;
  allInstructList: any;

  selectedDivisionalId: any;
  selectedProvinceId: any;
  selectedBusinessModelId: any;
  selectedDistrictId: any;
  selectedLandUnitId: any;
  selectedGramaId: any;
  selectedAgriId: any;
  selectedInstructId: any;
  selectedCultivationArea: any;
  farmerLandArea: any;
  farmerTypeList: FarmerType[] = [];
  waterResourceList: any = [];
  cultivationList: any = [];


  farmerTypes = [
    {
      'id': 1, 'description': 'REGISTER.FARMER.MAHAWELI'
    },
    {
      'id': 2, 'description': 'REGISTER.FARMER.GAP'
    },
    {
      'id': 3, 'description': 'REGISTER.FARMER.ORGANIC'
    }
  ];

  landUnitTypes = [
    {
      'id': 'ACRES', 'description': 'SIGN_UP.LAND_UNIT.ACRES'
    },
    {
      'id': 'PERCH', 'description': 'SIGN_UP.LAND_UNIT.PERCH'
    }
  ];

  waterResources = [
    {
      'id': 'IRRIGATION WATER', 'description': 'REGISTER.FARMER.WATER_RESOURCE.IRRIGATION_WATER'
    },
    {
      'id': 'RAIN WATER', 'description': 'REGISTER.FARMER.WATER_RESOURCE.RAIN_WATER'
    },
    {
      'id': 'TUBE WELL', 'description': 'REGISTER.FARMER.WATER_RESOURCE.TUBE_WELL'
    },
    {
      'id': 'AGGRO WELL', 'description': 'REGISTER.FARMER.WATER_RESOURCE.AGGRO_WELL'
    }
  ];

  cultivations = [
    {
      'id': 'FOOD CROPS', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.FOOD_CROPS'
    },
    {
      'id': 'CASH CROPS', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.CASH_CROP'
    },
    {
      'id': 'HORTICULTURE CROPS', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.HORTICULTURE_CROPS'
    },
    {
      'id': 'DAIRY POULTRY', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.DAILY_POULTRY'
    }
  ];

  businessModel = [
    {
      'id': 'Join Venture', 'description': 'COMPANY.Join_Venture'
    },
    {
      'id': 'Partnership', 'description': 'COMPANY.Partnership'
    },
    {
      'id': 'Private Limited Company', 'description': 'COMPANY.Private_Limited_Company'
    },
    {
      'id': 'Public Limited Company', 'description': 'COMPANY.Public_Limited_Company'
    },
    {
      'id': 'Sole Proprietorship', 'description': 'COMPANY.Sole_Proprietorship'
    }
  ];

  cultivationAreaModel = [
    {
      'id': 'Mahaweli', 'description': 'COMPANY.Mahaweli'
    },
    {
      'id': 'Interprovincial', 'description': 'COMPANY.Interprovincial'
    },
    {
      'id': 'Provincial Councils', 'description': 'COMPANY.Provincial_Councils'
    }
  ];

  waterResourceEnabled: boolean = false;
  cultivationEnabled: boolean = false;
  activeLang: string = 'en';
  agreeWithUs: boolean = false;
  tenantValue: any;

  incorrectNIC: boolean = false;
  incorrectMobile: boolean = false;
  incorrectEmail: boolean = false;
  incorrectUsername: boolean = false;
  incorrectUsernamePattern: boolean = false;
  invalidUnit: boolean = false;
  invalidArea: boolean = false;

  allCities: any;
  selectedCity: any;
  address1: any;
  address2: any;
  postalCode: any;
  cityObject: any;
  cityName: any;

  language: any = 'EN';

  @ViewChild('optModal', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;


  constructor(private _formBuilder: FormBuilder,
              private validationHandlerService: ValidationHandlerService,
              private identityService: IdentityService,
              private router: Router,
              private toastr: ToastrService,
              private _translateService: TranslateService,
              private smsService: SmsService,
              private interactionService: InteractionService,
              private commonSignUpService: CommonSignUpService) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

  }

  ngOnInit() {
    this.reactiveForm();
    this.getAllProvince();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
        this.getAllProvince();
      }
    });
  }

  reactiveForm() {
    this.signUpFarmer = this._formBuilder.group({
      farmerMobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyTenNumberValidation())]],
      farmerName: ['', [Validators.required, Validators.pattern(this.validationHandlerService.nameValidation())]],
      companyName: ['', [Validators.required, Validators.pattern(this.validationHandlerService.companyNameValidation())]],
      farmerNic: ['', [Validators.required, Validators.pattern(this.validationHandlerService.sriLankaNICValidation())]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      companyRegistrationNumber: ['', [Validators.required, Validators.pattern(this.validationHandlerService.companyRegistrationNoValidation())]],
      farmerLandArea: [''],
      province: ['', Validators.required],
      businessModel: ['', Validators.required],
      district: ['', Validators.required],
      division: ['', Validators.required],
      gramaList: ['', Validators.required],
      agriList: [''],
      instructList: [''],
      cultivationArea: ['', Validators.required],
      landUnitTypeList: [''],
      referralNumber: [''],
      language: [''],
      farmerUsername: ['', [Validators.required, Validators.pattern(this.validationHandlerService.userNameVerification())]],
      farmerEmail: ['', [Validators.pattern(this.validationHandlerService.emailValidation())]],
    });
  }

  get f() {
    return this.signUpFarmer.controls;
  }

  selectLandUnit(event) {
    this.selectedLandUnitId = event;
    this.invalidUnit = false;
  }

  selectCultivationArea(event: any) {
    this.selectedCultivationArea = event;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.allProvinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.allDistrictList = result;
      }
    });

  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;
      }
    });
  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.allDivisionList = result;
      }
    });
  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.allGramaList = result;
      }
    });
  }


  getAllAgriculturalServiceArea(id) {
    this.commonSignUpService.fetchAllAgri(id).subscribe((result) => {
      if (result) {
        this.allAgriList = result;
      }
    });
  }


  getAllInstructDivision(id) {
    this.commonSignUpService.fetchAllInstruct(id).subscribe((result) => {
      if (result) {
        this.allInstructList = result;
      }
    });
  }

  selectProvince(event: any) {
    this.selectedProvinceId = event;
    this.getAllDistrict(event);
    this.allDivisionList = [];
    this.allGramaList = [];
    this.allAgriList = [];
    this.allInstructList = [];
  }

  selectBusinessModel(event: any) {
    this.selectedBusinessModelId = event;
  }


  selectDivisional(event: any) {
    this.selectedDivisionalId = event;
    this.getAllGramaNiladari(event);
    this.getAllAgriculturalServiceArea(event);
  }

  selectDistrict(event: any) {
    this.selectedDistrictId = event;
    this.getAllCities(event);
    this.getAllDivSec(event);
    this.allGramaList = [];
    this.allAgriList = [];
    this.allInstructList = [];
  }

  selectGrama(event: any) {
    this.selectedGramaId = event;
  }

  selectAgri(event: any) {
    this.selectedAgriId = event;
    this.getAllInstructDivision(event);
  }

  selectIntruct(event: any) {
    this.selectedInstructId = event;
  }

  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.postalCode = result['postalCode'];
      }
    });
  }

  selectCity(event: any) {
    this.selectedCity = event;
    this.getCityById(event);
  }

  selectCityEn(cityName: any) {
    this.cityName = cityName;
  }

  submitFarmer() {
    this.submitted = true;
    if (this.signUpFarmer.invalid) {
      return;
    }
    if ((this.farmerLandArea !== null && this.farmerLandArea !== undefined) &&
      (this.selectedLandUnitId == null)) {
      this.invalidUnit = true;
      return;
    } else if ((this.farmerLandArea === null || this.farmerLandArea === undefined) &&
      (this.selectedLandUnitId !== null && this.selectedLandUnitId !== undefined)) {
      this.invalidArea = true;
      return;
    } else {
      this.invalidUnit = false;
      this.invalidArea = false;
    }
    this.loading();
    this.isOTPModalClose = false;
    let _requestOTP = {
      'telNo': [
        this.farmerMobile
      ],
      'username': this.farmerUsername,
      'systemUsername': this.farmerName,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
      Swal.close();
      if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
        this.OTPValidation(60 * 3, document.getElementById('time'));
      }
      this.optModal.nativeElement.click();
    }, () => {
      Swal.close();
    });
  }

  clearErr() {
    this.invalidArea = false;
  }

  saveFarmer() {
    this.loading();

    if (this.address2 === '' || this.address2 === undefined || this.address2 === null) {
      this.address2 = '';
      this.postalAddress = this.address1 + ',' + this.cityName;
    } else {
      this.postalAddress = this.address1 + ',' + this.address2 + ',' + this.cityName;
    }


    let _tempLandUnit;
    if ( this.selectedLandUnitId == null) {
      _tempLandUnit = null;
    } else {
      this._translateService.get(this.selectedLandUnitId).subscribe(lang => {
        _tempLandUnit = lang;
      });
    }

    let _tempWaterResourceList = [];
    if (this.waterResourceList.length > 0) {
      for (let waterResource of this.waterResourceList) {
        this._translateService.get(waterResource).subscribe(lang => {
          _tempWaterResourceList.push(lang);

        });
      }
    }

    let _tempBusinessModel;
    this._translateService.get(this.selectedBusinessModelId).subscribe(lang => {
      _tempBusinessModel = lang;
    });

    let _tempCultivationList = [];
    if (this.cultivationList.length > 0) {
      for (let cultivation of this.cultivationList) {
        this._translateService.get(cultivation).subscribe(lang => {
          _tempCultivationList.push(lang);
        });
      }
    }

    if (this.farmerEmail === '' || this.farmerEmail === null || this.farmerEmail === undefined) {
      this.farmerEmail = null;
    }

    let farmerBody = {
      'companyRegistrationNumber': this.companyRegistrationNumber,
      'companyName': this.companyName,
      'businessModel': _tempBusinessModel,
      'isRegisteredVat': this.isRegisteredVat,
      'companyVATRegistrationNumber': this.companyVATRegistrationNumber,
      'nic': this.farmerNic,
      'landArea': this.farmerLandArea,
      'contactNo': this.farmerMobile,
      'landUnit': _tempLandUnit,
      'isMine': this.landBeLongs,
      'cultivation': _tempCultivationList + '',
      'waterResource': _tempWaterResourceList + '',
      'name': this.farmerName,
      'postalAddress': this.postalAddress,
      'pin': "",
      'email': this.farmerEmail,
      'username': this.farmerUsername,
      'agentRef': this.referralNumber,
      'farmerType': this.farmerTypeList,
      'gramaNiladari': {
        'id': this.selectedGramaId
      },
      'instructionalDivision': {
        'id': this.selectedInstructId
      },
      'active': false,
      'adOne': this.address1,
      'adTwo': this.address2,
      'addressType': 1,
      'cityId': this.selectedCity,
      'countryId': 1,
      'postalCode': this.postalCode,
      'cultivationArea': this.selectedCultivationArea
    };

    let bodyLan = {
      'username': this.farmerUsername,
      'language': this.language
    };

    this.smsService.saveLanguage(bodyLan).subscribe(() => {

      this.commonSignUpService.saveCompanyFarmer(farmerBody).subscribe(result => {
        if (result) {
          // to the update sms sending language

          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.manageLocalizationSuccessTittle(sessionStorage.getItem('LANG')),
            text: this.validationHandlerService.manageLocalizationSuccessBodyMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          }).then(() => {
            this.signUpFarmer.reset();
            this.router.navigate(['/home/' + localStorage.getItem('TENANT_VALUE')]);

          });
          const title = Swal.getContent();
          title.style.textAlign = 'center';
        } else {
          Swal.fire({
            type: 'error',
            title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          });
        }
      }, (error) => {
        Swal.fire({
          type: 'error',
          title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        });
        console.log('Farmer save function :' + error);
      });
    });
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }


  selectFarmerType($event, value) {
    if ($event.checked) {
      let type = new FarmerType();
      type.id = value;
      this.farmerTypeList.push(type);
    } else {
      if (this.farmerTypeList.length > 0) {
        for (let farmerType of this.farmerTypeList) {
          if (farmerType.id === value) {
            let index = this.farmerTypeList.indexOf(farmerType);
            if (index !== -1) {
              this.farmerTypeList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  selectWaterResource($event, value) {
    if ($event.checked) {
      this.waterResourceList.push(value);
    } else {
      if (this.waterResourceList.length > 0) {
        for (let waterResource of this.waterResourceList) {
          if (waterResource === value) {
            let index = this.waterResourceList.indexOf(waterResource);
            if (index !== -1) {
              this.waterResourceList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  selectCultivation($event, value) {
    if ($event.checked) {
      this.cultivationList.push(value);
    } else {
      if (this.cultivationList.length > 0) {
        for (let cultivation of this.cultivationList) {
          if (cultivation === value) {
            let index = this.cultivationList.indexOf(cultivation);
            if (index !== -1) {
              this.cultivationList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  changeLandBeLongs($event) {
    this.landBeLongs = !!$event.checked;
  }

  changeRegisteredVat($event) {
    this.isRegisteredVat = !!$event.checked;
    if ($event.checked) {
      this.signUpFarmer.addControl('companyVATRegistrationNumber', new FormControl('', [Validators.required, Validators.pattern(this.validationHandlerService.companyVATNoValidation())]));
    } else {
      this.companyVATRegistrationNumber = undefined;
      this.signUpFarmer.removeControl('companyVATRegistrationNumber');
    }
    this.signUpFarmer.updateValueAndValidity();
  }

  agreeWithAgreement($event: MatCheckboxChange) {
    this.agreeWithUs = $event.checked;
  }

  checkExists(value, type) {
    switch (type) {

      case 'MOBILE':
        if (value !== undefined) {
          this.identityService.checkContactNoExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'CONTACT_NO_NOT_EXISTS') {
              this.incorrectMobile = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'CONTACT_NO_EXISTS') {
              this.incorrectMobile = true;
              this.signUpFarmer.controls['farmerMobile'].setErrors(value);
            }
          });
        }

        break;

      case 'NIC':
        if (value !== undefined) {
          this.identityService.checkNICExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'NIC_NOT_EXISTS') {
              this.incorrectNIC = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'NIC_EXISTS') {
              this.incorrectNIC = true;
              this.signUpFarmer.controls['farmerNic'].setErrors(value);
            }
          });
        }


        break;

      case 'USERNAME':
        if (value !== undefined) {

          // Cannot be only numbers ( Username should include letters and cannot be only numbers)
          //
          // Letters must be included ( Username should include letters)
          //
          // No Spaces and it has to be one word. (Username cannot have any spaces)

          let boolean = this.validationHandlerService.usernameValidationPattern(value);

          if (boolean) {
            this.incorrectUsernamePattern = true;
            this.signUpFarmer.controls['username'].setErrors(value);
          } else {
            this.incorrectUsernamePattern = false;
            this.signUpFarmer.updateValueAndValidity();
          }

          this.identityService.checkUsernameExists(value).subscribe(isExists => {
            if (isExists['message'] === 'USERNAME_NOT_EXISTS') {
              this.incorrectUsername = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'USERNAME_EXISTS') {
              this.incorrectUsername = true;
              this.signUpFarmer.controls['farmerUsername'].setErrors(value);
            }
          });
        }


        break;

      case 'EMAIL':
        if (value !== undefined) {
          this.identityService.checkEmailExists(value).subscribe(isExists => {
            if (isExists['message'] === 'EMAIL_NOT_EXISTS') {
              this.incorrectEmail = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'EMAIL_EXISTS') {
              this.incorrectEmail = true;
              this.signUpFarmer.controls['farmerEmail'].setErrors(value);
            }
          });
        }
        break;

    }

  }


  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;

  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    let x = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (this.isOTPModalClose) {
        clearInterval(x);
        document.getElementById('time').textContent = '03:00';
        return;
      }

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(x);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    this.isInvalid = false;
    this.ngOtpInputRef.setValue('');
    clearInterval();
  }

  resendOTPCode() {
    this.ngOtpInputRef.setValue('');
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time').textContent = '03:00';
    this.OTPValidation(60 * 3, document.getElementById('time'));
    this.isShowResendButton = false;

    let _requestOTP = {
      'telNo': [
        this.farmerMobile
      ],
      'username': this.farmerUsername,
      'systemUsername': this.farmerName,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {});
  }

  verifyOTP() {
    this.isLoading = true;
    let verifyOTPBody = {
      'username': this.farmerUsername,
      'otp': this.OTP_CODE
    };
    this.smsService.verifyOTPCode(verifyOTPBody).subscribe(isVerify => {
      if (isVerify) {
        this.isInvalid = false;
        this.isLoading = false;
        this.closeModal.nativeElement.click();
        this.saveFarmer();
      } else {
        this.saveFarmer();
        this.isInvalid = true;
        this.isLoading = false;
      }
    }, () => {
      this.isInvalid = true;
      this.isLoading = false;
    });
  }

  saveLanguage(event) {
    this.language = event;
  }

}
