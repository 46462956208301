<div class="sellproduct-steps" *ngIf="selectSpotNext==0">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PRO_CATEGORY' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="categoryForm" (ngSubmit)="submitCategoryForm()">
      <div class="form-group col-12">
        <label for="selectCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.MAIN_CATEGORY' | translate}}
          <span class="validationStar">*</span></label>
        <select disabled [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.category.errors }"
                class="form-control" id="selectCategory" (change)="selectCategory($event)"
                [(ngModel)]="selectCategoryId" formControlName="category">
          <option disabled [value]="none">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_YOUR_CATEGORY' | translate}}
          </option>
          <option *ngFor="let category of categoryList"
                  [value]="category.code">{{category.commonIndex.description}}
          </option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.category.errors" class="inputError">
          <div *ngIf="f1.category.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="selectSubCategory">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SUB_CATEGORY' | translate}}
          <span class="validationStar">*</span> </label>
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <select disabled [ngClass]="{ 'is-invalid': submittedCategoryForm && f1.subCategory.errors }"
                class="form-control" id="selectSubCategory" (change)="selectSubCategory($event)"
                [(ngModel)]="selectSubCategoryId" formControlName="subCategory">
          <option disabled [value]="none">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_SUB_CATEGORY' | translate}}
          </option>
          <option *ngFor="let subCategory of subCategoryList"
                  [value]="subCategory.code">{{subCategory.commonIndex.description}}
          </option>
        </select>
        <div *ngIf="submittedCategoryForm && f1.subCategory.errors" class="inputError">
          <div *ngIf="f1.subCategory.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.SUB_CATEGORY_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" data-dismiss="modal"
           aria-label="Close" (click)="closeModal()">{{'MY_ACCOUNT.SELL_A_PRODUCT.CANCEL' | translate}}</a>
        <a class="btn next-btn-half" (click)="categoryPageNext()" aria-label="Next">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<div class="sellproduct-steps" *ngIf="selectSpotNext==1">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.LIST_PRODUCT_DETAILS' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="productDetailsForm" (ngSubmit)="productDetailsFormSubmit()">
      <div class="flex-row col-12">
        <div class="form-group col-4">
          <label for="title">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.TITLE' | translate}}
            <span class="validationStar">*</span></label>
          <input [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.title.errors }"
                 [(ngModel)]="title" formControlName="title" type="text" class="form-control"
                 id="title"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.TITLE_NAME' | translate}}">
          <div *ngIf="submittedProductDetailsForm && f2.title.errors" class="inputError">
            <div *ngIf="f2.title.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.TITLE_REQUIRED' | translate}}
            </div>
            <div *ngIf="f2.title.errors.pattern">
              Cannot start with space.
            </div>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_STATUS' | translate}}</label>

          <span *ngIf="productStatus === 'REJECT'" style="width: auto!important;">
              Rejected
            </span>
          <span *ngIf="productStatus === 'PENDING'" style="width: auto!important;">
              Pending
            </span>
          <span *ngIf="productStatus === 'ACTIVE'" style="width: auto!important;">
              Active
            </span>
        </div>

      </div>
      <div class="form-group col-12">
        <label for="description">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DESCRIPTION' | translate}}
          <span class="validationStar">*</span></label>
        <textarea [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.description.errors }"
                  [(ngModel)]="this.description" formControlName="description" class="form-control"
                  id="description"
                  rows="3" minlength="1" maxlength="550"></textarea>
        <span class="textArea">
          <label>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.CHARACTER_REMAINING' | translate}} {{description.length}}
            /550</label>
        </span>
        <div *ngIf="submittedProductDetailsForm && f2.description.errors" class="inputError">
          <div *ngIf="f2.description.errors.required">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DESCRIPTION_REQUIRED' | translate}}
          </div>
          <div *ngIf="f2.description.errors.pattern">
            Cannot start with space.
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-4">
          <label for="buyDate">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.BEST_BUY_DATE' | translate}}
            <span class="validationStar">*</span></label>
          <input min="{{todayDate}}" [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.buyDate.errors }"
                 [value]="buyDate" [(ngModel)]="buyDate" formControlName="buyDate" type="date"
                 class="form-control" id="buyDate">
          <div *ngIf="submittedProductDetailsForm && f2.buyDate.errors" class="inputError">
            <div *ngIf="f2.buyDate.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.BEST_BUY_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-4" style="margin-left: 44px !important;">
          <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.AVAILABLE_QUANTITY' | translate}}
            <span class="validationStar">*</span></label>
          <input style="width: 200px;" (focusout)="manageAvailableTotalQty(availableTotalQty)"
                 [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.availableTotalQty.errors }"
                 [(ngModel)]="availableTotalQty" formControlName="availableTotalQty" type="number"
                 class="form-control smallinput" id="qty" min="0" value="0">
          <div *ngIf="submittedProductDetailsForm && f2.availableTotalQty.errors" class="inputError">
            <div *ngIf="f2.availableTotalQty.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.AVAILABLE_TOTAL_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="qty">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.UNIT_TYPE' | translate}}
            <span class="validationStar">*</span></label>
          <select style="width: 191px;"
                  [ngClass]="{ 'is-invalid': submittedProductDetailsForm && f2.unitType.errors }"
                  (change)="selectUnitType($event)"
                  [(ngModel)]="unitType" formControlName="unitType" class="form-control smallselect" id="unitType">
            <option disabled [value]="none">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.UNIT_TYPE' | translate}}
            </option>
            <option *ngFor="let unitType of unitTypeList" [value]="unitType.id">{{unitType.unit}}</option>
          </select>
          <div *ngIf="submittedProductDetailsForm && f2.unitType.errors" class="inputError">
            <div *ngIf="f2.unitType.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.UNIT_TYPE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" (click)="selectSpotNext=0"
           aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a class="btn next-btn-half" type="submit" (click)="productDetailsPageNext()"
           aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<div class="sellproduct-steps" *ngIf="selectSpotNext==2">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.LIST_PRODUCT_DETAILS' | translate}}</h4>

  <div class="anp-select-op categoryform row">
    <form [formGroup]="listProductDetailsForm">
      <div class="form-group col-12 opacitylow active" [ngClass]="{'active':isSellSmallerQty==false}">
        <label for="bulkSellingPrice">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.BULK_SELL_PRICE' | translate}}
          <span class="validationStar">*</span></label>
        <input [(ngModel)]="bulkSellingPrice" formControlName="bulkSellingPrice" type="number" min="0"
               class="form-control smallinput" id="bulkSellingPrice" value="0.0"
               [attr.disabled]="isSellSmallerQty ? '' : null">
        <span>{{currencyCode}}</span>
        <div *ngIf="validBulkSellingPrice" class="inputError">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.BULK_SELLING_PRICE_REQUIRED' | translate}}
        </div>
      </div>

      <div class="form-check">
        <input class="form-check-input checkone" type="checkbox" id="sellInSmallerQty"
               (change)="manageSellInSmallerQty()" [checked]=isSellSmallerQty>
        <label class="form-check-label" for="sellInSmallerQty">
          {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELL_SMALLER_QUANTITY' | translate}}
        </label>
      </div>

      <div class="opacitylow" [ngClass]="{'active':isSellSmallerQty==true}">
        <div class="form-group col-12">
          <label for="quantity">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.QUANTITY' | translate}}
            <span class="validationStar">*</span></label>
          <input [(ngModel)]="sellSmallerQty" formControlName="sellSmallerQty" type="number" min="0"
                 class="form-control smallinput" id="quantity" value="0"
                 [attr.disabled]="!isSellSmallerQty ? '' : null">
          <span> {{setUnitType(this.unitType)}}</span>

          <div *ngIf="validSellSmallerQty" class="inputError">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.QUANTITY_REQUIRED' | translate}}
          </div>

        </div>

        <div class="form-group col-12">
          <label for="sellingUnitPrice">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELL_UNIT_PRICE' | translate}}
            <span class="validationStar">*</span></label>
          <input [(ngModel)]="sellSmallerQtySellingUnitPrice" formControlName="sellSmallerQtySellingUnitPrice"
                 type="number" min="0" class="form-control smallinput" id="sellingUnitPrice" value="0"
                 [attr.disabled]="!isSellSmallerQty ? '' : null">
          <span>{{currencyCode}}</span>
          <div *ngIf="validSellingUnitPrice" class="inputError">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.SELL_UNIT_PRICE_REQUIRED' | translate}}
          </div>
        </div>
      </div>

      <div class="row fspce-info" *ngIf="!isSellSmallerQty">
        <div class="col-6" *ngIf="isLeadCommissionVisible">
          <span>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.LEAD_COMMISSION' | translate}}</span>
        </div>
        <div class="col-6 text-right" *ngIf="isLeadCommissionVisible">
          <span>
            {{'CURRENCY.CURRENCY' | translate}} {{(leadCommission * bulkSellingPrice)| number:'2.2-2'}}
          </span>
        </div>
        <div class="col-6">
          <span>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PLATFORM_COMMISSION' | translate}}</span>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="isLeadCommissionVisible">
          {{'CURRENCY.CURRENCY' | translate}} {{(platformCommission * bulkSellingPrice)| number:'2.2-2'}}
          </span>
          <span *ngIf="!isLeadCommissionVisible">
          {{'CURRENCY.CURRENCY' | translate}} {{((2.5 / 100) * bulkSellingPrice)| number:'2.2-2'}}
          </span>
        </div>
        <div class="col-6 fspce-lg">
          <span *ngIf="!isLeaderFarmer">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.YOUR_EARNING' | translate}}</span>
          <span *ngIf="isLeaderFarmer">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FARMER_EARNING' | translate}}</span>
        </div>
        <div class="col-6 text-right fspce-lg">
          {{'CURRENCY.CURRENCY' | translate}}
          {{(this.bulkSellingPrice * this.farmerCommission)| number:'2.2-2'}}
        </div>
      </div>


      <div class="row fspce-info"
           *ngIf="isSellSmallerQty && sellSmallerQty !== null && sellSmallerQtySellingUnitPrice !== null"
      >
        <div class="col-6" *ngIf="isLeadCommissionVisible">
          <span>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.LEAD_COMMISSION' | translate}}</span>
        </div>
        <div class="col-6 text-right" *ngIf="isLeadCommissionVisible">
           <span>
            {{'CURRENCY.CURRENCY' | translate}} {{(leadCommission * (sellSmallerQtySellingUnitPrice * (availableTotalQty / sellSmallerQty)))| number:'2.2-2'}}
          </span>
        </div>
        <div class="col-6">
          <span>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PLATFORM_COMMISSION' | translate}}</span>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="isLeadCommissionVisible">
          {{'CURRENCY.CURRENCY' | translate}} {{(platformCommission * sellSmallerQtySellingUnitPrice * (availableTotalQty / sellSmallerQty))| number:'2.2-2'}}
          </span>
          <span *ngIf="!isLeadCommissionVisible">
          {{'CURRENCY.CURRENCY' | translate}} {{((2.5 / 100) * sellSmallerQtySellingUnitPrice * (availableTotalQty / sellSmallerQty))| number:'2.2-2'}}
          </span>
        </div>
        <div class="col-6 fspce-lg">
          <span *ngIf="!isLeaderFarmer">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.YOUR_EARNING' | translate}}</span>
          <span *ngIf="isLeaderFarmer">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FARMER_EARNING' | translate}}</span>
        </div>

        <div class="col-6  text-right fspce-lg">
          {{'CURRENCY.CURRENCY' | translate}}
          {{(this.sellSmallerQtySellingUnitPrice * this.farmerCommission * (availableTotalQty / sellSmallerQty))| number:'2.2-2'}}
        </div>
      </div>


      <div class="form-group btn-sections">
        <a class="btn cancel-btn-half" (click)="selectSpotNext=1"
           aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a class="btn next-btn-half" (click)="productListNextPage()" type="submit"
           aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>


</div>

<div *ngIf="selectSpotNext==3" class="sellproduct-steps"
     style="max-height: 500px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
  <h4 style="margin: 0 0 15px 0;">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_PIK_ADDRESS' | translate}}</h4>

  <div class="col-12">
    <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_PIK_ADDRESS_MSG' | translate}}</p>
  </div>
  <div class="anp-select-op categoryform row">
    <form [formGroup]="pickUpAddressForm">

      <div class="transport-section">
        <div class="saved-address">
          <div class="d-flex row" style="max-height: 650px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
            <div class="col-6" *ngFor="let item of addressList; let i = index">
              <div class="section-transport">
                <input class="form-check-input" (click)="setAddress(i)" type="radio"
                       [id]="'address'+i" [name]="'address'" [value]="item.id"
                       [checked]="item.status && !isAgentFarmerCheck">
                <label class="form-check-label" [for]="'address'+i">
                  <h4>{{item.name}} <span *ngIf="item.status === true">(Default)</span></h4>
                  <p>{{item.addressOne}} {{item.addressTwo}} ,{{item.cities.nameEN}}</p>
                </label>
              </div>
            </div>
            <div class="section-transport" *ngIf="isAgentFarmerLogin">
              <input class="form-check-input" (click)="setAddress('FM')" type="radio"
                     [id]="'FM'" [name]="'FM'" [value]="'FM'" [checked]="isAgentFarmerCheck">
              <label class="form-check-label" [for]="'FM'">
                <h4>{{agentFarmerName}} <span>(Farmer)</span></h4>
                <p>{{agentFarmerAddress1}} {{agentFarmerAddress2}},{{agentFarmerCityName}} </p>
              </label>
            </div>
          </div>
        </div>
        <div class="addnewaddress d-flex justify-content-between align-items-center">
          <div>
            <input class="form-check-input" type="radio" name="address" id="newaddesss" value="option3"
                   (click)="setAddress('new')">
            <label class="form-check-label" for="newaddesss">
              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADD_NEW_ADDRESS' | translate}}</h4>
            </label>
          </div>
          <div class="form-check" [hidden]="!isItNewAddress">
            <input [checked]="isAddressSave" class="form-check-input checkone" (change)="manageAddressSave()"
                   id="saveAddress" type="checkbox">
            <label class="form-check-label" for="saveAddress">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SAVE_NEW_ADDRESS' | translate}}
            </label>
          </div>
        </div>
      </div>

      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="address1">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS_1' | translate}}
            <span class="validationStar">*</span></label>
          <input [(ngModel)]="address1"
                 [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.address1.errors }" class="form-control"
                 formControlName="address1" id="address1"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}"
                 type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.address1.errors" class="inputError">
            <div *ngIf="f3.address1.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="address2">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS_2' | translate}}</label>
          <input [(ngModel)]="address2" [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.address2.errors }"
                 class="form-control" formControlName="address2" id="address2"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.ADDRESS' | translate}}" type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.address2.errors" class="inputError">
            <div *ngIf="f3.address2.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.ADDRESS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">
        <div class="form-group col-6">
          <label for="pro">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PROVINCE' | translate}}
            <span class="validationStar">*</span></label>
          <select (change)="selectProvince($event)" [(ngModel)]="selectProvinceId" class="form-control province"
                  formControlName="province" id="pro">
            <option [value]="none" disabled>
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_PROVINCE' | translate}}
            </option>
            <option *ngFor="let province of provinceList" [value]="province.id">
              <span *ngIf="activeLang === 'en'">{{province.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{province.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{province.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.province.errors" class="inputError">
            <div *ngIf="f3.province.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.PROVINCE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label for="dis">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DISTRICT' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
          <select (change)="selectDistrict($event)" [(ngModel)]="selectDistrictId" class="form-control province"
                  formControlName="district" id="dis">
            <option [value]="none" disabled>
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.SELECT_DISTRICT' | translate}}
            </option>
            <option *ngFor="let district of districtList" [value]="district.id">
              <span *ngIf="activeLang === 'en'">{{district.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{district.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{district.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.district.errors" class="inputError">
            <div *ngIf="f3.district.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.DISTRICT_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row col-12">

        <div class="form-group col-6">
          <label for="dis">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.CITY' | translate}}
            <span class="validationStar">*</span></label>
          <mat-spinner *ngIf="loadingDist" diameter="20"></mat-spinner>
          <select (change)="selectCity($event)" [(ngModel)]="selectCityId" class="form-control province"
                  formControlName="city" id="city">
            <option [value]="none" disabled>
              Select City
            </option>
            <option *ngFor="let city of allCities" [value]="city.id" (click)="selectCityEn(city.nameEN)">
              <span *ngIf="activeLang === 'en'">{{city.nameEN}}</span>
              <span *ngIf="activeLang === 'si'">{{city.nameSI}}</span>
              <span *ngIf="activeLang === 'ta'">{{city.nameTA}}</span>
            </option>
          </select>
          <div *ngIf="submittedPicUpAddressForm && f3.city.errors" class="inputError">
            <div *ngIf="f3.city.errors.required">
              City is Required
            </div>
          </div>
        </div>


        <div class="form-group col-6">
          <label for="postalCode">
            {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}
          </label>
          <input [(ngModel)]="postalCode" disabled
                 [ngClass]="{ 'is-invalid': submittedPicUpAddressForm && f3.postalCode.errors }"
                 class="form-control" formControlName="postalCode" id="postalCode"
                 placeholder="{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.POSTAL_CODE' | translate}}" type="text">
          <div *ngIf="submittedPicUpAddressForm && f3.postalCode.errors" class="inputError">
            <div *ngIf="f3.postalCode.errors.required">
              {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.VALIDATE.POSTAL_CODE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group btn-sections">
        <a (click)="selectSpotNext=2" aria-label="Close"
           class="btn cancel-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
        <a (click)="pickUpLocationAddressNextPage()" aria-label="Next"
           class="btn next-btn-half">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
      </div>

    </form>
  </div>
</div>

<div class="sellproduct-steps" *ngIf="selectSpotNext==4"
     style="max-height: 500px ; height: auto; overflow-y: scroll; overflow-x: hidden;">
  <h4 class="margin0">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_OPTION' | translate}}</h4>
  <p class="subtext">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.TRANSPORT_STATUS' | translate}}
  </p>
  <div class="anp-select-op categoryform row">
    <div class="messgnetext">
      <form [formGroup]="deliveryForm">
        <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==1}">
          <div class="form-check">
            <mat-checkbox (change)="checkVal($event,3)" id="opt3" [checked]="isSellerDeliver"></mat-checkbox>
            <label class="form-check-label" style="color: black;">
              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGE' | translate}}
              </h4>
              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' | translate}}</p>

              <div class="row">
                <label
                  style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_PER' | translate}}
                  ({{'CURRENCY.CURRENCY' | translate}})</label>
                <input class="form-control" style="margin-left: 6%" formControlName="deliverCharge" type="number">
                <div *ngIf="isChargeReq" style="font-size: 10px;color: red;margin-left: 4vw;margin-top: 1vh;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' | translate}}
                </div>
              </div>
            </label>
          </div>
        </div>
      </form>
      <form [formGroup]="deliveryFormFixed">
        <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==5}">
          <div class="form-check">
            <mat-checkbox (change)="checkVal($event,5)" id="opt5" [checked]="isSellerDeliverFixed"></mat-checkbox>
            <label class="form-check-label" style="color: black;">
              <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_FINED_RATE' | translate}}</h4>
              <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY' | translate}}</p>
              <br/>
              <div class="row" style="margin-left: 6%">
                <mat-radio-button [checked]="areaDisable" value="1" (change)="areaDisable=true" class="tos-radio">
                  <p style="margin-left: 0">
                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.ISLAND_DELIVERY' | translate}}</p>
                </mat-radio-button>
                <mat-radio-button value="1" [checked]="!areaDisable" (change)="areaDisable=false" class="tos-radio">
                  <p style="margin-left: 0">
                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_DELIVERY' | translate}}</p>
                </mat-radio-button>
              </div>
              <div [hidden]="areaDisable" class="row">
                <label style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA' | translate}}
                </label>
                <textarea class="form-control" maxlength="100" style="margin-left: 6%" [(ngModel)]="areas"
                          formControlName="areas"></textarea>
                <div *ngIf="isAreaReq" style="font-size: 10px;color: red">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SPECIFIC_AREA_REQUIRED' | translate}}
                </div>
              </div>
              <div class="row">
                <label style="font-weight: bold!important;margin-left: 6%;margin-top: 5%;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGED' | translate}}
                  ({{'CURRENCY.CURRENCY' | translate}}) </label>
                <input class="form-control" style="margin-left: 6%" formControlName="deliverChargeFixed" type="number">
                <div *ngIf="isFixedChargeReq" style="font-size: 10px;color: red; margin-left: 4vw;margin-top: 1vh;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_CHARGES_REQUIRED' | translate}}
                </div>
              </div>
            </label>
          </div>
        </div>
      </form>
      <div class="optionbox section-transport">
        <div class="form-check">
          <mat-checkbox (change)="checkVal($event,4)" disabled="" id="opt4" [checked]="train"></mat-checkbox>
          <label class="form-check-label">
            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.USE_RAIL_TRANSPORT' | translate}}</h4>
            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_RESPONSIBILITY_BUYER' | translate}}</p>
            <div class="selects">
              <div class="form-group">
                <label for="railway">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.NEAREST_STATION' | translate}}
                </label>
                <select disabled (change)="selectRailway($event.target.value)" class="form-control province"
                        id="railway">
                  <option [value]="none" disabled>
                    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.SELECT_STATION' | translate}}
                  </option>
                  <option *ngFor="let station of railwayList" [value]="station.id">{{station.descriptionEng}}
                  </option>

                </select>
                <div class="inputError" *ngIf="railwayReq" style="margin-left: 4vw;margin-top: 1vh;">
                  {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.STATION_REQUIRED' | translate}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="optionbox section-transport" [ngClass]="{'activated':deliveryOptions==3}">
        <div class="form-check">
          <mat-checkbox (change)="checkVal($event,2)" [checked]="buyerDeliver" id="opt2"></mat-checkbox>
          <label class="form-check-label">
            <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_TRANSPORT' | translate}}</h4>
            <p>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.BUYER_RESPONSIBILITY' | translate}}</p>
          </label>
        </div>
      </div>
    </div>
    <div class="inputError" *ngIf="deliveryReq" style="margin-left: 1vw;">
      {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.DELIVERY_OPTION.DELIVERY_TYPE_REQUIRED' | translate}}</div>
    <div class="form-group btn-sections">
      <a class="btn cancel-btn-half" (click)="selectSpotNext=3"
         aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
      <a class="btn next-btn-half" (click)="next()"
         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.NEXT' | translate}}</a>
    </div>
  </div>
</div>


<div class="sellproduct-steps" *ngIf="selectSpotNext==5">
  <h4>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_PHOTOS' | translate}}
    <span class="validationStar">*</span></h4>
  <p style="
    color: #6B6C6F;
    font-weight: bold;
    padding: 15px;
    margin: 0;
    font-size: 14px;">
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE' | translate}}5MB
    {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.FILE_SIZE_ALLOWED' | translate}}</p>
  <p style=" color: #6B6C6F;
    font-weight: bold;
    padding: 0 15px;
    margin: 0;
    font-size: 14px;">
    {{'ALLOWED_FILE_TYPES' | translate}}</p>
  <div class="anp-select-op categoryform row" style=" height: 500px; overflow-y: scroll;">
    <div class="img-upload">
      <div class="uploader">

        <ul class="list-group lgroup" style="width: 36%">
          <li class="list-group-item" *ngFor="let image of imageObject">
            <div class="row">
              <div class="col-6">
                <img data-toggle="modal" data-target="#openImage" (click)="setViewImage(image.imageURL)"
                     src="{{image.imageURL}}" alt="" class="editimg"
                     style="width: 50px!important;height: 50px!important;border-radius: 10px">
              </div>
              <div class="col-6" style=" display: flex;  align-items: center;  justify-content: flex-end;">
                <button type="button" class="btn btn-danger" (click)="removeImage(image.imageId)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>


        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" accept="image/png,image/jpeg,image/jpg">
          <ngx-dropzone-label>
            <div class="uploaderbox">
              <em class="icon fa fa-camera"></em>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                                      [removable]="true" (removed)="onRemove(f)">
          </ngx-dropzone-image-preview>
          <div class="imgadd"><em class="fa fa-plus"></em></div>
        </ngx-dropzone>

      </div>
      <p>
        {{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_IDENTIFICATION' | translate}}</p>
      <ol>
        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_IDENTIFICATION_1' | translate}}</li>

        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_IDENTIFICATION_2' | translate}}</li>

        <li>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_IDENTIFICATION_3' | translate}}</li>
      </ol>
    </div>

    <div class="form-group btn-sections">
      <a class="btn cancel-btn-half" (click)="selectSpotNext=4"
         aria-label="Close">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
      <a class="btn next-btn-half" (click)="updateProduct()"
         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.SUBMIT_RESUBMIT' | translate}}</a>
    </div>
  </div>
</div>

<div class="sellproduct-steps" *ngIf="selectSpotNext==6">

  <div class="anp-select-op categoryform row">
    <div class="successmsg">
      <em class="fa fa-check"></em>
      <h3>{{'MY_ACCOUNT.SELL_A_PRODUCT.SPOT_SELLING.PRODUCT_SUCCESS_MSG' | translate}}</h3><br><br>
      <a class="btn next-btn-close" data-dismiss="modal" (click)="closeSuccessMsg()"
         aria-label="Next">{{'MY_ACCOUNT.SELL_A_PRODUCT.CLOSE' | translate}}</a>
    </div>

  </div>


</div>
