import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationHandlerService} from '../../../validation-handler/validation-handler.service';
import {CommonSignUpService} from 'src/app/service/common-sign-up.service';
import {InteractionService} from '../../../service/interaction.service';
import {MatCheckboxChange} from '@angular/material';
import {IdentityService} from '../../../service/identity.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {DivSecFilter} from '../../../dto/filter/divSecFilter';
import {GramaNilFilter} from '../../../dto/filter/gramaNilFilter';
import {CityFilter} from '../../../dto/filter/cityFilter';
import {SmsService} from '../../../service/sms.service';
import {TranslateService} from '@ngx-translate/core';
import {FarmerType} from '../../../dto/farmerType';
import {CoreService} from '../../../service/core.service';
import {AgricultureServiceAreaFilter} from "../../../dto/filter/agricultureServiceAreaFilter";
import {AgrarianInstructionalDivisionFilter} from "../../../dto/filter/agrarianInstructionalDivisionFilter";

@Component({
  selector: 'app-sign-up-farmer',
  templateUrl: './sign-up-farmer.component.html',
  styleUrls: ['./sign-up-farmer.component.css']
})
export class SignUpFarmerComponent implements OnInit {

  signUpFarmer: FormGroup;
  submitted = false;

  landBeLongs: boolean = false;
  farmerTypeEnable: boolean = false;

  farmerName: any;
  farmerNic: any;
  farmerMobile: any;
  farmerAddress: any;
  farmerEmail: any = null;
  farmerUsername: any;
  password: any;
  rePassword: any;
  referralNumber: any;

  allProvinceList: any;
  allDistrictList: any;
  allDivisionList: any;
  allGramaList: any;
  allAgriList: any;
  allInstructList: any;

  selectedDivisionalId: any;
  selectedProvinceId: any;
  selectedDistrictId: any;
  selectedLandUnitId: any;
  selectedGramaId: any;
  selectedAgriId: any;
  selectedInstructId: any;
  selectedCultivationArea: any;
  farmerLandArea: any;
  farmerTypeList: FarmerType[] = [];
  waterResourceList: any = [];
  cultivationList: any = [];


  farmerTypes = [
    {
      'id': 1, 'description': 'REGISTER.FARMER.MAHAWELI'
    },
    {
      'id': 2, 'description': 'REGISTER.FARMER.GAP'
    },
    {
      'id': 3, 'description': 'REGISTER.FARMER.ORGANIC'
    }
  ];

  landUnitTypes = [
    {
      'id': 'ACRES', 'description': 'SIGN_UP.LAND_UNIT.ACRES'
    },
    {
      'id': 'PERCH', 'description': 'SIGN_UP.LAND_UNIT.PERCH'
    }
  ];

  waterResources = [
    {
      'id': 'IRRIGATION WATER', 'description': 'REGISTER.FARMER.WATER_RESOURCE.IRRIGATION_WATER'
    },
    {
      'id': 'RAIN WATER', 'description': 'REGISTER.FARMER.WATER_RESOURCE.RAIN_WATER'
    },
    {
      'id': 'TUBE WELL', 'description': 'REGISTER.FARMER.WATER_RESOURCE.TUBE_WELL'
    },
    {
      'id': 'AGGRO WELL', 'description': 'REGISTER.FARMER.WATER_RESOURCE.AGGRO_WELL'
    }
  ];

  cultivations = [
    {
      'id': 'FOOD CROPS', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.FOOD_CROPS'
    },
    {
      'id': 'CASH CROPS', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.CASH_CROP'
    },
    {
      'id': 'HORTICULTURE CROPS', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.HORTICULTURE_CROPS'
    },
    {
      'id': 'DAIRY POULTRY', 'description': 'REGISTER.FARMER.NATURE_OF_CULTIVATION.DAILY_POULTRY'
    }
  ];

  cultivationAreaModel = [
    {
      'id': 'Mahaweli', 'description': 'COMPANY.Mahaweli'
    },
    {
      'id': 'Interprovincial', 'description': 'COMPANY.Interprovincial'
    },
    {
      'id': 'Provincial Councils', 'description': 'COMPANY.Provincial_Councils'
    }
  ];

  waterResourceEnabled: boolean = false;
  cultivationEnabled: boolean = false;
  activeLang: string = 'en';
  agreeWithUs: boolean = false;
  tenantValue: any;

  incorrectNIC: boolean = false;
  incorrectMobile: boolean = false;
  incorrectEmail: boolean = false;
  incorrectUsername: boolean = false;
  incorrectUsernamePattern: boolean = false;
  invalidUnit: boolean = false;
  invalidArea: boolean = false;
  isAgentFarmer: boolean = false;

  allCities: any;
  selectedCity: any;
  address1: any;
  address2: any;
  postalCode: any;
  cityObject: any;
  cityName: any;

  // CityFilter
  allCitiesUD: CityFilter[] = [];
  myControl = new FormControl();
  filteredOptions: Observable<CityFilter[]>;
  city: any = '';
  isCitySelected: boolean = false;
  cityUDObject: CityFilter;

  // DeviFilter
  myControlDevi = new FormControl();
  divi: any = '';
  filteredOptionsDivi: Observable<DivSecFilter[]>;
  allDivSecUD: DivSecFilter[] = [];
  divSecObject: DivSecFilter;
  isDiviSelected: boolean = false;

  // GramaFilter
  myControlGrama = new FormControl();
  grama: any = '';
  filteredOptionsGrama: Observable<GramaNilFilter[]>;
  allGramaUD: GramaNilFilter[] = [];
  gramaNilObject: GramaNilFilter;
  isGramaSelected: boolean = false;


  // AgricultureServiceAreaFilter
  myControlAgri = new FormControl();
  agri: any = '';
  filteredOptionsAgri: Observable<AgricultureServiceAreaFilter[]>;
  allAgriUD: AgricultureServiceAreaFilter[] = [];
  agriObject: AgricultureServiceAreaFilter;
  isAgriSelected: boolean = false;


  // Agrarian Instructional Division Filter
  myControlInstruct = new FormControl();
  instruct: any = '';
  filteredOptionsInstruct: Observable<AgricultureServiceAreaFilter[]>;
  allInstructUD: AgricultureServiceAreaFilter[] = [];
  instructObject: AgricultureServiceAreaFilter;
  isInstructSelected: boolean = false;


  language: any = 'EN';

  economicCenterShopRef: any;

  @ViewChild('optModal', {static: true}) optModal;
  @ViewChild('ngOtpInput', {static: true}) ngOtpInputRef: any;
  @ViewChild('closeModal', {static: true}) closeModal;

  constructor(private _formBuilder: FormBuilder,
              private validationHandlerService: ValidationHandlerService,
              private identityService: IdentityService,
              private smsService: SmsService,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private coreService: CoreService,
              private _translateService: TranslateService,
              private interactionService: InteractionService,
              private commonSignUpService: CommonSignUpService) {

    this.activeLang = sessionStorage.getItem('LANG');
    this.tenantValue = localStorage.getItem('TENANT_VALUE');

    if (localStorage.getItem('LEAD_CODE') !== undefined) {
      this.referralNumber = localStorage.getItem('LEAD_CODE');
    }
    if (localStorage.getItem('ECONOMIC_SHOP_CODE') !== undefined) {
      this.economicCenterShopRef = localStorage.getItem('ECONOMIC_SHOP_CODE');
    }
  }

  ngOnInit() {
    this.activeLang = sessionStorage.getItem('LANG');
    this.reactiveForm();
    this.getAllProvince();

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
        this.allProvinceList = undefined;
        this.getAllProvince();
      }
    });

    this.route.queryParams.subscribe(parms => {
      if (parms['agentFarmer']) {

        this.isAgentFarmer = parms['agentFarmer'];
        if (parms['agentRef'] !== undefined) {
          this.referralNumber = parms['agentRef'];
        }
        if (parms['shopRef'] !== undefined) {
          this.economicCenterShopRef = parms['shopRef'];
        }
      } else {
        this.referralNumber = undefined;
        this.economicCenterShopRef = undefined;
      }
    });

  }

  reactiveForm() {
    this.signUpFarmer = this._formBuilder.group({
      farmerMobile: ['', [Validators.required, Validators.pattern(this.validationHandlerService.onlyTenNumberValidation())]],
      farmerName: ['', [Validators.required, Validators.pattern(this.validationHandlerService.nameValidation())]],
      farmerNic: ['', [Validators.required, Validators.pattern(this.validationHandlerService.sriLankaNICValidation())]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      farmerLandArea: [''],
      province: ['', Validators.required],
      district: ['', Validators.required],
      division: ['', Validators.required],
      gramaList: ['', Validators.required],
      agriList: [''],
      instructList: [''],
      landUnitTypeList: [''],
      referralNumber: [''],
      economicCenterShopRef: [''],
      cultivationArea: ['', Validators.required],
      language: [''],
      farmerUsername: ['', [Validators.required, Validators.pattern(this.validationHandlerService.userNameVerification())]],
      farmerEmail: ['', [Validators.pattern(this.validationHandlerService.emailValidation())]],
    });
  }

  get f() {
    return this.signUpFarmer.controls;
  }

  selectLandUnit(event) {
    this.selectedLandUnitId = event;
    this.invalidUnit = false;
  }

  selectCultivationArea(event: any) {
    this.selectedCultivationArea = event;
  }

  getAllProvince() {
    this.commonSignUpService.fetchAllProvince().subscribe((result) => {
      if (result) {
        this.allProvinceList = result;
      }
    });
  }

  getAllDistrict(id) {
    this.commonSignUpService.fetchAllDistrict(id).subscribe((result) => {
      if (result) {
        this.allDistrictList = result;
      }
    });

  }

  getAllCities(id) {
    this.commonSignUpService.fetchAllCities(id).subscribe((result) => {
      if (result) {
        this.allCities = result;

        // to the autocomplete city
        this.allCitiesUD = [];
        for (let city of result) {
          this.cityUDObject = new CityFilter();
          this.cityUDObject.id = city.id;
          this.cityUDObject.cityName = city.nameEN;
          this.cityUDObject.nameEN = city.nameEN;
          this.cityUDObject.nameSI = city.nameSI;
          this.cityUDObject.nameTA = city.nameTA;
          this.allCitiesUD.push(this.cityUDObject);
        }

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filter(state) : this.allCitiesUD.slice())
          );
      }
    });
  }

  private _filter(value: string): CityFilter[] {
    if (value != '[object Object]') {

      this.isCitySelected = value === this.city;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allCitiesUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allCitiesUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allCitiesUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isCitySelected = true;
    }

  }


  private _filterDivi(value: string): DivSecFilter[] {
    if (value != '[object Object]') {

      this.isDiviSelected = value === this.divi;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allDivSecUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allDivSecUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allDivSecUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isDiviSelected = true;
    }

  }

  private _filterGrama(value: string): GramaNilFilter[] {
    if (value != '[object Object]') {

      this.isGramaSelected = value === this.grama;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allGramaUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allGramaUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allGramaUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isGramaSelected = true;
    }

  }

  private _filterAgri(value: string): AgricultureServiceAreaFilter[] {
    if (value != '[object Object]') {

      this.isAgriSelected = value === this.agri;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allAgriUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allAgriUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allAgriUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isAgriSelected = true;
    }

  }

  private _filterInstruct(value: string): AgrarianInstructionalDivisionFilter[] {
    if (value != '[object Object]') {

      this.isInstructSelected = value === this.instruct;
      const filterValue = value.toLowerCase();
      if (this.activeLang === 'en') {
        return this.allInstructUD.filter(option => option.nameEN != null && option.nameEN.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'si') {
        return this.allInstructUD.filter(option => option.nameSI != null && option.nameSI.toLowerCase().includes(filterValue));
      } else if (this.activeLang === 'ta') {
        return this.allInstructUD.filter(option => option.nameTA != null && option.nameTA.toLowerCase().includes(filterValue));
      }
    } else {
      this.isInstructSelected = true;
    }

  }


  getCityById(id) {
    this.commonSignUpService.fetchCityById(id).subscribe((result) => {
      if (result) {
        this.postalCode = result['postalCode'];
      }
    });
  }

  getAllDivSec(id) {
    this.commonSignUpService.fetchAllDivision(id).subscribe((result) => {
      if (result) {
        this.allDivisionList = result;
        this.allDivSecUD = [];
        for (let div of result) {
          this.divSecObject = new DivSecFilter();
          this.divSecObject.id = div.id;
          this.divSecObject.name = div.nameEN;
          this.divSecObject.nameEN = div.nameEN;
          this.divSecObject.nameSI = div.nameSI;
          this.divSecObject.nameTA = div.nameTA;

          this.allDivSecUD.push(this.divSecObject);
        }

        this.filteredOptionsDivi = this.myControlDevi.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterDivi(state) : this.allDivSecUD.slice())
          );

      }
    });
  }

  getAllGramaNiladari(id) {
    this.commonSignUpService.fetchAllGrama(id).subscribe((result) => {
      if (result) {
        this.allGramaList = result;
        this.allGramaUD = [];
        for (let div of result) {
          this.gramaNilObject = new GramaNilFilter();
          this.gramaNilObject.id = div.id;
          this.gramaNilObject.name = div.nameEN;
          this.gramaNilObject.nameEN = div.nameEN;
          this.gramaNilObject.nameSI = div.nameSI;
          this.gramaNilObject.nameTA = div.nameTA;

          this.allGramaUD.push(this.gramaNilObject);
        }

        this.filteredOptionsGrama = this.myControlGrama.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterGrama(state) : this.allGramaUD.slice())
          );
      }
    });
  }

  getAllAgriculturalServiceArea(id) {
    this.commonSignUpService.fetchAllAgri(id).subscribe((result) => {
      if (result) {
        this.allAgriList = result;
        this.allAgriUD = [];
        for (let div of result) {
          this.agriObject = new AgricultureServiceAreaFilter();
          this.agriObject.id = div.id;
          this.agriObject.name = div.nameEN;
          this.agriObject.nameEN = div.nameEN;
          this.agriObject.nameSI = div.nameSI;
          this.agriObject.nameTA = div.nameTA;

          this.allAgriUD.push(this.agriObject);
        }

        this.filteredOptionsAgri = this.myControlAgri.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterAgri(state) : this.allAgriUD.slice())
          );
      }
    });
  }


  getAllAgrarianInstructionalDivision(id) {
    this.commonSignUpService.fetchAllInstruct(id).subscribe((result) => {
      if (result) {
        this.allInstructList = result;
        this.allInstructUD = [];
        for (let div of result) {
          this.instructObject = new AgrarianInstructionalDivisionFilter();
          this.instructObject.id = div.id;
          this.instructObject.name = div.nameEN;
          this.instructObject.nameEN = div.nameEN;
          this.instructObject.nameSI = div.nameSI;
          this.instructObject.nameTA = div.nameTA;

          this.allInstructUD.push(this.instructObject);
        }

        this.filteredOptionsInstruct = this.myControlInstruct.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterInstruct(state) : this.allInstructUD.slice())
          );
      }
    });
  }

  selectProvince(event: any) {
    this.selectedProvinceId = event;
    this.getAllDistrict(event);
    this.allDivisionList = [];
    this.allGramaList = [];
  }

  selectDivisional(event: any) {
    if (this.activeLang === 'en') {
      this.divi = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.divi = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.divi = event.nameTA;
    }

    this.isDiviSelected = true;
    this.selectedDivisionalId = event.id;
    this.getAllGramaNiladari(event.id);
    this.getAllAgriculturalServiceArea(event.id);
  }

  selectDistrict(event: any) {
    this.selectedDistrictId = event;
    this.getAllCities(event);
    this.getAllDivSec(event);
    this.allGramaList = [];
  }

  selectGrama(event: any) {

    if (this.activeLang === 'en') {
      this.grama = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.grama = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.grama = event.nameTA;
    }
    this.isGramaSelected = true;
    this.selectedGramaId = event.id;
  }

  selectAgri(event: any) {

    if (this.activeLang === 'en') {
      this.agri = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.agri = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.agri = event.nameTA;
    }
    this.isAgriSelected = true;
    this.selectedAgriId = event.id;
    this.getAllAgrarianInstructionalDivision(event.id);
  }

  selectInstruct(event: any) {

    if (this.activeLang === 'en') {
      this.instruct = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.instruct = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.instruct = event.nameTA;
    }
    this.isInstructSelected = true;
    this.selectedInstructId = event.id;
  }

  selectCity(event: any) {

    if (this.activeLang === 'en') {
      this.city = event.nameEN;
    } else if (this.activeLang === 'si') {
      this.city = event.nameSI;
    } else if (this.activeLang === 'ta') {
      this.city = event.nameTA;
    }

    this.cityName = event.nameEN;

    this.isCitySelected = true;

    this.selectedCity = event.id;
    this.getCityById(this.selectedCity);
  }

  selectCityEn(event: any) {
    this.cityName = event;
  }

  submitFarmer() {
    this.submitted = true;
    if (this.signUpFarmer.invalid) {
      return;
    }
    if ((this.farmerLandArea !== null && this.farmerLandArea !== undefined) &&
      (this.selectedLandUnitId == null)) {
      this.invalidUnit = true;
      return;
    } else if ((this.farmerLandArea === null || this.farmerLandArea === undefined) &&
      (this.selectedLandUnitId !== null && this.selectedLandUnitId !== undefined)) {
      this.invalidArea = true;
      return;
    } else {
      this.invalidUnit = false;
      this.invalidArea = false;
    }

    if (!this.isCitySelected) {
      Swal.fire({
        type: 'error',
        title: 'please select city correctly'
      });
    } else if (!this.isDiviSelected) {
      Swal.fire({
        type: 'error',
        title: 'please select divisional secretariat correctly'
      });
    } else if (!this.isGramaSelected) {
      Swal.fire({
        type: 'error',
        title: 'please select Grama niladhari area correctly'
      });
    } else {
      if (this.referralNumber !== undefined && this.referralNumber !== null) {
        this.coreService.checkLeadCodeExist(this.referralNumber).subscribe(result => {
          if (result.success) {
            this.commonVerificationBody();
          } else {
            Swal.fire({
              type: 'error',
              title: 'Please enter valid lead reference code'
            });
          }

        }, () => {
          Swal.fire({
            type: 'error',
            title: 'Please enter valid lead reference code'
          });
        });
      } else if (this.economicCenterShopRef !== undefined && this.economicCenterShopRef !== null) {
        this.coreService.checkEconomicShopCodeExist(this.economicCenterShopRef).subscribe(result => {
          if (result.success) {
            this.commonVerificationBody();
          } else {
            Swal.fire({
              type: 'error',
              title: 'Please enter valid economic center reference code'
            });
          }

        }, () => {
          Swal.fire({
            type: 'error',
            title: 'Please enter valid economic center reference code'
          });
        });
      } else {
        this.loading();
        this.isOTPModalClose = false;

        let _requestOTP = {
          'telNo': [
            this.farmerMobile
          ],
          'username': this.farmerUsername,
          'systemUsername': this.farmerName,
          'code': 'OTP',
          'language': this.language
        };
        this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
          Swal.close();
          if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
            this.OTPValidation(60 * 3, document.getElementById('time'));
          }
          this.optModal.nativeElement.click();
        }, () => {
          Swal.close();
        });
      }

    }

  }

  commonVerificationBody() {
    this.loading();
    this.isOTPModalClose = false;

    let _requestOTP = {
      'telNo': [
        this.farmerMobile
      ],
      'username': this.farmerUsername,
      'systemUsername': this.farmerName,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
      Swal.close();
      if (document.querySelector('#time') !== null && !this.isOTPModalClose) {
        this.OTPValidation(60 * 3, document.getElementById('time'));
      }
      this.optModal.nativeElement.click();
    }, () => {
      Swal.close();
    });
  }

  clearErr() {
    this.invalidArea = false;
  }

  saveFarmer() {
    this.loading();

    if (this.address2 === '' || this.address2 === undefined || this.address2 === null) {
      this.address2 = '';
      this.farmerAddress = this.address1 + ',' + this.cityName;
    } else {
      this.farmerAddress = this.address1 + ',' + this.address2 + ',' + this.cityName;
    }

    let _tempLandUnit;
    if (this.selectedLandUnitId == null) {
      _tempLandUnit = null;
    } else {
      this._translateService.get(this.selectedLandUnitId).subscribe(lang => {
        _tempLandUnit = lang;
      });
    }
    let _tempWaterResourceList = [];
    if (this.waterResourceList.length > 0) {
      for (let waterResource of this.waterResourceList) {
        this._translateService.get(waterResource).subscribe(lang => {
          _tempWaterResourceList.push(lang);

        });
      }
    }

    let _tempCultivationList = [];
    if (this.cultivationList.length > 0) {
      for (let cultivation of this.cultivationList) {
        this._translateService.get(cultivation).subscribe(lang => {
          _tempCultivationList.push(lang);
        });
      }
    }

    if (this.farmerEmail === '' || this.farmerEmail === null || this.farmerEmail === undefined) {
      this.farmerEmail = null;
    }

    let farmerBody = {
      'nic': this.farmerNic,
      'landArea': this.farmerLandArea,
      'contactNo': this.farmerMobile,
      'landUnit': _tempLandUnit,
      'isMine': this.landBeLongs,
      'cultivation': _tempCultivationList + '',
      'waterResource': _tempWaterResourceList + '',
      'name': this.farmerName,
      'address': this.farmerAddress,
      'pin': '',
      'email': this.farmerEmail,
      'username': this.farmerUsername,
      'agentRef': this.referralNumber,
      'shopRef': this.economicCenterShopRef,
      'farmerType': this.farmerTypeList,
      'gramaNiladari': {
        'id': this.selectedGramaId
      },
      'instructionalDivision': {
        'id': this.selectedInstructId
      },
      'active': false,
      'addressOne': this.address1,
      'addressTwo': this.address2,
      'addressType': 1,
      'cityId': this.selectedCity,
      'countryId': 1,
      'postalCode': this.postalCode,
      'cultivationArea': this.selectedCultivationArea
    };

    // to the update sms sending language
    let bodyLan = {
      'username': this.farmerUsername,
      'language': this.language
    };
    // endpoint
    this.smsService.saveLanguage(bodyLan).subscribe(() => {
      //start of sign up form submit
      this.commonSignUpService.saveFarmer(farmerBody).subscribe((result) => {
        if (result) {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.manageLocalizationSuccessTittle(sessionStorage.getItem('LANG')),
            text: this.validationHandlerService.manageLocalizationSuccessBodyMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          }).then(() => {
            this.signUpFarmer.reset();

            if (this.isAgentFarmer) {
              this.router.navigate(['/my-account/farmers/wholesale']);
            } else {
              this.router.navigate(['/home/' + localStorage.getItem('TENANT_VALUE')]);
            }
          });
          const title = Swal.getContent();
          title.style.textAlign = 'center';
        } else {
          Swal.fire({
            type: 'error',
            title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: true,
            confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
          });
        }
      }, (error) => {
        Swal.fire({
          type: 'error',
          title: this.validationHandlerService.manageErrorMessage(sessionStorage.getItem('LANG')),
          showConfirmButton: true,
          confirmButtonText: this.validationHandlerService.manageLocalizationSuccessButton(sessionStorage.getItem('LANG'))
        });
        console.log('Farmer save function :' + error);
      });

    });
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }


  selectFarmerType($event, value) {
    if ($event.checked) {
      let type = new FarmerType();
      type.id = value;
      this.farmerTypeList.push(type);
    } else {
      if (this.farmerTypeList.length > 0) {
        for (let farmerType of this.farmerTypeList) {
          if (farmerType.id === value) {
            let index = this.farmerTypeList.indexOf(farmerType);
            if (index !== -1) {
              this.farmerTypeList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  selectWaterResource($event, value) {
    if ($event.checked) {
      this.waterResourceList.push(value);
    } else {
      if (this.waterResourceList.length > 0) {
        for (let waterResource of this.waterResourceList) {
          if (waterResource === value) {
            let index = this.waterResourceList.indexOf(waterResource);

            if (index !== -1) {
              this.waterResourceList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  selectCultivation($event, value) {
    if ($event.checked) {
      this.cultivationList.push(value);
    } else {
      if (this.cultivationList.length > 0) {
        for (let cultivation of this.cultivationList) {
          if (cultivation === value) {
            let index = this.cultivationList.indexOf(cultivation);
            if (index !== -1) {
              this.cultivationList.splice(index, 1);
            }
          }
        }
      }
    }
  }

  changeLandBeLongs($event) {
    this.landBeLongs = !!$event.checked;
  }

  agreeWithAgreement($event: MatCheckboxChange) {
    this.agreeWithUs = $event.checked;
  }

  checkExists(value, type) {
    switch (type) {

      case 'MOBILE':
        if (value !== undefined) {
          this.identityService.checkContactNoExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'CONTACT_NO_NOT_EXISTS') {
              this.incorrectMobile = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'CONTACT_NO_EXISTS') {
              this.incorrectMobile = true;
              this.signUpFarmer.controls['farmerMobile'].setErrors(value);
            }
          });
        }

        break;

      case 'NIC':
        if (value !== undefined) {
          this.identityService.checkNICExists(value.toUpperCase()).subscribe(isExists => {
            if (isExists['message'] === 'NIC_NOT_EXISTS') {
              this.incorrectNIC = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'NIC_EXISTS') {
              this.incorrectNIC = true;
              this.signUpFarmer.controls['farmerNic'].setErrors(value);
            }
          });
        }


        break;

      case 'USERNAME':
        if (value !== undefined) {

          // Cannot be only numbers ( Username should include letters and cannot be only numbers)
          //
          // Letters must be included ( Username should include letters)
          //
          // No Spaces and it has to be one word. (Username cannot have any spaces)

          let boolean = this.validationHandlerService.usernameValidationPattern(value);

          if (boolean) {
            this.incorrectUsernamePattern = true;
            this.signUpFarmer.controls['farmerUsername'].setErrors(value);
          } else {
            this.incorrectUsernamePattern = false;
            this.signUpFarmer.updateValueAndValidity();
          }

          this.identityService.checkUsernameExists(value).subscribe(isExists => {
            if (isExists['message'] === 'USERNAME_NOT_EXISTS') {
              this.incorrectUsername = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'USERNAME_EXISTS') {
              this.incorrectUsername = true;
              this.signUpFarmer.controls['farmerUsername'].setErrors(value);
            }
          });
        }


        break;

      case 'EMAIL':
        if (value !== undefined) {
          this.identityService.checkEmailExists(value).subscribe(isExists => {
            if (isExists['message'] === 'EMAIL_NOT_EXISTS') {
              this.incorrectEmail = false;
              this.signUpFarmer.updateValueAndValidity();
            } else if (isExists['message'] === 'EMAIL_EXISTS') {
              this.incorrectEmail = true;
              this.signUpFarmer.controls['farmerEmail'].setErrors(value);
            }
          });
        }
        break;
      default:
        break;

    }

  }

  onOtpChange(OTP) {
    this.OTP_CODE = OTP;
  }

  isShowResendButton: boolean = false;
  OTP_CODE: any;
  isLoading: boolean = false;
  isOTPModalClose: boolean = false;
  isInvalid: boolean = false;

  OTPValidation(duration, display) {
    let timer = duration, minutes, seconds;
    let x = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (this.isOTPModalClose) {
        clearInterval(x);
        document.getElementById('time').textContent = '03:00';
        return;
      }

      display.textContent = minutes + ':' + seconds;

      if (display.textContent === '00:00') {
        clearInterval(x);
        return this.isShowResendButton = true;
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  closeOTPModal() {
    this.isLoading = false;
    this.isOTPModalClose = true;
    this.isInvalid = false;
    this.ngOtpInputRef.setValue('');
    clearInterval();
  }

  resendOTPCode() {
    this.ngOtpInputRef.setValue('');
    this.isLoading = false;
    this.isInvalid = false;
    document.getElementById('time').textContent = '03:00';
    this.OTPValidation(60 * 3, document.getElementById('time'));
    this.isShowResendButton = false;

    let _requestOTP = {
      'telNo': [
        this.farmerMobile
      ],
      'username': this.farmerUsername,
      'systemUsername': this.farmerName,
      'code': 'OTP',
      'language': this.language
    };
    this.smsService.requestNewOTPCode(_requestOTP).subscribe(() => {
    });
  }

  verifyOTP() {
    this.isLoading = true;
    let verifyOTPBody = {
      'username': this.farmerUsername,
      'otp': this.OTP_CODE
    };
    this.smsService.verifyOTPCode(verifyOTPBody).subscribe(isVerify => {
      if (isVerify) {
        this.isInvalid = false;
        this.isLoading = false;
        this.closeModal.nativeElement.click();
        this.saveFarmer();
      } else {
        this.isInvalid = true;
        this.isLoading = false;
      }
    }, () => {
      this.isInvalid = true;
      this.isLoading = false;
    });
  }

  saveLanguage(event) {
    this.language = event;
  }
}
