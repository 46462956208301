<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="w-full-col p-30">

          <div class="container">
            <div class="vas-plans loan-plans">
              <div class="page-title text-center">
                <h3>{{'Manage My Insurance'|translate}}</h3>
                <div class="page-sub">
                  <p *ngIf="activeLang === 'en'">Pick an Insurance plan suited for you</p>
                  <p *ngIf="activeLang === 'si'">ඔබට ගැලපෙන රක්‍ෂණ සැලැස්මක් තෝරන්න</p>
                  <p *ngIf="activeLang === 'ta'">உங்களுக்கு ஏற்ற காப்பீட்டுத் திட்டத்தைத் தேர்ந்தெடுக்கவும்</p>
                </div>
              </div>

              <hr>
              <div class="vas-loans-container">
                <div class="top-btn">
                  <button class="btn btn-success d-green-btn"
                          [routerLink]="['/value-added-services/'+ tenantValue]">
                    {{'VALUE_ADDED_SERVICES'|translate}}
                  </button>
                </div>

                <div class="card-container insurance-cards vas-cards">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-3 col-xs-12">
                        <a class="card-sub vas-item">
                          <div class="select-icon vas-choice text-center"
                               [routerLink]="['/value-added-services/insurance-plans/'+ tenantValue]">
                            <div class="d-flex align-items-center">
                              <div data-target="#loanModal" data-toggle="modal">
                                <div class="item-img">
                                  <img src="../../../../../assets/images/payment-types/insuarance/slic.png"
                                       alt="" class="vasimg insimg">
                                </div>
                                <div class="item-info">
                                  <h3>{{'Proposal form for Insurance of own goods of  vehicles'|translate}}</h3>

                                  <p *ngIf="activeLang === 'en'">This Insurance Policy is applicable for Goods
                                    transported by farmers to the Market. Perishable nature goods transported in refer
                                    containers, freezer trucks are not intended to be covered and such Goods could be
                                    insured separately.
                                  </p>

                                  <p *ngIf="activeLang === 'si'">ගොවීන් වෙළඳපොලට ප්‍රවාහනය කරන භාණ්ඩ සඳහා මෙම රක්‍ෂණ
                                    ප්‍රතිපත්තිය අදාළ වේ. යොමු කරන ලද බහාලුම්වල, ශීතකරණ ට්‍රක් රථවල ප්‍රවාහනය කරන
                                    දිරාපත් වන ස්වාභාවික භාණ්ඩ ආවරණය කිරීමට අදහස් නොකරන අතර එම භාණ්ඩ වෙන වෙනම රක්ෂණය කළ
                                    හැකිය.
                                  </p>


                                  <p *ngIf="activeLang === 'ta'">
                                    சந்தைக்கு விவசாயிகள் கொண்டு செல்லும் பொருட்களுக்கு இந்த காப்பீட்டு கொள்கை
                                    பொருந்தும். அழிந்துபோகக்கூடிய இயற்கை பொருட்கள் குறிப்பு கொள்கலன்களில் கொண்டு
                                    செல்லப்படுகின்றன, உறைவிப்பான் லாரிகள் மறைக்கப்படாது, அத்தகைய பொருட்கள் தனித்தனியாக
                                    காப்பீடு செய்யப்படலாம்.
                                  </p>

                                  <button class="btn btn-success d-green-btn abtn">
                                    <span *ngIf="activeLang === 'en'">Apply</span>
                                    <span *ngIf="activeLang === 'si'">අයදුම් කරන්න</span>
                                    <span *ngIf="activeLang === 'ta'">விண்ணப்பிக்கவும்</span>
                                  </button>
                                </div>
                              </div>

                            </div>

                          </div>
                        </a>
                      </div>
                      <div class="col-md-3 col-xs-12">
                        <a class="card-sub vas-item">
                          <div class="select-icon vas-choice text-center"
                               [routerLink]="['/value-added-services/insurance-plans/plants-machinery/'+ tenantValue]">
                            <div class="d-flex align-items-center">
                              <div data-target="#loanModal" data-toggle="modal">
                                <div class="item-img">
                                  <img src="../../../../../assets/images/payment-types/insuarance/plant-machinery.png"
                                       alt="" class="vasimg">
                                </div>
                                <div class="item-info">
                                  <h3>{{'Contractor Plant and Machinery Insurance Benefit'|translate}}</h3>

                                  <p *ngIf="activeLang === 'en'">The Insurance Policy for Contractors Plant and
                                    Machinery Insurance cover is for the plant and machinery used by contractors against
                                    all types of risks on site during the course of construction.</p>

                                  <p *ngIf="activeLang === 'si'">කොන්ත්‍රාත්කරුවන් සඳහා වන රක්‍ෂණ ප්‍රතිපත්තිය පැල සහ
                                    යන්ත්‍රෝපකරණ රක්‍ෂණ ආවරණය ආවරණය වන අතර ඉදිකිරීම් කාලය තුළදී කොන්ත්‍රාත්කරුවන් විසින්
                                    භාවිතා කරන යන්ත්‍රෝපකරණ සහ යන්ත්‍රෝපකරණ සඳහා වූ වැඩබිමේ ඇති සියළුම අවදානම් වලට
                                    එරෙහිව භාවිතා කෙරේ.</p>


                                  <p *ngIf="activeLang === 'ta'">ஒப்பந்தக்காரர்கள் ஆலை மற்றும் இயந்திரங்களுக்கான
                                    காப்பீட்டு கொள்கை கட்டுமானத்தின் போது தளத்தில் உள்ள அனைத்து வகையான அபாயங்களுக்கும்
                                    எதிராக ஒப்பந்தக்காரர்களால் பயன்படுத்தப்படும் ஆலை மற்றும் இயந்திரங்களுக்கான காப்பீடு
                                    ஆகும்.</p>


                                  <button
                                    class="btn btn-success d-green-btn abtn">
                                    <span *ngIf="activeLang === 'en'">Apply</span>
                                    <span *ngIf="activeLang === 'si'">අයදුම් කරන්න</span>
                                    <span *ngIf="activeLang === 'ta'">விண்ணப்பிக்கவும்</span>
                                  </button>
                                </div>
                              </div>

                            </div>

                          </div>
                        </a>
                      </div>
                      <div class="col-md-3 col-xs-12">
                        <a class="card-sub vas-item">
                          <div class="select-icon vas-choice text-center"
                               [routerLink]="['/value-added-services/insurance-plans/fire/'+ tenantValue]">
                            <div class="d-flex align-items-center">
                              <div data-target="#loanModal" data-toggle="modal">
                                <div class="item-img">
                                  <img src="../../../../../assets/images/payment-types/insuarance/fire.png"
                                       alt="" class="vasimg">
                                </div>
                                <div class="item-info">
                                  <h3>{{'Helaviru Fire Insurance Benefit'|translate}}</h3>
                                  <p *ngIf="activeLang === 'en'">This Insurance Policy covers both Building and the
                                    Contents (Stock in trade, electrical items, furniture etc.).
                                  </p>

                                  <p *ngIf="activeLang === 'si'">මෙම රක්‍ෂණ ප්‍රතිපත්තිය ගොඩනැගිල්ල සහ එහි අඩංගු දෑ
                                    (වෙළඳාමේ කොටස්, විදුලි භාණ්ඩ, ගෘහ භාණ්ඩ ආදිය) ආවරණය කරයි.</p>


                                  <p *ngIf="activeLang === 'ta'">இந்த காப்பீட்டுக் கொள்கை கட்டிடம் மற்றும் உள்ளடக்கங்களை
                                    உள்ளடக்கியது (வர்த்தகத்தில் பங்கு, மின் பொருட்கள், தளபாடங்கள் போன்றவை).
                                  </p>

                                  <button
                                    class="btn btn-success d-green-btn abtn">
                                    <span *ngIf="activeLang === 'en'">Apply</span>
                                    <span *ngIf="activeLang === 'si'">අයදුම් කරන්න</span>
                                    <span *ngIf="activeLang === 'ta'">விண்ணப்பிக்கவும்</span>
                                  </button>
                                </div>
                              </div>

                            </div>

                          </div>
                        </a>
                      </div>
                      <div class="col-md-3 col-xs-12">
                        <a class="card-sub vas-item">
                          <div class="select-icon vas-choice text-center"
                               [routerLink]="['/value-added-services/insurance-plans/personal-accident/'+ tenantValue]">
                            <div class="d-flex align-items-center">
                              <div data-target="#loanModal" data-toggle="modal">
                                <div class="item-img">
                                  <img src="../../../../../assets/images/payment-types/insuarance/accident.png"
                                       alt="" class="vasimg">
                                </div>
                                <div class="item-info">
                                  <h3>{{'Helaviru Personal Accident Insurance Benefits'|translate}}
                                  </h3>

                                  <p *ngIf="activeLang === 'en'">A comprehensive cover provided for compensation against
                                    death and bodily injuries caused by violence, accident, or by other external and
                                    visible means, our Personal Accident Cover insures the policyholder in difficulties
                                    resulting from such unexpected incidents.</p>

                                  <p *ngIf="activeLang === 'si'">හදිසි අනතුරු හෝ වෙනත් බාහිර හා දෘශ්‍යමාන ආකාරයෙන් සිදු
                                    වන මරණ හා ශාරීරික තුවාල වලට වන්දි ගෙවීම සඳහා සවිස්තර ආවරණයක් සහිත පුද්ගලික අනතුරු
                                    ආවරණය මඟින් රක්‍ෂණ හිමියාට එවැනි අනපේක්ෂිත සිදුවීම් හේතුවෙන් ඇති වන දුෂ්කරතා සහතික
                                    කරයි.</p>


                                  <p *ngIf="activeLang === 'ta'">வன்முறை, விபத்து, அல்லது பிற வெளிப்புற மற்றும்
                                    புலப்படும் வழிமுறைகளால் ஏற்படும் இறப்பு மற்றும் உடல் காயங்களுக்கு எதிரான
                                    இழப்பீட்டுக்கு ஒரு விரிவான கவர் வழங்கப்படுகிறது, இதுபோன்ற எதிர்பாராத சம்பவங்களால்
                                    ஏற்படும் சிக்கல்களில் பாலிசிதாரருக்கு எங்கள் தனிப்பட்ட விபத்து கவர் காப்பீடு
                                    செய்கிறது.</p>


                                  <button
                                    class="btn btn-success d-green-btn abtn">
                                    <span *ngIf="activeLang === 'en'">Apply</span>
                                    <span *ngIf="activeLang === 'si'">අයදුම් කරන්න</span>
                                    <span *ngIf="activeLang === 'ta'">விண்ணப்பிக்கவும்</span>
                                  </button>
                                </div>
                              </div>

                            </div>

                          </div>
                        </a>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="tbl-loan- tbl basic-mat-tbl">
                  <div style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;">
                    <table [dataSource]="dataSource" class="table vastbl" mat-table matSort
                           style="width:100%">

                      <ng-container matColumnDef="insuranceId">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          <span *ngIf="activeLang === 'en'">Insurance ID</span>
                          <span *ngIf="activeLang === 'si'">රක්ෂණ හැඳුනුම්පත</span>
                          <span *ngIf="activeLang === 'ta'">காப்பீட்டு ஐடி</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.insuranceId}} </td>
                      </ng-container>

                      <ng-container matColumnDef="datel">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          <span *ngIf="activeLang === 'en'">Date</span>
                          <span *ngIf="activeLang === 'si'">දිනය</span>
                          <span *ngIf="activeLang === 'ta'">தேதி</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.datel}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          <span *ngIf="activeLang === 'en'">Status</span>
                          <span *ngIf="activeLang === 'si'">තත්ත්වය</span>
                          <span *ngIf="activeLang === 'ta'">நிலை</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          <span *ngIf="activeLang === 'en'">Action</span>
                          <span *ngIf="activeLang === 'si'">කටයුතු</span>
                          <span *ngIf="activeLang === 'ta'">நடவடிக்கை</span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                                                    <span class="material-icons greentext"
                                                          *ngIf="element.Status !== 'DRAFT'">
                                                        lock
                                                    </span>
                          <span class="material-icons greentext" *ngIf="element.Status === 'DRAFT'"
                                (click)="openDraftApplication(element.row)">
                                                        edit
                                                    </span>
                        </td>
                      </ng-container>

                      <tr *matHeaderRowDef="displayedColumns" mat-header-row>
                      </tr>
                      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>

                  </div>

                  <div *ngIf="spinner">
                    <div class="sk-cube-grid">
                      <div class="sk-cube sk-cube1"></div>
                      <div class="sk-cube sk-cube2"></div>
                      <div class="sk-cube sk-cube3"></div>
                      <div class="sk-cube sk-cube4"></div>
                      <div class="sk-cube sk-cube5"></div>
                      <div class="sk-cube sk-cube6"></div>
                      <div class="sk-cube sk-cube7"></div>
                      <div class="sk-cube sk-cube8"></div>
                      <div class="sk-cube sk-cube9"></div>
                    </div>
                  </div>

                  <div class="form-group btn-sections b-0 p-0 text-right">
                    <a aria-label="Close" class="btn cancel-btn-half"
                       [routerLink]="'/value-added-services/wholesale'">{{'MY_ACCOUNT.SELL_A_PRODUCT.BACK' | translate}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
