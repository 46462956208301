import {EventEmitter, Injectable} from '@angular/core';
import {UserRoleService} from '../service/user-role.service';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  tenantValue: any;
  public model = new EventEmitter<NgbModal>();
  public closeModel: NgbModal;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private userRoleService: UserRoleService,
              private validationHandlerService: ValidationHandlerService,
              private router:Router) {
    this.tenantValue = localStorage.getItem('TENANT_VALUE');
  }

  processing() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  convertDate(created: any) {
    let date = new Date(new Date(created)),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  logout() {
    Swal.fire({
      title: this.validationHandlerService.logOutMessage(sessionStorage.getItem('LANG')),
      text: this.validationHandlerService.logOutMessages(sessionStorage.getItem('LANG')),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.validationHandlerService.cancelMessage(sessionStorage.getItem('LANG')),
      confirmButtonText: this.validationHandlerService.logOutMessage(sessionStorage.getItem('LANG'))
    }).then((result) => {
      if (result.value) {
        localStorage.setItem('isLogged', 'False');
        localStorage.removeItem('cartID');
        localStorage.removeItem('Access-Token');
        this.router.navigate(['/home/'+this.tenantValue]);
      }
    });
  }

  adminStatusManage() {
    if (localStorage.getItem('isLogged') === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        for (let role of whoAmIDetails.roles) {
          if (role['name'] === 'ROLE_ADMIN') {
            localStorage.setItem('isLogged', 'False');
            localStorage.removeItem('cartID');
            let a = document.createElement('a');
            a.href = '/home/' + this.tenantValue;
            a.click();
          }
        }
      });
    }
  }

  checkPrivilege(privilegeList) {
    let _activePrivilegesList = localStorage.getItem('PRIVILEGES_LIST');
    for (let privilege of privilegeList) {
      if (_activePrivilegesList.includes(privilege)) {
        return true;
      }
    }
    return false;
  }

  orderIdLengthTrim(orderId, trimLength) {
    if (orderId !== undefined && orderId !== null) {
      return orderId.substring(orderId.length - trimLength);
    }
    return '';
  }

  setModel(mod: NgbModal) {
    this.closeModel = mod;
    this.model.emit(this.closeModel);
  }

  close() {
    this.closeModel.dismissAll();
  }

}
