import {Component, OnInit} from '@angular/core';
import {InteractionService} from '../../../../../../service/interaction.service';

@Component({
  selector: 'app-plant-machinery-scheme',
  templateUrl: './plant-machinery-scheme.component.html',
  styleUrls: ['./plant-machinery-scheme.component.css']
})
export class PlantMachinerySchemeComponent implements OnInit {
  activeLang: any;

  constructor(private interactionService: InteractionService) {
  }

  ngOnInit(): void {
    this.activeLang = sessionStorage.getItem('LANG');
    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });
  }

}
