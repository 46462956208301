<div class="row">
  <div class="col-lg-12">
    <div class="pro-right-col blockdiv">
      <div class="row">
        <div class="col-lg-12">
          <div class="row pip-title">
            <div class="col-8">
              <h2>{{'MY_ACCOUNT.SALES.SALES.SALES' | translate}}</h2>
            </div>
            <div class="col-12">
              <div class="separator">&nbsp;</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="width: 100px;">
              <div>
                <mat-form-field class="matsearch">
                  <input matInput placeholder="{{'SEARCH_BAR' | translate}}" [(ngModel)]="searchValue"  (keyup)="applyFilter($event.target.value)">
                </mat-form-field>

                <form style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto;overflow-x: scroll;">
                  <table class="table salestable" mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="orderID">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                        {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.TRANSACTION_ID' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        {{this.commonService.orderIdLengthTrim(row.transactionId, transactionTrimValue)}}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="pro_name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table tbl td">
                        {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.PRODUCT_NAME' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        <div class="d-flex flex-row align-items-center mt-2 mb-2">
                          <div style="width: 50px;">
                            <img *ngIf="row.productType !== 'ORDER_REQUEST'"
                                 style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;" alt=""
                                 src="{{this.mainUrl.MAIN_URL_FILE +'thumbnail/'+ row.image + '/?X-TenantID=' + this.tenantValue}}"/>
                            <div style="width: 50px;" *ngIf="row.productType === 'ORDER_REQUEST'">
                              <img style="border-radius: 10px;width: 50px; height: 50px;object-fit: cover;" alt=""
                                   src="{{setOrderRequestImage(row.image)}}"/>
                            </div>
                          </div>
                          <div class="rightdiv">
                            <h4>{{row.productName}}</h4>
                            <p>{{row.id}}</p>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="pro_price">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'MY_ACCOUNT.PURCHASES.MY_PURCHASES.UNIT_PRICE' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        {{'CURRENCY.CURRENCY' | translate}} {{row.unitPrice  | number:'2.2-2'}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="buyer">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'MY_ACCOUNT.SALES.SALES.BUYER' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        {{row.buyerName}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="qty">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'MY_ACCOUNT.SALES.SALES.QUANTITY' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        {{row.quantity}}{{row.productUnitType}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="productType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'MY_ACCOUNT.SALES.MY_PRODUCTS.PRODUCT_TYPE' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                          <span *ngIf="row.productType === 'SPOT'">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.SPOT_BUYING' | translate}}
                          </span>
                        <span *ngIf="row.productType === 'AUCTION'">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.AUCTION' | translate}}
                           </span>
                        <span *ngIf="row.productType === 'ORDER_REQUEST'">
                            {{'ORDER_REQUEST' | translate}}
                           </span>
                      </td>
                    </ng-container>
<!--                    <ng-container matColumnDef="farmerType">-->
<!--                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">-->
<!--                        {{'MY_ACCOUNT.SALES.ORDERS.SELLER_TYPE' | translate}}-->
<!--                      </th>-->
<!--                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">-->
<!--                        {{row.farmerType}}-->
<!--                      </td>-->
<!--                    </ng-container>-->
<!--                    <ng-container matColumnDef="seller">-->
<!--                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">-->
<!--                        {{'MY_ACCOUNT.SALES.ORDERS.SELLER_NAME' | translate}}-->
<!--                      </th>-->
<!--                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">-->
<!--                        {{row.seller}}-->
<!--                      </td>-->
<!--                    </ng-container>-->

                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'PRODUCT_VIEW_FORWARD.TOTAL_PRICE' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        {{'CURRENCY.CURRENCY' | translate}} {{row.totalPrice | number:'2.2-2'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'MY_ACCOUNT.SALES.SALES.DATE' | translate}}</th>
                      <td mat-cell class="center-table tbl" style="padding-left: 0;" *matCellDef="let row">
                        {{convertDate(row.created)}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table centertd tbl td">
                        {{'MY_ACCOUNT.SALES.SALES.STATUS' | translate}}
                      </th>
                      <td mat-cell class="center-table tbl" *matCellDef="let row">
                        <img alt="" src="../../../../../assets/images/{{manageStatus(row.status)}}.svg">
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" data-toggle="modal"
                        (click)="getOrder(row)" data-target="#vieworder"></tr>
                  </table>

                  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </form>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button id="openModelBtn" style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#detailModal"></button>
<button id="openActionBtn" style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#actionModal"></button>
<button id="RejectBtn" #Reject style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#Reject"></button>

<div aria-hidden="true" aria-labelledby="myExtraLargeModalLabel" class="modal fade bd-example-modal-xl"
     data-backdrop="static" data-keyboard="false"
     id="detailModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" style="width:100%;max-height: 700px ; height: auto; overflow-y: scroll;">
      <div class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="w-full-col p-30" id="print-receipt">
                <div class="cfs-title">
                  {{'MY_ACCOUNT.SALES.ORDERS.ORDER_RECEIPT' | translate}}
                </div>
                <div>
                  <div class="row">
                    <div class="col-4">
                      <h4>{{'MY_ACCOUNT.SALES.ORDERS.SHIPPING_ADDRESS' | translate}}</h4>
                      <div class="pc-info-col">
                        <p>
                          <label>{{name}}</label><br>
                          <label>{{address}}</label><br>
                          <label>{{endCity}}</label><br>
                          <label>{{province}}</label><br>
                          <label>{{postalCode}}</label><br>
                          <label>{{country}}</label><br>
                          <label>{{mobile}}</label><br>
                        </p>
                      </div>
                    </div>
                    <div class="col-8">
                      <div style=" display: block; position: relative;text-align: right">
                        <img [src]="qrImage" alt="QR Code" style="width: 120px;"/>
                      </div>
                    </div>

                    <div class="col-12">
                      <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                        <span>{{'MY_ACCOUNT.SALES.ORDERS.ORDER_STATUS' | translate}}<span
                          style="color: green;font-weight: bold">{{manageStatus(status)}}</span></span>
                      </div>
                    </div>

                    <div class="col-12">
                      <div style=" display: block; position: relative;text-align: right;margin-bottom: 1vh">
                        <span>{{'MY_ACCOUNT.SALES.ORDERS.TRANSACTION_ID' | translate}}<span
                          style="color: green;font-weight: bold">{{this.commonService.orderIdLengthTrim(transactionId, transactionTrimValue)}}</span></span>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Cart Table -->
                <div id="table-wrapper">
                  <div id="table-scroll">
                    <table class="table">
                      <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{'MY_ACCOUNT.SALES.ORDERS.PRODUCT_NAME' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.SALES.ORDERS.UNIT_PRICE' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.SALES.ORDERS.QUANTITY' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.SALES.ORDERS.TOTAL' | translate}}</th>
                        <th scope="col">{{'MY_ACCOUNT.SALES.ORDERS.IMAGE' | translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let product of productList;let i = index">
                        <th scope="row">{{i + 1}}</th>

                        <td>{{product.productName}}</td>
                        <td>{{CURRENCY_CODE}}&nbsp;{{product.unitPrice| number:'2.2-2'}}</td>
                        <td>{{product.quantity}}</td>
                        <td>{{CURRENCY_CODE}}&nbsp;{{product.unitPrice * product.quantity | number:'2.2-2'}}</td>
                        <td>
                          <img *ngIf="product.imageDTOS.length > 0" style="width: 30px" alt=""
                               src="{{loadImage(product.imageDTOS[0].image)}}">
                          <img *ngIf="product.imageDTOS.length <= 0" style="width: 30px" alt=""
                               src="assets/defaultBrokenImage.jpg"></td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="cart-summary">
                  <div class="cb-left" style="vertical-align: bottom !important;">
                    <span><img alt="" src="../../../assets/images/icons/cart_b1.png"/>
                      {{'MY_ACCOUNT.SALES.ORDERS.INFO_1' | translate}}</span><br>
                    <span style="margin-bottom:18px !important;"><img src="../../../assets/images/icons/cart_b2.png"
                                                                      alt=""/>
                      {{'MY_ACCOUNT.SALES.ORDERS.INFO_2' | translate}}</span>
                  </div>
                  <div class="cb-right">
                    <dl class="cb-sum">
                      <dt>{{'MY_ACCOUNT.SALES.ORDERS.TOTAL_UNIT_PRICE' | translate}}</dt>
                      <dd>{{CURRENCY_CODE}} {{totalUnitPrice| number:'2.2-2'}}</dd>
                    </dl>
                    <dl class="cb-sum">
                      <dt>{{'MY_ACCOUNT.SALES.ORDERS.SHIPPING_COST' | translate}}</dt>
                      <dd *ngIf="deliveryTotal === 0" style="color: green">FOC</dd>
                      <dd *ngIf="deliveryTotal !== 0">{{CURRENCY_CODE}} {{deliveryTotal| number:'2.2-2'}}</dd>
                    </dl>
                    <dl class="cb-sum total">
                      <dt>{{'MY_ACCOUNT.SALES.ORDERS.GRAND_TOTAL' | translate}}</dt>
                      <dd>{{CURRENCY_CODE}} {{grandTotal| number:'2.2-2'}}</dd>
                    </dl>
                  </div>
                </div>


              </div>
            </div>
            <div class="col-lg-12" style="text-align: right;margin-top:3vh">
              <button [useExistingCss]="true" printSectionId="print-receipt" ngxPrint
                      style="float:left;width: 150px; background: #8BC34A!important; border-radius: 4px; line-height: 42px; font-size: 16px; border-width: 1px; color: #fff; outline: 0;">
                {{'MY_ACCOUNT.SALES.ORDERS.PRINT' | translate}}
              </button>
              <button data-dismiss="modal"
                      style="width: 150px;float: right;background: #4D5A61!important;  border-radius: 4px; line-height: 42px; font-size: 16px; border-width: 1px; color: #fff; outline: 0;">
                {{'MY_ACCOUNT.SALES.ORDERS.CLOSE' | translate}}
              </button>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="myExtraLargeModalLabel" class="modal fade bd-example-modal-xl"
     data-backdrop="static" data-keyboard="false"
     id="actionModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" style="width:50%;max-height: 650px ; height: auto; overflow-y: scroll;">
      <button type="button" class="close" #closeButton data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" style="margin-left: 97%;">&times;</span>
      </button>
      <div class="container">
        <form [formGroup]="approveForm">
          <div class="row">
            <div class="col-lg-12">
              <div class="w-full-col p-30">
                <div class="cfs-title">
                  {{'MY_ACCOUNT.SALES.ORDERS.DECISION_SUBMISSION' | translate}}
                </div>
                <div><textarea formControlName="remark" rows="5"
                               style="border: green;font-size: 16px;width: 100%;background-color: aliceblue;"></textarea>
                </div>
                <div class="bottom-buttons">
                  <button class="btn-b b-decline" (click)="decisionSubmit('REJECT')">
                    {{'MY_ACCOUNT.SALES.ORDERS.REJECT' | translate}}
                  </button>
                  <button class="btn-b b-approve" (click)="decisionSubmit('SHIPPED')">
                    {{'MY_ACCOUNT.SALES.ORDERS.SHIPPING' | translate}}
                  </button>
                  <button class="btn-b b-approve" #success data-dismiss="modal" data-toggle="modal" hidden
                          data-target="#success-modal"></button>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" data-keyboard="false" id="success-modal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-check"></i>
          <h3>Order Shipping Completed.</h3>
          <p>{{'MY_ACCOUNT.SALES.ORDERS.ORDER_STATUS_UPDATED' | translate}}</p>
          <a href="" class="btn-b b-approve" data-dismiss="modal">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>

      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" data-keyboard="false" id="Reject"
     role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="middlebox">
        <div class="successmsg">
          <i class="fa fa-check"></i>
          <h3>{{'MY_ACCOUNT.SALES.ORDERS.REJECTED' | translate}}</h3>
          <p>{{'MY_ACCOUNT.SALES.ORDERS.ORDER_STATUS_UPDATED' | translate}}</p>
          <a href="" class="btn-b b-approve" data-dismiss="modal">{{'ADMIN_PANEL.ONBOARDING.CLOSE' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- order review -->
<div aria-hidden="true" class="modal fade npa-model" data-backdrop="static" data-keyboard="false" id="vieworder"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <button type="button" class="close" #closebutton data-dismiss="modal" aria-label="Close" hidden>
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-content">
      <div class="col-md-12">
        <div class="row order-review">
          <div class="col-md-4 d-flex align-items-center">
            <div style="width: 50px;">
              <img style="border-radius: 10px;width: 157px; height: 183px;object-fit: cover;" alt=""
                   *ngIf="type !== 'ORDER_REQUEST'"
                   src="{{this.mainUrl.MAIN_URL_FILE + image + '/?X-TenantID=' + this.tenantValue}}"/>
              <img style="border-radius: 10px;width: 157px; height: 183px;object-fit: cover;" alt=""
                   *ngIf="type === 'ORDER_REQUEST'"
                   src="{{setOrderRequestImage(category)}}"/>
            </div>
          </div>
          <div class="col-md-8 product-details pr-4">
            <div class="text-right">
              <i #closeButton aria-label="Close" class="material-icons graytext-3" data-dismiss="modal"
                 style="cursor: pointer;font-size: 18px;">close</i>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <h2 class="font-weight-bold graytext m-0">{{prodName}}</h2>
              <p class="order-type">{{'MY_ACCOUNT.SALES.ORDERS.ORDER_TYPE' | translate}} :
                <span *ngIf="type === 'SPOT'">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.SPOT_BUYING' | translate}}
                          </span>
                <span *ngIf="type === 'AUCTION'">
                            {{'PRODUCT_LIST.PREFERRED_BUYING_TYPE.AUCTION' | translate}}
                           </span>
                <span *ngIf="type === 'ORDER_REQUEST'">
                            {{'ORDER_REQUEST' | translate}}
                           </span>
              </p>
            </div>
            <p class="Order-date">{{'OrderDate'|translate}}: {{orderDate  | date: 'yyyy/MM/dd'}}</p>
            <div class="amount">
              <div class="form-group mb-0">
                <label class="graytext m-0">{{'MY_ACCOUNT.SALES.ORDERS.QUANTITY' | translate}}</label>
                <h2 class="graytext-2">
                  {{quantity}}{{unit}}
                </h2>

                <label class="graytext m-0" *ngIf="deliveryId !== null">{{'DeliveryOption'|translate}}</label>

                <div *ngIf="deliveryId === 3">
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{'seller_transport'|translate}}
                    - {{'Fixed_Rate'|translate}}</p>
                </div>

                <div *ngIf="deliveryId === 5">
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{'seller_transport'|translate}}
                    - {{'Fixed_Rate'|translate}}</p>
                </div>

                <div *ngIf="deliveryId === 3 || deliveryId === 5">
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{'DeliveryCharges'|translate}}
                    - {{'CURRENCY.CURRENCY' |
                      translate}}&nbsp;{{deliveryCharges| number:'2.2-2'}}</p>
                </div>
                <div *ngIf="deliveryId === 5">
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{'DeliverareaforFixedRate'|translate}}
                    - {{area}} </p>
                </div>
                <div *ngIf="deliveryId === 2">
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{'BUYER_TRANSPORT'|translate}}</p>
                </div>

                <div>
                  <p class="font-weight-bold p-0 m-0 dark-blue">{{'DELIVERY_DATE'|translate}}
                    : {{convertDate(deliveryDate)}}</p>
                </div>

              </div>
            </div>
            <div class="d-flex mt-3">
              <div class="w-45" style="word-break: break-all;margin-right: 3%;">
                <p class="mb-1">{{'PickupAddress'|translate}}</p>
                <p class="font-weight-bold p-0 m-0 dark-blue">{{pickupAdd}} </p>
              </div>
              <div>
                <p class="mb-1">{{'DeliveryAddress'|translate}}</p>
                <p class="font-weight-bold p-0 m-0 dark-blue">{{addressPur}} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-4">
        <div class="order-review-user">
          <div class="user">
            <div class="user-img">
              <img [src]="sellerImage" alt="" style="height: 80px;width: 80px">
            </div>
            <div class="user-details">
              <h5>{{buyerName}}</h5>
              <small>{{district}}</small>
              <p class="contact-user"><i class="material-icons">contact_phone</i> &nbsp;
                {{contact}}
              </p>
            </div>
          </div>
          <div class="price">
            <h2> {{'CURRENCY.CURRENCY' | translate}} {{amount | number:'2.2-2'}} </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
