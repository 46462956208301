<app-header></app-header>

<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" *ngIf="!loadArray">
        <div class="w-full-col p-30">
          <div class="cfs-title d-flex">
            {{'CART.SHOPPING_CART' | translate}}&nbsp;&nbsp;({{cartItemCount}} )&nbsp;&nbsp;<span
            *ngIf="cartItemCount == 1 ">
              {{'CART.ITEMS' | translate}}</span>
            <div *ngIf="cartItemCount > 1 ">{{'CART.ITEMS' | translate}}</div>
          </div>

          <!-- Cart Table -->
          <div id="table-wrapper">
            <div id="table-scroll">
              <table class="table cart">
                <thead class="thead-dark">
                <tr>
                  <th scope="col">{{'CART.ITEM_DETAILS' | translate}}</th>
                  <th scope="col">{{'CART.PRICE' | translate}}</th>
                  <th scope="col">{{'CART.QUANTITY' | translate}}</th>
                  <th scope="col">{{'CART.STATUS' | translate}}</th>
                  <th scope="col">{{'CART.TOTAL' | translate}}</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of selectItem;let i=index">
                  <td>
                    <dl>
                      <dt>
                        <img class="imageSize" *ngIf="item.productImages.length > 0"
                             src="{{this.mainUrl.MAIN_URL_FILE + item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}"
                             alt=""/>
                        <img class="imageSize" *ngIf="item.productImages.length <= 0"
                             src="assets/defaultBrokenImage.jpg" alt=""/>
                      </dt>
                      <dd>
                        <span *ngIf="item.quantity !== 0">{{item.name}}</span>
                        <span *ngIf="item.quantity === 0" style="text-decoration: line-through;">
                            {{item.name}}
                          </span>
                      </dd>
                    </dl>
                  </td>
                  <td>
                      <span *ngIf="item.quantity !== 0">{{'CURRENCY.CURRENCY' | translate}}
                        <span style="margin-left: .5vw"></span>{{item.unitPrice | number:'2.2-2'}}
                        <span style="font-size: 10px;font-weight: bold">(per {{item.unitName}})</span>
                      </span>
                    <span *ngIf="item.quantity === 0" style="text-decoration: line-through;">
                        {{'CURRENCY.CURRENCY' | translate}}<span style="margin-left: .5vw"></span>{{item.unitPrice|
                      number:'2.2-2'}}
                      <span style="font-size: 10px;font-weight: bold">(per {{item.unitName}})</span>
                      </span>
                  </td>
                  <td>
                    <div class="quantity">
                        <span *ngIf="item.quantity !== 0">
                          <div class="row">
                            <div class="col-4" style="margin-top: 20px">
                              <span [id]="'itemQty'+i">{{item.quantity}}</span>{{item.unitName}}
                            </div>
                            <div class="col-8" *ngIf="item.isSellSmallerQty">
                              <div class="row">
                                <div class="col-12">
                                  <button
                                    (click)="qtyUp(item.cartID,i,item.stock,item.availableTotalQty,item.isSellSmallerQty,item.unitPrice,item.productSku)"
                                    type="button" class="btn btn-light">
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                  </button>
                                </div>
                                <div class="col-12">
                                  <button
                                    (click)="qtyDown(item.cartID,i,item.stock,item.availableTotalQty,item.isSellSmallerQty,item.unitPrice,item.productSku)"
                                    type="button" class="btn btn-light">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                      <span *ngIf="item.quantity === 0" style="text-decoration: line-through;">
                          {{item.quantity}}
                        {{item.unitName}}</span>
                    </div>
                  </td>
                  <td>
                    <span *ngIf="item.quantity !== 0" style="color: green">{{'CART.AVAILABLE' | translate}}</span>
                    <span *ngIf="item.quantity === 0" style="color: red">{{'CART.OUT_STOCK' | translate}}</span>
                  </td>
                  <td>{{'CURRENCY.CURRENCY' | translate}}<span style="margin-left: .5vw"></span>
                    <span [id]="'perItemPrice'+i">{{calItemPrice(item.quantity * item.unitPrice)}}</span>
                  </td>

                  <td>
                      <span style="cursor: pointer!important;" data-toggle="tooltip" data-placement="right"
                            title="Remove" class="fa fa-times" aria-hidden="true"
                            (click)="removeItem(item.productSku)"></span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="cart-summary">
            <div class="cb-left">
              <span><img src="../../../assets/images/icons/cart_b1.png" alt=""/>
                {{'CART.SAFE_SECURE' | translate}}</span>
              <span><img src="../../../assets/images/icons/cart_b2.png" alt=""/>{{'CART.PAYMENT' | translate}}</span>
            </div>
            <div class="cb-right">
              <p class="font-size-11 pb-0 mb-1">
                {{'CART_DESC' | translate}}
              </p>
              <dl class="cb-sum total">
                <dt>{{'CART.GRAND_TOTAL' | translate}}</dt>
                <dd>{{'CURRENCY.CURRENCY' | translate}}<span style="margin-left: .5vw"></span>
                  <span id="grandTotal">{{totalAmount| number:'2.2-2'}}</span>
                </dd>
              </dl>
            </div>
          </div>

          <div class="cart-btn-wrapper mt-5">
            <button [disabled]="isDisabled" class="btn btn-success"
                    style="padding: 10px 20px; color: white; cursor: pointer;"
                    (click)="getInfo()">
              <i class="fa fa-credit-card" aria-hidden="true"></i> &nbsp;
              {{'CART.CHECKOUT' | translate}}
            </button>
          </div>
        </div>
      </div>

      <button #openDeliveryModal data-target="#transport-options" data-toggle="modal" hidden></button>

      <div *ngIf="isEmptyCart" class="emptyClass">
        <img src="assets\group_852_2x.png" alt="" style="width: 300px;border-radius: 10px;">
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>


<!-- Transport options -->
<div aria-hidden="true" class="modal fade npa-model overflowvisible" data-backdrop="static" id="transport-options"
     role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-1000 margin-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_OPTIONS' | translate}}</h5>
        <button #transportOptionsClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="opt-row">
          <div class="transport-opt row" *ngFor="let item of deliveryOptionProductList;index as i">
            <div class="products-set pset-a col-md-4 col-xs-12">
              <div class="c-products-sum">

                <div class="dcp-item pro-items d-flex align-items-start">
                  <div class="dcp-img">
                    <img
                      src="{{this.mainUrl.MAIN_URL_FILE + item.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}"
                      alt=""/>
                  </div>
                  <div class="dcp-info">
                    <h6 class="p-name">{{item.name}}</h6>
                    <span class="p-qty">{{item.quantity}}{{item.unitName}} x {{'CURRENCY.CURRENCY' | translate}}
                      {{item.unitPrice| number:'2.2-2'}} </span>
                    <p>
                      <span class="u-item">{{'CURRENCY.CURRENCY' | translate}}&nbsp;
                        {{calItemPrice(item.quantity * item.unitPrice)}}</span> <br/>
                      <span class="u-loc breakall">{{item.location}}</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <hr>
            <div class="products-set col-md-8 col-xs-12 transportmethods" id="nav-tabContent">
              <div class="insidepaddings">
                <mat-radio-group aria-label="Select an option">


                  <!-- riyaviru transport -->
                  <div class="buyertransport rb-cl-wp" data-target="#riyaviruModal" data-toggle="modal"
                       [ngClass]="{'active':selectedOption==item.productSku+1}"
                       (click)="clickRiyaviru(item,item.productSku)">
                    <mat-radio-button [id]="item.productSku+1" [value]="100+1" class="tos-radio"
                                      (change)="checkVal($event,item.productSku,1)">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'RIYAVIRU_TRANSPORT1' | translate}}</h5>
                          <p>{{'RIYAVIRU_TRANSPORT2' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <!-- buyer transport -->
                  <div class="buyertransport rb-cl-wp"
                       [ngClass]="{'active':selectedOption==item.productSku+2}">
                    <mat-radio-button [id]="item.productSku+2" [value]="100+2" class="tos-radio"
                                      (change)="checkVal($event,item.productSku,2)"
                                      (click)="selectedOption=item.productSku+2">
                      <div class="toptile">
                        <div class="t-title">
                          <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_B' | translate}}</h5>
                          <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.ORDER_IS_RESPONSIBLE' | translate}}</p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>

                  <div *ngFor="let trn of item.transport.deliveryOpt;index as k">

                    <!-- Train transport -->
                    <div class="toptile">
                      <div class="t-title">
                        <div class="ifrailtransport rb-cl-wp" [ngClass]="{'active':selectedOption==item.productSku+4}"
                             *ngIf="trn.deliveryOpt==='4'">
                          <mat-radio-button [value]="k+i" class="tos-radio" [id]="item.productSku+4"
                                            (change)="checkVal($event,item.productSku,4)"
                                            (click)="selectedOption=item.productSku+4">
                            <div class="toptile d-flex justify-content-between">
                              <div class="t-title">
                                <h5>Transport Method (Rail Freight Transport) </h5>
                                <p>Select the nearest train station to you for the pickup</p>
                              </div>
                              <div class="d-flex align-items-center rail">
                                <span class="material-icons"> train</span>
                                <p class="font-bold">Rail Freight Transport</p>
                              </div>
                            </div>
                            <div class="pickup-dropof row">
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Pickup Station</label>
                                <input name="pickup" class="form-control" style="width: 50%;" type="text" disabled
                                       value="item.transport.station">
                              </div>
                              <div class="form-group col-lg-12">
                                <label style="font-weight: bold!important;">Drop Station <span
                                  class="floatright">Estimated
                                    arrival time: 7-8 hours</span></label>
                                <select style="width: 50%;"
                                        class="form-control province" id="railway">
                                  <option disabled>
                                    Select a railway station
                                  </option>
                                  <option *ngFor="let station of railwayList" [value]="station.id">
                                    {{station.descriptionEng}}
                                  </option>

                                </select>
                              </div>
                            </div>

                            <p>Please note that the <strong>Arrival Times</strong> can be vary depending on the weather
                              conditions and other unforeseeable factors. <br>If the delivery is considerably late, or
                              if
                              you need
                              more information, please feel free to <a href="">contact us.</a></p>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>

                    <!-- seller transport -->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='3'"
                         [ngClass]="{'active':selectedOption==item.productSku+3}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k+i" class="tos-radio" [id]="item.productSku+3"
                                            (change)="checkVal($event,item.productSku,3)"
                                            (click)="selectedOption=item.productSku+3">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_DELIVER' | translate}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGE' |
                              translate}}{{'CURRENCY.CURRENCY' |
                              translate}} {{trn.deliveryCharges| number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{item.transport.sellerName}}</h5>
                          <p>{{item.transport.sellerLocation}}</p>
                        </div>
                      </div>
                      <i class="fa fa-truck"></i>
                      &nbsp;{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_PAID_S' | translate}}
                    </div>

                    <!-- seller transport fixed price-->
                    <div class="sellertransport rb-cl-wp" *ngIf="trn.deliveryOpt==='5'"
                         [ngClass]="{'active':selectedOption==item.productSku+5}">
                      <div class="toptile">
                        <div class="t-title">
                          <mat-radio-button [value]="k+i" class="tos-radio" [id]="item.productSku+5"
                                            (change)="checkVal($event,item.productSku,5)"
                                            (click)="selectedOption=item.productSku+5">
                            <h5>{{'PRODUCT_VIEW.DELIVERY_METHOD.TRANSPORT_METHOD_S' | translate}}</h5>
                            <p>{{'PRODUCT_VIEW.DELIVERY_METHOD.SELLER_WILL_DELIVER' | translate}}</p>
                            <p>{{trn.deliveryDesc}}</p>
                            <p class="dcharges">{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_FIX' | translate}}
                              {{'CURRENCY.CURRENCY' | translate}} {{trn.deliveryCharges | number: '2.2-2'}}</p>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="seller-details">
                        <div class="profile-img">
                          <img src="https://www.gravatar.com/avatar?d=mp" alt="">
                        </div>
                        <div class="sellerinfo">
                          <h5>{{item.transport.sellerName}}</h5>
                          <p>{{item.transport.sellerLocation}}</p>
                        </div>
                      </div>
                      <i class="fa fa-truck"></i>
                      &nbsp;{{'PRODUCT_VIEW.DELIVERY_METHOD.DELIVERY_CHARGES_PAID_S' | translate}}

                    </div>

                  </div>
                </mat-radio-group>


              </div>

            </div>
          </div>
        </div>

        <div class="pm-pop-bottom col-12">
          <div class="form-group btn-sections">
            <a aria-label="Close" class="btn cancel-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.CANCEL_B' | translate}}</a>
            <a (click)="CheckOut()" aria-label="Next" class="btn next-btn-half" data-dismiss="modal">
              {{'PRODUCT_VIEW.DELIVERY_METHOD.PROCEED_B' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Riyaviru transport modal -->
<div aria-hidden="true" data-backdrop="static" data-keyboard="false" class="modal overflowvisible fade npa-model"
     id="riyaviruModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><img src="../../../assets/images/riyaviru.png" alt="" class="riyviru">Riyaviru Transport
        </h5>
        <button #closeRiyawiruModal (click)="riyawiruModalShow()" aria-label="Close" class="close" data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="tr-riyaviru">
          <div class="d-flex dblock align-items-center justify-content-between top-bar">
            <div class="dleft d-flex align-items-center">
              <div class="dcp-img">
                <img
                  src="{{this.mainUrl.MAIN_URL_FILE + riyaviruModalData?.productImages[0]?.image + '/?X-TenantID=' + this.tenantValue}}"
                  alt=""/>
              </div>
              <div>
                <h5>{{riyaviruModalData?.name}}</h5>
                <span class="p-qty">{{riyaviruModalData?.quantity}} x {{'CURRENCY.CURRENCY' | translate}}
                  {{riyaviruModalData?.unitPrice| number:'2.2-2'}} </span>
                <p>
                  <span class="u-item">(per {{riyaviruModalData?.unitName}})</span> <br/>
                </p>
              </div>

            </div>
            <div class="dright">
              <p>{{'PRODUCT_WEIGHT'|translate}}
                <span>{{riyaviruModalData?.quantity}}{{riyaviruModalData?.unitName}}</span>
              </p>
            </div>
          </div>
          <div class="pick-option">
            <p><img src="../../../assets/images/icons/vehicle.png" alt=""> &nbsp;{{'PICK_A_VEHICLE'|translate}}</p>
            <div class="vehicle-list row">
              <div class="col-lg-2 v-i-list" *ngFor="let vehicle of riyawiruVehicleTypes;let i = index"
                   (click)="selectVehicleType(vehicle['typeId'],riyaviruModalData?.productSku)"
                   [ngClass]="{'active':selectedId==vehicle['typeId']}" style="margin-bottom: 1vh;">
                <div class="row">
                  <div class="col-12">
                    <div class="v-item">
                      <img src="{{vehicle['icons']?.icon}}" alt="">
                    </div>
                  </div>
                  <div class="col-12 vhclname" style="font-size: 12px;margin-bottom: 1vh;">
                     <span *ngIf="activeLang === 'en'" title=" {{vehicle['name']['nameEN']}}">
                      {{vehicle['name']['nameEN']}}
                    </span>
                    <span *ngIf="activeLang === 'si'" title="{{vehicle['name']['nameSI']}}">
                      {{vehicle['name']['nameSI']}}
                     </span>
                    <span *ngIf="activeLang === 'ta'" title="{{vehicle['name']['nameTM']}}">
                       {{vehicle['name']['nameTM']}}
                      </span>
                  </div>
                </div>

              </div>
            </div>
            <span style="color: red;font-size: 10px" *ngIf="isPickVehicle">{{'please_pick_the_vehicle'|translate}}
              !</span>
          </div>
          <div class="other-info">
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="dleft">
                  <div class="d-flex align-items-start">
                    <span class="material-icons iconred">
                      location_on
                    </span>
                    <div class="l-info">
                      <h5>{{'Item_Pickup_Location'|translate}}:</h5>
                      <p>{{riyaviruModalData?.location}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-bar">
            <div class="row align-items-center">
              <div class="col-md-8 col-xs-12">
                <div class="dleft">
                  <p>{{'My_Expectation'|translate}} ({{'CURRENCY.CURRENCY' | translate}})</p>
                  <input min="1" type="number" class="binput" [(ngModel)]="expectationPrice">
                  <span style="color: red;font-size: 10px"
                        *ngIf="isRequiredExpectation">{{'required_field'|translate}}</span>
                  <small>
                    {{'RIYAWIRU_BIT_REQUEST_TEXT'|translate}}
                  </small>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="dright">
                  <button (click)="sendBidRequest(riyaviruModalData?.productSku,expectationPrice)"
                          class="btn btn-default next" aria-label="Close">
                    {{'SEND_BID_REQUEST'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
