<div class="row">
    <div class="col-lg-12">
        <div class="pro-right-col blockdiv">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row pip-title">
                        <div class="col-8">
                            <h2>My Order Requests</h2>
                        </div>
                        <div class="col-4 text-right"><a data-target="#createOrder" data-toggle="modal"
                                class="btn btn-success"> Create a new order
                                <i aria-hidden="true" class="fa fa-long-arrow-right"></i></a>
                        </div>
                        <div class="col-12">
                            <div class="separator">&nbsp;</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div
                                style="width:100%;max-height: 100vh ; height: auto; overflow-y: auto; overflow-x: auto;">
                                <mat-form-field class="matsearch">
                                    <input id="keyUp" matInput placeholder="Search">
                                </mat-form-field>

                                <table [dataSource]="dataSource" class="table salestable" mat-table matSort
                                    style="width:100%">

                                    <ng-container matColumnDef="orderTitle">
                                        <th mat-header-cell *matHeaderCellDef class="center-table tbl td">
                                            Order Title
                                        </th>
                                        <td *matCellDef="let element" class="center-table tbl" mat-cell
                                            style="padding-left: 0;">
                                            <div class="font-weight-bold">
                                                {{element.orderTitle}}
                                            </div>
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="bids">
                                        <th *matHeaderCellDef class="center-table tbl td" mat-header-ceZZZZZZZZZZZZZZZZZZZZzzll>
                                            Bids
                                        </th>
                                        <td *matCellDef="let element" class="center-table tbl" mat-cell
                                            style="padding-left: 0%;">
                                            {{element.bids}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Fill">
                                        <th *matHeaderCellDef class="center-table tbl td" mat-header-cell>
                                            Fill
                                        </th>
                                        <td *matCellDef="let element" class="center-table tbl" mat-cell
                                            style="padding-left: 0%;">
                                            {{element.Fill}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Created">
                                        <th *matHeaderCellDef class="center-table tbl td" mat-header-cell>
                                            Created
                                        </th>
                                        <td *matCellDef="let element" class="center-table tbl" mat-cell
                                            style="padding-left: 0%;">
                                            {{element.Created}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th *matHeaderCellDef class="center-table tbl td" mat-header-cell>
                                            Status
                                        </th>
                                        <td class="center-table tbl" mat-cell style="padding-left: 0%;">
                                            <img src="../../../../../assets/images/icons/awarded.png" alt="">
                                            <img src="../../../../../assets/images/icons/declined.png" alt="">
                                        </td>
                                    </ng-container>

                                    <tr *matHeaderRowDef="displayedColumns" mat-header-row data-target="#viewbidproduct"
                                        data-toggle="modal">
                                    </tr>
                                    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

                                </table>

                                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- order review -->
<div aria-hidden="true" class="modal fade npa-model" id="viewbidproduct" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-700 margin-0 profile-edit" id="small-modal1" role="document">
        <div class="modal-content" style="padding:30px;">
            <div class="col-md-12">
                <div class="row order-review">
                    <div class="col-md-5 d-flex align-items-center justify-content-center">
                        <div>
                            <img src="../.././../../../assets/images/temp/img-001.png" class="oimg">
                        </div>
                    </div>
                    <div class="col-md-7 product-details pr-4">
                        <div class="product-section">
                            <h2 class="font-weight-bold graytext m-0">Fresh carrots for a restaurant</h2>
                        </div>
                        <div class="bidder0d">
                            <li><span class="bbold">Bidder:</span><span>Jeewan Ratnayaka</span></li>
                            <li><span class="bbold">Date:</span><span>05/05/2020</span></li>
                            <br>
                            <li><span class="bbold">BID:</span></li>
                            <div class="mainli">
                                <h4>LKR 12,400.00</h4>
                                <h6>100 kg / 120 kg</h6>
                            </div>

                            <li><span class="bbold">Delivery:</span><span>Seller Delivery</span></li>
                            <li><span class="bbold">Delivery charges:</span><span>50 LKR per KM</span></li>
                            <li><span class="bbold">Estimated cost:</span><span>850 LKR for 17 km</span></li>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center modal-btns">
                <div class="btn-section">
                    <button class="btn btn-default next" data-dismiss="modal" data-target="#success-modal"
                        data-toggle="modal"><i class="fa fa-check"></i>
                        &nbsp;Award
                    </button>&nbsp;
                    <button class="btn btn-default next redbtn" data-dismiss="modal" data-target="#decline-modal"
                        data-toggle="modal"><i class="fa fa-times"></i>
                        &nbsp;Decline
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- success -->
<div aria-hidden="true" class="modal fade npa-model" id="success-modal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-700 margin-0 profile-edit" role="document">

        <div class="modal-content">
            <div class="middlebox">
                <div class="successmsg">
                    <i class="fa fa-check"></i>
                    <h3>Bid Awarded Successfully</h3>
                    <p>Jeewan’s Bid has been awarded successfully.</p>
                    <a class="btn-b b-approve" data-dismiss="modal">Close</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- declined -->
<div aria-hidden="true" class="modal fade npa-model" id="decline-modal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-700 margin-0 profile-edit" role="document">

        <div class="modal-content">
            <div class="middlebox">
                <div class="successmsg">
                    <i class="fa fa-times redtxt"></i>
                    <h3>Bid Rejected</h3>
                    <p>Jeewan’s Bid has been declined successfully.</p>
                    <a class="btn-b b-approve redbtn" data-dismiss="modal">Close</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- create order -->
<div aria-hidden="true" class="modal fade npa-model" id="createOrder" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-800 margin-0 profile-edit" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    New Order Request
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-buyer-create-new-order></app-buyer-create-new-order>
            </div>
        </div>
    </div>
</div>
