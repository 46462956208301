export class MyBidProductsDTO {
  constructor(
    public orderTitle: any,
    public numberOfBids: any,
    public bidAmount: any,
    public bidPlacementDate: any,
    public bidEndDate: any,
    public status: any) {
  }

}
