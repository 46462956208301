import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../service/product.service';
import {Global} from '../service/global';
import {MainUrlService} from '../service/main-url.service';
import Swal from 'sweetalert2';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {CartService} from '../service/cart.service';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {SystemSettingsService} from '../service/system-settings.service';
import {InteractionService} from '../service/interaction.service';
import {UserRoleService} from '../service/user-role.service';
import {CommonService} from '../common/common.service';
import {Subject} from 'rxjs';
import {ValidationHandlerService} from '../validation-handler/validation-handler.service';

@Component({
  selector: 'app-product-view-foward',
  templateUrl: './product-view-foward.component.html',
  styleUrls: ['./product-view-foward.component.css']
})
export class ProductViewFowardComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject<void>();

  specAttributeRankOrder = [];
  specAttributes = [];
  filstatus: boolean = false; 
  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  spinner: number = 0;
  prodId: any;
  description: any;
  unitPrice: any;
  discount: any;
  location: any;
  listedDate: any;
  expiryDate: any;
  bestBuyDate: any;
  sellInSmallerQuantities: boolean = false;
  name: any;
  availableTotalQty: any;
  img1: any;
  img2: any;
  img3: any;
  img4: any;
  selectQty: any = 1;
  stockAvailable: any;
  installmentCollection: any;
  contractTotalAmount: any;
  categoryName: any;
  subCategoryName: any;
  cartItemCount: any;
  typeId: any;
  typeName: any;
  selectQtyPanel = false;
  hideReviews = false;
  bidEndDate: any;
  CURRENCY_CODE: any;
  hideAllComments = false;
  allComments: any;
  hideAllReply = false;
  replyForm: FormGroup;
  commentForm: FormGroup;
  reportForm: FormGroup;
  commentId: any;
  loadReplyComment: any;
  commonImg: any;
  unitType: any;
  SpecAll: any;
  subCatId: number;
  attributeRanks: any;
  profileId: any;
  SKU: any;
  contractInitialPayment: any;
  contractClosingPayment: any;
  startDate: any;
  addToCart: boolean;
  addToCartAlert: boolean;

  sku: any;

  spinner2: number = 1;
  spinner0: number = 1;

  allCategory: any;
  allNewArrivalCategory: any;
  load = 0;
  avgRating: any;
  tenantValue: any;
  Type: string;
  metadataList: any = [];
  CHECKOUT_ENABLE: boolean = false;
  farmerUsername: any;
  tempMilestones: any = [];

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private cookie: CookieService,
              private route: ActivatedRoute,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cart_service: CartService,
              private router: Router,
              private global: Global,
              private toastr: ToastrService,
              private titleService: Title,
              private setting_service: SystemSettingsService,
              private interactionService: InteractionService,
              private userRoleService: UserRoleService,
              public commonService: CommonService,
              private validationHandlerService: ValidationHandlerService
  ) {
  }

  ngOnInit() {


    this.reportForm = new FormGroup({
      'commentReport': new FormControl(null, Validators.required)
    });

    this.route.queryParams.subscribe(params => {
      this.prodId = params['id'];
      this.typeId = params['typeId'];
      this.typeName = params['typeName'];
      this.tenantValue = this.route.snapshot.paramMap.get('tenant_id');

      this.getSpecByProductId(this.prodId);
      this.fetchAllAdditionalMetaData(this.prodId);

      this.selectQtyPanel = true;
      this.hideReviews = true;
      this.productService.fetchForwardContract(this.prodId).subscribe(result => {
        this.CURRENCY_CODE = this.global.CURRENCY_CODE;
        this.setting_service.fetchAll().subscribe(fetchAll => {
          let tempArray = [];

          tempArray.push(fetchAll);

          for (let settings of tempArray) {
            for (let setting of settings) {

              switch (setting.code) {

                case 'BASE_COLOUR':
                  this.global.BASE_COLOUR = setting.value;

                  if (localStorage.getItem('--primary-color') !== this.global.BASE_COLOUR) {
                    localStorage.setItem('--primary-color', this.global.BASE_COLOUR);
                    document.documentElement.style.setProperty('--primary-color',
                      this.global.BASE_COLOUR);
                  } else {
                    document.documentElement.style.setProperty('--primary-color',
                      localStorage.getItem('--primary-color'));
                  }

                  break;

                case 'BASE_LANGUAGE':
                  this.global.BASE_LANGUAGE = setting.value;
                  localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);

                  if (localStorage.getItem('BASE_LANGUAGE') !== this.global.BASE_LANGUAGE) {
                    localStorage.setItem('BASE_LANGUAGE', this.global.BASE_LANGUAGE);
                  } else {
                    this.global.BASE_LANGUAGE = localStorage.getItem('BASE_LANGUAGE');
                  }

                  break;

                case 'BASE_URL':
                  this.global.BASE_URL = setting.value;
                  localStorage.setItem('BASE_URL', this.global.BASE_URL);

                  if (localStorage.getItem('BASE_URL') !== this.global.BASE_URL) {
                    localStorage.setItem('BASE_URL', this.global.BASE_URL);
                  } else {
                    this.global.BASE_URL = localStorage.getItem('BASE_URL');
                  }
                  break;

                case 'CURRENCY_CODE':
                  this.global.CURRENCY_CODE = setting.value;
                  this.CURRENCY_CODE = setting.value;
                  localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);

                  if (localStorage.getItem('CURRENCY_CODE') !== this.global.CURRENCY_CODE) {
                    localStorage.setItem('CURRENCY_CODE', this.global.CURRENCY_CODE);
                  } else {
                    this.global.CURRENCY_CODE = localStorage.getItem('CURRENCY_CODE');
                  }
                  break;

                case 'LOGO_WHITE':
                  this.global.LOGO_WHITE = setting.value;
                  localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);

                  if (localStorage.getItem('LOGO_WHITE') !== this.global.LOGO_WHITE) {
                    localStorage.setItem('LOGO_WHITE', this.global.LOGO_WHITE);
                  } else {
                    this.global.LOGO_WHITE = localStorage.getItem('LOGO_WHITE');
                  }
                  break;

                case 'MAINTENANCE_MODE':
                  this.global.MAINTENANCE_MODE = setting.value;
                  break;

                case 'CHECKOUT_ENABLE':
                  this.CHECKOUT_ENABLE = setting.value === 'true';
                  break;

              }
            }

          }
        });
        this.stockAvailable = result.futureProductDTOList[0].availableTotalQty;
        for (let loop of result.futureProductDTOList) {
          this.tempMilestones.push(loop);
        }
        this.installmentCollection = this.tempMilestones;
        this.contractTotalAmount = result.futureProductDTOList[0].contractTotalAmount;
        this.contractInitialPayment = result.futureProductDTOList[0].contractInitialPayment;
        this.contractClosingPayment = result.futureProductDTOList[0].contractClosingPayment;
        this.startDate = result.futureProductDTOList[0].startDate;
        this.addToCart = false;
        this.categoryName = result.subCategory.mainCategory.commonIndex.description;
        this.subCategoryName = result.subCategory.commonIndex.description;
        this.subCatId = result.subCategory.code;
        this.name = result.name;
        this.avgRating = result.avgRating;
        this.unitType = result.unit.unit;
        this.location = result.address;
        this.farmerUsername = result.listedBy;
        this.listedDate = this.commonService.convertDate(result.futureProductDTOList[0].created);
        this.expiryDate = this.commonService.convertDate(result.futureProductDTOList[0].expireDate);
        this.bestBuyDate = this.commonService.convertDate(result.futureProductDTOList[0].expireDate);
        this.getSpecAttributeRankOrder(this.subCatId);
        if (result.futureProductDTOList[0].productImages.length > 0) {
          this.img1 = this.mainUrl.MAIN_URL_FILE + result.futureProductDTOList[0].productImages[0].image + '/?X-TenantID=' +
            this.tenantValue;
          this.commonImg = this.img1;
        } else {
          this.commonImg = undefined;
        }
        this.description = result.description;
        if (result.futureProductDTOList[0].productImages.length >= 2) {
          this.img2 = this.mainUrl.MAIN_URL_FILE + result.futureProductDTOList[0].productImages[1].image + '/?X-TenantID=' +
            this.tenantValue;
        } else {
          this.img2 = false;
        }

        if (result.futureProductDTOList[0].productImages.length >= 3) {
          this.img3 = this.mainUrl.MAIN_URL_FILE + result.futureProductDTOList[0].productImages[2].image + '/?X-TenantID=' +
            this.tenantValue;
        } else {
          this.img3 = false;
        }

        if (result.futureProductDTOList[0].productImages.length === 4) {
          this.img4 = this.mainUrl.MAIN_URL_FILE + result.futureProductDTOList[0].productImages[3].image + '/?X-TenantID=' +
            this.tenantValue;
        } else {
          this.img4 = false;
        }

        Swal.close();
      });
      this.fetchAll();
    });

  }

  async addCart() {
    let cartID = localStorage.getItem('cartID');
    if (cartID !== null) {
      this.processing();
      this.common(cartID);
    } else {
      this.processing();
      if (this.selectQty !== undefined) {
        let UUID = this.create_UUID().toString();
        localStorage.setItem('cartID', UUID);
        this.common(UUID);
      }
    }
  }

  common(value) {

    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {

        if (this.SpecAll === 0) {

          if (this.selectQty > 0 && this.selectQty !== null) {

            this.cart_service.findCartByUsername(whoAmIDetails['email'],
              this.tenantValue).subscribe(findCartByUsernameRes => {

              this.commonAddToCart(this.SKU, findCartByUsernameRes.id);

            });

          } else {
            Swal.close();
          }

        } else {
          if (this.specAttributes.length > 0) {
            this.productService.checkSpecAvailability(this.specAttributes, this.profileId,
              this.prodId, this.tenantValue).subscribe(checkSpecAvailability => {
              if (checkSpecAvailability.success) {

                if (this.selectQty > 0 && this.selectQty !== null) {

                  this.cart_service.findCartByUsername(whoAmIDetails['email'],
                    this.tenantValue).subscribe(findCartByUsernameRes => {

                    this.commonAddToCart(checkSpecAvailability.message, findCartByUsernameRes.id);

                  });

                } else {
                  Swal.close();
                }

              } else {
                Swal.close();
              }

            });
          } else {
            Swal.close();
          }

        }


      });
    } else {

      if (this.SpecAll === 0) {
        if (this.selectQty > 0 && this.selectQty !== null) {

          this.cart_service.findCartByOwner(value, this.tenantValue).subscribe(findCartByOwner => {

            this.commonAddToCart(this.SKU, findCartByOwner.id);

          });

        } else {
          Swal.close();
        }
      } else {
        if (this.specAttributes.length > 0) {
          this.productService.checkSpecAvailability(this.specAttributes, this.profileId, this.prodId,
            this.tenantValue).subscribe(checkSpecAvailability => {

            if (checkSpecAvailability.success) {

              if (this.selectQty > 0 && this.selectQty !== null) {

                this.cart_service.findCartByOwner(value, this.tenantValue).subscribe(findCartByOwner => {

                  this.commonAddToCart(checkSpecAvailability.message, findCartByOwner.id);

                });

              } else {
                Swal.close();
              }
            } else {
              Swal.close();
            }

          });
        } else {
          Swal.close();
        }

      }
    }

  }

  commonAddToCart(productSku, id) {

    let qty;

    if (!this.sellInSmallerQuantities) {
      qty = this.availableTotalQty;
    } else {
      qty = this.selectQty;
    }

    let body = {
      'productSku': productSku,
      'quantity': qty
    };
    this.cart_service.addCart(id, body).subscribe(addCart => {
      if (addCart.success) {
        Swal.close();
        this.checkCart();

        if (this.addToCartAlert) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: this.validationHandlerService.itemAddedMessage(sessionStorage.getItem('LANG')),
            showConfirmButton: false,
            timer: 1500
          });
        }

        this.interactionService.callBackHeader('True');

        this.resetForm();

      } else {
        Swal.close();
      }
    }, () => {
      Swal.close();
    });
  }

  create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  processing() {
    Swal.fire({
      title: 'Processing...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  loading() {
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  checkCart() {
    let cartID = localStorage.getItem('cartID');
    if (cartID !== null) {
      this.temp(cartID);
    } else {
      localStorage.setItem('cartItemCount', '0');
    }
  }

  temp(value) {
    this.cart_service.showCartByOwner(value, this.tenantValue).subscribe(result => {
      this.cartItemCount = Object.keys(result).length;
      localStorage.setItem('cartItemCount', this.cartItemCount);
    });
  }

  loadComments() {
    this.productService.fetchAllComments(this.prodId, this.tenantValue).subscribe(fetchAllComments => {
      if (fetchAllComments) {
        if (Object.keys(fetchAllComments).length > 0) {
          this.allComments = fetchAllComments;
          this.hideAllComments = true;
        } else {
          this.hideAllComments = false;
        }
      }
    });
  }

  loadReply(id) {
    this.loadReplyComment = '';
    this.commentId = id;
    this.productService.fetchAllReply(id, this.tenantValue).subscribe(res => {
      if (res) {
        if (Object.keys(res).length > 0) {
          this.loadReplyComment = res;
          this.hideAllReply = true;
        } else {
          this.hideAllReply = false;
        }
      }
    });
  }


  replyComment() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        let _replyCommentBody = {
          'userName': whoAmIDetails['email'],
          'isSeller': true,
          'reply': this.replyForm.value['reply'],
          'comment': {
            'id': this.commentId
          }
        };

        this.productService.replayComment(_replyCommentBody, this.tenantValue).subscribe(replayComment => {
          if (replayComment) {
            this.toastr.success('Done!');
            this.replyForm = new FormGroup({
              'reply': new FormControl('')
            });
            this.loadReply(this.commentId);
            // this.loadComments();
          } else {
            this.toastr.error('Oops!');
          }
        }, () => {
          this.toastr.error('Oops!');
        });
      });
    } else {

      let body2 = {
        'userName': 'GUEST',
        'isSeller': false,
        'reply': this.replyForm.value['reply'],
        'comment': {
          'id': this.commentId
        }
      };
      this.replyForm = new FormGroup({
        'reply': new FormControl('')
      });
      this.productService.replayComment(body2, this.tenantValue).subscribe(replayComment => {
        if (replayComment) {
          this.toastr.success('Done!');

          this.loadReply(this.commentId);
          // this.loadComments();
        } else {
          this.toastr.error('Oops!');
        }
      }, () => {
        this.toastr.error('Oops!');
      });
    }

  }

  submitReview() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        let body1 = {
          'productId': this.prodId,
          'userName': whoAmIDetails['email'],
          'commentDescription': this.commentForm.value['comment']
        };
        this.commentForm = new FormGroup({
          'comment': new FormControl('')
        });
        this.productService.createComment(body1, this.tenantValue).subscribe(createComment => {
          if (createComment) {
            this.toastr.success('Done!');

            this.loadComments();
          } else {
            this.toastr.error('Oops!');
          }
        }, () => {
          this.toastr.error('Oops!');
        });
      });
    } else {

      let body2 = {
        'productId': this.prodId,
        'userName': 'GUEST',
        'commentDescription': this.commentForm.value['comment']
      };

      this.productService.createComment(body2, this.tenantValue).subscribe(res => {
        if (res) {
          this.toastr.success('Done!');
          this.commentForm = new FormGroup({
            'comment': new FormControl('')
          });
          this.loadComments();
        } else {
          this.toastr.error('Oops!');
        }
      }, () => {
        this.toastr.error('Oops!');
      });

    }

  }


  getSpecByProductId(proId) {
    this.productService.getSpecsByProductId(proId, this.tenantValue).subscribe(specList => {
      if (specList) {
        if (Object.keys(specList).length > 0) {
          for (let spec of specList) {
            if (spec.specCode === 'XXX') {
              this.SpecAll = 0;
              return;
            }
          }
          this.SpecAll = specList;

        } else {
          this.SpecAll = 0;
        }
      }
    });
  }

  getSpecAttributeRankOrder(code) {
    this.productService.getSpecsOrderBySubCategory(code, this.tenantValue).subscribe(result => {
      if (result) {
        this.attributeRanks = result;
        for (let loop of result) {
          this.profileId = loop.profile.code;
          this.specAttributeRankOrder.push({
            'specId': loop.spec.code,
            'specOrder': loop.specOrder,
            'attributeId': ''
          });
        }
      }
    });
  }

  fetchAll() {
    this.productService.getActiveProfile().subscribe(profileCode => {
      if (profileCode) {
        this.productService.getAllMainCategoryByProfileId
        (profileCode.code).subscribe(getAllMainCategoryByProfileId => {
          if (getAllMainCategoryByProfileId) {
            this.spinner0 = 0;
            this.allCategory = getAllMainCategoryByProfileId;
            this.CURRENCY_CODE = this.global.CURRENCY_CODE;

            this.productService.fetchAllNewArrivalSpotProducts(35, profileCode.code,
              this.tenantValue).subscribe(fetchAllNewArrivalSpotProducts => {
              if (fetchAllNewArrivalSpotProducts) {
                this.spinner2 = 0;
                this.allNewArrivalCategory = fetchAllNewArrivalSpotProducts;
              }
            });
          }
        });

      }
    });
  }

  fetchAllAdditionalMetaData(prodId) {
    this.productService.fetchAllAdditionalMedataByProductId(prodId, this.tenantValue).subscribe(result => {
      if (result) {

        if (Object.keys(result).length > 0) {
          for (let loop of result) {

            if (loop.metaDataId.visible === true && loop.value !== '' && loop.value !== null) {
              this.metadataList.push(loop);
            }
          }
        }
      }
    });
  }

  resetForm() {
    this.selectQty = 1;
  }


  setType(type: any) {
    switch (type) {
      case 'SPOT':
        return 'spot';

      case 'FORWARD_BN':
        return 'forward';

      default:
        return 'bid';
    }
  }



  submitReportForm() {
    if (this.reportForm.invalid) {
      return;
    }
    let comment = this.reportForm.value['commentReport'];
    Swal.fire({
      title: 'Are you sure, do you want to report?',
      text: 'Comment: ' + comment,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value === true) {
        this.reportFunction(comment);
      }
    });
  }

  reportFunction(comment: string) {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
          let body = {
            'reportedBy': whoAmIDetails['email'],
            'productId': this.prodId,
            'type': this.typeName,
            'commentReport': comment,
            'status': 'PENDING'
          };
          this.callReportFunction(body);
        }
      );
    } else {
      let body = {
        'reportedBy': 'GUEST',
        'productId': this.prodId,
        'type': this.typeName,
        'commentReport': comment,
        'status': 'PENDING'
      };
      this.callReportFunction(body);
    }
  }

  callReportFunction(body) {
    this.productService.reportProduct(body, this.tenantValue).subscribe(result => {
        if (result.success) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Reported Successfully',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, (error1 => {
        Swal.fire({
          position: 'center',
          type: 'warning',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        });
      })
    );
  }

  filterOn(){
    this.filstatus = !this.filstatus;       
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  payNow() {

  }

  back() {
    this.router.navigate(['/products/'+this.tenantValue],{queryParams:{
        $singleViewBack:true
      }});
  }

  viewImg(img, i) {
    this.commonImg = img;

    switch (i) {

      case 1 :

        document.getElementById('img' + 1).style.opacity = '1';
        document.getElementById('img' + 1).style.filter = 'unset';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 2 :

        document.getElementById('img' + 2).style.opacity = '1';
        document.getElementById('img' + 2).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 3 :

        document.getElementById('img' + 3).style.opacity = '1';
        document.getElementById('img' + 3).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 4).style.opacity = '0.5';
        document.getElementById('img' + 4).style.filter = 'grayscale(100%)';

        break;

      case 4 :

        document.getElementById('img' + 4).style.opacity = '1';
        document.getElementById('img' + 4).style.filter = 'unset';

        document.getElementById('img' + 1).style.opacity = '0.5';
        document.getElementById('img' + 1).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 2).style.opacity = '0.5';
        document.getElementById('img' + 2).style.filter = 'grayscale(100%)';

        document.getElementById('img' + 3).style.opacity = '0.5';
        document.getElementById('img' + 3).style.filter = 'grayscale(100%)';

        break;
      default:
        break;

    }

  }

}
