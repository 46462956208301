<app-header></app-header>

<div class="about-main">
  <div class="title-div">
    <h1 class="title">{{'FOOTER.ABOUT_US' | translate}}</h1>
    <h6 class="sub-title" *ngIf="activeLang === 'en'">About Helaviru</h6>
    <h6 class="sub-title" *ngIf="activeLang === 'si'">හෙළවිරු ගැන</h6>
    <h6 class="sub-title" *ngIf="activeLang === 'ta'">லெவிரு பற்றி</h6>
  </div>
  <div class="about-discription">
    <div class="container">
      <div class="row">
        <div class="col-md-5 left-div">
          <img src="../../assets/images/about/logo.png" alt="">
        </div>
        <div class="col-md-7 right-div">
          <h2>{{'FOOTER.ABOUT_US' | translate}}</h2>
          <h6 *ngIf="activeLang === 'en'">Who we are?</h6>
          <h6 *ngIf="activeLang === 'si'">අපි කවුද?</h6>
          <h6 *ngIf="activeLang === 'ta'">நாங்கள் யார்?</h6>
          <hr class="title-underline">
          <p *ngIf="activeLang === 'en'">
            HELAVIRU DIGITAL ECONOMIC CENTER is a Cloud-based Digital Marketplace that facilitates trading
            activities (buying & selling) of Agro-Produce and Commodities among diverse stakeholders on a
            seamlessly connected supply chain. Helaviru Platform will create an ecosystem of digitally connected
            Agro-producers (Farmers & Growers), small-scale collectors, wholesalers, retailers, large consumers
            (supermarkets, food processing industries, hotels, etc.) and Agro-produce exporters to provide them
            with an easy and secure channel to carry out all kinds of commercial and trading activities. In
            addition, diverse service providers such as Transport & Delivery Service providers, Fertilizer
            Suppliers, Plants & Seeds Suppliers, Auxiliary Products and Services Suppliers, Agro-Insurance
            Providers, Banks and Government Institutes too will be connected to facilitate the main stakeholders
            and their commercial transactions on the Platform. It offers immense benefits to the agro and
            farming community in Sri Lanka enabling them to timely sell their products at reasonable prices,
            while substantially improving bottom-line profitability of their cultivation.
            <br>
            Helaviru Platform operates as an independently driven private initiative to help the agrarian
            community of Sri Lanka.
          </p>
          <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
            හෙළවිරු, කෘෂි බෝග සහ ගොවිපල නිෂ්පාදන ආර්ථික මධ්‍යස්ථානයක් වන අතර, එය විවිධ පාර්ශවකරුවන් අතර කෘෂි
            නිෂ්පාදන, ගොවිපල නිෂ්පාදන සහ අනෙකුත් වෙළඳ භාණ්ඩ වල ගැණුම් සහ විකුණුම් සක්‍රීය කිරීම සඳහා නිර්මාණය
            කරන ලද සැපයුම් දාමයකි.
            <br>
            කෘෂි නිෂ්පාදකයින් (ගොවීන් සහ වගාකරුවන්), කෘෂි නිෂ්පාදන එකතු කරන්නන්, තොග වෙළඳුන්, සිල්ලර වෙළඳුන්,
            මහා පරිමාණ පාරිභෝගිකයින් (සුපිරි වෙළඳපොලවල්, ආහාර සැකසුම් කර්මාන්ත, හෝටල් ආදිය) සහ කෘෂි නිෂ්පාදන
            අපනයනකරුවන් යන ඉහතින් සඳහන් වූ විවිධ පාර්ශවකරුවන්ගේ එදිනෙදා වෙළඳ කටයුතු වල කාර්යක්‍ෂමතාවය සහ
            සුරක්ෂිතතාවය මෙමගින් තහවුරු කරයි. ප්‍රධාන පාර්ශවකරුවන්ට, ඔවුන්ගේ කාර්යයන් සහ වාණිජ ගණුදෙනු පහසු
            කිරීමට උපකාරී වන අනෙකුත් සේවා ලෙස, ප්‍රවාහන හා බෙදාහැරීමේ සේවා සපයන්නන්, පොහොර සැපයුම්කරුවන්, පැලෑටි
            සහ බීජ සැපයුම්කරුවන්, සහායක නිෂ්පාදන හා සේවා සැපයුම්කරුවන්, කෘෂි රක්‍ෂණ සේවා සපයන්නන්, බැංකු සහ
            රාජ්‍ය ආයතන වැනි විවිධ සේවා සපයන්නන් ද මෙම ආර්ථික මධ්‍යස්ථානය මගින් සම්බන්දීකරණය වෙයි. එමඟින් ශ්‍රී
            ලංකාවේ කෘෂි හා ගොවි ප්‍රජාවට ඉමහත් ප්‍රතිලාභ ලබා දෙන අතර ඔවුන්ගේ නිෂ්පාදන කාලානුරූපව සාධාරණ මිලකට
            අලෙවි කිරීමට හැකි වන අතර ඔවුන්ගේ වගාවේ පහළ මට්ටමේ ලාභදායීතාවය සැලකිය යුතු ලෙස වැඩි දියුණු කරයි.
            හෙළවිරු වේදිකාව ශ්‍රී ලංකාවේ ගොවිජන ප්‍රජාවට උපකාර කිරීම සඳහා ස්වාධීනව ක්‍රියාත්මක වන පෞද්ගලික
            මුලපිරීමක් ලෙස ක්‍රියාත්මක වේ.
          </p>
          <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
            ஹெலவிரு டிஜிட்டல் எகனாமிக் சென்டர் என்பது கிளவுட் அடிப்படையிலான டிஜிட்டல் சந்தையாகும், இது வேளாண்
            உற்பத்தி மற்றும் பொருட்களின் வர்த்தக நடவடிக்கைகளை (வாங்குதல் மற்றும் விற்பனை செய்தல்) பல்வேறு
            பங்குதாரர்களிடையே தடையின்றி இணைக்கப்பட்ட விநியோகச் சங்கிலியில் உதவுகிறது. டிஜிட்டல் முறையில்
            இணைக்கப்பட்ட வேளாண் உற்பத்தியாளர்கள் (விவசாயிகள் மற்றும் விவசாயிகள்), சிறிய அளவிலான சேகரிப்பாளர்கள்,
            மொத்த விற்பனையாளர்கள், சில்லறை விற்பனையாளர்கள், பெரிய நுகர்வோர் (பல்பொருள் அங்காடிகள், உணவு
            பதப்படுத்தும் தொழில்கள், ஹோட்டல்கள் போன்றவை) மற்றும் வேளாண் உற்பத்தி ஏற்றுமதியாளர்களின்
            சுற்றுச்சூழல் அமைப்பை ஹெலவிரு தளம் உருவாக்கும். அனைத்து வகையான வணிக மற்றும் வர்த்தக நடவடிக்கைகளையும்
            மேற்கொள்ள எளிதான மற்றும் பாதுகாப்பான சேனல். கூடுதலாக, போக்குவரத்து மற்றும் விநியோக சேவை
            வழங்குநர்கள், உர சப்ளையர்கள், தாவரங்கள் மற்றும் விதை சப்ளையர்கள், துணை தயாரிப்புகள் மற்றும் சேவைகள்
            சப்ளையர்கள், வேளாண் காப்பீட்டு வழங்குநர்கள், வங்கிகள் மற்றும் அரசு நிறுவனங்கள் போன்ற பல்வேறு சேவை
            வழங்குநர்களும் முக்கிய பங்குதாரர்களுக்கும் அவர்களின் வணிக பரிவர்த்தனைகளுக்கும் வசதியாக
            இணைக்கப்படுவார்கள். மேடையில். இது இலங்கையில் உள்ள வேளாண் மற்றும் விவசாய சமூகத்திற்கு மகத்தான
            நன்மைகளை அளிக்கிறது, சரியான நேரத்தில் தங்கள் தயாரிப்புகளை சரியான விலையில் விற்க உதவுகிறது, அதே
            நேரத்தில் அவர்களின் சாகுபடியின் கீழ்நிலை லாபத்தை கணிசமாக மேம்படுத்துகிறது.
            <br>
            இலங்கையின் விவசாய சமூகத்திற்கு உதவுவதற்காக சுயாதீனமாக இயக்கப்படும் தனியார் முயற்சியாக ஹெலவிரு தளம்
            செயல்படுகிறது.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="about-messages">
    <div class="container">
      <div class="messages-title-div">
        <h2 *ngIf="activeLang === 'en'">Messages</h2>
        <h2 *ngIf="activeLang === 'si'">පණිවිඩ</h2>
        <h2 *ngIf="activeLang === 'ta'">செய்திகள்</h2>
        <hr class="title-underline">
      </div>
    </div>
    <div class="container">
      <div class="row mtb">
        <div class="col-md-4 img-div">
          <img src="../../assets/images/about/nayana-dehigama.png" alt="Nayana Dehigama">
          <div class="user-title">
            <h4 *ngIf="activeLang === 'en'">Dr. Nayana Dehigama</h4>
            <h4 *ngIf="activeLang === 'ta'">டாக்டர் நயனா தேஹிகாமா</h4>
            <h4 *ngIf="activeLang === 'si'">ආචාර්ය නයන දෙහිගම</h4>
            <p *ngIf="activeLang === 'en'">Executive Chairman - Epic Technology Group</p>
            <p *ngIf="activeLang === 'si'">විධායක සභාපති - එපික් ටෙක්නොලොජීස් සමුහය</p>
            <p *ngIf="activeLang === 'ta'">நிர்வாகத் தலைவர் - காவிய தொழில்நுட்பக் குழு</p>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center">
          <div class="message">
            <h5 *ngIf="activeLang === 'en'">A message from Dr. Nayana Dehigama</h5>
            <h5 *ngIf="activeLang === 'si'">ආචාර්ය නයන දෙහිගමගෙන් පණිවිඩයක්</h5>
            <h5 *ngIf="activeLang === 'ta'">திரு. ரோஹன் பெர்னாண்டோவின் செய்தி</h5>
            <h6 *ngIf="activeLang === 'en'">Executive Chairman - Epic Technology Group</h6>
            <h6 *ngIf="activeLang === 'si'">විධායක සභාපති - එපික් ටෙක්නොලොජීස් සමුහය</h6>
            <h6 *ngIf="activeLang === 'ta'">நிர்வாகத் தலைவர் - காவிய தொழில்நுட்பக் குழு</h6>
            <p *ngIf="activeLang === 'en'">
              Our innovative Helaviru Digital Platform was created with the intention of effectively eliminating
              the
              current burning issues faced by the Agro-producers and Farmers especially with regards to the
              difficulties
              in finding proper and timely markets to sell their produce at a reasonable price. It will also help
              to
              eliminate the unwarranted manipulations by the middlemen involved in the trading process between
              farm-gate
              to the plate, and increase the revenue and profitability of the Agro-producers, while reducing the
              cost to
              the end consumers. Our Digital Platform will no doubt significantly reduce the wastage of the scarce
              Agro
              and Farm produce too by facilitating the stakeholders to sell their produce directly and on-time to
              the end-buyers conveniently.
              In that sense, we believe that our HELAVIRU Platform will address a national need of the hour as it
              provides an easy, fast,
              and secure channel for the unprivileged farmers and growers to find the right market that they can
              timely sell their produce
              at the best possible price without any difficulties or undue influence by middlemen, while
              significantly reducing waste.
            </p>
            <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
              අපගේ නවෝත්පාදක හෙළවිරු ඩිජිටල් ආර්ථික මධ්‍යස්ථානය නිර්මාණය කරන ලද්දේ කෘෂි නිෂ්පාදකයින් සහ ගොවීන් මුහුණ
              දෙන වර්තමාන ගැටලු ඵලදායී ලෙස තුරන් කිරීමේ අරමුණින් ය. විශේෂයෙන්ම ඔවුන්ගේ නිෂ්පාදන සාධාරණ මිලකට විකිණීම
              සඳහා නිසි සහ කාලෝචිත වෙළඳපොලවල් සොයා ගැනීමේ දුෂ්කරතා වැනි ගැටලු සම්බන්ධයෙන්ය. ගොවියා සහ පාරිභෝගිකයා
              අතර වෙළඳ ක්‍රියාවලියට සම්බන්ධ වන අතරමැදියන්ගේ අනවශ්‍ය ක්‍රියාවන් අවම කිරීම, කෘෂි නිෂ්පාදකයින්ගේ ආදායම සහ
              ලාභදායීතාවය ඉහළ නැංවීම සහ පාරිභෝගිකයින්ගේ පිරිවැය අඩු කිරීමටද හෙලවිරු ආර්ථික මධ්‍යස්ථානය උපකාරී වනු ඇත.
              අපගේ ඩිජිටල් වේදිකාව මඟින් කෘෂි හා ගොවිපල නිෂ්පාදන නාස්තිය සැලකිය යුතු ලෙස අවම කරනු ඇති අතර පාර්ශවකරුවන්ට
              තම නිෂ්පාදන සෘජුවම හා නියමිත වේලාවට අවසන් ගැනුම්කරුවන්ට පහසුවෙන් විකිණීමට හැකි වනු ඇත. ඒ අනුව, හෙළවිරු
              වේදිකාව කාලින ජාතික අවශ්‍යතාවයක් සපුරාලනු ඇතැයි අපි විශ්වාස කරන කරන අතරම වරප්‍රසාද නොලද ගොවීන්ට සහ
              වගාකරුවන්ට තම නිෂ්පාදන කාලෝචිත ලෙස හොඳම මිලට අලෙවි කළ හැකි නිවැරදි වෙළඳපොලක් සොයා ගැනීම පහසු කරවන,
              වේගවත් හා ආරක්ෂිත නාලිකාවක් සපයනු ඇත. නිෂ්පාදන නාස්තිය සැලකිය යුතු ලෙස අඩු කරමින් අතරමැදියන්ගේ කිසිදු දුෂ්කරතාවයකින්
              හෝ අනවශ්‍ය බලපෑමකින් තොරව තම නිෂ්පාදන කාලෝචිත ලෙස උපරිම මිලට අලෙවි කිරීමට හැකි වනු ඇත.
            </p>
            <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
              வேளாண் உற்பத்தியாளர்கள் மற்றும் விவசாயிகள் எதிர்கொள்ளும் தற்போதைய எரியும் சிக்கல்களை திறம்பட
              அகற்றும் நோக்கத்துடன் எங்கள் புதுமையான ஹெலவிரு டிஜிட்டல் இயங்குதளம் உருவாக்கப்பட்டது, குறிப்பாக
              தங்கள் தயாரிப்புகளை நியாயமான விலையில் விற்க சரியான மற்றும் சரியான நேரத்தில் சந்தைகளைக்
              கண்டுபிடிப்பதில் உள்ள சிரமங்கள் குறித்து. பண்ணை வாயிலுக்கு இடையேயான வர்த்தக செயல்பாட்டில்
              ஈடுபட்டுள்ள இடைத்தரகர்களின் தேவையற்ற கையாளுதல்களை அகற்றவும், வேளாண் உற்பத்தியாளர்களின் வருவாய்
              மற்றும் லாபத்தை அதிகரிக்கவும், இறுதி நுகர்வோருக்கு செலவைக் குறைக்கவும் இது உதவும். எங்கள் டிஜிட்டல்
              இயங்குதளம் பற்றாக்குறை வேளாண் மற்றும் பண்ணை உற்பத்திகளின் வீணையும் கணிசமாகக் குறைக்கும் என்பதில்
              சந்தேகமில்லை, பங்குதாரர்கள் தங்கள் தயாரிப்புகளை நேரடியாகவும் சரியான நேரத்திலும் இறுதி
              வாங்குபவர்களுக்கு வசதியாக விற்க உதவுகிறது. அந்த வகையில், எங்கள் ஹெலவீரு இயங்குதளம் ஒரு மணி நேரத்தின்
              தேசிய தேவையை நிவர்த்தி செய்யும் என்று நம்புகிறோம், ஏனெனில் இது தகுதியற்ற விவசாயிகளுக்கும்
              விவசாயிகளுக்கும் சரியான சந்தையை கண்டுபிடிப்பதற்கான எளிதான, வேகமான மற்றும் பாதுகாப்பான சேனலை
              வழங்குகிறது, ஏனெனில் அவர்கள் சரியான நேரத்தில் தங்கள் விளைபொருட்களை சிறந்த முறையில் விற்க முடியும்
              எந்தவொரு சிரமமும் இல்லாமல் அல்லது இடைத்தரகர்களின் தேவையற்ற செல்வாக்கு இல்லாமல் சாத்தியமான விலை,
              கழிவுகளை கணிசமாகக் குறைக்கும்.
            </p>
          </div>
        </div>
      </div>
      <div class="row mtb2">
        <div class="col-md-8 d-flex align-items-center">
          <div class="message">
            <h5 *ngIf="activeLang === 'en'">A message from Mr. Rohan Fernando</h5>
            <h5 *ngIf="activeLang === 'si'">රොහාන් ප්‍රනාන්දු මහතාගෙන් පණිවිඩයක්</h5>
            <h5 *ngIf="activeLang === 'ta'">திரு. ரோஹன் பெர்னாண்டோவின் செய்தி</h5>
            <h6 *ngIf="activeLang === 'en'">Chairman/Director - SLT Mobitel</h6>
            <h6 *ngIf="activeLang === 'si'">සභාපති / අධ්‍යක්ෂ - එස්එල්ටී මොබිටෙල්</h6>
            <h6 *ngIf="activeLang === 'ta'">தலைவர் / இயக்குனர் - எஸ்.எல்.டி மொபிடெல்</h6>
            <p *ngIf="activeLang === 'en'">
              Since time immemorial, agriculture has been the identity of the Sri Lankan people and the backbone
              of our economy. HE President Gotabaya Rajapaksa envisions Sri Lanka’s return to a production
              economy, and SLT-MOBITEL is proud to support the nation as the flag bearer of the Digitized Sri
              Lankan Agri Value Chain by powering the HELAVIRU DIGITAL ECONOMIC CENTER at Helaviru.lk with our
              partners Epic Technology Group.
              The traditional supply chain is highly inefficient, unorganized, and has a high rate of food wastage
              which undermines the hard work of our local farmers. Helaviru.lk will rectify these issues by
              creating a digital marketplace, integrating all stakeholders of the agro-produce and commodities
              supply-chain and minimising wastage, ultimately uplifting Sri Lankan agriculture.
              As the national ICT serviceprovider, SLT-MOBITEL is proud to partner this venture, hosting the
              platform and supporting the farming community with communication solutions and information support,
              enabling them to access and utilise the digital marketplace.
              We welcome all stakeholders to become partners of the HELAVIRU DIGITAL ECONOMIC CENTER and power Sri
              Lanka’s re-emergence as the 'Granary of the East'!
            </p>
            <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
              ඈත අතීතයේ සිටම කෘෂිකර්මාන්තය ශ්‍රී ලාංකික ජනයාගේ අනන්‍යතාවය සහ ආර්ථිකයේ කොඳු නාරටියයි.
              ශ්‍රී ලංකාව නිෂ්පාදන ආර්ථිකයක් කරා නැවත පැමිණීම ජනාධිපති ගෝඨාබය රාජපක්ෂ මහතාගේ අපේක්ෂාව වන
              අතර අපගේ හවුල්කරුවන් වන එපික් ලංකා ආයතනය සමග එක්ව හෙළවිරු ඩිජිටල් ආර්ථික මධ්‍යස්ථානය බල ගැන්වීමෙන්,
              ඩිජිටල්කරනය වූ ශ්‍රී ලංකා කෘෂි වටිනාකම් දාමයේ ධජය දරන්නා ලෙස ජාතියට සහයෝගය දැක්වීමට අවස්තාව ලැබීම
              SLT-MOBITEL අප හට ආඩම්බරයකි. සාම්ප්‍රදායික සැපයුම් දාමය ඉතා කාර්යක්ෂම සහ සංවිධානාත්මක නොවන අතර ඉහළ ආහාර නාස්තියකින් යුක්ත වේ.
              මෙය අපගේ දේශීය ගොවීන්ගේ වෙහෙස මහන්සි වී වැඩ කිරීම අඩපණ වීමට ද හේතු වේ. හෙළවිරු ඩිජිටල් ආර්ථික
              මධ්‍යස්ථානය විසින් ඩිජිටල් වෙළඳපොළක් නිර්මාණය කිරීම, කෘෂි නිෂ්පාදන හා වෙළඳ සැපයුම් දාමයේ සියලුම පාර්ශවකරුවන් ඒකාබද්ධ කිරීම,
              නාස්තිය අවම කිරීම මගින් සහ මෙම ගැටළු නිරාකරණය වීම මගින් අවසානයේදී ශ්‍රී ලංකා කෘෂිකර්මාන්තය නංවාලීම සිදු වනු ඇත.
              ගොවි ප්‍රජාවට සන්නිවේදන විසඳුම් සහ තොරතුරු සහාය ලබා දෙමින් ඔවුන්ට ඩිජිටල් වෙළඳපොළට ප්‍රවේශ වීමට සහාය ලබා දෙමින්
              හෙළවිරු වේදිකාවට සත්කාරය සපයමින්, ජාතික තොරතුරු හා සන්නිවේදන තාක්ෂණ සේවා සපයන්නා ලෙස SLT-MOBITEL මෙම
              ව්‍යාපාරයට හවුල් වීමට ලැබීම ගැන ආඩම්බර වෙමු.
              ශ්‍රී ලංකාව 'නැගෙනහිර ධාන්‍යාගාරය' ලෙස නැවත බලගැන්වීමට එක්වන හෙළවිරු ඩිජිටල් ආර්ථික මධ්‍යස්ථානයේ
              සියළුම හවුල්කරුවන් සහ පාර්ශවකරුවන් සාදරයෙන් පිළිගනිමු.
            </p>

            <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
              பழங்காலத்திலிருந்தே, விவசாயம் என்பது இலங்கை மக்களின் அடையாளமாகவும், நமது பொருளாதாரத்தின்
              முதுகெலும்பாகவும் இருந்து வருகிறது. அவர் தலைவரான கோட்டபய ராஜபக்ஷ, இலங்கை உற்பத்தி பொருளாதாரத்திற்கு
              திரும்புவதைக் கருதுகிறார், மேலும் எங்கள் கூட்டாளர்களான Epic தொழில்நுட்ப குழு.
              பாரம்பரிய விநியோகச் சங்கிலி மிகவும் திறமையற்றது, ஒழுங்கமைக்கப்படாதது மற்றும் அதிக உணவு வீணாக உள்ளது,
              இது நமது உள்ளூர் விவசாயிகளின் கடின உழைப்பைக் குறைமதிப்பிற்கு உட்படுத்துகிறது. டிஜிட்டல் சந்தையை
              உருவாக்குவதன் மூலமும், வேளாண் உற்பத்திகள் மற்றும் பொருட்களின் விநியோகச் சங்கிலியின் அனைத்து
              பங்குதாரர்களையும் ஒருங்கிணைத்து, வீணாகக் குறைப்பதன் மூலமும், இறுதியில் இலங்கை விவசாயத்தை
              மேம்படுத்துவதன் மூலமும் ஹெலவிரு.எல்.கே இந்த சிக்கல்களைச் சரிசெய்யும்.
              தேசிய ஐ.சி.டி சேவை வழங்குநராக, எஸ்.எல்.டி-மொபிடெல் இந்த முயற்சியில் பங்குதாரர் என்பதில் பெருமிதம்
              கொள்கிறது, தளத்தை ஹோஸ்ட் செய்கிறது மற்றும் விவசாய சமூகத்தை தகவல்தொடர்பு தீர்வுகள் மற்றும் தகவல்
              ஆதரவுடன் ஆதரிக்கிறது, டிஜிட்டல் சந்தையை அணுகவும் பயன்படுத்தவும் அவர்களுக்கு உதவுகிறது.
              அனைத்து பங்குதாரர்களும் ஹெலவீரு டிஜிட்டல் பொருளாதார மையத்தின் பங்காளிகளாக மாறுவதை நாங்கள்
              வரவேற்கிறோம், மேலும் இலங்கையின் 'கிழக்கின் களஞ்சியமாக' மீண்டும் தோன்றுவதற்கு சக்தி!
            </p>
          </div>
        </div>
        <div class="col-md-4 img-div">
          <img src="../../assets/images/about/rohan-fernando.png" alt="Rohan Fernando">
          <div class="user-title">
            <h4 *ngIf="activeLang === 'en'">Rohan Fernando</h4>
            <h4 *ngIf="activeLang === 'si'">රොහාන් ප්‍රනාන්දු</h4>
            <h4 *ngIf="activeLang === 'ta'">ரோஹன் பெர்னாண்டோ</h4>
            <p *ngIf="activeLang === 'en'">සභාපති / අධ්‍යක්ෂ - එස්එල්ටී මොබිටෙල්</p>
            <p *ngIf="activeLang === 'si'">සභාපති / අධ්‍යක්ෂ - එස්එල්ටී මොබිටෙල්</p>
            <p *ngIf="activeLang === 'ta'">தலைவர் / இயக்குனர் - எஸ்.எல்.டி மொபிடெல்</p>
          </div>
        </div>

      </div>
      <div class="patner-messages">
        <div class="container">
          <div class="messages-title-div align-items-start">
            <h2 *ngIf="activeLang === 'en'">Messages from our partners</h2>
            <h2 *ngIf="activeLang === 'si'">අපගේ හවුල්කරුවන්ගේ පණිවිඩ</h2>
            <h2 *ngIf="activeLang === 'ta'">எங்கள் கூட்டாளர்களிடமிருந்து செய்திகள்</h2>
            <hr class="title-underline">
          </div>
          <div class="row message">
            <div class="col-md-3">
              <img src="../../assets/images/about/peoples-bank.png" alt="">
            </div>
            <div class="col-md-9 d-flex align-items-center">
              <p *ngIf="activeLang === 'en'">
                Peoples bank is one of the top state-owned commercial banks in Sri Lanka providing banking and treasury
                services for personal, commercial, and corporate. As a strategic partner People’s bank provides tailor
                made loan facilities for Helaviru farmers.
              </p>
              <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                මහජන බැංකුව යනු ශ්‍රී ලංකාවේ පුද්ගලික, වාණිජ හා ආයතනික සඳහා බැංකු හා භාණ්ඩාගාර සේවා සපයන
                ඉහළම රාජ්‍ය වාණිජ බැංකුවකි. උපායමාර්ගික හවුල්කරුවෙකු ලෙස මහජන බැංකුව හෙළවිරු ගොවීන් සඳහා අවශ්‍ය ණය පහසුකම් සපයයි.
              </p>
              <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                தனிநபர், வணிக மற்றும் கார்ப்பரேட் நிறுவனங்களுக்கு வங்கி மற்றும் கருவூல சேவைகளை வழங்கும் இலங்கையில்
                அரசுக்கு சொந்தமான வணிக வங்கிகளில் ஒன்று மக்கள் வங்கி. ஒரு மூலோபாய பங்காளியாக மக்கள் வங்கி ஹெலவிரு
                விவசாயிகளுக்கு தகுந்த கடன் வசதிகளை வழங்குகிறது.
              </p>
            </div>
          </div>
          <div class="row message">
            <div class="col-md-3">
              <img src="../../assets/images/about/SLIC.png" alt="">
            </div>
            <div class="col-md-9 d-flex align-items-center">
              <p *ngIf="activeLang === 'en'">
                SLIC are Sri Lanka Insurance Corporation, the largest state-owned insurer in Sri Lanka anchored to a
                pioneering legacy of financial stability and trust spanning over five decades.
                Established in 1962 as a state owned corporation and the pioneer insurer in the country , Sri Lanka
                Insurance today manages an asset base of over LKR 211 billion which is the largest in the industry, the
                largest life insurance fund in the local insurance industry amounting in excess of LKR 116 billion, and
                a LKR 6 billion strong capitalization, making us one of the most secure and reliable insurance solutions
                providers in the country.
                SLIC have imbued a culture of financial independence in Sri Lanka, as SLIC successfully continue the
                journey towards achieving our company’s overarching vision to be the trusted insurer to the nation.

              </p>
              <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                SLIC යනු ශ්‍රී ලංකාවේ විශාලතම රාජ්‍ය රක්‍ෂණ සමාගම වන ශ්‍රී ලංකා රක්‍ෂණ සංස්ථාව වන අතර එය දශක පහක් පුරා
                විහිදුණු මූල්‍ය ස්ථායිතාව සහ විශ්වාසය පිළිබඳ පුරෝගාමී උරුමයකට නැංගුරම් ලා ඇත.
                1962 දී රාජ්‍ය සංස්ථාවක් සහ රටේ පුරෝගාමී රක්ෂකයෙකු ලෙස ආරම්භ කරන ලද ශ්‍රී ලංකා රක්ෂණ සමාගම අද වන විට
                ඩොලර් බිලියන 211 කට අධික වත්කම් පදනමක් කළමනාකරණය කරයි. එය කර්මාන්තයේ විශාලතම, දේශීය රක්ෂණ කර්මාන්තයේ
                විශාලතම ජීවිත රක්ෂණ අරමුදල අතිරික්තය බිලියන 116 ක් වන අතර LKR බිලියන 6 ක ශක්තිමත් ප්‍රාග්ධනීකරණයක් මගින්
                අප රටේ වඩාත්ම සුරක්‍ෂිත සහ විශ්වාසදායක රක්‍ෂණ විසඳුම් සපයන්නෙකු බවට පත්විය. ශ්‍රී ලංකා ශ්‍රී ලංකාව තුළ
                මූල්‍ය ස්වාධීනත්වයේ සංස්කෘතියක් ඇති කර ඇති අතර, ජාතියේ විශ්වාසනීය රක්‍ෂකයා බවට පත්වීම සඳහා අපගේ සමාගමේ
                පුළුල් දැක්ම සාක්ෂාත් කර ගැනීමේ ගමන SLIC සාර්ථකව කරගෙන යයි. ශ්‍රී ලංකා රක්ෂණ සමාගම හෙළවිරුඩිජිටල්
                ආර්ථික මද්‍යස්තානයේ රක්ෂණ සේවා හවුල්කරුවා ලෙස අත්වැල් බැඳගනී.

              </p>
              <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                எஸ்.எல்.ஐ.சி இலங்கை இன்சூரன்ஸ் கார்ப்பரேஷன் ஆகும், இது இலங்கையின் மிகப்பெரிய அரசுக்கு சொந்தமான
                காப்பீட்டாளர் ஆகும், இது ஐந்து தசாப்தங்களாக நீடித்திருக்கும் நிதி ஸ்திரத்தன்மை மற்றும் நம்பிக்கையின்
                முன்னோடி மரபுக்கு தொகுக்கப்பட்டுள்ளது.
                1962 ஆம் ஆண்டில் அரசுக்கு சொந்தமான நிறுவனமாகவும், நாட்டின் முன்னோடி காப்பீட்டாளராகவும் நிறுவப்பட்ட
                இலங்கை காப்பீடு இன்று எல்.கே.ஆர் 211 பில்லியனுக்கும் அதிகமான சொத்துத் தளத்தை நிர்வகிக்கிறது, இது
                தொழில்துறையில் மிகப்பெரியது, உள்ளூர் காப்பீட்டுத் துறையில் மிகப்பெரிய ஆயுள் காப்பீட்டு நிதி எல்.கே.ஆர்
                116 பில்லியன், மற்றும் எல்.கே.ஆர் 6 பில்லியன் வலுவான மூலதனம், இது நாட்டின் மிகவும் பாதுகாப்பான மற்றும்
                நம்பகமான காப்பீட்டு தீர்வுகள் வழங்குநர்களில் ஒருவராக திகழ்கிறது.
                SLIC இலங்கையில் நிதி சுதந்திர கலாச்சாரத்தை ஊக்குவித்துள்ளது, ஏனெனில் SLIC தேசத்தின் நம்பகமான
                காப்பீட்டாளராக எங்கள் நிறுவனத்தின் மிக உயர்ந்த பார்வையை அடைவதற்கான பயணத்தை வெற்றிகரமாக தொடர்கிறது.
              </p>
            </div>
          </div>
          <div class="row message">
            <div class="col-md-3">
              <img src="../../assets/images/about/Laugfs.png" alt="">
            </div>

            <div class="col-md-9 d-flex align-items-center">
              <p *ngIf="activeLang === 'en'">
                LAUGFS Supermarkets (Pvt) Ltd Limited has come on board as strategic partner of the ‘Helaviru’
                initiative. As strategic partner, LAUGF supermarkets will give their support and assistance in promoting
                ‘Helaviru’ and popularizing it among agriculture stakeholders in Sri Lanka and will encourage diverse
                stakeholders to actively use the platform for mutual benefit.
              </p>
              <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                සීමාසහිත ලාෆ්ස් සුපර් මාකට්ස් (පුද්) සමාගම ‘හෙලාවිරු’ වැඩසටහනේ උපායමාර්ගික හවුල්කරු ලෙස පැමිණ තිබේ.
                උපායමාර්ගික හවුල්කරුවෙකු ලෙස, ලාෆ් සුපිරි වෙළඳසැල් විසින් ‘හෙලාවිරු’ ප්‍රවර්ධනය කිරීමට සහ එය ශ්‍රී
                ලංකාවේ කෘෂිකාර්මික පාර්ශ්වකරුවන් අතර ප්‍රචලිත කිරීමට සහාය සහ සහාය ලබා දෙන අතර අන්‍යෝන්‍ය ප්‍රතිලාභ සඳහා
                වේදිකාව සක්‍රියව භාවිතා කිරීමට විවිධ පාර්ශ්වකරුවන් දිරිමත් කරනු ඇත.
              </p>
              <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                லாஃப்ஃப்ஸ் சூப்பர் மார்க்கெட்டுகள் (பிரைவேட்) லிமிடெட் லிமிடெட் ‘ஹெலவிரு’ முயற்சியின் மூலோபாய பங்காளியாக
                வந்துள்ளது. மூலோபாய பங்காளியாக, லாஃப்ஃப்ஸ் சூப்பர் மார்க்கெட்டுகள் ‘ஹெலவிரு’வை ஊக்குவிப்பதிலும்,
                இலங்கையில் விவசாய பங்குதாரர்களிடையே பிரபலப்படுத்துவதிலும் தங்கள் ஆதரவையும் உதவியையும் வழங்கும் மற்றும்
                பரஸ்பர நன்மைக்காக மேடையை தீவிரமாக பயன்படுத்த ஊக்குவிக்கும்.
              </p>
            </div>
          </div>
          <div class="row message">
            <div class="col-md-3">
              <img src="../../assets/images/about/BOC.png" alt="">
            </div>
            <div class="col-md-9 d-flex align-items-center">
              <p *ngIf="activeLang === 'en'">
                As Sri Lanka’s foremost Commercial and Government Bank, BOC make significant contribution through
                sustainable financing, philanthropic and social investments whilst building a healthy business for the
                benefit for Sri Lankan community. BOC is the payment partner of Helaviru platform with its reliable and
                secured internet payment gateway.
              </p>
              <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                ශ්‍රී ලංකාවේ ප්‍රමුඛතම වාණිජ හා රජයේ බැංකුව ලෙස, ශ්‍රී ලංකා ප්‍රජාවගේ ප්‍රයෝජනය සඳහා කාර්යක්ෂම ව්‍යාපාරයක්
                ගොඩනඟන අතරම තිරසාර මූල්‍යකරණය, පරාර්ථකාමී හා සමාජ ආයෝජන තුළින් BOC සැලකිය යුතු දායකත්වයක් සපයයි.
                BOC යනු හෙළවිරු වේදිකාවේ ගෙවීම් සහකරු  වන අතර හෙළවිරු වේදිකාවේ විශ්වාසදායක සහ ආරක්ෂිත අන්තර්ජාල ගෙවීම් දොරටුව වේ.
              </p>
              <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                இலங்கையின் முன்னணி வணிக மற்றும் அரசு வங்கியாக, BOC நிலையான நிதி, பரோபகார மற்றும் சமூக முதலீடுகள் மூலம்
                குறிப்பிடத்தக்க பங்களிப்பை அளிக்கிறது, அதே நேரத்தில் இலங்கை சமூகத்தின் நலனுக்காக ஆரோக்கியமான வணிகத்தை
                உருவாக்குகிறது. BOC அதன் நம்பகமான மற்றும் பாதுகாப்பான இணைய கட்டண நுழைவாயிலுடன் ஹெலவிரு தளத்தின் கட்டண
                பங்காளியாகும்.
              </p>
            </div>
          </div>
          <div class="row message">
            <div class="col-md-3">
              <img src="../../assets/images/about/ISB.png" alt="">
            </div>
            <div class="col-md-9 d-flex align-items-center">
              <p *ngIf="activeLang === 'en'" >
                Industrial Services Bureau (ISB) was established as a Statutory Body under the North SLICstern (Wayamba)
                Provincial Council in the mid 1990, with the sponsorship of Konrad Adenauer Stiftung (KAS) of Germany
                with a prime objective of accelerating the industrial development within the Province. Within the period
                of past three decades, SLIC have strived to achieve excellence by diversifying our activities into many
                challenging areas thus now functioning as an autonomous industrial and business services delivering
                institution, based in Kurunegala but encompassing the whole island and internationally as SLICll. Our
                Core Values make us an appealing and outstanding institution dispose to provide high-quality service.
              </p>
              <p *ngIf="activeLang === 'si'" style="font-size: 13px;">
                1990 මැද භාගයේදී, වයඹ පළාත් සභාව යටතේ ව්‍යවස්ථාපිත මණ්ඩලයක් ලෙස ජර්මනියේ කොන්රාඩ් ඇඩිනෝවර් ස්ටිෆ්ටුන්ග්
                අනුග්‍රහය ඇතිව පළාත තුළ කාර්මික සංවර්ධනය වේගවත් කිරීමේ මූලික අරමුණ ඇතිව කාර්මික සේවා කාර්යාංශය පිහිටුවන
                ලදී. පසුගිය දශක තුනක කාලය තුළ, අපගේ ක්‍රියාකාරකම් අභියෝගාත්මක ප්‍රදේශ ගණනාවකට විවිධාංගීකරණය කිරීමෙන්
                විශිෂ්ටත්වය ළඟා කර ගැනීමට අප උත්සාහ කර ඇති අතර, මේ වන විට කුරුණෙගල පදනම් කරගත් ස්වාධීන කාර්මික හා
                ව්‍යාපාරික සේවා සපයන ආයතනයක් ලෙස ක්‍රියාත්මක වන නමුත් මුළු දිවයිනම සහ ජාත්‍යන්තරව ද එය ආවරණය කරයි. අපගේ
                මූලික සාරධර්ම මගින් අපව උසස් තත්ත්වයේ සේවාවක් සැපයීම සඳහා සිත්ගන්නාසුළු හා කැපී පෙනෙන ආයතනයක් බවට පත්
                කරයි.
              </p>
              <p *ngIf="activeLang === 'ta'" style="font-size: 13px;">
                தொழில்துறை சேவைகள் பணியகம் (ஐ.எஸ்.பி) 1990 ஆம் ஆண்டின் நடுப்பகுதியில் வடமேற்கு (வயாம்பா) மாகாண சபையின்
                கீழ் ஒரு சட்டரீதியான அமைப்பாக நிறுவப்பட்டது, ஜெர்மனியின் கொன்ராட் அடினவர் ஸ்டிஃப்டுங் (கேஏஎஸ்)
                நிதியுதவியுடன் மாகாணத்திற்குள் தொழில்துறை வளர்ச்சியை துரிதப்படுத்தும் முக்கிய நோக்கத்துடன் . கடந்த
                மூன்று தசாப்த காலத்திற்குள், எங்கள் நடவடிக்கைகளை பல சவாலான பகுதிகளாக வேறுபடுத்துவதன் மூலம் சிறந்து
                விளங்க நாங்கள் பாடுபட்டுள்ளோம், இதனால் இப்போது குருநேகலை மையமாகக் கொண்ட ஒரு தன்னாட்சி தொழில்துறை மற்றும்
                வணிக சேவைகளை வழங்கும் நிறுவனமாக செயல்பட்டு வருகிறது, ஆனால் முழு தீவையும் சர்வதேச அளவையும் உள்ளடக்கியது.
                எங்கள் முக்கிய மதிப்புகள் உயர்தர சேவையை வழங்குவதற்கான ஒரு ஈர்க்கக்கூடிய மற்றும் சிறந்த நிறுவனத்தை
                உருவாக்குகின்றன.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="about-market">
    <div class="container">
      <div class="col-md-12">
        <div class="market-content">
          <h3 *ngIf="activeLang === 'en'">Digital Market Place</h3>
          <h3 *ngIf="activeLang === 'si'">ඩිජිටල් වෙළඳපොළ</h3>
          <h3 *ngIf="activeLang === 'ta'">டிஜிட்டல் சந்தை இடம்</h3>
          <p class="mb-4" *ngIf="activeLang === 'en'">Brought to you by trusted names of</p>
          <p class="mb-4" *ngIf="activeLang === 'si'">විශ්වාසදායක නම් වලින් ඔබ වෙත ගෙන එන ලදි</p>
          <p class="mb-4" *ngIf="activeLang === 'ta'">இன் நம்பகமான பெயர்களால் உங்களிடம் கொண்டு வரப்பட்டது</p>
          <img class="mt-4" src="../../assets/images/about/logos.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
