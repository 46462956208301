<app-header></app-header>
<div class="container" style="margin-top: 3vh;margin-bottom: 3vh">
  <div class=WordSection1>

    <p align=center style='text-align:center;line-height:115%'><strong><u><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>HELAVIRU AGREEMENT WITH RIYAVIRU TRANSPORT PROVIDERS</span></u></strong></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>This agreement
(hereinafter called the “Agreement”) is made by and between&nbsp;<strong><span
        style='font-family:"Calibri",sans-serif'>YOU (“ Riyaviru Transport
Provider/Rider”) and Epic Green Eco Systems (Private) Limited&nbsp;</span></strong>,
a company incorporated in the Democratic Socialist Republic of Sri Lanka under
the Companies Act No. 07 of 2007 and having its registered office at Epic
Techno-Village, 158/1/A, Kaduwela Road, Thalangama, Battaramulla, 10120, Sri
Lanka (hereinafter referred to as “EPIC”)</span></p>

    <p style='text-align:justify;line-height:115%'><strong><span style='font-size:
11.0pt;line-height:115%;font-family:"Calibri",sans-serif;color:black'>WHEREAS</span></strong><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>&nbsp;EPIC is a specialist in technology solutions and has
developed the Helaviru e-commerce platform and You acknowledge the same and
that EPIC does not provide transportation services, function as a carrier or
agent for the transportation of goods. EPIC is willing to facilitate the
provision of goods transportation services by You relating to the Helaviru
Platform developed by it and named it as “Riyaviru”.  </span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><b><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Facilitation of Services</span></b></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>EPIC grants the Riyaviru
transport providers ( hereinafter referred to as “Rider”) a license that is
non-exclusive and non-transferable, to use the Riyaviru Platform for the
purpose of facilitating goods transportation services for users of the Helaviru
Platform (hereinafter referred to as “Users”) during the term of this
Agreement.</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>EPIC has the
right to update and/or modify the Riyaviru Platform from time to time at its
discretion.</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>You shall not
send spam or unsolicited messages to the Users or inconvenience them in any
manner.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><b><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>No assignment of Intellectual Property Rights</span></b></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Nothing in this
Agreement shall operate to assign or transfer any Intellectual Property Rights
from EPIC to the Rider, or from the Rider to EPIC. &quot;<strong><span
        style='font-family:"Calibri",sans-serif'>Intellectual Property Rights</span></strong>&quot;
means all intellectual property rights wherever in the world, whether
registrable or unregistrable, registered or unregistered, including any
application or right of application for such rights (and these
&quot;intellectual property rights&quot; include copyright and related rights,
database rights, confidential information, trade secrets, know-how, business
names, trade names, trademarks, service marks, passing off rights, unfair
competition rights, patents, petty patents, utility models, semi-conductor
topography rights and rights in designs);</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><b><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Charges and Payments</span></b></p>

    <p style='margin-top:6.0pt;text-align:justify;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC shall be entitled to a certain percentage of the revenue
generated through the trips via the Riyaviru Platform by the Rider, as may be
decided by EPIC at its discretion which percentage shall be communicated to you
from time to time. EPIC shall deduct the said percentage of revenue from each trip
and remit the balance to the Rider within two (2) working days of each
transaction.</span></p>

    <p style='margin-top:6.0pt;text-align:justify;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>&nbsp;</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><b><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Confidentiality Obligations</span></b></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC may disclose the Confidential Information to EPIC's officers,
employees, professional advisers, insurers, agents and subcontractors who have
a need to access the Rider’s Confidential Information for the performance of
their work with respect to this Agreement and who are bound by a written
agreement or professional obligation to protect the confidentiality of the Rider’s
Confidential Information. </span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>&quot;&nbsp;<strong><span
      style='font-family:"Calibri",sans-serif'>Confidential Information</span></strong>&quot;
means any information disclosed by or on behalf of either party during the term
whether disclosed in writing, orally or otherwise, that at the time of
disclosure:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(i) was marked
or described as &quot;confidential&quot;; or</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(ii) should have
been reasonably understood to be confidential.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>This Clause imposes no obligations with respect to Confidential
Information that:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(a) is known
before disclosure under this Agreement and is not subject to any other
obligation of confidentiality;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(b) is or
becomes publicly known through no act or default of either party; or</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(c) is obtained
from a third party in circumstances where such party has no reason to believe
that there has been a breach of an obligation of confidentiality.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>The restrictions in this Clause do not apply to the extent that
any Confidential Information is required to be disclosed by any law or
regulation, by any judicial or governmental order or request.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>4.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>The provisions of this Clause shall continue in force indefinitely
following the termination of this Agreement.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>&nbsp;</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><b><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Data Access and use</span></b></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC access the current location data of the Rider when the application
is running in the foreground (app open and on-screen) of the mobile device if the
Rider enables to do so. </span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>The current location of the Rider is used to show the map to the
pickup location and to the final delivery location of the ride which enables
the App to show the path of the trip to the Rider from the current location. </span></p>

    <p style='margin-top:6.0pt;text-align:justify;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>&nbsp;</span></p>

    <p style='margin-top:6.0pt;text-align:justify;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>&nbsp;</span></p>

    <p style='margin-top:0in;margin-right:0in;margin-bottom:5.0pt;margin-left:.25in;
text-align:justify;text-indent:-.25in;line-height:115%'><b><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Limitations and Exclusions of Liability</span></b></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Nothing in this Agreement will:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(a) limit or
exclude your liability for death or personal injury resulting from negligence;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(b) limit or
exclude your liability for fraud or fraudulent misrepresentation;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(c) limit any
liabilities in any way that is not permitted under applicable law; or</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(d) exclude any
liabilities that may not be excluded under applicable law.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Neither party shall be liable to the other party in respect of any
losses arising out of a Force Majeure Event. &quot;&nbsp;<strong><span
        style='font-family:"Calibri",sans-serif'>Force Majeure Event</span></strong>&quot;
means an event, or a series of related events, that is outside the reasonable
control of the party affected (including failures of the internet or any public
telecommunications network, hacker attacks, denial of service attacks, virus or
other malicious software attacks or infections, power failures, industrial
disputes affecting any third party, changes to the laws, disasters, explosions,
fires, floods, riots, terrorist attacks, wars, curfew, lockdowns and quarantine
measures);</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC shall not be liable to the Rider in respect of any loss of
profits or anticipated savings, EPIC in respect of any loss of revenue or
income, any loss of business, contracts or opportunities.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC shall not be liable for any disruption of service done in
terms of payments, trading, delivery or any other business activity.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC shall not be liable to any end customer in terms of service,
delivery, etc.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>6.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>EPIC makes no representations, warranties, or guarantees as to the
actions or inactions of both Rider and User.  </span></p>

    <p style='margin-top:.2in;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><b><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Force Majeure Event</span></b></p>

    <p style='margin-top:.2in;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>If a Force Majeure Event gives rise to a failure or delay in
either party performing any obligation under this Agreement that obligation
will be suspended for the duration of the Force Majeure Event.</span></p>

    <p style='margin-top:.2in;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>A party that becomes aware of a Force Majeure Event which gives
rise to, or which is likely to give rise to, any failure or delay in that party
performing any obligation under this Agreement, must:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(a) promptly
notify the other; and</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(b) inform the
other of the period for which it is estimated that such failure or delay will
continue.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>7.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>A party whose performance of its obligations under this Agreement
is affected by a Force Majeure Event must take reasonable steps to mitigate the
effects of the Force Majeure Event.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><b><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Termination</span></b></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Either party may terminate this Agreement by giving to the other
party at least 30 days' written notice of termination.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Either party may terminate this Agreement immediately by giving
written notice of termination to the other party if the other party commits a
material breach of this Agreement.</span></p>

    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:5.0pt;margin-left:
.25in;text-align:justify;text-indent:-.25in;line-height:115%'><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Either party may terminate this Agreement immediately by giving
written notice of termination to the other party if:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(a) the other
party:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(i) is
dissolved;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(ii) ceases to
conduct all of its business;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(iii) is or becomes
unable to pay its debts as they fall due;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(iv) is or
becomes insolvent or is declared insolvent; or</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(v) convenes a
meeting or makes or proposes to make any arrangement or composition with its
creditors;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(b) an
administrator, administrative receiver, liquidator, receiver, trustee, manager
or similar is appointed over any of the assets of the other party;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(c) an order is
made for the winding up of the other party, or the other party passes a
resolution for its winding up; or</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(d) if that
other party is an individual:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(i) that other
party dies;</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(ii) as a result
of illness or incapacity, that other party becomes incapable of managing his or
her own affairs; or</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(iii) that other
party is the subject of a bankruptcy petition or order.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><b><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:11.0pt;line-height:115%;font-family:
"Calibri",sans-serif;color:black'>Effects of termination</span></b></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Upon the
termination of this Agreement, all of the provisions of this Agreement shall
cease to have effect. Except to the extent that this Agreement expressly
provides otherwise, the termination of this Agreement shall not affect the
accrued rights of either party. Within 30 days following the termination of
this Agreement for any reason the Rider must pay to EPIC any payments that have
been accrued before the termination of this Agreement without prejudice to the
parties' other legal rights.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><b><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><b><span
      style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>General</span></b></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Entire Agreement</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>This Agreement
contains all covenants, stipulations and provisions agreed by the Parties. No
agent or representative of either Party has authority to make, and the Parties
shall not be bound by or be liable for, any other statement, representation,
promise or agreement not set forth herein.</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Modifications or
Variations: Any modification or variation of the terms and conditions of this
Agreement, as well as any modification or variation in the scope of work, may
only be made by a written agreement duly signed by both the Parties.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Applicable Law</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Applicable Law
means and includes the laws and any other instruments having the force of law
in Sri Lanka as may be issued and in force from time to time. This Agreement
shall be interpreted in accordance with the laws of Sri Lanka.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Survival</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>The clauses of
this Agreement, which by nature are intended to survive termination of this
Agreement, shall remain in effect after such termination.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Settlement of Disputes</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>In case, a
dispute arises between parties, then there would be two ways for resolution of
the dispute under this Agreement viz:</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(i) Amicable
Settlement: Performance of this Agreement is governed by the terms and
conditions of this Agreement. However, at times dispute may arise about any
interpretation of any term or condition of this Agreement, including but not
limited to, the scope of work, the clauses of payments etc. In such a situation
either party to this Agreement may send a written notice of dispute to the
other party. The party receiving the notice of dispute will consider the notice
and respond to it in writing within 30 days after receipt. If that party fails
to respond within 30 days, or the dispute cannot be amicably settled within 30
days following the response of that party, then sub-clause (ii) shall become
applicable.</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>(ii)
Arbitration: Differences and/or disputes remaining unresolved amicably shall be
settled through arbitration in accordance with the rules of Arbitration of the
Sri Lanka National Arbitration Centre and the award made in pursuance thereof
shall be binding on the parties. The venue for Arbitration proceedings shall be
Colombo and the language of the arbitration proceedings and that of all
documents and communications between the Rider and the EPIC shall be English.
Arbitrator(s) shall give reasons for the award and the award shall be binding
on the Rider and EPIC and enforceable at the courts of jurisdiction in Sri
Lanka, in accordance with the provisions of the Arbitration Act in force. The
services under this Agreement shall be continued during the arbitration
proceedings, unless otherwise agreed in writing by the Rider and EPIC or unless
it is proved that the services cannot possibly be continued during the
arbitration proceedings.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Indemnification</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>If any action in
court is brought against EPIC or an agent or an employee or a representative of
EPIC by a Third Party pursuant to this Agreement on account of any
act/omission/negligence on the part of the Rider under this Agreement, or for
any damage or injury caused by the alleged act, omission or negligence on the
part of the User, its agents, representatives or sub-contractors or employees
to any Third Party, the Rider shall in all such cases indemnify and keep EPIC,
its agents, employees and representatives, harmless and indemnified from all
such losses, damages, expenses, etc. arising out of any decrees or Court
orders, etc. or otherwise arising out of any such act or omission.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Notices</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>All notices and
other communications under this Agreement must be in writing, and must either be
mailed by registered post with acknowledgement due or hand delivered with proof
of it having been received or sent by email or fax. If posted, all notices will
be considered as delivered after three days, of the notice having been posted.
If hand delivered, all notices will be considered as delivered, when received
by the party to whom the notice is sent. If the notice is faxed and /or
e-mailed, it would be considered as delivered on the same day; this will be
followed by a delivery of hardcopy within five days. All notices under this
Agreement shall be sent to or delivered at the address as specified by the
parties. A Notice shall be effective when delivered.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Waiver</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Save as where
this Agreement expressly provides, neither party shall be deemed to have waived
any right, power, privilege, or remedy under this Agreement unless such party
shall have delivered to the other party a written waiver signed by an
authorized official of such waiving party. No failure or delay on the part of
either party in exercising any right, power, privilege, or remedy hereunder
shall operate as a waiver, default, or acquiescence thereof. Any waiver on the
part of either party of any right, power, privilege, or remedy hereunder shall
not preclude any other or further exercise thereof. Any single or partial
exercise of any right, power, privileges, or remedy hereunder shall not
preclude any other or further exercise thereof.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Severability</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>If any provision
or condition of this Agreement is prohibited or rendered invalid or
unenforceable, such prohibition, invalidity or unenforceability shall not
affect the validity or enforceability of any other provisions and conditions of
this Agreement or this Agreement as a whole and the remaining provisions of
this Agreement shall remain in full force and effect.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Relationship of Parties</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Each Party is an
independent contractor and not an employee or agent of the other Party. Neither
Party shall have authority to commit or create any liability on the part of the
other in any manner whatsoever. Personnel retained or assigned by one Party to
perform work under this Agreement shall at all times be considered employees,
agents, or contractor of that Party only.</span></p>

    <p style='margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:
115%'><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>10.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
color:black'>Legal Authority</span></p>

    <p style='text-align:justify;line-height:115%'><span style='font-size:11.0pt;
line-height:115%;font-family:"Calibri",sans-serif;color:black'>Either Party has
the legal right and authority to enter into this Agreement and to perform its
obligations under this Agreement.</span></p>

    <p class=MsoNormal style='text-align:justify;line-height:115%'>&nbsp;</p>

  </div>
</div>


<app-footer></app-footer>
