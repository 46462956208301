import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainUrlService} from './main-url.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private http: HttpClient, private  mainUrl: MainUrlService) {

  }

  requestNewOTPCode(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_SMS + '/otp/requestOtpSMPP', body);
  }

  verifyOTPCode(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_SMS + '/otp/checkOTP', body);
  }

  forgetPasswordOTP(param): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_AUTH + '/auth/generateOTP/' + param+'/OTP');
  }

  loadLanguageByUsername(username:any): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_SMS + '/laguageSettings/findByUsername/'+username);
  }

  saveLanguage(body): Observable<any> {
    return this.http.post(this.mainUrl.MAIN_URL_SMS + '/laguageSettings/save/',body);
  }

  getPhoneNo(username: string): Observable<any> {
    return this.http.get(this.mainUrl.MAIN_URL_SMS + '/otp/getPhoneNo/'+username);
  }
}
