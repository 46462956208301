import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ProductService} from '../../service/product.service';
import {MainUrlService} from '../../service/main-url.service';
import {CartService} from '../../service/cart.service';
import {Global} from '../../service/global';
import {UserRoleService} from '../../service/user-role.service';
import {InteractionService} from '../../service/interaction.service';
import {ValidationHandlerService} from '../../validation-handler/validation-handler.service';
import Swal from 'sweetalert2';
import {TransportDetailsDTO} from '../../dto/TransportDetailsDTO';
import {UserTokenDTO} from '../../dto/userTokenDTO';
import {CoreService} from '../../service/core.service';
import {Md5} from 'ts-md5';
import {RiyawiruService} from '../../service/riyawiru.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  allCategory: any;
  selectItem: any;
  selectedOption = 0;
  totalAmount: any = 0;
  cartItemCount: any = 0;
  isEmptyCart = false;
  spinner: number = 1;
  loadArray = false;
  currency: any;
  tenantValue: any = 'wholesale';
  totalDiscount: any;
  total: any;
  attributeColorCode: any;
  station: any;
  seller: any;
  sellerName: any;
  sellerMobile: any;
  sellerLocation: any;
  isTrain: boolean = false;
  isSeller: boolean = false;
  isBuyer: boolean = false;
  imgUrl: any;
  userTokenDTO = new UserTokenDTO;
  transportList: any[];
  railwayList: any;
  transportID: any;
  prdTransportList = [];
  isSellerFixed = false;
  activeLang: any;
  selectedVehicleId: any;
  riyawiruSelectedVehicleType = [];

  @ViewChild('openDeliveryModal') openModal;

  /**
   * @author Sachin Dilshan
   * @version 1.0.0
   */

  constructor(private global: Global,
              private router: Router,
              private productService: ProductService,
              public mainUrl: MainUrlService,
              private cart_service: CartService,
              private riyawiruService: RiyawiruService,
              private userRoleService: UserRoleService,
              private interactionService: InteractionService,
              private validationHandlerService: ValidationHandlerService,
              private coreService: CoreService) {
    this.activeLang = sessionStorage.getItem('LANG');
    this.common();
    this.fetchAll();
    this.deliveryMethod();
    this.getRiyawiruVehicleTypes();
  }


  ngOnInit() {
    this.interactionService.checkoutItemForm$.subscribe(reCall => {
      if (reCall === 'true') {
        this.isDisabled = true;
        this.common();
        this.fetchAll();
      }
    });

    this.interactionService.activeLang$.subscribe(activeResult => {
      if (activeResult) {
        this.activeLang = sessionStorage.getItem('LANG');
      }
    });

  }

  fetchAll() {
    this.productService.getActiveProfileCode().subscribe(code => {
      this.productService.fetchAllCategory(code.code, this.tenantValue).subscribe(result => {
        this.currency = this.global.CURRENCY_CODE;
        this.allCategory = result;
      });
    });
  }

  common() {
    this.selectItem = '';
    this.spinner = 1;

    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.loading();
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        let cartID = localStorage.getItem('cartID');
        if (cartID !== null) {
          this.productService.cartMerge(whoAmIDetails['email'], cartID, this.tenantValue).subscribe(() => {
            this.showCartByUsername();
          }, () => {
            this.loadArray = true;
            this.spinner = 0;
            this.selectItem = '';
            this.isEmptyCart = true;
            Swal.close();
          });
        } else {
          this.showCartByUsername();
        }
      });
    }

  }

  removeItem(productId) {
    this.loading();
    let isLoggedIn = localStorage.getItem('isLogged');
    let cartID = localStorage.getItem('cartID');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.cart_service.deleteItemFromCartUser
        (whoAmIDetails['email'], productId, this.tenantValue).subscribe(deleteItemFromCartUser => {
          if (deleteItemFromCartUser.success) {
            this.commonSuccessBody();
          } else {
            Swal.close();
          }
        }, () => {
          Swal.close();
        });

      });
    } else if (cartID !== null) {
      this.cart_service.deleteItemFromCartOwner
      (cartID, productId, this.tenantValue).subscribe(deleteItemFromCartOwner => {
        if (deleteItemFromCartOwner.success) {
          this.commonSuccessBody();
        }
        Swal.close();
      }, () => {
        Swal.close();
      });
    }
  }

  commonSuccessBody() {
    Swal.fire({
      position: 'center',
      type: 'success',
      title: 'Cart item has been removed!',
      showConfirmButton: false,
      timer: 1500
    });
    Swal.close();
    this.interactionService.refreshCart('true');
    this.common();
  }

  loading() {
    Swal.fire({
      title: this.validationHandlerService.manageLoadingMessage(sessionStorage.getItem('LANG')),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  deliveryOptionProductList: any;

  showCartByUsername() {
    let isLoggedIn = localStorage.getItem('isLogged');
    if (isLoggedIn === 'True') {
      this.userRoleService.whoAmI().subscribe(whoAmIDetails => {
        this.cart_service.showCartByUsername(whoAmIDetails['email'], this.tenantValue).subscribe(cartDetails => {
          if (Object.keys(cartDetails).length > 0) {
            this.isEmptyCart = false;
            this.loadArray = false;
            this.selectItem = Object.values(cartDetails);
            this.cartItemCount = Object.keys(cartDetails).length;
            localStorage.setItem('cartItemCount', this.cartItemCount);

            let arr = [];
            if (this.cartItemCount > 0) {
              for (let cart of cartDetails) {
                if (cart.availableTotalQty !== 0) {
                  arr.push(cart);
                  this.isDisabled = false;
                }
              }
            }

            this.deliveryOptionProductList = arr;

            let _getPricingBody = {
              'userName': whoAmIDetails['email'],
              'couponCode': null,
              'startCity': null,
              'endCity': null
            };

            this.cart_service.getPricingByUsername(_getPricingBody).subscribe(getPricingByUsername => {
              if (getPricingByUsername) {
                this.totalDiscount = getPricingByUsername['totalDiscount'];
                this.totalAmount = getPricingByUsername['grandTotal'];
                this.total = getPricingByUsername['total'];
              }
              Swal.close();
            });

          } else {
            this.showCartByUsernameCommon();
            localStorage.setItem('cartItemCount', '0');
            this.cartItemCount = '0';
          }
        }, () => {
          this.showCartByUsernameCommon();
        });
        this.spinner = 0;

      });
    }
  }

  showCartByUsernameCommon() {
    this.loadArray = true;
    this.selectItem = '';
    this.isEmptyCart = true;
    Swal.close();
  }

  encryptInfoTransport_id: any;
  encryptInfoRiyawiruList: any;

  CheckOut() {
    if (this.selectItem.length !== this.prdTransportList.length) {
      return;
    }
    if (this.prdTransportList.length > 0) {
      let isLoggedIn = localStorage.getItem('isLogged');
      if (isLoggedIn === 'True') {

        this.encryptInfoTransport_id = encodeURIComponent(JSON.stringify(this.prdTransportList));
        this.encryptInfoRiyawiruList = encodeURIComponent(JSON.stringify(this.riyawiruSelectedVehicleType));

        this.router.navigate(['/checkout/' + this.tenantValue],
          {
            queryParams: {
              transport_id: this.encryptInfoTransport_id,
              riyawiruList: this.encryptInfoRiyawiruList
            }
          });
      } else {
        this.popUpLoginFormModal();
      }
    } else {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: this.validationHandlerService.validateDeliveryMethod(sessionStorage.getItem('LANG')),
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  setBase64(code: string): string {
    return btoa(code);
  }

  popUpLoginFormModal() {
    this.interactionService.popUpLoginModal('false');
  }

  loadTab(sku) {
    this.isBuyer = false;
    this.isSeller = false;
    this.isTrain = false;
    this.isSellerFixed = false;
    this.selectedOption = 0;
    this.cart_service.fetchTransportDetails(sku).subscribe(
      (data: TransportDetailsDTO) => {
        this.station = data.station;
        this.seller = data.seller;
        this.sellerName = data.sellerName;
        this.sellerMobile = data.sellerMobile;
        this.sellerLocation = data.sellerLocation;
        if (data.deliveryOpt !== null) {
          for (let opt of data.deliveryOpt) {
            if (opt === 2) {
              this.isBuyer = true;
            }
            if (opt === 3) {
              this.getUser();
              this.isSeller = true;
            }
            if (opt === 4) {
              this.isTrain = true;
            }
            if (opt === 5) {
              this.isSellerFixed = true;
            }
          }
        }
      }
    );
  }

  getInfo() {
    if (!this.isDisabled) {
      this.openModal.nativeElement.click();
    }
    this.loadTab(this.selectItem[0].productSku);
  }

  getUser() {
    this.userTokenDTO.userName = this.seller;
    this.coreService.fetchUserDetailsByUserName(this.userTokenDTO).subscribe(result => {
      if (result) {
        let hashStr;
        if (this.seller !== null) {
          hashStr = Md5.hashStr(this.seller);
        } else {
          hashStr = Md5.hashStr('');
        }
        this.imgUrl = result.imageUrl !== null ? this.mainUrl.MAIN_URL_FILE +
          result.imageUrl + '/?X-TenantID=' + this.tenantValue : 'https://www.gravatar.com/avatar/' + hashStr + '?d=mp';
      }
    });
  }

  deliveryMethod() {
    this.coreService.getDeliveryMethod().subscribe(
      (data) => {
        this.transportList = data;
      }, () => {
        Swal.close();
      }
    );
  }

  checkVal($event, sku, val) {
    for (let delivery of this.prdTransportList) {
      if (sku === delivery.sku) {
        let index = this.prdTransportList.indexOf(delivery);
        if (index !== -1) {
          this.prdTransportList.splice(index, 1);
        }
      }
    }

    for (let delivery of this.riyawiruSelectedVehicleType) {
      if (sku === delivery.sku) {
        let index = this.riyawiruSelectedVehicleType.indexOf(delivery);
        if (index !== -1) {
          this.riyawiruSelectedVehicleType.splice(index, 1);
        }
      }
    }

    this.prdTransportList.push({
      'sku': sku,
      'trnId': val
    });

    this.riyawiruSelectedVehicleType.push({
      'sku': sku,
      'value': 0,
      'paymentMethod': 'Cash On Delivery',
      'vehicleType': null
    });

  }

  qtyUp(cartID, index, stock, availableTotalQty, isSellInSmallerQty, unitPrice, productSku) {
    let temp = parseFloat((<HTMLInputElement>document.getElementById('itemQty' + index)).textContent);

    if (temp > 0 && temp < parseFloat(availableTotalQty)) {
      if (isSellInSmallerQty) {
        temp = temp + parseFloat(stock);
      } else {
        temp = temp + 1;
      }
    }

    document.getElementById('itemQty' + index).innerText = temp + '';
    document.getElementById('perItemPrice' + index).innerText = (temp * unitPrice).toFixed(2);

    let x = this.selectItem.length;
    let _total = 0;
    for (let i = 0; i < x; i++) {
      if (document.getElementById('perItemPrice' + i) !== null) {
        let subTotal = parseFloat((<HTMLInputElement>document.getElementById('perItemPrice' + i)).textContent);
        _total = _total + subTotal;
        document.getElementById('grandTotal').innerText = _total.toFixed(2);
      }
    }

    let body = {
      'cardId': cartID,
      'sku': productSku,
      'qty': temp
    };


    this.cart_service.updateCartQty(body).subscribe(() => {
      this.interactionService.updateCartQty('true');
    });

  }

  qtyDown(cartID, index, stock, availableTotalQty, isSellInSmallerQty, unitPrice, productSku) {
    let temp = parseFloat((<HTMLInputElement>document.getElementById('itemQty' + index)).textContent);

    if (isSellInSmallerQty) {
      if (temp > stock) {
        temp = temp - stock;
      } else {
        temp = stock;
      }
    } else {
      if (temp > 1) {
        temp = temp - 1;
      }
      if (temp < stock) {
        temp = 1;
      }
    }

    document.getElementById('itemQty' + index).innerText = temp + '';
    document.getElementById('perItemPrice' + index).innerText = (temp * unitPrice).toFixed(2);

    let x = this.selectItem.length;
    let _total = 0;
    for (let i = 0; i < x; i++) {
      if (document.getElementById('perItemPrice' + i) !== null) {
        let subTotal = parseFloat((<HTMLInputElement>document.getElementById('perItemPrice' + i)).textContent);
        _total = _total + subTotal;
        document.getElementById('grandTotal').innerText = _total.toFixed(2);
      }
    }

    let body = {
      'cardId': cartID,
      'sku': productSku,
      'qty': temp
    };

    this.cart_service.updateCartQty(body).subscribe(() => {
      this.interactionService.updateCartQty('true');
    });


  }

  calItemPrice(number: number) {
    return number.toFixed(2);
  }

  riyaviruModalData: any;
  riyawiruVehicleTypes: any;
  @ViewChild('openDeliveryModal', {static: false}) openDeliveryModal;
  @ViewChild('transportOptionsClose', {static: false}) transportOptionsClose;
  @ViewChild('closeRiyawiruModal', {static: false}) closeRiyawiruModal;
  expectationPrice: any;
  isRequiredExpectation: boolean = false;
  isPickVehicle: boolean = false;

  clickRiyaviru(item: any, sku: any) {
    this.transportOptionsClose.nativeElement.click();
    this.riyaviruModalData = item;
    this.selectedOption = sku + 1;
  }

  riyawiruModalShow() {
    this.openDeliveryModal.nativeElement.click();
  }

  getRiyawiruVehicleTypes() {
    this.riyawiruService.createToken().subscribe(createToken => {
      this.riyawiruService.fetchAllVehicleTypes(createToken['access_token']).subscribe(result => {
        this.riyawiruVehicleTypes = result;
      });
    });
  }

  selectVehicleType(id, sku) {
    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.vehicleType = id;
      }
    }
    this.selectedVehicleId = id;
    this.isPickVehicle = false;
    this.selectedId = id;
  }

  selectedItem: any;
  selectedId: any;
  isDisabled: boolean = true;

  isSelectedItem(item) {
    return this.selectedItem === item;
  }


  sendBidRequest(sku: any, expectationPrice: any) {

    if (this.selectedVehicleId === undefined) {
      this.isPickVehicle = true;
      return;
    }
    this.isPickVehicle = false;

    if (expectationPrice === undefined || expectationPrice === 0) {
      this.isRequiredExpectation = true;
      return;
    }
    this.isRequiredExpectation = false;

    for (let vel of this.riyawiruSelectedVehicleType) {
      if (vel.sku === sku) {
        vel.price = expectationPrice;
      }
    }
    this.expectationPrice = undefined;
    this.selectedVehicleId = undefined;
    this.closeRiyawiruModal.nativeElement.click();
  }
}
